export class LandingV3Controller {
  applicationName: string = "Brickflow";
  isWhiteLabelled: boolean = false;

  static $inject = ["$location", "$cookies", "$routeParams"];

  constructor(
    private $location: ng.ILocationService,
    private $cookies: ng.cookies.ICookiesService,
    private $routeParams: ng.route.IRouteParamsService,
  ) {
    // Clear out any pre-existing data
    //localStorage.removeItem('userRole'); //JS userRole will be set on navigation by no removing we fix BF-1866
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("projectname");
    sessionStorage.removeItem("selectedPackage");
    sessionStorage.removeItem("addAddon");
    sessionStorage.removeItem("productType");
    sessionStorage.removeItem("clientId");

    // Show a header if it is hidden
    document.getElementById("header").style.display = "flex";
    //Show 'Sign/Register' button if it is hidden.
    document.getElementById("signIn").style.display = "flex";

    if (this.$routeParams.introducercode) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("introducer_code", this.$routeParams.introducercode, {
        expires: expiryDate,
      });
    }
  }

  proceedAsDeveloper() {
    sessionStorage.setItem("userRole", "borrower");

    this.nextStep();

    this.$location.path("/allloans");
  }

  proceedAsBroker() {
    //TODO Change for New pricing
    window.location.assign("https://brickflow.com/pricing");
    /*sessionStorage.setItem('userRole', 'broker');

            this.nextStep();
            
            this.$location.path('/initialregister');*/
  }

  nextStep() {
    if (this.$cookies.get("access_token")) {
      this.$location.path("/promo");
    }
    //else {
    //    this.$location.path('/initialregister');
    //}
  }

  getApplicationName() {
    if (sessionStorage.getItem("applicationName") != null) {
      return sessionStorage.getItem("applicationName");
    } else {
      return "Brickflow";
    }
  }

  getLogo() {
    if (sessionStorage.getItem('companyLogoUrl') != null) {
      this.isWhiteLabelled = true;
      return sessionStorage.getItem('companyLogoUrl');
    } else {
      this.isWhiteLabelled = false;
      return '../../img/BrickFlow_Logo_RGB_Blue_Icon.svg';
    }
  }
}

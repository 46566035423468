import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { IntroducerDTO } from "@js/DTO/IntroducerDTO.cs.d";
import { IntroducerSimpleDTO } from "@js/DTO/IntroducerSimpleDTO.cs.d";
import { ApplicantRoleEnum } from "@js/models/enum/ApplicantRoleEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { IntroducerService } from "@js/services/IntroducerService";
import { IntroducerSimpleService } from "@js/services/IntroducerSimpleService";
import { LenderResultService } from "@js/services/LenderResultService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class RegistrationIntroducerController {
  user: ApplicationUserDTO;
  introducerSimple: IntroducerSimpleDTO;
  introducer: IntroducerDTO;

  registeredAccount: boolean;
  registeringAccount: boolean;
  registrationForm: FormData;
  error: string;
  passworderror: string;

  isIntroducer: boolean = false;
  adminUser: boolean = false;
  SendNewUserEmail: boolean = false;

  newUser: ApplicationUserDTO = {
    Id: "",
    Roles: ["Introducer"],
  } as ApplicationUserDTO;

  newIntroducer: IntroducerDTO = {} as IntroducerDTO;

  referrerOptions = [];
  locationOptions = [];

  static $inject = [
    "CaseService",
    "$scope",
    "$rootScope",
    "$routeParams",
    "$cookies",
    "$location",
    "$http",
    "$httpParamSerializerJQLike",
    "LenderResultService",
    "UserService",
    "AuthService",
    "RoleService",
    "IntroducerService",
    "IntroducerSimpleService",
    "$q",
    "SelectListService",
  ];

  constructor(
    private $CaseService: CaseService,
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $httpParamSerializerJQLike: ng.IHttpParamSerializer,
    private $lenderresultservice: LenderResultService,
    private $user: UserService,
    private $auth: AuthService,
    private $roleService: RoleService,
    private introducerService: IntroducerService,
    private $introducerSimpleService: IntroducerSimpleService,
    private $q: ng.IQService,
    private selectListService: SelectListService,
  ) {
    // If we have an introducer code set, let's store a cookie.
    if (this.$routeParams.introducercode) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("introducer_code", this.$routeParams.introducercode, {
        expires: expiryDate,
      });
    } else if (this.$routeParams["ic"]) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("introducer_code", this.$routeParams["ic"], {
        expires: expiryDate,
      });
    }

    if (this.$routeParams["orgc"]) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("org_code", this.$routeParams["orgc"], {
        expires: expiryDate,
      });
    }
    // Determine whether or not the user is logged in.
    if ($cookies.get("access_token")) {
      this.$auth.getProfile().then((response) => {
        this.newIntroducer.UserId = response.Id;
        this.user = response;

        this.$roleService.GetRolesIamAMemberOf().then((roles) => {
          this.user.Roles = roles;
          if (this.user.Roles.find((r) => r === "Introducer")) {
            this.isIntroducer = true;
          }
        });
        this.$roleService.isBrokerOrABove().then((response) => {
          this.adminUser = response;
          this.introducerService
            .getIntroducerDetailsByUserId(this.user.Id)
            .then((response) => {
              this.introducerSimple = response;
              this.introducer = response;
            });
        });
      });
    }

    this.referrerOptions = this.selectListService.GetReferrerOptions();
    this.locationOptions = this.selectListService.GetLocations();
  }

  doLogin(user: ApplicationUserDTO): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$auth
      .login(
        this.newUser.Email,
        this.newUser.Password,
        "CC022EBE67BE0424EA2A6548B062D2D71",
      )
      .then((response) => {
        let expiry: Date = response;
        this.$cookies.put("user_firstname", user.FirstName, {
          expires: expiry,
        });
        (this.$rootScope as any).selectedUser = user;
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }

  registerIntroducer(isRegisteringCurrentUser: boolean): void {
    this.registeringAccount = true;

    if ((this.newUser as any).ConfirmPassword !== this.newUser.Password) {
      this.error = "Passwords do not match";
    } else {
      //If we are registering a logged in user, set existing user details to go down update route
      if (
        (this.user && isRegisteringCurrentUser) ||
        (this.user && !this.isIntroducer && !this.adminUser)
      ) {
        this.newUser = this.user;
        this.registerIntroducerByUserId(this.user.Id);
      } else {
        // If we have an org code, let's set it.
        if (this.$cookies.get("org_code")) {
          this.newUser.OrgCode = this.$cookies.get("org_code");
        }
        var introduceeId = null;
        if (this.isIntroducer && !isRegisteringCurrentUser) {
          // Get id of introducee
          this.newUser.IntroducerId = this.introducerSimple.Id;
          this.newUser.IntroducerCode = this.introducer.IntroducerCode;
          introduceeId = this.user.Id;
        } else if (
          !this.user &&
          (isRegisteringCurrentUser == undefined ||
            isRegisteringCurrentUser == true)
        ) {
          // If we have an introducer code, let's set it.
          if (this.$cookies.get("introducer_code")) {
            this.newUser.IntroducerCode = this.$cookies.get("introducer_code");
          }
        }

        this.newUser.UserName = this.newUser.Email; //set user name to be same as email address for now
        // This is the fix for BF-1244
        this.newUser.ApplicantDefinedRole = ApplicantRoleEnum.Introducer;
        this.newUser.ApplicantDefinedRoleIntroducer =
          this.newIntroducer.IntroducerType;
        // send registration
        this.$user
          .addUpdate(this.newUser)
          .then((user) => {
            (this.$rootScope as any).selectedUser = user;

            //If this is a new user
            if (!this.user) {
              this.doLogin(user).then((loginSuccess) => {
                // Register introducer
                this.registerIntroducerByUserId(user.Id);
              });
            } else {
              // Register another person as introducer
              this.registerAnotherIntroducerByUserId(
                user.Id,
                introduceeId,
                this.SendNewUserEmail,
              );
            }
          })
          .catch((response) => {
            this.error =
              "It looks like there was a problem registering. " +
              response.data.Message;
            this.registeringAccount = false;
          })
          .finally(() => {});
      }
    }
  }

  registerIntroducerByUserId(userId: string): void {
    this.newIntroducer.UserId = userId;
    this.introducerService
      .registerIntroducer(this.newIntroducer)
      .then((success) => {
        //this.$auth.logout();
        //this.go("/introducer/new");
        this.go("/userdashboard/0/");
      })
      .catch((error) => {
        this.error =
          "It looks like there was a problem registering you as an introducer. Please try again in a moment.";
      })
      .finally(() => {
        this.registeringAccount = false;
      });
  }

  registerAnotherIntroducerByUserId(
    userId: string,
    introduceeId: string,
    sendNewUserEmail: boolean,
  ): void {
    this.newIntroducer.UserId = userId;
    var sendEmail = false;
    if (sendNewUserEmail) {
      sendEmail = true;
    }

    this.introducerService
      .registerAnotherIntroducer(this.newIntroducer, introduceeId, sendEmail)
      .then((success) => {
        this.go("/userdashboard");
      })
      .catch((error) => {
        this.error =
          "It looks like there was a problem registering you as an introducer. Please try again in a moment.";
      })
      .finally(() => {
        this.registeringAccount = false;
      });
  }

  change() {
    if ((this.newUser as any).ConfirmPassword === this.newUser.Password) {
      this.passworderror = "";
    } else {
      this.passworderror = "Passwords do not match";
    }
  }

  go(path): void {
    this.$location.path(path);
  }

  emailChange() {
    if (this.error) {
      this.error = "";
    }
  }
}

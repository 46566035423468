import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseSummaryDTO } from "@js/DTO/CaseSummaryDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { DevelopmentWithNoLoginDTO } from "@js/DTO/DevelopmentWithNoLoginDTO.cs.d";
import { IntroducerSimpleDTO } from "@js/DTO/IntroducerSimpleDTO.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { CaseSummaryService } from "@js/services/CaseSummaryService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { DevelopmentInputWithNoLoginService } from "@js/services/DevelopmentInputWithNoLoginService";
import { IntroducerService } from "@js/services/IntroducerService";
import { IntroducerSimpleService } from "@js/services/IntroducerSimpleService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class ClientDashboardController {
  dataLoading: boolean = false;

  //Introducers
  introducerFirstLogin: boolean = false;
  introduceClientModal: number = null;
  clientFirstName: string;
  clientSurname: string;
  clientEmail: string;
  clientPhoneNumber: string;
  notifyBorrower: boolean = true;
  introducedUsers: ApplicationUserDTO[];
  hideCaseCreation: boolean = false;
  fetchingResults: boolean;
  savedResults: DevelopmentInputDTO[];
  unsavedIntroducedSearches: DevelopmentWithNoLoginDTO[];
  savedIntroducedSearches: DevelopmentInputDTO[] = [];
  savedCases: DevelopmentInputDTO[];
  allResults: DevelopmentInputDTO[];
  selectedResult: DevelopmentInputDTO;

  shareEmails: string = "";
  multipleEmails: boolean = false;

  shareEmailsArray: string[];

  caseSummaryList: CaseSummaryDTO[];
  myCaseSummaryList: CaseSummaryDTO[];
  allCaseSummaryList: CaseSummaryDTO[];
  selectedUser: ApplicationUserDTO;
  userSearchResults: ApplicationUserDTO[];
  adminUser: boolean = false;
  isBroker: boolean = false;
  isLender: boolean = false;
  currentUser: ApplicationUserDTO = null;
  selecteduserName: string = null;
  OtherUsers: string[] = null;
  newSearchName: string;
  link: string;

  introducerSimpleDetails: IntroducerSimpleDTO;

  introducerId: number;

  clientAdminTools: boolean = false;

  showSetPasswordModal: boolean = false;
  verifyNewPassword: string;
  passwordSetSuccess: boolean = false;
  registrationForm: FormData;

  introducerErrorDisplayed: boolean = false;

  referrerOptions = [];

  static $inject = [
    "$rootScope",
    "$routeParams",
    "$location",
    "DevelopmentInputService",
    "DevelopmentInputWithNoLoginService",
    "UserService",
    "CaseService",
    "RoleService",
    "AuthService",
    "IntroducerService",
    "IntroducerSimpleService",
    "CaseSummaryService",
    "$cookies",
    "SelectListService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private $DevelopmentInputWithNoLoginService: DevelopmentInputWithNoLoginService,
    private $user: UserService,
    private $CaseService: CaseService,
    private roleService: RoleService,
    private authService: AuthService,
    private $IntroducerService: IntroducerService,
    private $IntroducerSimpleService: IntroducerSimpleService,
    private $CaseSummaryService: CaseSummaryService,
    private $cookies: ng.cookies.ICookiesService,
    private selectListService: SelectListService,
  ) {
    this.initialise();
    this.referrerOptions = this.selectListService.GetReferrerOptions();
  }

  initialise() {
    //Introducers
    this.introducerFirstLogin = (this.$rootScope as any).introducerFirstLogin;
    //get type of user
    this.roleService.isBrokerOrABove().then((response) => {
      this.adminUser = response;
      this.authService.getProfile().then((prof) => {
        this.currentUser = prof;
        //If half-registered user hasn't set a password, then prompt them to set one
        if (this.currentUser.TemporaryAccount === true) {
          this.showSetPasswordModal = true;
        }

        //Get my introducer's details
        this.$IntroducerSimpleService.getintroducerdetails().then((i) => {
          this.introducerSimpleDetails = i;
        });

        this.introducerId = prof.RegisteredIntroducerId;
        if (prof.RegisteredIntroducerId) {
          this.updateIntroducedSearches(prof.RegisteredIntroducerId);
          //this.updateIntroducedClientList(prof.RegisteredIntroducerId);
        }
        this.updateResults();
      });
    });
  }

  isValidEmail() {
    var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    this.shareEmailsArray = this.shareEmails.replace(/\s/g, "").split(/,|;/);
    for (var i = 0; i < this.shareEmailsArray.length; i++) {
      if (!regex.test(this.shareEmailsArray[i])) {
        return false;
      }
    }
    return true;
  }

  viewClientsDashboard(userName: string) {
    //Look up client's account details
    this.dataLoading = true;
    this.$user.searchByEmail(userName).then((users) => {
      this.selectedUser = users[0];
      this.selecteduserName = users[0].Email;
      (this.$rootScope as any).clientUsernameBeingViewed = users[0].Email;
      this.updateResults();
      this.clientAdminTools = false;
      this.dataLoading = false;
    });
  }

  exitClientView(): void {
    (this.$rootScope as any).clientUsernameBeingViewed = null;
    this.selecteduserName = this.currentUser.UserName;
    this.updateResults();
    this.clientAdminTools = true;
  }

  userLookup(userSearchTerm: string) {
    this.fetchingResults = true;
    this.$user
      .searchByEmail(userSearchTerm)
      .then((response) => {
        this.userSearchResults = response;
      })
      .finally(() => {
        this.fetchingResults = false;
      });
  }

  saveBlankSearch() {
    this.fetchingResults = true;
    let newDTO = {} as DevelopmentInputDTO;
    newDTO.OwnerUser = this.selecteduserName;
    newDTO.UserId = this.selectedUser.Id;
    newDTO.SaveQueryAndResults = true;
    newDTO.SearchName = this.newSearchName;
    newDTO.CI_Dev_Contingency = 0.05;
    this.$DevelopmentInputservice
      .addBlankSearch(newDTO)
      .then((response) => {
        this.$location.path("/criteria/" + response.Id);
      })
      .finally(() => {
        this.fetchingResults = false;
      });
  }

  newBlankCase() {
    var currentdate = new Date();

    var SearchName =
      "My Loan Search " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear();

    this.fetchingResults = true;
    let newDTO = {} as DevelopmentInputDTO;
    newDTO.OwnerUser = this.currentUser.UserName;
    newDTO.UserId = this.currentUser.Id;
    newDTO.SaveQueryAndResults = true;
    newDTO.SearchName = SearchName;
    newDTO.CI_Dev_Contingency = 0.05;
    this.$DevelopmentInputservice
      .saveSearch(newDTO)
      .then((response) => {
        newDTO.Id = response;
        this.makeCase(newDTO);
      })
      .finally(() => {
        this.fetchingResults = false;
      });
  }

  updateResults() {
    this.fetchingResults = true;
    let userForResults: string;
    if (this.selecteduserName) {
      userForResults = this.selecteduserName;
    } else if ((this.$rootScope as any).clientUsernameBeingViewed) {
      userForResults = (this.$rootScope as any).clientUsernameBeingViewed;
    } else {
      userForResults = this.currentUser.UserName;
    }
    this.selecteduserName = userForResults;
    if (this.selecteduserName !== this.currentUser.UserName) {
      this.$user.searchByEmail(this.selecteduserName).then((users) => {
        this.selectedUser = users[0];
      });
    }
    this.$DevelopmentInputservice
      .listbyusername(userForResults)
      .then((results) => {
        if (this.adminUser) {
          this.allResults = results;
          const distinct = (value: string, index: number, self: string[]) => {
            return self.indexOf(value) === index;
          };
          this.OtherUsers = this.allResults
            .map((s) => s.OwnerUser)
            .filter(distinct)
            .sort();
          this.allResults.map((s) => s.UserId);

          this.ApplyFilterOnUsers();
        } else {
          this.savedResults = results.filter((x) => !x.IsFullCase);
          this.savedCases = results.filter((x) => x.IsFullCase);
        }
      })
      .catch((error) => {})
      .finally(() => {
        this.fetchingResults = false;
      });
  }

  updateIntroducedSearches(RegisteredIntroducerId: number) {
    if (!RegisteredIntroducerId) {
      return;
    }
    this.$DevelopmentInputWithNoLoginService
      .fetchByIntroducerId(RegisteredIntroducerId)
      .then((results) => {
        this.unsavedIntroducedSearches = results.filter(
          (r) => r.IntroducedSearchStatus < 2,
        );
        let savedIntroducedSearchesIdentified = results.filter(
          (r) => r.IntroducedSearchStatus >= 2,
        );
        if (savedIntroducedSearchesIdentified.length > 0) {
          this.$DevelopmentInputservice
            .fetchByIntroducerId(RegisteredIntroducerId)
            .then((devInputResults) => {
              this.savedIntroducedSearches = devInputResults;
            });
        }

        this.updateIntroducedClientList();
      });
  }

  updateIntroducedClientList() {
    this.$user.getintroducees(this.currentUser.Id).then((introducedUsers) => {
      this.introducedUsers = introducedUsers;
    });
  }
  ApplyFilterOnUsers() {
    this.clientAdminTools = false;
    this.savedResults = this.allResults.filter(
      (x) => !x.IsFullCase && x.OwnerUser == this.selecteduserName,
    );
    this.savedCases = this.allResults.filter(
      (x) => x.IsFullCase && x.OwnerUser == this.selecteduserName,
    );
    (this.$rootScope as any).clientUsernameBeingViewed = this.selecteduserName;
  }

  makeCase(loanCriteria: DevelopmentInputDTO) {
    this.$CaseService
      .promotesearchtocase(loanCriteria.Id, null, null)
      .then((result: number) => {
        if (result) {
          loanCriteria.CaseId = result;
          this.savedCases.push(loanCriteria);
          this.savedResults.splice(this.savedResults.indexOf(loanCriteria), 1);
          loanCriteria.IsFullCase = true;
        }
        this.viewCase(loanCriteria);
      });
  }
  delete(loanCriteriaId: number, loanType: number, index: number) {
    switch (loanType) {
      case 0:
        //Full case
        this.$DevelopmentInputservice
          .markasdeleted(loanCriteriaId)
          .then((success) => {
            this.savedCases.splice(index, 1);
          });
        break;
      case 1:
        //Saved search
        this.$DevelopmentInputservice
          .markasdeleted(loanCriteriaId)
          .then((success) => {
            this.savedResults.splice(index, 1);
          });
        break;
      case 2:
        //Introducer: Referred search, not claimed by introducee yet
        this.$DevelopmentInputWithNoLoginService
          .markasdeleted(loanCriteriaId)
          .then((success) => {
            this.unsavedIntroducedSearches.splice(index, 1);
          });
        break;
      case 3:
        //Introducer: Referred search, claimed by introducee
        this.$DevelopmentInputservice
          .markasdeleted(loanCriteriaId)
          .then((success) => {
            this.savedIntroducedSearches.splice(index, 1);
          });
        break;
    }
  }
  viewCase(loanCriteria: DevelopmentInputDTO) {
    //this.$CaseService.getcasefromsearch(loanCriteria.Id).then((result) => {
    if (loanCriteria.IsFullCase && loanCriteria.CaseId) {
      this.$location.path("/casedashboard/" + loanCriteria.CaseId);
    } else if (loanCriteria.Id) {
      this.$location.path("/results/" + loanCriteria.Id);
    }
    //});
  }

  viewResults(loanCriteria: DevelopmentInputDTO) {
    if (loanCriteria.IsFullCase && loanCriteria.CaseId) {
      // this.$CaseService.getcasefromsearch(loanCriteria.Id).then((result) => {
      //need to tweak a bit when we have all the case status.
      this.$CaseService.updateTubeMap(loanCriteria.CaseStatus);
      this.$location.path(
        "/results/" + loanCriteria.Id + "/" + loanCriteria.CaseId,
      );
      //});
    } else if (loanCriteria.Id) {
      this.$location.path("/results/" + loanCriteria.Id);
    }
  }
  viewIntroducedResults(loanCriteria: DevelopmentWithNoLoginDTO) {
    this.$location.path("/referredSearch/" + loanCriteria.UniqueRef);
  }

  copy(developmentInputId: DevelopmentInputDTO) {
    this.$DevelopmentInputservice
      .copyCase(developmentInputId.Id, "")
      .then((response) => {
        let newDevelopmentInputId = response;
        if (newDevelopmentInputId) {
          this.viewCaseByDIId(newDevelopmentInputId);
        }
      })
      .finally(() => {
        this.fetchingResults = false;
      });
  }

  viewCaseByDIId(developmentInputId: number) {
    this.$CaseService.getcasefromsearch(developmentInputId).then((result) => {
      if (result) this.$location.path("/casedashboard/" + result.Id);
    });
  }

  newloan() {
    (this.$rootScope as any).loanCriteria = null;
    this.$location.path("/criteria");
  }

  registerIntroducer() {
    this.$location.path("/registerintroducer");
  }

  //introduceClient() {
  //    this.$IntroducerService.introduceClient(this.clientFirstName, this.clientEmail, this.clientSurname, this.clientPhoneNumber).then((success) => {
  //        this.introduceClientModal = 2;
  //        this.dataLoading = false;
  //    });
  //}

  sendLinkToUser() {
    this.$IntroducerService
      .sendLinkToUser(
        this.currentUser.FirstName,
        this.currentUser.Email,
        this.currentUser.Id,
      )
      .then((success) => {
        this.introduceClientModal = 6;
        this.dataLoading = false;
      });
  }

  copyLinkToClipboard() {
    this.$IntroducerService
      .copyLinkToClipboard(this.currentUser.Id)
      .then((response) => {
        this.link = response.toString();
        navigator.clipboard.writeText(this.link);
        this.introduceClientModal = 7;
        this.dataLoading = false;
      });
  }

  /// changed the result type as number to avoid compilation error (sevice code has been changed for newdashboard)
  introducerSendResultsToClient(
    result: DevelopmentInputDTO,
    notifyBorrower: boolean = false,
  ) {
    this.dataLoading = true;
    this.$IntroducerService
      .sendResultsToClient(
        result,
        this.clientFirstName,
        this.clientSurname,
        this.clientEmail,
        notifyBorrower,
      )
      .then((success) => {
        this.introduceClientModal = 4;
      })
      .catch((reason) => {
        this.introducerErrorDisplayed = true;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  introducerSendResultsToMultipleClient(
    result: DevelopmentInputDTO,
    notifyBorrower: boolean = false,
  ) {
    for (let i = 0; i < this.shareEmailsArray.length; i++) {
      this.dataLoading = true;
      this.$IntroducerService
        .sendResultsToClient(
          result,
          "",
          "",
          this.shareEmailsArray[i],
          notifyBorrower,
        )
        .then((success) => {
          this.introduceClientModal = 4;
        })
        .catch((reason) => {
          this.introducerErrorDisplayed = true;
        })
        .finally(() => {
          this.dataLoading = false;
          delete this.shareEmails;
        });
    }
  }

  introducerRefreshSearchesAndClients() {
    //Trigger a refresh to show results
    this.updateIntroducedSearches(this.introducerId);
    this.clientAdminTools = true;
  }

  completeAccount() {
    this.currentUser.TemporaryAccount = false;
    this.currentUser.Roles = [];
    this.currentUser.Roles.push("Client");
    if (this.currentUser.ApplicantDefinedRole == 0) {
      this.currentUser.NewUserEmailJourneyStarted = true;
    }
    this.$user.addUpdate(this.currentUser).then((response) => {
      this.passwordSetSuccess = true;
    });
  }

  afterSetPasswordContinue() {
    //User needs to be logged out and logged in again
    if (this.$cookies.get("access_token")) {
      this.authService.logout(false);
    }
    this.authService
      .login(
        this.currentUser.Email,
        this.currentUser.Password,
        "CC022EBE67BE0424EA2A6548B062D2D71",
      )
      .then((response) => {
        let expiry: Date = response;
        this.$cookies.put("user_firstname", this.currentUser.FirstName, {
          expires: expiry,
        });
        this.showSetPasswordModal = false;
        this.initialise();
      });
  }
}

import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";

export class FileAttachmentService extends BaseService<
  FileAttachmentDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/fileattachment";
    this.$broadcastBusinessNameSingular = "fileattachment";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getFileUploadUri(
    fileName: string,
    fileType: string,
    casenumber: number,
    module: ModuleEnum,
    caseLenderId: number,
  ): ng.IPromise<FileAttachmentDTO> {
    let defer = this.$q.defer<FileAttachmentDTO>();
    this.$http
      .get(this.$baseAddress + "/fileuploaduri", {
        params: {
          fileName: fileName,
          fileType: fileType,
          casenumber: casenumber,
          module: module,
          caseLenderId: caseLenderId,
        },
      })
      .then((response) => {
        defer.resolve(response.data as FileAttachmentDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  // TO upload without case Id
  getFileUpload(
    fileName: string,
    fileType: string,
  ): ng.IPromise<FileAttachmentDTO> {
    let defer = this.$q.defer<FileAttachmentDTO>();
    this.$http
      .get(this.$baseAddress + "/fileupload", {
        params: { fileName: fileName, fileType: fileType },
      })
      .then((response) => {
        defer.resolve(response.data as FileAttachmentDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getFileUri(fileName: string): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    const encodedFileName = encodeURIComponent(fileName);
    this.$http
      .get(this.$baseAddress + "/fileuri?key=" + encodedFileName)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  downloadZip(files: string[]): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(this.$baseAddress + "/downloadzip", {
        params: { keysString: files.toString() },
      })
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  renameFile(file: FileAttachmentDTO): ng.IPromise<FileAttachmentDTO> {
    let defer = this.$q.defer<FileAttachmentDTO>();
    this.$http
      .put(this.$baseAddress + "/filerename", JSON.stringify(file))
      .then((response) => {
        defer.resolve(response.data as FileAttachmentDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  UploadFileLstInSync(
    files,
    uploadingFiles: FileUploadProgressDTO[],
    objectid: number,
    fileLstOutput: FileAttachmentDTO[],
    module?: ModuleEnum,
    caseLenderId?: number,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    var file = files.pop();
    if (!uploadingFiles) {
      uploadingFiles = [];
    }
    if (file) {
      let tmpfile: FileUploadProgressDTO = {
        uploadingFile: file.name,
        uploadPercent: 0,
      };
      uploadingFiles.push(tmpfile);
      //Get link needed to upload file
      this.getFileUploadUri(
        file.name,
        file.type,
        objectid,
        module,
        caseLenderId,
      )
        .then((fileattach: FileAttachmentDTO) => {
          //send file up
          // this.$http.put(fileattach.TempUploadURL, files[0], { headers: { 'Content-Type': type }, uploadEventHandlers: {} })
          this.$http({
            method: "PUT",
            url: fileattach.TempUploadURL,
            data: file,
            headers: { "Content-Type": file.type },
            //eventHandlers: {
            //    readystatechange: function (event: any) {
            //        if (event.currentTarget.readyState === 4) {
            //        }
            //    }
            //},
            uploadEventHandlers: {
              progress(evt: any) {
                tmpfile.uploadPercent = 100.0 * (evt.loaded / evt.total);
              },
            },
          })
            .then((response) => {
              //file now sent up
              //save attachment record to srever
              this.addUpdatereturnonlyid(fileattach).then((id) => {
                //append to list of files
                fileattach.Id = id;
                fileLstOutput.push(fileattach);
                //remove the object
                if (uploadingFiles) {
                  uploadingFiles.splice(uploadingFiles.indexOf(tmpfile));
                  //hide uploads of all done
                  if (uploadingFiles.length === 0) {
                    uploadingFiles = null;
                  }
                }
              });
              // this.$scope.$apply();
            })
            .catch((response) => {
              if (uploadingFiles) {
                uploadingFiles.splice(uploadingFiles.indexOf(tmpfile));
              }
              //hide uploads of all done
              if (uploadingFiles.length === 0) {
                uploadingFiles = null;
              }
            });
        })
        .finally(() => {
          this.UploadFileLstInSync(
            files,
            uploadingFiles,
            objectid,
            fileLstOutput,
            module,
            caseLenderId,
          );
        });
      return defer.promise;
    }
  }

  uploadFileAndReturnURI(newFile): ng.IPromise<FileAttachmentDTO> {
    let defer = this.$q.defer<FileAttachmentDTO>();

    var file = newFile.pop();

    if (file) {
      let tmpfile: FileUploadProgressDTO = {
        uploadingFile: file.name,
        uploadPercent: 0,
      };

      return this.getFileUpload(file.name, file.type).then(
        (fileattach: FileAttachmentDTO) => {
          //send file up
          this.$http({
            method: "PUT",
            url: fileattach.TempUploadURL,
            data: file,
            headers: { "Content-Type": file.type },
            uploadEventHandlers: {
              progress(evt: any) {
                tmpfile.uploadPercent = 100.0 * (evt.loaded / evt.total);
              },
            },
          }).then((response) => {
            defer.resolve(fileattach as FileAttachmentDTO);
          });

          return defer.promise;
        },
      );
    }
  }

  /**
   * Upload a file to the public image folder on AWS
   * @param newImage
   */
  uploadPublicImage(newImageFile, orgCode: string): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    var file = newImageFile.pop();

    if (file) {
      let tmpfile: FileUploadProgressDTO = {
        uploadingFile: file.name,
        uploadPercent: 0,
      };

      this.getPreSignedPublicImageUrl(file.name, file.type, orgCode).then(
        (imageUrl: string) => {
          //send file up
          this.$http({
            method: "PUT",
            url: imageUrl,
            data: file,
            headers: { "Content-Type": file.type },
            uploadEventHandlers: {
              progress(evt: any) {
                tmpfile.uploadPercent = 100.0 * (evt.loaded / evt.total);
              },
            },
          }).then((response) => {
            // remove the pre-signed info from the url
            var url = imageUrl.substr(0, imageUrl.indexOf("?"));
            defer.resolve(url);
          });
        },
      );
    } else {
      defer.resolve();
    }

    return defer.promise;
  }

  /**
   * Gets a pre-signed url to the public "image" folder
   * @param fileName
   * @param fileType
   */
  getPreSignedPublicImageUrl(
    fileName: string,
    fileType: string,
    orgCode: string,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(this.$baseAddress + "/getpublicimageurl", {
        params: { fileName: fileName, fileType: fileType, orgCode: orgCode },
      })
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

import { TaskItemDTO } from "@js/DTO/TaskItemDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class TaskItemService extends BaseService<TaskItemDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/taskitem";
    this.$broadcastBusinessNameSingular = "taskitem";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchAllOrdered(): ng.IPromise<TaskItemDTO[]> {
    let defer = this.$q.defer<TaskItemDTO[]>();

    this.$http
      .get(this.$baseAddress + "/fetchallordered")
      .then((response) => {
        defer.resolve(response.data as TaskItemDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

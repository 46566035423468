export class CarouselComponentController {

    public carouselData: string[] = [];
    public totalSteps: number=0;
    public carouselStep: number = 0;
    public autoCycle: boolean = true;

    constructor(public $interval) { }

    $postLink() {
        this.totalSteps = this.carouselData.length - 1;
        this.$interval(() => {
            if (this.autoCycle) {
                this.carouselNext();
            }
        }, 7000);
    }

    $doCheck() {
        if (this.carouselStep > this.totalSteps) {
            this.carouselStep = 0;
        }
        if (this.carouselStep < 0) {
            this.carouselStep = this.totalSteps;
        }
    }

    public carouselNext() {
        //Just before stepping, make the opacity of the text 0
        this.carouselStep++;
    }

    public carouselBack() {
            this.carouselStep--;
    }

}


import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { ModuleAppFeatureDTO } from "@js/DTO/ModuleAppFeatureDTO.cs.d";
import { PricingFrequencyEnum } from "@js/models/enum/PricingFrequencyEnum.cs.d";
import { UserRoleEnum } from "@js/models/enum/UserRoleEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { UserService } from "@js/services/UserService";

export class PaymentController {
  caseId: number;
  case: CaseDTO;
  disablepayment: boolean = false;
  message: string = "";
  modal: boolean = false;
  isLoggedInUser: boolean = false;
  user: ApplicationUserDTO;

  appPackagePrices: AppPackagePricingDTO[];
  selectedProduct: AppPackagePricingDTO = null;
  selectedProductList: AppPackagePricingDTO[] = [];
  productCode: string;
  isSelected: boolean = false;
  moduleAppFeatures: ModuleAppFeatureDTO[];
  moduleFeatureCount: number = 0;
  selecteFeatureCount: number = 0;
  isBroker: boolean = false;
  isBorrower: boolean = false;
  billingFrequency: Array<{ id: number; value: string }> = [
    { id: 1, value: "Monthly" },
    { id: 2, value: "Yearly" },
  ];
  selectedPricingFrequency: boolean = false;
  selectedPricingFrequencyEnum: PricingFrequencyEnum;
  isQuickSearchSelected: boolean = false;
  isBookCallSelected: boolean = false;
  infoPanelSubheading: string;
  infoPanelHeading: string;
  panelHeaderText: string;
  orderButtonText: string = "Continue";
  orderButtonDisabled: boolean = false;
  showOrderButton: boolean = true;
  totalAmountToPay: number = 0;
  isAddOnSelected: boolean = false;
  whitelabelPrice: number = 0;
  leadGeneratorName: string;

  static $inject = [
    "$routeParams",
    "$scope",
    "$rootScope",
    "$location",
    "PaymentService",
    "CaseService",
    "RoleService",
    "$cookies",
    "AuthService",
    "UserService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $paymentService: PaymentService,
    private caseService: CaseService,
    private roleService: RoleService,
    private $cookies: ng.cookies.ICookiesService,
    private authService: AuthService,
    private userService: UserService,
  ) {
    //TODO Change for New pricing
    window.location.assign("https://brickflow.com/pricing");

    this.leadGeneratorName = this.$cookies.get("leadgeneratorname");
    this.isBroker = sessionStorage.getItem("userRole") == "broker";
    this.isBorrower = sessionStorage.getItem("userRole") == "borrower";
    this.user = JSON.parse(sessionStorage.getItem("userDetails"));

    if (
      sessionStorage.getItem("selectedPackage") &&
      sessionStorage.getItem("selectedPackage") != "undefined" &&
      sessionStorage.getItem("selectedPackage") != null
    ) {
      let data = sessionStorage.getItem("selectedPackage");
      this.selectedProduct = JSON.parse(data);
      this.selectedPricingFrequency =
        this.selectedProduct.PricingFrequency == PricingFrequencyEnum.Monthly
          ? false
          : true;
      this.getAddOnPrice(this.selectedProduct.PricingFrequency);
    }

    if (sessionStorage.getItem("addAddon")) {
      let data = sessionStorage.getItem("addAddon");
      this.isAddOnSelected = JSON.parse(data);
    }

    if (this.$routeParams.isBroker) {
      this.isBroker = true;
    }
    this.infoPanelHeading =
      this.isBroker == true
        ? "Ready to get started?"
        : "Compare loans from 40+ lenders, in minutes.<br><br>Model deals to check their viability, or contact us to secure the best value loan for your next development.";
    this.infoPanelSubheading =
      this.isBroker == true
        ? "We understand speed and reliable funding are key to retaining your clients. <br>" +
          this.getApplicationName() +
          " for Brokers gives you both."
        : "";
    this.panelHeaderText =
      this.isBroker == true
        ? "Welcome to " + this.getApplicationName() + " for Brokers"
        : "Welcome to " + this.getApplicationName();

    //if ($cookies.get('access_token')) {
    //    this.isLoggedInUser = true;
    //}
    this.getActivePackagePricesForFrequency();

    //this.roleService.GetUserRoles().then((result) => {
    //    if (result.filter(x => x == "Broker").length > 0) {
    //        this.isBroker = true;
    //    }
    //});

    /*  if (this.$routeParams.CaseId && this.$routeParams.CaseId != 'null') {
                  this.caseId = this.$routeParams.CaseId;
                  this.email = this.$routeParams.CaseId;
                  this.caseService.fetch(this.caseId).then(result => {
                      this.case = result;
  
                  })
              }*/
  }

  getTubeMapValue() {
    return this.caseService.getTubeMap();
  }

  makepaymentwithpaypal(caseId: number) {
    this.disablepayment = true;
    this.$paymentService
      .createpayment(caseId)
      .then((response) => {
        //could be called at c# end at some stage
        window.location.assign(response);
      })
      .catch(() => {
        this.disablepayment = false;
      });
  }

  markAsPaid(caseId: number) {
    this.disablepayment = true;
    this.case.IsApplicationFeePaid = 1;
    this.case.Paid = true;

    this.caseService.addUpdatereturnonlyid(this.case).then((response) => {
      this.modal = true;
      this.message = "This case has been marked as paid.";
    });
  }

  backToDashboard(): void {
    this.$location.path("/casedashboard/" + this.caseId);
  }

  getActivePackagePricesForFrequency() {
    this.selectedPricingFrequencyEnum = this.getPriceFrequencyEnum();

    this.$paymentService
      .getActivePackagePricesForFrequency(
        this.selectedPricingFrequencyEnum,
        this.isBroker ? UserRoleEnum.Broker : UserRoleEnum.Client,
      )
      .then((response) => {
        this.appPackagePrices = response;
        if (!sessionStorage.getItem("selectedPackage")) {
          this.selectedProduct = response[0];
        }
      });
  }

  searchNow() {
    (this.$rootScope as any).loanCriteria = null;
    sessionStorage.setItem(
      "selectedPackage",
      JSON.stringify(this.selectedProduct),
    );
    this.$location.path("/devfinancecriteria");
  }

  signUpNow() {
    sessionStorage.setItem(
      "selectedPackage",
      JSON.stringify(this.selectedProduct),
    );
    if (this.isBroker) {
      this.$location.path("registerbroker");
    } else {
      this.$location.path("register");
    }
  }

  bookCallNow() {
    this.authService
      .getHubspotDeveloperBookMeetingNoSearch()
      .then((hubSpotUrl: string) => {
        window.location.assign(hubSpotUrl);
      });
  }

  getPriceFrequencyEnum(): PricingFrequencyEnum {
    return this.selectedPricingFrequency == false
      ? PricingFrequencyEnum.Monthly
      : PricingFrequencyEnum.Yearly;
  }

  convertEnumToString(value) {
    switch (value) {
      case PricingFrequencyEnum.Monthly:
        return "month";
      case PricingFrequencyEnum.Yearly:
        return "year";
      default:
        return "";
    }
  }

  getApplicationName() {
    if (sessionStorage.getItem("applicationName") != null) {
      return sessionStorage.getItem("applicationName");
    } else {
      return "Brickflow";
    }
  }

  /* This will be used when we have multiple products
        onSelectingProduct(selectedProduct) {
            this.selectedProduct = { ...selectedProduct };

            this.isQuickSearchSelected = false;
            this.isBookCallSelected = false;
            if (this.isBroker) {
                if (this.selectedProductList != null && this.selectedProductList.length > 0) {
                    var found = this.selectedProductList.find(function (product) {
                        return product.AppPackageId == selectedProduct.AppPackageId;
                    });

                    if (found) {
                        this.selectedProductList = this.selectedProductList.filter(function (e) { return e.AppPackageId != selectedProduct.AppPackageId });
                    }

                    this.selectedProductList.push(this.selectedProduct);

                } else {
                    this.selectedProductList.push(this.selectedProduct);
                }
            } else {
                this.getModuleQuantityForAppPackage(selectedProduct.AppPackageId);
                this.selectedProductList = []
                this.selectedProductList.push(this.selectedProduct);
            }

            this.isSelected = true;
            this.totalAmountToPay = this.calculateTotal(this.selectedProductList);
        }
      

        isPackageSelected(selectedProduct) {
            if (this.isBroker && selectedProduct && this.selectedProductList.length > 0) {
                var found = this.selectedProductList.find(function (product) {
                    return product.Id == selectedProduct.Id;
                });

                if (found) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        getModuleQuantityForAppPackage(appPackageId: number) {
            this.$paymentService.getModuleQuantityForAppPackage(appPackageId).then((response) => {
                this.moduleFeatureCount = response;
                if (this.moduleFeatureCount > 0) {
                    this.$paymentService.getAllModuleAppFeatures().then((response) => {
                        this.moduleAppFeatures = response;
                    })
                }
            });
        }

        onSelectingModuleFeature(selectedAppFeature) {
            if (!selectedAppFeature.Selected) {
                this.selecteFeatureCount++;
            } else {
                this.selecteFeatureCount--;
            }

            selectedAppFeature.Selected = !selectedAppFeature.Selected;

        }
     
       onSelectingQuickSearch() {
            this.isQuickSearchSelected = !this.isQuickSearchSelected;
            this.removeSelectedProduct();
        }

        onSelectingBookCall() {
            this.isBookCallSelected = !this.isBookCallSelected;
            this.removeSelectedProduct();
        }

        removeSelectedProduct() {
            this.selectedProductList = [];
            this.selectedProduct = null;
            this.isSelected = false;
        }*/

  orderButton() {
    const currentPath = this.$location.path();

    if (currentPath.startsWith("/promo")) {
      this.userService.sendEventToHubspot(
        this.user,
        "PACKAGESELECTION",
        false,
        this.$cookies.get("org_code"),
        parseInt(sessionStorage.getItem("userRole")) as UserRoleEnum,
      );
      sessionStorage.setItem(
        "selectedPackage",
        JSON.stringify(this.selectedProduct),
      );
      this.$location.path("/addons");
    } else if (currentPath === "/addons") {
      this.userService.sendEventToHubspot(
        this.user,
        "ADDONSELECTION",
        false,
        this.$cookies.get("org_code"),
        parseInt(sessionStorage.getItem("userRole")) as UserRoleEnum,
      );
      this.signUpNow();
    }
  }

  back() {
    const currentPath = this.$location.path();
    if (currentPath.startsWith("/promo")) {
      this.$location.path("/initialregister");
    } else if (currentPath === "/addons") {
      this.$location.path("/promo/" + (this.isBroker ? "true" : ""));
    }
  }

  calculateTotal(): number {
    return (
      Number(
        this.selectedProduct?.Quantity * this.selectedProduct?.PriceAmount,
      ) + Number(this.isAddOnSelected ? this.whitelabelPrice : 0)
    );
  }

  emptyBasket() {
    this.removeAddOn();
    //this.selectedProduct.Quantity = 0;
    this.selectedProduct = this.appPackagePrices[0];
    sessionStorage.removeItem("selectedPackage");
  }

  getAddOnPrice(frequency: PricingFrequencyEnum) {
    this.$paymentService.getWhitelabelPrice(frequency).then((response) => {
      this.whitelabelPrice = response;
    });
  }

  addAddOn() {
    this.isAddOnSelected = true;
    sessionStorage.setItem("addAddon", "true");
  }

  removeAddOn() {
    this.isAddOnSelected = false;
    sessionStorage.setItem("addAddon", "false");
  }

  getContractLengthText(
    frequency: PricingFrequencyEnum,
    contractLength: number,
  ) {
    if (contractLength == 1) {
      return "1 Year Plan";
    } else if (contractLength == 2) {
      return "2 Year Plan";
    } else {
      return frequency == PricingFrequencyEnum.Monthly
        ? "Monthly Plan"
        : "Annual Plan";
    }
  }
}

import angular from "angular";

export function registerClickOutsideDirective() {
  const ccqdirectives = angular.module("Ccq.directives");

  ccqdirectives.directive('clickOutside', ['$document', function ($document) {
    return {
      restrict: 'A',
      scope: {
        clickOutside: '&'
      },
      link: function (scope, element) {
        // Function to check if the click is outside the HTML element
        const onClick = (event) => {
         
          // Check if the clicked target is inside the element or any ancestor has the click-outside directive
          const closestDropdown = event.target.closest('[click-outside]');

          // If the closest dropdown is not the current element, it means we clicked outside
         // const isInside = closestDropdown === element[0];

          
          // If the click is outside, trigger the clickOutside handler
          if (!element[0].contains(event.target) && !closestDropdown) {
            scope.$apply(function () {
              (scope as any).clickOutside();
            });
          }
        }
        // Listen for click events on the document
        $document.on('click', onClick);

        // Cleanup listener when the directive is destroyed
        scope.$on('$destroy', function () {
          $document.off('click', onClick);
        });
      }
    };
  }]);
}

import { CriteriaController } from "@js/controllers/CriteriaController";
import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { CommercialDealDTO } from "@js/DTO/Deal/CommercialDealDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import { SaveCommercialSearchRequest } from "@js/DTO/Messages/Deal/SaveCommercialSearchMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { DealSourceTypeEnum } from "@js/models/enum/DealSourceTypeEnum.cs.d";
import { LinkTypeEnum } from "@js/models/enum/LinkTypeEnum.cs.d";
import { LoanRepaymentTypeEnum } from "@js/models/enum/LoanRepaymentTypeEnum.cs.d";
import { LocationEnum } from "@js/models/enum/LocationEnum.cs.d";
import { OriginatorRoleTypeEnum } from "@js/models/enum/OriginatorRoleTypeEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { YesNoMaybe } from "@js/models/enum/YesNoMaybeEnum.cs.d";
import { SaveSearchReturnUniqueRefResponse } from "@js/models/SaveSearchReturnUniqueRef.cs";
import { AuthService } from "@js/services/AuthService";
import { CommercialDealService } from "@js/services/Deal/CommercialDealService";
import { DealService } from "@js/services/Deal/DealService";
import { SharedCriteriaService } from "@js/services/Deal/SharedCriteriaService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { EventLogService } from "@js/services/EventLogService";
import { OrganisationLinkService } from "@js/services/OrganisationLinkService";
import { OrganisationService } from "@js/services/OrganisationService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";
import { EnterpriseService } from "@js/services/EnterpriseService";
import { UserRoleEnum } from "@js/models/enum/UserRoleEnum.cs.d";

export class CommercialCriteriaController extends CriteriaController<CommercialDealDTO> {
  dealDto: CommercialDealDTO;
  tempDealDto: CommercialDealDTO;
  snapshotDealDto: CommercialDealDTO;
  productType: ProductTypeEnum;

  maxPurchaseDate: Date = new Date();
  minPurchaseDate: Date = new Date("Jan 01 1900");
  isLoggedInUser: boolean = false;
  // dataLoading: boolean = false;
  isSearchStarted: boolean = false;
  hasError: boolean = false;
  mortgageTerm: number = 0;

  multiPartForm1: ng.IFormController;

  //Share a search modal
  showShareSearchtoClientModal: boolean = false;
  existingUsers: UserSimpleDTO[];
  existingborrower: boolean;

  //Message to show when Enterprise url does not have valid lead gen license status or whitelabel add on is not paid or whitelabel setting is not on.
  noPermissionErrorMsg: string = "";

  //Enterprise user clientId
  clientId: string = null;
  enterpriseUser: ApplicationUserDTO = null;

  currentUseofBuildingOptions = [];
  locationOptions = [];
  borrowingEntityTypeOptions = [];

  hasPurchasecostChanged: boolean = false;

  guidanceCheckbox: boolean = true;
  shareDealDto: ShareDealDTO = {
    IsApplicant: true,
    IsPrimary: true,
  } as ShareDealDTO;
  //shows the selected existing client data on share modal
  showClientDetails: boolean = false;

  isDeal: boolean = true;

  isBorrower: boolean = false;
  organisation: OrganisationDTO;
  moveContactBrokerBtnInMiddle: boolean = false;
  initialRegistrationClient: ClientDTO;
  orgCode: string;

  //Postcode properties
  //showPostcodeErrorMessage: boolean = false;
  //postcodeErrorMsg: string;
  //previouslySelectedLocation: LocationEnum;

  isAdmin: boolean = false;
  isBroker: boolean = false;
  isLender: boolean = false;

  isCommercialOwnerOccupiedActive: boolean = false;
  showAutofill: boolean = false;
  isSubmitted: boolean = false;

  //enterprise widget journey
  isMobileView: boolean = false;
  enterpriseLinkLogo: string;
  showHelpModal: boolean = false;
  loanInfoText: string;
  progressAmount: string = "50%";
  progressSection: number = 1;
  totalSections: number = 3;
  loanRepaymentTypeOptions = [];
  fixedrateTermOptions = [];
  loanCompletionTypeOptions = [];
  productTypeOptions = [];
  timeLeftOnLeaseOptions = [];

  isWidget: boolean = false;
  //This propert is used to show only postcode ot location field on critera for mobile view
  isCriteria: boolean = true;

  error: string;
  showAdjustedAnnualEBITDAHelpertext: boolean = false;
  static $inject = [
    "$scope",
    "$rootScope",
    "$routeParams",
    "$cookies",
    "$location",
    "$q",
    "UserService",
    "RoleService",
    "OrganisationService",
    "SelectListService",
    "DevelopmentInputService",
    "CommercialDealService",
    "SharedCriteriaService",
    "EventLogService",
    "AuthService",
    "OrganisationLinkService",
    "DealService",
    "EnterpriseService"
  ].concat(CriteriaController.$inject);

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    protected $q: ng.IQService,
    private userService: UserService,
    private roleService: RoleService,
    private organisationService: OrganisationService,
    private selectListService: SelectListService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private commercialDealService: CommercialDealService,
    private sharedCriteriaService: SharedCriteriaService,
    private eventLogService: EventLogService,
    private $auth: AuthService,
    private organisationLinkService: OrganisationLinkService,
    dealService: DealService,
    private enterpriseService: EnterpriseService
  ) {
    super(dealService);
    try {

      if (this.$routeParams.context == 'widget' && window.self != window.top) {
        this.isWidget = true;
      } else {
        if (window.innerWidth <= 480)
          this.isMobileView = true
      }


      this.$auth.isCommercialOwnerOccupiedActive().then((response) => {
        this.isCommercialOwnerOccupiedActive = response;
      });

      //Initial data setup for a commercial deal
      if (this.dealDto == null) {
        this.dealDto = {} as CommercialDealDTO;
      }

      //Enteprise journey code
      if (!$cookies.get("access_token") &&
        (this.$location.path().startsWith("/e/commercialcriteria") ||
          this.$location.path().startsWith("/allloans"))
      ) {
        this.isBorrower = true;
        if (this.$location.path().startsWith("/e/commercialcriteria")) {
          var imgs = document.getElementsByTagName("img");
          this.enterpriseLinkLogo = imgs[0].src;
          this.sharedCriteriaService.storeEnterpriseLinkType(LinkTypeEnum.Commercial.toString());
        }

        if (this.isWidget) {
          //document.getElementById("footer").style.display = "none";
          this.organisationService.sendDataToParent("height", "745px");
        }

        if (!this.$routeParams.DealUniqueRef) {

          if (window.self == window.top) {
            this.orgCode = this.$cookies.get("org_code");
            sessionStorage.setItem("userRole", UserRoleEnum.Client.toString());
          } else {
            this.orgCode = location.pathname.replace("/", "");
            this.organisationService.sendDataToParent("userRole", UserRoleEnum.Client.toString());
          }

          this.logEventWithoutDealData();
        }

        this.organisationService
          .getOrgByUrlIfOrgHasEnterpriseSearchPermission(
            this.orgCode,
            ProductFamilyEnum.Commercial,
            this.$routeParams.linkId,
          )
          .then((response) => {
            if (response) {
              this.organisation = response;
              this.orgCode = response.OrganisationCode;
              if (this.$routeParams.linkId) {
                if (this.$location.path().startsWith("/e/commercialcriteria")) {
                  this.organisationLinkService
                    .getOrganisationLinkLogoByLinkIdAndLogPageLoadEvent(
                      this.$routeParams.linkId
                    )
                    .then((logoUrl) => {
                      if (logoUrl) this.enterpriseLinkLogo = imgs[0].src = logoUrl;
                    });
                }
              }

            } else {
              this.logEvent("COMMERCIALCRITERIA-NO-PERMISSION-TO-SEARCH");
              this.noPermissionErrorMsg =
                "We are unable to compare loans right now.  Please contact your broker.";
            }
          });
      }

      if (window.self == window.top && $cookies.get("access_token")) {
        this.isLoggedInUser = true;

        this.userService.getUserAutofill().then((response) => {
          this.showAutofill = response;
        });

        this.roleService.GetUserRoles().then((result) => {
          if (result.filter((x) => x.toLowerCase() == "admin").length > 0) {
            this.isAdmin = true;
          }
          if (result.filter((x) => x.toLowerCase() == "broker").length > 0) {
            this.isBroker = true;
            if (!this.isAdmin)
              //This block is to navigate a broker to userdashboard when they do not have a license.
              if (this.isBroker) {
                if (sessionStorage.getItem("isValidLicense") == "false") {
                  this.gotoDashboard();
                }
              }
          }
          if (
            result.filter((x) => x.toLowerCase() == "lender").length > 0 &&
            !this.isAdmin
          ) {
            this.isLender = true;
          }

          if (
            result.filter((x) => x.toLowerCase() == "client").length > 0 &&
            !this.isAdmin
          ) {
            this.isBorrower = true;
          }
        }).finally(() => {
          if (
            this.$auth.getHasValidStatustoShowShareSearchModal()
          ) {
            if (sessionStorage.getItem("skip")) {
              //This will hide's a share search to client modal
              this.showShareSearchtoClientModal = false;
            } else {
              //getting a previously entered DealName and Client data 
              if (this.dealService.getShowSearchNameAndUserSelectionModal()) {
                this.showShareSearchtoClientModal = true;
                this.getUsersBelongToBrokerOrAdmin();
              } else {
                this.shareDealDto.ClientDto = this.dealService.getClient();
                this.shareDealDto.DealName = this.dealService.getDealName();
              }
            }
          }
          this.getOriginatorInfoForUser();
        });
      } else {
        if (!this.$routeParams.DealUniqueRef) {
          if (window.self == window.top) {
            this.clientId = sessionStorage.getItem("clientId");
          } else {
            Promise.all([
              this.organisationService.getData("clientId").then((clientId) => {
                if (clientId) {
                  this.clientId = clientId;
                }
              }),
            ]);
          }
        }
      }

      if (this.$routeParams.DealUniqueRef && this.$routeParams.DealUniqueRef != '0') {
        this.dataLoading = true;
        this.dealService
          .fetchByUniqueRef<CommercialDealDTO>(this.$routeParams.DealUniqueRef)
          .then((response) => {
            this.postRetrieveProcessing(response);
          })
          .finally(() => {
            this.dataLoading = false;
          });
      }
      else if (this.$routeParams.SearchId > 0) {
        this.dataLoading = true;
        this.commercialDealService
          .fetch(this.$routeParams.SearchId)
          .then((response) => {
            this.postRetrieveProcessing(response)
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.dealDto.MaxLoanRequired = true;
        this.dealDto.ShowLenderInfoBrokerOverride = true;
        this.commercialTypeChanged(ProductTypeEnum.CommercialInvestment);
      }

      if (window.self == window.top) {
        this.updateGuidanceState();

        $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
          this.updateGuidanceState();
        });
      }

      this.locationOptions = this.selectListService.GetLocations();
      this.borrowingEntityTypeOptions =
        this.selectListService.GetBorrowingEntityTypeOptions(
          ProductTypeEnum.CommercialOwnerOccupied,
        );
      //iFrame agent
      this.loanRepaymentTypeOptions =
        this.selectListService.GetLoanRepaymentTypeOptions();
      this.fixedrateTermOptions =
        this.selectListService.GetFixedRateTermOptions();
      this.loanCompletionTypeOptions =
        this.selectListService.GetLoanCompletionOptions();
      this.productTypeOptions = this.selectListService.GetInterestRateType();
      this.timeLeftOnLeaseOptions =
        this.selectListService.GetTimeLeftOnLeaseOptions();
    } catch (e) {
      console.error("Error: ", e);
    }
  }

  getRequiredRoles(isCaseDashboard: boolean) {
    if (isCaseDashboard) return `['Admin', 'Client', 'Broker', 'Introducer']`;
    return `['Admin', 'Client', 'Broker', 'Introducer', 'Lender']`;
  }

  updateGuidanceState() {
    this.guidanceCheckbox =
      this.$cookies.get("guidance") === "on" ||
      this.$cookies.get("guidance") === undefined;
  }

  // Start of Share a modal functions
  viewClientsDashboardForNewSearch(userName: string) {
    this.shareDealDto =
      this.sharedCriteriaService.attachSelectedClientToShareDealDto(
        this.existingUsers,
        this.shareDealDto,
        userName,
      );
    this.showClientDetails = true;
  }

  //Clears the previously selected existing client data on share search modal
  clearInputFields() {
    this.shareDealDto =
      this.sharedCriteriaService.clearExistingUserOnShareDealDto(
        this.shareDealDto,
      );
    this.showClientDetails = false;
  }

  getUsersBelongToBrokerOrAdmin() {
    this.userService
      .getUserSummariesForBrokerOrAdmin()
      .then((response) => {
        this.existingUsers = response;
      })
      .finally(() => { });
  }

  gotoDashboard() {
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  //new search share modal close btn function
  OnClickOfSkip() {
    this.showShareSearchtoClientModal = false;
    if (this.$location.path().startsWith("/allloans") && (this.isAdmin || this.isBroker)) {
      this.dealService.setShowSearchNameAndUserSelectionModal(false);
    }
  }

  //new search share modal next btn function
  onClickOfNext() {
    this.showShareSearchtoClientModal = false;
    if (this.$location.path().startsWith("/allloans") && (this.isAdmin || this.isBroker)) {
      this.dealService.setShowSearchNameAndUserSelectionModal(false);
      this.dealService.setClient(this.shareDealDto.ClientDto);
      this.dealService.setDealName(this.shareDealDto.DealName);
    }
  }

  // End of Share a modal functions

  goToLoanSelectionOptions() {
    this.$auth.setHasValidStatustoShowShareSearchModal(
      true,
    );
    this.$location.path("/allloans");
  }

  calculatePurchaseOrLandCost() {
    if (this.dealDto.PurchasePrice && !this.hasPurchasecostChanged) {
      this.dealDto.PurchaseCosts = (this.dealDto.PurchasePrice * 5) / 100;
    }
  }

  datasetupOnOwnOrPurchaseChange() {
    this.dealDto = this.commercialDealService.datasetupOnOwnOrPurchaseChange(
      this.dealDto,
    );
  }

  datasetupOnMaxLoanRequiredChange() {
    this.dealDto = this.dealService.dataSetupOnMaxLoanRequiredChange(
      this.dealDto,
    );
  }

  onOwnOrPurchaseChange(OwnOrPurchase: boolean) {
    this.dealDto.OwnOrPurchase = this.dealDto.HasOwnOrPurchase
      ? OwnOrPurchaseEnum.Own
      : OwnOrPurchaseEnum.Purchasing;
    this.datasetupOnOwnOrPurchaseChange();
  }

  onCurrentUseOfBuilding() {
    if (this.dealDto.ProductType == ProductTypeEnum.CommercialInvestment) {
      this.dealDto = this.commercialDealService.datasetupOnCurrentUseOfBuilding(
        this.dealDto,
      );
    }
  }

  onIsFamilyInResidenceChange() {
    this.dealDto.IsFamilyInResidence = this.dealDto.HasIsFamilyInResidence
      ? YesNoMaybe.Yes
      : YesNoMaybe.No;
  }

  initialDataSetupForIsFamilyInResidence() {
    if (this.dealDto.HasIsFamilyInResidence) {
      this.dealDto.IsFamilyInResidence = YesNoMaybe.Yes;
      this.dealDto.HasIsFamilyInResidence = true;
    } else {
      this.dealDto.IsFamilyInResidence = YesNoMaybe.No;
      this.dealDto.HasIsFamilyInResidence = false;
    }
  }


  async logEvent(identifier: string) {
    var orgCode;
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto?.OrganisationLinkId,
          this.dealDto.ProductType,
          "",
          this.dealDto.Id,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto?.OrganisationLinkId,
          this.dealDto.ProductType,
        );
      }
    } else {
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");
      if (clientId) {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto.OrganisationLinkId,
          this.dealDto.ProductType,
          "",
          this.dealDto.Id,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto.OrganisationLinkId,
          this.dealDto.ProductType,
        );
      }
    }
  }

  goToSearchResults() {
    this.isSubmitted = true;
    this.isSearchStarted = true;
    this.hasError = false;
    this.error = null;
    // If enterprise
    if (
      !this.isLoggedInUser &&
      this.isBorrower &&
      (this.$location.path().startsWith("/e/commercialcriteria") ||
        this.$location.path().startsWith("/allloans"))
    ) {
      this.enterpriseService.setProductFamily(this.dealDto.ProductFamily);

      this.saveCriteriaReturnUniqueRef().then((response) => {
        if (!this.hasError) {
          this.sharedCriteriaService.setIsDataLoading(false);
          var uniqueRef = response as SaveSearchReturnUniqueRefResponse;
          if (window.self == window.top)
            sessionStorage.removeItem("productType");
          if (response.HasDealClient) {
            //checking route is to handle navigating to correct route when user lands on criteria on click of change search form side panel.
            if (sessionStorage.getItem('previousRoute') == 'referredsearchdeal') {
              this.$location.path(`/referredsearchdeal/${response.DealClientUniqueRef}/false`);
              sessionStorage.removeItem('previousRoute');
            } else {
              this.$location.path(`/e/commercialresults/${response.DealUniqueRef}`);
            }
          } else {
            var leadCaptureUrl = `/e/enterpriseleadcapture/${response.DealUniqueRef}`;
            leadCaptureUrl = this.isWidget ? `${leadCaptureUrl}/widget` : leadCaptureUrl;

            this.$location.path(leadCaptureUrl);
          }
        }
      });
    } else {
      this.saveCriteriaReturnId().then((response) => {
        if (!this.hasError) {
          var dealId = response as number;
          if (window.self == window.top)
            sessionStorage.removeItem("productType");
          if (this.isAdmin || this.isBroker) this.dealService.setShowSearchNameAndUserSelectionModal(true);
          this.$location.path("/commercialresults/" + dealId);
        }
      });
    }
  }

  searchDisabled() {

    if (this.progressSection == 1) {
      this.progressAmount = "0%";

      if (
        this.dealDto.IsFamilyInResidence == 1 ||
        this.dealDto.Locations == null ||
        this.dealDto.Locations <= 0 ||
        this.showPostcodeErrorMessage
      ) {
        return true;
      }

      if (this.multiPartForm1.$invalid) {
        return true;
      }

      this.progressAmount = "33%";
    }



    if (this.progressSection == 2) {
      this.progressAmount = "33%";

      if (
        this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing &&
        (this.dealDto.PurchasePrice == null || this.dealDto.PurchasePrice == 0)
      ) {
        return true;
      }

      if (
        this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own &&
        (this.dealDto.Currentvalue == null || this.dealDto.Currentvalue == 0)
      ) {
        return true;
      }

      if (this.dealDto.IsFamilyInResidence == 1 || this.hasValidNetLoanRequiredValue()) {
        return true;
      }

      if (this.multiPartForm1.$invalid) {
        return true;
      }

      this.progressAmount = "66%";

    }


    if (this.progressSection == 3) {
      this.progressAmount = "66%";

      if (this.multiPartForm1.$invalid) {
        return true;
      }

      this.progressAmount = "100%";
    }

    return false;
  }

  saveCriteriaReturnId(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.dataSetUpForSave();

    var clientDto = null;
    /* if (!this.isLoggedInUser && this.enterpriseUser && (this.$location.path().startsWith("/e/commercialcriteria") || this.$location.path().startsWith("/allloans"))) {
                clientDto = this.sharedCriteriaService.setClientDtoDataForEnterpriseSearch(this.enterpriseUser, this.brokerageOrg.Id, clientDto);
            }*/

    var request: SaveCommercialSearchRequest = {
      DealDto: this.dealDto,
      ShareDealDto: this.shareDealDto,
      OrgCode: this.isLoggedInUser ? "" : this.orgCode,
      ClientId:
        !this.isLoggedInUser &&
          this.clientId &&
          !this.enterpriseUser &&
          (this.$location.path().startsWith("/e/commercialcriteria") ||
            this.$location.path().startsWith("/allloans"))
          ? Number(this.clientId)
          : 0,
      EnterpriseClientDto: clientDto,
    };

    this.commercialDealService
      .saveCommercialSearchReturnsId(request)
      .then((response) => {
        this.dealDto.Id = response;

        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
        if (response.status === 500) {
          this.error = response.data.message;
        } else {
          this.error = 'Something went wrong while searching for loans. Please try again.';
        }
        this.hasError = true;
        this.isSearchStarted = false;
      });

    return defer.promise;
  }

  saveCriteriaReturnUniqueRef(): ng.IPromise<SaveSearchReturnUniqueRefResponse> {
    let defer = this.$q.defer<SaveSearchReturnUniqueRefResponse>();
    this.sharedCriteriaService.setIsDataLoading(true);

    this.dataSetUpForSave();

    var clientDto = null;
    /* if (!this.isLoggedInUser && this.enterpriseUser && (this.$location.path().startsWith("/e/commercialcriteria") || this.$location.path().startsWith("/allloans"))) {
                clientDto = this.sharedCriteriaService.setClientDtoDataForEnterpriseSearch(this.enterpriseUser, this.brokerageOrg.Id, clientDto);
            }*/

    var request: SaveCommercialSearchRequest = {
      DealDto: this.dealDto,
      ShareDealDto: this.shareDealDto,
      OrgCode: this.isLoggedInUser ? "" : this.orgCode,
      ClientId: !this.isWidget &&
        !this.isLoggedInUser &&
        this.clientId &&
        !this.enterpriseUser &&
        (this.$location.path().startsWith("/e/commercialcriteria") ||
          this.$location.path().startsWith("/allloans"))
        ? Number(this.clientId)
        : 0,
      EnterpriseClientDto: clientDto,
    };

    this.commercialDealService
      .saveCommercialSearchReturnsUniqueRef(request)
      .then((response) => {
        this.dealDto.UniqueRef = response.DealUniqueRef;

        defer.resolve(response as SaveSearchReturnUniqueRefResponse);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
        this.sharedCriteriaService.setIsDataLoading(false);
        if (response.status === 500) {
          this.error = response.data.message;
        } else {
          this.error = 'Something went wrong while searching for loans. Please try again.';
        }
        this.hasError = true;
        this.isSearchStarted = false;
      });

    return defer.promise;
  }

  dataSetUpForSave() {
    this.sharedCriteriaService.setNewSearch(this.dealDto, this.snapshotDealDto);

    if (this.dealDto.Id == null) {
      this.dealDto.Status = CaseStatusEnum.Search;
    }

    if (
      this.dealDto.Name == null ||
      this.dealDto.Name == "" ||
      this.dealDto.Name == undefined
    ) {
      this.dealDto.Name = this.sharedCriteriaService.defaultSearchName(
        this.dealDto.ProductType,
      );
    }

    if (
      !this.isLoggedInUser &&
      (this.$location.path().startsWith("/e/commercialcriteria") ||
        this.$location.path().startsWith("/allloans")) &&
      this.isBorrower
    ) {
      this.dealDto.HowManyFlats = this.dealDto.HowManyFlats
        ? this.dealDto.HowManyFlats
        : 0;
      this.dealDto.HowManyHouses = this.dealDto.HowManyHouses
        ? this.dealDto.HowManyHouses
        : 0;
    }

    if (!this.shareDealDto) {
      this.shareDealDto = {
        DealName: this.sharedCriteriaService.defaultSearchName(
          this.dealDto.ProductType,
        ),
      } as ShareDealDTO;
    }

    if (this.dealDto.PurchasePrice == null) {
      this.dealDto.PurchasePrice = 0;
    }

    if (this.dealDto.PurchaseCosts == null) {
      this.dealDto.PurchaseCosts = 0;
    }
  }

  dummysearch() {
    if (this.dealDto.hasPostcode) {
      this.dealDto.PostcodeSearchString =
        this.sharedCriteriaService.getRandomPostcode();
      this.getRegionByPostcode();
    } else {
      this.dealDto.Locations = LocationEnum.London;
    }

    this.mortgageTerm = this.sharedCriteriaService.getRandomIntInclusive(
      10,
      30,
    );
    this.mortgageTermToLoanTerm();
    this.dealDto.MaxLoanRequired =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 1
        ? true
        : false;
    if (!this.dealDto.MaxLoanRequired) {
      this.dealDto.NetLoanRequired =
        this.sharedCriteriaService.getRandomIntInclusive(400000, 2000000);
    }

    if (this.dealDto.ProductType == ProductTypeEnum.CommercialInvestment) {
      this.dealDto.GrossMonthlyRentalIncome =
        this.sharedCriteriaService.getRandomIntInclusive(10000, 20000);
    } else {
      this.dealDto.AdjustedAnnualEBITDA =
        this.sharedCriteriaService.getRandomIntInclusive(500000, 1000000);
    }

    this.dealDto.PurchasePrice =
      this.sharedCriteriaService.getRandomIntInclusive(5000000, 10000000);
    this.dealDto.PurchaseCosts =
      this.sharedCriteriaService.getRandomIntInclusive(100000, 1000000);
  }

  initMortgageTerm() {
    this.dealDto.LoanTermReq = this.dealDto.LoanTermReq || 240;
    this.mortgageTerm = this.dealDto.LoanTermReq / 12;
  }

  mortgageTermToLoanTerm() {
    this.dealDto.LoanTermReq = this.mortgageTerm * 12;
  }

  onLoanRepaymentTypeChange() {
    if (this.dealDto.LoanRepaymentType == LoanRepaymentTypeEnum.Repayment) {
      this.mortgageTerm = 20;
    } else if (
      this.dealDto.LoanRepaymentType == LoanRepaymentTypeEnum.InterestOnly
    ) {
      this.mortgageTerm = 10;
    }
    this.mortgageTermToLoanTerm();
  }

  hasValidNetLoanRequiredValue() {
    if (
      !this.dealDto.MaxLoanRequired &&
      (this.dealDto.NetLoanRequired == null ||
        isNaN(this.dealDto.NetLoanRequired) ||
        this.dealDto.NetLoanRequired <= 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getRegionByPostcode() {
    if (
      this.dealDto.PostcodeSearchString &&
      this.dealDto.PostcodeSearchString.replace(" ", "").length >= 2
    ) {
      if (
        this.dealService.isValidPostcodeString(
          this.dealDto.PostcodeSearchString,
        )
      ) {
        this.dataLoading = true;
        this.dealService
          .getRegionByPostcode(this.dealDto.PostcodeSearchString)
          .then((response) => {
            if (response.Location != null) {
              this.dealDto.Locations = response.Location;
              this.showPostcodeErrorMessage = false;
            } else {
              this.postcodeErrorMsg = response.PostCodeErrorMessage;
              this.showPostcodeErrorMessage = true;
              if (this.dealDto.Locations) this.dealDto.Locations = null;
            }
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.postcodeErrorMsg =
          "At least one letter and one number must be entered e.g. SW1.";
        this.showPostcodeErrorMessage = true;
        if (this.dealDto.Locations) this.dealDto.Locations = null;
      }
    } else {
      if (this.dealDto.Locations) this.dealDto.Locations = null;
    }
  }

  onHasPostcodeChange() {
    if (!this.dealDto.hasPostcode) {
      this.showPostcodeErrorMessage = false;
      this.dealDto.PostcodeSearchString = null;
      this.dealDto.Locations = this.previouslySelectedLocation;
    } else {
      this.previouslySelectedLocation = this.dealDto.Locations;
      this.dealDto.Locations = null;
    }
  }

  isCriteriaFormInValid() {
    if (
      this.isSearchStarted ||
      this.multiPartForm1.$invalid ||
      this.dealDto.IsFamilyInResidence == 1 ||
      this.dealDto.Locations == null ||
      this.dealDto.Locations <= 0 ||
      this.hasValidNetLoanRequiredValue() ||
      this.showPostcodeErrorMessage
    ) {
      if (this.isSearchStarted) {
        this.progressAmount = "100%";
      } else {
        this.progressAmount = "66%";
      }
      return true;
    } else {
      this.progressAmount = "100%";
      return false;
    }
  }

  commercialTypeChanged(productType: ProductTypeEnum) {
    this.progressSection = 1;
    this.getLoanInfoText(productType);
    this.dealDto = {} as CommercialDealDTO;
    this.commercialDealService.initialDataSetUp(this.dealDto, productType);
    if (this.$cookies.get("access_token")) {
      this.getOriginatorInfoForUser();
    } else {
      this.getOriginatorInfoForAnonymousUsers();
    }
    this.currentUseofBuildingOptions =
      this.selectListService.GetCommercialCurrentUseOfBuildingOptions(
        this.dealDto.ProductType,
      );
    this.onLoanRepaymentTypeChange();

    this.hasPurchasecostChanged = false; 
    if (!this.isLoggedInUser &&
      (this.$location.path().startsWith("/e/commercialcriteria") ||
        this.$location.path().startsWith("/allloans"))
    ) {
      if (this.$routeParams.linkId && this.$routeParams.linkId != "0") {
        this.dealDto.OrganisationLinkId = this.$routeParams.linkId;
      }

      this.logEvent("COMMERCIALCRITERIA-PAGE1");
    }
  }

  showBasedOnMainResidence() {
    return this.dealService.showBasedOnMainResidence(this.dealDto);
  }

  isFieldEmpty(fieldName, isEnum = false) {
    if (this.isSubmitted && this.dealDto) {
      const fieldValue = this.dealDto[fieldName];
      if (fieldName == "PlanningPermissionType") {
        if (fieldValue == null || fieldValue < 1) {
          return true;
        }
      }
      if (fieldValue == null || (isEnum && fieldValue === "0")) {
        return true;
      }
    }

    return false;
  }

  isFieldZero(fieldName) {
    if (this.isSubmitted && this.dealDto) {
      const fieldValue = this.dealDto[fieldName];
      if (fieldValue == null || fieldValue === '0' || fieldValue === 0) {
        return true;
      }
    }
    return false;
  }

  clickNextButton() {
    if (this.progressSection == 1) {
      if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/commercialcriteria") || this.$location.path().startsWith("/allloans"))) {
        this.logEvent("COMMERCIALCRITERIA-PAGE2");
      }
      this.progressSection = 2;
    } else {
      if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/commercialcriteria") || this.$location.path().startsWith("/allloans"))) {
        this.logEvent("COMMERCIALCRITERIA-PAGE3");
      }
      this.progressSection = 3;
    }
  }

  clickBackButton() {
    if (this.progressSection == 3) {
      if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/commercialcriteria") || this.$location.path().startsWith("/allloans"))) {
        this.logEvent("COMMERCIALCRITERIA-PAGE2");
      }
      this.progressSection = 2;
    } else {
      if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/commercialcriteria") || this.$location.path().startsWith("/allloans"))) {
        this.logEvent("COMMERCIALCRITERIA-PAGE1");
      }
      this.progressSection = 1;
    }
  }

  getLoanInfoText(productType) {
    switch (productType) {
      case ProductTypeEnum.CommercialInvestment:
        this.loanInfoText =
          "For landlords and investors that let to unrelated third parties";
        break;
      case ProductTypeEnum.CommercialOwnerOccupied:
        this.loanInfoText =
          "For businesses that own and operate out of their own premises";
        break;
      default:
        this.loanInfoText = "";
        break;
    }
  }
  async logEventWithoutDealData() {
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          "COMMERCIALCRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.CommercialAll,
          "",
          this.dealDto.Id,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          "COMMERCIALCRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.CommercialAll,
          "",
          this.dealDto.Id,
        );
      }
    } else {
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");

      if (clientId) {
        this.eventLogService.logPageLoad(
          "COMMERCIALCRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.CommercialAll,
          "",
          this.dealDto.Id,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          "COMMERCIALCRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.CommercialAll,
          "",
          this.dealDto.Id,
        );
      }
    }
  }

  getOriginatorInfoForUser() {
    if (this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Admin;
      this.dealDto.SourceType = DealSourceTypeEnum.Application;
    } else if (this.isBroker && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Broker;
    } else if (this.isBorrower && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Borrower;
    } else if (this.isLender && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Lender;
      this.dealDto.SourceType = DealSourceTypeEnum.Application;
    }

    if (window.self == window.top) {

      if (this.isLender) {
        this.userService.getCurrentUserLender().then((response) => {
          this.dealDto.OriginatorLenderId = response;
        });
      }

      if (this.isBroker || this.isBorrower) {
        this.organisationService.getOrganisation().then((org) => {
          if (org != null) {
            if (org.Id != 0) this.dealDto.OriginatorOrganisationId = org.Id;
            this.dealDto.SourceType = org.IsWhiteLabelled
              ? DealSourceTypeEnum.WhitelabelApplication
              : DealSourceTypeEnum.Application;
          }
        });
      }


    }
  }

  getOriginatorInfoForAnonymousUsers() {
    this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Borrower;

    if (window.self == window.top) {
      this.dealDto.SourceType = DealSourceTypeEnum.Enterprise;
    } else {
      this.dealDto.SourceType = this.isWidget ? DealSourceTypeEnum.WidgetEnterprise : DealSourceTypeEnum.IFrameEnterprise;
    }

    if (this.$routeParams.linkId) {
      if (window.self == window.top) {
        this.dealDto.SourceType = DealSourceTypeEnum.UniqueLink;
      } else {
        this.dealDto.SourceType = this.isWidget ? this.$routeParams.linkId == "0"? DealSourceTypeEnum.WidgetEnterprise: DealSourceTypeEnum.WidgetUniqueLink : DealSourceTypeEnum.IFrameUniqueLink;
      }
    }
  }

  postRetrieveProcessing(response) {
    this.dealDto = { ...response };
    this.getLoanInfoText(this.dealDto.ProductType);
    this.currentUseofBuildingOptions =
      this.selectListService.GetCommercialCurrentUseOfBuildingOptions(
        this.dealDto.ProductType,
      );
    this.mortgageTerm = this.dealDto.LoanTermReq / 12;
    this.snapshotDealDto = { ...response };
  }
}

import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { RegistrationRequestDTO } from "@js/DTO/RegistrationRequestDTO.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { LenderResultService } from "@js/services/LenderResultService";
import { OrganisationService } from "@js/services/OrganisationService";
import { RoleService } from "@js/services/RoleService";
import { UserService } from "@js/services/UserService";
import { ClientService } from "@js/services/ClientService"
import { DealClientService } from "@js/services/DealClientService"


export class LoginController {
  dataLoading: boolean = false;
  username: string;
  password: string;

  // For password reset;
  tempUsername: string;
  tempPassword: string;
  email: string;
  resetEmail: string;

  resetSubmitted: boolean;
  resettingPassword: boolean;

  registrationSubmitted: boolean;
  registeringAccount: boolean;
  registerAccountForm: FormData;
  passwordResetForm: boolean = true;
  loginform: FormData;
  error: string;
  newUser: RegistrationRequestDTO = {
    Email: "",
    FirstName: "",
    LastName: "",
  } as RegistrationRequestDTO;
  confirmEmailAddress: string;
  newPassword: string = null;
  verifyNewPassword: string = null;

  showPostRestPasswordScreen: boolean = false;

  loanCriteria: DevelopmentInputDTO;
  autoLogin: boolean = false;
  applicationName: string;

  orgUniqueRef: string;
  isBorrower: boolean = false;

  //Parameters passed from enterprise
  isEnterprise: boolean = false;
  productFamily: ProductFamilyEnum = null;
  dealUniqueRef: string = null;
  clientId: number = null;

  static $inject = [
    "CaseService",
    "$scope",
    "$rootScope",
    "$routeParams",
    "$cookies",
    "$location",
    "$http",
    "$httpParamSerializerJQLike",
    "LenderResultService",
    "AuthService",
    "RoleService",
    "UserService",
    "OrganisationService",
    "ClientService",
    "DealClientService"
  ];

  static searchInProgress: boolean = false;

  constructor(
    private $CaseService: CaseService,
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $httpParamSerializerJQLike: ng.IHttpParamSerializer,
    private $lenderresultservice: LenderResultService,
    private $auth: AuthService,
    private $roleService: RoleService,
    private $user: UserService,
    private organisationService: OrganisationService,
    private clientService: ClientService,
    private dealClientService: DealClientService

  ) {

    if (this.$location.path().startsWith('/e/login')) {
      this.isEnterprise = true;
      this.productFamily = Number(this.$routeParams.productFamily);
      this.dealUniqueRef = this.$routeParams.dealUniqueRef;
      if(this.$routeParams.clientId) this.clientId = Number(this.$routeParams.clientId);
      if(this.$routeParams.emailAddress) this.username = this.$routeParams.emailAddress;

    }

    //if (localStorage.getItem('userRole')) {
    //    this.isBorrower = localStorage.getItem('userRole') == 'borrower';
    //}

    // // Show a header if it is hidden
    document.getElementById("header").style.display = "flex";
    // //Hiding 'Sign/Register' button.
    document.getElementById("signIn").style.display = "none";

    // rootScope.title = "Login";
    if (this.$routeParams.tempUser && this.$routeParams.tempPassword) {
      this.tempUsername = this.$routeParams.tempUser;
      this.tempPassword = this.$routeParams.tempPassword;
      if (this.$routeParams.userStatus == "new") {
        this.autoLogin = true;
        //If this is a half registered user, log in using details in route params
        this.loginThenResetPassword(this.tempPassword);
      } else {
        //Get Application name depending on the user
        this.organisationService
          .getOrgApplicationNameByUserName(this.tempUsername)
          .then((applicationName: string) => {
            this.applicationName = applicationName;
            if (this.applicationName != "Brickflow") {
              sessionStorage.setItem("applicationName", this.applicationName);

              if ((window as any).HubSpotConversations) {
                (window as any).HubSpotConversations.widget.remove();
              }
            }
            this.showPostRestPasswordScreen = true;
          });
      }
    } else if ($cookies.get("access_token")) {
      this.$roleService.goHomeBasedOnUser();
    }

    // If we have an introducer code set, let's store a cookie.
    if (this.$routeParams.introducercode) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("introducer_code", this.$routeParams.introducercode, {
        expires: expiryDate,
      });
    } else if (this.$routeParams["ic"]) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("introducer_code", this.$routeParams["ic"], {
        expires: expiryDate,
      });
    }

    if (this.$routeParams["orgc"]) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("org_code", this.$routeParams["orgc"], {
        expires: expiryDate,
      });
    }

    if (this.$routeParams["orgref"]) {
      this.orgUniqueRef = this.$routeParams["orgref"];
      sessionStorage.setItem("assignUserToOrg", "true");
    }

    this.loanCriteria = (this.$rootScope as any).loanCriteria;
  }

  login() {
    (this.$rootScope as any).formSaved = true;
    this.loginThenResetPassword(null);
  }

  goToRegister() {
    if (
      (this.$rootScope as any).loginRedirectPath &&
      (this.$rootScope as any).loginRedirectPath.includes("/securitycheck")
    ) {
      var url = (this.$rootScope as any).loginRedirectPath;
      var introducerCode = url.replace("/securitycheck/", "");

      if (introducerCode) {
        this.$location.path("/registershareholder/" + introducerCode);
      } else {
        this.$location.path("/landingV3");
      }
    } else {
      this.username = null;
      this.password = null;
      this.$location.path("/landingV3");
    }
  }

  //this handles a normal login and a login where the password needs restting at same time
  private loginThenResetPassword(newPassword: string) {
    this.dataLoading = true;
    this.$auth
      .login(
        newPassword ? this.tempUsername : this.username,
        newPassword ? this.tempPassword : this.password,
        "CC022EBE67BE0424EA2A6548B062D2D71",
      )
      .then((response) => {
        let expiry: Date = response;
        //if a new password change it ie on resetpassword
        if (newPassword) {
          this.$auth.changePassword(this.tempPassword, newPassword);
        }
        this.$auth
          .getProfile()
          .then((response) => {
            this.$cookies.put("user_firstname", response.FirstName, {
              expires: expiry,
            });

            (this.$rootScope as any).selectedUser = response;

            this.$rootScope.$broadcast("login"); //tell everyone you have logged in
            this.$roleService.GetUserRoles(true).then((result) => {
              if (this.isEnterprise) {               
                if(this.$routeParams.emailAddress && this.$routeParams.clientId){
                  this.clientService.updateClientWithUserId(this.clientId);
                  this.gotoResultsPage();
                }else{
                  this.dealClientService.addCurrentUserToDeal(this.$routeParams.dealUniqueRef).then((response) => {
                    this.gotoResultsPage();
                  });
                }
              }
              else {
                //if there's a loanCriteria, create search to persist on system
                if (this.loanCriteria) {
                  this.loanCriteria.UserId = response.Id;
                  this.loanCriteria.User = response;
                  this.loanCriteria.SaveQueryAndResults = true;

                  this.$lenderresultservice
                    .fetchMatchingResults(
                      this.loanCriteria,
                      true,
                      true,
                      4,
                      false,
                      "",
                      response.Email,
                      response.PhoneNumber,
                      "",
                    )
                    .then((response) => {
                      if (this.loanCriteria.SaveCase === true) {
                        //case creation
                        this.$CaseService
                          .promotesearchtocase(
                            response.Id,
                            (this.$rootScope as any)
                              .selectedResultForAttachingToCase.Id,
                            (this.$rootScope as any).productResultList,
                          )
                          .then((success) => {
                            if (LoginController.searchInProgress) {
                              this.$location.path("/results/" + response.Id);
                            } else {
                              this.$location.path("/userdashboard");
                            }
                            LoginController.searchInProgress = false;
                          });
                      } else {
                        if (LoginController.searchInProgress) {
                          this.$location.path("/results/" + response.Id);
                        } else {
                          this.$location.path("/userdashboard");
                        }
                        LoginController.searchInProgress = false;
                      }
                    });
                } else {
                  var targeturl = this.$location.search().targeturl;

                  if (targeturl) {
                    this.$location.search({});
                    this.$location.path(targeturl);
                  }
                  // Check to see if we should load the admin dashboard or the end user dashboard.
                  else if (
                    this.$location.path().startsWith("/loginandreturnto")
                  ) {
                    //return back to the page it came from
                    (this.$rootScope as any).back();
                  } else if (
                    (this.$rootScope as any).loginRedirectPath &&
                    !(this.$rootScope as any).loginRedirectPath.includes("/login")
                  ) {
                    const loginRedirectPath = (this.$rootScope as any)
                      .loginRedirectPath;
                    (this.$rootScope as any).loginRedirectPath = undefined;
                    this.$location.path(loginRedirectPath);
                  } else if (this.orgUniqueRef != null) {
                    this.$location.path("/registerbroker/" + this.orgUniqueRef);
                  } else {
                    this.$roleService.goHomeBasedOnUser();
                  }
                }
              }

            });

            this.$user.sendEventToHubspot(response, null, true);
          })
          .catch((response) => {
            this.error = response;
          })
          .finally(() => {
            this.dataLoading = false;
          });
      })
      .catch((response) => {
        this.error = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  openResetModal() {
    this.resetEmail = this.username;
    this.resettingPassword = true;
  }

  resetPassword() {
    this.passwordResetForm = true;
    this.dataLoading = true;
    //this.username = null;
    //this.password = null;
    //this.login();
    this.$auth
      .resetPassword(this.resetEmail)
      .then((response) => {
        this.resetSubmitted = true;
        this.resettingPassword = false;
      })
      .catch((error) => {
        this.passwordResetForm = error;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  postResetPassword() {
    //login thenchange the password then
    if (this.verifyNewPassword == this.newPassword) {
      this.loginThenResetPassword(this.newPassword);
    }
  }

  clearPasswordReset() {
    this.resettingPassword = false;
    this.resetSubmitted = false;
  }

  gotoResultsPage(){
    var redirectUrl = '';

    switch (this.productFamily) {
      case ProductFamilyEnum.Development:
        redirectUrl = `/devfinanceresults/${this.dealUniqueRef}`;
        break;
      case ProductFamilyEnum.Bridging:
        redirectUrl = `/bridgingresults/${this.dealUniqueRef}`;
        break;
      case ProductFamilyEnum.Commercial:
        redirectUrl = `/commercialresults/${this.dealUniqueRef}`;
        break;
      default:
        redirectUrl = '/userdashboard';
        break;
    }

    this.$location.path(redirectUrl);
  }
}

import { InviteDTO } from "@js/DTO/InviteDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class InviteService extends BaseService<InviteDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/invite";
    this.$broadcastBusinessNameSingular = "invite";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getInvitedBorrowers(userId: string): ng.IPromise<InviteDTO[]> {
    let defer = this.$q.defer<InviteDTO[]>();
    this.$http
      .get(this.$baseAddress + "/getListInvitedBrokers?userId=" + userId)
      .then((response) => {
        let results = response.data as InviteDTO[];
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  checkIfInvitedIsUser(
    invitationCode: string,
    userId: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/checkIfInvitedIsUser?invitationCode=" +
          invitationCode +
          "&userId=" +
          userId,
      )
      .then((response) => {
        let results = response.data as boolean;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  checkIfRegisterUserWasInvited(
    inviteEmail: string,
    inviteGuid: string,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    var encodeInviteEmail = encodeURIComponent(inviteEmail);
    this.$http
      .get(
        this.$baseAddress +
          "/checkIfRegisterUserWasInvited?inviteEmail=" +
          encodeInviteEmail +
          "&inviteGuid=" +
          inviteGuid,
      )
      .then((response) => {
        let results = response.data as string;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

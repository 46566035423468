export const enum PlanningPermissionTypeEnum {
  None = 0,
  NoPlanning = 1,
  DetailedPlanning = 2,
  OutlinePlanning = 3,
  NoPlanningRequired = 4,
  PermittedDevelopment = 5,
  NoOutlinePlanning = 6,
  VaryPlanningonExistingBuilding = 7,
  NewPlanningOnAnotherPartOfSite = 8,
  VaryPlanningOnExistingAndNewPlanningOnAnotherPartOfSite = 9,
  NewPlanningApplicationOrDemolishExistingBuilding = 10,
}

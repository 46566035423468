import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { DevelopmentFinanceDealDTO } from "@js/DTO/Deal/DevelopmentFinanceDealDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import { SaveDevFinanceSearchRequest } from "@js/DTO/Messages/Deal/SaveDevFinanceSearchMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { AreaUnitEnum } from "@js/models/enum/AreaUnitEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { DealSourceTypeEnum } from "@js/models/enum/DealSourceTypeEnum.cs.d";
import { LinkTypeEnum } from "@js/models/enum/LinkTypeEnum.cs.d";
import { LocationEnum } from "@js/models/enum/LocationEnum.cs.d";
import { OriginatorRoleTypeEnum } from "@js/models/enum/OriginatorRoleTypeEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { YesNoMaybe } from "@js/models/enum/YesNoMaybeEnum.cs.d";
import { SaveSearchReturnUniqueRefResponse } from "@js/models/SaveSearchReturnUniqueRef.cs.d";
import { AuthService } from "@js/services/AuthService";
import { DealService } from "@js/services/Deal/DealService";
import { DevFinanceDealService } from "@js/services/Deal/DevFinanceDealService";
import { SharedCriteriaService } from "@js/services/Deal/SharedCriteriaService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { EventLogService } from "@js/services/EventLogService";
import { OrganisationLinkService } from "@js/services/OrganisationLinkService";
import { OrganisationService } from "@js/services/OrganisationService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";
import { CriteriaController } from "./CriteriaController";
import { EnterpriseService } from "@js/services/EnterpriseService";
import { UserRoleEnum } from "@js/models/enum/UserRoleEnum.cs.d";

export class DevFinanceCriteriaController extends CriteriaController<DevelopmentFinanceDealDTO> {
  dealDto: DevelopmentFinanceDealDTO;
  tempDealDto: DevelopmentFinanceDealDTO;
  snapshotDealDto: DevelopmentFinanceDealDTO;
  productType: ProductTypeEnum;

  maxPurchaseDate: string;
  minPurchaseDate: Date = new Date("Jan 01 1900");
  isLoggedInUser: boolean = false;
  dataLoading: boolean = false;
  isSearchStarted: boolean = false;
  hasError: boolean = false;
  isSubmitted: boolean = false;
  error: string;

  multiPartForm1: ng.IFormController;

  //Share a search modal
  showShareSearchtoClientModal: boolean = false;
  existingUsers: UserSimpleDTO[];
  existingborrower: boolean;

  //Message to show when Enterprise url does not have valid lead gen license status or whitelabel add on is not paid or whitelabel setting is not on.
  noPermissionErrorMsg: string = "";

  //Enterprise user clientId
  clientId: string = null;
  enterpriseUser: ApplicationUserDTO = null;

  currentUseofBuildingOptions = [];
  locationOptions = [];
  loanCompletionTypeOptions = [];

  hasPurchaseCostChanged: boolean = false;

  guidanceCheckbox: boolean = true;
  shareDealDto: ShareDealDTO = {
    IsApplicant: true,
    IsPrimary: true,
  } as ShareDealDTO;
  //shows the selected existing client data on share modal
  showClientDetails: boolean = false;

  isDeal: boolean = true;

  isBorrower: boolean = false;
  isAdmin: boolean = false;
  isBroker: boolean = false;
  isLender: boolean = false;
  organisation: OrganisationDTO;
  moveContactBrokerBtnInMiddle: boolean = false;
  initialRegistrationClient: ClientDTO;
  orgCode: string;
  buildCostPerSqFt: number;
  buildCostPerSqM: number;
  buildCostIntermediary: number;
  areaConversionFactor: number = 10.7639;
  contingencyValue: number;
  totalBuildCosts: number;
  defaultProfessionalCosts: number = 0.1;
  showAutofill: boolean = false;

  //Postcode properties
  // showPostcodeErrorMessage: boolean = false;
  //postcodeErrorMsg: string;
  //previouslySelectedLocation: LocationEnum;
  isCaseDashboard: boolean = false;

  isMobileView: boolean = false;
  showHelpModal: boolean = false;
  loanInfoText: string;
  progressAmount: string = "33%";
  progressSection: number = 1;
  totalSections: number = 3;
  enterpriseLinkLogo: string;

  isWidget: boolean = false;
  //This propert is used to show only postcode ot location field on critera for mobile view
  isCriteria: boolean = true;

  static $inject = [
    "$scope",
    "$rootScope",
    "$routeParams",
    "$cookies",
    "$location",
    "$q",
    "UserService",
    "RoleService",
    "OrganisationService",
    "SelectListService",
    "DevFinanceDealService",
    "SharedCriteriaService",
    "EventLogService",
    "AuthService",
    "DevelopmentInputService",
    "DealService",
    "OrganisationLinkService",
    "EnterpriseService"
  ].concat(CriteriaController.$inject);

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    protected $q: ng.IQService,
    private userService: UserService,
    private roleService: RoleService,
    private organisationService: OrganisationService,
    private selectListService: SelectListService,
    private devFinanceDealService: DevFinanceDealService,
    private sharedCriteriaService: SharedCriteriaService,
    private eventLogService: EventLogService,
    private $auth: AuthService,
    private $DevelopmentInputservice: DevelopmentInputService,
    dealService: DealService,
    private organisationLinkService: OrganisationLinkService,
    private enterpriseService: EnterpriseService
  ) {
    super(dealService);

    try {
      if (this.$routeParams.context == 'widget' && window.self != window.top) {
        this.isWidget = true;
      } else {
        if (window.innerWidth <= 480)
          this.isMobileView = true
      }


      this.maxPurchaseDate = new Date().toISOString().split("T")[0];

      //Initial data setup for a dev finance deal
      if (this.dealDto == null) {
        this.dealDto = {} as DevelopmentFinanceDealDTO;
        this.loanInfoText =
          "For all new-build, conversion or larger refurbishment projects";
        this.dealDto.IsFamilyInResidence = YesNoMaybe.No;
        this.dealDto = this.devFinanceDealService.initialDataSetUpForDeal(
          this.dealDto,
        );
        this.dealDto.GrossInternalAreaKnown = true;
        this.dealDto.AreaUnit = AreaUnitEnum.SquareFeet;
        this.dealDto.IncludesContingency = false;
        if (this.$routeParams.linkId && this.$routeParams.linkId != "0") {
          this.dealDto.OrganisationLinkId = this.$routeParams.linkId;
        }
      }

      //Enteprise journey code
      if (!$cookies.get("access_token") &&
        (this.$location.path().startsWith("/e/devfinancecriteria") ||
          this.$location.path().startsWith("/allloans"))
      ) {
        this.isBorrower = true;
        this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Borrower;

        if (this.$location.path().startsWith("/e/devfinancecriteria")) {
          var imgs = document.getElementsByTagName("img");
          this.enterpriseLinkLogo = imgs[0].src;
          this.sharedCriteriaService.storeEnterpriseLinkType(LinkTypeEnum.Development.toString());
        }

        if (this.isWidget) {
          //document.getElementById("footer").style.display = "none";
          this.organisationService.sendDataToParent("height", "745px");
        }

        if (!this.$routeParams.DealUniqueRef) {

          if (window.self == window.top) {
            this.orgCode = this.$cookies.get("org_code");
            sessionStorage.setItem("userRole", UserRoleEnum.Client.toString());
          } else {
            this.orgCode = location.pathname.replace("/", "");
            this.organisationService.sendDataToParent("userRole", UserRoleEnum.Client.toString());
          }

          this.logEventWithoutDealData();
          this.getOriginatorInfoForAnonymousUsers();
        }

        this.dataLoading = true;
        this.organisationService
          .getOrgByUrlIfOrgHasEnterpriseSearchPermission(
            this.orgCode,
            ProductFamilyEnum.Development,
            this.$routeParams.linkId,
          )
          .then((response) => {
            if (response) {
              this.organisation = response;
              this.orgCode = response.OrganisationCode;
              this.logEvent("DEVFINANCECRITERIA-PAGE1");

              if (this.$routeParams.linkId) {
                if (this.$location.path().startsWith("/e/devfinancecriteria")) {
                  this.organisationLinkService
                    .getOrganisationLinkLogoByLinkIdAndLogPageLoadEvent(
                      this.$routeParams.linkId
                    )
                    .then((logoUrl) => {
                      if (logoUrl) this.enterpriseLinkLogo = imgs[0].src = logoUrl;
                    });
                }

              }
            } else {
              this.logEvent("DEVFINANCECRITERIA-NO-PERMISSION-TO-SEARCH");
              this.noPermissionErrorMsg =
                "We are unable to compare loans right now.  Please contact your broker.";
            }
          })
          .finally(() => {
            this.dataLoading = false;
          });
      }

      if (window.self == window.top && $cookies.get("access_token")) {
        this.isLoggedInUser = true;

        this.userService.getUserAutofill().then((response) => {
          this.showAutofill = response;
        });

        this.roleService.GetUserRoles().then((result) => {
          if (result.filter((x) => x.toLowerCase() == "admin").length > 0) {
            this.isAdmin = true;
          }
          if (result.filter((x) => x.toLowerCase() == "broker").length > 0) {
            this.isBroker = true;
            if (!this.isAdmin)
              // this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Broker;
              //This block is to navigate a broker to userdashboard when they do not have a license.
              if (this.isBroker) {
                if (sessionStorage.getItem("isValidLicense") == "false") {
                  this.gotoDashboard();
                }
              }
          }
          if (
            result.filter((x) => x.toLowerCase() == "lender").length > 0 &&
            !this.isAdmin
          ) {
            this.isLender = true;
          }

          if (
            result.filter((x) => x.toLowerCase() == "client").length > 0 &&
            !this.isAdmin
          ) {
            this.isBorrower = true;
          }

        }).finally(() => {
          if (
            this.$auth.getHasValidStatustoShowShareSearchModal()
          ) {
            if (sessionStorage.getItem("skip")) {
              //This will hide's a share search to client modal
              this.showShareSearchtoClientModal = false;
            } else {
              //getting a previously entered DealName and Client data 
              if (this.dealService.getShowSearchNameAndUserSelectionModal()) {
                this.showShareSearchtoClientModal = true;
                this.getUsersBelongToBrokerOrAdmin();
              } else {
                this.shareDealDto.ClientDto = this.dealService.getClient();
                this.shareDealDto.DealName = this.dealService.getDealName();
              }
            }
            this.getOriginatorInfoForUser();
          }
        });

        //This block is to navigate a broker to userdashboard when they do not have a license.
        if (sessionStorage.getItem("isValidLicense") == "false") {
          this.gotoDashboard();
        }
      } else {
        if (!this.$routeParams.DealUniqueRef) {
          if (window.self == window.top) {
            this.clientId = sessionStorage.getItem("clientId");
          } else {
            Promise.all([
              this.organisationService.getData("clientId").then((clientId) => {
                if (clientId) {
                  this.clientId = clientId;
                }
              }),
            ]);
          }
        }
      }



      if (this.$routeParams.DealUniqueRef && this.$routeParams.DealUniqueRef != '0') {
        this.dataLoading = true;
        this.dealService
          .fetchByUniqueRef<DevelopmentFinanceDealDTO>(this.$routeParams.DealUniqueRef)
          .then((response) => {
            this.dealDto = { ...response };
            this.snapshotDealDto = { ...response };
          })
          .finally(() => {
            this.dataLoading = false;
            this.updateCalculatedFields();
          });
      }
      else if (this.$routeParams.SearchId > 0) {
        this.dataLoading = true;
        this.devFinanceDealService
          .fetch(this.$routeParams.SearchId)
          .then((response) => {
            this.dealDto = { ...response };
            this.snapshotDealDto = { ...response };
          })
          .finally(() => {
            this.dataLoading = false;
            this.updateCalculatedFields();
          });
      } else {
        this.dealDto.MaxLoanRequired = true;
        this.dealDto.ShowLenderInfoBrokerOverride = true;
      }

      if (window.self == window.top) {
        this.updateGuidanceState();

        $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
          this.updateGuidanceState();
        });
      }

      this.currentUseofBuildingOptions = this.selectListService.GetCurrentUseOfBuildingOptions();
      this.locationOptions = this.selectListService.GetLocations();
      this.loanCompletionTypeOptions = this.selectListService.GetLoanCompletionOptions();
    } catch (e) {
      console.error("Error: ", e);
    }
  }

  updateGuidanceState() {
    this.guidanceCheckbox =
      this.$cookies.get("guidance") === "on" ||
      this.$cookies.get("guidance") === undefined;
  }

  // Start of Share a modal functions
  viewClientsDashboardForNewSearch(userName: string) {
    this.shareDealDto =
      this.sharedCriteriaService.attachSelectedClientToShareDealDto(
        this.existingUsers,
        this.shareDealDto,
        userName,
      );
    this.showClientDetails = true;
  }

  //Clears the previously selected existing client data on share search modal
  clearInputFields() {
    this.shareDealDto =
      this.sharedCriteriaService.clearExistingUserOnShareDealDto(
        this.shareDealDto,
      );
    this.showClientDetails = false;
  }

  getUsersBelongToBrokerOrAdmin() {
    this.userService
      .getUserSummariesForBrokerOrAdmin()
      .then((response) => {
        this.existingUsers = response;
      })
      .finally(() => { });
  }

  gotoDashboard() {
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  //new search share modal close btn function
  OnClickOfSkip() {
    this.showShareSearchtoClientModal = false;
    if (this.$location.path().startsWith("/allloans") && (this.isAdmin || this.isBroker)) {
      this.dealService.setShowSearchNameAndUserSelectionModal(false);
    }
  }

  //new search share modal next btn function
  onClickOfNext() {
    this.showShareSearchtoClientModal = false;
    if (this.$location.path().startsWith("/allloans") && (this.isAdmin || this.isBroker)) {
      this.dealService.setShowSearchNameAndUserSelectionModal(false);
      this.dealService.setClient(this.shareDealDto.ClientDto);
      this.dealService.setDealName(this.shareDealDto.DealName);
    }
  }
  // End of Share a modal functions

  goToLoanSelectionOptions() {
    this.$auth.setHasValidStatustoShowShareSearchModal(
      true,
    );
    this.$location.path("/allloans");

  }

  calculatePurchaseOrLandCost() {
    if (this.dealDto.PurchasePrice && !this.hasPurchaseCostChanged) {
      this.dealDto.PurchaseCosts = (this.dealDto.PurchasePrice * 5) / 100;
    }
  }

  onPurchaseCostChange() {
    var purchaseCost = (this.dealDto.PurchasePrice * 5) / 100;
    if (this.dealDto.PurchaseCosts != purchaseCost) {
      this.hasPurchaseCostChanged = true;
    }
  }

  datasetupOnOwnOrPurchaseChange() {
    this.dealDto = this.devFinanceDealService.datasetupOnOwnOrPurchaseChange(
      this.dealDto,
    );
  }

  datasetupOnMaxLoanRequiredChange() {
    this.dealDto = this.dealService.dataSetupOnMaxLoanRequiredChange(
      this.dealDto,
    );
  }

  onOwnOrPurchaseChange(OwnOrPurchase: boolean) {
    this.dealDto.OwnOrPurchase = this.dealDto.HasOwnOrPurchase
      ? OwnOrPurchaseEnum.Own
      : OwnOrPurchaseEnum.Purchasing;
    this.datasetupOnOwnOrPurchaseChange();
  }

  onCurrentUseOfBuilding() {
    this.dealDto = this.devFinanceDealService.datasetupOnCurrentUseOfBuilding(
      this.dealDto,
    );
  }

  onIsFamilyInResidenceChange() {
    this.dealDto.IsFamilyInResidence = this.dealDto.HasIsFamilyInResidence
      ? YesNoMaybe.Yes
      : YesNoMaybe.No;
    this.clearSearchdata();
  }

  initialDataSetupForIsFamilyInResidence() {
    if (this.dealDto.HasIsFamilyInResidence) {
      this.dealDto.IsFamilyInResidence = YesNoMaybe.Yes;
      this.dealDto.HasIsFamilyInResidence = true;
    } else {
      this.dealDto.IsFamilyInResidence = YesNoMaybe.No;
      this.dealDto.HasIsFamilyInResidence = false;
    }
  }

  clearSearchdata() {
    this.dealDto = this.devFinanceDealService.clearSearchdata(this.dealDto);
  }

  async logEvent(identifier: string) {
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto.OrganisationLinkId,
          ProductTypeEnum.Development,
          "",
          this.dealDto.Id,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto.OrganisationLinkId,
          ProductTypeEnum.Development,
          "",
          this.dealDto.Id,
        );
      }
    } else {
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");

      if (clientId) {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto.OrganisationLinkId,
          ProductTypeEnum.Development,
          "",
          this.dealDto.Id,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto.OrganisationLinkId,
          ProductTypeEnum.Development,
          "",
          this.dealDto.Id,
        );
      }
    }
  }

  goToSearchResults() {
    this.error = null;
    this.isSubmitted = true;
    this.isSearchStarted = true;
    this.hasError = false;
    // If enterprise
    if (
      !this.isLoggedInUser &&
      this.isBorrower &&
      (this.$location.path().startsWith("/e/devfinancecriteria") || this.$location.path().startsWith("/allloans"))
    ) {
      this.enterpriseService.setProductFamily(this.dealDto.ProductFamily);

      this.saveCriteriaReturnUniqueRef().then((response) => {
        if (!this.hasError) {
          this.sharedCriteriaService.setIsDataLoading(false);
          var uniqueRef = response as SaveSearchReturnUniqueRefResponse;
          if (window.self == window.top)
            sessionStorage.removeItem("productType");
          if (response.HasDealClient) {
            //checking route is to handle navigating to correct route when user lands on criteria on click of change search form side panel.
            if (sessionStorage.getItem('previousRoute') == 'referredsearchdeal') {
              this.$location.path(`/referredsearchdeal/${response.DealClientUniqueRef}/false`);
              sessionStorage.removeItem('previousRoute');
            } else {
              this.$location.path(`/e/devfinanceresults/${response.DealUniqueRef}`);
            }

          } else {
            var leadCaptureUrl = `/e/enterpriseleadcapture/${response.DealUniqueRef}`;
            leadCaptureUrl = this.isWidget ? `${leadCaptureUrl}/widget` : leadCaptureUrl;

            this.$location.path(leadCaptureUrl);
          }
        }
      });
    } else {
      this.saveCriteriaReturnId().then((response) => {
        if (!this.hasError) {
          var dealId = response as number;
          if (window.self == window.top)
            sessionStorage.removeItem("productType");
          if (this.isAdmin || this.isBroker) this.dealService.setShowSearchNameAndUserSelectionModal(true);
          this.$location.path("/devfinanceresults/" + dealId);
        }
      });
    }

  }

  searchDisabled() {

    if (this.progressSection == 1) {
      this.progressAmount = "0%";
      if (
        this.dealDto.Locations == null ||
        this.dealDto.Locations <= 0 ||
        this.showPostcodeErrorMessage
      ) {
        return true;
      }

      if (this.multiPartForm1.$invalid) {
        return true;
      }

      this.progressAmount = "33%";
    }



    if (this.progressSection == 2) {

      this.progressAmount = "33%";

      if (this.multiPartForm1.$invalid) {
        return true;
      }

      this.progressAmount = "66%";
    }



    if (this.progressSection == 3) {
      this.progressAmount = "66%";

      if (this.multiPartForm1.$invalid) {
        return true;
      }
      this.progressAmount = "100%";
    }

    return false;
  }

  saveCriteriaReturnId(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.dataSetUpForSave();

    var clientDto = null;
    /*  if (
        !this.isLoggedInUser &&
        this.enterpriseUser &&
        this.$location.path().startsWith("/e/devfinancecriteria")
      ) {
        clientDto =
          this.sharedCriteriaService.setClientDtoDataForEnterpriseSearch(
            this.enterpriseUser,
            this.organisation.Id,
            clientDto,
          );
      }*/

    var request: SaveDevFinanceSearchRequest = {
      DealDto: this.dealDto,
      ShareDealDto: this.shareDealDto,
      OrgCode: this.isLoggedInUser ? "" : this.orgCode,
      ClientId:
        !this.isLoggedInUser &&
          this.clientId &&
          !this.enterpriseUser &&
          this.$location.path().startsWith("/e/devfinancecriteria")
          ? Number(this.clientId)
          : 0,
      EnterpriseClientDto: clientDto,
    };

    this.devFinanceDealService
      .saveDevFinanceSearchReturnsId(request)
      .then((response) => {
        this.dealDto.Id = response;

        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
        if (response.status === 500) {
          this.error = response.data.message;
        } else {
          this.error = 'Something went wrong while searching for loans. Please try again.';
        }
        this.hasError = true;
        this.isSearchStarted = false;
      });

    return defer.promise;
  }

  saveCriteriaReturnUniqueRef(): ng.IPromise<SaveSearchReturnUniqueRefResponse> {
    let defer = this.$q.defer<SaveSearchReturnUniqueRefResponse>();

    this.sharedCriteriaService.setIsDataLoading(true);
    this.dataSetUpForSave();

    var clientDto = null;
    /*   if (!this.isLoggedInUser && this.enterpriseUser && this.$location.path().startsWith("/e/devfinancecriteria")) {
                clientDto = this.sharedCriteriaService.setClientDtoDataForEnterpriseSearch(this.enterpriseUser, this.brokerageOrg.Id, clientDto);
            }*/

    var request: SaveDevFinanceSearchRequest = {
      DealDto: this.dealDto,
      ShareDealDto: this.shareDealDto,
      OrgCode: this.isLoggedInUser ? "" : this.orgCode,
      ClientId: !this.isWidget &&
        !this.isLoggedInUser &&
        this.clientId &&
        !this.enterpriseUser &&
        this.$location.path().startsWith("/e/devfinancecriteria")
        ? Number(this.clientId)
        : 0,
      EnterpriseClientDto: clientDto,
    };

    this.devFinanceDealService
      .saveDevFinanceSearchReturnsUniqueRef(request)
      .then((response) => {
        this.dealDto.UniqueRef = response.DealUniqueRef;

        defer.resolve(response as SaveSearchReturnUniqueRefResponse);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
        this.sharedCriteriaService.setIsDataLoading(false);
        if (response.status === 500) {
          this.error = response.data.message;
        } else {
          this.error = 'Something went wrong while searching for loans. Please try again.';
        }
        this.hasError = true;
        this.isSearchStarted = false;
      });

    return defer.promise;
  }

  dataSetUpForSave() {
    this.sharedCriteriaService.setNewSearch(this.dealDto, this.snapshotDealDto);

    if (this.dealDto.Id == null) {
      this.dealDto.Status = CaseStatusEnum.Search;
    }

    if (
      this.dealDto.Name == null ||
      this.dealDto.Name == "" ||
      this.dealDto.Name == undefined
    ) {
      this.dealDto.Name = this.sharedCriteriaService.defaultSearchName(
        this.dealDto.ProductType,
      );
    }

    if (
      !this.isLoggedInUser &&
      this.$location.path().startsWith("/e/devfinancecriteria") &&
      this.isBorrower
    ) {
      this.dealDto.HowManyFlats = this.dealDto.HowManyFlats
        ? this.dealDto.HowManyFlats
        : 0;
      this.dealDto.HowManyHouses = this.dealDto.HowManyHouses
        ? this.dealDto.HowManyHouses
        : 0;
    }

    if (!this.shareDealDto) {
      this.shareDealDto = {
        DealName: this.sharedCriteriaService.defaultSearchName(
          this.dealDto.ProductType,
        ),
      } as ShareDealDTO;
    }
  }

  dummysearch() {
    this.dealDto.hasPostcode =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 1
        ? true
        : false;

    if (this.dealDto.hasPostcode) {
      this.dealDto.PostcodeSearchString =
        this.sharedCriteriaService.getRandomPostcode();
      this.getRegionByPostcode();
    } else {
      this.dealDto.Locations = LocationEnum.London;
    }

    this.dealDto.OwnOrPurchase =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 0
        ? OwnOrPurchaseEnum.Own
        : OwnOrPurchaseEnum.Purchasing;

    this.dealDto.PurchasePrice =
      this.sharedCriteriaService.getRandomIntInclusive(5000000, 10000000);

    if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own) {
      this.dealDto.HasOwnOrPurchase = true;

      let date = new Date();
      date.setDate(
        date.getDate() -
        this.sharedCriteriaService.getRandomIntInclusive(1, 1000),
      );

      this.dealDto.OriginalPurchaseDate = date;
      this.dealDto.LandTotalOtherCosts =
        this.sharedCriteriaService.getRandomIntInclusive(10000, 100000);
      this.dealDto.Currentvalue =
        this.dealDto.PurchasePrice +
        this.sharedCriteriaService.getRandomIntInclusive(-10000, 5000000);
    } else {
      this.dealDto.HasOwnOrPurchase = false;
      this.dealDto.PurchaseCosts =
        this.sharedCriteriaService.getRandomIntInclusive(100000, 1000000);
    }

    this.dealDto.EndPropertyType =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 0
        ? PropertyTypeEnum.Residential
        : PropertyTypeEnum.MixedUse;

    if (this.dealDto.EndPropertyType == PropertyTypeEnum.MixedUse) {
      this.dealDto.CommercialGDV =
        this.sharedCriteriaService.getRandomIntInclusive(100000, 10000000);
    }

    this.dealDto.GrossInternalAreaKnown =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 0;

    if (this.dealDto.GrossInternalAreaKnown) {
      this.dealDto.AreaUnit =
        this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 0
          ? AreaUnitEnum.SquareFeet
          : AreaUnitEnum.SquareMeters;
      var areaMultiplier =
        this.dealDto.AreaUnit == AreaUnitEnum.SquareMeters
          ? 1
          : this.areaConversionFactor;
      this.dealDto.AreaAcquisition =
        this.sharedCriteriaService.getRandomIntInclusive(
          100 * areaMultiplier,
          10000 * areaMultiplier,
        );
      this.dealDto.BuildCostPerArea =
        this.sharedCriteriaService.getRandomIntInclusive(
          1000 / areaMultiplier,
          10000 / areaMultiplier,
        );

      if (this.dealDto.AreaUnit == AreaUnitEnum.SquareFeet) {
        this.buildCostPerSqFt = this.dealDto.BuildCostPerArea;
      } else {
        this.buildCostPerSqM = this.dealDto.BuildCostPerArea;
      }

      this.updateBuildArea();
    } else {
      this.buildCostIntermediary =
        this.sharedCriteriaService.getRandomIntInclusive(100000, 100000000);
    }

    this.dealDto.IncludesContingency =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 0;
    this.dealDto.Contingency =
      this.sharedCriteriaService.getRandomIntInclusive(1, 30) / 100;
    this.updateContingency();

    this.dealDto.LoanTermReq = this.sharedCriteriaService.getRandomIntInclusive(
      24,
      100,
    );
    this.dealDto.GDV =
      this.dealDto.PurchasePrice +
      this.sharedCriteriaService.getRandomIntInclusive(-10000, 100000000);
    this.dealDto.MaxLoanRequired =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 0;

    if (this.dealDto.MaxLoanRequired == false) {
      this.dealDto.NetLoanRequired =
        this.sharedCriteriaService.getRandomIntInclusive(10000, 5000000);
    }
  }

  hasValidNetLoanRequiredValue() {
    if (
      !this.dealDto.MaxLoanRequired &&
      (this.dealDto.NetLoanRequired == null ||
        isNaN(this.dealDto.NetLoanRequired) ||
        this.dealDto.NetLoanRequired <= 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /*getRegionByPostcode() {
    if (
      /[a-zA-Z]/.test(this.dealDto.PostcodeSearchString) &&
      /[0-9]/.test(this.dealDto.PostcodeSearchString)
    ) {
      if (this.dealDto.PostcodeSearchString.replace(" ", "").length <= 7) {
        this.dataLoading = true;
        this.dealService
          .getRegionByPostcode(this.dealDto.PostcodeSearchString)
          .then((response) => {
            if (response.Location != null) {
              this.dealDto.Locations = response.Location;
              this.showPostcodeErrorMessage = false;
            } else {
              this.postcodeErrorMsg = response.PostCodeErrorMessage;
              this.showPostcodeErrorMessage = true;
              this.dealDto.Locations = null;
            }
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.postcodeErrorMsg =
          "Please enter a more specific postcode to identify the location";
        this.showPostcodeErrorMessage = true;
      }
    } else {
      this.postcodeErrorMsg =
        "At least one letter and one number must be entered e.g. SW1.";
      this.showPostcodeErrorMessage = true;
    }
  }*/

  updateBuildArea(initial: boolean = false) {
    if (this.dealDto.AreaUnit == AreaUnitEnum.SquareFeet) {
      this.dealDto.BuildCostPerArea = this.buildCostPerSqFt;
      this.buildCostPerSqM = this.convertSqFtToSqM(this.buildCostPerSqFt);
    } else {
      this.dealDto.BuildCostPerArea = this.buildCostPerSqM;
      this.buildCostPerSqFt = this.convertSqMToSqFt(this.buildCostPerSqM);
    }

    if (!initial) {
      this.calculateBuildCostIntermediary();
    }
  }

  convertSqMToSqFt(costPerSqM: number): number {
    return costPerSqM / this.areaConversionFactor;
  }

  convertSqFtToSqM(costPerSqFt: number): number {
    return costPerSqFt * this.areaConversionFactor;
  }

  calculateBuildCostIntermediary() {
    if (
      this.dealDto.GrossInternalAreaKnown &&
      this.dealDto.BuildCostPerArea > 0 &&
      this.dealDto.AreaAcquisition > 0
    ) {
      this.buildCostIntermediary =
        this.dealDto.AreaAcquisition * this.dealDto.BuildCostPerArea;
    }
    this.calculateContingency();
  }

  calculateContingency() {
    if (this.dealDto.IncludesContingency) {
      this.contingencyValue =
        this.buildCostIntermediary -
        this.buildCostIntermediary / (1 + Number(this.dealDto.Contingency));
    } else {
      this.contingencyValue =
        this.buildCostIntermediary * this.dealDto.Contingency;
    }
    this.calculateTotalBuildCost();
  }

  calculateTotalBuildCost() {
    if (this.dealDto.IncludesContingency) {
      this.totalBuildCosts = this.buildCostIntermediary;
      this.dealDto.BuildCosts =
        Number(this.buildCostIntermediary) - this.contingencyValue;
    } else {
      this.totalBuildCosts =
        Number(this.buildCostIntermediary) + this.contingencyValue;
      this.dealDto.BuildCosts = Number(this.buildCostIntermediary);
    }

    this.calculateProfessionalCosts();
  }

  calculateProfessionalCosts() {
    if (this.multiPartForm1?.professionalCosts?.$dirty == false) {
      this.dealDto.AdditionalOngoingCosts =
        this.totalBuildCosts * this.defaultProfessionalCosts;
    }

    this.calculateTotalProjectCosts();
  }

  calculateTotalProjectCosts() {
    var totalCosts = 0;

    if (isNaN(Number(this.dealDto.AdditionalOngoingCosts)) == false) {
      totalCosts += Number(this.dealDto.AdditionalOngoingCosts);
    }

    if (isNaN(Number(this.totalBuildCosts)) == false) {
      totalCosts += Number(this.totalBuildCosts);
    }

    if (
      this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own &&
      isNaN(Number(this.dealDto.LandTotalOtherCosts)) == false
    ) {
      totalCosts += Number(this.dealDto.LandTotalOtherCosts);
    }

    if (isNaN(Number(this.dealDto.PurchasePrice)) == false) {
      totalCosts += Number(this.dealDto.PurchasePrice);
    }

    if (
      this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing &&
      isNaN(Number(this.dealDto.PurchaseCosts)) == false
    ) {
      totalCosts += Number(this.dealDto.PurchaseCosts);
    }

    this.dealDto.TotalProjectCosts = totalCosts;
  }

  updateGIA() {
    this.calculateBuildCostIntermediary();
  }

  updateContingency() {
    this.calculateContingency();
  }

  toggleKnowsGIA() {
    this.dealDto.GrossInternalAreaKnown = !this.dealDto.GrossInternalAreaKnown;
    if (this.dealDto.GrossInternalAreaKnown == true) {
      this.dealDto.AreaUnit = AreaUnitEnum.SquareFeet;
    } else {
      this.dealDto.AreaAcquisition = null;
      this.dealDto.AreaUnit = null;
      this.buildCostPerSqFt = null;
      this.buildCostPerSqM = null;
      this.dealDto.BuildCostPerArea = null;
    }

    this.buildCostIntermediary = null;
    this.multiPartForm1.buildCostIntermediary.$setUntouched();
    this.updateContingency();
  }

  updateCalculatedFields() {
    this.reverseBuildCost();
    this.reverseBuildCostPerArea();
    this.updateBuildArea(true);
    this.updateContingency();
  }

  reverseBuildCostPerArea() {
    if (this.dealDto.AreaUnit == AreaUnitEnum.SquareFeet) {
      this.buildCostPerSqFt = this.dealDto.BuildCostPerArea;
    } else {
      this.buildCostPerSqM = this.dealDto.BuildCostPerArea;
    }
  }

  reverseBuildCost() {
    if (this.dealDto.IncludesContingency == true) {
      this.buildCostIntermediary =
        Math.round(
          this.dealDto.BuildCosts * (1 + this.dealDto.Contingency) * 100,
        ) / 100;
    } else {
      this.buildCostIntermediary = this.dealDto.BuildCosts;
    }
  }

  getRequiredRoles(isCaseDashboard: boolean) {
    if (isCaseDashboard) return `['Admin', 'Client', 'Broker', 'Introducer']`;
    return `['Admin', 'Client', 'Broker', 'Introducer', 'Lender']`;
  }

  /*onHasPostcodeChange() {
    if (!this.dealDto.hasPostcode) {
      this.showPostcodeErrorMessage = false;
      this.dealDto.PostcodeSearchString = null;
      this.dealDto.Locations = this.previouslySelectedLocation;
    } else {
      this.previouslySelectedLocation = this.dealDto.Locations;
      this.dealDto.Locations = null;
    }
  }*/

  isFieldEmpty(fieldName, isEnum = false) {
    if (this.isSubmitted && this.dealDto) {
      const fieldValue = this.dealDto[fieldName];
      if (fieldName == "PlanningPermissionType") {
        if (fieldValue == null || fieldValue < 1) {
          return true;
        }
      }
      if (fieldValue == null || (isEnum && fieldValue === "0")) {
        return true;
      }
    }

    return false;
  }

  isFieldZero(fieldName) {
    if (this.isSubmitted && this.dealDto) {
      const fieldValue = this.dealDto[fieldName];
      if (fieldValue == null || fieldValue === "0" || fieldValue === 0) {
        return true;
      }
    }

    return false;
  }

  showBasedOnMainResidence() {
    return this.dealService.showBasedOnMainResidence(this.dealDto);
  }

  clickNextButton() {
    if (this.progressSection == 1) {
      if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/devfinancecriteria") || this.$location.path().startsWith("/allloans"))) {
        this.logEvent("DEVFINANCECRITERIA-PAGE2");
      }
      this.progressSection = 2;
    } else {
      if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/devfinancecriteria") || this.$location.path().startsWith("/allloans"))) {
        this.logEvent("DEVFINANCECRITERIA-PAGE3");
      }
      this.progressSection = 3;
    }
  }

  clickBackButton() {
    if (this.progressSection == 3) {
      if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/devfinancecriteria") || this.$location.path().startsWith("/allloans"))) {
        this.logEvent("DEVFINANCECRITERIA-PAGE2");
      }
      this.progressSection = 2;
    } else {
      if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/devfinancecriteria") || this.$location.path().startsWith("/allloans"))) {
        this.logEvent("DEVFINANCECRITERIA-PAGE1");
      }
      this.progressSection = 1;
    }
  }

  isCriteriaFormInValid() {
    if (
      this.isSearchStarted ||
      this.multiPartForm1.$invalid ||
      this.dealDto.Locations == null ||
      this.dealDto.Locations <= 0 ||
      this.hasValidNetLoanRequiredValue() ||
      this.showPostcodeErrorMessage ||
      this.dealDto.GDV == null ||
      this.dealDto.GDV == 0
    ) {
      if (this.isSearchStarted) {
        this.progressAmount = "100%";
      } else {
        this.progressAmount = "66%";
      }
      return true;
    } else {
      this.progressAmount = "100%";
      return false;
    }
  }

  async logEventWithoutDealData() {
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          "DEVFINANCECRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.Development,
          "",
          0,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          "DEVFINANCECRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.Development,
        );
      }
    } else {
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");

      if (clientId) {
        this.eventLogService.logPageLoad(
          "DEVFINANCECRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.Development,
          "",
          0,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          "DEVFINANCECRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.Development,
        );
      }
    }
  }

  getOriginatorInfoForUser() {
    if (this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Admin;
      this.dealDto.SourceType = DealSourceTypeEnum.Application;
    } else if (this.isBroker && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Broker;
    } else if (this.isBorrower && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Borrower;
    } else if (this.isLender && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Lender;
      this.dealDto.SourceType = DealSourceTypeEnum.Application;
    }

    if (window.self == window.top) {

      if (this.isLender) {
        this.userService.getCurrentUserLender().then((response) => {
          this.dealDto.OriginatorLenderId = response;
        });
      }

      if (this.isBroker || this.isBorrower) {
        this.organisationService.getOrganisation().then((org) => {
          if (org != null) {
            if (org.Id != 0) this.dealDto.OriginatorOrganisationId = org.Id;
            this.dealDto.SourceType = org.IsWhiteLabelled
              ? DealSourceTypeEnum.WhitelabelApplication
              : DealSourceTypeEnum.Application;
          }
        });
      }


    }
  }

  getOriginatorInfoForAnonymousUsers() {
    this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Borrower;

    if (window.self == window.top) {
      this.dealDto.SourceType = DealSourceTypeEnum.Enterprise;
    } else {
      this.dealDto.SourceType = this.isWidget ? DealSourceTypeEnum.WidgetEnterprise : DealSourceTypeEnum.IFrameEnterprise;
    }

    if (this.$routeParams.linkId) {
      if (window.self == window.top) {
        this.dealDto.SourceType = DealSourceTypeEnum.UniqueLink;
      } else {
        this.dealDto.SourceType = this.isWidget ? this.$routeParams.linkId == "0"? DealSourceTypeEnum.WidgetEnterprise: DealSourceTypeEnum.WidgetUniqueLink : DealSourceTypeEnum.IFrameUniqueLink;
      }
    }
  }


}

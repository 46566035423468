export const enum SortByEnum {
  None = 0,
  ROCE = 1,
  TrueMonthlyCost = 2,
  NetLoanSize = 3,
  LenderCosts = 4,
  LenderName = 5,
  InterestRate = 6,
  ArrangementFee = 7,
  MonthlyPayment = 8,
  DepositEquity = 9,
  LTGDV = 10,
}

import { SecurityQuestionEnum } from "@js/models/enum/SecurityQuestionEnum.cs.d";
import { DealClientService } from "@js/services/DealClientService";

export class DealSecurityCheckController {
  dateOfBirth: Date;
  email: string;
  phone: string;
  postcode: string;
  answers: number;
  error: boolean = false;

  securityValidation: ng.IFormController;
  maxDateOfBirth: Date = new Date();
  minDateOfBirth: Date = new Date("Jan 01 1900");
  uniqueId: string;
  showDob: boolean = false;
  showPhone: boolean = false;
  showPostCode: boolean = false;

  orgUniqueRef: string;
  brokerUserId: string;

  static $inject = ["$routeParams", "$location", "DealClientService"];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private dealClientService: DealClientService,
  ) {
    this.uniqueId = this.$routeParams.uniqueId;
    if (this.$routeParams.orgUniqueRef && this.$routeParams.brokerUserId) {
      this.orgUniqueRef = this.$routeParams.orgUniqueRef;
      this.brokerUserId = this.$routeParams.brokerUserId;
    }

    if (this.uniqueId) {
      this.dealClientService
        .getShareholderEmailAndSecurityQuestions(this.uniqueId)
        .then((response) => {
          this.email = response?.Email;
          var securityQuestions = response?.SecurityQuestions;

          this.showDob =
            SecurityQuestionEnum.DateOfBirth ===
            (securityQuestions & SecurityQuestionEnum.DateOfBirth);
          this.showPostCode =
            SecurityQuestionEnum.PostCode ===
            (securityQuestions & SecurityQuestionEnum.PostCode);
          this.showPhone =
            SecurityQuestionEnum.PhoneNumber ===
            (securityQuestions & SecurityQuestionEnum.PhoneNumber);
        });
    }
  }

  isValidAnswer() {
    this.answers = 0;

    if (this.dateOfBirth) {
      this.answers++;
    }

    if (this.phone) {
      this.answers++;
    }

    if (this.postcode) {
      this.answers++;
    }

    if (
      this.answers >= 2 &&
      this.securityValidation.$valid &&
      !this.securityValidation.$pristine
    ) {
      return false;
    } else {
      return true;
    }
  }

  send() {
    if (this.uniqueId) {
      this.dealClientService
        .securityCheck(
          this.uniqueId,
          this.phone ? this.phone : null,
          this.postcode ? this.postcode : null,
          this.dateOfBirth ? this.dateOfBirth : null,
          this.orgUniqueRef,
          this.brokerUserId,
        )
        .then((i) => {
          var targeturl = this.$location.search().targeturl;

          if (targeturl) {
            this.$location.search({});
            this.$location.path(targeturl);
          } else {
            this.$location.path(i);
          }
        })
        .catch(() => {
          this.error = true;
        });
    }
  }
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { InvitedBrokerDTO } from "@js/DTO/InvitedBrokerDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { OrganisationLinkDTO } from "@js/DTO/OrgnisationLinkDTO.cs.d";
import { PageWithTotalCountDTO } from "@js/DTO/PageWithTotalCountDTO.cs.d";
import { LinkTypeEnum } from "@js/models/enum/LinkTypeEnum.cs.d";
import { OrganisationTypeEnum } from "@js/models/enum/OrganisationTypeEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";
import angular from "angular";

export class OrganisationsController {
  organisations: PageWithTotalCountDTO;
  selectedOrganisation: OrganisationDTO;
  currentUser: ApplicationUserDTO;

  //invite users
  newUser: InvitedBrokerDTO;
  users: InvitedBrokerDTO[] = [];
  showModal: boolean = false;

  //rename Email
  renamingItem: InvitedBrokerDTO;
  newName: InvitedBrokerDTO = {
    Email: null,
    FirstName: null,
  };
  tempdata: InvitedBrokerDTO;
  //Brokers
  organisationAdminId: string;
  organisationAdmin: ApplicationUserDTO;
  organisationUsers: ApplicationUserDTO[];
  organisationUsersExceptAdmin: ApplicationUserDTO[];
  licensedOrganisationUsers: ApplicationUserDTO[];
  // temp storing user while deleteing
  confirmDeleteUser: ApplicationUserDTO;

  uniqueLinks: OrganisationLinkDTO[];
  selectedUniqueLink: OrganisationLinkDTO;

  page: number = 1;
  rolepageid: any;
  userFilter: string = "";
  countperpage: number = 10;

  loadingRoles: boolean;
  error: boolean;
  isInvalidForm: boolean;

  logoURL: string;

  deleteMessage: string;

  deleteConfirmMessage: string;

  registrationForm: ng.IFormController;

  addEmail: ng.IFormController;

  deleteConfirmation: boolean;
  messageDeleteModal: boolean;
  goToUserDashboard: boolean;
  brokerUserDelete: boolean = false;

  //toggle to control the Broker user section
  showBrokerUsers: boolean = false;

  profileEdit: ApplicationUserDTO;
  showEdit: boolean = false;
  isAdmin: boolean;
  newname: string;
  // Show and hide a input field to add users.
  hideInput: boolean = false;
  showErrorMsg: boolean = false;
  errorMsg: string;
  imageErrorMsg: string;

  // Indicates when org is saving
  isProcessingOrgSave: boolean = false;

  // Indicates when invites are being sent
  isProcessingInvites: boolean = false;

  // Indicates when saving a broker profile
  isProcessingProfileSave: boolean = false;

  orgFilter: string = "";

  allActiveAppPackagePrices: AppPackagePricingDTO[];
  selectedProductList: AppPackagePricingDTO[] = [];
  licenseEndDate: Date;
  quantity = 1;

  ShowIsBrickflow: boolean = true;
  buttonColor: string = "#304b9a";
  message: string;
  messageTitle: string;
  widgetModal: boolean = false;
  showLeadGenLicenseActive: boolean = false;
  leadGeneratorName: string;

  teamSizeOptions = [];
  linkTypeOptions = [];

  isAddonSelected: boolean = false;
  licenseStatusText: string;
  whiteLabelledUrl: string = "";

  showTestEmailModal: boolean = false;
  showTestEmailSentConfirmation: boolean = false;
  testEmails: string;
  testEmailBody: string = "This is a test email.";
  testEmailSubject: string = "Test email";
  isValidOrgCode: boolean = true;
  validateCodeClicked: boolean = false;
  fetchingUniqueLinks: boolean = false;
  displayMessage: string = "";
  deleteUniqueLinkClicked: boolean = false;
  confirmUniqueLinkDeletion: boolean = false;
  isEditUniqueLink: boolean = false;
  showEditUniqueLinkModal: boolean = false;
  editLogo: boolean = false;
  isSavingUniqueLink: boolean = false;
  ulsavemessage: string = "";

  organisation: OrganisationDTO;
  orgCode: string = "";
  orgLogo: string = "";

  //Preview a registration page
  previewContent: boolean = true;
  showPreview: boolean = false;

  hubspotMessage: string;
  isHubspotValid: boolean = false;
  missingProperties: string[];

  locationOptions = [];
  organisationThemeColour: string;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
    "RoleService",
    "OrganisationService",
    "FileAttachmentService",
    "PaymentService",
    "UserService",
    "$window",
    "AuthService",
    "SelectListService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $auth: AuthService,
    private $role: RoleService,
    private $organisationService: OrganisationService,
    private fileAttachmentService: FileAttachmentService,
    private $paymentService: PaymentService,
    private userService: UserService,
    private $window: ng.IWindowService,
    private authService: AuthService,
    private selectListService: SelectListService,
  ) {
    this.loadingRoles = true;
    this.deleteConfirmation = false;
    this.messageDeleteModal = false;
    this.isInvalidForm = true;
    if (sessionStorage.getItem("filterText")) {
      this.orgFilter = sessionStorage.getItem("filterText");
      sessionStorage.removeItem("filterText");
    }

    if (sessionStorage.getItem("pageNo")) {
      this.setPage(Number(sessionStorage.getItem("pageNo")));
      sessionStorage.removeItem("pageNo");
    } else {
      this.setPage(1);
    }
    this.checkForAdmin();
    this.authService.getUpdatedProfile().then((prof) => {
      this.currentUser = prof;
    });

    this.leadGeneratorName = this.$cookies.get("leadgeneratorname");
    this.teamSizeOptions = this.selectListService.GetTeamSizeOptions();
    this.getWhiteLabelledUrl();

    this.locationOptions = this.selectListService.GetLocations();
  }

  onSelectingOrganisation(selectedOrganisation) {
    this.selectedOrganisation = selectedOrganisation;
    this.getOrganisationDetail();
    this.fetchUniqueLinks();
    this.ShowIsBrickflow =
      selectedOrganisation.IsBrickflow ||
      this.organisations.List.every((org) => !org.IsBrickflow);
  }

  getLogoURL(url: string) {
    if (url) {
      this.fileAttachmentService.getFileUri(url).then((result) => {
        this.selectedOrganisation.LogoURL = result;
      });
    }
  }

  changeCompanyLogo(files, logoUrl: number) {
    this.imageErrorMsg = null;
    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "tif",
      "tiff",
      "ico",
      "jfif",
      "pjpeg",
      "pjp",
      "apng",
    ];
    const filteredFiles = files.filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return fileExtension && allowedExtensions.indexOf(fileExtension) !== -1;
    });

    if (filteredFiles.length === 0 && files.length > 0) {
      this.imageErrorMsg =
        "Invalid file type: SVG and webp formats are not supported";
      return;
    }
    this.fileAttachmentService
      .uploadPublicImage(files, this.selectedOrganisation.OrganisationCode)
      .then((imageUrl) => {
        if (logoUrl == 1) {
          this.selectedOrganisation.LogoURL = imageUrl;
          this.orgLogo = imageUrl;
        } else {
          this.selectedOrganisation.FaviconURL = imageUrl;
        }

        this.registrationForm.$setDirty();
      });
  }

  removeCompanyLogo(removeLogoUrl) {
    if (removeLogoUrl) {
      this.selectedOrganisation.LogoURL = null;
      this.orgLogo = null;
    } else {
      this.selectedOrganisation.FaviconURL = null;
    }
    this.registrationForm.$setDirty();
  }

  setPage(newPage: number): void {
    this.loadingRoles = true;
    this.page = newPage;
    this.$organisationService
      .fetchpage(this.orgFilter, this.page, this.countperpage)
      .then((response) => {
        this.organisations = response;

        if (sessionStorage.getItem("selectedOrganisation")) {
          this.selectedOrganisation = this.organisations.List.find(
            (organisation) =>
              organisation.UniqueRef ===
              sessionStorage.getItem("selectedOrganisation"),
          );
          this.getOrganisationDetail();
          this.fetchUniqueLinks();
          sessionStorage.removeItem("selectedOrganisation");
        } else if (this.organisations.List.length == 1) {
          this.selectedOrganisation = this.organisations.List[0];
          this.getOrganisationDetail();
          this.fetchUniqueLinks();
        }
        this.loadingRoles = false;
      })
      .catch((response) => {
        this.error = true;
        this.loadingRoles = false;
      });
  }

  displayItem(item) {
    this.selectedOrganisation = item;
  }

  addUser() {
    var temp = { ...this.newUser };
    this.users.push(temp);
    this.newUser.Email = null;
    this.newUser.FirstName = null;
    this.addEmail.$setPristine();
  }

  invite() {
    this.isProcessingInvites = true;

    this.$organisationService
      .inviteBrokers(this.users, this.selectedOrganisation.UniqueRef)
      .finally(() => {
        delete this.newUser;
        this.users = [];
        this.hideInput = false;
        this.deleteMessage = "Invitations have been sent.";
        this.messageDeleteModal = true;
      })
      .finally(() => {
        this.isProcessingInvites = false;
      });
  }

  removeEmail(item: InvitedBrokerDTO) {
    this.users = this.users.filter((x) => x != item);
  }

  renameItem(item: InvitedBrokerDTO) {
    if (this.renamingItem === undefined) {
      this.newName = item;
      this.renamingItem = item;
      this.tempdata = { ...this.renamingItem };
    } else {
      delete this.renamingItem;
    }
  }

  cancelRenaming(item: InvitedBrokerDTO) {
    this.users = this.users.filter((x) => x != item);
    this.users.push(this.tempdata);
    delete this.renamingItem;
    this.addEmail.editUserFirstName.$setUntouched();
    this.addEmail.editUserEmail.$setUntouched();
  }

  renamingItemComplete(item: InvitedBrokerDTO) {
    this.users = this.users.filter((x) => x != item);
    this.users.push(this.newName);
    this.renamingItem = null;
  }

  addNewUser() {
    if (this.newUser.Email != null && this.newUser.FirstName != null) {
      this.addUser();
      this.addEmail.newUserFirstName.$setUntouched();
      this.addEmail.newUserEmail.$setUntouched();
    }
  }

  cancelAddingNewUser() {
    this.newUser.Email = null;
    this.newUser.FirstName = null;
    this.addEmail.newUserFirstName.$setUntouched();
    this.addEmail.newUserEmail.$setUntouched();
  }

  getFocusToInput() {
    var getelement = this.$window.document.getElementById("newuser");
    getelement.focus();
  }

  removeErrors() {
    delete this.error;
    this.orgCode = this.selectedOrganisation.OrganisationCode;
  }

  saveOrganisation(): void {
    delete this.error;
    this.isProcessingOrgSave = true;

    if(this.selectedOrganisation.OrganisationThemeColour && (this.selectedOrganisation.OrganisationThemeColour == "#304B9A" || this.selectedOrganisation.OrganisationThemeColour == "#304b9a")){
      this.selectedOrganisation.OrganisationThemeColour = "";
    }

    if (
      this.selectedOrganisation.CompanyURL != null &&
      this.selectedOrganisation.CompanyURL.replace(/^https?\:\/\//g, "")
        .length > 0
    ) {
      if (!/^https?:\/\//i.test(this.selectedOrganisation.CompanyURL)) {
        this.selectedOrganisation.CompanyURL = `https://${this.selectedOrganisation.CompanyURL}`;
      }
    }

    if (
      this.selectedOrganisation.HasWhiteLabelAddOnPaid &&
      this.selectedOrganisation.IsWhiteLabelled
    ) {
      if (
        this.selectedOrganisation.TwitterUrl &&
        this.selectedOrganisation.TwitterUrl.length > 0
      ) {
        if (!/^https?:\/\//i.test(this.selectedOrganisation.TwitterUrl)) {
          this.selectedOrganisation.TwitterUrl = `https://${this.selectedOrganisation.TwitterUrl}`;
        }
      }
      if (
        this.selectedOrganisation.FacebookUrl &&
        this.selectedOrganisation.FacebookUrl.length > 0
      ) {
        if (!/^https?:\/\//i.test(this.selectedOrganisation.FacebookUrl)) {
          this.selectedOrganisation.FacebookUrl = `https://${this.selectedOrganisation.FacebookUrl}`;
        }
      }
      if (
        this.selectedOrganisation.YouTubeUrl &&
        this.selectedOrganisation.YouTubeUrl.length > 0
      ) {
        if (!/^https?:\/\//i.test(this.selectedOrganisation.YouTubeUrl)) {
          this.selectedOrganisation.YouTubeUrl = `https://${this.selectedOrganisation.YouTubeUrl}`;
        }
      }
      if (
        this.selectedOrganisation.LinkedInUrl &&
        this.selectedOrganisation.LinkedInUrl.length > 0
      ) {
        if (!/^https?:\/\//i.test(this.selectedOrganisation.LinkedInUrl)) {
          this.selectedOrganisation.LinkedInUrl = `https://${this.selectedOrganisation.LinkedInUrl}`;
        }
      }
    }
    /* }*/

    this.$organisationService
      .saveOrganisation(this.selectedOrganisation)
      .then((response) => {
        if (
          this.organisationAdminId &&
          this.organisationAdmin.Id != this.organisationAdminId
        ) {
          this.userService.setBrokerOrgAdmin(
            this.selectedOrganisation.Id,
            this.organisationAdminId,
          );
        }

        (this.$rootScope as any).formSaved = true;
        let index = this.organisations.List.indexOf(this.selectedOrganisation);
        this.organisations.List.splice(index, 1);
        this.organisations.List.push(this.selectedOrganisation);
        delete this.selectedOrganisation;
        if (this.organisations.List.length == 1) {
          this.selectedOrganisation = this.organisations.List[0];
        }

        this.registrationForm.$setPristine();
        this.deleteMessage = "Your changes have been saved.";
        this.messageDeleteModal = true;
        this.goToUserDashboard = true; // When the User closes the pop-up box, they will be redirected to the User Dashboard
      })
      .catch((response) => {
        this.error = response.data;
      })
      .finally(() => {
        this.isProcessingOrgSave = false;
      });
  }

  validateForm() {
    if (this.registrationForm.$invalid) {
      let invalidName;
      angular.forEach(this.registrationForm.$error, function (field) {
        angular.forEach(field, function (errorfield) {
          errorfield.$setTouched();
          invalidName = errorfield.$name;
        });
      });
      let element = this.$window.document.getElementsByName(
        invalidName,
      )[0] as unknown as HTMLElement;
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
      return true;
    }
    return false;
  }

  deleteOrganisation() {
    this.deleteConfirmation = true;
    this.deleteConfirmMessage =
      "Are you sure you want to delete the company" +
      this.selectedOrganisation.Name +
      "?";
  }

  confirmDelete(item: OrganisationDTO) {
    delete this.selectedOrganisation;
    this.deleteConfirmation = false;
    this.$organisationService
      .deleteOrganisation(item.Id)
      .then((response) => {
        this.deleteMessage =
          "The company " + item.Name + " has been successfully deleted.";
        this.messageDeleteModal = true;
        this.organisations.List = this.organisations.List.filter(
          (organisation) => organisation != item,
        );
      })
      .catch((response) => {
        this.error = true;
        this.deleteMessage =
          "Sorry! We had an error deleting the company " +
          item.Name +
          ". Please, try again later.";
        this.messageDeleteModal = true;
      });
  }

  cancelDelete() {
    this.deleteConfirmation = false;
  }

  closeMessageSuccesfullyDeleted() {
    if (this.goToUserDashboard && this.isAdmin) {
      this.$location.path("/userdashboard"); // redirects User to User Dashboard
      this.goToUserDashboard = false;
    }
    this.messageDeleteModal = false;
  }

  addNewOrganisation() {
    this.selectedOrganisation = {
      Name: "",
      OrganisationType: OrganisationTypeEnum.Broker,
      HasCommercialMortgage: true,
      HasBridging: true,
      IsDeleted: false,
    } as OrganisationDTO;
    this.ShowIsBrickflow = this.organisations.List.every(
      (org) => !org.IsBrickflow,
    );
    this.organisationAdminId = "";
    this.licensedOrganisationUsers = [];
  }

  formValidation(name: string, type: number) {
    if (!name || !type) {
      this.isInvalidForm = true;
    }

    this.isInvalidForm = false;
  }

  registerDisabled() {
    if (
      this.selectedOrganisation &&
      this.errorMsg == null &&
      !this.isProcessingOrgSave
    ) {
      return false;
    } else {
      return true;
    }
  }

  getOrganisationDetail(): void {
    this.userService
      .getAllOrganisationMembers(this.selectedOrganisation.Id)
      .then((orgBrokers: ApplicationUserDTO[]) => {
        this.organisationUsers = orgBrokers;
        this.organisationAdmin = orgBrokers.find((x) => x.IsOrganisationAdmin);

        this.licensedOrganisationUsers = this.organisationUsers.filter(
          (x) => x.SubscriptionStatus > 0 || x.IsOrganisationAdmin,
        );

        if (this.organisationAdmin) {
          this.organisationAdminId = this.organisationAdmin.Id;
          this.organisationUsersExceptAdmin = this.organisationUsers.filter(
            (x) => x.Id != this.organisationAdminId,
          );

          this.getActivePackagePricingsForTrialLicense();
          this.isLeadGenLicenseActive();
        }
      })
      .then(() => {
        if (this.organisations.List.length > 1) this.getLicenseStatusText();
      });

    this.linkTypeOptions = this.selectListService.GetLinkTypeOptions(
      this.selectedOrganisation.HasBridging,
      this.selectedOrganisation.HasCommercialMortgage,
    );
  }

  isLeadGenLicenseActive(): void {
    this.$organisationService
      .isLeadGenLicenseActive(this.selectedOrganisation.Id)
      .then((response) => {
        this.showLeadGenLicenseActive = response;
      });
  }

  deleteBrokerUser(user: ApplicationUserDTO) {
    this.deleteConfirmation = true;
    this.brokerUserDelete = true;
    this.deleteConfirmMessage =
      "Are you sure you want to delete the Broker User" +
      user.FirstName +
      " " +
      user.LastName +
      "?";
    this.confirmDeleteUser = { ...user };
  }

  confirmBrokerDelete(item: ApplicationUserDTO) {
    this.deleteConfirmation = false;
    this.userService
      .markasdeleted(item.UserName)
      .then((response) => {
        this.deleteMessage =
          "Your Broker" +
          item.FirstName +
          item.LastName +
          " was successfully deleted!";
        this.messageDeleteModal = true;
        this.organisationUsersExceptAdmin =
          this.organisationUsersExceptAdmin.filter((user) => user != item);
      })
      .catch((response) => {
        this.error = true;
        this.deleteMessage =
          "Sorry! We had an error deleting the broker user " +
          item.FirstName +
          " " +
          item.LastName +
          ". Please, try again later.";
        this.messageDeleteModal = true;
      });
  }

  cancelBrokerDelete() {
    this.deleteConfirmation = false;
    this.brokerUserDelete = false;
  }

  updateBrokerUser(user: ApplicationUserDTO): void {
    this.profileEdit = user;
    this.showEdit = true;
  }

  /** Save the user changes */
  saveProfile() {
    this.isProcessingProfileSave = true;
    this.showEdit = false;
    this.userService
      .addUpdate(this.profileEdit)
      .then((userResponse) => {
        this.deleteMessage =
          this.profileEdit.FirstName +
          " " +
          this.profileEdit.LastName +
          "'s details have been saved";
        this.messageDeleteModal = true;
      })
      .finally(() => {
        this.isProcessingProfileSave = false;
      });
  }

  closeEditModal() {
    this.showEdit = false;
    this.profileEdit = null;
  }

  checkForAdmin(): void {
    this.$role.isAdminUser().then((response) => {
      this.isAdmin = response;
    });
  }

  isHtml(input) {
    let isHtml;
    this.errorMsg = null;
    isHtml = /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(
      input,
    );
    if (isHtml) {
      this.errorMsg = "Please remove any special characters from footer text.";
    }
  }

  removeNewUserEmail() {
    this.newUser.Email = "";
  }

  go(path: string) {
    this.$location.path(path);
  }

  goToSettingPage() {
    sessionStorage.setItem(
      "selectedOrganisation",
      this.selectedOrganisation.UniqueRef,
    );
    if (this.orgFilter.length > 0)
      sessionStorage.setItem("filterText", this.orgFilter);
    if (this.page > 1) sessionStorage.setItem("pageNo", this.page.toString());
    this.go(`/settings/${this.organisationAdmin.Id}`);
  }

  getActivePackagePricingsForTrialLicense() {
    this.allActiveAppPackagePrices = [];
    this.$paymentService
      .getActivePackagePricingsForTrialLicense(this.organisationAdmin.UserName)
      .then((response) => {
        this.allActiveAppPackagePrices = response;
      });
  }

  onChangingPackage(selectedPackage) {
    if (
      this.selectedProductList != null &&
      this.selectedProductList.length > 0
    ) {
      var found = this.selectedProductList.find(function (product) {
        return product.Id == selectedPackage.Id;
      });

      if (found) {
        this.selectedProductList = this.selectedProductList.filter(
          function (e) {
            return e.Id != selectedPackage.Id;
          },
        );
      } else {
        this.selectedProductList.push(selectedPackage);
      }
    } else {
      this.selectedProductList.push(selectedPackage);
    }
  }

  assignLicenseToOrganisation() {
    this.$organisationService
      .assignTrialLicenseToOrg(
        this.selectedProductList,
        this.licenseEndDate,
        this.organisationAdmin.Id,
        this.quantity,
        true,
        this.isAddonSelected,
      )
      .then((response) => {
        if (response) {
          this.deleteMessage = `Trial license has been assigned to ${this.organisationAdmin.FullName}`;
          this.messageDeleteModal = true;
          this.allActiveAppPackagePrices = [];
          this.selectedOrganisation.HasWhiteLabelAddOnPaid =
            this.isAddonSelected;
        } else {
          this.deleteMessage = `Problem while assigning trial license, please try after some time.`;
          this.messageDeleteModal = true;
        }
      });
  }

  generateButtonCode() {
    if (this.selectedOrganisation.OrganisationCode) {
      //var style = `background-color:${this.buttonColor};border-radius: 4px; padding: 0.5rem 1rem;cursor: pointer;outline: none;line-height: 1.3rem;`
      //this.message = `<button  style="${style}" id="brickflowEmbed">Search Loan</button><script src="${location.origin}/embed.js" id='brickflowEmbedScript' url="${location.origin}/${this.selectedOrganisation.OrganisationCode}#!/initialregister/borrower/"></script>`;
      this.message = `${this.whiteLabelledUrl}${this.selectedOrganisation.OrganisationCode}#!/e/devfinancecriteria`;
      this.messageTitle =
        "Please copy the below URL to include in your website.";
      this.widgetModal = true;
    } else if (this.selectedOrganisation.IsBrickflow) {
      this.message = `${this.whiteLabelledUrl}#!/e/devfinancecriteria`;
      this.messageTitle =
        "Please copy the below URL to include in your website.";
      this.widgetModal = true;
    } else {
      this.deleteMessage =
        "To access your " +
        this.leadGeneratorName +
        " URL please ensure you have an active White Label add-on and you have completed the URL Company Code in your company settings (under White Label)";
      this.messageDeleteModal = true;
    }
  }

  generateAllLoansUrl(id: number = null): string {
    var url = "";

    if (this.selectedOrganisation.OrganisationCode) {
      url = `${this.whiteLabelledUrl}${this.selectedOrganisation.OrganisationCode}#!/allloans`;
    } else if (this.selectedOrganisation.IsBrickflow) {
      url = `${this.whiteLabelledUrl}#!/allloans`;
    }

    if (id != null) {
      url += `/${id}`;
    }

    return url;
  }

  generateBridgingCriteriaURL(): string {
    if (this.selectedOrganisation.OrganisationCode) {
      return `${this.whiteLabelledUrl}${this.selectedOrganisation.OrganisationCode}#!/e/bridgingcriteria`;
    } else if (this.selectedOrganisation.IsBrickflow) {
      return `${this.whiteLabelledUrl}#!/e/bridgingcriteria`;
    }
  }

  generateCommercialCriteriaURL(): string {
    if (this.selectedOrganisation.OrganisationCode) {
      return `${this.whiteLabelledUrl}${this.selectedOrganisation.OrganisationCode}#!/e/commercialcriteria`;
    } else if (this.selectedOrganisation.IsBrickflow) {
      return `${this.whiteLabelledUrl}#!/e/commercialcriteria`;
    }
  }

  generateCriteriaUrl(linkType: LinkTypeEnum, id: number = null): string {
    var url = "";

    if (linkType == LinkTypeEnum.Bridging) {
      url = this.generateBridgingCriteriaURL();
    } else if (linkType == LinkTypeEnum.Commercial) {
      url = this.generateCommercialCriteriaURL();
    }

    if (id != null) {
      url += "/0/" + id;
    }

    return url;
  }

  generateUniqueLinkCode(linkType: LinkTypeEnum, id: number = null, isWidgetLink:boolean = false) {
    if (linkType == LinkTypeEnum.Development) {
      this.generateButtonCode();

      if (id != null) {
        this.message += "/0/" + id;
      }
    } else if (
      linkType == LinkTypeEnum.Bridging ||
      linkType == LinkTypeEnum.Commercial
    ) {
      this.message = this.generateCriteriaUrl(linkType, id);
    } else {
      this.message = this.generateAllLoansUrl(id);

      if(isWidgetLink){
        this.message += "/0/widget";
      }
    }

    if (!isWidgetLink && (this.selectedOrganisation.OrganisationCode || this.selectedOrganisation.IsBrickflow)) {
      this.messageTitle =
        "Please copy the below URL to include in your website.";
      this.widgetModal = true;
    } else if (isWidgetLink) {
      this.messageTitle =
        "Please copy the below widget URL.";
      this.widgetModal = true;
    } else {
      if(isWidgetLink){
        this.deleteMessage =
        "To access URL please ensure company has an active White Label add-on and completed the URL Company Code in company settings (under White Label).";
      }else{
        this.deleteMessage =
        "To access your " +
        this.leadGeneratorName +
        " URL please ensure you have an active White Label add-on and you have completed the URL Company Code in your company settings (under White Label)";

      }
        this.messageDeleteModal = true;
    }
  }

  generateWidgetURL() {
    navigator.clipboard.writeText(
      `${location.origin}/${this.selectedOrganisation.OrganisationCode}#/initialregister`,
    );
    this.message = "Widget url has been copied to the clipboard";
    this.widgetModal = true;
  }

  closeWidgetModal() {
    this.widgetModal = false;
    this.message = null;
    this.messageTitle = null;
  }

  copyButtonCodeToClipBoard() {
    navigator.clipboard.writeText(this.message);
    this.messageTitle = null;
    this.message = "Code has been copied to the clipboard";
  }

  bookCall() {
    window.open(
      "https://brickflow.com/brokers/brickflow-enterprise/book-a-demo",
    );
  }

  getLicenseStatusText() {
    this.allActiveAppPackagePrices = [];
    this.userService
      .getLicenseStatusText(this.organisationAdmin.UserName)
      .then((response) => {
        this.licenseStatusText = response;
      });
  }

  getWhiteLabelledUrl() {
    this.authService.getWhiteLabelledUrl().then((response) => {
      this.whiteLabelledUrl = response;
    });
  }

  sendTestEmail(): void {
    this.$organisationService
      .sendTestEmail(
        this.testEmails,
        this.testEmailSubject,
        this.testEmailBody,
        this.selectedOrganisation.Id,
      )
      .then((response: boolean) => {
        if (response) {
          this.showTestEmailModal = false;
          this.showTestEmailSentConfirmation = true;
        } else {
          this.error = true;
        }
      })
      .catch((response) => {
        this.error = true;
      });
  }

  closeTestEmailConfirmation() {
    this.showTestEmailSentConfirmation = false;
    this.testEmails = null;
  }

  validateComanyCode() {
    this.validateCodeClicked = true;
    if (/^[A-Za-z0-9]*$/.test(this.selectedOrganisation.OrganisationCode)) {
      this.$organisationService
        .isOrganisationCodeValid(this.selectedOrganisation)
        .then((response: boolean) => {
          if (response) {
            this.isValidOrgCode = true;
          } else {
            this.isValidOrgCode = false;
          }
        });
    } else {
      this.isValidOrgCode = false;
    }
  }

  fetchUniqueLinks() {
    this.fetchingUniqueLinks = true;
    this.$organisationService
      .getUniqueLinks(this.selectedOrganisation.Id)
      .then((response) => {
        this.uniqueLinks = response;
      })
      .finally(() => {
        this.fetchingUniqueLinks = false;
      });
  }

  selectUniqueLink(uniqueLink?: OrganisationLinkDTO) {
    if (uniqueLink) {
      this.selectedUniqueLink = { ...uniqueLink };

      this.isEditUniqueLink = true;
    } else {
      this.selectedUniqueLink = {} as OrganisationLinkDTO;
      this.selectedUniqueLink.OrganisationId = this.selectedOrganisation.Id;
      this.isEditUniqueLink = false;
    }
    this.showEditUniqueLinkModal = true;
  }

  onClickOfDeleteUniqueLink(uniqueLink: OrganisationLinkDTO) {
    this.selectedUniqueLink = uniqueLink;
    if (this.isAdmin && !this.organisationAdmin) {
      this.displayMessage = `Please assign a new Org User before deleting ${this.selectedUniqueLink.LinkName}.`;
    } else {
      this.displayMessage = `Are you sure you wish to remove ${this.selectedUniqueLink.LinkName}?`;
      this.deleteUniqueLinkClicked = true;
    }
    this.confirmUniqueLinkDeletion = true;
  }

  getLinkType(linkTypeEnum: LinkTypeEnum) {
    if (linkTypeEnum == 1) return "Development";
    if (linkTypeEnum == 2) return "Bridging";
    if (linkTypeEnum == 3) return "All Loans";
    if (linkTypeEnum == 4) return "Commercial";
  }

  getLinkDescription(linkDescription) {
    return linkDescription === null ? "" : linkDescription;
  }

  removeUniqueLinkLogo() {
    this.selectedUniqueLink.LogoUrl = null;
  }

  onFileSelect(files) {
    if (files.length > 0) {
      this.imageErrorMsg = null;
      const allowedExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "tif",
        "tiff",
        "ico",
        "jfif",
        "pjpeg",
        "pjp",
        "apng",
      ];
      const filteredFiles = files.filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        return fileExtension && allowedExtensions.indexOf(fileExtension) !== -1;
      });

      if (filteredFiles.length === 0) {
        this.imageErrorMsg =
          "Invalid file type: SVG and webp formats are not supported";
        return;
      }

      this.fileAttachmentService
        .uploadPublicImage(files, this.selectedOrganisation.OrganisationCode)
        .then((imageUrl) => {
          this.selectedUniqueLink.LogoUrl = imageUrl;
          this.editLogo = true;
        });
    }
  }

  isUniqueLinkEmpty() {
    if (
      this.isNullOrWhitespace(this.selectedUniqueLink.LinkName) ||
      this.selectedUniqueLink.LinkType == null
    )
      return true;
    return false;
  }

  isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  saveUniqueLink() {
    this.isSavingUniqueLink = true;

    this.$organisationService
      .saveUniqueLink(this.selectedUniqueLink, this.isEditUniqueLink)
      .then((response) => {
        if (response) {
          this.isSavingUniqueLink = false;
          this.isEditUniqueLink = false;
          this.showEditUniqueLinkModal = false;
        } else {
          if (this.isEditUniqueLink) {
            this.ulsavemessage = `There is problem updating ${this.selectedUniqueLink.LinkName} details, Please try later.`;
          } else {
            this.ulsavemessage = `There is problem adding ${this.selectedUniqueLink.LinkName}, Please try later.`;
          }
        }
        this.fetchUniqueLinks();
      })
      .catch((error) => {
        this.isSavingUniqueLink = false;

        const errorMessage: string = error.data?.ExceptionMessage || "";
        if (
          errorMessage ===
          "A referential integrity constraint violation occurred: The property value(s) of 'ApplicationUser.Id' on one end of a relationship do not match the property value(s) of 'OrganisationLink.BrokerUserId' on the other end."
        ) {
          this.ulsavemessage =
            "As this link is already active, you'll need to set up a new unique link to change the assigned broker.";
        } else {
          this.ulsavemessage = "There is a problem. Please try later.";
        }
        console.error("Error saving unique link:", error);
      });
  }

  deleteUniqueLink() {
    if (this.deleteUniqueLinkClicked) {
      this.isEditUniqueLink = true;
      this.selectedUniqueLink.IsDeleted = true;
      this.selectedUniqueLink.IsEnabled = false;
      this.$organisationService
        .saveUniqueLink(this.selectedUniqueLink, this.isEditUniqueLink)
        .then((response) => {
          this.fetchUniqueLinks();
          this.isEditUniqueLink = false;
          this.confirmUniqueLinkDeletion = false;
          this.deleteUniqueLinkClicked = false;
        });
    } else {
      this.confirmUniqueLinkDeletion = false;
    }
  }

  cancelUniqueLinkModal() {
    this.selectedUniqueLink = undefined;
    this.showEditUniqueLinkModal = false;
    this.error = false;
  }

  closeUniqueLinkDeleteModal() {
    this.confirmUniqueLinkDeletion = false;
    this.deleteUniqueLinkClicked = false;
    this.ulsavemessage = "";
  }

  validateKey() {
    this.hubspotMessage = null;
    this.missingProperties = null;
    this.$organisationService
      .validateKey(
        this.selectedOrganisation.HubspotAccessToken,
        this.selectedOrganisation.HubspotPipelineStage,
      )
      .then((response) => {
        this.isHubspotValid = response == "Access token is valid.";
        this.hubspotMessage = response;
      })
      .then(() => {
        if (this.isHubspotValid) {
          this.$organisationService
            .getMissingProperties(
              this.selectedOrganisation.HubspotAccessToken,
              this.selectedOrganisation.HubspotPipelineStage,
            )
            .then((missingProperties) => {
              this.missingProperties = missingProperties;
            })
            .catch((error) => {
              this.hubspotMessage = `Error fetching missing properties: ${error.message}`;
            });
        } else {
          this.missingProperties = null;
        }
      });
  }

  setDefaultOrganisationThemeColour() {
    this.selectedOrganisation.OrganisationThemeColour = "#304B9A";
    this.registrationForm.$setDirty();
  }
  
}

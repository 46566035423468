export const enum LoanPurposeEnum {
  Speed = 1,
  DepositForAnotherPurchase = 2,
  Rebridge = 3,
  Other = 4,
  ChainBreak = 5,
  AuctionPurchase = 6,
  Refurbishment = 7,
  AnyOtherLegalPurpose = 8,
}

import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { LicenseAppPackageDTO } from "@js/DTO/LicenseAppPackageDTO.cs.d";
import { PaymentSessionDTO } from "@js/DTO/StripeSessionDTO.cs.d";

export class AccountSettingService {
  $http: ng.IHttpService;
  $cookies: ng.cookies.ICookiesService;
  $rootScope: ng.IRootScopeService;
  $q: ng.IQService;
  $timeout: ng.ITimeoutService;
  $baseAddress: string;

  static $inject = ["$http", "$cookies", "$rootScope", "$q", "$timeout"];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
  ) {
    this.$http = http;
    this.$cookies = cookies;
    this.$rootScope = rootScope;
    this.$q = q;
    this.$timeout = timeout;
    this.$baseAddress = "/api/pay";
  }

  createCheckoutSessionForUnpaid(
    products: AppPackagePricingDTO[],
    selectedUserName: string = "",
    isAddOnSelected: boolean,
  ): ng.IPromise<PaymentSessionDTO> {
    let defer = this.$q.defer<PaymentSessionDTO>();
    var encodeUserName =
      selectedUserName != "" ? encodeURIComponent(selectedUserName) : "";
    this.$http
      .post(
        this.$baseAddress +
          "/createcheckoutsessionforunpaid?selectedUserName=" +
          encodeUserName +
          "&isAddOnSelected=" +
          isAddOnSelected,
        products,
      )
      .then((response) => {
        defer.resolve(response.data as PaymentSessionDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  updateSubscription(
    products: AppPackagePricingDTO[],
    selectedUserName: string,
    isAddOnSelected: boolean,
    isUpdateWithPrecancel: boolean = false,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodeUserName = selectedUserName
      ? encodeURIComponent(selectedUserName)
      : selectedUserName;
    this.$http
      .post(
        this.$baseAddress +
          "/updateplanprice?selectedUserName=" +
          encodeUserName +
          "&isAddOnSelected=" +
          isAddOnSelected +
          "&isUpdateWithPrecancel=" +
          isUpdateWithPrecancel,
        products,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getSelectedPackagesForCurrentuser(
    userName: string = "",
  ): ng.IPromise<LicenseAppPackageDTO[]> {
    let defer = this.$q.defer<LicenseAppPackageDTO[]>();
    var encodeUserName = userName ? encodeURIComponent(userName) : userName;
    this.$http
      .get(
        this.$baseAddress +
          "/getselectedpackageforcurrentuser?userName=" +
          encodeUserName,
      )
      .then((response) => {
        defer.resolve(response.data as LicenseAppPackageDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  assignLicense(userId: string, orgId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
          "/assignlicense?userId=" +
          userId +
          "&orgId=" +
          orgId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  unAssignLicense(userId: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(this.$baseAddress + "/unassignlicense?userId=" + userId)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  updatePreCancelSubscription(
    products: AppPackagePricingDTO[],
    selectedUserName: string,
    isAddOnSelected: boolean,
    isUpdateWithPrecancel: boolean = false,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodeUserName = selectedUserName
      ? encodeURIComponent(selectedUserName)
      : selectedUserName;
    this.$http
      .post(
        this.$baseAddress +
          "/updateprecancelsubscription?selectedUserName=" +
          encodeUserName +
          "&isAddOnSelected=" +
          isAddOnSelected +
          "&isUpdateWithPrecancel=" +
          isUpdateWithPrecancel,
        products,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  assignCustomePrice(
    customPrice: string,
    numberOfLicenses: number,
    orgId: number,
    isAddOnSelected: boolean,
    isMonthlySubscription: boolean,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
          "/assigncustomprice?customPrice=" +
          customPrice +
          "&numberOfLicenses=" +
          numberOfLicenses +
          "&orgId=" +
          orgId +
          "&isAddOnSelected=" +
          isAddOnSelected +
          "&isMonthlySubscription=" +
          isMonthlySubscription,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  assignStandardPrice(orgId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(this.$baseAddress + "/assignstandardprice?orgId=" + orgId)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  offerLeadGeneratorLicense(
    leadGenPackage: LicenseAppPackageDTO,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .post(this.$baseAddress + "/offerleadgeneratorlicense", leadGenPackage)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  offerCustomPrice(
    customPrice: string,
    numberOfLicenses: number,
    orgId: number,
    isAddOnSelected: boolean,
    isMonthlySubscription: boolean,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
          "/offercustomprice?customPrice=" +
          customPrice +
          "&numberOfLicenses=" +
          numberOfLicenses +
          "&orgId=" +
          orgId +
          "&isAddOnSelected=" +
          isAddOnSelected +
          "&isMonthlySubscription=" +
          isMonthlySubscription,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /* addAddOnToLicense(orgId: number): ng.IPromise<boolean> {
            let defer = this.$q.defer<boolean>();

            this.$http.get(this.$baseAddress + "/addaddontolicense?orgId=" + orgId).then((response) => {
                defer.resolve(response.data as boolean);
            }).catch((response) => {
                defer.reject(response);
            });
            return defer.promise;
        }

        removeAddOnFromLicense(orgId: number): ng.IPromise<boolean> {
            let defer = this.$q.defer<boolean>();

            this.$http.get(this.$baseAddress + "/removeaddonfromlicense?orgId=" + orgId).then((response) => {
                defer.resolve(response.data as boolean);
            }).catch((response) => {
                defer.reject(response);
            });
            return defer.promise;
        }*/

  cancelLeadGeneratorOffer(
    selectedleadGenPackageId: number,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
          "/cancelleadgeneratoroffer?selectedleadGenPackageId=" +
          selectedleadGenPackageId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

import { DealLenderMessageDTO } from "@js/DTO/Deal/DealLenderMessageDTO.cs.d";
import {
  AppraisalData,
  TotalUnreadMessagesByAppraisalResponse,
  TotalUnreadMessagesByAppraisalRequest,
} from "@js/DTO/Messages/GetTotalUnReadMessagesByAppraisalMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class DealLenderMessageService extends BaseService<
  DealLenderMessageDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/deallendermessage";
    this.$broadcastBusinessNameSingular = "deallendermessage";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  updateDealLenderMessageReadDatetime(
    dealLenderId: number,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/updatedeallendermessagereaddatetime?dealLenderId=" +
          dealLenderId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getTotalUnreadMessagesPerAppraisal(
    appraisalDataList: AppraisalData[],
  ): ng.IPromise<TotalUnreadMessagesByAppraisalResponse> {
    let defer = this.$q.defer<TotalUnreadMessagesByAppraisalResponse>();
    var request: TotalUnreadMessagesByAppraisalRequest = {
      AppraisalDataList: appraisalDataList,
    };
    this.$http
      .post(this.$baseAddress + "/gettotalunreadmessagesperappraisal", request)
      .then((response) => {
        defer.resolve(response.data as TotalUnreadMessagesByAppraisalResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { CaseMemberDTO } from "@js/DTO/CaseMemberDTO.cs.d";
import { CaseSummaryDTO } from "@js/DTO/CaseSummaryDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { DevelopmentWithNoLoginDTO } from "@js/DTO/DevelopmentWithNoLoginDTO.cs.d";
import { IntroducerSimpleDTO } from "@js/DTO/IntroducerSimpleDTO.cs.d";
import { InviteDTO } from "@js/DTO/InviteDTO.cs.d";
import { LicenseMasterDTO } from "@js/DTO/LicenseMasterDTO.cs.d";
import { ModuleAppFeatureDTO } from "@js/DTO/ModuleAppFeatureDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { LicenseMasterStatusEnum } from "@js/models/enum/LicenseMasterStatusEnum.cs.d";
import { StatusEnum } from "@js/models/enum/StatusEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { CaseSummaryService } from "@js/services/CaseSummaryService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { DevelopmentInputWithNoLoginService } from "@js/services/DevelopmentInputWithNoLoginService";
import { IntroducerService } from "@js/services/IntroducerService";
import { IntroducerSimpleService } from "@js/services/IntroducerSimpleService";
import { InviteService } from "@js/services/InviteService";
import { LenderService } from "@js/services/LenderService";
import { LicenseService } from "@js/services/LicenseService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class NewDashboardController {
  dataLoading: boolean = false;
  showBrokerWelcomeMessage: boolean = false;
  orgAdminName: string;
  orgAdminEmail: string;

  //Introducers
  introducerFirstLogin: boolean = false;
  introduceClientModal: number = null;
  isProcessingSend: boolean = false; // to control disabling of send button on introduce a borrower modal
  clientFirstName: string;
  clientSurname: string;
  clientEmail: string;
  clientPhoneNumber: string;
  notifyBorrower: boolean = true;
  introducedUsers: ApplicationUserDTO[];
  hideCaseCreation: boolean = false;
  fetchingResults: boolean = false;
  savedResults: DevelopmentInputDTO[];
  unsavedIntroducedSearches: DevelopmentWithNoLoginDTO[];
  savedIntroducedSearches: DevelopmentInputDTO[] = [];
  savedCases: DevelopmentInputDTO[];
  allResults: DevelopmentInputDTO[];
  selectedResult: number;
  caseSummaryList: CaseSummaryDTO[];
  /*otherUsersCaseList: CaseSummaryDTO[];*/
  lenderName: string;
  selectedUser: ApplicationUserDTO;
  userSearchResults: ApplicationUserDTO[];
  adminUser: boolean = false;
  isBroker: boolean = false;
  isLender: boolean = false;
  isPrimaryApplicant: boolean = true;
  isBorrower: boolean = false;
  userRolesMessage: string;
  currentUser: ApplicationUserDTO = null;
  selecteduserName: string = null;
  OtherUsers: string[] = null;
  newSearchName: string;
  link: string;
  introducerSimpleDetails: IntroducerSimpleDTO;

  introducerId: number;

  confirmName: boolean = false;
  copyCase: number;
  caseName: string;
  caseId: number;
  newShareholders: CaseMemberDTO[];
  seeInviteBorrower: boolean = false;

  clientAdminTools: boolean = false;

  showSetPasswordModal: boolean = false;
  verifyNewPassword: string;
  passwordSetSuccess: boolean = false;
  registrationForm: FormData;
  modal: ng.IFormController;

  invite: InviteDTO;
  invitedUsers: InviteDTO[] = [];

  introducerErrorDisplayed: boolean = false;
  applyCaseStatusFilter: boolean = false;

  orderColumn: string = "LastUpdatedDateTime";
  isAscButton: boolean[] = [true, true, true, true, true, true, true, true];
  isAsc: boolean = true;

  locations: Array<{ id: number; value: string }> = [
    { id: 0, value: "Any" },
    { id: 1, value: "London" },
    { id: 2, value: "North West" },
    { id: 4, value: "Midlands" },
    { id: 8, value: "East of England" },
    { id: 16, value: "South East" },
    { id: 32, value: "South West" },
    { id: 64, value: "North East" },
    { id: 128, value: "Wales" },
    { id: 256, value: "Scotland" },
    { id: 512, value: "Northern Ireland" },
  ];
  propertyTypes: Array<{ id: number; value: string }> = [
    { id: 0, value: "Any" },
    { id: 1, value: "Residential" },
    { id: 2, value: "Mixed Use" },
    { id: 2048, value: "Licenced HMO" },
    { id: 4, value: "Hotel" },
    { id: 8, value: "Leisure" },
    { id: 16, value: "Care Home" },
    { id: 32, value: "Medical" },
    { id: 64, value: "Office" },
    { id: 128, value: "Retail" },
    { id: 256, value: "Light Industrial" },
    { id: 512, value: "Student" },
    { id: 1024, value: "Heavy Industrial" },
    { id: 4096, value: "Land without Planning" },
    { id: 8192, value: "Education" },
    { id: 16384, value: "Retirement" },
  ];

  caseStatuses: Array<{ id: number; value: string; order: number }> = [
    { id: -1, value: "Search", order: 1 },
    { id: 0, value: "New Case", order: 2 },
    { id: 1, value: "In Progress", order: 3 },
    { id: 2, value: "Under Review", order: 4 },
    { id: 3, value: "Ready To Submit", order: 5 },
    { id: 4, value: "Submitted To Lenders For DIP", order: 6 },
    { id: 5, value: "Applied", order: 7 },
    { id: 10, value: "Credit Approved", order: 8 },
    { id: 11, value: "With Professionals", order: 9 },
    { id: 6, value: "Completed", order: 10 },
    { id: 7, value: "Dormant", order: 11 },
    { id: 9, value: "Submitted To Lenders Pending Review", order: 12 },
  ];

  lenderCaseStatuses: Array<{ id: number; value: string; order: number }> = [
    { id: 0, value: "Shortlisted", order: 1 },
    { id: 1, value: "Received", order: 2 },
    { id: 2, value: "Offered", order: 3 },
    { id: 3, value: "Rejected", order: 4 },
    { id: 4, value: "Withdrawn", order: 5 },
    { id: 10, value: "Applied", order: 6 },
  ];

  clientCaseNameFilter: string = null;
  statusFilter: CaseStatusEnum = null;

  isProceedClicked: boolean = false;

  userOrganisation: OrganisationDTO = null;

  isBrokerNIntroducer: boolean = false;
  showOrgModal: boolean = false;
  welcomeSetting: boolean = false;
  popupHeader: string;

  appPackagePrices: AppPackagePricingDTO[];
  isSelected: boolean = false;
  selectedProduct: AppPackagePricingDTO = null;
  showPaymentModal: boolean = false;
  moduleAppFeatures: ModuleAppFeatureDTO[];
  moduleFeatureCount: number = 0;
  selecteFeatureCount: number = 0;
  licenseStatus: LicenseMasterStatusEnum = LicenseMasterStatusEnum.PaidUp;
  selectedProductList: AppPackagePricingDTO[] = [];
  billingFrequency: Array<{ id: number; value: string }> = [
    { id: 1, value: "Monthly" },
    { id: 2, value: "Yearly" },
  ];
  selectedBillingFrequency: number;

  referrerOptions = [];

  static $inject = [
    "$rootScope",
    "$routeParams",
    "$location",
    "DevelopmentInputService",
    "DevelopmentInputWithNoLoginService",
    "UserService",
    "CaseService",
    "RoleService",
    "AuthService",
    "IntroducerService",
    "IntroducerSimpleService",
    "CaseSummaryService",
    "LenderService",
    "$cookies",
    "CaseMemberService",
    "InviteService",
    "OrganisationService",
    "PaymentService",
    "LicenseService",
    "SelectListService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private $DevelopmentInputWithNoLoginService: DevelopmentInputWithNoLoginService,
    private $user: UserService,
    private $CaseService: CaseService,
    private roleService: RoleService,
    private authService: AuthService,
    private $IntroducerService: IntroducerService,
    private $IntroducerSimpleService: IntroducerSimpleService,
    private $CaseSummaryService: CaseSummaryService,
    private $LenderService: LenderService,
    private $cookies: ng.cookies.ICookiesService,
    private $applicantservice: CaseMemberService,
    private inviteService: InviteService,
    private organisationService: OrganisationService,
    private $paymentService: PaymentService,
    private licenseService: LicenseService,
    private selectListService: SelectListService,
  ) {
    this.initialise();

    if ((this.$rootScope as any).openWelcomeBrokerModal) {
      this.authService.getadminProfile().then((result) => {
        if (result) {
          this.orgAdminName = result.UserName;
          this.orgAdminEmail = result.Email;
          this.showBrokerWelcomeMessage = true;
        }
      });
    }

    this.referrerOptions = this.selectListService.GetReferrerOptions();
  }

  initialise() {
    //Introducers
    this.introducerFirstLogin = (this.$rootScope as any).introducerFirstLogin;

    //get type of user
    this.roleService.GetUserRoles().then((result) => {
      if (result.filter((x) => x.toLowerCase() == "admin").length > 0) {
        this.adminUser = true;
      }
      if (result.filter((x) => x.toLowerCase() == "broker").length > 0) {
        this.isBroker = true;
        this.isPrimaryApplicant = false;
      }
      if (result.filter((x) => x.toLowerCase() == "lender").length > 0) {
        this.isLender = true;
      }
      if (result.filter((x) => x.toLowerCase() == "client").length > 0) {
        this.isBorrower = true;
      }

      //This is do hide introduced search
      if (
        this.isBroker &&
        result.filter((x) => x.toLowerCase() == "introducer").length > 0
      ) {
        this.isBrokerNIntroducer = true;
      }

      this.userRolesMessage = this.generateUserRoleString();

      this.authService.getUpdatedProfile().then((prof) => {
        this.currentUser = prof;
        if ((this.$rootScope as any)?.currentUser?.SubscriptionStatus) {
          (this.$rootScope as any).currentUser.SubscriptionStatus =
            this.currentUser.SubscriptionStatus;
        }
        this.organisationService.getOrganisation().then((response) => {
          if (response.Id != 0) {
            this.userOrganisation = response;
          }

          this.popupHeader = "Customise Premium Features";

          // only display the broker admin welcome modal once we've retrieved the org info, otherwise there is a delay in showing the company name
          if (prof.IsOrganisationAdmin) {
            if (
              prof.DisplayOrganisationAdminWelcomepage &&
              !sessionStorage.getItem("showwelcome")
            ) {
              //temp comment to show payment engine modal
              //  this.showOrgModal = prof.DisplayOrganisationAdminWelcomepage;
              this.welcomeSetting = !prof.DisplayOrganisationAdminWelcomepage;
              sessionStorage.setItem("showwelcome", "show");
            }
          }
        });

        if (this.$routeParams.licensemasterId) {
          this.licenseService
            .fetch(this.$routeParams.licensemasterId)
            .then((license: LicenseMasterDTO) => {
              this.licenseStatus = license.Status;
              if (
                license.Status == LicenseMasterStatusEnum.Unpaid ||
                license.Status == LicenseMasterStatusEnum.PayFailure ||
                license.Status == LicenseMasterStatusEnum.PaidUp
              ) {
                this.showPaymentModal = true;
              }
            });
        }

        // get invited list of users
        this.inviteService
          .getInvitedBorrowers(this.currentUser.Id)
          .then((result) => {
            this.invitedUsers = result;
          });

        if (this.isLender) {
          //this.$LenderService.getLenderDetailsByUserId(this.currentUser.Id).then((results) => {
          //    this.lenderName = results.Name;
          //});
        }

        //If half-registered user hasn't set a password, then prompt them to set one
        if (this.currentUser.TemporaryAccount === true) {
          this.showSetPasswordModal = true;
        }

        //Get my introducer's details
        this.$IntroducerSimpleService.getintroducerdetails().then((i) => {
          this.introducerSimpleDetails = i;
        });

        this.introducerId = prof.RegisteredIntroducerId;
        if (prof.RegisteredIntroducerId) {
          this.updateIntroducedSearches(prof.RegisteredIntroducerId);
          //this.updateIntroducedClientList(prof.RegisteredIntroducerId);
        }

        //newdashboard
        if ((this.$rootScope as any).clientUsernameBeingViewed) {
          this.updateResults();
        } else {
          //this.$CaseSummaryService.getmycasesandsearches(this.currentUser.UserName).then((results) => {
          //    this.caseSummaryList = results;
          //});

          this.selecteduserName = this.currentUser.UserName;
        }
      });
    });
  }

  viewClientsDashboard(userName: string) {
    //Look up client's account details
    this.dataLoading = true;
    this.$user.searchByEmail(userName).then((users) => {
      this.selectedUser = users[0];
      this.selecteduserName = users[0].Email;
      (this.$rootScope as any).clientUsernameBeingViewed = users[0].Email;
      this.updateResults();
      this.clientAdminTools = false;
      this.dataLoading = false;
    });
  }

  exitClientView(): void {
    (this.$rootScope as any).clientUsernameBeingViewed = null;
    this.selecteduserName = this.currentUser.UserName;
    this.updateResults();
    this.clientAdminTools = true;
  }

  userLookup(userSearchTerm: string) {
    this.fetchingResults = true;
    this.$user
      .searchByEmail(userSearchTerm)
      .then((response) => {
        if (this.isBroker && this.currentUser.IsOrganisationAdmin) {
          this.userSearchResults = response.filter(
            (x) =>
              x.OrganisationReferralId == this.currentUser.OrganisationId ||
              x.OrganisationId == this.currentUser.OrganisationId,
          );
        } else if (this.isBroker) {
          this.userSearchResults = response.filter(
            (x) =>
              x.OrganisationReferralId == this.currentUser.OrganisationId &&
              !x.OrganisationId &&
              !x.IsOrganisationAdmin,
          );
        } else {
          this.userSearchResults = response;
        }
      })
      .finally(() => {
        this.fetchingResults = false;
      });
  }

  saveBlankSearch() {
    this.fetchingResults = true;

    let newDTO = {} as DevelopmentInputDTO;
    newDTO.OwnerUser = this.selecteduserName;
    newDTO.UserId = this.selectedUser.Id;
    newDTO.SaveQueryAndResults = true;

    var currentdate = new Date();
    newDTO.SearchName = this.newSearchName
      ? this.newSearchName
      : "My Loan Search " +
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear();

    newDTO.CI_Dev_Contingency = 0.05;
    newDTO.CI_Dev_BuildTerm = 12;
    newDTO.CI_Dev_LoanTermReq = 18;
    newDTO.CI_Dev_DateOfOrigPurch = new Date();

    this.$DevelopmentInputservice
      .addBlankSearch(newDTO)
      .then((response) => {
        let newCaseSummary = {
          CaseName: response.SearchName,
          CaseStatus: CaseStatusEnum.Search,
          DevelopmentInputID: response.Id,
          Client: this.adminUser ? response.OwnerUser : "", // The user should always be an admin when adding a blank search but adding the check anyway
          PropertyType: response.CI_EndPropType,
          Location: response.CI_Dev_Locations,
          LandCost: response.Dev_TotLndCostAdj,
          BuildCost: response.Dev_TotBuildCostsAdj,
          GDV: response.CI_GDVCalculated,
        } as CaseSummaryDTO;
        this.caseSummaryList.push(newCaseSummary);
      })
      .finally(() => {
        this.fetchingResults = false;
      });
  }

  newBlankCase() {
    this.fetchingResults = true;
    this.$CaseService
      .newBlankCase(this.currentUser, this.isPrimaryApplicant)
      .then((newBlankCaseDto) => {
        if (newBlankCaseDto && newBlankCaseDto.NewCaseId) {
          this.viewCase(newBlankCaseDto.NewCaseId);
          this.fetchingResults = false;
        }
      });
  }

  updateResults() {
    this.fetchingResults = true;

    if (this.selecteduserName) {
      this.selecteduserName = this.selecteduserName;
    } else if ((this.$rootScope as any).clientUsernameBeingViewed) {
      this.selecteduserName = (
        this.$rootScope as any
      ).clientUsernameBeingViewed;
    } else {
      this.selecteduserName = this.currentUser.UserName;
    }

    if (this.selecteduserName !== this.currentUser.UserName) {
      this.$user.searchByEmail(this.selecteduserName).then((users) => {
        this.selectedUser = users[0];
      });
    }

    //this.$CaseSummaryService.getmycasesandsearches(this.selecteduserName, this.clientCaseNameFilter, this.statusFilter).then((results) => {
    //    this.caseSummaryList = results;
    //}).catch((error) => {

    //}).finally(() => {
    //    this.fetchingResults = false;
    //});
  }

  updateIntroducedSearches(RegisteredIntroducerId: number) {
    if (!RegisteredIntroducerId) {
      return;
    }
    this.$DevelopmentInputWithNoLoginService
      .fetchByIntroducerId(RegisteredIntroducerId)
      .then((results) => {
        this.unsavedIntroducedSearches = results.filter(
          (r) => r.IntroducedSearchStatus < 2,
        );
        let savedIntroducedSearchesIdentified = results.filter(
          (r) => r.IntroducedSearchStatus >= 2,
        );
        if (savedIntroducedSearchesIdentified.length > 0) {
          this.$DevelopmentInputservice
            .fetchByIntroducerId(RegisteredIntroducerId)
            .then((devInputResults) => {
              this.savedIntroducedSearches = devInputResults;
            });
        }

        this.updateIntroducedClientList();
      });
  }

  updateIntroducedClientList() {
    this.$user.getintroducees(this.currentUser.Id).then((introducedUsers) => {
      this.introducedUsers = introducedUsers;
    });
  }
  ApplyFilterOnUsers() {
    this.clientAdminTools = false;
    this.savedResults = this.allResults.filter(
      (x) => !x.IsFullCase && x.OwnerUser == this.selecteduserName,
    );
    this.savedCases = this.allResults.filter(
      (x) => x.IsFullCase && x.OwnerUser == this.selecteduserName,
    );
    (this.$rootScope as any).clientUsernameBeingViewed = this.selecteduserName;
  }

  makeCase(loanCriteriaId: number, isPrimaryApplicant) {
    this.isProceedClicked = true;
    this.$CaseService
      .promotesearchtocase(loanCriteriaId, null, null, isPrimaryApplicant)
      .then((newCaseId: number) => {
        if (newCaseId) {
          this.viewCase(newCaseId);
        } else {
          this.isProceedClicked = false;
        }
      })
      .catch((response) => {
        this.isProceedClicked = false;
      });
  }
  // changed for newcasedashboard
  delete(
    loanCriteriaId: number,
    loanType: number,
    index: number,
    caseId: number,
  ) {
    switch (loanType) {
      case 0:
        //Full case and Saved search
        this.$DevelopmentInputservice
          .markasdeleted(loanCriteriaId)
          .then((success) => {
            if (caseId) {
              this.$CaseService.markasdeleted(caseId).then((success) => {});
            }
          })
          .finally(() => {
            this.caseSummaryList = this.caseSummaryList.filter(
              (caseSummary) => caseSummary.DevelopmentInputID != loanCriteriaId,
            );
          });

        break;
      case 2:
        //Introducer: Referred search, not claimed by introducee yet
        this.$DevelopmentInputWithNoLoginService
          .markasdeleted(loanCriteriaId)
          .then((success) => {
            this.unsavedIntroducedSearches.splice(index, 1);
          })
          .finally(() => {
            this.caseSummaryList = this.caseSummaryList.filter(
              (caseSummary) => caseSummary.DevelopmentInputID != loanCriteriaId,
            );
          });
        break;
      case 3:
        //Introducer: Referred search, claimed by introducee
        this.$DevelopmentInputservice
          .markasdeleted(loanCriteriaId)
          .then((success) => {
            this.savedIntroducedSearches.splice(index, 1);
          });
        break;
    }
  }

  // changed for newcasedashboard
  deleteForCaseSumarry(
    loanCriteriaId: number,
    loanType: number,
    index: number,
    caseId: number,
  ) {
    switch (loanType) {
      case 0:
        //Full case and Saved search
        this.$DevelopmentInputservice
          .markasdeleted(loanCriteriaId)
          .then((success) => {
            if (caseId) {
              this.$CaseService.markasdeleted(caseId).then((success) => {});
            }
          })
          .finally(() => {
            this.caseSummaryList = this.caseSummaryList.filter(
              (caseSummary) => caseSummary.DevelopmentInputID != loanCriteriaId,
            );
          });

        break;
      case 2:
        //Introducer: Referred search, not claimed by introducee yet
        this.$DevelopmentInputWithNoLoginService
          .markasdeleted(loanCriteriaId)
          .then((success) => {})
          .finally(() => {
            this.caseSummaryList = this.caseSummaryList.filter(
              (caseSummary) => caseSummary.DevelopmentInputID != loanCriteriaId,
            );
          });
        break;
    }
  }

  viewCase(caseId: number) {
    //this.$CaseService.getcasefromsearch(developmentId).then((result) => {
    //if (result)
    this.$location.path("/casedashboard/" + caseId);
    //});
  }

  //this is not used any more
  viewCaseByDIId(newCaseId: number) {
    this.$location.path("/casedashboard/" + newCaseId);
  }

  viewResults(caseSummary: CaseSummaryDTO) {
    if (caseSummary.CaseStatus != CaseStatusEnum.Search && caseSummary.CaseID) {
      //this.$CaseService.getcasefromsearch(caseSummary.DevelopmentInputID).then((result) => {
      //need to tweak a bit when we have all the case status.
      //this.$CaseService.updateTubeMap(result.CaseStatus);
      this.$location.path(
        "/results/" + caseSummary.DevelopmentInputID + "/" + caseSummary.CaseID,
      );
      //});
    } else if (caseSummary.UniqueRef != null) {
      this.$location.path("/referredSearch/" + caseSummary.UniqueRef);
    } else if (caseSummary.DevelopmentInputID) {
      this.$location.path("/results/" + caseSummary.DevelopmentInputID);
    }
  }

  viewIntroducedResults(loanCriteria: DevelopmentWithNoLoginDTO) {
    this.$location.path("/referredSearch/" + loanCriteria.UniqueRef);
  }

  openModal(developmentInput: CaseSummaryDTO) {
    this.confirmName = true;
    this.copyCase = developmentInput.DevelopmentInputID;
    this.caseId = developmentInput.CaseID;
    this.$DevelopmentInputservice
      .fetch(developmentInput.DevelopmentInputID)
      .then((result) => {
        this.caseName = result.SearchName + " - Copy";
      });
  }

  copy() {
    this.$DevelopmentInputservice
      .copyCase(this.copyCase, this.caseName)
      .then((response) => {
        let newCaseId = response;
        if (newCaseId) {
          this.$applicantservice.copyShareholder(this.caseId, newCaseId);
          this.viewCase(newCaseId);
        }
      })
      .finally(() => {
        this.fetchingResults = false;
      });
  }

  cancel() {
    this.confirmName = false;
    delete this.copyCase;
  }

  newloan() {
    (this.$rootScope as any).loanCriteria = null;
    this.$location.path("/criteria/0/0/1");
  }

  registerIntroducer() {
    this.$location.path("/registerintroducer");
  }

  introduceClient() {
    this.isProcessingSend = true;
    this.invite = {
      FirstName: this.clientFirstName,
      Email: this.clientEmail,
      LastName: this.clientSurname,
      InviteerUserId: this.currentUser.Id,
      UserId: null,
      PhoneNumber: this.clientPhoneNumber,
      Status: StatusEnum.Invited,
    } as InviteDTO;

    this.inviteService.addUpdate(this.invite).then((response) => {
      this.invitedUsers.push(response);

      this.$IntroducerService
        .introduceClient(
          response.FirstName,
          response.Email,
          response.LastName,
          response.PhoneNumber,
          response.guid,
        )
        .then((success) => {
          this.introduceClientModal = 2;
          this.dataLoading = false;
          this.isProcessingSend = false;
        })
        .finally(() => {
          delete this.clientFirstName;
          delete this.clientEmail;
          delete this.clientSurname;
          delete this.clientPhoneNumber;
        });
    });
  }

  inviteBorrower() {
    this.invite = {
      FirstName: this.clientFirstName,
      Email: this.clientEmail,
      LastName: this.clientSurname,
      InviteerUserId: this.currentUser.Id,
      UserId: null,
      PhoneNumber: this.clientPhoneNumber,
      Status: StatusEnum.Invited,
    } as InviteDTO;

    this.inviteService.addUpdate(this.invite).then((response) => {
      this.invitedUsers.push(response);

      this.$user.GetUserOrganisation().then((result) => {
        if (this.invite && result) {
          this.organisationService
            .inviteBorrowers(this.invite, result.UniqueRef)
            .then((success) => {
              this.introduceClientModal = 9;
              this.dataLoading = false;
            })
            .finally(() => {
              this.introduceClientModal = 9;
              delete this.clientFirstName;
              delete this.clientEmail;
              delete this.clientSurname;
              delete this.clientPhoneNumber;
            });
        }
      });
    });
  }

  sendLinkToUser() {
    this.$IntroducerService
      .sendLinkToUser(
        this.currentUser.FirstName,
        this.currentUser.Email,
        this.currentUser.Id,
      )
      .then((success) => {
        this.introduceClientModal = 6;
        this.dataLoading = false;
      });
  }

  copyLinkToClipboard() {
    this.$IntroducerService
      .copyLinkToClipboard(this.currentUser.Id)
      .then((response) => {
        this.link = response.toString();
        navigator.clipboard.writeText(this.link);
        this.introduceClientModal = 7;
        this.dataLoading = false;
      });
  }

  close() {
    this.introduceClientModal = null;
    delete this.clientFirstName;
    delete this.clientEmail;
    delete this.clientSurname;
  }

  introducerSendResultsToClient(id: number, notifyBorrower: boolean = false) {
    this.dataLoading = true;
    this.$DevelopmentInputservice.fetch(id).then((response) => {
      this.$IntroducerService
        .sendResultsToClient(
          response,
          this.clientFirstName,
          this.clientSurname,
          this.clientEmail,
          notifyBorrower,
        )
        .then((success) => {
          this.introduceClientModal = 4;
        })
        .catch((reason) => {
          this.introducerErrorDisplayed = true;
        })
        .finally(() => {
          this.dataLoading = false;
          delete this.clientFirstName;
          delete this.clientEmail;
          delete this.clientSurname;
        });
    });
  }

  introducerRefreshSearchesAndClients() {
    //Trigger a refresh to show results
    this.updateIntroducedSearches(this.introducerId);
    this.clientAdminTools = true;
  }

  go(path) {
    this.$location.path(path);
  }

  completeAccount() {
    this.currentUser.TemporaryAccount = false;
    this.currentUser.Roles = [];
    this.currentUser.Roles.push("Client");
    if (this.currentUser.ApplicantDefinedRole == 0) {
      this.currentUser.NewUserEmailJourneyStarted = true;
    }
    this.$user.addUpdate(this.currentUser).then((response) => {
      this.passwordSetSuccess = true;
    });
  }

  afterSetPasswordContinue() {
    //User needs to be logged out and logged in again
    if (this.$cookies.get("access_token")) {
      this.authService.logout(false);
    }
    this.authService
      .login(
        this.currentUser.Email,
        this.currentUser.Password,
        "CC022EBE67BE0424EA2A6548B062D2D71",
      )
      .then((response) => {
        let expiry: Date = response;
        this.$cookies.put("user_firstname", this.currentUser.FirstName, {
          expires: expiry,
        });
        this.showSetPasswordModal = false;
        this.initialise();
      });
  }

  /**
   * Order alphabetically
   * @param column
   * @param index
   */
  order(column: string, index: number) {
    this.isAscButton[index] = !this.isAscButton[index];
    this.isAsc = this.isAscButton[index];
    this.orderColumn = column;

    this.caseSummaryList.sort((rowA: CaseSummaryDTO, rowB: CaseSummaryDTO) => {
      let rowAValue = rowA[column];
      let rowBValue = rowB[column];

      if (rowAValue == rowBValue) {
        return 0;
      }

      return this.isAsc
        ? rowAValue < rowBValue
          ? -1
          : 1
        : rowAValue < rowBValue
          ? 1
          : -1;
    });
  }

  /**
   * Order numerically
   * @param column
   * @param index
   */
  orderNumber(column, index: number) {
    this.isAscButton[index] = !this.isAscButton[index];
    this.isAsc = this.isAscButton[index];
    this.orderColumn = column;

    this.caseSummaryList.sort((rowA: CaseSummaryDTO, rowB: CaseSummaryDTO) => {
      let rowAValue = rowA[column];
      let rowBValue = rowB[column];

      return this.isAsc ? rowAValue - rowBValue : rowBValue - rowAValue;
    });
  }

  /**
   * Order by the display value e.g. for enums
   * @param column
   * @param index
   */
  orderByDisplayValue(column: string, index: number) {
    this.isAscButton[index] = !this.isAscButton[index];
    this.isAsc = this.isAscButton[index];
    this.orderColumn = column;

    var lookup: Array<{ id: number; value: string }>;

    switch (column) {
      case "PropertyType": {
        lookup = this.propertyTypes;
        break;
      }
      case "Location": {
        lookup = this.locations;
        break;
      }
      default: {
        //Error - undefined sort column
      }
    }

    this.caseSummaryList.sort((rowA: CaseSummaryDTO, rowB: CaseSummaryDTO) => {
      let rowAValue = lookup.find((a) => a.id == rowA[column]).value;
      let rowBValue = lookup.find((b) => b.id == rowB[column]).value;

      if (rowAValue == rowBValue) {
        return 0;
      }

      return this.isAsc
        ? rowAValue < rowBValue
          ? -1
          : 1
        : rowAValue < rowBValue
          ? 1
          : -1;
    });
  }

  /**
   * Order by a specified numeric order
   * @param column
   * @param index
   */
  orderByOrderNumber(column: string, index: number) {
    this.isAscButton[index] = !this.isAscButton[index];
    this.isAsc = this.isAscButton[index];
    this.orderColumn = column;

    var lookup: Array<{ id: number; value: string; order: number }>;

    switch (column) {
      case "CaseStatus": {
        lookup = this.caseStatuses;
        break;
      }
      case "CaseLenderState": {
        lookup = this.lenderCaseStatuses;
        break;
      }
      default: {
        //Error - undefined sort column
      }
    }

    this.caseSummaryList.sort((rowA: CaseSummaryDTO, rowB: CaseSummaryDTO) => {
      let rowAOrder = lookup.find((a) => a.id == rowA[column]).order;
      let rowBOrder = lookup.find((b) => b.id == rowB[column]).order;

      return this.isAsc ? rowAOrder - rowBOrder : rowBOrder - rowAOrder;
    });
  }

  /**
   * Opens the Share search modal
   * @param item
   */
  openShareSearchModal(item: CaseSummaryDTO) {
    this.selectedResult = item.DevelopmentInputID;
    this.introduceClientModal = 3;

    if (
      this.roleService.isAdminUser &&
      item.ClientEmail != this.currentUser.UserName
    ) {
      this.clientFirstName = item.ClientFirstName;
      this.clientSurname = item.ClientSurname;
      this.clientEmail = item.ClientEmail;
    } else {
      this.clientFirstName = "";
      this.clientSurname = "";
      this.clientEmail = "";
    }
  }

  showResultButton(caseStatus: CaseStatusEnum) {
    if (
      caseStatus == CaseStatusEnum.SubmittedToLendersForHoT ||
      caseStatus == CaseStatusEnum.Applied ||
      caseStatus == CaseStatusEnum.CreditApproved ||
      caseStatus == CaseStatusEnum.Completed ||
      caseStatus == CaseStatusEnum.WithProfessionals
    ) {
      return true;
    }
    return false;
  }

  closeWelcomePopup() {
    if (
      this.currentUser.DisplayOrganisationAdminWelcomepage ==
      this.welcomeSetting
    ) {
      this.currentUser.DisplayOrganisationAdminWelcomepage =
        !this.welcomeSetting;
      this.$user.addUpdate(this.currentUser).then((userResponse) => {
        this.showOrgModal = false;
      });
    } else {
      this.showOrgModal = false;
    }
  }

  fillRegisterForm() {
    this.currentUser.Location = 1;
    this.currentUser.Password = "test";
    (this.currentUser as any).ConfirmPassword = "test";
  }

  generateUserRoleString() {
    var str = "Your role: ";

    if (this.adminUser) {
      str += "Admin ";
    } else if (this.isBroker) {
      if (this.isBrokerNIntroducer) {
        str += "Broker and Introducer ";
      } else {
        str += "Broker ";
      }
    } else if (this.isLender) {
      str += "Lender ";
      if (this.lenderName) {
        str += "(" + this.lenderName + ")";
      }
    } else if (this.isBorrower) {
      str += "Borrower ";
      if (this.userOrganisation?.Name?.length > 0) {
        str +=
          "(Your Broker Organisation: " + this.userOrganisation?.Name + ")";
      }
    }
    return str;
  }
}

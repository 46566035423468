import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { RoleService } from "@js/services/RoleService";

export class AgentLandingController {
  caseId: number;
  case: CaseDTO;
  disablepayment: boolean = false;
  message: string = "";
  modal: boolean = false;

  static $inject = [
    "$routeParams",
    "$scope",
    "$location",
    "$cookies",
    "RoleService",
    "$window",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $scope: ng.IScope,
    private $location: ng.ILocationService,
    private $cookies: ng.cookies.ICookiesService,
    private roleService: RoleService,
    private $window: ng.IWindowService,
  ) {
    //Check if the user is logged in
    if (
      this.$cookies.get("access_token") != null ||
      this.$cookies.get("access_token") != undefined
    ) {
      if (this.roleService.isAdminUser) {
        this.$location.path("/dashboard");
      } else {
        this.$location.path("/userdashboard");
      }
    }
  }

  goToRegistrationPage() {
    this.$location.path("/registerintroducer");
  }

  goToCriteriaPage() {
    this.$location.path("/criteria/0/0/1");
  }

  goToLoginPage() {
    this.$location.path("/login");
  }

  mailForMarketing() {
    this.$window.location.assign("mailto:john@brickflow.com");
  }
}

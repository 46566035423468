import { AddressLookupDTO } from "@js/DTO/AddressLookupDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { CaseService } from "@js/services/CaseService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { LenderResultService } from "@js/services/LenderResultService";
import { RoleService } from "@js/services/RoleService";
import { StreetAddressService } from "@js/services/StreetAddressService";

export class DevelopmentAppraisalScreenController {
  isLoggedInUser: boolean = false;

  fileUpload: FileAttachmentDTO[];
  uploadingFiles: FileUploadProgressDTO[];
  case: CaseDTO;
  caseTitle: string;
  openModal: boolean = false;
  thisModuleSection: ModuleEnum = ModuleEnum.DevelopmentAppraisal;
  total: number = 0;

  dataLoading: boolean = false;

  loanCriteria: DevelopmentInputDTO;
  step: number = 1;
  dateOfOrigPurchMonth: number;
  dateOfOrigPurchYear: number;
  DevelopmentInputForm: ng.IFormController;
  caseid: number;
  searchid: number;
  totalPurchaseCostsOwning: number;
  additionalBuild: number;
  totalBuild: number;
  totalSales: number;
  totalPurchaseCostsNotOwning: number;
  totalNDV: number;
  contingencyCost: number;

  searchterm: string;
  PostCodeOptions: PostalAddress[] = [];
  searchingForAddress: boolean = false;
  searchresults: string;

  criteriaPropertyForm: ng.IFormController;
  criteriaBorrowingForm: ng.IFormController;
  criteriaCostsForm: ng.IFormController;
  criteriaSalesForm: ng.IFormController;

  guidanceCheckbox: boolean = true;

  pageLoading: boolean = true;

  contingencyTotal: number;

  seeFiles: boolean = false;

  //array describing form sections
  formSectionNames = [
    {
      label: "Property",
      visible: true,
    },
    {
      label: "Build Costs",
      visible: true,
    },
    {
      label: "Borrowing",
      visible: true,
    },
    {
      label: "Sales",
      visible: true,
    },
  ];

  static $inject = [
    "$routeParams",
    "$rootScope",
    "$q",
    "DevelopmentInputService",
    "$location",
    "LenderResultService",
    "StreetAddressService",
    "$cookies",
    "CaseService",
    "FileAttachmentService",
    "$window",
    "RoleService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $rootScope: ng.IRootScopeService,
    protected $q: ng.IQService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private $location: ng.ILocationService,
    private $lenderresultservice: LenderResultService,
    private $streetAddressService: StreetAddressService,
    private $cookies: ng.cookies.ICookiesService,
    private $caseService: CaseService,
    private fileAttachmentService: FileAttachmentService,
    private $window: ng.IWindowService,
    private roleService: RoleService,
  ) {
    if ($cookies.get("access_token")) {
      this.isLoggedInUser = true;
    }

    //Init
    if (this.$routeParams.CaseId && this.$routeParams.SearchId) {
      this.caseid = this.$routeParams.CaseId;
      this.searchid = this.$routeParams.SearchId;
    } else {
      this.$location.path("/dashboard");
    }

    this.$caseService.fetch(this.$routeParams.CaseId).then((response) => {
      if (response == null) {
        if (this.isLoggedInUser) {
          this.$location.path("/userdashboard");
        }
      } else {
        this.fileUpload = response.Attachments;
        this.case = response;
        this.$caseService.updateCaseState(this.case.Id, this.case.CaseStatus);
        this.caseTitle = response.DevelopmentInput.SearchName;
      }
    });

    // Look up the existing search - must exist prior in order to have a case.

    this.$DevelopmentInputservice
      .fetch(this.searchid)
      .then((response) => {
        if (response == null) {
          if (this.isLoggedInUser) {
            this.$location.path("/userdashboard");
          }
        } else {
          this.loanCriteria = response;
          if (this.loanCriteria) {
            this.calcContingencyCost();
            if (this.loanCriteria.CI_Dev_DateOfOrigPurch) {
              this.dateOfOrigPurchMonth =
                this.loanCriteria.CI_Dev_DateOfOrigPurch.getMonth() + 1;
              this.dateOfOrigPurchYear =
                this.loanCriteria.CI_Dev_DateOfOrigPurch.getFullYear() % 100;
            }
            if (!this.loanCriteria.DI_PurchaseAgentFees) {
              this.loanCriteria.DI_PurchaseAgentFees = 0;
            }
            if (!this.loanCriteria.DI_PurchaseLegalFees) {
              this.loanCriteria.DI_PurchaseLegalFees = 0;
            }
            if (!this.loanCriteria.DI_PurchaseOtherFees) {
              this.loanCriteria.DI_PurchaseOtherFees = 0;
            }
            this.recalcContingencyTotal();
          }
        }
      })
      .catch((error) => {
        this.$location.path("/dashboard");
      })
      .finally(() => {
        this.pageLoading = false;
      });

    if (this.$routeParams.StepNumber) {
      this.step =
        this.$routeParams.StepNumber > 4 || this.$routeParams.StepNumber < 1
          ? 1
          : this.$routeParams.StepNumber;
    } else {
      if (this.isLoggedInUser) {
        this.go(
          "/criteriaappraisal/" +
            this.caseid +
            "/" +
            this.searchid +
            "/" +
            this.step,
        );
      }
    }

    this.updateGuidanceState();

    $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
      this.updateGuidanceState();
    });
  }

  updateVatOnLandPurchase(value: number): void {
    if (value === 1) {
      //if yes, show vat amount question prepopulated with 20% of purchase price
      this.loanCriteria.LandPurchaseVatAmount =
        this.loanCriteria.CI_Dev_OrigPP * 0.2;
    }
    this.loanCriteria.PayingVATOnLandPurchase = value;
    this.CalcTotalPurchaseCostsOwning();
    this.CalcTotalPurchaseCostsOwning();
  }

  indexPopup(methodName: string) {
    this.$rootScope.$broadcast(methodName);
  }

  updateGuidanceState() {
    this.guidanceCheckbox =
      this.$cookies.get("guidance") === "on" ||
      this.$cookies.get("guidance") === undefined;
  }

  getGuidanceSwitchState() {
    if (!this.$cookies.get("guidance")) {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "on") {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "off") {
      this.guidanceCheckbox = false;
    } else {
      this.guidanceCheckbox = true;
    }
    return this.guidanceCheckbox;
  }

  recordGuidanceCookie() {
    var guidanceSwitchState: string;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    if (this.guidanceCheckbox == true) {
      guidanceSwitchState = "on";
    } else {
      guidanceSwitchState = "off";
    }
    this.$cookies.put("guidance", guidanceSwitchState, { expires: expiryDate });
    this.$rootScope.$broadcast("guidanceStateChanged");
  }

  setAllFormsPristine(): void {
    if (this.criteriaPropertyForm) {
      this.criteriaPropertyForm.$setPristine();
    }
    if (this.criteriaBorrowingForm) {
      this.criteriaBorrowingForm.$setPristine();
    }
    if (this.criteriaCostsForm) {
      this.criteriaCostsForm.$setPristine();
    }
    if (this.criteriaSalesForm) {
      this.criteriaSalesForm.$setPristine();
    }
  }

  back(): void {
    this.save().then(() => {
      if (this.step > 0) {
        this.step--;
      }

      this.go(
        "/criteriaappraisal/" +
          this.caseid +
          "/" +
          this.searchid +
          "/" +
          this.step,
      );
    });
  }

  next(): void {
    this.save().then(() => {
      this.goToNextStep();
    });
  }

  /** Go to the next step */
  goToNextStep() {
    if (this.step == 6) {
      // Store the search on rootScope.
      //(this.$rootScope as any).loanCriteria = this.loanCriteria;
      //this.$location.path("/results");
    }
    this.step++;
    this.go(
      "/criteriaappraisal/" +
        this.caseid +
        "/" +
        this.searchid +
        "/" +
        this.step,
    );
  }

  goToCaseDashboard(save: boolean = false): void {
    if (!this.roleService.IsLender) {
      this.save();
      (this.$rootScope as any).formSaved = true;
    }

    this.$location.path("/casedashboard/" + this.caseid);
  }
  go(path): void {
    if (!this.roleService.IsLender) {
      this.save().then((response) => {
        this.$location.path(path);
      });
    } else {
      this.$location.path(path);
    }
  }

  setDate(dateToUpdate: Date, month: number, year: number) {
    // Two digit years!
    var fullYear: number;
    var today = new Date();
    if (today.getFullYear() < 2000 + year) {
      // If it's after this year, we probably mean 19xx.
      fullYear = 1900 + year;
    } else {
      // Otherwise it's probably in the 2000s.
      fullYear = 2000 + year;
    }
    dateToUpdate.setUTCFullYear(fullYear);
    // Note: Months are 0 based.
    dateToUpdate.setUTCMonth(month - 1);
  }

  calcContingencyCost(): number {
    if (this.pageLoading === false) {
      var res: number = 0;
      if (this.contingencyTotal == 0) {
        if (
          this.loanCriteria.CI_Dev_Contingency &&
          this.loanCriteria.CI_Dev_BuildCosts
        ) {
          res =
            this.loanCriteria.CI_Dev_Contingency *
            this.loanCriteria.CI_Dev_BuildCosts;
          this.contingencyTotal = res;
          return res;
        }
      }

      return this.contingencyTotal;
    }
  }

  recalcContingencyTotal(): void {
    let res: number = 0;
    //Case 1 - recalc Build costs
    if (
      this.loanCriteria.CI_Dev_Contingency &&
      this.loanCriteria.CI_Dev_BuildCosts
    ) {
      res =
        this.loanCriteria.CI_Dev_Contingency *
        this.loanCriteria.CI_Dev_BuildCosts;
      this.contingencyTotal = res;
    }
  }

  CalcTotalPurchaseCostsNotOwning(): number {
    if (this.pageLoading === false) {
      var res: number = 0;
      if (this.loanCriteria.CI_Dev_OrigPP) {
        res += Number(this.loanCriteria.CI_Dev_OrigPP);
      }
      if (this.loanCriteria.CI_Dev_SDLT) {
        res += Number(this.loanCriteria.CI_Dev_SDLT);
      }
      //if (this.loanCriteria.CI_Land_Total_other_Costs) {
      //    res += Number(this.loanCriteria.CI_Land_Total_other_Costs)
      //}
      if (this.loanCriteria.DI_PurchaseAgentFees) {
        res += Number(this.loanCriteria.DI_PurchaseAgentFees);
      }
      if (this.loanCriteria.DI_PurchaseLegalFees) {
        res += Number(this.loanCriteria.DI_PurchaseLegalFees);
      }
      if (this.loanCriteria.DI_PurchaseOtherFees) {
        res += Number(this.loanCriteria.DI_PurchaseOtherFees);
      }
      if (this.loanCriteria.PayingVATOnLandPurchase === 1) {
        res += Number(this.loanCriteria.LandPurchaseVatAmount);
      }
      //this.loanCriteria.Dev_TotLndCostAdj = res;
      this.totalPurchaseCostsNotOwning = res;
      return res;
    }
  }

  CalcTotalPurchaseCostsOwning(): number {
    if (this.pageLoading === false) {
      var res: number = 0;
      if (this.loanCriteria.CI_Dev_OrigPP) {
        res += Number(this.loanCriteria.CI_Dev_OrigPP);
      }
      if (this.loanCriteria.CI_Dev_SDLT) {
        res += Number(this.loanCriteria.CI_Dev_SDLT);
      }
      if (this.loanCriteria.DI_PurchaseAgentFees) {
        res += Number(this.loanCriteria.DI_PurchaseAgentFees);
      }
      if (this.loanCriteria.DI_PurchaseLegalFees) {
        res += Number(this.loanCriteria.DI_PurchaseLegalFees);
      }
      if (this.loanCriteria.DI_PurchaseOtherFees) {
        res += Number(this.loanCriteria.DI_PurchaseOtherFees);
      }
      if (this.loanCriteria.PayingVATOnLandPurchase === 1) {
        res += Number(this.loanCriteria.LandPurchaseVatAmount);
      }
      //this.loanCriteria.Dev_TotLndCostAdj = res;
      this.totalPurchaseCostsOwning = res;
      return res;
    }
  }
  CalcTotalBuild(): number {
    if (this.pageLoading === false) {
      var res: number = this.CalcAdditionalBuild();
      if (this.loanCriteria.CI_Dev_BuildCosts) {
        res += Number(this.loanCriteria.CI_Dev_BuildCosts);
      }
      if (this.contingencyTotal) {
        res += Number(this.contingencyTotal);
      }
      //this.loanCriteria.Dev_TotBuildCostsAdj = res;
      //this.loanCriteria.CI_Dev_BuildCosts = res;
      this.totalBuild = res;
      return res;
    }
  }
  CalcTotalSales(): number {
    if (this.pageLoading === false) {
      var res: number = 0;
      if (this.loanCriteria.DI_Sales_Dressing) {
        res += Number(this.loanCriteria.DI_Sales_Dressing);
      }
      if (this.loanCriteria.DC_SalesAgentFees) {
        res += Number(this.loanCriteria.DC_SalesAgentFees);
      }
      if (this.loanCriteria.DC_SalesLegalFeesPercent) {
        res += Number(this.loanCriteria.DC_SalesLegalFeesPercent);
      }
      this.totalSales = res;
      return res;
    }
  }

  CalcNDV(): number {
    if (this.pageLoading === false) {
      var gdv: number = 0;
      var sales: number = 0;
      if (
        this.loanCriteria.CI_UseGDVCalculated === false &&
        this.loanCriteria.CI_GDV
      ) {
        gdv = Number(this.loanCriteria.CI_GDV);
      }
      if (
        this.loanCriteria.CI_UseGDVCalculated === true &&
        this.loanCriteria.CI_GDVCalculated
      ) {
        gdv = Number(this.loanCriteria.CI_GDVCalculated);
      }
      if (this.totalSales) {
        sales = Number(this.totalSales);
      }
      var ndv: number = Number(gdv - sales);
      if (ndv <= 0) {
        ndv = Number(0);
      }
      this.totalNDV = ndv;
      return ndv;
    }
  }

  CalcAdditionalBuild(): number {
    if (this.pageLoading === false) {
      var res: number = 0;
      if (this.loanCriteria.DI_BreakDownBuildCosts) {
        if (this.loanCriteria.CI_Dev_ProfPlanning) {
          res += Number(this.loanCriteria.CI_Dev_ProfPlanning);
        }
        if (this.loanCriteria.CI_Dev_ProfQS) {
          res += Number(this.loanCriteria.CI_Dev_ProfQS);
        }
        if (this.loanCriteria.DI_BuildProjectManag) {
          res += Number(this.loanCriteria.DI_BuildProjectManag);
        }
        if (this.loanCriteria.CI_Dev_S106CIL) {
          res += Number(this.loanCriteria.CI_Dev_S106CIL);
        }
        if (this.loanCriteria.DI_BuildMechEng) {
          res += Number(this.loanCriteria.DI_BuildMechEng);
        }
        if (this.loanCriteria.DI_BuildStrucEng) {
          res += Number(this.loanCriteria.DI_BuildStrucEng);
        }
        if (this.loanCriteria.DI_BuildPartyWall) {
          res += Number(this.loanCriteria.DI_BuildPartyWall);
        }
        if (this.loanCriteria.DI_BuildLandscaping) {
          res += Number(this.loanCriteria.DI_BuildLandscaping);
        }
        if (this.loanCriteria.DI_BuildWarranty) {
          res += Number(this.loanCriteria.DI_BuildWarranty);
        }
        if (this.loanCriteria.DI_BuildDemolition) {
          res += Number(this.loanCriteria.DI_BuildDemolition);
        }
        if (this.loanCriteria.DI_BuildOtherCosts) {
          res += Number(this.loanCriteria.DI_BuildOtherCosts);
        }
      } else {
        if (this.loanCriteria.CI_Dev_AdditionalOngoingCosts) {
          res += Number(this.loanCriteria.CI_Dev_AdditionalOngoingCosts);
        }
      }
      this.additionalBuild = res;
      return res;
    }
  }
  save(): ng.IPromise<number> {
    //this.loanCriteria.SaveQueryAndResults = true;
    let defer = this.$q.defer<number>();
    if (!this.loanCriteria) {
      return defer.promise;
    }
    this.$DevelopmentInputservice
      .addUpdatereturnonlyid(this.loanCriteria)
      .then((response) => {
        this.$lenderresultservice
          .fetchMatchingResults(
            this.loanCriteria,
            true,
            true,
            20,
            false,
            "",
            "",
            "",
            this.loanCriteria.SearchName,
          )
          .then((results) => {
            this.setAllFormsPristine();
          })
          .catch((error) => {})
          .finally(() => {});
        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  formChanged(): void {
    (this.$rootScope as any).formSaved = false;
  }

  getAddressList(app, searchTerm: string) {
    let foundMatch = false;

    if (
      this.PostCodeOptions &&
      this.PostCodeOptions &&
      this.PostCodeOptions.length > 0
    ) {
      let addressLookup = this.PostCodeOptions.find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.$streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            app.DI_StreetAddress = address;
            this.searchterm = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        this.loanCriteria.DI_StreetAddress.AddressLine1 =
          addressLookup.AddressLine2;
        this.loanCriteria.DI_StreetAddress.AddressLine2 =
          addressLookup.AddressLine3;
        this.loanCriteria.DI_StreetAddress.AddressLine3 =
          addressLookup.AddressLine4;
        this.loanCriteria.DI_StreetAddress.AddressLine4 =
          addressLookup.PostCode;
        this.loanCriteria.DI_StreetAddress.PostCode =
          addressLookup.AddressLine1;
        this.searchterm = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.PostCodeOptions = [];

      this.$streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.PostCodeOptions = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }
  sum(a: number, b: number): number {
    return a + b;
  }

  //File upload

  onFileSelect(files: FileAttachmentDTO[], module: ModuleEnum) {
    if (files.length > 0) {
      //if case has no id it must be saved first
      if (this.case.Id < 1) {
        this.$caseService
          .addUpdate(this.case)
          .then((response) => {
            this.case = response;
            this.onFileSelect(files, module); //call itself and then exit as should now be saved so should be able to run
            return;
          })
          .finally(() => {
            this.dataLoading = false;
          });
        return;
      }
      this.fileAttachmentService
        .UploadFileLstInSync(
          files,
          this.uploadingFiles,
          this.case.Id,
          this.fileUpload,
          module,
        )
        .then((result) => {
          this.openModal = false;
        });
    }
  }

  downloadFile(file: FileAttachmentDTO) {
    this.fileAttachmentService
      .getFileUri(file.FileLocation)
      .then((uri) => {
        this.$window.open(uri);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  deleteFile(file: FileAttachmentDTO) {
    this.fileUpload.splice(this.fileUpload.indexOf(file), 1);
    this.fileAttachmentService.markasdeleted(file.Id).then((result) => {});
  }

  calculateTotalFiles(filter: number) {
    if (this.fileUpload) {
      this.total = this.fileUpload.filter(
        (item) => item.Module === filter,
      ).length;
    }
    return this.total;
  }

  viewResults(loanCriteria: DevelopmentInputDTO) {
    if (!this.roleService.IsLender) {
      this.save();
      (this.$rootScope as any).formSaved = true;
    }
    this.$location.path(
      "/results/" + this.case.DevelopmentInputID + "/" + this.case.Id,
    );
  }
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { LocationEnum } from "@js/models/enum/LocationEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import _ from "lodash";
import { OrganisationService } from "../OrganisationService";
import { ProductService } from "../ProductService";

export class SharedCriteriaService {
  dataLoading: boolean;

  
  static $inject = ["$q", "ProductService", "OrganisationService"];
  
    constructor(
    private $q: ng.IQService,
    private productService: ProductService,
    private organisationService: OrganisationService,
  ) {}

  /**
   * Adding a selected existing user from sharemodel dropdown to shareDealDto
   * @param existingUsers
   * @param shareDealDto
   * @param userName
   * @returns updated shareDealDto
   */
  attachSelectedClientToShareDealDto(
    existingUsers: UserSimpleDTO[],
    shareDealDto: ShareDealDTO,
    userName: string,
  ): ShareDealDTO {
    var users = existingUsers.filter(
      (x) => x.Email && x.Email.indexOf(userName) !== -1,
    );

    shareDealDto.IsApplicant = true;
    shareDealDto.IsPrimary = true;

    shareDealDto.ClientDto = {
      FirstName: users[0].FirstName,
      LastName: users[0].LastName,
      Email: users[0].Email,
      PhoneNumber: users[0].PhoneNumber,
      ClientUserId: users[0].Id,
    } as ClientDTO;

    return shareDealDto;
  }

  /**
   * Sets a clientDto value to null
   * @param shareDealDto
   * @returns updated shareDealDto
   */
  clearExistingUserOnShareDealDto(shareDealDto): ShareDealDTO {
    if (shareDealDto.ClientDto) {
      shareDealDto.ClientDto.FirstName = null;
      shareDealDto.ClientDto.LastName = null;
      shareDealDto.ClientDto.Email = null;
      shareDealDto.ClientDto.PhoneNumber = null;
    }

    return shareDealDto;
  }

  /**
   * Returns a default search name depending on product type
   * @param shareDealDto
   * @returns default search name
   */

  defaultSearchName(
    productType: ProductTypeEnum,
    isRegulatedBridge: boolean = false,
  ) {
    var currentdate = new Date();
    var productTypeDisplayText = "";

    if (isRegulatedBridge) {
      productTypeDisplayText = "Regulated Bridge";
    } else {
      productTypeDisplayText =
        this.productService.getProductTypeDisplayText(productType);
    }

    return (
      "My " +
      productTypeDisplayText +
      " Loan Search " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear()
    );
  }

  setNewSearch(dealDto, snapshotDealDto): void {
    var criteriaChanged = true;
    //Store that this is a new search
    if (sessionStorage.getItem("skip") == "true") {
      criteriaChanged = !_.isEqual(dealDto, snapshotDealDto);
    }

    if (window.self == window.top) {
      sessionStorage.setItem("newSearch", criteriaChanged.toString());
    } else {
      this.organisationService.sendDataToParent(
        "newSearch",
        criteriaChanged.toString(),
      );
    }
  }

  setClientDtoDataForEnterpriseSearch(
    user: ApplicationUserDTO,
    brokerageOrgId: number,
    clientDto: ClientDTO,
  ): ClientDTO {
    let userFullName = user.FullName;
    let spaceIndex = userFullName.indexOf(" ");

    let firstName = "";
    let lastName = "";

    // if not space has been added to the name then put the whole name in the first name field
    if (spaceIndex == -1) {
      firstName = userFullName;
    } else {
      firstName = userFullName.substring(0, userFullName.indexOf(" "));
      lastName = userFullName.substring(userFullName.indexOf(" ") + 1);
    }

    clientDto = {
      FirstName: firstName,
      LastName: lastName,
      Email: user.Email,
      PhoneNumber: user.PhoneNumber,
      BrokerOrganisationId: brokerageOrgId,
      PostalAddress: {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress,
    } as ClientDTO;

    return clientDto;
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getRandomPostcode() {
    //TODO This is code that was conflicting during the merge between BorrowerNotifications and DevFinDeal. Leaving here in case it shoudl be re-instated:
    //const postcodes = [
    //    "SW1A 1AA", // Buckingham Palace
    //    "SW1A 2AA", // Downing Street
    //    "E1 6AN",   // Near Shoreditch, London
    //    "B1 1TB",   // Birmingham City Centre
    //    "G1 1QX",   // Glasgow City Centre
    //    "L1 8JQ",   // Liverpool City Centre
    //    "M1 1AG",   // Manchester City Centre
    //    "S1 2BJ",   // Sheffield City Centre
    //    "NE1 1EE",  // Newcastle City Centre
    //    "CF10 1EP"  // Cardiff City Centre
    //];

    //const randomIndex = Math.floor(Math.random() * postcodes.length);
    //return postcodes[randomIndex];

    var postCodeList = [
      "SW1",
      "GL1",
      "BS32",
      "DH2 1JR",
      "SR2 9DJ",
      "LL30 2AS",
      "PO32 6BT",
      "M27 5TR",
      "EH15 3PQ",
      "WD3 8QB",
    ];

    return postCodeList[this.getRandomIntInclusive(0, 9)];
  }

  getRegionByPostcode(postcode) {
    var region = {
      SW1: LocationEnum.London,
      GL1: LocationEnum.SouthWest,
      BS32: LocationEnum.SouthWest,
      "DH2 1JR": LocationEnum.Wales,
      "SR2 9DJ": LocationEnum.SouthWest,
      "LL30 2AS": LocationEnum.Wales,
      "PO32 6BT": LocationEnum.SouthEast,
      "M27 5TR": LocationEnum.NorthWest,
      "EH15 3PQ": LocationEnum.Scotland,
      "WD3 8QB": LocationEnum.EastofEngland,
    };

    return region[postcode];
  }

  storeEnterpriseLinkType(linkType: string){
    if (window.self == window.top) {
      sessionStorage.setItem(
        "enterpriseLinkType",
        linkType
      );
    } else {
      this.organisationService.sendDataToParent(
        "enterpriseLinkType",linkType
      );
    }
  }

  getIsDataLoading() {
    return this.dataLoading;
  }

  setIsDataLoading(dataLoading) {
    this.dataLoading = dataLoading;
  }

 }

// AngularJS service registration

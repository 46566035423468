import { CriteriaController } from "@js/controllers/CriteriaController";
import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { BridgingDealDTO } from "@js/DTO/Deal/BridgingDealDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import { SaveBridgingSearchRequest } from "@js/DTO/Messages/Deal/SaveBridgingSearchMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { OrganisationLinkDTO } from "@js/DTO/OrgnisationLinkDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { DealSourceTypeEnum } from "@js/models/enum/DealSourceTypeEnum.cs.d";
import { LandOrPropertyEnum } from "@js/models/enum/LandOrPropertyEnum.cs.d";
import { LinkTypeEnum } from "@js/models/enum/LinkTypeEnum.cs.d";
import { LoanCompletionTypeEnum } from "@js/models/enum/LoanCompletionTypeEnum.cs.d";
import { LocationEnum } from "@js/models/enum/LocationEnum.cs.d";
import { OriginatorRoleTypeEnum } from "@js/models/enum/OriginatorRoleTypeEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { PlanningPermissionTypeEnum } from "@js/models/enum/PlanningPermissionTypeEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { RefurbishmentLevelEnum } from "@js/models/enum/RefurbishmentLevelEnum.cs.d";
import { YesNoMaybe } from "@js/models/enum/YesNoMaybeEnum.cs.d";
import { SaveSearchReturnUniqueRefResponse } from "@js/models/SaveSearchReturnUniqueRef.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { ClientService } from "@js/services/ClientService";
import { BridgingDealService } from "@js/services/Deal/BridgingDealService";
import { DealService } from "@js/services/Deal/DealService";
import { SharedCriteriaService } from "@js/services/Deal/SharedCriteriaService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { EventLogService } from "@js/services/EventLogService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationLinkService } from "@js/services/OrganisationLinkService";
import { OrganisationService } from "@js/services/OrganisationService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";
import { EnterpriseService } from "@js/services/EnterpriseService";
import { UserRoleEnum } from "@js/models/enum/UserRoleEnum.cs.d";

export class BridgingCriteriaController extends CriteriaController<BridgingDealDTO> {
  isSaving: boolean = false;
  hasError: boolean = false;

  productType: ProductTypeEnum;

  dealDto: BridgingDealDTO;
  tempDealDto: BridgingDealDTO;
  snapshotDealDto: BridgingDealDTO;
  classUses: {} = {};
  yesNoOptions = [];
  siteTypeOptions = [];
  propertyTypeOptions = [];
  showResidual: boolean = false;
  showSummary: boolean = false;
  showKeyMatrix: boolean = false;
  isLoggedInUser: boolean = false;
  isSearchStarted: boolean = false;
  totalLenders: number;
  buildingConditionOptions = [];
  buildStageOptions = [];
  locationOptions = [];
  loanCompletionTypeOptions = [];
  refurbishmentLevelOptions = [];
  planningOptions = [];
  multiPartForm1: ng.IFormController;
  multiPartForm2: ng.IFormController;
  multiPartForm3: ng.IFormController;
  propertyForm: ng.IFormController;

  isBorrower: boolean = false;
  organisation: OrganisationDTO;
  moveContactBrokerBtnInMiddle: boolean = false;
  user: ApplicationUserDTO = null;
  initialRegistrationClient: ClientDTO;
  orgCode: string;

  //Share a search modal
  showShareSearchtoClientModal: boolean = false;
  isResultScreen: boolean = false;
  existingUsers: UserSimpleDTO[];
  existingborrower: boolean;
  shareDealDto: ShareDealDTO = {
    IsApplicant: true,
    IsPrimary: true,
  } as ShareDealDTO;
  showClientDetails: boolean = false;
  profitBeforeCosts: number = 0;
  ROCE: number = 0;
  purchasePrice: number = 0;
  purchaseCosts: number = 0;
  GDV: number = 0;
  isSubmitted: boolean = false;
  showAutofill: boolean = false;
  error: string;

  //share search modal
  isBridging: boolean = true;

  guidanceCheckbox: boolean = true;
  maxPurchaseDate: Date = new Date();
  minPurchaseDate: Date = new Date("Jan 01 1900");
  minCompletionDate: Date;
  hasPurchasecostChanged: boolean = false;
  hasLandcostChanged: boolean = false;
  userRole: string = "";
  userDetails: string = null;
  projectName: string = null;
  clientId: string = null;
  //dataLoading: boolean = false;

  isFamilyInResidence: boolean = false;
  ownOrPurchase: boolean = false;
  landOrProperty: boolean = false;

  headingAdjustment: string;

  //Enterprise
  organisationLink: OrganisationLinkDTO;
  noPermissionErrorMsg: string = "";

  contingencyAmount: number = null;

  //Postcode properties
  //showPostcodeErrorMessage: boolean = false;
  // postcodeErrorMsg: string;
  //previouslySelectedLocation: LocationEnum;

  isAdmin: boolean = false;
  isBroker: boolean = false;
  isLender: boolean = false;

  isUniqueLink: boolean = false;

  //enterprise widget journey
  isMobileView: boolean = false;
  showHelpModal: boolean = false;
  loanInfoText: string;
  progressAmount: string = "50%";
  progressSection: number = 1;
  totalSections: number = 2;
  enterpriseLinkLogo: string;
  isWidget: boolean = false;

  //This propert is used to show only postcode ot location field on critera for mobile view
  isCriteria: boolean = true;

  static $inject = [
    "$scope",
    "$rootScope",
    "$routeParams",
    "$cookies",
    "DevelopmentInputService",
    "$location",
    "$q",
    "CaseService",
    "UserService",
    "RoleService",
    "OrganisationService",
    "SelectListService",
    "LenderService",
    "BridgingDealService",
    "ClientService",
    "ProductService",
    "AuthService",
    "OrganisationLinkService",
    "EventLogService",
    "SharedCriteriaService",
    "DealService",
    "EnterpriseService"
  ].concat(CriteriaController.$inject);

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private $location: ng.ILocationService,
    protected $q: ng.IQService,
    private caseService: CaseService,
    private userService: UserService,
    private roleService: RoleService,
    private organisationService: OrganisationService,
    private selectListService: SelectListService,
    private lenderService: LenderService,
    private bridgingDealService: BridgingDealService,
    private clientService: ClientService,
    private productService: ProductService,
    private $auth: AuthService,
    private organisationLinkService: OrganisationLinkService,
    private eventLogService: EventLogService,
    private sharedCriteriaService: SharedCriteriaService,
    dealService: DealService,
    private enterpriseService: EnterpriseService
  ) {
    super(dealService);
    try {

      if (this.$routeParams.context == 'widget' && window.self != window.top) {
        this.isWidget = true;
      } else {
        if (window.innerWidth <= 480)
          this.isMobileView = true
      }


      if (this.dealDto == null) {
        this.dealDto = {} as BridgingDealDTO;
      }

      //Enteprise journey code
      if (!$cookies.get("access_token") &&
        (this.$location.path().startsWith("/e/bridgingcriteria") ||
          this.$location.path().startsWith("/allloans"))
      ) {
        this.isBorrower = true;

        if (this.$location.path().startsWith("/e/bridgingcriteria")) {
          var imgs = document.getElementsByTagName("img");
          this.enterpriseLinkLogo = imgs[0].src;
          this.sharedCriteriaService.storeEnterpriseLinkType(LinkTypeEnum.Bridging.toString());
        }

        if (this.isWidget) {
         // document.getElementById("footer").style.display = "none";
          this.organisationService.sendDataToParent("height", "745px");
        }

        if (!this.$routeParams.DealUniqueRef) {
          if (window.self == window.top) {
            this.orgCode = this.$cookies.get("org_code");
            sessionStorage.setItem("userRole", UserRoleEnum.Client.toString());
          } else {
            this.orgCode = location.pathname.replace("/", "");
            this.organisationService.sendDataToParent("userRole", UserRoleEnum.Client.toString());
          }

          this.logEventWithoutDealData();

        }


        this.organisationService
          .getOrgByUrlIfOrgHasEnterpriseSearchPermission(
            this.orgCode,
            ProductFamilyEnum.Bridging,
            this.$routeParams.linkId,
          )
          .then((response) => {
            if (response) {
              this.organisation = response;
              this.orgCode = response.OrganisationCode;

              if (this.$routeParams.linkId) {
                if (this.$location.path().startsWith("/e/bridgingcriteria")) {
                  this.organisationLinkService
                    .getOrganisationLinkLogoByLinkIdAndLogPageLoadEvent(
                      this.$routeParams.linkId
                    )
                    .then((logoUrl) => {
                      if (logoUrl) this.enterpriseLinkLogo = imgs[0].src = logoUrl;
                    });
                }
              }

            } else {
              this.logEvent("BRIDGINGCRITERIA-NO-PERMISSION-TO-SEARCH");
              this.noPermissionErrorMsg =
                "We are unable to compare loans right now.  Please contact your broker.";
            }
          })
          .finally(() => { });
      }

      if (window.self == window.top && $cookies.get("access_token")) {
        this.isLoggedInUser = true;

        this.userService.getUserAutofill().then((response) => {
          this.showAutofill = response;
        });

        this.roleService.GetUserRoles().then((result) => {
          if (result.filter((x) => x.toLowerCase() == "admin").length > 0) {
            this.isAdmin = true;
          }
          if (result.filter((x) => x.toLowerCase() == "broker").length > 0) {
            this.isBroker = true;
            //This block is to navigate a broker to userdashboard when they do not have a license.
            if (this.isBroker) {
              if (sessionStorage.getItem("isValidLicense") == "false") {
                this.gotoDashboard();
              }
            }
          }
          if (
            result.filter((x) => x.toLowerCase() == "lender").length > 0 &&
            !this.isAdmin
          ) {
            this.isLender = true;
          }

          if (
            result.filter((x) => x.toLowerCase() == "client").length > 0 &&
            !this.isAdmin
          ) {
            this.isBorrower = true;
          }
        }).finally(() => {
          if (this.$auth.getHasValidStatustoShowShareSearchModal()) {
            if (sessionStorage.getItem("skip")) {
              //This will hide's a share search to client modal
              this.showShareSearchtoClientModal = false;
            } else {
              //getting a previously entered DealName and Client data 
              if (this.dealService.getShowSearchNameAndUserSelectionModal()) {
                this.showShareSearchtoClientModal = true;
                this.getUsersBelongToBrokerOrAdmin();
              } else {
                this.shareDealDto.ClientDto = this.dealService.getClient();
                this.shareDealDto.DealName = this.dealService.getDealName();
              }
            }
          }
          this.getOriginatorInfoForUser();
        });
      } else {
        if (!this.$routeParams.DealUniqueRef) {
          if (window.self == window.top) {
            this.clientId = sessionStorage.getItem("clientId");
          } else {
            Promise.all([
              this.organisationService.getData("clientId").then((clientId) => {
                if (clientId) {
                  this.clientId = clientId;
                }
              }),
            ]);
          }
        }
      }

      if (this.$routeParams.DealUniqueRef && this.$routeParams.DealUniqueRef != '0') {
        this.dataLoading = true;
        this.dealService
          .fetchByUniqueRef<BridgingDealDTO>(this.$routeParams.DealUniqueRef)
          .then((response) => {
            this.postRetrieveProcessing(response);
          })
          .finally(() => {
            this.dataLoading = false;
          });
      }
      else if (this.$routeParams.SearchId > 0) {
        this.dataLoading = true;
        this.bridgingDealService
          .fetch(this.$routeParams.SearchId)
          .then((response) => {
            this.postRetrieveProcessing(response);
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.bridgingTypeChanged(ProductTypeEnum.BridgingPurchaseOrRefinance);
      }

      // if (window.self == window.top) {
      //   this.updateGuidanceState();

      //   $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
      //     this.updateGuidanceState();
      //   });
      // }

      this.caseService.updateTubeMap(CaseStatusEnum.Search);
      this.buildStageOptions = this.selectListService.GetBuildStageOptions();
      this.locationOptions = this.selectListService.GetLocations();
      this.loanCompletionTypeOptions = this.selectListService.GetLoanCompletionOptions();
      this.refurbishmentLevelOptions = this.selectListService.GetRefurbishmentLevelOptions();
      this.planningOptions = this.selectListService.GetPlanningPermissionTypesOfBridging();
    } catch (e) {
      console.error("Error: ", e);
    }
  }

  getRequiredRoles(isCaseDashboard: boolean) {
    if (isCaseDashboard) return `['Admin', 'Client', 'Broker', 'Introducer']`;
    return `['Admin', 'Client', 'Broker', 'Introducer', 'Lender']`;
  }

  getBuildCosts() {
    var buildCosts =
      +this.dealDto.EstimatedSpending + +this.dealDto.PurchasePrice;
    if (isNaN(buildCosts) || buildCosts == undefined) {
      buildCosts = 0;
    }
    return buildCosts;
  }

  getProfitBeforeCosts() {
    this.profitBeforeCosts =
      this.dealDto.GDV -
      this.dealDto.PurchasePrice -
      this.dealDto.EstimatedSpending -
      this.dealDto.PurchaseCosts;
    if (isNaN(this.profitBeforeCosts)) {
      this.profitBeforeCosts = 0;
    }
    return this.profitBeforeCosts;
  }

  /* // updateGuidanceState() {
   //   this.guidanceCheckbox =
   //     this.$cookies.get("guidance") === "on" ||
   //     this.$cookies.get("guidance") === undefined;
   //   // Update tour settings
   // }
 
   // getGuidanceSwitchState() {
   //   if (!this.$cookies.get("guidance")) {
   //     this.guidanceCheckbox = true;
   //   } else if (this.$cookies.get("guidance") === "on") {
   //     this.guidanceCheckbox = true;
   //   } else if (this.$cookies.get("guidance") === "off") {
   //     this.guidanceCheckbox = false;
   //   } else {
   //     this.guidanceCheckbox = true;
   //   }
   //   return this.guidanceCheckbox;
   // }
 
   // recordGuidanceCookie() {
   //   var guidanceSwitchState: string;
   //   var expiryDate = new Date();
   //   expiryDate.setDate(expiryDate.getDate() + 365);
   //   if (this.guidanceCheckbox == true) {
   //     guidanceSwitchState = "on";
   //   } else {
   //     guidanceSwitchState = "off";
   //   }
   //   this.$cookies.put("guidance", guidanceSwitchState, { expires: expiryDate });
   //   this.$rootScope.$broadcast("guidanceStateChanged");
   // }
 
   //getROCE() {
   //    this.ROCE = (this.dealDto.GDV / this.totalCosts) * 100;
   //    if (isNaN(this.ROCE)) {
   //        this.ROCE = 0;
   //    }
   //    return this.ROCE.toFixed(2);
   //}
 
   //getPurchasePrice() {
   //    this.purchasePrice = this.dealDto.PurchasePrice;
   //    if (isNaN(this.purchasePrice)) {
   //        this.purchasePrice = 0;
   //    }
   //    return this.purchasePrice;
   //}
 
   //getPurchaseCosts() {
   //    this.purchaseCosts = this.dealDto.PurchaseCosts;
   //    if (isNaN(this.purchaseCosts)) {
   //        this.purchaseCosts = 0;
   //    }
   //    return this.purchaseCosts;
   //}
 
   //getGDV() {
   //    if (isNaN(this.dealDto.GDV) || this.dealDto.GDV == undefined) {
   //        this.dealDto.GDV = 0;
   //    } else {
   //        this.dealDto.GDV = this.dealDto.GDV;
   //    }
   //    return this.dealDto.GDV;
   //}
 */
  goToSearchResults() {
    this.hasError = false;
    this.error = null;
    this.isSubmitted = true;
    this.enterpriseService.setProductFamily(this.dealDto.ProductFamily);
    // If enterprise
    this.isSearchStarted = true;
    if (
      !this.isLoggedInUser &&
      this.isBorrower &&
      (this.$location.path().startsWith("/e/bridgingcriteria") ||
        this.$location.path().startsWith("/allloans"))
    ) {
      this.saveCriteriaReturnUniqueRef().then((response) => {
        if (!this.hasError) {
          this.sharedCriteriaService.setIsDataLoading(false);
          var response = response as SaveSearchReturnUniqueRefResponse;
          if (window.self == window.top)
            sessionStorage.removeItem("productType");

          if (response.HasDealClient) {
            //checking route is to handle navigating to correct route when user lands on criteria on click of change search form side panel.
            if (sessionStorage.getItem('previousRoute') == 'referredsearchdeal') {
              this.$location.path(`/referredsearchdeal/${response.DealClientUniqueRef}/false`);
              sessionStorage.removeItem('previousRoute');
            } else {
              this.$location.path(`/e/bridgingresults/${response.DealUniqueRef}`);
            }
          } else {
            var leadCaptureUrl = `/e/enterpriseleadcapture/${response.DealUniqueRef}`;
            leadCaptureUrl = this.isWidget ? `${leadCaptureUrl}/widget` : leadCaptureUrl;

            this.$location.path(leadCaptureUrl);
          }
        }
      });
    } else {
      this.saveCriteriaReturnId().then((response) => {
        if (!this.hasError) {
          var dealId = response as number;
          if (window.self == window.top)
            sessionStorage.removeItem("productType");

          if (this.isAdmin || this.isBroker) this.dealService.setShowSearchNameAndUserSelectionModal(true);
          this.$location.path("/bridgingresults/" + dealId);
        }
      });
    }
  }

  getTubeMapValue() {
    return this.caseService.getTubeMap();
  }

  getNumberOfLenders() {
    if (this.dealDto && this.dealDto.ProductType) {
      //TODO Bridging JS: replacing ProductTypeEnum.BridgingPreconstruction with this.productType assuming that preconstruction products will not apply to all product types.
      this.lenderService
        .getTotalLenders(ProductFamilyEnum.Bridging)
        .then((response: number) => {
          this.totalLenders = Math.floor(response);
        });
    } else {
      //TODO what should it default to?
    }
  }

  go(path: string): void {
    this.$location.path(path);
  }

  saveCriteriaReturnId(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.dataSetUpForSave();

    var clientDto = null;

    var request: SaveBridgingSearchRequest = {
      DealDto: this.dealDto,
      ShareDealDto: this.shareDealDto,
      OrgCode: this.isLoggedInUser ? "" : this.orgCode,
      ClientId:
        !this.isLoggedInUser &&
          this.clientId &&
          !this.user &&
          (this.$location.path().startsWith("/e/bridgingcriteria") ||
            this.$location.path().startsWith("/allloans"))
          ? Number(this.clientId)
          : 0,
      EnterpriseClientDto: clientDto,
    };

    this.bridgingDealService
      .saveBridgingSearchReturnsId(request)
      .then((response) => {
        this.dealDto.Id = response;
        this.isSaving = false;

        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
        if (response.status === 500) {
          this.error = response.data.message;
        } else {
          this.error = 'Something went wrong while searching for loans. Please try again.';
        }
        this.hasError = true;
        this.isSearchStarted = false;
      });

    return defer.promise;
  }

  saveCriteriaReturnUniqueRef(): ng.IPromise<SaveSearchReturnUniqueRefResponse> {
    let defer = this.$q.defer<SaveSearchReturnUniqueRefResponse>();
    this.sharedCriteriaService.setIsDataLoading(true);
    this.dataSetUpForSave();

    var clientDto = null;

    var request: SaveBridgingSearchRequest = {
      DealDto: this.dealDto,
      ShareDealDto: this.shareDealDto,
      OrgCode: this.isLoggedInUser ? "" : this.orgCode,
      ClientId: !this.isWidget &&
        !this.isLoggedInUser &&
        this.clientId &&
        !this.user &&
        (this.$location.path().startsWith("/e/bridgingcriteria") ||
          this.$location.path().startsWith("/allloans"))
        ? Number(this.clientId)
        : 0,
      EnterpriseClientDto: clientDto,
    };

    this.bridgingDealService
      .saveBridgingSearchReturnsUniqueRef(request)
      .then((response) => {
        this.dealDto.UniqueRef = response.DealUniqueRef;
        this.isSaving = false;

        defer.resolve(response as SaveSearchReturnUniqueRefResponse);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
        this.sharedCriteriaService.setIsDataLoading(false);
        if (response.status === 500) {
          this.error = response.data.message;
        } else {
          this.error = 'Something went wrong while searching for loans. Please try again.';
        }
        this.hasError = true;
        this.isSearchStarted = false;
      });

    return defer.promise;
  }

  dataSetUpForSave() {
    this.sharedCriteriaService.setNewSearch(this.dealDto, this.snapshotDealDto);

    if (this.dealDto.Id == null) {
      this.dealDto.Status = CaseStatusEnum.Search;
    }

    if (
      this.dealDto.Name == null ||
      this.dealDto.Name == "" ||
      this.dealDto.Name == undefined
    ) {
      this.dealDto.Name = this.sharedCriteriaService.defaultSearchName(
        this.dealDto.ProductType,
        this.dealDto.HasIsFamilyInResidence &&
        (this.dealDto.ProductType ==
          ProductTypeEnum.BridgingPurchaseOrRefinance ||
          this.dealDto.ProductType == ProductTypeEnum.BridgingRefurb),
      );
    }

    if (
      !this.isLoggedInUser &&
      (this.$location.path().startsWith("/e/bridgingcriteria") ||
        this.$location.path().startsWith("/allloans")) &&
      this.isBorrower
    ) {
      this.dealDto.HowManyFlats = this.dealDto.HowManyFlats
        ? this.dealDto.HowManyFlats
        : 0;
      this.dealDto.HowManyHouses = this.dealDto.HowManyHouses
        ? this.dealDto.HowManyHouses
        : 0;
    }

    if (!this.shareDealDto) {
      this.shareDealDto = {
        DealName: this.sharedCriteriaService.defaultSearchName(
          this.dealDto.ProductType,
          this.dealDto.HasIsFamilyInResidence &&
          (this.dealDto.ProductType ==
            ProductTypeEnum.BridgingPurchaseOrRefinance ||
            this.dealDto.ProductType == ProductTypeEnum.BridgingRefurb),
        ),
      } as ShareDealDTO;
    }
  }

  getUsersBelongToBrokerOrAdmin() {
    this.userService
      .getUserSummariesForBrokerOrAdmin()
      .then((response) => {
        this.existingUsers = response;
      })
      .finally(() => { });
  }

  viewClientsDashboardForNewSearch(userName: string) {
    this.shareDealDto =
      this.sharedCriteriaService.attachSelectedClientToShareDealDto(
        this.existingUsers,
        this.shareDealDto,
        userName,
      );
    this.showClientDetails = true;
  }

  onClickOfNext() {
    this.showShareSearchtoClientModal = false;
    if (this.$location.path().startsWith("/allloans") && (this.isAdmin || this.isBroker)) {
      this.dealService.setShowSearchNameAndUserSelectionModal(false);
      this.dealService.setClient(this.shareDealDto.ClientDto);
      this.dealService.setDealName(this.shareDealDto.DealName);
    }
  }

  OnClickOfSkip() {
    this.showShareSearchtoClientModal = false;
    if (this.$location.path().startsWith("/allloans") && (this.isAdmin || this.isBroker)) {
      this.dealService.setShowSearchNameAndUserSelectionModal(false);
    }
  }

  gotoDashboard() {
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  goToUserDashboard() {
    this.gotoDashboard();
  }

  searchDisabledPrecon() {

    var commonFields = [
      this.dealDto.OwnOrPurchase,
      this.dealDto.Locations,
      this.dealDto.IsFamilyInResidence,
      this.dealDto.LandOrProperty,
    ];

    if (this.progressSection == 1) {
      this.progressAmount = "0%";
      if (this.dealDto.LandOrProperty == LandOrPropertyEnum.Property)
        commonFields = [...commonFields, this.dealDto.EndPropertyType];

      if (this.dealDto.hasPostcode && !this.dealDto.PostcodeSearchString) {
        return true;
      }

      if (!this.dealDto.hasPostcode && !this.dealDto.Locations) {
        return true;
      }

      if (this.multiPartForm1 && this.multiPartForm1.$invalid) return true;

      if (
        this.dealDto.IsFamilyInResidence == 0 ||
        this.dealDto.IsFamilyInResidence == YesNoMaybe.Yes
      )
        return true;

      if (
        this.dealDto.PlanningPermissionType == null ||
        this.dealDto.PlanningPermissionType < 1
      ) {
        return true;
      }

      for (let i = 0; i < commonFields.length; i++) {
        if (commonFields[i] == null || commonFields[i] == "0") {
          return true;
        }
      }

      this.progressAmount = "50%";

    }

    if (this.progressSection == 2) {

      this.progressAmount = "50%";

      if (!this.dealService.showBasedOnMainResidence(this.dealDto) || this.hasValidNetLoanRequiredValue()) {
        return true;
      }

      commonFields = [
        ...commonFields,
        this.dealDto.PurchasePrice,
        this.dealDto.LoanTermReq,
      ];


      if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own) {
        let commonOwnFields = [
          this.dealDto.OriginalPurchaseDate,
          this.dealDto.Currentvalue,
          this.dealDto.LandTotalOtherCosts
        ]
        for (let i = 0; i < commonOwnFields.length; i++) {
          if (commonOwnFields[i] == null) {
            return true;
          }
        }
        if (this.dealDto.LandOrProperty == LandOrPropertyEnum.Land && this.dealDto.OwnOrPurchase != OwnOrPurchaseEnum.Own) {
          let commonLandFields = [
            this.dealDto.PurchaseCosts,
            this.dealDto.EstimatedSpending
          ]
          for (let i = 0; i < commonLandFields.length; i++) {
            if (commonLandFields[i] == null) {
              return true;
            }
          }
          if (this.dealDto.IsMortgaged) {
            if (this.dealDto.MortgageCurrentLender == null || this.dealDto.MortgageCurrentLender == '') {
              return true;
            }
          }
        }
        else if (this.dealDto.OwnOrPurchase != OwnOrPurchaseEnum.Own) {
          let commonPropFields = [
            this.dealDto.EndPropertyType
          ]
          for (let i = 0; i < commonPropFields.length; i++) {
            if (commonPropFields[i] == null || commonPropFields[i] == '0') {
              return true;
            }
          }
        }
      }

      if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing) {
        let commonPurchFields = [
          this.dealDto.PurchaseCosts
          //this.dealDto.EstimatedSpending
        ]
        for (let i = 0; i < commonPurchFields.length; i++) {
          if (commonPurchFields[i] == null) {
            return true;
          }
        }
        if (this.dealDto.LandOrProperty == LandOrPropertyEnum.Property) {
          let commonPropFields = [
            this.dealDto.EndPropertyType
          ]
          for (let i = 0; i < commonPropFields.length; i++) {
            if (commonPropFields[i] == null || commonPropFields[i] == '0') {
              return true;
            }
          }
        }
      }

      for (let i = 0; i < commonFields.length; i++) {
        if (commonFields[i] == null || commonFields[i] == "0") {
          return true;
        }
      }

      if (this.multiPartForm1 && this.multiPartForm1.$invalid) return true;

      this.progressAmount = "100%";
    }


    return false;
  }



  searchDisabledPurchaseOrRefinance() {
    this.progressAmount = "50%";

    let commonFields = [
      this.dealDto.OwnOrPurchase,
      this.dealDto.Locations,
      this.dealDto.IsFamilyInResidence,
      this.dealDto.LandOrProperty,
      this.dealDto.EndPropertyType,
    ];

    if (this.progressSection == 1) {
      this.progressAmount = "0%";

      if (this.dealDto.hasPostcode && !this.dealDto.PostcodeSearchString) {
        return true;
      }

      if (!this.dealDto.hasPostcode && !this.dealDto.Locations) {
        return true;
      }

      for (let i = 0; i < commonFields.length; i++) {
        if (commonFields[i] == null || commonFields[i] == "0") {
          return true;
        }
      }

      if (this.multiPartForm1 && this.multiPartForm1.$invalid) return true;

      this.progressAmount = "50%";

    }

    if (this.progressSection == 2) {
      this.progressAmount = "50%";
      if (!this.dealService.showBasedOnMainResidence(this.dealDto) || this.hasValidNetLoanRequiredValue()) {
        return true;
      }

      commonFields = [
        ...commonFields,
        this.dealDto.PurchasePrice,
        this.dealDto.LoanTermReq,
      ];

      if (this.dealDto.PurchaseCosts == null) {
        return true;
      }

      if (this.dealDto.IsPropertyImprovementDuringLoanTerm) {
        let commonImprovementFields = [
          this.dealDto.EstimatedSpending,
          this.dealDto.GDV,
        ];
        for (let i = 0; i < commonImprovementFields.length; i++) {
          if (commonImprovementFields[i] == null) {
            return true;
          }
        }
      }

      if (this.dealDto.IsMortgaged) {
        if (
          this.dealDto.MortgageCurrentLender == null ||
          this.dealDto.MortgageCurrentLender == ""
        ) {
          return true;
        }
      }

      if (!this.dealDto.MaxLoanRequired) {
        if (this.dealDto.NetLoanRequired == null) {
          return true;
        }
      }

      for (let i = 0; i < commonFields.length; i++) {
        if (commonFields[i] == null || commonFields[i] == "0") {
          return true;
        }
      }

      if (this.multiPartForm1 && this.multiPartForm1.$invalid) return true;

      this.progressAmount = "100%";
    }

  }

  searchDisabledBridgingDeveloperExit() {
    var commonFields = [
      this.dealDto.Locations,
      this.dealDto.IsFamilyInResidence,
      this.dealDto.EndPropertyType,
      this.dealDto.RefinancedUnits,
    ];

    if (this.progressSection == 1) {
      this.progressAmount = "0%";

      if (this.dealDto.hasPostcode && !this.dealDto.PostcodeSearchString) {
        return true;
      }

      if (!this.dealDto.hasPostcode && !this.dealDto.Locations) {
        return true;
      }

      if (this.multiPartForm1 && this.multiPartForm1.$invalid) return true;

      if (
        this.dealDto.IsFamilyInResidence == 0 ||
        this.dealDto.IsFamilyInResidence == YesNoMaybe.Yes
      )
        return true;

      for (let i = 0; i < commonFields.length; i++) {
        if (commonFields[i] == null || commonFields[i] == "0") {
          return true;
        }
      }
      this.progressAmount = "50%";

    }

    if (this.progressSection == 2) {

      this.progressAmount = "50%";


      if (!this.dealService.showBasedOnMainResidence(this.dealDto) || this.hasValidNetLoanRequiredValue()) {
        return true;
      }
      commonFields = [
        ...commonFields,
        this.dealDto.Currentvalue,
        this.dealDto.LoanTermReq,
      ];

      if (!this.dealDto.MaxLoanRequired) {
        if (this.dealDto.NetLoanRequired == null) {
          return true;
        }
      }

      if (this.multiPartForm1 && this.multiPartForm1.$invalid) return true;

      for (let i = 0; i < commonFields.length; i++) {
        if (commonFields[i] == null || commonFields[i] == "0") {
          return true;
        }
      }

      this.progressAmount = "100%";

    }


    return false;
  }

  searchDisabledRefurb() {
    this.progressAmount = "50%";

    let commonFields = [
      this.dealDto.OwnOrPurchase,
      this.dealDto.Locations,
      this.dealDto.IsFamilyInResidence,
      this.dealDto.LandOrProperty,
      this.dealDto.EndPropertyType, // TODO - will "Current use of building" be a different property to this one???
      this.dealDto.PurchasePrice,
      this.dealDto.RefurbishmentLevel,
    ];

    if (this.progressSection == 1) {
      this.progressAmount = "0%";

      if (this.dealDto.hasPostcode && !this.dealDto.PostcodeSearchString) {
        return true;
      }

      if (!this.dealDto.hasPostcode && !this.dealDto.Locations) {
        return true;
      }

      if (this.multiPartForm1 && this.multiPartForm1.$invalid) return true;


      if (this.dealDto.LandOrProperty == LandOrPropertyEnum.Land) {
        return true;
      }

      if (this.dealDto.PurchaseCosts == null) {
        return true;
      }

      for (let i = 0; i < commonFields.length; i++) {
        if (commonFields[i] == null || commonFields[i] == "0") {
          return true;
        }
      }

      this.progressAmount = "50%";

    }

    ////TODO current value when owned
    ////TODO purchase price when not owned ??? all the time?

    if (this.progressSection == 2) {

      this.progressAmount = "50%";

      if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own) {
        if (this.dealDto.Currentvalue == null) return true;
        if (this.dealDto.OriginalPurchaseDate == null) return true;
      }


      if (!this.dealService.showBasedOnMainResidence(this.dealDto) || this.hasValidNetLoanRequiredValue()) {
        return true;
      }

      commonFields = [
        ...commonFields,
        this.dealDto.BuildCosts,
        this.dealDto.LoanTermReq,
        this.dealDto.GDV,
      ];

      if (!this.dealDto.MaxLoanRequired) {
        if (this.dealDto.NetLoanRequired == null) {
          return true;
        }
      }

      if (this.multiPartForm1 && this.multiPartForm1.$invalid) return true;


      for (let i = 0; i < commonFields.length; i++) {
        if (commonFields[i] == null || commonFields[i] == "0") {
          return true;
        }
      }

      this.progressAmount = "100%";

    }


    return false;
  }

  searchDisabled() {
    switch (this.dealDto.ProductType) {
      case ProductTypeEnum.BridgingPreconstruction:
        return this.searchDisabledPrecon();
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        return this.searchDisabledPurchaseOrRefinance();
      case ProductTypeEnum.BridgingDeveloperExit:
        return this.searchDisabledBridgingDeveloperExit();
      case ProductTypeEnum.BridgingRefurb:
        return this.searchDisabledRefurb();
      default:
        return true;
    }
  }

  dummysearch() {
    let date = new Date();
    date.setDate(date.getDate() - 1);

    this.dealDto.IsFamilyInResidence = YesNoMaybe.No;
    this.dealDto.HasIsFamilyInResidence = false;

    this.dealDto.hasPostcode =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 1
        ? true
        : false;

    if (this.dealDto.hasPostcode) {
      this.dealDto.PostcodeSearchString =
        this.sharedCriteriaService.getRandomPostcode();
      this.getRegionByPostcode();
    } else {
      this.dealDto.Locations = LocationEnum.London;
    }

    this.dealDto.OwnOrPurchase = OwnOrPurchaseEnum.Own;
    this.dealDto.LandOrProperty =
      this.dealDto.ProductType == ProductTypeEnum.BridgingRefurb
        ? LandOrPropertyEnum.Property
        : LandOrPropertyEnum.Land;

    //this.getPlanningOptions();
    this.dealDto.PlanningPermissionType =
      this.dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction
        ? PlanningPermissionTypeEnum.NoPlanning
        : PlanningPermissionTypeEnum.None;

    this.dealDto.LoanTermReq = this.sharedCriteriaService.getRandomIntInclusive(
      6,
      18,
    );
    this.dealDto.MaxLoanRequired =
      this.sharedCriteriaService.getRandomIntInclusive(0, 1) == 1
        ? true
        : false;

    switch (this.dealDto.ProductType) {
      case ProductTypeEnum.BridgingPreconstruction:
        this.dealDto.OriginalPurchaseDate = date;
        this.dealDto.LandTotalOtherCosts =
          this.sharedCriteriaService.getRandomIntInclusive(10000, 20000);
        this.dealDto.PurchasePrice =
          this.sharedCriteriaService.getRandomIntInclusive(5000000, 10000000);
        this.dealDto.Currentvalue =
          this.dealDto.PurchasePrice +
          this.sharedCriteriaService.getRandomIntInclusive(-2000000, 2000000);
        this.dealDto.NetLoanRequired = !this.dealDto.MaxLoanRequired
          ? this.dealDto.PurchasePrice +
          this.dealDto.PurchaseCosts +
          this.sharedCriteriaService.getRandomIntInclusive(100000, 4000000)
          : null;
        this.dealDto.PurchaseCosts =
          this.sharedCriteriaService.getRandomIntInclusive(100000, 1000000);
        break;

      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        this.dealDto.HasLandOrProperty = true;
        this.onLandOrPropertyChange();
        this.dealDto.OriginalPurchaseDate = date;
        this.dealDto.LandTotalOtherCosts =
          this.sharedCriteriaService.getRandomIntInclusive(10000, 20000);
        this.dealDto.PurchasePrice =
          this.sharedCriteriaService.getRandomIntInclusive(5000000, 10000000);
        this.dealDto.Currentvalue =
          this.dealDto.PurchasePrice +
          this.sharedCriteriaService.getRandomIntInclusive(-2000000, 2000000);
        this.dealDto.NetLoanRequired = !this.dealDto.MaxLoanRequired
          ? this.dealDto.PurchasePrice +
          this.dealDto.PurchaseCosts +
          this.sharedCriteriaService.getRandomIntInclusive(100000, 4000000)
          : null;
        this.dealDto.PurchaseCosts =
          this.sharedCriteriaService.getRandomIntInclusive(100000, 1000000);
        break;

      case ProductTypeEnum.BridgingDeveloperExit:
        this.dealDto.RefinancedUnits =
          this.sharedCriteriaService.getRandomIntInclusive(1, 5);
        this.dealDto.LandOrProperty = LandOrPropertyEnum.Property;
        this.dealDto.Currentvalue =
          this.sharedCriteriaService.getRandomIntInclusive(1000000, 5000000);
        this.dealDto.NetLoanRequired =
          this.dealDto.Currentvalue +
          this.sharedCriteriaService.getRandomIntInclusive(-100000, 100000);
        break;
      case ProductTypeEnum.BridgingRefurb:
        this.dealDto.RefurbishmentLevel = RefurbishmentLevelEnum.Light;
        this.dealDto.BuildCosts =
          this.sharedCriteriaService.getRandomIntInclusive(100000, 1000000);
        this.dealDto.PurchasePrice =
          this.sharedCriteriaService.getRandomIntInclusive(5000000, 10000000);
        this.dealDto.OriginalPurchaseDate = date;
        this.dealDto.Currentvalue =
          this.dealDto.PurchasePrice +
          this.sharedCriteriaService.getRandomIntInclusive(-2000000, 2000000);
        this.dealDto.PurchaseCosts =
          this.sharedCriteriaService.getRandomIntInclusive(100000, 1000000);
        this.dealDto.GDV =
          this.dealDto.Currentvalue +
          this.sharedCriteriaService.getRandomIntInclusive(2000000, 4000000);
        this.dealDto.NetLoanRequired = !this.dealDto.MaxLoanRequired
          ? this.dealDto.PurchasePrice +
          this.dealDto.PurchaseCosts +
          this.sharedCriteriaService.getRandomIntInclusive(100000, 4000000)
          : null;
    }
  }

  debugSearch() {
    const stringJSON = JSON.stringify(this.dealDto);
    navigator.clipboard
      .writeText(stringJSON)
      .then()
      .catch((e) => console.log(e));
  }

  //Clears the previously selected existing client data on share search modal
  clearInputFields() {
    this.shareDealDto =
      this.sharedCriteriaService.clearExistingUserOnShareDealDto(
        this.shareDealDto,
      );
    this.showClientDetails = false;
  }

  // Setting the dependent data when option are changed
  clearSearchdata() {
    this.dealDto = this.bridgingDealService.clearSearchdata(this.dealDto);
  }

  isFieldEmpty(fieldName, isEnum = false) {
    if (this.isSubmitted && this.dealDto) {
      const fieldValue = this.dealDto[fieldName];
      if (fieldName == "PlanningPermissionType") {
        if (fieldValue == null || fieldValue < 1) {
          return true;
        }
      }
      if (fieldValue == null || (isEnum && fieldValue === "0")) {
        return true;
      }
    }

    return false;
  }

  isFieldZero(fieldName) {
    if (this.isSubmitted && this.dealDto) {
      const fieldValue = this.dealDto[fieldName];
      if (fieldValue == null || fieldValue === "0" || fieldValue === 0) {
        return true;
      }
    }

    return false;
  }

  datasetupOnOwnOrPurchaseChange() {
    this.dealDto = this.bridgingDealService.datasetupOnOwnOrPurchaseChange(
      this.dealDto,
    );
  }

  datasetupOnLandorPropertyChange() {
    this.dealDto = this.bridgingDealService.datasetupOnLandorPropertyChange(
      this.dealDto,
    );
  }

  datasetupOnInterestServiceChange() {
    this.dealDto = this.bridgingDealService.datasetupOnInterestServiceChange(
      this.dealDto,
    );
  }

  datasetupOnIsTenantedBuildingChange() {
    this.dealDto = this.bridgingDealService.datasetupOnIsTenantedBuildingChange(
      this.dealDto,
    );
  }

  datasetupOnEndPropertyTypeChange() {
    if (
      this.dealDto.EndPropertyType != PropertyTypeEnum.MixedUse &&
      this.dealDto.EndPropertyType != PropertyTypeEnum.Residential
    ) {
      this.classUses = {};
    }
    this.dealDto = this.bridgingDealService.datasetupOnEndPropertyTypeChange(
      this.dealDto,
    );
  }

  datasetupOnHasCompletionDateChange() {
    this.dealDto = this.bridgingDealService.datasetupOnHasCompletionDateChange(
      this.dealDto,
    );
  }

  datasetupOnMaxLoanRequiredChange() {
    this.dealDto = this.dealService.dataSetupOnMaxLoanRequiredChange(
      this.dealDto,
    );
  }


  initClassUses() {
    let classUses: {} = {};
    for (let i = 1; i >= 0; i *= 2) {
      if (this.dealDto.ClassUses >= i) {
        //number is in enum sum, add it to object
        if (this.dealDto.ClassUses & i) {
          classUses[i] = true;
        }
      } else {
        this.classUses = classUses;

        //watch and update classuses
        this.$scope.$watchCollection("ctrl.classUses", (newVal: {}) => {
          let valuesList = Object.keys(newVal);
          let total: number = 0;
          //tot up all the numeric keys (class use enums)
          valuesList.forEach((v, i) => {
            let keyToNum = Number(v);
            if (newVal[keyToNum]) {
              total += keyToNum;
            }
          });
          this.dealDto.ClassUses = total;
        });

        return;
      }
    }
  }

  onPurchaseDateChange() {
    this.minCompletionDate = this.dealDto.OriginalPurchaseDate;
  }


  getProductRoute(): string {
    switch (this.dealDto.ProductType) {
      case ProductTypeEnum.BridgingPreconstruction:
        return "";
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        return "/PurchaseRefinance";
      case ProductTypeEnum.BridgingDeveloperExit:
        return "/DeveloperExit";
      case ProductTypeEnum.BridgingRefurb:
        return "/Refurbishment";
      default:
        return "";
    }
  }

  bridgingTypeChanged(productType: ProductTypeEnum) {
    this.progressSection = 1;
    this.dealDto = {} as BridgingDealDTO;
    if (this.$cookies.get("access_token")) {
      this.getOriginatorInfoForUser();
    } else {
      this.getOriginatorInfoForAnonymousUsers();
    }
    this.dealDto.MaxLoanRequired = true;
    this.dealDto.ProductType = productType;
    this.dealDto.ProductFamily = ProductFamilyEnum.Bridging;
    this.getLoanInfoText();
    this.dealDto.HasIsFamilyInResidence = false;
    this.dealDto.IsFamilyInResidence = YesNoMaybe.No;
    this.dealDto.HasOwnOrPurchase = false;
    this.dealDto.OwnOrPurchase = OwnOrPurchaseEnum.Purchasing;
    this.dealDto.LandOrProperty =
      this.dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction
        ? LandOrPropertyEnum.Land
        : LandOrPropertyEnum.Property;
    this.dealDto.HasLandOrProperty =
      this.dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance ||
        this.dealDto.ProductType == ProductTypeEnum.BridgingRefurb
        ? true
        : false;
    //this.getPlanningOptions();
    this.dealDto.PlanningPermissionType =
      this.dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction
        ? PlanningPermissionTypeEnum.DetailedPlanning
        : PlanningPermissionTypeEnum.None;
    this.dealDto.LoanCompletionType =
      LoanCompletionTypeEnum.JustCheckingMyOptions;
    this.dealDto.RefurbishmentLevel =
      this.dealDto.ProductType == ProductTypeEnum.BridgingRefurb
        ? RefurbishmentLevelEnum.Light
        : null;
    this.dealDto.ShowLenderInfoBrokerOverride = true;
    this.dealDto.hasPostcode = true;
    this.hasPurchasecostChanged = false;
    this.hasLandcostChanged = false;
    if (
      this.$location.path().startsWith("/e/bridgingcriteria") ||
      this.$location.path().startsWith("/allloans")
    ) {
      if (this.$routeParams.linkId && this.$routeParams.linkId != "0") {
        this.dealDto.OrganisationLinkId = this.$routeParams.linkId;
      }

      this.logEvent("BRIDGINGCRITERIA-PAGE1");
    }
  }

  async getOriginatorInfoForUser() {
    if (this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Admin;
      this.dealDto.SourceType = DealSourceTypeEnum.Application;
    } else if (this.isBroker && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Broker;
    } else if (this.isBorrower && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Borrower;
    } else if (this.isLender && !this.isAdmin) {
      this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Lender;
      this.dealDto.SourceType = DealSourceTypeEnum.Application;
    }

    if (window.self == window.top) {

      if (this.isLender) {
        this.userService.getCurrentUserLender().then((response) => {
          this.dealDto.OriginatorLenderId = response;
        });
      }

      if (this.isBroker || this.isBorrower) {
        this.organisationService.getOrganisation().then((org) => {
          if (org != null) {
            if (org.Id != 0) this.dealDto.OriginatorOrganisationId = org.Id;
            this.dealDto.SourceType = org.IsWhiteLabelled
              ? DealSourceTypeEnum.WhitelabelApplication
              : DealSourceTypeEnum.Application;
          }
        });
      }


    }
  }

  getOriginatorInfoForAnonymousUsers() {
    this.dealDto.OriginatorRoleType = OriginatorRoleTypeEnum.Borrower;

    if (window.self == window.top) {
      this.dealDto.SourceType = DealSourceTypeEnum.Enterprise;
    } else {
      this.dealDto.SourceType = this.isWidget ? DealSourceTypeEnum.WidgetEnterprise : DealSourceTypeEnum.IFrameEnterprise;
    }

    if (this.$routeParams.linkId) {
      if (window.self == window.top) {
        this.dealDto.SourceType = DealSourceTypeEnum.UniqueLink;
      } else {
        this.dealDto.SourceType = this.isWidget ? this.$routeParams.linkId == "0"? DealSourceTypeEnum.WidgetEnterprise: DealSourceTypeEnum.WidgetUniqueLink : DealSourceTypeEnum.IFrameUniqueLink;
      }
    }
  }

  calculatePurchaseOrLandCost() {
    if (this.dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction) {
      if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own) {
        if (this.dealDto.PurchasePrice && !this.hasLandcostChanged) {
          this.dealDto.LandTotalOtherCosts =
            (this.dealDto.PurchasePrice * 5) / 100;
        }
      } else if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing) {
        if (this.dealDto.PurchasePrice && !this.hasPurchasecostChanged) {
          this.dealDto.PurchaseCosts = (this.dealDto.PurchasePrice * 5) / 100;
        }
      }
    } else if (
      this.dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance ||
      this.dealDto.ProductType == ProductTypeEnum.BridgingRefurb
    ) {
      if (this.dealDto.PurchasePrice && !this.hasPurchasecostChanged) {
        this.dealDto.PurchaseCosts = (this.dealDto.PurchasePrice * 5) / 100;
      }
    }
  }

  onPurchaseCostChange() {
    var purchaseCost = (this.dealDto.PurchasePrice * 5) / 100;
    if (this.dealDto.PurchaseCosts != purchaseCost) {
      this.hasPurchasecostChanged = true;
    }
  }

  onLandCostChange() {
    var landCost = (this.dealDto.PurchasePrice * 5) / 100;
    if (this.dealDto.PurchaseCosts != landCost) {
      this.hasLandcostChanged = true;
    }
  }

  onOwnOrPurchaseChange(OwnOrPurchase: boolean) {
    this.dealDto.OwnOrPurchase = this.dealDto.HasOwnOrPurchase
      ? OwnOrPurchaseEnum.Own
      : OwnOrPurchaseEnum.Purchasing;
    //this.getPlanningOptions();
    this.datasetupOnOwnOrPurchaseChange();
  }

  onLandOrPropertyChange() {
    this.dealDto.LandOrProperty = this.dealDto.HasLandOrProperty
      ? LandOrPropertyEnum.Property
      : LandOrPropertyEnum.Land;
    //this.getPlanningOptions();
    this.datasetupOnLandorPropertyChange();
  }

  onIsFamilyInResidenceChange() {
    this.dealDto.IsFamilyInResidence = this.dealDto.HasIsFamilyInResidence
      ? YesNoMaybe.Yes
      : YesNoMaybe.No;
  }

  initialDataSetupForIsFamilyInResidence() {
    if (this.dealDto.HasIsFamilyInResidence) {
      this.dealDto.IsFamilyInResidence = YesNoMaybe.Yes;
      this.dealDto.HasIsFamilyInResidence = true;
    } else {
      this.dealDto.IsFamilyInResidence = YesNoMaybe.No;
      this.dealDto.HasIsFamilyInResidence = false;
    }
  }

  goToLoanTypeSelectionPage() {
    if (this.isLoggedInUser) {
      this.$auth.setHasValidStatustoShowShareSearchModal(
        true,
      );
    }

    this.$location.path("/allloans");

  }

  goToDevFinanceCriteria() {
    if (this.$location.path().startsWith("/allloans")) {
      this.$scope.$emit("productFamilySelection", 1);
    }
    else if (this.isLoggedInUser) {
      this.$location.path("/devfinancecriteria");
    } else {
      this.$location.path("/e/devfinancecriteria");
    }
  }

  calculateMonthsSinceOrigPurchase() {
    let date1 = new Date(this.dealDto.OriginalPurchaseDate);
    let date2 = new Date();
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months;
  }

  async logEvent(identifier: string) {
    var orgCode;
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto?.OrganisationLinkId,
          this.dealDto.ProductType,
          "",
          this.dealDto.Id,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto?.OrganisationLinkId,
          this.dealDto.ProductType,
        );
      }
    } else {
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");
      if (clientId) {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto.OrganisationLinkId,
          this.dealDto.ProductType,
          "",
          this.dealDto.Id,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          identifier,
          this.orgCode,
          "",
          "Client",
          this.dealDto.OrganisationLinkId,
          this.dealDto.ProductType,
        );
      }
    }
  }


  calcContingencyAmount() {
    if (this.dealDto.Contingency && this.dealDto.BuildCosts) {
      this.contingencyAmount =
        this.dealDto.Contingency * this.dealDto.BuildCosts;
    } else {
      this.contingencyAmount = null;
    }
  }

  hasValidNetLoanRequiredValue() {
    if (
      !this.dealDto.MaxLoanRequired &&
      (this.dealDto.NetLoanRequired == null ||
        isNaN(this.dealDto.NetLoanRequired) ||
        this.dealDto.NetLoanRequired <= 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /*getRegionByPostcode() {
            if (this.dealDto.PostcodeSearchString && this.dealDto.PostcodeSearchString.replace(" ", "").length >= 2) {
                if (this.dealService.isValidPostcodeString(this.dealDto.PostcodeSearchString)) {
                    this.dataLoading = true;
                    this.dealService.getRegionByPostcode(this.dealDto.PostcodeSearchString).then((response) => {
                        if (response.Location != null) {
                            this.dealDto.Locations = response.Location;
                            this.showPostcodeErrorMessage = false;
                        } else {
                            this.postcodeErrorMsg = response.PostCodeErrorMessage;
                            this.showPostcodeErrorMessage = true;
                            if (this.dealDto.Locations) this.dealDto.Locations = null;
                        }
                    }).finally(() => {
                        this.dataLoading = false;
                    });
                } else {
                    this.postcodeErrorMsg = "At least one letter and one number must be entered e.g. SW1.";
                    this.showPostcodeErrorMessage = true;
                    if (this.dealDto.Locations) this.dealDto.Locations = null;
                }
            } else {
                if (this.dealDto.Locations) this.dealDto.Locations = null;
            }
        }*/

  showBasedOnMainResidence() {
    return this.dealService.showBasedOnMainResidence(this.dealDto);
  }


  getLoanInfoText() {
    switch (this.dealDto.ProductType) {
      case ProductTypeEnum.BridgingDeveloperExit:
        this.loanInfoText =
          "For properties that are at practical completion, but need more time to sell or refinance";
        break;
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        this.loanInfoText =
          "Loans against the property value only. Any improvement works need to be self-funded";
        break;
      case ProductTypeEnum.BridgingRefurb:
        this.loanInfoText =
          "Loans available against the property and the refurbishment works.";
        break;
      case ProductTypeEnum.BridgingPreconstruction:
        this.loanInfoText =
          "Loans for gaining planning or to amend existing planning";
        break;
      default:
        this.loanInfoText = "";
        break;
    }
  }

  clickNextButton() {
    if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/bridgingcriteria") || this.$location.path().startsWith("/allloans"))) {
      this.logEvent("BRIDGINGCRITERIA-PAGE2");
    }
    this.progressSection = 2;
    this.progressAmount = "50%";
  }

  clickBackButton() {
    if (!this.isLoggedInUser && (this.$location.path().startsWith("/e/bridgingcriteria") || this.$location.path().startsWith("/allloans"))) {
      this.logEvent("BRIDGINGCRITERIA-PAGE1");
    }
    this.progressSection = 1;
  }

  async logEventWithoutDealData() {
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          "BRIDGINGCRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.Bridging,
          "",
          0,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          "BRIDGINGCRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.Bridging,
        );
      }
    } else {
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");

      if (clientId) {
        this.eventLogService.logPageLoad(
          "BRIDGINGCRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.Bridging,
          "",
          0,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          "BRIDGINGCRITERIA",
          this.orgCode,
          "",
          "Client",
          this.$routeParams.linkId ? this.$routeParams.linkId : null,
          ProductTypeEnum.Bridging,
        );
      }
    }
  }

  postRetrieveProcessing(response) {
    this.dealDto = { ...response };
    this.getLoanInfoText();
    this.snapshotDealDto = { ...response };
    this.isFamilyInResidence =
      this.dealDto.IsFamilyInResidence == YesNoMaybe.Yes ? true : false;
    this.ownOrPurchase =
      this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own
        ? true
        : false;
    this.landOrProperty =
      this.dealDto.LandOrProperty == LandOrPropertyEnum.Property
        ? true
        : false;
    //this.initClassUses();
    this.calcContingencyAmount();
  }


}

export const enum EPCRatingTypeEnum {
  None = 0,
  A = 1,
  B = 2,
  C = 4,
  D = 8,
  E = 16,
  AboveE = 32,
  AtoC = 7,
  DtoE = 24,
}

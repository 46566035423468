import { CommercialDealDTO } from "@js/DTO/Deal/CommercialDealDTO.cs.d";
import { SaveCommercialSearchRequest } from "@js/DTO/Messages/Deal/SaveCommercialSearchMessage.cs.d";
import {
  UpdateCommercialSearchGetResultResponse,
  UpdateCommercialSearchGetResultRequest,
} from "@js/DTO/Messages/Deal/UpdateCommercialSearchGetResultMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { BorrowingEntityTypeEnum } from "@js/models/enum/BorrowingEntityTypeEnum.cs.d";
import { FixedRateTermEnum } from "@js/models/enum/FixedRateTermEnum.cs.d";
import { InterestRateTypeEnum } from "@js/models/enum/InterestRateTypeEnum.cs.d";
import { LoanCompletionTypeEnum } from "@js/models/enum/LoanCompletionTypeEnum.cs.d";
import { LoanRepaymentTypeEnum } from "@js/models/enum/LoanRepaymentTypeEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { TimeLeftOnLeaseEnum } from "@js/models/enum/TimeLeftOnLeaseEnum.cs.d";
import { TradingPeriodEnum } from "@js/models/enum/TradingPeriodEnum.cs.d";
import { YesNoMaybe } from "@js/models/enum/YesNoMaybeEnum.cs.d";
import { SaveSearchReturnUniqueRefResponse } from "@js/models/SaveSearchReturnUniqueRef.cs";

export class CommercialDealService extends BaseService<
  CommercialDealDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/commercialdeal";
    this.$broadcastBusinessNameSingular = "commercialdeal";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  /**
   * Updates the search Deal based on the CommercialDealDTO and returns the search results on the new criteria
   * @param criteriaDto
   * @param criteriaChanged only set to true when the search criteria changes, and not the filters. This controls data snapshotting.
   */
  updateSearchCriteriaAndGetResults(
    criteriaDto: CommercialDealDTO,
    criteriaChanged: boolean = false,
    debug1: boolean,
    isPostcodeChanged: boolean = false,
    isShortlistingMore: boolean = false,
  ): ng.IPromise<UpdateCommercialSearchGetResultResponse> {
    let defer = this.$q.defer<UpdateCommercialSearchGetResultResponse>();

    var request: UpdateCommercialSearchGetResultRequest = {
      CommercialDealDto: criteriaDto,
      CriteriaChanged: criteriaChanged,
      Limit: 0, //TODO: typings aren't picking up default values
      Debug1: debug1, //TODO: typings aren't picking up default values
      Debug2: false, //TODO: typings aren't picking up default values
      IsPostcodeChange: isPostcodeChanged,
      IsShortlistingMore: isShortlistingMore
    };

    this.$http
      .post(
        this.$baseAddress + "/updatesearchandgetresults",
        JSON.stringify(request),
      )
      .then((response) => {
        var res = response.data as UpdateCommercialSearchGetResultResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveCommercialSearchReturnsId(
    request: SaveCommercialSearchRequest,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.$http
      .post(this.$baseAddress + "/savecommercialsearchreturnsId", request)
      .then((response) => {
        var res = response.data as number;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getCountries(): ng.IPromise<[]> {
    let defer = this.$q.defer<[]>();
    this.$http
      .get(this.$baseAddress + "/getcountries")
      .then((response) => {
        defer.resolve(response.data as []);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getNationalities(): ng.IPromise<[]> {
    let defer = this.$q.defer<[]>();
    this.$http
      .get(this.$baseAddress + "/getnationalities")
      .then((response) => {
        defer.resolve(response.data as []);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  debugSearch(dealDto: CommercialDealDTO): ng.IPromise<[]> {
    let defer = this.$q.defer<[]>();
    this.$http
      .get(this.$baseAddress + "/debugSearch")
      .then((response) => {
        defer.resolve(response.data as []);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  initialDataSetUp(dealDto: CommercialDealDTO, productType: ProductTypeEnum) {
    dealDto.IsFamilyInResidence = YesNoMaybe.No;
    dealDto.HasIsFamilyInResidence = false;
    dealDto.HasOwnOrPurchase = false;
    dealDto.MaxLoanRequired = true;
    dealDto.ProductType = productType;
    dealDto.ProductFamily = ProductFamilyEnum.Commercial;
    dealDto.OwnOrPurchase = OwnOrPurchaseEnum.Purchasing;
    if (productType == ProductTypeEnum.CommercialInvestment) {
      dealDto.BorrowingEntityType = BorrowingEntityTypeEnum.Company;
      dealDto.CurrentPropertyType = PropertyTypeEnum.MixedUse;
      dealDto.TimeLeftOnLease = TimeLeftOnLeaseEnum.OneToThreeYears;
    }
    dealDto.LoanRepaymentType = LoanRepaymentTypeEnum.Repayment;
    dealDto.LoanCompletionType = LoanCompletionTypeEnum.JustCheckingMyOptions;
    dealDto.MortgageInterestType = InterestRateTypeEnum.Fixed;
    dealDto.FixedRateTerm = FixedRateTermEnum.FiveYear;
    dealDto.ShowLenderInfoBrokerOverride = true;
    dealDto.hasPostcode = true;
    if (productType == ProductTypeEnum.CommercialOwnerOccupied) {
      dealDto.BorrowingEntityType = BorrowingEntityTypeEnum.Company;
      dealDto.TradingPeriod = TradingPeriodEnum.MoreThanTwoYears;
      dealDto.CurrentPropertyType = PropertyTypeEnum.Office;
    }
    dealDto.IncludesArrangementFee = false;
    return dealDto;
  }

  datasetupOnOwnOrPurchaseChange(dealDto) {
    if (
      dealDto.OwnOrPurchase != null &&
      dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing
    ) {
      dealDto.OriginalPurchaseDate = null;
      dealDto.Currentvalue = null;
    }

    if (
      dealDto.OwnOrPurchase != null &&
      dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own
    ) {
      dealDto.PurchasePrice = 0;
      dealDto.PurchaseCosts = 0;
    }
    return dealDto;
  }

  datasetupOnCurrentUseOfBuilding(dealDto) {
    if (
      dealDto.CurrentPropertyType != null &&
      dealDto.CurrentPropertyType == PropertyTypeEnum.HMO
    ) {
      dealDto.UnitCount = null;
      dealDto.TimeLeftOnLease = TimeLeftOnLeaseEnum.OneToThreeYears;
    } else if (
      dealDto.CurrentPropertyType != null &&
      dealDto.CurrentPropertyType == PropertyTypeEnum.MultiUnitFreeholdBlock
    ) {
      dealDto.BedroomCount = null;
      dealDto.TimeLeftOnLease = TimeLeftOnLeaseEnum.OneToThreeYears;
    } else if (
      dealDto.CurrentPropertyType != null &&
      dealDto.CurrentPropertyType != PropertyTypeEnum.MultiUnitFreeholdBlock &&
      dealDto.CurrentPropertyType != PropertyTypeEnum.HMO
    ) {
      dealDto.BedroomCount = null;
      dealDto.UnitCount = null;
    }
    return dealDto;
  }

  datasetupOnIsTenantedBuildingChange(dealDto: CommercialDealDTO) {
    if (!dealDto.IsTenantedBuilding) {
      dealDto.NumberOfTenancies = null;
    }
    return dealDto;
  }

  saveCommercialSearchReturnsUniqueRef(
    request: SaveCommercialSearchRequest,
  ): ng.IPromise<SaveSearchReturnUniqueRefResponse> {
    let defer = this.$q.defer<SaveSearchReturnUniqueRefResponse>();

    this.$http
      .post(
        this.$baseAddress + "/savecommercialsearchreturnsuniqueref",
        request,
      )
      .then((response) => {
        var res = response.data as SaveSearchReturnUniqueRefResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

import { FC, useState } from "react";

export const TestComponent1: FC = () => {
  const [count, setCount] = useState<number>(0);

  return (
    <div>
      <div>
        <p>You have clicked me {count} times.</p>
      </div>

      <div>
        <button type="button" onClick={() => setCount(count + 1)}>
          Click me!
        </button>
      </div>
    </div>
  );
};

interface Props {
  fooBar: number;
  baz: string;
}

export const TestComponent2: FC<Props> = ({ fooBar, baz }) => {
  return (
    <div>
      <p>FooBar: {fooBar}</p>
      <p>Baz: {baz}</p>
    </div>
  );
};

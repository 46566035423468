import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { OrganisationLinkDTO } from "@js/DTO/OrgnisationLinkDTO.cs.d";
import { ApplicantRoleEnum } from "@js/models/enum/ApplicantRoleEnum.cs.d";
import { IntroducerTypeEnum } from "@js/models/enum/IntroducerTypeEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { ClientService } from "@js/services/ClientService";
import { EventLogService } from "@js/services/EventLogService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationLinkService } from "@js/services/OrganisationLinkService";
import { OrganisationService } from "@js/services/OrganisationService";
import { UserService } from "@js/services/UserService";
import { UserRoleEnum } from "@js/models/enum/UserRoleEnum.cs.d";

export class InitialRegistrationController {
  selectedType: number;

  RoleEnum = {
    All: 0,
    DevelopmentFinance: 1,
    BridgingFinance: 2,
    Clients: 3,
  };

  user: ApplicationUserDTO;
  client: ClientDTO;
  isBroker: boolean = false;
  projectName: string;

  error: string = "";
  registrationForm: ng.IFormController;
  isBorrower: boolean = false;
  panelHeaderText: string;
  dataLoading: boolean = false;
  totalLenders: number = 0;
  orgLogo: string = "";
  testimonialText: string = "";
  testimonalCustomer: string = "";
  showTsAndCs: boolean = false;
  acceptTerms: boolean = false;
  acceptDataTerms: boolean = false;
  showPrivacyPolicy: boolean = false;
  orgCode: string;
  headingAdjustment: string;
  organisation: OrganisationDTO;
  userRole: UserRoleEnum;
  organisationLink: OrganisationLinkDTO;
  clientId: string;

  static $inject = [
    "$routeParams",
    "$location",
    "UserService",
    "OrganisationService",
    "$rootScope",
    "LenderService",
    "$cookies",
    "EventLogService",
    "ClientService",
    "OrganisationLinkService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private userService: UserService,
    private organisationService: OrganisationService,
    public $rootScope: ng.IRootScopeService,
    private lenderService: LenderService,
    private $cookies: ng.cookies.ICookiesService,
    private eventLogService: EventLogService,
    private clientService: ClientService,
    private organisationLinkService: OrganisationLinkService,
  ) {
    this.setBrokerOrBorrower()
      .then(() => {
        if (this.$routeParams.isBroker == "broker") {
          this.isBroker = true;
          sessionStorage.setItem("userRole", UserRoleEnum.Broker.toString());
          //TODO Change for New pricing
          window.location.assign("https://brickflow.com/pricing");
        } else if (this.$routeParams.isBroker == "borrower") {
          this.$location.path("/borrowerinitialregistration");
        } else if (
          this.$location.path().startsWith("/borrowerinitialregistration")
        ) {
          if (this.$routeParams["Type"] == "bridging") {
            this.$location.path("/e/bridgingcriteria");
          } else {
            this.$location.path("/e/devfinancecriteria");
          }
        } else {
          //TODO Change for New pricing
          window.location.assign("https://brickflow.com/pricing");
        }

        this.getNumberOfLenders();

        //var orgCode;

        if (window.self == window.top) {
          this.orgCode = this.$cookies.get("org_code");
        } else {
          this.orgCode = location.pathname.replace("/", "");
        }

        if (this.orgCode) {
          this.initialRegisterEventLog(this.orgCode);

          this.organisationService
            .getOrganisationByOrgCode(this.orgCode)
            .then((organisation: OrganisationDTO) => {
              this.organisation = organisation;
              if (this.organisation && organisation.LogoURL) {
                this.orgLogo = organisation.LogoURL;
              }
            });
        } else {
          this.organisationService
            .getBrickflowBrokerOrg()
            .then((organisation: OrganisationDTO) => {
              this.organisation = organisation;
            });
          this.initialRegisterEventLog("");
        }

        if (window.self == window.top) {
          if (sessionStorage.getItem("userDetails")) {
            let data = sessionStorage.getItem("userDetails");
            this.user = JSON.parse(data);
          }
        }

        var applicationName = this.getApplicationName();

        if (this.isBroker == true) {
          this.panelHeaderText =
            "Welcome to " + applicationName + " for Brokers";
          if (!this.user) {
            this.user = {
              Roles: ["Broker", "Introducer"],
              IsOrganisationAdmin: true,
              ApplicantDefinedRole: ApplicantRoleEnum.Introducer,
              ApplicantDefinedRoleIntroducer: IntroducerTypeEnum.Broker,
            } as ApplicationUserDTO;
          }
        }
      })
      .catch((error) => {
        console.error("Failed to get initialize data: ", error);
      });
  }

  async getUserRole(): Promise<void> {
    if (window.self == window.top) {
      this.userRole = parseInt(sessionStorage.getItem("userRole")) as UserRoleEnum;
    } else {
      this.organisationService.getData("userRole").then((result) => {
        this.userRole = parseInt(result) as UserRoleEnum;
      });
    }
  }

  async setBrokerOrBorrower() {
    await this.getUserRole();
    this.isBroker = this.userRole === UserRoleEnum.Broker;
    this.isBorrower = this.userRole === UserRoleEnum.Client;
  }

  async initialRegisterEventLog(orgCode, organisationLinkId = null) {
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          "INITIALREGISTER",
          orgCode,
          "",
          sessionStorage.getItem("userRole"),
          organisationLinkId,
          this.$routeParams["Type"] == "bridging"
            ? ProductTypeEnum.Bridging
            : ProductTypeEnum.Development,
          undefined,
          undefined,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          "INITIALREGISTER",
          orgCode,
          "",
          sessionStorage.getItem("userRole"),
          organisationLinkId,
          this.$routeParams["Type"] == "bridging"
            ? ProductTypeEnum.Bridging
            : ProductTypeEnum.Development,
        );
      }
    } else {
      var userRole;
      userRole = await this.organisationService.getData("userRole");
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");
      if (clientId) {
        this.eventLogService.logPageLoad(
          "INITIALREGISTER",
          orgCode,
          "",
          sessionStorage.getItem("userRole"),
          organisationLinkId,
          this.$routeParams["Type"] == "bridging"
            ? ProductTypeEnum.Bridging
            : ProductTypeEnum.Development,
          undefined,
          undefined,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          "INITIALREGISTER",
          orgCode,
          "",
          sessionStorage.getItem("userRole"),
          organisationLinkId,
          this.$routeParams["Type"] == "bridging"
            ? ProductTypeEnum.Bridging
            : ProductTypeEnum.Development,
        );
      }
    }
  }

  saveUserdetails() {
    this.dataLoading = true;
    let emailExists;

    this.userService
      .checkEmailExists(this.user.Email)
      .then((result) => {
        emailExists = result;
        return this.organisationService.getData("userRole");
      })
      .then((role) => {
        if (window.self === window.top) {
          this.userRole = parseInt(sessionStorage.getItem("userRole")) as UserRoleEnum;
        } else {
          this.userRole = parseInt(role) as UserRoleEnum;
        }
        return this.organisationService.getData("clientId");
      })
      .then((id) => {
        if (window.self === window.top) {
          this.clientId = sessionStorage.getItem("clientId");
        } else {
          this.clientId = id;
        }
        if (emailExists == false) {
          this.processUserDetails(this.userRole, this.clientId);
        } else {
          this.error = "Email address is already in use, please sign in.";
        }
      })
      .catch((error) => {
        console.error("An error occurred in saveUserdetails:", error);
        this.error =
          "An error occurred while processing your request. Please try again.";
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  processUserDetails(userRole, clientId) {
    this.error = "";
    if (this.isBroker) {
      this.user.Roles = ["Broker", "Introducer"];
      this.user.IsOrganisationAdmin = true;
      (this.user.ApplicantDefinedRole = ApplicantRoleEnum.Introducer),
        (this.user.ApplicantDefinedRoleIntroducer = IntroducerTypeEnum.Broker);
    } else {
      this.user.Roles = ["Client"];
    }
    if (window.self == window.top) {
      sessionStorage.setItem("userDetails", JSON.stringify(this.user));
      sessionStorage.setItem("projectname", this.projectName);
      this.userService.sendEventToHubspot(
        this.user,
        "PREREGISTER",
        false,
        this.orgCode,
        parseInt(sessionStorage.getItem("userRole")) as UserRoleEnum,
        this.organisationLink && this.organisationLink.IsEnabled
          ? this.organisationLink.Id
          : 0,
        this.$routeParams["Type"] == "bridging"
          ? ProductTypeEnum.Bridging
          : ProductTypeEnum.Development,
        undefined,
        undefined,
        this.clientId,
      );
    } else {
      this.organisationService.sendDataToParent(
        "userDetails",
        JSON.stringify(this.user),
      );
      this.organisationService.sendDataToParent(
        "projectname",
        this.projectName,
      );
      this.userService.sendEventToHubspot(
        this.user,
        "PREREGISTER",
        false,
        this.orgCode,
        this.userRole,
        this.organisationLink && this.organisationLink.IsEnabled
          ? this.organisationLink.Id
          : 0,
        this.$routeParams["Type"] == "bridging"
          ? ProductTypeEnum.Bridging
          : ProductTypeEnum.Development,
        undefined,
        undefined,
        this.clientId,
      );
    }

    if (this.isBroker) {
      this.organisationService
        .brokerSignUpJourneyG0Email(this.user.Email, this.user.FullName)
        .then((response) => {});
    }

    if (this.isBroker) {
      this.$location.path("/promo/true");
    } else {
      (this.$rootScope as any).loanCriteria = null;
      if (this.selectedType == 1) {
        this.$location.path("/devfinancecriteria");
      } else if (this.selectedType == 0) {
        this.saveUserDataToClientTable().then(() => {
          this.$location.path("/newsearchselection/all");
        });
      } else if (this.selectedType == 2) {
        this.saveUserDataToClientTable().then(() => {
          this.$location.path("/bridgingcriteria");
        });
      }
    }
  }

  back() {
    this.$location.path("/landingV3");
  }

  isiframe() {
    if (window.self == window.top) return false;
    return true;
  }

  getApplicationName() {
    if (
      window.self == window.top &&
      sessionStorage.getItem("applicationName") != null
    ) {
      return sessionStorage.getItem("applicationName");
    } else {
      return "Brickflow";
    }
  }

  getNumberOfLenders() {
    var productType = ProductFamilyEnum.None;

    if (this.selectedType == this.RoleEnum.BridgingFinance) {
      productType = ProductFamilyEnum.Bridging;
    } else if (this.selectedType == this.RoleEnum.DevelopmentFinance) {
      productType = ProductFamilyEnum.Development;
    }

    if (productType == ProductFamilyEnum.None) {
      this.lenderService
        .getTotalLenders(ProductFamilyEnum.None)
        .then((response: number) => {
          this.totalLenders = Math.floor(response);
        });
    } else {
      this.lenderService
        .getTotalLenders(productType)
        .then((response: number) => {
          this.totalLenders = Math.floor(response);
        });
    }
  }

  genUUID() {
    let S4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    let guid = `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
    return guid.toLowerCase();
  }

  saveUserDataToClientTable() {
    return new Promise<void>(async (resolve, reject) => {
      let userFullName = this.user.FullName;
      let spaceIndex = userFullName.indexOf(" ");

      let firstName = "";
      let lastName = "";

      // if not space has been added to the name then put the whole name in the first name field
      if (spaceIndex == -1) {
        firstName = userFullName;
      } else {
        firstName = userFullName.substring(0, userFullName.indexOf(" "));
        lastName = userFullName.substring(userFullName.indexOf(" ") + 1);
      }

      var clientDto = {
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: this.user.PhoneNumber,
        Email: this.user.Email,
        BrokerOrganisationId: this.organisation.Id,
        PostalAddress: {
          AddressLine1: "",
          AddressLine2: "",
          AddressLine3: "",
          AddressLine4: "",
          PostCode: "",
        } as PostalAddress,
      } as ClientDTO;

      //if (localStorage.getItem('clientId')) {
      //    clientDto.Id = Number(localStorage.getItem('clientId'));
      //}

      try {
        this.clientService.addUpdatereturnonlyid(clientDto).then((response) => {
          if (window.self == window.top) {
            sessionStorage.setItem("clientId", response.toString());
          } else {
            this.organisationService.sendDataToParent(
              "clientId",
              response.toString(),
            );
          }
        });
        resolve();
      } catch (error) {
        console.error("An error occurred:", error);
        Promise.reject(error);
      }
    });
  }

  gotoSignInPage() {
    this.$location.path("/login");
  }
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { DealDTO } from "@js/DTO/Deal/DealDTO.cs.d";
import { LenderResultDTO } from "@js/DTO/DevelopmentFinance/LenderResultDTO.cs.d";
import { LenderResultSummaryDTO } from "@js/DTO/DevelopmentFinance/LenderResultSummaryDTO.cs.d";
import { LenderLogoDTO } from "@js/DTO/LenderLogoDTO.cs.d";
import { BridgingLenderResultSummaryDTO } from "@js/DTO/SearchResults/BridgingLenderResultSummaryDTO.cs.d";
import { CommercialLenderResultSummaryDTO } from "@js/DTO/SearchResults/CommercialLenderResultSummaryDTO.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { DealService } from "@js/services/Deal/DealService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { OrganisationService } from "@js/services/OrganisationService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";

export class ComparisonController {
  isLoading: boolean;

  selectedCandidates: (
    | LenderResultSummaryDTO
    | CommercialLenderResultSummaryDTO
    | BridgingLenderResultSummaryDTO
  )[];
  allResults: (
    | LenderResultSummaryDTO
    | CommercialLenderResultSummaryDTO
    | BridgingLenderResultSummaryDTO
  )[];
  rowSelected: number[] = [];

  //Lender Logos
  showLenderLogo: boolean = false;
  lenderIds: number[] = [];
  urlLenders: LenderLogoDTO[] = [];

  selectedUser: ApplicationUserDTO;

  enquiryOpen: boolean;
  enquiryEmail: string;
  enquiryBody: string;

  isBroker: boolean = false;

  isIntroducer: boolean = false;

  searchid: number;
  caseid: number;
  uniqueid: string;
  isLenderVisible: boolean;
  showAdditionalProductInfoModal: LenderResultDTO;
  case: CaseDTO;
  isDeal: boolean = false;

  criteria: {} = {};

  productTypeOptions = [];
  productLastVerifiedDates = {};

  isLoggedInUser: boolean = false;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "CaseMemberService",
    "RoleService",
    "FileAttachmentService",
    "OrganisationService",
    "CaseService",
    "DealService",
    "$routeParams",
    "SelectListService",
    "ProductService"
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private caseMemberService: CaseMemberService,
    private roleService: RoleService,
    private fileAttachmentService: FileAttachmentService,
    private organisationService: OrganisationService,
    private caseService: CaseService,
    private dealService: DealService,
    private $routeParams: ng.route.IRouteParamsService,
    private selectListService: SelectListService,
    private productService: ProductService
  ) {
    this.isDeal = this.$routeParams.isDeal ? true : false;

    if (this.$routeParams.SearchId) {
      this.searchid = this.$routeParams.SearchId;
    }
    this.showOrHideLenderLogo();
    this.productTypeOptions = this.selectListService.GetProductTypeOptions();

    if ($cookies.get("access_token")) {
      this.isLoggedInUser = true;
    }

    if (sessionStorage.getItem("UniqueId")) {
      this.uniqueid = sessionStorage.getItem("UniqueId");
    }

    this.allResults = JSON.parse(sessionStorage.getItem("TempSavedResults"));

    //We are sending caseId has 0 for bridging
    if (this.$routeParams.CaseId && this.$routeParams.CaseId > 0) {
      this.caseid = this.$routeParams.CaseId;
    }

    if (
      (this.$rootScope as any).selectedUser ||
      sessionStorage.getItem("SelectedUser")
    ) {
      if ((this.$rootScope as any).selectedUser) {
        this.selectedUser = (this.$rootScope as any).selectedUser;
        sessionStorage.setItem(
          "SelectedUser",
          JSON.stringify(this.selectedUser),
        );
      } else if (sessionStorage.getItem("SelectedUser")) {
        this.selectedUser = JSON.parse(sessionStorage.getItem("SelectedUser"));
      }

      this.checkForBrokerOrIntroducer();
    }

    if (
      sessionStorage.getItem("ComparisonList") &&
      sessionStorage.getItem("LoanCriteria")
    ) {
      this.selectedCandidates = JSON.parse(
        sessionStorage.getItem("ComparisonList"),
      );

      if (this.selectedCandidates) {
        for (var i = 0; i < this.selectedCandidates.length; i++) {
          this.getUrls(
            this.selectedCandidates[i].Id,
            this.selectedCandidates[i].LogoURL,
          );
        }
        const productIds = this.selectedCandidates.map((product) => product.ProductID);
        this.getProductLastVerifiedDates(productIds);
      }

      this.criteria = JSON.parse(sessionStorage.getItem("LoanCriteria"));
    } else {
      this.goToResults();
    }
  }

  getUrls(id: number, url: string) {
    if (url == null) return null;

    this.fileAttachmentService.getFileUri(url).then((response) => {
      var LenderLogoDTO = {
        Id: id,
        LogoURL: response,
      } as LenderLogoDTO;

      this.urlLenders.push(LenderLogoDTO);
    });
  }

  checkForBrokerOrIntroducer(): void {
    //Check if broker
    this.roleService.isBrokerOrABove().then((response) => {
      this.isBroker = response;
    });
    this.roleService.isIntroducer().then((response) => {
      this.isIntroducer = response;
    });
  }

  showOrHideLenderLogo() {
    this.organisationService.showLenderNamesAndLogos(this.searchid, this.isDeal).then((result) => {
      this.showLenderLogo = result;
    });
  }

  getLogoURL(id: number) {
    var found = this.urlLenders.find((x) => x.Id == id);

    if (found) {
      return found.LogoURL;
    } else {
      return "";
    }
  }

  sendEnquiry(): void {
    this.enquiryOpen = false;
  }

  goToDealResults(dealCriteria: DealDTO, shortlistingMore: boolean = false) {
    var route = shortlistingMore ? "shortlistmore" : "results";
    var enterpriseRoute = !this.isLoggedInUser && !shortlistingMore ? '/e' : '';

    if (this.dealService.isDevelopmentFinanceDealDto(dealCriteria)) {
      this.$location.path(enterpriseRoute + "/devfinance" + route + "/" + this.searchid);
    }

    if (this.dealService.isBridgingDealDto(dealCriteria)) {
      console.log(enterpriseRoute + "/bridging" + route + "/" + this.searchid);
      this.$location.path(enterpriseRoute + "/bridging" + route + "/" + this.searchid);
    }

    if (this.dealService.isCommercialDealDto(dealCriteria)) {
      this.$location.path(enterpriseRoute + "/commercial" + route + "/" + this.searchid);
    }
  }

  goToResults(): void {
    //Clearing the all lsd data(except 'LoanCriteria') saved for this view.
    sessionStorage.removeItem("TempSavedResults");
    sessionStorage.removeItem("SelectedUser");
    sessionStorage.removeItem("UniqueId");

    if (this.caseid && this.searchid) {
      this.caseService.fetch(this.caseid).then((result) => {
        if (
          result.CaseStatus == CaseStatusEnum.ReadyToReSubmit ||
          result.CaseStatus == CaseStatusEnum.SubmittedToLendersForHoT
        ) {
          this.$location.path(
            "/shortlistmore/" + this.searchid + "/" + this.caseid,
          );
        } else {
          if (this.isDeal) {
            this.goToDealResults(this.criteria as DealDTO);
          } else {
            this.$location.path(
              "/results/" + this.searchid + "/" + this.caseid,
            );
          }
        }
      });
    } else if (this.searchid || this.isDeal) {
      if (this.isDeal) {
        sessionStorage.setItem(
          "ComparisonList",
          JSON.stringify(this.selectedCandidates),
        );

        if (sessionStorage.getItem("DealClientUniqueRef")) {
          this.$location.path(
            "/referredsearchdeal/" +
            sessionStorage.getItem("DealClientUniqueRef") + "/false",
          );
          sessionStorage.removeItem("DealClientUniqueRef");
        } else {
          const dealCriteria = this.criteria as DealDTO;
          if (
            dealCriteria.Status == CaseStatusEnum.ReadyToReSubmit ||
            dealCriteria.Status == CaseStatusEnum.SubmittedToLendersForHoT
          ) {
            var shortlistingMore = true;
            this.goToDealResults(dealCriteria, shortlistingMore);
          } else {
            this.goToDealResults(dealCriteria);
          }
        }
      } else {
        this.$location.path("/results/" + this.searchid);
      }
    } else {
      if (this.uniqueid) {
        this.$location.path("/referredSearch/" + this.uniqueid);
      } else {
        this.$location.path("/results");
      }
    }
  }

  go(path): void {
    this.$location.path(path);
  }

  hideLenderName(label) {
    let newLabel = label.replace(/ *\([^)]*\) */g, "");
    return newLabel;
  }

  getProductLastVerifiedDates(productIds: number[]) {
    this.productService.getLastVerifiedDates(productIds).then((response) => {
      productIds.forEach(id => {
        if (response[id] != null) {
          this.productLastVerifiedDates[id] = this.formatDate(response[id]);
        }
      })
    });
  }

  formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };
}

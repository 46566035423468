import angular from "angular";
import { UserService } from "./services/UserService";
import { CaseStatusEnum } from "./models/enum/CaseStatusEnum.cs.d";

interface RequiresRolesOption {
  name: string;
  disable: boolean;
  hide: boolean;
}

export function registerRequireRoleDirective() {
  const ccqdirectives = angular.module("Ccq.directives");

  ccqdirectives.directive("requiresRolesTERMINATED", [
    "UserService",
    "$rootScope",
    (userService: UserService, $rootScope: ng.IRootScopeService) => {
      return {
        restrict: "A",
        controller: function ($scope, $element, $attrs) {
          $scope.userService = userService;
          this.requiresRoles = ($scope as any).requiresRoles;
          this.requiresRolesOptions = ($scope as any).requiresRolesOptions;
        },
        scope: {
          requiresRoles: "=",
          matchAllRoles: "=",
          minimumStateRequired: "=",
          clickedEvent: "@",
          disableIfNotInRole: "=",
          requiresRolesOptions: "=",
        },
        link: function (
          scope: angular.IScope,
          element: JQLite,
          attr: angular.IAttributes,
          ctrl: angular.IController,
        ) {
          const el: HTMLElement = angular.element(element)[0];
          const $rootScope = scope.$root;
          const $userService = (scope as any).userService;

          let requiresRoles: string[] | string = (scope as any).requiresRoles;
          let requiresRolesOptions: RequiresRolesOption[] = (scope as any)
            .requiresRolesOptions;
          const matchAllRoles: Boolean = (scope as any).matchAllRoles || false;
          let disableIfNotInRole: Boolean =
            (scope as any).disableIfNotInRole || false; // defines a behaviour - ability to be disabled

          let originalStyles = {
            cursor: el.style.cursor,
            pointerEvents: el.style.pointerEvents,
            opacity: el.style.opacity,
            display: el.style.display,
          };

          const permitted = function (): void {
            if (disableIfNotInRole) {
              performPermitted(false, true); // enable - if permitted and can be disabled
            } else {
              performPermitted(); // show button - if permitted and can be hidden
            }
          };

          const notPermitted = function (): void {
            if (disableIfNotInRole) {
              performNotPermitted(false, true); //button disabled - if permitted and can be disabled
            } else {
              performNotPermitted(); // hidden - if permitted and can be hidden
            }
          };

          const performPermitted = function (
            hideIfNotPermitted: Boolean = true,
            disableIfNotPermitted: Boolean = false,
          ): void {
            if (disableIfNotPermitted) {
              //enable
              el.style.cursor = originalStyles.cursor;
              el.style.pointerEvents = originalStyles.pointerEvents;
              el.style.opacity = originalStyles.opacity;
            }

            if (hideIfNotPermitted) {
              //show
              el.style.display = originalStyles.display;
            }
          };

          const performNotPermitted = function (
            hideIfNotPermitted: Boolean = true,
            disableIfNotPermitted: Boolean = false,
          ): void {
            if (disableIfNotPermitted) {
              //disable
              el.style.cursor = "not-allowed";
              el.style.pointerEvents = "none";
              el.style.opacity = "0.8";
            }

            if (hideIfNotPermitted) {
              //hide
              el.style.display = "none";
            }
          };

          const executeOptions = function (option: RequiresRolesOption): void {
            if (option.disable) {
              el.style.cursor = "not-allowed";
              el.style.pointerEvents = "none";
              el.style.opacity = "0.8";
              el.style.display = originalStyles.display;
            }

            if (option.hide) {
              el.style.display = originalStyles.display;
            }
          };

          const updateRoles = (
            requiresRoles: string[] | string,
            requiresRolesOptions: RequiresRolesOption[],
            currentUserRoles: string[],
          ): void => {
            // We may pass in a string or an array of strings. If it's jsut a single string, let's put it into an array so we can use the same handling either way.
            if (typeof requiresRoles === "string") {
              const role = requiresRoles;
              requiresRoles = [requiresRoles];
            }

            if (requiresRoles && currentUserRoles) {
              if (!matchAllRoles) {
                let matchFound = false;
                for (let i = 0; i < requiresRoles.length; i++) {
                  let role = requiresRoles[i];
                  var matchingGroup = currentUserRoles.find(
                    (group) => group.toLowerCase() === role.toLowerCase(),
                  );
                  if (matchingGroup) {
                    permitted();
                    matchFound = true;
                    break;
                  }
                  if (!matchFound) {
                    notPermitted();
                  }
                }
              } else {
                let matchFound = true;
                for (let i = 0; i < requiresRoles.length; i++) {
                  let role = requiresRoles[i];
                  var matchingGroup = currentUserRoles.find(
                    (group) => group === role,
                  );
                  if (!matchingGroup) {
                    matchFound = false;
                    notPermitted();
                    break;
                  }
                }
                if (matchFound) {
                  permitted();
                }
              }
            }

            // requiresRolesOptions will always override anything applied as part of requiresRoles
            if (requiresRolesOptions && currentUserRoles) {
              for (let i = 0; i < requiresRolesOptions.length; i++) {
                let roleOption = requiresRolesOptions[i];
                var matchingGroup = currentUserRoles.find(
                  (group) =>
                    group.toLowerCase() === roleOption.name.toLowerCase(),
                );

                if (matchingGroup) {
                  executeOptions(roleOption);
                  break;
                }
              }
            }
          };

          // Setup defaults; we should assume we don't have access then grant it if we find we do.
          notPermitted();

          // Listen for updates to status.
          $rootScope.$on(
            "caseStateChange",
            function (
              event,
              statusUpdate: { caseId: number; caseStatus: CaseStatusEnum },
            ) {},
          );

          // Listen for updates to user roles.
          $rootScope.$on(
            "userRolesChange",
            function (event, userRoles: string[]) {
              updateRoles(requiresRoles, requiresRolesOptions, userRoles);
            },
          );

          if (($rootScope as any).currentUser) {
            updateRoles(
              requiresRoles,
              requiresRolesOptions,
              ($rootScope as any).currentUser.Roles,
            );
          } else {
            $userService.getcurentuserrecord().then(
              function (response) {
                ($rootScope as any).currentUser = response;
                updateRoles(
                  this.requiresRoles,
                  this.requiresRolesOptions,
                  ($rootScope as any).currentUser.Roles,
                );
              }.bind({
                requiresRoles: (scope as any).requiresRoles,
                requiresRolesOptions: (scope as any).requiresRolesOptions,
              }),
            );
          }

          //el.onclick = (event: Event) => {
          //    if (clickPermitted) {
          //        event.preventDefault();
          //        event.stopPropagation();
          //        return false;
          //    }
          //};
        },
      };
    },
  ]);
}

export class CcqGlobalSearchBarComponent implements ng.IComponentOptions {

    public transclude: boolean = true;

    public bindings: any;
    public controller: any;
    public templateUrl: string;

    constructor() {
        this.bindings = {
            placeholder: '@',
            search: '&',
            selectItem: '&'

        };
        this.controller = CcqGlobalSearchBarController;
        this.templateUrl = 'js/dist/components/globalsearchbar/globalsearchbar.html';
    }
}

export class CcqGlobalSearchBarController {
    public placeholder: string;
    public search: (any) => ng.IPromise<any[]>;
    public selectItem: (any) => ng.IPromise<any[]>;

    public needle: string;
    public items: any[];
    public selectedItem: any;
    public selectedIndex: number;

    public meterCount: number = 0;
    public customerCount: number = 0;
    public agencyCount: number = 0;
    public siteCount: number = 0;


    static $inject = ['$parse'];
    constructor(private $parse: ng.IParseService) {

    }

    highlightItem(index: number) {
        if (!this.items) {
            return;
        }
    }

    keyUp($event: KeyboardEvent) {
        if ($event.keyCode === 13) {
            this.selectByIndex();
        }
        else if ($event.keyCode === 38) {
            if (this.selectedIndex > 0) {
                this.selectedIndex--;
            }
        }
        else if ($event.keyCode === 40) {
            if (this.selectedIndex === undefined) {
                this.selectedIndex = 0;
            }

        }

        this.highlightItem(this.selectedIndex);
    }

    updateSearch() {
        if (!this.needle) {
            delete this.items;
            return;
        }

        this.search({ needle: this.needle }).then((response) => {
            this.items = response;

        });
    }

    selectByIndex() {

    }

    select(item: any, type: string) {
        delete this.items;
        this.selectItem({ item: item });
    }
}



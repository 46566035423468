import { BridgingHeadsOfTermsController } from "@js/controllers/BridgingHeadsOfTermsController";
import { CommercialHeadsOfTermsController } from "@js/controllers/CommercialHeadsOfTermsController";
import { DevFinanceHeadsOfTermsController } from "@js/controllers/DevFinanceHeadsOfTermsController";
import { CaseLenderChangedMessageDTO } from "@js/DTO/CaseLenderChangedMessageDTO.cs.d";
import { DealLenderDTO } from "@js/DTO/Deal/DealLenderDTO.cs.d";
import {
  BridgingSubmitOfferRequest,
  BridgingSubmitOfferResponse,
} from "@js/DTO/Messages/Deal/BridgingSubmitOfferMessage.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class DealLenderService extends BaseService<DealLenderDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/deallender";
    this.$broadcastBusinessNameSingular = "deallender";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchByDealId(
    DealId: number,
    debug2?: boolean,
    DealLenderId?: number,
  ): ng.IPromise<DealLenderDTO[]> {
    let defer = this.$q.defer<DealLenderDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/fetchByDealId?DealId=" +
          DealId +
          "&debug2=" +
          debug2 +
          "&DealLenderId=" +
          DealLenderId,
      )
      .then((response) => {
        defer.resolve(response.data as DealLenderDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchByDealId",
          "Fetchby deal id failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchDealLender(DealId: number): ng.IPromise<DealLenderDTO> {
    let defer = this.$q.defer<DealLenderDTO>();
    this.$http
      .get(this.$baseAddress + "/fetchdeallender?DealId=" + DealId)
      .then((response) => {
        defer.resolve(response.data as DealLenderDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchDealLender",
          "fetch Deal Lender  failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  getSelectedCaseLender(Id: number): ng.IPromise<DealLenderDTO> {
    let defer = this.$q.defer<DealLenderDTO>();
    this.$http
      .get(this.$baseAddress + "/selectedcaselender?Id=" + Id)
      .then((response) => {
        defer.resolve(response.data as DealLenderDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "getSelectedCaseLender",
          "get selected case lender failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  submitOffer(
    request: BridgingSubmitOfferRequest,
  ): ng.IPromise<BridgingSubmitOfferResponse> {
    let defer = this.$q.defer<BridgingSubmitOfferResponse>();
    this.$http
      .put(this.$baseAddress + "/submitoffer", JSON.stringify(request))
      .then((response) => {
        defer.resolve(response.data as BridgingSubmitOfferResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  applyToLender(
    dealId: number,
    dealLenderId: number,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .get(
        this.$baseAddress +
          "/apply?dealId=" +
          dealId +
          "&dealLenderId=" +
          dealLenderId,
      )
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  cancelApplication(
    dealId: number,
    dealLenderId: number,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .get(
        this.$baseAddress +
          "/cancel?dealId=" +
          dealId +
          "&dealLenderId=" +
          dealLenderId,
      )
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendProceedDipEmails(
    dealId: number,
    dealLenderId: number,
  ): ng.IPromise<void> {
    let defer = this.$q.defer<void>();

    this.$http
      .get(
        this.$baseAddress +
          "/sendproceeddipemails?dealId=" +
          dealId +
          "&dealLenderId=" +
          dealLenderId,
      )
      .then((response) => {
        defer.resolve(response.data as void);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  rejectCase(
    dealLender: DealLenderDTO,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .put(this.$baseAddress + "/reject", JSON.stringify(dealLender))
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  withdrawHoT(
    dealLender: DealLenderDTO,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .put(this.$baseAddress + "/withdraw", JSON.stringify(dealLender))
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  shortlistMoreLenders(
    dealId: number,
    selectedLenderList: DealLenderDTO[],
    hasCriteriaChanged: boolean,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .put(
        this.$baseAddress +
          "/shortlistmorelenders?dealId=" +
          dealId +
          "&hasCriteriaChanged=" +
          hasCriteriaChanged,
        selectedLenderList,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  addLenderPortalLinkDetails(
    dealLenderId: number,
    borrowerDetails: UserSimpleDTO,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .put(
        this.$baseAddress +
          "/addlenderportallinkdetails?dealLenderId=" +
          dealLenderId,
        borrowerDetails,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  submitToDeallocker(dealLenderId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http.get(this.$baseAddress + "/submittodeallocker?dealLenderId=" + dealLenderId).then((response) => {
        defer.resolve(response.data as boolean);
    }).catch((response) => {
        defer.reject(response);
    });
    return defer.promise;
}

updateShortlistedLenders(selectedLenderList: DealLenderDTO[]): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http.put(this.$baseAddress + "/updateshortlistedlenders", selectedLenderList).then((response) => {
        defer.resolve(response.data as boolean);
    }).catch((response) => {
        defer.reject(response);
    });
    return defer.promise;
}


fetchDealLendersByDealId(
  DealId: number
): ng.IPromise<DealLenderDTO[]> {
  let defer = this.$q.defer<DealLenderDTO[]>();
  this.$http
    .get(
      this.$baseAddress +
        "/fetchdeallendersbydealId?DealId=" +
        DealId
    )
    .then((response) => {
      defer.resolve(response.data as DealLenderDTO[]);
    })
    .catch((response) => {
      this.$broadcastservice.broadcastError(
        "fetchdeallendersbydealId",
        "Fetch DealLender by deal id failed",
      );
      defer.reject(response);
    });
  return defer.promise;
}

  dummyDIP(controller) {
    const devFin = controller instanceof DevFinanceHeadsOfTermsController;
    const bridging = controller instanceof BridgingHeadsOfTermsController;
    const commercial = controller instanceof CommercialHeadsOfTermsController;

    if (devFin || bridging) {
      controller.currentCaseLender.TotalGrossLoan = this.getRandomIntInclusive(
        2000,
        1000000,
      );
      controller.calculateGrossLoanValVal();
      controller.currentCaseLender.BuildLoan = this.getRandomIntInclusive(
        2000,
        controller.currentCaseLender.TotalGrossLoan,
      );
      controller.calculateBuildLoadCalcVal();
    }

    if (devFin) {
      controller.currentCaseLender.LandLoan = this.getRandomIntInclusive(
        2000,
        controller.currentCaseLender.BuildLoan,
      );
      controller.calculateLandLoanCalcVal();
    }

    if (commercial) {
      controller.currentCaseLender.TotalNetLoan = this.getRandomIntInclusive(
        1000,
        999999,
      );
      controller.currentCaseLender.LoanToValue =
        this.getRandomIntInclusive(0, 100) * 0.01;
      controller.currentCaseLender.LenderArrangementFee =
        this.getRandomIntInclusive(100, 1000);
      controller.calculatemonetaryLAPercent();
      controller.currentCaseLender.ProductDuration = this.getRandomIntInclusive(
        1,
        15,
      );
    } else {
      controller.calculateTotalNetLoan();
    }

    if (bridging) {
      controller.currentCaseLender.TotalNetLoan = this.getRandomIntInclusive(
        2000,
        999999,
      );
      controller.calculateBorrowerEquity();
    }

    if (devFin || bridging) {
      controller.currentCaseLender.LenderArrangementFee =
        this.getRandomIntInclusive(100, 1000);
      controller.calculatemonetaryLAPercent();
      controller.calculatemonetaryLAFee();
    }

    if (bridging) {
      controller.currentCaseLender.MonthlyInterestRate =
        this.getRandomIntInclusive(1, 10) * 0.01;
      controller.calculatMonthlyInterestRate();
    }

    controller.currentCaseLender.InterestRate =
      this.getRandomIntInclusive(1, 10) * 0.01;

    if (devFin || bridging) {
      controller.currentCaseLender.InterestRateType =
        this.getRandomIntInclusive(1, 2);
      var types = ["own SVR", "SONIA"];
      controller.currentCaseLender.BasisOfInterestRate =
        types[this.getRandomIntInclusive(0, 1)];
    } else {
      controller.currentCaseLender.MonthlyMortgagePayment =
        this.getRandomIntInclusive(200, 9999);
      controller.currentCaseLender.EarlyRepaymentCharge = "Year 1 - 5%";
      controller.currentCaseLender.LoanTerm = this.getRandomIntInclusive(
        controller.currentCaseLender.ProductDuration,
        30,
      );
    }

    if (bridging) {
      controller.currentCaseLender.MinimumInterestPeriod =
        this.getRandomIntInclusive(1, 12);
    }

    if (devFin || bridging) {
      controller.currentCaseLender.LenderExitFee = this.getRandomIntInclusive(
        100,
        5000,
      );
      controller.calculatemonetaryLEPercent();
      controller.calculatemonetaryLEFee();

      controller.calculateTrueCost();
      controller.currentCaseLender.ProfessionalFeesEstimate =
        this.getRandomIntInclusive(100, 5000);
      controller.currentCaseLender.LenderAdditionalRequirements = "none";
    }

    if (bridging) {
      controller.currentCaseLender.LoanTerm = this.getRandomIntInclusive(
        1,
        600,
      );
    }
  }
  
}

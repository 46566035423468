import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { RoleService } from "@js/services/RoleService";
import {UserService} from "@js/services/UserService";

export class DashboardController {
  $scope: any;
  $cookies: any;
  $location: ng.ILocationService;
  $http: ng.IHttpService;
  user: ApplicationUserDTO;
  webServer: boolean = false;
  isLenderVisible: boolean;
  isTestBannerHidden: boolean;
  cardColours: string[] = [
    "card-colour-1",
    "card-colour-2",
    "card-colour-3",
    "card-colour-4",
    "card-colour-5",
    "card-colour-6",
  ];

  static $inject = [
    "$scope",
    "$cookies",
    "$location",
    "$http",
    "$rootScope",
    "RoleService",
    "CaseMemberService",
    "AuthService",
    "UserService"
  ];

  constructor(
    scope: ng.IScope,
    cookies: any,
    location: ng.ILocationService,
    http: ng.IHttpService,
    private $rootScope: ng.IRootScopeService,
    protected roleService: RoleService,
    private caseMember: CaseMemberService,
    private $auth: AuthService,
    private userService: UserService
  ) {
    this.$scope = scope;
    this.$cookies = cookies;
    this.$location = location;
    this.$http = http;
    this.isLenderVisible = this.roleService.getIsLenderVisible();

    // Used for test site to keep banner hidden
    if (localStorage.getItem("isTestBannerHidden")) {
      if (localStorage.getItem("isTestBannerHidden") == "true") {
        this.isTestBannerHidden = true;
      } else {
        this.isTestBannerHidden = false;
      }
    } else {
      this.isTestBannerHidden = false;
    }

    if (!this.roleService.IsAdminUser) {
      this.go("/userdashboard");
    }

    this.caseMember.getWebConfigValue().then((result) => {
      this.webServer = result;
    });
  }

  toggleHidden() {
    localStorage.setItem(
      "isTestBannerHidden",
      this.isTestBannerHidden.toString(),
    );
    if (this.isTestBannerHidden) {
      this.roleService.isTestBannerHidden = true;
    } else {
      this.roleService.isTestBannerHidden = false;
    }
  }

  logout() {
    delete this.$cookies.token;
    delete this.$cookies.loggedUser;
    delete this.$cookies.loggedUserID;
    delete this.$cookies.tokenValidTo;
    this.$location.path("/login");
  }

  newloan() {
    (this.$rootScope as any).loanCriteria = null;
    this.$location.path("/criteria/0/0/1");
  }
  go(path) {
    this.$location.path(path);
  }

  hideLenderName(isLenderVisible: boolean) {
    this.roleService.setIsLenderVisible(isLenderVisible);
  }

  sendTestEmail() {
    this.userService.sendTestEmailToUsers();
  }

}

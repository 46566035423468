import angular from "angular";
import { AccountsController } from "./controllers/AccountsController";
import { AccountSettingsController } from "./controllers/AccountSettingsController";
import { AgentLandingController } from "./controllers/AgentLandingController";
import { AllLoansLandingController } from "./controllers/AllLoansLandingController";
import { ApplicantController } from "./controllers/ApplicantController";
import { ApplicantDetailsController } from "./controllers/ApplicantDetailsController";
import { ApplicationDetailsController } from "./controllers/ApplicationDetailsController";
import { AssignLicensesController } from "./controllers/AssignLicensesController";
import { BorrowerTermsController } from "./controllers/BorrowerTermsController";
import { BridgingCaseController } from "./controllers/BridgingCaseController";
import { BridgingCriteriaController } from "./controllers/BridgingCriteriaController";
import { BridgingHeadsOfTermsController } from "./controllers/BridgingHeadsOfTermsController";
import { BridgingLenderResultScreenController } from "./controllers/BridgingLenderResultsScreenController";
import { CaseController } from "./controllers/CaseController";
import { CaseLenderController } from "./controllers/CaseLenderController";
import { CaseOverviewController } from "./controllers/CaseOverviewController";
import { ClientDashboardController } from "./controllers/ClientDashboardController";
import { CommercialCaseController } from "./controllers/CommercialCaseController";
import { CommercialComparisonController } from "./controllers/CommercialComparisonController";
import { CommercialCriteriaController } from "./controllers/CommercialCriteriaController";
import { CommercialHeadsOfTermsController } from "./controllers/CommercialHeadsOfTermsController";
import { CommercialLenderResultScreenController } from "./controllers/CommercialLenderResultsScreenController";
import { ComparisonController } from "./controllers/ComparisonController";
import { DashboardController } from "./controllers/DashboardController";
import { DealLenderController } from "./controllers/DealLenderController";
import { DealSecurityCheckController } from "./controllers/DealSecurityCheckController";
import { DeveloperExitFinanceComparisonController } from "./controllers/DeveloperExitFinanceComparisonController";
import { DeveloperExitFinanceDevelopmentInputController } from "./controllers/DeveloperExitFinanceDevelopmentInputController";
import { DeveloperExitFinanceDevScheduleInputController } from "./controllers/DeveloperExitFinanceDevScheduleInputController";
import { DeveloperExitFinanceLoanInputController } from "./controllers/DeveloperExitFinanceLoanInputController";
import { DeveloperExitFinanceResultController } from "./controllers/DeveloperExitFinanceResultController";
import { DevelopmentAppraisalScreenController } from "./controllers/DevelopmentAppraisalScreenController";
import { DevelopmentExperienceController } from "./controllers/DevelopmentExperienceController";
import { DevelopmentInputController } from "./controllers/DevelopmentInputController";
import { DevelopmentInputScheduleController } from "./controllers/DevelopmentInputScheduleController";
import { DevelopmentInputScreenController } from "./controllers/DevelopmentInputScreenController";
import { DevelopmentInputWithNoLoginController } from "./controllers/DevelopmentInputWithNoLoginController";
import { DevelopmentTrackRecordController } from "./controllers/DevelopmentTrackRecordController";
import { DevFinanceCaseController } from "./controllers/DevFinanceCaseController";
import { DevFinanceCriteriaController } from "./controllers/DevFinanceCriteriaController";
import { DevFinanceHeadsOfTermsController } from "./controllers/DevFinanceHeadsOfTermsController";
import { DevFinanceLenderResultScreenController } from "./controllers/DevFinanceLenderResultsScreenController";
import { EnquiryController } from "./controllers/EnquiryController";
import { EnterpriseLeadCaptureController } from "./controllers/EnterpriseLeadCaptureController";
import { FeaturePriceController } from "./controllers/FeaturePriceController";
import { FeedBackController } from "./controllers/FeedBackController";
import { FileAttachmentController } from "./controllers/FileAttachmentController";
import { HeadsOfTermsController } from "./controllers/HeadsOfTermsController";
import { IndexController } from "./controllers/IndexController";
import { InitialRegistrationController } from "./controllers/InitialRegistrationController";
import { IntroducerController } from "./controllers/IntroducerController";
import { LandingController } from "./controllers/LandingController";
import { LandingV3Controller } from "./controllers/LandingV3Controller";
import { LeadGenPromoController } from "./controllers/LeadGenPromoController";
import { LenderController } from "./controllers/LenderController";
import { LenderResultScreenController } from "./controllers/LenderResultScreenController";
import { LoginController } from "./controllers/LoginController";
import { ManageLicenseController } from "./controllers/ManageLicenseController";
import { NewDashboardController } from "./controllers/NewDashboardController";
import { NewSearchSelectionsController } from "./controllers/NewSearchSelectionsController";
import { OrganisationsController } from "./controllers/OrganisationsController";
import { PayFailController } from "./controllers/PayFailController";
import { PaymentController } from "./controllers/PaymentController";
import { PaySucessController } from "./controllers/PaySucessController";
import { PrivacyController } from "./controllers/PrivacyController";
import { ProductController } from "./controllers/ProductController";
import { ProductStubController } from "./controllers/ProductStubController";
import { ProfileController } from "./controllers/ProfileController";
import { ReferFriendController } from "./controllers/ReferFriendController";
import { RegistrationBrokerController } from "./controllers/RegistrationBrokerController";
import { RegistrationController } from "./controllers/RegistrationController";
import { RegistrationIntroducerController } from "./controllers/RegistrationIntroducerController";
import { RoleController } from "./controllers/RoleController";
import { SecurityCheckController } from "./controllers/SecurityCheckController";
import { TermsOfBusinessController } from "./controllers/TermsOfBusinessController";
import { TrackRecordController } from "./controllers/TrackRecordController";
import { UserController } from "./controllers/UserController";
import { UserDashboardController } from "./controllers/UserDashboardController";
import { UserLicenseController } from "./controllers/UserLicenseController";
import { WelcomeController } from "./controllers/WelcomeController";
import { UnsubscribeEmailController } from "./controllers/UnsubscribeEmailController";

export function registerControllers() {
  angular
    .module("ccqapp")
    .controller("AccountsController", AccountsController)
    .controller("AccountSettingsController", AccountSettingsController)
    .controller("AgentLandingController", AgentLandingController)
    .controller("AllLoansLandingController", AllLoansLandingController)
    .controller("ApplicantController", ApplicantController)
    .controller("ApplicantDetailsController", ApplicantDetailsController)
    .controller("ApplicationDetailsController", ApplicationDetailsController)
    .controller("AssignLicensesController", AssignLicensesController)
    .controller("BorrowerTermsController", BorrowerTermsController)
    .controller("BridgingCaseController", BridgingCaseController)
    .controller("BridgingCriteriaController", BridgingCriteriaController)
    .controller(
      "BridgingHeadsOfTermsController",
      BridgingHeadsOfTermsController,
    )
    .controller(
      "BridgingLenderResultScreenController",
      BridgingLenderResultScreenController,
    )
    .controller("CaseController", CaseController)
    .controller("CaseLenderController", CaseLenderController)
    .controller("CaseOverviewController", CaseOverviewController)
    .controller("ClientDashboardController", ClientDashboardController)
    .controller("CommercialCaseController", CommercialCaseController)
    .controller(
      "CommercialComparisonController",
      CommercialComparisonController,
    )
    .controller("CommercialCriteriaController", CommercialCriteriaController)
    .controller(
      "CommercialHeadsOfTermsController",
      CommercialHeadsOfTermsController,
    )
    .controller(
      "CommercialLenderResultScreenController",
      CommercialLenderResultScreenController,
    )
    .controller("ComparisonController", ComparisonController)
    .controller("DashboardController", DashboardController)
    .controller("DealLenderController", DealLenderController)
    .controller("DealSecurityCheckController", DealSecurityCheckController)
    .controller(
      "DeveloperExitFinanceComparisonController",
      DeveloperExitFinanceComparisonController,
    )
    .controller(
      "DeveloperExitFinanceDevelopmentInputController",
      DeveloperExitFinanceDevelopmentInputController,
    )
    .controller(
      "DeveloperExitFinanceDevScheduleInputController",
      DeveloperExitFinanceDevScheduleInputController,
    )
    .controller(
      "DeveloperExitFinanceLoanInputController",
      DeveloperExitFinanceLoanInputController,
    )
    .controller(
      "DeveloperExitFinanceResultController",
      DeveloperExitFinanceResultController,
    )
    .controller(
      "DevelopmentAppraisalScreenController",
      DevelopmentAppraisalScreenController,
    )
    .controller(
      "DevelopmentExperienceController",
      DevelopmentExperienceController,
    )
    .controller("DevelopmentInputController", DevelopmentInputController)
    .controller(
      "DevelopmentInputScheduleController",
      DevelopmentInputScheduleController,
    )
    .controller(
      "DevelopmentInputScreenController",
      DevelopmentInputScreenController,
    )
    .controller(
      "DevelopmentInputWithNoLoginController",
      DevelopmentInputWithNoLoginController,
    )
    .controller(
      "DevelopmentTrackRecordController",
      DevelopmentTrackRecordController,
    )
    .controller("DevFinanceCaseController", DevFinanceCaseController)
    .controller("DevFinanceCriteriaController", DevFinanceCriteriaController)
    .controller(
      "DevFinanceHeadsOfTermsController",
      DevFinanceHeadsOfTermsController,
    )
    .controller(
      "DevFinanceLenderResultScreenController",
      DevFinanceLenderResultScreenController,
    )
    .controller("EnquiryController", EnquiryController)
    .controller("FeaturePriceController", FeaturePriceController)
    .controller("FeedBackController", FeedBackController)
    .controller("FileAttachmentController", FileAttachmentController)
    .controller("HeadsOfTermsController", HeadsOfTermsController)
    .controller("IndexController", IndexController)
    .controller("InitialRegistrationController", InitialRegistrationController)
    .controller("IntroducerController", IntroducerController)
    .controller("LandingController", LandingController)
    .controller("LandingV3Controller", LandingV3Controller)
    .controller("LeadGenPromoController", LeadGenPromoController)
    .controller("LenderController", LenderController)
    .controller("LenderResultScreenController", LenderResultScreenController)
    .controller("LoginController", LoginController)
    .controller("ManageLicenseController", ManageLicenseController)
    .controller("NewDashboardController", NewDashboardController)
    .controller("NewSearchSelectionsController", NewSearchSelectionsController)
    //        .controller("OrganisationLinkController", OrganisationLinkController)
    .controller("OrganisationsController", OrganisationsController)
    .controller("PayFailController", PayFailController)
    .controller("PaymentController", PaymentController)
    .controller("PaySucessController", PaySucessController)
    .controller("PrivacyController", PrivacyController)
    .controller("ProductController", ProductController)
    .controller("ProductStubController", ProductStubController)
    .controller("ProfileController", ProfileController)
    .controller("ReferFriendController", ReferFriendController)
    .controller("RegistrationBrokerController", RegistrationBrokerController)
    .controller("RegistrationController", RegistrationController)
    .controller(
      "RegistrationIntroducerController",
      RegistrationIntroducerController,
    )
    .controller("RoleController", RoleController)
    .controller("SecurityCheckController", SecurityCheckController)
    .controller("TermsOfBusinessController", TermsOfBusinessController)
    .controller("TrackRecordController", TrackRecordController)
    .controller("UserController", UserController)
    .controller("UserDashboardController", UserDashboardController)
    .controller("UserLicenseController", UserLicenseController)
    .controller("WelcomeController", WelcomeController)
    .controller(
      "EnterpriseLeadCaptureController",
      EnterpriseLeadCaptureController,
    ).controller("UnsubscribeEmailController", UnsubscribeEmailController);
}

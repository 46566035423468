export class ModalService {
  isModalOpen: boolean = false;

  constructor() {}

  getIsModalOpen() {
    return this.isModalOpen;
  }

  setIsModalOpen(isModalOpen) {
    this.isModalOpen = isModalOpen;
  }
}

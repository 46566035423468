import { FeaturePriceService } from "@js/services/FeaturePriceService";

export class FeaturePriceController {
  //processing: boolean = false;
  //selectedFeaturePriceData: FeaturePriceDTO[];
  //showFilter: boolean;
  //newFeaturePrice: FeaturePriceDTO;
  //tempFeaturePrice: FeaturePriceDTO;
  //addOrEditForm: ng.IFormController;

  //selectedFeature: any;
  //currentDate: Date = new Date();
  //newFeature = false;
  //editFeature = false;
  //messageDeleteModal = false;
  //showInvalidStartDate = false;
  //showInvalidEndDate = false;
  //showAllFeaturePrice = false;
  //startDateErrorMsg: string = null;
  //endDateErrorMsg: string = null;

  //features: Array<{ id: number, name: string }> = [{ id: 1, name: 'Development Finance' }, { id: 2, name: 'Bridging Finance' }];

  static $inject = [
    "$routeParams",
    "$rootScope",
    "$location",
    "FeaturePriceService",
  ];
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private featurePriceService: FeaturePriceService,
  ) {}

  //    getSelectedFeaturePrice() {
  //        this.featurePriceService.getFeaturePriceByFeature(this.selectedFeature.id, this.showAllFeaturePrice).then((response) => {
  //            this.selectedFeaturePriceData = response;
  //        })
  //    }

  //    onFeatureChange(featureSelected) {
  //        this.showAllFeaturePrice = false;
  //        this.selectedFeature = featureSelected;
  //        this.getSelectedFeaturePrice();
  //    }

  //    createFeaturePrice() {
  //        this.newFeaturePrice = { Feature: this.selectedFeature.id } as FeaturePriceDTO;
  //        this.newFeature = true;
  //    }

  //    /**
  //     * Function to save the newly added feature price.
  //     * @param featurePrice
  //     */
  //    saveFeaturePrice(featurePrice: FeaturePriceDTO) {
  //        if (featurePrice.EndDateTime == null) {
  //            var found = this.selectedFeaturePriceData.find(function (featurePrice) {
  //                return featurePrice.EndDateTime == null;
  //            });

  //            if (found) {
  //                this.endDateErrorMsg = "You already have default price for this feature,Please select a end date."
  //                this.showInvalidEndDate = true;
  //                return;
  //            }
  //        }

  //        featurePrice.EndDateTime == null ? featurePrice.IsDefault = true : featurePrice.IsDefault = false;
  //        this.processing = true;
  //        this.featurePriceService.addUpdatereturnonlyid(featurePrice).then((response) => {
  //            this.processing = false;
  //            this.newFeature = false;
  //            this.editFeature = false;
  //            this.showInvalidEndDate = false;
  //            if (!this.newFeaturePrice.Id) {
  //                this.selectedFeaturePriceData.push(this.newFeaturePrice);
  //            } else {
  //                this.selectedFeaturePriceData = this.selectedFeaturePriceData.filter(price => price.Id != this.newFeaturePrice.Id);
  //                this.selectedFeaturePriceData.push(this.newFeaturePrice);
  //            }
  //            this.newFeaturePrice = undefined;
  //        }).catch((response) => {
  //            this.processing = false;
  //        });
  //    }

  //    /**
  //     * Function to edit the selected feature price.
  //     * @param featurePrice
  //     */
  //    editFeaturePrice(featurePrice: FeaturePriceDTO) {
  //        this.newFeaturePrice = { ...featurePrice };
  //        this.editFeature = true;
  //    }

  //    /**
  //     * Function to confirm selected feature price for deletion.
  //     * @param featurePrice
  //     */
  //    confirmRemoveFeaturePrice(featurePrice: FeaturePriceDTO) {
  //        this.tempFeaturePrice = { ...featurePrice };
  //        this.messageDeleteModal = true;
  //    }

  //    /**
  //     * Function to mark the selected feature price as deleted.
  //     * @param featurePrice
  //     */
  //    removeFeaturePrice(featurePrice: FeaturePriceDTO) {
  //        this.featurePriceService.markasdeleted(featurePrice.Id).then((response) => {
  //                if (response) {
  //                    this.selectedFeaturePriceData = this.selectedFeaturePriceData.filter(price => price.Id != featurePrice.Id);
  //                    this.messageDeleteModal = false;
  //                }
  //        });
  //    }

  //    /**
  //     * Function to check for valid start date..
  //     * @param endDate
  //     */

  //    CheckForValidStartdate(startdate) {
  //        var found = this.selectedFeaturePriceData.find(function (featurePrice) {
  //            return startdate >= featurePrice.StartDateTime && startdate <= featurePrice.EndDateTime;
  //        });
  //        console.log(startdate == this.currentDate);
  //        if (startdate != this.currentDate && startdate < this.currentDate) {
  //            this.startDateErrorMsg = "Start date should be greater or equal to today's date."
  //            this.showInvalidStartDate = true
  //        } else if (found) {
  //            this.startDateErrorMsg = "Start date should not fall between other feature price dates."
  //            this.showInvalidStartDate = true
  //        } else {
  //            this.startDateErrorMsg = null;
  //            this.showInvalidStartDate = false;
  //        }

  //    }

  //    /**
  //     * Function to check for valid end date..
  //     * @param endDate
  //     */

  //    CheckForValidEnddate(enddate) {
  //        var found = this.selectedFeaturePriceData.find(function (featurePrice) {
  //            return enddate <= featurePrice.StartDateTime && enddate >= featurePrice.EndDateTime ;
  //        });

  //        if (enddate != null && enddate < this.currentDate) {
  //            this.endDateErrorMsg = "End date should be greater or equal to today's date."
  //            this.showInvalidEndDate = true
  //        } else if (enddate != null && enddate < this.newFeaturePrice.StartDateTime) {
  //            this.endDateErrorMsg = "End date should be greater or equal to start date."
  //            this.showInvalidEndDate = true
  //        } else if (found) {
  //            this.endDateErrorMsg = "End date should not fall between other feature price dates."
  //            this.showInvalidEndDate = true;
  //        } else {
  //            var defaultval = this.selectedFeaturePriceData.find(function (featurePrice) {
  //                return featurePrice.EndDateTime == null && featurePrice.Id != this.newFeaturePrice.Id;
  //            });

  //            if (defaultval) {
  //                if (this.newFeaturePrice.StartDateTime >= defaultval.StartDateTime) {
  //                    this.endDateErrorMsg = "Selected start and end date is covered in other default feature price dates."
  //                    this.showInvalidEndDate = true;
  //                }

  //            } else {
  //                this.startDateErrorMsg = null;
  //                this.showInvalidEndDate = false;
  //            }
  //        }
  //    }

  //    closeModal() {
  //        this.newFeature = false;
  //        this.editFeature = false;
  //        this.showInvalidEndDate = false;
  //        this.showInvalidStartDate = false;
  //    }
}

import { CommercialDealDTO } from "@js/DTO/Deal/CommercialDealDTO.cs.d";
import { CommercialSearchResultsResponse } from "@js/DTO/Messages/Deal/CommercialSearchResultMessage.cs.d";
import { SearchResultsRequest } from "@js/DTO/Messages/Deal/SearchResultMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class CommercialSearchResultsService extends BaseService<
  CommercialDealDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/commercialsearchresults";
    this.$broadcastBusinessNameSingular = "commercialsearchresults";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  /**
   * Gets the search criteria and the search results based on the search criteria ID passed in
   * @param criteriaId
   * @param criteriaChanged - has the search criteria chaged? Used for snapshotting.
   * @param limit - the number of search results to display TODO - is this needed for search results????
   * @param debug1
   * @param debug2
   */
  getSearchCriteriaAndResultsByDealId(
    dealId: number,
    criteriaChanged: boolean = false,
    limit: number = 0,
    debug1: boolean = false,
    debug2: boolean = false,
  ): ng.IPromise<CommercialSearchResultsResponse> {
    var request: SearchResultsRequest = {
      DealId: dealId,
      DealClientUniqueRef: null,
      DealUniqueRef: null,
      ReturnSearchCriteriaDTO: true,
      CriteriaChanged: criteriaChanged,
      Limit: limit, // TODO is this needed for search results??
      Debug1: debug1,
      Debug2: debug2,
    };

    return this.getSearchCriteriaAndResults(request);
  }

  /**
   * Gets the search criteria and the search results based on the DealClient UniqueRef passed in
   * @param criteriaId
   * @param limit - the number of search results to display TODO - is this needed for search results????
   * @param debug1
   * @param debug2
   */
  getSearchCriteriaAndResultsByDealClientUniqueRef(
    dealClientUniqueRef: string,
    criteriaChanged: boolean = false,
    limit: number = 0,
    debug1: boolean = false,
    debug2: boolean = false,
  ): ng.IPromise<CommercialSearchResultsResponse> {
    var request: SearchResultsRequest = {
      DealClientUniqueRef: dealClientUniqueRef,
      DealUniqueRef: null,
      ReturnSearchCriteriaDTO: true,
      CriteriaChanged: criteriaChanged,
      Limit: limit, // TODO is this needed for search results??
      Debug1: debug1,
      Debug2: debug2,
    };

    return this.getSearchCriteriaAndResults(request);
  }

  /**
   * Gets the search criteria and the search results based on the search unique ref passed in
   * @param dealUniqueref
   * @param criteriaChanged - has the search criteria chaged? Used for snapshotting.
   * @param limit - the number of search results to display TODO - is this needed for search results????
   * @param debug1
   * @param debug2
   */
  getSearchCriteriaAndResultsByDealUniqueRef(
    dealUniqueref: string,
    criteriaChanged: boolean = false,
    limit: number = 0,
    debug1: boolean = false,
    debug2: boolean = false,
  ): ng.IPromise<CommercialSearchResultsResponse> {
    var request: SearchResultsRequest = {
      DealId: null,
      DealUniqueRef: dealUniqueref,
      DealClientUniqueRef: null,
      ReturnSearchCriteriaDTO: true,
      CriteriaChanged: criteriaChanged,
      Limit: limit, // TODO is this needed for search results??
      Debug1: debug1,
      Debug2: debug2,
    };

    return this.getSearchCriteriaAndResults(request);
  }

  /**
   * Fetches the Commercial Search based on the request passed in
   * @param request
   */
  private getSearchCriteriaAndResults(
    request: SearchResultsRequest,
  ): ng.IPromise<CommercialSearchResultsResponse> {
    let defer = this.$q.defer<CommercialSearchResultsResponse>();

    this.$http
      .post(
        this.$baseAddress + "/getsearchcriteriaandresults",
        JSON.stringify(request),
      )
      .then((response) => {
        var res = response.data as CommercialSearchResultsResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

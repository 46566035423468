
export class UserDashboardService {
    NavMenuEnum = {
        Home: 0,
        DevelopmentFinance: 1,
        BridgingFinance: 2,
        Clients: 3,
        CommercialMortgages: 4,
        EquityAndMezz: 5
    }

    goToNavMenu: number = null;

    constructor() {}

    getGoToNavMenu() {
        return this.goToNavMenu;
    }

    setGoToNavMenu(navMenu: number) {
        this.goToNavMenu = navMenu;
    }

    resetGoToNavMenu() {
        this.goToNavMenu = null;
    }
}


import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { SaveEnterpriseClientAndRenameSearchRequest, SaveEnterpriseClientAndRenameSearchResponse } from "@js/DTO/Messages/Deal/SaveEnterpriseClientAndRenameSearchMessage.cs.d";
import { LinkTypeEnum } from "@js/models/enum/LinkTypeEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { DealService } from "@js/services/Deal/DealService";
import { EventLogService } from "@js/services/EventLogService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationService } from "@js/services/OrganisationService";
import { ProductService } from "@js/services/ProductService";
import { UserService } from "@js/services/UserService";
import { EnterpriseService } from "@js/services/EnterpriseService";
import { AuthService } from "@js/services/AuthService";
import { ClientService } from "@js/services/ClientService";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs";

declare const window: Window & { dataLayer: any[] };

export class EnterpriseLeadCaptureController {
  loadingData: boolean = false;
  resultsCount: Number;
  enterpriseClient: ApplicationUserDTO;
  projectName: string;
  errorMessage: string = "";
  emailExistsError: boolean = false;
  dealDetails: SaveEnterpriseClientAndRenameSearchResponse;
  showContactForm = false;
  orgCode: string;
  enterpriseLinkLogo: string;
  totalLenders: number = null;
  isWidget: boolean = false;
  isMobileView: boolean = false;
  iFrame: boolean = false;
  organisationLinkId: number = null;
  enterpriseLinkType: string;
  leadCaptureForm: ng.IFormController;
  showEnterpriseRegistrationModal: boolean = false;
  showRegisterModal: boolean = false;
  showLoginSection: boolean = false;

  // Logging in/resetting password
  isLoginError: boolean = false;
  isResetPasswordSubmitted: boolean = false;
  registrationLoginError: string = null;

  acceptTerms: boolean = false;

  hasEnterpriseClient:boolean = false;
  phoneNumber:number;
  newUrl: string;
  message: string;
  showMessageModal: boolean = false;
  organisation: OrganisationDTO;
  static $inject = [
    "$rootScope",
    "$routeParams",
    "$location",
    "$cookies",
    "OrganisationService",
    "DealService",
    "UserService",
    "ProductService",
    "LenderService",
    "EventLogService",
    "EnterpriseService",
    "AuthService",
    "$q",
    "ClientService"
  ];

  constructor(
    public $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $cookies: ng.cookies.ICookiesService,
    private organisationService: OrganisationService,
    private dealService: DealService,
    private userService: UserService,
    private productService: ProductService,
    private lenderService: LenderService,
    private eventLogService: EventLogService,
    private enterpriseService: EnterpriseService,
    private $auth: AuthService,
    protected $q: ng.IQService,
    private clientService: ClientService
  ) {


    if (window.self == window.top) {
      this.orgCode = this.$cookies.get("org_code");
    } else {
      this.orgCode = location.pathname.replace("/", "");
    }

    this.getSearchResultsCountByUniqueRefAndLogPageLoadEvent();
    this.getNumberOfLenders();

    if (window.self == window.top) {
      this.enterpriseLinkType = sessionStorage.getItem("enterpriseLinkType");
    } else {

      this.organisationService.getData("enterpriseLinkType").then((linkType) => {
        if (linkType) {
          this.enterpriseLinkType = linkType;
        }
      }).catch((error) => {
        console.error("Failed to get user data: ", error);
      });
    }

    if (this.$routeParams.context == 'widget' && window.self != window.top) {
      document.getElementById("footer").style.display = "none";
      this.isWidget = true;
    } else if (window.innerWidth <= 480 && window.self == window.top) {
      this.isMobileView = true;
    } else if (window.self != window.top) {
      this.iFrame = true;
    }

  }

  getNumberOfLenders() {
    this.lenderService.getTotalLenders().then((totalLenders: number) => {
      this.totalLenders = Math.floor(totalLenders / 5) * 5;

      const trustboxRef = document.getElementById("trustbox");

      if (trustboxRef && (window as any).Trustpilot) {
        (window as any).Trustpilot.loadFromElement(trustboxRef);
      }
    });
  }

  getSearchResultsCountByUniqueRefAndLogPageLoadEvent() {
    this.loadingData = true;
    this.errorMessage = "";
    this.dealService
      .getSearchResultsCountByUniqueRefAndLogPageLoadEvent(this.$routeParams.uniqueRef, this.orgCode)
      .then((response) => {
        this.resultsCount = response.TotalResultsCount;
        this.organisationLinkId = response.DealUniqueLinkId;
        var imgs = document.getElementsByTagName("img");
        this.enterpriseLinkLogo = this.organisationLinkId != null && response.DealUniqueLinkLogoUrl? response.DealUniqueLinkLogoUrl : imgs[0].src;
        this.projectName = response.DealName;
        this.organisation = response.Organisation;
        if(response.EnterpriseClient){
          this.hasEnterpriseClient = true;
          this.enterpriseClient = {} as ApplicationUserDTO;
          this.enterpriseClient.FullName = `${response.EnterpriseClient.FirstName} ${response.EnterpriseClient.LastName}`;
          if(response.EnterpriseClient.PhoneNumber) this.phoneNumber = Number(response.EnterpriseClient.PhoneNumber);
          this.enterpriseClient.Email = response.EnterpriseClient.Email;
        }
        this.showContactForm = true;
      })
      .catch((response) => {
        this.errorMessage = "Error while loading page, please try later.";
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  onViewLoansClicked() {
    this.loadingData = true;
    this.errorMessage = "";
    this.emailExistsError = false;
    this.userService.checkEmailExists(this.enterpriseClient.Email).then((response) => {
      if (!response) {
        this.saveEnterpriseClientAndRenameDeal();
      } else {
        this.emailExistsError = true;
        this.loadingData = false;
      }
    });
  }


  saveEnterpriseClientAndRenameDeal(isLogin: boolean = false): ng.IPromise<SaveEnterpriseClientAndRenameSearchResponse> {
    let defer = this.$q.defer<SaveEnterpriseClientAndRenameSearchResponse>();
    let userFullName = this.enterpriseClient.FullName;
    let spaceIndex = userFullName.indexOf(" ");

    let firstName = "";
    let lastName = "";

    // if not space has been added to the name then put the whole name in the first name field
    if (spaceIndex == -1) {
      firstName = userFullName;
      lastName = userFullName;
    } else {
      firstName = userFullName.substring(0, userFullName.indexOf(" "));
      lastName = userFullName.substring(userFullName.indexOf(" ") + 1);
    }

    // Set up Client dto
    var clientDto = {
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: this.phoneNumber?.toString(),
      Email: this.enterpriseClient.Email,
      PostalAddress: {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress,
    } as ClientDTO;

    var request: SaveEnterpriseClientAndRenameSearchRequest = {
      DealUniqueref: this.$routeParams.uniqueRef,
      DealNewName: this.projectName,
      ClientDto: clientDto,
    };

    this.dealService
      .saveEnterpriseClientAndRenameDeal(request)
      .then((response) => {
        if (response) {
          this.dealDetails = response;
          if (window.self == window.top) {
            sessionStorage.setItem(
              "clientId",
              this.dealDetails.ClientId.toString(),
            );
          } else {
            this.organisationService.sendDataToParent(
              "clientId",
              this.dealDetails.ClientId.toString(),
            );
          }

          // For Google analytics tracking
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "results_gating_form",
            loan_product_family:
              this.productService.getProductFamilyTextForGa(
                this.dealDetails.ProductFamily,
              ),
            loan_product_type: this.productService.getProductTypeTextForGa(
              this.dealDetails.ProductType,
              this.dealDetails.IsFamilyInResidence,
            ),
          });

          if (!isLogin) {
            this.userService.sendEventToHubspot(
              this.enterpriseClient,
              "ENTERPRISE-LEAD-DETAILS-COLLECTED",
              false,
              this.orgCode,
              "Client",
              this.dealDetails.OrganisationLinkId != null
                ? this.dealDetails.OrganisationLinkId
                : 0,
              this.dealDetails.ProductType,
              this.dealDetails.DealId,
              null,
              this.dealDetails.ClientId.toString()
            );

            let resultPageurl = this.getResultsPageUrl();
            this.goToLink(resultPageurl, true);
          } 

          defer.resolve(response as SaveEnterpriseClientAndRenameSearchResponse);
        }
      }).catch((response) => {
        this.errorMessage = "Error while saving details, please try later.";
      })
      .finally(() => {
        this.loadingData = false;
      });

    return defer.promise;
  }

  getResultsPageUrl() {
    var url = "";
    switch (this.dealDetails.ProductFamily) {
      case ProductFamilyEnum.Bridging:
        url = `/e/bridgingresults/${this.$routeParams.uniqueRef}`;
        break;
      case ProductFamilyEnum.Commercial:
        url = `/e/commercialresults/${this.$routeParams.uniqueRef}`;
        break;
      case ProductFamilyEnum.Development:
        url = `/e/devfinanceresults/${this.$routeParams.uniqueRef}`;
        break;
      default:
        break;
    }
    return url;
  }

  showSignInModal() {
    if (!this.isWidget && !this.isMobileView && !this.iFrame) {
      this.enterpriseClient.UserName = this.enterpriseClient.Email;
      this.showEnterpriseRegistrationModal = true;
      this.showRegisterModal = true;
      this.showLoginSection = true;
      document.getElementById("body").style.overflow = "hidden";
    } else {
      this.saveEnterpriseClientAndRenameDeal(true).then((response) => {
        var redirectUrl = this.getLoginRedirectUrl();
        (this.$rootScope as any).loginRedirectPath = redirectUrl;
        this.goToLink(`/e/login/${this.dealDetails.ProductFamily}/${this.$routeParams.uniqueRef}/${this.dealDetails.ClientId}/${this.enterpriseClient.Email}`);
      });
    }
  }

  onClickLogin() {
    this.login();
  }

  login() {
    this.loadingData = true;
    if (!this.$cookies.get("access_token")) {
      this.$auth
        .login(
          this.enterpriseClient.UserName,
          this.enterpriseClient.Password,
          "CC022EBE67BE0424EA2A6548B062D2D71",
        )
        .then((response) => {
          let expiry: Date = response;
          this.isLoginError = false;

          this.userService.getcurentuserrecord().then((response) => {
            (this.$rootScope as any).currentUser = response;
            this.$cookies.put("user_firstname", response.FirstName, {
              expires: expiry,
            });
            (this.$rootScope as any).selectedUser = response;

            this.saveEnterpriseClientAndRenameDeal(true).then(() => {
              this.userService.sendEventToHubspot(
                this.enterpriseClient,
                "ENTERPRISE-LEAD-LOGGEDIN-FROM-LEADCAPTURE-PAGE",
                false,
                this.orgCode,
                "Client",
                this.dealDetails.OrganisationLinkId != null
                  ? this.dealDetails.OrganisationLinkId
                  : 0,
                this.dealDetails.ProductType,
                this.dealDetails.DealId,
                null,
                this.dealDetails.ClientId.toString()
              );

              // link user to the client record
              this.clientService.updateClientWithUserId(this.dealDetails.ClientId);

              this.$rootScope.$broadcast("login"); //tell everyone you have logged in
              var redirectUrl = this.getLoginRedirectUrl();
              this.$location.path(redirectUrl);
            })
          });
        })
        .catch((response) => {
          this.isLoginError = true;
          this.enterpriseClient.Password = null;
        })
        .finally(() => {
          this.loadingData = false;
        });
    } else {
      // User may have logged in on a different browser/tab
      this.saveEnterpriseClientAndRenameDeal(true).then(() => {
        var redirectUrl = this.getLoginRedirectUrl();
        this.$location.path(redirectUrl);
      });
    }
  }

  goToLink(url, isResultsLink = false) {
    var baseUrl = window.location.href.split("#!")[0] + "#!";
     this.newUrl = baseUrl + url;
     this.message = isResultsLink? 'view your results': 'sign in';

     if(isResultsLink) this.hasEnterpriseClient = true;

    var newWindow = window.open(this.newUrl, "_blank");

    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      this.showMessageModal = true;
    } 
  }

  openLink(){
    window.open(this.newUrl, "_blank");
    this.showMessageModal = false;
  }

  goToPreviousEnterprisePage() {

    if (this.enterpriseLinkType) {
      switch (Number(this.enterpriseLinkType)) {
        case LinkTypeEnum.Development:
          this.organisationLinkId != null ? this.$location.path(`/e/devfinancecriteria/0/${this.organisationLinkId}`) : this.$location.path("/e/devfinancecriteria");
          break;
        case LinkTypeEnum.Bridging:
          this.organisationLinkId != null ? this.$location.path(`/e/bridgingcriteria/0/${this.organisationLinkId}`) : this.$location.path("/e/bridgingcriteria");
          break;
        case LinkTypeEnum.Commercial:
          this.organisationLinkId != null ? this.$location.path(`/e/commercialcriteria/0/${this.organisationLinkId}`) : this.$location.path("/e/commercialcriteria");
          break;
        case LinkTypeEnum.AllLoans:
          var path = "/allloans";

          if (this.organisationLinkId != null) {
            path = `/allloans/${this.organisationLinkId}`;            
          }

          if (this.isWidget) {
            path = this.organisationLinkId != null? `{path}/widget`: `${path}/0/widget`;
          }

          this.$location.path(path);
          break;
        default:
          break;
      }
    }
  }

  getLoginRedirectUrl(): string {
    var redirectUrl = '';

    switch (Number(this.enterpriseService.getProductFamily())) {
      case ProductFamilyEnum.Development:
        redirectUrl = `/devfinanceresults/${this.$routeParams.uniqueRef}`;
        break;
      case ProductFamilyEnum.Bridging:
        redirectUrl = `/bridgingresults/${this.$routeParams.uniqueRef}`;
        break;
      case ProductFamilyEnum.Commercial:
        redirectUrl = `/commercialresults/${this.$routeParams.uniqueRef}`;
        break;
      default:
        redirectUrl = '/userdashboard';
        break;
    }

    return redirectUrl;
  }



  /**Sends an email to the email address for the user to reset their password */
  sendResetPasswordEmail() {
    this.loadingData = true;
    this.$auth
      .resetPassword(this.enterpriseClient.Email)
      .then((response) => {
        this.isLoginError = false;
        this.isResetPasswordSubmitted = true;
        this.registrationLoginError = null;
      })
      .catch((error) => {
        this.registrationLoginError = error;
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  footerSignInClicked(){
    var redirectUrl = this.getLoginRedirectUrl();
    if(this.projectName){
      this.dealService.renameDeal(this.$routeParams.uniqueRef,this.projectName).then(() => {
        (this.$rootScope as any).loginRedirectPath = redirectUrl;
        if(this.dealDetails){
          this.goToLink(`/e/login/${this.dealDetails.ProductFamily}/${this.$routeParams.uniqueRef}`);
        }else{
          this.goToLink(`/e/login/${Number(this.enterpriseService.getProductFamily())}/${this.$routeParams.uniqueRef}`);
        }
      });
    }else{
      (this.$rootScope as any).loginRedirectPath = redirectUrl;
      this.goToLink(`/e/login/${Number(this.enterpriseService.getProductFamily())}/${this.$routeParams.uniqueRef}`);
    }
  }
}

import { CaseLenderChangedMessageDTO } from "@js/DTO/CaseLenderChangedMessageDTO.cs.d";
import { CaseLenderDTO } from "@js/DTO/CaseLenderDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";

export class CaseLenderService extends BaseService<CaseLenderDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/caselender";
    this.$broadcastBusinessNameSingular = "caselender";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  submitHoT(
    CaseLender: CaseLenderDTO,
    caseLenderState: CaseLenderStateEnum,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();
    this.$http
      .put(
        this.$baseAddress + "/submitoffer?caseLenderState=" + caseLenderState,
        JSON.stringify(CaseLender),
      )
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  rejectCase(
    CaseLender: CaseLenderDTO,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .put(this.$baseAddress + "/reject", JSON.stringify(CaseLender))
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  withdrawHoT(
    CaseLender: CaseLenderDTO,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .put(this.$baseAddress + "/withdraw", JSON.stringify(CaseLender))
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  accept(CaseLender: CaseLenderDTO): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .put(this.$baseAddress + "/accept", JSON.stringify(CaseLender))
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  cancel(CaseLender: CaseLenderDTO): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .put(this.$baseAddress + "/cancel", JSON.stringify(CaseLender))
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  applyToLender(
    CaseId: number,
    CaseLenderId: number,
    SendEmails: boolean = true,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .get(
        this.$baseAddress +
          "/apply?caseId=" +
          CaseId +
          "&caseLenderId=" +
          CaseLenderId +
          "&sendEmails=" +
          SendEmails,
      )
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  cancelApplication(
    caseId: number,
    caseLenderId: number,
  ): ng.IPromise<CaseLenderChangedMessageDTO> {
    let defer = this.$q.defer<CaseLenderChangedMessageDTO>();

    this.$http
      .get(
        this.$baseAddress +
          "/cancel?caseId=" +
          caseId +
          "&caseLenderId=" +
          caseLenderId,
      )
      .then((response) => {
        defer.resolve(response.data as CaseLenderChangedMessageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendFeedbackToBorrower(
    CaseLender: CaseLenderDTO,
    CaseOwner: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .put(
        this.$baseAddress + "/sendfeedbacktoborrower?caseOwner=" + CaseOwner,
        JSON.stringify(CaseLender),
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendFeedbackToLender(CaseLender: CaseLenderDTO): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .put(
        this.$baseAddress + "/sendfeedbacktolender",
        JSON.stringify(CaseLender),
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchByCaseId(
    CaseId: number,
    debug2?: boolean,
    CaseLenderId?: number,
  ): ng.IPromise<CaseLenderDTO[]> {
    let defer = this.$q.defer<CaseLenderDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/fetchByCaseId?CaseId=" +
          CaseId +
          "&debug2=" +
          debug2 +
          "&CaseLenderId=" +
          CaseLenderId,
      )
      .then((response) => {
        defer.resolve(response.data as CaseLenderDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchbycaseID",
          "Fetchby case id failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchCaseLender(CaseId: number): ng.IPromise<CaseLenderDTO> {
    let defer = this.$q.defer<CaseLenderDTO>();
    this.$http
      .get(this.$baseAddress + "/fetchcaselender?CaseId=" + CaseId)
      .then((response) => {
        defer.resolve(response.data as CaseLenderDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchCaseLender",
          "Fetch caselender  id failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  getSelectedCaseLender(
    Id: number,
    showLenderNames: boolean,
  ): ng.IPromise<CaseLenderDTO> {
    let defer = this.$q.defer<CaseLenderDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/selectedcaselender?Id=" +
          Id +
          "&showLenderNames?=" +
          showLenderNames,
      )
      .then((response) => {
        defer.resolve(response.data as CaseLenderDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "getSelectedCaseLender",
          "get selected case lender failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  getStatusTextForLender(
    caseLenderState: CaseLenderStateEnum,
    IsBidAccepted: boolean,
  ): string {
    var statusText = "";
    switch (caseLenderState) {
      case CaseLenderStateEnum.Offered: {
        statusText = IsBidAccepted
          ? "DIP submitted and accepted"
          : "DIP submitted";
        break;
      }
      case CaseLenderStateEnum.Received: {
        statusText = "Case to review";
        break;
      }
      case CaseLenderStateEnum.Rejected: {
        statusText = "Case rejected";
        break;
      }
      case CaseLenderStateEnum.Withdrawn: {
        statusText = "DIP withdrawn";
        break;
      }
      case CaseLenderStateEnum.Cancelled: {
        statusText = "Application cancelled";
        break;
      }
      case CaseLenderStateEnum.Applied: {
        statusText = "DIP submitted and accepted";
        break;
      }
    }

    return statusText;
  }
}

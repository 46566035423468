import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";

export class PayFailController {
  user: ApplicationUserDTO;
  performingAction: boolean;

  static $inject = ["$scope", "$rootScope", "$cookies", "$location"];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
  ) {}
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { LicenseMasterStatusEnum } from "@js/models/enum/LicenseMasterStatusEnum.cs.d";
import { AuthService } from "@js/services/AuthService";

export class LeadGenPromoController {
  static $inject = [
    "$routeParams",
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
  ];

  licenseCount: number = 0;
  showAddLaterButton: boolean = true;
  currentUser: ApplicationUserDTO;

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private authService: AuthService,
  ) {
    if (this.$routeParams.licensecount) {
      this.licenseCount = this.$routeParams.licensecount;
    }

    this.authService.getUpdatedProfile().then((prof) => {
      this.currentUser = prof;
    });
  }

  addLaterClicked() {
    if (
      this.licenseCount > 1 &&
      (this.currentUser.SubscriptionStatus == LicenseMasterStatusEnum.PaidUp ||
        this.currentUser.SubscriptionStatus ==
          LicenseMasterStatusEnum.PaymentProcessing)
    ) {
      this.$location.path("/assignlicenses");
    } else {
      this.$location.path("/userdashboard");
    }
  }

  bookCall() {
    window.open(
      "https://brickflow.com/brokers/brickflow-enterprise/book-a-demo",
    );
  }
}

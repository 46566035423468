import { PaymentService } from "@js/services/PaymentService";

export class ManageLicenseController {
  isCancelClicked: boolean = false;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "PaymentService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $paymentService: PaymentService,
  ) {}

  updatePrice() {
    this.$paymentService.updatePlanPrice().then((response) => {});
  }

  cancelSubscription() {
    this.$paymentService.cancelSubscription("").then((response) => {
      this.isCancelClicked = true;
    });
  }

  gotoProfilePage() {
    this.$location.path("/profile");
  }
}

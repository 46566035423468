export const enum LicenseMasterStatusEnum {
  None = 0,
  PaidUp = 1,
  PayFailure = 2,
  Unpaid = 3,
  Cancelled = 4,
  PreCancel = 5,
  PreSubscription = 6,
  PaymentProcessing = 7,
  Trial = 8,
  Offered = 9,
}

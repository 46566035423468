import { CaseLenderMessageDTO } from "@js/DTO/CaseLenderMessageDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class CaseLenderMessageService extends BaseService<
  CaseLenderMessageDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/caselendermessage";
    this.$broadcastBusinessNameSingular = "caselendermessage";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  updateCaseLenderMessageReadDatetime(
    caseLenderId: number,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/updatecaselendermessagereaddatetime?caseLenderId=" +
          caseLenderId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "updateCaseLenderMessageReadDatetime",
          "update CaseLender Message Read Datetime failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}

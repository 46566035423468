import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { LenderDTO } from "@js/DTO/LenderDTO.cs.d";
import { ImportProductResponse } from "@js/DTO/Messages/ImportProductMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { ProductDTO } from "@js/DTO/ProductDTO.cs.d";
import { LenderProductTypeEnum } from "@js/models/enum/LenderProductTypeEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationService } from "@js/services/OrganisationService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class LenderController {
  canSeeMultipleLenders: boolean = false;
  selectedSection: string;

  objects: LenderDTO[];
  selectedObject: LenderDTO;
  tempSelectedObject: LenderDTO;
  userSearchResults: ApplicationUserDTO[];
  //user: ApplicationUserDTO;
  userName: string;
  userSelected: string;
  fetchingResults: boolean;
  showFilter: boolean;
  editLogo: boolean = false;

  selectedObjectProduct: ProductDTO;

  logoURL: string = "";

  // Upload Logos

  fileUpload: FileAttachmentDTO[];
  fileUploadPlaning: FileAttachmentDTO[];
  fileUploadTeam: FileAttachmentDTO[];
  fileUploadProperties: FileAttachmentDTO[];
  uploadingFiles: FileUploadProgressDTO[];
  renamingFile: FileAttachmentDTO;

  newLenderPopup: boolean = false;

  fetchingObjects: boolean;
  fetchingObject: boolean;

  lenderForm: ng.IFormController;

  file: Blob;

  step: number;

  editLenderName: boolean = false;
  updateRating: boolean = false;
  updatePreferredBrokers: boolean = false;

  timestamp: Date;

  multiPartForm1: ng.IFormController;
  multiPartForm2: ng.IFormController;
  multiPartForm3: ng.IFormController;
  multiPartForm4: ng.IFormController;
  multiPartForm5: ng.IFormController;
  multiPartForm6: ng.IFormController;
  multiPartForm7: ng.IFormController;

  totalRate: number;

  //array describing form sections
  formSectionNames = [
    {
      label: "Lender Details",
      visible: true,
    },
    {
      label: "Overview",
      visible: true,
    },
    {
      label: "Location",
      visible: true,
    },
    {
      label: "Development Specs",
      visible: true,
    },
    {
      label: "Industry",
      visible: true,
    },
    {
      label: "Liabilities",
      visible: true,
    },
    {
      label: "Fees",
      visible: true,
    },
  ];

  showLenderUpload: boolean = false;
  addLenders: boolean = true;
  updateLenders: boolean = true;
  disableProducts: boolean = false;
  dataLoading: boolean = false;
  importFile: any = false;

  assignedLenderUsers: ApplicationUserDTO[];
  selectLenderUserId: string = null;
  selectedLenderUser: ApplicationUserDTO;
  showEditLenderUserModal: boolean = false;
  isEditLenderUser: boolean = false;
  lenderUserProductType: {}[] = [];
  isSavingLenderUser: boolean = false;

  confirmChangingLenderAdmin: boolean = false;
  confirmLenderAdminDeletion: boolean = false;
  displayMessage: string;
  deleteLenderUserClicked: boolean = false;
  lendersavemessage: string;
  error: string = "";
  filterType: string = "";

  showSimulatedResults = false;
  simulatedResponse: ImportProductResponse;
  productTypeOptions = [];
  productFamilyOptions = [];
  filteredProductTypeOptions = [];
  refurbLevelOptions = [];
  EPCRatingOptions = [];
  acceptableEWS1GradeTypeOptions = [];
  locationOptions = [];
  tradingEntityOptions = [];
  valuationMethodOptions = [];

  duplicateProductModal: boolean = false;
  productForDuplication: ProductDTO;
  duplicateProductIndex: number;
  productRef: string = "";

  organisations: OrganisationDTO[] = [];
  selectedBrokers: OrganisationDTO[] = [];
  isValidSelection: boolean;
  preferredPackagersText: string;
  modalTextChanged: boolean = false;

  portalLinkSettingChanged: boolean = false;
  currentPortalLinkSetting: boolean = false;

  showEditProductNotesAndSnapshot: boolean = false;
  editedLenderProductFamily: ProductFamilyEnum = null;

  minLastVerifiedDate: Date = new Date("Jan 01 1900");
  maxLastVerifiedDate: Date = null;

  static $inject = [
    "$routeParams",
    "$rootScope",
    "LenderService",
    "ProductService",
    "Upload",
    "$location",
    "RoleService",
    "UserService",
    "FileAttachmentService",
    "SelectListService",
    "OrganisationService",
    "$sce",
  ];
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $rootScope: ng.IRootScopeService,
    private $lenderservice: LenderService,
    private $productservice: ProductService,
    private $uploadservice: any,
    private $location: ng.ILocationService,
    private $roleservice: RoleService,
    private $user: UserService,
    private fileAttachmentService: FileAttachmentService,
    private selectListService: SelectListService,
    private organisationService: OrganisationService,
    private $sce: ng.ISCEService,
  ) {
    if (this.$roleservice.isBrokerOrABove()) {
      this.canSeeMultipleLenders = true;
    }

    this.organisationService.fetchAll().then((response) => {
      this.organisations = response;
      this.organisations.sort(function (a, b) {
        var textA = a.Name.toUpperCase();
        var textB = b.Name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    });

    this.updateObjects();
    //init
    this.step = 1;
    this.updatetimestamp();
    this.fetchAllUsers();
    this.userName = "";
    this.showFilter = false;

    this.productTypeOptions = this.selectListService.GetProductTypeOptions();
    this.productFamilyOptions =
      this.selectListService.GetProductFamilyOptions();
    this.refurbLevelOptions =
      this.selectListService.GetRefurbishmentLevelOptions(true);
    this.EPCRatingOptions = this.selectListService.GetEPCRating();
    this.acceptableEWS1GradeTypeOptions =
      this.selectListService.GetEWS1GradeType();
    this.locationOptions = this.selectListService.GetLocations();
    this.tradingEntityOptions =
      this.selectListService.GetBorrowingEntityTypeOptions();

    this.valuationMethodOptions = this.selectListService.GetValuationMethodOptions();
  }

  onFileSelect(files) {
    if (files.length > 0) {
      this.fileAttachmentService
        .uploadFileAndReturnURI(files)
        .then((result) => {
          this.selectedObject.LogoURL = result.FileLocation;
          // this.$lenderservice.addUpdate(this.selectedObject);
          this.getLogoURL(this.selectedObject.LogoURL);
          this.editLogo = true;
        });
    }
  }

  getLogoURL(url: string) {
    this.fileAttachmentService.getFileUri(url).then((result) => {
      this.logoURL = result;
    });
  }

  fetchAllUsers() {
    this.fetchingObject = true;
    this.$user
      .getLenderUsersUnassigned("")
      .then((response) => {
        this.userSearchResults = response;
      })
      .finally(() => {
        this.fetchingObject = false;
      });
  }

  fetchAssignedLenderUsers() {
    this.fetchingObject = true;
    this.$user
      .getUsersByLenderId(this.selectedObject.Id)
      .then((response) => {
        this.assignedLenderUsers = response;
      })
      .finally(() => {
        this.fetchingObject = false;
      });
  }

  changeUser() {
    this.showFilter = !this.showFilter;
  }

  userLookupById(userId: string): string {
    const found = this.userSearchResults.find((user) => user.Id === userId);

    if (found) {
      return (this.userName = found.UserName);
    } else {
      return (this.userName = "");
    }
  }

  updatetimestamp(): void {
    this.timestamp = new Date();
  }
  back(): void {
    if (this.step > 0) {
      this.step--;
    }
  }

  next(): void {
    if (this.step < 7) {
      this.step++;
    }
  }
  cancel(): void {
    this.$location.path("/lenders/");
  }

  private preSelectedItem() {
    if (this.$routeParams.Id) {
      let selectedObject = this.objects.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.objectId);
      });

      this.selectObject(selectedObject);
    }
  }

  updateIsEnabled(product: ProductDTO, isEnabled) {
    product.IsEnabled = isEnabled;
    this.$productservice.addUpdatereturnonlyid(product).then((result) => { });
  }

  selectObject(object: LenderDTO) {
    this.selectedBrokers = [];
    this.selectedObject = object;
    this.fetchAssignedLenderUsers();
    this.selectedSection = "details";
    this.editLenderName = false;
    this.userSelected = "";
    this.userLookupById(object.UserId);
    this.preferredPackagersText = this.selectedObject.PreferredPackagersText;

    if (this.selectedObject.LogoURL) {
      this.getLogoURL(this.selectedObject.LogoURL);
    } else {
      this.logoURL = "";
    }

    this.currentPortalLinkSetting = this.selectedObject.UsePortalUrl;

    this.getPreferredOrgs();
  }

  createObject() {
    //Add new lender
    this.selectedObject = {
      Products: [] as ProductDTO[],
      HasLiveMarketSearch: true,
      HasDevelopmentFinanceSearch: true,
      HasBridgingSearch: true,
      HasCommercialSearch: true,
      IsLenderReferralActive: true,
    } as LenderDTO;
    this.editLenderName = true;
    this.currentPortalLinkSetting = false;
  }

  userLookup(userSearchTerm: string) {
    if (userSearchTerm === "") {
      this.fetchAllUsers();
    } else {
      this.fetchingResults = true;
      this.$user
        .getLenderUsersUnassigned(userSearchTerm)
        .then((response) => {
          this.userSearchResults = response;
        })
        .finally(() => {
          this.fetchingResults = false;
        });
    }
  }

  updateLenderUser(id: string) {
    this.selectedObject.UserId = id;
    this.$lenderservice
      .addUpdate(this.selectedObject)
      .then((response) => { })
      .finally(() => {
        this.showFilter = false;
        delete this.userSelected;
      });
  }

  editLender(SelectedLender: LenderDTO) {
    this.editLenderName = true;
    this.editLogo = false;
    this.tempSelectedObject = { ...SelectedLender };
  }

  cancelLenderUpdate(SelectedLender: LenderDTO) {
    this.selectedObject = this.tempSelectedObject;
    this.getPreferredOrgs();
    this.getLogoURL(this.selectedObject.LogoURL);
    this.preferredPackagersText = this.selectedObject.PreferredPackagersText;
    this.editLenderName = false;
    this.error = "";
  }

  saveLender(newLender: boolean = false) {
    if (
      this.selectedObject.UsePortalUrl &&
      (this.selectedObject.PortalUrl == null ||
        this.selectedObject.PortalUrl.length == 0)
    ) {
      this.editLenderName = true;
      this.error = "If portal link is enabled, please include a portal link.";
      return;
    }

    if (this.portalLinkSettingChanged) {
      if (!this.currentPortalLinkSetting && this.selectedObject.UsePortalUrl) {
        this.selectedObject.PortalUrlActiveDateTime = new Date();
      }
      if (this.currentPortalLinkSetting && !this.selectedObject.UsePortalUrl) {
        this.selectedObject.PortalUrlActiveDateTime = null;
      }
    }
    const existingSelection =
      this.selectedObject && this.selectedObject.Id ? true : false;
    const duplicateLender = this.objects.find(
      (x) => x.Name == this.selectedObject.Name,
    );
    if (
      (!duplicateLender || newLender == false) &&
      this.fetchingObjects == false
    ) {
      this.fetchingObjects = true;
      this.selectedObject.PreferredPackagersText = this.preferredPackagersText;
      this.$lenderservice
        .addUpdatereturnonlyid(this.selectedObject)
        .then((id) => {
          this.selectedObject.Id = id;
          //this.selectedObject.UserId = this.user.Id;
          if (!existingSelection) {
            this.objects.push(this.selectedObject);
          }

          const orgIds = this.selectedBrokers.map((broker) => broker.Id);

          this.currentPortalLinkSetting = this.selectedObject.UsePortalUrl;

          return this.$lenderservice.updatePreferredOrgs(
            this.selectedObject.Id,
            orgIds,
          );
        })
        .finally(() => {
          this.editLenderName = false;
          this.fetchingObjects = false;
          this.updateRating = false;
          this.updatePreferredBrokers = false;
          this.error = "";
          this.editedLenderProductFamily = null;
          this.showEditProductNotesAndSnapshot = false;
          this.lenderForm.$setPristine();
        });
    } else {
      this.editLenderName = false;
      this.error = "";
      this.selectedObject = duplicateLender;
    }
  }

  updateObjects() {
    this.fetchingObjects = true;

    // Fetch all objects.
    this.$lenderservice
      .fetchAll()
      .then((response) => {
        this.objects = response;
        if (this.filterType != "") {
          //TODO: need to fix this to use product type instead
          this.objects = this.objects.filter((lender) => {
            return lender.Products.some((product) => {
              return product.LI_LoanFamily == Number(this.filterType);
            });
          });
        }

        if (!this.canSeeMultipleLenders && this.objects.length > 0) {
          //need to select first item as no ability to select
          this.selectObject(this.objects[0]);
        }
        this.preSelectedItem();
      })
      .finally(() => {
        this.fetchingObjects = false;
        this.editLenderName = false;
      });
  }

  delete() {
    if (this.fetchingObjects !== true) {
      this.fetchingObjects = true;
      this.$lenderservice
        .markasdeleted(this.selectedObject.Id)
        .then((response) => {
          if (response) {
            this.objects.splice(this.objects.indexOf(this.selectedObject), 1);
            this.lenderForm.$setPristine();
            delete this.selectedObject;
          }
        })
        .finally(() => {
          this.fetchingObjects = false;
        });
    }
  }

  uploadProductFile(file, isSimulated) {
    this.dataLoading = true;
    let url =
      "/api/lender/importproducts?addLender=" +
      this.addLenders +
      "&updateLender=" +
      this.updateLenders +
      "&disableProducts=" +
      this.disableProducts +
      "&isSimulated=" +
      isSimulated;
    this.$uploadservice
      .upload({
        url: url,
        data: { file: file },
      })
      .then(
        (response) => {
          if (isSimulated) {
            this.simulatedResponse = response.data;
            this.showSimulatedResults = true;
          } else {
            this.updateObjects();
            this.showLenderUpload = false;
            this.showSimulatedResults = false;
          }
          this.dataLoading = false;
        },
        (response) => {
          // console.log('Error status: ' + resp.status);
        },
        (event) => {
          //            $scope.linkedInUploadProgress = 100.0 * parseFloat(evt.loaded) / parseFloat(evt.total);
          //          console.log('progress: ' + $scope.linkedInUploadProgress + '% ' + evt.config.data.file.name);
        },
      );
  }

  formChanged(): void {
    (this.$rootScope as any).formSaved = false;
  }

  removeProduct(product: ProductDTO) {
    if (product.Id > 0) {
      this.$productservice.markasdeleted(product.Id).then((response) => {
        if (response) {
          this.selectedObject.Products.splice(
            this.selectedObject.Products.indexOf(product),
            1,
          );
          this.selectObject(this.selectedObject);
          this.lenderForm.$setPristine();
        }
      });
    } else {
      //never saved so just remove
      this.selectedObject.Products.splice(
        this.selectedObject.Products.indexOf(product),
        1,
      );
      this.selectedObjectProduct = null;
      this.selectObject(this.selectedObject);
      this.lenderForm.$setPristine();
    }
  }

  createProduct() {
    this.step = 1;
    this.selectedObjectProduct = {
      LenderId: this.selectedObject.Id,
      LI_LoanProduct: ProductTypeEnum.Development,
    } as ProductDTO;
  }

  selectProduct(productId: number) {
    this.step = 1;
    this.$productservice.fetch(productId).then((response) => {
      this.selectedObjectProduct = response;
      this.maxLastVerifiedDate = response.LastVerifiedDate;
      //this.totalRate = this.selectedObjectProduct.LI_Dev_TotRate * 100;
      //     this.selectedLocations = this.selectedProduct.Location.split(", ");
    });
  }

  saveProduct(product: ProductDTO) {
    this.$productservice
      .isProductRefUnique(product.ProductRef, product.Id)
      .then((result) => {
        if (!result) {
          this.error = "Product Reference is not unique";
          return;
        }
        this.fetchingObject = true;
        // product.Location = this.selectedLocations.join(",");
        if (this.selectedObject.Id) {
          // product.LI_Dev_TotRate = this.totalRate / 100;
          this.$productservice
            .addUpdatereturnonlyid(product)
            .then((response) => {
              product.Id = response;
              this.fetchingObject = false;

              // Find the product in the selectedObject.Products list
              let existingProductIndex = this.selectedObject.Products.findIndex(
                (p) => p.Id === product.Id,
              );

              if (existingProductIndex !== -1) {
                // Update the existing product if found
                this.selectedObject.Products[existingProductIndex] = product;
              } else {
                // Otherwise, add the new product to the list
                this.selectedObject.Products.push(product);
              }

              this.selectedObjectProduct = undefined;
            })
            .catch((response) => {
              this.fetchingObject = false;
            });
          if (!this.selectedObjectProduct.Id) {
            this.selectedObject.Products.push(this.selectedObjectProduct);
          }
        } else {
          this.fetchingObject = false;
          this.lenderForm.$setDirty();
        }
      });
  }

  duplicateProduct(
    product: ProductDTO,
    index: number,
    productRef: string = "",
  ) {
    let newProduct = {} as ProductDTO;
    (Object as any).assign(newProduct, product);

    delete newProduct.Id;
    delete newProduct.LastUpdatedDateTime;
    delete newProduct.CreatedDateTime;
    delete newProduct.IsEnabled;

    this.$productservice.isProductRefUnique(productRef).then((result) => {
      //If producRef is not unique (or error) then do not duplicate product
      if (!result) {
        this.error = "Product Reference is not unique";
        return;
      }

      newProduct.ProductRef = productRef;

      this.selectedObject.Products.splice(index, 0, newProduct);

      this.$productservice.addUpdatereturnonlyid(newProduct).then((result) => {
        this.selectedObject.Products[index].Id = result;
      });

      this.error = "";
      this.duplicateProductModal = false;
    });
  }

  sum(a: number, b: number): number {
    return a + b;
  }

  carriageReturnCheck(event) {
    if (event.key === 'Enter') {
      this.selectedObjectProduct.AdditionalProductInfo += '\n';
    }
  }

  lenderKeyDown(event, field: string) {
    if (event.key === "Enter") {
      let position = event.target.selectionStart;
      if (this.selectedObject[field]) {
        this.selectedObject[field] = [this.selectedObject[field].slice(0, position), "\n", this.selectedObject[field].slice(position)].join("");

        setTimeout(() => {
          event.target.setSelectionRange(position + 1, position + 1);
        }, 0);
      } else {
        this.preferredPackagersText = "\n";
      }
    }
  }

  updateCustomerServiceRating(star) {
    if (this.selectedObject.RatingCustomerService == 1 && star == 1) {
      this.selectedObject.RatingCustomerService = 0;
      this.updateRating = true;
    } else {
      this.selectedObject.RatingCustomerService = star;
      this.updateRating = true;
    }
  }

  updateSpeedRating(star) {
    if (this.selectedObject.RatingSpeed == 1 && star == 1) {
      this.selectedObject.RatingSpeed = 0;
      this.updateRating = true;
    } else {
      this.selectedObject.RatingSpeed = star;
      this.updateRating = true;
    }
  }

  updateFlexibilityRating(star) {
    if (this.selectedObject.RatingFlexibility == 1 && star == 1) {
      this.selectedObject.RatingFlexibility = 0;
      this.updateRating = true;
    } else {
      this.selectedObject.RatingFlexibility = star;
      this.updateRating = true;
    }
  }

  updateUserRating(star) {
    if (this.selectedObject.RatingUserRating == 1 && star == 1) {
      this.selectedObject.RatingUserRating = 0;
      this.updateRating = true;
    } else {
      this.selectedObject.RatingUserRating = star;
      this.updateRating = true;
    }
  }

  addClass(star: number, id: string) {
    let elementId = "";
    for (let i = 1; i <= star; i++) {
      elementId = id + i;
      document.getElementById(elementId).classList.add("selected");
    }
  }

  removeClass(star: number, id: string, rating) {
    let elementId = "";
    if (rating == undefined) {
      rating = 0;
    }
    for (let i = star; i > rating; i--) {
      elementId = id + i;
      document.getElementById(elementId).classList.remove("selected");
    }
  }

  removeLenderLogo() {
    this.selectedObject.LogoURL = null;
    this.logoURL = null;
    this.lenderForm.$setDirty();
  }

  //calculateTotalRate() {
  //    this.totalRate = parseFloat(((this.selectedObjectProduct.LI_Margin * 100) + (this.selectedObjectProduct.LI_CoF * 100)).toFixed(2));
  //}

  totalRateValue() {
    return parseFloat(
      (
        this.selectedObjectProduct.LI_Margin * 100 +
        this.selectedObjectProduct.LI_CoF * 100
      ).toFixed(2),
    );
  }

  selectLenderUser(lenderUser: ApplicationUserDTO) {
    if (lenderUser) {
      this.selectedLenderUser = { ...lenderUser };
      this.initLenderUserProductType();

      this.isEditLenderUser = true;
    } else {
      this.selectedLenderUser = {} as ApplicationUserDTO;
      this.isEditLenderUser = false;
    }

    this.showEditLenderUserModal = true;
  }

  isLenderUserEmpty() {
    for (let key in this.selectedLenderUser) {
      if (this.selectedLenderUser.hasOwnProperty(key) && key !== "$$hashKey") {
        return false;
      }
    }
    return true;
  }

  initLenderUserProductType() {
    if (!this.selectedLenderUser.LenderProductTypes) {
      this.selectedLenderUser.LenderProductTypes = 0;
    } else {
      for (let i = 1; i >= 0; i *= 2) {
        if (this.selectedLenderUser.LenderProductTypes >= i) {
          //number is in enum sum, add it to object
          if (this.selectedLenderUser.LenderProductTypes & i) {
            this.lenderUserProductType[i] = true;
          } else {
            this.lenderUserProductType[i] = false;
          }
        } else {
          return;
        }
      }
    }
  }

  updateLenderUserProductTypes(item: LenderProductTypeEnum) {
    this.selectedLenderUser.LenderProductTypes =
      this.selectedLenderUser.LenderProductTypes > 0
        ? this.selectedLenderUser.LenderProductTypes
        : 0;

    if (item == LenderProductTypeEnum.All) {
      if (this.lenderUserProductType[item] == false) {
        this.selectedLenderUser.LenderProductTypes -= item;
      } else {
        this.lenderUserProductType.forEach((value, index) => {
          this.lenderUserProductType[index] = false;
        });

        this.selectedLenderUser.LenderProductTypes = 0;

        this.lenderUserProductType[item] = true;
        this.selectedLenderUser.LenderProductTypes += item;
      }
    } else {
      //if we are setting item to false and item exists, remove it, else add it
      if (this.lenderUserProductType[item] === true) {
        this.selectedLenderUser.LenderProductTypes += item;
      } else if (this.selectedLenderUser.LenderProductTypes > 0) {
        this.selectedLenderUser.LenderProductTypes -= item;
      }
    }
  }

  isLenderUserProductTypeEmpty() {
    if (this.lenderUserProductType.length === 0) return true;

    for (let i = 0; i < this.lenderUserProductType.length; i++) {
      let value = this.lenderUserProductType[i];
      if (value !== undefined && value !== null && value !== false) {
        return false;
      }
    }
    return true;
  }

  saveLenderUser() {
    var validUser = true;
    this.isSavingLenderUser = true;
    this.selectedLenderUser.LenderId = this.selectedObject.Id;

    if (!this.selectedLenderUser.IsLenderAdmin) {
      let lenderAdmin = this.assignedLenderUsers.find(
        (l) => l.IsLenderAdmin && l.Id != this.selectedLenderUser.Id,
      );
      if (!lenderAdmin) {
        this.lendersavemessage = `Please ensure this lender has at least one admin user.`;
        this.isSavingLenderUser = false;
        validUser = false;
      }
    }

    if (validUser) {
      this.$user
        .saveLenderUser(this.selectedLenderUser, !this.isEditLenderUser)
        .then((response) => {
          if (response) {
            this.isSavingLenderUser = false;
            this.isEditLenderUser = false;
            this.lenderUserProductType = [];
            this.showEditLenderUserModal = false;
          } else {
            if (this.isEditLenderUser) {
              this.lendersavemessage = `There is problem updating ${this.selectedLenderUser.FullName} details, Please try later.`;
            } else {
              this.lendersavemessage = `There is problem adding ${this.selectedLenderUser.FullName}, Please try later.`;
            }
          }
          this.fetchAssignedLenderUsers();
        });
    }
  }

  cancelEditLenderUserModal() {
    this.selectedLenderUser = undefined;
    this.lenderUserProductType = [];
    this.showEditLenderUserModal = false;
    this.error = "";
  }

  onClickOfMakeAdimn(lenderUser: ApplicationUserDTO) {
    this.selectedLenderUser = lenderUser;
    this.displayMessage = `Are you sure you wish to make ${lenderUser.FullName} admin for this lender?`;
    this.confirmChangingLenderAdmin = true;
  }

  setLenderAsAdmin() {
    this.selectedLenderUser.IsLenderAdmin = true;
    this.$user.setLenderAsAdmin(this.selectedLenderUser.Id).then((response) => {
      if (response) {
        this.confirmChangingLenderAdmin = false;
      } else {
        this.lendersavemessage = `There is problem converting ${this.selectedLenderUser.FullName} to admin, Please try later`;
      }
      this.fetchAssignedLenderUsers();
    });
  }

  onClickOfDeleteLenderUser(lenderUser: ApplicationUserDTO) {
    this.selectedLenderUser = lenderUser;
    let lenderAdmin = this.assignedLenderUsers.find(
      (l) => l.IsLenderAdmin && l.Id != lenderUser.Id,
    );
    if (this.selectedLenderUser.IsLenderAdmin && !lenderAdmin) {
      this.displayMessage = `Please assign a new Admin User before deleting ${lenderUser.FullName}.`;
    } else {
      this.displayMessage = `Are you sure you wish to remove ${lenderUser.FullName}?`;
      this.deleteLenderUserClicked = true;
    }
    this.confirmLenderAdminDeletion = true;
  }

  deleteLenderUser() {
    if (this.deleteLenderUserClicked) {
      this.$user.removeLenderUser(this.selectedLenderUser.Id).then((response) => {
        this.fetchAssignedLenderUsers();
        this.confirmLenderAdminDeletion = false;
        this.deleteLenderUserClicked = false;
      })
    } else {
      this.confirmLenderAdminDeletion = false;
    }
  }

  closeLenderUserDeleteModal() {
    this.confirmChangingLenderAdmin = false;
    this.confirmLenderAdminDeletion = false;
    this.deleteLenderUserClicked = false;
    this.lendersavemessage = "";
  }

  onIsLenderAdminChange() {
    if (!this.selectedLenderUser.IsLenderAdmin && this.isEditLenderUser) {
      let selectedUserFrom = this.assignedLenderUsers.find(
        (l) => l.Id == this.selectedLenderUser.Id,
      );
      let lenderAdmin = this.assignedLenderUsers.find(
        (l) => l.IsLenderAdmin && l.Id != this.selectedLenderUser.Id,
      );
      if (selectedUserFrom?.IsLenderAdmin && !lenderAdmin) {
        this.error = `Please assign a new Admin User.`;
      }
    } else if (this.selectedLenderUser.IsLenderAdmin) {
      this.error = "";
    }
  }

  lenderUserChanged() {
    this.initLenderUserProductType();
  }

  getProductName(key: number): string {
    let names: string[] = [];
    let totalKey = 0;

    for (let item of this.productTypeOptions) {
      if ((key & item.key) === item.key && item.key !== 0) {
        names.push(item.displayName);
        totalKey |= item.key;
      }
    }

    if (totalKey === ProductTypeEnum.Bridging) {
      return "Bridging";
    }

    return names.join(", ");
  }

  onFamilyChange(): void {
    this.filteredProductTypeOptions = this.productTypeOptions.filter((item) => {
      return this.productTypeBelongToFamily(item);
    });

    switch (this.selectedObjectProduct.LI_LoanFamily) {
      case ProductFamilyEnum.Bridging:
        this.selectedObjectProduct.LI_LoanProduct &= ProductTypeEnum.Bridging;
        break;
      case ProductFamilyEnum.Development:
        this.selectedObjectProduct.LI_LoanProduct &=
          ProductTypeEnum.Development;
        break;
      case ProductFamilyEnum.Commercial:
        this.selectedObjectProduct.LI_LoanProduct &=
          ProductTypeEnum.CommercialAll;
        break;
      case ProductFamilyEnum.None:
        this.selectedObjectProduct.LI_LoanProduct &= ProductTypeEnum.None;
        break;
      default:
        break;
    }

    if (
      this.selectedObjectProduct.LI_LoanProduct === 0 ||
      isNaN(this.selectedObjectProduct.LI_LoanProduct)
    ) {
      this.selectedObjectProduct.LI_LoanProduct = null;
    }
  }

  productTypeBelongToFamily(item): boolean {
    switch (this.selectedObjectProduct.LI_LoanFamily) {
      case ProductFamilyEnum.Bridging:
        return (item.key & ProductTypeEnum.Bridging) !== 0;
      case ProductFamilyEnum.Development:
        return item.key === ProductTypeEnum.Development;
      case ProductFamilyEnum.Commercial:
        return (item.key & ProductTypeEnum.CommercialAll) !== 0;
      default:
        return false;
    }
  }

  productIsValid(): boolean {
    const forms = [
      this.multiPartForm1,
      this.multiPartForm2,
      this.multiPartForm3,
      this.multiPartForm4,
      this.multiPartForm5,
      this.multiPartForm6,
      this.multiPartForm7,
    ];

    var productError;

    if (
      this.selectedObjectProduct.LI_LoanFamily == ProductFamilyEnum.Commercial
    ) {
      this.error = null;
      return true;
    }

    if (
      (this.multiPartForm6 &&
        this.multiPartForm6.$dirty &&
        !this.selectedObjectProduct.LI_Dev_ArrFeeIncBrokFee) ||
      Number(this.selectedObjectProduct.LI_Dev_ArrFeeIncBrokFee) <= 0
    ) {
      productError = this.error =
        "Arrangement Fee total % must be greater than 0";
    } else {
      this.error = null;
    }

    return productError == null;
  }

  validateSelection() {
    this.isValidSelection = this.selectedBrokers.length <= 3;
    this.updatePreferredBrokers = true;
  }

  getSelectedBrokersString() {
    if (this.selectedBrokers == null || this.selectedBrokers.length == 0) {
      return "";
    }
    return this.selectedBrokers
      .map(function (item) {
        return item.Name;
      })
      .join(", ");
  }

  getPreferredOrgs(): void {
    this.selectedBrokers = [];
    this.$lenderservice
      .getSelectedLenderPreferredOrgs(this.selectedObject.Id)
      .then((response) => {
        return response.map((x) => x.Id);
      })
      .then((ids) => {
        this.organisations.forEach((org: OrganisationDTO) => {
          (org as any).ticked = false;
          ids.forEach((id: number) => {
            if (org.Id == id) {
              this.selectedBrokers.push(org);
              (org as any).ticked = true;
            }
          });
        });
      });
  }

  trustedHtml(plainText) {
    return this.$sce.trustAsHtml(plainText);
  }

  validatePortalLink() {
    if (this.selectedObject.PortalUrl) {
      let url = this.selectedObject.PortalUrl;
      if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
      }
      window.open(url, '_blank');
    }
  };

  /**
   * Display a modal to edit details that are specific at a product family level
   * @param productFamily
   */
  showEditLenderProductFamilyModal(productFamily: ProductFamilyEnum) {
    this.tempSelectedObject = { ...this.selectedObject };
    this.editedLenderProductFamily = productFamily;
    this.showEditProductNotesAndSnapshot = true;
  }

  /** Close the lender's product family edit modal and cancel/undo changes */
  cancelEditLenderProductFamilyModal() {
    this.selectedObject = this.tempSelectedObject;
    this.editedLenderProductFamily = null;
    this.showEditProductNotesAndSnapshot = false;
    this.error = "";
  }
}


import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { LenderResultDTO } from "@js/DTO/DevelopmentFinance/LenderResultDTO.cs.d";
import { AuthService } from "@js/services/AuthService";
import { RoleService } from "@js/services/RoleService";

export class EnquiryController {
  registeringAccount: boolean;
  registrationform: FormData;
  error: string;

  loanCriteria: DevelopmentInputDTO;
  selectedResult: LenderResultDTO;

  enquireAfterRegistration: boolean;

  //newUser: RegistrationRequestDTO = {
  //    Email: "", FirstName: "", LastName: ""
  //} as RegistrationRequestDTO;

  newUser: any = {};

  static $inject = [
    "$scope",
    "$rootScope",
    "$routeParams",
    "$cookies",
    "$location",
    "$http",
    "$httpParamSerializerJQLike",
    "AuthService",
    "RoleService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $httpParamSerializerJQLike: ng.IHttpParamSerializer,
    private $auth: AuthService,
    private $roleService: RoleService,
  ) {
    // rootScope.title = "Registration";
    if ($cookies.get("access_token")) {
      $location.path("dashboard");
    }

    this.loanCriteria = (this.$rootScope as any).loanCriteria;
    this.selectedResult = (this.$rootScope as any).selectedResult;

    if (this.$routeParams.enquire !== undefined) {
      this.enquireAfterRegistration = true;
    }
  }

  registerAccount() {
    this.registeringAccount = true;
    if (this.newUser.ConfirmPassword !== this.newUser.Password) {
      this.error = "Passwords do not match";
    }
    // send registration
    this.$auth
      .registerAccount(this.newUser)
      .then((response) => {
        if (this.enquireAfterRegistration) {
          this.$location.path("/enquiry");
        } else {
          this.$location.path("/yourresults");
        }
      })
      .catch((response) => {
        this.error =
          "It looks like there was a problem registering. Please try again in a moment.";
      })
      .finally(() => {
        this.registeringAccount = false;
      });

    //if (this.selectedUser.Id == null) {
    //    this.selectedUser.Id = "";
    //    this.selectedUser.Roles = [];
    //    this.selectedUser.Roles.push("Client");
    //}
    //if (!this.selectedUser.UserName) {
    //    this.selectedUser.UserName = this.selectedUser.Email; //set user name to be same as email address for now
    //}
    //this.loanCriteria.User = this.selectedUser;
    //this.loanCriteria.SaveQueryAndResults = true;
    ////            this.$user.addUpdate(this.selectedUser).then((response) => {
    //this.$lenderresultservice.fetchMatchingResults(this.loanCriteria, false, false, 4).then((response) => {
    //    this.selectedUser.Id = response.UserId;
    //    this.showsaveinfo = false;
    //});
  }

  clearRegistration() {
    this.registeringAccount = false;
  }
}

import { LicenseMasterDTO } from "@js/DTO/LicenseMasterDTO.cs.d";
import { LicenseService } from "@js/services/LicenseService";

export class UserLicenseController {
  licenseMasterId: number = null;
  license: LicenseMasterDTO = null;

  dataLoading: boolean = false;

  static $inject = ["$http", "$routeParams", "LicenseService"];

  constructor(
    private $http: ng.IHttpService,
    private $routeParams: ng.route.IRouteParamsService,
    private licenseService: LicenseService,
  ) {
    if (this.$routeParams.licensemasterid) {
      this.dataLoading = true;
      this.licenseMasterId = +this.$routeParams.licensemasterid;

      this.licenseService
        .fetch(this.$routeParams.licensemasterid)
        .then((license: LicenseMasterDTO) => {
          this.license = license;
        });
    }
  }
}

import angular from "angular";

export function registerAppTermDirective() {
  const ccqdirectives = angular.module("Ccq.directives");

  ccqdirectives.directive("applicationName", [
    "$rootScope",
    ($rootScope: ng.IRootScopeService) => {
      return {
        restrict: "E",
        controller: function ($scope, $element, $attrs) {
          if (sessionStorage.getItem("applicationName") != null) {
            $scope.applicationName = sessionStorage.getItem("applicationName");
          } else {
            $scope.applicationName = "Brickflow";
          }

          $rootScope.$on(
            "applicationNameChanged",
            (event, newApplicationName) => {
              $scope.applicationName = newApplicationName;
            },
          );
        },
        template: "{{applicationName}}",
      };
    },
  ]);

  ccqdirectives.directive("applicationConsultant", [
    "$rootScope",
    ($rootScope: ng.IRootScopeService) => {
      return {
        restrict: "A",
        controller: function ($scope, $element, $attrs) {
          if (sessionStorage.getItem("applicationName") != null) {
            $scope.applicationName = sessionStorage.getItem("applicationName");
          } else {
            $scope.applicationName = "Brickflow";
          }

          $rootScope.$on(
            "applicationNameChanged",
            (event, newApplicationName) => {
              $scope.applicationName = newApplicationName;
            },
          );
        },
        template: "{{applicationName}} consultant ",
      };
    },
  ]);
}

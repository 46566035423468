import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { DealCompletionDTO } from "@js/DTO/DealCompletionDTO.cs.d";
import { LenderDTO } from "@js/DTO/LenderDTO.cs";
import {
  BrokerAppraisal,
  BrokerAppraisalLender,
} from "@js/DTO/Messages/BrokerAppraisalMessage.cs.d";
import {
  MakeReferralMessageRequest,
  LenderAppraisal,
} from "@js/DTO/Messages/MakeReferralMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { WebConfigDTO } from "@js/DTO/WebConfigDTO.cs.d";
import { AccountService } from "@js/services/AccountService";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { DealClientService } from "@js/services/DealClientService";
import { DealCompletionService } from "@js/services/DealCompletionService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { LenderService } from "@js/services/LenderService";
import { LicenseService } from "@js/services/LicenseService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { UserService } from "@js/services/UserService";

export class IndexController {
  fetchingResults: boolean = false;
  isPrimaryApplicant: boolean = true;

  user: ApplicationUserDTO;
  role: string;
  pageTitle: string;
  menuShrunk: boolean = false;
  errors: any[];
  copyrighttext: string;
  cookieMessageSeen: boolean;
  openShare: boolean;

  //Refer a friend
  referFriendName: string;
  referFriendSurname: string;
  referFriendEmail: string;

  //Generic share
  shareName: string;
  shareEmail: string;
  shareFriendsEmail: string;
  shareBody: string;

  //universal email send functions
  sendingsharemessage: boolean;
  sentsharemessage: boolean;

  //is Broker admin
  isAdminBroker: boolean = false;

  isIntroducer: boolean = false;
  isBroker: boolean = false;
  isLender: boolean = false;
  // is white labelling Org
  isWhiteLabelled: boolean = false;

  guidanceCheckbox: boolean = true;
  ShowHome: boolean = false;

  showMenu: boolean = false;

  showContactPopup: boolean = false;
  favIcon: HTMLLinkElement;
  title: HTMLLinkElement;
  companyWebsite: string;
  showContent: boolean = true;
  companyName: string = "Brickflow";
  companyUrlMenuIcon: string = null;
  footerTextFL: string;
  footerTextSL: string;
  footerTextTL: string;
  termsAndConditionsLink: string;
  privecyLink: string;
  cookiePolicyLink: string;
  productCode: string;
  nameInitial: string;
  openProfileImageSelection: boolean;
  brokerOrgNumber: string

  displayIfInactiveSubscription: boolean = false;
  showBanner: boolean = false;
  viewProfileRole: string; // the role displayed on profile menu : "view your ___ profile"
  isNormalBroker: boolean = false;
  orgAdminEmail: string;

  isAssigningBrokerToOrg: boolean = false;
  isLegacyUser: boolean = false;

  hubspotContactUsBookMeeting: string = "";

  headerBackgroundColour: string = "";

  //deal completion
  brokerAppraisals: BrokerAppraisal[] = [];
  newDealCompletion = {} as DealCompletionDTO;
  appraisalLenders: BrokerAppraisalLender[] = [];
  appraisalCompletionForm: ng.IFormController;
  appraisalName: string = "";
  showAppraisalNameDropdown: boolean = false;
  showAppraisalCompletionModal: boolean = false;
  filteredBrokerAppraisals: BrokerAppraisal[] = [];
  sendDealCompletionError: string = "";
  maxCompletionDate: Date = new Date();
  dataLoading: boolean = false;

  //Make a referral
  makeReferralForm: ng.IFormController;
  showMakeReferralModal: boolean = false;
  isSearchAttached?: boolean;
  isExistingSearch?: boolean;
  lenderReferralData: MakeReferralMessageRequest;
  lenderReferralClientFullName: string;
  message: string;
  showSubmitLenderReferralResponse: boolean = false;
  submitLenderReferralError: boolean = false;
  filteredLenderAppraisals: LenderAppraisal[] = [];
  lenderAppraisals: LenderAppraisal[] = [];
  hasLiveMarketSearch: boolean = false;
  isLenderReferralActive: boolean = false;
  contactDetails: string;

  loggedinUserLenderDetails: LenderDTO;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
    "RoleService",
    "AccountService",
    "CaseService",
    "OrganisationService",
    "FileAttachmentService",
    "UserService",
    "FileAttachmentService",
    "PaymentService",
    "ProductService",
    "DealCompletionService",
    "LicenseService",
    "DealClientService",
    "LenderService"
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $auth: AuthService,
    protected roleService: RoleService,
    private $accountservice: AccountService,
    private caseService: CaseService,
    private organisationService: OrganisationService,
    private fileAttachedService: FileAttachmentService,
    private userService: UserService,
    private fileAttachmentService: FileAttachmentService,
    private $paymentService: PaymentService,
    private productService: ProductService,
    private dealCompletionService: DealCompletionService,
    private licenseService: LicenseService,
    private dealClientService: DealClientService,
    private lenderService: LenderService
  ) {
    this.setAuthorisation();

    // Used for test site to keep banner hidden
    if (localStorage.getItem("isTestBannerHidden")) {
      if (localStorage.getItem("isTestBannerHidden") == "true") {
        this.roleService.isTestBannerHidden = true;
      }
    }


    var d = new Date();
    (this.$rootScope as any).formSaved = true;
    this.footerTextTL =
      "Copyright © Property Funding Hub Limited " + d.getFullYear();

    this.$auth.getHubspotContactUsBookMeeting().then((hubSpotUrl: string) => {
      this.hubspotContactUsBookMeeting = hubSpotUrl;
    });

    this.$accountservice.getFooterWebConfigValue().then((val: WebConfigDTO) => {
      this.termsAndConditionsLink = val.TermsAndConditionsURL;
      this.privecyLink = val.PrivecyURL;
      this.cookiePolicyLink = val.CookiePolicyURL;
      this.headerBackgroundColour = val.HeaderBackgroundColour;
    });
    //$suppliers.fetchAll().then((response) => {
    //    response.forEach((supplier, index) => {
    //        if (!supplier.Coordinates) {
    //            this.$http.get("https://nominatim.openstreetmap.org/search?q=" + encodeURIComponent(supplier.Postcode) + "&format=json").then((response) => {
    //                supplier.Coordinates = response.data[0].lat + "," + response.data[0].lon;
    //                this.$suppliers.addUpdate(supplier);
    //            });
    //        }
    //    });
    //});

    if ($scope) {
      $rootScope.$on("login", (event: ng.IAngularEvent) => {
        this.roleService.IsAdminUser = false;
        this.roleService.IsLenderUserOrAbove = false;
        this.roleService.IsClientUserOrAbove = false;
        this.roleService.IsIntroducer = false;
        this.ShowHome = false;
        this.CalcShowHome();
        //  this.showHubSpotChat();
      });

      $rootScope.$on("error", (event: ng.IAngularEvent, data) => {
        if (!this.errors) {
          this.errors = [];
        }

        this.errors.push(data);
      });

      $rootScope.$on("clearerrors", (event: ng.IAngularEvent, page: string) => {
        this.clearErrors(page);
      });

      if (window.self == window.top) {
        $scope.$on("$viewContentLoaded", (event, next, current) => {
          this.cookieMessage();
          this.getGuidanceSwitchState();
        });

        $scope.$on("$routeChangeStart", (event, next, current) => {
          this.setAuthorisation();
          this.getLeadGeneratorName();
        });
      }

      $scope.$on("$routeChangeSuccess", (event, current, previous) => {
        if (window.self == window.top)
          this.isAssigningBrokerToOrg =
            sessionStorage.getItem("assignUserToOrg") == "true";
        ($scope as any).ctrl.pageTitle = current.$$route.page;
        ($scope as any).ctrl.openSection = current.$$route.section;
        if (
          $location.path() === "usermanagement" ||
          $location.path() === "rolemanagement"
        ) {
          ($scope as any).ctrl.roleService.isAdminUser().then((isAdmin) => {
            if (!isAdmin) {
              $location.path("/login");
            }
          });
        }

        this.favIcon = document.querySelector("#appIcon");
        this.title = document.querySelector("#title");
        this.companyUrlMenuIcon = this.favIcon ? this.favIcon.href : null;

        if (window.self == window.top && $cookies.get("access_token")) {
          ($scope as any).ctrl.$auth.setIsLoggedIn(true);

          ($scope as any).ctrl.userService
            .getcurentuserrecord()
            .then((response) => {
              ($scope as any).ctrl.user = response;
              (this.$rootScope as any).selectedUser = response;

              this.nameInitial = response.FirstName.charAt(0);
              this.isLegacyUser = response.IsLegacyUser;

              if (
                response.ProfilePictureUrl &&
                response.ProfilePictureUrl.length > 0
              ) {
                this.fileAttachedService
                  .getFileUri(response.ProfilePictureUrl)
                  .then((result) => {
                    ($scope as any).ctrl.user.ProfilePictureFullUrl = result;
                    (
                      this.$rootScope as any
                    ).selectedUser.ProfilePictureFullUrl = result;
                  });
              }

              this.buildFooterText(response);
              this.isAdminBroker = response.IsOrganisationAdmin;
              this.isNormalBroker =
                response.OrganisationId && !response.IsOrganisationAdmin;

              // If the user hasn't followed a whitelabelled link then "whitelabel" based on the user
              if (!$cookies.get("org_code")) {
                organisationService
                  .getOrganisation()
                  .then((org: OrganisationDTO) => {
                    this.userService.fileSelected =
                      org.LogoURL != null &&
                        org.LogoURL.length > 0 &&
                        org.HasWhiteLabelAddOnPaid == true &&
                        org.IsWhiteLabelled == true
                        ? org.LogoURL
                        : "img/BrickFlow_Logo_RGB_Blue.svg";
                    this.isWhiteLabelled =
                      org.HasWhiteLabelAddOnPaid && org.IsWhiteLabelled;
                    this.companyWebsite =
                      org.HasWhiteLabelAddOnPaid == true &&
                        org.IsWhiteLabelled == true
                        ? org.CompanyURL
                        : "https://brickflow.com";

                    if (
                      org.LogoURL != null &&
                      org.LogoURL.length > 0 &&
                      org.HasWhiteLabelAddOnPaid &&
                      org.IsWhiteLabelled
                    ) {
                      this.favIcon.href = org.FaviconURL;
                      this.companyUrlMenuIcon = org.FaviconURL
                        ? this.favIcon.href
                        : null;

                      sessionStorage.setItem("companyLogoUrl", org.LogoURL);
                    }

                    this.companyName = org.Name;

                    this.brokerOrgNumber = org.PhoneNumber;

                    sessionStorage.setItem(
                      "companyWebsite",
                      this.companyWebsite,
                    );

                    if (!this.isWhiteLabelled) {
                      if (
                        !(this.$rootScope as any).selectedUser
                          .DefaultBrokerOrganisationId
                      ) {
                        this.loadHubSpotChat();
                      } else {
                        if ((window as any).HubSpotConversations) {
                          (window as any).HubSpotConversations.widget.remove();
                        }
                      }
                      this.removeLSData();
                    } else {
                      if ((window as any).HubSpotConversations) {
                        (window as any).HubSpotConversations.widget.remove();
                      }

                      if (
                        org.OrganisationTermForApp != null &&
                        org.OrganisationTermForApp.length > 0
                      ) {
                        sessionStorage.setItem(
                          "applicationName",
                          org.OrganisationTermForApp,
                        );
                        $rootScope.$broadcast(
                          "applicationNameChanged",
                          org.OrganisationTermForApp,
                        );
                        this.title.innerHTML = org.OrganisationTermForApp;
                      }
                    }
                  });
              } else {
                if (
                  !location.pathname.startsWith("/otmx") &&
                  !location.pathname.startsWith("/lnhn")
                ) {
                  if (
                    location.pathname.replace("/", "") != null &&
                    location.pathname.replace("/", "").length > 0
                  ) {
                    this.getOrganisationForWhiteLabelledUrl(response);
                  }
                } else {
                  this.removeLSData();
                }
              }

              this.roleService.GetUserRoles().then((roles) => {
                this.setViewProfileRole(roles);
                this.CalcShowHome();

                if (roles.find((x) => x.toLowerCase() == "broker")) {
                  this.isPrimaryApplicant = false;
                  this.isBroker = true;

                  if (!this.isBroker || response.HasActiveSubscription) {
                    sessionStorage.setItem("isValidLicense", "true");
                  } else {
                    sessionStorage.setItem("isValidLicense", "false");
                    this.displayIfInactiveSubscription = this.showBanner = true;
                  }

                  this.organisationService
                    .getOrganisationAdmin(this.user.OrganisationId)
                    .then((response) => {
                      this.orgAdminEmail = response;
                    });
                }

                (this.$rootScope as any).userRoles = roles;

                //Commenting a below code as we no more using a redirectiong a legacyuser to landing page
                /* this.roleService.isIntroducerOnly().then((isIntroducerOnly: boolean) => {
                                    if (!this.roleService.IsAdminUser && !this.roleService.IsLender && !isIntroducerOnly && !this.roleService.IsClient) {
                                        if (response.IsLegacyUser && current.$$route.page != 'Landing' && current.$$route.page != 'Promo' && current.$$route.page != 'Broker Registration' && current.$$route.page != 'Registration' && current.$$route.page != 'Criteria' && current.$$route.page != 'Lender Results') {
                                            $location.path("/landing");
                                        }
                                    } else if (sessionStorage.getItem('userOrgUniqueRef') != null && current.$$route.page != 'Broker Registration') {
                                        $location.path("/registerbroker");
                                    }
                                });*/
              });
            });
        } else {
          // default brickflow settings
          this.userService.fileSelected = "img/BrickFlow_Logo_RGB_Blue.svg";
          this.companyWebsite = "https://brickflow.com";
          //Below code is for displaying the application name
          if (
            !location.pathname.startsWith("/otmx") &&
            !location.pathname.startsWith("/lnhn")
          ) {
            if (
              location.pathname.replace("/", "") != null &&
              location.pathname.replace("/", "").length > 0
            ) {
              this.getOrganisationForWhiteLabelledUrl(null);
            } else {
              this.buildFooterText(null);
              this.loadHubSpotChat();
              if (window.self == window.top) this.removeLSData();
              this.favIcon.href = "favicon.ico";
              this.title.innerHTML = "Brickflow";
            }
          } else {
            this.buildFooterText(null);
            if (window.self == window.top) this.removeLSData();
          }
        }

        //keep track of last path
        //if (previous) {
        //    (this.$rootScope as any).previousPath = previous.$$route.originalPath;
        //    console.log("Last path: " + previous.$$route.originalPath);
        //}
        //if (current) {
        //    (this.$rootScope as any).currentPath = current.$$route.originalPath;
        //    console.log("Currrent path: " + current.$$route.originalPath);
        //}
      });
      if (window.self == window.top) {
        $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
          this.updateGuidanceState();
        });
        $rootScope.$on("showContactPopup", (event: ng.IAngularEvent) => {
          this.showContactPopup = !this.showContactPopup;
        });
      }

      /*  if ($cookies.get('access_token')) {
                    this.showHubSpotChat();
                }*/
    }
  }

  whiteLabeling(organisationId: number) {
    if (organisationId) {
      this.organisationService.fetch(organisationId).then((organisation) => {
        if (
          organisation &&
          organisation.IsWhiteLabelled == true &&
          organisation.HasWhiteLabelAddOnPaid == true &&
          organisation.LogoURL
        ) {
          this.fileAttachedService
            .getFileUri(organisation.LogoURL)
            .then((result) => {
              this.userService.fileSelected = result;
            });
        } else {
          this.userService.fileSelected = "img/BrickFlow_Logo_RGB_Blue.svg";
        }
      });
    }
  }

  contactPopup(): void {
    this.showContactPopup = !this.showContactPopup;
  }

  cookieMessage() {
    if (!this.$cookies.get("cookieMessageSeen")) {
      this.cookieMessageSeen = false;
    } else {
      this.cookieMessageSeen = true;
    }
    return this.cookieMessageSeen;
  }
  cookieConsented() {
    this.cookieMessageSeen = true;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    this.$cookies.put("cookieMessageSeen", "true", { expires: expiryDate });
  }

  updateGuidanceState() {
    this.guidanceCheckbox =
      this.$cookies.get("guidance") === "on" ||
      this.$cookies.get("guidance") === undefined;
  }

  getGuidanceSwitchState() {
    if (!this.$cookies.get("guidance")) {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "on") {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "off") {
      this.guidanceCheckbox = false;
    } else {
      this.guidanceCheckbox = true;
    }
    return this.guidanceCheckbox;
  }

  recordGuidanceCookie() {
    var guidanceSwitchState: string;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    if (this.guidanceCheckbox == true) {
      guidanceSwitchState = "on";
    } else {
      guidanceSwitchState = "off";
    }
    this.$cookies.put("guidance", guidanceSwitchState, { expires: expiryDate });
    this.$rootScope.$broadcast("guidanceStateChanged");
  }

  setAuthorisation() {
    if (
      this.$cookies.get("access_token") &&
      !this.$http.defaults.headers.common.Authorization
    ) {
      this.$http.defaults.headers.common.Authorization =
        "Bearer " + this.$cookies.get("access_token");
    }
  }

  clearErrors(page: string) {
    if (this.errors) {
      this.errors = this.errors.filter((value: any, index: number) => {
        return value.page !== page;
      });
    }
  }
  dismissError(index: number) {
    this.errors.splice(index, 1);
  }

  isMobile(): boolean {
    return window.innerWidth <= 451;
  }

  /*  displayDot() {
            var display = false;      
            var hasLicense = this.user.LicenseMasterId != null;
            if (!this.user.LicenseMasterId && !this.user.SubscriptionStatus) {
                this.$paymentService.getLicense().then((response) => {
                    var licenseData = response;
                    if (response != null && response.Status != LicenseMasterStatusEnum.PreSubscription && response.Status != LicenseMasterStatusEnum.None) {
                        this.user.SubscriptionStatus = licenseData.Status;
                        var invalidLicense = this.user.SubscriptionStatus != LicenseMasterStatusEnum.PaidUp && this.user.SubscriptionStatus != LicenseMasterStatusEnum.PreCancel && this.user.SubscriptionStatus != LicenseMasterStatusEnum.PaymentProcessing;
                        this.displayIfInactiveSubscription = this.showBanner = invalidLicense;
                    } else {
                        var invalidLicense = this.user.SubscriptionStatus != LicenseMasterStatusEnum.PaidUp && this.user.SubscriptionStatus != LicenseMasterStatusEnum.PreCancel && this.user.SubscriptionStatus != LicenseMasterStatusEnum.PaymentProcessing;
                        var display = !hasLicense || invalidLicense;
                        this.displayIfInactiveSubscription = this.showBanner = display;
                    }
                });
            } else {
                var invalidLicense = this.user.SubscriptionStatus != LicenseMasterStatusEnum.PaidUp && this.user.SubscriptionStatus != LicenseMasterStatusEnum.PreCancel && this.user.SubscriptionStatus != LicenseMasterStatusEnum.PaymentProcessing;
                var display = !hasLicense || invalidLicense;
                this.displayIfInactiveSubscription = this.showBanner = display;
            }

        }*/

  CalcShowHome() {
    if ((this.$scope as any).ctrl.$auth.getIsLoggedIn() == false) {
      this.ShowHome = false;
    } else {
      if (
        (this.roleService.isAdminUser && this.pageTitle == "Dashboard") ||
        (this.roleService.isClientUser &&
          this.pageTitle == "Client Dashboard") ||
        (this.roleService.isLenderUser && this.pageTitle == "Lenders")
      ) {
        this.ShowHome = false;
      } else {
        this.ShowHome = true;
      }
    }
  }

  GoHome() {
    if ((this.$scope as any).ctrl.$auth.getIsLoggedIn() == false) {
      return false;
    }

    this.roleService.goHomeBasedOnUser();
  }

  go(path: string) {
    this.$location.path(path);
  }

  newloan() {
    (this.$rootScope as any).loanCriteria = null;
    this.$location.path("/criteria");
  }

  sendShare(): void {
    this.sendingsharemessage = true;
    this.sentsharemessage = false;

    this.$accountservice
      .SendLoansAdvertEmail(
        this.shareName,
        this.shareEmail,
        this.shareFriendsEmail,
        this.shareBody,
      )
      .then((response2) => {
        this.sendingsharemessage = false;
        this.sentsharemessage = true;
      });
  }

  logout() {
    let broadcastResult = this.$rootScope.$broadcast("logout");

    if (broadcastResult.defaultPrevented) return;

    this.displayIfInactiveSubscription = false; //hide red dot

    delete this.user;
    this.$auth.logout();
    //  this.showHubSpotChat();
  }

  newBlankCase() {
    this.fetchingResults = true;
    this.caseService
      .newBlankCase(this.user, this.isPrimaryApplicant)
      .then((newBlankCaseDto) => {
        if (newBlankCaseDto && newBlankCaseDto.NewCaseId) {
          this.$location.path("/casedashboard/" + newBlankCaseDto.NewCaseId);
          this.fetchingResults = false;
        }
      });
  }

  /**Main menu link clicked (3 bricks icon button) */
  /**todo: add else outer statement for button to work when user is not logged in (not working at the moment)
   * else inner statement (userdashboard one) should do something similar to showHomeDashboard() method in "userdashboardcontroller.ts"
   */
  mainMenuLinkClicked() {
    if (this.$auth.isLoggedIn) {
      this.$auth.clearSelectedNavMenuAndFilterStorageData();

      if (this.roleService.IsAdminUser == true) {
        this.go("/dashboard");
      } else {
        /*clearing a session storage in case lender has set a data while referring search*/
        sessionStorage.removeItem("lenderReferralClient");
        this.go("/");
      }
    } else {
      this.go("/");
    }
  }

  goToNewUserDashboard() {
    // TODO - Temporary code JLH
    this.go("/userdashboard2");
  }

  homePageClicked() {
    let broadcastResult = this.$rootScope.$broadcast("logout");
    if (broadcastResult.defaultPrevented) return;
    window.location.href = this.companyWebsite;
  }

  showHubSpotChat() {
    this.organisationService
      .getOrganisation()
      .then((userOrganisation: OrganisationDTO) => {
        // Hide the hubspot chat feature if the user's organisation is white-labelled
        if (
          userOrganisation.IsWhiteLabelled == true &&
          userOrganisation.HasWhiteLabelAddOnPaid == true &&
          (window as any).HubSpotConversations
        ) {
          (window as any).HubSpotConversations.widget.remove();
        } else {
          if ((window as any).HubSpotConversations) {
            (window as any).HubSpotConversations.widget.load();
          }
        }
      });
  }

  /* Below code is to include chat script */
  loadHubSpotChat() {
    if ((window as any).HubSpotConversations) {
      (window as any).HubSpotConversations.widget.load();
    } else {
      const node = document.createElement("script");
      node.src = "//js.hs-scripts.com/7081779.js";
      node.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(node);
    }
  }

  removeLSData() {
    sessionStorage.removeItem("applicationName");
    sessionStorage.removeItem("companyWebsite");
    sessionStorage.removeItem("companyLogoUrl");
  }

  getOrganisationForWhiteLabelledUrl(user: ApplicationUserDTO) {
    this.showContent = false;
    this.organisationService
      .getOrganisationByOrgCode(location.pathname.replace("/", ""))
      .then((userOrganisation: OrganisationDTO) => {
        if (userOrganisation != null) {
          if (
            userOrganisation.HasWhiteLabelAddOnPaid == true &&
            userOrganisation.IsWhiteLabelled == true &&
            userOrganisation.OrganisationTermForApp != null &&
            userOrganisation.OrganisationTermForApp.length > 0
          ) {
            if (window.self == window.top) {
              sessionStorage.setItem(
                "applicationName",
                userOrganisation.OrganisationTermForApp,
              );
            } else {
              this.organisationService.sendDataToParent(
                "applicationName",
                userOrganisation.OrganisationTermForApp,
              );
            }
          } else {
            sessionStorage.removeItem("applicationName");
          }

          if (window.self == window.top && userOrganisation.CompanyURL) {
            sessionStorage.setItem(
              "companyWebsite",
              userOrganisation.CompanyURL,
            );
          } else {
            sessionStorage.removeItem("companyWebsite");
          }

          if (window.self == window.top && userOrganisation.LogoURL) {
            sessionStorage.setItem("companyLogoUrl", userOrganisation.LogoURL);
          } else {
            sessionStorage.removeItem("companyLogoUrl");
          }
        }
      })
      .finally(() => {
        this.showContent = true;
        this.buildFooterText(user);
      });
  }

  buildShareMsg() {
    let lsd = sessionStorage.getItem("applicationName");
    if (lsd != null) {
      this.shareBody =
        "Hi,\n\nI've just been trying out " +
        lsd +
        " - it’s a comparison site for specialist property finance, its's fast and it's really useful. You should check it out.";
    } else {
      this.shareBody =
        "Hi, \n\nI've just been trying out Brickflow - it’s a comparison site for specialist property finance, its's fast and it's really useful. You should check it out.";
    }
  }

  buildFooterText(user: ApplicationUserDTO) {
    var lsd;
    if (window.self == window.top)
      lsd = sessionStorage.getItem("applicationName");
    if (!user && lsd != null) {
      this.footerTextFL = `${lsd} is designed to be used by brokers and property investors to source and apply for funding. It’s a collaborative project management tool, where you can store information and invite your colleagues to participate to make the application process quicker and easier.`;
      this.footerTextSL = null;
    } else {
      if (user && (user.IsOrganisationAdmin || user.OrganisationId > 0)) {
        this.footerTextFL =
          "Property Funding Hub Limited (t/a Brickflow) is designed to be used by mortgage brokers to source and apply for specialist property finance for their clients.";
        this.footerTextSL = null;
      } else if (
        user &&
        user.OrganisationReferralId != null &&
        user.OrganisationReferralId > 0
      ) {
        this.footerTextFL = `${lsd} is designed to be used by brokers and property investors to source and apply for funding. It’s a collaborative project management tool, where you can store information and invite your colleagues to participate to make the application process quicker and easier.`;
        this.footerTextSL = null;
      } else {
        this.footerTextFL =
          "Property Funding Hub Limited (t/a Brickflow) is designed to be used by corporate borrowers (Limited Companies or Incorporated Partnerships) to compare and apply for specialist property finance loans.";
        this.footerTextSL =
          "Brickflow is not an advisory business and does not give advice. It can deal with purely factual inquiries and provide information but it will not give an opinion or recommendation in any circumstances.";
      }
    }
  }

  isLoggedIn(): boolean {
    return this.$auth.getIsLoggedIn();
  }

  /**
   * Called when a profile picture has been selected
   * @param files
   */
  onFileSelect(files) {
    this.fileAttachmentService.uploadFileAndReturnURI(files).then((result) => {
      // Make a copy of the main profile object so that the main page doesn't get updated at the same time
      //this.user = Utils.deepCopy(this.profile);

      this.user.ProfilePictureUrl = result.FileLocation;

      this.userService.addUpdate(this.user).then((userResponse) => {
        // TODO - JLH - I don't like that I'm getting the profile again - but the addupdate function isn't returning the roles in the user dto which causes them to disappear if you save again.
        this.userService
          .getcurentuserrecord()
          .then((user: ApplicationUserDTO) => {
            this.user = user;

            this.fileAttachedService
              .getFileUri(this.user.ProfilePictureUrl)
              .then((result) => {
                this.user.ProfilePictureFullUrl = result;
              });

            // Update the profile cookie so that the rest of the application can be aware of the profile changes
            this.$auth.setProfileCookie(this.user);
          });

        this.openProfileImageSelection = false;
      });
    });
  }

  toggleBanner() {
    this.showBanner = !this.showBanner;
  }

  setViewProfileRole(roles: string[]) {
    if (roles.find((x) => x.toLowerCase() == "admin"))
      this.viewProfileRole = "administrator";
    else if (roles.find((x) => x.toLowerCase() == "client"))
      this.viewProfileRole = "borrower";
    else if (roles.find((x) => x.toLowerCase() == "broker"))
      this.viewProfileRole = "broker";
    else if (roles.find((x) => x.toLowerCase() == "lender")) {
      this.viewProfileRole = "lender";
      this.isLender = true;
      this.lenderService
        .getUserLenderDetails()
        .then((response) => {
          this.loggedinUserLenderDetails = response;
          this.hasLiveMarketSearch = response.HasLiveMarketSearch;
          this.isLenderReferralActive = response.IsLenderReferralActive;
        })
        .finally(() => {
          if (this.hasLiveMarketSearch && this.isLenderReferralActive) {
            this.$auth.getReferralContactDetails().then((response) => {
              this.contactDetails = response;
            });
          }
        });
    } else this.viewProfileRole = "";
  }

  getLeadGeneratorName() {
    if (!this.$cookies.get("leadgeneratorname")) {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 1);

      this.productService.getLeadGeneratorName().then((response) => {
        this.$cookies.put("leadgeneratorname", response, {
          expires: expiryDate,
        });
      });
    }
  }

  getFilteredBrokerAppraisals() {
    if (this.appraisalName != null && this.appraisalName.length > 0) {
      this.filteredBrokerAppraisals = this.brokerAppraisals.filter(
        (appraisal) =>
          appraisal.AppraisalName != null &&
          appraisal.AppraisalName.toLowerCase().indexOf(
            this.appraisalName.toLowerCase(),
          ) !== -1,
      );
    } else {
      this.filteredBrokerAppraisals = this.brokerAppraisals;
    }

    this.showAppraisalNameDropdown = true;
  }

  onSelectingAppraisalName(item: BrokerAppraisal) {
    this.appraisalName = item.AppraisalName;
    this.showAppraisalNameDropdown = false;
    this.newDealCompletion.AppraisalId = item.AppraisalId;
    this.newDealCompletion.AppraisalType = item.IsDeal ? "Deal" : "Case";
    this.appraisalLenders = [];
    this.appraisalCompletionForm.LenderId.$setPristine();
    this.appraisalCompletionForm.LenderId.$setUntouched();
  }

  sendDealCompletionEmail() {
    this.dataLoading = true;
    this.dealCompletionService
      .sendDealCompletionEmail(this.newDealCompletion)
      .then((response) => {
        if (response) {
          this.showAppraisalCompletionModal = false;
        } else {
          this.sendDealCompletionError =
            "Your message could not be delivered. Please reach out to Brickflow so that we can investigate.";
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  onClickOfCompletion() {
    var modalContent = document.getElementsByClassName("modal-content");
    if (modalContent.length <= 0) {
      this.showAppraisalNameDropdown = false;
      this.newDealCompletion = {} as DealCompletionDTO;
      this.appraisalName = "";
      this.sendDealCompletionError = "";
      this.appraisalLenders = [];
      this.showAppraisalCompletionModal = !this.showAppraisalCompletionModal;
      this.dataLoading = true;
      this.dealCompletionService
        .getBrokerAppraisalDetails()
        .then((response) => {
          this.filteredBrokerAppraisals = this.brokerAppraisals =
            response.BrokerAppraisals;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  closeAppraisalNameListDropDown() {
    event.stopPropagation();
    if (this.showAppraisalNameDropdown && !this.dataLoading) {
      this.showAppraisalNameDropdown = false;
    }
  }

  onClickOfCaseNameElement() {
    event.stopPropagation();
    if (!this.dataLoading)
      this.showAppraisalNameDropdown = !this.showAppraisalNameDropdown;
  }

  populateAppraisalLenders() {
    if (
      this.appraisalName.length > 0 &&
      this.filteredBrokerAppraisals.length > 0
    ) {
      this.appraisalLenders = this.filteredBrokerAppraisals.find(
        (b) => b.AppraisalName == this.appraisalName,
      ).AppraisalLenders;
    } else {
      this.appraisalLenders = [];
    }
  }

  onClickOfMakeAReferral() {
    var modalContent = document.getElementsByClassName("modal-content");
    if (modalContent.length <= 0) {
      this.lenderReferralData = {} as MakeReferralMessageRequest;
      this.appraisalName = "";
      this.showAppraisalNameDropdown = false;
      this.isSearchAttached = null;
      this.isExistingSearch = null;
      this.lenderReferralClientFullName = "";
      this.showSubmitLenderReferralResponse = false;
      this.submitLenderReferralError = false;
      this.showMakeReferralModal = !this.showMakeReferralModal;
      this.dataLoading = true;
      this.userService
        .getLenderAppraisals()
        .then((response) => {
          this.filteredLenderAppraisals = this.lenderAppraisals = response;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  submitLenderReferral() {
    this.dataLoading = true;
    this.populateFirstAndLastNameForClient();
    if (!this.isSearchAttached) this.lenderReferralData.DealId = null;
    this.dealClientService
      .submitLenderReferral(this.lenderReferralData)
      .then((response) => {
        if (response) {
          this.message = "Your referral has been submitted.";
          this.showSubmitLenderReferralResponse = true;
        } else {
          this.message = "Error while making a referral, please try later.";
          this.showSubmitLenderReferralResponse = true;
          this.submitLenderReferralError = true;
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  goToLoanTypeSelectionPage() {
    this.populateFirstAndLastNameForClient();
    sessionStorage.setItem(
      "lenderReferralClient",
      JSON.stringify(this.lenderReferralData.ClientDTO),
    );
    this.$auth.setHasValidStatustoShowShareSearchModal(
      true,
    );
    this.showMakeReferralModal = false;
    this.$location.path("/allloans");
  }

  populateFirstAndLastNameForClient() {
    let userFullName = this.lenderReferralClientFullName;
    let spaceIndex = userFullName.indexOf(" ");

    let firstName = "";
    let lastName = "";

    // if not space has been added to the name then put the whole name in the first name field
    if (spaceIndex == -1) {
      firstName = userFullName;
    } else {
      firstName = userFullName.substring(0, userFullName.indexOf(" "));
      lastName = userFullName.substring(userFullName.indexOf(" ") + 1);
    }

    this.lenderReferralData.ClientDTO.FirstName = firstName;
    this.lenderReferralData.ClientDTO.LastName = lastName;
  }

  closeLenderReferralModal() {
    this.showMakeReferralModal = false;
    if (
      this.$location.path().startsWith("/bridgingresults") ||
      this.$location.path().startsWith("/commercialresults") ||
      this.$location.path().startsWith("/devfinanceresults")
    ) {
      window.location.reload();
    }
  }

  onLenderSelectingSearchName(item: LenderAppraisal) {
    this.showAppraisalNameDropdown = false;
    this.appraisalName = item.DealName;
    this.lenderReferralData.DealId = item.DealId;
  }

  getFilteredLenderAppraisals() {
    if (this.appraisalName != null && this.appraisalName.length > 0) {
      this.filteredLenderAppraisals = this.lenderAppraisals.filter(
        (appraisal) =>
          appraisal.DealName != null &&
          appraisal.DealName.toLowerCase().indexOf(
            this.appraisalName.toLowerCase(),
          ) !== -1,
      );
    } else {
      this.filteredLenderAppraisals = this.lenderAppraisals;
    }

    this.showAppraisalNameDropdown = true;
  }

  hideHeader() {
    if (
      !this.$cookies.get("access_token") &&
      (this.$location.path().startsWith("/e/bridgingcriteria") ||
        this.$location.path().startsWith("/e/commercialcriteria") ||
        this.$location.path().startsWith("/e/devfinancecriteria") ||
        this.$location.path().startsWith("/allloans") || this.$location.path().startsWith("/e/enterpriseleadcapture"))
    ) {
      return true;
    }
    return false;
  }

  hideFooter() {
    if (!this.$cookies.get("access_token") && this.$location.path().includes('widget') && window.self != window.top && 
    (this.$location.path().startsWith("/e/bridgingcriteria") ||
    this.$location.path().startsWith("/e/commercialcriteria") ||
    this.$location.path().startsWith("/e/devfinancecriteria") ||
    this.$location.path().startsWith("/allloans"))) {
      return true;
    }
    return false;
  }

  isResultsPage(){
    if (
        this.$location.path().startsWith("/referredSearch") ||
        this.$location.path().startsWith("/resultsExternalAPI") ||
        this.$location.path().startsWith("/exitloanresults") ||
        this.$location.path().startsWith("/bridgingresults") ||
        this.$location.path().startsWith("/bridgingshortlistmore") ||
        this.$location.path().startsWith("/referredsearchdeal") ||
        this.$location.path().startsWith("/referredsearchcommercial") ||
        this.$location.path().startsWith("/referredsearchdevfinance") ||
        this.$location.path().startsWith("/commercialresults") ||
        this.$location.path().startsWith("/commercialshortlistmore") ||
        this.$location.path().startsWith("/devfinanceresults") ||
        this.$location.path().startsWith("/devfinanceshortlistmore") ||
        this.$location.path().startsWith("/results") ||
        this.$location.path().startsWith("/shortlistmore") ||
        this.$location.path().startsWith("/e/bridgingresults") ||
        this.$location.path().startsWith("/e/commercialresults") ||
        this.$location.path().startsWith("/e/devfinanceresults") 
    ) {

        return true;
    }else{
      return false;
    }
  }

  goToNewSearch() {
    this.$location.path("/allloans");
  }
}


export const enum BorrowingEntityTypeEnum {
  None = 0,
  Individual = 1,
  Company = 2,
  SoleTrader = 4,
  Partnership = 8,
  LLP = 16,
  Trust = 32,
  SIPPOrSSAS = 64,
  OverseasCo = 128,
}

import { EventLogDTO } from "@js/DTO/EventLogDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { EventLogEnum } from "@js/models/enum/EventLogEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";

export class EventLogService extends BaseService<EventLogDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    private rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/eventlog";
    this.$broadcastBusinessNameSingular = "eventlog";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }
  logPageLoad(
    identifier: string,
    orgCode: string,
    user: string,
    userRole: string,
    organisationLinkId: number,
    productType: ProductTypeEnum = ProductTypeEnum.None,
    developmentInputWithNoLoginUniqueRef: string = "",
    dealId?: number,
    clientId: string = "",
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        this.$baseAddress +
          "/logpageload?identifier=" +
          identifier +
          "&orgCode=" +
          orgCode +
          "&user=" +
          user +
          "&userRole=" +
          userRole +
          "&organisationLinkId=" +
          organisationLinkId +
          "&productType=" +
          productType +
          "&developmentInputWithNoLoginUniqueRef=" +
          developmentInputWithNoLoginUniqueRef +
          "&dealId=" +
          dealId +
          "&clientId=" +
          clientId,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  logEvent(
    identifier: string,
    eventType: EventLogEnum,
    productType: ProductTypeEnum,
    dealId: number = 0,
    devInputWithNoLogin?: string,
    param1?: string,
    clientId?: string
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        this.$baseAddress +
          "/logevent?identifier=" +
          identifier +
          "&eventType=" +
          eventType +
          "&productType=" +
          productType +
          "&dealId=" +
          dealId +
          "&devInputWithNoLogin=" +
          devInputWithNoLogin + "&param1=" + param1
          + "&clientId=" + clientId 
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

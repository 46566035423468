import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { DealLenderDTO } from "@js/DTO/Deal/DealLenderDTO.cs.d";
import { DevelopmentFinanceDealDTO } from "@js/DTO/Deal/DevelopmentFinanceDealDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import { LenderResultSummaryDTO } from "@js/DTO/DevelopmentFinance/LenderResultSummaryDTO.cs.d";
import { LenderProductPairDTO } from "@js/DTO/LenderProductPairDTO.cs";
import { SaveAsSearchRequest } from "@js/DTO/Messages/Deal/SaveAsSearchMessage.cs.d";
import { SaveDevFinanceSearchRequest } from "@js/DTO/Messages/Deal/SaveDevFinanceSearchMessage.cs.d";
import { UpdateDevFinanceSearchGetResultResponse } from "@js/DTO/Messages/Deal/UpdateDevFinanceSearchGetResultMessage.cs.d";
import { MakeReferralMessageRequest } from "@js/DTO/Messages/MakeReferralMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { CaseAccessLevelEnum } from "@js/models/enum/CaseAccessLevelEnum.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { EventLogEnum } from "@js/models/enum/EventLogEnum.cs.d";
import { MaxFlatUnitPriceEnum } from "@js/models/enum/MaxFlatUnitPriceEnum.cs.d";
import { MaxHouseSalePriceEnum } from "@js/models/enum/MaxHouseSalePriceEnum.cs.d";
import { MaxPercCommercialForMixedUseEnum } from "@js/models/enum/MaxPercCommercialForMixedUseEnum.cs.d";
import { MaxSqFtSalePriceEnum } from "@js/models/enum/MaxSqFtSalePriceEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { PersonalGuaranteeLevelEnum } from "@js/models/enum/PersonalGuaranteeLevelEnum.cs.d";
import { PreviousDevelopmentsEnum } from "@js/models/enum/PreviousDevelopmentsEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { ShareholderDepositRequiredEnum } from "@js/models/enum/ShareholderDepositRequiredEnum.cs.d";
import { SortByEnum } from "@js/models/enum/SortByEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { ClientService } from "@js/services/ClientService";
import { DealLenderService } from "@js/services/Deal/DealLenderService";
import { DealService } from "@js/services/Deal/DealService";
import { DevFinanceDealService } from "@js/services/Deal/DevFinanceDealService";
import { DevFinanceSearchResultsService } from "@js/services/Deal/DevFinanceSearchResultsService";
import { SharedSearchResultsService } from "@js/services/Deal/SharedSearchResultsService";
import { DealClientService } from "@js/services/DealClientService";
import { EventLogService } from "@js/services/EventLogService";
import { LenderResultService } from "@js/services/LenderResultService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationLinkService } from "@js/services/OrganisationLinkService";
import { OrganisationService } from "@js/services/OrganisationService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

declare const window: Window & { dataLayer: any[] };

export class DevFinanceLenderResultScreenController {
  loadingData: boolean = false;
  // tourState: any = {
  //   tourStep: 1,
  //   tourTotalSteps: 0,
  // };
  guidanceCheckbox: boolean = true;
  // tourEnabled: boolean = false;
  totalLender: number = 0;
  isSearch: boolean = true;
  debug1: boolean = false;
  loanCriteria: DevelopmentFinanceDealDTO;
  dealLenders: DealLenderDTO[];
  tempLoanCriteria = null;
  comparisonList: LenderResultSummaryDTO[] = [];
  snapshotNewSearch: boolean = false;
  isShortlistingMore: boolean = false;
  isLoggedInUser: boolean = false;
  showLenderNamesAndLogosOverride: boolean = false;
  searchid: number = null;
  dealUniqueRef: string = null;
  dealClientUniqueRef: string = null;
  orgCode: string;
  orgName: string = "Brickflow";
  mortgageTerm: number = 0;

  //session storage values for enterprise journey
  userRole: string = null;
  clientId: string = null;
  isClient: boolean = false;

  //Enterprise user
  user: ApplicationUserDTO;
  showEnterpriseRegistrationModal: boolean = false;
  projectName: string = "";
  //TODO DEV FIN commented out until we know whether there will be an org toggle for dev finance
  //showCommercialDeal: boolean = true;
  summarySearchResults: LenderResultSummaryDTO[];
  initialSummarySearchResults: LenderResultSummaryDTO[];
  productLastVerifiedDates = {};
  deletedDealLenders: LenderProductPairDTO[];
  sliderShown: boolean;
  isAssigned: boolean = false;
  inActiveDealLender: number = 0;
  brokerageOrg: OrganisationDTO;
  moveContactBrokerBtnInMiddle: boolean = false;
  brokerDetail: string = "";
  brokerageOrgFilename: string;
  selectedUser: ApplicationUserDTO = {} as ApplicationUserDTO;
  isAdmin: boolean = false;
  isLender: boolean = false;
  isBroker: boolean = false;

  //These are added for new search functinality
  existingUsers: UserSimpleDTO[];
  newSearch: boolean = false;
  existingborrower: boolean;
  showClientDetails: boolean = false;

  isLoggingOut: boolean = false;
  appName: string;
  shareDealDto: ShareDealDTO = {
    IsApplicant: true,
    IsPrimary: true,
  } as ShareDealDTO;

  toggleEditSearchName: boolean = false;
  showProductNote: boolean = false;
  productNote: string;
  offer: LenderResultSummaryDTO;
  loanLabel: string;
  displayMsg: string = null;
  showMsg: boolean = false;
  ShowdeleteSearch: boolean = false;
  warningOff: boolean = false;
  emailExistsError: boolean = false;
  noOfShortlistAllowed: number = 5;
  showMessageToborrower: boolean = false;
  borrowerMessage: string = "";
  showContactBrokerModal: boolean = false;

  borrowingEntityTypeOptions = [];
  interestRateTypeOptions = [];
  loanRepaymentTypeOptions = [];
  timeLeftOnLeaseOptions = [];
  numberOfPreviousDevelopmentsOptions = [];
  personalGuaranteeLevelOptions = [];
  maxIndividualUnitPriceOptions = [];
  currentUseofBuildingOptions = [];
  locationOptions = [];
  depositRequiredFromShareholdersOptions = [];
  maxHouseSalePriceOptions = [];
  maxFlatUnitPriceOptions = [];
  maxSqFtSalePriceOptions = [];
  maxPercCommercialForMixedUseOptions = [];
  maxCommercialFloorspaceOptions = [];

  shareholderDepositRequired = null;
  maxHouseSalesPrice = null;
  maxFlatUnitPrice = null;
  maxSqFtSalePrice = null;
  maxSqFtSalePriceFlats = null;
  maxPercCommercialForMixedUse = null;
  previousDevelopments = null;
  personalGuaranteeLevel = null;

  hasSearchChanged: boolean = false;
  showFilters: boolean = false;

  isProceedClicked: boolean = false;
  isSaveorSaveAsClicked: boolean = false;
  productResultList: number;
  isSaveAsWthOutIntroducee: boolean = false;
  tempSearchName: string = null;
  // Flag to disable or enable the Save as button depending on the click.
  isClicked: boolean = false;
  showSaveResults: number;
  //This boolean is used in the shareedmodal.html for all the deals
  isDevelopmentFinance: boolean = true;

  isResultScreen: boolean = true;
  selecteduserName: string;
  sharedSearch: boolean = false;
  searchPanelForm: ng.IFormController;

  //This boolean is used in the shareedmodal.html for all the deals
  isDeal: boolean = true;

  buildCostIntermediary: number;
  contingencyValue: number;

  //Postcode
  showPostcodeErrorMessage: boolean = false;
  postcodeErrorMsg: string;
  isPostcodeChange: boolean = false;

  showSortBy: boolean = false;
  hasAccessToDeal: boolean = true;
  noAccessToDealMsg: string;

  //LenderReferralSearch
  lenderReferralData: MakeReferralMessageRequest =
    {} as MakeReferralMessageRequest;
  isLenderReferredSearch: boolean = false;
  isLenderReferringSearch: boolean = false;

  hasResultsProcessed: boolean = false;

  //SaveAs functionality
  isSaveAsClicked: boolean = false;
  saveAsSearchName: string;
  reloadSearch: boolean = false;

  //Enterprise journey
  showRegisterModal: boolean = false;
  showLoginSection: boolean = false;
  enterpriseClient: ApplicationUserDTO = {
    Id: "",
    AgreedToTermsAndPP: true,
    Roles: ["Client"],
  } as ApplicationUserDTO;
  error: string = "";

  // Previously shortlisted/submitted lenders which are not visible on current results because of criteria change
  HiddenDealLenderList: DealLenderDTO[] = [];
  showExportOptions: boolean = false;

  filterProperties = [
    "F_IsFirstTimeDeveloper",
    "F_NumberOfPreviousDevelopments",
    "F_ShareholderDepositRequired",
    "F_IsPersonalName",
    "F_IsOffshoreCompany",
    "F_IsMainShareholderOverseas",
    "F_PersonalGuaranteeLevel",
    "F_HasAdverseCredit",
    "F_MaxCommercialFloorspace",
    "F_IsModular",
    "F_IsAirRights",
    "F_IsPermittedDevelopmentScheme",
    "F_IsGradeOneListed",
    "F_IsGradeTwoListed",
    "F_MaxHouseSalesPrice",
    "F_MaxFlatUnitPrice",
    "F_MaxSqFtSalePrice",
    "F_MaxSqFtSalePriceFlats",
    "F_MaxPercCommercialForMixedUse",
    "F_IsWorkStarted",
    "F_MaxNumberOfUnits",
    "F_IsChargeOnAdditionalProperty",
    "F_IsFixedRate"
  ];

  static $inject = [
    "$route",
    "$routeParams",
    "$scope",
    "$rootScope",
    "$location",
    "$cookies",
    "OrganisationService",
    "DevFinanceSearchResultsService",
    "SelectListService",
    "EventLogService",
    "LenderService",
    "DealLenderService",
    "RoleService",
    "UserService",
    "SharedSearchResultsService",
    "DevFinanceDealService",
    "DealService",
    "AuthService",
    "LenderResultService",
    "DealClientService",
    "ProductService",
    "ClientService",
    'OrganisationLinkService'
  ];

  constructor(
    private $route: ng.route.IRouteService,
    private $routeParams: ng.route.IRouteParamsService,
    private $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $cookies: ng.cookies.ICookiesService,
    private organisationService: OrganisationService,
    private devFinanceSearchResultsService: DevFinanceSearchResultsService,
    private selectListService: SelectListService,
    private eventLogService: EventLogService,
    private lenderService: LenderService,
    private dealLenderService: DealLenderService,
    private roleService: RoleService,
    private $user: UserService,
    private sharedSearchResultsService: SharedSearchResultsService,
    private devFinanceDealService: DevFinanceDealService,
    private dealService: DealService,
    private $auth: AuthService,
    private lenderResultService: LenderResultService,
    private dealClientService: DealClientService,
    private productService: ProductService,
    private clientService: ClientService,
    private organisationLinkService: OrganisationLinkService
  ) {
    if (this.$location.path().startsWith("/devfinanceshortlistmore")) {
      this.isShortlistingMore = true;
    }

    //Hide header banner for borrower on page header
    /* if (
       window.self == window.top &&
       sessionStorage.getItem("userRole") &&
       sessionStorage.getItem("userRole") == "borrower" &&
       !$cookies.get("access_token")
     ) {
       document.getElementById("header").style.display = "none";
     }*/

    if (this.$routeParams.DealId) {
      if (/^\d+$/.test(this.$routeParams.DealId)) {
        // Treat the reference as an ID (e.g., fetch data using the ID)
        this.searchid = Number(this.$routeParams.DealId);
      } else {
        // Treat the reference as a unique string (e.g., fetch data using the string)
        this.dealUniqueRef = this.$routeParams.DealId;
      }
    }

    if (this.$routeParams.dealclientuniqueref) {
      this.dealClientUniqueRef = this.$routeParams.dealclientuniqueref;
    }

    if (window.self == window.top && $cookies.get("access_token")) {
      //This block is to navigate a broker to userdashboard when they do not have a license.
      if (sessionStorage.getItem("isValidLicense") == "false") {
        this.$auth.clearSelectedNavMenuAndFilterStorageData();
        this.$location.path("/userdashboard");
      }

      this.isLoggedInUser = true;
      this.loadingData = true;
      this.organisationService
        .getOrganisation()
        .then((organisation) => {
          if (organisation) {
            this.orgCode = organisation.IsWhiteLabelled
              ? organisation.OrganisationCode
              : "";
            this.showLenderNamesAndLogosOverride =
              organisation.ShowLenderNames ?? false;
            if (organisation.IsWhiteLabelled) {
              this.orgName = organisation.Name.replace(/ /g, "_");
            } else {
              this.orgName = "Brickflow";
            }
          }
        })
        .catch((error) => {
          this.loadingData = false;
        })
        .finally(() => {
          //this.loadingData = false;
        });
    } else {
      if (window.self == window.top) {
        this.userRole = sessionStorage.getItem("userRole");
        this.isClient = this.userRole == "borrower";
        this.orgCode = this.$cookies.get("org_code");
        this.clientId = sessionStorage.getItem("clientId");
        this.getSearchCriteriaAndResults();
      } else {
        this.orgCode = location.pathname.replace("/", "");
        this.guidanceCheckbox = false;
        Promise.all([
          this.organisationService
            .getData("userDetails")
            .then((userDetails) => {
              if (userDetails) {
                this.user = JSON.parse(userDetails);
              }
            }),
          this.organisationService.getData("userRole").then((userRole) => {
            if (userRole) {
              this.userRole = userRole;
              this.isClient = this.userRole == "borrower";
            }
          }),
          this.organisationService.getData("clientId").then((clientId) => {
            if (clientId) {
              this.clientId = clientId;
            }
          }),
          //Try get session storage if running in iframe before getting results
          this.organisationService.getData("newSearch").then((newSearch) => {
            if (newSearch) {
              this.snapshotNewSearch = newSearch == "true";
            }
          }),
        ])
          .then(() => {
            this.orgCode = location.pathname.replace("/", "");
            this.organisationService.sendDataToParent("newSearch", "false");
            this.getSearchCriteriaAndResults();
          })
          .catch((error) => {
            // One or both of the promises were rejected, handle the error
            console.error("Failed to get user data: ", error);
          });
      }
    }

    if (window.self == window.top) {
      // let cookieTourStep = this.$cookies.get("tourStep");
      // if (cookieTourStep) {
      //   this.tourState.tourStep = cookieTourStep;
      // }

      //if user is logged in, get profile
      if (this.$cookies.get("access_token")) {
        this.$user.getcurentuserrecord().then((result) => {
          this.selectedUser = result;

          this.roleService.isClientUser().then((response) => {
            this.isClient = response;
          });

          this.roleService.isAdminUser().then((response) => {
            this.isAdmin = response;
            if (this.isAdmin) {
              this.getUsersBelongToBrokerOrAdmin();
            }
          });

          this.roleService.isBroker().then((response) => {
            this.isBroker = response;
            if (this.isBroker) {
              this.getUsersBelongToBrokerOrAdmin();
            }
          });

          this.roleService.isLenderUser().then((response) => {
            this.isLender = response;

            ///Lender referred search
            if (sessionStorage.getItem("lenderReferralClient")) {
              this.lenderReferralData.ClientDTO = JSON.parse(
                sessionStorage.getItem("lenderReferralClient"),
              );
              this.isLenderReferringSearch = true;
            }
          });

          if (this.isShortlistingMore) {
            this.lenderService.getDealDeletedLenderIds(this.searchid).then(response => {
              this.deletedDealLenders = response;
            }).finally(() => {
              this.getSearchCriteriaAndResults();
            });
          } else {
            this.getSearchCriteriaAndResults();
          }
        });
      } else {
        this.isClient = true; // if the user isn't logged in then this is the enterprise journey and should be treated as a borrower/client
      }

      // this.updateGuidanceState();

      // $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
      //   this.updateGuidanceState();
      // });

      let logoutUnregister = $rootScope.$on(
        "logout",
        (event: ng.IAngularEvent) => {
          this.isLoggingOut = true;
        },
      );

      $scope.$on("$destroy", logoutUnregister);

      // $rootScope.$on("nextTour", (event: ng.IAngularEvent) => {
      //   this.tourNext();
      // });
      // $rootScope.$on("backTour", (event: ng.IAngularEvent) => {
      //   this.tourBack();
      // });
      // $rootScope.$on("skipTour", (event: ng.IAngularEvent) => {
      //   this.tourSkip();
      // });
    }

    this.numberOfPreviousDevelopmentsOptions =
      this.selectListService.GetNumberOfPreviousDevelopments();
    this.depositRequiredFromShareholdersOptions =
      this.selectListService.GetShareholderDepositRequired();
    this.personalGuaranteeLevelOptions =
      this.selectListService.GetPersonalGuaranteeLevels();
    this.maxHouseSalePriceOptions =
      this.selectListService.GetMaxHouseSalePrices();
    this.maxFlatUnitPriceOptions =
      this.selectListService.GetMaxFlatUnitPrices();
    this.maxSqFtSalePriceOptions =
      this.selectListService.GetMaxSqFtSalePrices();
    this.maxPercCommercialForMixedUseOptions =
      this.selectListService.GetMaxPercCommercialForMixedUse();
    this.locationOptions = this.selectListService.GetLocations();
    this.maxCommercialFloorspaceOptions = this.selectListService.GetMaxCommercialFloorspaceOptions();
  }

  /**
   * Gets the search results and criteria for selected search
   * @param none
   */
  getSearchCriteriaAndResults() {
    if (window.self == window.top) {
      this.snapshotNewSearch = sessionStorage.getItem("newSearch") == "true";
      sessionStorage.setItem("newSearch", "false");
    }
    if (this.searchid) {
      this.loadingData = true;
      this.devFinanceSearchResultsService
        .getSearchCriteriaAndResultsByDealId(
          this.searchid,
          this.snapshotNewSearch,
        )
        .then((results) => {
          this.postResultsProcessing(results);
        })
        .then(() => {
          const productIds = this.summarySearchResults.map((product) => product.ProductID);
          this.getProductLastVerifiedDates(productIds);
        })
        .then(() => {
          this.getSearchLenderCount();
          if (this.isLoggedInUser) this.getSelectedProducts();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.loanCriteria) {
            this.isSearch =
              this.loanCriteria &&
              this.loanCriteria.Status == CaseStatusEnum.Search;
            this.tempLoanCriteria = { ...this.loanCriteria };
            this.loadingData = false;
          }
          this.hasResultsProcessed = true;
        });
    } else if (this.dealUniqueRef) {
      this.loadingData = true;
      this.devFinanceSearchResultsService
        .getSearchCriteriaAndResultsByDealUniqueRef(
          this.dealUniqueRef,
          this.snapshotNewSearch,
        )
        .then((results) => {
          this.postResultsProcessing(results);
        })
        .then(() => {
          const productIds = this.summarySearchResults.map((product) => product.ProductID);
          this.getProductLastVerifiedDates(productIds);
        })
        .then(() => {
          this.getSearchLenderCount();
          if (this.loanCriteria) this.getSelectedProducts();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.loanCriteria) {
            this.isSearch =
              this.loanCriteria &&
              this.loanCriteria.Status == CaseStatusEnum.Search;
            this.tempLoanCriteria = { ...this.loanCriteria };
            this.loadingData = false;
          }
          this.hasResultsProcessed = true;
        });
    } else if (this.dealClientUniqueRef) {
      this.loadingData = true;
      this.devFinanceSearchResultsService
        .getSearchCriteriaAndResultsByDealClientUniqueRef(
          this.dealClientUniqueRef,
          this.snapshotNewSearch,
        )
        .then((results) => {
          this.postResultsProcessing(results);
        })
        .then(() => {
          this.getSearchLenderCount();
          if (this.loanCriteria) this.getSelectedProducts();

          if (
            sessionStorage.getItem("LoanCriteria") &&
            (this.$rootScope as any).previousRoute.startsWith("/compare")
          ) {
            this.comparisonList = JSON.parse(
              sessionStorage.getItem("ComparisonList"),
            );

            //Removing lsd data saved for compare view.
            sessionStorage.removeItem("LoanCriteria");
            sessionStorage.removeItem("ComparisonList");
          }
        })
        .catch((error) => { })
        .finally(() => {
          this.isSearch =
            this.loanCriteria &&
            this.loanCriteria.Status == CaseStatusEnum.Search;
          this.tempLoanCriteria = { ...this.loanCriteria };
          this.loadingData = false;
          this.hasResultsProcessed = true;
        });
    }
  }

  checkDeletedLender(lenderId: string, productId: string): boolean {
    if (this.isShortlistingMore && this.deletedDealLenders) {
      const deletedLender = this.deletedDealLenders.find(deletedLender => deletedLender.LenderId === String(lenderId));

      if (deletedLender) {
        return deletedLender.ProductId === String(productId);
      }
    }
    return false;
  }

  postResultsProcessing(results) {
    if (results.HasNoAccessToDeal) {
      this.hasAccessToDeal = false;
      if (this.isClient) {
        this.noAccessToDealMsg =
          "Please contact your broker in order to access this case.";
      } else {
        this.noAccessToDealMsg = "Error retrieving deal.";
      }
    } else {
      this.summarySearchResults = this.initialSummarySearchResults = results.Results;
      this.loanCriteria = results.CriteriaDto;
      this.sortSummaryResults();

      if (this.loanCriteria.LoanTermReq && this.loanCriteria.LoanTermReq > 0) {
        this.mortgageTerm = this.loanCriteria.LoanTermReq / 12;
      }

      if (this.isLoggedInUser) {
        if (this.dealClientUniqueRef) {
          if (this.isClient) {
            this.eventLogService.logPageLoad(
              "AFTER-ENTERPRISE-LEAD-REGISTRATION-USING-EMAIL-LINK",
              this.orgCode,
              this.selectedUser.UserName,
              "Client",
              this.loanCriteria?.OrganisationLinkId,
              this.loanCriteria.ProductType,
              "",
              this.loanCriteria?.Id,
            );
          }
        } else {
          if (this.loanCriteria.LenderReferralLenderId)
            this.isLenderReferredSearch = true;

          // Expanding a 'new search' panel for first time search execution
          if (window.self == window.top && sessionStorage.getItem("newSearch")) {
            this.sliderShown = (this.$rootScope as any).previousRoute.startsWith("/compare") ? false : true;
            sessionStorage.removeItem("newSearch");
          }
        }
      } else {
        if (this.$location.path().startsWith("/e/devfinanceresults")) {
          this.populateEnterpriseClientValue("LENDERRESULTS");
        }

        if (this.dealClientUniqueRef) {
          if (this.$routeParams.showOnlyRegister == "false")
            this.isClient = true;
          this.populateEnterpriseClientValue("LENDERRESULTS-USING-EMAIL-LINK");
        }
      }

    }
  }

  getSearchLenderCount() {
    this.lenderService
      .getTotalLenders(ProductFamilyEnum.Development)
      .then((result: number) => {
        this.totalLender = result;
      });
  }

  getSelectedProducts() {
    if (this.summarySearchResults && this.summarySearchResults.length > 0) {
      this.dealLenderService
        .fetchDealLendersByDealId(this.dealClientUniqueRef || this.dealUniqueRef ? this.loanCriteria.Id : this.searchid)
        .then((results) => {
          this.dealLenders = results;
        })
        .then(() => {
          this.comparisonList = this.summarySearchResults.filter((result) => {
            const isDealLender = this.dealLenders.find(
              (dealLender) => dealLender.ProductId === result.ProductID,
            );
            if (isDealLender) {
              result.DealLenderStatus = isDealLender.Status;
            }
            return (result.IsDealLender = Boolean(isDealLender));
          });

          // Previously shortlisted/submitted lenders which are not visible on current results because of criteria change
          this.HiddenDealLenderList = this.dealLenders.filter(l =>
            !this.summarySearchResults.some(s => l.ProductId === s.ProductID)
          );

          if (this.comparisonList.length > 0) {
            var inActiveDealLenderList = this.comparisonList.filter(
              (ia) =>
                ia.DealLenderStatus == CaseLenderStateEnum.Rejected ||
                ia.DealLenderStatus == CaseLenderStateEnum.Withdrawn,
            );
            this.inActiveDealLender = inActiveDealLenderList.length;
          }

          if (
            sessionStorage.getItem("LoanCriteria") &&
            (this.$rootScope as any).previousRoute.startsWith("/compare")
          ) {
            if (sessionStorage.getItem("actualcomparisonList")) {
              var selectedProducts = sessionStorage.getItem(
                "actualcomparisonList",
              );
              this.comparisonList = JSON.parse(selectedProducts);
              sessionStorage.removeItem("actualcomparisonList");
            } else {
              this.comparisonList = JSON.parse(
                sessionStorage.getItem("ComparisonList"),
              );
            }
            if (this.inActiveDealLender > 0) {
              this.comparisonList = this.comparisonList.concat(
                inActiveDealLenderList,
              );
            }

            //Removing lsd data saved for compare view.
            sessionStorage.removeItem("LoanCriteria");
            sessionStorage.removeItem("ComparisonList");
          }
        });
    }
  }

  getOrganisationAndBrokerDetails() {
    if (!this.isLoggedInUser && !this.isAdmin && !this.isBroker) {
      this.loadingData = true;
      this.organisationService
        .getOrganisationAndBrokerByDealId(this.loanCriteria.Id)
        .then((response) => {
          if (response && response.Organisation != null) {
            this.brokerageOrg = response.Organisation;

            if (response.Organisation.IsBrickflow) {
              this.moveContactBrokerBtnInMiddle = true;
            } else {
              if (response.Broker != null) {
                this.brokerDetail = `${response.Broker.FullName} (${response.Broker.Email})`;
              }

              if (this.brokerageOrg.IsWhiteLabelled) {
                this.orgName = this.brokerageOrg.Name.replace(/ /g, "_");
              }
            }
          }
        })
        .finally(() => {
          this.loadingData = false;
        });
    }
  }

  getUsersBelongToBrokerOrAdmin() {
    this.$user
      .getUserSummariesForBrokerOrAdmin()
      .then((response) => {
        this.existingUsers = response;
      });
  }

  // updateGuidanceState() {
  //   this.tourEnabled = this.sharedSearchResultsService.updateGuidanceState(
  //     this.guidanceCheckbox,
  //   );
  // }

  // getGuidanceSwitchState() {
  //   return this.sharedSearchResultsService.getGuidanceSwitchState();
  // }

  // recordGuidanceCookie() {
  //   this.sharedSearchResultsService.recordGuidanceCookie(this.guidanceCheckbox);
  //   this.$rootScope.$broadcast("guidanceStateChanged");
  // }

  // tourNext(): void {
  //   this.tourState.tourStep++;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  // }

  // tourBack(): void {
  //   this.tourState.tourStep--;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  // }

  // tourSkip(): void {
  //   this.tourEnabled = false;
  //   this.$cookies.put("tourEnabled", "false");
  //   this.$cookies.remove("tourStep");
  // }

  // startTour(): void {
  //   this.tourState.tourStep = 1;
  //   this.tourState.tourTotalSteps = 0;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  //   this.tourEnabled = true;
  //   this.$cookies.put("tourEnabled", "true");
  // }

  /**
   * Saves the new search name on the edit.
   * @param none
   */
  doRenameSearch() {
    var request: SaveDevFinanceSearchRequest = {
      DealDto: this.loanCriteria,
      ShareDealDto: this.shareDealDto,
      OrgCode: this.orgCode,
      EnterpriseClientDto: null,
    };

    if (this.searchid) {
      this.devFinanceDealService.saveDevFinanceSearchReturnsId(request)
        .then((response) => {
          this.toggleEditSearchName = false;
        });
    } else if (this.dealUniqueRef) {
      this.devFinanceDealService.saveDevFinanceSearchReturnsUniqueRef(request)
        .then((response) => {
          this.toggleEditSearchName = false;
        });
    } else {
      this.toggleEditSearchName = false;
    }
  }

  toggleLenderComparisonSelection(item: LenderResultSummaryDTO, index: number) {

    let comparisonMatches: LenderResultSummaryDTO[] =
      this.comparisonList.filter((result, index) => {
        return result.ProductID === item.ProductID;
      });

    if (!comparisonMatches || comparisonMatches.length === 0) {
      this.comparisonList.push(item);
      this.eventLogService.logEvent(
        `SHORTLISTING-RESULT`,
        EventLogEnum.Shortlisting,
        this.loanCriteria.ProductType,
        this.loanCriteria.Id,
        "",
        item.ProductID.toString(),
        ""
      );
    } else {
      if (this.isShortlistingMore && item.IsDealLender && (this.loanCriteria.Status != CaseStatusEnum.Search && this.loanCriteria.Status != CaseStatusEnum.NewCase))
        return;
      comparisonMatches.forEach((value, index) => {
        this.comparisonList.splice(this.comparisonList.indexOf(value), 1);
      });
      this.eventLogService.logEvent(
        `DESELECTING-SHORTLISTED-RESULT`,
        EventLogEnum.Shortlisting,
        this.loanCriteria.ProductType,
        this.loanCriteria.Id,
        "",
        item.ProductID.toString(),
        ""
      );
    }
    //This is user for shortlisting pdf
    this.prepareDataForShortlistPdf();
  }

  //This is used in shortlisting pdf
  prepareDataForShortlistPdf() {
    this.loanCriteria.ComparisonList = this.comparisonList.filter(
      (cl) =>
        cl.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        cl.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );
  }

  comparisonContains(item: LenderResultSummaryDTO): boolean {
    return !!this.comparisonList.find((result, index) => {
      return result.ProductID === item.ProductID;
    });
  }

  selectionOrderNumber(item: LenderResultSummaryDTO) {
    var activeProductList = this.comparisonList.filter(
      (cl) =>
        cl.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        cl.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );

    var hiddenProduct = this.HiddenDealLenderList.filter(hl => hl.Status != CaseLenderStateEnum.Rejected && hl.Status != CaseLenderStateEnum.Withdrawn);
    var hiddenProductLength = this.isShortlistingMore ? hiddenProduct.length : 0;

    var order =
      activeProductList.map((item) => item.ProductID).indexOf(item.ProductID) + hiddenProductLength +
      1;
    if (order === 0) {
      return " ";
    }

    return order;
  }

  /**
   * Processes the clicking of the "View Eligibility" anchor/button and index to show on the row number
   * @param item BridgingLenderResultSummaryDTO
   */
  viewEligibility(item: LenderResultSummaryDTO) {
    event.preventDefault(); // prevents default behaviour of href
    this.showProductNote = true;
    this.offer = item;
    this.loanLabel = item.LoanLabel;
    this.productNote = item.AdditionalProductInfo;
  }

  /**
   *Marks a selected search as a deleted
   * @param
   */
  deleteSearch() {
    if (this.$routeParams.DealId) {
      this.dealService
        .markasdeleted(this.$routeParams.DealId)
        .then((response) => {
          this.displayMsg = "Search is successfully deleted";
          this.showMsg = true;
          this.ShowdeleteSearch = true;
        })
        .catch((error) => {
          this.displayMsg = "Sorry, something went wrong. Please try again.";
          this.showMsg = true;
        });
    }
  }

  closeDeleteModal() {
    this.showMsg = false;
    this.displayMsg = null;
    this.ShowdeleteSearch = false;
    this.warningOff = true;
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  viewSingleLoan(item: LenderResultSummaryDTO) {
    event.preventDefault(); // prevents default behaviour of href
    this.warningOff = true;
    //Storing the actual comparisonList to local storage so that to recover it later when we comeback to result screen from compare screen.
    sessionStorage.setItem(
      "actualcomparisonList",
      JSON.stringify(this.comparisonList),
    );
    this.comparisonList = [];

    this.comparisonList.push(item);
    this.goCompare();
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
  }

  goCompare() {
    this.warningOff = true;

    this.sharedSearchResultsService.clearStorageDataForCompareView();

    let comparisonList = this.comparisonList.filter(
      (cl) =>
        cl.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        cl.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );

    sessionStorage.setItem("ComparisonList", JSON.stringify(comparisonList));
    sessionStorage.setItem("LoanCriteria", JSON.stringify(this.loanCriteria));

    if (this.dealClientUniqueRef) {
      if (!this.isLoggedInUser) {
        this.dealUniqueRef = this.loanCriteria.UniqueRef;
        //This property is used in compare view controller to find previous route 
        sessionStorage.setItem("DealClientUniqueRef", this.dealClientUniqueRef);
      } else {
        this.searchid = this.loanCriteria.Id;
      }
    }


    if (this.searchid) {
      this.$location.path("compare/" + this.searchid + "/" + 0 + "/" + true);
    } else {
      this.$location.path("compare/" + this.dealUniqueRef + "/" + 0 + "/" + true);
    }
  }

  showBookMeetingButton() {
    return (
      !this.isLoggedInUser &&
      this.isClient &&
      this.brokerDetail == "" &&
      this.brokerageOrg != null
    );
  }

  bookMeeting() {
    this.$auth
      .getHubspotDeveloperBookMeetingWithSearch()
      .then((hubSpotUrl: string) => {
        window.open(hubSpotUrl);
      });
  }

  sendMessageToBroker(message) {
    this.loadingData = true;
    this.borrowerMessage = null;
    this.organisationService
      .sendBorrowerMessageToSearchBroker(
        this.loanCriteria.Id,
        message,
        this.loanCriteria.DealClients[0].Client.FirstName,
        this.loanCriteria.DealClients[0].Client.LastName,
        this.loanCriteria.DealClients[0].Client.Email,
        ProductTypeEnum.Development,
        "Results",
      )
      .then((results) => {
        if (results) {
          this.displayMsg = `Message has been sent successfully.`;
          this.showMessageToborrower = true;
        } else {
          this.displayMsg = `There is problem sending a message, Please try later.`;
          this.showMessageToborrower = true;
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  closeContactBrokerModal() {
    this.showContactBrokerModal = false;
    this.showMessageToborrower = false;
    this.displayMsg = null;
  }

  getTotalShortlistedLenders() {
    var activeDealLenders = this.comparisonList.filter(
      (al) =>
        al.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        al.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );

    var hiddenProduct = this.HiddenDealLenderList.filter(hl => hl.Status != CaseLenderStateEnum.Rejected && hl.Status != CaseLenderStateEnum.Withdrawn);
    var hiddenProductLength = this.isShortlistingMore ? hiddenProduct.length : 0;

    return activeDealLenders.length + hiddenProductLength;
  }

  logPdfDownload(eventName) {
    this.eventLogService.logEvent(
      eventName,
      EventLogEnum.Export,
      this.loanCriteria.ProductType,
      this.loanCriteria.Id,
    );
  }

  go(path): void {
    this.$location.path(path);
  }

  changeSearch() {
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
    this.warningOff = true;
    sessionStorage.setItem("skip", "true");
    if (!this.isLoggedInUser && (this.dealUniqueRef || this.dealClientUniqueRef)) {
      if (this.dealClientUniqueRef) sessionStorage.setItem('previousRoute', 'referredsearchdeal')
      var url = `/e/devfinancecriteria/${this.loanCriteria.UniqueRef}`;
      if (this.loanCriteria.OrganisationLinkId > 0) {
        url = `${url}/${this.loanCriteria.OrganisationLinkId}`;
      }
      this.go(url);
    }
    else if (this.searchid) {
      this.go("/devfinancecriteria/" + this.searchid);
    } else {
      this.go("/devfinancecriteria");
    }
  }

  newloan() {
    (this.$rootScope as any).loanCriteria = null;
    this.$location.path("/devfinancecriteria/0");
  }

  updateSearchViaSlider(isAdminDebugValueChanged: boolean = false): void {
    if (this.searchPanelForm.$valid && !this.showPostcodeErrorMessage) {
      if (this.loanCriteria.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing) {
        if (this.loanCriteria.PurchasePrice <= 0) return;
      }

      if (this.loanCriteria.OwnOrPurchase == OwnOrPurchaseEnum.Own) {
        if (this.loanCriteria.Currentvalue <= 0) return;
      }

      if (this.loanCriteria.GDV <= 0) return;

      this.loadingData = true;
      this.hasSearchChanged = true;

      this.clearSelected();

      this.updateCriteriaAndGetResults(
        isAdminDebugValueChanged && this.isAdmin ? false : true,
      );
    }
  }

  clearSelected() {
    if (this.isShortlistingMore) {
      this.comparisonList = this.comparisonList.filter(item =>
        this.summarySearchResults.some(result => result.ProductID == item.ProductID)
      );

      const dealLenders = this.initialSummarySearchResults.filter(result =>
        result.IsDealLender && result.DealLenderStatus === CaseLenderStateEnum.Received && !result.IsDeleted
      );

      dealLenders.forEach(dealLender => {
        if (!this.comparisonList.some(item => item.ProductID === dealLender.ProductID)) {
          this.comparisonList.push(dealLender);
        }
      });
    } else {
      this.comparisonList = this.comparisonList.filter(item =>
        this.summarySearchResults.some(result => result.ProductID == item.ProductID)
      );
    }
  }

  updateCriteriaAndGetResults(criteriaChanged: boolean, updateLoanLabels: boolean = false) {
    this.devFinanceDealService.updateSearchCriteriaAndGetResults(this.loanCriteria, criteriaChanged, this.debug1, this.isPostcodeChange, this.isShortlistingMore).then((results: UpdateDevFinanceSearchGetResultResponse) => {
      if (this.isPostcodeChange) {
        if (results.CriteriaLocation != null) {
          this.loanCriteria.Locations = results.CriteriaLocation;
          this.summarySearchResults = results.Results;
          this.sortSummaryResults();

        } else {
          this.postcodeErrorMsg = results.PostCodeErrorMessage;
          this.showPostcodeErrorMessage = true;
        }
      } else {
        this.summarySearchResults = results.Results;
        this.sortSummaryResults();
      }
    }).catch((error) => {

    }).finally(() => {
      this.tempLoanCriteria = { ...this.loanCriteria };
      this.clearSelected();
      this.isPostcodeChange = false;

      if (updateLoanLabels) {
        this.updateLoanLabels();
      }
      this.loadingData = false;
    });
  }

  getSearchResultCount() {
    return this.sharedSearchResultsService.getSearchResultCount(
      this.summarySearchResults,
    );
  }

  onPersonalGuaranteeLevelOptionsChange(e: PersonalGuaranteeLevelEnum) {
    switch (e) {
      case PersonalGuaranteeLevelEnum.UpTo20Percent:
        this.loanCriteria.F_PersonalGuaranteeLevel = 0.2;
        break;
      case PersonalGuaranteeLevelEnum.TwentyOneTo35Percent:
        this.loanCriteria.F_PersonalGuaranteeLevel = 0.35;
        break;
      case PersonalGuaranteeLevelEnum.ThirtySixTo50Percent:
        this.loanCriteria.F_PersonalGuaranteeLevel = 0.5;
        break;
      case PersonalGuaranteeLevelEnum.OneHundredPercent:
        this.loanCriteria.F_PersonalGuaranteeLevel = 1.0;
        break;
      default:
        this.loanCriteria.F_PersonalGuaranteeLevel = null;
        break;
    }
  }

  getPersonalGuaranteeLevelOptions() {
    var value = null;
    switch (this.tempLoanCriteria.F_PersonalGuaranteeLevel) {
      case 0.2:
        value = PersonalGuaranteeLevelEnum.UpTo20Percent;
        break;
      case 0.35:
        value = PersonalGuaranteeLevelEnum.TwentyOneTo35Percent;
        break;
      case 0.5:
        value = PersonalGuaranteeLevelEnum.ThirtySixTo50Percent;
        break;
      case 1.0:
        value = PersonalGuaranteeLevelEnum.OneHundredPercent;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  applyFilters() {
    this.loadingData = true;
    this.showFilters = false;
    this.updateCriteriaAndGetResults(false);
  }

  clearClientFilters() {
    this.loanCriteria.F_IsFirstTimeDeveloper = null;
    this.loanCriteria.F_NumberOfPreviousDevelopments = null;
    this.loanCriteria.F_ShareholderDepositRequired = null;
    this.shareholderDepositRequired = null;
    this.loanCriteria.F_IsPersonalName = null;
    this.loanCriteria.F_IsOffshoreCompany = null;
    this.loanCriteria.F_IsMainShareholderOverseas = null;
    this.loanCriteria.F_PersonalGuaranteeLevel = null;
    this.personalGuaranteeLevel = null;
    this.loanCriteria.F_HasAdverseCredit = null;
    this.loanCriteria.F_MaxCommercialFloorspace = null;
  }

  showClearForClientFilters() {
    if (
      this.loanCriteria.F_IsFirstTimeDeveloper != null ||
      this.loanCriteria.F_NumberOfPreviousDevelopments != null ||
      this.loanCriteria.F_ShareholderDepositRequired != null ||
      this.loanCriteria.F_IsPersonalName != null ||
      this.loanCriteria.F_IsOffshoreCompany != null ||
      this.loanCriteria.F_IsMainShareholderOverseas != null ||
      this.loanCriteria.F_PersonalGuaranteeLevel != null ||
      this.loanCriteria.F_HasAdverseCredit != null ||
      this.loanCriteria.F_MaxCommercialFloorspace != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleCheckbox(variableName) {
    if (
      this.loanCriteria[variableName] === null ||
      this.loanCriteria[variableName] === false
    ) {
      this.loanCriteria[variableName] = true;
    } else {
      this.loanCriteria[variableName] = null;
    }
  }

  clearPropertyFilters() {
    this.loanCriteria.F_IsAirRights = null;
    this.loanCriteria.F_IsModular = null;
    this.loanCriteria.F_IsPermittedDevelopmentScheme = null;
    this.loanCriteria.F_IsGradeOneListed = null;
    this.loanCriteria.F_IsGradeTwoListed = null;
    this.loanCriteria.F_MaxHouseSalesPrice = null;
    this.loanCriteria.F_MaxFlatUnitPrice = null;
    this.loanCriteria.F_MaxNumberOfUnits = null;
    this.loanCriteria.F_MaxPercCommercialForMixedUse = null;
    this.loanCriteria.F_MaxSqFtSalePrice = null;
    this.loanCriteria.F_MaxSqFtSalePriceFlats = null;
    this.loanCriteria.F_IsWorkStarted = null;
    this.maxHouseSalesPrice = null;
    this.maxFlatUnitPrice = null;
    this.maxSqFtSalePrice = null;
    this.maxPercCommercialForMixedUse = null;
    this.maxSqFtSalePriceFlats = null;
    this.previousDevelopments = null;
  }

  showClearForPropertyFilters() {
    if (
      this.loanCriteria.F_IsAirRights != null ||
      this.loanCriteria.F_IsModular != null ||
      this.loanCriteria.F_IsPermittedDevelopmentScheme != null ||
      this.loanCriteria.F_IsGradeOneListed != null ||
      this.loanCriteria.F_IsGradeTwoListed != null ||
      this.loanCriteria.F_MaxHouseSalesPrice != null ||
      this.loanCriteria.F_MaxFlatUnitPrice != null ||
      this.loanCriteria.F_MaxNumberOfUnits != null ||
      this.loanCriteria.F_MaxPercCommercialForMixedUse != null ||
      this.loanCriteria.F_MaxSqFtSalePrice != null ||
      this.loanCriteria.F_IsWorkStarted != null ||
      this.loanCriteria.F_MaxSqFtSalePriceFlats != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearFinanceFilters() {
    this.loanCriteria.F_IsChargeOnAdditionalProperty = null;
    this.loanCriteria.F_IsFixedRate = null;
  }

  showClearForFinanceFilters() {
    if (
      this.loanCriteria.F_IsChargeOnAdditionalProperty != null ||
      this.loanCriteria.F_IsFixedRate != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearAllFilters() {
    this.clearClientFilters();
    this.clearPropertyFilters();
    this.clearFinanceFilters();
  }

  debugSearch() {
    const stringJSON = JSON.stringify(this.loanCriteria);
    navigator.clipboard
      .writeText(stringJSON)
      .then()
      .catch((e) => console.log(e));
  }

  onClickFilterCancel() {
    this.filterProperties.forEach(
      (e) => (this.loanCriteria[e] = this.tempLoanCriteria[e]),
    );

    this.shareholderDepositRequired = null;
    this.personalGuaranteeLevel = null;
    this.maxHouseSalesPrice = null;
    this.maxFlatUnitPrice = null;
    this.maxSqFtSalePrice = null;
    this.maxSqFtSalePriceFlats = null;
    this.maxPercCommercialForMixedUse = null;
  }

  checkFilterSelected(property: string) {
    return this.sharedSearchResultsService.checkFilterSelected(
      property,
      this.tempLoanCriteria,
    );
  }

  getDisplayTextForProperty(property) {
    if (property == "F_MaxNumberOfUnits") {
      return `Max ${this.loanCriteria.F_MaxNumberOfUnits} Units`;
    } else {
      return this.sharedSearchResultsService.getDisplayTextForProperty(
        property,
      );
    }
  }

  getPropertyValue(property) {
    let propertyValue = this.loanCriteria[property];
    switch (property) {
      case "F_ShareholderDepositRequired":
        return this.selectListService
          .GetShareholderDepositRequired()
          .find((item) => item.key === this.getShareholderDepositRequired())
          ?.displayName;
      case "F_PersonalGuaranteeLevel":
        return this.selectListService
          .GetPersonalGuaranteeLevels()
          .find((item) => item.key === this.getPersonalGuaranteeLevelOptions())
          ?.displayName;
      case "F_MaxHouseSalesPrice":
        return this.selectListService
          .GetMaxHouseSalePrices()
          .find((item) => item.key === this.getMaxHouseSalesPrice())
          ?.displayName;
      case "F_MaxFlatUnitPrice":
        return this.selectListService
          .GetMaxFlatUnitPrices()
          .find((item) => item.key === this.getMaxFlatUnitPrice())?.displayName;
      case "F_MaxSqFtSalePrice":
        return this.selectListService
          .GetMaxSqFtSalePrices()
          .find((item) => item.key === this.getMaxSqFtSalePrice())?.displayName;
      case "F_MaxSqFtSalePriceFlats":
        return this.selectListService
          .GetMaxSqFtSalePrices()
          .find((item) => item.key === this.getMaxSqFtSalePriceFlats())
          ?.displayName;
      case "F_MaxPercCommercialForMixedUse":
        return this.selectListService
          .GetMaxPercCommercialForMixedUse()
          .find((item) => item.key === this.getMaxPercCommercialForMixedUse())
          ?.displayName;
      case "F_NumberOfPreviousDevelopments":
        return this.selectListService
          .GetNumberOfPreviousDevelopments()
          .find((item) => item.key === this.getPreviousDevelopments())
          ?.displayName;
      case "F_MaxNumberOfUnits":
        return propertyValue;
      case "F_MaxCommercialFloorspace":
        return this.selectListService
          .GetMaxCommercialFloorspaceOptions()
          .find((item) => item.key === this.tempLoanCriteria.F_MaxCommercialFloorspace)
          ?.displayName;
      default:
        return propertyValue;
    }
  }

  filterPropertyToText(property: string) {
    if (property === "F_MaxNumberOfUnits") return;

    let value = this.tempLoanCriteria[property];
    if (typeof value == "boolean" && value == true) {
      return;
    } else if (value != null && typeof value != "boolean") {
      return `: ${this.getPropertyValue(property)}`;
    }else if(value == null){
      return;
    }
    return false;
  }

  countFiltersSelected() {
    let count = 0;
    count = this.sharedSearchResultsService.countFiltersSelected(
      this.tempLoanCriteria,
      this.filterProperties,
    );
    return count;
  }

  /*saveEnterpriseClientAndRenameDeal() {
            this.loadingData = true;

            this.$user.checkEmailExists(this.user.Email).then((response) => {
                if (!response) {
                    let userFullName = this.user.FullName;
                    let spaceIndex = userFullName.indexOf(' ');

                    let firstName = '';
                    let lastName = '';

                    // if not space has been added to the name then put the whole name in the first name field
                    if (spaceIndex == -1) {
                        firstName = userFullName;
                    } else {
                        firstName = userFullName.substring(0, userFullName.indexOf(' '));
                        lastName = userFullName.substring(userFullName.indexOf(' ') + 1);
                    }

                    // Set up Client dto
                    var clientDto = {
                        FirstName: firstName,
                        LastName: lastName,
                        PhoneNumber: this.user.PhoneNumber,
                        Email: this.user.Email,
                        BrokerOrganisationId: this.brokerageOrg.Id,
                        PostalAddress: {
                            AddressLine1: '',
                            AddressLine2: '',
                            AddressLine3: '',
                            AddressLine4: '',
                            PostCode: ''
                        } as PostalAddress
                    } as ClientDTO;

                    var currentDate = new Date();
                    this.loanCriteria.Name = this.projectName ? this.projectName : firstName + ' ' + lastName + ' Loan Search ' + currentDate.getDate() + "/" + (currentDate.getMonth() + 1) + "/" + currentDate.getFullYear()

                    var request: SaveEnterpriseClientAndRenameSearchRequest = {
                        BridgingDealDto: null,
                        CommercialDealDto: null,
                        ClientDto: clientDto,
                        ProductFamily: ProductFamilyEnum.Development,
                        DevFinanceDealDto: this.loanCriteria
                    }

                    this.dealService.saveEnterpriseClientAndRenameDeal(request).then((response) => {
                        if (response) {
                            document.getElementById('body').style.overflow = "auto";
                            this.showEnterpriseRegistrationModal = false;
                            this.loanCriteria = response.DevFinanceDealDto;
                            if (window.self == window.top) {
                                sessionStorage.setItem('clientId', this.loanCriteria.DealClients[0].Client.Id.toString());
                            } else {
                                this.organisationService.sendDataToParent('clientId', this.loanCriteria.DealClients[0].Client.Id.toString());
                            }

                            this.$user.sendEventToHubspot(this.user, "ENTERPRISE-CONTACT-REGISTERED", false, this.orgCode, this.userRole, this.loanCriteria && this.loanCriteria.OrganisationLinkId != null ? this.loanCriteria.OrganisationLinkId : 0, this.loanCriteria.ProductType, this.loanCriteria ? this.loanCriteria.Id : 0);

                            // For Google analytics tracking
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                'event': 'results_gating_form',
                                'loan_product_family': this.productService.getProductFamilyTextForGa(this.loanCriteria.ProductFamily),
                                'loan_product_type': this.productService.getProductTypeTextForGa(this.loanCriteria.ProductType, this.loanCriteria.IsFamilyInResidence)
                            });
                        }

                    }).finally(() => {
                        this.loadingData = false;
                    });
                } else {
                    this.emailExistsError = true;
                    this.loadingData = false;
                }
            });


        }*/

  gotoSignInPage() {
    this.$location.path("/login");
  }

  goToLink(url) {
    var baseUrl = window.location.href.split("#!")[0] + "#!";
    var newUrl = baseUrl + url;
    window.open(newUrl, "_blank");
  }

  /**
   * Opens a 'share a search with client' modal if search do not have client attached to.
   * @param none
   */
  saveResultsForShareSearchToClient() {
    if (!this.isSaveAsClicked) {
      this.register(false, this.productResultList);
    } else {
      this.saveSaveAsSearch();
    }
    this.newSearch = false;
  }

  showShareSearchModal() {
    this.newSearch = true;

    this.shareDealDto = {
      DealId: this.searchid,
      DealName: this.isSaveAsClicked ? "" : this.loanCriteria?.Name,
      ShowLenderNamesAndLogos: this.loanCriteria?.ShowLenderInfoBrokerOverride,
      EmailLinkToClient: false,
      AccessLevel: CaseAccessLevelEnum.FullAccess,
      IsApplicant: true,
      IsPrimary: true,
    } as ShareDealDTO;

    this.shareDealDto.ClientDto = {
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
    } as ClientDTO;
  }

  /**
   * Saves the search or saves the search and create a case.
   * @param createCase (boolean set to true when promoting search to case)
   */

  //TODO: this functon does not need a productResultList parameter but as views have been shared with devfinance we are passing it
  register(createCase: boolean = false, productResultList: number) {
    this.loadingData = true;
    if (this.isShortlistingMore) {
      if(this.validateWithdrawnLendersUnique() == true){
        this.dealLenderService.shortlistMoreLenders(this.loanCriteria.Id, this.getDealLenders(this.comparisonList), this.hasSearchChanged).then((response) => {
        }).then(() => {
          this.getSelectedProducts();
        }).finally(() => {
          this.loadingData = false;
        });
      }else{
        this.loadingData = false;
      }    
    } else {
      if (
        this.shareDealDto &&
        this.shareDealDto.DealName &&
        this.shareDealDto.DealName.length > 0
      ) {
        this.loanCriteria.Name = this.shareDealDto.DealName;
      }

      var request: SaveDevFinanceSearchRequest = {
        DealDto: this.loanCriteria,
        ShareDealDto: null,
        OrgCode: "",
        EnterpriseClientDto: null,
      };

      this.devFinanceDealService
        .saveDevFinanceSearchReturnsId(request)
        .then((response) => {
          this.loanCriteria.Id = response as number;
          this.loadingData = false;

          // Creating the case is done on the casedashboard for deals
          if (createCase) {
            this.$location.path(
              "/devfinancecasedashboard/" + this.loanCriteria.Id + "/" + true,
            );
          }

          var shortlistDealLenders = this.getDealLenders(this.comparisonList);

          this.dealLenderService
            .addUpdatelistreturnonlyids(shortlistDealLenders)
            .then((response) => {
              if (this.dealLenders != null) {
                let deallenders = this.dealLenders.filter(
                  (lender) => response.indexOf(lender.Id) === -1,
                );

                deallenders.forEach((lender) =>
                  this.dealLenderService.markasdeleted(lender.Id),
                );
              }
            })
            .finally(() => {
              this.getSelectedProducts();
              this.isClicked = false;
              this.newSearch = false;
              this.isProceedClicked = false;
              this.showSaveResults = null;
              this.loadingData = false;
            });
        })
        .catch((response) => {
          //TODO BRIDGING - capture if an error has occurred and process it
          //this.isError = true;
          this.isProceedClicked = false;
        })
        .finally(() => { });
    }
  }

  /**
   * updates a seach with introducee details and saves it and sends emil to client if 'notifyBorrower' is set to true.
   * @param notifyBorrower
   */

  sendResultsToClient(notifyBorrower) {
    if (this.isSaveAsClicked) {
      this.saveSaveAsSearch(true);
    } else {
      this.loadingData = true;
      this.dealService
        .shareDealWithClient(this.shareDealDto)
        .then(() => {
          this.displayMsg =
            "Search results have been sent to " +
            this.shareDealDto.ClientDto.FirstName +
            " " +
            this.shareDealDto.ClientDto.LastName;
          //this.loanCriteria.DealClients[0] = this.shareDealDto.ClientDto;
          this.loanCriteria.Name = this.shareDealDto.DealName;
          this.newSearch = false;
          this.showMsg = true;
          this.loadingData = false;
          this.isAssigned = true;
          this.reloadSearch = true;
        })
        .finally(() => {
          delete this.shareDealDto;
          this.loadingData = false;
        });
    }
  }

  getDealLenders(selectedLenders: LenderResultSummaryDTO[]) {
    var lendersList: DealLenderDTO[] = [];

    if (this.dealClientUniqueRef || this.dealUniqueRef) {
      this.searchid = this.loanCriteria.Id;
    }

    selectedLenders.forEach(({ ProductID, LenderID, GrossLoan }) => {
      var dealLender: DealLenderDTO;

      if (this.dealLenders) {
        dealLender = this.dealLenders.find(
          (dl) =>
            dl.DealId === this.searchid &&
            dl.ProductId === ProductID &&
            dl.LenderId === LenderID,
        );
      }

      if (!dealLender) {
        dealLender = {
          DealId: this.searchid,
          ProductId: ProductID,
          LenderId: LenderID,
          Status: CaseLenderStateEnum.Shortlisted,
          OriginalTotalGrossLoan: GrossLoan,
        } as DealLenderDTO;
      }

      lendersList.push(dealLender);
    });

    var unselecteLenderList = [];

    if (this.dealLenders) {
      unselecteLenderList = this.dealLenders.filter(dl => !lendersList.some(ll => ll.ProductId === dl.ProductId && ll.LenderId === dl.LenderId) && (dl.Status == CaseLenderStateEnum.Rejected || dl.Status == CaseLenderStateEnum.Withdrawn));
    }

    if (unselecteLenderList.length > 0) {
      unselecteLenderList.forEach((l) => {
        l.IsDeleted = true;
        lendersList.push(l);
      });
    }

    return lendersList;
  }

  /** closes share results modal*/
  cancelNewSearch() {
    this.newSearch = false;
    this.shareDealDto.ClientDto = null;
    this.existingborrower = null;
    this.showClientDetails = false;
    this.selecteduserName = null;
    this.isSaveAsClicked = false;
    this.sharedSearch = false;
    if (this.tempSearchName) {
      this.loanCriteria.Name = this.tempSearchName;
      this.tempSearchName = "";
    }
  }

  openSendResultsToBorrowerModal() {
    var client = this.loanCriteria.DealClients[0]["Client"]; // TODO Deal FUTURE- always want to assume there's one for now until there's a redesign of sharing
    this.shareDealDto.DealId = this.loanCriteria.Id;
    this.shareDealDto.DealName = this.loanCriteria.Name;
    this.shareDealDto.IsApplicant = true;
    this.shareDealDto.IsPrimary = true;
    this.shareDealDto.ClientDto = {
      FirstName: client.FirstName,
      LastName: client.LastName,
      Email: client.Email,
      PhoneNumber: client.PhoneNumber,
      ClientUserId: client.ClientUserId,
    } as ClientDTO;
    this.existingborrower = null;
    this.newSearch = true;
    this.sharedSearch = true;
  }

  viewClientsDashboardForNewSearch(userName: string) {
    this.loadingData = true;
    this.shareDealDto.IsApplicant = true;
    this.shareDealDto.IsPrimary = true;
    //Look up client's account details
    this.$user.searchByEmail(userName).then((users) => {
      this.shareDealDto.ClientDto.FirstName = users[0].FirstName;
      this.shareDealDto.ClientDto.Email = users[0].Email;
      this.shareDealDto.ClientDto.LastName = users[0].LastName;
      this.shareDealDto.ClientDto.PhoneNumber = users[0].PhoneNumber;
      this.shareDealDto.ClientDto.ClientUserId = users[0].Id;
      this.showClientDetails = true;
      this.loadingData = false;
    });
  }

  /**
   * Sends email to client attached to the search
   */
  sendShareSearchEmailToClient() {
    this.loadingData = true;
    this.shareDealDto.EmailLinkToClient = true;
    this.dealService
      .shareDealWithClient(this.shareDealDto)
      .then(() => {
        this.displayMsg =
          "Search results have been sent to " +
          this.shareDealDto.ClientDto.FirstName +
          " " +
          this.shareDealDto.ClientDto.LastName;
      })
      .catch((error) => {
        this.displayMsg = "Sorry, something went wrong. Please try again.";
      })
      .finally(() => {
        this.newSearch = false;
        this.showMsg = true;
        this.loadingData = false;
        this.sharedSearch = false;
      });
  }

  applyForLoan() {
    this.isProceedClicked = true;
    this.register(true, this.productResultList);
  }

  returnToCase() {
    if (this.isShortlistingMore) {
      // copy newly selected products/lenders to caselender
      this.dealLenderService
        .shortlistMoreLenders(
          this.loanCriteria.Id,
          this.getDealLenders(this.comparisonList),
          this.hasSearchChanged,
        )
        .then((response) => {
          if (response) {
            //sending 'Promote' as true if search has changed & 'NewShortlist' as true if isShortlistingMore
            this.$location.path(
              "/devfinancecasedashboard/" +
              this.loanCriteria.Id +
              "/" +
              null +
              "/" +
              true +
              "/" +
              this.hasSearchChanged,
            );
          }
        });
    } else {
      this.dealLenderService
        .updateShortlistedLenders(this.getDealLenders(this.comparisonList))
        .then((response) => {
          this.$location.path(
            "/devfinancecasedashboard/" + this.loanCriteria.Id,
          );
        });
    }
  }

  toggleShowLenderNamesBrokerOverride() {
    if (this.searchPanelForm.$valid && !this.showPostcodeErrorMessage) {
      this.updateCriteriaAndGetResults(false, true);
    }
  }

  mortgageTermToLoanTerm(): void {
    this.loanCriteria.LoanTermReq = this.mortgageTerm * 12;
  }

  /**Update the loan label in the comparison results based on the loan label returned in the results
   * Primary purpose is to update the loan label when the show lender names button is toggled
   * */
  updateLoanLabels() {
    for (var i = 0; i < this.comparisonList.length; i++) {
      let resultIndex = this.summarySearchResults.findIndex(
        (item) => item.ProductID == this.comparisonList[i].ProductID,
      );

      if (resultIndex > -1) {
        this.comparisonList[i].LoanLabel =
          this.summarySearchResults[resultIndex].LoanLabel;
      }
    }

    //This is used in shortlisting pdf
    this.prepareDataForShortlistPdf();
  }

  datasetupOnMaxLoanRequiredChange() {
    this.loanCriteria = this.dealService.dataSetupOnMaxLoanRequiredChange(
      this.loanCriteria,
    );
    this.updateSearchViaSlider();
  }

  showContactbrokerButton() {
    if (
      this.brokerageOrg != null &&
      this.loanCriteria != null &&
      this.loanCriteria.DealClients != null &&
      this.loanCriteria.DealClients.length > 0 &&
      this.loanCriteria.DealClients[0]?.Client != null &&
      this.loanCriteria.DealClients[0].Client.Email.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  getPreviousDevelopments() {
    var value = null;
    switch (this.tempLoanCriteria.F_NumberOfPreviousDevelopments) {
      case 1:
        value = PreviousDevelopmentsEnum.One;
        break;
      case 2:
        value = PreviousDevelopmentsEnum.Two;
        break;
      case 3:
        value = PreviousDevelopmentsEnum.Three;
        break;
      case 4:
        value = PreviousDevelopmentsEnum.FourOrMore;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  getShareholderDepositRequired() {
    var value = null;
    switch (this.tempLoanCriteria.F_ShareholderDepositRequired) {
      case 0:
        value = ShareholderDepositRequiredEnum.None;
        break;
      case 0.25:
        value = ShareholderDepositRequiredEnum.UpTo25Percent;
        break;
      case 0.5:
        value = ShareholderDepositRequiredEnum.TwentyFiveTo50Percent;
        break;
      case 1.0:
        value = ShareholderDepositRequiredEnum.FiftyPercentPlus;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onShareholderDepositRequiredChange(e: ShareholderDepositRequiredEnum) {
    switch (e) {
      case ShareholderDepositRequiredEnum.None:
        this.loanCriteria.F_ShareholderDepositRequired = 0;
        break;
      case ShareholderDepositRequiredEnum.UpTo25Percent:
        this.loanCriteria.F_ShareholderDepositRequired = 0.25;
        break;
      case ShareholderDepositRequiredEnum.TwentyFiveTo50Percent:
        this.loanCriteria.F_ShareholderDepositRequired = 0.5;
        break;
      case ShareholderDepositRequiredEnum.FiftyPercentPlus:
        this.loanCriteria.F_ShareholderDepositRequired = 1.0;
        break;
      default:
        this.loanCriteria.F_ShareholderDepositRequired = null;
        break;
    }
  }

  onMaxHouseSalesPriceChange(e: MaxHouseSalePriceEnum) {
    switch (e) {
      case MaxHouseSalePriceEnum.UpTo1Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 1000000;
        break;
      case MaxHouseSalePriceEnum.UpTo2Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 2000000;
        break;
      case MaxHouseSalePriceEnum.UpTo5Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 5000000;
        break;
      case MaxHouseSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxHouseSalesPrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxHouseSalesPrice = null;
        break;
    }
  }

  getMaxHouseSalesPrice() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxHouseSalesPrice) {
      case 1000000:
        value = MaxHouseSalePriceEnum.UpTo1Million;
        break;
      case 2000000:
        value = MaxHouseSalePriceEnum.UpTo2Million;
        break;
      case 5000000:
        value = MaxHouseSalePriceEnum.UpTo5Million;
        break;
      case -999:
        value = MaxHouseSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxFlatUnitPriceChange(e: MaxFlatUnitPriceEnum) {
    switch (e) {
      case MaxFlatUnitPriceEnum.UpTo1Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 1000000;
        break;
      case MaxFlatUnitPriceEnum.UpTo2Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 2000000;
        break;
      case MaxFlatUnitPriceEnum.UpTo3Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 3000000;
        break;
      case MaxFlatUnitPriceEnum.NoMax:
        this.loanCriteria.F_MaxFlatUnitPrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxFlatUnitPrice = null;
        break;
    }
  }

  getMaxFlatUnitPrice() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxFlatUnitPrice) {
      case 1000000:
        value = MaxFlatUnitPriceEnum.UpTo1Million;
        break;
      case 2000000:
        value = MaxFlatUnitPriceEnum.UpTo2Million;
        break;
      case 3000000:
        value = MaxFlatUnitPriceEnum.UpTo3Million;
        break;
      case -999:
        value = MaxFlatUnitPriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxSqFtSalePriceChange(e: MaxSqFtSalePriceEnum) {
    switch (e) {
      case MaxSqFtSalePriceEnum.UpTo850:
        this.loanCriteria.F_MaxSqFtSalePrice = 850;
        break;
      case MaxSqFtSalePriceEnum.UpTo1000:
        this.loanCriteria.F_MaxSqFtSalePrice = 1000;
        break;
      case MaxSqFtSalePriceEnum.UpTo1500:
        this.loanCriteria.F_MaxSqFtSalePrice = 1500;
        break;
      case MaxSqFtSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxSqFtSalePrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxSqFtSalePrice = null;
        break;
    }
  }

  onMaxSqFtFlatsSalePriceChange(e: MaxSqFtSalePriceEnum) {
    switch (e) {
      case MaxSqFtSalePriceEnum.UpTo850:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 850;
        break;
      case MaxSqFtSalePriceEnum.UpTo1000:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 1000;
        break;
      case MaxSqFtSalePriceEnum.UpTo1500:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 1500;
        break;
      case MaxSqFtSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = -999;
        break;
      default:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = null;
        break;
    }
  }

  getMaxSqFtSalePrice() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxSqFtSalePrice) {
      case 850:
        value = MaxSqFtSalePriceEnum.UpTo850;
        break;
      case 1000:
        value = MaxSqFtSalePriceEnum.UpTo1000;
        break;
      case 1500:
        value = MaxSqFtSalePriceEnum.UpTo1500;
        break;
      case -999:
        value = MaxSqFtSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  getMaxSqFtSalePriceFlats() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxSqFtSalePriceFlats) {
      case 850:
        value = MaxSqFtSalePriceEnum.UpTo850;
        break;
      case 1000:
        value = MaxSqFtSalePriceEnum.UpTo1000;
        break;
      case 1500:
        value = MaxSqFtSalePriceEnum.UpTo1500;
        break;
      case -999:
        value = MaxSqFtSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxPercCommercialForMixedUseChange(e: MaxPercCommercialForMixedUseEnum) {
    switch (e) {
      case MaxPercCommercialForMixedUseEnum.UpTo25:
        this.loanCriteria.F_MaxPercCommercialForMixedUse = 0.25;
        break;
      case MaxPercCommercialForMixedUseEnum.TwentyFiveTo50:
        this.loanCriteria.F_MaxPercCommercialForMixedUse = 0.5;
        break;
      case MaxPercCommercialForMixedUseEnum.Over50:
        this.loanCriteria.F_MaxPercCommercialForMixedUse = 1.0;
        break;
      default:
        this.loanCriteria.F_MaxPercCommercialForMixedUse = null;
        break;
    }
  }

  getMaxPercCommercialForMixedUse() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxPercCommercialForMixedUse) {
      case 0.25:
        value = MaxPercCommercialForMixedUseEnum.UpTo25;
        break;
      case 0.5:
        value = MaxPercCommercialForMixedUseEnum.TwentyFiveTo50;
        break;
      case 1.0:
        value = MaxPercCommercialForMixedUseEnum.Over50;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  CalcPurchaseAdditionalCosts(): number {
    if (this.loanCriteria) {
      var res: number = 0;

      if (this.loanCriteria.StampDutyLandTax) {
        res += Number(this.loanCriteria.StampDutyLandTax);
      }
      if (this.loanCriteria.SalesAgentFees) {
        res += Number(this.loanCriteria.SalesAgentFees);
      }
      if (this.loanCriteria.PurchaseLegalFees) {
        res += Number(this.loanCriteria.PurchaseLegalFees);
      }

      if (this.loanCriteria.OwnOrPurchase == OwnOrPurchaseEnum.Own) {
        if (this.loanCriteria.LandTotalOtherCosts) {
          res += Number(this.loanCriteria.LandTotalOtherCosts);
        }
      }

      if (this.loanCriteria.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing) {
        if (this.loanCriteria.PurchaseCosts) {
          res += Number(this.loanCriteria.PurchaseCosts);
        }
      }

      return res;
    }
  }

  isSelectedResult(offer: LenderResultSummaryDTO, index: number) {
    return this.comparisonContains(offer);
  }

  isResultAvailableToSelect(offer: LenderResultSummaryDTO) {
    return (
      offer.IsCaseLender &&
      offer.CaseLenderStatus != CaseLenderStateEnum.Shortlisted
    );
  }

  contingencyChanged() {
    this.calculateContingency();
  }

  updateLoanRequiredValue() {
    if (this.loanCriteria.MaxLoanRequired) {
      this.loanCriteria.NetLoanRequired = 0;
      this.updateSearchViaSlider();
    } else {
      this.updateSearchViaSlider();
    }
  }

  calculateContingency() {
    if (this.loanCriteria.IncludesContingency) {
      this.contingencyValue =
        this.loanCriteria.BuildCosts -
        this.loanCriteria.BuildCosts /
        (1 + Number(this.loanCriteria.Contingency));
    }
    this.calculateTotalBuildCost();
  }

  calculateTotalBuildCost() {
    if (this.loanCriteria.IncludesContingency) {
      this.loanCriteria.BuildCosts =
        Number(this.loanCriteria.BuildCosts) - this.contingencyValue;
    }

    this.updateSearchViaSlider();
  }

  getRegionByPostcode() {
    if (
      this.loanCriteria.PostcodeSearchString &&
      this.loanCriteria.PostcodeSearchString.replace(" ", "").length >= 2
    ) {
      if (
        this.dealService.isValidPostcodeString(
          this.loanCriteria.PostcodeSearchString,
        )
      ) {
        this.showPostcodeErrorMessage = false;
        this.isPostcodeChange = true;
        this.updateSearchViaSlider();
      } else {
        this.postcodeErrorMsg =
          "At least one letter and one number must be entered e.g. SW1.";
        this.showPostcodeErrorMessage = true;
      }
    }
  }

  calculateMonthsSinceOrigPurchase() {
    let date1 = new Date(this.loanCriteria.OriginalPurchaseDate);
    let date2 = new Date();
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months;
  }

  sortByField(field) {
    this.loanCriteria.SortBy = field;
    this.updateCriteriaAndGetResults(false, false);
    this.showSortBy = !this.showSortBy;
  }

  sortSummaryResults() {
    switch (this.loanCriteria.SortBy) {
      case SortByEnum.ROCE:
        this.summarySearchResults.sort(
          (a, b) => b.ROCE - a.ROCE || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.TrueMonthlyCost:
        this.summarySearchResults.sort(
          (a, b) => a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.NetLoanSize:
        this.summarySearchResults.sort(
          (a, b) =>
            b.NetLoan - a.NetLoan || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.LenderName:
        this.summarySearchResults.sort(
          (a, b) =>
            a.LoanLabel.localeCompare(b.LoanLabel) ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.InterestRate:
        this.summarySearchResults.sort(
          (a, b) =>
            a.Interest - b.Interest || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.ArrangementFee:
        this.summarySearchResults.sort(
          (a, b) =>
            a.ArrangementFee - b.ArrangementFee ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.DepositEquity:
        this.summarySearchResults.sort(
          (a, b) =>
            a.Deposit - b.Deposit || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.LTGDV:
        this.lenderResultService
          .sortProductsByLTGDV(this.summarySearchResults)
          .then((sortedResults) => {
            this.summarySearchResults = sortedResults;
          })
          .catch((error) => {
            console.error("Error sorting by LTGDV:", error);
          });
        break;
      default:
        this.summarySearchResults.sort((a, b) => {
          if (a.NetLoan < b.NetLoan) return 1;
          if (a.NetLoan > b.NetLoan) return -1;

          if (a.TrueMonthlyCost > b.TrueMonthlyCost) return 1;
          if (a.TrueMonthlyCost < b.TrueMonthlyCost) return -1;

          return 0;
        });
        this.loanCriteria.SortBy = SortByEnum.NetLoanSize;
    }

    for (var i = 0; i < this.summarySearchResults.length; i++) {
      this.summarySearchResults[i].SelectedRowNumber = i + 1;
    }
  }

  isFieldZero(fieldName) {
    if (this.loanCriteria) {
      const fieldValue = this.loanCriteria[fieldName];
      if (fieldValue == null || fieldValue === "0" || fieldValue === 0) {
        return true;
      }
    }

    return false;
  }

  submitLenderReferral() {
    this.loadingData = true;
    this.lenderReferralData.DealId = this.loanCriteria.Id;
    this.dealClientService
      .submitLenderReferral(this.lenderReferralData)
      .then((response) => {
        if (response) {
          this.displayMsg = "Your referral has been submitted.";
          this.showMsg = true;
          this.isLenderReferredSearch = true;
        } else {
          this.displayMsg = "Error while making a referral, please try later.";
          this.showMsg = true;
        }
      })
      .finally(() => {
        this.loadingData = false;
        this.lenderReferralData = null;
        sessionStorage.removeItem("lenderReferralData");
      });
  }

  onClickSaveAs() {
    this.isSaveAsClicked = true;

    if (this.isAdmin || this.isBroker) {
      if (!this.shareDealDto) {
        this.shareDealDto = {
          IsApplicant: true,
          IsPrimary: true,
        } as ShareDealDTO;
      }
      this.shareDealDto.DealName = "";
      this.showShareSearchModal();
    } else {
      this.showSaveResults = 3;
    }
  }

  saveSaveAsSearch(attachClient = false) {
    this.loadingData = true;
    let newSearchName = this.isBroker || this.isAdmin ? this.shareDealDto.DealName : this.saveAsSearchName;

    if (newSearchName.length == 0) {
      newSearchName = this.loanCriteria.Name + ' (Copy)';
    }

    var request: SaveAsSearchRequest = {
      ShareDealDto: attachClient ? this.shareDealDto : null,
      ProductFamily: this.loanCriteria.ProductFamily,
      SearchId: this.loanCriteria.Id,
      NewSearchName: newSearchName,
    };

    this.dealService
      .saveSaveAsSearch(request)
      .then((result) => {
        this.displayMsg = `${newSearchName} search has been saved to your dashboard.`;
      })
      .catch((error) => {
        this.displayMsg = "Sorry, something went wrong. Please try again.";
      })
      .finally(() => {
        this.cancelNewSearch();
        this.loadingData = false;
        this.isSaveAsClicked = false;
        this.shareDealDto = {
          IsApplicant: true,
          IsPrimary: true,
        } as ShareDealDTO;
        this.saveAsSearchName = "";
        this.showSaveResults = null;
        this.showMsg = true;
      });
  }

  reloadPage() {
    if (this.reloadSearch) {
      window.location.reload();
    }
  }

  /** Redirects to the user dashboard */
  goToUserDashboard() {
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  // Enterprise widget
  openRegisterModal() {
    this.showRegisterModal = true;
  }

  onEditSearchNameClick() {
    this.toggleEditSearchName = !this.toggleEditSearchName;
  }

  /*onClickEligibleConfirm() {
    this.loadingData = true;
    this.loanCriteria.DealClients[0].Client.PhoneNumber =
      this.enterpriseClient.PhoneNumber;
    this.clientService
      .addUpdatereturnonlyid(this.loanCriteria.DealClients[0].Client)
      .then((response) => {
        this.showEligibleModal = false;
        window.scrollTo(0, 0);
      })
      .finally(() => {
        this.loadingData = false;
      });
  }*/

  onSortbyClick() {
    this.closeFilterOptions();
    this.showSortBy = !this.showSortBy;
  }

  onFilterClick() {
    this.closeSortByDropdown();
    this.showFilters = !this.showFilters;
  }

  getSelectedSortByOptionText() {
    if (this.loanCriteria) {
      switch (this.loanCriteria.SortBy) {
        case SortByEnum.TrueMonthlyCost:
          return "True Monthly Cost";
        case SortByEnum.NetLoanSize:
          return "Net Loan Size";
        case SortByEnum.LenderCosts:
          return "Lender Costs (Est.)";
        case SortByEnum.LenderName:
          return "Lender Name (A-Z)";
        case SortByEnum.InterestRate:
          return "Interest Rate";
        case SortByEnum.ArrangementFee:
          return "Arrangement Fee";
        case SortByEnum.DepositEquity:
          return "Est. Deposit/Equity";
        case SortByEnum.ROCE:
          return "ROCE";
        case SortByEnum.LTGDV:
          return "LTGDV";
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  createAccount() {
    this.loadingData = true;
    this.enterpriseClient.OrgUniqueRef = this.brokerageOrg.UniqueRef;
    this.$user
      .addUpdateWithOrgUniqueRef(this.enterpriseClient)
      .then((user) => {
        // clean up session storage
        if (window.self == window.top) {
          sessionStorage.removeItem("clientId");
          sessionStorage.removeItem("userRole");
        } else {
          this.organisationService.sendDataToParent("clientId", "");
          this.organisationService.sendDataToParent("userRole", "");
        }
        this.saveShortlistedLenders();
        this.login("ENTERPRISE-LEAD-REGISTERED-FROM-RESULTS-PAGE", EventLogEnum.EnterpriseUserRegistration);
      })
      .catch((response) => {
        this.error = response.data.Message;
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  login(eventIdentifier: string = "ENTERPRISE-CONTACT-LOGGEDIN-FROM-RESULTS-PAGE", eventType: EventLogEnum = EventLogEnum.EnterpriseUserLogin) {
    this.loadingData = true;
    if (!this.$cookies.get("access_token")) {
      this.$auth
        .login(
          this.enterpriseClient.UserName,
          this.enterpriseClient.Password,
          "CC022EBE67BE0424EA2A6548B062D2D71",
        )
        .then((response) => {
          let expiry: Date = response;

          this.$user.getcurentuserrecord().then((response) => {
            this.eventLogService.logEvent(
              eventIdentifier,
              eventType,
              this.loanCriteria.ProductType,
              this.loanCriteria.Id,
              "",
              "",
              this.loanCriteria.DealClients[0]?.Client.Id?.toString()
            );

            (this.$rootScope as any).currentUser = response;
            this.$cookies.put("user_firstname", response.FirstName, {
              expires: expiry,
            });
            (this.$rootScope as any).selectedUser = response;
            this.$rootScope.$broadcast("login"); //tell everyone you have logged in
            this.$location.path("/userdashboard");
          });
        })
        .finally(() => {
          this.loadingData = false;
        });
    }
  }

  onClickLogin() {
    this.saveShortlistedLenders();
    this.login();
  }

  saveShortlistedLenders() {
    var shortlistDealLenders = this.getDealLenders(this.comparisonList);
    this.dealLenderService
      .updateShortlistedLenders(shortlistDealLenders)
      .then((response) => { });
  }

  populateEnterpriseClientValue(eventType) {
    if (this.loanCriteria.DealClients[0] != null) {
      this.enterpriseClient.FirstName =
        this.loanCriteria.DealClients[0].Client.FirstName;
      this.enterpriseClient.LastName =
        this.loanCriteria.DealClients[0].Client.LastName;
      this.enterpriseClient.Email =
        this.loanCriteria.DealClients[0].Client.Email;
      this.enterpriseClient.UserName =
        this.loanCriteria.DealClients[0].Client.Email;
      this.enterpriseClient.DealClientUniqueRef = this.loanCriteria.DealClients[0].UniqueRef;
      this.enterpriseClient.OrgCode = this.orgCode;
      this.enterpriseClient.PhoneNumber =
        this.loanCriteria.DealClients[0].Client.PhoneNumber;
      if (this.loanCriteria.DealClients[0].Client.ClientUserId) {
        this.showRegisterModal = true;
        this.showLoginSection = true;
      }

      if (this.loanCriteria.OrganisationLinkId) {
        this.organisationLinkService
          .getOrganisationLinkLogoByLinkIdAndLogPageLoadEvent(Number(this.loanCriteria.OrganisationLinkId), eventType, this.loanCriteria.Id, this.loanCriteria.ProductType, this.loanCriteria.DealClients[0]?.Client?.Email, this.loanCriteria.DealClients[0]?.Client?.Id.toString())
          .then((logoUrl) => {
            if (logoUrl) {
              var imgs = document.getElementsByTagName("img");
              imgs[0].src = logoUrl
            }
          });
      } else {
        this.eventLogService.logPageLoad(
          eventType,
          this.orgCode,
          this.loanCriteria.DealClients.length > 0
            ? this.loanCriteria.DealClients[0].Client.Email
            : "",
          "Client",
          this.loanCriteria.OrganisationLinkId != null
            ? this.loanCriteria.OrganisationLinkId
            : 0,
          this.loanCriteria?.ProductType,
          "",
          this.loanCriteria?.Id,
          this.loanCriteria.DealClients.length > 0
            ? this.loanCriteria.DealClients[0].Client.Id.toString()
            : null,
        );
      }

      //Todo Roopa: do we need a broker details?
      this.getOrganisationAndBrokerDetails();
    }
  }

  returnShortlistedDealLenderStatusText(item: LenderResultSummaryDTO) {
    if (this.checkDeletedLender(item.LenderID.toString(), item.ProductID.toString())) {
      return "Previously Deleted";
    }

    if (item.IsDealLender && (this.loanCriteria.Status != CaseStatusEnum.Search && this.loanCriteria.Status != CaseStatusEnum.NewCase && this.loanCriteria.Status != CaseStatusEnum.ReadyToSubmit)) {
      switch (item.DealLenderStatus) {
        case CaseLenderStateEnum.Shortlisted:
          return "Shortlisted";
        case CaseLenderStateEnum.Received:
          return "Awaiting DIP";
        case CaseLenderStateEnum.Offered:
          return "DIP Received";
        case CaseLenderStateEnum.Rejected:
          return "Lender Rejected";
        case CaseLenderStateEnum.Withdrawn:
          return "Lender Withdrawn";
        default:
          break
      }
    }
  }

  isProceedButtonDisabled(): boolean {

    if (this.isShortlistingMore) {
      if (this.validateWithdrawnLendersUnique() == false)
        return true;
    }

    if (
      !this.comparisonList ||
      this.comparisonList.length < 1 ||
      this.comparisonList.length > this.noOfShortlistAllowed ||
      this.isProceedClicked == true
    ) {
      return true;
    }

    return false;
  }

  onSaveClick() {
    if (this.isAssigned) {
      this.isSaveorSaveAsClicked = true;
      this.register(false, this.productResultList);
    } else {
      this.showShareSearchModal();
    }
  }

  getProductLastVerifiedDates(productIds: number[]) {
    this.productService.getLastVerifiedDates(productIds).then((response) => {
      productIds.forEach(id => {
        if (response[id] != null) {
          this.productLastVerifiedDates[id] = this.formatDate(response[id]);
        }
      })
    });
  }

  formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  validateWithdrawnLendersUnique(): boolean {
    if (this.isShortlistingMore) {
      const matchingHiddenDeallender = this.HiddenDealLenderList.filter(dl =>
        this.comparisonList.some(l => dl.LenderId === l.LenderID && dl.ProductId != l.ProductID && l.DealLenderStatus != CaseLenderStateEnum.Withdrawn) && dl.Status == CaseLenderStateEnum.Withdrawn
      );

      const matchingDeallender = this.dealLenders?.filter(dl =>
        this.comparisonList.some(l => dl.LenderId === l.LenderID && dl.ProductId != l.ProductID && l.DealLenderStatus != CaseLenderStateEnum.Withdrawn) && dl.Status == CaseLenderStateEnum.Withdrawn
      );

      if (matchingHiddenDeallender.length > 0 || (matchingDeallender && matchingDeallender.length > 0)) return false;
    }

    return true;
  }

  closeFilterOptions() {
    this.showFilters = false;
  }

  closeSortByDropdown() {
    this.showSortBy = false;
  }

  closeExportDropdown() {
    this.showExportOptions = false;
  }
}

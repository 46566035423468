import { CaseMemberDTO } from "@js/DTO/CaseMemberDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class ApplicantService extends BaseService<CaseMemberDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/applicant";
    this.$broadcastBusinessNameSingular = "applicant";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  addOrUpdateCaseMember(
    dto: CaseMemberDTO,
    sendInviteEmail: boolean = false,
  ): ng.IPromise<CaseMemberDTO> {
    let defer = this.$q.defer<CaseMemberDTO>();

    this.$http
      .post(
        this.$baseAddress +
          "/addorupdatecasemember?sendInviteEmail=" +
          sendInviteEmail,
        dto,
      )
      .then((response) => {
        defer.resolve(response.data as CaseMemberDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "AddOrUpdateCaseMember",
          "AddOrUpdateCaseMember failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}

import { DeveloperExitFinanceInputSetDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceInputSetDTO.cs.d";
import { DeveloperExitFinanceResultDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceResultDTO.cs.d";
import { DeveloperExitFinanceResultSetDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceResultSetDTO.cs.d";
import { LenderResultDTO } from "@js/DTO/DevelopmentFinance/LenderResultDTO.cs.d";
import { DeveloperExitFinanceResultService } from "@js/services/DevExitFinance/DeveloperExitFinanceResultService";

export class DeveloperExitFinanceResultController {
  selectedSection: string;

  //objects: DeveloperExitFinanceResultDTO[];
  selectedObject: DeveloperExitFinanceResultDTO;

  exitLoanCriteria: DeveloperExitFinanceInputSetDTO;

  results: DeveloperExitFinanceResultSetDTO;

  matchingResults: DeveloperExitFinanceResultDTO[];
  largestResults: DeveloperExitFinanceResultDTO[];

  comparisonList: DeveloperExitFinanceResultDTO[] = [];

  selectedResult: DeveloperExitFinanceResultDTO;

  warningOff: boolean = false;

  enquiryOpen: boolean;
  enquiryEmail: string;
  enquiryBody: string;
  enquiryTelephone: string;
  sentmessage: boolean = false;
  sendingmessage: boolean = false;

  fetchingObjects: boolean;
  fetchingObject: boolean;

  developerexitfinanceresultForm: ng.IFormController;

  static $inject = [
    "$rootScope",
    "$location",
    "$routeParams",
    "DeveloperExitFinanceResultService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    $routeParams: ng.route.IRouteParamsService,
    private $developerexitfinanceresultservice: DeveloperExitFinanceResultService,
  ) {
    this.updateObjects();
  }

  updateObjects() {
    this.fetchingObjects = true;
    this.exitLoanCriteria = (this.$rootScope as any).exitLoanCriteria;
    this.updateResults();
  }

  updateResults() {
    this.$developerexitfinanceresultservice
      .fetchMatchingResults(
        this.exitLoanCriteria,
        true,
        true,
        20,
        false,
        "",
        "",
        "",
        "",
      )
      .then((results) => {
        this.results = results;
        this.matchingResults = results.MatchingResults;
        this.largestResults = results.LargestLoanResults;
      })
      .catch((error) => {
        this.matchingResults = [];
      })
      .finally(() => {});
  }

  sendEnquiry(): void {
    this.sendingmessage = true;
    this.sentmessage = false;

    this.$developerexitfinanceresultservice
      .fetchMatchingResults(
        this.exitLoanCriteria,
        true,
        true,
        20,
        true,
        this.enquiryBody,
        this.enquiryEmail,
        this.enquiryTelephone,
        this.GetSelectedLabel(),
      )
      .then((response2) => {
        this.sendingmessage = false;
        this.sentmessage = true;
      });
  }

  private GetSelectedLabel() {
    var selectedlabel = "";
    if (this.selectedResult && this.selectedResult.LoanLabel) {
      selectedlabel = this.selectedResult.LoanLabel;
    }
    return selectedlabel;
  }

  comparisonContains(item: DeveloperExitFinanceResultDTO) {
    let found = !!this.comparisonList.find((result, index) => {
      return result === item;
    });

    if (found) {
    }

    return found;
  }

  toggleLenderComparisonSelection(item: DeveloperExitFinanceResultDTO) {
    let match: DeveloperExitFinanceResultDTO = this.comparisonList.find(
      (result, index) => {
        return result === item;
      },
    );

    if (!match && this.comparisonList.length < 3) {
      this.comparisonList.push(item);
    } else {
      this.comparisonList.splice(this.comparisonList.indexOf(match), 1);
    }
  }

  viewOffer(offer: LenderResultDTO) {
    this.selectedResult = offer;
  }

  goCompare() {
    this.warningOff = true;
    (this.$rootScope as any).comparisonList = this.comparisonList;

    // temporarily put all results into saved results so the comparison controller can use them.

    let allResults: DeveloperExitFinanceResultDTO[] = [];
    this.matchingResults.forEach((result) => {
      allResults.push(result);
    });
    this.largestResults.forEach((result) => {
      allResults.push(result);
    });

    (this.$rootScope as any).tempSavedResults = allResults;

    this.$location.path("exitloancompare");
  }

  viewSingleLoan(item: LenderResultDTO) {
    this.warningOff = true;
    this.comparisonList = [];
    this.comparisonList.push(item);
    this.goCompare();
  }
  go(path): void {
    this.$location.path(path);
  }

  applyForLoan(offer: DeveloperExitFinanceResultDTO, index) {
    this.selectedResult = offer;
    this.enquiryBody = "Please call me about loan: " + offer.LoanLabel;
    this.enquiryOpen = true;
  }
}

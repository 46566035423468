import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseLenderDTO } from "@js/DTO/CaseLenderDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { BridgingDealDTO } from "@js/DTO/Deal/BridgingDealDTO.cs.d";
import { DealLenderDTO } from "@js/DTO/Deal/DealLenderDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import { LenderResultDTO } from "@js/DTO/DevelopmentFinance/LenderResultDTO.cs.d";
import { LenderProductPairDTO } from "@js/DTO/LenderProductPairDTO.cs";
import { LenderResultMinimalDTO } from "@js/DTO/DevelopmentFinance/LenderResultMinimalDTO.cs.d";
import { BridgingSearchResultsResponse } from "@js/DTO/Messages/BridgingSearchResultMessage.cs.d";
import { SaveAsSearchRequest } from "@js/DTO/Messages/Deal/SaveAsSearchMessage.cs.d";
import { SaveBridgingSearchRequest } from "@js/DTO/Messages/Deal/SaveBridgingSearchMessage.cs.d";
import { UpdateBridgingSearchGetResultResponse } from "@js/DTO/Messages/Deal/UpdateBridgingSearchGetResultMessage.cs.d";
import { MakeReferralMessageRequest } from "@js/DTO/Messages/MakeReferralMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { BridgingLenderResultSummaryDTO } from "@js/DTO/SearchResults/BridgingLenderResultSummaryDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { BridgingPersonalGuaranteeLevelEnum } from "@js/models/enum/BridgingPersonalGuaranteeLevelEnum.cs.d";
import { CaseAccessLevelEnum } from "@js/models/enum/CaseAccessLevelEnum.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { EventLogEnum } from "@js/models/enum/EventLogEnum.cs.d";
import { MaxFlatUnitPriceEnum } from "@js/models/enum/MaxFlatUnitPriceEnum.cs.d";
import { MaxHouseSalePriceEnum } from "@js/models/enum/MaxHouseSalePriceEnum.cs.d";
import { MaxPercCommercialForMixedUseEnum } from "@js/models/enum/MaxPercCommercialForMixedUseEnum.cs.d";
import { MaxSqFtSalePriceEnum } from "@js/models/enum/MaxSqFtSalePriceEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { PreviousDevelopmentsEnum } from "@js/models/enum/PreviousDevelopmentsEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { SortByEnum } from "@js/models/enum/SortByEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { BridgingSearchService } from "@js/services/BridgingSearchService";
import { CaseService } from "@js/services/CaseService";
import { BridgingDealService } from "@js/services/Deal/BridgingDealService";
import { DealLenderService } from "@js/services/Deal/DealLenderService";
import { DealService } from "@js/services/Deal/DealService";
import { SharedSearchResultsService } from "@js/services/Deal/SharedSearchResultsService";
import { DealClientService } from "@js/services/DealClientService";
import { EventLogService } from "@js/services/EventLogService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationLinkService } from "@js/services/OrganisationLinkService";
import { OrganisationService } from "@js/services/OrganisationService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";
import { SharedDataService } from "@js/services/SharedDataService";

declare const window: Window & { dataLayer: any[] };

export class BridgingLenderResultScreenController {
  loadingData: boolean = false;
  searchPanelForm: ng.IFormController;

  // tourState: any = {
  //   tourStep: 1,
  //   tourTotalSteps: 0,
  // };
  guidanceCheckbox: boolean = true;
  // tourEnabled: boolean = false;

  totalLender: number = 0;
  isSearch: boolean = true;

  debug1: boolean = false;

  loanCriteria: BridgingDealDTO;
  summarySearchResults: BridgingLenderResultSummaryDTO[];
  initialSummarySearchResults: BridgingLenderResultSummaryDTO[];
  productLastVerifiedDates = {};
  deletedDealLenders: LenderProductPairDTO[];
  offer: BridgingLenderResultSummaryDTO;
  comparisonList: BridgingLenderResultSummaryDTO[] = [];

  selectedResult: LenderResultDTO;

  selectedUser: ApplicationUserDTO = {} as ApplicationUserDTO;
  searchid: number = null;
  dealClientUniqueRef: string = null;
  dealUniqueRef: string = null;
  hideCaseCreation: boolean = false;

  loanLabel: string;
  showSaveResults: number;
  warningOff: boolean = false;
  currentCase: CaseDTO;
  productResultList: number;
  productId: number;
  caseLenders: CaseLenderDTO[];
  dealLenders: DealLenderDTO[];
  caseLendersNotInResults: DealLenderDTO[];
  filterLenderNames: string[] = [];

  sliderShown: boolean;
  searchNameCache: string;

  notifyBorrower: boolean = true;

  showProductNote: boolean = false;
  productNote: string;

  savePressed: boolean = false;

  //Capture name and email address & send results to unregistered searcher
  newUser: ApplicationUserDTO = {} as ApplicationUserDTO;
  error: string = "";

  // Flag to disable or enable the Save as button depending on the click.
  isClicked: boolean = false;
  showContactBrokerModal: boolean = false;

  //For admins accessing client accounts
  //clientUsernameBeingAccessed: string;
  toggleEditSearchName: boolean = false;
  // isBlankSearch: boolean = false;
  isAdmin: boolean = false;
  showActionPanel: boolean = false;

  isLoggedInUser: boolean = false;
  isLegacyUser: boolean = false;
  tempSearchName: string = null;
  isLoggingOut: boolean = false;

  //These are added for new search functinality
  existingUsers: UserSimpleDTO[];
  newSearch: boolean = false;
  existingborrower: boolean;
  showClientDetails: boolean = false;

  clientId: string = null;
  isIntroducer: boolean = false;
  isIntroduceronly: boolean = false;
  isLender: boolean = false;
  isBroker: boolean = false;
  appName: string;
  isSaveorSaveAsClicked: boolean = false;
  noOfShortlistAllowed: number = 5;
  displayMsg: string = null;
  showMsg: boolean = false;
  showMsgToAdminOrBroker: boolean = false;
  ShowdeleteSearch: boolean = false;
  isProceedClicked: boolean = false;

  isResultScreen: boolean = true;
  selecteduserName: string;
  isClient: boolean = false;
  tempLoanCriteria = null;
  showFilters: boolean = false;
  isFilterUpdate: boolean = false;
  isShortlistingMore: boolean = false;
  showSortBy: boolean = false;

  showContactLender: boolean = false;
  shareForm: ng.IFormController;

  lenderEmail: string;
  shareEmailSubject: string;
  sharedSearch: boolean = false;
  dataLoading: boolean = false;

  orgCode: string;
  orgName: string;
  orgNameUnderscore: string;
  brokerDetail: string = "";
  brokerageOrg: OrganisationDTO;
  brokerageOrgFilename: string = "Brickflow";
  showMessageToborrower: boolean = false;
  borrowerMessage: string = "";
  user: ApplicationUserDTO;
  moveContactBrokerBtnInMiddle: boolean = false;
  clientOrganisationPhoneNumber: string;
  isBridging: boolean = true;
  isMaximumLoanRequired: boolean = false;
  userLenderId: number = null;

  carouselData: string[] = [];
  numberOfPreviousDevelopmentsOptions = [];

  personalGuaranteeLevelOptions = [];
  maxHouseSalePriceOptions = [];
  maxFlatUnitPriceOptions = [];
  maxSqFtSalePriceOptions = [];
  maxPercCommercialForMixedUseOptions = [];
  locationOptions = [];
  valuationMethodOptions = [];
  maxCommercialFloorspaceOptions = [];

  personalGuaranteeLevel = null;
  maxHouseSalesPrice = null;
  maxFlatUnitPrice = null;
  maxSqFtSalePrice = null;
  maxPercCommercialForMixedUse = null;
  maxSqFtSalePriceFlats = null;
  previousDevelopments = null;

  shareDealDto: ShareDealDTO = {
    IsApplicant: true,
    IsPrimary: true,
  } as ShareDealDTO;
  hasSearchChanged: boolean = false;
  inActiveDealLender: number = 0;
  hasAccessToDeal: boolean = true;
  tempLoanRequired: number = null;

  showEligibility: boolean = false;
  showLenderSnapshot: boolean = false;
  showLenderNamesAndLogosOverride: boolean = false;
  isAssigned = false;
  userRole: string = null;

  dealProductTypeDisplayText: string;

  showEnterpriseRegistrationModal: boolean = false;
  projectName: string = "";
  registrationForm: ng.IFormController;
  emailExistsError: boolean = false;

  snapshotNewSearch: boolean = false;
  showPostcodeErrorMessage: boolean = false;
  postcodeErrorMsg: string;
  isPostcodeChange: boolean = false;

  noAccessToDealMsg: string;

  //This boolean is used in the shareedmodal.html for all the deals
  isDeal: boolean = true;

  //LenderReferralSearch
  lenderReferralData: MakeReferralMessageRequest = {} as MakeReferralMessageRequest;
  isLenderReferredSearch: boolean = false;
  isLenderReferringSearch: boolean = false;

  hasResultsProcessed: boolean = false;

  //SaveAs functionality
  isSaveAsClicked: boolean = false;
  saveAsSearchName: string;
  reloadSearch: boolean = false;

  //Enterprise journey
  showRegisterModal: boolean = false;
  showLoginSection: boolean = false;
  enterpriseClient: ApplicationUserDTO = {
    Id: "",
    AgreedToTermsAndPP: true,
    Roles: ["Client"],
  } as ApplicationUserDTO;

  // Previously shortlisted/submitted lenders which are not visible on current results because of criteria change
  HiddenDealLenderList: DealLenderDTO[] = [];
  showExportOptions: boolean = false;

  hasLiveSearch: boolean = true;


  //Confirm phone number for new client, when they register through enterprise journey from results page
  showClientPhoneNumberConfirmationModal: boolean = false;
  clientUpdatedContact: string;
  confirmClientPhoneNumberForm: ng.IFormController;
  clientUpdatedContactErrorMessage: string;

  // Logging in/resetting password
  isLoginError: boolean = false;
  isResetPasswordSubmitted: boolean = false;
  registrationLoginError: string = null;
  
  filterProperties = [
    "F_IsFirstTimeDeveloper",
    "F_NumberOfPreviousDevelopments",
    "F_IsPersonalName",
    "F_IsOffshoreCompany",
    "F_IsMainShareholderOverseas",
    "F_PersonalGuaranteeMax",
    "F_HasAdverseCredit",
    "F_IsBelowMarketValue",
    "F_IsAutomatedValuationModel",
    "F_IsAuctionPurchase",
    "F_ValuationMethod",
    "F_MaxCommercialFloorspace",
    "F_IsShariaLoan",
    "F_IsGradeOneListed",
    "F_IsGradeTwoListed",
    "F_MaxHouseSalesPrice",
    "F_MaxFlatUnitPrice",
    "F_MaxNumberOfUnits",
    "F_MaxSqFtSalePrice",
    "F_MaxSqFtSalePriceFlats",
    "F_IsChargeOnAdditionalProperty",
    "F_IsFixedRate",
    "F_IsReBridge",
    "F_IsServicedInterest",
    "F_IsTitleInsurance",
    "F_IsIndemnityInsurance",
    "F_IsRetypedValuation",
    "F_IsTimberFrameConstruction",
    "F_IsConcreteConstruction",
  ];

  static $inject = [
    "$route",
    "$routeParams",
    "$scope",
    "$rootScope",
    "$location",
    "$cookies",
    "UserService",
    "CaseService",
    "AuthService",
    "RoleService",
    "LenderService",
    "OrganisationService",
    "SelectListService",
    "UserService",
    "BridgingSearchService",
    "BridgingDealService",
    "DealService",
    "DealLenderService",
    "ProductService",
    "EventLogService",
    "SharedSearchResultsService",
    "DealClientService",
    'OrganisationLinkService',
    'SharedDataService'
  ];

  constructor(
    private $route: ng.route.IRouteService,
    private $routeParams: ng.route.IRouteParamsService,
    private $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $cookies: ng.cookies.ICookiesService,
    private $user: UserService,
    private $CaseService: CaseService,
    private $auth: AuthService,
    private roleService: RoleService,
    private lenderService: LenderService,
    private organisationService: OrganisationService,
    private selectListService: SelectListService,
    private userService: UserService,
    private bridgingSearchService: BridgingSearchService,
    private bridgingDealService: BridgingDealService,
    private dealService: DealService,
    private dealLenderService: DealLenderService,
    private productService: ProductService,
    private eventLogService: EventLogService,
    private sharedSearchResultsService: SharedSearchResultsService,
    private dealClientService: DealClientService,
    private organisationLinkService: OrganisationLinkService,
    private sharedDataService: SharedDataService
  ) {
    this.$auth.getShowBridgingEligibility().then((response) => {
      this.showEligibility = response;
    });

    this.$auth.getShowBridgingLenderSnapshot().then((response) => {
      this.showLenderSnapshot = response;
    });

    if (this.$location.path().startsWith("/bridgingshortlistmore")) {
      this.isShortlistingMore = true;
    }


    if (this.$routeParams.SearchId) {
      if (/^\d+$/.test(this.$routeParams.SearchId)) {
        // Treat the reference as an ID (e.g., fetch data using the ID)
        this.searchid = Number(this.$routeParams.SearchId);
      } else {
        // Treat the reference as a unique string (e.g., fetch data using the string)
        this.dealUniqueRef = this.$routeParams.SearchId;
      }
    } /*else {
                this.$CaseService.updateTubeMap(null);
      }*/

    if (this.$routeParams.dealclientuniqueref) {
      this.dealClientUniqueRef = this.$routeParams.dealclientuniqueref;
    }

    if (window.self == window.top && $cookies.get("access_token")) {
      //This block is to navigate a broker to userdashboard when they do not have a license.
      if (sessionStorage.getItem("isValidLicense") == "false") {
        this.$auth.clearSelectedNavMenuAndFilterStorageData();
        this.$location.path("/userdashboard");
      }

      this.isLoggedInUser = true;
      this.loadingData = true;
      this.organisationService
        .getOrganisation()
        .then((organisation) => {
          if (organisation) {
            if (!organisation.IsBrickflow) {
              //Getting organisation phone number to display on the screen with other text, when there are no results.
              this.clientOrganisationPhoneNumber = organisation.PhoneNumber;
            }
            this.orgCode = organisation.IsWhiteLabelled
              ? organisation.OrganisationCode
              : "";
            this.showLenderNamesAndLogosOverride =
              organisation.ShowLenderNames ?? false;

            if (organisation.IsWhiteLabelled) {
              this.orgName = organisation.Name.replace(/ /g, "_");
            } else {
              this.orgName = "Brickflow";
            }
          }
        })
        .catch((error) => {
          this.loadingData = false;
        })
        .finally(() => {
          //this.loadingData = false;
        });
    } else {
      if (window.self == window.top) {
        //this.userRole = sessionStorage.getItem('userRole');
        this.isClient = this.userRole == "borrower";
        this.orgCode = this.$cookies.get("org_code");
        //this.clientId = sessionStorage.getItem('clientId');
        this.getSearchCriteriaAndResults();
      } else {
        this.orgCode = location.pathname.replace("/", "");
        this.guidanceCheckbox = false;
        Promise.all([
          this.organisationService.getData("userRole").then((userRole) => {
            if (userRole) {
              this.userRole = userRole;
              this.isClient = this.userRole == "borrower";
            }
          }) /*,
                        this.organisationService.getData('clientId').then(clientId => {
                            if (clientId) {
                                this.clientId = clientId;
                            }
                        })*/,
          //Try get session storage if running in iframe before getting results
          this.organisationService.getData("newSearch").then((newSearch) => {
            if (newSearch) {
              this.snapshotNewSearch = newSearch == "true";
            }
          }),
        ])
          .then(() => {
            this.orgCode = location.pathname.replace("/", "");
            this.organisationService.sendDataToParent("newSearch", "false");
            this.getSearchCriteriaAndResults();
          })
          .catch((error) => {
            // One or both of the promises were rejected, handle the error
            console.error("Failed to get user data: ", error);
          });
      }
    }

    if (window.self == window.top) {
      // let cookieTourStep = this.$cookies.get("tourStep");
      // if (cookieTourStep) {
      //   this.tourState.tourStep = cookieTourStep;
      // }

      //if user is logged in, get profile
      if (this.$cookies.get("access_token")) {
        this.$user.getcurentuserrecord().then((result) => {
          this.selectedUser = result;
          this.isLegacyUser = this.selectedUser.IsLegacyUser;

          this.roleService.isClientUser().then((response) => {
            this.isClient = response;

            if (this.clientOrganisationPhoneNumber && !this.isClient) {
              this.clientOrganisationPhoneNumber = null;
            }
          });

          this.roleService.isAdminUser().then((response) => {
            this.isAdmin = response;
            if (this.isAdmin) {
              this.getUsersBelongToBrokerOrAdmin();
            }
          });

          this.roleService.isBroker().then((response) => {
            this.isBroker = response;
            if (this.isBroker) {
              this.getUsersBelongToBrokerOrAdmin();
            }
          });

          this.roleService.isIntroducer().then((response) => {
            this.isIntroducer = response;
          });

          this.roleService.isIntroducerOnly().then((response) => {
            this.isIntroduceronly = response;
          });

          this.roleService.isLenderUser().then((response) => {
            this.isLender = response;
            if (this.isLender) {
              this.hasLiveSearch = false;
              this.lenderService.getUserLenderDetails().then((lender) => {
                if (lender.HasLiveMarketSearch) {
                  this.hasLiveSearch = true;
                }
              });

              //Lender referred search
              if (sessionStorage.getItem("lenderReferralClient")) {
                this.lenderReferralData.ClientDTO = JSON.parse(
                  sessionStorage.getItem("lenderReferralClient"),
                );
                this.isLenderReferringSearch = true;
              }
              this.userService.getCurrentUserLender().then((response) => {
                this.userLenderId = response;
              });
            }
          });
          if (this.isShortlistingMore) {
            this.lenderService.getDealDeletedLenderIds(this.searchid).then(response => {
              this.deletedDealLenders = response;
            }).finally(() => {
              this.getSearchCriteriaAndResults();
            });
          } else {
            this.getSearchCriteriaAndResults();
          }
        });
      } else {
        this.isClient = true; // if the user isn't logged in then this is the enterprise journey and should be treated as a borrower/client
      }

      // this.updateGuidanceState();

      // $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
      //   this.updateGuidanceState();
      // });

      let logoutUnregister = $rootScope.$on(
        "logout",
        (event: ng.IAngularEvent) => {
          this.isLoggingOut = true;
        },
      );

      $scope.$on("$destroy", logoutUnregister);

      // $rootScope.$on("nextTour", (event: ng.IAngularEvent) => {
      //   this.tourNext();
      // });
      // $rootScope.$on("backTour", (event: ng.IAngularEvent) => {
      //   this.tourBack();
      // });
      // $rootScope.$on("skipTour", (event: ng.IAngularEvent) => {
      //   this.tourSkip();
      // });
    }

    this.getAppName();

    this.numberOfPreviousDevelopmentsOptions =
      this.selectListService.GetNumberOfPreviousDevelopmentsForBridging();
    this.personalGuaranteeLevelOptions =
      this.selectListService.GetBridgingPersonalGuaranteeLevels();
    this.maxHouseSalePriceOptions =
      this.selectListService.GetMaxHouseSalePrices();
    this.maxFlatUnitPriceOptions =
      this.selectListService.GetMaxFlatUnitPrices();
    this.maxSqFtSalePriceOptions =
      this.selectListService.GetMaxSqFtSalePrices();
    this.locationOptions = this.selectListService.GetLocations();
    this.valuationMethodOptions = this.selectListService.GetValuationMethodOptions();
    this.maxCommercialFloorspaceOptions = this.selectListService.GetMaxCommercialFloorspaceOptions();
  }

  getRequiredRoles(isCaseDashboard: boolean) {
    if (isCaseDashboard) return `['Admin', 'Client', 'Broker', 'Introducer']`;
    return `['Admin', 'Client', 'Broker', 'Introducer', 'Lender']`;
  }

  onPersonalGuaranteeLevelOptionsChange(e: BridgingPersonalGuaranteeLevelEnum) {
    switch (e) {
      case BridgingPersonalGuaranteeLevelEnum.None:
        this.loanCriteria.F_PersonalGuaranteeMin = 0.0;
        this.loanCriteria.F_PersonalGuaranteeMax = 0.0;
        break;
      case BridgingPersonalGuaranteeLevelEnum.OneToTwentyFivePercent:
        this.loanCriteria.F_PersonalGuaranteeMin = 0.01;
        this.loanCriteria.F_PersonalGuaranteeMax = 0.25;
        break;
      case BridgingPersonalGuaranteeLevelEnum.TwentySixToFiftyPercent:
        this.loanCriteria.F_PersonalGuaranteeMin = 0.26;
        this.loanCriteria.F_PersonalGuaranteeMax = 0.5;
        break;
      case BridgingPersonalGuaranteeLevelEnum.FiftyOneToOneHundredPercent:
        this.loanCriteria.F_PersonalGuaranteeMin = 0.51;
        this.loanCriteria.F_PersonalGuaranteeMax = 1.0;
        break;
      default:
        this.loanCriteria.F_PersonalGuaranteeMin = null;
        this.loanCriteria.F_PersonalGuaranteeMax = null;
        break;
    }
  }

  getPersonalGuaranteeLevelOptions() {
    return this.sharedSearchResultsService.getPersonalGuaranteeLevelOptions(
      this.tempLoanCriteria.F_PersonalGuaranteeMax,
    );
  }

  getPreviousDevelopments() {
    var value = null;
    switch (this.tempLoanCriteria.F_NumberOfPreviousDevelopments) {
      case 0:
        value = PreviousDevelopmentsEnum.None;
        break;
      case 5:
        value = PreviousDevelopmentsEnum.OneOrTwo;
        break;
      case 6:
        value = PreviousDevelopmentsEnum.ThreeOrMore;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxHouseSalesPriceChange(e: MaxHouseSalePriceEnum) {
    switch (e) {
      case MaxHouseSalePriceEnum.UpTo1Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 1000000;
        break;
      case MaxHouseSalePriceEnum.UpTo2Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 2000000;
        break;
      case MaxHouseSalePriceEnum.UpTo5Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 5000000;
        break;
      case MaxHouseSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxHouseSalesPrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxHouseSalesPrice = null;
        break;
    }
  }

  getMaxHouseSalesPrice() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxHouseSalesPrice) {
      case 1000000:
        value = MaxHouseSalePriceEnum.UpTo1Million;
        break;
      case 2000000:
        value = MaxHouseSalePriceEnum.UpTo2Million;
        break;
      case 5000000:
        value = MaxHouseSalePriceEnum.UpTo5Million;
        break;
      case -999:
        value = MaxHouseSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxFlatUnitPriceChange(e: MaxFlatUnitPriceEnum) {
    switch (e) {
      case MaxFlatUnitPriceEnum.UpTo1Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 1000000;
        break;
      case MaxFlatUnitPriceEnum.UpTo2Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 2000000;
        break;
      case MaxFlatUnitPriceEnum.UpTo3Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 3000000;
        break;
      case MaxFlatUnitPriceEnum.NoMax:
        this.loanCriteria.F_MaxFlatUnitPrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxFlatUnitPrice = null;
        break;
    }
  }

  getMaxFlatUnitPrice() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxFlatUnitPrice) {
      case 1000000:
        value = MaxFlatUnitPriceEnum.UpTo1Million;
        break;
      case 2000000:
        value = MaxFlatUnitPriceEnum.UpTo2Million;
        break;
      case 3000000:
        value = MaxFlatUnitPriceEnum.UpTo3Million;
        break;
      case -999:
        value = MaxFlatUnitPriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxSqFtSalePriceChange(e: MaxSqFtSalePriceEnum) {
    switch (e) {
      case MaxSqFtSalePriceEnum.UpTo850:
        this.loanCriteria.F_MaxSqFtSalePrice = 850;
        break;
      case MaxSqFtSalePriceEnum.UpTo1000:
        this.loanCriteria.F_MaxSqFtSalePrice = 1000;
        break;
      case MaxSqFtSalePriceEnum.UpTo1500:
        this.loanCriteria.F_MaxSqFtSalePrice = 1500;
        break;
      case MaxSqFtSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxSqFtSalePrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxSqFtSalePrice = null;
        break;
    }
  }

  onMaxSqFtFlatsSalePriceChange(e: MaxSqFtSalePriceEnum) {
    switch (e) {
      case MaxSqFtSalePriceEnum.UpTo850:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 850;
        break;
      case MaxSqFtSalePriceEnum.UpTo1000:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 1000;
        break;
      case MaxSqFtSalePriceEnum.UpTo1500:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 1500;
        break;
      case MaxSqFtSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = -999;
        break;
      default:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = null;
        break;
    }
  }

  getMaxSqFtSalePrice() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxSqFtSalePrice) {
      case 850:
        value = MaxSqFtSalePriceEnum.UpTo850;
        break;
      case 1000:
        value = MaxSqFtSalePriceEnum.UpTo1000;
        break;
      case 1500:
        value = MaxSqFtSalePriceEnum.UpTo1500;
        break;
      case -999:
        value = MaxSqFtSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  getMaxSqFtSalePriceFlats() {
    var value = null;
    switch (this.tempLoanCriteria.F_MaxSqFtSalePriceFlats) {
      case 850:
        value = MaxSqFtSalePriceEnum.UpTo850;
        break;
      case 1000:
        value = MaxSqFtSalePriceEnum.UpTo1000;
        break;
      case 1500:
        value = MaxSqFtSalePriceEnum.UpTo1500;
        break;
      case -999:
        value = MaxSqFtSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  clearSelected() {
    if (this.isShortlistingMore) {
      this.comparisonList = this.comparisonList.filter(item =>
        this.summarySearchResults.some(result => result.ProductID == item.ProductID)
      );

      const dealLenders = this.initialSummarySearchResults.filter(result =>
        result.IsDealLender && !result.IsDeleted
      );

      dealLenders.forEach(dealLender => {
        if (!this.comparisonList.some(item => item.ProductID === dealLender.ProductID)) {
          this.comparisonList.push(dealLender);
        }
      });
    } else {
      this.comparisonList = this.comparisonList.filter(item =>
        this.summarySearchResults.some(result => result.ProductID == item.ProductID)
      );
    }
  }

  applyFilters() {
    this.loadingData = true;
    this.showFilters = false;
    this.isFilterUpdate = true;

    if (this.loanCriteria.F_IsFirstTimeDeveloper) {
      this.loanCriteria.F_NumberOfPreviousDevelopments = null;
    }

    this.updateCriteriaAndGetResults(false);
  }

  clearClientFilters() {
    event.stopPropagation();
    this.loanCriteria.F_IsFirstTimeDeveloper = null;
    this.loanCriteria.F_NumberOfPreviousDevelopments = null;
    this.loanCriteria.F_IsPersonalName = null;
    this.loanCriteria.F_IsOffshoreCompany = null;
    this.loanCriteria.F_IsMainShareholderOverseas = null;

    this.loanCriteria.F_PersonalGuaranteeLevel = null;
    this.personalGuaranteeLevel = null;
    this.previousDevelopments = null;

    this.loanCriteria.F_PersonalGuaranteeMin = null;
    this.loanCriteria.F_PersonalGuaranteeMax = null;
    this.loanCriteria.F_HasAdverseCredit = null;
    this.loanCriteria.F_IsBelowMarketValue = null;
    this.loanCriteria.F_IsAutomatedValuationModel = null;
    this.loanCriteria.F_IsAuctionPurchase = null;
    this.loanCriteria.F_ValuationMethod = null;
  }

  showClearForClientFilters() {
    if (
      this.loanCriteria.F_IsFirstTimeDeveloper != null ||
      this.loanCriteria.F_NumberOfPreviousDevelopments != null ||
      this.loanCriteria.F_IsPersonalName != null ||
      this.loanCriteria.F_IsOffshoreCompany != null ||
      this.loanCriteria.F_IsMainShareholderOverseas != null ||
      this.loanCriteria.F_PersonalGuaranteeLevel != null ||
      this.loanCriteria.F_PersonalGuaranteeMin != null ||
      this.loanCriteria.F_PersonalGuaranteeMax != null ||
      this.loanCriteria.F_HasAdverseCredit != null ||
      this.loanCriteria.F_IsBelowMarketValue != null ||
      this.loanCriteria.F_IsAutomatedValuationModel != null ||
      this.loanCriteria.F_IsAuctionPurchase != null ||
      this.loanCriteria.F_ValuationMethod != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleCheckbox(variableName) {
    if (
      this.loanCriteria[variableName] === null ||
      this.loanCriteria[variableName] === false
    ) {
      this.loanCriteria[variableName] = true;
    } else {
      this.loanCriteria[variableName] = null;
    }
  }

  clearPropertyFilters() {
    event.stopPropagation();
    //this.loanCriteria.F_IsAirRights = null;
    //this.loanCriteria.F_IsModular = null;
    //this.loanCriteria.F_IsPermittedDevelopmentScheme = null;
    this.loanCriteria.F_IsGradeOneListed = null;
    this.loanCriteria.F_IsGradeTwoListed = null;
    this.loanCriteria.F_MaxHouseSalesPrice = null;
    this.loanCriteria.F_MaxFlatUnitPrice = null;
    this.loanCriteria.F_MaxNumberOfUnits = null;
    this.loanCriteria.F_MaxPercCommercialForMixedUse = null;
    this.loanCriteria.F_MaxSqFtSalePrice = null;
    this.loanCriteria.F_MaxSqFtSalePriceFlats = null;

    //this.loanCriteria.F_IsWorkStarted = null;
    this.maxHouseSalesPrice = null;
    this.maxFlatUnitPrice = null;
    this.maxSqFtSalePrice = null;
    this.maxSqFtSalePriceFlats = null;
    this.loanCriteria.F_IsTimberFrameConstruction = null;
    this.loanCriteria.F_IsConcreteConstruction = null;
    this.loanCriteria.F_MaxCommercialFloorspace = null;
  }

  showClearForPropertyFilters() {
    if (
      this.loanCriteria.F_IsGradeOneListed != null ||
      this.loanCriteria.F_IsGradeTwoListed != null ||
      this.loanCriteria.F_MaxHouseSalesPrice != null ||
      this.loanCriteria.F_MaxFlatUnitPrice != null ||
      this.loanCriteria.F_MaxNumberOfUnits != null ||
      this.loanCriteria.F_MaxSqFtSalePrice != null ||
      this.loanCriteria.F_MaxSqFtSalePriceFlats != null ||
      this.loanCriteria.F_IsTimberFrameConstruction != null ||
      this.loanCriteria.F_IsConcreteConstruction != null ||
      this.loanCriteria.F_MaxCommercialFloorspace != null

    ) {
      return true;
    } else {
      return false;
    }
  }

  clearFinanceFilters() {
    event.stopPropagation();
    this.loanCriteria.F_IsChargeOnAdditionalProperty = null;
    this.loanCriteria.F_IsFixedRate = null;
    this.loanCriteria.F_IsShariaLoan = null;
    this.loanCriteria.F_IsRetypedValuation = null;
    this.loanCriteria.F_IsIndemnityInsurance = null;
    this.loanCriteria.F_IsTitleInsurance = null;
    this.loanCriteria.F_IsServicedInterest = null;
    this.loanCriteria.F_IsReBridge = null;
  }

  showClearForFinanceFilters() {
    if (
      this.loanCriteria.F_IsChargeOnAdditionalProperty != null ||
      this.loanCriteria.F_IsFixedRate != null ||
      this.loanCriteria.F_IsRetypedValuation != null ||
      this.loanCriteria.F_IsIndemnityInsurance != null ||
      this.loanCriteria.F_IsTitleInsurance != null ||
      this.loanCriteria.F_IsServicedInterest != null ||
      this.loanCriteria.F_IsReBridge != null ||
      this.loanCriteria.F_IsShariaLoan != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearAllFilters() {
    this.clearClientFilters();
    this.clearPropertyFilters();
    this.clearFinanceFilters();
  }

  debugSearch() {
    const stringJSON = JSON.stringify(this.loanCriteria);
    navigator.clipboard
      .writeText(stringJSON)
      .then()
      .catch((e) => console.log(e));
  }

  onClickFilterCancel() {
    this.filterProperties.forEach(
      (e) => (this.loanCriteria[e] = this.tempLoanCriteria[e]),
    );

    this.personalGuaranteeLevel = null;
    this.maxHouseSalesPrice = null;
    this.maxFlatUnitPrice = null;
  }

  filterPropertyToText(property: string) {
    let value = this.tempLoanCriteria[property];
    if (typeof value == "boolean" && value == true) {
      return;
    } else if (value != null && typeof value != "boolean") {
      return `: ${this.getPropertyValue(property)}`;
    } else if (value == null) {
      return;
    }
    return false;
  }

  checkFilterSelected(property: string) {
    return this.sharedSearchResultsService.checkFilterSelected(
      property,
      this.tempLoanCriteria,
    );
  }

  getPropertyValue(property) {
    let propertyValue = this.tempLoanCriteria[property];
    switch (property) {
      case "F_PersonalGuaranteeMax":
        return this.selectListService
          .GetBridgingPersonalGuaranteeLevels()
          .find((item) => item.key === this.getPersonalGuaranteeLevelOptions())
          ?.displayName;
      case "F_MaxHouseSalesPrice":
        return this.selectListService
          .GetMaxHouseSalePrices()
          .find((item) => item.key === this.getMaxHouseSalesPrice())
          ?.displayName;
      case "F_MaxFlatUnitPrice":
        return this.selectListService
          .GetMaxFlatUnitPrices()
          .find((item) => item.key === this.getMaxFlatUnitPrice())?.displayName;
      case "F_MaxSqFtSalePrice":
        return this.selectListService
          .GetMaxSqFtSalePrices()
          .find((item) => item.key === this.getMaxSqFtSalePrice())?.displayName;
      case "F_MaxSqFtSalePriceFlats":
        return this.selectListService
          .GetMaxSqFtSalePrices()
          .find((item) => item.key === this.getMaxSqFtSalePriceFlats())
          ?.displayName;
      case "F_NumberOfPreviousDevelopments":
        return this.selectListService
          .GetNumberOfPreviousDevelopmentsForBridging()
          .find((item) => item.key === this.getPreviousDevelopments())
          ?.displayName;
      case "F_MaxNumberOfUnits":
        return propertyValue;
      case "F_ValuationMethod":
        return this.selectListService
          .GetValuationMethodOptions()
          .find((item) => item.key === this.tempLoanCriteria.F_ValuationMethod)
          ?.displayName;
      case "F_MaxCommercialFloorspace":
        return this.selectListService
          .GetMaxCommercialFloorspaceOptions()
          .find((item) => item.key === this.tempLoanCriteria.F_MaxCommercialFloorspace)
          ?.displayName;
      default:
        return propertyValue;
    }
  }

  countFiltersSelected() {
    let count = 0;
    count = this.sharedSearchResultsService.countFiltersSelected(
      this.tempLoanCriteria,
      this.filterProperties,
    );
    return count;
  }

  getDisplayTextForProperty(property) {
    return this.sharedSearchResultsService.getDisplayTextForProperty(property);
  }

  splitCamelCase(str) {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
  }

  updateCriteriaAndGetResults(
    criteriaChanged: boolean,
    updateLoanLabels: boolean = false,
  ) {
    this.bridgingDealService
      .updateSearchCriteriaAndGetResults(
        this.loanCriteria,
        criteriaChanged,
        this.debug1,
        this.isPostcodeChange,
        this.isShortlistingMore
      )
      .then((results: UpdateBridgingSearchGetResultResponse) => {
        if (this.isPostcodeChange) {
          if (results.CriteriaLocation != null) {
            this.loanCriteria.Locations = results.CriteriaLocation;
            this.summarySearchResults = results.Results;
          } else {
            this.postcodeErrorMsg = results.PostCodeErrorMessage;
            this.showPostcodeErrorMessage = true;
          }
        } else {
          this.summarySearchResults = results.Results;
          this.sortSummaryResults();
        }
      })
      .catch((error) => { })
      .finally(() => {
        this.tempLoanCriteria = { ...this.loanCriteria };
        this.clearSelected();
        this.isPostcodeChange = false;

        if (updateLoanLabels) {
          this.updateLoanLabels();
        }

        this.loadingData = false;
      });
  }

  /**Update the loan label in the comparison results based on the loan label returned in the results
   * Primary purpose is to update the loan label when the show lender names button is toggled
   * */
  updateLoanLabels() {
    for (var i = 0; i < this.comparisonList.length; i++) {
      let resultIndex = this.summarySearchResults.findIndex(
        (item) => item.ProductID == this.comparisonList[i].ProductID,
      );

      if (resultIndex > -1) {
        this.comparisonList[i].LoanLabel =
          this.summarySearchResults[resultIndex].LoanLabel;
      }
    }

    //This is used in shortlisting pdf
    this.prepareDataForShortlistPdf();
  }

  changeSearch() {
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
    this.warningOff = true;
    sessionStorage.setItem("skip", "true");
    if (!this.isLoggedInUser && (this.dealUniqueRef || this.dealClientUniqueRef)) {
      if (this.dealClientUniqueRef) sessionStorage.setItem('previousRoute', 'referredsearchdeal')
      var url = `/e/bridgingcriteria/${this.loanCriteria.UniqueRef}`;
      if (this.loanCriteria.OrganisationLinkId > 0) {
        url = `${url}/${this.loanCriteria.OrganisationLinkId}`;
      }
      this.go(url);
    }
    else if (this.searchid) {
      this.go("/bridgingcriteria/" + this.searchid);
    } else {
      this.go("/bridgingcriteria");
    }
  }

  newloan() {
    (this.$rootScope as any).loanCriteria = null;
    this.$location.path("/bridgingcriteria/0");
  }

  getSearchResultCount() {
    return this.sharedSearchResultsService.getSearchResultCount(
      this.summarySearchResults,
    );
  }

  checkDeletedLender(lenderId: string, productId: string): boolean {
    if (this.isShortlistingMore && this.deletedDealLenders) {
      const deletedLender = this.deletedDealLenders.find(deletedLender => deletedLender.LenderId === String(lenderId));

      if (deletedLender) {
        return deletedLender.ProductId === String(productId);
      }
    }
    return false;
  }

  getSearchLenderCount() {
    this.lenderService
      .getTotalLenders(ProductFamilyEnum.Bridging)
      .then((result: number) => {
        this.totalLender = result;
      });
  }

  getUsersBelongToBrokerOrAdmin() {
    this.loadingData = true;
    this.$user
      .getUserSummariesForBrokerOrAdmin()
      .then((response) => {
        this.existingUsers = response;
      });
  }

  // updateGuidanceState() {
  //   this.tourEnabled = this.sharedSearchResultsService.updateGuidanceState(
  //     this.guidanceCheckbox,
  //   );
  // }

  // getGuidanceSwitchState() {
  //   return this.sharedSearchResultsService.getGuidanceSwitchState();
  // }

  // recordGuidanceCookie() {
  //   this.sharedSearchResultsService.recordGuidanceCookie(this.guidanceCheckbox);
  //   this.$rootScope.$broadcast("guidanceStateChanged");
  // }

  // tourNext(): void {
  //   this.tourState.tourStep++;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  // }

  // tourBack(): void {
  //   this.tourState.tourStep--;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  // }

  // tourSkip(): void {
  //   this.tourEnabled = false;
  //   this.$cookies.put("tourEnabled", "false");
  //   this.$cookies.remove("tourStep");
  // }

  // startTour(): void {
  //   this.tourState.tourStep = 1;
  //   this.tourState.tourTotalSteps = 0;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  //   this.tourEnabled = true;
  //   this.$cookies.put("tourEnabled", "true");
  // }

  /**
   * Saves the new search name on the edit.
   * @param none
   */
  doRenameSearch() {
    var request: SaveBridgingSearchRequest = {
      DealDto: this.loanCriteria,
      ShareDealDto: this.shareDealDto,
      OrgCode: this.orgCode,
      EnterpriseClientDto: null,
    };

    if (this.searchid) {
      this.bridgingDealService
        .saveBridgingSearchReturnsId(request)
        .then((response) => {
          this.toggleEditSearchName = false;
        });
    } else if (this.dealUniqueRef) {
      if (this.isLoggedInUser) {
        this.bridgingDealService.saveBridgingSearchReturnsUniqueRef(request)
          .then((response) => {
            this.toggleEditSearchName = false;
          });
      } else {
        this.dealService.renameDeal(this.dealUniqueRef, this.loanCriteria.Name)
          .then((response) => {
            this.toggleEditSearchName = false;
          });
      }
    }
    else {
      this.toggleEditSearchName = false;
    }
  }

  /**
   * Gets the search results and criteria for selected search
   * @param none
   */
  getSearchCriteriaAndResults() {
    if (window.self == window.top) {
      this.snapshotNewSearch = sessionStorage.getItem("newSearch") == "true";
      sessionStorage.setItem("newSearch", "false");
    }

    if (this.searchid) {
      this.loadingData = true;
      this.bridgingSearchService
        .getSearchCriteriaAndResultsByDealId(
          this.searchid,
          this.snapshotNewSearch,
        )
        .then((results) => {
          this.postResultsProcessing(results);
        })
        .then(() => {
          if (this.summarySearchResults != null) {
            const productIds = this.summarySearchResults.map((product) => product.ProductID);
            this.getProductLastVerifiedDates(productIds);
          }
        })
        .then(() => {
          this.getSearchLenderCount();
          if (this.isLoggedInUser) this.getSelectedProducts();
          this.updateLoanLabels();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.loanCriteria) {
            this.isMaximumLoanRequired = this.loanCriteria.MaxLoanRequired;
            this.isSearch =
              this.loanCriteria &&
              this.loanCriteria.Status == CaseStatusEnum.Search;
            this.tempLoanCriteria = { ...this.loanCriteria };
            this.$CaseService.updateTubeMap(this.loanCriteria.Status);
            this.loadingData = false;
            this.hasResultsProcessed = true;
          }
        });
    } else if (this.dealUniqueRef) {
      this.loadingData = true;
      this.bridgingSearchService
        .getSearchCriteriaAndResultsByDealUniqueRef(
          this.dealUniqueRef,
          this.snapshotNewSearch,
        )
        .then((results) => {
          this.postResultsProcessing(results);
        })
        .then(() => {
          if (this.summarySearchResults != null) {
            const productIds = this.summarySearchResults.map((product) => product.ProductID);
            this.getProductLastVerifiedDates(productIds);
          }
        })
        .then(() => {
          this.getSearchLenderCount();
          if (this.loanCriteria) this.getSelectedProducts();
          this.updateLoanLabels();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.loanCriteria) {
            this.isMaximumLoanRequired = this.loanCriteria.MaxLoanRequired;
            this.isSearch =
              this.loanCriteria &&
              this.loanCriteria.Status == CaseStatusEnum.Search;
            this.tempLoanCriteria = { ...this.loanCriteria };
            //this.$CaseService.updateTubeMap(this.loanCriteria.Status);
            this.loadingData = false;
            this.hasResultsProcessed = true;
          }
        });
    } else if (this.dealClientUniqueRef) {
      this.loadingData = true;
      this.bridgingSearchService
        .getSearchCriteriaAndResultsByDealClientUniqueRef(
          this.dealClientUniqueRef,
          this.snapshotNewSearch,
        )
        .then((results) => {
          this.postResultsProcessing(results);
        })
        .then(() => {
          if (this.loanCriteria) this.getSelectedProducts();
          this.getSearchLenderCount();
          if (
            sessionStorage.getItem("LoanCriteria") &&
            (this.$rootScope as any).previousRoute.startsWith("/compare")
          ) {
            this.comparisonList = JSON.parse(
              sessionStorage.getItem("ComparisonList"),
            );
            //Removing lsd data saved for compare view.
            sessionStorage.removeItem("LoanCriteria");
            sessionStorage.removeItem("ComparisonList");
          }
        })
        .finally(() => {
          this.isMaximumLoanRequired = this.loanCriteria.MaxLoanRequired;
          this.isSearch =
            this.loanCriteria &&
            this.loanCriteria.Status == CaseStatusEnum.Search;
          //this.$CaseService.updateTubeMap(this.loanCriteria.Status);
          this.tempLoanCriteria = { ...this.loanCriteria };
          this.loadingData = false;
          this.hasResultsProcessed = true;
        });
    }
  }

  postResultsProcessing(results: BridgingSearchResultsResponse) {
    if (results.HideDeal || results.HasNoAccessToDeal) {
      if (results.HasNoAccessToDeal) {
        if (this.isClient) {
          this.noAccessToDealMsg =
            "Please contact your broker in order to access this case.";
        } else {
          this.noAccessToDealMsg = "Error retrieving deal.";
        }
      }

      if (results.HideDeal) {
        this.noAccessToDealMsg =
          "You cannot access Bridging search results. Please contact your admin for more details.";
      }

      this.hasAccessToDeal = false;
    } else {
      this.summarySearchResults = this.initialSummarySearchResults = results.Results;
      this.loanCriteria = results.CriteriaDto;
      
      if(this.isLoggedInUser && (this.loanCriteria.Status != CaseStatusEnum.NewCase && this.loanCriteria.Status != CaseStatusEnum.Search && !this.isShortlistingMore)){
        this.dealLenderService
        .fetchDealLendersByDealId( this.loanCriteria.Id)
        .then((results) => {
          if(results && results.filter(dl => !dl.IsDeleted && dl.Status != CaseLenderStateEnum.Withdrawn && dl.Status != CaseLenderStateEnum.Rejected).length >= 5 && results.filter(dl => !dl.IsDeleted && dl.IsBidAccepted)){
            if (this.isAdmin || this.isBroker) {
              this.$location.path("/dealforum/" + this.loanCriteria.Id)
            }
            else {
              this.$location.path(`/userdashboard`)
            }
          }else{
            this.$location.path(`/bridgingshortlistmore/${this.loanCriteria.Id}`)
          }
        })
      }

      this.sortSummaryResults();

      if (this.isLoggedInUser) {
        if (this.dealClientUniqueRef) {
          if (this.isClient) {
            this.eventLogService.logPageLoad(
              "AFTER-ENTERPRISE-LEAD-REGISTRATION-USING-EMAIL-LINK",
              this.orgCode,
              this.selectedUser.UserName,
              "Client",
              this.loanCriteria?.OrganisationLinkId,
              this.loanCriteria.ProductType,
              "",
              this.loanCriteria?.Id,
            );
          }
        } else {
          if (this.loanCriteria.LenderReferralLenderId) this.isLenderReferredSearch = true;

          // Showing confirm client phone number modal once client register through result page
          if (this.isClient && sessionStorage.getItem('showConfirmClientPhoneNo') == 'true') {
            this.showClientPhoneNumberConfirmationModal = true;
            this.clientUpdatedContact = this.loanCriteria?.DealClients[0].Client.PhoneNumber;
          }

          this.dealProductTypeDisplayText = this.productService.getProductTypeDisplayText(this.loanCriteria.ProductType,);

          // Expanding a 'new search' panel for first time search execution
          if (
            window.self == window.top &&
            sessionStorage.getItem("newSearch")
          ) {
            this.sliderShown = (this.$rootScope as any).previousRoute.startsWith("/compare") || this.showClientPhoneNumberConfirmationModal ? false : true;
            sessionStorage.removeItem("newSearch");
          }
        }
      } else {
        if (this.$location.path().startsWith("/e/bridgingresults")) {
          this.populateEnterpriseClientValue("LENDERRESULTS");
        }

        if (this.dealClientUniqueRef) {
          if (this.$routeParams.showOnlyRegister == "false")
            this.isClient = true;
          this.populateEnterpriseClientValue("LENDERRESULTS-USING-EMAIL-LINK");
        }
      }

      var owner = this.loanCriteria.DealClients.find((dc) => dc.IsPrimary);

      if (owner) {
        this.isAssigned = true;
      }
    }
  }

  populateEnterpriseClientValue(eventType) {
    if (this.loanCriteria.DealClients[0] != null) {
      this.enterpriseClient.FirstName =
        this.loanCriteria.DealClients[0].Client.FirstName;
      this.enterpriseClient.LastName =
        this.loanCriteria.DealClients[0].Client.LastName;
      this.enterpriseClient.Email =
        this.loanCriteria.DealClients[0].Client.Email;
      this.enterpriseClient.UserName =
        this.loanCriteria.DealClients[0].Client.Email;
      this.enterpriseClient.DealClientUniqueRef = this.loanCriteria.DealClients[0].UniqueRef;
      this.enterpriseClient.OrgCode = this.orgCode;
      this.enterpriseClient.PhoneNumber =
        this.loanCriteria.DealClients[0].Client.PhoneNumber;
      if (this.loanCriteria.DealClients[0].Client.ClientUserId) {
        this.showRegisterModal = true;
        this.showLoginSection = true;
      }

      if (this.loanCriteria.OrganisationLinkId) {
        this.organisationLinkService
          .getOrganisationLinkLogoByLinkIdAndLogPageLoadEvent(Number(this.loanCriteria.OrganisationLinkId), eventType, this.loanCriteria.Id, this.loanCriteria.ProductType, this.loanCriteria.DealClients[0]?.Client?.Email, this.loanCriteria.DealClients[0]?.Client?.Id.toString())
          .then((logoUrl) => {
            if (logoUrl) {
              var imgs = document.getElementsByTagName("img");
              imgs[0].src = logoUrl
            }
          });
      } else {
        this.eventLogService.logPageLoad(
          eventType,
          this.orgCode,
          this.loanCriteria.DealClients.length > 0
            ? this.loanCriteria.DealClients[0].Client.Email
            : "",
          "Client",
          this.loanCriteria.OrganisationLinkId != null
            ? this.loanCriteria.OrganisationLinkId
            : null,
          this.loanCriteria?.ProductType,
          "",
          this.loanCriteria?.Id,
          this.loanCriteria.DealClients.length > 0
            ? this.loanCriteria.DealClients[0].Client.Id.toString()
            : null,
        );
      }
      //Todo Roopa: do we need a broker details?
      this.getOrganisationAndBrokerDetails();
    }
  }

  go(path): void {
    this.$location.path(path);
  }

  toggleLenderComparisonSelection(
    item: BridgingLenderResultSummaryDTO,
    index: number,
  ) {

    let comparisonMatches: BridgingLenderResultSummaryDTO[] =
      this.comparisonList.filter((result, index) => {
        return result.ProductID === item.ProductID;
      });

    if (!comparisonMatches || comparisonMatches.length === 0) {
      this.comparisonList.push(item);
      this.eventLogService.logEvent(
        `SHORTLISTING-RESULT`,
        EventLogEnum.Shortlisting,
        this.loanCriteria.ProductType,
        this.loanCriteria.Id,
        "",
        item.ProductID.toString(),
        ""
      );
    } else {

      if (this.isShortlistingMore && item.IsDealLender && (this.loanCriteria.Status != CaseStatusEnum.Search && this.loanCriteria.Status != CaseStatusEnum.NewCase))
        return;
      comparisonMatches.forEach((value, index) => {
        this.comparisonList.splice(this.comparisonList.indexOf(value), 1);
      });
      this.eventLogService.logEvent(
        `DESELECTING-SHORTLISTED-RESULT`,
        EventLogEnum.Shortlisting,
        this.loanCriteria.ProductType,
        this.loanCriteria.Id,
        "",
        item.ProductID.toString(),
        ""
      );
    }
    //This is user for shortlisting pdf
    this.prepareDataForShortlistPdf();
  }

  comparisonContains(item: BridgingLenderResultSummaryDTO): boolean {
    return !!this.comparisonList.find((result, index) => {
      return result.ProductID === item.ProductID;
    });
  }

  checkLender(item: BridgingLenderResultSummaryDTO): boolean {
    if (this.userLenderId == item.LenderID) return true;
    return false;
  }

  selectionOrderNumber(item: BridgingLenderResultSummaryDTO) {


    var activeProductList = this.comparisonList.filter(
      (cl) =>
        cl.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        cl.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );

    var hiddenProduct = this.HiddenDealLenderList.filter(hl => hl.Status != CaseLenderStateEnum.Rejected && hl.Status != CaseLenderStateEnum.Withdrawn);
    var hiddenProductLength = this.isShortlistingMore ? hiddenProduct.length : 0;

    var order =
      activeProductList.map((item) => item.ProductID).indexOf(item.ProductID) + hiddenProductLength +
      1;
    if (order === 0) {
      return " ";
    }

    return order;
  }

  /**
   * Processes the clicking of the "View Eligibility" anchor/button and index to show on the row number
   * @param item BridgingLenderResultSummaryDTO
   */
  viewEligibility(item: BridgingLenderResultSummaryDTO) {
    event.preventDefault(); // prevents default behaviour of href
    this.showProductNote = true;
    this.offer = item;
    this.loanLabel = item.LoanLabel;
    this.productNote = item.AdditionalProductInfo;
  }

  /**
   *Marks a selected search as a deleted
   * @param
   */
  deleteSearch() {
    if (this.$routeParams.SearchId) {
      this.dealService
        .markasdeleted(this.$routeParams.SearchId)
        .then((response) => {
          this.displayMsg = "Search is successfully deleted";
          this.showMsg = true;
          this.ShowdeleteSearch = true;
        })
        .catch((error) => {
          this.displayMsg = "Sorry, something went wrong. Please try again.";
          this.showMsg = true;
        });
    }
  }

  closeDeleteModal() {
    this.showMsg = false;
    this.displayMsg = null;
    this.ShowdeleteSearch = false;
    this.warningOff = true;
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.go("/userdashboard");
  }

  viewSingleLoan(item: BridgingLenderResultSummaryDTO) {
    event.preventDefault(); // prevents default behaviour of href
    this.warningOff = true;
    //Storing the actual comparisonList to local storage so that to recover it later when we comeback to result screen from compare screen.
    sessionStorage.setItem(
      "actualcomparisonList",
      JSON.stringify(this.comparisonList),
    );
    this.comparisonList = [];

    this.comparisonList.push(item);
    this.goCompare();
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
  }

  goCompare() {
    this.warningOff = true;

    this.sharedSearchResultsService.clearStorageDataForCompareView();
    let comparisonList = this.comparisonList.filter(
      (cl) =>
        cl.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        cl.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );

    sessionStorage.setItem("ComparisonList", JSON.stringify(comparisonList));
    sessionStorage.setItem("LoanCriteria", JSON.stringify(this.loanCriteria));


    // temporarily put all results into saved results so the comparison controller can use them.
    let allResults: BridgingLenderResultSummaryDTO[] = [];
    this.summarySearchResults.forEach((result) => {
      allResults.push(result);
    });

    sessionStorage.setItem("TempSavedResults", JSON.stringify(allResults));

    if (this.dealClientUniqueRef) {
      if (!this.isLoggedInUser) {
        this.dealUniqueRef = this.loanCriteria.UniqueRef;
        //This property is used in compare view controller to find previous route 
        sessionStorage.setItem("DealClientUniqueRef", this.dealClientUniqueRef);
      } else {
        this.searchid = this.loanCriteria.Id;
      }
    }

    if (this.searchid) {
      this.$location.path("compare/" + this.searchid + "/" + 0 + "/" + true);
    } else {
      this.$location.path("compare/" + this.dealUniqueRef + "/" + 0 + "/" + true);
    }

  }

  /**
   * Opens the modal to enter new name or calls 'register' method to svae and promote search to case.
   */

  applyForLoan() {
    this.isProceedClicked = true;
    this.register(true, this.productResultList);
    //if (this.searchid && this.loanCriteria.SearchName.startsWith('My Loan Search')) {
    //    this.showSaveResults = 2;
    //} else if (this.searchid) {
    //    this.register(true, this.productResultList)
    //}
  }

  getDealLenders(selectedLenders: BridgingLenderResultSummaryDTO[]) {
    var lendersList: DealLenderDTO[] = [];

    if (this.dealClientUniqueRef || this.dealUniqueRef) {
      this.searchid = this.loanCriteria.Id;
    }

    selectedLenders.forEach(({ ProductID, LenderID, GrossLoan }) => {
      var dealLender: DealLenderDTO;

      if (this.dealLenders) {
        dealLender = this.dealLenders.find(
          (dl) =>
            dl.DealId === this.searchid &&
            dl.ProductId === ProductID &&
            dl.LenderId === LenderID,
        );
      }

      if (!dealLender) {
        dealLender = {
          DealId: this.searchid,
          ProductId: ProductID,
          LenderId: LenderID,
          Status: CaseLenderStateEnum.Shortlisted,
          OriginalTotalGrossLoan: GrossLoan,
        } as DealLenderDTO;
      }

      lendersList.push(dealLender);
    });


    var unselecteLenderList = [];

    if (this.dealLenders) {
      unselecteLenderList = this.dealLenders.filter(dl => !lendersList.some(ll => ll.ProductId === dl.ProductId && ll.LenderId === dl.LenderId) && (dl.Status == CaseLenderStateEnum.Rejected || dl.Status == CaseLenderStateEnum.Withdrawn));
    }

    if (unselecteLenderList.length > 0) {
      unselecteLenderList.forEach((l) => {
        l.IsDeleted = true;
        lendersList.push(l);
      });
    }

    return lendersList;
  }


  getSelectedProducts() {

    if (this.summarySearchResults && this.summarySearchResults.length > 0) {
      this.dealLenderService
        .fetchDealLendersByDealId(this.dealClientUniqueRef || this.dealUniqueRef ? this.loanCriteria.Id : this.searchid)
        .then((results) => {
          this.dealLenders = results;
        })
        .then(() => {
          this.comparisonList = this.summarySearchResults.filter((result) => {
            const isDealLender = this.dealLenders.find(
              (dealLender) => dealLender.ProductId === result.ProductID,
            );
            if (isDealLender) {
              result.DealLenderStatus = isDealLender.Status;
            }
            return (result.IsDealLender = Boolean(isDealLender));
          });

          // Previously shortlisted/submitted lenders which are not visible on current results because of criteria change
          this.HiddenDealLenderList = this.dealLenders.filter(l =>
            !this.summarySearchResults.some(s => l.ProductId === s.ProductID)
          );

          if (this.comparisonList.length > 0) {
            var inActiveDealLenderList = this.comparisonList.filter(
              (ia) =>
                ia.DealLenderStatus == CaseLenderStateEnum.Rejected ||
                ia.DealLenderStatus == CaseLenderStateEnum.Withdrawn,
            );
            this.inActiveDealLender = inActiveDealLenderList.length;
          }

          if (
            sessionStorage.getItem("LoanCriteria") &&
            (this.$rootScope as any).previousRoute.startsWith("/compare")
          ) {
            if (sessionStorage.getItem("actualcomparisonList")) {
              var selectedProducts = sessionStorage.getItem(
                "actualcomparisonList",
              );
              this.comparisonList = JSON.parse(selectedProducts);
              sessionStorage.removeItem("actualcomparisonList");
            } else {
              this.comparisonList = JSON.parse(
                sessionStorage.getItem("ComparisonList"),
              );
            }
            if (this.inActiveDealLender > 0) {
              this.comparisonList = this.comparisonList.concat(
                inActiveDealLenderList,
              );
            }

            //Removing lsd data saved for compare view.
            sessionStorage.removeItem("LoanCriteria");
            sessionStorage.removeItem("ComparisonList");
          }
        });
    }
  }

  /**
   * Saves the search or saves the search and create a case.
   * @param createCase (boolean set to true when promoting search to case)
   */
  register(createCase: boolean = false, productResultList: number) {
    this.loadingData = true;

    if (this.isShortlistingMore) {
      if (this.validateAllLendersUnique(this.comparisonList) == true) {
        this.dealLenderService.shortlistMoreLenders(this.loanCriteria.Id, this.getDealLenders(this.comparisonList), this.hasSearchChanged).then((response) => {
        }).then(() => {
          this.getSelectedProducts();
        }).finally(() => {
          this.loadingData = false;
        });
      } else {
        this.loadingData = false;
      }
    } else {
      if (
        this.shareDealDto &&
        this.shareDealDto.DealName &&
        this.shareDealDto.DealName.length > 0
      ) {
        this.loanCriteria.Name = this.shareDealDto.DealName;
      }

      var request: SaveBridgingSearchRequest = {
        DealDto: this.loanCriteria,
        ShareDealDto: null,
        OrgCode: "",
        EnterpriseClientDto: null,
      };

      this.bridgingDealService
        .saveBridgingSearchReturnsId(request)
        .then((response) => {
          this.loanCriteria.Id = response as number;
          this.loadingData = false;

          // Creating the case is done on the casedashboard for deals
          if (createCase) {
            this.$location.path(
              "/bridgingcasedashboard/" + this.loanCriteria.Id + "/" + true,
            );
          }

          var shortlistDealLenders = this.getDealLenders(this.comparisonList);

          this.dealLenderService
            .addUpdatelistreturnonlyids(shortlistDealLenders)
            .then((response) => {
              let deallenders = this.dealLenders.filter(
                (lender) => response.indexOf(lender.Id) === -1,
              );

              deallenders.forEach((lender) =>
                this.dealLenderService.markasdeleted(lender.Id),
              );
            }).finally(() => {
              this.getSelectedProducts();
              this.isClicked = false;
              this.newSearch = false;
              this.isProceedClicked = false;
              this.showSaveResults = null;
              this.loadingData = false;
            });
        })
        .catch((response) => {
          //TODO BRIDGING - capture if an error has occurred and process it
          //this.isError = true;
          this.isProceedClicked = false;
        })
        .finally(() => { });
    }
  }

  /*onClickSaveAs() {
            this.showSaveResults = 3;
            this.tempSearchName = this.loanCriteria.Name;
            this.loanCriteria.Name = '';
        }

        saveAs() {

            this.isSaveorSaveAsClicked = true;
            this.register(false, this.productResultList);
        }*/

  onClickSaveAs() {
    this.isSaveAsClicked = true;
    if (this.isAdmin || this.isBroker) {
      if (!this.shareDealDto) {
        this.shareDealDto = {
          IsApplicant: true,
          IsPrimary: true,
        } as ShareDealDTO;
      }
      this.shareDealDto.DealName = "";
      this.showShareSearchModal();
    } else {
      this.showSaveResults = 3;
    }
  }

  saveSaveAsSearch(attachClient = false) {
    this.loadingData = true;
    let newSearchName = this.isBroker || this.isAdmin ? this.shareDealDto.DealName : this.saveAsSearchName;

    if (newSearchName.length == 0) {
      newSearchName = this.loanCriteria.Name + ' (Copy)';
    }

    var request: SaveAsSearchRequest = {
      ShareDealDto: attachClient ? this.shareDealDto : null,
      ProductFamily: this.loanCriteria.ProductFamily,
      SearchId: this.loanCriteria.Id,
      NewSearchName: newSearchName,
    };

    this.dealService
      .saveSaveAsSearch(request)
      .then((result) => {
        this.displayMsg = `${newSearchName} search has been saved to your dashboard.`;
      })
      .catch((error) => {
        this.displayMsg = "Sorry, something went wrong. Please try again.";
      })
      .finally(() => {
        this.cancelNewSearch();
        this.loadingData = false;
        this.isSaveAsClicked = false;
        this.shareDealDto = {
          IsApplicant: true,
          IsPrimary: true,
        } as ShareDealDTO;
        this.saveAsSearchName = "";
        this.showSaveResults = null;
        this.showMsg = true;
      });
  }

  openSendResultsToBorrowerModal() {
    var client = this.loanCriteria.DealClients[0]["Client"]; // TODO BRIDGING FUTURE- always want to assume there's one for now until there's a redesign of sharing
    this.shareDealDto.DealId = this.loanCriteria.Id;
    this.shareDealDto.DealName = this.loanCriteria.Name;
    this.shareDealDto.IsApplicant = true;
    this.shareDealDto.IsPrimary = true;
    this.shareDealDto.ClientDto = {
      FirstName: client.FirstName,
      LastName: client.LastName,
      Email: client.Email,
      PhoneNumber: client.PhoneNumber,
      ClientUserId: client.ClientUserId,
    } as ClientDTO;
    this.existingborrower = null;
    this.newSearch = true;
    this.sharedSearch = true;
  }

  /** closes share results modal*/
  cancelNewSearch() {
    this.newSearch = false;
    this.shareDealDto.ClientDto = null;
    this.existingborrower = null;
    this.showClientDetails = false;
    this.selecteduserName = null;
    this.isSaveAsClicked = false;
    this.sharedSearch = false;
    if (this.tempSearchName) {
      this.loanCriteria.Name = this.tempSearchName;
      this.tempSearchName = "";
    }
  }

  /**
   * Sends email to client attached to the search
   */
  sendShareSearchEmailToClient() {
    this.dataLoading = true;
    this.shareDealDto.EmailLinkToClient = true;
    this.dealService
      .shareDealWithClient(this.shareDealDto)
      .then(() => {
        this.displayMsg =
          "Search results have been sent to " +
          this.shareDealDto.ClientDto.FirstName +
          " " +
          this.shareDealDto.ClientDto.LastName;
      })
      .catch((error) => {
        this.displayMsg = "Sorry, something went wrong. Please try again.";
      })
      .finally(() => {
        this.newSearch = false;
        this.showMsg = true;
        this.dataLoading = false;
        this.sharedSearch = false;
      });
  }

  /**
   * Opens a 'share a search with client' modal if search do not have client attached to.
   * @param none
   */
  /* saveAsWthOutIntroducee() {
            this.isSaveAsWthOutIntroducee = true;
            this.tempSearchName = this.loanCriteria?.Name;
            //this.loanCriteria.Name = '';
            this.showShareSearchModal()
        }*/

  /**
   * Opens a 'share a search with client' modal if search do not have client attached to.
   * @param none
   */
  saveResultsForShareSearchToClient() {
    if (!this.isSaveAsClicked) {
      this.register(false, this.productResultList);
    } else {
      this.saveSaveAsSearch();
    }
    this.newSearch = false;
  }

  showShareSearchModal() {
    this.newSearch = true;

    this.shareDealDto = {
      DealId: this.searchid,
      DealName: this.isSaveAsClicked ? "" : this.loanCriteria?.Name,
      ShowLenderNamesAndLogos: this.loanCriteria?.ShowLenderInfoBrokerOverride,
      EmailLinkToClient: false,
      AccessLevel: CaseAccessLevelEnum.FullAccess,
      IsApplicant: true,
      IsPrimary: true,
    } as ShareDealDTO;

    this.shareDealDto.ClientDto = {
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
    } as ClientDTO;
  }

  /**
   * updates a seach with introducee details and saves it and sends emil to client if 'notifyBorrower' is set to true.
   * @param notifyBorrower
   */

  sendResultsToClient(notifyBorrower) {
    if (this.isSaveAsClicked) {
      this.saveSaveAsSearch(true);
    } else {
      this.loadingData = true;
      this.dealService
        .shareDealWithClient(this.shareDealDto)
        .then(() => {
          this.displayMsg =
            "Search results have been sent to " +
            this.shareDealDto.ClientDto.FirstName +
            " " +
            this.shareDealDto.ClientDto.LastName;
          //this.loanCriteria.DealClients[0] = this.shareDealDto.ClientDto;
          this.loanCriteria.Name = this.shareDealDto.DealName;
          this.newSearch = false;
          this.showMsg = true;
          this.reloadSearch = true;
          this.loadingData = false;
          this.isAssigned = true;
        })
        .finally(() => {
          delete this.shareDealDto;
          this.loadingData = false;
        });
    }
  }

  cancelSavePopup() {
    this.showSaveResults = null;
    this.loanCriteria.Name = this.searchNameCache;
  }

  showBookMeetingButton() {
    return (
      !this.isLoggedInUser &&
      this.isClient &&
      this.brokerDetail == "" &&
      this.brokerageOrg != null
    );
  }

  bookMeeting() {
    this.$auth
      .getHubspotDeveloperBookMeetingWithSearch()
      .then((hubSpotUrl: string) => {
        window.open(hubSpotUrl);
      });
  }

  sendMessageToBroker(message) {
    this.loadingData = true;
    this.borrowerMessage = null;
    this.organisationService
      .sendBorrowerMessageToSearchBroker(
        this.loanCriteria.Id,
        message,
        this.loanCriteria.DealClients[0].Client.FirstName,
        this.loanCriteria.DealClients[0].Client.LastName,
        this.loanCriteria.DealClients[0].Client.Email,
        this.loanCriteria.ProductType,
        "BRIDGINGRESULTSSCREEN",
      )
      .then((results) => {
        if (results) {
          this.displayMsg = `Message has been sent successfully.`;
          this.showMessageToborrower = true;
        } else {
          this.displayMsg = `There is problem sending a message, Please try later.`;
          this.showMessageToborrower = true;
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  closeContactBrokerModal() {
    this.showContactBrokerModal = false;
    this.showMessageToborrower = false;
    this.displayMsg = null;
  }

  getTotalShortlistedLenders() {
    var activeDealLenders = this.comparisonList.filter(
      (al) =>
        al.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        al.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );
    var hiddenProduct = this.HiddenDealLenderList.filter(hl => hl.Status != CaseLenderStateEnum.Rejected && hl.Status != CaseLenderStateEnum.Withdrawn);
    var hiddenProductLength = this.isShortlistingMore ? hiddenProduct.length : 0;

    return activeDealLenders.length + hiddenProductLength;
  }

  isLendersFiltered(): boolean {
    //If the search is empty return true
    if (!this.summarySearchResults && this.caseLenders) return true;

    if (!this.caseLenders) return false;

    //For all products that have been submitted for DIP and active, check if in the search
    return !this.caseLenders.every((lender) => {
      if (
        lender.CaseLenderState !== CaseLenderStateEnum.Withdrawn &&
        lender.CaseLenderState !== CaseLenderStateEnum.Rejected
      ) {
        const match = this.summarySearchResults.some(
          (summarySearchResult) =>
            lender.ProductID === summarySearchResult.ProductID,
        );
        if (!match) {
          if (this.filterLenderNames.indexOf(lender.LenderName) === -1) {
            this.filterLenderNames.push(lender.LenderName);
          }
        }
        return match;
      }
      return true;
    });
  }

  getLenderNames(): string {
    if (this.filterLenderNames.length === 1) {
      return "lender " + this.filterLenderNames[0];
    } else {
      return "lenders " + this.filterLenderNames.join(", ");
    }
  }

  viewClientsDashboardForNewSearch(userName: string) {
    this.loadingData = true;
    this.shareDealDto.IsApplicant = true;
    this.shareDealDto.IsPrimary = true;
    //Look up client's account details
    this.$user.searchByEmail(userName).then((users) => {
      this.shareDealDto.ClientDto.FirstName = users[0].FirstName;
      this.shareDealDto.ClientDto.Email = users[0].Email;
      this.shareDealDto.ClientDto.LastName = users[0].LastName;
      this.shareDealDto.ClientDto.PhoneNumber = users[0].PhoneNumber;
      this.shareDealDto.ClientDto.ClientUserId = users[0].Id;
      this.showClientDetails = true;
      this.loadingData = false;
    });
  }

  returnToCase() {
    if (this.isShortlistingMore) {
      // copy newly selected products/lenders to caselender
      this.dealLenderService
        .shortlistMoreLenders(
          this.loanCriteria.Id,
          this.getDealLenders(this.comparisonList),
          this.hasSearchChanged,
        )
        .then((response) => {
          if (response) {
            //sending 'Promote' as true if search has changed & 'NewShortlist' as true if isShortlistingMore
            this.$location.path(
              "/bridgingcasedashboard/" +
              this.loanCriteria.Id +
              "/" +
              null +
              "/" +
              true +
              "/" +
              this.hasSearchChanged,
            );
          }
        });
    } else {
      this.dealLenderService
        .updateShortlistedLenders(this.getDealLenders(this.comparisonList))
        .then((response) => {
          this.$location.path("/bridgingcasedashboard/" + this.loanCriteria.Id);
        });
    }
  }

  updateSearchViaSlider(isAdminDebugValueChanged: boolean = false): void {
    if (this.searchPanelForm.$valid && !this.showPostcodeErrorMessage) {
      if (
        this.loanCriteria.ProductType !=
        ProductTypeEnum.BridgingDeveloperExit &&
        !(
          this.loanCriteria.OwnOrPurchase == OwnOrPurchaseEnum.Own &&
          this.loanCriteria.ProductType ==
          ProductTypeEnum.BridgingPurchaseOrRefinance
        ) &&
        !(
          this.loanCriteria.OwnOrPurchase == 1 &&
          this.loanCriteria.ProductType == ProductTypeEnum.BridgingRefurb
        )
      ) {
        if (this.loanCriteria.PurchasePrice <= 0) return;
      }

      if (
        this.loanCriteria.ProductType ==
        ProductTypeEnum.BridgingDeveloperExit ||
        (this.loanCriteria.OwnOrPurchase == OwnOrPurchaseEnum.Own &&
          this.loanCriteria.ProductType ==
          ProductTypeEnum.BridgingPurchaseOrRefinance) ||
        (this.loanCriteria.OwnOrPurchase == 1 &&
          this.loanCriteria.ProductType == ProductTypeEnum.BridgingRefurb)
      ) {
        if (this.loanCriteria.Currentvalue <= 0) return;
      }

      if (this.loanCriteria.ProductType == ProductTypeEnum.BridgingRefurb) {
        if (this.loanCriteria.GDV <= 0) return;
      }
      this.loadingData = true;

      this.hasSearchChanged = true;

      this.clearSelected();

      this.updateCriteriaAndGetResults(
        isAdminDebugValueChanged && this.isAdmin ? false : true,
      );
    }
  }

  //Clears the client data on share search modal
  clearInputFields() {
    this.shareDealDto.ClientDto.FirstName = null;
    this.shareDealDto.ClientDto.LastName = null;
    this.shareDealDto.ClientDto.Email = null;
    this.shareDealDto.ClientDto.PhoneNumber = null;
    this.shareDealDto.IsApplicant = true;
    this.shareDealDto.IsPrimary = true;
    this.showClientDetails = false;
    this.selecteduserName = null;
  }

  /** Redirects to the user dashboard */
  goToUserDashboard() {
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  getTubeMapValue() {
    return this.$CaseService.getTubeMap();
  }

  updateLoanRequiredValue() {
    this.loanCriteria.MaxLoanRequired = this.isMaximumLoanRequired;

    if (this.isMaximumLoanRequired) {
      if (this.loanCriteria.NetLoanRequired) {
        this.tempLoanRequired = this.loanCriteria.NetLoanRequired;
      }
      this.loanCriteria.NetLoanRequired = 0;
      this.updateSearchViaSlider();
    } else {
      this.loanCriteria.NetLoanRequired = this.tempLoanRequired;
      this.updateSearchViaSlider();
    }
  }

  calculateMonthsSinceOrigPurchase() {
    let date1 = new Date(this.loanCriteria.OriginalPurchaseDate);
    let date2 = new Date();
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months;
  }

  datasetupOnEndPropertyTypeChange() {
    this.updateSearchViaSlider();
  }

  logPdfDownload(eventName) {
    this.eventLogService.logEvent(
      eventName,
      EventLogEnum.Export,
      this.loanCriteria.ProductType,
      this.loanCriteria.Id,
    );
  }

  /* saveEnterpriseClientAndRenameDeal() {
            this.loadingData = true;

            this.userService.checkEmailExists(this.user.Email).then((response) => {
                if (!response) {
                    let userFullName = this.user.FullName;
                    let spaceIndex = userFullName.indexOf(' ');

                    let firstName = '';
                    let lastName = '';

                    // if not space has been added to the name then put the whole name in the first name field
                    if (spaceIndex == -1) {
                        firstName = userFullName;
                    } else {
                        firstName = userFullName.substring(0, userFullName.indexOf(' '));
                        lastName = userFullName.substring(userFullName.indexOf(' ') + 1);
                    }

                    // Set up Client dto
                    var clientDto = {
                        FirstName: firstName,
                        LastName: lastName,
                        PhoneNumber: this.user.PhoneNumber,
                        Email: this.user.Email,
                        BrokerOrganisationId: this.brokerageOrg.Id,
                        PostalAddress: {
                            AddressLine1: '',
                            AddressLine2: '',
                            AddressLine3: '',
                            AddressLine4: '',
                            PostCode: ''
                        } as PostalAddress
                    } as ClientDTO;



                    var currentDate = new Date();
                    this.loanCriteria.Name = this.projectName ? this.projectName : firstName + ' ' + lastName + ' Loan Search ' + currentDate.getDate() + "/" + (currentDate.getMonth() + 1) + "/" + currentDate.getFullYear()

                    var request: SaveEnterpriseClientAndRenameSearchRequest = {
                       // BridgingDealDto: this.loanCriteria,
                        ClientDto: clientDto,
                        //ProductFamily: ProductFamilyEnum.Bridging,
                        CommercialDealDto: null,
                        DevFinanceDealDto: null
                    }

                    this.dealService.saveEnterpriseClientAndRenameDeal(request).then((response) => {
                        if (response) {
                            document.getElementById('body').style.overflow = "auto";
                            this.showEnterpriseRegistrationModal = false;
                            this.loanCriteria = response.BridgingDealDto;
                            if (window.self == window.top) {
                                sessionStorage.setItem('clientId', this.loanCriteria.DealClients[0].Client.Id.toString());
                            } else {
                                this.organisationService.sendDataToParent('clientId', this.loanCriteria.DealClients[0].Client.Id.toString());
                            }

                            this.userService.sendEventToHubspot(this.user, "ENTERPRISE-CONTACT-REGISTERED", false, this.orgCode, this.userRole, this.loanCriteria && this.loanCriteria.OrganisationLinkId != null ? this.loanCriteria.OrganisationLinkId : 0, this.loanCriteria.ProductType, this.loanCriteria ? this.loanCriteria.Id : 0);

                            // For Google analytics tracking
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                'event': 'results_gating_form',
                                'loan_product_family': this.productService.getProductFamilyTextForGa(this.loanCriteria.ProductFamily),
                                'loan_product_type': this.productService.getProductTypeTextForGa(this.loanCriteria.ProductType, this.loanCriteria.IsFamilyInResidence)
                            });
                        }
                        
                    }).finally(() => {
                        this.loadingData = false;
                    });
                } else {
                    this.emailExistsError = true;
                    this.loadingData = false;
                }
            });

           
        }*/

  gotoSignInPage() {
    this.$location.path("/login");
  }

  goToLink(url) {
    var baseUrl = window.location.href.split("#!")[0] + "#!";
    var newUrl = baseUrl + url;
    window.open(newUrl, "_blank");
  }

  toggleShowLenderNamesBrokerOverride() {
    // this.loanCriteria.ShowLenderInfoBrokerOverride = !this.loanCriteria.ShowLenderInfoBrokerOverride;
    if (this.searchPanelForm.$valid && !this.showPostcodeErrorMessage) {
      this.updateCriteriaAndGetResults(false, true);
    }
  }

  getAppName() {
    let lsd = null;
    if (window.self == window.top)
      lsd = sessionStorage.getItem("applicationName");
    if (lsd != null) {
      this.appName = lsd;
    } else {
      this.appName = "Brickflow";
    }
  }

  onSelectingExportShortlist(eventName) {
    let comparisonList = this.comparisonList.filter(
      (cl) =>
        cl.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        cl.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );
    this.loanCriteria.ComparisonList = comparisonList;
    this.eventLogService.logEvent(
      eventName,
      EventLogEnum.Export,
      this.loanCriteria.ProductType,
      this.loanCriteria.Id,
    );
  }

  showContactBrokerAndExportButton() {
    if (
      this.brokerageOrg != null &&
      this.loanCriteria != null &&
      this.loanCriteria.DealClients != null &&
      this.loanCriteria.DealClients.length > 0 &&
      this.loanCriteria.DealClients[0]?.Client != null &&
      this.loanCriteria.DealClients[0].Client.Email.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  //This is used in shortlisting pdf
  prepareDataForShortlistPdf() {
    this.loanCriteria.ComparisonList = this.comparisonList.filter(
      (cl) =>
        cl.DealLenderStatus != CaseLenderStateEnum.Rejected &&
        cl.DealLenderStatus != CaseLenderStateEnum.Withdrawn,
    );
  }

  getRegionByPostcode() {
    if (
      this.loanCriteria.PostcodeSearchString &&
      this.loanCriteria.PostcodeSearchString.replace(" ", "").length >= 2
    ) {
      if (
        this.dealService.isValidPostcodeString(
          this.loanCriteria.PostcodeSearchString,
        )
      ) {
        this.showPostcodeErrorMessage = false;
        this.isPostcodeChange = true;
        this.updateSearchViaSlider();
      } else {
        this.postcodeErrorMsg =
          "At least one letter and one number must be entered e.g. SW1.";
        this.showPostcodeErrorMessage = true;
      }
    }
  }

  getOrganisationAndBrokerDetails() {
    this.loadingData = true;
    if (!this.isLoggedInUser && !this.isAdmin && !this.isBroker) {
      this.loadingData = true;
      this.organisationService
        .getOrganisationAndBrokerByDealId(this.loanCriteria.Id)
        .then((response) => {
          if (response && response.Organisation != null) {
            this.brokerageOrg = response.Organisation;
          }
        })
        .finally(() => {
          this.loadingData = false;
        });
    }
  }

  sortByField(field) {
    this.loanCriteria.SortBy = field;
    this.updateCriteriaAndGetResults(false, false);
    this.showSortBy = !this.showSortBy;
  }

  sortSummaryResults() {
    switch (this.loanCriteria.SortBy) {
      case SortByEnum.TrueMonthlyCost:
        this.summarySearchResults.sort(
          (a, b) => a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.NetLoanSize:
        this.summarySearchResults.sort(
          (a, b) =>
            b.NetLoan - a.NetLoan || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.LenderCosts:
        this.summarySearchResults.sort(
          (a, b) =>
            a.LenderCosts - b.LenderCosts ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.LenderName:
        this.summarySearchResults.sort(
          (a, b) =>
            a.LenderName.localeCompare(b.LenderName) ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.InterestRate:
        this.summarySearchResults.sort(
          (a, b) =>
            a.AnnualInterest - b.AnnualInterest ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.ArrangementFee:
        this.summarySearchResults.sort(
          (a, b) =>
            a.ArrangementFee - b.ArrangementFee ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.DepositEquity:
        this.summarySearchResults.sort(
          (a, b) =>
            a.EquityOrDeposit - b.EquityOrDeposit ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      default:
        this.summarySearchResults.sort((a, b) => {
          if (a.NetLoan < b.NetLoan) return 1;
          if (a.NetLoan > b.NetLoan) return -1;

          if (a.TrueMonthlyCost > b.TrueMonthlyCost) return 1;
          if (a.TrueMonthlyCost < b.TrueMonthlyCost) return -1;

          return 0;
        });
        this.loanCriteria.SortBy = SortByEnum.NetLoanSize;
    }

    for (var i = 0; i < this.summarySearchResults.length; i++) {
      this.summarySearchResults[i].SelectedRowNumber = i + 1;
    }
  }

  isFieldZero(fieldName) {
    if (this.loanCriteria) {
      const fieldValue = this.loanCriteria[fieldName];
      if (fieldValue == null || fieldValue === "0" || fieldValue === 0) {
        return true;
      }
    }

    return false;
  }

  submitLenderReferral() {
    this.loadingData = true;
    this.lenderReferralData.DealId = this.loanCriteria.Id;
    this.dealClientService
      .submitLenderReferral(this.lenderReferralData)
      .then((response) => {
        if (response) {
          this.displayMsg = "Your referral has been submitted.";
          this.showMsg = true;
          this.isLenderReferredSearch = true;
        } else {
          this.displayMsg = "Error while making a referral, please try later.";
          this.showMsg = true;
        }
      })
      .finally(() => {
        this.loadingData = false;
        this.lenderReferralData = null;
        sessionStorage.removeItem("lenderReferralData");
      });
  }

  showBasedOnMainResidence() {
    return this.dealService.showBasedOnMainResidence(this.loanCriteria);
  }

  reloadPage() {
    if (this.reloadSearch) {
      window.location.reload();
    }
  }

  openRegisterModal() {

    this.showRegisterModal = true;

  }

  onEditSearchNameClick() {
    this.toggleEditSearchName = !this.toggleEditSearchName;
  }


  onSortbyClick() {
    //event.stopPropagation();
    this.closeFilterOptions();
    this.closeExportDropdown();
    this.showSortBy = !this.showSortBy;
  }

  onFilterClick() {
    // event.stopPropagation();
    this.closeSortByDropdown();
    this.closeExportDropdown();
    this.showFilters = !this.showFilters;
  }

  onExportClick() {
    this.prepareDataForShortlistPdf();
    this.closeSortByDropdown();
    this.closeFilterOptions();
    this.showExportOptions = !this.showExportOptions;
  }

  getSelectedSortByOptionText() {
    if (this.loanCriteria) {
      switch (this.loanCriteria.SortBy) {
        case SortByEnum.TrueMonthlyCost:
          return "True Monthly Cost";
        case SortByEnum.NetLoanSize:
          return "Net Loan Size";
        case SortByEnum.LenderCosts:
          return "Lender Costs (Est.)";
        case SortByEnum.LenderName:
          return "Lender Name (A-Z)";
        case SortByEnum.InterestRate:
          return "Interest Rate";
        case SortByEnum.ArrangementFee:
          return "Arrangement Fee";
        case SortByEnum.DepositEquity:
          return "Est. Deposit/Equity";
        case SortByEnum.ROCE:
          return "ROCE";
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  createAccount() {
    this.dataLoading = true;
    this.enterpriseClient.OrgUniqueRef = this.brokerageOrg.UniqueRef;
    this.$user
      .registerEnterpriseUserAndSaveShortlisteResults(this.enterpriseClient, this.getDealLenders(this.comparisonList))
      .then((user) => {
        // clean up session storage
        if (window.self == window.top) {
          sessionStorage.removeItem("clientId");
          sessionStorage.removeItem("userRole");
        } else {
          this.organisationService.sendDataToParent("clientId", "");
          this.organisationService.sendDataToParent("userRole", "");
        }
        this.login("ENTERPRISE-LEAD-REGISTERED-FROM-RESULTS-PAGE", EventLogEnum.EnterpriseUserRegistration);
      })
      .catch((response) => {
        this.error = "Error while registering an account, please try again later.";
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  login(eventIdentifier: string = "ENTERPRISE-CONTACT-LOGGEDIN-FROM-RESULTS-PAGE", eventType: EventLogEnum = EventLogEnum.EnterpriseUserLogin) {
    this.dataLoading = true;
    if (!this.$cookies.get("access_token")) {
      this.$auth
        .login(
          this.enterpriseClient.UserName,
          this.enterpriseClient.Password,
          "CC022EBE67BE0424EA2A6548B062D2D71",
        )
        .then((response) => {
          this.error ="";
          let expiry: Date = response;

          this.$user.getcurentuserrecord().then((response) => {
            this.eventLogService.logEvent(
              eventIdentifier,
              eventType,
              this.loanCriteria.ProductType,
              this.loanCriteria.Id,
              "",
              "",
              this.loanCriteria.DealClients[0]?.Client.Id?.toString()
            );

            (this.$rootScope as any).currentUser = response;
            this.$cookies.put("user_firstname", response.FirstName, {
              expires: expiry,
            });
            (this.$rootScope as any).selectedUser = response;
            this.$rootScope.$broadcast("login"); //tell everyone you have logged in
            if (eventType == EventLogEnum.EnterpriseUserRegistration) sessionStorage.setItem('showConfirmClientPhoneNo', 'true');
            this.$location.path(`/bridgingresults/${this.loanCriteria.Id}`);
          });
        }).catch((response) => {
          this.isLoginError  = true;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  onClickLogin() {
    this.saveShortlistedLenders();
    this.login();
  }

  saveShortlistedLenders() {
    var shortlistDealLenders = this.getDealLenders(this.comparisonList);
    this.dealLenderService
      .updateShortlistedLenders(shortlistDealLenders)
      .then((response) => { });
  }


  returnShortlistedDealLenderStatusText(item: BridgingLenderResultSummaryDTO) {
    if (this.checkDeletedLender(item.LenderID.toString(), item.ProductID.toString())) {
      return "Previously Deleted";
    }

    if (item.IsDealLender && (this.loanCriteria.Status != CaseStatusEnum.Search && this.loanCriteria.Status != CaseStatusEnum.NewCase && this.loanCriteria.Status != CaseStatusEnum.ReadyToSubmit)) {

      switch (item.DealLenderStatus) {
        case CaseLenderStateEnum.Shortlisted:
          return "Shortlisted";
        case CaseLenderStateEnum.Received:
          return "Awaiting DIP";
        case CaseLenderStateEnum.Offered:
          return "DIP Received";
        case CaseLenderStateEnum.Rejected:
          return "Lender Rejected";
        case CaseLenderStateEnum.Withdrawn:
          return "Lender Withdrawn";
        default:
          break
      }
    }
  }

  isProceedButtonDisabled(): boolean {
    if (this.validateAllLendersUnique(this.comparisonList) == false) {
      return true;
    }

    const validLenders = this.comparisonList?.filter(
      (item) =>
        item.DealLenderStatus !== CaseLenderStateEnum.Rejected &&
        item.DealLenderStatus !== CaseLenderStateEnum.Withdrawn
    ) || [];
  
    if (
      !validLenders ||
      validLenders.length < 1 ||
      validLenders.length > this.noOfShortlistAllowed ||
      this.isProceedClicked == true
    ) {
      return true;
    }

    return false;
  }

  onSaveClick() {
    if (this.isAssigned) {
      this.isSaveorSaveAsClicked = true;
      this.register(false, this.productResultList);
    } else {
      this.showShareSearchModal();
    }
  }

  getProductLastVerifiedDates(productIds: number[]) {
    this.productService.getLastVerifiedDates(productIds).then((response) => {
      productIds.forEach(id => {
        if (response[id] != null) {
          this.productLastVerifiedDates[id] = this.formatDate(response[id]);
        }
      })
    });
  }

  formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };


  validateAllLendersUnique(
    comparisonList: BridgingLenderResultSummaryDTO[],
  ): boolean {

    if (this.isShortlistingMore) {
      const matchingItems = this.HiddenDealLenderList.filter(item1 =>
        this.comparisonList.some(item2 => item1.LenderId === item2.LenderID)
      );

      if (matchingItems.length > 0) return false;

      const numberOfUniqueLenderIds: number = new Set(
        this.comparisonList.map((item) => item.LenderID),
      ).size;

      return numberOfUniqueLenderIds == this.comparisonList.length;
    }

    return true;

  }

  /**Closes all context menus */
  closeContextMenus() {
    var myevent = event;
    this.closeFilterOptions();
    this.closeSortByDropdown();
  }

  closeFilterOptions() {
    this.showFilters = false;
  }

  closeSortByDropdown() {
    this.showSortBy = false;
  }

  closeExportDropdown() {
    this.showExportOptions = false;
  }

  goToNewSearch() {
    this.sharedDataService.cleanLenderReferralSessionStorage();
    // If the current user is a broker and doesn't have a subscription then don't allow them to create a new search
    if (this.isBroker && !this.selectedUser.HasActiveSubscription) {
      return;
    }

    if (!this.loadingData) {
      (this.$rootScope as any).loanCriteria = null;

      this.$location.path("/allloans");
    }
  }

  updateClientContactInfoAndNotifyBroker(){
      if(this.loanCriteria !=null && this.loanCriteria.DealClients != null){
        this.dealClientService.UpdateClientContactInfoAndNotifyBroker(this.clientUpdatedContact, this.loanCriteria?.Id,this.loanCriteria.DealClients[0].Client.Id).then((response) => {
          this.showClientPhoneNumberConfirmationModal = false;
          this.sliderShown = true;
        }).catch((response) => {
          this.error = "There was an error updating the contact. Please try again later."
        })
        .finally(() => {
          sessionStorage.removeItem('showConfirmClientPhoneNo');
        });
    } else {
      sessionStorage.removeItem('showConfirmClientPhoneNo');
      this.showClientPhoneNumberConfirmationModal = false;
      this.sliderShown = true;
    }
  }

  getOrgName(){
    if(this.orgName){
      return this.orgName.replace(/_/g, " ");
    }
    return "";
  }

  /**Sends an email to the email address for the user to reset their password */
  sendResetPasswordEmail() {
    this.loadingData = true;
    this.$auth
      .resetPassword(this.enterpriseClient.Email)
      .then((response) => {
        this.isLoginError = false;
        this.isResetPasswordSubmitted = true;
        this.registrationLoginError = null;
      })
      .catch((error) => {
        this.registrationLoginError = "There was an error sending the password reset email. Please try again later.";
      })
      .finally(() => {
        this.loadingData = false;
      });
  }
}

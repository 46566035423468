import { FeedBackDTO } from "@js/DTO/FeedBackDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { FeedBackService } from "@js/services/FeedBackService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { OrganisationService } from "@js/services/OrganisationService";
import { RoleService } from "@js/services/RoleService";

export class FeedBackController {
  static $inject = [
    "$rootScope",
    "$routeParams",
    "$location",
    "FeedBackService",
    "OrganisationService",
    "FileAttachmentService",
    "RoleService",
  ];

  feedBackDTO: FeedBackDTO;
  organisation: OrganisationDTO;
  fullAvatarUrl: string = "";
  showReplySection: boolean = false;
  dataLoading: boolean = false;
  isDeal: boolean = true;
  reviewerFullName: string;
  reviewerOrganisationName: string;
  reviewerOrganisationUrl: string;
  reviewerProfileUrl: string;
  reviewerInitil: string;
  responseUserOrgName: string;
  dealName: string;
  isLender: boolean;
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private feedBackService: FeedBackService,
    private organisationService: OrganisationService,
    private fileAttachmentService: FileAttachmentService,
    private roleService: RoleService,
  ) {
    if (this.$location.path().startsWith("/casefeedback")) {
      this.isDeal = false;
    }

    this.feedBackService
      .getFeedBackAndReviewerDetails(
        Number(this.$routeParams.FeedBackId),
        Number(this.$routeParams.DealLenderId),
        this.isDeal,
      )
      .then((response) => {
        this.feedBackDTO = response.feedBackDTO;
        this.reviewerFullName = response.ReviewerFullName;
        this.reviewerOrganisationName = response.ReviewerOrganisationName;
        this.reviewerOrganisationUrl = response.ReviewerOrganisationUrl;
        this.reviewerProfileUrl = response.ReviewerProfileUrl;
        this.reviewerInitil = response.ReviewerInitil;
        this.responseUserOrgName = response.ResponseUserOrgName;
        this.dealName = response.DealName;
        this.checkingIfUserIsLender();
      });
  }

  checkingIfUserIsLender() {
    this.roleService.isLenderUser().then((response) => {
      this.isLender = response;
    });
  }

  submitReplyToFeedBack() {
    this.dataLoading = true;
    this.feedBackService
      .sendFeedBackReply(
        this.feedBackDTO,
        Number(this.$routeParams.DealLenderId),
        this.isDeal,
      )
      .then((response) => {
        this.$location.path("/userdashboard");
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  /** Redirects to the user dashboard */
  goToUserDashboard() {
    this.$location.path("/userdashboard");
  }
}

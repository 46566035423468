export const enum DealSourceTypeEnum {
  Unknown = 0,
  Application = 1,
  WhitelabelApplication = 2,
  IFrameEnterprise = 3,
  IFrameUniqueLink = 4,
  UniqueLink = 5,
  Enterprise = 6,
  Api = 7,
  WidgetUniqueLink = 8,
  WidgetEnterprise = 9,
}

import angular from "angular";
import { UserService } from "./services/UserService";
import { LicenseMasterStatusEnum } from "./models/enum/LicenseMasterStatusEnum.cs.d";

interface RequiredOption {
  name: string;
  disable: boolean;
  hide: boolean;
}

export function registerRequiresAllDirective() {
  const ccqdirectives = angular.module("Ccq.directives");

  ccqdirectives.directive("requiresAll", [
    "UserService",
    "$rootScope",
    "$cookies",
    (
      userService: UserService,
      $rootScope: ng.IRootScopeService,
      $cookies: ng.cookies.ICookiesService,
    ) => {
      return {
        restrict: "A",
        controller: function ($scope, $element, $attrs) {
          $scope.userService = userService;
          //console.log($rootScope)
        },
        scope: false,
        link: function (
          scope: angular.IScope,
          element: JQLite,
          attr: angular.IAttributes,
          ctrls: angular.IController[],
        ) {
          const el: HTMLElement = angular.element(element)[0];
          const $rootScope = scope.$root;
          const $userService = (scope as any)?.userService;

          const statusRequirementId =
            ($rootScope as any)?.statusRequirementId ?? null;
          const statusRequirementEventName =
            (ctrls[0] as any)?.statusRequirementEventName ?? null;

          // dictate behaviour if requirement not met ( || default values if not provided)
          const hideIfNotInState: Boolean =
            scope.$parent.$eval(attr.hideIfNotInState) || false;
          const disableIfNotInRole: Boolean =
            scope.$parent.$eval(attr.disableIfNotInRole) || false;
          const matchAllRoles: Boolean =
            scope.$parent.$eval(attr.matchAllRoles) || false;
          const disableIfNotInFeatures: Boolean =
            scope.$parent.$eval(attr.disableIfNotInFeatures) || false;
          const hideIfInvalidSubscription: Boolean =
            scope.$parent.$eval(attr.hideIfInvalidSubscription) || false;

          // requirements are fetched from the elements attributes
          const requiredStates: string[] | string = scope.$parent.$eval(
            attr.requiredStates,
          );
          const requiredRoles: string[] | string = scope.$parent.$eval(
            attr.requiredRoles,
          );
          const requiredFeatures: string[] | string = scope.$parent.$eval(
            attr.requiredFeatures,
          );

          // providing 'required features' will override value if set to false or not provided
          // in order to check features there must be a valid license
          const requiresActiveSubscription: Boolean =
            scope.$parent.$eval(attr.requiresActiveSubscription) ||
            !!requiredFeatures;
          const allowIfInvited: Boolean =
            scope.$parent.$eval(attr.allowIfInvited) || false;

          //override options - allows specific outcomes to be set for specific values, overuling the required... values and default behaviour
          const requiredRolesOptions: RequiredOption[] = scope.$parent.$eval(
            attr.requiredRolesOptions,
          );
          const requiredStatesOptions: RequiredOption[] = scope.$parent.$eval(
            attr.requiredStatesOptions,
          );
          const requiredFeaturesOptions: RequiredOption[] = scope.$parent.$eval(
            attr.requiredFeaturesOptions,
          );

          // define callback scope with initial values
          // required for async functions to use values after function has ended and values are destroyed
          var callbackScope = {
            parentScope: scope,
            requiredStates: requiredStates,
            requiredStatesOptions: requiredStatesOptions,
            statusRequirementId: statusRequirementId,
            requiredRoles: requiredRoles,
            requiredRolesOptions: requiredRolesOptions,
            requiredFeatures: requiredFeatures,
            requiredFeaturesOptions: requiredFeaturesOptions,
            requiresActiveSubscription: requiresActiveSubscription,
          };

          // if the status requirement id changes, we update the value in callbackScope
          $rootScope.$watch(
            "statusRequirementId",
            function () {
              this.callbackScope.statusRequirementId = ($rootScope as any)
                ?.statusRequirementId;
              if (!!this.callbackScope.statusRequirementId) {
                let roles =
                  (($rootScope as any)?.currentUser?.Roles as string[]) ?? null;
                let status =
                  this.callbackScope.statusRequirementId > 0
                    ? (($rootScope as any)?.caseStateChange[
                        this.callbackScope.statusRequirementId
                      ] as string)
                    : null;
                let features =
                  ($rootScope as any)?.currentUser?.Features ?? null;
                let subscriptionStatus =
                  (($rootScope as any)?.currentUser
                    ?.SubscriptionStatus as number) ?? null;

                updateAll(
                  this.callbackScope.requiredStates,
                  status,
                  this.callbackScope.requiredStatesOptions,
                  this.callbackScope.requiredRoles,
                  roles,
                  this.callbackScope.requiredRolesOptions,
                  this.callbackScope.requiredFeatures,
                  features,
                  this.callbackScope.requiredFeaturesOptions,
                  this.callbackScope.requiresActiveSubscription,
                  subscriptionStatus,
                  ($rootScope as any)?.isInvitedToCase as boolean,
                  ($rootScope as any)?.isInvitedAndHasReadonlyAccess as boolean,
                );
              }
            }.bind({ callbackScope: callbackScope }),
          );

          const originalStyles = {
            cursor: el.style.cursor,
            pointerEvents: el.style.pointerEvents,
            opacity: el.style.opacity,
            display: el.style.display,
          };

          //Styling Functions
          const disable = () => {
            el.style.cursor = "not-allowed";
            el.style.pointerEvents = "none";
            el.style.opacity = "0.8";
          };
          const hide = () => {
            el.style.display = "none";
          };
          const enable = () => {
            el.style.cursor = originalStyles.cursor;
            el.style.pointerEvents = originalStyles.pointerEvents;
            el.style.opacity = originalStyles.opacity;
          };
          const show = () => {
            el.style.display = originalStyles.display;
          };
          const applyStyle = (showElement: boolean, enableElement: boolean) => {
            if (showElement) {
              show();
              if (enableElement) {
                enable();
              } else {
                disable();
              }
            } else {
              hide();
            }
          };

          //Helper Functions
          const isLenderOrAdmin = (roles: string[]): boolean => {
            if (!roles) return false;
            let res = roles.find(
              (role) =>
                role.toLowerCase() === "lender" ||
                role.toLowerCase() === "admin",
            );
            return !!res;
          };

          const checkRolesMatch = (
            currentRoles: string[],
            requiredRoles: string[],
            matchAllRoles: Boolean,
          ): boolean => {
            let roleMatch = false;
            // set rolematch based on requiredRoles, currentRoles and matchAllRoles
            for (let i = 0; i < requiredRoles.length; i++) {
              var matchingGroup = currentRoles.find(
                (group) =>
                  group.toLowerCase() === requiredRoles[i].toLowerCase(),
              );
              if (matchingGroup && !matchAllRoles) {
                roleMatch = true;
                break;
              } else if (matchingGroup && matchAllRoles) {
                roleMatch = true;
              } // needed since we start rolematch as false
              else if (!matchingGroup && matchAllRoles) {
                roleMatch = false;
                break;
              }
            }

            return roleMatch;
          };

          const checkFeaturesMatch = (
            currentFeatures: string[],
            requiredFeatures: string[],
          ): boolean => {
            let matchingFeatures = false;
            for (let i = 0; i < requiredFeatures.length; i++) {
              let match = currentFeatures.find(
                (feature) =>
                  feature.toLowerCase() === requiredFeatures[i].toLowerCase(),
              );
              if (!!match) {
                matchingFeatures = true;
                break;
              }
            }
            return matchingFeatures;
          };

          const checkCaseStateMatch = (
            currentState: string,
            requiredStates: string[],
          ): boolean => {
            let matchingState = requiredStates.find(
              (state) => state.toLowerCase() === currentState.toLowerCase(),
            );
            let stateMatch = !!matchingState;
            return stateMatch;
          };

          // Main function containing logic
          const updateAll = (
            requiredStates: string[] | string,
            currentState: string,
            requiredStatesOptions: RequiredOption[],
            requiredRoles: string[] | string,
            currentRoles: string[],
            requiredRolesOptions: RequiredOption[],
            requiredFeatures: string[] | string,
            currentFeatures: string[],
            requiredFeaturesOptions: RequiredOption[],
            requiresActiveSubscription: Boolean,
            subscriptionStatus: number,
            isInvited: Boolean,
            isInvitedAndHasReadonlyAccess: Boolean,
          ): void => {
            if (typeof requiredStates === "string") {
              requiredStates = [requiredStates];
            }
            if (typeof requiredRoles === "string") {
              requiredRoles = [requiredRoles];
            }
            if (typeof requiredFeatures === "string") {
              requiredFeatures = [requiredFeatures] as string[];
            }

            //defaults
            disable();
            hide();

            var showState = false;
            var enableState = false;
            //maintain state within each criteria - needed for override options
            var showTmp = showState;
            var enableTmp = enableState;

            /* Order or Priority
                        - subscription status
                        - feature access
                        - correct role
                        - correct case status
    
                       Exceptions
                       - invited users don't require an active subscription
                       - admins and lenders don't need active subscriptions
    
                       Required options
                       - can only override changes made by respective criteria
                        - requiredRoleOption cannot enable a button is invalid subscription status has disabled it
                     */

            if (
              (isLenderOrAdmin(currentRoles) && requiresActiveSubscription) ||
              (allowIfInvited == true && isInvited == true)
            ) {
              showTmp = showState = true;
              enableTmp = enableState = true;
            }
            // check if active subscription required
            else if (requiresActiveSubscription) {
              const activeSubscription =
                subscriptionStatus == LicenseMasterStatusEnum.PaidUp ||
                subscriptionStatus ==
                  LicenseMasterStatusEnum.PaymentProcessing ||
                subscriptionStatus == LicenseMasterStatusEnum.PreCancel;

              // valid subscription
              if (activeSubscription) {
                showTmp = true;
                enableTmp = true;
              }
              // invalid subscription
              else {
                if (hideIfInvalidSubscription) {
                  showTmp = false;
                } else {
                  showTmp = true;
                  enableTmp = false;
                }
              }

              showState = showTmp;
              enableState = enableTmp;
              //stop eval if hidden
              if (!showState) {
                hide();
                return;
              }

              if (requiredFeatures && currentFeatures) {
                const matchingFeatures = checkFeaturesMatch(
                  currentFeatures,
                  requiredFeatures as string[],
                );

                if (!matchingFeatures) {
                  if (disableIfNotInFeatures && showState && enableState) {
                    //only disable if it hasn't been hidden or disabled by inactive subscription
                    enableTmp = false;
                  } else {
                    showTmp = false;
                  }
                }
                //right feature is same as result from subscription so no need to disable/hide again

                if (currentFeatures && requiredFeaturesOptions) {
                  for (let i = 0; i < requiredFeaturesOptions.length; i++) {
                    const option = requiredFeaturesOptions[i];
                    const matchingGroup = currentFeatures.find(
                      (group) =>
                        group.toLowerCase() === option.name.toLowerCase(),
                    );

                    if (!!matchingGroup) {
                      // don't change if been disabled by previous requirement
                      if (enableState == true) {
                        if (option.disable) {
                          disable();
                          show();
                          showTmp = true;
                          enableTmp = false;
                        } else {
                          enable();
                          show();
                          showTmp = true;
                          enableTmp = true;
                        }
                      }

                      if (option.hide) {
                        showTmp = false;
                      } else {
                        show();
                        showTmp = true;
                      }
                    }
                    break;
                  }
                }
              }

              showState = showTmp;
              enableState = enableTmp;
              //stop eval if hidden
              if (!showState) {
                hide();
                return;
              }
            }

            // At this stage, element is either visible or doesn't require subscription
            // element is not hidden or required roles is most important criteria
            if (requiredRoles && currentRoles) {
              const roleMatch = checkRolesMatch(
                currentRoles,
                requiredRoles,
                matchAllRoles,
              );
              if (!roleMatch) {
                if (disableIfNotInRole) {
                  showTmp = true;
                  enableTmp = false;
                } else {
                  showTmp = false;
                }
              }
              //only allowed to enable if it is the most important criteria so not to override prev changes
              else if (roleMatch && !requiresActiveSubscription) {
                showTmp = true;
                enableTmp = true;
              }
              if (currentRoles && requiredRolesOptions) {
                for (let i = 0; i < requiredRolesOptions.length; i++) {
                  const option = requiredRolesOptions[i];

                  const matchingGroup = currentRoles.find(
                    (group) =>
                      group.toLowerCase() === option.name.toLowerCase(),
                  );

                  if (!!matchingGroup) {
                    if (enableState == true) {
                      if (option.disable) {
                        showTmp = true;
                        enableTmp = false;
                      } else {
                        showTmp = true;
                        enableTmp = true;
                      }
                    }
                    if (option.hide) {
                      showTmp = false;
                    } else {
                      showTmp = true;
                    }
                  }
                  break;
                }
              }

              showState = showTmp;
              enableState = enableTmp;
              if (!showState) {
                hide();
                return;
              }
            }

            // if element is not hidden or state is most important requirement
            if (requiredStates && currentState) {
              let stateMatch = checkCaseStateMatch(
                currentState,
                requiredStates,
              );

              if (!stateMatch) {
                if (hideIfNotInState) {
                  showTmp = false;
                } else {
                  showTmp = true;
                  enableTmp = false;
                }
              }
              //only allowed to enable if it is the most important criteria so not to override prev changes
              else if (
                stateMatch &&
                !(
                  requiresActiveSubscription ||
                  requiredFeatures ||
                  requiredRoles
                )
              ) {
                showTmp = true;
                enableTmp = true;
              }

              if (currentState && requiredStatesOptions) {
                for (let i = 0; i < requiredStatesOptions.length; i++) {
                  let option = requiredStatesOptions[i];
                  let matchingState =
                    option.name.toLowerCase() === currentState.toLowerCase();

                  if (matchingState) {
                    if (enableState == true) {
                      if (option.disable) {
                        showTmp = true;
                        enableTmp = false;
                      } else {
                        showTmp = true;
                        enableTmp = true;
                      }
                    }
                    if (option.hide) {
                      showTmp = false;
                    } else {
                      showTmp = true;
                    }
                  }
                }
              }

              showState = showTmp;
              enableState = enableTmp;
              if (!showState) {
                hide();
                return;
              }
            }

            // apply styling

            if (isInvitedAndHasReadonlyAccess) {
              applyStyle(showState, false);
            } else {
              applyStyle(showState, enableState);
            }
          };

          // test removing event listener
          $rootScope.$on(
            statusRequirementEventName,
            function (event, statusUpdate: { id: number; status: string }) {
              if (statusUpdate.id === this.statusRequirementId) {
                let currentRoles =
                  (($rootScope as any)?.currentUser?.Roles as string[]) ?? null;
                let features =
                  (($rootScope as any)?.currentUser?.Features as string[]) ??
                  null;
                let subscriptionStatus =
                  (($rootScope as any)?.currentUser
                    ?.SubscriptionStatus as number) ?? null;

                updateAll(
                  this.requiredStates,
                  statusUpdate.status,
                  this.requiredStatesOptions,
                  this.requiredRoles,
                  currentRoles,
                  this.requiredRolesOptions,
                  this.requiredFeatures,
                  features,
                  this.requiredFeaturesOptions,
                  this.requiresActiveSubscription,
                  subscriptionStatus,
                  ($rootScope as any)?.isInvitedToCase as boolean,
                  ($rootScope as any)?.isInvitedAndHasReadonlyAccess as boolean,
                );
              }
            }.bind(callbackScope),
          );

          $rootScope.$on(
            "userRolesChange",
            function (event, userRoles: string[]) {
              let status = this.statusRequirementId
                ? (($rootScope as any)?.caseStateChange[
                    this.statusRequirementId
                  ] as string)
                : null;
              let features =
                (($rootScope as any)?.currentUser?.Features as string[]) ??
                null;
              let subscriptionStatus =
                (($rootScope as any)?.currentUser
                  ?.SubscriptionStatus as number) ?? null;

              updateAll(
                this.requiredStates,
                status,
                this.requiredStatesOptions,
                this.requiredRoles,
                userRoles,
                this.requiredRolesOptions,
                this.requiredFeatures,
                features,
                this.requiredFeaturesOptions,
                this.requiresActiveSubscription,
                subscriptionStatus,
                ($rootScope as any)?.isInvitedToCase as boolean,
                ($rootScope as any)?.isInvitedAndHasReadonlyAccess as boolean,
              );
            }.bind(callbackScope),
          );

          $rootScope.$on(
            "invitedStatusChange",
            function () {
              let roles = ($rootScope as any)?.currentUser?.Roles;
              let status = this.statusRequirementId
                ? (($rootScope as any)?.caseStateChange[
                    this.statusRequirementId
                  ] as string)
                : null;
              let features =
                (($rootScope as any)?.currentUser?.Features as string[]) ??
                null;
              let subscriptionStatus =
                (($rootScope as any)?.currentUser
                  ?.SubscriptionStatus as number) ?? null;

              updateAll(
                this.requiredStates,
                status,
                this.requiredStatesOptions,
                this.requiredRoles,
                roles,
                this.requiredRolesOptions,
                this.requiredFeatures,
                features,
                this.requiredFeaturesOptions,
                this.requiresActiveSubscription,
                subscriptionStatus,
                ($rootScope as any)?.isInvitedToCase as boolean,
                ($rootScope as any)?.isInvitedAndHasReadonlyAccess as boolean,
              );
            }.bind(callbackScope),
          );

          if (
            ($rootScope as any)?.currentUser &&
            $cookies.get("access_token")
          ) {
            let roles = ($rootScope as any)?.currentUser?.Roles;
            let status = statusRequirementId
              ? (($rootScope as any)?.caseStateChange[
                  statusRequirementId
                ] as string)
              : null;
            let features =
              (($rootScope as any)?.currentUser?.Features as string[]) ?? null;
            let subscriptionStatus =
              (($rootScope as any)?.currentUser
                ?.SubscriptionStatus as number) ?? null;

            updateAll(
              requiredStates,
              status,
              requiredStatesOptions,
              requiredRoles,
              roles,
              requiredRolesOptions,
              requiredFeatures,
              features,
              requiredFeaturesOptions,
              requiresActiveSubscription,
              subscriptionStatus,
              ($rootScope as any)?.isInvitedToCase as boolean,
              ($rootScope as any)?.isInvitedAndHasReadonlyAccess as boolean,
            );
          } else if ($cookies.get("access_token")) {
            $userService.getcurentuserrecord().then(
              function (response) {
                ($rootScope as any).currentUser = response;

                let roles = ($rootScope as any)?.currentUser?.Roles;
                let status = this.statusRequirementId
                  ? (($rootScope as any)?.caseStateChange[
                      this.statusRequirementId
                    ] as string)
                  : null;
                let features =
                  (($rootScope as any)?.currentUser?.Features as string[]) ??
                  null;
                let subscriptionStatus =
                  (($rootScope as any)?.currentUser
                    ?.SubscriptionStatus as number) ?? null;

                updateAll(
                  this.requiredStates,
                  status,
                  this.requiredStatesOptions,
                  this.requiredRoles,
                  roles,
                  this.requiredRolesOptions,
                  this.requiredFeatures,
                  features,
                  this.requiredFeaturesOptions,
                  this.requiresActiveSubscription,
                  subscriptionStatus,
                  ($rootScope as any)?.isInvitedToCase as boolean,
                  ($rootScope as any)?.isInvitedAndHasReadonlyAccess as boolean,
                );
              }.bind(callbackScope),
            );
          }
        },
      };
    },
  ]);
  /*
   * ======================================================================================
   *
   * use state variables to set values right at the end and not call functions every time
   * break out of evaluation as soon as show state is set to false. - no need to eval as can't override hidden in later eval
   *      must evaluate ...options first
   *
   * make use of object pass by reference to reuse code
   *
   * extract function to get values from root scope
   *
   */
}

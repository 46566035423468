import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AccountService } from "@js/services/AccountService";
import { AuthService } from "@js/services/AuthService";
import { RoleService } from "@js/services/RoleService";

export class ReferFriendController {
  currentUser: ApplicationUserDTO = null;

  //Refer a friend
  referFriendName: string;
  referFriendSurname: string;
  referFriendEmail: string;
  referFriendBody: string;

  //universal email send functions
  sendingrefermessage: boolean;
  sentrefermessage: boolean;

  page: number = 1;
  referForm1: ng.IFormController;
  referForm2: ng.IFormController;
  companyWebsite: string;
  isBrickflowOrg: boolean = false;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
    "RoleService",
    "AccountService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $auth: AuthService,
    protected roleService: RoleService,
    private $accountservice: AccountService,
  ) {
    this.$auth.getProfile().then((profile) => {
      this.currentUser = profile;
    });
    this.companyWebsite = sessionStorage.getItem("companyWebsite");
    this.isBrickflowOrg = this.companyWebsite == "https://brickflow.com";
  }

  next() {
    if (this.page === 1) {
      this.page = 2;
    }
  }

  back() {
    if (this.page === 2) {
      this.page = 1;
    }
  }

  go(path: string) {
    this.$location.path(path);
  }

  sendRefer(): void {
    this.sendingrefermessage = true;
    this.sentrefermessage = false;

    this.$accountservice
      .SendFriendReferralEmail(
        this.currentUser.FirstName,
        this.currentUser.LastName,
        this.currentUser.Email,
        this.referFriendName,
        this.referFriendSurname,
        this.referFriendEmail,
        this.referFriendBody,
      )
      .then((response2) => {
        this.sendingrefermessage = false;
        this.sentrefermessage = true;
      });
  }
}

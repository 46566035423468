export const enum BrokerNetworkEnum
{
    None = 0,
    ConnectIFA = 1 << 0,                     // 1 (2^0)
    Synergy = 1 << 1,                        // 2 (2^1)
    NewLeafDistribution = 1 << 2,            // 4 (2^2)
    Fiducia = 1 << 3,                        // 8 (2^3)
    RosemountFinancialSolutions = 1 << 4,    // 16 (2^4)
    StJamesPlace = 1 << 5,                   // 32 (2^5)
    Primis = 1 << 6,                         // 64 (2^6)
    Quilter = 1 << 7,                        // 128 (2^7)
    Stonebridge = 1 << 8,                    // 256 (2^8)
    Openwork = 1 << 9,                       // 512 (2^9)
    HLPartnership = 1 << 10,                 // 1024 (2^10)
    TheRightMortgageLimited = 1 << 11,       // 2048 (2^11)
    PlanWealthManagement = 1 << 12,          // 4096 (2^12)
    Sesame = 1 << 13,                        // 8192 (2^13)
    TheOnLinePartnershipLtd = 1 << 14,       // 16384 (2^14)
    MortgageAdviceBureau = 1 << 15,          // 32768 (2^15)
    SenseNetwork = 1 << 16,                  // 65536 (2^16)
    MortgageIntelligenceLtd = 1 << 17,       // 131072 (2^17)
    ValidPathLimited = 1 << 18,              // 262144 (2^18)
    BestPracticeIFAGroupLimited = 1 << 19,   // 524288 (2^19)
    WhiteRoseFinanceGroupLimited = 1 << 20,  // 1048576 (2^20)
    TMGDirectLimited = 1 << 21,              // 2097152 (2^21)
    BeneficialLimited = 1 << 22,             // 4194304 (2^22)
    JulianHarris = 1 << 23,                  // 8388608 (2^23)
    DragonBrokersLimited = 1 << 24,          // 16777216 (2^24)
    JohnCharcolLimited = 1 << 25,            // 33554432 (2^25)
    CornerstoneFinancial = 1 << 26,          // 67108864 (2^26)   
    CorbelPartners = 1 << 27,                // 67108864 (2^27)
    RichdaleBrokers = 1 << 28,               // 134217728 (2^28)
    PJFinancialLtd = 1 << 29,                // 268435456 (2^29)
    JLMMortgageNetwork = 1 << 30,            // 536870912 (2^30)
    IngrandFinancial = 1 << 31               // 1073741824 (2^31)
}

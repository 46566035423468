import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { DevelopmentInputScheduleDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputScheduleDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";
import { ScheduleTypeEnum } from "@js/models/enum/ScheduleTypeEnum.cs.d";
import { TenureEnum } from "@js/models/enum/TenureEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { DevelopmentInputScheduleService } from "@js/services/DevelopmentInputScheduleService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { RoleService } from "@js/services/RoleService";
import { UserService } from "@js/services/UserService";

export class DevelopmentInputScheduleController {
  isLoggedInUser: boolean = false;
  dataLoading: boolean = false;

  saving: boolean = false;
  saved: boolean = false;
  error: boolean = false;
  selectedSection: string;
  developmentInput: DevelopmentInputDTO;
  developmentInputid: number;
  objects: DevelopmentInputScheduleDTO[]; // This is a full list of houses, flats, commercial units and parking spaces in the dev schedule
  objectsMarkedForDeletion: DevelopmentInputScheduleDTO[];
  houses: DevelopmentInputScheduleDTO[];
  flats: DevelopmentInputScheduleDTO[];
  communalAreas: DevelopmentInputScheduleDTO[];
  commercialUnits: DevelopmentInputScheduleDTO[];
  parkingSpaces: DevelopmentInputScheduleDTO[];
  selectedObject: DevelopmentInputScheduleDTO;
  OverallGDVLocal: number;
  OverallSQft: number = 0;
  sqlUnmatch: boolean = false;
  fetchingObjects: boolean;
  fetchingObject: boolean;

  fileUpload: FileAttachmentDTO[];
  uploadingFiles: FileUploadProgressDTO[];
  case: CaseDTO;
  file: FileAttachmentDTO;
  caseTitle: string;
  thisModuleSection: ModuleEnum = ModuleEnum.DevelopmentSchedule;
  openModal: boolean = false;
  seeFiles: boolean = false;
  seeInfo: boolean = false;

  showInformationMessage: boolean = false;
  messageContent: string;
  savingInProgress: boolean = false;
  showAutofill: boolean = false;

  GDVmismatch: boolean = false;
  OverallFlats: number = 0;
  OverallHouses: number = 0;
  OverallCommercial: number = 0;
  OverallCommunal: number = 0;

  parkingSoldSeparately: boolean;

  currentCaseNumber: number;

  developmentScheduleForm: ng.IFormController;

  //addingSubobject: boolean;

  //subobjects: SubobjectDTO[];
  //selectedSubobject: SubobjectDTO;

  //fetchingSubobjects: boolean;
  //fetchingSubobject: boolean;

  DevelopmentInputscheduleForm: ng.IFormController;
  DevelopmentScheduleFormUnitsDetailForm: ng.IFormController;
  //subDevelopmentInputscheduleForm: ng.IFormController;

  previousHouseNumber: number = 0;
  previousFlatNumber: number = 0;
  previousCommunalNumber: number = 0;
  previousParkingNumber: number = 0;
  previousCommercialNumber: number = 0;

  static $inject = [
    "$routeParams",
    "$rootScope",
    "DevelopmentInputScheduleService",
    "DevelopmentInputService",
    "$location",
    "$q",
    "CaseService",
    "FileAttachmentService",
    "RoleService",
    "$window",
    "$cookies",
    "AuthService",
    "UserService",

    //'SubobjectService',
  ];
  //private $subobjectservice: App.Services.SubobjectService
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $rootScope: ng.IRootScopeService,
    private $DevelopmentInputscheduleservice: DevelopmentInputScheduleService,
    private $DevelopmentInputService: DevelopmentInputService,
    private $location: ng.ILocationService,
    protected $q: ng.IQService,
    private $caseService: CaseService,
    private fileAttachmentService: FileAttachmentService,
    private roleService: RoleService,
    private $window: ng.IWindowService,
    private $cookies: ng.cookies.ICookiesService,
    private authService: AuthService,
    private userService: UserService,
  ) {
    if ($cookies.get("access_token")) {
      this.isLoggedInUser = true;

      this.userService.getUserAutofill().then((response) => {
        this.showAutofill = response;
      });
    }

    this.objects = [{} as DevelopmentInputScheduleDTO];

    if (this.$routeParams.CaseId) {
      this.currentCaseNumber = this.$routeParams.CaseId;
    }

    this.$caseService.fetch(this.$routeParams.CaseId).then((response) => {
      if (response == null) {
        if (this.isLoggedInUser) {
          this.$location.path("/userdashboard");
        }
      } else {
        this.case = response;
        this.fileUpload = response.Attachments;
        this.caseTitle = response.DevelopmentInput.SearchName;
        this.calculateFilesForThisModule();
      }
    });

    if (
      this.$routeParams.DevelopmentId &&
      this.$routeParams.DevelopmentId != "null"
    ) {
      this.developmentInputid = this.$routeParams.DevelopmentId;
      this.$DevelopmentInputService
        .fetch(this.developmentInputid)
        .then((response) => {
          if (response == null) {
            if (this.isLoggedInUser) {
              this.$location.path("/userdashboard");
            }
          } else {
            this.developmentInput = response;

            this.updateObjects();

            if (this.developmentInput) {
              this.CalcAll();
            }
          }
        });
    }
  }
  formChanged(): void {
    (this.$rootScope as any).formSaved = false;
  }
  //ReduceOrInceaseRecordsOfType(rectype:ScheduleTypeEnum) {

  //}

  //SingleUnitChanged() {
  //    this.developmentInput.HowManyUnits = 1;
  //    this.developmentInput.HowManyHouses = 0;
  //    this.developmentInput.HowManyParkingSpaces = 0;
  //    this.developmentInput.HowManyFlats = 0;

  //}
  setAllFormsPristine(): void {
    if (this.developmentScheduleForm) {
      this.developmentScheduleForm.$setPristine();
    }
    if (this.DevelopmentScheduleFormUnitsDetailForm) {
      this.DevelopmentScheduleFormUnitsDetailForm.$setPristine();
    }
  }

  NumberCommercialChanged() {
    if (this.developmentInput.HowManyUnits == undefined) {
      this.developmentInput.HowManyUnits = 0;
    }

    (this.developmentInput.HowManyUnits ||
      this.developmentInput.HowManyUnits == 0) &&
      this.NumberChanged(
        ScheduleTypeEnum.Commercial,
        this.developmentInput.HowManyUnits,
      );

    this.commercialUnits.length = this.developmentInput.HowManyUnits;

    this.CalcGDVCorporateUnitsSpaces();
    this.previousCommercialNumber = this.developmentInput.HowManyUnits;
  }

  NumberHousesChanged() {
    if (this.developmentInput.HowManyHouses == undefined) {
      this.developmentInput.HowManyHouses = 0;
    }

    (this.developmentInput.HowManyHouses ||
      this.developmentInput.HowManyHouses == 0) &&
      this.NumberChanged(
        ScheduleTypeEnum.House,
        this.developmentInput.HowManyHouses,
      );

    this.houses.length = this.developmentInput.HowManyHouses;

    this.CalcGDVHouses();
    this.previousHouseNumber = this.developmentInput.HowManyHouses;
  }

  NumberFlatsChanged() {
    if (this.developmentInput.HowManyFlats == undefined) {
      this.developmentInput.HowManyFlats = 0;
    }

    (this.developmentInput.HowManyFlats ||
      this.developmentInput.HowManyFlats == 0) &&
      this.NumberChanged(
        ScheduleTypeEnum.Flat,
        this.developmentInput.HowManyFlats,
      );

    this.flats.length = this.developmentInput.HowManyFlats;
    this.CalcGDVFlats();

    this.previousFlatNumber = this.developmentInput.HowManyFlats;
  }

  NumberCommunalAreas() {
    if (this.developmentInput.HowManyCommunalAreas === undefined) {
      this.developmentInput.HowManyCommunalAreas = 0;
    }

    (this.developmentInput.HowManyCommunalAreas ||
      this.developmentInput.HowManyCommunalAreas == 0) &&
      this.NumberChanged(
        ScheduleTypeEnum.Communal,
        this.developmentInput.HowManyCommunalAreas,
      );

    this.communalAreas.length = this.developmentInput.HowManyCommunalAreas;
  }

  SetParkingSpacesToZero() {
    if (this.developmentInput.ParkingSoldSeparately === true) {
      this.NumberChanged(ScheduleTypeEnum.Parking, 0);
      this.CalcGDVParkingSpaces();
    }
  }

  NumberParkingSpacesChanged() {
    if (this.developmentInput.HowManyParkingSpaces == undefined) {
      this.developmentInput.HowManyParkingSpaces = 0;
    }

    (this.developmentInput.HowManyParkingSpaces ||
      this.developmentInput.HowManyParkingSpaces == 0) &&
      this.NumberChanged(
        ScheduleTypeEnum.Parking,
        this.developmentInput.HowManyParkingSpaces,
      );

    this.parkingSpaces.length = this.developmentInput.HowManyParkingSpaces;

    this.CalcGDVParkingSpaces();

    this.previousParkingNumber = this.developmentInput.HowManyParkingSpaces;
  }
  CalcTotalForListOfSalesPrices(
    salesprices: DevelopmentInputScheduleDTO[],
  ): number {
    var total: number = 0;
    salesprices.forEach((x) => {
      if (x.SalesPrice && x.SalesPrice > 0) {
        total = total + Number(x.SalesPrice);
      }
    });
    return total;
  }
  CalcTotalForListOfSalesPricesMultQuanity(
    salesprices: DevelopmentInputScheduleDTO[],
  ): number {
    var total: number = 0;
    salesprices.forEach((x) => {
      if (
        x.SalesPrice &&
        x.SalesPrice > 0 &&
        x.NumberOfUnits &&
        x.NumberOfUnits > 0
      ) {
        total = total + Number(x.SalesPrice) * x.NumberOfUnits;
      }
    });
    return total;
  }
  CalcGDVHouses() {
    this.developmentInput.Houses_GDV = this.CalcTotalForListOfSalesPrices(
      this.houses,
    );
    this.CalcOverallTotal();
  }
  CalcGDVFlats() {
    this.developmentInput.Flats_GDV = this.CalcTotalForListOfSalesPrices(
      this.flats,
    );
    this.CalcOverallTotal();
  }
  CalcGDVParkingSpaces() {
    this.developmentInput.Parking_GDV =
      this.CalcTotalForListOfSalesPricesMultQuanity(this.parkingSpaces);
    this.CalcOverallTotal();
  }
  CalcGDVCorporateUnitsSpaces() {
    this.developmentInput.Commercial_GDV = this.CalcTotalForListOfSalesPrices(
      this.commercialUnits,
    );
    this.CalcOverallTotal();
  }
  CalcOverallTotal() {
    this.OverallGDVLocal = 0;

    if (
      this.developmentInput.CI_EndPropType == 1 ||
      this.developmentInput.CI_EndPropType == 2
    ) {
      this.OverallGDVLocal +=
        this.developmentInput.Houses_GDV +
        this.developmentInput.Flats_GDV +
        this.developmentInput.Parking_GDV;
    }

    if (this.developmentInput.CI_EndPropType != 1) {
      this.OverallGDVLocal += this.developmentInput.Commercial_GDV;
    }

    if (
      this.OverallGDVLocal > 0 &&
      this.OverallGDVLocal !== this.developmentInput.CI_GDV
    ) {
      this.GDVmismatch = true;
    } else {
      this.GDVmismatch = false;
    }
  }

  CalcOverallSQft() {
    this.OverallSQft = 0;
    this.OverallHouses = 0;
    this.OverallFlats = 0;
    this.OverallCommercial = 0;
    this.OverallCommunal = 0;

    if (this.houses && this.houses.length > 0) {
      var tempHouseArray = this.houses.filter((house) => house.Area > 0);

      tempHouseArray.forEach(
        (i) => (i.AreaUnit = this.developmentInput.DI_AreaUnit),
      );

      if (tempHouseArray && tempHouseArray.length > 0) {
        this.OverallHouses = tempHouseArray
          .map((i) => i.Area)
          .reduce((a, b) => a + b);
      }
    }

    if (this.flats && this.flats.length > 0) {
      var tempFlatArray = this.flats.filter((flat) => flat.Area > 0);

      tempFlatArray.forEach(
        (i) => (i.AreaUnit = this.developmentInput.DI_AreaUnit),
      );

      if (tempFlatArray && tempFlatArray.length > 0) {
        this.OverallFlats = tempFlatArray
          .map((i) => i.Area)
          .reduce((a, b) => a + b);
      }
    }

    if (this.commercialUnits && this.commercialUnits.length > 0) {
      var tempCMUArray = this.commercialUnits.filter(
        (commercialUnit) => commercialUnit.Area > 0,
      );
      tempCMUArray.forEach(
        (i) => (i.AreaUnit = this.developmentInput.DI_AreaUnit),
      );

      if (tempCMUArray && tempCMUArray.length > 0) {
        this.OverallCommercial = tempCMUArray
          .map((i) => i.Area)
          .reduce((a, b) => a + b);
      }
    }

    if (this.communalAreas && this.communalAreas.length > 0) {
      this.communalAreas = this.checkIfAreaisNull(this.communalAreas);
      this.communalAreas = this.checkIfDescriptionIsNull(this.communalAreas);
      var tempCAArray = this.communalAreas.filter(
        (communalArea) => communalArea.Area > 0,
      );

      tempCAArray.forEach(
        (i) => (i.AreaUnit = this.developmentInput.DI_AreaUnit),
      );

      if (tempCAArray && tempCAArray.length > 0) {
        this.OverallCommunal = tempCAArray
          .map((i) => i.Area)
          .reduce((a, b) => a + b);
      }
    }

    if (
      this.developmentInput.CI_EndPropType == 1 ||
      this.developmentInput.CI_EndPropType == 2
    ) {
      this.OverallSQft +=
        this.OverallHouses + this.OverallFlats + this.OverallCommunal;
    }

    if (this.developmentInput.CI_EndPropType != 1) {
      this.OverallSQft += this.OverallCommercial;
    }

    if (
      this.OverallSQft !== this.developmentInput.DI_AreaSalesAreaResidential
    ) {
      this.sqlUnmatch = true;
    } else {
      this.sqlUnmatch = false;
    }
  }

  checkIfAreaisNull(overallBuild: DevelopmentInputScheduleDTO[]) {
    return overallBuild.map((i) => {
      if (i.Area === undefined || i.Area === null) {
        i.Area = 0;
      } else {
        i.Area;
      }
      return i;
    });
  }

  checkIfDescriptionIsNull(overallBuild: DevelopmentInputScheduleDTO[]) {
    return overallBuild.map((i) => {
      if (i.Description === undefined || i.Description === null) {
        i.Description = "Communal Areas";
      }
      return i;
    });
  }

  CalcAll() {
    if (this.developmentInput && this.objects && this.objects.length > 0) {
      this.developmentInput.Houses_GDV =
        this.houses && this.houses.length > 0
          ? this.CalcTotalForListOfSalesPrices(this.houses)
          : null;
      this.developmentInput.Parking_GDV =
        this.parkingSpaces && this.parkingSpaces.length > 0
          ? this.CalcTotalForListOfSalesPricesMultQuanity(this.parkingSpaces)
          : null;
      this.developmentInput.Flats_GDV =
        this.flats && this.flats.length > 0
          ? this.CalcTotalForListOfSalesPrices(this.flats)
          : null;
      this.developmentInput.Commercial_GDV =
        this.commercialUnits && this.commercialUnits.length > 0
          ? this.CalcTotalForListOfSalesPrices(this.commercialUnits)
          : null;
      this.CalcOverallTotal();
      this.CalcOverallSQft();
    }
  }
  FilterMainObjects(recType: ScheduleTypeEnum): DevelopmentInputScheduleDTO[] {
    if (this.objects) {
      return this.objects
        .filter((x) => x.ScheduleType == recType)
        .sort(function (a, b) {
          return a.OrderId < b.OrderId ? -1 : 1;
        });
    }
    return null;
  }
  NumberChanged(recType: ScheduleTypeEnum, numberCurrently: number) {
    var currentObjects: DevelopmentInputScheduleDTO[];

    // by using = when currentObjects changes the original array will also change as it is "copied" by reference
    switch (recType) {
      case ScheduleTypeEnum.Commercial:
        currentObjects = this.commercialUnits;
        break;
      case ScheduleTypeEnum.Flat:
        currentObjects = this.flats;
        break;
      case ScheduleTypeEnum.House:
        currentObjects = this.houses;
        break;
      case ScheduleTypeEnum.Parking:
        currentObjects = this.parkingSpaces;
        break;
      case ScheduleTypeEnum.Communal:
        currentObjects = this.communalAreas;
        break;
    }

    //get back list related to corporate units
    var difference: number = numberCurrently - currentObjects.length;
    if (difference < 0) {
      difference = Math.abs(difference);
      //remove the latter ones

      for (
        var i = currentObjects.length - difference;
        i < currentObjects.length;
        i++
      ) {
        if (!this.objectsMarkedForDeletion) {
          this.objectsMarkedForDeletion = [];
        }
        this.objectsMarkedForDeletion.push(currentObjects[i]);
      }
    } else if (difference > 0) {
      //add new ones
      for (var i = 0; i < difference; i++) {
        var singlerec: DevelopmentInputScheduleDTO =
          {} as DevelopmentInputScheduleDTO;
        singlerec.ScheduleType = recType;
        singlerec.DevelopmentInputId = this.developmentInput.Id;
        singlerec.SalesPrice = 0;

        if (recType === 0) {
          singlerec.Tenure = TenureEnum.Freehold;
        }
        if (recType === 1) {
          singlerec.Tenure = TenureEnum.Leasehold;
        }

        currentObjects.push(singlerec);
      }
    }
  }

  updateObjects() {
    this.fetchingObjects = true;

    // Fetch all objects.
    this.$DevelopmentInputscheduleservice
      .fetchByCaseId(this.developmentInputid)
      .then((response) => {
        this.objects = response;

        this.objects.forEach((o) => {
          o.Area != 0 ? o.Area : (o.Area = null);
        });

        this.populatePropertyArrays();

        this.CalcAll();
        this.preSelectedItem();

        this.NumberCommercialChanged();
        this.NumberCommunalAreas();
        this.NumberFlatsChanged();
        this.NumberHousesChanged();
        this.NumberParkingSpacesChanged();
      })
      .finally(() => {
        this.fetchingObjects = false;
      });
  }

  private preSelectedItem() {
    if (this.$routeParams.Id) {
      let selectedObject = this.objects.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.objectId);
      });

      this.selectObject(selectedObject);
    }
  }

  selectObject(object: DevelopmentInputScheduleDTO) {
    this.selectedObject = object;

    //this.fetchingSubobjects = true;
    //this.$subobjectservice.fetchSubobjectsForObject(object.Id).then((response) => {
    //    this.subobjects = response;
    //    this.fetchingSubobjects = false;
    //}).catch((response) => {
    //    this.fetchingSubobjects = false;
    //});

    this.selectedSection = "details";
  }

  createObject() {
    this.selectedSection = "details";
    this.selectedObject = {} as DevelopmentInputScheduleDTO;
  }

  save(): ng.IPromise<number[]> {
    this.saving = true;
    let defer = this.$q.defer<number[]>();

    // Concatenate the separate arrays back into the main objects array for saving
    this.objects = this.houses.concat(
      this.flats,
      this.commercialUnits,
      this.parkingSpaces,
      this.communalAreas,
    );

    this.objects.forEach((o) => {
      o.SalesPrice > -1 ? o.SalesPrice : (o.SalesPrice = 0);
    });

    this.objects.forEach((o) => {
      o.Area > 0 ? o.Area : (o.Area = 0);
    });

    this.$DevelopmentInputscheduleservice
      .addUpdatelistreturnonlyids(this.objects)
      .then((response) => {
        this.objects.forEach((o) => {
          o.Area != 0 ? o.Area : (o.Area = null);
        });
        //set ids to those of response
        this.objects.forEach((o, i) => (o.Id = response[i]));
        // re-populate the individual arrays with the new data
        this.populatePropertyArrays();

        this.$DevelopmentInputService
          .addUpdatereturnonlyid(this.developmentInput)
          .then((devInputResponse) => {
            this.developmentInput.Id = devInputResponse;
            this.CalcAll();
            this.setAllFormsPristine();
            this.saved = true;
            defer.resolve(response as number[]);
            //TODO: add to architecture delete all
            if (this.objectsMarkedForDeletion) {
              for (var i = 0; i < this.objectsMarkedForDeletion.length; i++) {
                if (this.objectsMarkedForDeletion[i].Id) {
                  this.$DevelopmentInputscheduleservice.delete(
                    this.objectsMarkedForDeletion[i].Id,
                  );
                }
              }
              this.objectsMarkedForDeletion = [];
            }

            return response;
          });
      })
      .catch((response) => {
        defer.reject(response);
        this.error = true;
      })
      .finally(() => {
        this.saving = false;
      });
    return defer.promise;
  }

  delete() {
    this.$DevelopmentInputscheduleservice
      .delete(this.selectedObject.Id)
      .then((response) => {
        if (response) {
          this.objects.splice(this.objects.indexOf(this.selectedObject), 1);
          this.DevelopmentInputscheduleForm.$setPristine();
          delete this.selectedObject;
        }
      });
  }

  cancel(): void {
    this.$location.path("/casedashboard/" + this.currentCaseNumber);
  }

  saveChanges(): void {
    this.dataLoading = true;
    this.save()
      .then((response) => {
        (this.$rootScope as any).formSaved = true;
        this.DevelopmentScheduleFormUnitsDetailForm.$setPristine();
        this.developmentScheduleForm.$setPristine();
        this.messageContent = "Your changes have been saved.";
      })
      .catch((error) => {
        this.messageContent =
          "Sorry, something went wrong while saving. Please try again.";
      })
      .finally(() => {
        this.dataLoading = false;
        this.showInformationMessage = true;
      });
  }

  goToCaseDashboard(saveChanges: boolean): void {
    if (saveChanges && this.roleService.IsLender != true) {
      this.save().then((response) => {
        (this.$rootScope as any).formSaved = true;
      });
    }
    this.$location.path("/casedashboard/" + this.currentCaseNumber);
  }

  closeModal(): void {
    this.showInformationMessage = false;
    this.$location.path("/casedashboard/" + this.currentCaseNumber);
  }

  updateGIA() {
    this.developmentInput.DI_AreaSalesAreaResidential = this.OverallSQft;
    this.$DevelopmentInputService
      .addUpdatereturnonlyid(this.developmentInput)
      .then((devInputResponse) => {
        this.developmentInput.Id = devInputResponse;
        this.developmentInput.DI_AreaSalesAreaResidential = this.OverallSQft;
        this.CalcAll();
        this.setAllFormsPristine();
        this.saved = true;
        return devInputResponse;
      });
    this.CalcOverallSQft();
  }

  updateGDV() {
    this.developmentInput.CI_GDV = this.OverallGDVLocal;
    this.$DevelopmentInputService
      .addUpdatereturnonlyid(this.developmentInput)
      .then((devInputResponse) => {
        this.developmentInput.Id = devInputResponse;
        this.developmentInput.CI_GDV = this.OverallGDVLocal;
        this.CalcAll();
        this.setAllFormsPristine();
        this.saved = true;
        return devInputResponse;
      });
  }

  //File upload

  calculateTotalFiles(filter: number) {
    if (this.fileUpload) {
      return this.fileUpload.length;
    }
  }

  calculateFilesForThisModule() {
    this.fileUpload = this.fileUpload.filter(
      (file) => file.Module === this.thisModuleSection,
    );
    if (this.fileUpload.length <= 0) {
      this.seeInfo = true;
    } else {
      this.seeInfo = false;
    }
  }

  onFileSelect(files: FileAttachmentDTO[], module?: ModuleEnum) {
    if (files.length > 0) {
      //if case has no id it must be saved first
      if (this.case.Id < 1) {
        this.$caseService
          .addUpdate(this.case)
          .then((response) => {
            this.case = response;
            this.onFileSelect(files); //call itself and then exit as should now be saved so should be able to run
            return;
          })
          .finally(() => {
            this.dataLoading = false;
            this.calculateFilesForThisModule();
            this.openModal = false;
          });
        return;
      }

      this.fileAttachmentService
        .UploadFileLstInSync(
          files,
          this.uploadingFiles,
          this.case.Id,
          this.fileUpload,
          module,
        )
        .then((result) => {
          this.calculateFilesForThisModule();
          this.openModal = false;
        });
    }
  }

  downloadFile(file: FileAttachmentDTO) {
    this.fileAttachmentService
      .getFileUri(file.FileLocation)
      .then((uri) => {
        this.$window.open(uri);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  deleteFile(file: FileAttachmentDTO) {
    this.fileUpload.splice(this.fileUpload.indexOf(file), 1);
    this.fileAttachmentService.markasdeleted(file.Id).then((result) => {
      this.calculateFilesForThisModule();
    });
  }

  uploadYourOwnClicked() {
    event.preventDefault(); // prevents default behaviour of href
    this.openModal = true;
  }

  setAreaVal(area) {
    if (area === undefined || area === null) {
      return 0;
    } else {
      return area;
    }
  }

  populatePropertyArrays() {
    // First clear them:
    this.houses = [];
    this.flats = [];
    this.communalAreas = [];
    this.commercialUnits = [];
    this.parkingSpaces = [];

    // Then populate them from the main objects array
    this.houses = this.FilterMainObjects(ScheduleTypeEnum.House);
    this.flats = this.FilterMainObjects(ScheduleTypeEnum.Flat);
    this.communalAreas = this.FilterMainObjects(ScheduleTypeEnum.Communal);
    this.commercialUnits = this.FilterMainObjects(ScheduleTypeEnum.Commercial);
    this.parkingSpaces = this.FilterMainObjects(ScheduleTypeEnum.Parking);
  }

  //addSubobject() {
  //    this.selectedSubobject = {
  //        AssociatedObjectId: this.selectedObject.Id,
  //        AssociatedObjectName: this.selectedObject.Name
  //    } as SubobjectDTO;
  //}

  //removeSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.deleteSubobject(subobject.Id).then((response) => {
  //        this.subobjects.splice(this.subobjects.indexOf(this.selectedSubobject), 1);
  //        delete this.selectedSubobject;
  //    });
  //}

  //cancelUpdateSubobject() {
  //    delete this.selectedSubobject;
  //}

  //updateSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.addUpdateSubobject(subobject).then((response) => {
  //        if (this.subobjects.indexOf(subobject) === -1) {
  //            this.subobjects.push(subobject);
  //        }
  //        delete this.selectedSubobject;
  //    }).catch((response) => {
  //        delete this.selectedSubobject;
  //    });

  //}

  autofillSchedule() {
    this.developmentInput.HowManyHouses = 2;
    this.houses = [];
    var house1 = {
      DevelopmentInputId: this.developmentInput.Id,
      ScheduleType: ScheduleTypeEnum.House,
      LocationIdentifier: "1",
      Description: "Detached house",
      NumberOfBeds: 4,
      NumberOfBaths: 3,
      Tenure: TenureEnum.Freehold,
      Area: 1500,
      SalesPrice: 1000000,
    } as DevelopmentInputScheduleDTO;

    this.houses.push(house1);

    var house2 = {
      DevelopmentInputId: this.developmentInput.Id,
      ScheduleType: ScheduleTypeEnum.House,
      LocationIdentifier: "2",
      Description: "Semi-detached house",
      NumberOfBeds: 4,
      NumberOfBaths: 3,
      Tenure: TenureEnum.Freehold,
      Area: 1500,
      SalesPrice: 1500000,
    } as DevelopmentInputScheduleDTO;

    this.houses.push(house2);

    this.developmentInput.Houses_GDV = 2500000;
  }
}

import { UserService } from "@js/services/UserService";

export class UnsubscribeEmailController {

    static $inject = [
        '$routeParams',
        'UserService',
    ];

    error: boolean = false;

    constructor(private $routeParams: ng.route.IRouteParamsService, private userService:UserService) {


        if (this.$routeParams.emailCode && this.$routeParams.userId) {
            this.userService.unSubscribeFromEmail(this.$routeParams.emailCode, this.$routeParams.userId).then((response) => {
            }).catch((response) => {
                this.error = true;
            });
        }

    }

}


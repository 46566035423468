import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { GetRegionByPostcodeResponse } from "@js/DTO/Messages/GetRegionByPostcodeMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class DevelopmentInputService extends BaseService<
  DevelopmentInputDTO,
  number
> {
  isValidStatus: boolean;

  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/developmentinput";
    this.$broadcastBusinessNameSingular = "developmentinput";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchByIntroducerId(
    IntroducerId: number,
  ): ng.IPromise<DevelopmentInputDTO[]> {
    let defer = this.$q.defer<DevelopmentInputDTO[]>();
    this.$http
      .get(
        this.$baseAddress + "/fetchByIntroducerId?IntroducerId=" + IntroducerId,
      )
      .then((response) => {
        defer.resolve(response.data as DevelopmentInputDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  listbyuserid(userId: string): ng.IPromise<DevelopmentInputDTO[]> {
    let defer = this.$q.defer<DevelopmentInputDTO[]>();
    this.$http
      .get(this.$baseAddress + "/listbyuserid?userId=" + userId)
      .then((response) => {
        defer.resolve(response.data as DevelopmentInputDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  listbyusername(username: string): ng.IPromise<DevelopmentInputDTO[]> {
    let defer = this.$q.defer<DevelopmentInputDTO[]>();
    this.$http
      .get(this.$baseAddress + "/listbyusername?username=" + username)
      .then((response) => {
        defer.resolve(response.data as DevelopmentInputDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "listbyuser",
          "List by user failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  addBlankSearch(dto: DevelopmentInputDTO): ng.IPromise<DevelopmentInputDTO> {
    let defer = this.$q.defer<DevelopmentInputDTO>();
    this.$http
      .post(
        this.$baseAddress + "/addblanksearch",
        JSON.stringify(dto, this.jsonPropertyReplacer),
      )
      .then((response) => {
        defer.resolve(response.data as DevelopmentInputDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "addblanksearch",
          "Add blank search failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  saveSearch(dto: DevelopmentInputDTO): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .post(
        this.$baseAddress + "/savesearch",
        JSON.stringify(dto, this.jsonPropertyReplacer),
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "savesearch",
          "Save search failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  copyCase(developmentInputId: number, name: string): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .post(
        this.$baseAddress +
          "/copyCase?developmentInputId=" +
          developmentInputId +
          "&name=" +
          name,
        "",
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "savesearch",
          "Save search failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  sendResultsToNewClient(
    dto: DevelopmentInputDTO,
    clientFirstName: string,
    clientSurname: string,
    clientEmail: string,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .put(
        this.$baseAddress +
          "/sendresultstonewclient?clientFirstName=" +
          clientFirstName +
          "&clientSurname=" +
          clientSurname +
          "&clientEmail=" +
          clientEmail,
        JSON.stringify(dto),
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendResultsToExistingClient(
    dto: DevelopmentInputDTO,
    clientFirstName: string,
    clientSurname: string,
    clientEmail: string,
    clientId: string,
    notifyClient: boolean,
    clientPhoneNumber: string,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .put(
        this.$baseAddress +
          "/sendresultstoexistingclient?clientFirstName=" +
          clientFirstName +
          "&clientSurname=" +
          clientSurname +
          "&clientEmail=" +
          clientEmail +
          "&clientId=" +
          clientId +
          "&notifyClient=" +
          notifyClient +
          "&clientPhoneNumber=" +
          clientPhoneNumber,
        JSON.stringify(dto),
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendEmailToExistingUser(
    searchId: number,
    showLenderNameValue: boolean = false,
    updateshowLenderName: boolean = false,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        this.$baseAddress +
          "/sendemailtoexistinguser?searchId=" +
          searchId +
          "&showLenderNameValue=" +
          showLenderNameValue +
          "&updateshowLenderName=" +
          updateshowLenderName,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getHasValidStatustoShowShareSearchModal() {
    return this.isValidStatus;
  }

  setHasValidStatustoShowShareSearchModal(isValidStatus) {
    this.isValidStatus = isValidStatus;
  }

  assignBrokerToSearch(
    developmentInputId: number,
    brokerUserId: string,
    isNoLoginRecord: boolean,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .put(
        this.$baseAddress +
          "/assignbrokertosearch?developmentInputId=" +
          developmentInputId +
          "&brokerUserId=" +
          brokerUserId +
          "&isNoLoginRecord=" +
          isNoLoginRecord,
        "",
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getRegionByPostcode(
    postcodeSearchString: string,
  ): ng.IPromise<GetRegionByPostcodeResponse> {
    let defer = this.$q.defer<GetRegionByPostcodeResponse>();
    this.$http
      .get(
        this.$baseAddress +
          "/getregionbypostcode?postcodeSearchString=" +
          postcodeSearchString,
      )
      .then((response) => {
        defer.resolve(response.data as GetRegionByPostcodeResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  isValidPostcodeString(postcodeSearchString: string) {
    if (
      /^[A-Za-z]{1,2}[0-9]{1}/.test(postcodeSearchString) &&
      postcodeSearchString.replace(" ", "").length <= 7
    ) {
      return true;
    }
    return false;
  }

  isUserHasAccessToCase(searchId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(this.$baseAddress + "/isuserhasaccesstocase?id=" + searchId)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

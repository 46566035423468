import { DeveloperExitFinanceDevelopmentInputDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceDevelopmentInputDTO.cs.d";
import { DeveloperExitFinanceDevScheduleInputDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceDevScheduleInputDTO.cs.d";
import { DeveloperExitFinanceInputSetDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceInputSetDTO.cs.d";
import { DeveloperExitFinanceLoanInputDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceLoanInputDTO.cs.d";
import { DeveloperExitFinanceDevelopmentInputService } from "@js/services/DevExitFinance/DeveloperExitFinanceDevelopmentInputService";
import { DeveloperExitFinanceDevScheduleInputService } from "@js/services/DevExitFinance/DeveloperExitFinanceDevScheduleInputService";
import { DeveloperExitFinanceLoanInputService } from "@js/services/DevExitFinance/DeveloperExitFinanceLoanInputService";
import { SelectListService } from "@js/services/SelectListService";

export class DeveloperExitFinanceDevScheduleInputController {
  selectedSection: string;

  objects: DeveloperExitFinanceDevScheduleInputDTO[];
  selectedObject: DeveloperExitFinanceDevScheduleInputDTO;

  devInputs: DeveloperExitFinanceDevelopmentInputDTO;
  loanInputs: DeveloperExitFinanceLoanInputDTO;
  exitLoanCriteria: DeveloperExitFinanceInputSetDTO;

  fetchingObjects: boolean;
  fetchingObject: boolean;

  totalUnitRentalPrice: number;
  totalUnitPrice: number;

  developerexitfinancedevscheduleinputForm: ng.IFormController;

  locationOptions = [];

  static $inject = [
    "$rootScope",
    "$location",
    "$routeParams",
    "DeveloperExitFinanceDevScheduleInputService",
    "DeveloperExitFinanceDevelopmentInputService",
    "DeveloperExitFinanceLoanInputService",
    "SelectListService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $routeParams: ng.route.IRouteParamsService,
    private $developerexitfinancedevscheduleinputservice: DeveloperExitFinanceDevScheduleInputService,
    private $developerexitfinancedevelopmentinputservice: DeveloperExitFinanceDevelopmentInputService,
    private $developerexitfinanceloaninputservice: DeveloperExitFinanceLoanInputService,
    private selectListService: SelectListService,
  ) {
    this.updateObjects();

    this.locationOptions = this.selectListService.GetLocations();
  }

  updateObjects() {
    this.fetchingObjects = true;
    if ((this.$rootScope as any).exitLoanCriteria) {
      this.exitLoanCriteria = (this.$rootScope as any).exitLoanCriteria;
      this.objects =
        this.exitLoanCriteria.DevelopmentInput.DevelopmentSchedules;
    } else {
      this.exitLoanCriteria = {} as DeveloperExitFinanceInputSetDTO;
      this.objects = [];
      this.addUnit();
    }
    //this.$developerexitfinancedevscheduleinputservice.fetchAll().then((response) => {
    //    this.objects = response;
    //    this.preSelectedItem();
    //}).finally(() => {
    //    this.fetchingObjects = false;
    //});
  }

  private preSelectedItem() {
    if (this.$routeParams.Id) {
      let selectedObject = this.objects.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.objectId);
      });

      this.selectObject(selectedObject);
    }
  }

  calcTotalUnitPrice() {
    var res: number = 0;
    this.objects.forEach((item) => {
      if (item.SalesPrice) {
        res += Number(item.SalesPrice);
      }
    });
    this.totalUnitPrice = res;
  }
  calcTotalUnitRentalPrice() {
    var res: number = 0;
    this.objects.forEach((item) => {
      if (item.MonthlyRent) {
        res += Number(item.MonthlyRent);
      }
    });
    this.totalUnitRentalPrice = res;
  }

  selectObject(object: DeveloperExitFinanceDevScheduleInputDTO) {
    this.selectedObject = object;

    this.selectedSection = "details";
  }

  createObject() {
    this.selectedSection = "details";
    this.selectedObject = {} as DeveloperExitFinanceDevScheduleInputDTO;
  }

  addUnit() {
    this.selectedObject = {} as DeveloperExitFinanceDevScheduleInputDTO;
    this.objects.push(this.selectedObject);
  }

  save() {
    this.$developerexitfinancedevscheduleinputservice
      .addUpdatelist(this.objects)
      .then((response) => {
        //this.selectedObject.Id = response;
        if (!this.objects) {
          this.objects = [];
        }

        this.developerexitfinancedevscheduleinputForm.$setPristine();
        this.updateObjects();
      });
  }

  deleteUnit(item: DeveloperExitFinanceDevScheduleInputDTO) {
    //this.$developerexitfinancedevscheduleinputservice.delete(item.Id).then((response) => {
    //    if (response) {
    this.objects.splice(this.objects.indexOf(item), 1);
    this.developerexitfinancedevscheduleinputForm.$setPristine();
    //    }
    //});
  }

  delete() {
    this.$developerexitfinancedevscheduleinputservice
      .delete(this.selectedObject.Id)
      .then((response) => {
        if (response) {
          this.objects.splice(this.objects.indexOf(this.selectedObject), 1);
          this.developerexitfinancedevscheduleinputForm.$setPristine();
          delete this.selectedObject;
        }
      });
  }

  fetchResults() {
    this.exitLoanCriteria.DevelopmentInput.DevelopmentSchedules = this.objects;
    this.exitLoanCriteria.DevelopmentInput.HowManyUnits =
      this.exitLoanCriteria.DevelopmentInput.DevelopmentSchedules.length;
    (this.$rootScope as any).exitLoanCriteria = this.exitLoanCriteria;
    this.$location.path("/exitloanresults");
  }
}

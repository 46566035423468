import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { IntroducerDTO } from "@js/DTO/IntroducerDTO.cs.d";
import { InvitedBrokerDTO } from "@js/DTO/InvitedBrokerDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { RegisterOrganisationDTO } from "@js/DTO/RegisterOrganisationDTO.cs.d";
import { ApplicantRoleEnum } from "@js/models/enum/ApplicantRoleEnum.cs.d";
import { IntroducerTypeEnum } from "@js/models/enum/IntroducerTypeEnum.cs.d";
import { OrganisationTypeEnum } from "@js/models/enum/OrganisationTypeEnum.cs.d";
import { PricingFrequencyEnum } from "@js/models/enum/PricingFrequencyEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { IntroducerService } from "@js/services/IntroducerService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class RegistrationBrokerController {
  bar: number = 0;
  formEntered: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  step: number = 1;
  stepName: string = "";
  seats: number = 1;
  dataLoading: boolean = false;
  progressBar: {
    step: number;
    label: string;
    active: boolean;
    complete: boolean;
  }[] = [
    { step: 1, label: "About you", active: false, complete: false },
    { step: 2, label: "About your business", active: false, complete: false },
    {
      step: 3,
      label: "More about your business",
      active: false,
      complete: false,
    },
    { step: 4, label: "Assign licenses", active: false, complete: false },
  ];

  aboutYouSection: boolean = false;
  aboutYourBusinessSection: boolean = false;
  moreAboutYourBusinessSection: boolean = false;
  user: ApplicationUserDTO = {
    Roles: ["Broker", "Introducer"],
    IsOrganisationAdmin: true,
    ApplicantDefinedRole: ApplicantRoleEnum.Introducer,
    ApplicantDefinedRoleIntroducer: IntroducerTypeEnum.Broker,
  } as ApplicationUserDTO;

  selectedPackage: AppPackagePricingDTO;

  organization: OrganisationDTO;
  userEmails: InvitedBrokerDTO[] = [];
  newUser: InvitedBrokerDTO = {
    Email: null,
    FirstName: null,
  };
  tempdata: InvitedBrokerDTO;
  //tour
  welcomeMessage: string =
    "Welcome to the Brickflow for Brokers - to setup your account we need some details on you and your company.";
  tourOrder: number = 1;

  registrationFormStep1: ng.IFormController;
  registrationFormStep2: ng.IFormController;
  registrationFormStep3: ng.IFormController;

  //display Logos
  companyLogo: FileAttachmentDTO;
  profileLogo: FileAttachmentDTO;

  error: string;

  // TODO - JLH - what are these variables?  can we improve the naming?
  //rename Email
  renamingItem: InvitedBrokerDTO;
  newName: InvitedBrokerDTO;

  passwordError: boolean = false;

  isProcessingRegister: boolean = false;

  // Show and hide a input field to add users.
  hideInput: boolean = false;
  showErrorMsg: boolean = false;
  confirmPassword: string;
  selectedProductList: AppPackagePricingDTO[] = [];

  isPayClicked: boolean = false;

  isConvertingExistingUser: boolean = false;

  orgUniqueRef: string;
  orgNameToAssign: string;
  isProceedClicked: boolean = false;
  confirmAssignToOrg: boolean = false;
  isAddonselected: boolean = false;

  locationOptions = [];
  referrerOptions = [];

  static $inject = [
    "FileAttachmentService",
    "$routeParams",
    "$cookies",
    "$location",
    "RoleService",
    "$rootScope",
    "OrganisationService",
    "AuthService",
    "IntroducerService",
    "$window",
    "UserService",
    "DevelopmentInputService",
    "PaymentService",
    "SelectListService",
  ];

  constructor(
    private fileAttachmentService: FileAttachmentService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $roleService: RoleService,
    private $rootScope: ng.IRootScopeService,
    private $organisation: OrganisationService,
    private $auth: AuthService,
    private introduceService: IntroducerService,
    private $window: ng.IWindowService,
    private userService: UserService,
    private developmentInputService: DevelopmentInputService,
    private $paymentService: PaymentService,
    private selectListService: SelectListService,
  ) {
    //TODO Change for New pricing
    window.location.assign("https://brickflow.com/pricing");

    // If we have an introducer code set, let's store a cookie.
    if (this.$routeParams["ic"]) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("introducer_code", this.$routeParams["ic"], {
        expires: expiryDate,
      });
    }

    if (this.$routeParams["orgc"]) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("org_code", this.$routeParams["orgc"], {
        expires: expiryDate,
      });
    }

    if (this.$routeParams["orguniqueref"]) {
      this.orgUniqueRef = this.$routeParams["orguniqueref"];
      this.user.IsOrganisationAdmin = false;

      this.$organisation
        .getOrganisationByUniqueRef(this.orgUniqueRef)
        .then((org: OrganisationDTO) => {
          this.orgNameToAssign = org.Name;
        });
    }

    /* window.onbeforeunload = function (event) {
                //console.log(event);
                //console.log(this.registrationFormStep1.$invalid);
                /*fetch("/api/organisation/brokersignupjourneyg2email?brokerEmail=" + this.user.Email + "&brokerFirstName=" + this.user.FirstName + "&brokerLastName=" + this.user.LastName, {
                    method: "GET",
                    keepalive: true,
                 });*/
    //navigator.sendBeacon("/api/organisation/brokersignupjourneyg2email?brokerEmail=" + this.user.Email + "&brokerFirstName=" + this.user.FirstName + "&brokerLastName=" + this.user.LastName);
    /* if (this.user.Email != null && this.user.FirstName != null && this.user.LastName != null ) {
                    this.$organisation.brokerSignUpJourneyG2Email(this.user.Email, this.user.FirstName, this.user.LastName).then((response) => {
                    });
                }
        }*/

    if (this.$cookies.get("access_token")) {
      this.userService.getcurentuserrecord().then((result) => {
        this.user = result;

        if (this.user.IsLegacyUser || this.orgUniqueRef) {
          this.isConvertingExistingUser = this.user.IsLegacyUser;
          this.user = result;

          this.user.Roles = ["Broker", "Introducer"];

          this.user.IsOrganisationAdmin = !this.orgUniqueRef ? true : false;
          this.user.ApplicantDefinedRole = ApplicantRoleEnum.Introducer;
          this.user.ApplicantDefinedRoleIntroducer = IntroducerTypeEnum.Broker;
        } else {
          this.$roleService.goHomeBasedOnUser();
        }
      });
    } else {
      if (sessionStorage.getItem("userDetails")) {
        let data = sessionStorage.getItem("userDetails");
        let userdetails = JSON.parse(data);

        let userFullName = userdetails.FullName;
        let spaceIndex = userFullName.indexOf(" ");

        let firstName = "";
        let lastName = "";

        // if not space has been added to the name then put the whole name in the first name field
        if (spaceIndex == -1) {
          firstName = userFullName;
        } else {
          firstName = userFullName.substring(0, userFullName.indexOf(" "));
          lastName = userFullName.substring(userFullName.indexOf(" ") + 1);
        }

        this.user.FirstName = firstName;
        this.user.LastName = lastName;
        this.user.Email = userdetails.Email;
        this.user.PhoneNumber = userdetails.PhoneNumber;
      }
    }

    if (sessionStorage.getItem("selectedPackage")) {
      let data = sessionStorage.getItem("selectedPackage");
      this.selectedPackage = JSON.parse(data);
    }

    this.referrerOptions = this.selectListService.GetReferrerOptions();
    this.locationOptions = this.selectListService.GetLocations();
  }

  change() {
    if (this.confirmPassword) {
      if (this.confirmPassword !== this.user.Password) {
        this.error = "Passwords do not match";
      } else {
        delete this.error;
      }
    }
  }

  addNewUser() {
    if (this.newUser.Email != null && this.newUser.FirstName != null) {
      var temp = { ...this.newUser };
      this.userEmails.push(temp);
      this.newUser.Email = null;
      this.newUser.FirstName = null;
      // this.registrationForm.newUserFirstName.$setUntouched()
      //  this.registrationForm.newUserEmail.$setUntouched();
    }
  }

  removeNewUserEmail(event) {
    event.preventDefault();
    delete this.newUser;
  }

  cancelAddingNewUser() {
    this.newUser.Email = null;
    this.newUser.FirstName = null;
    // this.registrationForm.newUserFirstName.$setUntouched();
    //this.registrationForm.newUserEmail.$setUntouched()
  }

  getFocusToInput() {
    var getelement = this.$window.document.getElementById("newuser");
    getelement.focus();
  }

  changeCompanyLogo(files) {
    if (files.length > 0) {
      this.fileAttachmentService
        .uploadPublicImage(files, this.organization.OrganisationCode)
        .then((result) => {
          if (result) {
            this.organization.LogoURL = result;
          }
        });
    }
  }

  changeProfileLogo(files) {
    if (files.length > 0) {
      this.fileAttachmentService
        .uploadFileAndReturnURI(files)
        .then((result) => {
          if (result) {
            this.profileLogo = result;
          }
        });
    }
  }

  registerAccount() {
    this.isPayClicked = true;
    this.confirmAssignToOrg = false;
    this.dataLoading = true;
    this.getStepName();

    if (
      !this.orgUniqueRef &&
      !this.user.IsLegacyUser &&
      this.confirmPassword !== this.user.Password
    ) {
      this.passwordError = true;
      this.dataLoading = false;
      this.isPayClicked = false;
    } else {
      this.userService.sendEventToHubspot(
        this.user,
        this.stepName,
        true,
        this.$cookies.get("org_code"),
        sessionStorage.getItem("userRole"),
      );
      this.passwordError = false;
      this.user.UserName = this.user.Email;
      this.userRegistration();
    }
  }

  userRegistration() {
    this.isProcessingRegister = true;

    this.user.ProfilePictureUrl = this.profileLogo
      ? this.profileLogo.FileLocation
      : null;

    if (this.$cookies.get("introducer_code")) {
      this.user.IntroducerCode = this.$cookies.get("introducer_code");
    }

    if (this.orgUniqueRef) {
      this.$organisation
        .registerBroker(this.user, this.orgUniqueRef)
        .then((convertedBrokerDto: ApplicationUserDTO) => {
          // clean up local storage
          sessionStorage.removeItem("userDetails");
          sessionStorage.removeItem("userRole");
          sessionStorage.removeItem("projectname");

          (this.$rootScope as any).selectedUser = convertedBrokerDto;

          this.$auth
            .login(
              this.user.Email,
              this.user.Password,
              "CC022EBE67BE0424EA2A6548B062D2D71",
            )
            .then((response) => {
              let expiry: Date = response;

              this.$cookies.put("user_firstname", this.user.FirstName, {
                expires: expiry,
              });

              this.cleanupAfterConversion();
            })
            .catch((response) => {
              this.error = response.data.Message;
              this.isProcessingRegister = false;
            });
        })
        .catch((response) => {
          this.error = response.data.ExceptionMessage;
          this.isProcessingRegister = false;
          this.dataLoading = false;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    } else {
      this.organization.OrganisationType = OrganisationTypeEnum.Broker;
      this.organization.CommissionPercent = 0.01;

      this.user.Location = this.organization.Location;

      var registerOrgDto: RegisterOrganisationDTO = {
        OrganisationDTO: this.organization,
        OrganisationAdminUserDTO: this.user,
        InviteOrganisationUsers: this.userEmails,
      };

      this.$organisation
        .registerOrganisationAndAdmin(registerOrgDto)
        .then((registerOrganisationDto: RegisterOrganisationDTO) => {
          // clean up session storage
          sessionStorage.removeItem("userDetails");
          sessionStorage.removeItem("userRole");
          sessionStorage.removeItem("projectname");

          (this.$rootScope as any).selectedUser =
            registerOrganisationDto.OrganisationAdminUserDTO;

          if (
            this.$cookies.get("access_token") &&
            this.isConvertingExistingUser
          ) {
            this.cleanupAfterConversion();
          } else if (!this.$cookies.get("access_token")) {
            // Automatically log in the broker org admin
            this.$auth
              .login(
                this.user.Email,
                this.user.Password,
                "CC022EBE67BE0424EA2A6548B062D2D71",
              )
              .then((response) => {
                let expiry: Date = response;

                this.$cookies.put("user_firstname", this.user.FirstName, {
                  expires: expiry,
                });

                this.onRegistrationComplete();
              })
              .catch((response) => {
                this.error = response.data.Message;
                this.isProcessingRegister = false;
              });
          } else {
            this.error = "logged in but not converting an existing user";
            this.isProcessingRegister = false;
            this.dataLoading = false;
          }
        })
        .catch((response) => {
          this.error = response.data.ExceptionMessage;
          this.isProcessingRegister = false;
          this.dataLoading = false;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  onRegistrationComplete() {
    if (this.selectedPackage) {
      /* this.selectedPackage.Quantity = this.seats;*/
      this.selectedProductList.push(this.selectedPackage);
      this.createCheckoutSession();
    } else {
      this.$location.path("/userdashboard");
    }
  }

  cleanupAfterConversion() {
    // clear and reset cached data based on the new information

    this.$auth.getProfile().then((response) => {
      this.$cookies.put("user_firstname", response.FirstName);
      (this.$rootScope as any).selectedUser = response;
    });

    (this.$rootScope as any).selectedUser = null;
    (this.$rootScope as any).currentUser = null;

    this.$roleService.GetUserRoles(true); //reset user roles

    sessionStorage.setItem("isUpgradedUser", "true");

    this.onRegistrationComplete();
  }

  registerDisabled() {
    if (
      this.organization &&
      this.organization.Location &&
      this.organization.PhoneNumber
    ) {
      return false;
    } else {
      return true;
    }
  }

  registerIntroducerByUserId(userId: string): void {
    var introducer = {
      UserId: userId,
      IsIndividual: false,
      IntroducerType: IntroducerTypeEnum.Broker,
      CompanyName: this.organization.Name,
      CompanyURL: this.organization?.CompanyURL,
    } as IntroducerDTO;

    this.introduceService
      .registerIntroducer(introducer)
      .then((success) => {})
      .catch((error) => {
        this.error =
          "It looks like there was a problem registering you as an introducer. Please try again in a moment.";
      });
  }

  removeEmail(item: InvitedBrokerDTO) {
    this.userEmails = this.userEmails.filter((x) => x != item);
  }

  renameItem(item: InvitedBrokerDTO) {
    if (this.renamingItem === undefined) {
      this.renamingItem = item;
      this.newName = item;
      this.tempdata = { ...this.renamingItem };
    } else {
      delete this.renamingItem;
    }
  }

  renamingItemComplete(item: InvitedBrokerDTO) {
    this.userEmails = this.userEmails.filter((x) => x != item);
    this.userEmails.push(this.newName);
    this.renamingItem = null;
  }

  cancelRenaming(item: InvitedBrokerDTO) {
    this.userEmails = this.userEmails.filter((x) => x != item);
    this.userEmails.push(this.tempdata);
    delete this.renamingItem;
    //this.registrationForm.editUserEmail.$setUntouched()
    //this.registrationForm.editUserFirstName.$setUntouched()
  }

  /*   back() {
            this.tourOrder--;
            this.welcomeMessage = "Welcome to the Brickflow for Brokers - to setup your account we need some details on you and your company.";
        }
        next() {
            this.tourOrder++;
            this.welcomeMessage = "Impress your clients with Brickflow Plus; add your company logo and profile picture, to personalise the software.";
        }
        */

  fillRegisterForm() {
    let name = new Date().toLocaleString().replace(/ |,|:|\/|/g, "");
    this.organization.Name = name;
    this.organization.CompanyURL = "www.google.com";
    this.organization.Location = 1;
    this.organization.PhoneNumber = "12345677";
    this.user.Email = `${name}@test.com`;
    this.user.FirstName = `${name}Admin`;
    this.user.LastName = "t";
    this.user.PhoneNumber = "12345677";
    this.user.Referrer = 1;
    this.user.Representative = "None";
    this.organization.FCANumber = "12344";
    this.organization.TagLine = `${name}tag`;
    this.user.Password = "test";
    this.confirmPassword = "test";
  }

  next() {
    this.getStepName();

    if (!this.user.IsLegacyUser) {
      this.userService
        .checkEmailExists(this.user.Email)
        .then((emailExists: boolean) => {
          if (emailExists == true) {
            this.error = "Email address is already in use, please sign in.";
          } else {
            this.userService.sendEventToHubspot(
              this.user,
              this.stepName,
              false,
              this.$cookies.get("org_code"),
              sessionStorage.getItem("userRole"),
            );
            this.step++;
          }
        });
    } else {
      this.userService.sendEventToHubspot(
        this.user,
        this.stepName,
        false,
        this.$cookies.get("org_code"),
        sessionStorage.getItem("userRole"),
      );
      this.step++;
    }
  }

  back() {
    this.step--;
  }

  proceed() {}

  calculateBarPercentage(index: number, value: string) {
    if (!this.formEntered[index] && (value || value != "")) {
      this.bar += 8;
      this.formEntered[index] = !this.formEntered[index];
    } else if (
      this.formEntered[index] &&
      (value == undefined || value == "" || value == null)
    ) {
      this.bar -= 8;
      this.formEntered[index] = !this.formEntered[index];
    }
  }

  isFirstStepValid() {
    if (
      !this.registrationFormStep1.$invalid &&
      !this.error &&
      !this.passwordError
    ) {
      this.progressBar[0].complete = true;
      return true;
    } else {
      this.progressBar[0].complete = false;
      return false;
    }
  }

  isSecondStepValid() {
    if (!this.registrationFormStep2.$invalid) {
      this.progressBar[1].complete = true;
      return true;
    } else {
      this.progressBar[1].complete = false;
      return false;
    }
  }

  isThirdStepValid() {
    if (
      !this.registerDisabled() &&
      this.user.AgreedToTermsAndPP === true &&
      !this.isProcessingRegister &&
      !this.registrationFormStep3.$invalid
    ) {
      this.progressBar[2].complete = true;
      return true;
    } else {
      this.progressBar[2].complete = false;
      return false;
    }
  }

  getTotalCost(): number {
    if (this.selectedPackage) {
      return this.selectedPackage.PriceAmount * this.seats;
    } else {
      return 0;
    }
  }

  getPricingFrequency(): string {
    var frequencyText = "Month";
    if (this.selectedPackage) {
      switch (this.selectedPackage.PricingFrequency) {
        case PricingFrequencyEnum.Yearly: {
          frequencyText = "Year";
          break;
        }
        case PricingFrequencyEnum.Monthly:
        default:
          frequencyText = "Month";
      }
    }
    return frequencyText;
  }

  createCheckoutSession() {
    //this.selectedProductList[0].SelectedModuleAppFeature = this.moduleAppFeatures;
    // Need to save the search so we can get back to it after payment ($rootscope will lose the data)
    if (sessionStorage.getItem("addAddon")) {
      this.isAddonselected =
        sessionStorage.getItem("addAddon") == "true" ? true : false;
    }

    if ((this.$rootScope as any).loanCriteria != null) {
      (this.$rootScope as any).loanCriteria.UserId = (
        this.$rootScope as any
      ).selectedUser.Id;
      (this.$rootScope as any).loanCriteria.BrokerOrganisationId = (
        this.$rootScope as any
      ).selectedUser.OrganisationId;
      this.developmentInputService
        .saveSearch((this.$rootScope as any).loanCriteria)
        .then((developmentInputId: number) => {
          this.$paymentService
            .createCheckoutSession(
              this.selectedPackage,
              this.isAddonselected,
              "",
              developmentInputId,
            )
            .then((response) => {
              window.location.assign(response.SessionUrl);
            });
        });
    } else {
      this.$paymentService
        .createCheckoutSession(
          this.selectedPackage,
          this.isAddonselected,
          "",
          null,
        )
        .then((response) => {
          window.location.assign(response.SessionUrl);
        });
    }
  }

  cancelCheckout() {
    if ((this.$rootScope as any).loanCriteria) {
      this.$location.path("/results");
    } else {
      this.$location.path("/userdashboard");
    }
  }

  getApplicationName() {
    if (sessionStorage.getItem("applicationName") != null) {
      return sessionStorage.getItem("applicationName");
    } else {
      return "Brickflow";
    }
  }

  getStepName() {
    switch (this.step) {
      case 1:
        this.stepName = "REGISTRATIONUSERDETAILS";
        break;
      case 2:
        this.stepName = "REGISTRATIONCOMPANYDETAILS";
        break;
      case 3:
        this.stepName = "REGISTATIONFINALSTEP";
        break;
    }
  }
}

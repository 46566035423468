import { BridgingDealDTO } from "@js/DTO/Deal/BridgingDealDTO.cs.d";
import { SaveBridgingSearchRequest } from "@js/DTO/Messages/Deal/SaveBridgingSearchMessage.cs.d";
import {
  UpdateBridgingSearchGetResultResponse,
  UpdateBridgingSearchGetResultRequest,
} from "@js/DTO/Messages/Deal/UpdateBridgingSearchGetResultMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { ExistingPlanningChangeTypeEnum } from "@js/models/enum/ExistingPlanningChangeTypeEnum.cs.d";
import { InterestServicedorRolledEnum } from "@js/models/enum/InterestServicedorRolledEnum.cs.d";
import { LandOrPropertyEnum } from "@js/models/enum/LandOrPropertyEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PropertyTypeDetailEnum } from "@js/models/enum/PropertyTypeDetailEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { YesNoMaybe } from "@js/models/enum/YesNoMaybeEnum.cs.d";
import { DealService } from "./DealService";
import { SaveSearchReturnUniqueRefResponse } from "@js/models/SaveSearchReturnUniqueRef.cs";

export class BridgingDealService extends BaseService<BridgingDealDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
    "DealService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
    private dealService: DealService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/bridgingdeal";
    this.$broadcastBusinessNameSingular = "bridgingdeal";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  /**
   * Updates the search Deal based on the BridgingDealDTO and returns the search results on the new criteria
   * @param criteriaDto
   * @param criteriaChanged only set to true when the search criteria changes, and not the filters. This controls data snapshotting.
   */
  updateSearchCriteriaAndGetResults(
    criteriaDto: BridgingDealDTO,
    criteriaChanged: boolean = false,
    debug1: boolean,
    isPostcodeChanged: boolean = false,
    isShortlistingMore: boolean = false
  ): ng.IPromise<UpdateBridgingSearchGetResultResponse> {
    let defer = this.$q.defer<UpdateBridgingSearchGetResultResponse>();

    var request: UpdateBridgingSearchGetResultRequest = {
      BridgingDealDto: criteriaDto,
      CriteriaChanged: criteriaChanged,
      Limit: 0, //TODO: typings aren't picking up default values
      Debug1: debug1, //TODO: typings aren't picking up default values
      Debug2: false, //TODO: typings aren't picking up default values
      IsPostcodeChange: isPostcodeChanged,
      IsShortlistingMore :isShortlistingMore
    };

    this.$http
      .post(
        this.$baseAddress + "/updatesearchandgetresults",
        JSON.stringify(request),
      )
      .then((response) => {
        var res = response.data as UpdateBridgingSearchGetResultResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveBridgingSearchReturnsId(
    request: SaveBridgingSearchRequest,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.$http
      .post(this.$baseAddress + "/savebridgingsearchreturnsId", request)
      .then((response) => {
        var res = response.data as number;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveBridgingSearchReturnsUniqueRef(
    request: SaveBridgingSearchRequest,
  ): ng.IPromise<SaveSearchReturnUniqueRefResponse> {
    let defer = this.$q.defer<SaveSearchReturnUniqueRefResponse>();

    this.$http
      .post(this.$baseAddress + "/savebridgingsearchreturnsuniqueref", request)
      .then((response) => {
        var res = response.data as SaveSearchReturnUniqueRefResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getCountries(): ng.IPromise<[]> {
    let defer = this.$q.defer<[]>();
    this.$http
      .get(this.$baseAddress + "/getcountries")
      .then((response) => {
        defer.resolve(response.data as []);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getNationalities(): ng.IPromise<[]> {
    let defer = this.$q.defer<[]>();
    this.$http
      .get(this.$baseAddress + "/getnationalities")
      .then((response) => {
        defer.resolve(response.data as []);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  debugSearch(dealDto: BridgingDealDTO): ng.IPromise<[]> {
    let defer = this.$q.defer<[]>();
    this.$http
      .get(this.$baseAddress + "/debugSearch")
      .then((response) => {
        defer.resolve(response.data as []);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  clearSearchdata(dealDto) {
    let tempDealDto = dealDto;
    dealDto = {} as BridgingDealDTO;
    dealDto.MaxLoanRequired = true;
    dealDto.OwnOrPurchase = tempDealDto.OwnOrPurchase;
    dealDto.Locations = tempDealDto.Locations;
    dealDto.HasIsFamilyInResidence = tempDealDto.HasIsFamilyInResidence;
    dealDto.IsFamilyInResidence = tempDealDto.IsFamilyInResidence;
    dealDto.ProductType = tempDealDto.ProductType;
    dealDto.LoanCompletionType = tempDealDto.LoanCompletionType;
    dealDto.PropertyAddress = tempDealDto.PropertyAddress;

    if (!dealDto.HasIsFamilyInResidence) {
      dealDto.HasIsFamilyInResidence = tempDealDto.HasIsFamilyInResidence;
      dealDto.HasOwnOrPurchase = false;
      dealDto.OwnOrPurchase = OwnOrPurchaseEnum.Purchasing;
      dealDto.LandOrProperty =
        dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance
          ? LandOrPropertyEnum.Property
          : LandOrPropertyEnum.Land;
      dealDto.HasLandOrProperty =
        dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance
          ? true
          : false;
    }
    return dealDto;
  }

  datasetupOnOwnOrPurchaseChange(dealDto) {
    if (
      dealDto.OwnOrPurchase != null &&
      dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing
    ) {
      dealDto.OriginalPurchaseDate = null;
      dealDto.Currentvalue = null;
    }
    return dealDto;
  }

  datasetupOnLandorPropertyChange(dealDto) {
    if (dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction) {
      if (dealDto.LandOrProperty == LandOrPropertyEnum.Property) {
        dealDto.EndPropertyType = PropertyTypeEnum.None;
        dealDto.IsTenantedBuilding = null;
        dealDto.BuildingCondition = null;
        dealDto.LandTotalOtherCosts = 0;
        dealDto.IsMortgaged = false;
        dealDto.MortgageCurrentLender = "";
        dealDto.MortgageCurrentBalance = 0;
      } else if (dealDto.LandOrProperty == LandOrPropertyEnum.Land) {
        dealDto.PurchaseCosts = 0;
      }
    } else if (
      dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance
    ) {
      if (dealDto.LandOrProperty == LandOrPropertyEnum.Property) {
        dealDto.EndPropertyType = PropertyTypeEnum.Residential;
      } else if (dealDto.LandOrProperty == LandOrPropertyEnum.Land) {
        dealDto.EndPropertyType = PropertyTypeEnum.None;
        dealDto.PropertyTypeDetail = PropertyTypeDetailEnum.None;
        dealDto.PropertyTenure = null;
        dealDto.IsTenantedBuilding = false;
        dealDto.BuildingCostTodate = 0;
        dealDto.IsPropertyImprovementDuringLoanTerm = false;
        dealDto.IsExistingPlanChange = false;
        dealDto.ExistingPlanningChangeType =
          ExistingPlanningChangeTypeEnum.None;
        dealDto.PurchasePrice = 0;
        dealDto.PurchaseCosts = 0;
        dealDto.HasCompletionDate = YesNoMaybe.No;
        dealDto.CompletionDate = null;
        dealDto.NumberOfTenancies = null;
        dealDto.GrossAnnualRentalReceipts = null;
        dealDto.BuildingCondition = null;
        dealDto.Currentvalue = 0;
      }
    }

    return dealDto;
  }

  datasetupOnInterestServiceChange(dealDto) {
    if (dealDto.InterestService == InterestServicedorRolledEnum.Rolled) {
      dealDto.SourceOfIncome = null;
    }
    return dealDto;
  }

  datasetupOnIsTenantedBuildingChange(dealDto: BridgingDealDTO) {
    if (!dealDto.IsTenantedBuilding) {
      dealDto.NumberOfTenancies = null;
      dealDto.GrossAnnualRentalReceipts = null;
      if (dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance) {
        dealDto.BuildingCondition = null;
      }
    }
    return dealDto;
  }

  datasetupOnEndPropertyTypeChange(dealDto) {
    if (
      dealDto.EndPropertyType != PropertyTypeEnum.MixedUse &&
      dealDto.EndPropertyType != PropertyTypeEnum.Residential
    ) {
      dealDto.PropertyTypeDetail = null;
      dealDto.HowManyFlats = null;
      dealDto.HowManyHouses = null;
    }
    return dealDto;
  }

  datasetupOnHasCompletionDateChange(dealDto) {
    if (dealDto.HasCompletionDate != 1) {
      dealDto.CompletionDate = null;
    }
    return dealDto;
  }
}

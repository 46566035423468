import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";

export class SharedDataService {
  static $inject = ["$q", "OrganisationService"];

  userRole: string = "";
  userDetails: string = null;
  projectName: string = null;
  isBorrower: boolean = false;
  user: ApplicationUserDTO = null;
  clientId: string = null;
  applicationName: string = null;
  organisationService: any;
  organisationLinkId: string = null;
  leadSourceUrl: string = null;

  constructor(
    private $q: ng.IQService,
    OrganisationService: any,
  ) {
    this.organisationService = OrganisationService;
  }

  initializeAsync(bridging: boolean): ng.IPromise<void> {
    let defer = this.$q.defer<void>();

    let promiseArray = [
      this.setBorrowerFlag(),
      this.getUser(),
      this.getProjectName(),
      this.getApplicationName(),
      this.getOrganisationLinkId(),
    ];

    if (bridging) {
      promiseArray.push(this.getClientId());
    }

    Promise.all(promiseArray)
      .then(() => {
        defer.resolve();
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        defer.reject(error);
      });
    return defer.promise;
  }

  cleanLenderReferralSessionStorage() {
    if (sessionStorage.getItem("lenderReferralData")) {
      sessionStorage.removeItem("lenderReferralData");
    }
  }

  private async getLeadSourceUrl(): Promise<void> {
    if (window.self === window.top) {
      this.leadSourceUrl = sessionStorage.getItem("sourceUrl");
    } else {
      this.leadSourceUrl = await this.organisationService.getData("sourceUrl");
    }
  }

  private async getOrganisationLinkId(): Promise<void> {
    if (window.self === window.top) {
      this.organisationLinkId = sessionStorage.getItem("organisationLinkId");
    } else {
      this.organisationLinkId =
        await this.organisationService.getData("organisationLinkId");
    }
  }

  private async setBorrowerFlag(): Promise<void> {
    if (window.self === window.top) {
      this.userRole = sessionStorage.getItem("userRole");
      this.isBorrower = this.userRole === "borrower";
    } else {
      this.userRole = await this.organisationService.getData("userRole");
      this.isBorrower = this.userRole === "borrower";
    }
  }

  private async getUser(): Promise<void> {
    if (window.self === window.top) {
      this.user = JSON.parse(sessionStorage.getItem("userDetails"));
    } else {
      this.userDetails = await this.organisationService.getData("userDetails");
      this.user = JSON.parse(this.userDetails);
    }
  }

  private async getProjectName(): Promise<void> {
    if (window.self === window.top) {
      this.projectName = sessionStorage.getItem("projectname");
    } else {
      this.projectName = await this.organisationService.getData("projectname");
    }
  }

  private async getApplicationName(): Promise<void> {
    if (window.self === window.top) {
      this.applicationName = sessionStorage.getItem("applicationName");
    } else {
      this.applicationName =
        await this.organisationService.getData("applicationName");
    }
  }

  private async getClientId(): Promise<void> {
    if (window.self == window.top) {
      this.clientId = sessionStorage.getItem("clientId");
    } else {
      this.clientId = await this.organisationService.getData("clientId");
    }
  }

}

// AngularJS service registration

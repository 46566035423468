import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { OrganisationLinkDTO } from "@js/DTO/OrgnisationLinkDTO.cs.d";
import { LinkTypeEnum } from "@js/models/enum/LinkTypeEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { SharedCriteriaService } from "@js/services/Deal/SharedCriteriaService";
import { EventLogService } from "@js/services/EventLogService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationLinkService } from "@js/services/OrganisationLinkService";
import { OrganisationService } from "@js/services/OrganisationService";
import { UserService } from "@js/services/UserService";


export class AllLoansLandingController {
  isLoggedInUser: boolean = false;
  totalLenders: number = 0;
  isBorrower: boolean = false;
  orgCode: string;
  organisationLink: OrganisationLinkDTO;
  previewContent: boolean = false;
  organisation: OrganisationDTO;

  //error message to enterprise client when organisation does not have a valid permission
  noPermissionErrorMsg: string = "";
  dataLoading: boolean = false;

  isCommercialOwnerOccupiedActive: boolean = false;

  showHelpModal: boolean = false;
  headerProductFamily: ProductFamilyEnum = ProductFamilyEnum.None;
  enterpriseLinkLogo: string;
  showBridgingOption: boolean = false;
  showCommercialOption: boolean = false;
  showDevFinaceOption: boolean = true;
  isBridgingDisabled: boolean = false;
  isCommercialDisabled: boolean = false;

  isWidget: boolean = false;
  showCriteriaSection: boolean = false;
  isMobileView: boolean = false;
  static $inject = [
    "$scope",
    "$routeParams",
    "$cookies",
    "$location",
    "$q",
    "AuthService",
    "OrganisationService",
    "LenderService",
    "EventLogService",
    "OrganisationLinkService",
    "SharedCriteriaService",
    "UserService"
  ];

  constructor(
    private $scope: ng.IScope,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    protected $q: ng.IQService,
    private $auth: AuthService,
    private organisationService: OrganisationService,
    private lenderService: LenderService,
    private eventLogService: EventLogService,
    private organisationLinkService: OrganisationLinkService,
    private sharedCriteriaService: SharedCriteriaService,
    private userService: UserService,

  ) {
    this.getNumberOfLenders();

    this.$auth.isCommercialOwnerOccupiedActive().then((response) => {
      this.isCommercialOwnerOccupiedActive = response;
    });

    if (this.$routeParams.context == 'widget' && window.self != window.top) {
        this.organisationService.sendDataToParent("height", "170px");
        document.getElementById("footer").style.display = "none";
        this.isWidget = true;
    }else if (window.innerWidth <= 480 && window.self == window.top){
      this.isMobileView = true;
    }


    if ($cookies.get("access_token")) {
      this.isLoggedInUser = true;
      this.setProductButtonStateAndVisibility();
    } else if (this.$location.path().startsWith("/allloans") && !$cookies.get("access_token")) {
         this.sharedCriteriaService.storeEnterpriseLinkType(LinkTypeEnum.AllLoans.toString());
        if (window.self == window.top) {
          this.orgCode = this.$cookies.get("org_code");
        } else {
          this.orgCode = location.pathname.replace("/", "");
        }

        this.organisationService
          .getOrgByUrlIfOrgHasEnterpriseSearchPermission(
            this.orgCode,
            ProductFamilyEnum.None,
            this.$routeParams.linkId,
          )
          .then((response) => {
            if (response) {
              this.organisation = response;
              this.orgCode = response.OrganisationCode;
              this.showBridgingOption = response.HasBridging;
              this.showCommercialOption = response.HasCommercialMortgage;

              if( (this.isWidget && this.organisation.HasWidget) || !this.isWidget ){
                this.selectDefaultProductFamily();
                this.logEvent();
                var imgs = document.getElementsByTagName("img");
  
                if (this.$routeParams.linkId && this.$routeParams.linkId != "0") {
                  this.organisationLinkService
                    .getOrganisationLinkLogoByLinkIdAndLogPageLoadEvent(
                      this.$routeParams.linkId
                    )
                    .then((logoUrl) => {
                        if(logoUrl) imgs[0].src = this.enterpriseLinkLogo = logoUrl; 
                    });
                } else {
                  this.enterpriseLinkLogo = imgs[0].src;
                }
              }else{
                this.organisationService.getOrganisationAdminByOrgCode(this.orgCode).then((admin) => {
                    this.noPermissionErrorMsg = admin ? `We are unable to compare loans right now. Please contact your broker: ${admin.Email}.` :
                                                      "We are unable to compare loans right now.  Please contact your broker.";
                })
              }             
            } else {
              this.noPermissionErrorMsg =
                "We are unable to compare loans right now.  Please contact your broker.";
            }
          });
    }

    //This event is added to navigate user to devfinance critera on click of devfinance link from helper text, when user is on Bare Land path for purchase/refinance search.

    let productFamilySelectionUnregister = $scope.$on("productFamilySelection", (event: ng.IAngularEvent,newValue) => {
      this.updateProdcutFamilyOption(newValue);
    });

    $scope.$on("$destroy", productFamilySelectionUnregister);
  }

  getNumberOfLenders() {
    this.lenderService
      .getTotalLenders(ProductFamilyEnum.None)
      .then((totalLenders: number) => {
        this.totalLenders = Math.floor(totalLenders / 5) * 5;
      });
  }

  updateProdcutFamilyOption(val){
    this.headerProductFamily = val;
  }


  async initialRegisterEventLog(orgCode, organisationLinkId = null) {
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          "ALLLOANS",
          orgCode,
          "",
          "Client",
          organisationLinkId,
          ProductTypeEnum.None,
          undefined,
          undefined,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          "ALLLOANS",
          orgCode,
          "",
          "Client",
          organisationLinkId,
          ProductTypeEnum.None,
        );
      }
    } else {
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");
      if (clientId) {
        this.eventLogService.logPageLoad(
          "ALLLOANS",
          orgCode,
          "",
          "Client",
          organisationLinkId,
          ProductTypeEnum.None,
          undefined,
          undefined,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          "ALLLOANS",
          orgCode,
          "",
          "Client",
          organisationLinkId,
          ProductTypeEnum.None,
        );
      }
    }
  }

  logEvent() {
    if (this.$routeParams.linkId) {
      if (this.orgCode) {
        this.initialRegisterEventLog(
          this.orgCode,
          Number(this.$routeParams.linkId),
        );
      } else {
        this.initialRegisterEventLog("", Number(this.$routeParams.linkId));
      }
    } else {
      if (this.orgCode) {
        this.initialRegisterEventLog(this.orgCode);
      } else {
        this.initialRegisterEventLog("");
      }
    }
  }


  onClickShowHelpModal() {
    this.showHelpModal = true;
    if (this.isWidget && !this.showCriteriaSection)
      this.organisationService.sendDataToParent("height", "745px");
  }

  closeHelpModal() {
    if (this.isWidget && !this.showCriteriaSection)
      this.organisationService.sendDataToParent("height", "170px");
    this.showHelpModal = false;
  }

  selectDefaultProductFamily() {
    if (window.innerWidth > 767) {
      if (this.showBridgingOption && !this.isBridgingDisabled) {
        this.headerProductFamily = ProductFamilyEnum.Bridging;
      } else if (this.showCommercialOption && !this.isCommercialDisabled) {
        this.headerProductFamily = ProductFamilyEnum.Commercial;
      } else {
        this.headerProductFamily = ProductFamilyEnum.Development;
      }
      this.showCriteriaSection = true;
    } 
  }

  setProductButtonStateAndVisibility() {    
      this.userService
      .getCurrentUserPermissionForProductSearch(
      )
      .then((response) => {
        if (response) {
          if (response.IsLender) {
            this.showBridgingOption = response.HasBridgingSearchPermission;
            this.showDevFinaceOption = response.HasDevFinanceSearchPermission;
            this.showCommercialOption = response.HasCommercialSearchPermission;
          } else {
            this.isBridgingDisabled = !response.HasBridgingSearchPermission;
            this.isCommercialDisabled = !response.HasCommercialSearchPermission;
            this.showBridgingOption = true;
            this.showDevFinaceOption = true;
            this.showCommercialOption = true;
          }  
          
          this.selectDefaultProductFamily();

        } else {
            //This is to navigate a user to userdashboard when they try to access path directly
            this.$location.path("/userdashboard");
        }
      });
      
  }


  showorHideCriteriaSection(){
    if(this.showCriteriaSection){
      this.organisationService.sendDataToParent("height", "170px");
    }
    this.showCriteriaSection = !this.showCriteriaSection;
  }

  footerSignInClicked(){
    var baseUrl = window.location.href.split("#!")[0] + '#!';
    var newUrl = `${baseUrl}/login`;
    window.open(newUrl, "_blank");
  }
}

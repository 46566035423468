export const enum IntroducerTypeEnum {
  Accountant = 1,
  Architect = 2,
  Broker = 3,
  Builder = 4,
  Developer = 5,
  EstateOrLandAgent = 6,
  IFA = 7,
  ProjectManager = 8,
  PropertyManager = 9,
  QuantitySurveyor = 10,
  Solicitor = 11,
  Trustee = 12,
  Other = 13,
}

import { DealFileAttachmentDTO } from "@js/DTO/Deal/DealFileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";

export class DealFileAttachmentService extends BaseService<
  DealFileAttachmentDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/dealfileattachment";
    this.$broadcastBusinessNameSingular = "dealfileattachment";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getFileUploadUri(
    fileName: string,
    fileType: string,
    dealId: number,
    module: ModuleEnum,
    dealLenderId: number,
    dealClientId: number,
  ): ng.IPromise<DealFileAttachmentDTO> {
    let defer = this.$q.defer<DealFileAttachmentDTO>();
    this.$http
      .get(this.$baseAddress + "/fileuploaduri", {
        params: {
          fileName: fileName,
          fileType: fileType,
          dealId: dealId,
          module: module,
          dealLenderId: dealLenderId,
          dealClientId: dealClientId,
        },
      })
      .then((response) => {
        defer.resolve(response.data as DealFileAttachmentDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getFileUri(fileName: string): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(this.$baseAddress + "/fileuri?key=" + fileName)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  renameFile(file: DealFileAttachmentDTO): ng.IPromise<DealFileAttachmentDTO> {
    let defer = this.$q.defer<DealFileAttachmentDTO>();
    this.$http
      .put(this.$baseAddress + "/filerename", JSON.stringify(file))
      .then((response) => {
        defer.resolve(response.data as DealFileAttachmentDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  UploadFileListInSync(
    files,
    uploadingFiles: FileUploadProgressDTO[],
    objectid: number,
    fileLstOutput: DealFileAttachmentDTO[],
    module?: ModuleEnum,
    caseLenderId?: number,
    dealClientId?: number,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    var file = files.pop();
    if (!uploadingFiles) {
      uploadingFiles = [];
    }
    if (file) {
      let tmpfile: FileUploadProgressDTO = {
        uploadingFile: file.name,
        uploadPercent: 0,
      };
      uploadingFiles.push(tmpfile);
      //Get link needed to upload file
      this.getFileUploadUri(
        file.name,
        file.type,
        objectid,
        module,
        caseLenderId,
        dealClientId,
      )
        .then((fileattach: DealFileAttachmentDTO) => {
          //send file up
          // this.$http.put(fileattach.TempUploadURL, files[0], { headers: { 'Content-Type': type }, uploadEventHandlers: {} })
          this.$http({
            method: "PUT",
            url: fileattach.TempUploadURL,
            data: file,
            headers: { "Content-Type": file.type },
            //eventHandlers: {
            //    readystatechange: function (event: any) {
            //        if (event.currentTarget.readyState === 4) {
            //        }
            //    }
            //},
            uploadEventHandlers: {
              progress(evt: any) {
                tmpfile.uploadPercent = 100.0 * (evt.loaded / evt.total);
              },
            },
          })
            .then((response) => {
              //file now sent up
              //save attachment record to srever
              this.addUpdatereturnonlyid(fileattach).then((id) => {
                //append to list of files
                fileattach.Id = id;
                fileLstOutput.push(fileattach);
                //remove the object
                if (uploadingFiles) {
                  uploadingFiles.splice(uploadingFiles.indexOf(tmpfile));
                  //hide uploads of all done
                  if (uploadingFiles.length === 0) {
                    uploadingFiles = null;
                  }
                }
              });
              // this.$scope.$apply();
            })
            .catch((response) => {
              if (uploadingFiles) {
                uploadingFiles.splice(uploadingFiles.indexOf(tmpfile));
              }
              //hide uploads of all done
              if (uploadingFiles.length === 0) {
                uploadingFiles = null;
              }
            });
        })
        .finally(() => {
          this.UploadFileListInSync(
            files,
            uploadingFiles,
            objectid,
            fileLstOutput,
            module,
            caseLenderId,
            dealClientId,
          );
        });
      return defer.promise;
    } else {
      return this.$q.resolve("No files to upload.");
    }
  }

  /**
   * Fetch all file attachments for the deal ID
   * @param dealId
   */
  fetchListByDealId(dealId: number): ng.IPromise<DealFileAttachmentDTO[]> {
    let defer = this.$q.defer<DealFileAttachmentDTO[]>();
    this.$http
      .get(this.$baseAddress + "/fetchlistbydealid?dealId=" + dealId)
      .then((response) => {
        defer.resolve(response.data as DealFileAttachmentDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

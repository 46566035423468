import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseMemberDTO } from "@js/DTO/CaseMemberDTO.cs.d";
import { DevelopmentTrackRecordDTO } from "@js/DTO/DevelopmentFinance/DevelopmentTrackRecordDTO.cs.d";
import { ProjectOwnershipDTO } from "@js/DTO/DevelopmentFinance/ProjectOwnershipDTO.cs.d";
import { AreaUnitEnum } from "@js/models/enum/AreaUnitEnum.cs.d";
import { CaseAccessLevelEnum } from "@js/models/enum/CaseAccessLevelEnum.cs.d";
import { OwnerOrEmployeeEnum } from "@js/models/enum/OwnerOrEmployeeEnum.cs.d";
import { OwnNewDevelopmentEnum } from "@js/models/enum/OwnNewDevelopmentEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { ApplicantService } from "@js/services/ApplicantService";
import { AuthService } from "@js/services/AuthService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { DevelopmentExperienceService } from "@js/services/DevelopmentExperienceService";
import { RoleService } from "@js/services/RoleService";
import { UserService } from "@js/services/UserService";

export class DevelopmentExperienceController {
  isLoggedInUser: boolean = false;

  case: CaseDTO;
  caseTitle: string;

  message: string = "";
  modal: boolean = false;

  //Check if shareholder is Admin or current user
  isAdmin: boolean = false;
  shareholder: CaseMemberDTO;
  selectedUser: ApplicationUserDTO;
  isPrimaryApplicant: boolean;

  //share shareholder
  shareContext: number = null;
  shareSuplId: number = null;
  userFirstName: string;
  shareNote: string;
  shareFirstName: string;
  shareSurname: string;
  shareEmail: string;
  showShare: boolean = false;

  dataLoading: boolean = false;
  selectedObject: ProjectOwnershipDTO;
  maxDateOfBirth: Date = new Date();
  minDateOfBirth: Date = new Date("Jan 01 1900");
  fetchingObjects: boolean;
  fetchingObject: boolean;
  show: boolean[] = [];
  showAutofill: boolean = false;

  step: number = 1;
  caseid: number;

  formattedAcquisitionDate: any[] = [];

  error: boolean = false;
  saving: boolean = false;
  saved: boolean = false;

  devexperienceId: number;

  multiPartForm1: ng.IFormController;
  assetform: ng.IFormController;

  //array describing form sections
  formSectionNames = [
    {
      label: "Ownership",
      visible: true,
    },
    {
      label: "Shareholders",
      visible: true,
    },
    {
      label: "Track Record",
      visible: true,
    },
  ];

  applicantNo: number = 0;

  guidanceCheckbox: boolean = true;

  applicantForm: ng.IFormController;
  showDelete: boolean = false;
  confirmDeleteApplicant: CaseMemberDTO;
  tempApplicant: CaseMemberDTO;
  showQuickEditModal: boolean = false;
  addEditApplicant: CaseMemberDTO;
  disableQuickEditSave: boolean = false;
  isAddShareholder: boolean;

  showAdd: boolean = false;
  showEdit: boolean = false;
  editApplicant: CaseMemberDTO;
  newApplicant: CaseMemberDTO;

  owners: CaseMemberDTO[];
  IsBroker: boolean = false;
  maxDateForDateField: Date = new Date("Jan 01 3000");

  howWillYouOwnNewDevelopmentOptions = [
    { label: "UK Limited Company", value: 1 },
    { label: "UK Limited Liability Partnership", value: 2 },
    { label: "Personal Names", value: 3 },
    { label: "Overseas Limited Company", value: 4 },
    { label: "Pension Fund", value: 5 },
    { label: "Other(Please Specify)", value: 6 },
  ];

  static $inject = [
    "$window",
    "$rootScope",
    "$q",
    "$routeParams",
    "DevelopmentExperienceService",
    "ApplicantService",
    "$location",
    "CaseService",
    "$cookies",
    "CaseMemberService",
    "RoleService",
    "UserService",
    "AuthService",
  ];

  //private $subobjectservice: App.Services.SubobjectService
  constructor(
    private $window: ng.IWindowService,
    private $rootScope: ng.IRootScopeService,
    protected $q: ng.IQService,
    private $routeParams: ng.route.IRouteParamsService,
    private $developmentexperienceservice: DevelopmentExperienceService,
    private $applicantservice: ApplicantService,
    private $location: ng.ILocationService,
    private $caseService: CaseService,
    private $cookies: ng.cookies.ICookiesService,
    private $caseMemberService: CaseMemberService,
    private roleService: RoleService,
    private userService: UserService,
    private $auth: AuthService,
  ) {
    if ($cookies.get("access_token")) {
      this.isLoggedInUser = true;

      this.userService.getUserAutofill().then((response) => {
        this.showAutofill = response;
      });
    }

    //Get step number
    if (this.$routeParams.StepNumber) {
      this.step =
        this.$routeParams.StepNumber > 8 || this.$routeParams.StepNumber < 1
          ? 1
          : this.$routeParams.StepNumber;
    }

    if (this.$routeParams.CaseId) {
      this.caseid = this.$routeParams.CaseId;
    }

    //existing devexperience
    if (this.$routeParams.Id && this.$routeParams.Id > 0) {
      this.devexperienceId = this.$routeParams.Id;

      this.userService.getcurentuserrecord().then((result) => {
        this.selectedUser = result;
      });

      //if (!this.selectedObject) {
      this.$developmentexperienceservice
        .fetch(this.$routeParams.Id)
        .then((response) => {
          if (response == null) {
            if (this.isLoggedInUser) {
              this.$location.path("/userdashboard");
            } else {
              this.$location.path("/landing");
            }
          } else {
            this.selectedObject = response;
            if (this.selectedObject.HowWillYouOwnNewDevelopment <= 0) {
              this.selectedObject.HowWillYouOwnNewDevelopment = 1;
            }

            this.$caseMemberService
              .fetchByCaseId(this.caseid)
              .then((response) => {
                this.owners = response;

                this.selectedObject.HowManyOwnersShareholdersOrPartners =
                  this.owners.length;
              });

            this.selectedObject.DevelopmentTrackRecords.forEach((d, index) => {
              if (d.AcquistionDate != null) {
                this.formattedAcquisitionDate[index] = new Date(
                  d.AcquistionDate.toString(),
                );
              }
            });
          }
        })
        .finally(() => {
          this.fetchingObjects = false;
        });
    } else if (this.caseid) {
      //assoicate the case with it so when it is saved the server attaches it to the case
      this.selectedObject = {
        parentCaseToAssoicateWithOnCreation: this.caseid,
      } as ProjectOwnershipDTO;
    } else {
      this.selectedObject = {} as ProjectOwnershipDTO;
    }

    this.$caseService.fetch(this.$routeParams.CaseId).then((response) => {
      this.case = response;
      this.caseTitle = response.DevelopmentInput.SearchName;
      this.checkForPrimaryUser();
    });

    if (this.$routeParams.Id < 1) {
      // no develoment experience id
      this.save(false).then((response) => {
        this.devexperienceId = response;
        this.go(
          "/devexperience/" + this.caseid + "/" + this.devexperienceId + "/1",
        );
      });
    } else if (!this.$routeParams.StepNumber) {
      this.go(
        "/devexperience/" +
          this.caseid +
          "/" +
          this.devexperienceId +
          "/" +
          this.step,
      );
    }

    if (this.$routeParams.ApplicantNo) {
      this.applicantNo = this.$routeParams.ApplicantNo;
    }

    this.updateGuidanceState();
    this.checkForAdmin();
    this.checkIfOnlyBroker();

    $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
      this.updateGuidanceState();
    });
  }

  checkForAdmin(): void {
    this.dataLoading = true;
    this.roleService
      .isAdminUser()
      .then((response) => {
        this.isAdmin = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkIfOnlyBroker() {
    this.dataLoading = true;
    this.roleService
      .isBroker()
      .then((response) => {
        this.IsBroker = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkForPrimaryUser(): boolean {
    if (
      (this.$rootScope as any).selectedUser &&
      this.case &&
      (this.$rootScope as any).selectedUser.UserName == this.case.OwnerUser
    ) {
      this.isPrimaryApplicant = true;
      return true;
    }

    this.isPrimaryApplicant = false;
    return false;
  }

  clientAccountAccessCheck(shareholder: CaseMemberDTO): boolean {
    if (this.owners) {
      this.shareholder = this.owners.find(
        (x) => x.UserId == shareholder.UserId,
      );
    }

    if (this.shareholder.UserId == (this.$rootScope as any).selectedUser.Id) {
      return true;
    }

    return false;
  }

  updateGuidanceState() {
    this.guidanceCheckbox =
      this.$cookies.get("guidance") === "on" ||
      this.$cookies.get("guidance") === undefined;
  }

  getGuidanceSwitchState() {
    if (!this.$cookies.get("guidance")) {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "on") {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "off") {
      this.guidanceCheckbox = false;
    } else {
      this.guidanceCheckbox = true;
    }
    return this.guidanceCheckbox;
  }

  addApplicantPopUp() {
    delete this.newApplicant;
    this.showAdd = true;
  }

  resetApplicant() {
    this.applicantForm.$setPristine();
    this.applicantForm.$setUntouched();
    this.newApplicant = {} as CaseMemberDTO;
    this.showAdd = false;
  }

  updateApplicant(applicant: CaseMemberDTO): void {
    this.editApplicant = applicant;
    this.tempApplicant = { ...this.editApplicant };
    this.showEdit = true;
  }

  saveUpdatedApplicant(applicant: CaseMemberDTO): void {
    this.disableQuickEditSave = true; // this is just because sometimes the save takes a little while and the user could click the save button again
    applicant.CaseId = this.case.Id;

    if (!applicant.StreetAddress) {
      applicant.StreetAddress = {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress;
    }

    this.$applicantservice
      .addOrUpdateCaseMember(applicant, this.isAddShareholder)
      .then((response) => {
        response.DateOfBirth = applicant.DateOfBirth;
        const existingShareholder = this.owners.find(
          (s) => s.Id == response.Id,
        );

        // If found then we're updating
        if (existingShareholder) {
          this.owners = this.owners.filter(
            (member) => member.Id != applicant.Id,
          );
          this.owners.push(response);
        } else {
          this.owners.push(response);
        }
      })
      .finally(() => {
        delete this.newApplicant;
        this.showEdit = false;
        this.showAdd = false;
        this.disableQuickEditSave = false;
      });
  }

  cancelUpdateApplicant() {
    this.showEdit = false;
    this.owners = this.owners.filter(
      (applicant) => applicant.Id != this.tempApplicant.Id,
    );
    this.owners.push(this.tempApplicant); // Resets anything that is changed using the original data object
  }

  recordGuidanceCookie() {
    var guidanceSwitchState: string;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    if (this.guidanceCheckbox == true) {
      guidanceSwitchState = "on";
    } else {
      guidanceSwitchState = "off";
    }
    this.$cookies.put("guidance", guidanceSwitchState, { expires: expiryDate });
    this.$rootScope.$broadcast("guidanceStateChanged");
  }

  formChanged(): void {
    (this.$rootScope as any).formSaved = false;
  }

  save(isFormComplete: boolean): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    if (!this.selectedObject) {
      return defer.promise;
    }

    if (!this.roleService.IsLender) {
      this.saving = true;

      this.formattedAcquisitionDate.forEach((d, index) => {
        this.selectedObject.DevelopmentTrackRecords[index].AcquistionDate = d;
      });

      var saveRequest = this.$developmentexperienceservice
        .addUpdatereturnonlyid(this.selectedObject)
        .then((response) => {
          //set ID of selectedobject
          this.selectedObject.Id = response;
          //after save take out saving assoication each time
          this.selectedObject.parentCaseToAssoicateWithOnCreation = null;
          this.setAllFormsPristine();
          this.saved = true;
          if (isFormComplete) {
            var markComplete = {
              Id: this.caseid,
              DevelopmentAppraisalComplete: true,
            } as CaseDTO;
            this.$caseService
              .addUpdatereturnonlyid(markComplete)
              .then((response) => {
                this.goToCaseDashboard(true);
              });
          }
          defer.resolve(response as number);
          return response;
        })
        .catch((response) => {
          defer.reject(response);
          this.error = true;
        })
        .finally(() => {
          this.saving = false;
        });
    }
    return defer.promise;
  }

  next(): void {
    //go to next screen
    this.step++;
    if (this.step > 1 && this.step <= 3) {
      this.go(
        "/devexperience/" +
          this.caseid +
          "/" +
          this.devexperienceId +
          "/" +
          this.step,
      );
      (this.$rootScope as any).formSaved = this.roleService.IsLender;
    } else {
      this.$location.path("/casedashboard/" + this.caseid);
    }
  }

  back(): void {
    if (this.step > 1) {
      this.step--;
      this.go(
        "/devexperience/" +
          this.caseid +
          "/" +
          this.devexperienceId +
          "/" +
          this.step,
      );
      (this.$rootScope as any).formSaved = this.roleService.IsLender;
    } else if (this.step <= 1) {
      this.$location.path("/casedashboard/" + this.caseid);
    }
  }

  goToCaseDashboard(blockSave?: boolean): void {
    if (this.roleService.IsLender == true) {
      this.$location.path("/casedashboard/" + this.caseid);
    } else {
      if (blockSave) {
        this.save(true).then((response) => {
          this.$location.path("/casedashboard/" + this.caseid);
          (this.$rootScope as any).formSaved = true;
        });
      } else {
        this.save(false).then(() => {
          this.$location.path("/casedashboard/" + this.caseid);
        });
      }
    }
  }

  setAllFormsPristine(): void {
    if (this.multiPartForm1) {
      this.multiPartForm1.$setPristine();
    }
  }

  updateComparable(index) {
    this.show[index] = true;
  }

  ShowCompanyOrPartnershipName(): boolean {
    if (
      this.selectedObject &&
      this.selectedObject.HowWillYouOwnNewDevelopment !=
        OwnNewDevelopmentEnum.PersonalNames &&
      this.selectedObject.HasTheCompanyOrPensionBeenFormedYet
    ) {
      return true;
    }
    return false;
  }
  ShowCompanyOrPartnershipNumber(): boolean {
    if (
      this.selectedObject &&
      this.selectedObject.HowWillYouOwnNewDevelopment !=
        OwnNewDevelopmentEnum.PersonalNames &&
      this.selectedObject.HasTheCompanyOrPensionBeenFormedYet
    ) {
      return true;
    }
    return false;
  }
  ShowPensionType(): boolean {
    if (
      this.selectedObject &&
      this.selectedObject.HowWillYouOwnNewDevelopment ==
        OwnNewDevelopmentEnum.PensionFund &&
      this.selectedObject.HasTheCompanyOrPensionBeenFormedYet
    ) {
      return true;
    }
    return false;
  }

  go(path): void {
    // Only need to save if user is NOT a lender (everything is in read-only mode for lender)
    if (this.roleService.IsLender == true) {
      this.$location.path(path);
    } else {
      this.save(false).then((response) => {
        this.devexperienceId = response;
        this.$location.path(path);
      });
    }
  }

  sum(a: number, b: number): number {
    return a + b;
  }

  getMethodOwningText(): string {
    var methodofowningnewdevelopmenttext;
    var nameOfSubParts;
    if (
      this.selectedObject &&
      this.selectedObject.HowWillYouOwnNewDevelopment
    ) {
      this.formSectionNames.forEach((s) => (s.visible = true));
      switch (this.selectedObject.HowWillYouOwnNewDevelopment) {
        case OwnNewDevelopmentEnum.UKLimitedCompany:
          methodofowningnewdevelopmenttext = "UK Limited Company";
          nameOfSubParts = "Shareholders";
          break;
        case OwnNewDevelopmentEnum.OverseasLimitedCompany:
          methodofowningnewdevelopmenttext = "Overseas Limited Company";
          nameOfSubParts = "Shareholders";
          break;
        case OwnNewDevelopmentEnum.Other:
          methodofowningnewdevelopmenttext =
            this.selectedObject.HowWillYouOwnNewDevelopmentOther;
          nameOfSubParts = "Owners";
          this.formSectionNames[1].visible = false;
          //step = 2;
          break;
        case OwnNewDevelopmentEnum.PensionFund:
          methodofowningnewdevelopmenttext = "Pension Fund";
          nameOfSubParts = "beneficiaries";
          break;
        case OwnNewDevelopmentEnum.PersonalNames:
          methodofowningnewdevelopmenttext = "Personal Names";
          nameOfSubParts = "Owners";
          this.formSectionNames[1].visible = false;
          //step = 2;
          break;
        case OwnNewDevelopmentEnum.UKLimitedLiabilityPartnership:
          methodofowningnewdevelopmenttext = "UK Limited Liability Partnership";
          nameOfSubParts = "Partners";
          break;
        default:
      }
      return methodofowningnewdevelopmenttext;
    }
    return "";
  }

  /** Initialise the CaseMemberDTO for adding a new Shareholder/Applicant */
  initialiseNewApplicant() {
    this.addEditApplicant = {
      AccessLevel: CaseAccessLevelEnum.Hidden,
      CaseId: this.$routeParams.CaseId,
      IsApplicant: true,
      Order: this.owners && this.owners.length > 0 ? this.owners.length + 1 : 1,
      StreetAddress: {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress,
    } as CaseMemberDTO;
  }

  /**
   * Open the Shareholder/Applicant quick edit
   * @param applicant
   */
  openShareholderQuickEdit(applicant: CaseMemberDTO) {
    if (applicant) {
      this.isAddShareholder = false;
      this.addEditApplicant = applicant;
      this.tempApplicant = { ...this.addEditApplicant };
    } else {
      this.isAddShareholder = true;
      if (this.applicantForm) {
        this.applicantForm.$setPristine();
        this.applicantForm.$setUntouched();
      }

      this.initialiseNewApplicant();
    }

    this.showQuickEditModal = true;
  }

  /** Cancel any adding or editing of a Shareholder/Applicant */
  cancelShareholderQuickEdit() {
    if (this.tempApplicant && this.tempApplicant.Id > 0) {
      this.owners = this.owners.filter(
        (applicant) => applicant.Id != this.tempApplicant.Id,
      );
      this.owners.push(this.tempApplicant);
    }

    this.closeShareholderQuickEdit();
  }

  /** Save a new or updated Shareholder/Applicant on quick add/edit */
  saveApplicant(): void {
    this.disableQuickEditSave = true; // this is just because sometimes the save takes a little while and the user could click the save button again

    this.$applicantservice
      .addOrUpdateCaseMember(this.addEditApplicant, true)
      .then((response) => {
        const existingShareholder = this.owners.find(
          (s) => s.Id == response.Id,
        );

        // If found then we're updating
        if (existingShareholder) {
          this.owners = this.owners.filter(
            (member) => member.Id != this.addEditApplicant.Id,
          );
          this.owners.push(response);
        } else {
          this.owners.push(response);
        }
      })
      .finally(() => {
        this.showQuickEditModal = false;
        this.applicantForm.$setPristine();
        this.applicantForm.$setUntouched();
        this.tempApplicant = {} as CaseMemberDTO;
        this.addEditApplicant = {} as CaseMemberDTO;
        this.disableQuickEditSave = false;
      });
  }

  deleteApplicant(): void {
    var id = this.confirmDeleteApplicant.Id;

    this.$caseMemberService
      .markAsDeleteAndSetNextCaseMenmberAsPrimary(
        this.caseid,
        this.confirmDeleteApplicant.Id,
      )
      .then((response) => {
        this.owners = response;
      });
    this.confirmDeleteApplicant = {} as CaseMemberDTO;
    this.showDelete = false;
  }

  deleteConfirmApplicant(applicant: CaseMemberDTO) {
    this.showDelete = true;
    this.confirmDeleteApplicant = { ...applicant };
  }

  /** Processing that should be done each time the modal closes - on cancel or save */
  closeShareholderQuickEdit() {
    this.showQuickEditModal = false;
    this.applicantForm.$setPristine();
    this.applicantForm.$setUntouched();
    this.tempApplicant = {} as CaseMemberDTO;
    this.addEditApplicant = {} as CaseMemberDTO;
  }

  isInvalid() {
    if (this.selectedObject) {
      if (!this.selectedObject.HasTheCompanyOrPensionBeenFormedYet) {
        return false;
      }
      if (
        this.selectedObject.CompanyPensionOrPartnershipName &&
        this.selectedObject.CompanyOrPartnershipNumber
      ) {
        return false;
      }
      return true;
    }
  }

  goToApplicantDetails(applicant: CaseMemberDTO): void {
    //change applicant.id to index (use a for loop to iterate through dto)
    var applicantId;

    for (let i = 0; i <= this.owners.length - 1; i++) {
      if (this.owners[i].Id == applicant.Id) {
        applicantId = i;
      }
    }
    if (applicantId || applicantId == 0) {
      this.go(
        "/applicantdetails/" +
          this.caseid +
          "/" +
          this.devexperienceId +
          "/" +
          applicant.Id +
          "/1",
      );
    }
  }

  invite(applicant: CaseMemberDTO) {
    this.$caseMemberService
      .inviteApplicant(applicant.UniqueRef)
      .then((i) => {
        this.modal = true;
        this.message =
          "A request has been sent to " +
          applicant.FirstName +
          " " +
          applicant.Surname +
          " complete their shareholder profile.";
      })
      .catch((error) => {
        this.modal = true;
        this.message =
          "There was a problem sending the invite request to " +
          applicant.FirstName +
          " " +
          applicant.Surname +
          ". Please try again later.";
      });
  }

  /**
   * Determines when to show/hide the Invite button
   * @param shareholder
   */
  showInviteButton(shareholder: CaseMemberDTO) {
    // If the shareholder has already been verified then don't show the button
    if (shareholder.IsVerified) {
      return false;
    }

    // At least 2 of the security questions should be answered
    var count = 0;

    if (shareholder.PhoneNumber) {
      count++;
    }

    if (shareholder.StreetAddress.PostCode) {
      count++;
    }

    if (shareholder.DateOfBirth) {
      count++;
    }

    if (count >= 2) {
      return true;
    }

    return false;
  }

  openSendToBorrower(shareholder: CaseMemberDTO, shareContext: number) {
    this.shareContext = shareContext;
    this.shareSuplId = shareholder.Id;
    if (this.selectedUser.UserName !== this.case.DevelopmentInput.OwnerUser) {
      this.userFirstName = "";
    } else {
      this.userFirstName = this.selectedUser.FirstName;
    }

    var message =
      `Please take a look and complete the information for  ` +
      shareholder.FirstName +
      `\n\nIf you need my help or want me to complete this information with you on a call, then please contact me as soon as possible.`;

    this.shareNote = message;

    this.shareEmail = shareholder.Email;
    this.shareFirstName = shareholder.FirstName;
    this.shareSurname = shareholder.Surname;

    //if (this.$cookies.get('access_token')) {
    //    this.userService.getcurentuserrecord().then((result) => {

    //        if (this.selectedUser.UserName !== this.case.DevelopmentInput.OwnerUser) {
    //            this.shareEmail = this.case.DevelopmentInput.OwnerUser;
    //            this.shareFirstName = "";
    //            this.shareSurname = "";
    //        }
    //        else {
    //            this.shareEmail = result.Email;
    //            this.shareFirstName = result.FirstName;
    //            this.shareSurname = result.LastName;
    //        }
    //    });
    //}

    this.showShare = true;
  }

  sendToBorrower() {
    this.dataLoading = true;
    this.$caseService
      .sendNoteToBorrower(
        this.shareFirstName,
        this.shareSurname,
        this.shareEmail,
        this.shareNote,
        this.case.Id,
        this.shareContext,
        this.shareSuplId,
        this.case.DevelopmentExperienceID,
      )
      .then((success) => {
        this.showShare = false;
        this.dataLoading = false;
      });
  }

  autoFillOwnershipDetails() {
    this.selectedObject.HowWillYouOwnNewDevelopment =
      OwnNewDevelopmentEnum.UKLimitedCompany;
    this.selectedObject.HasTheCompanyOrPensionBeenFormedYet = true;
    this.selectedObject.CompanyPensionOrPartnershipName =
      "Globe Results Limited";
    this.selectedObject.CompanyOrPartnershipNumber = "12322370";
    this.selectedObject.ExcludedLenders = "Example Lender";

    this.selectedObject.DevelopmentTrackRecords = [];

    let newDevelopmentTrackRecord = {
      DevelopmentTrackRecordNumber: 1,
      DevelopmentExperienceId: this.selectedObject.Id,
      AreaUnit: 1,
      DevelopmentTrackRecordType: null,
      StreetAddress: {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress,
    } as DevelopmentTrackRecordDTO;

    this.selectedObject.DevelopmentTrackRecords.push(newDevelopmentTrackRecord);

    newDevelopmentTrackRecord = {
      DevelopmentTrackRecordNumber: 2,
      DevelopmentExperienceId: this.selectedObject.Id,
      AreaUnit: 1,
      DevelopmentTrackRecordType: null,
      StreetAddress: {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress,
    } as DevelopmentTrackRecordDTO;

    this.selectedObject.DevelopmentTrackRecords.push(newDevelopmentTrackRecord);
    this.selectedObject.DevelopmentTrackRecords[0].OwnerOrEmployee =
      OwnerOrEmployeeEnum.Owner;
    this.selectedObject.DevelopmentTrackRecords[0].StreetAddress.AddressLine1 =
      "32 Brookside Road";
    this.selectedObject.DevelopmentTrackRecords[0].StreetAddress.AddressLine2 =
      "Istead Rise";
    this.selectedObject.DevelopmentTrackRecords[0].StreetAddress.AddressLine3 =
      "Gravesend";
    this.selectedObject.DevelopmentTrackRecords[0].StreetAddress.AddressLine4 =
      "Kent";
    this.selectedObject.DevelopmentTrackRecords[0].StreetAddress.PostCode =
      "DA13 9JJ";
    const maxDate = Date.now();
    const timestamp = Math.floor(Math.random() * maxDate);
    this.selectedObject.DevelopmentTrackRecords[0].AcquistionDate = new Date(
      timestamp,
    );
    this.selectedObject.DevelopmentTrackRecords[0].AcquistionCosts =
      this.getRandomIntInclusive(1000, 3000);
    this.selectedObject.DevelopmentTrackRecords[0].DidYouUseDevelopmentFinance =
      true;
    this.selectedObject.DevelopmentTrackRecords[0].DevelopmentFinanceLenderName =
      "Masthaven";
    this.selectedObject.DevelopmentTrackRecords[0].DevelopmentAreas =
      this.getRandomIntInclusive(2000, 3000).toString();
    this.selectedObject.DevelopmentTrackRecords[0].BuildCosts =
      this.getRandomIntInclusive(1000000, 2000000);
    this.selectedObject.DevelopmentTrackRecords[0].BuildTime =
      this.getRandomIntInclusive(5, 12);
    this.selectedObject.DevelopmentTrackRecords[0].GDV =
      this.getRandomIntInclusive(10000000, 20000000);
    this.selectedObject.DevelopmentTrackRecords[0].DescriptionOfWorks =
      "Knock down and rebuild of main residence";

    this.selectedObject.DevelopmentTrackRecords[1].OwnerOrEmployee =
      OwnerOrEmployeeEnum.Owner;
    this.selectedObject.DevelopmentTrackRecords[1].StreetAddress.AddressLine1 =
      "7 Soho Square";
    this.selectedObject.DevelopmentTrackRecords[1].StreetAddress.AddressLine2 =
      "";
    this.selectedObject.DevelopmentTrackRecords[1].StreetAddress.AddressLine3 =
      "London";
    this.selectedObject.DevelopmentTrackRecords[1].StreetAddress.AddressLine4 =
      "";
    this.selectedObject.DevelopmentTrackRecords[1].StreetAddress.PostCode =
      "W1D 3QB";
    this.selectedObject.DevelopmentTrackRecords[1].AcquistionDate = new Date(
      timestamp,
    );
    this.selectedObject.DevelopmentTrackRecords[1].AcquistionCosts =
      this.getRandomIntInclusive(1000, 3000);
    this.selectedObject.DevelopmentTrackRecords[1].DidYouUseDevelopmentFinance =
      true;
    this.selectedObject.DevelopmentTrackRecords[1].DevelopmentFinanceLenderName =
      "Masthaven";
    (this.selectedObject.DevelopmentTrackRecords[1].AreaUnit =
      AreaUnitEnum.SquareMeters),
      (this.selectedObject.DevelopmentTrackRecords[1].DevelopmentAreas =
        this.getRandomIntInclusive(2000, 3000).toString());
    this.selectedObject.DevelopmentTrackRecords[1].BuildCosts =
      this.getRandomIntInclusive(1000000, 2000000);
    this.selectedObject.DevelopmentTrackRecords[1].BuildTime =
      this.getRandomIntInclusive(12, 24);
    this.selectedObject.DevelopmentTrackRecords[1].GDV =
      this.getRandomIntInclusive(10000000, 20000000);
    this.selectedObject.DevelopmentTrackRecords[1].DescriptionOfWorks =
      "Office to residential conversion on upper floors, and retention of the downstairs commercial - included a basement dig and 2 storey extension";
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  hasMinSecurityQuestionsBeenAnswered(shareholder: CaseMemberDTO) {
    var answers = 0;

    if (shareholder.DateOfBirth) {
      answers++;
    }

    if (shareholder.PhoneNumber) {
      answers++;
    }

    if (shareholder.StreetAddress.PostCode) {
      answers++;
    }

    if (answers >= 2 || shareholder.IsVerified) {
      return true;
    } else {
      return false;
    }
  }
}

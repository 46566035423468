import { DealClientDTO } from "@js/DTO/Deal/DealClientDTO.cs.d";
import { AppraisalModuleEnum } from "@js/models/enum/AppraisalModuleEnum.cs.d";
import { OwnershipRoleEnum } from "@js/models/enum/OwnershipRoleEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";

export class SharedDealService {
  tinymceOptions = {
    content_style: "body {color: black;font:300 12px Roboto,sans-serif;}",
    menubar: false,
    height: 150,
    branding: false,
    statusbar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | formatselect | " +
      "bold italic backcolor forecolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | preview |" +
      "removeformat | help",
  };

  static $inject = ["$q"];

  constructor(private $q: ng.IQService) {}

  disablePersonalDetails(
    currentApplicant: DealClientDTO,
    personalDetailsForm: ng.IFormController,
  ) {
    if (currentApplicant == null) return true;

    let selectFields = [currentApplicant.Profile.CountryOfResidence];

    if (personalDetailsForm.$invalid) return true;

    for (let i = 0; i < selectFields.length; i++) {
      if (selectFields[i] == "0" || selectFields[i] == undefined) {
        return true;
      }
    }

    return false;
  }

  modifyDateForFrontend(property: Date) {
    let receivedDate = new Date(property.getTime());

    receivedDate.setUTCMinutes(
      receivedDate.getUTCMinutes() + receivedDate.getTimezoneOffset(),
    );
    property = receivedDate;
    return property;
  }

  modifyDateForBackend(property: Date) {
    let originalDate = new Date(property.getTime());

    originalDate.setUTCMinutes(
      originalDate.getUTCMinutes() - originalDate.getTimezoneOffset(),
    );
    property = originalDate;
    return property;
  }

  /**
   * Determines when to show/hide the Invite button
   * @param shareholder
   */
  showInviteButton(shareholder: DealClientDTO) {
    // If the shareholder has already been verified then don't show the button
    if (shareholder.IsVerified) {
      return false;
    }

    // At least 2 of the security questions should be answered
    var count = 0;

    if (shareholder.Client.PhoneNumber) {
      count++;
    }

    if (shareholder.Client.PostalAddress.PostCode) {
      count++;
    }

    if (shareholder.Client.DateOfBirth) {
      count++;
    }

    if (count >= 2) {
      return true;
    }

    return false;
  }

  nonUkWarningVisible(currentApplicant: DealClientDTO): boolean {
    if (
      currentApplicant &&
      currentApplicant.Profile &&
      currentApplicant.Profile.CountryOfResidence
    ) {
      return (
        currentApplicant.Profile.CountryOfResidence !=
        "United Kingdom of Great Britain and Northern Ireland (the)"
      );
    }
    return false;
  }

  constructShareNoteMessage(shareContext, currentUser): string {
    var message: string;
    var messageStart: string = `Please take a look at the `;
    var messageEnd: string =
      ` section in your Smart Appraisal™ and complete as much as you can.\n\nIf you would like our help or want us to complete this module with you, please do give me a call on ` +
      currentUser.PhoneNumber +
      `.`;

    switch (shareContext) {
      case AppraisalModuleEnum.Borrower:
        message =
          "Please review your Borrower profile and help your broker by completing all of the necessary information.\n\nProviding lenders with as much information as possible about your experience helps them to make quicker and more reliable decisions.\n\n";
        break;
      case AppraisalModuleEnum.Property:
        message = messageStart + `"Property"` + messageEnd;
        break;
      case AppraisalModuleEnum.Loan:
        message = messageStart + `"Loan"` + messageEnd;
        break;
      case AppraisalModuleEnum.Overview:
        message =
          "Please upload the supporting documents associated with your project.\n\nAdding supporting documents helps lenders make quicker and more reliable decisions.";
        break;
    }

    return message;
  }

  addressHistoryComplete(
    currentApplicant,
    applicantAddressHistory,
    missingAddressGaps,
  ) {
    const gapAllowed = 7 /* Number of days */ * 24 * 60 * 60 * 1000;
    const gaps = [];

    // Calculate the target date 3 years ago
    const threeYearsAgo = new Date();
    threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);

    let currentTime = currentApplicant.Profile.CurrentAddressFrom;

    // Exit early if the current address already covers 3 years
    if (currentTime <= threeYearsAgo) {
      return true;
    }

    // Sort the applicant address history by FromDate in descending order
    applicantAddressHistory.sort(
      (a, b) => b.FromDate.getTime() - a.FromDate.getTime(),
    );

    let lastToDate = currentTime; // Initialize lastToDate with the current address's FromDate

    // Loop through sorted history to identify gaps
    for (const {
      FromDate: fromDate,
      ToDate: toDate,
    } of applicantAddressHistory) {
      // Register a gap if one exists between toDate and lastToDate
      if (toDate.getTime() + gapAllowed < lastToDate.getTime()) {
        gaps.push([new Date(toDate.getTime()), new Date(lastToDate.getTime())]);
      }

      lastToDate = fromDate;
    }

    // Check for a gap between the earliest FromDate and threeYearsAgo
    if (lastToDate > threeYearsAgo) {
      gaps.push([threeYearsAgo, lastToDate]);
    }

    // Convert the gaps into readable strings
    missingAddressGaps = gaps.map(
      (gap) =>
        `${gap[0].toLocaleDateString()} to ${gap[1].toLocaleDateString()}`,
    );

    // Return false if gaps are present, otherwise return true
    return gaps.length === 0;
  }

  getNextAddressHistoryId(applicantAddressHistory): number {
    if (applicantAddressHistory.length == 0) {
      return 1;
    } else {
      // Get the current maximum Id and add one to it for the next ID
      var id = Math.max.apply(
        Math,
        applicantAddressHistory.map(function (a) {
          return a.Id;
        }),
      );
      id++;
      return id;
    }
  }

  getAddressAsString(address: PostalAddress) {
    var addressAsString: string = "";

    addressAsString = this.concatenateAddressLine(
      address.AddressLine1,
      addressAsString,
    );
    addressAsString = this.concatenateAddressLine(
      address.AddressLine2,
      addressAsString,
    );
    addressAsString = this.concatenateAddressLine(
      address.AddressLine3,
      addressAsString,
    );
    addressAsString = this.concatenateAddressLine(
      address.AddressLine4,
      addressAsString,
    );

    return addressAsString;
  }

  concatenateAddressLine(addressLine: string, concatenatedAddress: string) {
    if (addressLine != null && addressLine.length > 0) {
      if (concatenatedAddress.length > 0) {
        concatenatedAddress = concatenatedAddress.concat(", ", addressLine);
      } else {
        concatenatedAddress = concatenatedAddress.concat(addressLine);
      }
    }

    return concatenatedAddress;
  }

  calculateTotalShareholding(
    currentapplicant: DealClientDTO,
    applicantList: DealClientDTO[],
  ) {
    var shareholdingApplicants = applicantList.filter(
      (o) =>
        o.OwnershipRole != OwnershipRoleEnum.Director &&
        o.OwnershipRole != OwnershipRoleEnum.None,
    );
    var totalShareholding = shareholdingApplicants.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.OwnershipPercentage),
      0,
    );

    if (currentapplicant.Id == null || currentapplicant.Id < 1) {
      return totalShareholding + Number(currentapplicant.OwnershipPercentage) >
        1
        ? true
        : false;
    } else if (currentapplicant.Id > 0) {
      return totalShareholding > 1 ? true : false;
    }
  }

  getOwnershipPercentageText(currentApplicant, productType) {
    if (productType == ProductTypeEnum.CommercialOwnerOccupied) {
      if (
        currentApplicant.OwnershipRole == OwnershipRoleEnum.Shareholder ||
        currentApplicant.OwnershipRole ==
          OwnershipRoleEnum.DirectorAndShareholder
      ) {
        return "Shareholding";
      } else if (currentApplicant.OwnershipRole == OwnershipRoleEnum.Member) {
        return "Member";
      } else if (currentApplicant.OwnershipRole == OwnershipRoleEnum.Partner) {
        return "Partner";
      }
    } else {
      return "Shareholding";
    }
  }

  showBasedOnMainResidence(dealDto): boolean {
    return (
      dealDto.IsFamilyInResidence != 1 ||
      (dealDto.IsFamilyInResidence == 1 &&
        (dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance ||
          dealDto.ProductType == ProductTypeEnum.BridgingRefurb))
    );
  }

  downloadFile(fileUrl: string, fileName: string) {
    fetch(fileUrl)
        .then(response => response.blob())  // Convert the response to a Blob
        .then(blob => {
            // Create a temporary URL for the Blob
            var blobUrl = window.URL.createObjectURL(blob);

            // Create a hidden <a> element
            var link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;

            // Append the link to the body and click it programmatically
            document.body.appendChild(link);
            link.click();

            // Clean up: Remove the link and revoke the Blob URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        })
    } 
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseSummaryDTO } from "@js/DTO/CaseSummaryDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { InviteDTO } from "@js/DTO/InviteDTO.cs.d";
import { LicenseMasterDTO } from "@js/DTO/LicenseMasterDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { CaseAccessLevelEnum } from "@js/models/enum/CaseAccessLevelEnum.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { LenderProductTypeEnum } from "@js/models/enum/LenderProductTypeEnum.cs.d";
import { LicenseMasterStatusEnum } from "@js/models/enum/LicenseMasterStatusEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { StatusEnum } from "@js/models/enum/StatusEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseLenderService } from "@js/services/CaseLenderService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { CaseSummaryService } from "@js/services/CaseSummaryService";
import { ClientService } from "@js/services/ClientService";
import { BridgingDealService } from "@js/services/Deal/BridgingDealService";
import { DealLenderMessageService } from "@js/services/Deal/DealLenderMessageService";
import { DealLenderService } from "@js/services/Deal/DealLenderService";
import { DealService } from "@js/services/Deal/DealService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { DevelopmentInputWithNoLoginService } from "@js/services/DevelopmentInputWithNoLoginService";
import { IntroducerService } from "@js/services/IntroducerService";
import { InviteService } from "@js/services/InviteService";
import { LenderService } from "@js/services/LenderService";
import { LicenseService } from "@js/services/LicenseService";
import { OrganisationService } from "@js/services/OrganisationService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { TaskItemService } from "@js/services/TaskItemService";
import { UserDashboardService } from "@js/services/UserDashboardService";
import { UserService } from "@js/services/UserService";
import { SharedDataService } from "@js/services/SharedDataService";


export class UserDashboardController {
  isPageLoading: boolean = false;
  isCardView: boolean = true;

  cardWithOpenMenu = {
    id: null,
  };

  isSearchesLoading: boolean = false;
  searches: CaseSummaryDTO[];

  isDevFinanceProjectsLoading: boolean = false;
  isBridgingPreConProjectsLoading: boolean = false;
  isCommercialProjectsLoading: boolean = false;
  isAllProjectsLoading: boolean = false;
  projects: CaseSummaryDTO[];

  actionItem: CaseSummaryDTO;

  confirmSearchToDelete: boolean;
  confirmProjectToDelete: boolean;

  confirmCopyProjectName: boolean;
  newProjectName: string;

  currentUser: ApplicationUserDTO;
  hasEnterpriseLicense: boolean;

  //Share search
  newSearch: boolean = false;
  isResultScreen: boolean = true;
  existingborrower: number = null;
  showClientDetails: boolean = false;
  existingUsers: UserSimpleDTO[];
  clientId: string = null;
  loanCriteria: DevelopmentInputDTO;
  displayMsg: string;
  sharedSearch: boolean = false;
  showShareResults: number;
  isBridging: boolean = false;

  shareDealDto: ShareDealDTO;

  NavMenuEnum = {
    Home: 0,
    DevelopmentFinance: 1,
    BridgingFinance: 2,
    Clients: 3,
    CommercialMortgages: 4,
    EquityAndMezz: 5
  };

  selectedNavMenu: number;

  DashboardTypeEnum = {
    Home: 0,
    Searches: 1,
    Projects: 2,
    Introductions: 3,
  };

  selectedDashboardType: number;

  //isNewSearchSelect: boolean;

  homeScreenCount: number = 3;

  isAdmin: boolean = false;
  isBroker: boolean = false;
  isLender: boolean = false;
  isBorrower: boolean = false;
  isIntroducer: boolean = false;

  shareSearchModal: number = null;
  shareSearchErrorDisplayed: boolean = false;

  // Introducer variables
  introduceClientModal: number = null;
  clientFirstName: string;
  clientSurname: string;
  clientEmail: string;
  clientPhoneNumber: string;

  isProcessingSend: boolean = false;

  invite: InviteDTO;
  invitedUsers: InviteDTO[] = [];

  introducedUsers: ApplicationUserDTO[];

  savedIntroducedSearches: DevelopmentInputDTO[] = [];
  unsavedIntroducedSearches: DevelopmentInputDTO[] = [];

  clientAdminTools: boolean = false;
  userSearchResults: ApplicationUserDTO[];

  selectedUser: ApplicationUserDTO;
  selecteduserName: string;
  //viewAsClientUserName: string;
  viewAsClientFirstName: string;
  selectedClientName: string;
  //TODO - these need to be related to the enums and moved into a "LookupService" so that we're only defining them once
  locations: Array<{ id: number; value: string }> = [
    { id: 0, value: "Any" },
    { id: 1, value: "London" },
    { id: 2, value: "North West" },
    { id: 4, value: "Midlands" },
    { id: 8, value: "East of England" },
    { id: 16, value: "South East" },
    { id: 32, value: "South West" },
    { id: 64, value: "North East" },
    { id: 128, value: "Wales" },
    { id: 256, value: "Scotland" },
    { id: 512, value: "Northern Ireland" },
  ];
  propertyTypes: Array<{ id: number; value: string }> = [
    { id: 0, value: "Any" },
    { id: 1, value: "Residential" },
    { id: 2, value: "Mixed Use" },
    { id: 2048, value: "Licenced HMO" },
    { id: 4, value: "Hotel" },
    { id: 8, value: "Leisure" },
    { id: 16, value: "Care Home" },
    { id: 32, value: "Medical" },
    { id: 64, value: "Office" },
    { id: 128, value: "Retail" },
    { id: 256, value: "Light Industrial" },
    { id: 512, value: "Student" },
    { id: 1024, value: "Heavy Industrial" },
    { id: 4096, value: "Land without Planning" },
    { id: 8192, value: "Education" },
    { id: 16384, value: "Retirement" },
  ];

  // end of Introducer variables

  // Start of dashboard search variables

  //caseStatuses: Array<{ id: number, value: string, order: number }> = [{ id: 999, value: 'Search', order: 1 }, { id: 0, value: 'New Project', order: 2 }, { id: 1, value: 'DIP In Progress', order: 3 }, { id: 2, value: 'Under Review', order: 4 }, { id: 3, value: 'Ready To Submit', order: 5 }, { id: 4, value: 'Submitted To Lenders For DIP', order: 6 }, { id: 12, value: 'Ready to be Re-submitted to Lenders For DIP', order: 6 }, { id: 5, value: 'Applied', order: 7 }]; //, { id: 9, value: "Submitted To Lenders Pending Review", order: 12 }
  lenderCaseStatuses: Array<{ id: number; value: string; order: number }> = [
    { id: 1, value: "Case to review", order: 2 },
    { id: 2, value: "DIP submitted", order: 3 },
    { id: 3, value: "Case rejected", order: 4 },
    { id: 4, value: "DIP withdrawn", order: 5 },
    { id: 10, value: "DIP submitted and accepted", order: 6 },
  ];

  // Status filter for non-lenders
  filterStatuses: Array<{ id: number; value: string; order: number }> = [
    { id: 1, value: "Search", order: 2 },
    { id: 2, value: "Case in Progress", order: 3 },
    { id: 3, value: "Submitted to Lender", order: 4 },
    { id: 8, value: "Ready to Re-Submit to Lenders", order: 4.5 },
    { id: 4, value: "DIP: Offered", order: 5 },
    { id: 5, value: "DIP: Rejected", order: 6 },
    { id: 6, value: "DIP: Withdrawn", order: 7 },
    { id: 7, value: "Applied to Lender", order: 8 },
  ];

  clientCaseNameFilter: string = null;
  statusFilter: number = undefined;
  caseStatusFilter: CaseStatusEnum = null;
  dipStatusFilter: CaseLenderStateEnum = null;

  // end of dashboard search variables

  licenseStatus: LicenseMasterStatusEnum;
  showPaymentModal: boolean;
  selectedResult: number;
  dataLoading: boolean = false;
  introducerErrorDisplayed: boolean = false;

  isUpgradedUser: boolean = false;
  isAssignedUserToOrg: boolean = false;

  showLogOutModal: boolean = false;

  addToHubspot: boolean = false;

  selectedSearch: CaseSummaryDTO;

  confirmAssignBrokerSearch: boolean = false;
  confirmAssignLenderSearch: boolean = false;
  licensedOrganisationBrokers: ApplicationUserDTO[];
  lenderUsers: ApplicationUserDTO[];
  selectedBroker: ApplicationUserDTO;
  selectedLender: ApplicationUserDTO;
  lenderList: ApplicationUserDTO[] = [];
  showDeletedSearchMessageModal: boolean = false;
  borrowerMessage: string = null;
  showMessageToborrower: boolean = false;
  showContactBrokerModal: boolean = false;

  productTypeOptions = [];

  //hiding and showing briding option.
  hasBridging: boolean = false;

  //hiding and showing commercial option.
  hasCommercial: boolean = false;

  isDeallockerIntegrationEnabled: boolean = false

  //Hiding menus for every one.
  hideDevfinanceMenu: boolean = true;
  hideBridgingMenu: boolean = true;
  hideCommercialMenu: boolean = true;

  //Hiding search for lenders.
  hasLiveSearch: boolean = true;
  hideDevelopmentFinanceSearch: boolean = false;
  hideBridgingSearch: boolean = false;
  hideCommercialSearch: boolean = false;

  // Boolean to send search email to client
  emailClient: boolean = false;

  showLenderTerms: boolean = false;
  lenderSelectedProject: CaseSummaryDTO;

  isCommercialOwnerOccupiedActive: boolean = false;
  goToDealForumClicked: boolean = false;
  assignedLenders = {};

  bridgingTotalUnreadMessage: number = 0;
  commercialTotalUnreadMessage: number = 0;
  devfinanceTotalUnreadMessage: number = 0;
  deallockerTabSelected: number = 1;

  static $inject = [
    "$rootScope",
    "$routeParams",
    "$location",
    "DevelopmentInputService",
    "DevelopmentInputWithNoLoginService",
    "UserService",
    "CaseService",
    "RoleService",
    "AuthService",
    "IntroducerService",
    "CaseSummaryService",
    "CaseMemberService",
    "InviteService",
    "OrganisationService",
    "LicenseService",
    "CaseLenderService",
    "UserService",
    "TaskItemService",
    "SelectListService",
    "ClientService",
    "DealService",
    "BridgingDealService",
    "LenderService",
    "DealLenderService",
    "DealLenderMessageService",
    "UserDashboardService",
    "SharedDataService"
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $DevelopmentInputService: DevelopmentInputService,
    private $DevelopmentInputWithNoLoginService: DevelopmentInputWithNoLoginService,
    private $user: UserService,
    private $CaseService: CaseService,
    private roleService: RoleService,
    private authService: AuthService,
    private $IntroducerService: IntroducerService,
    private $CaseSummaryService: CaseSummaryService,
    private $applicantservice: CaseMemberService,
    private inviteService: InviteService,
    private organisationService: OrganisationService,
    private licenseService: LicenseService,
    private caseLenderService: CaseLenderService,
    private userService: UserService,
    private taskItemService: TaskItemService,
    private selectListService: SelectListService,
    private clientService: ClientService,
    private dealService: DealService,
    private $bridgingDealService: BridgingDealService,
    private $lenderService: LenderService,
    private dealLenderService: DealLenderService,
    private dealLenderMessageService: DealLenderMessageService,
    private userDashboardService: UserDashboardService,
    private sharedDataService: SharedDataService
  ) {
    (this.$rootScope as any).isInvitedAndHasReadonlyAccess = false;

    this.dealService.setShowSearchNameAndUserSelectionModal(true);

    this.authService.isCommercialOwnerOccupiedActive().then((response) => {
      this.isCommercialOwnerOccupiedActive = response;
    });

    if (sessionStorage.getItem("returningFromdeletedSearch")) {
      this.showDeletedSearchMessageModal = true;
    }

    this.isUpgradedUser = sessionStorage.getItem("isUpgradedUser") == "true";
    this.isAssignedUserToOrg = sessionStorage.getItem("assignUserToOrg") == "true";


    //This removes most of the localstorage and session storage data.
    this.authService.clearStorageData();

    this.productTypeOptions =
      this.selectListService.GetProductTypeOptions(true);

    this.isPageLoading = true;

    this.roleService
      .GetUserRoles()
      .then((result) => {
        if (result.filter((x) => x.toLowerCase() == "admin").length > 0) {
          this.isAdmin = true;
        }
        if (result.filter((x) => x.toLowerCase() == "broker").length > 0) {
          this.isBroker = true;
        }

        if (result.filter((x) => x.toLowerCase() == "client").length > 0) {
          this.isBorrower = true;
        }
        if (result.filter((x) => x.toLowerCase() == "introducer").length > 0) {
          this.isIntroducer = true;
        }

        if (result.filter((x) => x.toLowerCase() == "lender").length > 0) {
          this.isLender = true;
        }

        this.authService.getUpdatedProfile().then((prof) => {
          this.currentUser = prof;
          this.hasEnterpriseLicense = this.currentUser.HasActiveEnterpriseLicense;

          if (
            this.currentUser != null &&
            this.currentUser.IsOrganisationAdmin
          ) {
            this.getBrokerBelongToOrganisation(this.currentUser.OrganisationId);
          }

          if (this.isBroker || this.isBorrower) {
            this.hideDevfinanceMenu = false;
            this.hideBridgingMenu = false;
            this.hideCommercialMenu = false;
            this.setHasBridgingAndHasCommercialValue();
          } else {
            this.hasBridging = true;
            this.hasCommercial = true;
            if (!this.isAdmin && !this.isIntroducer) {
              this.currentLenderProductType();
            } else {
              this.hideDevfinanceMenu = false;
              this.hideBridgingMenu = false;
              this.hideCommercialMenu = false;
            }
          }

          if (this.isAdmin) {
            this.organisationService.getOrganisationProductAccess().then((response) => {
              this.isDeallockerIntegrationEnabled = response.IsDeallockerIntegrationEnabled;
            });
          }

          if (this.isLender) {
            this.hideDevelopmentFinanceSearch = true;
            this.hideBridgingSearch = true;
            this.hideCommercialSearch = true;
            this.hasLiveSearch = false;

            this.$lenderService.getUserLenderDetails().then((lender) => {
              if (lender.HasLiveMarketSearch) {
                this.hasLiveSearch = true;

                if (lender.HasBridgingSearch) {
                  this.hideBridgingMenu = false;
                  this.hideBridgingSearch = false;
                }
                if (lender.HasDevelopmentFinanceSearch) {
                  this.hideDevfinanceMenu = false;
                  this.hideDevelopmentFinanceSearch = false;
                }
                if (lender.HasCommercialSearch) {
                  this.hideCommercialMenu = false;
                  this.hideCommercialSearch = false;
                }
              }
            });

            this.$user
              .getUsersByLenderId(this.currentUser.LenderId)
              .then((response) => {
                this.lenderUsers = response;
              });
          }

          if (this.addToHubspot) {
            this.userService.sendEventToHubspot(this.currentUser);
          }

          if (!(this.$rootScope as any).clientUsernameBeingViewed) {
            this.selecteduserName = this.currentUser.UserName;
          }

          // this is why I'm getting the update profile within the getuserroles call, I want to know if introducer is their only role
          if (result.length == 1 && this.isIntroducer) {
            this.showIntroductionsDashboard();
          } else {
            var goToNavMenu = this.userDashboardService.getGoToNavMenu();
            if (goToNavMenu) {
              if (goToNavMenu == this.userDashboardService.NavMenuEnum.EquityAndMezz) {
                this.showEquityAndMezzDashboard();
              }
            } else {
              this.showHomeDashboard();
            }
          }
        });
      })
      .finally(() => {
        this.isPageLoading = false;
      });

    // Will hit here if the user has come from the payment page
    if (this.$routeParams.licensemasterId) {
      if (this.$routeParams.licensemasterId == "0") {
        this.showPaymentModal = true;
      } else {
        this.licenseService
          .fetch(this.$routeParams.licensemasterId)
          .then((license: LicenseMasterDTO) => {
            this.licenseStatus = license.Status;
            if (
              license.Status == LicenseMasterStatusEnum.PreSubscription ||
              LicenseMasterStatusEnum.None ||
              LicenseMasterStatusEnum.Unpaid ||
              license.Status == LicenseMasterStatusEnum.PayFailure ||
              license.Status == LicenseMasterStatusEnum.PaidUp
            ) {
              this.showPaymentModal = true;
            }
          });
      }
      this.addToHubspot = true;
    } else if (this.isAssignedUserToOrg) {
      this.showLogOutModal = true;
    }
  }

  getProjectsAndSearches() {
    sessionStorage.setItem("selectedNavMenu", this.selectedNavMenu.toString());
    if (
      this.isBorrower ||
      this.isBroker ||
      this.isAdmin ||
      this.isIntroducer ||
      this.isLender
    ) {
      //only get searches if user might have any
      this.getSearches(this.homeScreenCount);
    } else {
      this.homeScreenCount = 6; //for lender
    }
    this.getProjects(this.homeScreenCount);
  }

  showHomeDashboard() {
    this.selectedDashboardType = this.DashboardTypeEnum.Home;
    if (
      sessionStorage.getItem("selectedNavMenu") &&
      this.isValidPreviousPath()
    ) {
      this.selectedNavMenu = parseInt(
        sessionStorage.getItem("selectedNavMenu"),
      );
    } else {
      this.selectedNavMenu = this.NavMenuEnum.Home;
    }

    if (
      sessionStorage.getItem("clientCaseNameFilter") &&
      this.isValidPreviousPath()
    ) {
      this.clientCaseNameFilter = sessionStorage.getItem(
        "clientCaseNameFilter",
      );
    }

    if (sessionStorage.getItem("statusFilter") && this.isValidPreviousPath()) {
      this.statusFilter = parseInt(sessionStorage.getItem("statusFilter"));
      this.mapFilterStatus();
    }

    this.getProjectsAndSearches();
  }

  showEquityAndMezzDashboard() {
    this.selectedNavMenu = this.NavMenuEnum.EquityAndMezz;
    this.selectedDashboardType = this.DashboardTypeEnum.Home;
    this.userDashboardService.resetGoToNavMenu();
  }

  showDevelopmentFinanceDashboard() {
    this.selectedNavMenu = this.NavMenuEnum.DevelopmentFinance;
    this.selectedDashboardType = this.DashboardTypeEnum.Home;
    //this.isNewSearchSelect = false;

    this.getProjectsAndSearches();
  }

  showBridgingDashboard() {
    if (this.hasBridging) {
      this.selectedNavMenu = this.NavMenuEnum.BridgingFinance;
      this.selectedDashboardType = this.DashboardTypeEnum.Home;
      //this.isNewSearchSelect = false;

      //TODO JLH remove
      //this.selectedDashboardType = this.DashboardTypeEnum.Home;

      this.getProjectsAndSearches();
    }
  }

  showCommercialDashboard() {
    if (this.hasCommercial) {
      this.selectedNavMenu = this.NavMenuEnum.CommercialMortgages;
      this.selectedDashboardType = this.DashboardTypeEnum.Home;
      // this.isNewSearchSelect = false;
      this.getProjectsAndSearches();
    }
  }

  showDevFinanceSearchDashboard() {
    this.selectedDashboardType = this.DashboardTypeEnum.Searches;
    //this.isNewSearchSelect = false;
    this.getSearches(0);
  }

  showProjectDashboard() {
    this.selectedDashboardType = this.DashboardTypeEnum.Projects;
    //this.isNewSearchSelect = false;
    this.getProjects(0);
  }

  showIntroductionsDashboard() {
    this.isPageLoading = true;

    this.selectedDashboardType = this.DashboardTypeEnum.Introductions;
    // this.isNewSearchSelect = false;

    if (this.isIntroducer || this.isAdmin) {
      this.getInvitedUsers();
      this.getIntroducedBorrowers();
      this.getIntroducedSearches(this.currentUser.RegisteredIntroducerId);
    }

    this.isPageLoading = false;
  }

  checkSearchPermission(): boolean {
    if (this.selectedNavMenu == this.NavMenuEnum.Home) {
      return true;
    }

    if (
      this.selectedNavMenu == this.NavMenuEnum.DevelopmentFinance &&
      !this.hideDevelopmentFinanceSearch
    ) {
      return true;
    }

    if (
      this.selectedNavMenu == this.NavMenuEnum.BridgingFinance &&
      !this.hideBridgingSearch
    ) {
      return true;
    }

    if (
      this.selectedNavMenu == this.NavMenuEnum.CommercialMortgages &&
      !this.hideCommercialSearch
    ) {
      return true;
    }
    return false;
  }

  getSearches(count: number) {
    if (this.dipStatusFilter) {
      this.searches = [];
      return;
    }

    this.isSearchesLoading = true;

    this.searches = [];

    var userName = this.currentUser.UserName;

    if (this.selectedNavMenu == this.NavMenuEnum.DevelopmentFinance) {
      this.$CaseSummaryService
        .getDevFinanceSearchesForUser(
          userName,
          this.clientCaseNameFilter,
          this.caseStatusFilter,
          count,
        )
        .then((results: CaseSummaryDTO[]) => {
          if (this.selectedNavMenu == this.NavMenuEnum.DevelopmentFinance) {
            this.searches = results;
            this.isSearchesLoading = false;
          }
        })
        .catch((error) => {
          this.searches = [];
          this.isSearchesLoading = false;
        });
    } else if (this.selectedNavMenu == this.NavMenuEnum.BridgingFinance) {
      this.$CaseSummaryService
        .getBridgingSearchesForUser(
          userName,
          this.clientCaseNameFilter,
          this.caseStatusFilter,
          count,
        )
        .then((results: CaseSummaryDTO[]) => {
          if (this.selectedNavMenu == this.NavMenuEnum.BridgingFinance) {
            this.searches = results;
            this.isSearchesLoading = false;
          }
        })
        .catch((error) => {
          this.searches = [];
          this.isSearchesLoading = false;
        });
    } else if (this.selectedNavMenu == this.NavMenuEnum.CommercialMortgages) {
      this.$CaseSummaryService
        .getCommercialSearchesForUser(
          userName,
          this.clientCaseNameFilter,
          this.caseStatusFilter,
          count,
        )
        .then((results: CaseSummaryDTO[]) => {
          if (this.selectedNavMenu == this.NavMenuEnum.CommercialMortgages) {
            this.searches = results;
            this.isSearchesLoading = false;
          }
        })
        .catch((error) => {
          this.searches = [];
          this.isSearchesLoading = false;
        });
    } else {
      this.$CaseSummaryService
        .getAllSearchesForUser(
          userName,
          this.clientCaseNameFilter,
          this.caseStatusFilter,
          count,
        )
        .then((results: CaseSummaryDTO[]) => {
          if (this.selectedNavMenu == this.NavMenuEnum.Home) {
            this.searches = results;
            this.isSearchesLoading = false;
          }
        })
        .catch((error) => {
          this.searches = [];
          this.isSearchesLoading = false;
        });
    }
  }
  /**
   * Retrieves projects (appraisals) according to which menu option has been selected
   * @param count
   */
  //TODO: Use this
  getDealproject(count: number) { }
  /**
   * Retrieves projects (appraisals) according to which menu option has been selected
   * @param count
   */
  getProjects(count: number) {
    this.projects = [];

    var userName = this.currentUser.UserName;

    if (this.selectedNavMenu == this.NavMenuEnum.DevelopmentFinance) {
      this.isDevFinanceProjectsLoading = true;

      this.$CaseSummaryService
        .getDevFinanceCasesForUser(
          userName,
          this.clientCaseNameFilter,
          this.caseStatusFilter,
          this.dipStatusFilter,
          count,
        )
        .then((results) => {
          if (this.selectedNavMenu == this.NavMenuEnum.DevelopmentFinance) {
            this.projects = results;
            if (this.isLender) {
              this.projects.forEach((project) => {
                if (
                  project.ProductType == ProductTypeEnum.Development &&
                  project.DealID == null &&
                  project.CaseID != null
                ) {
                  this.setAssignedLender(project.CaseID, true);
                } else {
                  this.setAssignedLender(project.DealID);
                }
              });
            }
            this.isDevFinanceProjectsLoading = false;
          }
        })
        .then(() => {
          this.getTotalUnreadMessagesPerAppraisal();
        })
        .catch((error) => {
          this.projects = [];
          this.isDevFinanceProjectsLoading = false;
        });
    } else if (this.selectedNavMenu == this.NavMenuEnum.BridgingFinance) {
      this.isBridgingPreConProjectsLoading = true;

      this.$CaseSummaryService
        .getBridgingCasesForUser(
          userName,
          this.clientCaseNameFilter,
          this.caseStatusFilter,
          this.dipStatusFilter,
          count,
        )
        .then((results) => {
          if (this.selectedNavMenu == this.NavMenuEnum.BridgingFinance) {
            this.projects = results;
            if (this.isLender) {
              this.projects.forEach((project) => {
                this.setAssignedLender(project.DealID);
              });
            }
            this.isBridgingPreConProjectsLoading = false;
          }
        })
        .then(() => {
          this.getTotalUnreadMessagesPerAppraisal();
        })
        .catch((error) => {
          this.projects = [];
          this.isBridgingPreConProjectsLoading = false;
        });
    } else if (this.selectedNavMenu == this.NavMenuEnum.CommercialMortgages) {
      this.isCommercialProjectsLoading = true;

      this.$CaseSummaryService
        .getCommercialCasesForUser(
          userName,
          this.clientCaseNameFilter,
          this.caseStatusFilter,
          this.dipStatusFilter,
          count,
        )
        .then((results) => {
          if (this.selectedNavMenu == this.NavMenuEnum.CommercialMortgages) {
            this.projects = results;
            if (this.isLender) {
              this.projects.forEach((project) => {
                this.setAssignedLender(project.DealID);
              });
            }
            this.isCommercialProjectsLoading = false;
          }
        })
        .then(() => {
          this.getTotalUnreadMessagesPerAppraisal();
        })
        .catch((error) => {
          this.projects = [];
          this.isCommercialProjectsLoading = false;
        });
    } else {
      this.isAllProjectsLoading = true;

      this.$CaseSummaryService
        .getAllCasesForUser(
          userName,
          this.clientCaseNameFilter,
          this.caseStatusFilter,
          this.dipStatusFilter,
          count,
        )
        .then((results) => {
          if (this.selectedNavMenu == this.NavMenuEnum.Home) {
            this.projects = results;
            if (this.isLender) {
              this.projects.forEach((project) => {
                if (
                  project.ProductType == ProductTypeEnum.Development &&
                  project.DealID == null &&
                  project.CaseID != null
                ) {
                  this.setAssignedLender(project.CaseID, true);
                } else {
                  this.setAssignedLender(project.DealID);
                }
              });
            }
            this.isAllProjectsLoading = false;
          }
        })
        .then(() => {
          this.getTotalUnreadMessagesPerAppraisal();
        })
        .catch((error) => {
          this.projects = [];
          this.isAllProjectsLoading = false;
        });
    }
  }

  //toggleCardTableView() {
  //    // hide any open menus
  //    this.closeTileMenu();

  //    this.isCardView = !this.isCardView;
  //}

  getInvitedUsers() {
    this.inviteService
      .getInvitedBorrowers(this.currentUser.Id)
      .then((result) => {
        this.invitedUsers = result;
      });
  }

  getIntroducedBorrowers() {
    this.$user.getintroducees(this.currentUser.Id).then((introducedUsers) => {
      this.introducedUsers = introducedUsers;
    });
  }

  getIntroducedSearches(registeredIntroducerId: number) {
    if (!registeredIntroducerId) {
      return;
    }
    this.$DevelopmentInputWithNoLoginService
      .fetchByIntroducerId(registeredIntroducerId)
      .then((results) => {
        this.unsavedIntroducedSearches = results.filter(
          (r) => r.IntroducedSearchStatus < 2,
        );
        let savedIntroducedSearchesIdentified = results.filter(
          (r) => r.IntroducedSearchStatus >= 2,
        );
        if (savedIntroducedSearchesIdentified.length > 0) {
          this.$DevelopmentInputService
            .fetchByIntroducerId(registeredIntroducerId)
            .then((devInputResults) => {
              this.savedIntroducedSearches = devInputResults;
            });
        }

        this.getIntroducedBorrowers();
      });
  }

  filterProjectsAndSearches() {
    if (this.clientCaseNameFilter != null)
      sessionStorage.setItem("clientCaseNameFilter", this.clientCaseNameFilter);
    if (this.statusFilter != null)
      sessionStorage.setItem("statusFilter", this.statusFilter.toString());

    //TODO - what is this code doing?!
    if (this.selecteduserName) {
      this.selecteduserName = this.selecteduserName; // this is baffling!
    } else if ((this.$rootScope as any).clientUsernameBeingViewed) {
      this.selecteduserName = (
        this.$rootScope as any
      ).clientUsernameBeingViewed;
    } else {
      this.selecteduserName = this.currentUser.UserName;
    }

    if (this.selecteduserName !== this.currentUser.UserName) {
      this.$user.searchByEmail(this.selecteduserName).then((users) => {
        this.selectedUser = users[0];
      });
    }
    // end of todo

    this.mapFilterStatus();

    if (this.selectedDashboardType == this.DashboardTypeEnum.Home) {
      this.getProjectsAndSearches();
    } else if (this.selectedDashboardType == this.DashboardTypeEnum.Searches) {
      this.showDevFinanceSearchDashboard();
    } else if (this.selectedDashboardType == this.DashboardTypeEnum.Projects) {
      this.showProjectDashboard();
    }
  }

  /**Map the combined status filter dropdown to the correct enum filter (case/caselender) */
  mapFilterStatus() {
    if (this.statusFilter == undefined || isNaN(this.statusFilter)) {
      this.caseStatusFilter = null;
      this.dipStatusFilter = null;
      return;
    }

    if (this.isLender) {
      this.caseStatusFilter = null;
      this.dipStatusFilter = this.statusFilter;
    } else {
      switch (this.statusFilter) {
        case 1:
          this.caseStatusFilter = CaseStatusEnum.Search;
          this.dipStatusFilter = null;
          break;
        case 2:
          this.caseStatusFilter = CaseStatusEnum.NewCase;
          this.dipStatusFilter = null;
          break;
        case 3:
          this.caseStatusFilter = CaseStatusEnum.SubmittedToLendersForHoT;
          this.dipStatusFilter = null;
          break;
        case 4:
          this.caseStatusFilter = null;
          this.dipStatusFilter = CaseLenderStateEnum.Offered;
          break;
        case 5:
          this.caseStatusFilter = null;
          this.dipStatusFilter = CaseLenderStateEnum.Rejected;
          break;
        case 6:
          this.caseStatusFilter = null;
          this.dipStatusFilter = CaseLenderStateEnum.Withdrawn;
          break;
        case 7:
          this.caseStatusFilter = CaseStatusEnum.Applied;
          this.dipStatusFilter = null;
          break;
        case 8:
          this.caseStatusFilter = CaseStatusEnum.ReadyToReSubmit;
          this.dipStatusFilter = null;
          break;
        case 0:
        default:
          this.caseStatusFilter = null;
          this.dipStatusFilter = null;
          break;
      }
    }
  }

  getCaseStatusText(status: CaseStatusEnum): string {
    var displayText = "";

    switch (status) {
      case CaseStatusEnum.NewCase:
        displayText = "CASE IN PROGRESS";
        break;
      case CaseStatusEnum.UnderReview:
        displayText = "UNDER ADMIN REVIEW";
        break;
      case CaseStatusEnum.ReadyToSubmit:
        displayText = "READY TO SUBMIT TO LENDERS";
        break;
      case CaseStatusEnum.SubmittedToLendersForHoT:
        displayText = "SUBMITTED TO LENDERS FOR DIP";
        break;
      case CaseStatusEnum.Applied:
        displayText = "APPLIED TO LENDER";
        break;
      case CaseStatusEnum.Completed:
        displayText = "COMPLETE";
        break;
      case CaseStatusEnum.SubmittedToLendersPendingReview:
        displayText = "SUBMITTED TO LENDERS PENDING REVIEW";
        break;
      case CaseStatusEnum.ReadyToReSubmit:
        displayText = "READY TO BE RE-SUBMITTED TO LENDERS FOR DIP";
        break;
      default:
      case CaseStatusEnum.NewCase:
        displayText = "";
        break;
    }

    return displayText;
  }

  nFormatter(num: number, decimalPlaces: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(decimalPlaces).replace(rx, "$1") +
      item.symbol
      : "0";
  }

  isDateToday(date: Date) {
    if (date) {
      const today = new Date();

      if (
        today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate()
      ) {
        return true;
      }
    }

    return false;
  }

  isDateYesterday(date: Date) {
    if (date) {
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      if (
        yesterday.getFullYear() === date.getFullYear() &&
        yesterday.getMonth() === date.getMonth() &&
        yesterday.getDate() === date.getDate()
      ) {
        return true;
      }
    }

    return false;
  }

  getLastUpdated(date: Date): string {
    var displayValue: string;

    if (date) {
      if (this.isDateToday(date)) {
        displayValue = "Today";
      } else if (this.isDateYesterday(date)) {
        displayValue = "Yesterday";
      } else {
        displayValue = date.toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      }
    }

    return displayValue;
  }

  goToSearchResults(caseSummary: CaseSummaryDTO) {
    this.sharedDataService.cleanLenderReferralSessionStorage();
    if (caseSummary.ProductType != ProductTypeEnum.Development) {
      if (this.isSearchClickable(caseSummary.isOwner)) {
        if (
          caseSummary.ProductType == ProductTypeEnum.CommercialInvestment ||
          caseSummary.ProductType == ProductTypeEnum.CommercialOwnerOccupied
        ) {
          this.$location.path("/commercialresults/" + caseSummary.DealID);
        } else {
          this.$location.path("/bridgingresults/" + caseSummary.DealID);
        }
      }
    } else {
      if (this.isSearchClickable(caseSummary.isOwner)) {
        if (caseSummary.UniqueRef != null) {
          this.$DevelopmentInputWithNoLoginService
            .isResultActive(caseSummary.UniqueRef)
            .then((results) => {
              if (!results) {
                this.displayMsg = `Changes have been made to this search by your client, your user dashboard will now refresh`;
                this.showShareResults = 5;
              } else {
                this.$location.path("/referredSearch/" + caseSummary.UniqueRef);
              }
            });
        } else if (caseSummary.DevelopmentInputID) {
          this.$location.path("/results/" + caseSummary.DevelopmentInputID);
        } else if (caseSummary.DealID) {
          this.$location.path("/devfinanceresults/" + caseSummary.DealID);
        }
      }
    }
  }

  goToProject(project: CaseSummaryDTO) {
    this.sharedDataService.cleanLenderReferralSessionStorage();
    if (
      (this.roleService.IsLender && this.showLenderTerms) ||
      !this.roleService.IsLender
    ) {
      if (this.roleService.IsLender && this.showLenderTerms) {
        project = { ...this.lenderSelectedProject };
      }

      if (this.roleService.IsLender && this.goToDealForumClicked) {
        this.goToDealForum(project);
      } else {
        if (this.isProjectClickable(project.isInvitedToCase)) {
          if (project.isInvitedAndHasReadonlyAccess) {
            (this.$rootScope as any).isInvitedAndHasReadonlyAccess = true;
            this.$rootScope.$broadcast("invitedStatusChange");
          }
          if (
            project.ProductType == ProductTypeEnum.BridgingPreconstruction ||
            project.ProductType ==
            ProductTypeEnum.BridgingPurchaseOrRefinance ||
            project.ProductType == ProductTypeEnum.BridgingDeveloperExit ||
            project.ProductType == ProductTypeEnum.BridgingRefurb
          ) {
            this.$location.path("/bridgingcasedashboard/" + project.DealID);
          } else if (
            project.ProductType == ProductTypeEnum.CommercialInvestment ||
            project.ProductType == ProductTypeEnum.CommercialOwnerOccupied
          ) {
            this.$location.path("/commercialcasedashboard/" + project.DealID);
          } else if (project.ProductType == ProductTypeEnum.Development) {
            if (project.DealID) {
              this.$location.path("/devfinancecasedashboard/" + project.DealID);
            } else {
              this.$location.path("/casedashboard/" + project.CaseID);
            }
          }
        }
      }
    } else if (this.roleService.IsLender && !this.showLenderTerms) {
      this.showLenderTerms = true;
      this.lenderSelectedProject = { ...project };
    }
  }

  goToNewSearch() {
    this.sharedDataService.cleanLenderReferralSessionStorage();
    // If the current user is a broker and doesn't have a subscription then don't allow them to create a new search
    if (this.isBroker && !this.currentUser.HasActiveSubscription) {
      return;
    }

    if (!this.isPageLoading) {
      (this.$rootScope as any).loanCriteria = null;

      if (
        this.isAdmin ||
        (this.currentUser && this.currentUser.SubscriptionStatus) ||
        this.isLender
      ) {
        this.authService.setHasValidStatustoShowShareSearchModal(
          true,
        );
      }

      if (
        this.selectedNavMenu == this.NavMenuEnum.DevelopmentFinance &&
        !this.hideDevelopmentFinanceSearch
      ) {
        this.goToNewDevFinanceSearch();
      }

      if (
        this.selectedNavMenu == this.NavMenuEnum.BridgingFinance &&
        !this.hideBridgingSearch
      ) {
        this.goToNewBridgingFinanceSearch();
      }

      if (
        this.selectedNavMenu == this.NavMenuEnum.CommercialMortgages &&
        !this.hideCommercialSearch
      ) {
        this.goToNewCommercialSearch();
      }

      if (this.selectedNavMenu == this.NavMenuEnum.Home || this.selectedNavMenu == this.NavMenuEnum.EquityAndMezz) {
        this.$location.path("/allloans");
      }
    }
  }

  goToNewProject() {
    this.isPageLoading = true;
    this.roleService.isBroker().then((isBroker: boolean) => {
      this.$CaseService
        .newBlankCase(this.currentUser, !isBroker)
        .then((newBlankCaseDto) => {
          if (newBlankCaseDto && newBlankCaseDto.NewCaseId) {
            this.$location.path("/casedashboard/" + newBlankCaseDto.NewCaseId);
          }

          this.isPageLoading = false;
        });
    });
  }

  goToNewBridgingSearch() {
    if (this.hasBridging) {
      this.$location.path("/bridgingcriteria/");
    }
  }

  goToNewCommercialSearch() {
    if (this.hasCommercial) {
      this.$location.path("/commercialcriteria");
    }
  }

  goToAllLoanPage() {
    this.$location.path("/allloans");
  }

  goToPurchaseOrRefinanceSearch() {
    if (this.hasBridging) {
      this.$location.path("/purchaserefinancecriteria/");
    }
  }

  goToNewDevFinanceSearch() {
    this.$location.path("/devfinancecriteria");
  }

  goToNewBridgingFinanceSearch() {
    this.$location.path("/bridgingcriteria");
  }

  deleteSearchConfirmation(dataItem) {
    this.closeTileMenu();
    event.stopPropagation();
    this.actionItem = dataItem;

    if (this.actionItem.isDevInputWithNoLogin) {
      this.$DevelopmentInputWithNoLoginService
        .isResultActive(this.actionItem.UniqueRef)
        .then((results) => {
          if (!results) {
            this.displayMsg = `Changes have been made to this search by your client, your user dashboard will now refresh`;
            this.showShareResults = 5;
          } else {
            this.confirmSearchToDelete = true;
          }
        });
    } else {
      this.confirmSearchToDelete = true;
    }
  }

  showAssignBrokerSearchModal(dataItem) {
    this.closeTileMenu();
    event.stopPropagation();
    this.actionItem = dataItem;
    this.confirmAssignBrokerSearch = true;
    this.selectedBroker = this.licensedOrganisationBrokers.find(
      (b) => b.Id == this.actionItem.BrokerUserId,
    );
  }

  showAssignLenderSearchModal(dataItem) {
    this.closeTileMenu();
    event.stopPropagation();
    this.actionItem = dataItem;
    this.getLenderUsersList();
    if (
      this.actionItem.ProductType == ProductTypeEnum.Development &&
      this.actionItem.DealID == null &&
      this.actionItem.CaseID != null
    ) {
      this.caseLenderService
        .fetchCaseLender(this.actionItem.CaseID)
        .then((response) => {
          return this.userService.getUserByUserId(
            response.AssignedLenderUserId,
          );
        })
        .then((response) => {
          if (response != null) {
            this.selectedLender = response;
          }
        })
        .finally(() => {
          this.confirmAssignLenderSearch = true;
        });
    } else {
      this.dealLenderService
        .fetchDealLender(this.actionItem.DealID)
        .then((response) => {
          return this.userService.getUserByUserId(
            response.AssignedLenderUserId,
          );
        })
        .then((response) => {
          if (response != null) {
            this.selectedLender = response;
          }
        })
        .finally(() => {
          this.confirmAssignLenderSearch = true;
        });
    }
  }

  assignBrokerToSearch(assignBrokerId) {
    this.confirmAssignBrokerSearch = false;
    this.isPageLoading = true;

    if (
      this.actionItem.ProductType == ProductTypeEnum.Development &&
      this.actionItem.DealID == null
    ) {
      if (this.actionItem.CaseID) {
        this.$CaseService
          .changeBrokerUser(this.actionItem.CaseID, assignBrokerId)
          .then((success) => {
            if (success) {
              this.getProjects(
                this.selectedDashboardType == this.DashboardTypeEnum.Home
                  ? this.homeScreenCount
                  : 0,
              );
            } else {
              this.displayMsg =
                "We were unable to assign the broker to the case at this time, please try again later.";
              this.showShareResults = 4;
            }
          })
          .finally(() => {
            this.actionItem = null;
            this.isPageLoading = false;
            this.selectedBroker = null;
          });
      } else {
        this.$DevelopmentInputService
          .assignBrokerToSearch(
            this.actionItem.DevelopmentInputID,
            assignBrokerId,
            this.actionItem.isDevInputWithNoLogin,
          )
          .then((success) => {
            if (success) {
              this.getSearches(
                this.selectedDashboardType == this.DashboardTypeEnum.Home
                  ? this.homeScreenCount
                  : 0,
              );
            } else {
              this.displayMsg =
                "We were unable to assign the broker to the search at this time, please try again later.";
              this.showShareResults = 4;
            }
          })
          .finally(() => {
            this.actionItem = null;
            this.isPageLoading = false;
            this.selectedBroker = null;
          });
      }
    } else {
      this.dealService
        .assignBrokerToDeal(this.actionItem.DealID, assignBrokerId)
        .then((success) => {
          if (success) {
            if (this.actionItem.CaseStatus == CaseStatusEnum.Search) {
              this.getSearches(
                this.selectedDashboardType == this.DashboardTypeEnum.Home
                  ? this.homeScreenCount
                  : 0,
              );
            } else {
              this.getProjects(
                this.selectedDashboardType == this.DashboardTypeEnum.Home
                  ? this.homeScreenCount
                  : 0,
              );
            }
          } else {
            this.displayMsg =
              "There was a problem assigning the broker, please try later.";
            this.showShareResults = 4;
          }
        })
        .finally(() => {
          this.actionItem = null;
          this.isPageLoading = false;
          this.selectedBroker = null;
        });
    }
  }

  assignLenderToSearch(assignLenderId) {
    this.confirmAssignLenderSearch = false;
    this.isPageLoading = true;

    if (
      this.actionItem.ProductType == ProductTypeEnum.Development &&
      this.actionItem.DealID == null &&
      this.actionItem.CaseID != null
    ) {
      this.$CaseService
        .assignLenderToCase(
          this.actionItem.CaseID,
          this.currentUser.LenderId,
          assignLenderId,
        )
        .then((success) => {
          if (success) {
            if (this.actionItem.CaseStatus == CaseStatusEnum.Search) {
              this.getSearches(
                this.selectedDashboardType == this.DashboardTypeEnum.Home
                  ? this.homeScreenCount
                  : 0,
              );
            } else {
              this.getProjects(
                this.selectedDashboardType == this.DashboardTypeEnum.Home
                  ? this.homeScreenCount
                  : 0,
              );
            }
            this.setAssignedLender(this.actionItem.CaseID);
          } else {
            this.displayMsg =
              "There was a problem assigning the lender, please try later.";
            this.showShareResults = 4;
          }
        })
        .finally(() => {
          this.actionItem = null;
          this.isPageLoading = false;
          this.selectedLender = null;
          this.lenderList = null;
        });
    } else {
      this.dealService
        .assignLenderToDeal(
          this.actionItem.DealID,
          this.currentUser.LenderId,
          assignLenderId,
        )
        .then((success) => {
          if (success) {
            if (this.actionItem.CaseStatus == CaseStatusEnum.Search) {
              this.getSearches(
                this.selectedDashboardType == this.DashboardTypeEnum.Home
                  ? this.homeScreenCount
                  : 0,
              );
            } else {
              this.getProjects(
                this.selectedDashboardType == this.DashboardTypeEnum.Home
                  ? this.homeScreenCount
                  : 0,
              );
            }
            this.setAssignedLender(this.actionItem.DealID);
          } else {
            this.displayMsg =
              "There was a problem assigning the lender, please try later.";
            this.showShareResults = 4;
          }
        })
        .finally(() => {
          this.actionItem = null;
          this.isPageLoading = false;
          this.selectedLender = null;
          this.lenderList = null;
        });
    }
  }

  setAssignedLender(caseId: number, oldDevFinance: boolean = false) {
    if (oldDevFinance) {
      this.caseLenderService
        .fetchCaseLender(caseId)
        .then((response) => {
          return this.userService.getUserByUserId(
            response.AssignedLenderUserId,
          );
        })
        .then((response) => {
          if (response != null) {
            this.assignedLenders[caseId] = response.FullName;
          } else {
            this.assignedLenders[caseId] = null;
          }
        })
        .catch(() => {
          this.assignedLenders[caseId] = null;
        });
    } else {
      this.dealLenderService
        .fetchDealLender(caseId)
        .then((response) => {
          return this.userService.getUserByUserId(
            response.AssignedLenderUserId,
          );
        })
        .then((response) => {
          if (response != null) {
            this.assignedLenders[caseId] = response.FullName;
          } else {
            this.assignedLenders[caseId] = null;
          }
        })
        .catch(() => {
          this.assignedLenders[caseId] = null;
        });
    }
  }

  checkAssignedLender(selectedCase: CaseSummaryDTO) {
    if (
      selectedCase.ProductType == ProductTypeEnum.Development &&
      selectedCase.DealID == null &&
      selectedCase.CaseID != null
    ) {
      return this.assignedLenders[selectedCase.CaseID] != null;
    } else {
      return this.assignedLenders[selectedCase.DealID] != null;
    }
  }

  getLenderUsersList() {
    this.lenderList = [];
    this.lenderUsers?.forEach((user) => {
      if (user.IsLenderAdmin) {
        this.lenderList.push(user);
      } else {
        if (
          (user.LenderProductTypes & LenderProductTypeEnum.All) ==
          LenderProductTypeEnum.All
        ) {
          this.lenderList.push(user);
        }

        if (
          (user.LenderProductTypes &
            LenderProductTypeEnum.DevelopmentFinance) ==
          LenderProductTypeEnum.DevelopmentFinance &&
          this.actionItem.ProductType == ProductTypeEnum.Development
        ) {
          this.lenderList.push(user);
        }

        if (
          (user.LenderProductTypes & LenderProductTypeEnum.BridgingFinance) ==
          LenderProductTypeEnum.BridgingFinance &&
          (this.actionItem.ProductType == ProductTypeEnum.Bridging ||
            this.actionItem.ProductType ==
            ProductTypeEnum.BridgingPreconstruction ||
            this.actionItem.ProductType ==
            ProductTypeEnum.BridgingPurchaseOrRefinance ||
            this.actionItem.ProductType == ProductTypeEnum.BridgingRefurb ||
            this.actionItem.ProductType ==
            ProductTypeEnum.BridgingDeveloperExit)
        ) {
          this.lenderList.push(user);
        }

        if (
          (user.LenderProductTypes &
            LenderProductTypeEnum.CommercialMortgages) ==
          LenderProductTypeEnum.CommercialMortgages &&
          (this.actionItem.ProductType == ProductTypeEnum.CommercialAll ||
            this.actionItem.ProductType ==
            ProductTypeEnum.CommercialInvestment ||
            this.actionItem.ProductType ==
            ProductTypeEnum.CommercialOwnerOccupied)
        ) {
          this.lenderList.push(user);
        }
      }
    });
  }

  deleteSearch() {
    this.confirmSearchToDelete = false;
    this.isPageLoading = true;
    if (this.actionItem.isDevInputWithNoLogin) {
      //Introducer: Referred search, not claimed by introducee yet
      this.$DevelopmentInputWithNoLoginService
        .markasdeleted(this.actionItem.DevelopmentInputID)
        .then((success) => {
          this.getSearches(
            this.selectedDashboardType == this.DashboardTypeEnum.Home
              ? this.homeScreenCount
              : 0,
          );
        })
        .finally(() => {
          this.actionItem = null;
          this.isPageLoading = false;
        });
    } else {
      if (
        this.actionItem.ProductType == ProductTypeEnum.Development &&
        this.actionItem.DevelopmentInputID
      ) {
        //Introducer: Referred search, claimed by introducee
        this.$DevelopmentInputService
          .markasdeleted(this.actionItem.DevelopmentInputID)
          .then((success) => {
            this.getSearches(
              this.selectedDashboardType == this.DashboardTypeEnum.Home
                ? this.homeScreenCount
                : 0,
            );
          })
          .finally(() => {
            this.actionItem = null;
            this.isPageLoading = false;
          });
      } else {
        this.dealService
          .markasdeleted(this.actionItem.DealID)
          .then((success) => {
            this.getSearches(
              this.selectedDashboardType == this.DashboardTypeEnum.Home
                ? this.homeScreenCount
                : 0,
            );
          })
          .finally(() => {
            this.actionItem = null;
            this.isPageLoading = false;
          });
      }
    }
  }

  cancelCopyProject() {
    this.confirmCopyProjectName = false;
  }

  copyProjectConfirmation(dataItem) {
    this.closeTileMenu();
    event.stopPropagation();
    this.actionItem = dataItem;

    this.newProjectName = this.actionItem.CaseName;

    // show popup input for new name
    this.confirmCopyProjectName = true;
  }

  copyProject() {
    this.confirmCopyProjectName = false;
    this.isPageLoading = true;

    if (
      this.actionItem.ProductType == ProductTypeEnum.Development &&
      this.actionItem.DevelopmentInputID
    ) {
      this.$DevelopmentInputService
        .copyCase(this.actionItem.DevelopmentInputID, this.newProjectName)
        .then((response) => {
          let newCaseId = response;
          if (newCaseId) {
            this.$applicantservice.copyShareholder(
              this.actionItem.CaseID,
              newCaseId,
            );
            this.getProjects(
              this.selectedDashboardType == this.DashboardTypeEnum.Home
                ? this.homeScreenCount
                : 0,
            );
          }
        })
        .finally(() => {
          this.getProjects(
            this.selectedDashboardType == this.DashboardTypeEnum.Home
              ? this.homeScreenCount
              : 0,
          );
          this.actionItem = null;
          this.isPageLoading = false;
        });
    } else {
      this.dealService
        .copyCase(
          this.actionItem.DealID,
          this.newProjectName,
          this.actionItem.ProductType,
        )
        .then((response) => {
          let newCaseId = response;
          if (newCaseId) {
            //TODO: On monday make getprojects use deal model
            this.getDealproject(
              this.selectedDashboardType == this.DashboardTypeEnum.Home
                ? this.homeScreenCount
                : 0,
            );
          }
        })
        .finally(() => {
          this.getProjects(
            this.selectedDashboardType == this.DashboardTypeEnum.Home
              ? this.homeScreenCount
              : 0,
          );
          this.actionItem = null;
          this.isPageLoading = false;
        });
    }
  }

  deleteProjectConfirmation(dataItem) {
    this.closeTileMenu();
    event.stopPropagation();
    this.actionItem = dataItem;

    this.confirmProjectToDelete = true;
  }

  deleteProject() {
    this.confirmProjectToDelete = false;

    if (
      this.actionItem.ProductType == ProductTypeEnum.Development &&
      this.actionItem.DevelopmentInputID
    ) {
      //Full case and Saved search
      this.$DevelopmentInputService
        .markasdeleted(this.actionItem.DevelopmentInputID)
        .then((success) => {
          if (this.actionItem.CaseID) {
            this.$CaseService
              .markasdeleted(this.actionItem.CaseID)
              .then((success) => { });
          }
        })
        .finally(() => {
          this.getProjects(
            this.selectedDashboardType == this.DashboardTypeEnum.Home
              ? this.homeScreenCount
              : 0,
          );
          this.actionItem = null;
          this.isPageLoading = false;
        });
    } else {
      this.dealService
        .markasdeleted(this.actionItem.DealID)
        .then((success) => { })
        .finally(() => {
          this.getProjects(
            this.selectedDashboardType == this.DashboardTypeEnum.Home
              ? this.homeScreenCount
              : 0,
          );
          this.actionItem = null;
          this.isPageLoading = false;
        });
    }
  }

  // START OF INTRODUCTIONS CODE - TO BE REVIEWED WHEN UI IS RE-WORKED

  introduceClient() {
    this.isProcessingSend = true;
    this.invite = {
      FirstName: this.clientFirstName,
      Email: this.clientEmail,
      LastName: this.clientSurname,
      InviteerUserId: this.currentUser.Id,
      UserId: null,
      PhoneNumber: this.clientPhoneNumber,
      Status: StatusEnum.Invited,
    } as InviteDTO;

    this.inviteService.addUpdate(this.invite).then((response) => {
      this.invitedUsers.push(response);

      this.$IntroducerService
        .introduceClient(
          response.FirstName,
          response.Email,
          response.LastName,
          response.PhoneNumber,
          response.guid,
        )
        .then((success) => {
          this.introduceClientModal = 2;
          this.isPageLoading = false;
          this.isProcessingSend = false;
        })
        .finally(() => {
          delete this.clientFirstName;
          delete this.clientEmail;
          delete this.clientSurname;
          delete this.clientPhoneNumber;
        });
    });
  }

  userLookup(userSearchTerm: string) {
    this.isPageLoading = true;
    this.$user
      .searchByEmail(userSearchTerm)
      .then((response) => {
        if (this.isBroker && this.currentUser.IsOrganisationAdmin) {
          this.userSearchResults = response.filter(
            (x) =>
              x.OrganisationReferralId == this.currentUser.OrganisationId ||
              x.OrganisationId == this.currentUser.OrganisationId,
          );
        } else if (this.isBroker) {
          this.userSearchResults = response.filter(
            (x) =>
              x.OrganisationReferralId == this.currentUser.OrganisationId &&
              !x.OrganisationId &&
              !x.IsOrganisationAdmin,
          );
        } else {
          this.userSearchResults = response;
        }
      })
      .finally(() => {
        this.isPageLoading = false;
      });
  }

  exitClientView() {
    this.selectedClientName = null;
    this.showHomeDashboard();
  }

  deleteUnsavedIntroducedSearch(loanCriteriaId: number, index: number) {
    //Introducer: Referred search, not claimed by introducee yet
    this.$DevelopmentInputWithNoLoginService
      .markasdeleted(loanCriteriaId)
      .then((success) => {
        this.unsavedIntroducedSearches.splice(index, 1);
      });
  }

  deleteSavedIntroducedSearch(loanCriteriaId: number, index: number) {
    //Introducer: Referred search, claimed by introducee
    this.$DevelopmentInputService
      .markasdeleted(loanCriteriaId)
      .then((success) => {
        this.savedIntroducedSearches.splice(index, 1);
      });
  }

  copyLinkToClipboard() {
    this.$IntroducerService
      .copyLinkToClipboard(this.currentUser.Id)
      .then((response) => {
        //this.link = response.toString();
        navigator.clipboard.writeText(response.toString());
        this.introduceClientModal = 7;
      });
  }

  sendLinkToUser() {
    this.$IntroducerService
      .sendLinkToUser(
        this.currentUser.FirstName,
        this.currentUser.Email,
        this.currentUser.Id,
      )
      .then((success) => {
        this.introduceClientModal = 6;
      });
  }

  registerIntroducer() {
    this.$location.path("/registerintroducer");
  }

  inviteBorrower() {
    this.isProcessingSend = true;
    this.invite = {
      FirstName: this.clientFirstName,
      Email: this.clientEmail,
      LastName: this.clientSurname,
      InviteerUserId: this.currentUser.Id,
      UserId: null,
      PhoneNumber: this.clientPhoneNumber,
      Status: StatusEnum.Invited,
    } as InviteDTO;

    this.inviteService.addUpdate(this.invite).then((response) => {
      this.invitedUsers.push(response);

      this.$user.GetUserOrganisation().then((result) => {
        if (this.invite && result) {
          this.organisationService
            .inviteBorrowers(this.invite, result.UniqueRef)
            .then((success) => {
              this.introduceClientModal = 9;
              this.dataLoading = false;
            })
            .finally(() => {
              this.introduceClientModal = 9;
              delete this.clientFirstName;
              delete this.clientEmail;
              delete this.clientSurname;
              delete this.clientPhoneNumber;
              this.isProcessingSend = false;
            });
        }
      });
    });
  }

  // END OF INTRODUCTIONS CODE - ALL INTRODUCTIONS CODE TO BE REVIEWED WHEN UI IS RE-WORKED

  openShareSearchModal(item: CaseSummaryDTO) {
    event.stopPropagation();
    this.closeTileMenu();

    // Only get the broker's/admin's users if we haven't already got it
    if (!this.existingUsers) {
      this.roleService
        .isBrokerOrABove()
        .then((isBrokerOrAbove: boolean) => {
          if (isBrokerOrAbove) {
            this.getUserSummariesForBrokerOrAdmin();
          }
        });
    }

    if (
      item.ProductType == ProductTypeEnum.Development &&
      item.DevelopmentInputID
    ) {
      this.isBridging = false;
      if (item.isDevInputWithNoLogin) {
        /** This will check if the result shared with new user is active */
        this.$DevelopmentInputWithNoLoginService
          .isResultActive(item.UniqueRef)
          .then((results) => {
            if (!results) {
              this.displayMsg = `Changes have been made to this search by your client, your user dashboard will now refresh`;
              this.showShareResults = 5;
            } else {
              this.$DevelopmentInputWithNoLoginService
                .fetch(item.DevelopmentInputID)
                .then((response) => {
                  this.selectedSearch = item;
                  this.loanCriteria = response;
                  this.selectedResult = item.DevelopmentInputID;
                  this.shareSearchModal = 3;
                  this.newSearch = true;
                  this.sharedSearch = false;
                  this.existingborrower = null;

                  if (this.loanCriteria.ShowLenderNamesToBorrower == null) {
                    this.organisationService.getOrganisation().then((org) => {
                      this.loanCriteria.ShowLenderNamesToBorrower =
                        org.ShowLenderNames;
                    });
                  }

                  if (
                    (this.roleService.isAdminUser || this.isBroker) &&
                    item.ClientEmail != this.currentUser.UserName
                  ) {
                    this.clientFirstName = item.ClientFirstName;
                    this.clientSurname = item.ClientSurname;
                    this.clientEmail = item.ClientEmail;
                    this.sharedSearch = true;
                  } else {
                    this.clientFirstName = "";
                    this.clientSurname = "";
                    this.clientEmail = "";
                    this.clientPhoneNumber = "";
                  }
                });
            }
          });
      } else {
        this.$DevelopmentInputService
          .fetch(item.DevelopmentInputID)
          .then((response) => {
            this.selectedSearch = item;
            this.loanCriteria = response;
            this.selectedResult = item.DevelopmentInputID;
            this.shareSearchModal = 3;
            this.newSearch = true;
            this.sharedSearch = false;
            this.existingborrower = null;

            if (this.loanCriteria.ShowLenderNamesToBorrower == null) {
              this.organisationService.getOrganisation().then((org) => {
                this.loanCriteria.ShowLenderNamesToBorrower =
                  org.ShowLenderNames;
              });
            }

            if (
              (this.roleService.isAdminUser || this.isBroker) &&
              item.ClientEmail != this.currentUser.UserName
            ) {
              this.clientFirstName = item.ClientFirstName;
              this.clientSurname = item.ClientSurname;
              this.clientEmail = item.ClientEmail;
              this.sharedSearch = true;
            } else {
              this.clientFirstName = "";
              this.clientSurname = "";
              this.clientEmail = "";
              this.clientPhoneNumber = "";
            }
          });
      }
    } else {
      this.isBridging = true;
      this.selectedSearch = item;
      this.selectedResult = item.DevelopmentInputID;
      this.shareSearchModal = 3;
      this.newSearch = true;
      this.sharedSearch = false;
      this.existingborrower = null;

      this.clientService
        .fetchListByDealId(item.DealID)
        .then((dealClients: ClientDTO[]) => {
          this.shareDealDto = {
            DealId: item.DealID,
            DealName: item.CaseName,
            ShowLenderNamesAndLogos: false,
            EmailLinkToClient: false,
            AccessLevel: CaseAccessLevelEnum.FullAccess,
            IsApplicant: true,
            IsPrimary: true,
          } as ShareDealDTO;

          if (dealClients && dealClients.length > 0) {
            var client = dealClients[0]; // TODO - always want to assume there's one for now until there's a redesign of sharing

            this.shareDealDto.ClientDto = {
              FirstName: client.FirstName,
              LastName: client.LastName,
              Email: client.Email,
              PhoneNumber: client.PhoneNumber,
              ClientUserId: client.ClientUserId,
            } as ClientDTO;

            this.sharedSearch = true;
          } else {
            this.shareDealDto.ClientDto = {
              FirstName: "",
              LastName: "",
              Email: "",
              PhoneNumber: "",
            } as ClientDTO;
          }
        });
    }
  }

  /*
        introducerSendResultsToClient(notifyBorrower: boolean = true) {
            this.dataLoading = true;
            this.$IntroducerService.sendResultsToClient(this.selectedSearch, this.clientFirstName, this.clientSurname, this.clientEmail, notifyBorrower).then((success) => {
                this.shareSearchModal = 4;
            }).catch(reason => {
                this.shareSearchErrorDisplayed = true;
            }).finally(() => {
                this.dataLoading = false;
                delete this.clientFirstName;
                delete this.clientEmail;
                delete this.clientSurname;
                delete this.selectedSearch;
            });
        }*/

  introducerRefreshSearchesAndClients() {
    this.getIntroducedSearches(this.currentUser.RegisteredIntroducerId);
    this.clientAdminTools = true;
  }

  updateIntroducedClientList() {
    this.$user.getintroducees(this.currentUser.Id).then((introducedUsers) => {
      this.introducedUsers = introducedUsers;
    });
  }

  closeIntroducerClientModal() {
    this.introduceClientModal = null;
    delete this.clientFirstName;
    delete this.clientEmail;
    delete this.clientSurname;
    this.introducerErrorDisplayed = false;
  }

  closeSearchSearchModal() {
    this.shareSearchModal = null;
    this.shareSearchErrorDisplayed = false;
    delete this.selectedSearch;
  }

  isProjectClickable(isInvitedToCase: boolean) {
    // To be able to click on a project you must have one of the following:
    // 1. Paid up subcription
    // 2. Pre-cancel subscription i.e. it's been cancelled but it is still active until the end of the payment period
    // 3. subscription Payment processing i.e. they've signed up but the payment is still being processed e.g. DDs
    // 4. be a lender (no license required)
    // 5. be an admin (no license required)
    // 6. be invited to the case

    if (
      this.currentUser.SubscriptionStatus == LicenseMasterStatusEnum.PaidUp ||
      this.currentUser.SubscriptionStatus ==
      LicenseMasterStatusEnum.PreCancel ||
      this.currentUser.SubscriptionStatus ==
      LicenseMasterStatusEnum.PaymentProcessing ||
      this.roleService.IsLender ||
      this.roleService.IsAdminUser ||
      isInvitedToCase == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  isSearchClickable(isOwner: boolean) {
    // To be able to click on a project you must have one of the following:
    // 1. Paid up subcription
    // 2. Pre-cancel subscription i.e. it's been cancelled but it is still active until the end of the payment period
    // 3. subscription Payment processing i.e. they've signed up but the payment is still being processed e.g. DDs
    // 4. be a lender (no license required)
    // 5. be an admin (no license required)
    // 6. be an owner and a client user - we're assuming here this will identify an invited user.  This may need re-work

    if (
      this.currentUser.SubscriptionStatus == LicenseMasterStatusEnum.PaidUp ||
      this.currentUser.SubscriptionStatus ==
      LicenseMasterStatusEnum.PreCancel ||
      this.currentUser.SubscriptionStatus ==
      LicenseMasterStatusEnum.PaymentProcessing ||
      this.roleService.IsLender ||
      this.roleService.IsAdminUser ||
      (isOwner == true && this.roleService.IsClient)
    ) {
      return true;
    } else {
      return false;
    }
  }

  signout() {
    this.authService.logout(true);
  }

  getStatusTextForLender(
    caseLenderState: CaseLenderStateEnum,
    IsBidAccepted: boolean,
  ): string {
    var statusText = this.caseLenderService.getStatusTextForLender(
      caseLenderState,
      IsBidAccepted,
    );
    return statusText;
  }

  /** closes share pop up*/
  cancelNewSearch() {
    this.newSearch = false;
    this.clearInputFields();
  }

  clearInputFields() {
    this.clientFirstName = null;
    this.clientEmail = null;
    this.clientSurname = null;
    this.clientPhoneNumber = null;
    this.showClientDetails = false;
    this.selecteduserName = null;
  }

  getUserSummariesForBrokerOrAdmin() {
    this.$user
      .getUserSummariesForBrokerOrAdmin()
      .then((response) => {
        this.existingUsers = response;
      })
      .finally(() => { });
  }

  getBrokerBelongToOrganisation(orgId): void {
    this.userService
      .getAllOrganisationMembers(orgId)
      .then((orgBrokers: ApplicationUserDTO[]) => {
        this.licensedOrganisationBrokers = orgBrokers.filter(
          (x) => x.SubscriptionStatus > 0,
        );
      });
  }

  viewClientsDashboardForNewSearch(userName: string) {
    //Look up client's account details
    this.$user.searchByEmail(userName).then((users) => {
      if (
        this.selectedSearch.ProductType == ProductTypeEnum.Development &&
        this.selectedSearch.DealID == null
      ) {
        this.clientFirstName = users[0].FirstName;
        this.clientEmail = users[0].Email;
        this.clientSurname = users[0].LastName;
        this.clientPhoneNumber = users[0].PhoneNumber;
        this.clientId = users[0].Id;
        this.showClientDetails = true;
      } else {
        this.shareDealDto.IsApplicant = true;
        this.shareDealDto.IsPrimary = true;
        this.shareDealDto.ClientDto.FirstName = users[0].FirstName;
        this.shareDealDto.ClientDto.Email = users[0].Email;
        this.shareDealDto.ClientDto.LastName = users[0].LastName;
        this.shareDealDto.ClientDto.PhoneNumber = users[0].PhoneNumber;
        this.shareDealDto.ClientDto.ClientUserId = users[0].Id;
        this.showClientDetails = true;
      }
    });
  }

  sendResultsToClient(notifyBorrower) {
    this.dataLoading = true;
    this.newSearch = false;
    this.existingborrower = null;
    this.showClientDetails = false;

    if (
      this.selectedSearch.ProductType == ProductTypeEnum.Development &&
      this.selectedSearch.DevelopmentInputID
    ) {
      this.userService
        .getUserDetailsByEmailIfExists(this.clientEmail)
        .then((user: ApplicationUserDTO) => {
          if (user == null) {
            this.loanCriteria.UserId = null;
            this.$IntroducerService
              .sendResultsToClient(
                this.loanCriteria,
                this.clientFirstName,
                this.clientSurname,
                this.clientEmail,
                notifyBorrower,
                this.clientPhoneNumber,
              )
              .then((success) => {
                this.showShareResults = 2;
              });
          } else {
            this.clientId = user.Id;
            this.$DevelopmentInputService
              .sendResultsToExistingClient(
                this.loanCriteria,
                this.clientFirstName,
                this.clientSurname,
                this.clientEmail,
                this.clientId,
                notifyBorrower,
                this.clientPhoneNumber,
              )
              .then((success) => {
                this.showShareResults = 3;
              });
          }
        })
        .finally(() => {
          delete this.clientFirstName;
          delete this.clientEmail;
          delete this.clientSurname;
          delete this.clientPhoneNumber;
          delete this.clientId;
          this.dataLoading = false;
        });
    } else {
      this.dealService
        .shareDealWithClient(this.shareDealDto)
        .then(() => {
          this.displayMsg =
            "Search results have been sent to " +
            this.shareDealDto.ClientDto.FirstName +
            " " +
            this.shareDealDto.ClientDto.LastName;
          this.showShareResults = 4;
        })
        .finally(() => {
          delete this.shareDealDto;
          this.dataLoading = false;
        });
    }
  }

  /* sendResultsToExistingClient(notifyBorrower: boolean = true, showMsgAfterResultSent: boolean = true) {
             this.newSearch = false;
             this.existingborrower = null;
             this.showClientDetails = false;
             this.$DevelopmentInputService.sendResultsToExistingClient(this.loanCriteria, this.clientFirstName, this.clientSurname, this.clientEmail, this.clientId, notifyBorrower, this.clientPhoneNumber).then((success) => {
                 delete this.clientFirstName;
                 delete this.clientEmail;
                 delete this.clientSurname;
                 delete this.clientPhoneNumber;
                 delete this.clientId;
                 this.showShareResults = 3;
             });
         }
 
 
         introducerSendResultsToClient(notifyBorrower: boolean = true) {
             this.newSearch = false;
             this.existingborrower = null;
             this.showClientDetails = false;
 
             // remove the user id because otherwise the client won't be able to see the search on their dashboard
             this.loanCriteria.UserId = null;
 
             this.$IntroducerService.sendResultsToClient(this.loanCriteria, this.clientFirstName, this.clientSurname, this.clientEmail, notifyBorrower, this.clientPhoneNumber).then((success) => {
                 delete this.clientFirstName;
                 delete this.clientEmail;
                 delete this.clientSurname;
                 delete this.clientPhoneNumber;
                 if (this.isBroker) {
                     this.showShareResults = 2;
                 } else {
                     this.showShareResults = 1;
                 }
             });
         }
         */

  sendShareSearchEmailToClient() {
    this.dataLoading = true;

    if (
      this.selectedSearch.ProductType == ProductTypeEnum.Development &&
      this.selectedSearch.DevelopmentInputID
    ) {
      if (
        this.loanCriteria.IntroduceeFirstName == null ||
        this.loanCriteria.IntroduceeSurname == null
      ) {
        this.loanCriteria.IntroduceeFirstName = this.clientFirstName;
        this.loanCriteria.IntroduceeSurname = this.clientSurname;
      }
      if (this.selectedSearch.isDevInputWithNoLogin) {
        this.$DevelopmentInputWithNoLoginService
          .sendEmailToNewUser(
            this.loanCriteria.UniqueRef,
            this.loanCriteria.ShowLenderNamesToBorrower,
            true,
          )
          .then((response) => {
            this.displayMsg =
              "Search results have been sent to " +
              this.loanCriteria.IntroduceeFirstName +
              " " +
              this.loanCriteria.IntroduceeSurname;
            this.newSearch = false;
            this.showShareResults = 4;
            this.dataLoading = false;
          })
          .catch((error) => {
            this.displayMsg = "Sorry, something went wrong. Please try again.";
            this.newSearch = false;
            this.showShareResults = 4;
            this.dataLoading = false;
          })
          .finally(() => {
            delete this.selectedSearch;
            delete this.loanCriteria;
            delete this.selectedResult;
            delete this.shareSearchModal;
            delete this.existingborrower;
            delete this.clientFirstName;
            delete this.clientSurname;
            delete this.clientEmail;
          });
      } else {
        this.$DevelopmentInputService
          .sendEmailToExistingUser(
            this.loanCriteria.Id,
            this.loanCriteria.ShowLenderNamesToBorrower,
            true,
          )
          .then((response) => {
            this.displayMsg =
              "Search results have been sent to " +
              this.loanCriteria.IntroduceeFirstName +
              " " +
              this.loanCriteria.IntroduceeSurname;
            this.newSearch = false;
            this.showShareResults = 4;
            this.dataLoading = false;
          })
          .catch((error) => {
            this.displayMsg = "Sorry, something went wrong. Please try again.";
            this.newSearch = false;
            this.showShareResults = 4;
            this.dataLoading = false;
          });
      }
    } else {
      this.shareDealDto.EmailLinkToClient = true;
      this.dealService
        .shareDealWithClient(this.shareDealDto)
        .then(() => {
          this.displayMsg =
            "Search results have been sent to " +
            this.shareDealDto.ClientDto.FirstName +
            " " +
            this.shareDealDto.ClientDto.LastName;
          this.newSearch = false;
          this.showShareResults = 4;
          this.dataLoading = false;
        })
        .finally(() => {
          delete this.selectedSearch;
          delete this.loanCriteria;
          delete this.selectedResult;
          delete this.shareSearchModal;
          delete this.existingborrower;
          delete this.shareDealDto;
        });
    }
  }

  reloadPage() {
    window.location.reload();
  }

  viewClientsDashboard(userName: string) {
    //Look up client's account details
    this.dataLoading = true;
    this.$user.searchByEmail(userName).then((users) => {
      this.showHomeDashboard();
      this.dataLoading = false;
    });
  }

  sendMessageToBrokerAdmin(message) {
    this.dataLoading = true;
    this.borrowerMessage = null;
    this.organisationService
      .sendBorrowerMessageToOrganisationAdmin(
        this.currentUser.DefaultBrokerOrganisationId,
        message,
        this.currentUser.FirstName,
        this.currentUser.LastName,
        this.currentUser.Email,
        ProductTypeEnum.Development,
        "USERDASHBOARD",
      )
      .then((results) => {
        if (results) {
          this.displayMsg = `Message has been sent successfully.`;
          this.showMessageToborrower = true;
          this.showContactBrokerModal = false;
        } else {
          this.displayMsg = `There is problem sending a message, Please try later.`;
          this.showMessageToborrower = true;
          this.showContactBrokerModal = false;
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  closeDeletedSearchMessageModal() {
    this.showDeletedSearchMessageModal = false;
    this.showMessageToborrower = false;
    this.showContactBrokerModal = false;
    this.displayMsg = null;
    sessionStorage.removeItem("returningFromdeletedSearch");
  }

  /**
   * Toggles the display of the context menu on the tile for searches/cases
   * @param id
   */
  toggleTileMenu(id) {
    event.stopPropagation();
    this.closeAddMenu();
    this.cardWithOpenMenu.id = this.cardWithOpenMenu.id == id ? null : id;
  }

  /**
   * Toggles the display of the Add Item menu
   * @param event
   */
  toggleAddMenu(event) {
    event.stopPropagation();

    if (this.isBroker && !this.currentUser.HasActiveSubscription) {
      return;
    }
    this.closeTileMenu();

    var menu = document
      .getElementsByClassName("new-item-menu")
      .item(0) as HTMLElement;

    if (menu && (menu.style.display == "none" || menu.style.display == "")) {
      var boundingRect = event.target.getBoundingClientRect();
      var offsetHeight = event.target.offsetHeight;

      menu.style.display = "flex";
      menu.style.left = boundingRect.left - menu.clientWidth - 10 + "px";
      menu.style.bottom = offsetHeight + "px";
    } else {
      if (menu) {
        menu.style.display = "none";
      }
    }
  }

  /**Closes the context menu on search/case tiles */
  closeTileMenu() {
    this.cardWithOpenMenu.id = null;
  }

  /**Closes the Add new item menu */
  closeAddMenu() {
    var menu = document
      .getElementsByClassName("new-item-menu")
      .item(0) as HTMLElement;

    if (menu) {
      menu.style.display = "none";
    }
  }

  /**Closes all context menus */
  closeContextMenus() {
    this.closeTileMenu();
    this.closeAddMenu();
  }

  getProductTypeText(
    productType: ProductTypeEnum,
    isRegulatedBridge: boolean = false,
  ) {
    if (isRegulatedBridge) {
      return "regulated bridge";
    } else {
      var productTypeInfo = this.productTypeOptions.find(
        (p) => p.key == productType,
      );

      if (productTypeInfo) {
        return productTypeInfo.displayName;
      }
    }

    return "";
  }

  /** Determines whether to show the "no searches" message on the user dashboard */
  showNoSearchesMessage(): boolean {
    return this.searches?.length == 0 && !this.isSearchesLoading;
  }

  /** Determines whether to show the "no cases" message on the user dashboard */
  showNoCasesMessage(): boolean {
    if (this.selectedNavMenu == this.NavMenuEnum.DevelopmentFinance) {
      return this.projects.length == 0 && !this.isDevFinanceProjectsLoading;
    } else if (this.selectedNavMenu == this.NavMenuEnum.BridgingFinance) {
      return this.projects.length == 0 && !this.isBridgingPreConProjectsLoading;
    } else if (this.selectedNavMenu == this.NavMenuEnum.CommercialMortgages) {
      return this.projects.length == 0 && !this.isCommercialProjectsLoading;
    } else if (
      this.selectedNavMenu == this.NavMenuEnum.Home &&
      !this.isAllProjectsLoading
    ) {
      return this.projects.length == 0 && !this.isAllProjectsLoading;
    }
  }

  /**Determines whether to show the case loading spinner */
  showCasesLoadingSpinner(): boolean {
    if (this.selectedNavMenu == this.NavMenuEnum.DevelopmentFinance) {
      return !this.projects || this.isDevFinanceProjectsLoading;
    } else if (this.selectedNavMenu == this.NavMenuEnum.BridgingFinance) {
      return !this.projects || this.isBridgingPreConProjectsLoading;
    } else if (this.selectedNavMenu == this.NavMenuEnum.CommercialMortgages) {
      return !this.projects || this.isCommercialProjectsLoading;
    } else if (this.selectedNavMenu == this.NavMenuEnum.Home) {
      return !this.projects || this.isAllProjectsLoading;
    }
  }

  /** Determines whether to show the list of Case cards*/
  showCaseCards(): boolean {
    return this.projects != null && this.projects != undefined;
  }

  returnCaseOrDealId(item: CaseSummaryDTO): number {
    if (item.ProductType == ProductTypeEnum.Development && item.CaseID) {
      return item.CaseID;
    } else {
      return item.DealID;
    }
  }

  returnSearchOrDealId(item: CaseSummaryDTO): number {
    if (
      item.ProductType == ProductTypeEnum.Development &&
      item.DevelopmentInputID
    ) {
      return item.DevelopmentInputID;
    } else {
      return item.DealID;
    }
  }

  setHasBridgingAndHasCommercialValue(): void {
    this.organisationService.getOrganisationProductAccess().then((response) => {
      this.hasBridging = response.HasBridging;
      this.hasCommercial = response.HasCommercial;
      this.isDeallockerIntegrationEnabled = response.IsDeallockerIntegrationEnabled;
    });
  }

  currentLenderProductType(): void {
    this.hideBridgingMenu = true;
    this.hideDevfinanceMenu = true;
    this.hideCommercialMenu = true;

    this.$lenderService.getLenderProductTypes().then((response) => {
      if (
        this.currentUser != null &&
        this.currentUser.LenderProductTypes != null
      ) {
        // When the current user is a lender admin then they should see all product types that the lender has
        if (this.currentUser.IsLenderAdmin) {
          if (response == LenderProductTypeEnum.All) {
            this.hideBridgingMenu = false;
            this.hideDevfinanceMenu = false;
            this.hideCommercialMenu = false;

            return;
          }

          if (
            (response & LenderProductTypeEnum.DevelopmentFinance) ==
            LenderProductTypeEnum.DevelopmentFinance
          ) {
            this.hideDevfinanceMenu = false;
          }

          if (
            (response & LenderProductTypeEnum.BridgingFinance) ==
            LenderProductTypeEnum.BridgingFinance
          ) {
            this.hideBridgingMenu = false;
          }

          if (
            (response & LenderProductTypeEnum.CommercialMortgages) ==
            LenderProductTypeEnum.CommercialMortgages
          ) {
            this.hideCommercialMenu = false;
          }

          return;
        } else {
          // If the lender has all product types and the lender user has been set up to see all products types
          if (
            response == LenderProductTypeEnum.All &&
            (this.currentUser.LenderProductTypes & LenderProductTypeEnum.All) ==
            LenderProductTypeEnum.All
          ) {
            this.hideBridgingMenu = false;
            this.hideDevfinanceMenu = false;
            this.hideCommercialMenu = false;

            return;
          }

          if (
            (response & LenderProductTypeEnum.DevelopmentFinance) ==
            LenderProductTypeEnum.DevelopmentFinance &&
            ((this.currentUser.LenderProductTypes &
              LenderProductTypeEnum.DevelopmentFinance) ==
              LenderProductTypeEnum.DevelopmentFinance ||
              (this.currentUser.LenderProductTypes &
                LenderProductTypeEnum.All) ==
              LenderProductTypeEnum.All)
          ) {
            this.hideDevfinanceMenu = false;
          }

          if (
            (response & LenderProductTypeEnum.BridgingFinance) ==
            LenderProductTypeEnum.BridgingFinance &&
            ((this.currentUser.LenderProductTypes &
              LenderProductTypeEnum.BridgingFinance) ==
              LenderProductTypeEnum.BridgingFinance ||
              (this.currentUser.LenderProductTypes &
                LenderProductTypeEnum.All) ==
              LenderProductTypeEnum.All)
          ) {
            this.hideBridgingMenu = false;
          }

          if (
            (response & LenderProductTypeEnum.CommercialMortgages) ==
            LenderProductTypeEnum.CommercialMortgages &&
            ((this.currentUser.LenderProductTypes &
              LenderProductTypeEnum.CommercialMortgages) ==
              LenderProductTypeEnum.CommercialMortgages ||
              (this.currentUser.LenderProductTypes &
                LenderProductTypeEnum.All) ==
              LenderProductTypeEnum.All)
          ) {
            this.hideCommercialMenu = false;
          }

          //if (response == LenderProductTypeEnum.All && (this.currentUser.LenderProductTypes & LenderProductTypeEnum.BridgingFinance) == LenderProductTypeEnum.BridgingFinance && (this.currentUser.LenderProductTypes & LenderProductTypeEnum.DevelopmentFinance) == LenderProductTypeEnum.DevelopmentFinance && (this.currentUser.LenderProductTypes & LenderProductTypeEnum.CommercialMortgages) == LenderProductTypeEnum.CommercialMortgages) {
          //    this.hideBridgingMenu = false;
          //    this.hideDevfinanceMenu = false;
          //    this.hideCommercialMenu = false;
          //} else if ((response == LenderProductTypeEnum.All && (this.currentUser.LenderProductTypes & LenderProductTypeEnum.All) == LenderProductTypeEnum.All) || (response == LenderProductTypeEnum.All && this.currentUser.IsLenderAdmin)) {
          //    this.hideBridgingMenu = false;
          //    this.hideDevfinanceMenu = false;
          //    this.hideCommercialMenu = false;
          //} else if ((response == LenderProductTypeEnum.BridgingFinance || response == LenderProductTypeEnum.All) && ((this.currentUser.LenderProductTypes & LenderProductTypeEnum.BridgingFinance) == LenderProductTypeEnum.BridgingFinance || this.currentUser.IsLenderAdmin)) {
          //    this.hideBridgingMenu = false;
          //} else if ((response == LenderProductTypeEnum.DevelopmentFinance || response == LenderProductTypeEnum.All) && ((this.currentUser.LenderProductTypes & LenderProductTypeEnum.DevelopmentFinance) == LenderProductTypeEnum.DevelopmentFinance || this.currentUser.IsLenderAdmin)) {
          //    this.hideDevfinanceMenu = false;
          //} else if ((response == LenderProductTypeEnum.CommercialMortgages || response == LenderProductTypeEnum.All) && ((this.currentUser.LenderProductTypes & LenderProductTypeEnum.CommercialMortgages) == LenderProductTypeEnum.CommercialMortgages || this.currentUser.IsLenderAdmin)) {
          //    this.hideCommercialMenu = false;
          //}
          //else if (response == LenderProductTypeEnum.DevelopmentFinance && ((this.currentUser.LenderProductTypes & LenderProductTypeEnum.All) == LenderProductTypeEnum.All || this.currentUser.IsLenderAdmin)) {
          //    this.hideDevfinanceMenu = false;
          //} else if (response == LenderProductTypeEnum.BridgingFinance && ((this.currentUser.LenderProductTypes & LenderProductTypeEnum.All) == LenderProductTypeEnum.All || this.currentUser.IsLenderAdmin)) {
          //    this.hideBridgingMenu = false;
          //} else if (response == LenderProductTypeEnum.CommercialMortgages && ((this.currentUser.LenderProductTypes & LenderProductTypeEnum.All) == LenderProductTypeEnum.All || this.currentUser.IsLenderAdmin)) {
          //    this.hideCommercialMenu = false;
          //}
        }
      }
    });
  }

  showViewButton(productType: ProductTypeEnum) {
    if (
      productType == ProductTypeEnum.Development ||
      ((productType == ProductTypeEnum.Bridging ||
        productType == ProductTypeEnum.BridgingPreconstruction ||
        productType == ProductTypeEnum.BridgingPurchaseOrRefinance ||
        productType == ProductTypeEnum.BridgingRefurb ||
        productType == ProductTypeEnum.BridgingDeveloperExit) &&
        this.hasBridging) ||
      ((productType == ProductTypeEnum.CommercialAll ||
        productType == ProductTypeEnum.CommercialInvestment ||
        productType == ProductTypeEnum.CommercialOwnerOccupied) &&
        this.hasCommercial)
    ) {
      return true;
    }

    return false;
  }

  onPlusButtonHover(event) {
    if (this.isBroker) {
      event.currentTarget.children[0].style.display = "";
    }
  }

  goToDealForum(item) {
    if (this.isLender) {
      if (this.goToDealForumClicked) {
        sessionStorage.setItem("lenderTask", "feedback");
        this.goToDealForumClicked = false;
        if (item.ProductType == ProductTypeEnum.Development) {
          if (item.DealID) {
            this.$location.path(
              "/devfinanceheadsofterm/" + item.DealID + "/" + item.DealLenderId,
            );
          } else {
            this.$location.path(
              "/headsofterm/" + item.CaseID + "/" + item.DealLenderId,
            );
          }
        } else if (
          item.ProductType == ProductTypeEnum.CommercialAll ||
          item.ProductType == ProductTypeEnum.CommercialInvestment ||
          item.ProductType == ProductTypeEnum.CommercialOwnerOccupied
        ) {
          this.$location.path(
            "/commercialheadsofterm/" + item.DealID + "/" + item.DealLenderId,
          );
        } else if (
          item.ProductType == ProductTypeEnum.BridgingPreconstruction ||
          item.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance ||
          item.ProductType == ProductTypeEnum.BridgingDeveloperExit ||
          item.ProductType == ProductTypeEnum.BridgingRefurb ||
          item.ProductType == ProductTypeEnum.Bridging
        ) {
          this.$location.path(
            "/bridgingheadsofterm/" + item.DealID + "/" + item.DealLenderId,
          );
        }
      } else {
        this.goToDealForumClicked = true;
        this.lenderSelectedProject = { ...item };
        this.showLenderTerms = true;
      }
    } else {
      if (
        item.ProductType == ProductTypeEnum.Development &&
        item.DealID == null
      ) {
        this.$location.path("/lending/" + item.CaseID);
      } else {
        this.$location.path("/dealforum/" + item.DealID);
      }
    }
  }

  getTotalUnreadMessagesPerAppraisal() {
    if (this.projects && this.projects.length > 0) {
      let appraisalList = this.projects.map((obj) => ({
        AppraisalId: obj.DealID != null ? obj.DealID : obj.CaseID,
        IsDeal: obj.DealID != null ? true : false,
        BrokerUserId: obj.BrokerUserId,
      }));

      this.dealLenderMessageService
        .getTotalUnreadMessagesPerAppraisal(appraisalList)
        .then((response) => {
          this.bridgingTotalUnreadMessage =
            response.BridgingTotalUnreadMessages;
          this.commercialTotalUnreadMessage =
            response.CommercialTotalUnreadMessages;
          this.devfinanceTotalUnreadMessage =
            response.DevFinanceTotalUnreadMessages;

          for (const proj of this.projects) {
            const matchingProj =
              proj.DealID != null
                ? response.AppraisalDataList.find(
                  (dl) => dl.AppraisalId === proj.DealID && dl.IsDeal,
                )
                : response.AppraisalDataList.find(
                  (dl) => dl.AppraisalId === proj.CaseID && !dl.IsDeal,
                );
            if (matchingProj) {
              proj.TotalUnreadMessages = matchingProj.TotalUnreadMessages;
            }
          }
        });
    }
  }

  //If previous path is any one of these then only it restore previous selected navmenu and filter
  isValidPreviousPath() {
    if (
      ((this.$rootScope as any).previousRoute.startsWith(
        "/commercialresults",
      ) ||
        (this.$rootScope as any).previousRoute.startsWith("/bridgingresults") ||
        (this.$rootScope as any).previousRoute.startsWith(
          "/devfinanceresults",
        ) ||
        (this.$rootScope as any).previousRoute.startsWith(
          "/commercialcasedashboard",
        ) ||
        (this.$rootScope as any).previousRoute.startsWith(
          "/bridgingcasedashboard",
        ) ||
        (this.$rootScope as any).previousRoute.startsWith(
          "/devfinancecasedashboard",
        ) ||
        (this.$rootScope as any).previousRoute == "/" ||
        (this.$rootScope as any).previousRoute.startsWith(
          "/devfinancecriteria",
        ) ||
        (this.$rootScope as any).previousRoute.startsWith(
          "/bridgingcriteria",
        ) ||
        (this.$rootScope as any).previousRoute.startsWith(
          "/commercialcriteria",
        ) ||
        (this.$rootScope as any).previousRoute.startsWith("/referredSearch") ||
        (this.$rootScope as any).previousRoute.startsWith("/casedashboard") ||
        (this.$rootScope as any).previousRoute.startsWith("/results") ||
        (this.$rootScope as any).previousRoute.startsWith("/dealforum") ||
        (this.$rootScope as any).previousRoute.startsWith("/lending")) &&
      (this.$rootScope as any).previousRoute != "/"
    ) {
      return true;
    }
    return false;
  }

  onSelectingHomeMenu() {
    sessionStorage.removeItem("selectedNavMenu");
  }
}

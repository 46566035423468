import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { LicenseAppPackageDTO } from "@js/DTO/LicenseAppPackageDTO.cs.d";
import { PaymentSessionDTO } from "@js/DTO/StripeSessionDTO.cs.d";
import { PricingFrequencyEnum } from "@js/models/enum/PricingFrequencyEnum.cs.d";
import { UserRoleEnum } from "@js/models/enum/UserRoleEnum.cs.d";

export class PaymentService {
  $http: ng.IHttpService;
  $cookies: ng.cookies.ICookiesService;
  $rootScope: ng.IRootScopeService;
  $q: ng.IQService;
  $timeout: ng.ITimeoutService;
  $baseAddress: string;

  static $inject = ["$http", "$cookies", "$rootScope", "$q", "$timeout"];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
  ) {
    this.$http = http;
    this.$cookies = cookies;
    this.$rootScope = rootScope;
    this.$q = q;
    this.$timeout = timeout;
    this.$baseAddress = "/api/pay";
  }

  createpayment(caseId: number): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(this.$baseAddress + "/createpayment?caseId=" + caseId)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  //not used yet in this solution
  //refundentry(caseId: number): ng.IPromise<boolean> {
  //    let defer = this.$q.defer<boolean>();
  //    this.$http.get(this.$baseAddress + "/refundentry?caseId=" + caseId).then((response) => {
  //        defer.resolve(response.data as boolean);
  //    }).catch((response) => {
  //        defer.reject(response);
  //    });
  //    return defer.promise;
  //}

  createCheckoutSession(
    products: AppPackagePricingDTO,
    isAddonselected,
    selectedUserName,
    developmentInputId,
  ): ng.IPromise<PaymentSessionDTO> {
    let defer = this.$q.defer<PaymentSessionDTO>();
    var encodeUserName =
      selectedUserName != "" ? encodeURIComponent(selectedUserName) : "";
    this.$http
      .post(
        this.$baseAddress +
          "/createcheckoutsession?isAddonselected=" +
          isAddonselected +
          "&selectedUserName=" +
          encodeUserName +
          "&developmentInputId=" +
          developmentInputId,
        products,
      )
      .then((response) => {
        defer.resolve(response.data as PaymentSessionDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getAllActivePackagePrices(
    userName: string,
  ): ng.IPromise<AppPackagePricingDTO[]> {
    let defer = this.$q.defer<AppPackagePricingDTO[]>();
    var encodeUserName = encodeURIComponent(userName);
    this.$http
      .get(
        this.$baseAddress +
          "/getallactivepackageprices?userName=" +
          encodeUserName,
      )
      .then((response) => {
        defer.resolve(response.data as AppPackagePricingDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getActivePackagePricesForFrequency(
    frequency: PricingFrequencyEnum,
    userRole: UserRoleEnum = UserRoleEnum.None,
  ) {
    let defer = this.$q.defer<AppPackagePricingDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/getactivepackagepricesforfrequency?frequency=" +
          frequency +
          "&userRole=" +
          userRole,
      )
      .then((response) => {
        defer.resolve(response.data as AppPackagePricingDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  createPortalSession(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(this.$baseAddress + "/createportalsession")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  updatePlanPrice(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(this.$baseAddress + "/updateplanprice")
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  cancelSubscription(subscriptionId: string): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(
        this.$baseAddress +
          "/cancelsubscription?subscriptionId=" +
          subscriptionId,
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  unCancelSubscription(subscriptionId: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/uncancelsubscription?subscriptionId=" +
          subscriptionId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getModuleQuantityForAppPackage(appPackageId: number): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        this.$baseAddress +
          "/getmodulequantityforapppackage?appPackageId=" +
          appPackageId,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  //getAllModuleAppFeatures(): ng.IPromise<ModuleAppFeatureDTO[]> {
  //    let defer = this.$q.defer<ModuleAppFeatureDTO[]>();
  //    this.$http.get(this.$baseAddress + "/getallmoduleappfeatures").then((response) => {
  //        defer.resolve(response.data as ModuleAppFeatureDTO[]);
  //    }).catch((response) => {
  //        defer.reject(response);
  //    });
  //    return defer.promise;
  //}

  getLicense(userName: string = ""): ng.IPromise<LicenseAppPackageDTO> {
    let defer = this.$q.defer<LicenseAppPackageDTO>();
    var encodeUserName = userName != "" ? encodeURIComponent(userName) : "";
    this.$http
      .get(this.$baseAddress + "/getlicense?userName=" + encodeUserName)
      .then((response) => {
        defer.resolve(response.data as LicenseAppPackageDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getTotalUnassignedLicenses(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(this.$baseAddress + "/gettotalunassignedlicenses")
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  addLeadGeneratorSubscription(
    licenseAppPackage: LicenseAppPackageDTO,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .post(this.$baseAddress + "/addleadgeneratorlicense", licenseAppPackage)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getWhitelabelPrice(frequency: PricingFrequencyEnum): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(this.$baseAddress + "/getwhitelabelprice?frequency=" + frequency)
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  isSubscriptionCancelable(subscriptionId: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/issubscriptioncancelable?subscriptionId=" +
          subscriptionId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  createCheckoutSessionForLeadGen(
    leadGenPackage: LicenseAppPackageDTO,
  ): ng.IPromise<PaymentSessionDTO> {
    let defer = this.$q.defer<PaymentSessionDTO>();
    this.$http
      .post(
        this.$baseAddress + "/createcheckoutsessionforleadgen",
        leadGenPackage,
      )
      .then((response) => {
        defer.resolve(response.data as PaymentSessionDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  createCheckoutSessionForCustomPrice(
    products: LicenseAppPackageDTO,
    isAddonselected,
    selectedUserName,
    developmentInputId,
  ): ng.IPromise<PaymentSessionDTO> {
    let defer = this.$q.defer<PaymentSessionDTO>();
    var encodeUserName =
      selectedUserName != "" ? encodeURIComponent(selectedUserName) : "";
    this.$http
      .post(
        this.$baseAddress +
          "/createcheckoutsessionforcustomprice?isAddonselected=" +
          isAddonselected +
          "&selectedUserName=" +
          encodeUserName +
          "&developmentInputId=" +
          developmentInputId,
        products,
      )
      .then((response) => {
        defer.resolve(response.data as PaymentSessionDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getActivePackagePricingsForTrialLicense(
    userName: string,
  ): ng.IPromise<AppPackagePricingDTO[]> {
    let defer = this.$q.defer<AppPackagePricingDTO[]>();
    var encodeUserName = encodeURIComponent(userName);
    this.$http
      .get(
        this.$baseAddress +
          "/getactivepackagepricingsfortriallicense?userName=" +
          encodeUserName,
      )
      .then((response) => {
        defer.resolve(response.data as AppPackagePricingDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

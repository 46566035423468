import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { DevelopmentWithNoLoginDTO } from "@js/DTO/DevelopmentWithNoLoginDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { OrganisationLinkDTO } from "@js/DTO/OrgnisationLinkDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { DevelopmentTrackRecordTypeEnum } from "@js/models/enum/DevelopmentTrackRecordTypeEnum.cs.d";
import { ExitStrategyEnum } from "@js/models/enum/ExitStrategyEnum.cs.d";
import { LocationEnum } from "@js/models/enum/LocationEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PropertyTypeDetailEnum } from "@js/models/enum/PropertyTypeDetailEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { SharedCriteriaService } from "@js/services/Deal/SharedCriteriaService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { DevelopmentInputWithNoLoginService } from "@js/services/DevelopmentInputWithNoLoginService";
import { EventLogService } from "@js/services/EventLogService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationLinkService } from "@js/services/OrganisationLinkService";
import { OrganisationService } from "@js/services/OrganisationService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { SharedDataService } from "@js/services/SharedDataService";
import { UserService } from "@js/services/UserService";

export class DevelopmentInputScreenController {
  loanCriteria: DevelopmentInputDTO;
  snapshotLoanCriteria: DevelopmentInputDTO;
  step: number = 1;
  dateOfOrigPurchMonth: number;
  dateOfOrigPurchYear: number;
  DevelopmentInputForm: ng.IFormController;
  guidanceCheckbox: boolean = true;
  buildCostPerArea: number;
  searchid: number = null;
  caseid: number = null;
  existingClassUses: number = 0;
  classUses: {} = {};
  projectType: {}[] = [];
  totalBuild: number = 0;
  totalLand: number = 0;
  multiPartForm1: ng.IFormController;
  multiPartForm2: ng.IFormController;
  multiPartForm3: ng.IFormController;

  showVideo: boolean = false;

  //boolean to fetch largest or matcing results
  isMaximumLoanRequired: boolean = true;

  tempLoanRequired: number;

  //array describing form sections
  formSectionNames = [
    {
      label: "Property",
      visible: true,
    },
    {
      label: "Build Costs",
      visible: true,
    },
    {
      label: "Borrowing",
      visible: true,
    },
  ];

  isLoggedInUser: boolean = false;
  isLegacyUser: boolean = false;
  isAdmin: boolean = false;
  isBroker: boolean = false;
  isBorrower: boolean = false;
  isLender: boolean = false;
  brokerOrAdminUser: ApplicationUserDTO;
  existingUsers: UserSimpleDTO[];
  showShareSearchtoClientModal: boolean = false;
  clientFirstName: string;
  clientSurname: string;
  clientEmail: string;
  clientPhoneNumber: string;
  clientId: string = null;
  showClientDetails: boolean = false;
  existingborrower: boolean;
  loadingData: boolean = false;
  dataLoading: boolean = false;
  isResultScreen: boolean = false;
  isIntroduceronly: boolean = false;
  selectedBorrower: UserSimpleDTO = null;
  showAutofill: boolean = false;

  totalLenders: number = 0;
  brokerageOrg: OrganisationDTO;
  showContactBrokerModal: boolean = false;
  showMessageToborrower: boolean = false;
  messageToborrower: string = "";
  user: ApplicationUserDTO;
  stepName: string = "";
  moveContactBrokerBtnInMiddle: boolean = false;
  showActionPanel: boolean = false;
  userRole: string = "";
  userDetails: string = null;
  projectName: string = null;
  applicationName: string = null;

  organisationLink: OrganisationLinkDTO;
  orgCode: string = "";
  noPermissionErrorMsg: string = "";

  locationOptions = [];

  //Postcode properties
  showPostcodeErrorMessage: boolean = false;
  postcodeErrorMsg: string;
  previouslySelectedLocation: LocationEnum;

  static $inject = [
    "$scope",
    "$rootScope",
    "$routeParams",
    "$cookies",
    "DevelopmentInputService",
    "$location",
    "$q",
    "CaseService",
    "RoleService",
    "UserService",
    "AuthService",
    "OrganisationService",
    "DevelopmentInputWithNoLoginService",
    "LenderService",
    "SharedDataService",
    "OrganisationLinkService",
    "EventLogService",
    "SelectListService",
    "SharedCriteriaService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private $location: ng.ILocationService,
    protected $q: ng.IQService,
    private caseService: CaseService,
    private roleService: RoleService,
    private userService: UserService,
    private $auth: AuthService,
    private organisationService: OrganisationService,
    private $DevelopmentInputWithNoLoginService: DevelopmentInputWithNoLoginService,
    private lenderService: LenderService,
    private sharedDataService: SharedDataService,
    private organisationLinkService: OrganisationLinkService,
    private eventLogService: EventLogService,
    private selectListService: SelectListService,
    private sharedCriteriaService: SharedCriteriaService,
  ) {
    try {
      if (this.$location.path().startsWith("/e/criteria")) {
        // for enterprise and unique links we should now use the new dev finance search so have commented out the code below
        if (this.$routeParams.linkId) {
          this.$location.path(
            `/e/devfinancecriteria/0/${this.$routeParams.linkId}`,
          );
        } else {
          this.$location.path("/e/devfinancecriteria");
        }
      } else if (
        this.$location.path().startsWith("/criteria") &&
        (!this.$routeParams.SearchId || this.$routeParams.SearchId == 0)
      ) {
        if (window.self == window.top && $cookies.get("access_token")) {
          this.$location.path("/devfinancecriteria");
        } else {
          this.$location.path("/e/devfinancecriteria");
        }
      }

      if (window.self == window.top && $cookies.get("access_token")) {
        this.isLoggedInUser = true;

        this.userService.getUserAutofill().then((response) => {
          this.showAutofill = response;
        });

        this.roleService.GetUserRoles().then((result) => {
          if (result.filter((x) => x.toLowerCase() == "admin").length > 0) {
            this.isAdmin = true;
          }
          if (result.filter((x) => x.toLowerCase() == "broker").length > 0) {
            this.isBroker = true;
            //This block is to navigate a broker to userdashboard when they do not have a license.
            if (this.isBroker) {
              if (sessionStorage.getItem("isValidLicense") == "false") {
                this.gotoDashboard();
              }
            }
          }

          if (result.filter((x) => x.toLowerCase() == "client").length > 0) {
            this.sharedDataService.isBorrower = true;
          }

          if (
            result.filter((x) => x.toLowerCase() == "lender").length > 0 &&
            !this.isAdmin
          ) {
            this.isLender = true;
          }

          if (
            result.filter((x) => x == "Introducer").length > 0 &&
            result.filter((x) => x == "Broker").length == 0 &&
            result.filter((x) => x == "Client").length == 0 &&
            result.filter((x) => x == "Admin").length == 0
          ) {
            this.isIntroduceronly = true;
          }

          if (
            this.$routeParams.SearchId == 0 &&
            this.$routeParams.CaseId == 0 &&
            this.step == 1
          ) {
            if (this.isBroker || this.isAdmin) {
              if (sessionStorage.getItem("searchuser")) {
                let data = sessionStorage.getItem("searchuser");
                let userdetails = JSON.parse(data);
                this.clientFirstName = userdetails.clientFirstName;
                this.clientSurname = userdetails.clientSurname;
                this.clientEmail = userdetails.clientEmail;
                this.clientPhoneNumber = userdetails.clientPhoneNumber;
                this.clientId = userdetails.clientId;
                this.existingborrower = userdetails.existingborrower;
                this.loanCriteria.SearchName = userdetails.searchName;
                if (this.existingborrower) {
                  this.showClientDetails = true;
                }
              } else {
                this.loanCriteria.SearchName = this.loanCriteria.SearchName
                  ? this.loanCriteria.SearchName
                  : null;
              }

              if (
                this.$DevelopmentInputservice.getHasValidStatustoShowShareSearchModal()
              ) {
                if (sessionStorage.getItem("skip")) {
                  this.showShareSearchtoClientModal = false;
                } else {
                  this.showShareSearchtoClientModal = true;
                  this.getUsersBelongToBrokerOrAdmin();
                }
              }
            }
            if (this.isLender) {
              if (sessionStorage.getItem("skip")) {
                this.showShareSearchtoClientModal = false;
              } else {
                this.showShareSearchtoClientModal = true;
              }
            }
          }

          this.userService.getcurentuserrecord().then((result) => {
            this.brokerOrAdminUser = result;
            this.isLegacyUser = result.IsLegacyUser;
          });
        });
      }

      // If we have an introducer code set, let's store a cookie.
      if (this.$routeParams.introducercode) {
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 100);
        this.$cookies.put("introducer_code", this.$routeParams.introducercode, {
          expires: expiryDate,
        });
      } else if (this.$routeParams["ic"]) {
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 100);
        this.$cookies.put("introducer_code", this.$routeParams["ic"], {
          expires: expiryDate,
        });
      }

      // If we have an invite code set, let's store a cookie.
      if (this.$routeParams.invitecode) {
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 100);
        this.$cookies.put("invite_code", this.$routeParams.invitecode, {
          expires: expiryDate,
        });
      }
      // If we have an org code set, let's store a cookie.
      if (this.$routeParams["orgc"]) {
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 100);
        this.$cookies.put("org_code", this.$routeParams["orgc"], {
          expires: expiryDate,
        });
      }

      if (this.$routeParams.SearchId && this.$routeParams.SearchId > 0) {
        if (this.$routeParams.CaseId && this.$routeParams.CaseId > 0) {
          this.caseService.fetch(this.$routeParams.CaseId).then((result) => {
            if (result.CaseStatus == 0 || result.CaseStatus == 1) {
              this.caseService.updateTubeMap(CaseStatusEnum.InProgress);
            } else {
              this.caseService.updateTubeMap(result.CaseStatus);
            }
          });
        } else {
          this.caseService.updateTubeMap(null);
        }
      } else {
        this.caseService.updateTubeMap(CaseStatusEnum.Search);
      }

      if (this.$routeParams.SearchId > 0) {
        this.searchid = this.$routeParams.SearchId;
      } else {
        this.searchid = 0;
      }
      if (this.$routeParams.CaseId > 0) {
        this.caseid = this.$routeParams.CaseId;
      } else {
        this.caseid = 0;
      }
      if (this.$routeParams.StepNumber) {
        this.step =
          this.$routeParams.StepNumber > 3 || this.$routeParams.StepNumber < 1
            ? 1
            : this.$routeParams.StepNumber;
      }

      //redirect to full path if full path not given
      if (
        (!this.$routeParams.SearchId ||
          !this.$routeParams.CaseId ||
          !this.$routeParams.StepNumber) &&
        (this.$routeParams.uniqueId == undefined ||
          this.$routeParams.uniqueId == "null")
      ) {
        this.go(
          "/criteria/" + this.searchid + "/" + this.caseid + "/" + this.step,
          false,
        );
      }

      this.snapshotLoanCriteria = {
        ...(this.$rootScope as any).snapshotLoanCriteria,
      };

      // See if we've got an existing search in database.
      if (this.searchid > 0) {
        this.$DevelopmentInputservice.fetch(this.searchid).then((response) => {
          this.loanCriteria = response;
          this.tempLoanRequired = response.CI_Dev_LoanReq;
          if (response.CI_Dev_LoanReq > 0) {
            this.isMaximumLoanRequired = false;
          }
          if (this.loanCriteria.CI_Dev_DateOfOrigPurch) {
            this.dateOfOrigPurchMonth =
              this.loanCriteria.CI_Dev_DateOfOrigPurch.getMonth() + 1;
            this.dateOfOrigPurchYear =
              this.loanCriteria.CI_Dev_DateOfOrigPurch.getFullYear() % 100;
          }
          this.existingClassUses = this.loanCriteria.DI_Dev_ClassUses;
          this.initClassUses();
          this.initProjectTypes();
        });
      } else if (
        this.$routeParams.uniqueId !== undefined &&
        this.$routeParams.uniqueId != "null"
      ) {
        this.$DevelopmentInputWithNoLoginService
          .fetchByUniqueId(this.$routeParams.uniqueId)
          .then((results) => {
            this.loanCriteria = {} as DevelopmentInputDTO;
            (<any>Object).assign(this.loanCriteria, results);
            this.tempLoanRequired = this.loanCriteria.CI_Dev_LoanReq;
            if (this.loanCriteria.CI_Dev_LoanReq > 0) {
              this.isMaximumLoanRequired = false;
            }
            if (this.loanCriteria.CI_Dev_DateOfOrigPurch) {
              this.dateOfOrigPurchMonth =
                this.loanCriteria.CI_Dev_DateOfOrigPurch.getMonth() + 1;
              this.dateOfOrigPurchYear =
                this.loanCriteria.CI_Dev_DateOfOrigPurch.getFullYear() % 100;
            }
            this.existingClassUses = this.loanCriteria.DI_Dev_ClassUses;
            this.initClassUses();
            this.initProjectTypes();
          });
      } else if ((this.$rootScope as any).loanCriteria) {
        this.loanCriteria = { ...(this.$rootScope as any).loanCriteria };
        this.tempLoanRequired = this.loanCriteria.CI_Dev_LoanReq;
        if (this.loanCriteria.CI_Dev_LoanReq > 0) {
          this.isMaximumLoanRequired = false;
        }
        if (this.loanCriteria.CI_Dev_DateOfOrigPurch) {
          this.dateOfOrigPurchMonth =
            this.loanCriteria.CI_Dev_DateOfOrigPurch.getMonth() + 1;
          this.dateOfOrigPurchYear =
            this.loanCriteria.CI_Dev_DateOfOrigPurch.getFullYear() % 100;
        }
        this.existingClassUses = this.loanCriteria.DI_Dev_ClassUses;
        this.initClassUses();
        this.initProjectTypes();
      } else {
        this.loanCriteria = {
          CI_Dev_Contingency: 0.05,
          CI_Dev_DateOfOrigPurch: new Date(),
          ShowLenderInfoBrokerOverride: true,
        } as DevelopmentInputDTO;
        this.loanCriteria.CI_Dev_Locations = LocationEnum.None;
        this.loanCriteria.CI_EndPropType = PropertyTypeEnum.None;
        this.loanCriteria.CI_Dev_ExitStrategy = ExitStrategyEnum.None;
        this.loanCriteria.DI_AreaUnit = 1;
        this.loanCriteria.hasPostcode = true;

        // If the current user is a broker then record the broker info on the search record (devleopmentInput)

        if (this.isLoggedInUser) {
          this.roleService.isBroker().then((isBroker: boolean) => {
            if (isBroker == true) {
              this.userService
                .getcurentuserrecord()
                .then((currentUser: ApplicationUserDTO) => {
                  this.loanCriteria.BrokerUserId = currentUser.Id;
                  this.loanCriteria.BrokerOrganisationId =
                    currentUser.OrganisationId;
                });
            }
          });
        }
        if (!this.isLoggedInUser && this.sharedDataService.isBorrower) {
          //if (orgCode) {
          //    this.organisationService.getOrganisationByOrgCode(orgCode).then((organisation: OrganisationDTO) => {
          //        this.loanCriteria.BrokerOrganisationId = organisation.Id;
          //    });
          //} else {
          //    this.organisationService.getBrickflowBrokerOrg().then((organisation: OrganisationDTO) => {
          //        this.loanCriteria.BrokerOrganisationId = organisation.Id;
          //    });
          //}
          var data;

          //if (window.self == window.top) {
          //    data = sessionStorage.getItem('userDetails');

          //    if (sessionStorage.getItem('organisationLinkId')) this.loanCriteria.OrganisationLinkId = Number(sessionStorage.getItem('organisationLinkId'));

          //    if (sessionStorage.getItem('sourceUrl')) this.loanCriteria.LeadSourceUrl = sessionStorage.getItem('sourceUrl');

          //} else {
          //    data = this.sharedDataService.userDetails;

          //    if (this.sharedDataService.organisationLinkId != null) this.loanCriteria.OrganisationLinkId = Number(this.sharedDataService.organisationLinkId);
          //    if (this.sharedDataService.leadSourceUrl != null) this.loanCriteria.LeadSourceUrl = this.sharedDataService.leadSourceUrl;

          //}

          //if (data) {
          //    let userdetails = JSON.parse(data);

          //    let userFullName = userdetails.FullName;
          //    let spaceIndex = userFullName.indexOf(' ');

          //    let firstName = '';
          //    let lastName = '';

          //    // if not space has been added to the name then put the whole name in the first name field
          //    if (spaceIndex == -1) {
          //        firstName = userFullName;
          //    } else {
          //        firstName = userFullName.substring(0, userFullName.indexOf(' '));
          //        lastName = userFullName.substring(userFullName.indexOf(' ') + 1);
          //    }

          //    this.loanCriteria.IntroduceeFirstName = firstName;
          //    this.loanCriteria.IntroduceeSurname = lastName;
          //    this.loanCriteria.IntroduceeEmail = userdetails.Email;
          //    this.loanCriteria.IntroduceePhone = userdetails.PhoneNumber;

          //    var currentDate = new Date();
          //    var projectName = this.sharedDataService.projectName;
          //    this.loanCriteria.SearchName = projectName ? projectName : firstName + ' ' + lastName + ' Loan Search ' + currentDate.getDate() + "/" + (currentDate.getMonth() + 1) + "/" + currentDate.getFullYear()
          //}
          //}).catch ((error) => {
          //    console.error("Failed to get user details: ", error);
          //});
        }

        this.initClassUses();
        this.initProjectTypes();
      }

      if (window.self == window.top) {
        this.updateGuidanceState();

        $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
          this.updateGuidanceState();
        });
      }
    } catch (e) {
      console.error("Error: ", e);
    }
  }

  indexPopup(methodName: string) {
    this.$rootScope.$broadcast(methodName);
  }

  initClassUses() {
    let classUses: {} = {};
    for (let i = 1; i >= 0; i *= 2) {
      if (this.loanCriteria.DI_Dev_ClassUses >= i) {
        //number is in enum sum, add it to object
        if (this.loanCriteria.DI_Dev_ClassUses & i) {
          classUses[i] = true;
        }
      } else {
        this.classUses = classUses;

        //watch and update classuses
        this.$scope.$watchCollection("ctrl.classUses", (newVal: {}) => {
          let valuesList = Object.keys(newVal);
          let total: number = 0;
          //tot up all the numeric keys (class use enums)
          valuesList.forEach((v, i) => {
            let keyToNum = Number(v);
            if (newVal[keyToNum]) {
              total += keyToNum;
            }
          });
          this.loanCriteria.DI_Dev_ClassUses = total;
        });

        return;
      }
    }
  }

  initProjectTypes() {
    if (!this.loanCriteria.ProjectType) {
      this.loanCriteria.ProjectType = 0;
    } else {
      for (let i = 1; i >= 0; i *= 2) {
        if (this.loanCriteria.ProjectType >= i) {
          //number is in enum sum, add it to object
          if (this.loanCriteria.ProjectType & i) {
            this.projectType[i] = true;
          }
        } else {
          return;
        }
      }
    }
  }

  getTubeMapValue() {
    return this.caseService.getTubeMap();
  }

  updateProjectTypes(item: DevelopmentTrackRecordTypeEnum) {
    //if we are setting item to false and item exists, remove it, else add it
    if (
      this.projectType[item] === true &&
      !!!(this.loanCriteria.ProjectType & item)
    ) {
      this.loanCriteria.ProjectType += item;
    } else {
      this.loanCriteria.ProjectType -= item;
    }
  }

  back(): void {
    if (this.step > 1) {
      this.step--;
      if (this.$location.path().startsWith("/e/criteria")) {
        if (this.$routeParams.linkId) {
          this.go(
            "/e/criteria/" +
              this.searchid +
              "/" +
              this.caseid +
              "/" +
              this.step +
              "/" +
              this.$routeParams.linkId,
          );
        } else {
          this.go(
            "/e/criteria/" +
              this.searchid +
              "/" +
              this.caseid +
              "/" +
              this.step,
          );
        }
      } else {
        this.go(
          "/criteria/" + this.searchid + "/" + this.caseid + "/" + this.step,
        );
      }
    } else if (this.step == 1 && !this.isLoggedInUser) {
      this.go("/");
    }
  }

  formChanged(): void {
    (this.$rootScope as any).formSaved = false;
  }

  setNewSearch(): void {
    var criteriaChanged = true;

    //Store that this is a new search
    if (sessionStorage.getItem("skip") == "true") {
      criteriaChanged = !this.shallowCompare(
        this.loanCriteria,
        this.snapshotLoanCriteria,
      );
    }

    if (window.self == window.top) {
      sessionStorage.setItem("newSearch", criteriaChanged.toString());
    } else {
      this.organisationService.sendDataToParent(
        "newSearch",
        criteriaChanged.toString(),
      );
    }
  }

  next(): void {
    if (this.step >= 3) {
      this.setNewSearch();
      //go to results
      if (!this.loanCriteria.CI_Dev_LoanReq) {
        this.loanCriteria.CI_Dev_LoanReq = 0;
      }
      if (this.caseid > 0 && this.searchid > 0) {
        //If attached case, save changes and go to case related search results
        this.save().then((response) => {
          this.$location.path("/results/" + this.searchid + "/" + this.caseid);
        });
      } else if (this.searchid > 0) {
        //If a previously saved search, save changes and go to existing search results page
        this.save().then((response) => {
          this.$location.path("/results/" + this.searchid);
        });
      } else if (
        this.$routeParams.uniqueId !== undefined &&
        this.$routeParams.uniqueId != "null"
      ) {
        this.save().then((response) => {
          this.$location.path("/referredSearch/" + this.$routeParams.uniqueId);
        });
      } else {
        // Store the search on rootScope.
        (this.$rootScope as any).loanCriteria = this.loanCriteria;
        (this.$rootScope as any).formSaved = true;

        if (window.self == window.top) sessionStorage.removeItem("skip");

        if (
          !this.isLoggedInUser &&
          this.isBorrower &&
          this.$location.path().startsWith("/e/criteria")
        ) {
          this.loanCriteria.BrokerOrganisationId = this.brokerageOrg.Id;
          if (this.$routeParams.linkId) {
            this.loanCriteria.OrganisationLinkId = Number(
              this.$routeParams.linkId,
            );
          }

          if (this.loanCriteria.UniqueRef) {
            var result = {} as DevelopmentWithNoLoginDTO;
            (<any>Object).assign(result, this.loanCriteria);
            this.$DevelopmentInputWithNoLoginService
              .saveChangesToReferredSearch(result, this.loanCriteria.UniqueRef)
              .then((result) => {
                //Go to fresh results page
                this.$location.path("/results");
              });
          } else {
            //Go to fresh results page
            this.$location.path("/results");
          }
        } else {
          //Go to fresh results page
          this.$location.path("/results");
        }
      }
    } else {
      this.step++;
      //go to next screen
      if (this.caseid > 0 && this.searchid > 0) {
        this.save().then((response) => {
          this.$location.path(
            "/criteria/" + this.searchid + "/" + this.caseid + "/" + this.step,
          );
        });
      } else if (this.searchid > 0) {
        this.save().then((response) => {
          this.$location.path(
            "/criteria/" + this.searchid + "/" + this.caseid + "/" + this.step,
          );
        });
      } else if (
        this.$routeParams.uniqueId !== undefined &&
        this.$routeParams.uniqueId != "null"
      ) {
        this.save().then((response) => {
          this.$location.path(
            "/referredsearchcriteria/" +
              this.$routeParams.uniqueId +
              "/" +
              this.caseid +
              "/" +
              this.step,
          );
        });
      } else {
        (this.$rootScope as any).loanCriteria = this.loanCriteria;
        if (this.$location.path().startsWith("/e/criteria")) {
          if (this.$routeParams.linkId) {
            this.$location.path(
              "/e/criteria/" +
                this.searchid +
                "/" +
                this.caseid +
                "/" +
                this.step +
                "/" +
                this.$routeParams.linkId,
            );
          } else {
            this.$location.path(
              "/e/criteria/" +
                this.searchid +
                "/" +
                this.caseid +
                "/" +
                this.step,
            );
          }
        } else {
          this.$location.path(
            "/criteria/" + this.searchid + "/" + this.caseid + "/" + this.step,
          );
        }
      }
    }
  }

  save(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    if (!this.loanCriteria) {
      defer.reject("not ready");
    }

    //Only save if the user is not lender
    if (!this.roleService.IsLender) {
      // JNG clear selected results, as this is effectively a new query and it may have different results available.
      this.loanCriteria.SelectedResults = [];

      if (
        this.$routeParams.uniqueId !== undefined &&
        this.$routeParams.uniqueId != "null"
      ) {
        var result = {} as DevelopmentWithNoLoginDTO;
        (<any>Object).assign(result, this.loanCriteria);
        this.$DevelopmentInputWithNoLoginService
          .saveChangesToReferredSearch(result, this.$routeParams.uniqueId)
          .then((response) => {
            defer.resolve(response);
          })
          .catch((response) => {
            defer.reject(response);
          });
      } else {
        this.$DevelopmentInputservice
          .addUpdatereturnonlyid(this.loanCriteria)
          .then((response) => {
            defer.resolve(response);
            return response;
          })
          .catch((response) => {
            defer.reject(response);
          });
      }
    }
    return defer.promise;
  }

  updateGuidanceState() {
    this.guidanceCheckbox =
      this.$cookies.get("guidance") === "on" ||
      this.$cookies.get("guidance") === undefined;
  }

  getGuidanceSwitchState() {
    if (!this.$cookies.get("guidance")) {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "on") {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "off") {
      this.guidanceCheckbox = false;
    } else {
      this.guidanceCheckbox = true;
    }
    return this.guidanceCheckbox;
  }

  recordGuidanceCookie() {
    var guidanceSwitchState: string;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    if (this.guidanceCheckbox == true) {
      guidanceSwitchState = "on";
    } else {
      guidanceSwitchState = "off";
    }
    this.$cookies.put("guidance", guidanceSwitchState, { expires: expiryDate });
    this.$rootScope.$broadcast("guidanceStateChanged");
  }

  updateClassUse() {
    //this.loanCriteria.DI_Dev_ClassUses has now changed to the single new selection or group of selections. Subtract current set of selections from last state to get last state
    let newSelections: number = this.loanCriteria.DI_Dev_ClassUses;
    let lastState: number = this.existingClassUses;
    //have we re-selected any options
    if (!!(lastState & newSelections)) {
      this.loanCriteria.DI_Dev_ClassUses = lastState - newSelections;
    } else {
      this.loanCriteria.DI_Dev_ClassUses = lastState + newSelections;
    }
    this.existingClassUses = this.loanCriteria.DI_Dev_ClassUses;
  }

  go(path: string, save: boolean = true): void {
    if (!save) {
      this.$location.path(path);
      return;
    }

    if (this.searchid > 0) {
      this.save().then(() => {
        this.$location.path(path);
      });
    } else {
      if (this.loanCriteria) {
        (this.$rootScope as any).loanCriteria = this.loanCriteria;
      }
      this.$location.path(path);
    }
  }

  setDate(dateToUpdate: Date, month: number, year: number) {
    // Two digit years!
    var fullYear: number;
    var today = new Date();
    if (today.getFullYear() < 2000 + year) {
      // If it's after this year, we probably mean 19xx.
      fullYear = 1900 + year;
    } else {
      // Otherwise it's probably in the 2000s.
      fullYear = 2000 + year;
    }
    dateToUpdate.setUTCFullYear(fullYear);
    // Note: Months are 0 based.
    dateToUpdate.setUTCMonth(month - 1);
  }

  recalcCosts(updatedField: string): void {
    switch (updatedField) {
      case "CI_Dev_BuildCosts":
        this.recalcCostPerArea();
        break;
      case "CI_Dev_Contingency":
        this.recalcCostPerArea();
        break;
      case "DC_AreaAcquisition":
        this.recalcCostPerArea();
        break;
    }
  }

  recalcTotalBuildCost(): void {
    let res: number = 0;
    //Case 1 - recalc Build costs
    if (
      this.loanCriteria.CI_Dev_Contingency &&
      this.loanCriteria.DC_AreaAcquisition &&
      this.loanCriteria.DC_BuildCostPerArea
    ) {
      res =
        (this.loanCriteria.DC_BuildCostPerArea /
          (1 + this.loanCriteria.CI_Dev_Contingency)) *
        this.loanCriteria.DC_AreaAcquisition;
      this.loanCriteria.CI_Dev_BuildCosts = res;
    }
  }

  checkValidationCriteria(index: number) {
    if (index === 0) {
      if (!this.disabledPropertyScreen()) {
        return true;
      }

      return false;
    }

    if (index === 1) {
      if (this.multiPartForm2 && this.multiPartForm2.$valid) {
        return true;
      }
      return false;
    }

    if (index === 2) {
      if (this.multiPartForm3 && this.multiPartForm3.$valid) {
        return true;
      }
      return false;
    }
  }

  //recalcContingency(): void {
  //    //let res: number = 0;
  //    ////Case 2 - recalc Contingency
  //    //if (this.loanCriteria.CI_Dev_BuildCosts && this.loanCriteria.DC_AreaAcquisition && this.loanCriteria.DC_BuildCostPerArea) {
  //    //    res = ((this.loanCriteria.DC_BuildCostPerArea * this.loanCriteria.DC_AreaAcquisition) / this.loanCriteria.CI_Dev_BuildCosts) - 1;
  //    //    this.loanCriteria.CI_Dev_Contingency = res;
  //    //}
  //}

  recalcGia(): void {
    let res: number = 0;
    //Case 3 - recalc GIA
    if (
      this.loanCriteria.CI_Dev_BuildCosts &&
      this.loanCriteria.CI_Dev_Contingency &&
      this.loanCriteria.DC_BuildCostPerArea
    ) {
      res =
        (this.loanCriteria.CI_Dev_BuildCosts *
          (this.loanCriteria.CI_Dev_Contingency + 1)) /
        this.loanCriteria.DC_BuildCostPerArea;
      this.loanCriteria.DC_AreaAcquisition = res;
    }
  }

  recalcCostPerArea(): void {
    let res: number = 0;
    //Case 4 - recalc cost per sq. m
    if (
      this.loanCriteria.CI_Dev_BuildCosts &&
      this.loanCriteria.CI_Dev_Contingency &&
      this.loanCriteria.DC_AreaAcquisition
    ) {
      res =
        (this.loanCriteria.CI_Dev_BuildCosts *
          (1 + Number(this.loanCriteria.CI_Dev_Contingency))) /
        this.loanCriteria.DC_AreaAcquisition;
      this.loanCriteria.DC_BuildCostPerArea = res;

      if (
        this.loanCriteria.CI_EndPropType === 2 ||
        this.loanCriteria.CI_EndPropType === 1
      ) {
        this.loanCriteria.DI_AreaSalesAreaResidential =
          this.loanCriteria.DC_AreaAcquisition;
      }
      if (this.loanCriteria.CI_EndPropType != 1) {
        this.loanCriteria.DI_AreaSalesAreaCommercial =
          this.loanCriteria.DC_AreaAcquisition;
      }
    }
  }

  calcTotalBuild(): number {
    if (!this.loanCriteria) {
      return 0;
    }

    let res: number = 0;

    // Add build costs
    if (this.loanCriteria.CI_Dev_BuildCosts) {
      res += Number(this.loanCriteria.CI_Dev_BuildCosts);
    }

    // Add contingency cost
    if (
      this.loanCriteria.CI_Dev_BuildCosts &&
      this.loanCriteria.CI_Dev_Contingency
    ) {
      res +=
        Number(this.loanCriteria.CI_Dev_BuildCosts) *
        Number(this.loanCriteria.CI_Dev_Contingency);
    }

    // Add Professional costs and charges
    if (this.loanCriteria.CI_Dev_AdditionalOngoingCosts) {
      res += Number(this.loanCriteria.CI_Dev_AdditionalOngoingCosts);
    }

    this.totalBuild = res;
    return res;
  }

  calcTotalLand(): number {
    if (!this.loanCriteria) {
      return 0;
    }
    let res: number = 0;

    if (this.loanCriteria.CI_Dev_OrigPP) {
      res += Number(this.loanCriteria.CI_Dev_OrigPP);
    }
    if (this.loanCriteria.CI_Dev_DoYouOwnTheProperty) {
      if (this.loanCriteria.CI_Dev_OrigPPExtra) {
        res += Number(this.loanCriteria.CI_Dev_OrigPPExtra);
      }
    } else {
      if (this.loanCriteria.CI_Land_Total_other_Costs) {
        res += Number(this.loanCriteria.CI_Land_Total_other_Costs);
      }
    }
    this.totalLand = res;
    return res;
  }

  totalWarningVisible(): boolean {
    if (!this.loanCriteria.CI_GDV) return false;
    this.calcTotalBuild();
    this.calcTotalLand();

    return this.totalBuild + this.totalLand > Number(this.loanCriteria.CI_GDV);
  }

  debugSearch() {
    const stringJSON = JSON.stringify(this.loanCriteria);
    navigator.clipboard
      .writeText(stringJSON)
      .then()
      .catch((e) => console.log(e));
  }

  sum(a: number, b: number): number {
    return a + b;
  }
  videotour() {
    this.showVideo = true;
  }

  disabledPropertyScreen() {
    if (this.multiPartForm1 && this.loanCriteria) {
      if (
        this.multiPartForm1.$valid &&
        this.loanCriteria.CI_Dev_Locations != 0 &&
        this.loanCriteria.CI_EndPropType != 0 &&
        !this.showPostcodeErrorMessage
      ) {
        if (
          this.loanCriteria.CI_EndPropType == 1 ||
          this.loanCriteria.CI_EndPropType == 2
        ) {
          if (this.loanCriteria.CI_PropTypeDetail != 0) {
            return false;
          }
          return true;
        }
        return false;
      }
      return true;
    }
  }

  videopause(id) {
    this.showVideo = false;
    let containerElement = document.getElementById(id);
    let iframe_tag = containerElement.querySelector("iframe");
    if (iframe_tag) {
      let iframeSrc = iframe_tag.src;
      iframe_tag.src = iframeSrc;
    }
  }

  updateLoanRequiredValue() {
    if (this.isMaximumLoanRequired) {
      this.loanCriteria.CI_Dev_LoanReq = 0;
    } else {
      this.loanCriteria.CI_Dev_LoanReq = this.tempLoanRequired;
    }
  }

  dummysearch() {
    this.loanCriteria.CI_Dev_OrigPP = this.getRandomIntInclusive(
      1000000,
      2000000,
    );
    this.loanCriteria.CI_Land_Total_other_Costs = this.getRandomIntInclusive(
      1000,
      20000,
    );
    if (this.loanCriteria.hasPostcode) {
      this.loanCriteria.PostcodeSearchString =
        this.sharedCriteriaService.getRandomPostcode();
      this.loanCriteria.CI_Dev_Locations =
        this.sharedCriteriaService.getRegionByPostcode(
          this.loanCriteria.PostcodeSearchString,
        );
    } else {
      this.loanCriteria.CI_Dev_Locations = LocationEnum.London;
    }

    this.loanCriteria.CI_EndPropType = PropertyTypeEnum.Residential;
    this.loanCriteria.CI_PropTypeDetail = PropertyTypeDetailEnum.Flats;
    this.loanCriteria.HowManyHouses = this.getRandomIntInclusive(10, 20);
    this.loanCriteria.CI_Dev_BuildCosts = this.getRandomIntInclusive(
      1000000,
      2000000,
    );
    this.loanCriteria.DC_AreaAcquisition = this.getRandomIntInclusive(
      1000,
      3000,
    );
    this.loanCriteria.CI_Dev_AdditionalOngoingCosts = 1000;
    this.loanCriteria.CI_GDV = this.getRandomIntInclusive(10000000, 20000000);
    this.step = 3;
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
    if (this.$location.path().startsWith("/e/criteria")) {
      if (this.$routeParams.linkId) {
        this.$location.path(
          "/e/criteria/" +
            this.searchid +
            "/" +
            this.caseid +
            "/" +
            this.step +
            "/" +
            this.$routeParams.linkId,
        );
      } else {
        this.$location.path(
          "/e/criteria/" + this.searchid + "/" + this.caseid + "/" + this.step,
        );
      }
    } else {
      this.$location.path(
        "/criteria/" + this.searchid + "/" + this.caseid + "/" + this.step,
      );
    }
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  registerNow() {
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
    this.$location.path("promo");
  }

  userLookupForNewSearch(userSearchTerm: string) {
    this.loadingData = true;
    this.userService
      .searchByEmail(userSearchTerm)
      .then((response) => {
        if (this.isBroker) {
          this.existingUsers = response.filter(
            (x) =>
              x.OrganisationReferralId ==
                this.brokerOrAdminUser.OrganisationId &&
              !x.OrganisationId &&
              !x.IsOrganisationAdmin,
          );
        } else {
          this.existingUsers = response;
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  viewClientsDashboardForNewSearch(userName: string) {
    var users = this.existingUsers.filter(
      (x) => x.Email.indexOf(userName) !== -1,
    );
    this.clientFirstName = users[0].FirstName;
    this.clientEmail = users[0].Email;
    this.clientSurname = users[0].LastName;
    this.clientPhoneNumber = users[0].PhoneNumber;
    this.clientId = users[0].Id;
    this.selectedBorrower = users[0];
    this.showClientDetails = true;
  }

  attachClientDatatoSearch() {
    this.showShareSearchtoClientModal = false;
    if (
      this.clientEmail != null &&
      this.clientFirstName != null &&
      this.clientSurname != null &&
      this.clientPhoneNumber != null
    ) {
      var searchuser = {
        clientFirstName: "",
        clientSurname: "",
        clientEmail: "",
        clientPhoneNumber: "",
        clientId: "",
        existingborrower: false,
        searchName: this.loanCriteria.SearchName,
        clientUser: null,
      };
      searchuser.clientEmail = this.clientEmail;
      searchuser.clientFirstName = this.clientFirstName;
      searchuser.clientSurname = this.clientSurname;
      searchuser.clientPhoneNumber = this.clientPhoneNumber;
      searchuser.clientId = this.clientId;
      searchuser.existingborrower = this.existingborrower;
      if (searchuser.existingborrower) {
        searchuser.clientUser = this.selectedBorrower;
      }
      sessionStorage.setItem("searchuser", JSON.stringify(searchuser));
    }
  }

  clearInputFields() {
    this.clientFirstName = null;
    this.clientEmail = null;
    this.clientSurname = null;
    this.clientPhoneNumber = null;
    this.showClientDetails = false;
  }

  OnClickOfSkip() {
    this.showShareSearchtoClientModal = false;
    sessionStorage.setItem("skip", "true");
    sessionStorage.removeItem("searchuser");
  }

  gotoDashboard() {
    this.$location.path("/userdashboard");
  }

  getUsersBelongToBrokerOrAdmin() {
    this.loadingData = true;
    this.userService
      .getUserSummariesForBrokerOrAdmin()
      .then((response) => {
        this.existingUsers = response;
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  getNumberOfLenders() {
    this.lenderService
      .getTotalLenders(ProductFamilyEnum.Development)
      .then((response: number) => {
        this.totalLenders = Math.floor(response);
      });
  }

  sendMessageToBroker(message) {
    this.loadingData = true;
    this.messageToborrower = null;
    this.organisationService
      .sendBorrowerMessageToSearchBroker(
        0,
        message,
        this.loanCriteria.IntroduceeFirstName,
        this.loanCriteria.IntroduceeSurname,
        this.loanCriteria.IntroduceeEmail,
        ProductTypeEnum.Development,
        "CRITERIA",
        this.loanCriteria.UniqueRef,
      )
      .then((results) => {
        if (results) {
          this.messageToborrower = `Message has been sent successfully.`;
          this.showMessageToborrower = true;
        } else {
          this.messageToborrower = `There is problem sending a message, Please try later.`;
          this.showMessageToborrower = true;
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  closeContactBrokerModal() {
    this.showContactBrokerModal = false;
    this.showMessageToborrower = false;
    this.messageToborrower = null;
  }

  getStepName(step: number): string {
    if (step == 1) return "PROPERTYCRITERIA";
    if (step == 2) return "BUILDCOSTCRITERIA";
    if (step == 3) return "BORROWINGCRITERIA";
  }

  calculateMonthsSinceOrigPurchase() {
    let date1 = new Date(this.loanCriteria.CI_Dev_DateOfOrigPurch);
    let date2 = new Date();
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months;
  }

  async logEvent() {
    var orgCode;
    if (window.self == window.top) {
      if (sessionStorage.getItem("clientId")) {
        this.eventLogService.logPageLoad(
          this.getStepName(Number(this.$routeParams.StepNumber)),
          this.orgCode,
          this.loanCriteria?.IntroduceeEmail,
          sessionStorage.getItem("userRole"),
          this.$routeParams.linkId ? Number(this.$routeParams.linkId) : 0,
          ProductTypeEnum.Development,
          this.loanCriteria?.UniqueRef,
          0,
          sessionStorage.getItem("clientId"),
        );
      } else {
        this.eventLogService.logPageLoad(
          this.getStepName(Number(this.$routeParams.StepNumber)),
          this.orgCode,
          this.loanCriteria?.IntroduceeEmail,
          sessionStorage.getItem("userRole"),
          this.$routeParams.linkId ? Number(this.$routeParams.linkId) : 0,
          ProductTypeEnum.Development,
          this.loanCriteria?.UniqueRef,
        );
      }
    } else {
      var clientId: string = null;
      clientId = await this.organisationService.getData("clientId");

      if (clientId) {
        this.eventLogService.logPageLoad(
          this.getStepName(Number(this.$routeParams.StepNumber)),
          this.orgCode,
          this.loanCriteria?.IntroduceeEmail,
          "borrower",
          this.$routeParams.linkId ? Number(this.$routeParams.linkId) : 0,
          ProductTypeEnum.Development,
          this.loanCriteria?.UniqueRef,
          0,
          clientId,
        );
      } else {
        this.eventLogService.logPageLoad(
          this.getStepName(Number(this.$routeParams.StepNumber)),
          this.orgCode,
          this.loanCriteria?.IntroduceeEmail,
          "borrower",
          this.$routeParams.linkId ? Number(this.$routeParams.linkId) : 0,
          ProductTypeEnum.Development,
          this.loanCriteria?.UniqueRef,
        );
      }
    }
  }

  shallowCompare(obj1, obj2) {
    // Check if both are null or if one is null
    if (obj1 == null && obj2 == null) {
      return true;
    }
    if (obj1 == null || obj2 == null) {
      return false;
    }
    var keys1 = Object.keys(obj1);
    var keys2 = Object.keys(obj2);

    keys1 = keys1.filter((key) => key !== "SaveQueryAndResults");
    keys2 = keys2.filter((key) => key !== "SaveQueryAndResults");

    // Check if both objects have the same number of keys
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if all keys in obj1 have corresponding equal values in obj2
    for (let key of keys1) {
      if (typeof obj1[key] === "object" || typeof obj2[key] === "object") {
        continue;
      }
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  getRegionByPostcode() {
    if (
      this.loanCriteria.PostcodeSearchString &&
      this.loanCriteria.PostcodeSearchString.replace(" ", "").length >= 2
    ) {
      if (
        this.$DevelopmentInputservice.isValidPostcodeString(
          this.loanCriteria.PostcodeSearchString,
        )
      ) {
        this.dataLoading = true;
        this.$DevelopmentInputservice
          .getRegionByPostcode(this.loanCriteria.PostcodeSearchString)
          .then((response) => {
            if (response.Location != null) {
              this.loanCriteria.CI_Dev_Locations = response.Location;
              this.showPostcodeErrorMessage = false;
            } else {
              this.postcodeErrorMsg = response.PostCodeErrorMessage;
              this.showPostcodeErrorMessage = true;
              if (this.loanCriteria.CI_Dev_Locations)
                this.loanCriteria.CI_Dev_Locations = null;
            }
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.postcodeErrorMsg =
          "At least one letter and one number must be entered e.g. SW1.";
        this.showPostcodeErrorMessage = true;
        if (this.loanCriteria.CI_Dev_Locations)
          this.loanCriteria.CI_Dev_Locations = null;
      }
    } else {
      if (this.loanCriteria.CI_Dev_Locations)
        this.loanCriteria.CI_Dev_Locations = null;
    }
  }

  onHasPostcodeChange() {
    if (!this.loanCriteria.hasPostcode) {
      this.showPostcodeErrorMessage = false;
      this.loanCriteria.PostcodeSearchString = null;
      this.loanCriteria.CI_Dev_Locations = this.previouslySelectedLocation;
    } else {
      this.previouslySelectedLocation = this.loanCriteria.CI_Dev_Locations;
      this.loanCriteria.CI_Dev_Locations = null;
    }
  }
}

import angular from "angular";
import {
  CcqFileUploadComponent,
  CcqFileUploadController,
} from "./filesfromccqbase/Components/fileupload/fileupload";
import {
  CcqGlobalSearchBarComponent,
  CcqGlobalSearchBarController,
} from "./filesfromccqbase/Components/globalsearchbar/globalsearchbar";
import {
  LazyLoadListComponent,
  LazyLoadListController,
} from "./filesfromccqbase/Components/lazyloadlist/lazyloadlist";

export function registerCCQBaseComponents() {
  angular
    .module("ccqapp")
    .controller("FileUploadController", CcqFileUploadController)
    .component("fileupload", new CcqFileUploadComponent())
    .controller("GlobalSearchBarController", CcqGlobalSearchBarController)
    .component("globalSearchBar", new CcqGlobalSearchBarComponent())
    .controller("LazyLoadListController", LazyLoadListController)
    .component("lazyLoadList", new LazyLoadListComponent());
}

import { AddressLookupDTO } from "@js/DTO/AddressLookupDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";

export class StreetAddressService extends BaseService<PostalAddress, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/streetaddress";
    this.$broadcastBusinessNameSingular = "streetaddress";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }
  getAddressByPostCode(postcode: string): ng.IPromise<PostalAddress> {
    let defer = this.$q.defer<PostalAddress>();
    this.$http
      .get(this.$baseAddress + "/GetAddressByPostCode?postCode=" + postcode)
      .then((response) => {
        defer.resolve(response.data as PostalAddress);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  getAddressList(searchTerm: string): ng.IPromise<AddressLookupDTO[]> {
    let defer = this.$q.defer<AddressLookupDTO[]>();
    this.$http
      .get(this.$baseAddress + "/getAddressList?searchTerm=" + searchTerm)
      .then((response) => {
        defer.resolve(response.data as AddressLookupDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  getPostCodeAddressList(
    postCode: AddressLookupDTO,
    searchTerm: string,
  ): ng.IPromise<AddressLookupDTO[]> {
    let defer = this.$q.defer<AddressLookupDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/getPostCodeAddressList?postCodeId=" +
          postCode.Id +
          "&searchTerm=" +
          searchTerm,
      )
      .then((response) => {
        defer.resolve(response.data as AddressLookupDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  getFullAddress(searchId: string): ng.IPromise<PostalAddress> {
    let defer = this.$q.defer<PostalAddress>();
    this.$http
      .get(this.$baseAddress + "/getFullAddress?addressId=" + searchId)
      .then((response) => {
        defer.resolve(response.data as PostalAddress);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

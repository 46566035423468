import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseService } from "@js/services/CaseService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";

export class LandingController {
  caseId: number;
  case: CaseDTO;
  disablepayment: boolean = false;
  message: string = "";
  modal: boolean = false;

  static $inject = [
    "$routeParams",
    "$scope",
    "$location",
    "PaymentService",
    "CaseService",
    "$cookies",
    "RoleService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $scope: ng.IScope,
    private $location: ng.ILocationService,
    private $paymentService: PaymentService,
    private caseService: CaseService,
    private $cookies: ng.cookies.ICookiesService,
    private roleService: RoleService,
  ) {
    //Check if the user is logged in
    if (
      this.$cookies.get("access_token") != null ||
      this.$cookies.get("access_token") != undefined
    ) {
      if (this.roleService.isAdminUser) {
        this.$location.path("/dashboard");
      } else {
        this.$location.path("/userdashboard");
      }
    }
  }

  goToLoginPage() {
    this.$location.path("/login");
  }

  goToWelcomePage() {
    this.$location.path("/landingV3");
  }

  goToAgentLandingPage() {
    this.$location.path("/agentlanding");
  }
}

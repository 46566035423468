export const enum ScheduleDescriptionTypeEnum {
  DetachedHouse = 1,
  SemiDetachedHouse = 2,
  TerrancedHouse = 3,
  Cottage = 4,
  Bungalow = 5,
  Studio = 6,
  SingleStorey = 7,
  Duplex = 8,
  Triplex = 9,
}

import { AddressHistoryDTO } from "@js/DTO/AddressHistoryDTO.cs.d";
import { AddressLookupDTO } from "@js/DTO/AddressLookupDTO.cs.d";
import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseMemberDTO } from "@js/DTO/CaseMemberDTO.cs.d";
import { DevelopmentTrackRecordDTO } from "@js/DTO/DevelopmentFinance/DevelopmentTrackRecordDTO.cs.d";
import { ProjectOwnershipDTO } from "@js/DTO/DevelopmentFinance/ProjectOwnershipDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { DevelopmentTrackRecordTypeEnum } from "@js/models/enum/DevelopmentTrackRecordTypeEnum.cs.d";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { DevelopmentExperienceService } from "@js/services/DevelopmentExperienceService";
import { DevelopmentTrackRecordService } from "@js/services/DevelopmentTrackRecordService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { RoleService } from "@js/services/RoleService";
import { StreetAddressService } from "@js/services/StreetAddressService";
import { UserService } from "@js/services/UserService";

export class TrackRecordController {
  //case id
  caseid: number;

  development: ProjectOwnershipDTO;
  developmentTrackRecordType: {}[] = [];

  //navigate for pages
  step: number = 3;
  applicantNo: number = 0;
  devexperienceId: number;

  //current case
  case: CaseDTO;
  caseTitle: string;

  //user
  selectedUser: ApplicationUserDTO;

  //share shareholder
  shareContext: number = null;
  shareSuplId: number = null;
  userFirstName: string;
  shareNote: string;
  shareFirstName: string;
  shareSurname: string;
  shareEmail: string;
  showShare: boolean = false;

  saving: boolean = false;
  saved: boolean = false;
  error: boolean = false;
  dataLoading: boolean = false;

  //calculations
  total: number = 0;

  //modal
  message: string = "";
  modal: boolean = false;
  openModal: boolean = false;

  owners: CaseMemberDTO[];
  currentApplicant: CaseMemberDTO;

  //files
  fileUpload: FileAttachmentDTO[];
  uploadingFiles: FileUploadProgressDTO[];
  thisModuleSection: ModuleEnum = ModuleEnum.ApplicantDetails;

  //forms
  multiPartForm4: ng.IFormController;
  multiPartForm7: ng.IFormController;

  //address
  previousAddressSearchTerm: string;
  searchingForPreviousAddress: boolean = false;
  previousAddressPostCodeOptions: PostalAddress[] = [];
  showAddHistoryConfirmDelete: boolean = false;
  addressHistoryIdToDelete: number;
  showAddressHistoryModal: boolean = false;
  shareholderAddressHistory: AddressHistoryDTO[] = [];
  addressHistoryItem: AddressHistoryDTO;
  previousAddressSearchResults: string[];
  searchpostcode: string[];
  searchterm: string[];
  searchresults: string[];
  PostCodeOptions: PostalAddress[][] = [];
  searchingForAddress: boolean = false;

  //current shareholder
  currentShareholder: CaseMemberDTO;

  formattedAcquisitionDate: any[] = [];

  caseMembersWithAccess: CaseMemberDTO[];
  selectedClientToShareWith: CaseMemberDTO;

  static $inject = [
    "$location",
    "RoleService",
    "$rootScope",
    "$q",
    "StreetAddressService",
    "DevelopmentTrackRecordService",
    "CaseMemberService",
    "DevelopmentExperienceService",
    "$routeParams",
    "CaseService",
    "FileAttachmentService",
    "$window",
    "UserService",
    "$scope",
  ];

  constructor(
    private $location: ng.ILocationService,
    private roleService: RoleService,
    private $rootScope: ng.IRootScopeService,
    protected $q: ng.IQService,
    private streetAddressService: StreetAddressService,
    private developmenttrackrecordservice: DevelopmentTrackRecordService,
    private caseMemberService: CaseMemberService,
    private developmentexperienceservice: DevelopmentExperienceService,
    private $routeParams: ng.route.IRouteParamsService,
    private caseService: CaseService,
    private fileAttachmentService: FileAttachmentService,
    private $window: ng.IWindowService,
    private userService: UserService,
    private $scope: ng.IScope,
  ) {
    this.developmentexperienceservice
      .fetch(this.$routeParams.Id)
      .then((response) => {
        this.devexperienceId = this.$routeParams.Id;
        this.development = response;
        /*  if (localStorage.getItem('DevelopmentTrackRecords')) {
                    var data = localStorage.getItem('DevelopmentTrackRecords');
                    if (data.length > 0) {
                        var localStorageDevelopmentTrackRecords = JSON.parse(data);
                        var n = this.development.DevelopmentTrackRecords.length;

                        while (n != localStorageDevelopmentTrackRecords.length && localStorageDevelopmentTrackRecords.length > 0) {
                            this.development.DevelopmentTrackRecords.push(localStorageDevelopmentTrackRecords[n]);
                            n = this.development.DevelopmentTrackRecords.length;
                        }
                    }                
                }*/

        //Update track record development types#
        this.initDevelopmentTrackRecordTypes();
      });

    if (this.$routeParams.ApplicantNo) {
      this.currentApplicant = this.owners[this.$routeParams.ApplicantNo];
    }

    if (this.$routeParams.CaseId) {
      this.caseid = this.$routeParams.CaseId;

      this.caseService.fetch(this.caseid).then((result) => {
        this.case = result;
        this.caseTitle = result.DevelopmentInput.SearchName;
        this.fileUpload = result.Attachments;
      });
    }

    this.userService.getcurentuserrecord().then((result) => {
      this.selectedUser = result;
    });

    /*  $scope.$on('$locationChangeStart', (event: ng.IAngularEvent) => {
                localStorage.setItem('DevelopmentTrackRecords', JSON.stringify(this.development.DevelopmentTrackRecords));
            });*/
  }

  onFileSelect(files: FileAttachmentDTO[], moduleSelected?: string) {
    var module = ModuleEnum.DeveloperProfile;

    if (moduleSelected === "Ownership") {
      module = ModuleEnum.DeveloperProfile;
    } else if (moduleSelected === "Stack") {
      module = ModuleEnum.Track;
    } else if (moduleSelected === "Assets") {
      module = ModuleEnum.AssetsLiabilities;
    } else if ((moduleSelected = "ProofIncome")) {
      module = ModuleEnum.ProofOfIncome;
    }

    if (files.length > 0) {
      //if case has no id it must be saved first
      if (this.caseid < 1) {
        this.caseService
          .addUpdate(this.case)
          .then((response) => {
            this.case = response;
            this.onFileSelect(files, moduleSelected); //call itself and then exit as should now be saved so should be able to run
            return;
          })
          .finally(() => {
            this.dataLoading = false;
            this.openModal = false;
          });
        return;
      }

      this.dataLoading = true;
      this.fileAttachmentService
        .UploadFileLstInSync(
          files,
          this.uploadingFiles,
          this.case.Id,
          this.fileUpload,
          module,
        )
        .finally(() => {
          //this.calculateFilesForThisModule();
          this.openModal = false;
          this.dataLoading = false;
        });
    }
  }

  downloadFile(file: FileAttachmentDTO) {
    this.dataLoading = true;
    this.fileAttachmentService
      .getFileUri(file.FileLocation)
      .then((uri) => {
        this.$window.open(uri);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  deleteFile(file: FileAttachmentDTO) {
    this.fileUpload.splice(this.fileUpload.indexOf(file), 1);
    this.fileAttachmentService.markasdeleted(file.Id).then((result) => {});
  }

  calculateTotalFiles(filter: number) {
    if (this.fileUpload) {
      this.total = this.fileUpload.filter(
        (item) => item.Module === filter,
      ).length;
    }
    return this.total;
  }

  goToCaseDashboard(blockSave?: boolean): void {
    if (this.roleService.IsLender == true) {
      this.$location.path("/casedashboard/" + this.caseid);
    } else {
      if (blockSave) {
        this.save(true).then((response) => {
          this.$location.path("/casedashboard/" + this.caseid);
          (this.$rootScope as any).formSaved = true;
        });
      } else {
        this.save(false).then(() => {
          this.$location.path("/casedashboard/" + this.caseid);
        });
      }
    }
  }

  //save
  save(isFormComplete: boolean): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.saving = true;

    this.caseMemberService
      .addUpdatereturnonlyid(this.currentApplicant)
      .then((response) => {
        this.setAllFormsPristine();
        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
        this.error = true;
      })
      .finally(() => {
        this.saving = false;
      });

    return defer.promise;
  }

  formatDate(unformattedDate) {
    if (unformattedDate) {
      var formattedDate = new Date(
        unformattedDate.getTime() - unformattedDate.getTimezoneOffset() * 60000,
      )
        .toISOString()
        .split("T")[0];

      return formattedDate;
    }
  }

  getAddressList(app: CaseMemberDTO, index: number, searchTerm: string) {
    let foundMatch = false;

    if (
      this.PostCodeOptions &&
      this.PostCodeOptions[index] &&
      this.PostCodeOptions[index].length > 0
    ) {
      let addressLookup = this.PostCodeOptions[index].find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            this.currentShareholder.StreetAddress = address;
            this.searchterm[index] = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        this.currentShareholder.StreetAddress.AddressLine1 =
          addressLookup.AddressLine2;
        this.currentShareholder.StreetAddress.AddressLine2 =
          addressLookup.AddressLine3;
        this.currentShareholder.StreetAddress.AddressLine3 =
          addressLookup.AddressLine4;
        this.currentShareholder.StreetAddress.AddressLine4 =
          addressLookup.PostCode;
        this.currentShareholder.StreetAddress.PostCode =
          addressLookup.AddressLine1;
        this.searchterm[index] = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.PostCodeOptions[index] = [];

      this.streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.PostCodeOptions[index] = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  saveDev(): void {
    this.saving = true;

    if (
      this.development.DevelopmentTrackRecords === null ||
      !this.development.DevelopmentTrackRecords
    ) {
      this.development.DevelopmentTrackRecords = [];
    }

    //add a new one

    let newDevelopmentTrackRecord = {
      DevelopmentTrackRecordNumber:
        this.development.DevelopmentTrackRecords.length + 1,
      DevelopmentExperienceId: this.development.Id,
      AreaUnit: 1,
      DevelopmentTrackRecordType: null,
      StreetAddress: {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress,
    } as DevelopmentTrackRecordDTO;

    this.development.DevelopmentTrackRecords.push(newDevelopmentTrackRecord);

    this.formattedAcquisitionDate.forEach((d, index) => {
      this.development.DevelopmentTrackRecords[index].AcquistionDate = d;
    });

    this.developmenttrackrecordservice
      .addUpdatelistreturnonlyids(this.development.DevelopmentTrackRecords)
      .then((response) => {
        //update IDs
        this.development.DevelopmentTrackRecords.forEach((x, index) => {
          x.Id = response[index];
        });

        this.setAllFormsPristine();
        this.saved = true;
      })
      .catch((error) => {
        this.error = true;
      })
      .finally(() => {
        this.saving = false;
      });
  }

  //resent forms
  setAllFormsPristine(): void {
    if (this.multiPartForm4) {
      this.multiPartForm4.$setPristine();
    }
    if (this.multiPartForm7) {
      this.multiPartForm7.$setPristine();
    }
  }

  removeDevelopment(item: DevelopmentTrackRecordDTO) {
    if (item.Id > 0) {
      this.developmenttrackrecordservice
        .markasdeleted(item.Id)
        .then((response) => {
          if (response) {
            this.development.DevelopmentTrackRecords.splice(
              this.development.DevelopmentTrackRecords.indexOf(item),
              1,
            );
            this.formattedAcquisitionDate.splice(
              this.development.DevelopmentTrackRecords.indexOf(item),
              1,
            );
          }
        });
    } else {
      //never saved so just remove
      this.development.DevelopmentTrackRecords.splice(
        this.development.DevelopmentTrackRecords.indexOf(item),
        1,
      );
    }
  }

  back(): void {
    if (this.step > 1) {
      this.step--;
      this.go(
        "/devexperience/" +
          this.caseid +
          "/" +
          this.devexperienceId +
          "/" +
          this.step,
      );
      (this.$rootScope as any).formSaved = this.roleService.IsLender;
    } else if (this.step <= 1) {
      this.$location.path("/casedashboard/" + this.caseid);
    }
  }

  getAddressListArray(app: CaseMemberDTO, index: number, searchTerm: string) {
    let foundMatch = false;

    if (
      this.PostCodeOptions &&
      this.PostCodeOptions[index] &&
      this.PostCodeOptions[index].length > 0
    ) {
      let addressLookup = this.PostCodeOptions[index].find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            app.StreetAddress = address;
            this.searchterm[index] = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        this.development.DevelopmentTrackRecords[
          index
        ].StreetAddress.AddressLine1 = addressLookup.AddressLine2;
        this.development.DevelopmentTrackRecords[
          index
        ].StreetAddress.AddressLine2 = addressLookup.AddressLine3;
        this.development.DevelopmentTrackRecords[
          index
        ].StreetAddress.AddressLine3 = addressLookup.AddressLine4;
        this.development.DevelopmentTrackRecords[
          index
        ].StreetAddress.AddressLine4 = addressLookup.PostCode;
        this.development.DevelopmentTrackRecords[index].StreetAddress.PostCode =
          addressLookup.AddressLine1;
        this.searchterm[index] = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.PostCodeOptions[index] = [];

      this.streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.PostCodeOptions[index] = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  initDevelopmentTrackRecordTypes() {
    this.development.DevelopmentTrackRecords.forEach((r, index) => {
      this.developmentTrackRecordType[index] = {};
      for (let i = 1; i >= 0; i *= 2) {
        if (r.DevelopmentTrackRecordType >= i) {
          //number is in enum sum, add it to object
          if (r.DevelopmentTrackRecordType & i) {
            this.developmentTrackRecordType[index][i] = true;
          }
        } else {
          return;
        }
      }
    });
  }

  updateDevelopmentTrackRecordTypes(
    index: number,
    item: DevelopmentTrackRecordTypeEnum,
  ) {
    //if we are setting item to false and item exists, remove it, else add it
    if (
      this.developmentTrackRecordType[index][item] === true &&
      !!!(
        this.development.DevelopmentTrackRecords[index]
          .DevelopmentTrackRecordType & item
      )
    ) {
      this.development.DevelopmentTrackRecords[
        index
      ].DevelopmentTrackRecordType += item;
    } else {
      this.development.DevelopmentTrackRecords[
        index
      ].DevelopmentTrackRecordType -= item;
    }
  }

  trackRecordFinish(): void {
    this.developmenttrackrecordservice
      .addUpdatelistreturnonlyids(this.development.DevelopmentTrackRecords)
      .then((response) => {
        this.$location.path("/casedashboard/" + this.caseid);
        (this.$rootScope as any).formSaved = true;
      });
  }

  go(path): void {
    // Only need to save if user is NOT a lender (everything is in read-only mode for lender)
    if (this.roleService.IsLender == true) {
      this.$location.path(path);
    } else {
      this.save(false).then((response) => {
        this.devexperienceId = response;
        this.$location.path(path);
      });
    }
  }

  openSendToBorrower() {
    this.dataLoading = false;
    this.shareContext = 11;
    this.shareSuplId = this.devexperienceId;
    if (this.selectedUser.UserName !== this.case.DevelopmentInput.OwnerUser) {
      this.userFirstName = "";
    } else {
      this.userFirstName = this.selectedUser.FirstName;
    }

    var message = `Please take a look to the track record. \n\nIf you need my help or want me to complete this information with you on a call, then please contact me as soon as possible.`;

    this.shareNote =
      `Dear ${
        this.shareFirstName != null ? this.shareFirstName + "," : " " + ","
      }\n\n` + message;

    this.caseMemberService
      .fetchcasememberswithaccess(this.case.Id)
      .then((response: CaseMemberDTO[]) => {
        this.caseMembersWithAccess = response;

        this.showShare = true;
      });
  }

  sendToBorrower() {
    this.dataLoading = true;
    this.caseService
      .sendNoteToBorrower(
        this.selectedClientToShareWith.FirstName,
        this.selectedClientToShareWith.Surname,
        this.selectedClientToShareWith.Email,
        this.shareNote,
        this.case.Id,
        this.shareContext,
        this.shareSuplId,
        this.case.DevelopmentExperienceID,
      )
      .then((success) => {
        this.showShare = false;
        this.dataLoading = false;
        this.selectedClientToShareWith = null;
      });
  }

  isShareNoteFormComplete(): boolean {
    if (!this.selectedClientToShareWith) {
      return false;
    }

    if (
      this.shareNote == "undefined" ||
      this.shareNote == null ||
      this.shareNote.length == 0
    ) {
      return false;
    }

    return true;
  }
}

import { CaseChangedMessageDTO } from "@js/DTO/CaseChangedMessageDTO.cs.d";
import { BridgingDealDTO } from "@js/DTO/Deal/BridgingDealDTO.cs.d";
import { CommercialDealDTO } from "@js/DTO/Deal/CommercialDealDTO.cs.d";
import { DealDTO } from "@js/DTO/Deal/DealDTO.cs.d";
import { DevelopmentFinanceDealDTO } from "@js/DTO/Deal/DevelopmentFinanceDealDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import {
  AssignBrokerToDealRequest,
  AssignBrokerToDealResponse,
} from "@js/DTO/Messages/AssignBrokerToDealMessage.cs.d";
import {
  AssignLenderToDealRequest,
  AssignLenderToDealResponse,
} from "@js/DTO/Messages/AssignLenderToDealMessage.cs.d";
import {
  FetchDealRequest,
  FetchDealResponse,
} from "@js/DTO/Messages/Deal/FetchDealMessage.cs.d";
import { GetDealUniqueLinkResultsCountDataAndLogPageLoadEventResponse } from "@js/DTO/Messages/Deal/GetDealUniqueLinkResultsCountDataAndLogPageLoadEventMessage.cs";
import {
  InvitedUserRequest,
  InvitedUserResponse,
} from "@js/DTO/Messages/Deal/InvitedUserMessage.cs.d";
import {
  MarkSectionAsCompleteRequest,
  MarkSectionAsCompleteResponse,
} from "@js/DTO/Messages/Deal/MarkSectionAsComplete.cs.d";
import {
  PromoteSearchToCaseRequest,
  PromoteSearchToCaseResponse,
} from "@js/DTO/Messages/Deal/PromoteSearchToCaseMessage.cs.d";
import { SaveAsSearchRequest } from "@js/DTO/Messages/Deal/SaveAsSearchMessage.cs.d";
import { SaveEnterpriseClientAndRenameSearchRequest, SaveEnterpriseClientAndRenameSearchResponse } from "@js/DTO/Messages/Deal/SaveEnterpriseClientAndRenameSearchMessage.cs.d";
import {
  ShareDealRequest,
  ShareDealResponse,
} from "@js/DTO/Messages/Deal/ShareDealMessage.cs.d";
import { ShareModuleRequest } from "@js/DTO/Messages/Deal/ShareModuleMessage.cs.d";
import { SubmitToLendersRequest } from "@js/DTO/Messages/Deal/SubmitToLendersMessage.cs.d";
import { GetRegionByPostcodeResponse } from "@js/DTO/Messages/GetRegionByPostcodeMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { DealSummaryDTO } from "@js/DTO/Deal/DealSummaryDTO.cs.d"
import { ClientDTO } from "@js/DTO/ClientDTO.cs";

export class DealService extends BaseService<any, number> {
  IsSupplimentInfoFilled: boolean;
  showModal:boolean;
  dealName: string;
  previouslySelectedClient:ClientDTO;
  
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
    "$location",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
    private $location: ng.ILocationService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/deal";
    this.$broadcastBusinessNameSingular = "deal";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  /**
   * Shares a deal with a client
   * @param shareDealDto
   */
  shareDealWithClient(shareDealDto: ShareDealDTO): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    var request = {
      ShareDealDto: shareDealDto,
    } as ShareDealRequest;

    this.$http
      .post(this.$baseAddress + "/sharedeal", request)
      .then((response) => {
        var res = response.data as ShareDealResponse;
        defer.resolve(res.IsSuccess);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Gets a deal by its unique ref
   * @param uniqueRef
   */
  fetchByUniqueRef<T>(uniqueRef: string): ng.IPromise<T> {
    let defer = this.$q.defer<T>();
    this.$http
      .get(this.$baseAddress + "/fetchbyuniqueref?dealUniqueRef=" + uniqueRef)
      .then((response) => {
        var res = response.data as T;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchProductFamilyByClientUniqueRef(
    dealClientUniqueRef: string,
  ): ng.IPromise<ProductFamilyEnum> {
    let defer = this.$q.defer<ProductFamilyEnum>();

    this.$http
      .get(
        this.$baseAddress +
        "/fetchproductfamilybyclientuniqueref?dealClientUniqueRef=" +
        dealClientUniqueRef,
      )
      .then((response) => {
        var res = response.data as ProductFamilyEnum;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Assigns a broker to a deal
   * @param dealId
   * @param brokerUserId
   */
  assignBrokerToDeal(
    dealId: number,
    brokerUserId: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    var request = {
      DealId: dealId,
      BrokerUserId: brokerUserId,
    } as AssignBrokerToDealRequest;

    this.$http
      .post(this.$baseAddress + "/assignbrokertodeal", request)
      .then((response) => {
        var res = response.data as AssignBrokerToDealResponse;
        defer.resolve(res.IsSuccess);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Assigns a lender to a deal
   * @param dealId
   * @param lenderId
   * @param lenderUserId
   */
  assignLenderToDeal(
    dealId: number,
    lenderId: number,
    lenderUserId: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    var request = {
      DealId: dealId,
      LenderId: lenderId,
      LenderUserId: lenderUserId,
    } as AssignLenderToDealRequest;

    this.$http
      .post(this.$baseAddress + "/assignlendertodeal", request)
      .then((response) => {
        var res = response.data as AssignLenderToDealResponse;
        defer.resolve(res.IsSuccess);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getIsSupplimentInfoFilled() {
    return this.IsSupplimentInfoFilled;
  }

  setIsSupplimentInfoFilled(IsSupplimentInfoFilled) {
    this.IsSupplimentInfoFilled = IsSupplimentInfoFilled;
  }

  submitToLenders(
    request: SubmitToLendersRequest,
  ): ng.IPromise<CaseChangedMessageDTO> {
    let defer = this.$q.defer<CaseChangedMessageDTO>();
    this.$http
      .post(this.$baseAddress + "/submittolenders", request)
      .then((response) => {
        var res = response.data as CaseChangedMessageDTO;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchByDealId(
    dealId: number,
    productFamily: ProductFamilyEnum,
    isCaseDashboard: boolean = false
  ): ng.IPromise<FetchDealResponse> {
    let defer = this.$q.defer<FetchDealResponse>();

    var request = {
      DealId: dealId,
      ProductFamily: productFamily,
      IsCaseDashboardCall: isCaseDashboard
    } as FetchDealRequest;

    this.$http
      .post(this.$baseAddress + "/fetchbydealid", request)
      .then((response) => {
        defer.resolve(response.data as FetchDealResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  markSectionAsComplete(
    dealId: number,
    propertyName: string,
    productFamily: ProductFamilyEnum,
  ): ng.IPromise<MarkSectionAsCompleteResponse> {
    let defer = this.$q.defer<MarkSectionAsCompleteResponse>();

    var request = {
      DealId: dealId,
      PropertyName: propertyName,
      ProductFamily: productFamily,
    } as MarkSectionAsCompleteRequest;

    this.$http
      .post(this.$baseAddress + "/marksectionascomplete", request)
      .then((response) => {
        var res = response.data as MarkSectionAsCompleteResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  shareModuleToBorrower(request: ShareModuleRequest): ng.IPromise<boolean> {
    request.Note = request.Note.replace(/\n/g, "<br />");
    let defer = this.$q.defer<boolean>();
    this.$http
      .post(this.$baseAddress + "/sharemoduletoborrower", request)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  copyCase(
    dealId: number,
    dealName: string,
    productType: ProductTypeEnum,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    let productFamily = ProductFamilyEnum.Bridging;

    if ((productType & ProductTypeEnum.CommercialAll) != 0) {
      productFamily = ProductFamilyEnum.Commercial;
    } else if (productType == ProductTypeEnum.Development) {
      productFamily = ProductFamilyEnum.Development;
    }

    this.$http
      .post(
        this.$baseAddress +
        "/copycase?Id=" +
        dealId +
        "&name=" +
        dealName +
        "&productFamily=" +
        productFamily.toString(),
        "",
      )
      .then((response) => {
        defer.resolve(response.data as number);
        this.$broadcastservice.broadcastError(
          "savesearch",
          "Save search failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  checkIfUserIsInvited(
    userId: string,
    dealId: number,
  ): ng.IPromise<InvitedUserResponse> {
    let defer = this.$q.defer<InvitedUserResponse>();

    var request = {
      UserId: userId,
      DealId: dealId,
    } as InvitedUserRequest;

    this.$http
      .post(this.$baseAddress + "/isuserinvitedtodeal", request)
      .then((response) => {
        defer.resolve(response.data as InvitedUserResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  promoteSearchToCase(
    dealId: number,
    productFamily: ProductFamilyEnum,
  ): ng.IPromise<PromoteSearchToCaseResponse> {
    let defer = this.$q.defer<PromoteSearchToCaseResponse>();

    var request = {
      DealId: dealId,
      ProductFamily: productFamily,
    } as PromoteSearchToCaseRequest;

    this.$http
      .post(this.$baseAddress + "/promotesearchtocase", request)
      .then((response) => {
        var res = response.data as PromoteSearchToCaseResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveEnterpriseClientAndRenameDeal(
    request: SaveEnterpriseClientAndRenameSearchRequest,
  ): ng.IPromise<SaveEnterpriseClientAndRenameSearchResponse> {
    let defer = this.$q.defer<SaveEnterpriseClientAndRenameSearchResponse>();

    this.$http
      .post(this.$baseAddress + "/saveenterpriseclientandrenamedeal", request)
      .then((response) => {
        var res = response.data as SaveEnterpriseClientAndRenameSearchResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getRegionByPostcode(
    postcodeSearchString: string,
  ): ng.IPromise<GetRegionByPostcodeResponse> {
    let defer = this.$q.defer<GetRegionByPostcodeResponse>();
    this.$http
      .get(
        this.$baseAddress +
        "/getregionbypostcode?postcodeSearchString=" +
        postcodeSearchString,
      )
      .then((response) => {
        defer.resolve(response.data as GetRegionByPostcodeResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  isValidPostcodeString(postcodeSearchString: string) {
    if (
      /^[A-Za-z]{1,2}[0-9]{1}/.test(postcodeSearchString) &&
      postcodeSearchString.replace(" ", "").length <= 7
    ) {
      return true;
    }
    return false;
  }

  isCommercialDealDto(deal: DealDTO): deal is CommercialDealDTO {
    return deal.ProductFamily === ProductFamilyEnum.Commercial;
  }

  isBridgingDealDto(deal: DealDTO): deal is BridgingDealDTO {
    return deal.ProductFamily === ProductFamilyEnum.Bridging;
  }

  isDevelopmentFinanceDealDto(
    deal: DealDTO,
  ): deal is DevelopmentFinanceDealDTO {
    return deal.ProductFamily === ProductFamilyEnum.Development;
  }

  goToCaseDashboard(dealId: number, productFamily: ProductFamilyEnum) {
    switch (productFamily) {
      case ProductFamilyEnum.Bridging:
        this.$location.path("/bridgingcasedashboard/" + dealId);
        break;
      case ProductFamilyEnum.Commercial:
        this.$location.path("/commercialcasedashboard/" + dealId);
        break;
      case ProductFamilyEnum.Development:
        this.$location.path("/devfinancecasedashboard/" + dealId);
    }
  }

  dataSetupOnMaxLoanRequiredChange(dealDto) {
    if (!dealDto.MaxLoanRequired) {
      dealDto.NetLoanRequired = 0;
    }
    return dealDto;
  }

  showBasedOnMainResidence(dealDto): boolean {
    return (
      dealDto.IsFamilyInResidence != 1 ||
      (dealDto.IsFamilyInResidence == 1 &&
        (dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance ||
          dealDto.ProductType == ProductTypeEnum.BridgingRefurb))
    );
  }

  saveSaveAsSearch(request: SaveAsSearchRequest): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .post(this.$baseAddress + "/savesaveassearch", request)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getSearchResultsCountByUniqueRefAndLogPageLoadEvent(uniqueRef: string, orgCode: string): ng.IPromise<GetDealUniqueLinkResultsCountDataAndLogPageLoadEventResponse> {
    let defer = this.$q.defer<GetDealUniqueLinkResultsCountDataAndLogPageLoadEventResponse>();

    this.$http
      .get(
        this.$baseAddress +
        "/getsearchresultscountbyuniquerefandlogpageloadevent?uniqueRef=" +
        uniqueRef + "&orgCode=" +
        orgCode
      )
      .then((response) => {
        defer.resolve(response.data as GetDealUniqueLinkResultsCountDataAndLogPageLoadEventResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  renameDeal(dealUniqueRef: string, newName: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
        "/renamedeal?dealUniqueRef=" +
        dealUniqueRef + "&newName=" + newName,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getDealSummary(dealId: number): ng.IPromise<DealSummaryDTO> {
    let defer = this.$q.defer<DealSummaryDTO>();

    this.$http
      .get(
        this.$baseAddress +
        "/getdealsummary?dealId=" +
        dealId,
      )
      .then((response) => {
        defer.resolve(response.data as DealSummaryDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getShowSearchNameAndUserSelectionModal() {
    return this.showModal;
  }

  setShowSearchNameAndUserSelectionModal(showModal) {
    this.showModal = showModal;
  }

  getDealName() {
    return this.dealName;
  }

  setDealName(dealName) {
    this.dealName = dealName;
  }

  getClient() {
    return this.previouslySelectedClient;
  }

  setClient(client) {
    this.previouslySelectedClient = client;
  }
}

export const enum ProductTypeEnum {
  None = 0,
  Development = 1,
  BridgingPreconstruction = 2,
  BridgingPurchaseOrRefinance = 4,
  BridgingRefurb = 8,
  BridgingDeveloperExit = 16,
  CommercialInvestment = 32,
  CommercialOwnerOccupied = 64,
  /** IMPORTANT NOTE JSAny changes to the above enums (including additions) must be reflected below.Issue with the MadsKristensen.TypeScriptDefinitionGenerator being used since the generated file does not calculate the composite value,and will just increment 1 to the previous item in the list. Therefore the below commented code would not work.Bridging = BridgingPreconstruction | BridgingPurchaseOrRefinance | BridgingRefurb | BridgingDeveloperExitCommercialAll = CommercialInvestment | CommercialOwnerOccupied */
  Bridging = 30,
  CommercialAll = 96,
}

import { DealSummaryDTO } from "@js/DTO/Deal/DealSummaryDTO.cs.d";
import { DealLenderDTO } from "@js/DTO/Deal/DealLenderDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { CaseLenderService } from "@js/services/CaseLenderService";
import { BridgingDealService } from "@js/services/Deal/BridgingDealService";
import { DealLenderService } from "@js/services/Deal/DealLenderService";
import { DealService } from "@js/services/Deal/DealService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationService } from "@js/services/OrganisationService";
import { RoleService } from "@js/services/RoleService";
import { UserService } from "@js/services/UserService";

export class DealLenderController {
  static $inject = [
    "$routeParams",
    "LenderService",
    "$location",
    "RoleService",
    "UserService",
    "OrganisationService",
    "BridgingDealService",
    "DealLenderService",
    "CaseLenderService",
    "DealService",
    "$sce",
  ];

  //To save url for Lender Logo Image
  lenderLogos: string[] = [];
  //To save the Lender Id and request Lender URL
  lenderId: number[] = [];
  // Controls showing the data loading "spinner"
  dataLoading: boolean = false;
  //Show lender names or Lender Generic Name
  showLenders: boolean = false;

  currentDeal: DealSummaryDTO;
  caseLenders: DealLenderDTO[];
  // The lender the user has clicked apply/heads of terms/feedback for
  selectedCaseLender: DealLenderDTO;
  // Controls showing the Application Terms and Conditions modal
  showApplyTsAndCs: boolean = false;
  // Controls showing the Preferred Lenders modal
  showPreferredPackagers: boolean = false;
  // Controls showing the application confirmation message
  showApplicationConfirmation: boolean = false;
  // Show the feedback modal
  showFeedbackModal: boolean = false;
  // Show the Information modal
  showInformationMessage: boolean = false;
  // show the button to confirm deleting the case lender
  showConfirmDeleteButton: boolean = false;
  // case to remove
  dealLender: DealLenderDTO;
  // indicates whether a bid has been accepted on the case
  hasBidAccepted: boolean = false;

  // Message to be displayed in the message modal
  messageContent: string;

  showProceedModal: boolean = false;

  IsSupplimentInfoFilled: boolean = false;

  currentLenderPreferredBrokers: OrganisationDTO[];
  currentLenderPreferredPackagersText: string;
  hasLenderPreferredBrokers: boolean = false;
  isPreferredBrokerBrickflow: boolean = false;
  emailCache = {};

  //Export Modal
  selectedLenders: { [lenderId: number]: boolean } = {};
  showExport: boolean = false;
  showExportButton: boolean = false;
  seeLenderNames: boolean = false;
  showLenderNames: boolean = true;

  isBridging: boolean = true;

  portalLinks: {} = {};

  isDeallockerIntegrationEnabled: boolean = false;
  showDeallockerModal: boolean = false;
  isDeallockerTermsAccepted: boolean = false;
  isSubmittingToDeallocker: boolean = false;
  deallockerTabSelected: number = 1;
  hasEnterpriseLicense: boolean = false;

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private lenderService: LenderService,
    private $location: ng.ILocationService,
    private roleService: RoleService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private bridgingDealService: BridgingDealService,
    private dealLenderService: DealLenderService,
    private caseLenderService: CaseLenderService,
    private dealService: DealService,
    private $sce: ng.ISCEService,
  ) {
    this.lenderDisplayName();

    this.userService.getCurrentUserGotEnterpriseLicense().then((response) => {
      this.hasEnterpriseLicense = response;
    });

    if (this.$routeParams.DealId) {
      this.dataLoading = true;

      this.organisationService.getOrganisationProductAccess().then((response) => {
        this.isDeallockerIntegrationEnabled = response.IsDeallockerIntegrationEnabled;
      });

      this.dealService
        .getDealSummary(this.$routeParams.DealId)
        .then((response) => {
          this.currentDeal = response;

          this.userService.GetDefaultBrokerOrganisation().then((result) => {
            this.seeLenderNames = result?.ShowLenderNames;
            this.dealLenderService
              .fetchByDealId(this.$routeParams.DealId, this.seeLenderNames)
              .then((response) => {
                this.caseLenders = response.filter((dl) => dl.isPrimaryProduct);

                for (var i = 0; i < this.caseLenders.length; i++) {
                  this.lenderId[i] = this.caseLenders[i].LenderId;
                  this.toggleLender(this.caseLenders[i]);
                }
                /* this.getAllLenderLogos(this.lenderId);*/

                var bidAccepted = this.caseLenders.find(
                  (x) => x.IsBidAccepted == true,
                );

                if (bidAccepted) {
                  this.hasBidAccepted = true;
                }
                this.dipRecieved();
              })
              .then(() => {
                this.getLenderPortalLinks();
              });
          });

          this.IsSupplimentInfoFilled =
            this.dealService.getIsSupplimentInfoFilled();
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  ////Get URL for the specific lender
  //getLenderLogos(index: number) {
  //    return this.lenderLogos[index];
  //}

  //// Get all URL for all Lender result
  //getAllLenderLogos(lenderId: number[]) {
  //    this.lenderService.getAllLenderLogos(lenderId).then(result => {
  //        this.lenderLogos = result;

  //    })
  //}

  /** Redirects to the case dashboard */
  goToCaseDashboard() {
    this.dealService.goToCaseDashboard(
      this.currentDeal.Id,
      this.currentDeal.ProductFamily,
    );
  }

  /** Redirects to the user dashboard */
  goToUserDashboard() {
    this.userService.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  lenderDisplayName() {
    this.organisationService.showLenderNamesAndLogos().then((result) => {
      this.showLenders = result;
    });
  }

  /**Process the Submit Terms and Conditions button being clicked */
  submitTsAndCsClicked() {
    this.showApplyTsAndCs = false;
    this.selectedCaseLender.IsBidAccepted = true;

    this.dealLenderService
      .addUpdate(this.selectedCaseLender)
      .then((response) => {
        this.selectedCaseLender.IsBidAccepted = response.IsBidAccepted;
        this.hasBidAccepted = response.IsBidAccepted;

        return this.dealLenderService.applyToLender(this.selectedCaseLender.DealId, this.selectedCaseLender.Id);
      })
      .then((response) => {
        this.selectedCaseLender.Status = response.CaseLenderState;
        this.caseLenders = this.caseLenders.map(cl => cl.Id !== this.selectedCaseLender.Id ? cl : this.selectedCaseLender);
      })
      .finally(() => {
        this.dealLenderService.sendProceedDipEmails(this.currentDeal.Id, this.selectedCaseLender.Id);
        this.messageContent = 'Thank you for submitting an application with this lender.';
        if (this.hasLenderPreferredBrokers) {
          this.showPreferredPackagers = true;
        }
        else {
          this.showInformationMessage = true;
        }
      });
  }


  /**
   * Process the Apply button being clicked
   */
  submit(lender) {
    this.selectedCaseLender = lender;

    this.lenderService
      .getSelectedLenderPreferredOrgs(this.selectedCaseLender.LenderId)
      .then((response) => {
        this.currentLenderPreferredBrokers = response;

        return this.userService.getBrokerOrganisationId();
      })
      .then((response) => {
        //checks if broker that applies is not part of the preferred brokers list

        this.hasLenderPreferredBrokers =
          this.currentLenderPreferredBrokers != null &&
          this.currentLenderPreferredBrokers.length > 0 &&
          !this.currentLenderPreferredBrokers.some(
            (org) => org.Id === response,
          );

        if (this.hasLenderPreferredBrokers) {
          this.currentLenderPreferredBrokers.forEach((org) => {
            this.getBrokerAdminEmail(org);
          });
        }

        // Determine if any of the preferred brokers are IsBrickflow = true
        this.isPreferredBrokerBrickflow = this.currentLenderPreferredBrokers.some(org => org.IsBrickflow);

        return this.lenderService.getLenderPreferredPackagersText(
          this.selectedCaseLender.LenderId,
        );
      })
      .then((response) => {
        this.currentLenderPreferredPackagersText = response;
      })
      .finally(() => {
        this.showApplyTsAndCs = true;
      });
  }

  /**
   * Process the Apply button being clicked
   * @param currentCaseLender
   */
  applyClicked(selectedCaseLender: DealLenderDTO) {
    this.selectedCaseLender = selectedCaseLender;

    // Show the Terms and Conditions modal
    this.showApplyTsAndCs = true;
  }
  /**
   * Process the cancel Application button being clicked
   * @param currentCaseLender
   */
  cancelTerms(selectedCaseLender: DealLenderDTO) {
    this.dealLenderService
      .getSelectedCaseLender(selectedCaseLender.Id)
      .then((response) => {
        this.selectedCaseLender = response;
      })
      .then(() => {
        if (this.selectedCaseLender.Status == CaseLenderStateEnum.Applied) {
          this.selectedCaseLender.IsBidAccepted = false;
          this.dealLenderService
            .addUpdate(this.selectedCaseLender)
            .then((response) => {
              this.selectedCaseLender.IsBidAccepted = response.IsBidAccepted;
              this.hasBidAccepted = response.IsBidAccepted;

              return this.dealLenderService.cancelApplication(
                this.selectedCaseLender.DealId,
                this.selectedCaseLender.Id,
              );
            })
            .then((response) => {
              this.selectedCaseLender.Status = response.CaseLenderState;
              this.caseLenders = this.caseLenders.map((cl) =>
                cl.Id !== this.selectedCaseLender.Id
                  ? cl
                  : this.selectedCaseLender,
              );
            })
            .finally(() => {
              this.messageContent =
                "Application with this lender has been cancelled.";
              this.showInformationMessage = true;
            });
        } else {
          this.messageContent =
            "There was a problem with cancelling this application. Please refresh and try again.";
          this.showInformationMessage = true;
        }
      });

    //this.showProceedModal = true;
  }

  getStatusText(selectedCaseLender): string {
    var statusText = "";

    if (this.roleService.IsLender) {
      statusText = this.caseLenderService.getStatusTextForLender(
        selectedCaseLender.Status,
        selectedCaseLender.IsBidAccepted,
      );
    } else {
      switch (selectedCaseLender.Status) {
        case CaseLenderStateEnum.Offered: {
          if (this.hasBidAccepted) {
            statusText = selectedCaseLender.IsBidAccepted
              ? "DIP received and accepted"
              : "DIP received but applying to another lender";
          } else {
            statusText = "DIP received";
          }
          break;
        }
        case CaseLenderStateEnum.Received: {
          statusText = "Awaiting DIP";
          if (this.hasBidAccepted) {
            statusText = "Awaiting DIP but applying to another lender";
          } else {
            statusText = "Awaiting DIP";
          }
          break;
        }
        case CaseLenderStateEnum.Cancelled: {
          if (this.hasBidAccepted) {
            statusText = "Application cancelled but applying to another lender";
          } else {
            statusText = "Application cancelled";
          }
          break;
        }
      }
    }

    return statusText;
  }

  editApplicationDetails() {
    this.$location.path("/dealapplicationdetails/" + this.currentDeal.Id);
  }

  deleteButton(dealLender: DealLenderDTO) {
    this.showConfirmDeleteButton = true;
    this.showInformationMessage = true;
    this.messageContent =
      "Are you sure you want to remove " + dealLender.LenderName + "?";
    this.dealLender = dealLender;
  }

  delete() {
    this.dealLenderService
      .markasdeleted(this.dealLender.Id)
      .then((result) => {
        this.caseLenders = this.caseLenders.filter(
          (x) => x.Id != this.dealLender.Id,
        );
      })
      .finally(() => {
        this.showInformationMessage = false;
        delete this.dealLender;
        this.showConfirmDeleteButton = false;
      });
  }

  /**
   * Determines whether to show the Shortlist More Lenders button.
   * Should be shown if there are less than 3 "active" shortlisted lenders i.e. haven't rejected/withdrawn
   * and no DIP is accepted
   * */
  showShortlistMoreLendersButton(): boolean {
    var showButton: boolean = false;
    if (this.caseLenders) {
      var activeShortlistCount = this.caseLenders.filter(
        (lender) =>
          lender.Status != CaseLenderStateEnum.Withdrawn &&
          lender.Status != CaseLenderStateEnum.Rejected,
      ).length;

      showButton = activeShortlistCount < 5 ? true : false;
    }

    //Hide button if DIP accepted
    if (this.hasBidAccepted) {
      showButton = false;
    }

    return showButton;
  }

  shortlistMoreLenders() {
    if (this.currentDeal.ProductFamily == ProductFamilyEnum.Commercial) {
      this.$location.path("/commercialshortlistmore/" + this.currentDeal.Id);
    } else if (this.currentDeal.ProductFamily == ProductFamilyEnum.Bridging) {
      this.$location.path("/bridgingshortlistmore/" + this.currentDeal.Id);
    } else if (
      this.currentDeal.ProductFamily == ProductFamilyEnum.Development
    ) {
      this.$location.path("/devfinanceshortlistmore/" + this.currentDeal.Id);
    }
  }

  toggleLender(lender: DealLenderDTO) {
    this.selectedLenders[lender.Id] = !this.selectedLenders[lender.Id];
  }

  stateFilter(lender: DealLenderDTO) {
    return !lender.IsDeleted && (lender.Status === 2 || lender.Status === 10);
  }

  showAndHideExport() {
    this.showExport = !this.showExport;
  }

  getSelectedCaseLenders() {
    const selectedCaseLenders = this.caseLenders.filter(
      (lender) => this.selectedLenders[lender.Id],
    );
    return selectedCaseLenders;
  }

  areLendersSelected() {
    for (var lenderId in this.selectedLenders) {
      if (this.selectedLenders[lenderId]) {
        return true;
      }
    }
    return false;
  }

  dipRecieved() {
    this.showExportButton = this.caseLenders.some(
      (lender) => lender.Status === 2 || lender.Status === 10,
    );
  }

  /** Process the Heads of Terms button being clicked */
  headsOfTermsClicked(selectedCaseLender: DealLenderDTO, index) {
    this.selectedCaseLender = selectedCaseLender;
    if (this.currentDeal.ProductFamily == ProductFamilyEnum.Commercial) {
      this.$location.path(
        "/commercialheadsofterm/" +
        this.currentDeal.Id +
        "/" +
        this.selectedCaseLender.Id,
      );
    } else if (this.currentDeal.ProductType == ProductTypeEnum.Development) {
      this.$location.path(
        "/devfinanceheadsofterm/" +
        this.currentDeal.Id +
        "/" +
        this.selectedCaseLender.Id,
      );
    } else {
      this.$location.path(
        "/bridgingheadsofterm/" +
        this.currentDeal.Id +
        "/" +
        this.selectedCaseLender.Id,
      );
    }
  }

  trustedHtml(plainText) {
    return this.$sce.trustAsHtml(plainText);
  }

  getBrokerAdminEmail(org: OrganisationDTO) {
    if (this.emailCache && this.emailCache[org.Id]) {
      return;
    }

    if (!org.PreferredPackagerEmail) {
      this.userService.getOrganisationAdminEmail(org.Id).then((response) => {
        this.emailCache[org.Id] = response;
      });
    } else {
      this.emailCache[org.Id] = org.PreferredPackagerEmail;
    }
  }

  getLenderPortalLinks() {
    const lenderIds = this.caseLenders
      .filter((dl) => dl.HasAppliedInLenderPortal)
      .map((dl) => dl.LenderId);

    this.lenderService
      .getLenderPortalLinks(lenderIds)
      .then((links) => {
        this.portalLinks = links;
      })
      .catch((error) => {
        this.portalLinks = null;
      });
  }

  openPortalLink(portalLink: string) {
    if (!/^https?:\/\//i.test(portalLink)) {
      portalLink = "http://" + portalLink;
    }
    window.open(portalLink, "_blank");
  }

  submitToDealocker() {
    this.isSubmittingToDeallocker = true;
    //TODO call deallocker API end point
    this.dealLenderService.submitToDeallocker(this.dealLender.Id).then(response => {
      return response;
    }).then(response => {
      if (response) {
        return this.dealLenderService.getSelectedCaseLender(this.dealLender.Id).then(caseLender => {
          if (caseLender && caseLender.Id) {
            this.caseLenders = [...this.caseLenders.map(lender =>
              lender.Id === caseLender.Id ? caseLender : lender
            )];
          }
          this.showInformationMessage = true;
          this.messageContent = 'Your case information has been sent to Deallocker. They\'ll confirm receipt via email.';
          return caseLender;
        });
      }
    }).catch(() => {
      // TODO do something if it fails?
    }).finally(() => {
      this.showDeallockerModal = false;
      this.isSubmittingToDeallocker = false;
      this.isDeallockerTermsAccepted = false;
      this.dealLender = null;
    });

  }
}

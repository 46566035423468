export class RegInfoPanelController {
    public heading: string;
    public subtext: string;
    public mainheading: string;
    public subheading: string;
    public logoUrl: string = '';
    public isWhiteLabelled: boolean = false;

    constructor(private $rootScope, private $element) {
    }

    public getLogo() {
        if (sessionStorage.getItem('companyLogoUrl') != null) {
            this.isWhiteLabelled = true;
            return sessionStorage.getItem('companyLogoUrl');
        } else {
            this.isWhiteLabelled = false;
            return '../../img/BrickFlow_Logo_RGB_Blue_Icon.svg';
        }
    }
}

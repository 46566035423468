import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { InvitedBrokerDTO } from "@js/DTO/InvitedBrokerDTO.cs.d";
import { InviteDTO } from "@js/DTO/InviteDTO.cs.d";
import { OrganisationAndBrokerDetailsMessage } from "@js/DTO/Messages/OrganisationAndBrokerDetailsMessage.cs.d";
import { OrganisationProductAccessMessageResponse } from "@js/DTO/Messages/OrganisationProductAccessMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { OrganisationLinkDTO } from "@js/DTO/OrgnisationLinkDTO.cs.d";
import { PageWithTotalCountDTO } from "@js/DTO/PageWithTotalCountDTO.cs.d";
import { RegisterOrganisationDTO } from "@js/DTO/RegisterOrganisationDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { PlanTypeEnum } from "@js/models/enum/PlanTypeEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";

export class OrganisationService extends BaseService<OrganisationDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  brickflowBrokerOrg: OrganisationDTO;

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/organisation";
    this.$broadcastBusinessNameSingular = "organisation";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  protected fetchpageInternal(
    needle: string,
    page: number,
    count: number,
    screen: string,
    message: string,
  ): ng.IPromise<PageWithTotalCountDTO> {
    let defer = this.$q.defer<PageWithTotalCountDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/organisationPage?needle=" +
          needle +
          "&page=" +
          page +
          "&Count=" +
          count,
      )
      .then((response) => {
        defer.resolve(response.data as PageWithTotalCountDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }
  fetchpage(
    needle: string,
    page: number,
    count: number,
  ): ng.IPromise<PageWithTotalCountDTO> {
    return this.fetchpageInternal(
      needle,
      page,
      count,
      this.$broadcastScreen,
      "Unable to get a list of " +
        this.$broadcastBusinessNamePlural +
        " from the  Please try again shortly.",
    );
  }

  inviteBrokers(
    users: InvitedBrokerDTO[],
    orgUniqueRef: string,
  ): ng.IPromise<string[]> {
    let defer = this.$q.defer<string[]>();

    this.$http
      .post(
        this.$baseAddress + "/inviteBrokers?orgRef=" + orgUniqueRef,
        JSON.stringify(users),
      )
      .then((response) => {
        defer.resolve(response.data as string[]);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  inviteBorrowers(
    users: InviteDTO,
    orgUniqueRef: string,
  ): ng.IPromise<string[]> {
    let defer = this.$q.defer<string[]>();

    this.$http
      .post(
        this.$baseAddress + "/registerBorrower?orgRef=" + orgUniqueRef,
        JSON.stringify(users),
      )
      .then((response) => {
        defer.resolve(response.data as string[]);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getOrganisationIdByUniqueRef(uniqueRef: string): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        this.$baseAddress +
          "/findOrganisationIdByUniqueRef?uniqueRef=" +
          uniqueRef,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getOrganisationByUniqueRef(uniqueRef: string): ng.IPromise<OrganisationDTO> {
    let defer = this.$q.defer<OrganisationDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/findOrganisationByUniqueRef?uniqueRef=" +
          uniqueRef,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Is the Organisation a "paid up" i.e. active, premium organisation
   * @param orgId
   */
  isOrgActivePremium(orgId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.fetch(orgId).then((response: OrganisationDTO) => {
      // TODO JLH Need to implement the paid up part of this function
      var isPaidUpPremium =
        response.PlanType == PlanTypeEnum.Premium; /* && isPaidUp*/
      defer.resolve(isPaidUpPremium);
    });

    return defer.promise;
  }

  /**
   * Is the supplied organisation a white labelled organisation
   * @param orgId
   */
  isOrgWhiteLabelled(orgId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.fetch(orgId).then((response: OrganisationDTO) => {
      var isWhiteLabelled =
        response.HasWhiteLabelAddOnPaid == true &&
        response.IsWhiteLabelled == true;
      defer.resolve(isWhiteLabelled);
    });

    return defer.promise;
  }

  /**
   *Display or not the logo
   */
  showLenderNamesAndLogos(searchId: number = 0, isDeal : boolean = false): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(this.$baseAddress + "/showLenderNamesAndLogos?searchId=" + searchId + "&isDeal=" + isDeal)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Register a new Organisation,  org admin user and invite users to join the organisation
   * @param registerOrganisationDto
   * returns RegisterOrganisationDTO containing the newly created organisation and org admin
   */
  registerOrganisationAndAdmin(
    registerOrganisationDto: RegisterOrganisationDTO,
  ): ng.IPromise<RegisterOrganisationDTO> {
    let defer = this.$q.defer<RegisterOrganisationDTO>();

    this.$http
      .post(
        this.$baseAddress + "/registerorganisation?",
        JSON.stringify(registerOrganisationDto),
      )
      .then((response) => {
        defer.resolve(response.data as RegisterOrganisationDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Register a new Broker and assign it to an organisation, converting it also to Introducer
   * @param newUser uniqueRef
   * returns ApplicationUserDTO containing the newly created broker
   */
  registerBroker(
    newUser: ApplicationUserDTO,
    uniqueRef: string,
  ): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();

    this.$http
      .post(
        this.$baseAddress + "/registerBroker?uniqueRef=" + uniqueRef,
        JSON.stringify(newUser),
      )
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Saves the organisation and generates/processes the whitelabelling json file for the organisation
   * @param organisationDto
   */
  saveOrganisation(
    organisationDto: OrganisationDTO,
  ): ng.IPromise<OrganisationDTO> {
    let defer = this.$q.defer<OrganisationDTO>();

    this.$http
      .post(
        this.$baseAddress + "/saveorganisation?",
        JSON.stringify(organisationDto),
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Gets the organisation relevant to the current user
   */
  getOrganisation(): ng.IPromise<OrganisationDTO> {
    let defer = this.$q.defer<OrganisationDTO>();

    this.$http
      .get(this.$baseAddress + "/getorganisation")
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Gets the organisation by the organisation's code
   * @param organisationCode
   */
  getOrganisationByOrgCode(
    organisationCode: string,
  ): ng.IPromise<OrganisationDTO> {
    let defer = this.$q.defer<OrganisationDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/findorganisationbyorgcode?orgcode=" +
          organisationCode,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Gets the organisation application name
   * @param organisationCode
   */
  getOrgApplicationNameByUserName(userName: string): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    var encodedUserName = encodeURIComponent(userName);
    this.$http
      .get(
        this.$baseAddress +
          "/findorgapplicationnamebyusername?username=" +
          encodedUserName,
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getAppName() {
    let lsd = sessionStorage.getItem("applicationName");
    if (lsd != null) {
      return lsd;
    } else {
      return "Brickflow";
    }
  }

  deleteOrganisation(orgId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(this.$baseAddress + "/deleteorganisation?orgId=" + orgId)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Send email to Broker when they leave inbetween the registration
   * @param brokerEmail,brokerFirstName,brokerLastName
   */
  brokerSignUpJourneyG2Email(
    brokerEmail: string,
    brokerFirstName: string,
    brokerLastName: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodedEmail = encodeURIComponent(brokerEmail);
    this.$http
      .get(
        this.$baseAddress +
          "/brokersignupjourneyg2email?brokerEmail=" +
          encodedEmail +
          "&brokerFirstName=" +
          brokerFirstName +
          "&brokerLastName=" +
          brokerLastName,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  brokerSignUpJourneyG5Email(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(this.$baseAddress + "/brokersignupjourneyg5email")
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  brokerSignUpJourneyG0Email(
    brokerEmail: string,
    brokerFullName: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodedEmail = encodeURIComponent(brokerEmail);
    this.$http
      .get(
        this.$baseAddress +
          "/brokersignupjourneyg0email?brokerEmail=" +
          encodedEmail +
          "&brokerFullName=" +
          brokerFullName,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getOrganisationAdmin(orgId: number): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(this.$baseAddress + "/getorganisationadmin?orgId=" + orgId)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  assignTrialLicenseToOrg(
    products: AppPackagePricingDTO[],
    licenseEndDate: Date,
    orgAdminUserId: string,
    quantity: number,
    IsFreeTrialForOrg: boolean,
    isAddonSelected: boolean,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var licenseEndDateString = null;
    if (licenseEndDate != null) {
      var templicenseEndDate = new Date();
      templicenseEndDate.setHours(0, 0, 0, 0);
      var licenseEndDateUtc = new Date(
        templicenseEndDate.setUTCFullYear(
          licenseEndDate.getFullYear(),
          licenseEndDate.getMonth(),
          licenseEndDate.getDate(),
        ),
      );
      licenseEndDateString = licenseEndDateUtc.getTime();
    }
    this.$http
      .post(
        this.$baseAddress +
          "/assigntriallicensetoorg?licenseEndDate=" +
          licenseEndDateString +
          "&orgAdminUserId=" +
          orgAdminUserId +
          "&quantity=" +
          quantity +
          "&IsFreeTrialForOrg=" +
          IsFreeTrialForOrg +
          "&isAddonSelected=" +
          isAddonSelected,
        JSON.stringify(products),
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getBrickflowBrokerOrg(): ng.IPromise<OrganisationDTO> {
    let defer = this.$q.defer<OrganisationDTO>();
    if (this.brickflowBrokerOrg) {
      defer.resolve(this.brickflowBrokerOrg);
    } else {
      this.$http
        .get(this.$baseAddress + "/getbrickflowbrokerorg")
        .then((response) => {
          this.brickflowBrokerOrg = response.data as OrganisationDTO;
          defer.resolve(response.data as OrganisationDTO);
        })
        .catch((response) => {
          defer.reject(response);
        });
    }

    return defer.promise;
  }

  getOrgByUrlIfOrgHasEnterpriseSearchPermission(
    orgCode: string,
    productFamily: ProductFamilyEnum,
    orgLinkId: string = "",
  ): ng.IPromise<OrganisationDTO> {
    let defer = this.$q.defer<OrganisationDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/getorgbyurliforghasenterprisesearchpermission?orgCode=" +
          orgCode +
          "&productFamily=" +
          productFamily +
          "&orgLinkId=" +
          orgLinkId,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Gets the organisation admin by the organisation's code
   * @param organisationCode
   */
  getOrganisationAdminByOrgCode(
    organisationCode: string,
  ): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/findorganisationadminbyorgcode?orgcode=" +
          organisationCode,
      )
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Gets the organisation licenses
   * @param organisationId
   */
  isLeadGenLicenseActive(organisationId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/isleadgenlicenseactive?organisationId=" +
          organisationId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Send a email to broker attached to search
   * @param dealId
   * @param message
   * @param borrowerFirstName
   * @param borrowerLastName
   * @param borrowerEmail
   * @param productType
   * @param screenType
   * @param uniqueRef
   */
  sendBorrowerMessageToSearchBroker(
    dealId: number,
    message: string,
    borrowerFirstName: string,
    borrowerLastName: string,
    borrowerEmail: string,
    productType: ProductTypeEnum,
    screenType: string,
    uniqueRef: string = "",
  ): ng.IPromise<OrganisationDTO> {
    let defer = this.$q.defer<OrganisationDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/sendborrowermessagetosearchbroker?dealId=" +
          dealId +
          "&message=" +
          message +
          "&borrowerFirstName=" +
          borrowerFirstName +
          "&borrowerLastName=" +
          borrowerLastName +
          "&borrowerEmail=" +
          borrowerEmail +
          "&productType=" +
          productType +
          "&screenType=" +
          screenType +
          "&uniqueRef=" +
          uniqueRef,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Send a email to organisation admin
   * @param organisationId
   * @param message
   * @param borrowerFirstName
   * @param borrowerLastName
   * @param borrowerEmail
   */
  sendBorrowerMessageToOrganisationAdmin(
    organisationId: number,
    message: string,
    borrowerFirstName: string,
    borrowerLastName: string,
    borrowerEmail: string,
    productType: ProductTypeEnum,
    screenType: string,
  ): ng.IPromise<OrganisationDTO> {
    let defer = this.$q.defer<OrganisationDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/sendborrowermessagetoorganisationadmin?organisationId=" +
          organisationId +
          "&message=" +
          message +
          "&borrowerFirstName=" +
          borrowerFirstName +
          "&borrowerLastName=" +
          borrowerLastName +
          "&borrowerEmail=" +
          borrowerEmail +
          "&productType=" +
          productType +
          "&screenType=" +
          screenType,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }
  sendTestEmail(
    recipientEmailAddress: string,
    emailSubject: string,
    emailMessage: string,
    orgId: number,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/sendtestemail?recipientEmailAddress=" +
          recipientEmailAddress +
          "&emailSubject=" +
          emailSubject +
          "&emailMessage=" +
          emailMessage +
          "&orgId=" +
          orgId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Gets a HasBridging and HasCommercialMortgage values from current user organisation
   */
  getOrganisationProductAccess(): ng.IPromise<OrganisationProductAccessMessageResponse> {
    let defer = this.$q.defer<OrganisationProductAccessMessageResponse>();

    this.$http
      .get(this.$baseAddress + "/getorganisationproductaccess")
      .then((response) => {
        defer.resolve(
          response.data as OrganisationProductAccessMessageResponse,
        );
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }
  /**
   * @param data
   */
  sendDataToParent(type: string, data: any): void {
    try {
      if (window.self !== window.top) {
        window.parent.postMessage(
          {
            type: type,
            data,
          },
          "*",
        );
      } else {
        console.log(
          "Not running inside an iframe. Cannot send data to parent.",
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  isOrganisationCodeValid(org: OrganisationDTO): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .post(
        this.$baseAddress + "/isorganisationcodevalid?orgId",
        JSON.stringify(org),
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getUniqueLinks(organisationId: number): ng.IPromise<OrganisationLinkDTO[]> {
    let defer = this.$q.defer<OrganisationLinkDTO[]>();
    this.$http
      .get(
        this.$baseAddress + "/getuniquelinks?organisationId=" + organisationId,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationLinkDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveUniqueLink(
    organisationLink: OrganisationLinkDTO,
    isEditUniqueLink: boolean = false,
  ): ng.IPromise<OrganisationLinkDTO> {
    let defer = this.$q.defer<OrganisationLinkDTO>();
    this.$http
      .post(
        this.$baseAddress +
          "/saveuniquelink?isEditUniqueLink=" +
          isEditUniqueLink,
        JSON.stringify(organisationLink),
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationLinkDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getData(type: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (window.self !== window.top) {
        const messageHandler = (event) => {
          if (event.data && event.data.type === `${type}Data`) {
            let targetOrigin = event.origin;
            if (!targetOrigin || targetOrigin === "null") {
              targetOrigin = "*";
            }
            (event.source as Window).postMessage(
              {
                type: "acknowledgment",
                data: {
                  message: `${type}Data received and processed`,
                  originalType: `${type}`,
                },
              },
              targetOrigin,
            );
            resolve(event.data.data[type]);
            window.removeEventListener("message", messageHandler);
          }
        };

        // remove existing message event listeners
        window.removeEventListener("message", messageHandler);

        // add new message event listener
        window.addEventListener("message", messageHandler, false);

        // Request data from parent
        window.parent.postMessage(
          {
            type: `request${type}`,
          },
          "*",
        );

        setTimeout(() => reject(`Timed out while waiting for ${type}`), 5000);
      } else {
        resolve("");
      }
    });
  }

  /**
   * Gets the organisation and broker for attached search
   * @param organisationCode
   */
  getOrganisationAndBrokerByUniqueRef(
    uniqueRef: string,
  ): ng.IPromise<OrganisationAndBrokerDetailsMessage> {
    let defer = this.$q.defer<OrganisationAndBrokerDetailsMessage>();
    this.$http
      .get(
        this.$baseAddress +
          "/getorganisationandbrokerbyuniqueRef?uniqueRef=" +
          uniqueRef,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationAndBrokerDetailsMessage);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Gets the organisation and broker for attached deal
   * @param dealId
   */
  getOrganisationAndBrokerByDealId(
    dealId: number,
  ): ng.IPromise<OrganisationAndBrokerDetailsMessage> {
    let defer = this.$q.defer<OrganisationAndBrokerDetailsMessage>();
    this.$http
      .get(
        this.$baseAddress +
          "/getorganisationandbrokerbydealid?dealId=" +
          dealId,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationAndBrokerDetailsMessage);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Checks validity of broker hubpspot integration key
   * @param accessToken
   * @param pipelineStage
   */
  validateKey(accessToken: string, pipelineStage: string): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(
        this.$baseAddress +
          "/validatekey?accessToken=" +
          accessToken +
          "&pipelineStage=" +
          pipelineStage,
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Gets missing properties in broker hubpspot integration
   * @param accessToken
   * @param pipelineStage
   */
  getMissingProperties(
    accessToken: string,
    pipelineStage: string,
  ): ng.IPromise<string[]> {
    let defer = this.$q.defer<string[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/getmissingproperties?accessToken=" +
          accessToken +
          "&pipelineStage=" +
          pipelineStage,
      )
      .then((response) => {
        defer.resolve(response.data as string[]);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  hasValidWhitelabelProperties(organisation) {
    if (
      organisation != null &&
      organisation.OrganisationCode != null &&
      organisation.OrganisationCode.length > 0 &&
      organisation.HasWhiteLabelAddOnPaid &&
      organisation.IsWhiteLabelled
    ) {
      return true;
    }

    return false;
  }
}

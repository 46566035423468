import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { LenderAppraisal } from "@js/DTO/Messages/MakeReferralMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { PageWithTotalCountDTO } from "@js/DTO/PageWithTotalCountDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { LicenseMasterStatusEnum } from "@js/models/enum/LicenseMasterStatusEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { OrganisationService } from "./OrganisationService";
import { RoleService } from "./RoleService";
import { GetCurrentUserPermissionForProductSearchResponse } from "@js/DTO/Messages/GetCurrentUserPermissionForProductSearchMessage.cs";

export class UserService extends BaseService<ApplicationUserDTO, string> {
  fileSelected: string;

  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
    "OrganisationService",
    "RoleService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
    private organisationService: OrganisationService,
    private roleService: RoleService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/users";
    this.$broadcastBusinessNameSingular = "User";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getcurentuserrecord(): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();

    // override implementation called from UserController
    this.$http
      .get(this.$baseAddress + "/meadminstatus")
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  searchByEmail(searchTerm: string): ng.IPromise<ApplicationUserDTO[]> {
    let defer = this.$q.defer<ApplicationUserDTO[]>();
    this.$http
      .get(this.$baseAddress + "/searchbyemail?searchterm=" + searchTerm)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getintroducees(userId: string): ng.IPromise<ApplicationUserDTO[]> {
    let defer = this.$q.defer<ApplicationUserDTO[]>();
    this.$http
      .get(this.$baseAddress + "/introducees?userId=" + userId)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getUserEmail(userId: string): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(this.$baseAddress + "/getuseremail?userId=" + userId)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  createtemporaryuser(dto: ApplicationUserDTO) {
    let defer = this.$q.defer<ApplicationUserDTO>();
    this.$http
      .post(this.$baseAddress + "/createtemporaryuser", dto)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  protected fetchpageInternal(
    needle: string,
    page: number,
    count: number,
    screen: string,
    message: string,
    role?: string,
  ): ng.IPromise<PageWithTotalCountDTO> {
    let defer = this.$q.defer<PageWithTotalCountDTO>();
    this.$http
      .get(
        this.$baseAddress +
        "/user?needle=" +
        needle +
        "&page=" +
        page +
        "&Count=" +
        count +
        "&Role=" +
        role,
      )
      .then((response) => {
        defer.resolve(response.data as PageWithTotalCountDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(screen, message);
        defer.reject(response);
      });
    return defer.promise;
  }
  fetchpage(
    needle: string,
    page: number,
    count: number,
    role?: string,
  ): ng.IPromise<PageWithTotalCountDTO> {
    return this.fetchpageInternal(
      needle,
      page,
      count,
      this.$broadcastScreen,
      "Unable to get a list of " +
      this.$broadcastBusinessNamePlural +
      " from the  Please try again shortly.",
      role,
    );
  }

  /**Is the current user a Broker organisation admin */
  IsBrokerOrgAdmin(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    //TODO - JLH - does this function need to be more speciic that this?  e.g. passing in an org Id to know whether the user is the admin of a particular org (or could be a separate function)
    if ((this.$rootScope as any).currentUser) {
      var currentUser: ApplicationUserDTO = (this.$rootScope as any)
        .currentUser;
      defer.resolve(currentUser.IsOrganisationAdmin);
    } else {
      this.getcurentuserrecord().then((response) => {
        (this.$rootScope as any).currentUser = response;
        var currentUser: ApplicationUserDTO = (this.$rootScope as any)
          .currentUser;
        defer.resolve(currentUser.IsOrganisationAdmin);
      });
    }

    return defer.promise;
  }

  /** Gets the default broker organisation Id for the current user */
  GetDefaultBrokerOrgId(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    if ((this.$rootScope as any).currentUser) {
      var currentUser: ApplicationUserDTO = (this.$rootScope as any)
        .currentUser;
      defer.resolve(currentUser.DefaultBrokerOrganisationId);
    } else {
      this.getcurentuserrecord().then((response) => {
        (this.$rootScope as any).currentUser = response;
        var currentUser: ApplicationUserDTO = (this.$rootScope as any)
          .currentUser;
        defer.resolve(currentUser.DefaultBrokerOrganisationId);
      });
    }

    return defer.promise;
  }

  /**Gets the default broker organisation DTO for the current user */
  GetDefaultBrokerOrganisation(): ng.IPromise<OrganisationDTO> {
    var defaultBrokerOrgId: number;

    let defer = this.$q.defer<OrganisationDTO>();

    if ((this.$rootScope as any).currentUser) {
      var currentUser: ApplicationUserDTO = (this.$rootScope as any)
        .currentUser;
      defaultBrokerOrgId = currentUser.DefaultBrokerOrganisationId;

      if (defaultBrokerOrgId) {
        this.organisationService
          .fetch(defaultBrokerOrgId)
          .then((response: OrganisationDTO) => {
            defer.resolve(response);
          });
      } else {
        defer.resolve(null);
      }
    } else {
      this.getcurentuserrecord().then((response) => {
        (this.$rootScope as any).currentUser = response;
        var currentUser: ApplicationUserDTO = (this.$rootScope as any)
          .currentUser;
        defaultBrokerOrgId = currentUser.DefaultBrokerOrganisationId;

        if (defaultBrokerOrgId) {
          this.organisationService
            .fetch(defaultBrokerOrgId)
            .then((response: OrganisationDTO) => {
              defer.resolve(response);
            });
        } else {
          defer.resolve(null);
        }
      });
    }

    return defer.promise;
  }

  /** Gets the user's organisation Id for the current user */
  GetUserOrganisationId(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    if ((this.$rootScope as any).currentUser) {
      var currentUser: ApplicationUserDTO = (this.$rootScope as any)
        .currentUser;
      defer.resolve(currentUser.OrganisationId);
    } else {
      this.getcurentuserrecord().then((response) => {
        (this.$rootScope as any).currentUser = response;
        var currentUser: ApplicationUserDTO = (this.$rootScope as any)
          .currentUser;
        defer.resolve(currentUser.OrganisationId);
      });
    }

    return defer.promise;
  }

  /**Gets the user's organisation DTO for the current user */
  GetUserOrganisation(): ng.IPromise<OrganisationDTO> {
    var userOrgId: number;

    if ((this.$rootScope as any).currentUser) {
      var currentUser: ApplicationUserDTO = (this.$rootScope as any)
        .currentUser;
      userOrgId = currentUser.OrganisationId;
    } else {
      this.getcurentuserrecord().then((response) => {
        (this.$rootScope as any).currentUser = response;
        var currentUser: ApplicationUserDTO = (this.$rootScope as any)
          .currentUser;
        userOrgId = currentUser.OrganisationId;
      });
    }

    let defer = this.$q.defer<OrganisationDTO>();

    this.organisationService
      .fetch(userOrgId)
      .then((response: OrganisationDTO) => {
        defer.resolve(response);
      });

    return defer.promise;
  }

  /** Is the user's OrganisationId paid up (active) and premium*/
  IsUserOrgActivePremium(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.GetUserOrganisationId().then((orgId: number) => {
      this.organisationService
        .isOrgActivePremium(orgId)
        .then((isOrgActivePremium: boolean) => {
          defer.resolve(isOrgActivePremium);
        });
    });
    return defer.promise;
  }

  /** Is the user's organisation or the organisation who referred them a "white labelled" org */
  IsWhiteLabelled(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var userOrgId: number;
    var userReferredOrgId: number;

    if ((this.$rootScope as any).currentUser) {
      var currentUser: ApplicationUserDTO = (this.$rootScope as any)
        .currentUser;
      userOrgId = currentUser.OrganisationId;
      userReferredOrgId = currentUser.OrganisationReferralId;

      if (userOrgId || userReferredOrgId) {
        this.isUserOrgWhiteLabelled(userOrgId, userReferredOrgId).then(
          (isOrgWhiteLabelled: boolean) => {
            defer.resolve(isOrgWhiteLabelled);
          },
        );
      } else {
        defer.resolve(false);
      }
    } else {
      this.getcurentuserrecord().then((response) => {
        (this.$rootScope as any).currentUser = response;
        var currentUser: ApplicationUserDTO = (this.$rootScope as any)
          .currentUser;
        userOrgId = currentUser.OrganisationId;
        userReferredOrgId = currentUser.OrganisationReferralId;

        if (userOrgId || userReferredOrgId) {
          this.isUserOrgWhiteLabelled(userOrgId, userReferredOrgId).then(
            (isOrgWhiteLabelled: boolean) => {
              defer.resolve(isOrgWhiteLabelled);
            },
          );
        } else {
          defer.resolve(false);
        }
      });
    }

    if (userOrgId || userReferredOrgId) {
    } else {
    }

    return defer.promise;
  }

  /**
   * Is the user's organisation or the organisation who referred them a "white labelled" organisation
   * @param orgId
   * @param referralOrgId
   */
  private isUserOrgWhiteLabelled(
    orgId: number,
    referralOrgId: number,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    // TODO JLH Can a user have an org ID AND a referralOrgId and in this scenario would we need to check both organisations for white labelling?
    if (orgId || referralOrgId) {
      this.organisationService
        .isOrgWhiteLabelled(orgId ? orgId : referralOrgId)
        .then((isOrgWhiteLabelled: boolean) => {
          defer.resolve(isOrgWhiteLabelled);
        });
    } else {
      defer.resolve(false);
    }

    return defer.promise;
  }

  setBrokerOrgAdmin(brokerOrgId: number, organisationAdminId: string) {
    let defer = this.$q.defer<ApplicationUserDTO>();
    this.$http
      .get(
        this.$baseAddress +
        "/setBrokerOrgAdmin?brokerOrgId=" +
        brokerOrgId +
        "&organisationAdminId=" +
        organisationAdminId,
      )
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /** get the broker organisation for the current user */
  getBrokerOrganisationId(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    var organisationId: number;

    if ((this.$rootScope as any).currentUser) {
      var currentUser = (this.$rootScope as any).currentUser;

      if (currentUser.OrganisationId) {
        organisationId = currentUser.OrganisationId;
      } else {
        organisationId = currentUser.OrganisationReferralId;
      }

      defer.resolve(organisationId);
    } else {
      this.getcurentuserrecord()
        .then((currentUser: ApplicationUserDTO) => {
          if (currentUser.OrganisationId) {
            organisationId = currentUser.OrganisationId;
          } else {
            organisationId = currentUser.OrganisationReferralId;
          }

          defer.resolve(organisationId);
        })
        .catch((response) => {
          defer.reject(response);
        });
    }

    return defer.promise;
  }

  addUpdateWithOrgUniqueRef(
    userDto: ApplicationUserDTO,
  ): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();
    this.$http
      .post(this.$baseAddress, userDto)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  GetOrganisationAdminUserId(orgId: number): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get(this.$baseAddress + "/getorganisationadminuserid")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getLenderUsers(searchTerm: string): ng.IPromise<ApplicationUserDTO[]> {
    let defer = this.$q.defer<ApplicationUserDTO[]>();
    this.$http
      .get(this.$baseAddress + "/getlenderusers?searchterm=" + searchTerm)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  checkEmailExists(email: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodedEmail = encodeURIComponent(email);
    this.$http
      .get(this.$baseAddress + "/checkemailexists?email=" + encodedEmail)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  registerBrokerUsers(
    newBorkerUsers: ApplicationUserDTO[],
    selectedUserName: string = "",
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    var selectedUserName = encodeURIComponent(selectedUserName);
    this.$http
      .post(
        this.$baseAddress +
        "/registerbrokerusers?selectedUserName=" +
        selectedUserName,
        JSON.stringify(newBorkerUsers),
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  updateSubscriptionStatus(
    email: string,
  ): ng.IPromise<LicenseMasterStatusEnum> {
    let defer = this.$q.defer<LicenseMasterStatusEnum>();
    var encodedEmail = encodeURIComponent(email);
    this.$http
      .get(
        this.$baseAddress +
        "/getsubscriptionstatusfromuser?email=" +
        encodedEmail,
      )
      .then((response) => {
        defer.resolve(response.data as LicenseMasterStatusEnum);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getOrganisationNonAdminUsers(
    orgId: number,
  ): ng.IPromise<ApplicationUserDTO[]> {
    let defer = this.$q.defer<ApplicationUserDTO[]>();
    this.$http
      .get(this.$baseAddress + "/getorganisationnonadminusers?orgId=" + orgId)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getAllOrganisationMembers(orgId: number): ng.IPromise<ApplicationUserDTO[]> {
    let defer = this.$q.defer<ApplicationUserDTO[]>();
    this.$http
      .get(this.$baseAddress + "/getallorganisationmembers?orgId=" + orgId)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  assigTrialLicenseToUser(
    products: AppPackagePricingDTO[],
    licenseEndDate: Date,
    selectedUserId,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var licenseEndDateString = null;
    if (licenseEndDate != null) {
      var templicenseEndDate = new Date();
      templicenseEndDate.setHours(0, 0, 0, 0);
      var licenseEndDateUtc = new Date(
        templicenseEndDate.setUTCFullYear(
          licenseEndDate.getFullYear(),
          licenseEndDate.getMonth(),
          licenseEndDate.getDate(),
        ),
      );
      licenseEndDateString = licenseEndDateUtc.getTime();
    }
    this.$http
      .post(
        this.$baseAddress +
        "/assigntriallicensetouser?licenseEndDate=" +
        licenseEndDateString +
        "&selectedUserId=" +
        selectedUserId,
        JSON.stringify(products),
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getLicenseStatusText(selectedUserName: string): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    var licenseEndDateString = null;
    var encodeUserName = encodeURIComponent(selectedUserName);
    this.$http
      .get(
        this.$baseAddress +
        "/getlicensestatustext?selectedUserName=" +
        encodeUserName,
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getUsersBelongToBrokerOrAdmin(): ng.IPromise<ApplicationUserDTO[]> {
    let defer = this.$q.defer<ApplicationUserDTO[]>();
    this.$http
      .get(this.$baseAddress + "/getusersbelongtobrokeroradmin")
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /** gets a list of user summaries for the current user (broker or admin)  */
  getUserSummariesForBrokerOrAdmin(): ng.IPromise<UserSimpleDTO[]> {
    let defer = this.$q.defer<UserSimpleDTO[]>();
    this.$http
      .get(this.$baseAddress + "/getusersummariesforbrokeroradmin")
      .then((response) => {
        defer.resolve(response.data as UserSimpleDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getUserByUserId(userId): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();
    this.$http
      .get(this.$baseAddress + "/getuserbyuserid?userId=" + userId)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendTestEmail(
    recipientEmailAddress: string,
    emailSubject: string,
    emailMessage: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
        "/sendtestemail?recipientEmailAddress=" +
        recipientEmailAddress +
        "&emailSubject=" +
        emailSubject +
        "&emailMessage=" +
        emailMessage,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendEventToHubspot(
    dto: ApplicationUserDTO,
    page: string = "",
    login: boolean = false,
    orgCode: string = "",
    role: string = "",
    organisationLinkId: number = 0,
    productType: ProductTypeEnum = ProductTypeEnum.None,
    dealId: number = 0,
    developmentInputwithNoLoginUniqueRef: string = "",
    clientId: string = "",
  ) {
    if (dto === null) {
      return;
    }

    let defer = this.$q.defer<ApplicationUserDTO>();
    let data = {
      dto: dto,
      page: page,
      login: login,
      orgCode: orgCode,
      role: role,
      organisationLinkId: organisationLinkId,
      productType: productType,
      dealId: dealId,
      developmentInputwithNoLoginUniqueRef:
        developmentInputwithNoLoginUniqueRef,
      clientId: clientId,
    };
    this.$http
      .post(this.$baseAddress + "/sendeventtohubspot", data)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  checkUserNameExists(userName: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodedUserName = encodeURIComponent(userName);
    this.$http
      .get(
        this.$baseAddress + "/checkusernameexists?username=" + encodedUserName,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getUserDetailsByEmailIfExists(
    email: string,
  ): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();
    var encodedEmail = encodeURIComponent(email);
    this.$http
      .get(
        this.$baseAddress +
        "/getuserdetailsdyemailifexists?email=" +
        encodedEmail,
      )
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getUsersByLenderId(lenderId: number): ng.IPromise<ApplicationUserDTO[]> {
    let defer = this.$q.defer<ApplicationUserDTO[]>();
    this.$http
      .get(this.$baseAddress + "/getusersbylenderid?lenderId=" + lenderId)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getLenderUsersUnassigned(
    searchTerm: string,
  ): ng.IPromise<ApplicationUserDTO[]> {
    let defer = this.$q.defer<ApplicationUserDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
        "/getlenderusersunassigned?searchterm=" +
        searchTerm,
      )
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  setLenderAsAdmin(userId: string): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();
    this.$http
      .get(this.$baseAddress + "/setlenderasadmin?userId=" + userId)
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveLenderUser(
    lenderUser: ApplicationUserDTO,
    isSavingLenderUser: boolean = false,
  ): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();
    this.$http
      .post(
        this.$baseAddress +
        "/savelenderuser?isSavingLenderUser=" +
        isSavingLenderUser,
        lenderUser,
      )
      .then((response) => {
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  removeLenderUser(lenderUserId: string): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();
    this.$http.get(this.$baseAddress + "/removelenderuser?lenderUserId=" + lenderUserId).then((response) => {
      defer.resolve(response.data as ApplicationUserDTO);
    }).catch((response) => {
      defer.reject(response);
    });
    return defer.promise;
  }

  getCurrentUserLender(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(this.$baseAddress + "/getcurrentuserlender")
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getUserAutofill(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(this.$baseAddress + "/getcurrentuserautofill")
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getOrganisationAdminEmail(orgId: number): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get(this.$baseAddress + "/getorganisationadminemail?orgId=" + orgId)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getLenderAppraisals() {
    let defer = this.$q.defer<LenderAppraisal[]>();
    this.$http
      .get(this.$baseAddress + "/getlenderappraisals")
      .then((response) => {
        defer.resolve(response.data as LenderAppraisal[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  clearSelectedNavMenuAndFilterStorageData() {
    sessionStorage.removeItem("selectedNavMenu");
    sessionStorage.removeItem("clientCaseNameFilter");
    sessionStorage.removeItem("statusFilter");
  }

  unSubscribeFromEmail(emailCode: string, userId: string) {
    let defer = this.$q.defer<boolean>();
    this.$http.get(this.$baseAddress + "/unsubscribefromemail?emailCode=" + emailCode + "&userId=" + userId).then((response) => {
      defer.resolve(response.data as boolean);
    }).catch((response) => {
      defer.reject(response);
    });
    return defer.promise;
  }

  getCurrentUserPermissionForProductSearch() {
    let defer = this.$q.defer<GetCurrentUserPermissionForProductSearchResponse>();
    this.$http.get(this.$baseAddress + "/getcurrentuserpermissionforproductsearch").then((response) => {
      defer.resolve(response.data as GetCurrentUserPermissionForProductSearchResponse);
    }).catch((response) => {
      defer.reject(response);
    });
    return defer.promise;
  }

  sendTestEmailToUsers() {
    let defer = this.$q.defer<boolean>();
    this.$http.get(this.$baseAddress + "/sendtestemailtousers").then((response) => {
      defer.resolve(response.data as boolean);
    }).catch((response) => {
      defer.reject(response);
    });
    return defer.promise;
  }

  getCurrentUserGotEnterpriseLicense() {
    let defer = this.$q.defer<boolean>();
    this.$http.get(this.$baseAddress + "/hascurrentusergotenterpriselicense").then((response) => {
      defer.resolve(response.data as boolean);
    }).catch((response) => {
      defer.reject(response);
    });
    return defer.promise;
  }

}

import { LenderService } from "@js/services/LenderService";

export class AssistanceSliderComponentController {

    public assistanceSlider: boolean = false;
    public accordionShow: boolean[] = [];
    public applicationName: string;
    public totalLenderCount: number;

    constructor(public $location, private lenderService: LenderService) {
        this.lenderService.getTotalLenders().then((result: number) => {
            this.totalLenderCount = result;
        });
    }

    public toggleAccordion(id: number) {
        if (!this.accordionShow[id]) {
            this.accordionShow[id] = true;
        } else {
            this.accordionShow[id] = !this.accordionShow[id];
        }

    }

    public getApplicationName() {
        let lsd = sessionStorage.getItem('applicationName');
        if (lsd != null) {
            this.applicationName = lsd;
        } else {
            this.applicationName = 'Brickflow';
        }
    }

}

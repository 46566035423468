export class CcqFileUploadComponent implements ng.IComponentOptions {

    public transclude: boolean = true;

    public bindings: any;
    public controller: any;
    public templateUrl: string;

    constructor() {
        this.bindings = {
            maxSize: '@', // "10MB"
            fileType: '@', // "*.xlsx"
            uploadRequestUrl: '@', // "/api/fileattachment/fileuploaduri"
            uploadComplete: '&',
            folderPath: '@'

        };
        this.controller = CcqFileUploadController;
        this.templateUrl = 'js/dist/components/fileupload/fileupload.html';
    }
}

export class CcqFileUploadController {
    public maxSize: string;
    public fileType: string;
    public folderPath: string;
    public uploadRequestUrl: string = '/api/fileattachment/fileuploaduri';
    public file: Blob;
    public uploadComplete: (string) => ng.IPromise<string>;

    public uploading: boolean;

    public error: boolean;


    static $inject = ['$scope', '$http'];
    constructor(private $scope: ng.IScope, public $http: ng.IHttpService) {

    }

    uploadFile(file: File) {
        this.uploading = true;
        if (!file) {
            return;
        }

        this.$http.get(this.uploadRequestUrl, { params: { fileName: this.folderPath  + file.name, fileType: file.type } }).then((response) => {

            this.$http.put(response.data as string, file, { headers: { 'Content-Type': file.type } })
                .then((response) => {
                    this.uploadComplete({ filename: file.name });
                })
                .catch((response) => {

                }).finally(() => {
                    this.uploading = false;
                });
        });
    };

}

import angular from "angular";
import "angular-animate";
import "angular-cookies";
import "angular-resource";
import "angular-route";
import "angular-sanitize";
import "tinymce";
import "angular-ui-tinymce";
import "angular-utils-pagination";
import "angularjs-toaster";
import "isteven-angular-multiselect/isteven-multi-select.js";
import "ng-file-upload";
import { authInterceptor } from "./filesfromccqbase/AuthInterceptor";
import { BroadcastService } from "./filesfromccqbase/BroadcastService";
import {
  instantiateCcqDirectives,
  IpdfdownlaodScope,
} from "./filesfromccqbase/CcqDirectives";
import "./libs/date";
import { registerCCQBaseComponents } from "./registerCCQBaseComponents";
import { registerComponents } from "./registerComponents";
import { registerControllers } from "./registerControllers";
import { registerReactComponents } from "./registerReactComponents";
import { registerServices } from "./registerServices";
import { registerRoutes } from "./routes";

import "angularjs-toaster/toaster.min.css";
import "isteven-angular-multiselect/isteven-multi-select.css";
import "../css/styles.scss";

// The ccqapp module depends on the Ccq.directives module
// so we must instantiate it first
instantiateCcqDirectives();

const ccqapp = angular.module("ccqapp", [
  "Ccq.directives",
  "ngResource",
  "ngSanitize",
  "ngRoute",
  "ngCookies",
  "ngAnimate",
  "ngFileUpload",
  "toaster",
  "angularUtils.directives.dirPagination",
  "ui.tinymce",
  "isteven-multi-select",
]);

registerRoutes();
registerCCQBaseComponents();
registerServices();
registerControllers();
registerComponents();
registerReactComponents();

angular
  .module("ccqapp")
  .config(
    (
      $httpProvider: ng.IHttpProvider,
      $cookiesProvider: ng.cookies.ICookiesProvider,
    ) => {
      $cookiesProvider.defaults = {
        secure: true,
        samesite: "none",
      };
      // Add our authInterceptor to the http provider array of interceptors
      $httpProvider.interceptors.push("authInterceptor");
    },
  );

//Create our auth interceptor as an angular factory
ccqapp.factory("authInterceptor", authInterceptor);
ccqapp.service("BroadcastService", BroadcastService);

//app.config(['bugsnagProvider', function (bugsnagProvider) {
//    bugsnagProvider
//        .noConflict()
//        .apiKey('a080f8c3ebc0accbdbb5b5d7ea742f71')
//        .releaseStage('release')
//        .appVersion('0.1.0')
//        .beforeNotify(['$log', function ($log) {
//            return function (error, metaData) {
//                $log.debug(error.name);
//                return true;
//            };
//        }]);
//}]);

function convertDateStringsToDates(input: any, toLocal: boolean): any {
  // Ignore things that aren't objects.
  if (typeof input !== "object") return input;

  for (var key in input) {
    if (!input.hasOwnProperty(key)) continue;

    var value = input[key];
    // Check for string properties which look like dates.
    if (
      typeof value === "string" &&
      value.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})/)
    ) {
      input[key] = new Date(value + "Z");
      if (Object.prototype.toString.call(input[key]) === "[object Date]") {
        // it is a date
        if (isNaN(input[key].getTime())) {
          // d.valueOf() could also work
          input[key] = value;
        }
      } else {
        // not a date
        input[key] = value;
      }
    } else if (typeof value === "object") {
      // Recurse into object
      convertDateStringsToDates(value, toLocal);
    }
  }
  return input;
}

ccqapp.config([
  "$httpProvider",
  function ($httpProvider: ng.IHttpProvider) {
    $httpProvider.interceptors.push(function () {
      return {
        request: function (request) {
          //return convertDateStringsToDates(request, false);
          // We're not modifying anything sent to the server.
          return request;
        },

        response: function (response) {
          // Modify anything received from the server to local timezone.
          return convertDateStringsToDates(response, true);
          //return response;
        },
      };
    });
  },
]);

ccqapp.config(function ($sceDelegateProvider) {
  $sceDelegateProvider.resourceUrlWhitelist([
    "self",
    "https://www.youtube.com/**",
  ]);
});

ccqapp.filter("nospaces", function () {
  return function (text) {
    if (text === null || text === undefined) {
      return null;
    }

    return text.replace(/\s+/g, "");
  };
});

//this is for formatting labels - e.g. td>{{ myValue | percentage: 2 ...
ccqapp.filter("percentage", [
  "$filter",
  function ($filter) {
    return function (input, decimals) {
      input = parseFloat(input);
      return $filter("number")(input ? input * 100 : 0, decimals);
    };
  },
]);

ccqapp.filter("convertBoolToYesNo", function () {
  return function (input) {
    return input ? "Yes" : "No";
  };
});

ccqapp.filter("startFrom", function () {
  return function (input, start) {
    start = +start; //parse to int
    return input.slice(start);
  };
});

ccqapp.directive("focusOnMax", function () {
  return {
    restrict: "A",
    link: function (scope, element, attr) {
      element.bind("keyup", function (e) {
        if (
          (e.target as HTMLInputElement).maxLength ===
          (e.target as HTMLInputElement).value.length
        ) {
          document.getElementById(attr.focusOnMax).focus();
        }
      });
      event.preventDefault();
    },
  };
});

ccqapp.directive("toolTip", function () {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      var x, y;
      element.on("mousemove", function (e) {
        (x = e.clientX), (y = e.clientY);
        this.children[0].style.top = y - 15 + "px";
        this.children[0].style.left = x + 30 + "px";
      });
    },
  };
});

ccqapp.directive("form", function () {
  return function (scope, el, attrs) {
    el.bind("keydown", function (event) {
      if (13 == event.which && event.target.nodeName != "TEXTAREA") {
        event.preventDefault(); // Some mobile browsers...
        window.stop(); // Everything else except IE...
        document.execCommand("Stop"); // IE
        return false;
      }
    });
  };
});

//ccqapp.directive('inViewport', function ($window) {
//    return {
//        scope: {
//            inViewport: '@'
//        },
//        link: function (scope, element: any, attrs) {
//            angular.element($window).bind("scroll", function (e) {
//                var elementTop = angular.element(element).offset().top;
//                var elementBottom = elementTop + $(element).outerHeight();
//                var viewportTop = $($window).scrollTop();
//                var viewportBottom = viewportTop + $($window).height();
//                return elementBottom > viewportTop && elementTop < viewportBottom;
//            })
//        }
//    };
//});

//this is for input boxes - e.g. <input type="number" percentage ...
ccqapp.directive("percentage", function () {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, element, attr, ngModel) {
      function fromUser(percAsWholeNumber: number) {
        return (percAsWholeNumber / 100).toFixed(4);
      }

      function toUser(percAsDecimal) {
        var x = humaniseNumber(percAsDecimal * 100);
        var y = parseFloat(x);
        return y;
      }
      function humaniseNumber(x) {
        return x.toFixed(6).replace(/\.?0*$/, "");
      }
      (ngModel as any).$parsers.push(fromUser);
      (ngModel as any).$formatters.push(toUser);
    },
  };
});

ccqapp.directive("isActiveFormRow", function ($compile, $parse) {
  return {
    restrict: "A",
    priority: 600,
    replace: true,
    compile: function (element, attr) {
      var $elem = angular.element(element);
      $elem.attr(
        "ng-class",
        "{'input-row-highlight': " +
          (attr as any).activeBinding +
          " === '" +
          (attr as any).isActiveFormRow +
          "'}",
      );
      $elem.attr(
        "ng-mouseover",
        (attr as any).activeBinding +
          " = '" +
          (attr as any).isActiveFormRow +
          "'",
      );
      $elem.removeAttr("is-active-form-row");
      $elem.removeAttr("active-binding");
      return {
        pre: function (scope, element, attr, controller, transcludeFn) {},
        post: function (scope, element, attributes, controller, transcludeFn) {
          $compile(element)(scope);
        },
      };
    },
  };
});

ccqapp.directive("formOnChange", function ($parse) {
  return {
    require: "form",
    link: function (scope, element, attrs) {
      var cb = $parse(attrs.formOnChange);
      element.on("change", function () {
        cb(scope);
      });
    },
  };
});

ccqapp.directive("bfPdfDownload", function (toaster) {
  return {
    restrict: "E",
    templateUrl: "/views/partials/pdfdownload.html",
    scope: true,
    link: function (scope: IpdfdownlaodScope, element, attr) {
      var anchor = element.children()[0];
      scope.name = attr.controlname;
      scope.icon = attr.icon;

      var toastId = null;

      scope.$on("download-init", function () {
        toaster.pop({
          type: "info",
          title: "Preparing download",
          body: "The download is starting, please wait...",
          timeout: 0,
        });
      });

      scope.$on("download-start", function () {
        toaster.clear(toastId);
        toastId = null;
      });

      // When the download finishes, attach the data to the link. and init the click event
      scope.$on("downloaded", function (event, data, filename) {
        var fileURL = window.URL.createObjectURL(data);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = fileURL;
        if (attr.filename && attr.filename.length > 0) {
          a.download = attr.filename;
        } else {
          a.download = filename;
        }
        a.click();
      });
    },
    controller: [
      "$scope",
      "$attrs",
      "$http",
      function ($scope, $attrs, $http) {
        $scope.downloadPdf = () => {
          $scope.$emit("download-init");
          if ($attrs.body) {
            //var body = JSON.stringify($attrs.body)
            $http
              .put($attrs.url, $attrs.body, {
                responseType: "arraybuffer",
              })
              .then((response: any) => {
                $scope.$emit("download-start");
                var filename = "";
                var disposition = response.headers()["content-disposition"];
                if (disposition && disposition.indexOf("attachment") !== -1) {
                  var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  var matches = filenameRegex.exec(disposition);
                  if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                  }
                }
                var dispostype = response.headers()["content-type"];
                if (!dispostype) {
                  dispostype = "octet/stream";
                }
                var blob = new Blob([response.data], { type: dispostype });
                $scope.$emit("downloaded", blob, filename);
              })
              .catch((error) => {
                console.error("Error in PUT request:", error);
              });
          } else {
            $http
              .get($attrs.url, {
                responseType: "arraybuffer",
              })
              .then((response: any) => {
                $scope.$emit("download-start");
                var filename = "";
                var disposition = response.headers()["content-disposition"];
                if (disposition && disposition.indexOf("attachment") !== -1) {
                  var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  var matches = filenameRegex.exec(disposition);
                  if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                  }
                }
                var dispostype = response.headers()["content-type"];
                if (!dispostype) {
                  dispostype = "octet/stream";
                }
                var blob = new Blob([response.data], { type: dispostype });
                $scope.$emit("downloaded", blob, filename);
              })
              .catch((error) => {
                console.error("Error in GET request:", error);
              });
          }
        };
      },
    ],
  };
});

ccqapp.directive("autoGrow", function () {
  return function (scope, element, attrs) {
    element.on("input", function () {
      this.style.height = "auto";
      this.style.height = this.scrollHeight + "px";
    });
  };
});


ccqapp.directive('greaterThanZero', function () {
  return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
          ngModel.$validators.greaterThanZero = function (modelValue, viewValue) {
              const value = modelValue || viewValue;
              return Number(value) > 0;
          };
      }
  };
});

declare global {
  interface String {
    isNumber(): boolean;
    startsWith(needle: string): boolean;
  }
  interface Object {
    watch(prop: PropertyDescriptor, handler: Function): Object;
    unwatch(prop: PropertyDescriptor): any;
  }

  interface Array<T> {
    mapProperty(property: PropertyDescriptor): any;
  }

  interface Array<T> {
    find(
      predicate: (value: T, index: number, obj: Array<T>) => boolean,
      thisArg?: any,
    ): T | undefined;
  }
}

String.prototype.isNumber = function () {
  return /^\d+$/.test(this);
};

String.prototype.startsWith = function (needle: string) {
  return this.indexOf(needle) == 0;
};

// object.watch
if (!Object.prototype.watch) {
  Object.defineProperty(Object.prototype, "watch", {
    enumerable: false,
    configurable: true,
    writable: false,
    value: function (prop, handler) {
      var oldval = this[prop],
        newval = oldval,
        getter = function () {
          return newval;
        },
        setter = function (val) {
          oldval = newval;
          newval = handler.call(this, prop, oldval, val);
          return newval;
        };
      if (delete this[prop]) {
        // can't watch constants
        Object.defineProperty(this, prop, {
          get: getter,
          set: setter,
          enumerable: true,
          configurable: true,
        });
      }
    },
  });
}

// object.unwatch
if (!Object.prototype.unwatch) {
  Object.defineProperty(Object.prototype, "unwatch", {
    enumerable: false,
    configurable: true,
    writable: false,
    value: function (prop) {
      var val = this[prop];
      delete this[prop]; // remove accessors
      this[prop] = val;
    },
  });
}

Array.prototype.mapProperty = function (property: any) {
  return this.map(function (obj: any) {
    return obj[property];
  });
};

if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function (predicate: any) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
  });
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default ccqapp;

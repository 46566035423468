import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { UserService } from "@js/services/UserService";

export class DevelopmentInputController {
  selectedSection: string;

  objects: DevelopmentInputDTO[];
  selectedObject: DevelopmentInputDTO = {} as DevelopmentInputDTO;

  fetchingObjects: boolean;
  fetchingObject: boolean;

  userRecords: ApplicationUserDTO[];

  DevelopmentInputForm: ng.IFormController;
  //subDevelopmentInputForm: ng.IFormController;

  static $inject = ["$routeParams", "DevelopmentInputService", "UserService"];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private $applicationuserservice: UserService,
  ) {
    //Is search related to a saved search?

    if (this.$routeParams.searchId) {
      this.$DevelopmentInputservice
        .fetch(parseInt(this.$routeParams.searchId))
        .then((object) => {
          this.selectedObject = object;
        });
    } else {
      //Unsaved search. Use $rootScope
    }
  }

  save() {
    this.$DevelopmentInputservice
      .addUpdatereturnonlyid(this.selectedObject)
      .then((response) => {
        this.selectedObject.Id = response;
        if (!this.objects) {
          this.objects = [];
        }

        let matches: DevelopmentInputDTO[] = this.objects.filter(
          (value, index) => {
            return value.Id == response;
          },
        );

        if (!matches || matches.length === 0) {
          this.objects.push(this.selectedObject);
        }

        this.DevelopmentInputForm.$setPristine();
      });
  }

  delete() {
    this.$DevelopmentInputservice
      .delete(this.selectedObject.Id)
      .then((response) => {
        if (response) {
          this.objects.splice(this.objects.indexOf(this.selectedObject), 1);
          this.DevelopmentInputForm.$setPristine();
          delete this.selectedObject;
        }
      });
  }
}

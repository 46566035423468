import { LenderDTO } from "@js/DTO/LenderDTO.cs.d";
import { ProductDTO } from "@js/DTO/ProductDTO.cs.d";
import { LenderService } from "@js/services/LenderService";
import { ProductService } from "@js/services/ProductService";

export class ProductController {
  selectedSection: string;

  objects: ProductDTO[];
  selectedObject: ProductDTO;

  fetchingObjects: boolean;
  fetchingObject: boolean;

  lenderRecords: LenderDTO[];

  //addingSubobject: boolean;

  //subobjects: SubobjectDTO[];
  //selectedSubobject: SubobjectDTO;

  //fetchingSubobjects: boolean;
  //fetchingSubobject: boolean;

  productForm: ng.IFormController;
  //subproductForm: ng.IFormController;

  static $inject = [
    "$routeParams",
    "ProductService",
    "LenderService",

    //'SubobjectService',
  ];
  //private $subobjectservice: App.Services.SubobjectService
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $productservice: ProductService,
    private $lenderservice: LenderService,
  ) {
    this.updateObjects();
  }

  updateObjects() {
    this.fetchingObjects = true;

    // Fetch all objects.
    this.$productservice
      .fetchAll()
      .then((response) => {
        this.objects = response;
        this.preSelectedItem();
      })
      .finally(() => {
        this.fetchingObjects = false;
      });
  }

  private preSelectedItem() {
    if (this.$routeParams.Id) {
      let selectedObject = this.objects.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.objectId);
      });

      this.selectObject(selectedObject);
    }
  }

  selectObject(object: ProductDTO) {
    this.selectedObject = object;

    //this.fetchingSubobjects = true;
    //this.$subobjectservice.fetchSubobjectsForObject(object.Id).then((response) => {
    //    this.subobjects = response;
    //    this.fetchingSubobjects = false;
    //}).catch((response) => {
    //    this.fetchingSubobjects = false;
    //});

    this.selectedSection = "details";
  }

  createObject() {
    this.selectedSection = "details";
    this.selectedObject = {} as ProductDTO;
  }

  save() {
    this.$productservice
      .addUpdatereturnonlyid(this.selectedObject)
      .then((response) => {
        this.selectedObject.Id = response;
        if (!this.objects) {
          this.objects = [];
        }

        let matches: ProductDTO[] = this.objects.filter((value, index) => {
          return value.Id == response;
        });

        if (!matches || matches.length === 0) {
          this.objects.push(this.selectedObject);
        }

        this.productForm.$setPristine();
        this.updateObjects();
      });
  }

  delete() {
    this.$productservice.delete(this.selectedObject.Id).then((response) => {
      if (response) {
        this.objects.splice(this.objects.indexOf(this.selectedObject), 1);
        this.productForm.$setPristine();
        delete this.selectedObject;
      }
    });
  }

  getlenders() {
    this.$lenderservice
      .fetchAll()
      .then((response) => {
        this.lenderRecords = response;
      })
      .finally(() => {});
  }

  //addSubobject() {
  //    this.selectedSubobject = {
  //        AssociatedObjectId: this.selectedObject.Id,
  //        AssociatedObjectName: this.selectedObject.Name
  //    } as SubobjectDTO;
  //}

  //removeSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.deleteSubobject(subobject.Id).then((response) => {
  //        this.subobjects.splice(this.subobjects.indexOf(this.selectedSubobject), 1);
  //        delete this.selectedSubobject;
  //    });
  //}

  //cancelUpdateSubobject() {
  //    delete this.selectedSubobject;
  //}

  //updateSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.addUpdateSubobject(subobject).then((response) => {
  //        if (this.subobjects.indexOf(subobject) === -1) {
  //            this.subobjects.push(subobject);
  //        }
  //        delete this.selectedSubobject;
  //    }).catch((response) => {
  //        delete this.selectedSubobject;
  //    });

  //}
}

export const enum LocationEnum {
  None = 0,
  London = 1,
  NorthWest = 2,
  Midlands = 4,
  EastofEngland = 8,
  SouthEast = 16,
  SouthWest = 32,
  NorthEast = 64,
  Wales = 128,
  Scotland = 256,
  NorthernIreland = 512,
  ChannelIsland = 1024,
  Any,
}

import { AssistanceSliderComponentController } from "../components/assistance-slider/assistance-slider";
import { BreadcrumbTabsComponentController } from "../components/breadcrumb-tabs/breadcrumb-tabs";
import { CarouselComponentController } from "../components/carousel/carousel";
import { ModalPopupComponentController } from "../components/modal-popup/modal-popup";
import { PaymentToggleController } from "../components/payment-toggle/payment-toggle";
import { RegInfoPanelController } from "../components/registration-info-panel/reg-info-panel";
import { SpinnerController } from "../components/spinner/spinner";
import { TourPopupComponentController } from "../components/tour-popup/tour-popup";
import { VerticalProgressBarController } from "../components/vertical-progress-bar/vertical-progress-bar";
import { BridgingCriteriaController } from "./controllers/BridgingCriteriaController";
import { CommercialCriteriaController } from "./controllers/CommercialCriteriaController";
import { DevFinanceCriteriaController } from "./controllers/DevFinanceCriteriaController";

export function registerComponents() {
  angular
    .module("ccqapp")
    .component("assistanceSlider", {
      bindings: {},
      controller: [
        "$location",
        "LenderService",
        AssistanceSliderComponentController,
      ],
      templateUrl: "components/assistance-slider/assistance-slider.html",
      transclude: true,
    })
    .component("breadcrumbTabs", {
      bindings: {
        toggleModal: "=",
        loading: "=",
      },
      controller: ["$window", BreadcrumbTabsComponentController],
      templateUrl: "components/breadcrumb-tabs/breadcrumb-tabs.html",
      transclude: true,
    })
    .component("carousel", {
      bindings: {
        carouselData: "<",
        carouselStep: "<",
      },
      controller: ["$interval", CarouselComponentController],
      templateUrl: "components/carousel/carousel.html",
      transclude: true,
    })
    .component("modalPopup", {
      bindings: {
        toggleModal: "=",
        loading: "=",
        formSectionNames: "<",
        step: "=",
        closeOnBlur: "=",
      },
      controller: ["$element", "$scope", ModalPopupComponentController],
      templateUrl: "components/modal-popup/modal-popup.html",
      transclude: true,
    })
    .component("paymentToggle", {
      bindings: {
        ngModel: "=",
        ngChange: "&",
      },
      controller: ["$rootScope", "$element", PaymentToggleController],
      templateUrl: "components/payment-toggle/payment-toggle.html",
      transclude: true,
    })
    .component("regInfoPanel", {
      bindings: {
        heading: "@",
        subheading: "@",
        mainheading: "@",
      },
      controller: ["$rootScope", "$element", RegInfoPanelController],
      templateUrl: "components/registration-info-panel/reg-info-panel.html",
      transclude: true,
    })
    .component("spinner", {
      bindings: {
        minValue: "@",
        maxValue: "@",
        value: "=",
      },
      controller: ["$rootScope", "$element", SpinnerController],
      templateUrl: "components/spinner/spinner.html",
      transclude: true,
    })
    .component("tourPopup", {
      bindings: {
        tourOrder: "@",
        tourState: "<",
        stepEnabled: "<",
      },
      controller: ["$rootScope", "$element", TourPopupComponentController],
      templateUrl: "components/tour-popup/tour-popup.html",
      transclude: true,
    })
    .component("verticalProgressBar", {
      bindings: {
        nodes: "=",
        currentStep: "@",
      },
      controller: ["$rootScope", "$element", VerticalProgressBarController],
      templateUrl:
        "components/vertical-progress-bar/vertical-progress-bar.html",
      transclude: true,
    })
    .component("bridgingCriteria", {
      controller: BridgingCriteriaController,
      templateUrl: "components/bridging-criteria/bridging-criteria.html",
      transclude: true,
      controllerAs: "ctrl",
    })
    .component("commercialCriteria", {
      controller: CommercialCriteriaController,
      templateUrl: "components/commercial-criteria/commercial-criteria.html",
      transclude: true,
      controllerAs: "ctrl",
    })
    .component("devfinanceCriteria", {
      controller: DevFinanceCriteriaController,
      templateUrl: "components/devfinance-criteria/devfinance-criteria.html",
      transclude: true,
      controllerAs: "ctrl",
    });
}

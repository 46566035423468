export const enum ALItemTypeEnum {
  Other = 0,
  ResidentialProperty = 1,
  BTLProperty = 2,
  CommercialProperty = 3,
  OverseasProperty = 4,
  CashDeposits = 5,
  StocksShares = 6,
  VehicleBoat = 7,
  BusinessEquipment = 8,
  ArtJewellryAntique = 9,
  ResidentialPropertyLoan = 30,
  BTLPropertyLoan = 31,
  CommercialPropertyLoan = 32,
  OverseasPropertyLoan = 33,
  HPLease = 34,
  Overdraft = 35,
  OtherLoan = 36,
  PersonalGuarantee = 60,
}

import { DeveloperExitFinanceInputSetDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceInputSetDTO.cs.d";
import { DeveloperExitFinanceResultDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceResultDTO.cs.d";
import { DeveloperExitFinanceResultSetDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceResultSetDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class DeveloperExitFinanceResultService extends BaseService<
  DeveloperExitFinanceResultDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/developerexitfinanceresult";
    this.$broadcastBusinessNameSingular = "developerexitfinanceresult";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchMatchingResults(
    criteria: DeveloperExitFinanceInputSetDTO,
    includeSenior: boolean,
    includeMez: boolean,
    totalNumberOfEachResults: number,
    sendResult: boolean,
    message: string,
    from: string,
    telephone: string,
    loanLabel: string,
  ): ng.IPromise<DeveloperExitFinanceResultSetDTO> {
    let defer = this.$q.defer<DeveloperExitFinanceResultSetDTO>();
    this.$http
      .put(
        this.$baseAddress +
          "/listlargestandmatchingset?includeSenior=" +
          includeSenior +
          "&includeMez=" +
          includeMez +
          "&totalNumberOfEachResults=" +
          totalNumberOfEachResults +
          "&sendResult=" +
          sendResult +
          "&message=" +
          message +
          "&from=" +
          from +
          "&telephone=" +
          telephone +
          "&loanLabel=" +
          loanLabel,
        JSON.stringify(criteria),
      )
      .then((response) => {
        let results: DeveloperExitFinanceResultSetDTO =
          response.data as DeveloperExitFinanceResultSetDTO;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

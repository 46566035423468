import { CaseChangedMessageDTO } from "@js/DTO/CaseChangedMessageDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseNoteDTO } from "@js/DTO/CaseNoteDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import {
  AssignLenderToDealRequest,
  AssignLenderToDealResponse,
} from "@js/DTO/Messages/AssignLenderToDealMessage.cs.d";
import { LenderInfo } from "@js/DTO/Messages/Deal/LendersInfoForSubmitToLenderMessage.cs.d";
import { NewBlankCaseDTO } from "@js/DTO/NewBlankCaseDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { DevelopmentInputService } from "./DevelopmentInputService";
import { RoleService } from "./RoleService";
import { UserService } from "./UserService";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";

export class CaseService extends BaseService<CaseDTO, number> {
  private caseStatusEnum = {
    Search: undefined,
    NewCase: 0,
    InProgress: 1,
    UnderReview: 2,
    ReadyToSubmit: 3,
    SubmittedToLendersForHoT: 4,
    PaymentReceived: 8,
    ReadyToReSubmit: 12,
    Applied: 5,
    CreditApproved: 10,
    WithProfessionals: 11,
    Completed: 6,
    Dormant: 7,
    SubmittedToLendersPendingReview: 9,
  };

  currentLender;
  tubeMapStatus;
  isProceed: boolean;
  task: string;
  IsSupplimentInfoFilled: boolean;

  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
    "DevelopmentInputService",
    "UserService",
    "RoleService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    private rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private $user: UserService,
    private $roleService: RoleService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/case";
    this.$broadcastBusinessNameSingular = "case";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  promotesearchtocase(
    searchId: number,
    productId: number,
    productResultList: number,
    isPrimaryApplicant: boolean = true,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.$http
      .get(
        this.$baseAddress +
        "/promotesearchtocase?searchId=" +
        searchId +
        "&productId=" +
        productId +
        "&productResultList=" +
        productResultList +
        "&isPrimaryApplicant=" +
        isPrimaryApplicant,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  promoteandmigratetodeal(
    searchId: number,
    productId: number,
    productResultList: number,
    isPrimaryApplicant: boolean = true,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.$http
      .get(
        this.$baseAddress +
        "/promoteandmigratetodeal?searchId=" +
        searchId +
        "&productId=" +
        productId +
        "&productResultList=" +
        productResultList +
        "&isPrimaryApplicant=" +
        isPrimaryApplicant,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  setProductOnCase(
    CaseId: number,
    productId: number,
    productResultList: number,
  ): ng.IPromise<Boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
        "/setproductoncase?caseId=" +
        CaseId +
        "&productId=" +
        productId +
        "&productResultList=" +
        productResultList,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  public updateCaseState(caseId: number, caseStatus: CaseStatusEnum): void {
    const caseStatusString: string = Object.keys(this.caseStatusEnum).find(
      (key) => this.caseStatusEnum[key] === caseStatus,
    );
    if (!(this.rootScope as any).caseStateChange) {
      (this.rootScope as any).caseStateChange = {};
    }
    (this.rootScope as any).caseStateChange[caseId] = caseStatusString;
    this.rootScope.$broadcast("caseStateChange", {
      id: caseId,
      status: caseStatusString,
    });
  }

  public onStateChange(caseId, callback: (caseStatus: string) => void): void {
    // TODO JNG Review using a single rootscope listener with an array of callbacks per caseId.
    this.rootScope.$on(
      "caseStateChange",
      function (event, statusUpdate: { id: number; status: string }) {
        if (statusUpdate.id === caseId) {
          callback(statusUpdate.status);
        }
      },
    );
  }

  setStatusOnCase(
    caseId: number,
    caseStatus: CaseStatusEnum,
  ): ng.IPromise<Boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
        "/setstatusoncase?caseId=" +
        caseId +
        "&caseStatus=" +
        caseStatus,
      )
      .then((response) => {
        //TODO JLH Ideally setstatusoncase should return a CaseChangedMessageDTO and we should use teh status on that to send to updateCaseState
        this.updateCaseState(caseId, caseStatus);
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  setApplyStatus(CaseId: number): ng.IPromise<CaseStatusEnum> {
    let defer = this.$q.defer<CaseStatusEnum>();

    this.$http
      .get(this.$baseAddress + "/setapplystatus?caseId=" + CaseId)
      .then((response) => {
        defer.resolve(response.data as CaseStatusEnum);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getcasefromsearch(searchId: number): ng.IPromise<CaseDTO> {
    let defer = this.$q.defer<CaseDTO>();

    this.$http
      .get(this.$baseAddress + "/getcasefromsearch?searchId=" + searchId)
      .then((response) => {
        defer.resolve(response.data as CaseDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getCaseLenderById(searchId: number): ng.IPromise<CaseDTO> {
    let defer = this.$q.defer<CaseDTO>();

    this.$http
      .get(this.$baseAddress + "/getcaselenderbyid?searchId=" + searchId)
      .then((response) => {
        defer.resolve(response.data as CaseDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  SendPreAppEmail(caseid: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(this.$baseAddress + "/sendpreappemail?caseid=" + caseid)
      .then((response) => {
        let results: boolean = response.data as boolean;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendNoteToBorrower(
    clientFirstName: string,
    clientSurname: string,
    clientEmail: string,
    note: string,
    caseId: number,
    section: number,
    suplId: number,
    DevelopmentExperienceID: number,
  ): ng.IPromise<number> {
    note = note.replace(/\n/g, "<br />");
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        this.$baseAddress +
        "/sendnote?clientFirstName=" +
        clientFirstName +
        "&clientSurname=" +
        clientSurname +
        "&clientEmail=" +
        clientEmail +
        "&Note=" +
        note +
        "&caseId=" +
        caseId +
        "&section=" +
        section +
        "&suplId=" +
        suplId +
        "&DevelopmentExperienceID=" +
        DevelopmentExperienceID,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getCurrentCase(lender) {
    this.currentLender = lender;
    return this.currentLender;
  }

  requestReview(
    caseId: number,
    confirmPhoneNumber: string,
    requestMessageReason: string,
  ) {
    let defer = this.$q.defer<CaseChangedMessageDTO>();
    this.$http
      .get(
        this.$baseAddress +
        "/requestreview?caseId=" +
        caseId +
        "&confirmPhoneNumber=" +
        confirmPhoneNumber +
        "&requestMessageReason=" +
        requestMessageReason,
      )
      .then((response) => {
        const caseChangedMessage = response.data as CaseChangedMessageDTO;
        this.updateCaseState(caseId, caseChangedMessage.CaseState);
        defer.resolve(caseChangedMessage);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  requestAdminReview(caseId: number) {
    let defer = this.$q.defer<CaseChangedMessageDTO>();
    this.$http
      .get(this.$baseAddress + "/requestAdminReview?caseId=" + caseId)
      .then((response) => {
        const caseChangedMessage = response.data as CaseChangedMessageDTO;
        this.updateCaseState(caseId, caseChangedMessage.CaseState);
        defer.resolve(caseChangedMessage);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  approve(caseId: number) {
    let defer = this.$q.defer<CaseChangedMessageDTO>();
    this.$http
      .get(this.$baseAddress + "/approve?caseId=" + caseId)
      .then((response) => {
        const caseChangedMessage = response.data as CaseChangedMessageDTO;
        this.updateCaseState(caseId, caseChangedMessage.CaseState);
        defer.resolve(caseChangedMessage);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  submitToLenders(
    caseId: number,
    brokerCommission: number,
    lendersInfo: LenderInfo[],
    brokerPhoneNumber: string,
    isBrokerPhoneNumberUpdated: boolean,
  ) {
    let defer = this.$q.defer<CaseChangedMessageDTO>();
    this.$http
      .post(
        this.$baseAddress +
        "/submittolenders?caseId=" +
        caseId +
        "&brokerCommission=" +
        brokerCommission +
        "&brokerPhoneNumber=" +
        brokerPhoneNumber +
        "&isBrokerPhoneNumberUpdated=" +
        isBrokerPhoneNumberUpdated,
        lendersInfo,
      )
      .then((response) => {
        const caseChangedMessage = response.data as CaseChangedMessageDTO;
        this.updateCaseState(caseId, caseChangedMessage.CaseState);
        defer.resolve(caseChangedMessage);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  resubmitToLenders(
    caseId: number,
    brokerCommission: number,
    lendersInfo: LenderInfo[],
    brokerPhoneNumber: string,
    isBrokerPhoneNumberUpdated: boolean,
    hasCriteriaChanged: boolean = false,
  ) {
    let defer = this.$q.defer<CaseChangedMessageDTO>();
    this.$http
      .post(
        this.$baseAddress +
        "/resubmittolenders?caseId=" +
        caseId +
        "&brokerCommission=" +
        brokerCommission +
        "&hasCriteriaChanged=" +
        hasCriteriaChanged +
        "&brokerPhoneNumber=" +
        brokerPhoneNumber +
        "&isBrokerPhoneNumberUpdated=" +
        isBrokerPhoneNumberUpdated,
        lendersInfo,
      )
      .then((response) => {
        const caseChangedMessage = response.data as CaseChangedMessageDTO;
        this.updateCaseState(caseId, caseChangedMessage.CaseState);
        defer.resolve(caseChangedMessage);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  updateTubeMap(caseStatus) {
    switch (caseStatus) {
      case CaseStatusEnum.Search:
        this.tubeMapStatus = 0;
        break;
      case CaseStatusEnum.NewCase:
        this.tubeMapStatus = 2;
        break;
      case CaseStatusEnum.InProgress:
        this.tubeMapStatus = 2;
        break;
      case CaseStatusEnum.UnderReview:
        this.tubeMapStatus = 2;
        break;
      case CaseStatusEnum.ReadyToSubmit:
        this.tubeMapStatus = 3;
        break;
      case CaseStatusEnum.SubmittedToLendersPendingReview:
        this.tubeMapStatus = 2;
        break;
      case CaseStatusEnum.SubmittedToLendersForHoT:
        this.tubeMapStatus = 4;
        break;
      case CaseStatusEnum.Applied:
        this.tubeMapStatus = 4;
        break;
      case CaseStatusEnum.CreditApproved:
        this.tubeMapStatus = 5;
        break;
      case CaseStatusEnum.WithProfessionals:
        this.tubeMapStatus = 6;
        break;
      case CaseStatusEnum.Completed:
        this.tubeMapStatus = 7;
        break;
      case CaseStatusEnum.Dormant:
        this.tubeMapStatus = 7;
        break;
      default:
        this.tubeMapStatus = 1;
    }
  }

  getTubeMap() {
    return this.tubeMapStatus;
  }

  getIsProceed() {
    return this.isProceed;
  }

  setIsProceed(isProceed) {
    this.isProceed = isProceed;
  }

  //Create a new blank case
  newBlankCase(currentUser, isPrimaryApplicant): ng.IPromise<NewBlankCaseDTO> {
    let defer = this.$q.defer<NewBlankCaseDTO>();
    var currentdate = new Date();

    var SearchName =
      "My Loan Search " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear();

    let newDTO = {} as DevelopmentInputDTO;
    newDTO.OwnerUser = currentUser.UserName;
    newDTO.UserId = currentUser.Id;
    newDTO.SaveQueryAndResults = true;
    newDTO.SearchName = SearchName;
    newDTO.CI_Dev_Contingency = 0.05;
    newDTO.CI_Dev_BuildTerm = 12;
    newDTO.CI_Dev_LoanTermReq = 18;
    newDTO.CI_Dev_DateOfOrigPurch = new Date();

    // lookup the broker info for the current user and Set the broker info on to the search
    this.$user.getBrokerOrganisationId().then((organisationId: number) => {
      if (organisationId != null) {
        newDTO.BrokerOrganisationId = organisationId;

        this.$roleService.isBroker().then((isBroker: boolean) => {
          if (!isBroker) {
            this.$user
              .GetOrganisationAdminUserId(organisationId)
              .then((orgAdminUserId: string) => {
                newDTO.BrokerUserId = orgAdminUserId;
              });
          } else {
            newDTO.BrokerUserId = currentUser.Id;
          }
        });
      }

      this.$DevelopmentInputservice.saveSearch(newDTO).then((response) => {
        let newBlankCaseDTO = {} as NewBlankCaseDTO;
        newBlankCaseDTO.NewSearchId = response;

        newDTO.Id = response;
        this.promotesearchtocase(newDTO.Id, null, null, isPrimaryApplicant)
          .then((newCaseId: number) => {
            newBlankCaseDTO.NewSearchId = response;
            newBlankCaseDTO.NewCaseId = newCaseId;

            defer.resolve(newBlankCaseDTO);
          })
          .catch((response) => {
            defer.reject(newBlankCaseDTO);
          });
      });
    });

    return defer.promise;
  }

  /**
   * Change the broker organisation
   * @param caseId
   * @param organisationId
   */
  changeBrokerOrganisation(
    caseId: number,
    organisationId: number,
  ): ng.IPromise<CaseNoteDTO> {
    let defer = this.$q.defer<CaseNoteDTO>();

    this.$http
      .get(
        this.$baseAddress +
        "/setbrokerorganisation?caseId=" +
        caseId +
        "&organisationId=" +
        organisationId,
      )
      .then((response) => {
        defer.resolve(response.data as CaseNoteDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Change the broker organisation user
   * @param caseId
   * @param organisationId
   */
  changeBrokerUser(
    caseId: number,
    brokerUserId: string,
  ): ng.IPromise<CaseNoteDTO> {
    let defer = this.$q.defer<CaseNoteDTO>();

    this.$http
      .get(
        this.$baseAddress +
        "/setbrokeruser?caseId=" +
        caseId +
        "&brokerUserId=" +
        brokerUserId,
      )
      .then((response) => {
        defer.resolve(response.data as CaseNoteDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getLenderTask() {
    return this.task;
  }

  setLenderTask(task) {
    this.task = task;
  }

  getIsSupplimentInfoFilled() {
    return this.IsSupplimentInfoFilled;
  }

  setIsSupplimentInfoFilled(IsSupplimentInfoFilled) {
    this.IsSupplimentInfoFilled = IsSupplimentInfoFilled;
  }

  checkIfUserIsInvited(userId: string, caseId: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
        "/isuserinvitedtocase?userId=" +
        userId +
        "&caseId=" +
        caseId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  autofillCase(caseDTO: CaseDTO): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .put(this.$baseAddress + "/autofill", JSON.stringify(caseDTO))
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  shortlistMoreLenders(
    caseId: number,
    hasCriteriaChanged: boolean,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
        "/shortlistmorelenders?caseId=" +
        caseId +
        "&hasCriteriaChanged=" +
        hasCriteriaChanged,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  assignBrokerToCase(
    caseId: number,
    brokerUserId: string,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .put(
        this.$baseAddress +
        "/assignbrokertocase?caseId=" +
        caseId +
        "&brokerUserId=" +
        brokerUserId,
        "",
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  assignLenderToCase(
    caseId: number,
    lenderId: number,
    lenderUserId: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    var request = {
      DealId: caseId,
      LenderId: lenderId,
      LenderUserId: lenderUserId,
    } as AssignLenderToDealRequest;

    this.$http
      .post(this.$baseAddress + "/assignlendertocase", request)
      .then((response) => {
        var res = response.data as AssignLenderToDealResponse;
        defer.resolve(res.IsSuccess);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveSaveAsSearch(
    searchId: number,
    shareDealDto: ShareDealDTO,
    attachClient: boolean,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .post(
        this.$baseAddress +
        "/savesaveassearch?searchId=" +
        searchId +
        "&attachClient=" +
        attachClient,
        shareDealDto,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

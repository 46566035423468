import angular from "angular";
import { UserService } from "./services/UserService";

export function registerWhiteLabelledDirective() {
  const ccqdirectives = angular.module("Ccq.directives");

  ccqdirectives.directive("whiteLabelOnly", [
    "UserService",
    "$rootScope",
    (userService: UserService, $rootScope: ng.IRootScopeService) => {
      return {
        restrict: "A",
        controller: function ($scope, $element, $attrs) {},
        link: function (
          scope: angular.IScope,
          element: JQLite,
          attr: angular.IAttributes,
          ctrl: angular.IController,
        ) {
          const el: HTMLElement = angular.element(element)[0];
          const $rootScope = scope.$root;

          let originalStyles = {
            display: el.style.display,
          };

          const permitted = function (): void {
            el.style.display = originalStyles.display;
          };

          const notPermitted = function (): void {
            el.style.display = "none";
          };

          const updateWhiteLabel = (isWhiteLabelled: boolean): void => {
            if (isWhiteLabelled == true) {
              permitted();
            } else {
              notPermitted();
            }
          };

          // Setup defaults; we should assume we don't have access then grant it if we find we do.
          notPermitted();

          if (($rootScope as any).isWhiteLabelled) {
            updateWhiteLabel(($rootScope as any).isWhiteLabelled);
          } else {
            userService.IsWhiteLabelled().then(
              function (response: boolean) {
                ($rootScope as any).isWhiteLabelled = response;
                updateWhiteLabel(($rootScope as any).isWhiteLabelled);
              }.bind({ requiresRoles: (scope as any).requiresRoles }),
            );
          }
        },
      };
    },
  ]);
}

import { TestComponent1, TestComponent2 } from "@react/ReactTest";
import angular from "angular";
import { react2angular } from "react2angular";

export function registerReactComponents() {
  angular
    .module("ccqapp")
    .component("testComponent1", react2angular(TestComponent1))
    .component(
      "testComponent2",
      react2angular(TestComponent2, ["fooBar", "baz"]),
    );
}

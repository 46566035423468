import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { DeveloperExitFinanceInputSetDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceInputSetDTO.cs.d";
import { DeveloperExitFinanceResultDTO } from "@js/DTO/DevelopmentExitFinance/DeveloperExitFinanceResultDTO.cs.d";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";

export class DeveloperExitFinanceComparisonController {
  isLoading: boolean;

  exitLoanCriteria: DeveloperExitFinanceInputSetDTO;

  selectedCandidates: DeveloperExitFinanceResultDTO[];

  selectedUser: ApplicationUserDTO;

  enquiryOpen: boolean;
  enquiryEmail: string;
  enquiryBody: string;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "DevelopmentInputService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $DevelopmentInputService: DevelopmentInputService,
  ) {
    this.selectedUser = (this.$rootScope as any).selectedUser;

    this.selectedCandidates = (this.$rootScope as any).comparisonList;
    this.exitLoanCriteria = (this.$rootScope as any).exitLoanCriteria;

    if (!this.selectedCandidates || !this.exitLoanCriteria) {
      this.$location.path("/");
    }
  }

  sendEnquiry(): void {
    this.enquiryOpen = false;
  }

  go(path): void {
    this.$location.path(path);
  }
}

import { FeedBackDTO } from "@js/DTO/FeedBackDTO.cs.d";
import {
  FeedBackMessageResponse,
  FeedBackMessageRequest,
} from "@js/DTO/Messages/FeedBackMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class FeedBackService extends BaseService<FeedBackDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    private rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/feedback";
    this.$broadcastBusinessNameSingular = "feedback";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getFeedBackAndReviewerDetails(
    feedBackId: number,
    dealLenderId: number,
    isDeal: boolean,
  ): ng.IPromise<FeedBackMessageResponse> {
    var request = {
      FeedBackId: feedBackId,
      DealLeanderId: dealLenderId,
      IsDeal: isDeal,
    } as FeedBackMessageRequest;

    let defer = this.$q.defer<FeedBackMessageResponse>();
    this.$http
      .post(
        this.$baseAddress + "/getfeedbackandreviewerdetails",
        JSON.stringify(request),
      )
      .then((response) => {
        defer.resolve(response.data as FeedBackMessageResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  addFeedBack(
    feedBack: FeedBackDTO,
    dealLenderId: number,
    isDeal: boolean,
  ): ng.IPromise<FeedBackMessageResponse> {
    let defer = this.$q.defer<FeedBackMessageResponse>();
    this.$http
      .post(
        this.$baseAddress +
          "/addfeedback?dealLenderId=" +
          dealLenderId +
          "&isDeal=" +
          isDeal,
        JSON.stringify(feedBack),
      )
      .then((response) => {
        defer.resolve(response.data as FeedBackMessageResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendFeedBackReply(
    feedBack: FeedBackDTO,
    dealLenderId: number,
    isDeal: boolean,
  ): ng.IPromise<FeedBackMessageResponse> {
    let defer = this.$q.defer<FeedBackMessageResponse>();
    this.$http
      .post(
        this.$baseAddress +
          "/sendfeedbackreply?dealLenderId=" +
          dealLenderId +
          "&isDeal=" +
          isDeal,
        JSON.stringify(feedBack),
      )
      .then((response) => {
        defer.resolve(response.data as FeedBackMessageResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  addClass(star: number, id: string) {
    let elementId = "";
    for (let i = 1; i <= star; i++) {
      elementId = id + i;
      document.getElementById(elementId).classList.add("selected");
    }
  }

  removeClass(star: number, id: string, rating) {
    let elementId = "";
    if (rating == undefined) {
      rating = 0;
    }
    for (let i = star; i > rating; i--) {
      elementId = id + i;
      document.getElementById(elementId).classList.remove("selected");
    }
  }

  isFeedBackFormDisabled(isLender: boolean, feedBackDTO: FeedBackDTO) {
    if (isLender) {
      if (
        feedBackDTO != null &&
        feedBackDTO.RatingKnowledge != null &&
        feedBackDTO.RatingPresentation != null &&
        feedBackDTO.RatingResponsiveness != null &&
        feedBackDTO.IsAnonymous != null &&
        feedBackDTO.HasSpoken != null
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        feedBackDTO != null &&
        feedBackDTO.RatingCustomerService > 0 &&
        feedBackDTO.RatingSpeed > 0 &&
        feedBackDTO.RatingUnderwritingFlexibility > 0 &&
        feedBackDTO.HasSpoken != null &&
        feedBackDTO.IsAnonymous != null
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  updateRating(star: number, rating: string, feedBackDTO: FeedBackDTO) {
    switch (rating) {
      case "RatingCustomerService":
        feedBackDTO.RatingCustomerService =
          feedBackDTO != undefined &&
          feedBackDTO.RatingCustomerService == 1 &&
          star == 1
            ? 0
            : star;
        break;
      case "RatingSpeed":
        feedBackDTO.RatingSpeed =
          feedBackDTO != null && feedBackDTO?.RatingSpeed == 1 && star == 1
            ? 0
            : star;
        break;
      case "RatingUnderwritingFlexibility":
        feedBackDTO.RatingUnderwritingFlexibility =
          feedBackDTO != null &&
          feedBackDTO?.RatingUnderwritingFlexibility == 1 &&
          star == 1
            ? 0
            : star;
        break;
      case "RatingPresentation":
        feedBackDTO.RatingPresentation =
          feedBackDTO != null &&
          feedBackDTO?.RatingPresentation == 1 &&
          star == 1
            ? 0
            : star;
        break;
      case "RatingResponsiveness":
        feedBackDTO.RatingResponsiveness =
          feedBackDTO != null &&
          feedBackDTO?.RatingResponsiveness == 1 &&
          star == 1
            ? 0
            : star;
        break;
      case "RatingKnowledge":
        feedBackDTO.RatingKnowledge =
          feedBackDTO != null && feedBackDTO?.RatingKnowledge == 1 && star == 1
            ? 0
            : star;
        break;
      default:
        break;
    }

    return feedBackDTO;
  }
}

export class PrivacyController {
  orgCode: string;

  static $inject = ["$routeParams", "$scope", "$location"];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $scope: ng.IScope,
    private $location: ng.ILocationService,
  ) {
    document.getElementById("header").style.display = "none";

    this.orgCode = location.pathname.replace("/", "");
  }
}

import { PageWithTotalCountDTO } from "@js/DTO/PageWithTotalCountDTO.cs.d";
import { RoleDTO } from "@js/DTO/RoleDTO.cs.d";
import { AuthService } from "@js/services/AuthService";
import { RoleService } from "@js/services/RoleService";

export class RoleController {
  roles: PageWithTotalCountDTO;
  selectedRole: RoleDTO;

  page: number = 1;
  rolepageid: any;
  roleFilter: string;
  countperpage: number = 10;

  loadingRoles: boolean;
  error: boolean;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
    "RoleService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $auth: AuthService,
    private $role: RoleService,
  ) {
    this.loadingRoles = true;
    this.setPage(1);
  }

  setPage(newPage: number): void {
    this.loadingRoles = true;
    this.page = newPage;
    this.$role
      .fetchpage(this.roleFilter, this.page, this.countperpage)
      .then((response) => {
        this.roles = response;
        this.loadingRoles = false;
      })
      .catch((response) => {
        this.error = true;
        this.loadingRoles = false;
      });
  }

  addNewRole(): void {
    this.selectedRole = {
      Id: "",
      Name: "",
    } as RoleDTO;
  }

  formChanged(): void {
    (this.$rootScope as any).formSaved = false;
  }

  saveRole(): void {
    this.$role
      .addUpdate(this.selectedRole)
      .then((response) => {
        if (response) {
          if (!this.selectedRole.Id) {
            this.setPage(this.page);
          }
          delete this.selectedRole;
        } else {
          this.error = true;
        }
      })
      .catch((response) => {
        this.error = true;
      });
  }

  deleteRole(): void {
    this.$role
      .delete(this.selectedRole.Name)
      .then((response) => {
        if (response) {
          this.roles.List.splice(this.roles.List.indexOf(this.selectedRole), 1);
          delete this.selectedRole;
        } else {
          this.error = true;
        }
      })
      .catch((response) => {
        this.error = true;
      });
  }
}

import { AddressHistoryDTO } from "@js/DTO/AddressHistoryDTO.cs.d";
import { AddressLookupDTO } from "@js/DTO/AddressLookupDTO.cs.d";
import { AssetLiabilityItemDTO } from "@js/DTO/AssetLiabilityItemDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseMemberDTO } from "@js/DTO/CaseMemberDTO.cs.d";
import { ProjectOwnershipDTO } from "@js/DTO/DevelopmentFinance/ProjectOwnershipDTO.cs.d";
import { QualificationsDTO } from "@js/DTO/QualificationsDTO.cs.d";
import { ALItemTypeEnum } from "@js/models/enum/ALItemTypeEnum.cs.d";
import { ALTypeEnum } from "@js/models/enum/ALTypeEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { ApplicantService } from "@js/services/ApplicantService";
import { AssetLiabilityItemService } from "@js/services/AssetLiabilityItemService";
import { AuthService } from "@js/services/AuthService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { DevelopmentTrackRecordService } from "@js/services/DevelopmentTrackRecordService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { QualificationsService } from "@js/services/QualificationsService";
import { RoleService } from "@js/services/RoleService";
import { StreetAddressService } from "@js/services/StreetAddressService";

export class ApplicantDetailsController {
  //navigate for pages
  step: number = 1;
  applicantNo: number = 0;
  devexperienceId: number;

  //current case
  case: CaseDTO;
  caseTitle: string;

  //current shareholder
  currentShareholder: CaseMemberDTO;

  //case id
  caseid: number;

  development: ProjectOwnershipDTO;
  developmentTrackRecordType: {}[] = [];

  //qualifications
  newQualification: string[] = [];
  newSkill: string[] = [];
  duplicate: number[] = [];

  //address
  previousAddressSearchTerm: string;
  searchingForPreviousAddress: boolean = false;
  previousAddressPostCodeOptions: PostalAddress[] = [];
  showAddHistoryConfirmDelete: boolean = false;
  addressHistoryIdToDelete: number;
  showAddressHistoryModal: boolean = false;
  shareholderAddressHistory: AddressHistoryDTO[] = [];
  addressHistoryItem: AddressHistoryDTO;
  previousAddressSearchResults: string[];
  searchpostcode: string[];
  searchterm: string[];
  searchresults: string[];
  PostCodeOptions: PostalAddress[][] = [];
  searchingForAddress: boolean = false;

  error: boolean = false;
  saving: boolean = false;
  dataLoading: boolean = false;
  saved: boolean = false;

  //modal
  message: string = "";
  modal: boolean = false;
  openModal: boolean = false;

  //to edit input on A&L
  newDescription: string;
  newValue: number;
  newStartDate: Date;
  newEndDate: Date;
  ALItemType: ALItemTypeEnum;
  item: AssetLiabilityItemDTO;
  applicant: CaseMemberDTO;
  renamingItem: AssetLiabilityItemDTO;
  confirmationDelete: boolean = false;

  //validation dates
  maxDateOfBirth: Date = new Date();
  minDateOfBirth: Date = new Date("Jan 01 1900");
  maxDateForDateField: Date = new Date("Jan 01 3000");
  isAddFormInvalid: boolean = true;
  isEditFormInvalid: boolean = true;

  //forms
  multiPartForm1: ng.IFormController;
  multiPartForm2: ng.IFormController;
  multiPartForm3: ng.IFormController;
  multiPartForm4: ng.IFormController;
  multiPartForm7: ng.IFormController;

  formattedAcquisitionDate: any[] = [];

  YearsWorkedInProfessionsListArchitectCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListBuilderCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListCarpenterCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListElectricianCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListEstateAgencyCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListPlannerCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListProjectManagerCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListPropertySurveyorCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListQuantitySurveyorCheckbox: boolean[] = [];
  YearsWorkedInProfessionsListStructuralEngineerCheckbox: boolean[] = [];

  professionsList: string[] = [
    "Architect",
    "Builder",
    "Carpenter",
    "Electrician",
    "EstateAgency",
    "Planner",
    "ProjectManager",
    "PropertySurveyor",
    "QuantitySurveyor",
    "StructuralEngineer",
  ];

  applicantDetailsFormSectionNames = [
    {
      label: "Applicant Details",
      visible: true,
    },
  ];

  asset = {};

  static $inject = [
    "$routeParams",
    "$q",
    "$rootScope",
    "$location",
    "ApplicantService",
    "RoleService",
    "CaseMemberService",
    "AssetLiabilityItemService",
    "CaseService",
    "FileAttachmentService",
    "DevelopmentTrackRecordService",
    "StreetAddressService",
    "QualificationsService",
    "AuthService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    protected $q: ng.IQService,
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $applicantservice: ApplicantService,
    private roleService: RoleService,
    private caseMemberService: CaseMemberService,
    private assetLiabilityItemService: AssetLiabilityItemService,
    private caseService: CaseService,
    private fileAttachmentService: FileAttachmentService,
    private developmenttrackrecordservice: DevelopmentTrackRecordService,
    private streetAddressService: StreetAddressService,
    private qualificationsService: QualificationsService,
    private authService: AuthService,
  ) {
    this.onInit();
  }

  onInit() {
    //Get step number
    if (this.$routeParams.StepNumber) {
      this.step =
        this.$routeParams.StepNumber > 8 || this.$routeParams.StepNumber < 1
          ? 1
          : this.$routeParams.StepNumber;
    }

    if (this.$routeParams.CaseId) {
      this.caseid = this.$routeParams.CaseId;

      this.caseService.fetch(this.caseid).then((result) => {
        this.case = result;
        this.caseTitle = result.DevelopmentInput.SearchName;
      });
    }

    if (this.$routeParams.shareholderId) {
      this.applicantNo = this.$routeParams.shareholderId;

      this.$applicantservice.fetch(this.applicantNo).then((result) => {
        this.currentShareholder = result;
        if (
          this.currentShareholder.AddressHistory &&
          this.currentShareholder.AddressHistory.length > 0
        ) {
          this.parseAddressHistory(this.currentShareholder.AddressHistory);
        }
      });
    }

    if (this.$routeParams.Id && this.$routeParams.Id > 0) {
      this.devexperienceId = this.$routeParams.Id;
    }
  }

  goToCaseDashboard(blockSave?: boolean): void {
    if (this.roleService.IsLender == true) {
      this.$location.path("/casedashboard/" + this.caseid);
    } else {
      if (blockSave) {
        this.save(true).then((response) => {
          this.$location.path("/casedashboard/" + this.caseid);
          (this.$rootScope as any).formSaved = true;
        });
      } else {
        this.save(false).then(() => {
          this.$location.path("/casedashboard/" + this.caseid);
        });
      }
    }
  }

  goToShareholders(blockSave?: boolean): void {
    if (this.roleService.IsLender == true) {
      this.$location.path(
        "/devexperience/" + this.caseid + "/" + this.devexperienceId + "/2",
      );
    } else {
      if (blockSave) {
        this.save(true).then((response) => {
          this.$location.path(
            "/devexperience/" + this.caseid + "/" + this.devexperienceId + "/2",
          );
          (this.$rootScope as any).formSaved = true;
        });
      } else {
        this.save(false).then(() => {
          this.$location.path(
            "/devexperience/" + this.caseid + "/" + this.devexperienceId + "/2",
          );
        });
      }
    }
  }

  //save
  save(isFormComplete: boolean): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.saving = true;

    this.caseMemberService
      .addUpdatereturnonlyid(this.currentShareholder)
      .then((response) => {
        this.setAllFormsPristine();
        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
        this.error = true;
      })
      .finally(() => {
        this.saving = false;
      });

    return defer.promise;
  }

  //resent forms
  setAllFormsPristine(): void {
    if (this.multiPartForm1) {
      this.multiPartForm1.$setPristine();
    }
    if (this.multiPartForm2) {
      this.multiPartForm2.$setPristine();
    }
    if (this.multiPartForm3) {
      this.multiPartForm3.$setPristine();
    }
    if (this.multiPartForm4) {
      this.multiPartForm4.$setPristine();
    }
    if (this.multiPartForm7) {
      this.multiPartForm7.$setPristine();
    }
  }

  //Navigate for tabs

  navigateTabs(index: number) {
    this.profileGo(
      "/applicantdetails/" +
        this.caseid +
        "/" +
        this.devexperienceId +
        "/" +
        this.applicantNo +
        "/" +
        this.sum(index, 1),
    );
    (this.$rootScope as any).formSaved = true;
  }

  sum(a: number, b: number): number {
    return a + b;
  }

  go(path): void {
    // Only need to save if user is NOT a lender (everything is in read-only mode for lender)
    if (this.roleService.IsLender == true) {
      this.$location.path(path);
    } else {
      this.save(false).then((response) => {
        this.devexperienceId = response;
        this.$location.path(path);
      });
    }
  }

  profileGo(path): void {
    if (this.roleService.IsLender == true) {
      this.$location.path(path);
    } else {
      this.save(false).then((response) => {
        this.$location.path(path);
      });
    }
  }

  next(): void {
    if (this.step == 1 && this.step < 2) {
      this.step++;
      this.profileGo(
        "/applicantdetails/" +
          this.caseid +
          "/" +
          this.devexperienceId +
          "/" +
          this.$routeParams.shareholderId +
          "/" +
          this.step,
      );
      (this.$rootScope as any).formSaved = true;
    }
  }

  backApplicantDetails(): void {
    if (this.step >= 1 && this.step < 3) {
      this.step--;
      this.profileGo(
        "/applicantdetails/" +
          this.caseid +
          "/" +
          this.devexperienceId +
          "/" +
          this.$routeParams.shareholderId +
          "/" +
          this.step,
      );
      (this.$rootScope as any).formSaved = true;
    }
  }

  updateProfessionsCheckboxes(
    applicant: CaseMemberDTO,
    index: number,
  ): boolean {
    if (applicant) {
      this.professionsList.forEach((p) => {
        if (applicant["YearsWorkedInProfessionsList" + p] > 0) {
          this["YearsWorkedInProfessionsList" + p + "Checkbox"][index] = true;
        }
      });
      return true;
    } else {
      return false;
    }
  }

  finish(): void {
    this.$location.path(
      "/devexperience/" + this.caseid + "/" + this.devexperienceId + "/2",
    );
    (this.$rootScope as any).formSaved = true;
  }

  addAssetLiabilityItem(
    currentApplicant: CaseMemberDTO,
    ALTtype,
    AlTItem,
    description,
    value,
    startDate = null,
    endDate = null,
  ) {
    if (value === null || value === undefined) {
      value = 0;
    }

    var newAssetLiabilityItem: AssetLiabilityItemDTO = {
      ALType: ALTtype,
      ALItemType: AlTItem,
      Description: description,
      Value: value,
      StartDate: startDate,
      EndDate: endDate,
      CaseMemberId: currentApplicant.Id,
      Id: 0,
      IsDeleted: false,
      CreatedDateTime: null,
      LastUpdatedDateTime: null,
    } as AssetLiabilityItemDTO;
    currentApplicant.AssetLiabilityItems.push(newAssetLiabilityItem);
    this.assetLiabilityItemService
      .addUpdatelistreturnonlyids(currentApplicant.AssetLiabilityItems)
      .then((response) => {
        //update IDs
        currentApplicant.AssetLiabilityItems.forEach((x, index) => {
          x.Id = response[index];
        });
        this.asset = {};
      })
      .catch((error) => {
        this.error = true;
      })
      .finally(() => {
        delete this.asset;
      });
  }

  //Calculations

  CalculateTotalAssets(currentApplicant: CaseMemberDTO): number {
    var totalAssets: number = 0;
    if (currentApplicant && currentApplicant.AssetLiabilityItems) {
      for (let i = 0; i < currentApplicant.AssetLiabilityItems.length; i++) {
        if (
          currentApplicant.AssetLiabilityItems[i].ALType === ALTypeEnum.Asset
        ) {
          totalAssets += Number(currentApplicant.AssetLiabilityItems[i].Value);
        }
      }
    }
    return totalAssets;
  }

  CalculateTotalLiabilities(currentApplicant: CaseMemberDTO): number {
    var totalLiabilities: number = 0;
    if (currentApplicant && currentApplicant.AssetLiabilityItems) {
      for (let i = 0; i < currentApplicant.AssetLiabilityItems.length; i++) {
        if (
          currentApplicant.AssetLiabilityItems[i].ALType ===
          ALTypeEnum.Liability
        ) {
          totalLiabilities += Number(
            currentApplicant.AssetLiabilityItems[i].Value,
          );
        }
      }
    }
    return totalLiabilities;
  }

  CalculateTotalOutstandingPersonalGuarantees(
    currentApplicant: CaseMemberDTO,
  ): number {
    var totalPersonalGuarantees: number = 0;
    if (currentApplicant && currentApplicant.AssetLiabilityItems) {
      for (let i = 0; i < currentApplicant.AssetLiabilityItems.length; i++) {
        if (
          currentApplicant.AssetLiabilityItems[i].ALType ===
          ALTypeEnum.PersonalGuarantee
        ) {
          totalPersonalGuarantees += Number(
            currentApplicant.AssetLiabilityItems[i].Value,
          );
        }
      }
    }
    return totalPersonalGuarantees;
  }

  CalculateTotalNetAssets(currentApplicant: CaseMemberDTO): number {
    var totalNetAssets: number;

    if (currentApplicant && currentApplicant.AssetLiabilityItems) {
      totalNetAssets =
        this.CalculateTotalAssets(currentApplicant) -
        this.CalculateTotalLiabilities(currentApplicant);
    }
    return totalNetAssets;
  }

  cancelDelete() {
    this.confirmationDelete = false;
  }

  deleteConfirmationMessage(
    applicant: CaseMemberDTO,
    item: AssetLiabilityItemDTO,
  ) {
    this.item = item;
    this.applicant = applicant;
    this.confirmationDelete = true;
  }

  removeAssetLiabilityItem() {
    this.confirmationDelete = false;
    let index = this.applicant.AssetLiabilityItems.indexOf(this.item);
    this.applicant.AssetLiabilityItems.splice(index, 1);
  }

  renameItem(item: AssetLiabilityItemDTO) {
    this.ALItemType = item.ALItemType;
    this.newDescription = item.Description;
    this.newValue = item.Value;
    this.newStartDate = item.StartDate;
    this.newEndDate = item.EndDate;

    if (this.renamingItem === undefined) {
      this.renamingItem = item;
    } else {
      delete this.renamingItem;
    }
  }

  renamingItemComplete(
    currentApplicant: CaseMemberDTO,
    item: AssetLiabilityItemDTO,
    index: number,
  ) {
    this.assetLiabilityItemService
      .addUpdatelistreturnonlyids(currentApplicant.AssetLiabilityItems)
      .then((response) => {
        //update IDs
        currentApplicant.AssetLiabilityItems.forEach((x, index) => {
          x.Id = response[index];
        });
        this.asset = {};
      })
      .catch((error) => {
        this.error = true;
      })
      .finally(() => {});

    delete this.renamingItem;
  }

  cancelSaveItem(item: AssetLiabilityItemDTO, index: number) {
    item.ALItemType = this.ALItemType;
    item.Description = this.newDescription;
    item.Value = this.newValue;
    item.StartDate = this.newStartDate;
    item.EndDate = this.newEndDate;
    delete this.renamingItem;
  }

  showprofession(enumVal: number) {
    if (this.currentShareholder) {
      return (
        this.currentShareholder.DevelopedPropertyBefore &&
        (this.currentShareholder.ProfessionsWorkedIn & enumVal) == enumVal
      );
    } else {
      return false;
    }
  }

  resetDuplicateMessage(index: number) {
    this.duplicate[index] = 0;
  }

  addQualification(
    currentApplicant: CaseMemberDTO,
    index,
    isQualification,
    qualificationOrSkill,
  ) {
    this.saving = true;
    if (
      currentApplicant.Qualifications === null ||
      !currentApplicant.Qualifications
    ) {
      currentApplicant.Qualifications = [];
    }
    if (
      !currentApplicant.Qualifications.find(
        (x) =>
          x.QualificationOrSkill === qualificationOrSkill ||
          x.QualificationOrSkill === qualificationOrSkill,
      )
    ) {
      var qual = {
        CaseMemberId: currentApplicant.Id,
        QualificationOrSkill: qualificationOrSkill,
        IsQualification: isQualification,
        Id: 0,
        IsDeleted: false,
      } as QualificationsDTO;
      currentApplicant.Qualifications.push(qual);

      this.qualificationsService
        .addUpdatelistreturnonlyids(currentApplicant.Qualifications)
        .then((response) => {
          //update IDs
          currentApplicant.Qualifications.forEach((x, index) => {
            x.Id = response[index];
          });
        })
        .catch((error) => {
          this.error = true;
        })
        .finally(() => {
          delete this.newQualification[index];
          delete this.newSkill[index];
          this.saving = false;
        });
    } else {
      //Qualification already present
      if (isQualification) {
        //Qual entered already on list
        this.duplicate[index] = 1;
      } else {
        //Skill entered already on list
        this.duplicate[index] = 2;
      }
      this.saving = false;
      delete this.newQualification[index];
      delete this.newSkill[index];
    }
  }

  removeSkillOrQualification(
    currentApplicant: CaseMemberDTO,
    item: QualificationsDTO,
  ) {
    let index = currentApplicant.Qualifications.indexOf(item);
    currentApplicant.Qualifications.splice(index, 1);
  }

  getALItemType(ALItemType) {
    ALItemType = parseInt(ALItemType);
    var ALItemTypeValue = "";
    switch (ALItemType) {
      case ALItemTypeEnum.Other: {
        ALItemTypeValue = "Other";
        break;
      }
      case ALItemTypeEnum.ResidentialProperty: {
        ALItemTypeValue = "Residential Property";
        break;
      }
      case ALItemTypeEnum.OverseasProperty: {
        ALItemTypeValue = "Overseas Property";
        break;
      }
      case ALItemTypeEnum.BTLProperty: {
        ALItemTypeValue = "BTL Property";
        break;
      }
      case ALItemTypeEnum.CommercialProperty: {
        ALItemTypeValue = "Commercial Property";
        break;
      }
      case ALItemTypeEnum.ArtJewellryAntique: {
        ALItemTypeValue = "Art Jewellry Antique";
        break;
      }
      case ALItemTypeEnum.CashDeposits: {
        ALItemTypeValue = "Cash / Deposits";
        break;
      }
      case ALItemTypeEnum.StocksShares: {
        ALItemTypeValue = "Stocks & Shares";
        break;
      }
      case ALItemTypeEnum.VehicleBoat: {
        ALItemTypeValue = "Vehicle / Boats";
        break;
      }
      case 8: {
        ALItemTypeValue = "Business Equipment / Plant & Machinery";
        break;
      }
      case 9: {
        ALItemTypeValue = "Art / Jewellery / Antiques";
        break;
      }
      case ALItemTypeEnum.ResidentialPropertyLoan: {
        ALItemTypeValue = "Residential Property Loan";
        break;
      }
      case ALItemTypeEnum.OverseasPropertyLoan: {
        ALItemTypeValue = "Overseas Property Loan";
        break;
      }
      case ALItemTypeEnum.BTLPropertyLoan: {
        ALItemTypeValue = "BTL Property Loan";
        break;
      }
      case ALItemTypeEnum.CommercialPropertyLoan: {
        ALItemTypeValue = "Commercial Property Loan";
        break;
      }
      case ALItemTypeEnum.Overdraft: {
        ALItemTypeValue = "Overdraft";
        break;
      }
      case ALItemTypeEnum.HPLease: {
        ALItemTypeValue = "HP / Leasing";
        break;
      }
      case ALItemTypeEnum.Overdraft: {
        ALItemTypeValue = "HP / Leasing";
        break;
      }
      case 35: {
        ALItemTypeValue = "Overdraft";
        break;
      }
      case 36: {
        ALItemTypeValue = "Other Bank / Finance Loans";
        break;
      }
      case ALItemTypeEnum.PersonalGuarantee: {
        ALItemTypeValue = "Outstanding Personal Guarantee";
        break;
      }

      default: {
        ALItemTypeValue = "Unknown";
        break;
      }
    }
    return ALItemTypeValue;
  }

  proprtyTypeFilter(property) {
    if (property.ALType == ALTypeEnum.Asset) {
      return (
        property.ALItemType == ALItemTypeEnum.ResidentialProperty ||
        property.ALItemType == ALItemTypeEnum.OverseasProperty ||
        property.ALItemType == ALItemTypeEnum.BTLProperty ||
        property.ALItemType == ALItemTypeEnum.CommercialProperty
      );
    }
  }

  otherProprtyTypeFilter(property) {
    if (property.ALType == ALTypeEnum.Asset) {
      return (
        property.ALItemType == ALItemTypeEnum.CashDeposits ||
        property.ALItemType == ALItemTypeEnum.StocksShares ||
        property.ALItemType == ALItemTypeEnum.VehicleBoat ||
        property.ALItemType == ALItemTypeEnum.BusinessEquipment ||
        property.ALItemType == ALItemTypeEnum.ArtJewellryAntique ||
        property.ALItemType == ALItemTypeEnum.Other
      );
    }
  }

  liabilitiesFilter(property) {
    if (property.ALType == ALTypeEnum.Liability) {
      return (
        property.ALItemType == ALItemTypeEnum.ResidentialPropertyLoan ||
        property.ALItemType == ALItemTypeEnum.OverseasPropertyLoan ||
        property.ALItemType == ALItemTypeEnum.BTLPropertyLoan ||
        property.ALItemType == ALItemTypeEnum.CommercialPropertyLoan
      );
    }
  }

  otherliabilitiesFilter(property) {
    if (property.ALType == ALTypeEnum.Liability) {
      return (
        property.ALItemType == ALItemTypeEnum.HPLease ||
        property.ALItemType == ALItemTypeEnum.Overdraft ||
        property.ALItemType == ALItemTypeEnum.OtherLoan ||
        property.ALItemType == ALItemTypeEnum.Other
      );
    }
  }

  isInvalidForm(
    description: string,
    value: number,
    startDate: Date,
    endDate: Date,
    prefix: string,
  ) {
    if (!description || !value) {
      return true;
    }

    var validStartDate = document
      .getElementById(prefix + "-startDate")
      .className.search("ng-valid");
    var validEndDate = document
      .getElementById(prefix + "-endDate")
      .className.search("ng-valid");

    if (validStartDate < 0 || validEndDate < 0) {
      return true;
    }

    if (startDate && !this.isValidDate(startDate)) {
      return true;
    }

    if (endDate && !this.isValidDate(endDate)) {
      return true;
    }

    return false;
  }

  isAddFormValid(
    description: string,
    value: number,
    startDate: Date,
    endDate: Date,
  ) {
    this.isAddFormInvalid = this.isInvalidForm(
      description,
      value,
      startDate,
      endDate,
      "add",
    );
  }

  isEditFormValid(
    description: string,
    value: number,
    startDate: Date,
    endDate: Date,
  ) {
    this.isEditFormInvalid = this.isInvalidForm(
      description,
      value,
      startDate,
      endDate,
      "edit",
    );
  }

  isValidDate(date: Date) {
    if (date.getFullYear() > 1900 && date.getFullYear() < 2900) {
      return true;
    }
    return false;
  }

  /**
   * Save a new PostAddress history item
   * @param shareholder
   */
  saveAddressHistory(shareholder: CaseMemberDTO) {
    var id: number = 0;

    if (!this.shareholderAddressHistory) {
      this.shareholderAddressHistory = [];
    }

    // If the Id hasn't been set then this is a new previous address
    if (this.addressHistoryItem.Id == null) {
      id = this.getNextAddressHistoryId();
      this.addressHistoryItem.Id = id;
      this.shareholderAddressHistory.push(this.addressHistoryItem);
    } else {
      var foundIndex = this.shareholderAddressHistory
        .map(function (a) {
          return a.Id;
        })
        .indexOf(this.addressHistoryItem.Id);
      if (foundIndex > -1) {
        this.shareholderAddressHistory.splice(
          foundIndex,
          1,
          this.addressHistoryItem,
        ); // removed previous entry and adds the updated one in its place
      }
    }

    this.caseMemberService
      .saveAddressHistory(
        shareholder.Id,
        JSON.stringify(this.shareholderAddressHistory),
      )
      .then((addressHistory: string) => {
        shareholder.AddressHistory = addressHistory;
        this.parseAddressHistory(addressHistory);
        this.showAddressHistoryModal = false;
      })
      .catch((error) => {
        this.modal = true;
        this.message =
          "There was a problem saving the address history. Please try again later.";
      })
      .finally(() => {
        this.showAddressHistoryModal = false;
      });
  }

  getNextAddressHistoryId(): number {
    if (this.shareholderAddressHistory.length == 0) {
      return 1;
    } else {
      // Get the current maximum Id and add one to it for the next ID
      var id = Math.max.apply(
        Math,
        this.shareholderAddressHistory.map(function (a) {
          return a.Id;
        }),
      );
      id++;
      return id;
    }
  }

  /**
   * Convert addres history JSON back to an array of PostalAddress objects
   * @param addressHistoryJson
   */
  parseAddressHistory(addressHistoryJson: string) {
    // JSON doesn't understand date types so therefore user the reviver function to manually convert them back to dates as opposed to strings
    this.shareholderAddressHistory = JSON.parse(
      addressHistoryJson,
      this.reviver,
    );

    // Order the address histories by the from date (ascending)
    this.shareholderAddressHistory.sort(
      (a: AddressHistoryDTO, b: AddressHistoryDTO) => {
        return this.getTime(a.FromDate) - this.getTime(b.FromDate);
      },
    );
  }

  getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  /**
   * Tests whether a string is a date and converts it if so - used in JSON.parse
   * @param key
   * @param value
   */
  reviver(key, value) {
    var a;
    if ((typeof value === "string" && key == "ToDate") || key == "FromDate") {
      a = Date.parse(value);
      if (a) {
        return new Date(a);
      }
    }
    return value;
  }

  getAddressAsString(address: PostalAddress) {
    var addressAsString: string = "";

    addressAsString = this.concatenateAddressLine(
      address.AddressLine1,
      addressAsString,
    );
    addressAsString = this.concatenateAddressLine(
      address.AddressLine2,
      addressAsString,
    );
    addressAsString = this.concatenateAddressLine(
      address.AddressLine3,
      addressAsString,
    );
    addressAsString = this.concatenateAddressLine(
      address.AddressLine4,
      addressAsString,
    );

    return addressAsString;
  }

  concatenateAddressLine(addressLine: string, concatenatedAddress: string) {
    if (addressLine != null && addressLine.length > 0) {
      if (concatenatedAddress.length > 0) {
        concatenatedAddress = concatenatedAddress.concat(", ", addressLine);
      } else {
        concatenatedAddress = concatenatedAddress.concat(addressLine);
      }
    }

    return concatenatedAddress;
  }

  openAddressHistoryModal(addressHistory: AddressHistoryDTO = null) {
    this.previousAddressSearchResults = [];
    this.previousAddressPostCodeOptions = [];

    if (addressHistory) {
      this.addressHistoryItem = addressHistory;
    } else {
      this.addressHistoryItem = null;
    }

    this.showAddressHistoryModal = true;
  }

  showConfirmAddressDelete(addressHistoryId: number) {
    this.showAddHistoryConfirmDelete = true;
    this.addressHistoryIdToDelete = addressHistoryId;
  }

  deleteAddressHistory() {
    this.showAddHistoryConfirmDelete = false;

    var foundIndex = this.shareholderAddressHistory
      .map(function (a) {
        return a.Id;
      })
      .indexOf(this.addressHistoryIdToDelete);

    if (foundIndex > -1) {
      this.shareholderAddressHistory.splice(foundIndex, 1);

      this.caseMemberService
        .saveAddressHistory(
          this.currentShareholder.Id,
          JSON.stringify(this.shareholderAddressHistory),
        )
        .then((addressHistory: string) => {
          this.currentShareholder.AddressHistory = addressHistory;
          this.parseAddressHistory(addressHistory);
          this.showAddressHistoryModal = false;
        })
        .catch((error) => {
          this.modal = true;
          this.message =
            "There was a problem deleting the address history item. Please try again later.";
        });
    }
  }

  formatDate(unformattedDate) {
    if (unformattedDate) {
      var formattedDate = new Date(
        unformattedDate.getTime() - unformattedDate.getTimezoneOffset() * 60000,
      )
        .toISOString()
        .split("T")[0];

      return formattedDate;
    }
  }

  getAddressList(app: CaseMemberDTO, index: number, searchTerm: string) {
    let foundMatch = false;

    if (
      this.PostCodeOptions &&
      this.PostCodeOptions[index] &&
      this.PostCodeOptions[index].length > 0
    ) {
      let addressLookup = this.PostCodeOptions[index].find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            this.currentShareholder.StreetAddress = address;
            this.searchterm[index] = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        this.currentShareholder.StreetAddress.AddressLine1 =
          addressLookup.AddressLine2;
        this.currentShareholder.StreetAddress.AddressLine2 =
          addressLookup.AddressLine3;
        this.currentShareholder.StreetAddress.AddressLine3 =
          addressLookup.AddressLine4;
        this.currentShareholder.StreetAddress.AddressLine4 =
          addressLookup.PostCode;
        this.currentShareholder.StreetAddress.PostCode =
          addressLookup.AddressLine1;
        this.searchterm[index] = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.PostCodeOptions[index] = [];

      this.streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.PostCodeOptions[index] = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  getAddressListArray(app: CaseMemberDTO, index: number, searchTerm: string) {
    let foundMatch = false;

    if (
      this.PostCodeOptions &&
      this.PostCodeOptions[index] &&
      this.PostCodeOptions[index].length > 0
    ) {
      let addressLookup = this.PostCodeOptions[index].find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            app.StreetAddress = address;
            this.searchterm[index] = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        this.development.DevelopmentTrackRecords[
          index
        ].StreetAddress.AddressLine1 = addressLookup.AddressLine2;
        this.development.DevelopmentTrackRecords[
          index
        ].StreetAddress.AddressLine2 = addressLookup.AddressLine3;
        this.development.DevelopmentTrackRecords[
          index
        ].StreetAddress.AddressLine3 = addressLookup.AddressLine4;
        this.development.DevelopmentTrackRecords[
          index
        ].StreetAddress.AddressLine4 = addressLookup.PostCode;
        this.development.DevelopmentTrackRecords[index].StreetAddress.PostCode =
          addressLookup.AddressLine1;
        this.searchterm[index] = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.PostCodeOptions[index] = [];

      this.streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.PostCodeOptions[index] = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  lookupPreviousAddress(searchTerm: string) {
    let foundMatch = false;

    if (
      this.previousAddressPostCodeOptions &&
      this.previousAddressPostCodeOptions &&
      this.previousAddressPostCodeOptions.length > 0
    ) {
      let addressLookup = this.previousAddressPostCodeOptions.find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            this.addressHistoryItem = {
              AddressLine1: address.AddressLine1,
              AddressLine2: address.AddressLine2,
              AddressLine3: address.AddressLine3,
              AddressLine4: address.AddressLine4,
              PostCode: address.PostCode,
            };

            this.previousAddressSearchTerm = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        this.addressHistoryItem = {
          AddressLine1: addressLookup.AddressLine2,
          AddressLine2: addressLookup.AddressLine3,
          AddressLine3: addressLookup.AddressLine4,
          AddressLine4: addressLookup.PostCode,
          PostCode: addressLookup.AddressLine1,
        };

        this.previousAddressSearchTerm = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.previousAddressPostCodeOptions = [];

      this.streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.previousAddressPostCodeOptions = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  autofillShareholderInfo() {
    this.currentShareholder.DevelopedPropertyBefore = true;
    this.currentShareholder.LengthOfTimeBeenDeveloper = 10;
    this.currentShareholder.DevelopedPropertyBeforeNumber = 7;
    this.currentShareholder.DevelopedResidentialPropertyCompletedNumber = 7;
    // this.YearsWorkedInProfessionsListProjectManagerCheckbox[1] = true;
    this.currentShareholder.YearsWorkedInProfessionsListProjectManager = 7;
    this.addAssetLiabilityItem(
      this.currentShareholder,
      ALTypeEnum.Asset,
      ALItemTypeEnum.ResidentialProperty,
      "32 Brookside Road",
      900000,
      null,
      null,
    );
    this.addAssetLiabilityItem(
      this.currentShareholder,
      ALTypeEnum.Asset,
      ALItemTypeEnum.BTLProperty,
      "10 Brookside Road",
      100000,
      null,
      null,
    );
    this.addAssetLiabilityItem(
      this.currentShareholder,
      ALTypeEnum.Asset,
      ALItemTypeEnum.CommercialProperty,
      "King Street, York",
      2300000,
      null,
      null,
    );
    this.addAssetLiabilityItem(
      this.currentShareholder,
      ALTypeEnum.Asset,
      ALItemTypeEnum.CashDeposits,
      "Savings",
      1000000,
      null,
      null,
    );
    this.addAssetLiabilityItem(
      this.currentShareholder,
      ALTypeEnum.Asset,
      ALItemTypeEnum.StocksShares,
      "Barclays Bank",
      450000,
      null,
      null,
    );
    this.addAssetLiabilityItem(
      this.currentShareholder,
      ALTypeEnum.Liability,
      ALItemTypeEnum.ResidentialPropertyLoan,
      "32 Brookside",
      450000,
      null,
      null,
    );
    const maxDate = Date.now();
    const timestamp = Math.floor(Math.random() * maxDate);
    const timestamp2 = timestamp + 20;
    this.addAssetLiabilityItem(
      this.currentShareholder,
      2,
      60,
      "Example lender",
      400000,
      new Date(timestamp),
      new Date(timestamp2),
    );
  }
}

import { BridgingDealDTO } from "@js/DTO/Deal/BridgingDealDTO.cs.d";
import { CommercialDealDTO } from "@js/DTO/Deal/CommercialDealDTO.cs.d";
import { DevelopmentFinanceDealDTO } from "@js/DTO/Deal/DevelopmentFinanceDealDTO.cs.d";
import { BridgingPersonalGuaranteeLevelEnum } from "@js/models/enum/BridgingPersonalGuaranteeLevelEnum.cs.d";

export class SharedSearchResultsService {
  static $inject = ["$q", "$cookies"];

  constructor(
    private $q: ng.IQService,
    private $cookies: ng.cookies.ICookiesService,
  ) { }

  updateGuidanceState(guidanceCheckbox) {
    guidanceCheckbox =
      this.$cookies.get("guidance") === "on" ||
      this.$cookies.get("guidance") === undefined;
    // Update tour settings
    let tourEnabled = this.$cookies.get("tourEnabled");
    if (
      (tourEnabled == "true" || tourEnabled === undefined) &&
      guidanceCheckbox
    ) {
      return true;
    } else {
      return false;
    }
  }

  getGuidanceSwitchState() {
    if (!this.$cookies.get("guidance")) {
      return true;
    } else if (this.$cookies.get("guidance") === "on") {
      return true;
    } else if (this.$cookies.get("guidance") === "off") {
      return false;
    } else {
      return true;
    }
  }

  recordGuidanceCookie(guidanceCheckbox) {
    var guidanceSwitchState: string;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    if (guidanceCheckbox == true) {
      guidanceSwitchState = "on";
    } else {
      guidanceSwitchState = "off";
    }
    this.$cookies.put("guidance", guidanceSwitchState, { expires: expiryDate });
  }

  clearStorageDataForCompareView() {
    // Makeing sure all the old data is deleted
    sessionStorage.removeItem("LoanCriteria");
    sessionStorage.removeItem("DealClientUniqueRef");
    sessionStorage.removeItem("ComparisonList");
    sessionStorage.removeItem("TempSavedResults");
  }

  getSearchResultCount(summarySearchResults) {
    if (summarySearchResults) {
      return summarySearchResults.length;
    } else {
      return 0;
    }
  }

  getPersonalGuaranteeLevelOptions(F_PersonalGuaranteeMax) {
    var value = null;
    switch (F_PersonalGuaranteeMax) {
      case 0.0:
        value = BridgingPersonalGuaranteeLevelEnum.None;
        break;
      case 0.25:
        value = BridgingPersonalGuaranteeLevelEnum.OneToTwentyFivePercent;
        break;
      case 0.5:
        value = BridgingPersonalGuaranteeLevelEnum.TwentySixToFiftyPercent;
        break;
      case 1.0:
        value = BridgingPersonalGuaranteeLevelEnum.FiftyOneToOneHundredPercent;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  getDisplayTextForProperty(property) {
    switch (property) {
      case "F_IsFirstTimeDeveloper":
        return "1st Time Dev";
      case "F_NumberOfPreviousDevelopments":
        return "Previous Developments";
      case "F_IsPersonalName":
        return "Personal Name";
      case "F_IsOffshoreCompany":
        return "Non-UK Company";
      case "F_IsMainShareholderOverseas":
        return "Client Based Overseas";
      case "F_IsGradeOneListed":
        return "Grade 1 Listed";
      case "F_IsGradeTwoListed":
        return "Grade 2 Listed";
      case "F_MaxHouseSalesPrice":
        return "Max Sales Price (Houses)";
      case "F_MaxSqFtSalePrice":
        return "Max £/sq.ft (Houses)";
      case "F_MaxSqFtSalePriceFlats":
        return "Max £/sq.ft (Flats)";
      case "F_MaxFlatUnitPrice":
        return "Max Sales Price (Flats)";
      case "F_IsChargeOnAdditionalProperty":
        return "Additional Security";
      case "F_IsFixedRate":
        return "Fixed Rate";
      case "F_IsShariaLoan":
        return "Sharia Loan";
      case "F_IsSyndicationLoan":
        return "Syndication Loan";
      case "F_PersonalGuaranteeMax":
        return "Personal Guarantee";
      case "F_IsReBridge":
        return "Re-Bridge";
      case "F_IsServicedInterest":
        return "Serviced Interest";
      case "F_IsTitleInsurance":
        return "Title Insurance";
      case "F_IsIndemnityInsurance":
        return "Indemnity Insurance";
      case "F_IsRetypedValuation":
        return "Retyped Valuation";
      case "F_MinOwnershipPeriod":
        return "Min Ownership Period";
      case "F_IsTimberFrameConstruction":
        return "Timber Frame Construction";
      case "F_IsConcreteConstruction":
        return "Concrete Construction";
      case "F_MaxNumberOfBedrooms":
        return "Max Number Of Bedrooms";
      case "F_MaxIndividualUnitPrice":
        return "Max Individual Unit Price";
      case "F_AcceptableEPCRating":
        return "Acceptable EPC Rating";
      case "F_EWS1AcceptableType":
        return "EWS1 acceptable type";
      case "F_IsInterestOnly":
        return "Interest Only";
      case "F_IsCapitalAndRepayment":
        return "Capital And Interest";
      case "F_MaxNumberOfUnits":
        return "Max Number Of Units";
      case "F_ShareholderDepositRequired":
        return "Shareholder Deposit Required";
      case "F_PersonalGuaranteeLevel":
        return "Personal Guarantee Level";
      case "F_IsModular":
        return "Modular Construction";
      case "F_IsAirRights":
        return "Air Rights";
      case "F_IsPermittedDevelopmentScheme":
        return "Permitted Development Scheme";
      case "F_MaxPercCommercialForMixedUse":
        return "Max % Commercial (Mixed Use)";
      case "F_IsWorkStarted":
        return "Work Started";
      case "F_HasAdverseCredit":
        return "Adv. Credit";
      case "F_IsBelowMarketValue":
        return "BMV";
      case "F_IsAutomatedValuationModel":
        return "AVM";
      case "F_IsAuctionPurchase":
        return "Auction";
      case "F_ValuationMethod":
        return "Val. Method";
      case "F_MaxCommercialFloorspace":
        return "Com. Floorspace (max %)";
      case "F_MaxAgeAtStartOfMortgageTerm":
      return "Max age Start of Mortgage Term";
      case "F_MaxAgeAtEndOfMortgageTerm":
      return "Max age End of Mortgage Term";
      default:
        return "";
    }
  }

  countFiltersSelected(
    loanCriteria:
      | BridgingDealDTO
      | CommercialDealDTO
      | DevelopmentFinanceDealDTO,
    filterProperties: string[],
  ) {
    let count = 0;
    if (loanCriteria) {
      filterProperties.forEach((property) => {
        if (this.checkFilterSelected(property, loanCriteria)) {
          count++;
        }
      });
    }
    return count;
  }

  checkFilterSelected(
    property: string,
    loanCriteria:
      | BridgingDealDTO
      | CommercialDealDTO
      | DevelopmentFinanceDealDTO,
  ) {
    let value = loanCriteria[property];

    if (typeof value === "boolean" && value === true) {
      return true;
    } else if (value != null && !isNaN(value) && typeof value != "boolean") {
      return true;
    }
    return false;
  }
}

// AngularJS service registration

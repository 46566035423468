import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseLenderDTO } from "@js/DTO/CaseLenderDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { EventLogEnum } from "@js/models/enum/EventLogEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { CaseLenderService } from "@js/services/CaseLenderService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { EventLogService } from "@js/services/EventLogService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationService } from "@js/services/OrganisationService";
import { RoleService } from "@js/services/RoleService";
import { UserService } from "@js/services/UserService";

export class CaseLenderController {
  static $inject = [
    "$routeParams",
    "LenderService",
    "$location",
    "CaseMemberService",
    "CaseService",
    "CaseLenderService",
    "RoleService",
    "UserService",
    "OrganisationService",
    "EventLogService",
    "$sce",
  ];

  // have todays date
  todaysDate: Date = new Date();
  //To save url for Lender Logo Image
  lenderLogos: string[] = [];
  //To save the Lender Id and request Lender URL
  lenderId: number[] = [];
  // Controls showing the data loading "spinner"
  dataLoading: boolean = false;
  //Show lender names or Lender Generic Name
  showLenders: boolean = false;

  //get development Input Id
  developmentInputId: number;

  currentCase: CaseDTO;
  caseLenders: CaseLenderDTO[];
  // The lender the user has clicked apply/heads of terms/feedback for
  selectedCaseLender: CaseLenderDTO;
  // Controls showing the Application Terms and Conditions modal
  showApplyTsAndCs: boolean = false;
  // Controls showing the Preferred Lenders modal
  showPreferredPackagers: boolean = false;
  // Controls showing the application confirmation message
  showApplicationConfirmation: boolean = false;
  // Show the feedback modal
  showFeedbackModal: boolean = false;
  // Show the Information modal
  showInformationMessage: boolean = false;
  // show the button to confirm deleting the case lender
  showConfirmDeleteButton: boolean = false;
  // case to remove
  caseLender: CaseLenderDTO;
  // indicates whether a bid has been accepted on the case
  hasBidAccepted: boolean = false;

  // Message to be displayed in the message modal
  messageContent: string;

  showProceedModal: boolean = false;

  IsSupplimentInfoFilled: boolean = false;

  currentLenderPreferredBrokers: OrganisationDTO[];
  currentLenderPreferredPackagersText: string;
  hasLenderPreferredBrokers: boolean = false;
  emailCache = {};

  //Export Modal
  selectedLenders: { [lenderId: number]: boolean } = {};
  showExport: boolean = false;
  showExportButton: boolean = false;
  seeLenderNames: boolean = false;
  showLenderNames: boolean = true;

  currentUserOrg: OrganisationDTO;

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private lenderService: LenderService,
    private $location: ng.ILocationService,
    private caseMemberService: CaseMemberService,
    private caseService: CaseService,
    private caseLenderService: CaseLenderService,
    private roleService: RoleService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private eventLogService: EventLogService,
    private $sce: ng.ISCEService,
  ) {
    this.lenderDisplayName();

    if (this.$routeParams.CaseId) {
      this.dataLoading = true;

      this.caseService
        .fetch(this.$routeParams.CaseId)
        .then((response) => {
          this.currentCase = response;
          this.developmentInputId = response.DevelopmentInputID;
          this.userService.GetDefaultBrokerOrganisation().then((result) => {
            this.currentUserOrg = result;
            this.seeLenderNames = result?.ShowLenderNames;
            this.caseLenderService
              .fetchByCaseId(this.$routeParams.CaseId, this.seeLenderNames)
              .then((response) => {
                this.caseLenders = response;

                for (var i = 0; i < this.caseLenders.length; i++) {
                  this.lenderId[i] = this.caseLenders[i].LenderId;
                  this.toggleLender(this.caseLenders[i]);
                }
                /*this.getAllLenderLogos(this.lenderId);*/

                var bidAccepted = this.caseLenders.find(
                  (x) => x.IsBidAccepted == true,
                );

                if (bidAccepted) {
                  this.hasBidAccepted = true;
                }
                this.dipRecieved();
              });
          });

          //TODO JLH - why is this being done??
          //this.caseService.setStatusOnCase(this.currentCase.Id, this.currentCase.CaseStatus);

          this.IsSupplimentInfoFilled =
            this.caseService.getIsSupplimentInfoFilled();
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  ////Get URL for the specific lender
  //getLenderLogos(index: number) {
  //    return this.lenderLogos[index];
  //}

  //// Get all URL for all Lender result
  //getAllLenderLogos(lenderId: number[]) {
  //    this.lenderService.getAllLenderLogos(lenderId).then(result => {
  //        this.lenderLogos = result;

  //    })
  //}

  /** Redirects to the case dashboard */
  goToCaseDashboard() {
    this.$location.path("/casedashboard/" + this.currentCase.Id);
  }

  /** Redirects to the user dashboard */
  goToUserDashboard() {
    this.userService.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  /** Process the Heads of Terms button being clicked */
  headsOfTermsClicked(selectedCaseLender: CaseLenderDTO, index) {
    this.selectedCaseLender = selectedCaseLender;
    this.$location.path(
      "/headsofterm/" + this.currentCase.Id + "/" + this.selectedCaseLender.Id,
    );
  }
  /**
   * Process the feedback button being clicked
   * @param selectedCaseLender The lender associated with the button's row
   */
  feedbackButtonClicked(selectedCaseLender: CaseLenderDTO) {
    this.selectedCaseLender = selectedCaseLender;

    // Show the Feedback Modal
    this.showFeedbackModal = true;
  }

  lenderDisplayName() {
    this.organisationService.showLenderNamesAndLogos().then((result) => {
      this.showLenders = result;
    });
  }

  /**Process the Submit Terms and Conditions button being clicked */
  submitTsAndCsClicked() {
    this.showApplyTsAndCs = false;
    this.selectedCaseLender.IsBidAccepted = true;

    this.caseLenderService
      .addUpdate(this.selectedCaseLender)
      .then((response) => {
        this.selectedCaseLender.IsBidAccepted = response.IsBidAccepted;
        this.hasBidAccepted = response.IsBidAccepted;

        return this.caseLenderService.applyToLender(
          this.selectedCaseLender.CaseId,
          this.selectedCaseLender.Id,
          this.hasLenderPreferredBrokers,
        );
      })
      .then((response) => {
        this.selectedCaseLender.CaseLenderState = response.CaseLenderState;
        this.caseLenders = this.caseLenders.map((cl) =>
          cl.Id !== this.selectedCaseLender.Id ? cl : this.selectedCaseLender,
        );
      })
      .finally(() => {
        this.messageContent =
          "Thank you for submitting an application with this lender.";
        if (this.hasLenderPreferredBrokers) {
          this.showPreferredPackagers = true;
        } else {
          this.showInformationMessage = true;
        }
      });
  }

  /**
   * Process the Apply button being clicked
   */
  submit(lender: CaseLenderDTO) {
    this.selectedCaseLender = lender;

    this.lenderService
      .getSelectedLenderPreferredOrgs(this.selectedCaseLender.LenderId)
      .then((response) => {
        this.currentLenderPreferredBrokers = response;

        return this.userService.getBrokerOrganisationId();
      })
      .then((response) => {
        //checks if broker that applies is not part of the preferred brokers list

        this.hasLenderPreferredBrokers =
          this.currentLenderPreferredBrokers != null &&
          this.currentLenderPreferredBrokers.length > 0 &&
          !this.currentLenderPreferredBrokers.some(
            (org) => org.Id === response,
          );

        if (this.hasLenderPreferredBrokers) {
          this.currentLenderPreferredBrokers.forEach((org) => {
            this.getBrokerAdminEmail(org);
          });
        }

        return this.lenderService.getLenderPreferredPackagersText(
          this.selectedCaseLender.LenderId,
        );
      })
      .then((response) => {
        this.currentLenderPreferredPackagersText = response;
      })
      .finally(() => {
        this.showApplyTsAndCs = true;
      });
  }

  /**
   * Process the Send Feedback button being clicked
   * @param message
   */
  sendFeedbackClicked() {
    this.caseLenderService
      .sendFeedbackToLender(this.selectedCaseLender)
      .then((response) => {
        this.messageContent = "Your feedback has been sent to the lender.";
        this.showInformationMessage = true;
      })
      .catch((error) => {
        this.messageContent =
          "Sorry, something went wrong while sending feedback to lender. Please try again.";
      })
      .finally(() => {
        this.showFeedbackModal = false;
      });
  }

  /**
   * Process the Apply button being clicked
   * @param currentCaseLender
   */
  applyClicked(selectedCaseLender: CaseLenderDTO) {
    this.selectedCaseLender = selectedCaseLender;

    // Show the Terms and Conditions modal
    this.showApplyTsAndCs = true;
  }
  /**
   * Process the Cancel Application button being clicked
   * @param currentCaseLender
   */

  cancelTerms(selectedCaseLender: CaseLenderDTO) {
    this.caseLenderService
      .getSelectedCaseLender(selectedCaseLender.Id, this.seeLenderNames)
      .then((response) => {
        this.selectedCaseLender = response;
      })
      .then(() => {
        if (
          this.selectedCaseLender.CaseLenderState == CaseLenderStateEnum.Applied
        ) {
          this.selectedCaseLender.IsBidAccepted = false;
          this.caseLenderService
            .addUpdate(this.selectedCaseLender)
            .then((response) => {
              this.selectedCaseLender.IsBidAccepted = response.IsBidAccepted;
              this.hasBidAccepted = response.IsBidAccepted;

              return this.caseLenderService.cancelApplication(
                this.selectedCaseLender.CaseId,
                this.selectedCaseLender.Id,
              );
            })
            .then((response) => {
              this.selectedCaseLender.CaseLenderState =
                response.CaseLenderState;
              this.caseLenders = this.caseLenders.map((cl) =>
                cl.Id !== this.selectedCaseLender.Id
                  ? cl
                  : this.selectedCaseLender,
              );
            })
            .finally(() => {
              this.messageContent =
                "Application with this lender has been cancelled.";
              this.showInformationMessage = true;
            });
        } else {
          this.messageContent =
            "There was a problem with cancelling this application. Please refresh and try again.";
          this.showInformationMessage = true;
        }
      });

    //this.showProceedModal = true;
  }

  getStatusText(selectedCaseLender: CaseLenderDTO): string {
    var statusText = "";

    if (this.roleService.IsLender) {
      statusText = this.caseLenderService.getStatusTextForLender(
        selectedCaseLender.CaseLenderState,
        selectedCaseLender.IsBidAccepted,
      );
    } else {
      switch (selectedCaseLender.CaseLenderState) {
        case CaseLenderStateEnum.Offered: {
          if (this.hasBidAccepted) {
            statusText = selectedCaseLender.IsBidAccepted
              ? "DIP received and accepted"
              : "DIP received but applying to another lender";
          } else {
            statusText = "DIP received";
          }
          break;
        }
        case CaseLenderStateEnum.Received: {
          statusText = "Awaiting DIP";
          if (this.hasBidAccepted) {
            statusText = "Awaiting DIP but applying to another lender";
          } else {
            statusText = "Awaiting DIP";
          }
          break;
        }
        case CaseLenderStateEnum.Cancelled: {
          if (this.hasBidAccepted) {
            statusText = "Application cancelled but applying to another lender";
          } else {
            statusText = "Application cancelled";
          }
          break;
        }
      }
    }

    return statusText;
  }

  editApplicationDetails() {
    this.$location.path("/applicationdetails/" + this.currentCase.Id);
  }

  deleteButton(caseLender: CaseLenderDTO) {
    this.showConfirmDeleteButton = true;
    this.showInformationMessage = true;
    this.messageContent =
      "Are you sure you want to remove " + caseLender.LenderName + "?";
    this.caseLender = caseLender;
  }

  delete() {
    this.caseLenderService
      .markasdeleted(this.caseLender.Id)
      .then((result) => {
        this.caseLenders = this.caseLenders.filter(
          (x) => x.Id != this.caseLender.Id,
        );
      })
      .finally(() => {
        this.showInformationMessage = false;
        delete this.caseLender;
        this.showConfirmDeleteButton = false;
      });
  }

  /**
   * Determines whether to show the Shortlist More Lenders button.
   * Should be shown if there are less than 3 "active" shortlisted lenders i.e. haven't rejected/withdrawn
   * and no DIP is accepted
   * */
  showShortlistMoreLendersButton(): boolean {
    var showButton: boolean = false;
    if (this.caseLenders) {
      var activeShortlistCount = this.caseLenders.filter(
        (lender) =>
          lender.CaseLenderState != CaseLenderStateEnum.Withdrawn &&
          lender.CaseLenderState != CaseLenderStateEnum.Rejected,
      ).length;

      showButton = activeShortlistCount < 5 ? true : false;
    }

    //Hide button if DIP accepted
    if (this.hasBidAccepted) {
      showButton = false;
    }

    return showButton;
  }

  shortlistMoreLenders() {
    this.$location.path(
      "/shortlistmore/" +
        this.currentCase.DevelopmentInputID +
        "/" +
        this.currentCase.Id,
    );
  }

  toggleLender(lender: CaseLenderDTO) {
    this.selectedLenders[lender.Id] = !this.selectedLenders[lender.Id];
  }

  stateFilter(lender: CaseLenderDTO) {
    return (
      !lender.IsDeleted &&
      (lender.CaseLenderState === 2 || lender.CaseLenderState === 10)
    );
  }

  showAndHideExport() {
    this.showExport = !this.showExport;
  }

  getSelectedCaseLenders() {
    const selectedCaseLenders = this.caseLenders.filter(
      (lender) => this.selectedLenders[lender.Id],
    );
    return selectedCaseLenders;
  }

  areLendersSelected() {
    for (var lenderId in this.selectedLenders) {
      if (this.selectedLenders[lenderId]) {
        return true;
      }
    }
    return false;
  }

  dipRecieved() {
    this.showExportButton = this.caseLenders.some(
      (lender) => lender.CaseLenderState === 2 || lender.CaseLenderState === 10,
    );
  }

  logPdfDownload() {
    this.eventLogService.logEvent(
      "EXPORTDIPPDFCLICKED",
      EventLogEnum.Export,
      ProductTypeEnum.Development,
    );
  }

  trustedHtml(plainText) {
    return this.$sce.trustAsHtml(plainText);
  }

  getBrokerAdminEmail(org: OrganisationDTO) {
    if (this.emailCache && this.emailCache[org.Id]) {
      return;
    }

    if (!org.PreferredPackagerEmail) {
      this.userService.getOrganisationAdminEmail(org.Id).then((response) => {
        this.emailCache[org.Id] = response;
      });
    } else {
      this.emailCache[org.Id] = org.PreferredPackagerEmail;
    }
  }
}

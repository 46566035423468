import { ProductDTO } from "@js/DTO/ProductDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";

export class ProductService extends BaseService<ProductDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/product";
    this.$broadcastBusinessNameSingular = "product";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getLeadGeneratorName(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get(this.$baseAddress + "/getLeadGeneratorName")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  isProductRefUnique(productRef: string, productId: number = 0) {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
          "/isProductRefUnique?productRef=" +
          productRef +
          "&productId=" +
          productId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getProductTypeDisplayText(
    productType: ProductTypeEnum,
    isRegulatedBridging: boolean = false,
  ): string {
    let displayText: string;

    switch (productType) {
      case ProductTypeEnum.Development:
        displayText = "Development Finance";
        break;
      case ProductTypeEnum.BridgingPreconstruction:
        displayText = "Planning Bridge";
        break;
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        displayText = isRegulatedBridging
          ? "Regulated"
          : "Purchase / Refinance";
        break;
      case ProductTypeEnum.BridgingDeveloperExit:
        displayText = "Developer Exit";
        break;
      case ProductTypeEnum.BridgingRefurb:
        displayText = isRegulatedBridging ? "Regulated" : "Refurbishment";
        break;
      case ProductTypeEnum.CommercialInvestment:
        displayText = "Commercial Investment";
        break;
      case ProductTypeEnum.CommercialOwnerOccupied:
        displayText = "Owner Occupied Mortgage";
        break;
      default:
        displayText = "Undefined";
        break;
    }

    return displayText;
  }

  getProductTypeSearchName(productType: ProductTypeEnum): string {
    let displayText: string;

    switch (productType) {
      case ProductTypeEnum.Development:
        displayText = "Development Finance";
        break;
      case ProductTypeEnum.BridgingPreconstruction:
        displayText = "Planning Bridge";
        break;
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        displayText = "Purchase / Refinance";
        break;
      case ProductTypeEnum.BridgingDeveloperExit:
        displayText = "Developer Exit";
        break;
      case ProductTypeEnum.BridgingRefurb:
        displayText = "Refurbishment";
        break;
      case ProductTypeEnum.CommercialInvestment:
        displayText = "Commercial Investment";
        break;
      case ProductTypeEnum.CommercialOwnerOccupied:
        displayText = "Owner Occupied";
        break;
      default:
        displayText = "Undefined";
        break;
    }

    return displayText;
  }

  getProductTypeFullName(productType: ProductTypeEnum): string {
    let displayText: string;

    switch (productType) {
      case ProductTypeEnum.Development:
        displayText = "Development Finance";
        break;
      case ProductTypeEnum.BridgingPreconstruction:
        displayText = "Planning Bridge";
        break;
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        displayText = "Purchase / Refinance Bridge";
        break;
      case ProductTypeEnum.BridgingDeveloperExit:
        displayText = "Developer Exit Bridge";
        break;
      case ProductTypeEnum.BridgingRefurb:
        displayText = "Refurbishment Bridge";
        break;
      case ProductTypeEnum.CommercialInvestment:
        displayText = "Commercial Investment Mortgage";
        break;
      case ProductTypeEnum.CommercialOwnerOccupied:
        displayText = "Commercial Owner Occupied Mortgage";
        break;
      default:
        displayText = "Undefined";
        break;
    }

    return displayText;
  }

  getProductFamilyByProductType(
    productType: ProductTypeEnum,
  ): ProductFamilyEnum {
    let productFamily: ProductFamilyEnum;

    switch (productType) {
      case ProductTypeEnum.Development:
        productFamily = ProductFamilyEnum.Development;
        break;
      case ProductTypeEnum.BridgingPreconstruction:
        productFamily = ProductFamilyEnum.Bridging;
        break;
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        productFamily = ProductFamilyEnum.Bridging;
        break;
      case ProductTypeEnum.BridgingDeveloperExit:
        productFamily = ProductFamilyEnum.Bridging;
        break;
      case ProductTypeEnum.BridgingRefurb:
        productFamily = ProductFamilyEnum.Bridging;
        break;
      case ProductTypeEnum.Bridging:
        productFamily = ProductFamilyEnum.Bridging;
        break;
      case ProductTypeEnum.CommercialInvestment:
        productFamily = ProductFamilyEnum.Commercial;
        break;
      case ProductTypeEnum.CommercialOwnerOccupied:
        productFamily = ProductFamilyEnum.Commercial;
        break;
      case ProductTypeEnum.CommercialAll:
        productFamily = ProductFamilyEnum.Commercial;
        break;
      default:
        productFamily = ProductFamilyEnum.None;
        break;
    }

    return productFamily;
  }

  /**
   * Text to send to google analytics
   * @param productType
   * @param isRegulatedBridging
   */
  getProductTypeTextForGa(
    productType: ProductTypeEnum,
    isRegulatedBridging: boolean = false,
  ): string {
    let displayText: string;

    switch (productType) {
      case ProductTypeEnum.Development:
        displayText = "Development Finance";
        break;
      case ProductTypeEnum.BridgingPreconstruction:
        displayText = "Planning Bridge";
        break;
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        displayText = isRegulatedBridging
          ? "Purchase / Refinance Regulated"
          : "Purchase / Refinance";
        break;
      case ProductTypeEnum.BridgingDeveloperExit:
        displayText = "Developer Exit";
        break;
      case ProductTypeEnum.BridgingRefurb:
        displayText = isRegulatedBridging
          ? "Refurbishment Regulated"
          : "Refurbishment";
        break;
      case ProductTypeEnum.CommercialInvestment:
        displayText = "Commercial Investment";
        break;
      case ProductTypeEnum.CommercialOwnerOccupied:
        displayText = "Owner Occupied Mortgage";
        break;
      default:
        displayText = "Undefined";
        break;
    }

    return displayText;
  }

  /**
   * Text to sent to google analytics
   * @param productFamily
   */
  getProductFamilyTextForGa(productFamily: ProductFamilyEnum): string {
    let displayText: string;

    switch (productFamily) {
      case ProductFamilyEnum.Development:
        displayText = "Development Finance";
        break;
      case ProductFamilyEnum.Bridging:
        displayText = "Bridging";
        break;
      case ProductFamilyEnum.Commercial:
        displayText = "Commercial";
        break;
      default:
        displayText = "Undefined";
        break;
    }

    return displayText;
  }

  getLastVerifiedDates(productIds: number[]) {
    let defer = this.$q.defer<{ [key: number]: Date }>();

    this.$http.post(this.$baseAddress + "/getlastverifieddates", productIds)
      .then((response) => {
        defer.resolve(response.data as { [key: number]: Date });
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

}

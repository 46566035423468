export function registerRoutes() {
  angular
    .module("ccqapp")
    .config([
      "$routeProvider",
      function ($routeProvider) {
        $routeProvider
          .when("/i/:introducercode", {
            page: "Criteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/i/:introducercode/:invitecode", {
            page: "Criteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/landing", {
            page: "Landing",
            controller: "LandingV3Controller",
            controllerAs: "ctrl",
            templateUrl: "views/landing-default.html",
          })
          .when("/agentlanding", {
            page: "Agent Landing",
            controller: "AgentLandingController",
            controllerAs: "ctrl",
            templateUrl: "views/agentlanding.html",
          })
          //when('/landingV2', { page: "Welcome", controller: '', controllerAs: 'ctrl', templateUrl: 'views/landing-V2.html' }).
          .when("/landingV3", {
            page: "Welcome",
            controller: "LandingV3Controller",
            controllerAs: "ctrl",
            templateUrl: "views/landing-default.html",
          })
          .when("/initialregister", {
            page: "InitialRegistration",
            controller: "InitialRegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/initialregistration.html",
          })
          .when("/initialregister/:isBroker", {
            page: "InitialRegistration",
            controller: "InitialRegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/initialregistration.html",
          })
          .when("/initialregister/:isBroker/:widgeturl", {
            page: "InitialRegistration",
            controller: "InitialRegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/initialregistration.html",
          })
          .when("/borrowerinitialregistration", {
            page: "InitialRegistration",
            controller: "InitialRegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/initialregistrationborrower.html",
          })
          .when("/promo", {
            page: "Promo",
            controller: "PaymentController",
            controllerAs: "ctrl",
            templateUrl: "views/subscribe.html",
          })
          .when("/promo/:isBroker", {
            page: "Promo",
            controller: "PaymentController",
            controllerAs: "ctrl",
            templateUrl: "views/promo.html",
          })
          .when("/assignlicenses", {
            page: "AssignLicense",
            controller: "AssignLicensesController",
            controllerAs: "ctrl",
            templateUrl: "views/assignlicenses.html",
          })
          .when("/leadgeneratorpromo/:licensecount", {
            page: "LeadGeneratorPromo",
            controller: "LeadGenPromoController",
            controllerAs: "ctrl",
            templateUrl: "views/leadgeneratorpromo.html",
          })
          /* when('/subscribe', { page: "Payment", controller: 'PaymentController', controllerAs: 'ctrl', templateUrl: 'views/subscribe.html' }).*/
          .when("/welcome", {
            page: "Welcome",
            controller: "WelcomeController",
            controllerAs: "ctrl",
            templateUrl: "views/welcome.html",
          })
          .when("/login", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          })
          .when("/login/:orgref", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          })
          .when("/loginandreturnto", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          })
          .when("/login/:tempUser/:tempPassword", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          })
          .when("/login/:userStatus/:tempUser/:tempPassword", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          }).when("/e/login/:productFamily/:dealUniqueRef", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          }).when("/e/login/:productFamily/:dealUniqueRef/:clientId/:emailAddress", {
            page: "Login",
            controller: "LoginController",
            controllerAs: "ctrl",
            templateUrl: "views/login.html",
          })
          .when("/register", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when("/register/:enquire", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/registration.html",
          })
          .when("/registerwithsearch/:noLoginUniqueRef", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when(
            "/registerwithsearch/:noLoginUniqueRef/:brokerOrgUniqueRef/:brokerUserId",
            {
              page: "Registration",
              controller: "RegistrationController",
              controllerAs: "ctrl",
              templateUrl: "views/newregistration.html",
            },
          )
          //Added a new registerwithdeal route which can handle all type of deals but keeping this too, so that we can handle the old email links
          .when("/registerwithbridgingsearch/:dealclientuniqueref", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when(
            "/registerwithbridgingsearch/:dealclientuniqueref/:brokerOrgUniqueRef/:brokerUserId",
            {
              page: "Registration",
              controller: "RegistrationController",
              controllerAs: "ctrl",
              templateUrl: "views/newregistration.html",
            },
          )
          .when("/register/:brokerOrgUniqueRef", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/registration.html",
          }) // TODO don't think this is being used
          .when("/registershareholder/:uniqueId", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when("/registershareholder/:uniqueId/:brokerOrgUniqueRef", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when("/register/startprojectappraisal", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/registration.html",
          })
          .when("/register/applyfordevloan", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/registration.html",
          })
          .when("/register/invite/:invite", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/registration.html",
          })
          .when("/dashboard", {
            page: "Dashboard",
            controller: "DashboardController",
            controllerAs: "ctrl",
            templateUrl: "views/dashboard.html",
          })
          .when("/introducer/:status", {
            page: "Introducer",
            controller: "IntroducerController",
            controllerAs: "ctrl",
            templateUrl: "views/introducer.html",
          })
          .when("/introducer", {
            page: "Introducer",
            controller: "IntroducerController",
            controllerAs: "ctrl",
            templateUrl: "views/introducer.html",
          })
          .when("/registerbroker", {
            page: "Broker Registration",
            controller: "RegistrationBrokerController",
            controllerAs: "ctrl",
            templateUrl: "views/registrationbroker.html",
          })
          .when("/registerbroker/:orguniqueref", {
            page: "Broker Registration",
            controller: "RegistrationBrokerController",
            controllerAs: "ctrl",
            templateUrl: "views/registrationbroker.html",
          })
          .when("/registerbroker/:uniqueRef", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/registration.html",
          }) // TODO is this used? concerned about this route in combination with the one above
          .when("/registerborrower/:uniqueRef", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when("/registerintroducer", {
            page: "Introducer Registration",
            controller: "RegistrationIntroducerController",
            controllerAs: "ctrl",
            templateUrl: "views/registrationintroducer.html",
          })
          .when("/registerintroducer/:introducercode", {
            page: "Introducer Registration",
            controller: "RegistrationIntroducerController",
            controllerAs: "ctrl",
            templateUrl: "views/registrationintroducer.html",
          })
          .when("/r/:introducercode", {
            page: "Introducee Registration",
            controller: "LandingV3Controller",
            controllerAs: "ctrl",
            templateUrl: "views/landing-default.html",
          })
          .when("/olddashboard", {
            page: "Client Dashboard",
            controller: "ClientDashboardController",
            controllerAs: "ctrl",
            templateUrl: "views/clientdashboard.html",
          })
          .when("/lenders", {
            page: "Lenders",
            controller: "LenderController",
            controllerAs: "ctrl",
            templateUrl: "views/lenders.html",
          })
          .when("/featureprices", {
            page: "Feature Prices",
            controller: "FeaturePriceController",
            controllerAs: "ctrl",
            templateUrl: "views/featureprice.html",
          })
          .when("/criteria", {
            page: "Criteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/criteria/:SearchId", {
            page: "Criteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/criteria/:SearchId/:CaseId", {
            page: "Criteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/e/criteria/:SearchId/:CaseId/:StepNumber", {
            page: "Criteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/e/criteria/:SearchId/:CaseId/:StepNumber/:linkId", {
            page: "Criteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/criteria/:SearchId/:CaseId/:StepNumber", {
            page: "Criteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/referredsearchcriteria/:uniqueId/:CaseId/:StepNumber", {
            page: "ReferredSearchCriteria",
            controller: "DevelopmentInputScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteria.html",
          })
          .when("/criteriaappraisal/:CaseId/:SearchId", {
            page: "CriteriaAppraisal",
            controller: "DevelopmentAppraisalScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteriaappraisal.html",
          })
          .when("/criteriaappraisal/:CaseId/:SearchId/:StepNumber", {
            page: "CriteriaAppraisal",
            controller: "DevelopmentAppraisalScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/criteriaappraisal.html",
          })
          .when("/querynologin", {
            page: "Querynologin",
            controller: "DevelopmentInputWithNoLoginController",
            controllerAs: "ctrl",
            templateUrl: "views/developmentinputWithNoLoginlist.html",
          })
          .when("/query", {
            page: "Query",
            controller: "DevelopmentInputController",
            controllerAs: "ctrl",
            templateUrl: "views/developmentinputlist.html",
          })
          .when("/results", {
            page: "Lender Results",
            controller: "LenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/results/:SearchId", {
            page: "Lender Results",
            controller: "LenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/results/:SearchId/:CaseId", {
            page: "Lender Results",
            controller: "LenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/shortlistmore/:SearchId/:CaseId", {
            page: "Lender Results",
            controller: "LenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/resultsExternalAPI/:uniqeId", {
            page: "Lender Results",
            controller: "LenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/referredSearch/:uniqueId", {
            page: "Lender Results",
            controller: "LenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/referredSearch/:uniqueId/:brokerOrgUniqueRef/:brokerUserId", {
            page: "Lender Results",
            controller: "LenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/referredSearch/:uniqueId/:showOnlyRegister", {
            page: "Lender Results",
            controller: "LenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/compare", {
            page: "Comparison",
            controller: "ComparisonController",
            controllerAs: "ctrl",
            templateUrl: "views/comparison.html",
          })
          .when("/compare/:SearchId", {
            page: "Comparison",
            controller: "ComparisonController",
            controllerAs: "ctrl",
            templateUrl: "views/comparison.html",
          })
          .when("/compare/:SearchId/:CaseId", {
            page: "Comparison",
            controller: "ComparisonController",
            controllerAs: "ctrl",
            templateUrl: "views/comparison.html",
          })
          .when("/compare/:SearchId/:CaseId/:isDeal", {
            page: "Comparison",
            controller: "ComparisonController",
            controllerAs: "ctrl",
            templateUrl: "views/comparison.html",
          })
          .when("/usermanagement", {
            page: "User Management",
            controller: "UserController",
            controllerAs: "ctrl",
            templateUrl: "views/users.html",
          })
          .when("/rolemanagement", {
            page: "User Role Management",
            controller: "RoleController",
            controllerAs: "ctrl",
            templateUrl: "views/RoleMangement.html",
          })
          .when("/accounts/", {
            page: "accounts",
            controller: "AccountsController",
            controllerAs: "ctrl",
            templateUrl: "views/accounts.html",
          })
          .when("/devexperience", {
            page: "Devexperience",
            controller: "DevelopmentExperienceController",
            controllerAs: "ctrl",
            templateUrl: "views/devexperience.html",
          })
          .when("/devexperience/:CaseId/:Id", {
            page: "Devexperience",
            controller: "DevelopmentExperienceController",
            controllerAs: "ctrl",
            templateUrl: "views/devexperience.html",
          })
          .when("/devexperience/:CaseId/:Id/:StepNumber", {
            page: "Devexperience",
            controller: "DevelopmentExperienceController",
            controllerAs: "ctrl",
            templateUrl: "views/devexperience.html",
          })
          .when("/devschedule", {
            page: "Devschedule",
            controller: "DevelopmentInputScheduleController",
            controllerAs: "ctrl",
            templateUrl: "views/devschedule.html",
          })
          .when("/devschedule/:CaseId/:DevelopmentId", {
            page: "Devschedule",
            controller: "DevelopmentInputScheduleController",
            controllerAs: "ctrl",
            templateUrl: "views/devschedule.html",
          })
          // when('/payment/:CaseId', { page: "Payment", controller: 'PaymentController', controllerAs: 'ctrl', templateUrl: 'views/paymentform.html' }).
          .when("/paysuccess", {
            page: "PaySuccess",
            controller: "PaySucessController",
            controllerAs: "ctrl",
            templateUrl: "views/paysuccess.html",
          })
          .when("/payfailure", {
            page: "Payfailure",
            controller: "PayFailController",
            controllerAs: "ctrl",
            templateUrl: "views/payfailure.html",
          })
          .when("/exitfinance", {
            page: "Exitfinance",
            controller: "DeveloperExitFinanceDevScheduleInputController",
            controllerAs: "ctrl",
            templateUrl:
              "views/partials/DevExitFinance/developerexitfinancedevscheduleinputdetail.html",
          })
          .when("/exitloanresults", {
            page: "ExitFinanceResults",
            controller: "DeveloperExitFinanceResultController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/exitloancompare", {
            page: "ExitFinanceCompare",
            controller: "DeveloperExitFinanceComparisonController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/DevExitFinance/comparison.html",
          })
          .when("/refer", {
            page: "ReferAFriend",
            controller: "ReferFriendController",
            controllerAs: "ctrl",
            templateUrl: "views/referafriend.html",
          })
          .when("/casedashboard/:CaseId", {
            page: "Casedashboard",
            controller: "CaseController",
            controllerAs: "ctrl",
            templateUrl: "views/casedashboard.html",
          })
          .when("/casedashboard/:CaseId/:PaymentStatus", {
            page: "Casedashboard",
            controller: "CaseController",
            controllerAs: "ctrl",
            templateUrl: "views/casedashboard.html",
          })
          .when("/caseoverview/:CaseId", {
            page: "Caseoverview",
            controller: "CaseOverviewController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/caseoverview.html",
          })
          .when("/caseoverview/:CaseId/:Planning", {
            page: "Caseoverview",
            controller: "CaseOverviewController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/caseoverview.html",
          })
          .when("/applicationdetails/:CaseId", {
            page: "ApplicationDetails",
            controller: "ApplicationDetailsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/applicationdetails.html",
          })
          .when("/planning/:CaseId", {
            page: "Planning",
            controller: "CaseController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/planningmodule.html",
          })
          .when("/teammember/:CaseId", {
            page: "TeamMember",
            controller: "CaseController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/professionalteam.html",
          })
          .when("/comparableproperties/:CaseId", {
            page: "Comparableproperties",
            controller: "CaseController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/comparableproperties.html",
          })
          .when("/settings", {
            page: "Account Settings",
            controller: "AccountSettingsController",
            controllerAs: "ctrl",
            templateUrl: "views/accountsettings.html",
          })
          .when("/settings/:userId/:selectedTab?", {
            page: "Account Settings",
            controller: "AccountSettingsController",
            controllerAs: "ctrl",
            templateUrl: "views/accountsettings.html",
          })
          .when("/profiles/:CaseId/:Id", {
            page: "Profiles",
            controller: "DevelopmentExperienceController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/profiles.html",
          })
          .when("/lending/:CaseId", {
            page: "Lending",
            controller: "CaseLenderController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/lending.html",
          })
          .when("/headsofterm/:CaseId/:CaseLenderId", {
            page: "Heads Of Terms",
            controller: "HeadsOfTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/headsofterms.html",
          })
          .when("/headsofterm/:CaseId/:CaseLenderId/:openModule", {
            page: "Heads Of Terms",
            controller: "HeadsOfTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/headsofterms.html",
          })
          .when("/applicantdetails/:CaseId/:Id/:shareholderId", {
            page: "ApplicantDetails",
            controller: "ApplicantDetailsController",
            controllerAs: "ctrl",
            templateUrl: "views/applicantdetails.html",
          })
          .when("/applicantdetails/:CaseId/:Id/:shareholderId/:StepNumber", {
            page: "ApplicantDetails",
            controller: "ApplicantDetailsController",
            controllerAs: "ctrl",
            templateUrl: "views/applicantdetails.html",
          })
          .when("/securitycheck/:uniqueId", {
            page: "SecurityCheck",
            controller: "SecurityCheckController",
            controllerAs: "ctrl",
            templateUrl: "views/securityCheck.html",
          })
          .when("/securitycheck/:uniqueId/:orgUniqueRef", {
            page: "SecurityCheck",
            controller: "SecurityCheckController",
            controllerAs: "ctrl",
            templateUrl: "views/securityCheck.html",
          })
          .when("/securitycheck", {
            page: "SecurityCheck",
            controller: "SecurityCheckController",
            controllerAs: "ctrl",
            templateUrl: "views/securityCheck.html",
          })
          .when("/userdashboard", {
            page: "New Dashboard",
            controller: "UserDashboardController",
            controllerAs: "ctrl",
            templateUrl: "views/masterdashboard.html",
          })
          .when("/userdashboard/:licensemasterId", {
            page: "New Dashboard",
            controller: "UserDashboardController",
            controllerAs: "ctrl",
            templateUrl: "views/masterdashboard.html",
          })
          .when("/Organisations", {
            page: "Organisations",
            controller: "OrganisationsController",
            controllerAs: "ctrl",
            templateUrl: "views/organisations.html",
          })
          .when("/borrowertermsconditions", {
            page: "Borrower Terms and Conditions",
            controller: "BorrowerTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/borrowertermsandconditions.html",
          })
          .when("/termsofbusiness", {
            page: "Terms of Business",
            controller: "TermsOfBusinessController",
            controllerAs: "ctrl",
            templateUrl: "views/termsofbusiness.html",
          })
          .when("/privacypolicy", {
            page: "Privacy Policy",
            controller: "PrivacyController",
            controllerAs: "ctrl",
            templateUrl: "views/privacypolicy.html",
          })
          .when("/profile", {
            page: "Profile",
            controller: "ProfileController",
            controllerAs: "ctrl",
            templateUrl: "views/profile.html",
          })
         /* .when("/unsubscribe", {
            page: "Profile",
            controller: "ProfileController",
            controllerAs: "ctrl",
            templateUrl: "views/profile.html",
          })*/
          .when("/subscribe", {
            page: "Payment",
            controller: "PaymentController",
            controllerAs: "ctrl",
            templateUrl: "views/subscribe.html",
          })
          .when("/license/:licensemasterid", {
            page: "License",
            controller: "UserLicenseController",
            controllerAs: "ctrl",
            templateUrl: "views/userlicense.html",
          })
          .when("/managelicense", {
            page: "ManageLicense",
            controller: "ManageLicenseController",
            controllerAs: "ctrl",
            templateUrl: "views/managelicense.html",
          })
          //when('/userdashboard2', { page: "User Dashboard", controller: 'NewDashboardController', controllerAs: 'ctrl', templateUrl: 'views/newdashboard.html' }).
          .when("/addons", {
            page: "Addons",
            controller: "PaymentController",
            controllerAs: "ctrl",
            templateUrl: "views/addons.html",
          })
          .when("/bridgingdashboard", {
            page: "Bridging Dashboard",
            controller: "BridgingDashboardController",
            controllerAs: "ctrl",
            templateUrl: "views/bridgingdashboard.html",
          })
          // Enterprise version of the bridging criteria page (borrower only)
          .when("/e/bridgingcriteria", {
            page: "Bridging Criteria",
            controller: "BridgingCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/bridgingcriteria.html",
          })
          .when("/e/bridgingcriteria/:DealUniqueRef", {
            page: "Bridging Criteria",
            controller: "BridgingCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/bridgingcriteria.html",
          })
          .when("/e/bridgingcriteria/:DealUniqueRef/:linkId", {
            page: "Bridging Criteria",
            controller: "BridgingCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/bridgingcriteria.html",
          })
          .when("/bridgingcriteria", {
            page: "Bridging Criteria",
            controller: "BridgingCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/bridgingcriteria.html",
          })
          .when("/bridgingcriteria/:SearchId", {
            page: "Bridging Criteria",
            controller: "BridgingCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/bridgingcriteria.html",
          })
          .when("/bridgingcriteria/:SearchId/:CaseId", {
            page: "Bridging Criteria",
            controller: "BridgingCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/bridgingcriteria.html",
          })
          //when('/purchaserefinancecriteria', { page: "Bridging Purchase Or Refinance Criteria", controller: 'BridgingCriteriaController', controllerAs: 'ctrl', templateUrl: 'views/bridgingcriteria.html' }).
          //when('/purchaserefinancecriteria/:SearchId', { page: "Bridging Purchase Or Refinance Criteria", controller: 'BridgingCriteriaController', controllerAs: 'ctrl', templateUrl: 'views/bridgingcriteria.html' }).
          //when('/purchaserefinancecriteria/:SearchId/:CaseId', { page: "Bridging Purchase Or Refinance Criteria", controller: 'BridgingCriteriaController', controllerAs: 'ctrl', templateUrl: 'views/bridgingcriteria.html' }).
          //when('/purchaserefinancecriteria/:SearchId/:CaseId/:StepNumber', { page: "Bridging Purchase Or Refinance Criteria", controller: 'BridgingCriteriaController', controllerAs: 'ctrl', templateUrl: 'views/bridgingcriteria.html' }).
          .when("/bridgingcriteriadepreciated", {
            page: "Bridging Criteria",
            controller: "BridgingCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/bridgingcriteriadepreciated.html",
          })
          .when("/bridgingcasedashboard/:DealId", {
            page: "Bridging Case Dashboard",
            controller: "BridgingCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/bridgingcasedashboard/:DealId/:Promote", {
            page: "Bridging Case Dashboard",
            controller: "BridgingCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/bridgingcasedashboard/:DealId/:Promote/:NewShortlist/:SearchChanged", {
            page: "Bridging Case Dashboard",
            controller: "BridgingCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/sharedbridgingcasedashboard/:DealId/:selectedNavMenu", {
            page: "Bridging Case Dashboard",
            controller: "BridgingCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/bridgingresults", {
            page: "Bridging Results",
            controller: "BridgingLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/bridgingresults/:SearchId", {
            page: "Lender Results",
            controller: "BridgingLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/e/bridgingresults/:SearchId", {
            page: "Lender Results",
            controller: "BridgingLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/bridgingshortlistmore/:SearchId", {
            page: "Lender Results",
            controller: "BridgingLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/referredsearchdeal/:dealclientuniqueref", {
            page: "Lender Results",
            controller: "BridgingLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          //Added a new dealforum route which can handle all type of deals but keeping this too, so that we can handle the old email links
          .when("/bridgingdealforum/:DealId", {
            page: "BridgingLending",
            controller: "DealLenderController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/lending.html",
          })
          .when("/bridgingheadsofterm/:DealId/:DealLenderId", {
            page: "Heads Of Terms",
            controller: "BridgingHeadsOfTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/headsofterms.html",
          })
          .when("/bridgingheadsofterm/:DealId/:DealLenderId/:openModule", {
            page: "Heads Of Terms",
            controller: "BridgingHeadsOfTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/headsofterms.html",
          })
          .when("/dealapplicationdetails/:DealId", {
            page: "ApplicationDetails",
            controller: "ApplicationDetailsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/applicationdetails.html",
          })
          .when("/referredsearchdeal/:dealclientuniqueref/:showOnlyRegister", {
            page: "Lender Results",
            controller: "BridgingLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/referredsearchcommercial/:dealclientuniqueref", {
            page: "Lender Results",
            controller: "CommercialLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when(
            "/referredsearchcommercial/:dealclientuniqueref/:showOnlyRegister",
            {
              page: "Lender Results",
              controller: "CommercialLenderResultScreenController",
              controllerAs: "ctrl",
              templateUrl: "views/lenderresultsnew.html",
            },
          )
          //when('/bridgingsecuritycheck/:uniqueId', { page: "SecurityCheck", controller: 'SecurityCheckController', controllerAs: 'ctrl', templateUrl: 'views/securityCheck.html' }).
          .when("/bridgingsecuritycheck/:uniqueId/:orgUniqueRef", {
            page: "SecurityCheck",
            controller: "SecurityCheckController",
            controllerAs: "ctrl",
            templateUrl: "views/securityCheck.html",
          })
          //Added a new dealregistershareholder route which can handle all type of deals but keeping this too, so that we can handle the old email links
          .when("/bridgingregistershareholder/:uniqueId", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when("/bridgingregistershareholder/:uniqueId/:brokerOrgUniqueRef", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when("/borrowerinitialregistration/:Type", {
            page: "InitialRegistration",
            controller: "InitialRegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/initialregistrationborrower.html",
          })
          .when("/allloans", {
            page: "All Loans Landing Page",
            controller: "AllLoansLandingController",
            controllerAs: "ctrl",
            templateUrl: "views/allloanslandingpage.html",
          })
          //when('/borrowerinitialregistration/:Type/:linkId', { page: "InitialRegistration", controller: 'InitialRegistrationController', controllerAs: 'ctrl', templateUrl: 'views/initialregistrationborrower.html' }).
          .when("/allloans/:linkId", {
            page: "All Loans Landing Page",
            controller: "AllLoansLandingController",
            controllerAs: "ctrl",
            templateUrl: "views/allloanslandingpage.html",
          })
          .when("/allloans/:linkId/:context", {
            page: "All Loans Landing Page",
            controller: "AllLoansLandingController",
            controllerAs: "ctrl",
            templateUrl: "views/allloanslandingpage.html",
          })
          .when("/newsearchselection", {
            page: "New Search Selection",
            controller: "NewSearchSelectionsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/UserDashboard/newsearchselection.html",
          })
          .when("/newsearchselection/:Type", {
            page: "New Search Selection",
            controller: "NewSearchSelectionsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/UserDashboard/newsearchselection.html",
          })
          //when('/previewallloans', { page: "All Loans Landing Page", controller: 'AllLoansLandingController', controllerAs: 'ctrl', templateUrl: 'views/allloanslandingpage.html' }).
          .when("/dealfeedback/:DealLenderId/:FeedBackId", {
            page: "FeedBack Page",
            controller: "FeedBackController",
            controllerAs: "ctrl",
            templateUrl: "views/feedback.html",
          })
          .when("/casefeedback/:DealLenderId/:FeedBackId", {
            page: "FeedBack Page",
            controller: "FeedBackController",
            controllerAs: "ctrl",
            templateUrl: "views/feedback.html",
          })
          //For testing without going through forms
          .when("/testlenderintro", {
            page: "Test Lender Intro",
            controller: "",
            controllerAs: "ctrl",
            templateUrl: "views/partials/criteria/intro.html",
          })
          //for testing maintenance page
          .when("/scheduledmaintenance", {
            page: "Scheduled Maintenance Page",
            controller: "",
            controllerAs: "ctrl",
            templateUrl: "views/scheduledmaintenance.html",
          })
          //COMMERCIAL
          .when("/commercialcriteria", {
            page: "Commercial Criteria",
            controller: "CommercialCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/commercialcriteria.html",
          })
          .when("/commercialcriteria/:SearchId", {
            page: "Commercial Criteria",
            controller: "CommercialCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/commercialcriteria.html",
          })
          // Enterprise version of the commercial criteria page (borrower only)
          .when("/e/commercialcriteria", {
            page: "Commercial Criteria",
            controller: "CommercialCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/commercialcriteria.html",
          }).when("/e/commercialcriteria/:DealUniqueRef", {
            page: "Commercial Criteria",
            controller: "CommercialCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/commercialcriteria.html",
          })
          .when("/e/commercialcriteria/:DealUniqueRef/:linkId", {
            page: "Commercial Criteria",
            controller: "CommercialCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/commercialcriteria.html",
          })
          .when("/commercialresults", {
            page: "Commercial Results",
            controller: "CommercialLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/commercialresults/:DealId", {
            page: "Commercial Results",
            controller: "CommercialLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/e/commercialresults/:DealId", {
            page: "Enterprise Commercial Results",
            controller: "CommercialLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/commercialshortlistmore/:DealId", {
            page: "Commercial Results",
            controller: "CommercialLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/commercialcasedashboard/:DealId", {
            page: "Commercial Case Dashboard",
            controller: "CommercialCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/commercialcasedashboard/:DealId/:Promote", {
            page: "Commercial Case Dashboard",
            controller: "CommercialCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/commercialcasedashboard/:DealId/:Promote/:NewShortlist/:SearchChanged", {
            page: "Commercial Case Dashboard",
            controller: "CommercialCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/dealforum/:DealId", {
            page: "Deal forum",
            controller: "DealLenderController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/lending.html",
          })
          .when("/commercialheadsofterm/:DealId/:DealLenderId", {
            page: "DIP Page",
            controller: "CommercialHeadsOfTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/headsofterms.html",
          })
          .when("/commercialheadsofterm/:DealId/:DealLenderId/:openModule", {
            page: "DIP Page",
            controller: "CommercialHeadsOfTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/headsofterms.html",
          })
          .when("/dealregistershareholder/:uniqueId", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when("/dealregistershareholder/:uniqueId/:brokerOrgUniqueRef", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when("/dealsecuritycheck/:uniqueId", {
            page: "DealSecurityCheckController",
            controller: "DealSecurityCheckController",
            controllerAs: "ctrl",
            templateUrl: "views/securityCheck.html",
          })
          .when("/dealsecuritycheck/:uniqueId/:orgUniqueRef", {
            page: "DealSecurityCheckController",
            controller: "DealSecurityCheckController",
            controllerAs: "ctrl",
            templateUrl: "views/securityCheck.html",
          })
          .when("/sharedcommercialcasedashboard/:DealId/:selectedNavMenu", {
            page: "Commercial Case Dashboard",
            controller: "CommercialCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          //DEV FINANCE DEAL
          .when("/devfinancecriteria", {
            page: "Development Finance Criteria",
            controller: "DevFinanceCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/devfinancecriteria.html",
          })
          .when("/devfinancecriteria/:SearchId", {
            page: "Development Finance Criteria",
            controller: "DevFinanceCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/devfinancecriteria.html",
          })
          // Enterprise version of the commercial criteria page (borrower only)
          .when("/e/devfinancecriteria", {
            page: "Development Finance Criteria",
            controller: "DevFinanceCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/devfinancecriteria.html",
          })
          .when("/e/devfinancecriteria/:DealUniqueRef", {
            page: "Development Finance Criteria",
            controller: "DevFinanceCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/devfinancecriteria.html",
          })
          .when("/e/devfinancecriteria/:DealUniqueRef/:linkId", {
            page: "Development Finance Criteria",
            controller: "DevFinanceCriteriaController",
            controllerAs: "ctrl",
            templateUrl: "views/devfinancecriteria.html",
          })
          .when("/devfinanceresults", {
            page: "Development Finance Results",
            controller: "DevFinanceLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/devfinanceresults/:DealId", {
            page: "Development Finance Results",
            controller: "DevFinanceLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/e/devfinanceresults/:DealId", {
            page: "Enterprise Development Finance Results",
            controller: "DevFinanceLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/devfinanceshortlistmore/:DealId", {
            page: "Development Finance Results",
            controller: "DevFinanceLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when("/devfinancecompare/:DealId", {
            page: "Development Finance Comparison",
            controller: "ComparisonController",
            controllerAs: "ctrl",
            templateUrl: "views/comparison.html",
          })
          .when("/devfinancecasedashboard/:DealId", {
            page: "Development Finance Case Dashboard",
            controller: "DevFinanceCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/devfinancecasedashboard/:DealId/:Promote", {
            page: "Development Finance Case Dashboard",
            controller: "DevFinanceCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/devfinancecasedashboard/:DealId/:Promote/:NewShortlist/:SearchChanged", {
            page: "Development Finance Case Dashboard",
            controller: "DevFinanceCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/shareddevfinancecasedashboard/:DealId/:selectedNavMenu", {
            page: "Development Finance Case Dashboard",
            controller: "DevFinanceCaseController",
            controllerAs: "ctrl",
            templateUrl: "views/dealdashboard.html",
          })
          .when("/devfinanceheadsofterm/:DealId/:DealLenderId", {
            page: "DIP Page",
            controller: "DevFinanceHeadsOfTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/headsofterms.html",
          })
          .when("/devfinanceheadsofterm/:DealId/:DealLenderId/:openModule", {
            page: "DIP Page",
            controller: "DevFinanceHeadsOfTermsController",
            controllerAs: "ctrl",
            templateUrl: "views/partials/headsofterms.html",
          })
          .when("/referredsearchdevfinance/:dealclientuniqueref", {
            page: "Lender Results",
            controller: "DevFinanceLenderResultScreenController",
            controllerAs: "ctrl",
            templateUrl: "views/lenderresultsnew.html",
          })
          .when(
            "/referredsearchdevfinance/:dealclientuniqueref/:showOnlyRegister",
            {
              page: "Lender Results",
              controller: "DevFinanceLenderResultScreenController",
              controllerAs: "ctrl",
              templateUrl: "views/lenderresultsnew.html",
            },
          )
          .when("/registerwithdeal/:dealclientuniqueref", {
            page: "Registration",
            controller: "RegistrationController",
            controllerAs: "ctrl",
            templateUrl: "views/newregistration.html",
          })
          .when(
            "/registerwithdeal/:dealclientuniqueref/:brokerOrgUniqueRef/:brokerUserId",
            {
              page: "Registration",
              controller: "RegistrationController",
              controllerAs: "ctrl",
              templateUrl: "views/newregistration.html",
            },
          )
          .when("/e/enterpriseleadcapture/:uniqueRef", {
            page: "Enterprise lead capture page",
            controller: "EnterpriseLeadCaptureController",
            controllerAs: "ctrl",
            templateUrl: "views/enterpriseleadcapture.html",
          })
          .when("/e/enterpriseleadcapture/:uniqueRef/:context", {
            page: "Enterprise lead capture page",
            controller: "EnterpriseLeadCaptureController",
            controllerAs: "ctrl",
            templateUrl: "views/enterpriseleadcapture.html"
          })
          .when("/reacttest", {
            page: "React Test",
            templateUrl: "views/reacttest.html",
          })
          .when('/managepreferences', { 
            page: "Profile", 
            controller: 'ProfileController', 
            controllerAs: 'ctrl', 
            templateUrl: 'views/profile.html' 
          })
          .when('/unsubscribe/:emailCode/:userId', { 
            page: "UnsubscribeEmail", 
            controller: 'UnsubscribeEmailController', 
            controllerAs: 'ctrl', 
            templateUrl: 'views/unsubscribeemail.html' 
          })
          //End testing section
          .otherwise({ redirectTo: "/" });

        if (
          window &&
          window.location &&
          window.location.pathname &&
          window.location.pathname.startsWith("/otmx")
        ) {
          $routeProvider.when("/", {
            page: "Landing",
            controller: "LandingController",
            controllerAs: "ctrl",
            templateUrl: "views/landing.html",
          });
        } else if (
          window &&
          window.location &&
          window.location.pathname &&
          window.location.pathname.startsWith("/lnhn")
        ) {
          $routeProvider.when("/", {
            page: "Landing",
            controller: "LandingController",
            controllerAs: "ctrl",
            templateUrl: "views/landinglnhn.html",
          });
        } else {
          $routeProvider.when("/", {
            page: "Welcome",
            controller: "LandingV3Controller",
            controllerAs: "ctrl",
            templateUrl: "views/landing-default.html",
          });
        }
      },
    ])
    .run(
      function (
        $rootScope,
        $injector,
        $cookies,
        $location,
        $http,
        $anchorScroll,
        $routeParams,
        toaster,
      ) {
        var history = [];

        $rootScope.$on("$routeChangeStart", function () {
          //let orgCode = $cookies.get("org_code");
          //console.log("Cooookiieeee: " + orgCode);
          gtag("set", "page_path", $location.$$path);
          gtag("set", "page_title", "Brickflow - " + $location.$$path);

          // Set OTMX introducer code unless we already have an explicit introducer code
          if ($location.$$normalizeUrl().endsWith("otmx")) {
            if (!$location.$$search || !$location.$$search.introducercode) {
              var expiryDate = new Date();
              expiryDate.setDate(expiryDate.getDate() + 100);
              $cookies.put(
                "introducer_code",
                "ce314d09-0317-403b-9096-9bdf359fbfbb",
                { expires: expiryDate },
              );
            }
          }
          // Set LNHN introducer code unless we already have an explicit introducer code
          else if ($location.$$normalizeUrl().endsWith("lnhn")) {
            if (!$location.$$search || !$location.$$search.introducercode) {
              var expiryDate = new Date();
              expiryDate.setDate(expiryDate.getDate() + 100);
              $cookies.put(
                "introducer_code",
                "5d14625b-fdbf-4a16-a874-9cc11bdcaff6",
                { expires: expiryDate },
              );
            }
          }
        });

        $rootScope.$on("$routeChangeSuccess", function () {
          // Send pageview to Google, only if we're on production.
          if ($location.$$host === "app.brickflow.com") {
            gtag("event", "routeChange", { page_path: $location.$$path });

            gtag("event", "page_view", {
              page_title: "Brickflow - " + $location.$$path,
              page_location: $location.$$absUrl,
              page_path: $location.$$path,
            });

            if ($location.$$path === "/results") {
              // Google tagging for conversion tracking.
              // n.b. Do we want to call this every time, or only once per user?
              gtag("event", "conversion", {});
            }
          }

          if ($location.$$path === "/" && $cookies.get("access_token")) {
            $location.path("/userdashboard");
          }

          if (
            $cookies.get("access_token") &&
            ($location.$$path.startsWith("/initialregister") ||
              $location.$$path.startsWith("/promo") ||
              $location.$$path.startsWith("/addons") ||
              $location.$$path == "/registerbroker" ||
              $location.$$path == "/register" ||
              $location.$$path.startsWith("/e/bridgingcriteria") ||
              $location.$$path.startsWith("/e/commercialcriteria") ||
              $location.$$path.startsWith("/e/criteria") ||
              $location.$$path.startsWith("/e/bridgingresults") ||
              $location.$$path.startsWith("/e/commercialresults") ||
              $location.$$path.startsWith("/e/enterpriseleadcapture")
            )
          ) {
            $location.path("/userdashboard");
          }

          if (
            $location.$$path !== undefined &&
            $location.$$path !== "/login" &&
            $location.$$path !== "/registerbroker" &&
            $location.$$path !== "/landing" &&
            $location.$$path !== "/welcome" &&
            $location.$$path !== "/agentlanding" &&
            !$location.$$path.startsWith("/login") &&
            !$location.$$path.startsWith("/e/login") &&
            $location.$$path !== "/register" &&
            !$location.$$path.startsWith("/registerintroducer") &&
            !$location.$$path.startsWith("/registerbroker") &&
            !$location.$$path.startsWith("/registerborrower") &&
            !$location.$$path.startsWith("/registershareholder") &&
            !$location.$$path.startsWith("/bridgingregistershareholder") &&
            !$location.$$path.startsWith("/dealregistershareholder") &&
            $location.$$path !== "/introducer/new" &&
            !$location.$$path.startsWith("/r/") &&
            $location.$$path !== "/register/enquire" &&
            $location.$$path !== "/register/startprojectappraisal" &&
            $location.$$path !== "/register/applyfordevloan" &&
            !$location.$$path.startsWith("/register/invite") &&
            $location.$$path !== "/olddashboard" &&
            $location.$$path !== "/criteria" &&
            !$location.$$path.startsWith("/e/criteria") &&
            $location.$$path !== "/i" &&
            $location.$$path !== "/criteria/0/0/1" &&
            $location.$$path !== "/criteria/0/0/2" &&
            $location.$$path !== "/criteria/0/0/3" &&
            $location.$$path !== "/bridgingcriteria" &&
            !$location.$$path.startsWith("/e/bridgingcriteria") &&
            !$location.$$path.startsWith("/e/commercialcriteria") &&
            $location.$$path !== "/results" &&
            !$location.$$path.startsWith("/compare") &&
            !$location.$$path.startsWith("/devfinancecompare") &&
            $location.$$path !== "/compare" &&
            $location.$$path !== "/exitfinance" &&
            $location.$$path !== "/exitloanresults" &&
            $location.$$path !== "/exitloancompare" &&
            //testing pages without filling in form
            $location.$$path !== "/testlenderintro" &&
            //end testing section
            $location.$$path !== "/refer" &&
            !$location.$$path.startsWith("/resultsExternalAPI") &&
            !$location.$$path.startsWith("/referredSearch") &&
            !$location.$$path.startsWith("/referredsearchdeal") &&
            !$location.$$path.startsWith("/referredsearchcommercial") &&
            !$location.$$path.startsWith("/referredsearchdevfinance") &&
            $location.$$path !== "/" &&
            $location.$$path !== "/paysuccess" &&
            $location.$$path !== "/landingV3" &&
            $location.$$path !== "/termsofbusiness" &&
            $location.$$path !== "/privacypolicy" &&
            !$location.$$path.startsWith("/initialregister") &&
            !$location.$$path.startsWith("/borrowerinitialregistration") &&
            //!$location.$$path.startsWith("/borrowerinitialregistration/all") &&
            !$location.$$path.startsWith(
              "/borrowerinitialregistration/bridging",
            ) &&
            !$location.$$path.startsWith("/promo") &&
            !$location.$$path.startsWith("/addons") &&
            !$location.$$path.startsWith("/registerwithsearch") &&
            !$location.$$path.startsWith("/registerwithbridgingsearch") &&
            !$location.$$path.startsWith("/registerwithdeal") &&
            $location.$$path !== "/bridgingresults" &&
            !$location.$$path.startsWith("/newsearchselection") &&
            //!$location.$$path.startsWith("/previewallloans") &&
            !$location.$$path.startsWith("/e/bridgingresults") &&
            !$location.$$path.startsWith("/allloans") &&
            !$location.$$path.startsWith("/e/commercialresults") &&
            !$location.$$path.startsWith("/e/devfinancecriteria") &&
            !$location.$$path.startsWith("/e/devfinanceresults") &&
            !$location.$$path.startsWith("/devfinancecriteria") &&
            !$location.$$path.startsWith("/devfinanceresults") &&
            !$location.$$path.startsWith("/e/enterpriseleadcapture") &&
            !$location.$$path.startsWith("/unsubscribe")
          ) {
            if ($cookies.get("access_token") && $http) {
              $http.defaults.headers.common.Authorization =
                "Bearer " + $cookies.get("access_token");
            }

            if (
              $cookies.get("access_token") === null ||
              $cookies.get("access_token") === undefined
            ) {
              //toaster.pop('info', "Login Required", "Please login to use the system.");
              $rootScope.loginRedirectPath = $location.$$path;
              $location.path("/login");
            }
          }
          if (history[history.length - 1] !== $location.$$path) {
            $rootScope.previousRoute =
              history.length > 1 ? history.splice(-1)[0] : "/";
            history.push($location.$$path);
          }
        });

        $rootScope.$on(
          "$routeChangeSuccess",
          function (event, currentRoute, previousRoute) {
            $location.hash($routeParams.scrollTo);
            $anchorScroll();
            window.scrollTo(0, 0);
          },
        );

        var goingBack = false;
        $rootScope.back = function () {
          if (!goingBack) {
            goingBack = true;
            var prevUrl = history.length > 1 ? history.splice(-2)[0] : "/";
            $location.path(prevUrl);
            goingBack = false;
          }
        };

        // listen for route changes.
        //$rootScope.$on("$routeChangeStart", function (event, next, current) {
        //    if ($location.$$path !== undefined && next.templateUrl !== "/" && $location.$$path !== "/login") {
        //        if ($cookies.get('access_token') && $http) {
        //            $http.defaults.headers.common.Authorization = 'Bearer ' + $cookies.get('access_token');
        //        }
        //        if (($cookies.get('access_token') === null || $cookies.get('access_token') === undefined)) {
        //            toaster.pop('info', "Login Required", "Please login to use the system.");
        //            $location.path("/login");
        //        }
        //    }
        //});

        //    if (next.templateUrl !== undefined) {
        //        // If the user is not logged in, or the token has expired, let's go back to login.
        //        if ($cookies.loggedUser === null || $cookies.loggedUser === undefined || $cookies.token === null) {
        //            if (next.templateUrl !== "views/login.html" && next.templateUrl !== "unsubscribe.html") {
        //                // redirect
        //                toaster.pop('info', "Login Required", "Please login to use the system.");
        //                $location.path("/login");
        //            }
        //        }
        //        else if (new Date() > Date($cookies.tokenValidTo)) {
        //            if (next.templateUrl !== "views/login.html") {
        //                toaster.pop('warning', "Session Timed out", "Please login to use the system.");
        //                $location.path("/login");
        //            }
        //        }
        //    }
        //        // See if we're going to login when we don't need to.
        //    else {
        //        if (next.templateUrl === "views/login.html") {
        //            $location.path("/dashboard");
        //        }
        //    }
        //});
      },
    );
}

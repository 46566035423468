import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class ClientService extends BaseService<ClientDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
    "DevelopmentInputService",
    "UserService",
    "RoleService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    private rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/client";
    this.$broadcastBusinessNameSingular = "client";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  /**
   * Fetch list of ClientDtos of the clients on the Deal
   * @param dealId
   */
  fetchListByDealId(dealId: number): ng.IPromise<ClientDTO[]> {
    let defer = this.$q.defer<ClientDTO[]>();

    this.$http
      .get(this.$baseAddress + "/fetchbydealid?dealId=" + dealId)
      .then((response) => {
        defer.resolve(response.data as ClientDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Fetch ClientDto for the DealClient.UniqueRef
   * @param dealId
   */
  fetchByDealClientUniqueRef(
    dealClientUniqueRef: string,
  ): ng.IPromise<ClientDTO> {
    let defer = this.$q.defer<ClientDTO>();

    this.$http
      .get(
        this.$baseAddress +
        "/fetchbydealclientuniqueref?dealClientUniqueRef=" +
        dealClientUniqueRef,
      )
      .then((response) => {
        defer.resolve(response.data as ClientDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Schedule Search Abandoned Email
   * @param clientId
   * @param organisationId
   */
  scheduleSearchAbandonedEmail(clientId, organisationId): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
        "/schedulesearchabandonedemail?clientId=" +
        clientId +
        "&organisationId=" +
        organisationId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  updateClientWithUserId(clientId): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
        "/updateclientwithuserid?clientId=" +
        clientId,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

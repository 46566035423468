import { DevelopmentFinanceDealDTO } from "@js/DTO/Deal/DevelopmentFinanceDealDTO.cs.d";
import { SaveDevFinanceSearchRequest } from "@js/DTO/Messages/Deal/SaveDevFinanceSearchMessage.cs.d";
import {
  UpdateDevFinanceSearchGetResultRequest,
  UpdateDevFinanceSearchGetResultResponse,
} from "@js/DTO/Messages/Deal/UpdateDevFinanceSearchGetResultMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { BuildProcurementTypeEnum } from "@js/models/enum/BuildProcurementTypeEnum.cs.d";
import { ExitStrategyEnum } from "@js/models/enum/ExitStrategyEnum.cs.d";
import { LoanCompletionTypeEnum } from "@js/models/enum/LoanCompletionTypeEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { PlanningPermissionTypeEnum } from "@js/models/enum/PlanningPermissionTypeEnum.cs.d";
import { PlanningResponsibilityEnum } from "@js/models/enum/PlanningResponsibilityEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { TimeLeftOnLeaseEnum } from "@js/models/enum/TimeLeftOnLeaseEnum.cs.d";
import { SaveSearchReturnUniqueRefResponse } from "@js/models/SaveSearchReturnUniqueRef.cs.d";

export class DevFinanceDealService extends BaseService<
  DevelopmentFinanceDealDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/devfinancedeal";
    this.$broadcastBusinessNameSingular = "devfinancedeal";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  /**
   * Updates the search Deal based on the DevelopmentFinanceDealDTO and returns the search results on the new criteria
   * @param criteriaDto
   * @param criteriaChanged only set to true when the search criteria changes, and not the filters. This controls data snapshotting.
   */
  updateSearchCriteriaAndGetResults(
    criteriaDto: DevelopmentFinanceDealDTO,
    criteriaChanged: boolean = false,
    debug1: boolean,
    isPostcodeChanged: boolean = false,
    isShortlistingMore: boolean = false,
  ): ng.IPromise<UpdateDevFinanceSearchGetResultResponse> {
    let defer = this.$q.defer<UpdateDevFinanceSearchGetResultResponse>();

    var request: UpdateDevFinanceSearchGetResultRequest = {
      DevFinanceDealDto: criteriaDto,
      CriteriaChanged: criteriaChanged,
      Limit: 0, //TODO: typings aren't picking up default values
      Debug1: debug1, //TODO: typings aren't picking up default values
      Debug2: false, //TODO: typings aren't picking up default values
      IsPostcodeChange: isPostcodeChanged,
      IsShortlistingMore:isShortlistingMore
    };

    this.$http
      .post(
        this.$baseAddress + "/updatesearchandgetresults",
        JSON.stringify(request),
      )
      .then((response) => {
        var res = response.data as UpdateDevFinanceSearchGetResultResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveDevFinanceSearchReturnsId(
    request: SaveDevFinanceSearchRequest,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.$http
      .post(this.$baseAddress + "/savedevfinancesearchreturnsId", request)
      .then((response) => {
        var res = response.data as number;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveDevFinanceSearchReturnsUniqueRef(
    request: SaveDevFinanceSearchRequest,
  ): ng.IPromise<SaveSearchReturnUniqueRefResponse> {
    let defer = this.$q.defer<SaveSearchReturnUniqueRefResponse>();

    this.$http
      .post(
        this.$baseAddress + "/savedevfinancesearchreturnsuniqueref",
        request,
      )
      .then((response) => {
        var res = response.data as SaveSearchReturnUniqueRefResponse;
        defer.resolve(res);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  debugSearch(dealDto: DevelopmentFinanceDealDTO): ng.IPromise<[]> {
    let defer = this.$q.defer<[]>();
    this.$http
      .get(this.$baseAddress + "/debugSearch")
      .then((response) => {
        defer.resolve(response.data as []);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  clearSearchdata(dealDto) {
    let tempDealDto = { ...dealDto };
    dealDto = {} as DevelopmentFinanceDealDTO;
    dealDto.Id = tempDealDto.Id;
    dealDto.HasIsFamilyInResidence = tempDealDto.HasIsFamilyInResidence;
    dealDto.IsFamilyInResidence = tempDealDto.IsFamilyInResidence;
    dealDto.PropertyAddress = tempDealDto.PropertyAddress;
    dealDto = this.initialDataSetUpForDeal(dealDto);
    return dealDto;
  }

  initialDataSetUpForDeal(dealDto: DevelopmentFinanceDealDTO) {
    dealDto.MaxLoanRequired = true;
    dealDto.ProductType = ProductTypeEnum.Development;
    dealDto.ProductFamily = ProductFamilyEnum.Development;
    dealDto.OwnOrPurchase = OwnOrPurchaseEnum.Purchasing;
    dealDto.EndPropertyType = PropertyTypeEnum.Residential;
    dealDto.LoanCompletionType = LoanCompletionTypeEnum.JustCheckingMyOptions;
    dealDto.ShowLenderInfoBrokerOverride = true;
    dealDto.hasPostcode = true;
    dealDto.PlanningStatusAtEndOfLoan =
      PlanningPermissionTypeEnum.DetailedPlanning;
    dealDto.PlanningResponsibility = PlanningResponsibilityEnum.Borrower;
    dealDto.BuildProcurementType = BuildProcurementTypeEnum.TraditionalContract;
    dealDto.ExitStrategy = ExitStrategyEnum.Sale;
    dealDto.HasOwnOrPurchase = false;

    return dealDto;
  }

  datasetupOnOwnOrPurchaseChange(dealDto) {
    if (
      dealDto.OwnOrPurchase != null &&
      dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing
    ) {
      dealDto.OriginalPurchaseDate = null;
      dealDto.Currentvalue = null;
      dealDto.LandTotalOtherCosts = 0;
    }

    if (
      dealDto.OwnOrPurchase != null &&
      dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own
    ) {
      dealDto.PurchaseCosts = 0;
    }
    return dealDto;
  }

  datasetupOnCurrentUseOfBuilding(dealDto) {
    if (
      dealDto.CurrentPropertyType != null &&
      dealDto.CurrentPropertyType == PropertyTypeEnum.HMO
    ) {
      dealDto.UnitCount = null;
      dealDto.TimeLeftOnLease = TimeLeftOnLeaseEnum.OneToThreeYears;
    } else if (
      dealDto.CurrentPropertyType != null &&
      dealDto.CurrentPropertyType == PropertyTypeEnum.MultiUnitFreeholdBlock
    ) {
      dealDto.BedroomCount = null;
      dealDto.TimeLeftOnLease = TimeLeftOnLeaseEnum.OneToThreeYears;
    } else if (
      dealDto.CurrentPropertyType != null &&
      dealDto.CurrentPropertyType != PropertyTypeEnum.MultiUnitFreeholdBlock &&
      dealDto.CurrentPropertyType != PropertyTypeEnum.HMO
    ) {
      dealDto.BedroomCount = null;
      dealDto.UnitCount = null;
    }
    return dealDto;
  }

  datasetupOnIsTenantedBuildingChange(dealDto: DevelopmentFinanceDealDTO) {
    if (!dealDto.IsTenantedBuilding) {
      dealDto.NumberOfTenancies = null;
    }
    return dealDto;
  }
}

export const enum MaritalStatusEnum {
  None = 0,
  Single = 1,
  Married = 2,
  LivingWithPartner = 3,
  Widowed = 4,
  CivilPartnership = 5,
  Divorced = 6,
  Seperated = 7,
}

export const enum ReferrerEnum {
  GoogleSearch = 1,
  OtherSearch = 2,
  LinkedIn = 3,
  Facebook = 4,
  Agent = 5,
  ExistingUser = 6,
  BrickflowConsultant = 7,
  Broker = 8,
  Developer = 9,
  Other = 10,
  MassReports = 11,
  NimbusMaps = 12,
  /** NotSure = 13, */
  PressRelease = 13,
  TheRodcast = 14,
  OTM = 15,
  WordOfMouth = 16,
  PersonalRecommendation = 17,
  Ad = 18,
  SocialMedia = 19,
  FIBA = 20,
}

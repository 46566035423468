import { BridgingDealDTO } from "@js/DTO/Deal/BridgingDealDTO.cs.d";
import { CommercialDealDTO } from "@js/DTO/Deal/CommercialDealDTO.cs.d";
import { DevelopmentFinanceDealDTO } from "@js/DTO/Deal/DevelopmentFinanceDealDTO.cs.d";
import { LocationEnum } from "@js/models/enum/LocationEnum.cs.d";
import { DealService } from "@js/services/Deal/DealService";

export class CriteriaController<
  T extends BridgingDealDTO | CommercialDealDTO | DevelopmentFinanceDealDTO,
> {
  dealDto: T;
  dataLoading: boolean = false;

  //Postcode properties
  showPostcodeErrorMessage: boolean = false;
  postcodeErrorMsg: string;
  previouslySelectedLocation: LocationEnum;

  static $inject = ["DealService"];

  constructor(public dealService: DealService) {}

  getRegionByPostcode() {
    if (
      this.dealDto.PostcodeSearchString &&
      this.dealDto.PostcodeSearchString.replace(" ", "").length >= 2
    ) {
      if (
        this.dealService.isValidPostcodeString(
          this.dealDto.PostcodeSearchString,
        )
      ) {
        this.dataLoading = true;
        this.dealService
          .getRegionByPostcode(this.dealDto.PostcodeSearchString)
          .then((response) => {
            if (response.Location != null) {
              this.dealDto.Locations = response.Location;
              this.showPostcodeErrorMessage = false;
            } else {
              this.postcodeErrorMsg = response.PostCodeErrorMessage;
              this.showPostcodeErrorMessage = true;
              if (this.dealDto.Locations) this.dealDto.Locations = null;
            }
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.postcodeErrorMsg =
          "At least one letter and one number must be entered e.g. SW1.";
        this.showPostcodeErrorMessage = true;
        if (this.dealDto.Locations) this.dealDto.Locations = null;
      }
    } else {
      if (this.dealDto.Locations) this.dealDto.Locations = null;
    }
  }

  onHasPostcodeChange() {
    if (!this.dealDto.hasPostcode) {
      this.showPostcodeErrorMessage = false;
      this.dealDto.PostcodeSearchString = null;
      this.dealDto.Locations = this.previouslySelectedLocation;
    } else {
      this.previouslySelectedLocation = this.dealDto.Locations;
      this.dealDto.Locations = null;
    }
  }
}

import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";

export class FileAttachmentController {
  selectedSection: string;

  objects: FileAttachmentDTO[];
  selectedObject: FileAttachmentDTO;

  fetchingObjects: boolean;
  fetchingObject: boolean;

  //addingSubobject: boolean;

  //subobjects: SubobjectDTO[];
  //selectedSubobject: SubobjectDTO;

  //fetchingSubobjects: boolean;
  //fetchingSubobject: boolean;

  fileattachmentForm: ng.IFormController;
  //subfileattachmentForm: ng.IFormController;

  static $inject = [
    "$routeParams",
    "FileAttachmentService",

    //'SubobjectService',
  ];
  //private $subobjectservice: App.Services.SubobjectService
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $fileattachmentservice: FileAttachmentService,
  ) {
    this.updateObjects();
  }

  updateObjects() {
    this.fetchingObjects = true;

    // Fetch all objects.
    this.$fileattachmentservice
      .fetchAll()
      .then((response) => {
        this.objects = response;
        this.preSelectedItem();
      })
      .finally(() => {
        this.fetchingObjects = false;
      });
  }

  private preSelectedItem() {
    if (this.$routeParams.Id) {
      let selectedObject = this.objects.find((value, index) => {
        return value.Id === parseInt(this.$routeParams.objectId);
      });

      this.selectObject(selectedObject);
    }
  }

  selectObject(object: FileAttachmentDTO) {
    this.selectedObject = object;

    //this.fetchingSubobjects = true;
    //this.$subobjectservice.fetchSubobjectsForObject(object.Id).then((response) => {
    //    this.subobjects = response;
    //    this.fetchingSubobjects = false;
    //}).catch((response) => {
    //    this.fetchingSubobjects = false;
    //});

    this.selectedSection = "details";
  }

  createObject() {
    this.selectedSection = "details";
    this.selectedObject = {} as FileAttachmentDTO;
  }

  save() {
    this.$fileattachmentservice
      .addUpdatereturnonlyid(this.selectedObject)
      .then((response) => {
        this.selectedObject.Id = response;
        if (!this.objects) {
          this.objects = [];
        }

        let matches: FileAttachmentDTO[] = this.objects.filter(
          (value, index) => {
            return value.Id == response;
          },
        );

        if (!matches || matches.length === 0) {
          this.objects.push(this.selectedObject);
        }

        this.fileattachmentForm.$setPristine();
        this.updateObjects();
      });
  }

  delete() {
    this.$fileattachmentservice
      .delete(this.selectedObject.Id)
      .then((response) => {
        if (response) {
          this.objects.splice(this.objects.indexOf(this.selectedObject), 1);
          this.fileattachmentForm.$setPristine();
          delete this.selectedObject;
        }
      });
  }

  //addSubobject() {
  //    this.selectedSubobject = {
  //        AssociatedObjectName: this.selectedObject.Name
  //        AssociatedObjectId: this.selectedObject.Id,
  //    } as SubobjectDTO;
  //}

  //removeSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.deleteSubobject(subobject.Id).then((response) => {
  //        this.subobjects.splice(this.subobjects.indexOf(this.selectedSubobject), 1);
  //        delete this.selectedSubobject;
  //    });
  //}

  //cancelUpdateSubobject() {
  //    delete this.selectedSubobject;
  //}

  //updateSubobject(subobject: SubobjectDTO) {
  //    this.$subobjectservice.addUpdateSubobject(subobject).then((response) => {
  //        if (this.subobjects.indexOf(subobject) === -1) {
  //            this.subobjects.push(subobject);
  //        }
  //        delete this.selectedSubobject;
  //    }).catch((response) => {
  //        delete this.selectedSubobject;
  //    });

  //}
}

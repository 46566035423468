import { ApplicationUserBaseDTO } from "@js/DTO/ApplicationUserBaseDTO.cs.d";
import { RegistrationRequestDTO } from "@js/DTO/RegistrationRequestDTO.cs.d";

export class AuthServiceBase<T extends ApplicationUserBaseDTO> {
  testsite: boolean = null;
  calledtestsite: boolean = false;
  isLoadingTestSite: boolean = true
  constructor(
    protected $http: ng.IHttpService,
    protected $cookies: ng.cookies.ICookiesService,
    protected $rootScope: any,
    protected $q: ng.IQService,
    protected $timeout: ng.ITimeoutService,
    private $httpParamSerializerJQLike: ng.IHttpParamSerializer,
  ) {}

  // #region Base64
  base64encode(input: string): string {
    var keyStr =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    var output: any = "";
    var chr1: any,
      chr2: any,
      chr3: any = "";
    var enc1: any,
      enc2: any,
      enc3: any,
      enc4: any = "";
    var i = 0;
    do {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output =
        output +
        keyStr.charAt(enc1) +
        keyStr.charAt(enc2) +
        keyStr.charAt(enc3) +
        keyStr.charAt(enc4);
      chr1 = chr2 = chr3 = "";
      enc1 = enc2 = enc3 = enc4 = "";
    } while (i < input.length);
    return output;
  }

  base64decode(input: string): string {
    var keyStr =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output: any = "";
    var chr1: any,
      chr2: any,
      chr3: any = "";
    var enc1: any,
      enc2: any,
      enc3: any,
      enc4: any = "";
    var i = 0;
    // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
    var base64test = /[^A-Za-z0-9\+\/\=]/g;
    if (base64test.exec(input)) {
      window.alert(
        "There were invalid base64 characters in the input text.\n" +
          "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
          "Expect errors in decoding.",
      );
    }
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    do {
      enc1 = keyStr.indexOf(input.charAt(i++));
      enc2 = keyStr.indexOf(input.charAt(i++));
      enc3 = keyStr.indexOf(input.charAt(i++));
      enc4 = keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;
      output = output + String.fromCharCode(chr1);
      if (enc3 !== 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 !== 64) {
        output = output + String.fromCharCode(chr3);
      }
      chr1 = chr2 = chr3 = "";
      enc1 = enc2 = enc3 = enc4 = "";
    } while (i < input.length);
    return output;
  }
  // #endregion

  login(
    username: string,
    password: string,
    clientid: string,
  ): ng.IPromise<Date> {
    let defer = this.$q.defer<Date>();
    this.$cookies.remove("user_type");
    this.$cookies.remove("user_firstname");

    this.$http.defaults.headers.common["Authorization"] =
      "Basic " + this.base64encode(username + ":" + password);
    this.$http({
      method: "POST",
      url: "/oauth/token",
      data: this.$httpParamSerializerJQLike({
        grant_type: "password",
        username: username,
        password: password,
        client_id: clientid,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        let t = new Date();
        t.setSeconds(t.getSeconds() + (response.data as any).expires_in);

        this.$cookies.put("access_token", (response.data as any).access_token, {
          expires: t,
        });
        this.$cookies.put("access_token_expires", t.toISOString(), {
          expires: t,
        });
        this.$http.defaults.headers.common.Authorization =
          "Bearer " + (response.data as any).access_token;

        defer.resolve(t);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  setCredentials(username: string, password: string) {
    var authdata = this.base64encode(username + ":" + password);
    this.$rootScope["globals"] = {
      currentUser: {
        username: username,
        authdata: authdata,
      },
    };
    this.$http.defaults.headers.common["Authorization"] = "Basic " + authdata; // jshint ignore:line
    this.$cookies.putObject("globals", this.$rootScope["globals"]);
  }

  clearCredentials() {
    this.$rootScope["globals"] = {};
    this.$cookies.remove("globals");
    this.$cookies.remove("profile");
    this.$http.defaults.headers.common.Authorization = "Basic ";
  }

  registerAccount(newUser: RegistrationRequestDTO): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .post("/api/users/addupdate", JSON.stringify(newUser))
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        "/api/users/changepassword?oldPassword=" +
          encodeURIComponent(oldPassword) +
          "&newPassword=" +
          encodeURIComponent(newPassword),
      )
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }

  changeEmail(newEmail: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get("/api/users/changeemail?email=" + newEmail)
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }

  getProfile(): ng.IPromise<T> {
    let defer = this.$q.defer<T>();

    if (this.$cookies.getObject("profile")) {
      defer.resolve(this.$cookies.getObject("profile") as T);
    } else {
      this.$http
        .get("/api/users/me")
        .then((response) => {
          this.$cookies.putObject("profile", response.data);
          defer.resolve(response.data as T);
        })
        .catch((response) => {
          defer.reject(response);
        });
    }
    return defer.promise;
  }
  getadminProfile(): ng.IPromise<T> {
    let defer = this.$q.defer<T>();

    if (this.$cookies.getObject("profile")) {
      defer.resolve(this.$cookies.getObject("profile") as T);
    } else {
      this.$http
        .get("/api/users/meadminstatus")
        .then((response) => {
          this.$cookies.putObject("profile", response.data);
          defer.resolve(response.data as T);
        })
        .catch((response) => {
          defer.reject(response);
        });
    }
    return defer.promise;
  }

  getUserFirstname(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    if (this.$cookies.get("user_firstname")) {
      // We have this cached. Let's return it straight away.
      defer.resolve(this.$cookies.get("user_firstname") as string);
    } else {
      // Not cached, let's go fetch the data and cache it, then return.
      this.getProfile()
        .then((response) => {
          //this.$cookies.put('user_type', response.UserType);
          this.$cookies.put("user_firstname", response.FirstName);
          defer.resolve(response.FirstName);
        })
        .catch((response) => {
          defer.reject(response);
        });
    }
    return defer.promise;
  }
  logout(): void {
    //delete headers

    this.$http.defaults.headers.common["Authorization"] = undefined;

    //set to expire straight away as sometimes doesnt delete
    let t = new Date();
    t.setSeconds(t.getSeconds());

    this.$cookies.put("access_token", "", {
      expires: t,
    });
    this.$cookies.put("access_token_expires", t.toISOString(), {
      expires: t,
    });

    this.$cookies.remove("profile");
    this.$cookies.remove("access_token");
    this.$cookies.remove("access_token_expires");

    for (var x in this.$cookies.getAll()) {
      this.$cookies.remove(x, { path: "/" });
    }

    localStorage.clear();
  }

  resetPassword(userName: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get("/api/users/passwordreset?userName=" + userName)
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }
  public AmIATestSite(force: boolean = false) {
    if (this.testsite == null || force) {
      if (!this.testsite) {
        if (this.calledtestsite) {
          return false;
        }
        this.calledtestsite = true;
        this.isLoadingTestSite = true;
        this.callamIATestSite()
          .then((result) => {
            this.testsite = result;
          })
          .catch(() => {
            this.testsite = false;
          })
          .finally(() => {
            this.calledtestsite = false;
            this.isLoadingTestSite = false;
          });
      }
    } else {
      return this.testsite;
    }
  }
  private callamIATestSite(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get("/api/static/amiatestsite")
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(false);
      });
    return defer.promise;
  }
}

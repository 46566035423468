import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseLenderDTO } from "@js/DTO/CaseLenderDTO.cs.d";
import { CaseNoteDTO } from "@js/DTO/CaseNoteDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { ShareDealDTO } from "@js/DTO/Deal/SearchDealDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { LenderResultDTO } from "@js/DTO/DevelopmentFinance/LenderResultDTO.cs.d";
import { LenderResultMinimalDTO } from "@js/DTO/DevelopmentFinance/LenderResultMinimalDTO.cs.d";
import { LenderResultSummaryDTO } from "@js/DTO/DevelopmentFinance/LenderResultSummaryDTO.cs.d";
import { DevelopmentWithNoLoginDTO } from "@js/DTO/DevelopmentWithNoLoginDTO.cs.d";
import { LenderResultSetDTO } from "@js/DTO/LenderResultSet.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { SelectedResultDTO } from "@js/DTO/SelectedResultDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { CaseAccessLevelEnum } from "@js/models/enum/CaseAccessLevelEnum.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { EventLogEnum } from "@js/models/enum/EventLogEnum.cs.d";
import { LicenseMasterStatusEnum } from "@js/models/enum/LicenseMasterStatusEnum.cs.d";
import { MaxFlatUnitPriceEnum } from "@js/models/enum/MaxFlatUnitPriceEnum.cs.d";
import { MaxHouseSalePriceEnum } from "@js/models/enum/MaxHouseSalePriceEnum.cs.d";
import { MaxSqFtSalePriceEnum } from "@js/models/enum/MaxSqFtSalePriceEnum.cs.d";
import { PersonalGuaranteeLevelEnum } from "@js/models/enum/PersonalGuaranteeLevelEnum.cs.d";
import { PreviousDevelopmentsEnum } from "@js/models/enum/PreviousDevelopmentsEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { ShareholderDepositRequiredEnum } from "@js/models/enum/ShareholderDepositRequiredEnum.cs.d";
import { SortByEnum } from "@js/models/enum/SortByEnum.cs.d";
import { AccountService } from "@js/services/AccountService";
import { AuthService } from "@js/services/AuthService";
import { BridgingSearchService } from "@js/services/BridgingSearchService";
import { CaseLenderService } from "@js/services/CaseLenderService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseNoteService } from "@js/services/CaseNoteService";
import { CaseService } from "@js/services/CaseService";
import { ClientService } from "@js/services/ClientService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { DevelopmentInputWithNoLoginService } from "@js/services/DevelopmentInputWithNoLoginService";
import { EventLogService } from "@js/services/EventLogService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { IntroducerService } from "@js/services/IntroducerService";
import { InviteService } from "@js/services/InviteService";
import { LenderResultService } from "@js/services/LenderResultService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";
import { LoginController } from "./LoginController";
import { UserRoleEnum } from "@js/models/enum/UserRoleEnum.cs.d";

interface ResultArrayMobile {
  name: string;
  array: LenderResultMinimalDTO[];
  template: string;
  headerTemplate: string;
  order: number;
}

export class LenderResultScreenController {
  loadingData: boolean = false;

  // tourState: any = {
  //   tourStep: 1,
  //   tourTotalSteps: 0,
  // };
  // tourEnabled: boolean = false;

  assistanceSlider: boolean = true;
  offer: LenderResultSummaryDTO;

  totalLender: number = 0;

  enquiryOpen: boolean;
  enquiryEmail: string;
  enquiryBody: string;
  enquiryTelephone: string;
  sentmessage: boolean = false;
  sendingmessage: boolean = false;
  firstTime: boolean = true;

  fetchingMatches: boolean;
  fetchingLargest: boolean;
  fetchingMezzanine: boolean;
  fetchingEquity: boolean;

  mezzanineLenders: boolean;
  equityLenders: boolean;
  guidanceCheckbox: boolean = true;
  showVideo: boolean = false;
  isSearch: boolean = true;
  isMaximumLoanRequired: boolean = true;
  tempLoanRequired: number;
  showHelpText: boolean = true;
  productIdAttachedToCase: number;

  loanCriteria: DevelopmentInputDTO;

  results: LenderResultSetDTO;

  matchingResults: LenderResultMinimalDTO[];
  largestResults: LenderResultMinimalDTO[];
  mezzanineResults: LenderResultDTO[];
  equityResults: LenderResultDTO[];
  allResults: ResultArrayMobile[] = [];
  selectedResultArray: LenderResultDTO[];

  summarySearchResults: LenderResultSummaryDTO[];

  selectedResult: LenderResultDTO;
  selectedResultForAttachingToCase: LenderResultMinimalDTO;

  comparisonList: LenderResultMinimalDTO[] = [];
  summaryComparisonList: LenderResultSummaryDTO[] = [];
  totProjCosts: number;
  showsaveinfo: boolean = false;
  selectedUser: ApplicationUserDTO = {} as ApplicationUserDTO;
  hideusername: boolean = true;
  caseid: number = null;
  searchid: number = null;
  uniqueId: string = null;

  hideCaseCreation: boolean = false;
  loanLabel: string;
  limitmatches: boolean = true;
  limitlargestmatches: boolean = true;
  showSaveResults: number;
  warningOff: boolean = false;
  doubleWarningOff: boolean = false;
  currentCase: CaseDTO;
  currentProductOnCase: string;
  caseUpdated: boolean = false;
  switchingProduct: boolean = false;
  productResultList: number;
  productId: number;
  caseLenders: CaseLenderDTO[];
  caseLendersNotInResults: CaseLenderDTO[];
  filterLenderNames: string[] = [];

  sliderShown: boolean;
  updatedResultsWithSlider: boolean = false;

  rowsShown: number = 4;
  emailJourneyStarted: boolean = true;
  searchNameCache: string;

  //Introducers
  clientFirstName: string;
  clientSurname: string;
  clientEmail: string;
  notifyBorrower: boolean = true;

  // confirmation before leave
  confirmSaving: boolean = false;
  prevPath: string;

  loanCriteriaWithoutCredentials: DevelopmentInputDTO;
  showProductNote: boolean = false;
  productNote: string;

  savePressed: boolean = false;

  //Capture name and email address & send results to unregistered searcher
  newUserCaptureForm: FormData;
  newUser: ApplicationUserDTO = {} as ApplicationUserDTO;
  //captureFirstName: string;
  //captureSurname: string;
  //captureEmail: string;
  //applicantDefinedRole: ApplicantRoleEnum;
  agreedToBeEmailed: boolean = false;
  error: string = "";
  // Flag to disable or enable the Save as button depending on the click.
  isClicked: boolean = false;
  showContactBrokerModal: boolean = false;
  hasAccessToDeal: boolean = true;

  //Carousel
  carouselData: string[] = [
    "Brickflow is the quickest and easiest way to apply for development finance.",
    "To protect borrowers, we only work with the UK's most reputable and financially secure lenders.",
    "We work with mainstream & challenger banks, as well as specialist development lenders.",
    "The results below are estimates based on critera provided to us by our lenders.",
    "To get a Lender Approved Decision in Principle (DIP), select up to 3x loan options below and click Proceed.",
  ];
  carouselStep: number = 0;

  //For admins accessing client accounts
  clientUsernameBeingAccessed: string;

  toggleEditSearchName: boolean = false;

  isBlankSearch: boolean = false;

  isAdmin: boolean = false;
  debug1: boolean = false;

  showActionPanel: boolean = false;

  isLoggedInUser: boolean = false;
  isLegacyUser: boolean = false;

  isProceedClicked: boolean = false;
  isDIPClicked: boolean = false;

  tempSearchName: string = null;

  isLoggingOut: boolean = false;

  //These are added for new search functinality
  existingUsers: UserSimpleDTO[];
  isNewSearch: boolean;
  newSearch: boolean = false;
  existingborrower: boolean;
  clientPhoneNumber: string;
  showClientDetails: boolean = false;
  isBroker: boolean = false;
  clientId: string = null;
  isreferredSearch: boolean = false;
  isIntroducer: boolean = false;
  isIntroduceronly: boolean = false;
  isLender: boolean = false;
  searchName: string = null;
  searchReference: string;
  newSearchId: number;

  //Accordion
  accordionShow: boolean[] = [];
  showRequestEmailForm: boolean = false;
  requestMessageReason: string = "";
  message: boolean = false;
  messageContent: string;
  sendingMessage: boolean = false;

  appName: string;

  guid: string = null;
  isSaveorSaveAsClicked: boolean = false;
  showOnlyRegisterButton: boolean = false;
  noOfShortlistAllowed: number = 5;
  emailClient: boolean = false;
  displayMsg: string = null;
  showMsg: boolean = false;
  showMsgToAdminOrBroker: boolean = false;
  ShowdeleteSearch: boolean = false;
  newLeadFirstName: string;
  newLeadLastName: string;
  newLeadEmail: string;
  isResultScreen: boolean = true;
  isSaveClientSearch: boolean = false;
  selecteduserName: string;

  isClient: boolean = false;

  numberOfPreviousDevelopmentsOptions = [];
  depositRequiredFromShareholdersOptions = [];
  personalGuaranteeLevelOptions = [];
  maxHouseSalePriceOptions = [];
  maxFlatUnitPriceOptions = [];
  maxSqFtSalePriceOptions = [];
  maxPercCommercialForMixedUseOptions = [];
  locationOptions = [];
  maxCommercialFloorspaceOptions

  shareholderDepositRequired = null;
  personalGuaranteeLevel = null;
  maxHouseSalesPrice = null;
  maxFlatUnitPrice = null;
  maxSqFtSalePrice = null;
  maxSqFtSalePriceFlats = null;
  maxPercCommercialForMixedUse = null;
  tempLoanCriteria = null;
  previousDevelopments = null;

  showFilters: boolean = false;
  isFilterUpdate: boolean = false;
  showSortBy: boolean = false;

  isShortlistingMore: boolean = false;

  showContactLender: boolean = false;
  shareForm: ng.IFormController;
  shareFirstName: string;
  shareNote: string;
  lenderEmail: string;
  shareEmailSubject: string;
  sharedSearch: boolean = false;
  dataLoading: boolean = false;
  isSaveAsWthOutIntroducee: boolean = false;
  hasSearchChanged: boolean = false;
  hasCriteriaChanged: boolean = false;
  orgCode: string;
  orgName: string;
  brokerDetail: string = "";
  reloadSearch: boolean = false;
  brokerageOrg: OrganisationDTO;
  brokerageOrgFilename: string;
  showMessageToborrower: boolean = false;
  borrowerMessage: string = "";
  user: ApplicationUserDTO;
  moveContactBrokerBtnInMiddle: boolean = false;
  clientOrganisationPhoneNumber: string;
  showBridgingEligibility: boolean = false;
  isBridging: boolean = false;
  isOldDevFinance: boolean = true;
  showLenderNamesAndLogosOverride: boolean = false;
  userRole: string;
  userDetails: string;
  userLenderId: number = null;

  //New enterprise journey
  showEnterpriseRegistrationModal: boolean = false;
  projectName: string = "";
  registrationForm: ng.IFormController;
  emailExistsError: boolean = false;
  enterpriseClientId: string = "";

  snapshotNewSearch: boolean = false;
  showPostcodeErrorMessage: boolean = false;
  postcodeErrorMsg: string;

  hasResultsProcessed: boolean = false;

  //SaveAs functionality
  isSaveAsClicked: boolean = false;
  saveAsSearchName: string;

  filterProperties = [
    "F_IsFirstTimeDeveloper",
    "F_NumberOfPreviousDevelopments",
    "F_ShareholderDepositRequired",
    "F_IsPersonalName",
    "F_IsOffshoreCompany",
    "F_IsMainShareholderOverseas",
    "F_PersonalGuaranteeLevel",
    "F_HasAdverseCredit",
    "F_MaxCommercialFloorspace",
    "F_IsAirRights",
    "F_IsModular",
    "F_IsPermittedDevelopmentScheme",
    "F_IsGradeOneListed",
    "F_IsGradeTwoListed",
    "F_MaxHouseSalesPrice",
    "F_MaxFlatUnitPrice",
    "F_MaxNumberOfUnits",
    //"F_MaxPercCommercialForMixedUse",
    "F_MaxSqFtSalePrice",
    "F_MaxSqFtSalePriceFlats",
    "F_IsWorkStarted",
    "F_IsChargeOnAdditionalProperty",
    "F_IsFixedRate",
  ];

  static $inject = [
    "$route",
    "$routeParams",
    "$scope",
    "$rootScope",
    "$location",
    "$cookies",
    "LenderResultService",
    "UserService",
    "CaseService",
    "DevelopmentInputService",
    "DevelopmentInputWithNoLoginService",
    "IntroducerService",
    "AuthService",
    "RoleService",
    "FileAttachmentService",
    "LenderService",
    "CaseMemberService",
    "InviteService",
    "OrganisationService",
    "AccountService",
    "CaseNoteService",
    "PaymentService",
    "SelectListService",
    "CaseLenderService",
    "UserService",
    "EventLogService",
    "BridgingSearchService",
    "ClientService",
  ];

  constructor(
    private $route: ng.route.IRouteService,
    private $routeParams: ng.route.IRouteParamsService,
    private $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $cookies: ng.cookies.ICookiesService,
    private $lenderresultservice: LenderResultService,
    private $user: UserService,
    private $CaseService: CaseService,
    private $DevelopmentInputService: DevelopmentInputService,
    private $DevelopmentInputWithNoLoginService: DevelopmentInputWithNoLoginService,
    private $IntroducerService: IntroducerService,
    private $auth: AuthService,
    private roleService: RoleService,
    private fileAttachmentService: FileAttachmentService,
    private lenderService: LenderService,
    private caseMemberService: CaseMemberService,
    private inviteService: InviteService,
    private organisationService: OrganisationService,
    private $accountservice: AccountService,
    private caseNoteService: CaseNoteService,
    private paymentService: PaymentService,
    private selectListService: SelectListService,
    private caseLenderService: CaseLenderService,
    private userService: UserService,
    private eventLogService: EventLogService,
    private bridingSearchService: BridgingSearchService,
    private clientService: ClientService,
  ) {
    if (this.$location.path().startsWith("/shortlistmore")) {
      this.isShortlistingMore = true;
    }

    if (
      window.self == window.top &&
      sessionStorage.getItem("userRole") &&
      sessionStorage.getItem("userRole") == "borrower" &&
      !$cookies.get("access_token")
    ) {
      document.getElementById("header").style.display = "none";
    } else if ($cookies.get("access_token")) {
      document.getElementById("header").style.display = "flex";
    }

    //Get CaseId & SearchId from route params. If the case don't have id, display the change serach menu
    if (this.$routeParams.CaseId) {
      this.caseid = Number(this.$routeParams.CaseId);
      this.isSearch = false;
    } else {
      this.isSearch = true;
    }

    if (this.$routeParams.SearchId) {
      this.searchid = Number(this.$routeParams.SearchId);
    } else {
      if (window.self == window.top && $cookies.get("access_token"))
        // Expanding a 'new search' panel for first time search execution
        this.sliderShown = (this.$rootScope as any).previousRoute.startsWith("/compare") ? false : true;
    }
    if (this.$routeParams.uniqueId) {
      this.uniqueId = this.$routeParams.uniqueId;
    }

    if (window.self == window.top && $cookies.get("access_token")) {
      //This block is to navigate a broker to userdashboard when they do not have a license.
      if (sessionStorage.getItem("isValidLicense") == "false") {
        this.$auth.clearSelectedNavMenuAndFilterStorageData();
        this.$location.path("/userdashboard");
      }
      this.isLoggedInUser = true;
      this.assistanceSlider = false; //assistance-slider open the fist time you access to the case
      this.loadingData = true;
      this.organisationService
        .getOrganisation()
        .then((organisation) => {
          if (organisation) {
            if (!organisation.IsBrickflow) {
              this.clientOrganisationPhoneNumber = organisation.PhoneNumber;
            }
            this.orgCode = organisation.IsWhiteLabelled
              ? organisation.OrganisationCode
              : "";
            this.showLenderNamesAndLogosOverride =
              organisation.ShowLenderNames ?? false;
            if (organisation.IsWhiteLabelled) {
              this.orgName = organisation.Name.replace(/ /g, "_");
            } else {
              this.orgName = "Brickflow";
            }
          }
        })
        .finally(() => {
          this.loadingData = false;
        });
    } else {
      //not logged in so show the agree terms box every time
      this.selectedUser.LenderResultsTermsAgreed = false;
      this.determineSearchSource();
      if (window.self == window.top) {
        this.userRole = sessionStorage.getItem("userRole");
        this.isClient = this.userRole == "borrower";
        this.user = JSON.parse(sessionStorage.getItem("userDetails"));
        this.orgCode = this.$cookies.get("org_code");
        this.enterpriseClientId = sessionStorage.getItem("clientId");
      } else {
        this.orgCode = location.pathname.replace("/", "");
        this.guidanceCheckbox = false;
        Promise.all([
          this.organisationService
            .getData("userDetails")
            .then((userDetails) => {
              if (userDetails) {
                this.user = JSON.parse(userDetails);
              }
            }),
          this.organisationService.getData("userRole").then((userRole) => {
            if (userRole) {
              this.userRole = userRole;
              this.isClient = this.userRole == "borrower";
            }
          }),
          this.organisationService.getData("clientId").then((clientId) => {
            if (clientId) {
              this.enterpriseClientId = clientId;
            }
          }),
          this.organisationService.getData("newSearch").then((newSearch) => {
            this.snapshotNewSearch = newSearch == "true";
          }),
        ])
          .then(() => {
            this.orgCode = location.pathname.replace("/", "");
            this.organisationService.sendDataToParent("newSearch", "false");
          })
          .catch((error) => {
            console.error("Failed to get user data: ", error);
          });
      }
    }

    this.getSearchLenderCount();

    // if (window.self == window.top) {
    //   let cookieTourStep = this.$cookies.get("tourStep");
    //   if (cookieTourStep) {
    //     this.tourState.tourStep = cookieTourStep;
    //   }
    // }

    if (this.caseid && this.caseid > 0) {
      this.getCase();
    }
    //else if (this.searchid) {
    //    this.getCaseFromSearch(this.searchid);
    //} else {
    else {
      this.$CaseService.updateTubeMap(null);
    }

    if (window.self == window.top && sessionStorage.getItem("userDetails")) {
      this.showOnlyRegisterButton = true;
    }

    //if user is logged in, get profile
    if (window.self == window.top && this.$cookies.get("access_token")) {
      this.$user.getcurentuserrecord().then((result) => {
        this.selectedUser = result;

        this.isLegacyUser = this.selectedUser.IsLegacyUser;

        this.roleService.isClientUser().then((response) => {
          this.isClient = response;

          if (this.clientOrganisationPhoneNumber && !this.isClient) {
            this.clientOrganisationPhoneNumber = null;
          }
        });

        this.roleService.isAdminUser().then((response) => {
          this.isAdmin = response;
          if (this.isAdmin) {
            this.getUsersBelongToBrokerOrAdmin();
          }
        });

        this.roleService.isBroker().then((response) => {
          this.isBroker = response;
          if (this.isBroker) {
            this.getUsersBelongToBrokerOrAdmin();
          }
        });

        this.roleService.isIntroducer().then((response) => {
          this.isIntroducer = response;
        });

        this.roleService.isIntroducerOnly().then((response) => {
          this.isIntroduceronly = response;
        });

        this.roleService.isLenderUser().then((response) => {
          this.isLender = response;
          if (this.isLender) {
            this.$user.getCurrentUserLender().then((response) => {
              this.userLenderId = response;
            });
          }
        });

        this.determineSearchSource();
      });
    }

    // if (window.self == window.top) this.updateGuidanceState();

    this.checkShowBridgingEligibility();

    $scope.$on("$locationChangeStart", (event: ng.IAngularEvent) => {
      /*this.paymentService.getLicense().then((response) => {
                    var license: LicenseMasterDTO = response;

                    if (license.Status == LicenseMasterStatusEnum.PaidUp
                        || license.Status == LicenseMasterStatusEnum.PaymentProcessing
                        || license.Status == LicenseMasterStatusEnum.PreCancel) {
                        this.saveChanges(event);
                    }
                });*/

      if (
        this.selectedUser.SubscriptionStatus ==
        LicenseMasterStatusEnum.PaidUp ||
        this.selectedUser.SubscriptionStatus ==
        LicenseMasterStatusEnum.PaymentProcessing ||
        this.selectedUser.SubscriptionStatus ==
        LicenseMasterStatusEnum.PreCancel
      ) {
        this.saveChanges(event);
      }
    });

    let logoutUnregister = $rootScope.$on(
      "logout",
      (event: ng.IAngularEvent) => {
        this.isLoggingOut = true;
        /* this.paymentService.getLicense().then((response) => {
                     var license: LicenseMasterDTO = response;
 
                     if (license.Status == LicenseMasterStatusEnum.PaidUp
                         || license.Status == LicenseMasterStatusEnum.PaymentProcessing
                         || license.Status == LicenseMasterStatusEnum.PreCancel) {
                         this.saveChanges(event);
                     }
                 });*/

        if (
          this.selectedUser.SubscriptionStatus ==
          LicenseMasterStatusEnum.PaidUp ||
          this.selectedUser.SubscriptionStatus ==
          LicenseMasterStatusEnum.PaymentProcessing ||
          this.selectedUser.SubscriptionStatus ==
          LicenseMasterStatusEnum.PreCancel
        ) {
          this.saveChanges(event);
        }
      },
    );

    if (window.self == window.top) {
      $scope.$on("$destroy", logoutUnregister);

      // $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
      //   this.updateGuidanceState();
      // });

      // $rootScope.$on("nextTour", (event: ng.IAngularEvent) => {
      //   this.tourNext();
      // });
      // $rootScope.$on("backTour", (event: ng.IAngularEvent) => {
      //   this.tourBack();
      // });
      // $rootScope.$on("skipTour", (event: ng.IAngularEvent) => {
      //   this.tourSkip();
      // });
    }

    // Set the broker information on the new user object
    if (
      this.$routeParams.brokerOrgUniqueRef !== undefined &&
      this.$routeParams.brokerOrgUniqueRef != "null" &&
      this.$routeParams.brokerUserId !== undefined &&
      this.$routeParams.brokerUserId != "null"
    ) {
      this.organisationService
        .getOrganisationIdByUniqueRef(this.$routeParams.brokerOrgUniqueRef)
        .then((brokerOrgId: number) => {
          this.newUser.OrganisationReferralId = brokerOrgId;
          this.newUser.DefaultBrokerOrganisationId = brokerOrgId;
        });
    }

    this.getAppName();

    this.numberOfPreviousDevelopmentsOptions =
      this.selectListService.GetNumberOfPreviousDevelopments();
    this.depositRequiredFromShareholdersOptions =
      this.selectListService.GetShareholderDepositRequired();
    this.personalGuaranteeLevelOptions =
      this.selectListService.GetPersonalGuaranteeLevels();
    this.maxHouseSalePriceOptions =
      this.selectListService.GetMaxHouseSalePrices();
    this.maxFlatUnitPriceOptions =
      this.selectListService.GetMaxFlatUnitPrices();
    this.maxSqFtSalePriceOptions =
      this.selectListService.GetMaxSqFtSalePrices();
    this.locationOptions = this.selectListService.GetLocations();
    this.maxCommercialFloorspaceOptions = this.selectListService.GetMaxCommercialFloorspaceOptions();
  }

  getRequiredRoles(isCaseDashboard: boolean) {
    if (isCaseDashboard) return `['Admin', 'Client', 'Broker', 'Introducer']`;
    return `['Admin', 'Client', 'Broker', 'Introducer', 'Lender']`;
  }

  saveChanges(event: ng.IAngularEvent) {
    if (!(this.isBroker && (this.$routeParams.uniqueId || this.searchid))) {
      if (this.warningOff) {
        this.warningOff = false;
        return;
      } else {
        if (
          !this.showSaveResults &&
          !this.searchid &&
          !this.doubleWarningOff &&
          this.isLoggedInUser
        ) {
          this.prevPath = this.$location.url();
          event.preventDefault();
          this.confirmSaving = true;
          //var leaveWithoutSave = confirm("Are you sure you want to leave this page without saving your results?")
        }
      }
    }
  }

  cancelSavingResults() {
    event.preventDefault();
    this.confirmSaving = false;
    if (
      this.loanCriteria.SearchName &&
      !this.loanCriteria.SearchName.startsWith("My Development Finance Search")
    ) {
      this.isSaveorSaveAsClicked = true;
      this.register(false, this.productResultList);
    } else {
      this.showSaveResults = 1;
    }
  }

  cancelSaving() {
    this.warningOff = true;
    this.confirmSaving = false;

    if (this.isLoggingOut == true) {
      this.isLoggingOut = false;
      this.$auth.logout();
    } else {
      this.$location.path(this.prevPath);
    }
  }

  determineSearchSource() {
    //if called from third party api
    if (this.$location.path().startsWith("/resultsExternalAPI")) {
      if (
        this.$routeParams.uniqeId !== undefined &&
        this.$routeParams.uniqeId != "null"
      ) {
        //read back data using
        this.$lenderresultservice
          .fetchByUniqueRef(this.$routeParams.uniqeId, 20)
          .then((results) => {
            (this.$rootScope as any).loanCriteria = results.DevelopmentInput;
            this.loanCriteria = (this.$rootScope as any).loanCriteria;
            if (this.loanCriteria) {
              this.newUser.FirstName = this.loanCriteria.IntroduceeFirstName;
              this.newUser.LastName = this.loanCriteria.IntroduceeSurname;
              this.newUser.Email = this.loanCriteria.IntroduceeEmail;
              this.newUser.PhoneNumber = this.loanCriteria.IntroduceePhone;
              this.tempLoanRequired = this.loanCriteria.CI_Dev_LoanReq;
              if (this.loanCriteria.CI_Dev_LoanReq > 0) {
                this.isMaximumLoanRequired = false;
              }
              this.isDIPClicked = this.loanCriteria.IsFullCase;
            }
            //check if associated user if so makes sense to login to give full functionality
            if (this.loanCriteria.UserId) {
              if (
                this.$cookies.get("access_token") === null ||
                this.$cookies.get("access_token") === undefined
              ) {
                if (this.loanCriteria.UserId == "User Not logged in") {
                  this.warningOff = true;
                  this.doubleWarningOff = true;
                  (this.$rootScope as any).loanCriteria = null;
                  this.$location.path("/loginandreturnto/");
                } else {
                  this.$location.path("/loginandreturnto/");
                }
              }
            }
            if (results.LenderResultSet) {
              this.matchingResults = results.LenderResultSet.MatchingResults;
              this.allResults.push({
                order: 1,
                name: "Matching Results",
                template:
                  "/views/partials/lender-results-mobile-table-row.html",
                headerTemplate:
                  "/views/partials/lender-results-mobile-table-header.html",
                array: this.matchingResults,
              });

              this.largestResults = results.LenderResultSet.LargestLoanResults;
              this.allResults.push({
                order: 2,
                name: "Alternative Options",
                template:
                  "/views/partials/lender-results-mobile-largest-table-row.html",
                headerTemplate:
                  "/views/partials/lender-results-mobile-largest-table-header.html",
                array: this.largestResults,
              });

              this.summarySearchResults =
                results.LenderResultSet.CombinedSummaryResultSet;

              this.sortSummaryResults();
            }
          })
          .finally(() => {
            this.fetchingLargest = false;
            this.fetchingMatches = false;
            this.hasResultsProcessed = true;
          });
      }
    }
    //if called from introduced client link
    else if (this.$location.path().startsWith("/referredSearch")) {
      if (
        this.$routeParams.uniqueId !== undefined &&
        this.$routeParams.uniqueId != "null"
      ) {
        this.isreferredSearch = true;
        if (this.$routeParams.showOnlyRegister == "true") {
          this.showOnlyRegisterButton = true;
          sessionStorage.setItem("userRole", UserRoleEnum.Broker.toString());
        }
        //Pull DevelopmentInputWithNoLogin search results
        this.$DevelopmentInputWithNoLoginService
          .fetchByUniqueId(this.$routeParams.uniqueId)
          .then((results) => {
            if (!results) {
              if (this.isLoggedInUser) {
                this.displayMsg = `Changes have been made to this search by your client, you will now be taken back to your user dashboard`;
              } else {
                this.displayMsg = `Please contact your broker in order to access this case.`;
              }

              this.showMsgToAdminOrBroker = true;
            } else {
              this.loanCriteria = {} as DevelopmentInputDTO;
              (<any>Object).assign(this.loanCriteria, results);

              //Prefill client details for popup
              this.confirmSaving = false;
              this.newUser.FirstName = results.IntroduceeFirstName;
              this.newUser.LastName = results.IntroduceeSurname;
              this.newUser.Email = results.IntroduceeEmail;
              this.newUser.PhoneNumber = results.IntroduceePhone;

              if (this.loanCriteria) {
                this.isDIPClicked = this.loanCriteria.IsFullCase;
                this.tempLoanRequired = this.loanCriteria.CI_Dev_LoanReq;
                if (this.loanCriteria.CI_Dev_LoanReq > 0) {
                  this.isMaximumLoanRequired = false;
                }
                if (this.isClient) {
                  this.loanCriteria.UserId = this.loanCriteria.UserId
                    ? this.loanCriteria.UserId
                    : this.selectedUser.Id;
                  this.loanCriteria.User = this.loanCriteria.User
                    ? this.loanCriteria.User
                    : this.selectedUser;
                  this.loanCriteria.SaveQueryAndResults = true;
                }
              }

              if (this.$routeParams.showOnlyRegister == "false") {
                this.isClient = true;
                this.loanCriteria.SaveQueryAndResults = false;
              }

              if (this.isBroker || this.isAdmin) {
                this.loanCriteria.SaveQueryAndResults = true;
              }

              this.updateResults();
            }
          })
          .finally(() => {
            this.loadingData = false;
            this.hasResultsProcessed = true;
          });
      }

      if (
        this.$routeParams.brokerOrgUniqueRef !== undefined &&
        this.$routeParams.brokerOrgUniqueRef != "null" &&
        this.$routeParams.brokerUserId !== undefined &&
        this.$routeParams.brokerUserId != "null"
      ) {
        if (this.selectedUser) {
          if (
            this.selectedUser.OrganisationReferralId == null &&
            this.selectedUser.DefaultBrokerOrganisationId == null
          ) {
            this.organisationService
              .getOrganisationIdByUniqueRef(
                this.$routeParams.brokerOrgUniqueRef,
              )
              .then((orgId: number) => {
                this.selectedUser.OrganisationReferralId = orgId;
                this.selectedUser.DefaultBrokerOrganisationId = orgId;
                this.$user.addUpdate(this.selectedUser);
              });
          }
        }
      }
    } else if (this.$location.path().startsWith("/securitycheck")) {
      this.$location.path("/casedashboard/" + this.$routeParams.caseId);
    }
    //if called with a saved entry
    else if (this.searchid) {
      this.loadingData = true;
      this.$DevelopmentInputService
        .fetch(this.searchid)
        .then((loanCriteria) => {
          if (loanCriteria.IsFullCase && this.isLoggedInUser && this.isClient) {
            this.$DevelopmentInputService
              .isUserHasAccessToCase(this.searchid)
              .then((response) => {
                if (!response) {
                  this.hasAccessToDeal = false;
                  this.displayMsg =
                    "Please contact your broker in order to access this case.";
                  this.showMsgToAdminOrBroker = true;
                } else {
                  this.postLoanCriteriaRetrieveProcessing(loanCriteria);
                }
              });
          } else {
            this.postLoanCriteriaRetrieveProcessing(loanCriteria);
          }
        })
        .finally(() => {
          this.loadingData = false;
        });
    } else {
      // We are no more storing loanCriteria as rootscope property when navigating to compare page.
      if (
        window.self == window.top &&
        sessionStorage.getItem("LoanCriteria") &&
        (this.$rootScope as any).previousRoute.startsWith("/compare")
      ) {
        this.loanCriteria = JSON.parse(sessionStorage.getItem("LoanCriteria"));
      } else {
        // Pull our loan criteria from rootScope.
        this.loanCriteria = (this.$rootScope as any).loanCriteria;
      }

      // This block handles the reloading results for borrower(with out login) on refresh of page
      if (
        !this.loanCriteria &&
        window.self == window.top &&
        sessionStorage.getItem("loanCriteria") &&
        !this.isLoggedInUser
      ) {
        this.loanCriteria = JSON.parse(sessionStorage.getItem("loanCriteria"));
      } else if (
        window.self == window.top &&
        sessionStorage.getItem("loanCriteria")
      ) {
        sessionStorage.removeItem("loanCriteria");
      }

      if (this.loanCriteria) {
        this.tempLoanRequired = this.loanCriteria.CI_Dev_LoanReq;
        if (this.loanCriteria.CI_Dev_LoanReq > 0) {
          this.isMaximumLoanRequired = false;
        }
        this.isDIPClicked = this.loanCriteria.IsFullCase;
      }

      if (!this.loanCriteria) {
        this.$location.path("/");
      } else {
        if (this.selectedUser) {
          this.loanCriteria.User = this.selectedUser;
          this.loanCriteria.UserId = this.selectedUser.Id;
        }

        if (
          !this.loanCriteria.Id &&
          window.self == window.top &&
          this.loanCriteria.SearchName &&
          !sessionStorage.getItem("searchuser") &&
          (this.isAdmin || this.isBroker)
        ) {
          this.loadingData = true;
          this.$DevelopmentInputService
            .saveSearch(this.loanCriteria)
            .then((loanCriteria) => {
              this.warningOff = true;
              this.go("/results/" + loanCriteria);
            })
            .finally(() => {
              this.loadingData = false;
            });
        } else if (
          window.self == window.top &&
          sessionStorage.getItem("searchuser")
        ) {
          let data = sessionStorage.getItem("searchuser");
          let userdetails = JSON.parse(data);
          this.clientFirstName = userdetails.clientFirstName;
          this.clientSurname = userdetails.clientSurname;
          this.clientEmail = userdetails.clientEmail;
          this.clientPhoneNumber = userdetails.clientPhoneNumber;
          this.clientId = userdetails.clientId;
          this.existingborrower = userdetails.existingborrower;
          this.loanCriteria.SearchName = userdetails.searchName;
          if (this.existingborrower) {
            this.showClientDetails = true;
            this.loanCriteria.User = userdetails.clientUser;
            this.loanCriteria.UserId = this.clientId;
          } else {
            this.loanCriteria.User = this.selectedUser;
            this.loanCriteria.UserId = this.selectedUser.Id;
          }

          sessionStorage.removeItem("searchuser");

          this.sendResultsToClient(false, true);
        }

        if (
          !this.loanCriteria.SearchName ||
          this.loanCriteria.SearchName.length == 0
        ) {
          this.loanCriteria.SearchName = this.defaultSearchName();
        }
        this.tempLoanCriteria = { ...this.loanCriteria };
        this.updateResults();
      }
    }
  }

  assistanceSliderCall() {
    return this.assistanceSlider;
  }

  recalcCosts(updatedField: string): void {
    switch (updatedField) {
      case "CI_Dev_BuildCosts":
        this.recalcCostPerArea();
        break;
      case "CI_Dev_Contingency":
        this.recalcCostPerArea();
        break;
      case "DC_AreaAcquisition":
        this.recalcCostPerArea();
        break;
    }
  }

  recalcCostPerArea(): void {
    let res: number = 0;
    //Case 4 - recalc cost per sq. m
    if (
      this.loanCriteria.CI_Dev_BuildCosts &&
      this.loanCriteria.CI_Dev_Contingency &&
      this.loanCriteria.DC_AreaAcquisition
    ) {
      res =
        (this.loanCriteria.CI_Dev_BuildCosts *
          (1 + Number(this.loanCriteria.CI_Dev_Contingency))) /
        this.loanCriteria.DC_AreaAcquisition;
      this.loanCriteria.DC_BuildCostPerArea = res;

      if (
        this.loanCriteria.CI_EndPropType === PropertyTypeEnum.MixedUse ||
        this.loanCriteria.CI_EndPropType === PropertyTypeEnum.Residential
      ) {
        this.loanCriteria.DI_AreaSalesAreaResidential =
          this.loanCriteria.DC_AreaAcquisition;
      }
      if (this.loanCriteria.CI_EndPropType != PropertyTypeEnum.Residential) {
        this.loanCriteria.DI_AreaSalesAreaCommercial =
          this.loanCriteria.DC_AreaAcquisition;
      }
    }
  }

  newloan() {
    (this.$rootScope as any).loanCriteria = null;
    this.$location.path("/criteria/0/0/1");
  }

  getTubeMapValue() {
    return this.$CaseService.getTubeMap();
  }

  updateResults(saveResults?: boolean, updateLoanLabels: boolean = false) {
    Promise.all([
      this.organisationService.getData("userDetails").then((userDetails) => {
        if (userDetails) {
          this.userDetails = userDetails;
          this.user = JSON.parse(userDetails);
        }
      }),
      this.organisationService.getData("userRole").then((userRole) => {
        if (userRole) {
          this.userRole = userRole;
          this.isClient = userRole == "borrower";
        }
      }),

      //Check if is a new search when inside iframe
      this.organisationService.getData("newSearch").then((newSearch) => {
        if (newSearch) {
          this.snapshotNewSearch = newSearch == "true";
        }
      }),
    ])
      .then(() => {
        this.organisationService.sendDataToParent("newSearch", "false");
        const originalSaveState = this.loanCriteria.SaveQueryAndResults;
        // If saveResults is set, override it temporarily.
        if (saveResults !== undefined) {
          this.loanCriteria.SaveQueryAndResults = false;
        }
        this.tempLoanCriteria = { ...this.loanCriteria };
        this.fetchingLargest = true;
        this.fetchingMatches = true;

        //Determine whether we have a blank search & show message
        if (
          (this.loanCriteria.CI_Dev_OrigPP == 0 &&
            this.loanCriteria.CI_EndPropType == 0 &&
            this.loanCriteria.CI_GDV == 0 &&
            this.loanCriteria.CI_Dev_LoanReq == 0) ||
          this.loanCriteria.CI_GDV == 0 ||
          this.loanCriteria.CI_Dev_BuildTerm == 0 ||
          this.loanCriteria.CI_Dev_BuildCosts == 0 ||
          this.loanCriteria.CI_Dev_LoanTermReq == 0
        ) {
          this.isBlankSearch = true;
          this.loadingData = false;
        } else {
          this.isBlankSearch = false;
          let selectedResults: SelectedResultDTO[] =
            this.loanCriteria.SelectedResults || [];
          let rootScopeLoanCriteriaId = (this.$rootScope as any)?.loanCriteria
            ?.Id;

          if (
            window.self == window.top &&
            sessionStorage.getItem("LoanCriteria") &&
            (this.$rootScope as any).previousRoute.startsWith("/compare")
          ) {
            selectedResults = JSON.parse(
              sessionStorage.getItem("LoanCriteria"),
            ).SelectedResults;
            //Removing the lsd data saved for compare view.
            sessionStorage.removeItem("LoanCriteria");
          }

          if (
            !this.selectedUser.NewUserEmailJourneyStarted &&
            !this.searchid &&
            this.selectedUser.ApplicantDefinedRole == 0 &&
            !this.$routeParams.uniqueId
          ) {
            this.emailJourneyStarted = false;
          } else if (this.$routeParams.uniqueId) {
            this.emailJourneyStarted = true;
          } else {
            this.emailJourneyStarted = true;
          }

          var orgCode;

          if (window.self == window.top) {
            orgCode = this.$cookies.get("org_code");
          } else {
            orgCode = location.pathname.replace("/", "");
          }

          if (orgCode) {
            this.loanCriteria.ReferralOrgCode = orgCode;
          }

          if (window.self == window.top) {
            if (
              sessionStorage.getItem("userDetails") &&
              sessionStorage.getItem("userRole")
            ) {
              let role = sessionStorage.getItem("userRole") == "broker";
              let data = sessionStorage.getItem("userDetails");
              this.populateNewLeadInfo(role, data);
            }

            //Check if new search (not in iframe)
            this.snapshotNewSearch =
              sessionStorage.getItem("newSearch") == "true";
            sessionStorage.setItem("newSearch", "false");
          } else {
            if (this.userRole && this.userDetails) {
              let role = this.userRole == "broker";
              this.populateNewLeadInfo(role, this.userDetails);
            }
          }

          var criteriaChanged =
            this.hasCriteriaChanged || this.snapshotNewSearch;
          sessionStorage.setItem("newSearch", "false");
          this.hasCriteriaChanged = false;
          this.snapshotNewSearch = false;

          this.$lenderresultservice
            .fetchMatchingResults(
              this.loanCriteria,
              true,
              true,
              0,
              false,
              "",
              "",
              "",
              this.GetSelectedLabel(),
              this.emailJourneyStarted,
              this.debug1,
              this.roleService.getIsLenderVisible(),
              this.newLeadFirstName,
              this.newLeadEmail,
              this.orgCode,
              this.isreferredSearch,
              this.userRole,
              this.userDetails,
              criteriaChanged,
            )
            .then((results) => {
              this.results = results;
              if (!this.isLoggedInUser && !this.$routeParams.showOnlyRegister) {
                if (!this.user && !this.enterpriseClientId) {
                  this.showEnterpriseRegistrationModal = true;
                  document.getElementById("body").style.overflow = "hidden";
                }
              }

              if (this.results.DevelopmentInputWithNoLoginUniqueRef) {
                this.loanCriteria.UniqueRef =
                  this.results.DevelopmentInputWithNoLoginUniqueRef;

                if (!this.isLoggedInUser) {
                  this.getOrganisationAndBrokerDetails();
                  sessionStorage.setItem(
                    "loanCriteria",
                    JSON.stringify(this.loanCriteria),
                  );
                  if (!this.user && this.enterpriseClientId) {
                    this.addClientAndRenameDeal();
                  }
                }
                this.eventLogService.logPageLoad(
                  "LENDERRESULTS",
                  this.orgCode,
                  this.loanCriteria?.IntroduceeEmail,
                  "borrower",
                  this.loanCriteria.OrganisationLinkId != null
                    ? this.loanCriteria.OrganisationLinkId
                    : 0,
                  ProductTypeEnum.Development,
                  this.loanCriteria?.UniqueRef,
                );
              }

              if (!this.isLoggedInUser && this.$routeParams.showOnlyRegister) {
                this.getOrganisationAndBrokerDetails();
                this.eventLogService.logPageLoad(
                  "LENDERRESULTS-USING-EMAIL-LINK",
                  this.orgCode,
                  this.loanCriteria?.IntroduceeEmail,
                  "borrower",
                  this.loanCriteria.OrganisationLinkId != null
                    ? this.loanCriteria.OrganisationLinkId
                    : 0,
                  ProductTypeEnum.Development,
                  this.$routeParams.uniqueId,
                );
              }

              if (
                this.isLoggedInUser &&
                results.Id > 0 &&
                this.isClient &&
                this.$location.path().startsWith("/referredSearch") &&
                this.$routeParams.uniqueId !== undefined &&
                this.$routeParams.uniqueId != "null"
              ) {
                this.eventLogService.logPageLoad(
                  "AFTER-BORROWER-REGISTRATION",
                  this.orgCode,
                  this.selectedUser.UserName,
                  "borrower",
                  this.loanCriteria.OrganisationLinkId,
                  ProductTypeEnum.Development,
                  this.$routeParams.uniqueId,
                );
                this.go("/results/" + results.Id);
              }

              this.matchingResults = results.MatchingResults;
              this.largestResults = results.LargestLoanResults;
              this.allResults.push({
                order: 1,
                name: "Matching Results",
                array: this.matchingResults,
                template:
                  "/views/partials/lender-results-mobile-table-row.html",
                headerTemplate:
                  "/views/partials/lender-results-mobile-table-header.html",
              });
              this.allResults.push({
                order: 2,
                name: "Alternative Options",
                array: this.largestResults,
                template:
                  "/views/partials/lender-results-mobile-largest-table-row.html",
                headerTemplate:
                  "/views/partials/lender-results-mobile-largest-table-header.html",
              });

              this.summarySearchResults = results.CombinedSummaryResultSet;

              this.sortSummaryResults();

              if (
                rootScopeLoanCriteriaId &&
                selectedResults &&
                selectedResults.length > 0
              ) {
                this.loanCriteria.SelectedResults = selectedResults;
              }

              if (
                this.searchid &&
                !this.selectedUser.NewUserEmailJourneyStarted &&
                this.selectedUser.ApplicantDefinedRole == 0
              ) {
                this.$lenderresultservice
                  .startNewUserSearchEmail(
                    this.loanCriteria.UniqueRef,
                    results.MatchingResults.length,
                    results.LargestLoanResults.length,
                  )
                  .then((results) => {
                    if (results) {
                      this.selectedUser.NewUserEmailJourneyStarted = true;
                    }
                  });
              }
              this.restoreSelectedItems(selectedResults);
            })
            .catch((error) => {
              //this.matchingResults = [];
              //this.largestResults = [];
            })
            .finally(() => {
              this.fetchingLargest = false;
              this.fetchingMatches = false;
              this.loanCriteria.SaveQueryAndResults = originalSaveState;

              if (updateLoanLabels) {
                this.updateLoanLabels();
              }

              this.loadingData = false;
              this.isFilterUpdate = false;
            });
        }
      })
      .catch((error) => {
        // One or both of the promises were rejected, handle the error
        console.error("Failed to get user data: ", error);
      });
  }

  /**Update the loan label in the comparison results based on the loan label returned in the results
   * Primary purpose is to update the loan label when the show lender names button is toggled
   * */
  updateLoanLabels() {
    for (var i = 0; i < this.comparisonList.length; i++) {
      let resultIndex = this.summarySearchResults.findIndex(
        (item) => item.ProductID == this.comparisonList[i].SeniorProductID,
      );

      if (resultIndex > -1) {
        this.comparisonList[i].LoanLabel =
          this.summarySearchResults[resultIndex].LoanLabel;
      }
    }

    //This is user for shortlisting pdf
    this.prepareDataForShortlistPdf();
  }

  populateNewLeadInfo(role, data) {
    let userdetails = JSON.parse(data);
    let userFullName = userdetails.FullName;
    let spaceIndex = userFullName.indexOf(" ");

    let firstName = "";
    let lastName = "";

    // if not space has been added to the name then put the whole name in the first name field
    if (spaceIndex == -1) {
      firstName = userFullName;
    } else {
      firstName = userFullName.substring(0, userFullName.indexOf(" "));
      lastName = userFullName.substring(userFullName.indexOf(" ") + 1);
    }

    this.newLeadFirstName = firstName;
    this.newLeadLastName = lastName;
    this.newLeadEmail = userdetails.Email;
  }

  restoreSelectedItems(selectedResults: SelectedResultDTO[]) {
    if (selectedResults) {
      selectedResults.forEach((item, index) => {
        let match;
        var found = false;
        // first compare whether the selectedResultDTO is already an active caseLender record, if so it MUST be kept
        if (this.caseLenders) {
          found = this.caseLenders.some((cl) => cl.ProductID == item.ProductId);
        }

        if (!found) {
          found = this.summarySearchResults.some(
            (result) =>
              result.ProductID === item.ProductId &&
              result.CaseLenderStatus != CaseLenderStateEnum.Rejected &&
              result.CaseLenderStatus != CaseLenderStateEnum.Withdrawn,
          );
        }
        // Keeping this here rather than using summarySearchResults (above) because otherwise comparison etc stops working as it relies on using LenderResultMinimalDTO
        if (found) {
          if (item.MenuId === 1) {
            match = this.matchingResults.find(
              (result) =>
                result.SeniorProductID === item.ProductId &&
                result.CalculatedBasedOnMatchingLoan === true,
            );
          } else if (item.MenuId === 2) {
            match = this.largestResults.find(
              (result) =>
                result.SeniorProductID === item.ProductId &&
                result.CalculatedBasedOnMatchingLoan === false,
            );
          }
        }

        if (match) {
          /* 'this.isFilterUpdate' condition is to fix the issue with wrong number display beside toggle button when using filter*/
          if (this.isFilterUpdate) {
            var comparematch = this.comparisonList.filter(
              (result) => result.SeniorProductID == item.ProductId,
            );
            var index = this.comparisonList.indexOf(comparematch[0]);
            if (index !== -1) {
              this.comparisonList[index] = match;
            } else {
              this.comparisonList.push(match);
            }
          } else {
            this.comparisonList.push(match);
          }

          if (index === 0) {
            this.selectedResultForAttachingToCase = match;
          }
        }
      });
    }
  }

  //Show whose account is being accessed if admin is accessing client
  getEmailOfClientBeingAccessed(): string {
    if (
      this.selectedUser &&
      this.loanCriteria &&
      this.selectedUser.Id !== this.loanCriteria.UserId
    ) {
      return this.loanCriteria.OwnerUser;
    }
  }

  indexPopup(methodName: string) {
    this.$rootScope.$broadcast(methodName);
  }

  agreeTerms() {
    if (!this.selectedUser.Id) {
      //new user who opted in to receiving emails, socapture details
      this.loadingData = true;
      this.newUser.UserName = this.newUser.Email;
      this.newUser.LenderResultsTermsAgreed = true;
      this.newUser.AgreedToTermsAndPP = true;
      this.newUser.Roles = ["Client"];

      var orgCode;
      if (window.self == window.top) {
        orgCode = this.$cookies.get("org_code");
      } else {
        orgCode = location.pathname.replace("/", "");
      }
      if (orgCode) {
        this.newUser.ReferralOrgCode = orgCode;
      }

      // If we have an introducer code, let's set it.
      if (this.$cookies.get("introducer_code")) {
        this.newUser.IntroducerCode = this.$cookies.get("introducer_code");
        this.createTemporalyUser();
      } else {
        if (this.$cookies.get("invite_code")) {
          this.guid = this.$cookies.get("invite_code");
        }
        this.inviteService
          .checkIfRegisterUserWasInvited(this.newUser.Email, this.guid)
          .then((result) => {
            this.newUser.IntroducerCode = result;
            this.createTemporalyUser();
          });
      }
    } else if (
      this.selectedUser.Id &&
      !this.selectedUser.NewUserEmailJourneyStarted &&
      this.selectedUser.ApplicantDefinedRole == 0
    ) {
      this.loadingData = true;
      this.selectedUser.LenderResultsTermsAgreed = true;
      if (this.$cookies.get("access_token")) {
        this.loadingData = true;
        this.$user
          .addUpdate(this.selectedUser)
          .then((response) => {
            this.loanCriteria.UserId = this.selectedUser.Id;
            this.loanCriteria.User = this.selectedUser;
            this.loanCriteria.SaveQueryAndResults = true;
            this.loanCriteria.SelectedResults = [];
            if (this.selectedUser.OrganisationReferralId != null) {
              this.loanCriteria.BrokerOrganisationId =
                this.selectedUser.OrganisationReferralId;
            }

            this.$DevelopmentInputService
              .saveSearch(this.loanCriteria)
              .then((savedSearchId) => {
                this.warningOff = true;
                this.go("/results/" + savedSearchId);
              });
          })
          .finally(() => {
            this.loadingData = false;
          });
      }
    } else {
      //Logged in user, persist agreement to terms; persist that terms agreed.
      this.loadingData = true;
      this.selectedUser.LenderResultsTermsAgreed = true;

      if (this.$cookies.get("access_token")) {
        this.loadingData = true;
        this.$user
          .addUpdate(this.selectedUser)
          .then((response) => { })
          .finally(() => {
            this.loadingData = false;
          });
      }
    }
    //if user is not logged in, make a note in rootScope not to show this message during this session
    if (!this.selectedUser.Id) {
      (this.$rootScope as any).lenderResultsIntroSeen = true;
    }
  }

  createTemporalyUser() {
    this.$user
      .createtemporaryuser(this.newUser)
      .then((user) => {
        this.newUser.ApplicantDefinedRole == 0;
        this.newUser.Roles = [];
        this.newUser.Roles.push("Client");
        (this.$rootScope as any).selectedUser = user;
        this.$auth
          .login(
            this.newUser.Email,
            user.Password,
            "CC022EBE67BE0424EA2A6548B062D2D71",
          )
          .then((response) => {
            let expiry: Date = response;

            this.$cookies.put("user_firstname", user.FirstName, {
              expires: expiry,
            });
            //Add loan to newly created temporary account
            this.loanCriteria.UserId = user.Id;
            this.loanCriteria.User = user;
            this.loanCriteria.SaveQueryAndResults = true;
            this.loanCriteria.SelectedResults = [];

            this.loanCriteria.BrokerOrganisationId =
              user.DefaultBrokerOrganisationId;

            if (
              this.$routeParams.brokerUserId !== undefined &&
              this.$routeParams.brokerUserId != "null"
            ) {
              this.loanCriteria.BrokerUserId = this.$routeParams.brokerUserId;
            }

            if (this.$cookies.get("invite_code")) {
              this.inviteService.checkIfInvitedIsUser(
                this.$cookies.get("invite_code"),
                user.Id,
              );
            }

            this.$DevelopmentInputService
              .saveSearch(this.loanCriteria)
              .then((savedSearchId) => {
                this.warningOff = true;
                this.go("/results/" + savedSearchId);
              });
          });
      })
      .catch((response) => {
        this.error = response.data;
        this.newUser.LenderResultsTermsAgreed = false;
        this.newUser.AgreedToTermsAndPP = false;
        (this.$rootScope as any).lenderResultsIntroSeen = false;
        this.loadingData = false;
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  lenderResultsIntroSeen(): boolean {
    if ((this.$rootScope as any).lenderResultsIntroSeen) {
      return true;
    } else {
      return false;
    }
  }

  captureNewUserEmail(): void {
    this.loadingData = true;
  }

  getCase() {
    this.loadingData = true;
    this.$CaseService
      .fetch(this.$routeParams.CaseId)
      .then((result) => {
        this.currentCase = result;
        this.productId = result.ProductID;
        this.productResultList = result.ProductResultList;
        this.caseLenderService
          .fetchByCaseId(this.currentCase.Id)
          .then((result) => {
            if (result) {
              this.caseLenders = result.filter(
                (cl) =>
                  cl.CaseLenderState != CaseLenderStateEnum.Withdrawn &&
                  cl.CaseLenderState != CaseLenderStateEnum.Rejected,
              );
            }
          });
        if (result.CaseStatus == 0 || result.CaseStatus == 1) {
          this.$CaseService.updateTubeMap(CaseStatusEnum.InProgress);
        } else {
          this.$CaseService.updateTubeMap(result.CaseStatus);
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  getCaseFromSearch(searchId: number) {
    this.$CaseService
      .getcasefromsearch(searchId)
      .then((result) => {
        this.currentCase = result;
        this.caseid = result.Id;
        if (this.caseid) {
          this.isDIPClicked = true;
        }
        this.productId = result.ProductID;
        this.productResultList = result.ProductResultList;

        if (result.CaseStatus == 0 || result.CaseStatus == 1) {
          this.$CaseService.updateTubeMap(CaseStatusEnum.InProgress);
        } else {
          this.$CaseService.updateTubeMap(result.CaseStatus);
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  // updateGuidanceState() {
  //   this.guidanceCheckbox =
  //     this.$cookies.get("guidance") === "on" ||
  //     this.$cookies.get("guidance") === undefined;

  //   // Update tour settings
  //   let tourEnabled = this.$cookies.get("tourEnabled");
  //   if (
  //     (tourEnabled == "true" || tourEnabled === undefined) &&
  //     this.guidanceCheckbox
  //   ) {
  //     this.tourEnabled = true;
  //   } else {
  //     this.tourEnabled = false;
  //   }

  //   //if (this.guidanceCheckbox) {
  //   //    this.startTour();
  //   //}
  // }

  // turnOffGuidance() {
  //   this.guidanceCheckbox = false;
  //   var expiryDate = new Date();
  //   expiryDate.setDate(expiryDate.getDate() + 365);
  //   this.$cookies.put("guidance", "off", { expires: expiryDate });
  //   this.$rootScope.$broadcast("guidanceStateChanged");
  // }

  // getGuidanceSwitchState() {
  //   if (!this.$cookies.get("guidance")) {
  //     this.guidanceCheckbox = true;
  //   } else if (this.$cookies.get("guidance") === "on") {
  //     this.guidanceCheckbox = true;
  //   } else if (this.$cookies.get("guidance") === "off") {
  //     this.guidanceCheckbox = false;
  //   } else {
  //     this.guidanceCheckbox = true;
  //   }
  //   return this.guidanceCheckbox;
  // }

  // recordGuidanceCookie() {
  //   var guidanceSwitchState: string;
  //   var expiryDate = new Date();
  //   expiryDate.setDate(expiryDate.getDate() + 365);
  //   if (this.guidanceCheckbox == true) {
  //     guidanceSwitchState = "on";
  //   } else {
  //     guidanceSwitchState = "off";
  //   }
  //   this.$cookies.put("guidance", guidanceSwitchState, { expires: expiryDate });
  //   this.$rootScope.$broadcast("guidanceStateChanged");
  // }

  //updateCase(offer: LenderResultMinimalDTO, productResultList: number) {
  //    this.switchingProduct = true;
  //    this.selectedResultForAttachingToCase = offer;
  //    this.caseUpdated = true;
  //    this.loadingData = true;
  //    this.$CaseService.setProductOnCase(this.caseid, this.selectedResultForAttachingToCase.Id, productResultList).then((response) => {
  //        if (response === true) {
  //            this.currentProductOnCase = this.selectedResultForAttachingToCase.LoanLabel;
  //            this.productId = this.selectedResultForAttachingToCase.Id;
  //            this.productResultList = productResultList;
  //        }
  //    }).finally(() => {
  //        this.loadingData = false;
  //        this.switchingProduct = false;
  //        this.returnToCase();
  //    });
  //}

  setSelectedItem(offer: LenderResultMinimalDTO) {
    this.matchingResults.forEach((item) => {
      item.SelectedItem = false;
    });
    this.largestResults.forEach((item) => {
      item.SelectedItem = false;
    });
    offer.SelectedItem = true;
    this.selectedResultForAttachingToCase = offer;
  }

  toggleMezzanine() {
    this.mezzanineLenders = !this.mezzanineLenders;
    this.fetchingMezzanine = true;
    // Do some stuff
  }

  toggleEquity() {
    this.equityLenders = !this.equityLenders;
    this.fetchingEquity = true;
    // Do some stuff
  }

  comparisonContains(item: LenderResultSummaryDTO): boolean {
    var match = this.getLenderResultMinimalDTO(item);

    let found = !!this.comparisonList.find((result, index) => {
      return result === match;
    });

    if (found) {
    }

    return found;
  }

  checkLender(item: LenderResultSummaryDTO): boolean {
    if (this.userLenderId == item.LenderID) return true;
    return false;
  }

  selectionOrderNumber(item: LenderResultSummaryDTO) {
    //let found = !!this.comparisonList.find((result, index) => {
    //    return result === item;
    //});
    var match = this.getLenderResultMinimalDTO(item);
    var order = this.comparisonList.indexOf(match) + 1;

    if (order === 0) {
      return " ";
    }

    /* Adds number of active applications  */
    return (
      order +
      (this.caseLendersNotInResults ? this.caseLendersNotInResults.length : 0)
    );
  }

  toggleLenderComparisonSelection(item: LenderResultSummaryDTO) {
    /** This will check if the result shared with new user is active */
    var isActive = this.isResultActive();

    if (isActive) {
      // Find Lender offer within the original arrays to use on the compare view
      var match = this.getLenderResultMinimalDTO(item);

      var menuId = match.CalculatedBasedOnMatchingLoan === true ? 1 : 2;

      if (this.loanCriteria) {
        if (!this.loanCriteria.SelectedResults) {
          this.loanCriteria.SelectedResults = [];
        }
        let selectedResultMatches: SelectedResultDTO[] =
          this.loanCriteria.SelectedResults.filter((result, index) => {
            return (
              result.ProductId === match.SeniorProductID &&
              result.MenuId === menuId
            );
          });

        let comparisonMatches: LenderResultMinimalDTO[] =
          this.comparisonList.filter((result, index) => {
            return result === match;
          });

        // Handle selected results for comparison or selection.
        if (!selectedResultMatches || selectedResultMatches.length === 0) {
          this.loanCriteria.SelectedResults.push({
            ProductId: match.SeniorProductID,
            DevelopmentInputId: this.loanCriteria.Id,
            MenuId: menuId,
          } as SelectedResultDTO);
          // If this is the first item, let's mark it as the selectedItem
          if (this.loanCriteria.SelectedResults.length === 1) {
            this.productResultList = menuId;
            this.setSelectedItem(match);
          }
        } else {
          selectedResultMatches.forEach((value, index) => {
            this.loanCriteria.SelectedResults.splice(
              this.loanCriteria.SelectedResults.indexOf(value),
              1,
            );
          });
        }

        if (!comparisonMatches || comparisonMatches.length === 0) {
          this.comparisonList.push(match);
          this.eventLogService.logEvent(
            `SHORTLISTING-RESULT`,
            EventLogEnum.Shortlisting,
            ProductTypeEnum.Development,
            this.$routeParams.uniqueId ? 0 : this.loanCriteria.Id,
            this.$routeParams.uniqueId ? this.$routeParams.uniqueId : "",
            item.ProductID.toString()
          );
        } else {
          comparisonMatches.forEach((value, index) => {
            this.comparisonList.splice(this.comparisonList.indexOf(value), 1);
          });
          this.eventLogService.logEvent(
            `DESELECTING-SHORTHLISTED-RESULT`,
            EventLogEnum.Shortlisting,
            ProductTypeEnum.Development,
            this.$routeParams.uniqueId ? 0 : this.loanCriteria.Id,
            this.$routeParams.uniqueId ? this.$routeParams.uniqueId : "",
            item.ProductID.toString()
          );
        }
      }
    }

    this.loanCriteria.ComparisonList = this.comparisonList;
  }

  viewOffer(offer: LenderResultDTO) {
    this.selectedResult = offer;
  }

  goCompare() {
    /** This will check if the result shared with new user is active */
    var isActive = this.isResultActive();
    if (isActive) {
      this.warningOff = true;
      //We are setting this value to avoid displaying save modal while navigating to compare screen without saving search
      this.doubleWarningOff = true;
      // Makeing sure all the old data is deleted
      sessionStorage.removeItem("LoanCriteria");
      sessionStorage.removeItem("UniqueId");
      sessionStorage.removeItem("ComparisonList");
      sessionStorage.removeItem("TempSavedResults");

      sessionStorage.setItem(
        "ComparisonList",
        JSON.stringify(this.getSummaryComparisonList()),
      );
      sessionStorage.setItem("LoanCriteria", JSON.stringify(this.loanCriteria));

      if (this.uniqueId) {
        sessionStorage.setItem("UniqueId", this.uniqueId);
      }

      // temporarily put all results into saved results so the comparison controller can use them.

      let allResults: LenderResultMinimalDTO[] = [];
      this.matchingResults.forEach((result) => {
        allResults.push(result);
      });
      this.largestResults.forEach((result) => {
        allResults.push(result);
      });

      sessionStorage.setItem("TempSavedResults", JSON.stringify(allResults));

      if (this.searchid && this.caseid) {
        this.$location.path("compare/" + this.searchid + "/" + this.caseid);
      } else if (this.searchid) {
        this.$location.path("compare/" + this.searchid);
      } else {
        this.$location.path("compare/");
      }
    }
  }

  viewSingleLoan(item: LenderResultSummaryDTO) {
    event.preventDefault(); // prevents default behaviour of href
    this.warningOff = true;

    //Removed in favour of summaryComparisonList
    //this.comparisonList = [];

    //// Find Lender offer within the original arrays to use on the compare view
    //var match = this.getLenderResultMinimalDTO(item);

    //this.comparisonList.push(match);

    this.summaryComparisonList = [];
    this.summaryComparisonList.push(item);

    this.goCompare();
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
  }

  /**
   * Processes the clicking of the "View Eligibility" anchor/button and index to show on the row number
   * @param item and index
   */
  viewEligibility(item: LenderResultSummaryDTO) {
    event.preventDefault(); // prevents default behaviour of href
    this.showProductNote = true;
    this.offer = item;
    this.loanLabel = item.LoanLabel;
    this.productNote = item.AdditionalProductInfo;
  }

  returnToCase() {
    if (this.comparisonList.length > 0) {
      this.loadingData = true;
      this.loanCriteria.SaveQueryAndResults = true;
      this.$lenderresultservice
        .fetchMatchingResults(
          this.loanCriteria,
          true,
          true,
          0,
          false,
          "",
          "",
          "",
          this.GetSelectedLabel(),
        )
        .then((response) => {
          if (this.isShortlistingMore) {
            // copy newly selected products/lenders to caselender
            this.$CaseService
              .shortlistMoreLenders(this.caseid, this.hasSearchChanged)
              .then((response) => {
                this.$location.path("/casedashboard/" + this.caseid);
              });
          } else {
            this.$location.path("/casedashboard/" + this.caseid);
          }
        })
        .finally(() => {
          this.loadingData = false;
        });
    } else {
      this.$location.path("/casedashboard/" + this.caseid);
    }
  }

  go(path): void {
    this.$location.path(path);
  }
  goWarningOff(path): void {
    this.warningOff = true;
    LoginController.searchInProgress = true;
    (this.$rootScope as any).loginRedirectPath = this.$location.url();
    this.go(path);
  }

  backToClientSearches(): void {
    (this.$rootScope as any).clientUsernameBeingViewed =
      this.loanCriteria.OwnerUser;
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.go("/userdashboard");
  }

  //setSelectedResultsFromComparisonList() {
  //    this.loanCriteria.SelectedResults = this.comparisonList.map((item, index) => {
  //        return {
  //            DevelopmentInputId: item.DevelopmentInputID,
  //            ProductId: item.SeniorProductID,
  //            MenuId: null
  //        } as SelectedResultDTO;
  //    });
  //}

  register(
    createCase: boolean = false,
    productResultList: number,
    introducingAClient?: boolean,
    preventRedirect?: boolean,
  ) {
    this.loadingData = true;
    this.isProceedClicked = true;
    // Set the selected or "preferred" result.
    productResultList = this.productResultList;

    //this.setSelectedResultsFromComparisonList();
    (this.$rootScope as any).productResultList = productResultList;
    if (!this.selectedUser.Id || this.selectedUser.Id === null) {
      (this.$rootScope as any).selectedResultForAttachingToCase =
        this.selectedResultForAttachingToCase;
      this.loanCriteria.SaveCase = createCase;
      (this.$rootScope as any).loanCriteria = this.loanCriteria;
      var path: string = "/register";

      if (
        this.$routeParams.brokerOrgUniqueRef !== undefined &&
        this.$routeParams.brokerOrgUniqueRef != "null" &&
        this.$routeParams.brokerUserId !== undefined &&
        this.$routeParams.brokerUserId != "null"
      ) {
        path +=
          "/" +
          this.$routeParams.brokerOrgUniqueRef +
          "/" +
          this.$routeParams.brokerUserId;
      }

      this.$location.path(path);
    } else {
      this.loanCriteria.UserId = this.loanCriteria.UserId
        ? this.loanCriteria.UserId
        : this.selectedUser.Id;
      this.loanCriteria.User = this.loanCriteria.User
        ? this.loanCriteria.User
        : this.selectedUser;
      this.loanCriteria.SaveQueryAndResults = true;
      this.loanCriteria.SaveCase = createCase;
      if (this.isLender) {
        this.loanCriteria.LenderUserId = this.selectedUser.Id;
      }
      // if broker info has been sent to the page then set this to the search
      if (
        this.$routeParams.brokerOrgUniqueRef !== undefined &&
        this.$routeParams.brokerOrgUniqueRef != "null"
      ) {
        this.organisationService
          .getOrganisationIdByUniqueRef(this.$routeParams.brokerOrgUniqueRef)
          .then((orgId: number) => {
            this.loanCriteria.BrokerOrganisationId = orgId;

            if (
              this.$routeParams.brokerUserId !== undefined &&
              this.$routeParams.brokerUserId != "null"
            ) {
              this.loanCriteria.BrokerUserId = this.$routeParams.brokerUserId;
            } else {
              this.$user
                .GetOrganisationAdminUserId(orgId)
                .then((adminUserId: string) => {
                  this.loanCriteria.BrokerUserId = adminUserId;
                });
            }

            // Save Search
            this.saveSearch(createCase, productResultList, preventRedirect);
          });
      } else {
        // Save Search
        this.saveSearch(createCase, productResultList, preventRedirect);
      }
      if (createCase == true) {
        this.loanCriteria.IsFullCase = true;
        (this.$rootScope as any).loanCriteria = this.loanCriteria;
      }
    }
  }

  saveSearch(
    createCase: boolean,
    productResultList: number,
    preventRedirect?: boolean,
  ) {
    if (this.selectedResultForAttachingToCase) {
      this.loanCriteria.SelectedProduct =
        this.selectedResultForAttachingToCase.LI_LoanProduct;
    }
    this.loadingData = true;
    // We always want to save at this point.
    this.loanCriteria.SaveQueryAndResults = true;
    if (this.searchName != null && this.searchName.length > 0) {
      this.loanCriteria.SearchName = this.searchName;
    }
    this.$lenderresultservice
      .fetchMatchingResults(
        this.loanCriteria,
        true,
        true,
        0,
        false,
        "",
        "",
        "",
        this.GetSelectedLabel(),
      )
      .then((response) => {
        this.tempLoanCriteria = { ...this.loanCriteria };
        if (createCase === true) {
          //case creation
          //this.sendEnquiry();
          this.$CaseService
            .promoteandmigratetodeal(
              response.Id,
              this.selectedResultForAttachingToCase.LI_LoanProduct,
              productResultList,
            )
            .then((newCaseId) => {
              var expiryDate = new Date();
              expiryDate.setDate(expiryDate.getDate() + 365);
              this.$cookies.remove("caseLandingDontShowAgain");
              //this.$CaseService.getcasefromsearch(response.Id).then((caseobject) => {
              if (newCaseId) {
                this.$location.path(
                  "/devfinancecasedashboard/" + newCaseId + "/" + true,
                );
              } else {
                this.isProceedClicked = false;
              }
              //});
            })
            .catch((response) => {
              this.isProceedClicked = false;
            });
        } else {
          //if (introducingAClient) {
          //    this.introducerSendResultsToClient(response.Id);
          //} else {

          if (!preventRedirect) {
            if (
              (this.isSaveorSaveAsClicked || this.isSaveClientSearch) &&
              this.selectedUser.TemporaryAccount != true
            ) {
              this.doubleWarningOff = true;
              this.showSaveResults = null;

              if (this.prevPath) {
                this.$location.path(this.prevPath);
              } else {
                this.$location.path("/results/" + response.Id);
              }
            } else {
              this.$auth.clearSelectedNavMenuAndFilterStorageData();
              this.$location.path("/userdashboard");
            }
          }

          //}
        }
      })
      .catch((response) => {
        this.isProceedClicked = false;
      })
      .finally(() => {
        this.loadingData = false;
        this.isClicked = false;
        this.isProceedClicked = false;
        this.newSearch = false;
      });
  }

  // TODO JLH - don't think this is in use
  sendEnquiry(): void {
    this.sendingmessage = true;
    this.sentmessage = false;
    this.enquiryTelephone = this.selectedUser.PhoneNumber;
    this.enquiryEmail = this.selectedUser.Email;
    this.enquiryBody =
      "Please send me more information about loan option " +
      this.GetSelectedLabel();
    this.$lenderresultservice
      .fetchMatchingResults(
        this.loanCriteria,
        true,
        true,
        0,
        true,
        this.enquiryBody,
        this.enquiryEmail,
        this.enquiryTelephone,
        this.GetSelectedLabel(),
      )
      .then((response2) => {
        this.sendingmessage = false;
        this.sentmessage = true;
      });
  }

  private GetSelectedLabel() {
    var selectedlabel = "";
    if (
      this.selectedResultForAttachingToCase &&
      this.selectedResultForAttachingToCase.LoanLabel
    ) {
      selectedlabel = this.selectedResultForAttachingToCase.LoanLabel;
    }
    return selectedlabel;
  }

  saveUser(): void {
    if (this.selectedUser.Id == null) {
      this.selectedUser.Id = "";
      this.selectedUser.Roles = [];
      this.selectedUser.Roles.push("Client");
    }
    if (!this.selectedUser.UserName) {
      this.selectedUser.UserName = this.selectedUser.Email; //set user name to be same as email address for now
    }
    this.loanCriteria.User = this.selectedUser;
    this.loanCriteria.SaveQueryAndResults = true;
    //            this.$user.addUpdate(this.selectedUser).then((response) => {
    this.loadingData = true;
    this.$lenderresultservice
      .fetchMatchingResults(
        this.loanCriteria,
        true,
        true,
        0,
        false,
        "",
        "",
        "",
        this.GetSelectedLabel(),
      )
      .then((response) => {
        this.selectedUser.Id = response.UserId;
        this.showsaveinfo = false;
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  // tourNext(): void {
  //   this.tourState.tourStep++;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  // }
  // tourBack(): void {
  //   this.tourState.tourStep--;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  // }

  // tourSkip(): void {
  //   this.tourEnabled = false;
  //   this.$cookies.put("tourEnabled", "false");
  //   this.$cookies.remove("tourStep");
  // }

  // startTour(): void {
  //   this.tourState.tourStep = 1;
  //   this.tourState.tourTotalSteps = 0;
  //   this.$cookies.put("tourStep", this.tourState.tourStep);
  //   this.tourEnabled = true;
  //   this.$cookies.put("tourEnabled", "true");
  // }

  applyForLoan(offer, productResultList: number) {
    /** This will check if the result shared with new user is active */
    var isActive = this.isResultActive();
    if (isActive) {
      //this.productResultList = productResultList;
      //this.SetSelectedItem(offer);
      if (
        this.searchid &&
        this.loanCriteria.SearchName.startsWith("My Development Finance Search")
      ) {
        this.showSaveResults = 2;
      } else if (this.searchid) {
        this.register(true, this.productResultList);
      } else {
        if (
          this.clientFirstName != null ||
          this.clientSurname != null ||
          this.clientEmail != null ||
          this.clientPhoneNumber != null
        ) {
          this.loanCriteria.IntroduceeFirstName = this.clientFirstName;
          this.loanCriteria.IntroduceeSurname = this.clientSurname;
          this.loanCriteria.IntroduceeEmail = this.clientEmail;
          this.loanCriteria.IntroduceePhone = this.clientPhoneNumber;
        }

        this.showSaveResults = 2;
      }
    }
  }

  showSaveResultsPopup() {
    this.showSaveResults = 1;
  }

  showSaveCasePopup() {
    this.showSaveResults = 2;
  }

  changeSearch() {
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
    (this.$rootScope as any).snapshotLoanCriteria = this.loanCriteria;
    this.warningOff = true;
    sessionStorage.setItem("skip", "true");
    if (this.caseid) {
      this.go("/criteria/" + this.searchid + "/" + this.caseid);
    } else if (this.searchid) {
      this.go("/criteria/" + this.searchid);
    } else if (this.isreferredSearch) {
      this.$location.path(
        "/referredsearchcriteria/" +
        this.$routeParams.uniqueId +
        "/" +
        0 +
        "/" +
        1,
      );
    } else {
      this.go("/criteria");
    }
  }

  debugSearch() {
    const stringJSON = JSON.stringify(this.loanCriteria);
    navigator.clipboard
      .writeText(stringJSON)
      .then()
      .catch((e) => console.log(e));
  }

  /**Processing for Net Loan Required input field OnBlur */
  netLoanRequiredOnBlur() {
    //wait to the debounce to update ng-model
    setTimeout(() => {
      // When the Net Loan required amount is empty or 0 then set the Maximum Loan required toggle to TRUE
      if (
        !this.loanCriteria.CI_Dev_LoanReq ||
        this.loanCriteria.CI_Dev_LoanReq == 0 ||
        String(this.loanCriteria.CI_Dev_LoanReq) == ""
      ) {
        this.isMaximumLoanRequired = true;
      }
    }, 1000);
  }

  updateSearchViaSlider(isAdminDebugValueChanged: boolean = false): void {
    /** This will check if the result shared with new user is active */
    var isActive = this.isResultActive();
    if (isActive && !this.showPostcodeErrorMessage) {
      this.loadingData = true;

      // Set criteria properties to 0 that have been cleared by the user e.g. backspace or delete has been used
      if (
        (!this.loanCriteria.CI_Dev_LoanReq &&
          this.loanCriteria.CI_Dev_LoanReq != 0) ||
        String(this.loanCriteria.CI_Dev_LoanReq) == ""
      ) {
        this.loanCriteria.CI_Dev_LoanReq = 0;
      }

      if (
        (!this.loanCriteria.CI_Dev_LoanTermReq &&
          this.loanCriteria.CI_Dev_LoanTermReq != 0) ||
        String(this.loanCriteria.CI_Dev_LoanTermReq) == ""
      ) {
        this.loanCriteria.CI_Dev_LoanTermReq = 0;
      }

      if (
        (!this.loanCriteria.CI_Dev_BuildTerm &&
          this.loanCriteria.CI_Dev_BuildTerm != 0) ||
        String(this.loanCriteria.CI_Dev_BuildTerm) == ""
      ) {
        this.loanCriteria.CI_Dev_BuildTerm = 0;
      }

      if (
        (!this.loanCriteria.CI_GDV && this.loanCriteria.CI_GDV != 0) ||
        String(this.loanCriteria.CI_GDV) == ""
      ) {
        this.loanCriteria.CI_GDV = 0;
      }

      if (
        (!this.loanCriteria.CI_Dev_BuildCosts &&
          this.loanCriteria.CI_Dev_BuildCosts != 0) ||
        String(this.loanCriteria.CI_Dev_BuildCosts) == ""
      ) {
        this.loanCriteria.CI_Dev_BuildCosts = 0;
      }

      this.updatedResultsWithSlider =
        this.hasSearchChanged =
        this.hasCriteriaChanged =
        true;

      if (isAdminDebugValueChanged && this.isAdmin) {
        this.hasCriteriaChanged = false;
      }

      this.clearSelected();

      this.updateResults();
    }
  }

  clearSelected() {
    this.comparisonList = [];

    this.loanCriteria.SelectedResults = [];
  }

  cancelSavePopup() {
    this.showSaveResults = null;
    this.loanCriteria.SearchName = this.searchNameCache;
  }

  /* saveAs() {
            this.isClicked = true;
            this.isSaveorSaveAsClicked = true;
            delete this.loanCriteria.Id;
            delete this.loanCriteria.IsFullCase;
            delete this.loanCriteria.UniqueRef;
            this.register(false, this.productResultList);
        }*/

  doRenameSearch() {
    //this.register(false, this.productResultList, false, true);
    if (this.$routeParams.SearchId) {
      this.$DevelopmentInputService
        .addUpdate(this.loanCriteria)
        .then((response) => {
          this.toggleEditSearchName = false;
        });
    } else if (
      this.$routeParams.uniqueId !== undefined &&
      this.$routeParams.uniqueId != "null"
    ) {
      var result = {} as DevelopmentWithNoLoginDTO;
      (<any>Object).assign(result, this.loanCriteria);
      this.$DevelopmentInputWithNoLoginService
        .saveChangesToReferredSearch(result, this.$routeParams.uniqueId)
        .then((response) => {
          this.toggleEditSearchName = false;
        });
    } else {
      this.toggleEditSearchName = false;
    }
  }

  emailChange() {
    if (this.error) {
      this.error = "";
    }
  }

  videotour() {
    this.showVideo = true;
  }

  videopause(id) {
    this.showVideo = false;
    let containerElement = document.getElementById(id);
    let iframe_tag = containerElement.querySelector("iframe");
    if (iframe_tag) {
      let iframeSrc = iframe_tag.src;
      iframe_tag.src = iframeSrc;
    }
  }

  updateLoanRequiredValue() {
    if (this.isMaximumLoanRequired) {
      if (this.loanCriteria.CI_Dev_LoanReq) {
        this.tempLoanRequired = this.loanCriteria.CI_Dev_LoanReq;
      }
      this.loanCriteria.CI_Dev_LoanReq = 0;
      this.updateSearchViaSlider();
    } else {
      this.loanCriteria.CI_Dev_LoanReq = this.tempLoanRequired;
      this.updateSearchViaSlider();
    }
  }

  /**
   * Finds the product in the Summary DTO and matches it to the original LenderResultMinimalDTOs
   * This is needed following the resdesign of the Search Results view and to keep the other views
   * functioning with minimal refactoring/impact
   * @param summaryDTO
   */
  getLenderResultMinimalDTO(summaryDTO: LenderResultSummaryDTO) {
    // Find Lender offer within the original arrays to use on the compare view
    let match: LenderResultMinimalDTO;
    if (summaryDTO.IsMatching === true) {
      match = this.matchingResults.find(
        (result) => result.SeniorProductID === summaryDTO.ProductID,
      );
    } else if (summaryDTO.IsMatching === false) {
      match = this.largestResults.find(
        (result) => result.SeniorProductID === summaryDTO.ProductID,
      );
    }

    // Overriding the loan label with the Lender Generic name
    match.LoanLabel = summaryDTO.LoanLabel;

    return match;
  }

  CalcProfessionalCostsAndCharges(): number {
    if (this.loanCriteria) {
      var res: number = 0;
      if (this.loanCriteria.DI_BreakDownBuildCosts) {
        if (this.loanCriteria.CI_Dev_ProfPlanning) {
          res += Number(this.loanCriteria.CI_Dev_ProfPlanning);
        }
        if (this.loanCriteria.CI_Dev_ProfQS) {
          res += Number(this.loanCriteria.CI_Dev_ProfQS);
        }
        if (this.loanCriteria.DI_BuildProjectManag) {
          res += Number(this.loanCriteria.DI_BuildProjectManag);
        }
        if (this.loanCriteria.CI_Dev_S106CIL) {
          res += Number(this.loanCriteria.CI_Dev_S106CIL);
        }
        if (this.loanCriteria.DI_BuildMechEng) {
          res += Number(this.loanCriteria.DI_BuildMechEng);
        }
        if (this.loanCriteria.DI_BuildStrucEng) {
          res += Number(this.loanCriteria.DI_BuildStrucEng);
        }
        if (this.loanCriteria.DI_BuildPartyWall) {
          res += Number(this.loanCriteria.DI_BuildPartyWall);
        }
        if (this.loanCriteria.DI_BuildLandscaping) {
          res += Number(this.loanCriteria.DI_BuildLandscaping);
        }
        if (this.loanCriteria.DI_BuildWarranty) {
          res += Number(this.loanCriteria.DI_BuildWarranty);
        }
        if (this.loanCriteria.DI_BuildDemolition) {
          res += Number(this.loanCriteria.DI_BuildDemolition);
        }
        if (this.loanCriteria.DI_BuildOtherCosts) {
          res += Number(this.loanCriteria.DI_BuildOtherCosts);
        }
      } else {
        if (this.loanCriteria.CI_Dev_AdditionalOngoingCosts) {
          res += Number(this.loanCriteria.CI_Dev_AdditionalOngoingCosts);
        }
      }

      return res;
    }
  }

  CalcPurchaseAdditionalCosts(): number {
    if (this.loanCriteria) {
      var res: number = 0;

      if (this.loanCriteria.CI_Dev_SDLT) {
        res += Number(this.loanCriteria.CI_Dev_SDLT);
      }
      if (this.loanCriteria.DI_PurchaseAgentFees) {
        res += Number(this.loanCriteria.DI_PurchaseAgentFees);
      }
      if (this.loanCriteria.DI_PurchaseLegalFees) {
        res += Number(this.loanCriteria.DI_PurchaseLegalFees);
      }
      if (this.loanCriteria.DI_PurchaseOtherFees) {
        res += Number(this.loanCriteria.DI_PurchaseOtherFees);
      }

      return res;
    }
  }

  /**Close the search summary panel */
  showSearchSummary(show: boolean) {
    (this.$rootScope as any).hideSearchSummary = !show;
    this.sliderShown = show;
  }

  contingencyChanged() {
    this.recalcCosts("CI_Dev_Contingency");
    this.updateSearchViaSlider();
  }

  getSearchResultCount() {
    if (this.summarySearchResults) {
      return this.summarySearchResults.length;
    } else {
      return 0;
    }
  }

  getSearchLenderCount() {
    this.lenderService
      .getTotalLenders(ProductFamilyEnum.Development)
      .then((result) => {
        this.totalLender = result;
      });
  }

  /** user lookup for newsearch popup */
  userLookupForNewSearch(userSearchTerm: string) {
    this.loadingData = true;
    this.$user
      .searchByEmail(userSearchTerm)
      .then((response) => {
        if (this.isBroker) {
          this.existingUsers = response.filter(
            (x) =>
              x.OrganisationReferralId == this.selectedUser.OrganisationId &&
              !x.OrganisationId &&
              !x.IsOrganisationAdmin,
          );
        } else {
          this.existingUsers = response;
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  /** closes new search pop up*/
  cancelNewSearch() {
    this.newSearch = false;
    delete this.clientFirstName;
    delete this.clientEmail;
    delete this.clientSurname;
    delete this.clientPhoneNumber;
    delete this.clientId;
    this.existingborrower = null;
    this.showClientDetails = false;
    this.selecteduserName = null;
    this.sharedSearch = false;
    if (this.tempSearchName) {
      this.loanCriteria.SearchName = this.tempSearchName;
      this.tempSearchName = "";
    }
  }

  sendResultsToClient(notifyBorrower, reloadSearch = false) {
    if (this.isSaveAsClicked) {
      this.saveSaveAsSearch(true);
    } else {
      this.loadingData = true;
      this.newSearch = false;
      this.existingborrower = null;
      this.showClientDetails = false;
      if (this.searchName != null && this.searchName.length > 0) {
        this.loanCriteria.SearchName = this.searchName;
      }

      this.userService
        .getUserDetailsByEmailIfExists(this.clientEmail)
        .then((user: ApplicationUserDTO) => {
          if (user == null) {
            this.loanCriteria.UserId = null;
            this.$IntroducerService
              .sendResultsToClient(
                this.loanCriteria,
                this.clientFirstName,
                this.clientSurname,
                this.clientEmail,
                notifyBorrower,
                this.clientPhoneNumber,
              )
              .then((success) => {
                this.searchReference = success;
                if (reloadSearch) {
                  this.warningOff = true;
                  this.go("/referredSearch/" + this.searchReference);
                } else {
                  this.showSaveResults = 6;
                }
              });
          } else {
            this.loanCriteria.User = user;
            this.clientId = user.Id;
            this.$DevelopmentInputService
              .sendResultsToExistingClient(
                this.loanCriteria,
                this.clientFirstName,
                this.clientSurname,
                this.clientEmail,
                this.clientId,
                notifyBorrower,
                this.clientPhoneNumber,
              )
              .then((success) => {
                this.newSearchId = success;
                if (reloadSearch) {
                  this.warningOff = true;
                  this.go("/results/" + this.newSearchId);
                } else {
                  this.showSaveResults = 7;
                }
              });
          }
        })
        .finally(() => {
          delete this.clientFirstName;
          delete this.clientEmail;
          delete this.clientSurname;
          delete this.clientPhoneNumber;
          delete this.clientId;
          this.loadingData = false;
        });
    }
  }

  /*  sendResultsToExistingClient(notifyBorrower: boolean = true) {

            this.$DevelopmentInputService.sendResultsToExistingClient(this.loanCriteria, this.clientFirstName, this.clientSurname, this.clientEmail, this.clientId, notifyBorrower, this.clientPhoneNumber).then((success) => {
                this.newSearchId = success;
                this.loadingData = false;
                delete this.clientFirstName;
                delete this.clientEmail;
                delete this.clientSurname;
                delete this.clientPhoneNumber;
                delete this.clientId;
                this.showSaveResults = 7;              
            });
        }

        introducerSendResultsToClient(notifyBorrower: boolean = true) {
            // remove the user id because otherwise the client won't be able to see the search on their dashboard
            this.loanCriteria.UserId = null;
            this.$IntroducerService.sendResultsToClient(this.loanCriteria, this.clientFirstName, this.clientSurname, this.clientEmail, notifyBorrower, this.clientPhoneNumber).then((success) => {
                this.loadingData = false;
                delete this.clientFirstName;
                delete this.clientEmail;
                delete this.clientSurname;
                delete this.clientPhoneNumber;

                if (this.isBroker) {
                    this.searchReference = success;
                    this.showSaveResults = 6;
                } else {
                    this.showSaveResults = 5;
                }

            });
        }
        */

  viewClientsDashboardForNewSearch(userName: string) {
    //Look up client's account details
    this.loadingData = true;
    this.$user.searchByEmail(userName).then((users) => {
      console.log(users);
      console.log(users[0].FirstName);
      this.clientFirstName = users[0].FirstName;
      this.clientEmail = users[0].Email;
      this.clientSurname = users[0].LastName;
      this.clientPhoneNumber = users[0].PhoneNumber;
      this.clientId = users[0].Id;
      this.showClientDetails = true;
      this.loadingData = false;
    });
  }

  clearInputFields() {
    this.clientFirstName = null;
    this.clientEmail = null;
    this.clientSurname = null;
    this.clientPhoneNumber = null;
    this.showClientDetails = false;
    this.selecteduserName = null;
  }

  // These functions are for the FAQ slider

  toggleAccordion(id: number) {
    if (!this.accordionShow[id]) {
      this.accordionShow[id] = true;
    } else {
      this.accordionShow[id] = !this.accordionShow[id];
    }
  }

  requestEmail(reason): void {
    this.showRequestEmailForm = true;
    this.requestMessageReason = reason;
    this.message = true;
    this.messageContent = "";
  }

  closeModal() {
    this.message = false;
  }

  sendRequestEmail() {
    this.showRequestEmailForm = false;
    this.loadingData = true;
    this.sendingMessage = true;

    if (this.currentCase) {
      this.$accountservice
        .RequestAssistanceReview(this.requestMessageReason, this.currentCase.Id)
        .then((response) => {
          this.showMessage(
            `${this.appName} have been notified that you require assistance and will be in touch shortly to help.`,
          );

          let caseNote = {
            CaseId: this.currentCase.Id,
            UserId: this.selectedUser.Id,
            NoteText: "You have requested " + this.requestMessageReason + ".",
            ExpandNote: false,
          } as CaseNoteDTO;

          this.caseNoteService.addUpdate(caseNote);
        })
        .catch((error) => {
          this.showMessage(
            "Sorry, something went wrong while sending your email. Please try again.",
          );
        })
        .finally(() => {
          this.sendingMessage = false;
          this.loadingData = false;
        });
    } else if (this.searchid) {
      this.$lenderresultservice
        .RequestAssistance(this.requestMessageReason, this.searchid)
        .then((response) => {
          this.showMessage(
            `${this.appName} have been notified that you require assistance and will be in touch shortly to help.`,
          );
        })
        .catch((error) => {
          this.showMessage(
            "Sorry, something went wrong while sending your email. Please try again.",
          );
        })
        .finally(() => {
          this.sendingMessage = false;
          this.loadingData = false;
        });
    }
  }

  showMessage(message: string) {
    this.messageContent = message;
  }

  hideRequestAssistance(): boolean {
    return !this.searchid;
  }

  // End of functions for the FAQ slider

  getAppName() {
    let lsd = null;
    if (window.self == window.top)
      lsd = sessionStorage.getItem("applicationName");
    if (lsd != null) {
      this.appName = lsd;
    } else {
      this.appName = "Brickflow";
    }
  }

  defaultSearchName() {
    var currentdate = new Date();
    return (
      "My Development Finance Search " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear()
    );
  }

  fillHalfRegisterForm() {
    let name = new Date().toLocaleString().replace(/ |,|:|\/|/g, "");
    this.newUser.Email = `${name}@test.com`;
    this.newUser.FirstName = name;
    this.newUser.LastName = "t";
    this.newUser.PhoneNumber = "12345677";
    this.newUser.ApplicantDefinedRole = 0;
  }

  registerNow() {
    (this.$rootScope as any).loanCriteria = this.loanCriteria;
    this.cancelSaving();
    this.$location.path("promo");
  }

  sendShareSearchEmailToClient() {
    this.dataLoading = true;
    if (this.$routeParams.uniqueId) {
      this.$DevelopmentInputWithNoLoginService
        .isResultActive(this.$routeParams.uniqueId)
        .then((results) => {
          if (results) {
            this.$DevelopmentInputWithNoLoginService
              .sendEmailToNewUser(
                this.$routeParams.uniqueId,
                this.loanCriteria.ShowLenderNamesToBorrower,
                true,
              )
              .then((response) => {
                this.displayMsg =
                  "Search results have been sent to " +
                  this.loanCriteria.IntroduceeFirstName +
                  " " +
                  this.loanCriteria.IntroduceeSurname;
                this.newSearch = false;
                this.showMsg = true;
                this.dataLoading = false;
              })
              .catch((error) => {
                this.displayMsg =
                  "Sorry, something went wrong. Please try again.";
                this.newSearch = false;
                this.showMsg = true;
                this.dataLoading = false;
              });
          } else {
            this.displayMsg = `Changes have been made to this search by your client, you will now be taken back to your user dashboard`;
            this.newSearch = false;
            this.showMsgToAdminOrBroker = true;
            this.dataLoading = false;
          }
        });
    } else {
      this.$DevelopmentInputService
        .sendEmailToExistingUser(
          this.$routeParams.SearchId,
          this.loanCriteria.ShowLenderNamesToBorrower,
          true,
        )
        .then((response) => {
          this.displayMsg =
            "Search results have been sent to " +
            this.loanCriteria.IntroduceeFirstName +
            " " +
            this.loanCriteria.IntroduceeSurname;
          this.newSearch = false;
          this.showMsg = true;
          this.dataLoading = false;
        })
        .catch((error) => {
          this.displayMsg = "Sorry, something went wrong. Please try again.";
          this.newSearch = false;
          this.newSearch = false;
          this.showMsg = true;
          this.dataLoading = false;
        });
    }
  }

  deleteSearch() {
    if (this.$routeParams.SearchId) {
      this.$DevelopmentInputService
        .markasdeleted(this.$routeParams.SearchId)
        .then((response) => {
          this.displayMsg = "Search is successfully deleted";
          this.showMsg = true;
          this.ShowdeleteSearch = true;
        })
        .catch((error) => {
          this.displayMsg = "Sorry, something went wrong. Please try again.";
          this.showMsg = true;
        });
    } else {
      /** This will check if the result shared with new user is active */
      this.$DevelopmentInputWithNoLoginService
        .isResultActive(this.$routeParams.uniqueId)
        .then((results) => {
          if (!results) {
            this.displayMsg = `Changes have been made to this search by your client, you will now be taken back to your user dashboard`;
            this.showMsgToAdminOrBroker = true;
          } else {
            this.$DevelopmentInputWithNoLoginService
              .markasdeleted(this.loanCriteria.Id)
              .then((response) => {
                this.displayMsg = "Search is successfully  deleted";
                this.showMsg = true;
                this.ShowdeleteSearch = true;
              })
              .catch((error) => {
                this.displayMsg =
                  "Sorry, something went wrong. Please try again.";
                this.showMsg = true;
              });
          }
        });
    }
  }

  closeDeleteModal() {
    this.showMsg = false;
    this.displayMsg = null;
    this.ShowdeleteSearch = false;
    this.warningOff = true;
    this.$auth.clearSelectedNavMenuAndFilterStorageData();
    this.go("/userdashboard");
  }

  onDIPClick(item: LenderResultSummaryDTO) {
    this.isDIPClicked = true;

    this.toggleLenderComparisonSelection(item);

    if (
      this.searchid &&
      this.loanCriteria.SearchName.startsWith("My Loan Search")
    ) {
      this.showSaveResults = 2;
    } else if (this.searchid) {
      this.register(true, this.productResultList);
      this.warningOff = false;
    } else if (this.loanCriteria.SearchName) {
      this.register(true, this.productResultList);
      this.doubleWarningOff = true;
    } else {
      this.showSaveResults = 2;
    }
  }

  /* onClickSaveAs() {
            this.showSaveResults = 3;
            this.tempSearchName = this.loanCriteria.SearchName;
            this.loanCriteria.SearchName = '';
        }*/

  onClickSaveAs() {
    this.isSaveAsClicked = true;
    if (this.isAdmin || this.isBroker) {
      this.tempSearchName = this.loanCriteria.SearchName;
      this.loanCriteria.SearchName = "";
      this.showShareSearchModal();
    } else {
      this.showSaveResults = 3;
    }
  }

  saveAsWthOutIntroducee() {
    this.isSaveAsWthOutIntroducee = true;
    this.tempSearchName = this.loanCriteria.SearchName;
    this.loanCriteria.SearchName = "";
    this.showShareSearchModal();
  }

  saveChangesToReferredSearch() {
    var result = {} as DevelopmentWithNoLoginDTO;
    this.loadingData = true;
    (<any>Object).assign(result, this.loanCriteria);
    this.$DevelopmentInputWithNoLoginService
      .saveChangesToReferredSearch(result, this.$routeParams.uniqueId)
      .then((savedSearchId) => {
        this.loadingData = false;
      });
  }

  change() {
    if ((this.newUser as any).ConfirmPassword === this.newUser.Password) {
      this.error = "";
    } else {
      this.error = "Passwords do not match";
    }
  }

  getUsersBelongToBrokerOrAdmin() {
    this.loadingData = true;
    this.$user
      .getUserSummariesForBrokerOrAdmin()
      .then((response) => {
        this.existingUsers = response;
      });
  }

  saveResultsClicked() {
    if (this.prevPath) {
      this.isSaveorSaveAsClicked = false;
    } else {
      this.isSaveorSaveAsClicked = true;
    }
    this.register(false, this.productResultList);
  }

  checkShowBridgingEligibility() {
    return this.$auth.getShowBridgingEligibility();
  }

  showBookMeetingButton() {
    return (
      !this.isLoggedInUser &&
      this.isClient &&
      this.brokerDetail == "" &&
      this.brokerageOrg != null
    );
  }

  bookMeeting() {
    this.$auth
      .getHubspotDeveloperBookMeetingWithSearch()
      .then((hubSpotUrl: string) => {
        window.open(hubSpotUrl);
      });
  }

  onShareholderDepositRequiredChange(e: ShareholderDepositRequiredEnum) {
    switch (e) {
      case ShareholderDepositRequiredEnum.None:
        this.loanCriteria.F_ShareholderDepositRequired = 0;
        break;
      case ShareholderDepositRequiredEnum.UpTo25Percent:
        this.loanCriteria.F_ShareholderDepositRequired = 0.25;
        break;
      case ShareholderDepositRequiredEnum.TwentyFiveTo50Percent:
        this.loanCriteria.F_ShareholderDepositRequired = 0.5;
        break;
      case ShareholderDepositRequiredEnum.FiftyPercentPlus:
        this.loanCriteria.F_ShareholderDepositRequired = 1.0;
        break;
      default:
        this.loanCriteria.F_ShareholderDepositRequired = null;
        break;
    }
  }

  getShareholderDepositRequired() {
    var value = null;
    switch (this.loanCriteria.F_ShareholderDepositRequired) {
      case 0:
        value = ShareholderDepositRequiredEnum.None;
        break;
      case 0.25:
        value = ShareholderDepositRequiredEnum.UpTo25Percent;
        break;
      case 0.5:
        value = ShareholderDepositRequiredEnum.TwentyFiveTo50Percent;
        break;
      case 1.0:
        value = ShareholderDepositRequiredEnum.FiftyPercentPlus;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  getPreviousDevelopments() {
    var value = null;
    switch (this.loanCriteria.F_NumberOfPreviousDevelopments) {
      case 1:
        value = PreviousDevelopmentsEnum.One;
        break;
      case 2:
        value = PreviousDevelopmentsEnum.Two;
        break;
      case 3:
        value = PreviousDevelopmentsEnum.Three;
        break;
      case 4:
        value = PreviousDevelopmentsEnum.FourOrMore;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onPersonalGuaranteeLevelOptionsChange(e: PersonalGuaranteeLevelEnum) {
    switch (e) {
      case PersonalGuaranteeLevelEnum.UpTo20Percent:
        this.loanCriteria.F_PersonalGuaranteeLevel = 0.2;
        break;
      case PersonalGuaranteeLevelEnum.TwentyOneTo35Percent:
        this.loanCriteria.F_PersonalGuaranteeLevel = 0.35;
        break;
      case PersonalGuaranteeLevelEnum.ThirtySixTo50Percent:
        this.loanCriteria.F_PersonalGuaranteeLevel = 0.5;
        break;
      case PersonalGuaranteeLevelEnum.OneHundredPercent:
        this.loanCriteria.F_PersonalGuaranteeLevel = 1.0;
        break;
      default:
        this.loanCriteria.F_PersonalGuaranteeLevel = null;
        break;
    }
  }

  getPersonalGuaranteeLevelOptions() {
    var value = null;
    switch (this.loanCriteria.F_PersonalGuaranteeLevel) {
      case 0.2:
        value = PersonalGuaranteeLevelEnum.UpTo20Percent;
        break;
      case 0.35:
        value = PersonalGuaranteeLevelEnum.TwentyOneTo35Percent;
        break;
      case 0.5:
        value = PersonalGuaranteeLevelEnum.ThirtySixTo50Percent;
        break;
      case 1.0:
        value = PersonalGuaranteeLevelEnum.OneHundredPercent;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxHouseSalesPriceChange(e: MaxHouseSalePriceEnum) {
    switch (e) {
      case MaxHouseSalePriceEnum.UpTo1Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 1000000;
        break;
      case MaxHouseSalePriceEnum.UpTo2Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 2000000;
        break;
      case MaxHouseSalePriceEnum.UpTo5Million:
        this.loanCriteria.F_MaxHouseSalesPrice = 5000000;
        break;
      case MaxHouseSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxHouseSalesPrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxHouseSalesPrice = null;
        break;
    }
  }

  getMaxHouseSalesPrice() {
    var value = null;
    switch (this.loanCriteria.F_MaxHouseSalesPrice) {
      case 1000000:
        value = MaxHouseSalePriceEnum.UpTo1Million;
        break;
      case 2000000:
        value = MaxHouseSalePriceEnum.UpTo2Million;
        break;
      case 5000000:
        value = MaxHouseSalePriceEnum.UpTo5Million;
        break;
      case -999:
        value = MaxHouseSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxFlatUnitPriceChange(e: MaxFlatUnitPriceEnum) {
    switch (e) {
      case MaxFlatUnitPriceEnum.UpTo1Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 1000000;
        break;
      case MaxFlatUnitPriceEnum.UpTo2Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 2000000;
        break;
      case MaxFlatUnitPriceEnum.UpTo3Million:
        this.loanCriteria.F_MaxFlatUnitPrice = 3000000;
        break;
      case MaxFlatUnitPriceEnum.NoMax:
        this.loanCriteria.F_MaxFlatUnitPrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxFlatUnitPrice = null;
        break;
    }
  }

  getMaxFlatUnitPrice() {
    var value = null;
    switch (this.loanCriteria.F_MaxFlatUnitPrice) {
      case 1000000:
        value = MaxFlatUnitPriceEnum.UpTo1Million;
        break;
      case 2000000:
        value = MaxFlatUnitPriceEnum.UpTo2Million;
        break;
      case 3000000:
        value = MaxFlatUnitPriceEnum.UpTo3Million;
        break;
      case -999:
        value = MaxFlatUnitPriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  onMaxSqFtSalePriceChange(e: MaxSqFtSalePriceEnum) {
    switch (e) {
      case MaxSqFtSalePriceEnum.UpTo850:
        this.loanCriteria.F_MaxSqFtSalePrice = 850;
        break;
      case MaxSqFtSalePriceEnum.UpTo1000:
        this.loanCriteria.F_MaxSqFtSalePrice = 1000;
        break;
      case MaxSqFtSalePriceEnum.UpTo1500:
        this.loanCriteria.F_MaxSqFtSalePrice = 1500;
        break;
      case MaxSqFtSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxSqFtSalePrice = -999;
        break;
      default:
        this.loanCriteria.F_MaxSqFtSalePrice = null;
        break;
    }
  }

  onMaxSqFtFlatsSalePriceChange(e: MaxSqFtSalePriceEnum) {
    switch (e) {
      case MaxSqFtSalePriceEnum.UpTo850:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 850;
        break;
      case MaxSqFtSalePriceEnum.UpTo1000:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 1000;
        break;
      case MaxSqFtSalePriceEnum.UpTo1500:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = 1500;
        break;
      case MaxSqFtSalePriceEnum.NoMax:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = -999;
        break;
      default:
        this.loanCriteria.F_MaxSqFtSalePriceFlats = null;
        break;
    }
  }

  getMaxSqFtSalePrice() {
    var value = null;
    switch (this.loanCriteria.F_MaxSqFtSalePrice) {
      case 850:
        value = MaxSqFtSalePriceEnum.UpTo850;
        break;
      case 1000:
        value = MaxSqFtSalePriceEnum.UpTo1000;
        break;
      case 1500:
        value = MaxSqFtSalePriceEnum.UpTo1500;
        break;
      case -999:
        value = MaxSqFtSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  getMaxSqFtSalePriceFlats() {
    var value = null;
    switch (this.loanCriteria.F_MaxSqFtSalePriceFlats) {
      case 850:
        value = MaxSqFtSalePriceEnum.UpTo850;
        break;
      case 1000:
        value = MaxSqFtSalePriceEnum.UpTo1000;
        break;
      case 1500:
        value = MaxSqFtSalePriceEnum.UpTo1500;
        break;
      case -999:
        value = MaxSqFtSalePriceEnum.NoMax;
        break;
      default:
        value = null;
        break;
    }
    return value;
  }

  applyFilters() {
    this.loadingData = true;
    this.showFilters = false;
    this.isFilterUpdate = true;
    this.clearSelected();
    this.updateResults();

    if (this.loanCriteria.Id) {
      if (
        this.$routeParams.uniqueId !== undefined &&
        this.$routeParams.uniqueId != "null"
      ) {
        var result = {} as DevelopmentWithNoLoginDTO;
        (<any>Object).assign(result, this.loanCriteria);
        this.$DevelopmentInputWithNoLoginService
          .saveChangesToReferredSearch(result, this.$routeParams.uniqueId)
          .catch((error) => {
            this.displayMsg =
              "Sorry, something went wrong and your filters were not saved. Please try again.";
            this.showMsg = true;
          });
      } else {
        this.$DevelopmentInputService
          .saveSearch(this.loanCriteria)
          .catch((error) => {
            this.displayMsg =
              "Sorry, something went wrong and your filters were not saved. Please try again.";
            this.showMsg = true;
          });
      }
    }
  }

  clearClientFilters() {
    this.loanCriteria.F_IsFirstTimeDeveloper = null;
    this.loanCriteria.F_NumberOfPreviousDevelopments = null;
    this.loanCriteria.F_ShareholderDepositRequired = null;
    this.shareholderDepositRequired = null;
    this.loanCriteria.F_IsPersonalName = null;
    this.loanCriteria.F_IsOffshoreCompany = null;
    this.loanCriteria.F_IsMainShareholderOverseas = null;
    this.loanCriteria.F_PersonalGuaranteeLevel = null;
    this.personalGuaranteeLevel = null;
    this.loanCriteria.F_HasAdverseCredit = null;
    this.loanCriteria.F_MaxCommercialFloorspace = null;
  }

  showClearForClientFilters() {
    if (
      this.loanCriteria.F_IsFirstTimeDeveloper != null ||
      this.loanCriteria.F_NumberOfPreviousDevelopments != null ||
      this.loanCriteria.F_ShareholderDepositRequired != null ||
      this.loanCriteria.F_IsPersonalName != null ||
      this.loanCriteria.F_IsOffshoreCompany != null ||
      this.loanCriteria.F_IsMainShareholderOverseas != null ||
      this.loanCriteria.F_PersonalGuaranteeLevel != null ||
      this.loanCriteria.F_HasAdverseCredit != null ||
      this.loanCriteria.F_MaxCommercialFloorspace != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearPropertyFilters() {
    this.loanCriteria.F_IsAirRights = null;
    this.loanCriteria.F_IsModular = null;
    this.loanCriteria.F_IsPermittedDevelopmentScheme = null;
    this.loanCriteria.F_IsGradeOneListed = null;
    this.loanCriteria.F_IsGradeTwoListed = null;
    this.loanCriteria.F_MaxHouseSalesPrice = null;
    this.loanCriteria.F_MaxFlatUnitPrice = null;
    this.loanCriteria.F_MaxNumberOfUnits = null;
    //this.loanCriteria.F_MaxPercCommercialForMixedUse = null;
    this.loanCriteria.F_MaxSqFtSalePrice = null;
    this.loanCriteria.F_MaxSqFtSalePriceFlats = null;
    this.loanCriteria.F_IsWorkStarted = null;
    this.maxHouseSalesPrice = null;
    this.maxFlatUnitPrice = null;
    this.maxSqFtSalePrice = null;
    this.maxPercCommercialForMixedUse = null;
    this.maxSqFtSalePriceFlats = null;
    this.previousDevelopments = null;
  }

  showClearForPropertyFilters() {
    if (
      this.loanCriteria.F_IsAirRights != null ||
      this.loanCriteria.F_IsModular != null ||
      this.loanCriteria.F_IsPermittedDevelopmentScheme != null ||
      this.loanCriteria.F_IsGradeOneListed != null ||
      this.loanCriteria.F_IsGradeTwoListed != null ||
      this.loanCriteria.F_MaxHouseSalesPrice != null ||
      this.loanCriteria.F_MaxFlatUnitPrice != null ||
      this.loanCriteria.F_MaxNumberOfUnits != null ||
      //this.loanCriteria.F_MaxPercCommercialForMixedUse != null ||
      this.loanCriteria.F_MaxSqFtSalePrice != null ||
      this.loanCriteria.F_IsWorkStarted != null ||
      this.loanCriteria.F_MaxSqFtSalePriceFlats != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearFinanceFilters() {
    this.loanCriteria.F_IsChargeOnAdditionalProperty = null;
    this.loanCriteria.F_IsFixedRate = null;
    this.loanCriteria.F_InterestRateBasis = null;
    this.loanCriteria.F_IsBank = null;
  }

  showClearForFinanceFilters() {
    if (
      this.loanCriteria.F_IsChargeOnAdditionalProperty != null ||
      this.loanCriteria.F_IsFixedRate != null ||
      this.loanCriteria.F_InterestRateBasis != null ||
      this.loanCriteria.F_IsBank != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearAllFilters() {
    this.clearClientFilters();
    this.clearPropertyFilters();
    this.clearFinanceFilters();
  }

  onClickFilterCancel() {
    this.loanCriteria.F_IsFirstTimeDeveloper =
      this.tempLoanCriteria.F_IsFirstTimeDeveloper;
    this.loanCriteria.F_NumberOfPreviousDevelopments =
      this.tempLoanCriteria.F_NumberOfPreviousDevelopments;
    this.loanCriteria.F_ShareholderDepositRequired =
      this.tempLoanCriteria.F_ShareholderDepositRequired;
    this.loanCriteria.F_IsPersonalName = this.tempLoanCriteria.F_IsPersonalName;
    this.loanCriteria.F_IsOffshoreCompany =
      this.tempLoanCriteria.F_IsOffshoreCompany;
    this.loanCriteria.F_IsMainShareholderOverseas =
      this.tempLoanCriteria.F_IsMainShareholderOverseas;
    this.loanCriteria.F_PersonalGuaranteeLevel =
      this.tempLoanCriteria.F_PersonalGuaranteeLevel;
    this.loanCriteria.F_IsAirRights = this.tempLoanCriteria.F_IsAirRights;
    this.loanCriteria.F_IsModular = this.tempLoanCriteria.F_IsModular;
    this.loanCriteria.F_IsPermittedDevelopmentScheme =
      this.tempLoanCriteria.F_IsPermittedDevelopmentScheme;
    this.loanCriteria.F_IsGradeOneListed =
      this.tempLoanCriteria.F_IsGradeOneListed;
    this.loanCriteria.F_IsGradeTwoListed =
      this.tempLoanCriteria.F_IsGradeTwoListed;
    this.loanCriteria.F_MaxHouseSalesPrice =
      this.tempLoanCriteria.F_MaxHouseSalesPrice;
    this.loanCriteria.F_MaxFlatUnitPrice =
      this.tempLoanCriteria.F_MaxFlatUnitPrice;
    this.loanCriteria.F_MaxNumberOfUnits =
      this.tempLoanCriteria.F_MaxNumberOfUnits;
    // this.loanCriteria.F_MaxPercCommercialForMixedUse =
    //   this.tempLoanCriteria.F_MaxPercCommercialForMixedUse;
    this.loanCriteria.F_MaxSqFtSalePrice =
      this.tempLoanCriteria.F_MaxSqFtSalePrice;
    this.loanCriteria.F_MaxSqFtSalePriceFlats =
      this.tempLoanCriteria.F_MaxSqFtSalePriceFlats;
    this.loanCriteria.F_IsWorkStarted = this.tempLoanCriteria.F_IsWorkStarted;
    this.loanCriteria.F_IsChargeOnAdditionalProperty =
      this.tempLoanCriteria.F_IsChargeOnAdditionalProperty;
    this.loanCriteria.F_IsFixedRate = this.tempLoanCriteria.F_IsFixedRate;
    this.loanCriteria.F_InterestRateBasis =
      this.tempLoanCriteria.F_InterestRateBasis;
    this.loanCriteria.F_IsBank = this.tempLoanCriteria.F_IsBank;
  }

  checkFilterSelected(property: string) {
    let value = this.loanCriteria[property];
    if (typeof value === "boolean" && value === true) {
      return true;
    } else if (value != null && typeof value != "boolean") {
      return true;
    }
    return false;
  }

  filterPropertyToText(property: string) {
    let value = this.loanCriteria[property];
    if (typeof value == "boolean" && value == true) {
      return;
    } else if (value != null && typeof value != "boolean") {
      return `: ${this.getPropertyValue(property)}`;
    }
    return false;
  }

  getDisplayTextForProperty(property) {
    switch (property) {
      case "F_IsFirstTimeDeveloper":
        return "1st Time Dev";
      case "F_ShareholderDepositRequired":
        return "Shareholder Deposit";
      case "F_IsPersonalName":
        return "Personal Name";
      case "F_IsOffshoreCompany":
        return "Non-UK Company";
      case "F_IsMainShareholderOverseas":
        return "Client Based Overseas";
      case "F_PersonalGuaranteeLevel":
        return "PG";
      case "F_IsAirRights":
        return "Air Rights Scheme";
      case "F_IsModular":
        return "Modular";
      case "F_IsPermittedDevelopmentScheme":
        return "PD Scheme";
      case "F_IsGradeOneListed":
        return "Grade 1 Listed";
      case "F_IsGradeTwoListed":
        return "Grade 2 Listed";
      case "F_MaxHouseSalesPrice":
        return "Max Sales Price (Houses)";
      case "F_MaxSqFtSalePrice":
        return "Max £/sq.ft (Houses)";
      case "F_MaxSqFtSalePriceFlats":
        return "Max £/sq.ft (Flats)";
      case "F_MaxFlatUnitPrice":
        return "Max Sales Price (Flats)";
      // case "F_MaxPercCommercialForMixedUse":
      //   return "Max Commercial or Mixed Use";
      case "F_NumberOfPreviousDevelopments":
        return "Previous Developments";
      case "F_MaxNumberOfUnits":
        return `Max Units`;
      case "F_IsWorkStarted":
        return "Works Commenced";
      case "F_IsChargeOnAdditionalProperty":
        return "Additional Security";
      case "F_IsFixedRate":
        return "Fixed Rate";
      case "F_HasAdverseCredit":
        return "Adv. Credit";
      case "F_MaxCommercialFloorspace":
        return "Com. Floorspace (max %)";
      default:
        return "";
    }
  }

  getPropertyValue(property) {
    let propertyValue = this.loanCriteria[property];
    switch (property) {
      case "F_ShareholderDepositRequired":
        return this.selectListService
          .GetShareholderDepositRequired()
          .find((item) => item.key === this.getShareholderDepositRequired())
          ?.displayName;
      case "F_PersonalGuaranteeLevel":
        return this.selectListService
          .GetPersonalGuaranteeLevels()
          .find((item) => item.key === this.getPersonalGuaranteeLevelOptions())
          ?.displayName;
      case "F_MaxHouseSalesPrice":
        return this.selectListService
          .GetMaxHouseSalePrices()
          .find((item) => item.key === this.getMaxHouseSalesPrice())
          ?.displayName;
      case "F_MaxFlatUnitPrice":
        return this.selectListService
          .GetMaxFlatUnitPrices()
          .find((item) => item.key === this.getMaxFlatUnitPrice())?.displayName;
      case "F_MaxSqFtSalePrice":
        return this.selectListService
          .GetMaxSqFtSalePrices()
          .find((item) => item.key === this.getMaxSqFtSalePrice())?.displayName;
      case "F_MaxSqFtSalePriceFlats":
        return this.selectListService
          .GetMaxSqFtSalePrices()
          .find((item) => item.key === this.getMaxSqFtSalePriceFlats())
          ?.displayName;
      case "F_NumberOfPreviousDevelopments":
        return this.selectListService
          .GetNumberOfPreviousDevelopments()
          .find((item) => item.key === this.getPreviousDevelopments())
          ?.displayName;
      case "F_MaxNumberOfUnits":
        return propertyValue;
      case "F_MaxCommercialFloorspace":
        return this.selectListService
          .GetMaxCommercialFloorspaceOptions()
          .find((item) => item.key === this.loanCriteria.F_MaxCommercialFloorspace)
          ?.displayName;
      default:
        return propertyValue;
    }
  }

  countFiltersSelected() {
    let count = 0;
    if (this.loanCriteria) {
      this.filterProperties.forEach((property) => {
        if (this.checkFilterSelected(property)) {
          count++;
        }
      });
    }
    return count;
  }

  splitCamelCase(str) {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
  }

  showShareSearchModal() {
    this.newSearch = true;
    this.loanCriteria.ShowLenderNamesToBorrower =
      this.loanCriteria.ShowLenderInfoBrokerOverride;
  }

  isShareNoteFormComplete(): boolean {
    if (
      this.shareEmailSubject == "undefined" ||
      this.shareEmailSubject == null ||
      this.shareEmailSubject.length == 0
    ) {
      return false;
    }
    if (
      this.shareNote == "undefined" ||
      this.shareNote == null ||
      this.shareNote.length == 0
    ) {
      return false;
    }
    return true;
  }

  openSendToLender(item: LenderResultSummaryDTO) {
    this.shareFirstName = item.ContactUserFirstName;
    this.lenderEmail = item.ContactUserEmail;
    this.showContactLender = true;
  }

  sendToLender() {
    this.loadingData = true;
    this.$lenderresultservice
      .sendNoteToLender(
        this.lenderEmail,
        this.shareEmailSubject,
        this.shareNote,
      )
      .then((success) => {
        this.showContactLender = false;
        this.loadingData = false;
      });
  }

  /** This will check if the result shared with new user is active */
  isResultActive() {
    if (this.$routeParams.uniqueId && this.isBroker) {
      this.$DevelopmentInputWithNoLoginService
        .isResultActive(this.$routeParams.uniqueId)
        .then((results) => {
          if (results) {
            return true;
          } else {
            this.displayMsg = `Changes have been made to this search by your client, you will now be taken back to your user dashboard`;
            this.showMsgToAdminOrBroker = true;
          }
        });
    }
    return true;
  }

  toggleLenderContactDetails(item: LenderResultSummaryDTO) {
    if (item.ShowLenderUserDetails == false) {
      this.summarySearchResults.forEach(
        (offer) => (offer.ShowLenderUserDetails = false),
      );
    }
    item.ShowLenderUserDetails = !item.ShowLenderUserDetails;
  }

  openSendResultsToBorrowerModal() {
    if (this.loanCriteria.ShowLenderNamesToBorrower == null) {
      this.organisationService.getOrganisation().then((org) => {
        this.loanCriteria.ShowLenderNamesToBorrower = org.ShowLenderNames;
      });
    }

    this.existingborrower = null;
    this.clientFirstName = this.loanCriteria.IntroduceeFirstName;
    this.clientSurname = this.loanCriteria.IntroduceeSurname;
    this.clientEmail = this.loanCriteria.IntroduceeEmail;
    this.clientPhoneNumber = this.loanCriteria.IntroduceePhone;
    this.newSearch = true;
    this.sharedSearch = true;
  }

  saveResultsForShareSearchToClient() {
    if (!this.isSaveAsClicked) {
      this.isSaveClientSearch = true;
      this.register(false, this.productResultList);
    } else {
      this.saveSaveAsSearch();
    }
  }

  getLenderNames(): string {
    if (this.filterLenderNames.length === 1) {
      return "lender " + this.filterLenderNames[0];
    } else {
      return "lenders " + this.filterLenderNames.join(", ");
    }
  }

  isLendersFiltered(): boolean {
    //If the search is empty return true
    if (!this.summarySearchResults && this.caseLenders) return true;

    if (!this.caseLenders) return false;

    //For all products that have been submitted for DIP and active, check if in the search
    return !this.caseLenders.every((lender) => {
      if (
        lender.CaseLenderState !== CaseLenderStateEnum.Withdrawn &&
        lender.CaseLenderState !== CaseLenderStateEnum.Rejected
      ) {
        const match = this.summarySearchResults.some(
          (summarySearchResult) =>
            lender.ProductID === summarySearchResult.ProductID,
        );
        if (!match) {
          if (this.filterLenderNames.indexOf(lender.LenderName) === -1) {
            this.filterLenderNames.push(lender.LenderName);
          }
        }
        return match;
      }
      return true;
    });
  }

  getTotalShortlistedLenders() {
    if (this.caseLenders) {
      this.caseLendersNotInResults = this.caseLenders.filter(
        (cl) =>
          !this.comparisonList.some(
            (compare) => compare.SeniorProductID == cl.ProductID,
          ),
      );

      return (
        this.comparisonList.length +
        (this.caseLendersNotInResults ? this.caseLendersNotInResults.length : 0)
      );
    } else {
      return this.comparisonList.length;
    }
  }

  reloadPage() {
    window.location.reload();
  }

  sendMessageToBroker(message) {
    this.loadingData = true;
    this.borrowerMessage = null;
    var newLeadFirstName = null,
      newLeadLastName = null,
      newLeadEmail = null;

    if (this.$location.path().startsWith("/referredSearch")) {
      newLeadFirstName = this.newUser.FirstName;
      newLeadLastName = this.newUser.LastName;
      newLeadEmail = this.newUser.Email;
    } else if (this.enterpriseClientId) {
      newLeadFirstName = this.loanCriteria.IntroduceeFirstName;
      newLeadLastName = this.loanCriteria.IntroduceeSurname;
      newLeadEmail = this.loanCriteria.IntroduceeEmail;
    } else {
      newLeadFirstName = this.newLeadFirstName;
      newLeadLastName = this.newLeadLastName;
      newLeadEmail = this.newLeadEmail;
    }

    this.organisationService
      .sendBorrowerMessageToSearchBroker(
        0,
        message,
        newLeadFirstName,
        newLeadLastName,
        newLeadEmail,
        ProductTypeEnum.Development,
        "RESULTSSCREEN",
        this.loanCriteria.UniqueRef,
      )
      .then((results) => {
        if (results) {
          this.displayMsg = `Message has been sent successfully.`;
          this.showMessageToborrower = true;
        } else {
          this.displayMsg = `There is problem sending a message, Please try later.`;
          this.showMessageToborrower = true;
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  closeContactBrokerModal() {
    this.showContactBrokerModal = false;
    this.showMessageToborrower = false;
    this.displayMsg = null;
  }

  logPdfDownload(eventName) {
    this.eventLogService.logEvent(
      eventName,
      EventLogEnum.Export,
      ProductTypeEnum.Development,
      this.$routeParams.uniqueId ? 0 : this.loanCriteria.Id,
      this.$routeParams.uniqueId ? this.$routeParams.uniqueId : "",
    );
  }

  calculateMonthsSinceOrigPurchase() {
    let date1 = new Date(this.loanCriteria.CI_Dev_DateOfOrigPurch);
    let date2 = new Date();
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months;
  }

  saveEnterpriseClientAndRenameDeal() {
    this.loadingData = true;

    this.userService.checkEmailExists(this.user.Email).then((response) => {
      if (!response) {
        let userFullName = this.user.FullName;
        let spaceIndex = userFullName.indexOf(" ");

        let firstName = "";
        let lastName = "";

        // if not space has been added to the name then put the whole name in the first name field
        if (spaceIndex == -1) {
          firstName = userFullName;
        } else {
          firstName = userFullName.substring(0, userFullName.indexOf(" "));
          lastName = userFullName.substring(userFullName.indexOf(" ") + 1);
        }

        this.loanCriteria.IntroduceeFirstName = firstName;
        this.loanCriteria.IntroduceeSurname = lastName;
        this.loanCriteria.IntroduceeEmail = this.user.Email;
        this.loanCriteria.IntroduceePhone = this.user.PhoneNumber;

        var currentDate = new Date();
        this.loanCriteria.SearchName = this.projectName;

        var result = {} as DevelopmentWithNoLoginDTO;
        (<any>Object).assign(result, this.loanCriteria);
        this.$DevelopmentInputWithNoLoginService
          .saveChangesToReferredSearch(
            result,
            this.loanCriteria.UniqueRef,
            true,
          )
          .then((response) => {
            this.showEnterpriseRegistrationModal = false;
            document.getElementById("body").style.overflow = "auto";
            if (window.self == window.top) {
              sessionStorage.setItem("userDetails", JSON.stringify(this.user));
            } else {
              this.organisationService.sendDataToParent(
                "userDetails",
                JSON.stringify(this.user),
              );
            }

            this.userService.sendEventToHubspot(
              this.user,
              "ENTERPRISE-CONTACT-REGISTERED",
              false,
              this.brokerageOrg?.OrganisationCode,
              UserRoleEnum.Client,
              this.loanCriteria && this.loanCriteria.OrganisationLinkId != null
                ? this.loanCriteria.OrganisationLinkId
                : 0,
              ProductTypeEnum.Development,
              0,
              this.loanCriteria.UniqueRef,
            );
            this.populateNewLeadInfo("borrower", JSON.stringify(this.user));
          })
          .finally(() => {
            this.loadingData = false;
          });
      } else {
        this.emailExistsError = true;
        this.loadingData = false;
      }
    });
  }

  addClientAndRenameDeal() {
    this.loadingData = true;
    var result = {} as DevelopmentWithNoLoginDTO;
    (<any>Object).assign(result, this.loanCriteria);
    this.$DevelopmentInputWithNoLoginService
      .addClientToSearch(
        result,
        this.loanCriteria.UniqueRef,
        Number(this.enterpriseClientId),
      )
      .then((response) => {
        if (response) {
          this.loanCriteria.IntroduceeFirstName = response.IntroduceeFirstName;
          this.loanCriteria.IntroduceeSurname = response.IntroduceeSurname;
          this.loanCriteria.IntroduceeEmail = response.IntroduceeEmail;
          this.loanCriteria.IntroduceePhone = response.IntroduceePhone;
          this.loanCriteria.SearchName = response.SearchName;
        }
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  gotoSignInPage() {
    this.$location.path("/login");
  }

  goToLink(url) {
    var baseUrl = window.location.href.split("#!")[0] + "#!";
    var newUrl = baseUrl + url;
    window.open(newUrl, "_blank");
  }

  toggleShowLenderNamesBrokerOverride() {
    // this.loanCriteria.ShowLenderInfoBrokerOverride = !this.loanCriteria.ShowLenderInfoBrokerOverride;
    this.comparisonList = [];
    this.updateResults(undefined, true);
  }

  showContactBrokerAndExportButton() {
    if (
      this.brokerageOrg != null &&
      this.loanCriteria != null &&
      this.loanCriteria.IntroduceeEmail != null &&
      this.loanCriteria.IntroduceeEmail.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  //This is used in shortlisting pdf
  prepareDataForShortlistPdf() {
    this.loanCriteria.ComparisonList = this.comparisonList;
  }

  getRegionByPostcode() {
    if (
      this.loanCriteria.PostcodeSearchString &&
      this.loanCriteria.PostcodeSearchString.replace(" ", "").length >= 2
    ) {
      if (
        this.$DevelopmentInputService.isValidPostcodeString(
          this.loanCriteria.PostcodeSearchString,
        )
      ) {
        this.dataLoading = true;
        this.$DevelopmentInputService
          .getRegionByPostcode(this.loanCriteria.PostcodeSearchString)
          .then((response) => {
            if (response.Location != null) {
              this.loanCriteria.CI_Dev_Locations = response.Location;
              this.showPostcodeErrorMessage = false;
              this.updateSearchViaSlider();
            } else {
              this.postcodeErrorMsg = response.PostCodeErrorMessage;
              this.showPostcodeErrorMessage = true;
            }
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.postcodeErrorMsg =
          "At least one letter and one number must be entered e.g. SW1.";
        this.showPostcodeErrorMessage = true;
      }
    }
  }

  isSelectedResult(offer: LenderResultSummaryDTO, index: number) {
    return this.comparisonContains(offer) && !offer.IsCaseLender;
  }

  isResultAvailableToSelect(offer: LenderResultSummaryDTO) {
    return offer.IsCaseLender;
  }

  getOrganisationAndBrokerDetails() {
    if (!this.isLoggedInUser && !this.isAdmin && !this.isBroker) {
      this.loadingData = true;
      this.organisationService
        .getOrganisationAndBrokerByUniqueRef(this.loanCriteria.UniqueRef)
        .then((response) => {
          if (response && response.Organisation != null) {
            this.brokerageOrg = response.Organisation;

            if (response.Organisation.IsBrickflow) {
              this.moveContactBrokerBtnInMiddle = true;
            } else {
              if (response.Broker != null) {
                this.brokerDetail = `${response.Broker.FullName} (${response.Broker.Email})`;
              }

              if (this.brokerageOrg.IsWhiteLabelled) {
                this.orgName = this.brokerageOrg.Name.replace(/ /g, "_");
              }
            }
          }
        })
        .finally(() => {
          this.loadingData = false;
        });
    }
  }

  sortByField(field) {
    this.loanCriteria.SortBy = field;
    this.sortSummaryResults();
    this.saveSearch(false, this.productResultList, true);
    this.showSortBy = !this.showSortBy;
  }

  sortSummaryResults() {
    switch (this.loanCriteria.SortBy) {
      case SortByEnum.ROCE:
        this.summarySearchResults.sort(
          (a, b) => b.ROCE - a.ROCE || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.TrueMonthlyCost:
        this.summarySearchResults.sort(
          (a, b) => a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.NetLoanSize:
        this.summarySearchResults.sort(
          (a, b) =>
            b.NetLoan - a.NetLoan || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.LenderName:
        this.summarySearchResults.sort(
          (a, b) =>
            a.LoanLabel.localeCompare(b.LoanLabel) ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.InterestRate:
        this.summarySearchResults.sort(
          (a, b) =>
            a.Interest - b.Interest || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.ArrangementFee:
        this.summarySearchResults.sort(
          (a, b) =>
            a.ArrangementFee - b.ArrangementFee ||
            a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.DepositEquity:
        this.summarySearchResults.sort(
          (a, b) =>
            a.Deposit - b.Deposit || a.TrueMonthlyCost - b.TrueMonthlyCost,
        );
        break;
      case SortByEnum.LTGDV:
        this.$lenderresultservice
          .sortProductsByLTGDV(this.summarySearchResults)
          .then((sortedResults) => {
            this.summarySearchResults = sortedResults;
          })
          .catch((error) => {
            console.error("Error sorting by LTGDV:", error);
          });
        break;
      default:
        this.summarySearchResults.sort((a, b) => {
          if (a.NetLoan < b.NetLoan) return 1;
          if (a.NetLoan > b.NetLoan) return -1;

          if (a.TrueMonthlyCost > b.TrueMonthlyCost) return 1;
          if (a.TrueMonthlyCost < b.TrueMonthlyCost) return -1;

          return 0;
        });
        this.loanCriteria.SortBy = SortByEnum.NetLoanSize;
    }

    for (var i = 0; i < this.summarySearchResults.length; i++) {
      this.summarySearchResults[i].SelectedRowNumber = i + 1;
    }
  }

  postLoanCriteriaRetrieveProcessing(loanCriteria) {
    this.loanCriteria = loanCriteria;
    this.tempLoanRequired = this.loanCriteria.CI_Dev_LoanReq;
    if (this.loanCriteria.CI_Dev_LoanReq > 0) {
      this.isMaximumLoanRequired = false;
    }
    this.loanCriteria.SaveQueryAndResults = true;
    this.isDIPClicked = this.loanCriteria.IsFullCase;

    this.updateResults(false);
  }

  saveSaveAsSearch(attachClient = false) {
    this.loadingData = true;

    let newSearchName =
      this.isBroker || this.isAdmin
        ? this.loanCriteria.SearchName
        : this.saveAsSearchName;

    let shareDealDto = {
      DealId: this.searchid,
      DealName: newSearchName,
      ShowLenderNamesAndLogos:
        this.loanCriteria?.ShowLenderNamesToBorrower ?? false,
      EmailLinkToClient: this.emailClient,
      AccessLevel: CaseAccessLevelEnum.FullAccess,
      IsApplicant: true,
      IsPrimary: true,
    } as ShareDealDTO;

    shareDealDto.ClientDto = {
      FirstName: this.clientFirstName,
      LastName: this.clientSurname,
      Email: this.clientEmail,
      PhoneNumber: this.clientPhoneNumber,
      ClientUserId: this.clientId,
    } as ClientDTO;

    this.$CaseService
      .saveSaveAsSearch(this.loanCriteria.Id, shareDealDto, attachClient)
      .then((result) => {
        this.displayMsg = `${newSearchName} search has been saved to your dashboard.`;
      })
      .catch((error) => {
        this.displayMsg = "Sorry, something went wrong. Please try again.";
      })
      .finally(() => {
        this.cancelNewSearch();
        this.loadingData = false;
        this.isSaveAsClicked = false;
        this.saveAsSearchName = "";
        this.showSaveResults = null;
        this.showMsg = true;
      });
  }

  returnShortlistedDealLenderStatusText(item: LenderResultSummaryDTO) {
    if (item.IsCaseLender && (this.currentCase.CaseStatus != CaseStatusEnum.Search && this.currentCase.CaseStatus != CaseStatusEnum.NewCase && this.currentCase.CaseStatus != CaseStatusEnum.ReadyToSubmit)) {
      switch (item.CaseLenderStatus) {
        case CaseLenderStateEnum.Shortlisted:
          return "Shortlisted";
        case CaseLenderStateEnum.Received:
          return "Awaiting DIP";
        case CaseLenderStateEnum.Offered:
          return "DIP Received";
        case CaseLenderStateEnum.Rejected:
          return "Lender Rejected";
        case CaseLenderStateEnum.Withdrawn:
          return "Lender Withdrawn";
        default:
          break
      }
    }
  }

  onFilterClick() {
    //This is added to show only applied filters on pill
    if (this.showFilters && !this.isLoggedInUser) {
      this.loanCriteria = { ...this.tempLoanCriteria };
    }
    this.showFilters = !this.showFilters;
  }

  onSortbyClick() {
    this.showSortBy = !this.showSortBy;
  }

  getSelectedSortByOptionText() {
    if (this.loanCriteria) {
      switch (this.loanCriteria.SortBy) {
        case SortByEnum.TrueMonthlyCost:
          return "True Monthly Cost";
        case SortByEnum.NetLoanSize:
          return "Net Loan Size";
        case SortByEnum.LenderCosts:
          return "Lender Costs (Est.)";
        case SortByEnum.LenderName:
          return "Lender Name (A-Z)";
        case SortByEnum.InterestRate:
          return "Interest Rate";
        case SortByEnum.ArrangementFee:
          return "Arrangement Fee";
        case SortByEnum.DepositEquity:
          return "Est. Deposit/Equity";
        case SortByEnum.ROCE:
          return "ROCE";
        case SortByEnum.LTGDV:
          return "LTGDV";
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  getSummaryComparisonList() {

    this.summaryComparisonList = [];
    for (var i = 0; i < this.comparisonList.length; i++) {
      let resultIndex = this.summarySearchResults.findIndex(
        (item) => item.ProductID == this.comparisonList[i].SeniorProductID,
      );

      if (resultIndex > -1) {
        this.summaryComparisonList.push(this.summarySearchResults[resultIndex]);
      }
    }

    return this.summaryComparisonList;

  }

  isProceedButtonDisabled(): boolean {
    if (
      !this.comparisonList ||
      this.comparisonList.length < 1 ||
      this.comparisonList.length > this.noOfShortlistAllowed ||
      this.isProceedClicked == true
    ) {
      return true;
    }

    return false;
  }

  onSaveClick() {
    if (this.loanCriteria.IntroduceeEmail) {
      this.isSaveorSaveAsClicked = true;
      this.register(false, this.productResultList);
    } else {
      this.showShareSearchModal();
    }
  }

}

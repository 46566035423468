import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { DealFileAttachmentDTO } from "@js/DTO/Deal/DealFileAttachmentDTO.cs.d";
import { DealLenderDTO } from "@js/DTO/Deal/DealLenderDTO.cs.d";
import { DealLenderMessageDTO } from "@js/DTO/Deal/DealLenderMessageDTO.cs.d";
import { DevelopmentFinanceDealDTO } from "@js/DTO/Deal/DevelopmentFinanceDealDTO.cs.d";
import { FeedBackDTO } from "@js/DTO/FeedBackDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { BridgingSubmitOfferRequest } from "@js/DTO/Messages/Deal/BridgingSubmitOfferMessage.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { InterestRateTypeEnum } from "@js/models/enum/InterestRateTypeEnum.cs.d";
import { MessageRoleEnum } from "@js/models/enum/MessageRoleEnum.cs.d";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { DealLenderMessageService } from "@js/services/Deal/DealLenderMessageService";
import { DealLenderService } from "@js/services/Deal/DealLenderService";
import { DevFinanceDealService } from "@js/services/Deal/DevFinanceDealService";
import { DealFileAttachmentService } from "@js/services/DealFileAttachmentService";
import { FeedBackService } from "@js/services/FeedBackService";
import { LenderService } from "@js/services/LenderService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class DevFinanceHeadsOfTermsController {
  static $inject = [
    "$routeParams",
    "$rootScope",
    "$location",
    "LenderService",
    "RoleService",
    "DealLenderMessageService",
    "DealFileAttachmentService",
    "$window",
    "DevFinanceDealService",
    "DealLenderService",
    "SelectListService",
    "FeedBackService",
    "UserService",
    "AuthService",
    "ProductService",
  ];

  dataLoading: boolean = false; // Controls showing the data loading "spinner"
  dealLenders: DealLenderDTO[];
  showInformationMessage: boolean = false;
  messageContent: string;

  showDirection: boolean = false;

  toggleFeedbackSection: boolean = false;
  toggleHoTDetailSection: boolean = false;
  toggleRejectSection: boolean = false;
  toggleWithdrawSection: boolean = false;
  currentUser: ApplicationUserDTO = {} as ApplicationUserDTO;
  isLender: boolean = false;
  isBroker: boolean = false;
  isAdmin: boolean = false;
  isClient: boolean = false;

  // show different sections to Lender based on which button clicked on Case Dashboard
  showFeedbackSectionLender: boolean = false;
  showHoTDetailSectionLender: boolean = false;
  showRejectSectionLender: boolean = false;
  showWithdrawSectionLender: boolean = false;

  showTsAndCsModal: boolean = false;
  showTsAndCsForm: boolean = false;
  sendingMessage: boolean = false;
  goToUserDashboard: boolean = false;

  currentDeal: DevelopmentFinanceDealDTO;
  currentCaseLender: DealLenderDTO;
  InterestRateTypeOptions = [];

  lenderTask: string = "";

  isRejectClicked: boolean = false;
  isWithdrawClicked: boolean = false;
  isConfirmClicked: boolean = false;
  isSubmitClicked: boolean = false;

  //Lender Message
  newChatMessage: string = "";
  showFullMessageId: number;

  //files
  fileUpload: DealFileAttachmentDTO[];
  uploadingFiles: FileUploadProgressDTO[];
  openModal: boolean = false;
  module = ModuleEnum.DIP;

  showFullLenderMessage = false;
  showFullBorrowerMessage = false;
  showAutofill: boolean = false;

  //Lender/Broker feedback properties
  showLenderFeedBackModal: boolean = false;
  showBrokerFeedBackModal: boolean = false;
  feedBackDTO: FeedBackDTO;
  prevPath: string;
  brokerName: string;
  brokerOrganisationName: string;
  lendingForm: ng.IFormController;

  //Deleteing a chat message
  selectedDealLenderMessageDto: DealLenderMessageDTO;
  showdeleteChatMessageModal: boolean = false;
  showConfirmDeleteButton: boolean = false;

  grossLoanCalcVal: number = 0;
  builLoanCalcVal: number = 0;
  landLoanCalcVal: number = 0;
  isDevFinance: boolean = true;

  productTypeText: string = null;
  noLenderOrMoreLenderError:boolean = false;
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private lenderService: LenderService,
    private roleService: RoleService,
    private dealLenderMessageService: DealLenderMessageService,
    private dealFileAttachmentService: DealFileAttachmentService,
    private $window: ng.IWindowService,
    private devFinanceDealService: DevFinanceDealService,
    private dealLenderService: DealLenderService,
    private selectListService: SelectListService,
    private feedBackService: FeedBackService,
    private userService: UserService,
    private $auth: AuthService,
    private productService: ProductService,
  ) {
    this.userService.getUserAutofill().then((response) => {
      this.showAutofill = response;
    });

    this.feedBackDTO = {} as FeedBackDTO;

    if (
      this.$routeParams.openModule &&
      this.$routeParams.openModule == "withdraw"
    ) {
      this.toggleWithdrawSection = true;
    } else if (
      this.$routeParams.openModule &&
      this.$routeParams.openModule == "reject"
    ) {
      this.toggleRejectSection = true;
    } else if (this.$routeParams.openModule) {
      this.toggleFeedbackSection = true;
    }

    if (this.$routeParams.DealId && this.$routeParams.DealLenderId) {
      this.dataLoading = true;

      this.devFinanceDealService
        .fetch(this.$routeParams.DealId)
        .then((response) => {
          this.currentDeal = response;
          if (this.currentDeal) {
            this.dealLenderService
              .fetchByDealId(
                this.$routeParams.DealId,
                this.roleService.getIsLenderVisible(),
                this.$routeParams.DealLenderId,
              )
              .then((response) => {
                this.dealLenders = response;
                if (this.$routeParams.DealLenderId) {
                  if (this.dealLenders.length != 1) {
                    this.noLenderOrMoreLenderError = true;
                    return;
                  }
                  this.currentCaseLender = this.dealLenders[0];

                  this.fileUpload = this.currentDeal.Attachments.filter((f) => {
                    return f.DealLenderId == this.$routeParams.DealLenderId;
                  });
                  this.calculateGrossLoanValVal();
                  this.calculateBuildLoadCalcVal();
                  this.calculateLandLoanCalcVal();
                }
              }).finally(() => {
                this.populateLenderInformation(this.currentUser)
              });

            this.lenderTask = sessionStorage.getItem("lenderTask");
            if (this.lenderTask != null && this.lenderTask.length > 0) {
              switch (this.lenderTask) {
                case "reject":
                  this.showRejectSectionLender = true;
                  break;
                case "withdraw":
                  this.showWithdrawSectionLender = true;
                  break;
                case "feedback":
                  this.showFeedbackSectionLender = true;
                  this.updateCaseLenderMessageReadDatetime();
                  break;
                case "terms":
                  this.showHoTDetailSectionLender = true;
                  break;
                default:
                  break;
              }
            }
            this.productTypeText = this.productService.getProductTypeFullName(
              this.currentDeal.ProductType,
            );
          } else {
            if ((this.$rootScope as any).selectedUser) {
              this.$location.path("/dashboard");
            }
          }
        })
        .finally(() => {
          this.dataLoading = false;
          this.getCurrentUserAndRoles();
        });
    }

    this.InterestRateTypeOptions = this.selectListService.GetInterestRateType();
  }

  /*checkingIfUserIsLender() {
            this.roleService.isLenderUser().then(response => {
                this.isLender = response;
            });
        }

        checkingIfUerIsBroker() {
            this.dataLoading = true;
            this.roleService.isBroker().then((response) => {
                this.isBroker = response;
            }).finally(() => {
                this.dataLoading = false;
            });
        }

        checkingIfUserIsAdmin() {
            this.dataLoading = true;
            this.roleService.isAdminUser().then((response) => {
                this.isAdmin = response;
            }).finally(() => {
                this.dataLoading = false;
            });
        }

        checkingIfUserIsClient() {
            this.dataLoading = true;
            this.roleService.isClientUser().then((response) => {
                this.isClient = response;
            }).finally(() => {
                this.dataLoading = false;
            });
        }*/

  /** Redirects to the case dashboard */
  goToCaseDashboard() {
    this.showFeedbackSectionLender = false;
    this.showHoTDetailSectionLender = false;
    this.showRejectSectionLender = false;
    this.showWithdrawSectionLender = false;
    if (
      this.isBroker && this.currentCaseLender && 
      !this.currentCaseLender.IsLenderReviewComplete &&
      (this.currentCaseLender.Status == CaseLenderStateEnum.Offered ||
        this.currentCaseLender.Status == CaseLenderStateEnum.Rejected ||
        this.currentCaseLender.Status == CaseLenderStateEnum.Withdrawn)
    ) {
      this.prevPath = "devfinancecasedashboard";
      this.feedBackDTO.IsLenderFeedback = true;
      this.showLenderFeedBackModal = true;
    } else {
      this.$location.path("/devfinancecasedashboard/" + this.currentDeal.Id);
    }
  }

  /** Close modal after send feedback */
  closeModal() {
    this.showDirection = false;
    this.showTsAndCsModal = false;
    this.showInformationMessage = false;
    this.isConfirmClicked = false;
    this.showdeleteChatMessageModal = false;
    if (this.goToUserDashboard) {
      this.$auth.clearSelectedNavMenuAndFilterStorageData();
      this.$location.path("/userdashboard");
      this.goToUserDashboard = false;
      this.showFeedbackSectionLender = false;
      this.showHoTDetailSectionLender = false;
      this.showRejectSectionLender = false;
      this.showWithdrawSectionLender = false;
    }

    if (this.prevPath) {
      if (this.isLender) {
        this.$auth.clearSelectedNavMenuAndFilterStorageData();
        this.$location.path("/userdashboard");
      } else {
        this.$location.path(`/${this.prevPath}/${this.currentDeal.Id}`);
      }
    }
  }

  goToLending() {
    if (
      this.isBroker && this.currentCaseLender &&
      !this.currentCaseLender.IsLenderReviewComplete &&
      (this.currentCaseLender.Status == CaseLenderStateEnum.Offered ||
        this.currentCaseLender.Status == CaseLenderStateEnum.Rejected ||
        this.currentCaseLender.Status == CaseLenderStateEnum.Withdrawn)
    ) {
      this.prevPath = "dealforum";
      this.feedBackDTO.IsLenderFeedback = true;
      this.showLenderFeedBackModal = true;
    } else {
      this.$location.path("/dealforum/" + this.currentDeal.Id);
    }
  }

  saveUpdatedCaseLender(): void {
    this.dealLenderService
      .addUpdate(this.currentCaseLender)
      .then((response) => {
        this.currentCaseLender = response;
        this.messageContent = "Your changes have been saved.";
        this.showInformationMessage = true;
      });
  }

  dummyDIP() {
    this.dealLenderService.dummyDIP(this);
  }

  /** Process the Reject button being clicked*/
  rejectClicked(): void {
    this.isRejectClicked = true;
    this.dealLenderService
      .rejectCase(this.currentCaseLender)
      .then((response) => {
        this.currentCaseLender.Status = response.CaseLenderState;
        this.brokerName = response.DealBrokerName;
        this.brokerOrganisationName = response.DealBrokerOrganisationName;
        this.messageContent =
          "The borrower has been notified you have rejected their application.";
      })
      .catch((error) => {
        this.messageContent = "Sorry, something went wrong. Please try again.";
        this.isRejectClicked = false;
      })
      .finally(() => {
        this.goToUserDashboard = true;
        this.currentCaseLender.IsBrokerReviewComplete ||
          this.currentDeal.BrokerOrganisationId == null ||
          !this.isLender
          ? (this.showInformationMessage = true)
          : this.showBrokerFeedBack();
      });
  }

  ///**Process the Withdraw button being clicked */
  withdrawClicked(): void {
    this.isWithdrawClicked = true;
    this.dealLenderService
      .withdrawHoT(this.currentCaseLender)
      .then((response) => {
        this.currentCaseLender.Status = response.CaseLenderState;
        this.brokerName = response.DealBrokerName;
        this.brokerOrganisationName = response.DealBrokerOrganisationName;
        this.messageContent =
          "The borrower has been notified you have withdrawn terms for their application.";
      })
      .catch((error) => {
        this.messageContent = "Sorry, something went wrong. Please try again.";
        this.isWithdrawClicked = false;
      })
      .finally(() => {
        this.goToUserDashboard = true;
        this.currentCaseLender.IsBrokerReviewComplete ||
          this.currentDeal.BrokerOrganisationId == null ||
          !this.isLender
          ? (this.showInformationMessage = true)
          : this.showBrokerFeedBack();
      });
  }

  ///**Process the Submit button being clicked */
  submitClicked() {
    this.isConfirmClicked = true;
    this.showTsAndCsModal = true;
  }

  ///**Heads of Terms Submit button clicked event */
  submitHoTClicked() {
    // Close the Heads of Terms' Terms and Conditions modal
    this.showTsAndCsModal = false;
    this.sendingMessage = true;

    var request = {
      dealLender: this.currentCaseLender,
      dealLenderStatus: this.currentCaseLender.Status,
    } as BridgingSubmitOfferRequest;

    // Submit the Heads of Terms
    this.dealLenderService
      .submitOffer(request)
      .then((response) => {
        this.currentCaseLender.Status = response.dealLenderStatus;
        this.brokerName = response.DealBrokerName;
        this.brokerOrganisationName = response.DealBrokerOrganisationName;
        this.messageContent =
          "Thank you for submitting a Decision in Principle.";
        this.goToUserDashboard = true;
      })
      .catch((error) => {
        this.messageContent =
          "Sorry, something went wrong while submitting your Decision in Principle. Please try again.";
        this.isConfirmClicked = false;
      })
      .finally(() => {
        this.currentCaseLender.IsBrokerReviewComplete ||
          this.currentDeal.BrokerOrganisationId == null ||
          !this.isLender
          ? (this.showInformationMessage = true)
          : this.showBrokerFeedBack();
        this.sendingMessage = false;
      });
  }

  /**
   * Process the Send Feedback button being clicked
   * @param message
   */
  sendChatMessage() {
    this.dataLoading = true;
    let DealChatMessage = {
      Message: this.newChatMessage,
      DealId: this.$routeParams.DealId,
      DealLenderId: this.$routeParams.DealLenderId,
      SenderRole: this.getSenderMessageRole(),
      RecipientRoles: this.isLender ? 10 : 4,
    } as DealLenderMessageDTO;

    this.dealLenderMessageService
      .addUpdate(DealChatMessage)
      .then((dealChatMessageResponse) => {
        this.currentCaseLender.DealLenderMessage.push(dealChatMessageResponse);
        this.newChatMessage = null;
      })
      .catch((error) => {
        this.messageContent =
          "Sorry, something went wrong while sending feedback. Please try again.";
      }).finally(() =>{
        this.dataLoading = false;
      });
  }

  showFullChatMessage(index) {
    this.showFullMessageId = index;
  }

  updateCaseLenderMessageReadDatetime() {
    this.dealLenderMessageService
      .updateDealLenderMessageReadDatetime(this.$routeParams.DealLenderId)
      .then((caseLenderMessageResponse) => { });
  }

  onFileSelect(files: FileAttachmentDTO[], module: ModuleEnum) {
    if (files.length > 0) {
      this.dealFileAttachmentService
        .UploadFileListInSync(
          files,
          this.uploadingFiles,
          this.$routeParams.DealId,
          this.fileUpload,
          module,
          this.$routeParams.DealLenderId,
        )
        .then((result) => {
          this.openModal = false;
        });
    }
  }

  downloadFile(file: DealFileAttachmentDTO) {
    this.dataLoading = true;
    this.dealFileAttachmentService
      .getFileUri(file.FileLocation)
      .then((uri) => {
        this.$window.open(uri);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  deleteFile(file: DealFileAttachmentDTO) {
    this.fileUpload.splice(this.fileUpload.indexOf(file), 1);
    this.dealFileAttachmentService.markasdeleted(file.Id).then((result) => { }); //remove on the server
  }

  showActionButtons() {
    if (this.currentCaseLender && (this.currentCaseLender.Status == CaseLenderStateEnum.Received || this.currentCaseLender.Status == CaseLenderStateEnum.Rejected || this.currentCaseLender.Status == CaseLenderStateEnum.Offered || this.currentCaseLender.Status == CaseLenderStateEnum.Cancelled || this.currentCaseLender.Status == CaseLenderStateEnum.Withdrawn)) {
      return true;
    } else {
      return false;
    }
  }

  addClass(star: number, id: string) {
    this.feedBackService.addClass(star, id);
  }

  removeClass(star: number, id: string, rating) {
    this.feedBackService.removeClass(star, id, rating);
  }

  isFeedBackFormDisabled() {
    return this.feedBackService.isFeedBackFormDisabled(
      this.isLender,
      this.feedBackDTO,
    );
  }

  updateRating(star, rating) {
    this.feedBackDTO = this.feedBackService.updateRating(
      star,
      rating,
      this.feedBackDTO,
    );
  }

  submitFeedBack() {
    if (this.isLender) {
      this.feedBackDTO.OrganisationId = this.currentDeal.BrokerOrganisationId;
      this.feedBackDTO.BrokerUserId = this.currentDeal.BrokerUserId;
    } else {
      this.feedBackDTO.LenderId = this.currentCaseLender.LenderId;
    }

    this.feedBackService
      .addFeedBack(this.feedBackDTO, this.currentCaseLender.Id, true)
      .then((response) => {
        if (this.isLender) {
          this.$auth.clearSelectedNavMenuAndFilterStorageData();
          this.$location.path("/userdashboard");
        } else {
          this.$location.path(`/${this.prevPath}/${this.currentDeal.Id}`);
        }
      });
  }

  showBrokerFeedBack() {
    this.feedBackDTO.IsBrokerFeedback = true;
    this.showBrokerFeedBackModal = true;
  }

  onClickDeleteMessageButton(message: DealLenderMessageDTO) {
    this.showdeleteChatMessageModal = true;
    this.messageContent = "Are you sure you want to delete a selected message?";
    this.selectedDealLenderMessageDto = message;
    this.showConfirmDeleteButton = true;
  }

  deleteChatMessage() {
    this.dataLoading = true;
    this.dealLenderMessageService
      .markasdeleted(this.selectedDealLenderMessageDto.Id)
      .then((response) => {
        this.currentCaseLender.DealLenderMessage =
          this.currentCaseLender.DealLenderMessage.filter(
            (x) => x.Id != this.selectedDealLenderMessageDto.Id,
          );
      })
      .catch((error) => {
        this.messageContent =
          "Sorry, something went wrong while deleting message. Please try again.";
      })
      .finally(() => {
        this.showdeleteChatMessageModal = false;
        delete this.selectedDealLenderMessageDto;
        this.showConfirmDeleteButton = false;
        this.dataLoading = false;
      });
  }

  /**Calculate the Total Net Loan */
  calculateTotalNetLoan() {
    var totalNetLoan: number = 0;
    if (
      this.currentCaseLender &&
      this.currentCaseLender.BuildLoan &&
      this.currentCaseLender.LandLoan
    ) {
      totalNetLoan =
        +this.currentCaseLender.BuildLoan + +this.currentCaseLender.LandLoan;
      totalNetLoan = Math.round(totalNetLoan * 100) / 100;
    }
    return totalNetLoan;
  }

  /**Calculate total lender costs */
  totalLenderCosts() {
    var lenderCosts: number = 0;

    if (this.currentCaseLender) {
      lenderCosts =
        +this.currentCaseLender.LenderArrangementFee +
        +this.currentCaseLender.LenderExitFee +
        +this.currentCaseLender.InterestCoverAllocation;
    }
    return lenderCosts;
  }

  /**Calculate Arrangement Amount */
  recalculateArrangementAmount() {
    if (this.currentCaseLender && this.currentCaseLender.TotalGrossLoan) {
      this.currentCaseLender.LenderArrangementFeePercent =
        (this.currentCaseLender.LenderArrangementFee * 100) /
        this.currentCaseLender.TotalGrossLoan;
      this.currentCaseLender.LenderArrangementFeePercent = parseFloat(
        this.currentCaseLender.LenderArrangementFeePercent.toFixed(2),
      );

      this.totalLenderCosts();
    }
  }

  /**Calculate borrower equity
         (Puchase Price - land loan)
         */
  calculateBorrowerEquity(calcShareholderDeposit: boolean = true) {
    var borrowerEquity: number = 0;

    if (
      this.currentDeal &&
      this.currentDeal.PurchasePrice &&
      this.currentCaseLender &&
      this.currentCaseLender.LandLoan
    ) {
      borrowerEquity =
        this.currentDeal.PurchasePrice - this.currentCaseLender.LandLoan;
    }

    if (calcShareholderDeposit) {
      this.calculateMinShareholderDeposit();
    }
    return Number(borrowerEquity);
  }

  /**Calculate true cost */
  calculateTrueCost(): number {
    if (this.currentCaseLender) {
      var trueCost: number =
        +this.currentCaseLender.InterestRate / 12 +
        (+this.currentCaseLender.LenderArrangementFeePercent +
          +this.currentCaseLender.LenderExitFeePercent) /
        +this.currentCaseLender.LoanTerm;
      return trueCost;
    }
  }

  /**Re-calculate arrangement percent */
  recalculateArrangementPercent() {
    if (this.currentCaseLender && this.currentCaseLender.TotalGrossLoan) {
      this.currentCaseLender.LenderArrangementFee =
        (this.currentCaseLender.TotalGrossLoan *
          this.currentCaseLender.LenderArrangementFeePercent) /
        100;
      this.currentCaseLender.LenderArrangementFee =
        Math.round(this.currentCaseLender.LenderArrangementFee * 100) / 100;
      this.totalLenderCosts();
    }
  }

  /**Recalculate Exit Amount */
  recalculateExitAmount() {
    const element = event.currentTarget as HTMLInputElement;
    const value = parseFloat(element.value);
    if (this.currentCaseLender && this.currentCaseLender.TotalGrossLoan) {
      this.currentCaseLender.LenderExitFee = value;
      this.currentCaseLender.LenderExitFeePercent =
        (this.currentCaseLender.LenderExitFee * 100) /
        this.currentCaseLender.TotalGrossLoan;
      this.currentCaseLender.LenderExitFeePercent =
        Math.round(this.currentCaseLender.LenderExitFeePercent * 100) / 100;
      this.totalLenderCosts();
    }
  }

  /**Recalculate Exit Percent */
  recalculateExitPercent() {
    if (
      this.currentCaseLender &&
      this.currentCaseLender.TotalGrossLoan &&
      this.currentCaseLender.LenderExitFeePercent
    ) {
      this.currentCaseLender.LenderExitFee =
        (this.currentCaseLender.TotalGrossLoan *
          this.currentCaseLender.LenderExitFeePercent) /
        100;
      this.currentCaseLender.LenderExitFee =
        Math.round(this.currentCaseLender.LenderExitFee * 100) / 100;
      this.totalLenderCosts();
    }
  }

  calculatemonetaryLAFee() {
    if (
      this.currentCaseLender.LenderArrangementFeePercent != null &&
      this.currentCaseLender.LenderArrangementFeePercent > 0 &&
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      this.currentCaseLender.LenderArrangementFee =
        Math.round(
          this.currentCaseLender.TotalGrossLoan *
          this.currentCaseLender.LenderArrangementFeePercent *
          100,
        ) / 100;
    } else {
      this.currentCaseLender.LenderArrangementFee = 0;
    }
  }

  calculatemonetaryLAPercent() {
    if (
      this.currentCaseLender.LenderArrangementFee != null &&
      this.currentCaseLender.LenderArrangementFee > 0 &&
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      this.currentCaseLender.LenderArrangementFeePercent =
        this.currentCaseLender.LenderArrangementFee /
        this.currentCaseLender.TotalGrossLoan;

      this.currentCaseLender.LenderArrangementFeePercent = parseFloat(
        this.currentCaseLender.LenderArrangementFeePercent.toFixed(4),
      );
    } else {
      this.currentCaseLender.LenderArrangementFeePercent = 0;
    }
  }

  calculatemonetaryLEFee() {
    if (
      this.currentCaseLender.LenderExitFeePercent != null &&
      this.currentCaseLender.LenderExitFeePercent > 0 &&
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      this.currentCaseLender.LenderExitFee =
        Math.round(
          this.currentCaseLender.TotalGrossLoan *
          this.currentCaseLender.LenderExitFeePercent *
          100,
        ) / 100;
    } else {
      this.currentCaseLender.LenderExitFee = 0;
    }
  }

  calculatemonetaryLEPercent() {
    if (
      this.currentCaseLender.LenderExitFee != null &&
      this.currentCaseLender.LenderExitFee > 0 &&
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      this.currentCaseLender.LenderExitFeePercent =
        this.currentCaseLender.LenderExitFee /
        this.currentCaseLender.TotalGrossLoan;
      this.currentCaseLender.LenderExitFeePercent = parseFloat(
        this.currentCaseLender.LenderExitFeePercent.toFixed(4),
      );
    } else {
      this.currentCaseLender.LenderExitFeePercent = 0;
    }
  }

  CalcTotalBuild(): number {
    var res: number = this.CalcAdditionalBuild();
    if (this.currentDeal && this.currentDeal.BuildCosts) {
      res += Number(this.currentDeal.BuildCosts);
    }

    res += this.calcContingencyCost();
    return res;
  }

  CalcAdditionalBuild(): number {
    var res: number = 0;
    if (this.currentDeal.HasProfessionalCostsBreakdown) {
      if (this.currentDeal.PlanningCosts) {
        res += Number(this.currentDeal.PlanningCosts);
      }
      if (this.currentDeal.ProjectManagementCosts) {
        res += Number(this.currentDeal.ProjectManagementCosts);
      }
      if (this.currentDeal.ClientQSCosts) {
        res += Number(this.currentDeal.ClientQSCosts);
      }
      if (this.currentDeal.S106CILCosts) {
        res += Number(this.currentDeal.S106CILCosts);
      }
      if (this.currentDeal.MechanicalEngineeringCosts) {
        res += Number(this.currentDeal.MechanicalEngineeringCosts);
      }
      if (this.currentDeal.StructuralEngineeringCosts) {
        res += Number(this.currentDeal.StructuralEngineeringCosts);
      }
      if (this.currentDeal.PartyWallCosts) {
        res += Number(this.currentDeal.PartyWallCosts);
      }
      if (this.currentDeal.WarrantyCosts) {
        res += Number(this.currentDeal.WarrantyCosts);
      }
      if (this.currentDeal.LandscapingCosts) {
        res += Number(this.currentDeal.LandscapingCosts);
      }
      if (this.currentDeal.OtherProfessionalCosts) {
        res += Number(this.currentDeal.OtherProfessionalCosts);
      }
    } else {
      if (this.currentDeal.AdditionalOngoingCosts) {
        res += Number(this.currentDeal.AdditionalOngoingCosts);
      }
    }
    return res;
  }

  calcContingencyCost(): number {
    var res: number = 0;
    if (this.currentDeal.Contingency && this.currentDeal.BuildCosts) {
      res = this.currentDeal.Contingency * this.currentDeal.BuildCosts;
      return res;
    }
    return res;
  }

  calculateGrossLoanValVal() {
    if (
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan >= 0 &&
      this.currentDeal.GDV != null &&
      this.currentDeal.GDV >= 0
    ) {
      this.grossLoanCalcVal = parseFloat(
        (
          (this.currentCaseLender.TotalGrossLoan / this.currentDeal.GDV) *
          100
        ).toFixed(2),
      );
    }

    if (
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      if (
        this.currentCaseLender.LenderArrangementFeePercent != null &&
        this.currentCaseLender.LenderArrangementFeePercent > 0
      ) {
        this.calculatemonetaryLAFee();
      } else {
        if (
          this.currentCaseLender.LenderArrangementFee != null &&
          this.currentCaseLender.LenderArrangementFee > 0
        ) {
          this.calculatemonetaryLAPercent();
        }
      }

      if (
        this.currentCaseLender.LenderExitFeePercent != null &&
        this.currentCaseLender.LenderExitFeePercent > 0
      ) {
        this.calculatemonetaryLEFee();
      } else {
        if (
          this.currentCaseLender.LenderExitFee != null &&
          this.currentCaseLender.LenderExitFee > 0
        ) {
          this.calculatemonetaryLEPercent();
        }
      }
    } else {
      this.currentCaseLender.LenderArrangementFeePercent = 0;
      this.currentCaseLender.LenderArrangementFee = 0;
      this.currentCaseLender.LenderExitFeePercent = 0;
      this.currentCaseLender.LenderExitFee = 0;
    }
  }

  calculateBuildLoadCalcVal() {
    if (
      this.currentCaseLender.BuildLoan != null &&
      this.currentCaseLender.BuildLoan >= 0
    ) {
      this.builLoanCalcVal = parseFloat(
        (
          (this.currentCaseLender.BuildLoan / this.CalcTotalBuild()) *
          100
        ).toFixed(2),
      );
    }
  }

  calculateLandLoanCalcVal() {
    if (
      this.currentCaseLender.LandLoan != null &&
      this.currentCaseLender.LandLoan >= 0
    ) {
      this.landLoanCalcVal = parseFloat(
        (
          (this.currentCaseLender.LandLoan / this.currentDeal.PurchasePrice) *
          100
        ).toFixed(2),
      );
    }
  }

  getRateTypeText(e: InterestRateTypeEnum) {
    switch (e) {
      case InterestRateTypeEnum.Fixed:
        return "Fixed";
      case InterestRateTypeEnum.Variable:
        return "Variable";
      default:
        break;
    }
  }

  getCurrentUserAndRoles() {
    this.dataLoading = true;
    this.userService
      .getcurentuserrecord()
      .then((result) => {
        this.currentUser = result;

        this.roleService.isClientUser().then((response) => {
          this.isClient = response;
        });

        this.roleService.isAdminUser().then((response) => {
          this.isAdmin = response;
        });

        this.roleService.isBroker().then((response) => {
          this.isBroker = response;
        });

        this.roleService.isLenderUser().then((response) => {
          this.isLender = response;
          if (!this.isLender) {
            this.toggleFeedbackSection = true;
            this.updateCaseLenderMessageReadDatetime();
          }
        });
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  getSenderMessageRole() {
    if (this.isLender) {
      return MessageRoleEnum.Lender;
    } else if (this.isBroker) {
      return MessageRoleEnum.Broker;
    } else if (this.isAdmin) {
      return MessageRoleEnum.Admin;
    } else if (this.isClient) {
      return MessageRoleEnum.Client;
    }
  }

  populateLenderInformation(user: ApplicationUserDTO) {
    if (this.isLender) {
      this.currentCaseLender.ContactName = user.FullName;
      this.currentCaseLender.ContactEmail = user.Email;
      this.currentCaseLender.ContactMobilePhoneNumber = user.PhoneNumber;
    } 
  }

  calculateMinShareholderDeposit() {
    if (this.currentCaseLender) {
      if (this.currentCaseLender.MinShareholderDepositPercent != null && this.currentCaseLender.MinShareholderDepositPercent > 0) {
        this.currentCaseLender.MinShareholderDeposit = Math.round((this.calculateBorrowerEquity(false) * this.currentCaseLender.MinShareholderDepositPercent) * 100) / 100;
      } else {
        this.currentCaseLender.MinShareholderDeposit = 0;
      }
    }
  }

  calculateMinShareholderDepositPercent() {
    if (this.currentCaseLender) {
      if (this.currentCaseLender.MinShareholderDeposit != null && this.currentCaseLender.MinShareholderDeposit > 0) {
        this.currentCaseLender.MinShareholderDepositPercent = parseFloat((this.currentCaseLender.MinShareholderDeposit / this.calculateBorrowerEquity(false)).toFixed(4));

      } else {
        this.currentCaseLender.MinShareholderDepositPercent = 0;
      }
    }
  }
}



    export class SpinnerController {
        public minValue: number;
        public maxValue: number;
        public value: number;
        
        constructor(private $rootScope, private $element) { }

        public next() {
            if (this.value == this.maxValue) {
                return;
            }
            this.value++;
        }

        public prev() {
            if (this.value == this.minValue) {
                return;
            }
            this.value--;
        }
    }

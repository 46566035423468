export const enum CRMEnum
{
    None = 0,
    OMS = 1 << 0,          // 1 (2^0)
    Finova = 1 << 1,       // 2 (2^1)
    BATSoftware = 1 << 2,  // 4 (2^2)
    Acre = 1 << 3,         // 8 (2^3)
    Smart365 = 1 << 4,     // 16 (2^4)
    Salesforce = 1 << 5,   // 32 (2^5)
    Pipedrive = 1 << 6,    // 64 (2^6)
    Zoho = 1 << 7          // 128 (2^7)
}
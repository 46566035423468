import { AddressLookupDTO } from "@js/DTO/AddressLookupDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { ContactDetailsDTO } from "@js/DTO/ContactDetailsDTO.cs.d";
import { DealDTO } from "@js/DTO/Deal/DealDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { CaseService } from "@js/services/CaseService";
import { DealService } from "@js/services/Deal/DealService";
import { RoleService } from "@js/services/RoleService";
import { StreetAddressService } from "@js/services/StreetAddressService";

export class ApplicationDetailsController {
  //Search address
  searchresults: string;
  PostCodeOptions: PostalAddress[] = [];
  searchterm: string;
  searchingForAddress: boolean = false;

  //Case info
  loanCriteria: DevelopmentInputDTO;
  case: CaseDTO;
  deal: DealDTO;
  siteDetails: ContactDetailsDTO;
  accountantDetails: ContactDetailsDTO;
  solicitorDetails: ContactDetailsDTO;

  //Sections
  site: boolean = false;
  accountant: boolean = false;
  solicitors: boolean = false;

  isDeal: boolean = false;

  static $inject = [
    "$rootScope",
    "$routeParams",
    "CaseService",
    "$location",
    "StreetAddressService",
    "$q",
    "RoleService",
    "DealService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private caseService: CaseService,
    private $location: ng.ILocationService,
    private $streetAddressService: StreetAddressService,
    protected $q: ng.IQService,
    private roleService: RoleService,
    private dealService: DealService,
  ) {
    if (this.$routeParams.DealId) {
      this.isDeal = true;
    }

    if (this.isDeal) {
      this.dealService.fetch(this.$routeParams.DealId).then((result) => {
        this.deal = result;
        this.siteDetails = this.parseContactDetails(this.deal.SiteDetails);
        this.accountantDetails = this.parseContactDetails(
          this.deal.AccountantDetails,
        );
        this.solicitorDetails = this.parseContactDetails(
          this.deal.SolicitorDetails,
        );
      });
    } else {
      this.caseService.fetch(this.$routeParams.CaseId).then((result) => {
        this.case = result;
        this.siteDetails = this.parseContactDetails(this.case.SiteDetails);
        this.accountantDetails = this.parseContactDetails(
          this.case.AccountantDetails,
        );
        this.solicitorDetails = this.parseContactDetails(
          this.case.SolicitorDetails,
        );
      });
    }
  }

  goToCaseDashboard(saveChanges: boolean): void {
    if (saveChanges && !this.roleService.IsLender) {
      this.save().then((response) => {
        (this.$rootScope as any).formSaved = true;
        this.dealService.goToCaseDashboard(
          this.deal.Id,
          this.deal.ProductFamily,
        );
      });
    } else {
      this.dealService.goToCaseDashboard(this.deal.Id, this.deal.ProductFamily);
    }
  }

  goToDealForum(saveChanges: boolean): void {
    if (saveChanges && !this.roleService.IsLender) {
      this.save().then((response) => {
        (this.$rootScope as any).formSaved = true;
        this.isDeal
          ? this.$location.path("/dealforum/" + this.deal.Id)
          : this.$location.path("/lending/" + this.case.Id);
      });
    } else {
      this.isDeal
        ? this.$location.path("/dealforum/" + this.deal.Id)
        : this.$location.path("/lending/" + this.case.Id);
    }
  }

  expandOrCollapse(value: boolean) {
    this.site = value;
    this.accountant = value;
    this.solicitors = value;
    return value;
  }

  save(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    if (this.isDeal) {
      this.deal.SiteDetails = JSON.stringify(this.siteDetails);
      this.deal.AccountantDetails = JSON.stringify(this.accountantDetails);
      this.deal.SolicitorDetails = JSON.stringify(this.solicitorDetails);

      this.dealService
        .addUpdatereturnonlyid(this.deal)
        .then((response) => {
          defer.resolve(response as number);
          if (
            this.siteDetails.FirstName != null &&
            this.siteDetails.Email != null &&
            this.siteDetails.Role != null &&
            this.siteDetails.LastName != null &&
            this.solicitorDetails.FirstName != null &&
            this.solicitorDetails.LastName != null &&
            this.solicitorDetails.Email != null &&
            this.accountantDetails.FirstName != null &&
            this.accountantDetails.LastName != null &&
            this.accountantDetails.Email != null
          ) {
            this.dealService.setIsSupplimentInfoFilled(true);
          } else {
            this.dealService.setIsSupplimentInfoFilled(false);
          }
          this.goToDealForum(false);
        })
        .catch((response) => {
          defer.reject(response);
        });
    } else {
      this.case.SiteDetails = JSON.stringify(this.siteDetails);
      this.case.AccountantDetails = JSON.stringify(this.accountantDetails);
      this.case.SolicitorDetails = JSON.stringify(this.solicitorDetails);

      this.caseService
        .addUpdatereturnonlyid(this.case)
        .then((response) => {
          defer.resolve(response as number);
          if (
            this.siteDetails.FirstName != null &&
            this.siteDetails.Email != null &&
            this.siteDetails.Role != null &&
            this.siteDetails.LastName != null &&
            this.solicitorDetails.FirstName != null &&
            this.solicitorDetails.LastName != null &&
            this.solicitorDetails.Email != null &&
            this.accountantDetails.FirstName != null &&
            this.accountantDetails.LastName != null &&
            this.accountantDetails.Email != null
          ) {
            this.caseService.setIsSupplimentInfoFilled(true);
          } else {
            this.caseService.setIsSupplimentInfoFilled(false);
          }
          this.goToDealForum(false);
        })
        .catch((response) => {
          defer.reject(response);
        });
    }

    return defer.promise;
  }

  getAddressList(searchTerm: string) {
    let foundMatch = false;

    if (
      this.PostCodeOptions &&
      this.PostCodeOptions &&
      this.PostCodeOptions.length > 0
    ) {
      let addressLookup = this.PostCodeOptions.find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.$streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            this.siteDetails.AddressLine1 = address.AddressLine1;
            this.siteDetails.AddressLine2 = address.AddressLine2;
            this.siteDetails.AddressLine3 = address.AddressLine3;
            this.siteDetails.AddressLine4 = address.AddressLine4;
            this.siteDetails.PostCode = address.PostCode;

            this.searchterm = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;

        this.siteDetails.AddressLine1 = addressLookup.AddressLine1;
        this.siteDetails.AddressLine2 = addressLookup.AddressLine2;
        this.siteDetails.AddressLine3 = addressLookup.AddressLine3;
        this.siteDetails.AddressLine4 = addressLookup.AddressLine4;
        this.siteDetails.PostCode = addressLookup.PostCode;
        this.searchterm = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.PostCodeOptions = [];

      this.$streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.PostCodeOptions = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  parseContactDetails(contactHistoryJson: string): ContactDetailsDTO {
    return JSON.parse(contactHistoryJson);
  }
}

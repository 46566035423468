import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseLenderDTO } from "@js/DTO/CaseLenderDTO.cs.d";
import { CaseLenderMessageDTO } from "@js/DTO/CaseLenderMessageDTO.cs.d";
import { DealLenderMessageDTO } from "@js/DTO/Deal/DealLenderMessageDTO.cs.d";
import { FeedBackDTO } from "@js/DTO/FeedBackDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { InterestRateTypeEnum } from "@js/models/enum/InterestRateTypeEnum.cs.d";
import { MessageRoleEnum } from "@js/models/enum/MessageRoleEnum.cs.d";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseLenderMessageService } from "@js/services/CaseLenderMessageService";
import { CaseLenderService } from "@js/services/CaseLenderService";
import { CaseService } from "@js/services/CaseService";
import { FeedBackService } from "@js/services/FeedBackService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { LenderService } from "@js/services/LenderService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class HeadsOfTermsController {
  static $inject = [
    "$routeParams",
    "$rootScope",
    "$location",
    "LenderService",
    "CaseService",
    "CaseLenderService",
    "RoleService",
    "CaseLenderMessageService",
    "FileAttachmentService",
    "$window",
    "SelectListService",
    "FeedBackService",
    "UserService",
    "AuthService",
    "ProductService",
  ];

  isDevelopmentFinance: boolean = true;
  dataLoading: boolean = false; // Controls showing the data loading "spinner"
  caseLenders: CaseLenderDTO[];
  showInformationMessage: boolean = false;
  messageContent: string;
  feedbackMessageToLender: string;

  showDirection: boolean = false;

  toggleFeedbackSection: boolean = false;
  toggleHoTDetailSection: boolean = false;
  toggleRejectSection: boolean = false;
  toggleWithdrawSection: boolean = false;
  isLender: boolean = false;
  isBroker: boolean = false;
  isAdmin: boolean = false;
  isClient: boolean = false;

  // show different sections to Lender based on which button clicked on Case Dashboard
  showFeedbackSectionLender: boolean = false;
  showHoTDetailSectionLender: boolean = false;
  showRejectSectionLender: boolean = false;
  showWithdrawSectionLender: boolean = false;

  showTsAndCsModal: boolean = false;
  showTsAndCsForm: boolean = false;
  sendingMessage: boolean = false;
  goToUserDashboard: boolean = false;

  currentCase: CaseDTO;
  currentCaseLender: CaseLenderDTO;
  grossLoanCalcVal: number = 0;
  builLoanCalcVal: number = 0;
  landLoanCalcVal: number = 0;
  monetaryLAFee: number = 0;
  monetaryLEFee: number = 0;
  lenderTask: string = "";
  netBuildLoanCalcVal: number = 0;

  isRejectClicked: boolean = false;
  isWithdrawClicked: boolean = false;
  isConfirmClicked: boolean = false;
  isSubmitClicked: boolean = false;

  //Lender Message
  newChatMessage: string = "";
  showFullMessageId: number;

  //files
  fileUpload: FileAttachmentDTO[];
  uploadingFiles: FileUploadProgressDTO[];
  openModal: boolean = false;
  module = ModuleEnum.DIP;

  showFullLenderMessage = false;
  showFullBorrowerMessage = false;
  InterestRateTypeOptions = [];

  showLenderFeedBackModal: boolean = false;
  showBrokerFeedBackModal: boolean = false;
  feedBackDTO: FeedBackDTO;
  prevPath: string;

  brokerName: string;
  brokerOrganisationName: string;

  //Deleteing a chat message
  selectedDealLenderMessageDto: DealLenderMessageDTO;
  showdeleteChatMessageModal: boolean = false;
  showConfirmDeleteButton: boolean = false;

  currentUser: ApplicationUserDTO = {} as ApplicationUserDTO;
  productTypeText: string = null;
  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private lenderService: LenderService,
    private caseService: CaseService,
    private caseLenderService: CaseLenderService,
    private roleService: RoleService,
    private caseLenderMessageService: CaseLenderMessageService,
    private fileAttachmentService: FileAttachmentService,
    private $window: ng.IWindowService,
    private selectListService: SelectListService,
    private feedBackService: FeedBackService,
    private userService: UserService,
    private $auth: AuthService,
    private productService: ProductService,
  ) {
    this.feedBackDTO = {} as FeedBackDTO;
    if (
      this.$routeParams.openModule &&
      this.$routeParams.openModule == "withdraw"
    ) {
      this.toggleWithdrawSection = true;
    } else if (
      this.$routeParams.openModule &&
      this.$routeParams.openModule == "reject"
    ) {
      this.toggleRejectSection = true;
    } else if (this.$routeParams.openModule) {
      this.toggleFeedbackSection = true;
    }

    if (this.$routeParams.CaseId && this.$routeParams.CaseLenderId) {
      this.dataLoading = true;

      this.caseService
        .fetch(this.$routeParams.CaseId)
        .then((response) => {
          this.currentCase = response;

          if (this.currentCase) {
            this.caseLenderService
              .fetchByCaseId(
                this.$routeParams.CaseId,
                this.roleService.getIsLenderVisible(),
                this.$routeParams.CaseLenderId,
              )
              .then((response) => {
                this.caseLenders = response;
                if (this.$routeParams.CaseLenderId) {
                  this.currentCaseLender = this.caseLenders.find(
                    (caseLender) => {
                      return caseLender.Id == this.$routeParams.CaseLenderId;
                    },
                  );
                  this.fileUpload = this.currentCase.Attachments.filter((f) => {
                    return f.CaseLenderId == this.$routeParams.CaseLenderId;
                  });
                  //this.recalculateArrangementPercent(); //  this actually re-calculates the arrangement fee not the % - function name is wrong
                  // this.recalculateExitPercent(); //  this actually re-calculates the exit fee not the % - function name is wrong
                  this.calculateGrossLoanValVal();
                  this.calculateBuildLoadCalcVal();
                  this.calculateLandLoanCalcVal();
                  // this.calculatemonetaryLAFee();
                  //this.calculatemonetaryLEFee();
                }
              });
            this.caseService.updateCaseState(
              this.currentCase.Id,
              this.currentCase.CaseStatus,
            );
            this.lenderTask = sessionStorage.getItem("lenderTask");
            if (this.lenderTask != null && this.lenderTask.length > 0) {
              switch (this.lenderTask) {
                case "reject":
                  this.showRejectSectionLender = true;
                  break;
                case "withdraw":
                  this.showWithdrawSectionLender = true;
                  break;
                case "feedback":
                  this.showFeedbackSectionLender = true;
                  this.updateCaseLenderMessageReadDatetime();
                  break;
                case "terms":
                  this.showHoTDetailSectionLender = true;
                  break;
                default:
                  break;
              }
            }

            this.productTypeText = this.productService.getProductTypeFullName(
              ProductTypeEnum.Development,
            );
          } else {
            if ((this.$rootScope as any).selectedUser) {
              this.$location.path("/dashboard");
            }
          }
        })
        .finally(() => {
          this.dataLoading = false;
          this.getCurrentUserAndRoles();
        });
    }

    this.InterestRateTypeOptions = this.selectListService.GetInterestRateType();
  }

  /**Process the Send Feedback button being clicked */
  /* sendFeedbackClicked() {
            this.caseLenderService.sendFeedbackToBorrower(this.currentCaseLender, this.currentCase.DevelopmentInput.OwnerUser).then((response) => {
                //this.caseLenders = this.caseLenders.filter(lender => lender.Id != this.currentCaseLender.Id);
                //this.caseLenders.push(response);
                this.messageContent = 'Your feedback has been sent to the borrower.';
                this.goToUserDashboard = true;
            }).catch((error) => {
                this.messageContent = 'Sorry, something went wrong while sending feedback to the borrower. Please try again.';
            }).finally(() => {
                this.showInformationMessage = true;
            })
        }*/

  /**Calculate the Total Net Loan */
  calculateTotalNetLoan() {
    var totalNetLoan: number = 0;
    if (
      this.currentCaseLender &&
      this.currentCaseLender.BuildLoan &&
      this.currentCaseLender.LandLoan
    ) {
      totalNetLoan =
        +this.currentCaseLender.BuildLoan + +this.currentCaseLender.LandLoan;
      totalNetLoan = Math.round(totalNetLoan * 100) / 100;
    }
    return totalNetLoan;
  }

  checkingIfUserIsLender() {
    this.roleService.isLenderUser().then((response) => {
      this.isLender = response;
    });
  }

  checkingIfUerIsBroker() {
    this.dataLoading = true;
    this.roleService
      .isBroker()
      .then((response) => {
        this.isBroker = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkingIfUserIsAdmin() {
    this.dataLoading = true;
    this.roleService
      .isAdminUser()
      .then((response) => {
        this.isAdmin = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkingIfUserIsClient() {
    this.dataLoading = true;
    this.roleService
      .isClientUser()
      .then((response) => {
        this.isClient = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  /**Calculate total lender costs */
  totalLenderCosts() {
    var lenderCosts: number = 0;

    if (this.currentCaseLender) {
      lenderCosts =
        +this.currentCaseLender.LenderArrangementFee +
        +this.currentCaseLender.LenderExitFee +
        +this.currentCaseLender.InterestCoverAllocation;
    }
    return lenderCosts;
  }

  /**Calculate Arrangement Amount */
  recalculateArrangementAmount() {
    if (this.currentCaseLender && this.currentCaseLender.TotalGrossLoan) {
      //const element = event.currentTarget as HTMLInputElement
      //const value = parseFloat(element.value)

      //this.currentCaseLender.LenderArrangementFee = value;

      this.currentCaseLender.LenderArrangementFeePercent =
        (this.currentCaseLender.LenderArrangementFee * 100) /
        this.currentCaseLender.TotalGrossLoan;
      this.currentCaseLender.LenderArrangementFeePercent = parseFloat(
        this.currentCaseLender.LenderArrangementFeePercent.toFixed(2),
      );

      this.totalLenderCosts();
    }
  }

  /**Calculate borrower equity
         (Puchase Price - land loan)
         */
  calculateBorrowerEquity() {
    var borrowerEquity: number = 0;

    if (
      this.currentCase &&
      this.currentCase.DevelopmentInput.CI_Dev_OrigPP &&
      this.currentCaseLender &&
      this.currentCaseLender.LandLoan
    ) {
      borrowerEquity =
        this.currentCase.DevelopmentInput.CI_Dev_OrigPP -
        this.currentCaseLender.LandLoan;
    }
    return Number(borrowerEquity);
  }

  /**Calculate true cost */
  calculateTrueCost(): number {
    if (this.currentCaseLender) {
      var trueCost: number =
        +this.currentCaseLender.InterestRate / 12 +
        (+this.currentCaseLender.LenderArrangementFeePercent +
          +this.currentCaseLender.LenderExitFeePercent) /
          +this.currentCase.DevelopmentInput.CI_Dev_LoanTermReq;
      return trueCost;
    }
  }

  /**Re-calculate arrangement percent */
  recalculateArrangementPercent() {
    //const element = event.currentTarget as HTMLInputElement
    //const value = parseFloat(element.value)
    if (this.currentCaseLender && this.currentCaseLender.TotalGrossLoan) {
      //this.currentCaseLender.LenderArrangementFeePercent = value;
      this.currentCaseLender.LenderArrangementFee =
        (this.currentCaseLender.TotalGrossLoan *
          this.currentCaseLender.LenderArrangementFeePercent) /
        100;
      this.currentCaseLender.LenderArrangementFee =
        Math.round(this.currentCaseLender.LenderArrangementFee * 100) / 100;
      this.totalLenderCosts();
    }
  }

  /**Recalculate Exit Amount */
  recalculateExitAmount() {
    const element = event.currentTarget as HTMLInputElement;
    const value = parseFloat(element.value);
    if (this.currentCaseLender && this.currentCaseLender.TotalGrossLoan) {
      this.currentCaseLender.LenderExitFee = value;

      this.currentCaseLender.LenderExitFeePercent =
        (this.currentCaseLender.LenderExitFee * 100) /
        this.currentCaseLender.TotalGrossLoan;
      /*this.currentCaseLender.LenderExitFeePercent = parseInt(this.currentCaseLender.LenderExitFeePercent.toFixed(2));*/
      this.currentCaseLender.LenderExitFeePercent =
        Math.round(this.currentCaseLender.LenderExitFeePercent * 100) / 100;
      this.totalLenderCosts();
    }
  }

  /**Recalculate Exit Percent */
  recalculateExitPercent() {
    //const element = event.currentTarget as HTMLInputElement
    //const value = parseFloat(element.value)
    if (
      this.currentCaseLender &&
      this.currentCaseLender.TotalGrossLoan &&
      this.currentCaseLender.LenderExitFeePercent
    ) {
      //this.currentCaseLender.LenderExitFeePercent = value;
      this.currentCaseLender.LenderExitFee =
        (this.currentCaseLender.TotalGrossLoan *
          this.currentCaseLender.LenderExitFeePercent) /
        100;
      this.currentCaseLender.LenderExitFee =
        Math.round(this.currentCaseLender.LenderExitFee * 100) / 100;
      this.totalLenderCosts();
    }
  }

  /** Redirects to the case dashboard */
  goToCaseDashboard() {
    this.showFeedbackSectionLender = false;
    this.showHoTDetailSectionLender = false;
    this.showRejectSectionLender = false;
    this.showWithdrawSectionLender = false;
    if (
      this.isBroker &&
      !this.currentCaseLender.IsLenderReviewComplete &&
      (this.currentCaseLender.CaseLenderState == CaseLenderStateEnum.Offered ||
        this.currentCaseLender.CaseLenderState ==
          CaseLenderStateEnum.Rejected ||
        this.currentCaseLender.CaseLenderState == CaseLenderStateEnum.Withdrawn)
    ) {
      this.prevPath = "casedashboard";
      this.feedBackDTO.IsLenderFeedback = true;
      this.showLenderFeedBackModal = true;
    } else {
      this.$location.path("/casedashboard/" + this.currentCase.Id);
    }
  }

  /** Close modal after send feedback */
  closeModal() {
    this.showDirection = false;
    this.showTsAndCsModal = false;
    this.showInformationMessage = false;
    this.isConfirmClicked = false;
    this.showdeleteChatMessageModal = false;
    if (this.goToUserDashboard) {
      this.$auth.clearSelectedNavMenuAndFilterStorageData();
      this.$location.path("/userdashboard");
      this.goToUserDashboard = false;
      this.showFeedbackSectionLender = false;
      this.showHoTDetailSectionLender = false;
      this.showRejectSectionLender = false;
      this.showWithdrawSectionLender = false;
    }

    if (this.prevPath) {
      if (this.isLender) {
        this.$auth.clearSelectedNavMenuAndFilterStorageData();
        this.$location.path("/userdashboard");
      } else {
        this.$location.path(`/${this.prevPath}/${this.currentCase.Id}`);
      }
    }
  }

  goToLending() {
    if (
      this.isBroker &&
      !this.currentCaseLender.IsLenderReviewComplete &&
      (this.currentCaseLender.CaseLenderState == CaseLenderStateEnum.Offered ||
        this.currentCaseLender.CaseLenderState ==
          CaseLenderStateEnum.Rejected ||
        this.currentCaseLender.CaseLenderState == CaseLenderStateEnum.Withdrawn)
    ) {
      this.prevPath = "lending";
      this.feedBackDTO.IsLenderFeedback = true;
      this.showLenderFeedBackModal = true;
    } else {
      this.$location.path("/lending/" + this.currentCase.Id);
    }
  }

  saveUpdatedCaseLender(): void {
    //TODO JLH debug this process, seems a bit odd
    this.caseLenderService
      .addUpdate(this.currentCaseLender)
      .then((response) => {
        //TODO JLH debug this process, seems a bit odd
        this.caseLenders = this.caseLenders.filter(
          (lender) => lender.Id != this.currentCaseLender.Id,
        );
        this.caseLenders.push(response);

        this.messageContent = "Your changes have been saved.";
        this.showInformationMessage = true;
      });
  }

  /** Process the Lender button being clicked*/
  lenderClicked() {
    (this.$rootScope as any).isLender = false; // TODO JLH why is this being set to false?  What if the user is a lender?
    this.$location.path("/lending/" + this.currentCase.Id);
  }

  /** Process the Reject button being clicked*/
  rejectClicked(): void {
    this.isRejectClicked = true;
    this.caseLenderService
      .rejectCase(this.currentCaseLender)
      .then((response) => {
        this.currentCaseLender.CaseLenderState = response.CaseLenderState;
        this.brokerName = response.DealBrokerName;
        this.brokerOrganisationName = response.DealBrokerOrganisationName;
        this.messageContent =
          "The borrower has been notified you have rejected their application.";
        //TODO JLH debug this process, seems a bit odd
        this.caseLenders = this.caseLenders.filter(
          (lender) => lender.Id != this.currentCaseLender.Id,
        );
        this.caseLenders.push(this.currentCaseLender);
      })
      .catch((error) => {
        this.messageContent = "Sorry, something went wrong. Please try again.";
        this.isRejectClicked = false;
      })
      .finally(() => {
        this.goToUserDashboard = true;
        this.currentCaseLender.IsBrokerReviewComplete ||
        this.currentCase.BrokerOrganisationId == null ||
        !this.isLender
          ? (this.showInformationMessage = true)
          : this.showBrokerFeedBack();
      });
  }

  /**Process the Withdraw button being clicked */
  withdrawClicked(): void {
    this.isWithdrawClicked = true;
    this.caseLenderService
      .withdrawHoT(this.currentCaseLender)
      .then((response) => {
        //TODO JLH what is being returned here?  the whole case?
        this.currentCaseLender.CaseLenderState = response.CaseLenderState;
        this.brokerName = response.DealBrokerName;
        this.brokerOrganisationName = response.DealBrokerOrganisationName;
        this.messageContent =
          "The borrower has been notified you have withdrawn terms for their application.";
        //TODO JLH debug this process, seems a bit odd
        this.caseLenders = this.caseLenders.filter(
          (lender) => lender.Id != this.currentCaseLender.Id,
        );
        this.caseLenders.push(this.currentCaseLender);
      })
      .catch((error) => {
        this.messageContent = "Sorry, something went wrong. Please try again.";
        this.isWithdrawClicked = false;
      })
      .finally(() => {
        this.goToUserDashboard = true;
        this.currentCaseLender.IsBrokerReviewComplete ||
        this.currentCase.BrokerOrganisationId == null ||
        !this.isLender
          ? (this.showInformationMessage = true)
          : this.showBrokerFeedBack();
      });
  }

  /**Process the Submit button being clicked */
  submitClicked() {
    this.isConfirmClicked = true;
    this.showTsAndCsModal = true;
  }

  /**Heads of Terms Submit button clicked event */
  submitHoTClicked() {
    // Close the Heads of Terms' Terms and Conditions modal
    this.showTsAndCsModal = false;
    this.sendingMessage = true;
    // Submit the Heads of Terms
    this.caseLenderService
      .submitHoT(this.currentCaseLender, this.currentCaseLender.CaseLenderState)
      .then((response) => {
        this.currentCaseLender.CaseLenderState = response.CaseLenderState;
        this.brokerName = response.DealBrokerName;
        this.brokerOrganisationName = response.DealBrokerOrganisationName;
        this.caseLenders = this.caseLenders.filter(
          (lender) => lender.Id != this.currentCaseLender.Id,
        );
        this.caseLenders.push(this.currentCaseLender);
        this.messageContent =
          "Thank you for submitting a Decision in Principle.";
        this.goToUserDashboard = true;
      })
      .catch((error) => {
        this.messageContent =
          "Sorry, something went wrong while submitting your Decision in Principle. Please try again.";
        this.isConfirmClicked = false;
      })
      .finally(() => {
        this.sendingMessage = false;
        this.currentCaseLender.IsBrokerReviewComplete ||
        this.currentCase.BrokerOrganisationId == null ||
        !this.isLender
          ? (this.showInformationMessage = true)
          : this.showBrokerFeedBack();
      });
  }

  showBrokerFeedBack() {
    this.feedBackDTO.IsBrokerFeedback = true;
    this.showBrokerFeedBackModal = true;
  }

  /**
   * Process the Send Feedback button being clicked
   * @param message
   */
  /*   sendlenderFeedbackClicked() {
            this.caseLenderService.sendFeedbackToLender(this.currentCaseLender).then((response) => {
                this.messageContent = `Your feedback has been sent to ${this.currentCaseLender.LoanLabel != null ? this.currentCaseLender.LoanLabel:'the lender'}.`;
                this.feedbackMessageToLender = '';
                this.showInformationMessage = true;
            }).catch((error) => {
                this.messageContent = 'Sorry, something went wrong while sending feedback to lender. Please try again.';
            });
        }*/

  /**
   * Process the Apply button being clicked
   * @param currentCaseLender
   */
  applyClicked(selectedCaseLender: CaseLenderDTO) {
    // Show the Terms and Conditions modal
    this.showTsAndCsModal = true;
  }

  /**Process the Submit Terms and Conditions button being clicked */
  submitTsAndCsClicked() {
    this.isSubmitClicked = true;
    this.showTsAndCsModal = false;

    this.$location.path("/payment/" + this.currentCase.Id);

    //TODO JLH need to wait for payment to be complete before actually applying ideally

    this.caseLenderService
      .applyToLender(this.currentCaseLender.CaseId, this.currentCaseLender.Id)
      .then((response) => {
        this.currentCaseLender.CaseLenderState = response.CaseLenderState;
      })
      .finally(() => {
        this.messageContent = `Thank you for submitting an application with ${
          this.currentCaseLender.LoanLabel != null
            ? this.currentCaseLender.LoanLabel
            : "this lender"
        }.`;
        this.showInformationMessage = true;
      });
  }

  calculateGrossLoanValVal() {
    if (
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan >= 0 &&
      this.currentCase.DevelopmentInput.CI_GDVCalculated != null &&
      this.currentCase.DevelopmentInput.CI_GDVCalculated >= 0
    ) {
      this.grossLoanCalcVal = parseFloat(
        (
          (this.currentCaseLender.TotalGrossLoan /
            this.currentCase.DevelopmentInput.CI_GDVCalculated) *
          100
        ).toFixed(2),
      );
    }

    if (
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      if (
        this.currentCaseLender.LenderArrangementFeePercent != null &&
        this.currentCaseLender.LenderArrangementFeePercent > 0
      ) {
        this.calculatemonetaryLAFee();
      } else {
        if (
          this.currentCaseLender.LenderArrangementFee != null &&
          this.currentCaseLender.LenderArrangementFee > 0
        ) {
          this.calculatemonetaryLAPercent();
        }
      }

      if (
        this.currentCaseLender.LenderExitFeePercent != null &&
        this.currentCaseLender.LenderExitFeePercent > 0
      ) {
        this.calculatemonetaryLEFee();
      } else {
        if (
          this.currentCaseLender.LenderExitFee != null &&
          this.currentCaseLender.LenderExitFee > 0
        ) {
          this.calculatemonetaryLEPercent();
        }
      }
    } else {
      this.currentCaseLender.LenderArrangementFeePercent = 0;
      this.currentCaseLender.LenderArrangementFee = 0;
      this.currentCaseLender.LenderExitFeePercent = 0;
      this.currentCaseLender.LenderExitFee = 0;
    }
  }

  calculateBuildLoadCalcVal() {
    if (
      this.currentCaseLender.BuildLoan != null &&
      this.currentCaseLender.BuildLoan >= 0
    ) {
      this.builLoanCalcVal = parseFloat(
        (
          (this.currentCaseLender.BuildLoan / this.CalcTotalBuild()) *
          100
        ).toFixed(2),
      );
    }
  }

  calculateLandLoanCalcVal() {
    if (
      this.currentCaseLender.LandLoan != null &&
      this.currentCaseLender.LandLoan >= 0
    ) {
      this.landLoanCalcVal = parseFloat(
        (
          (this.currentCaseLender.LandLoan /
            this.currentCase.DevelopmentInput.CI_Dev_OrigPP) *
          100
        ).toFixed(2),
      );
    }
  }

  calculatemonetaryLAFee() {
    if (
      this.currentCaseLender.LenderArrangementFeePercent != null &&
      this.currentCaseLender.LenderArrangementFeePercent > 0 &&
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      this.currentCaseLender.LenderArrangementFee =
        Math.round(
          this.currentCaseLender.TotalGrossLoan *
            this.currentCaseLender.LenderArrangementFeePercent *
            100,
        ) / 100;
    } else {
      this.currentCaseLender.LenderArrangementFee = 0;
    }
  }

  calculatemonetaryLAPercent() {
    if (
      this.currentCaseLender.LenderArrangementFee != null &&
      this.currentCaseLender.LenderArrangementFee > 0 &&
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      this.currentCaseLender.LenderArrangementFeePercent =
        this.currentCaseLender.LenderArrangementFee /
        this.currentCaseLender.TotalGrossLoan;

      this.currentCaseLender.LenderArrangementFeePercent = parseFloat(
        this.currentCaseLender.LenderArrangementFeePercent.toFixed(4),
      );
    } else {
      this.currentCaseLender.LenderArrangementFeePercent = 0;
    }
  }

  calculatemonetaryLEFee() {
    if (
      this.currentCaseLender.LenderExitFeePercent != null &&
      this.currentCaseLender.LenderExitFeePercent > 0 &&
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      this.currentCaseLender.LenderExitFee =
        Math.round(
          this.currentCaseLender.TotalGrossLoan *
            this.currentCaseLender.LenderExitFeePercent *
            100,
        ) / 100;
    } else {
      this.currentCaseLender.LenderExitFee = 0;
    }
  }

  calculatemonetaryLEPercent() {
    if (
      this.currentCaseLender.LenderExitFee != null &&
      this.currentCaseLender.LenderExitFee > 0 &&
      this.currentCaseLender.TotalGrossLoan != null &&
      this.currentCaseLender.TotalGrossLoan > 0
    ) {
      this.currentCaseLender.LenderExitFeePercent =
        this.currentCaseLender.LenderExitFee /
        this.currentCaseLender.TotalGrossLoan;
      this.currentCaseLender.LenderExitFeePercent = parseFloat(
        this.currentCaseLender.LenderExitFeePercent.toFixed(4),
      );
    } else {
      this.currentCaseLender.LenderExitFeePercent = 0;
    }
  }

  CalcTotalBuild(): number {
    var res: number = this.CalcAdditionalBuild();
    if (
      this.currentCase &&
      this.currentCase.DevelopmentInput.CI_Dev_BuildCosts
    ) {
      res += Number(this.currentCase.DevelopmentInput.CI_Dev_BuildCosts);
    }

    res += this.calcContingencyCost();
    return res;
  }

  CalcAdditionalBuild(): number {
    var res: number = 0;
    if (this.currentCase.DevelopmentInput.DI_BreakDownBuildCosts) {
      if (this.currentCase.DevelopmentInput.CI_Dev_ProfPlanning) {
        res += Number(this.currentCase.DevelopmentInput.CI_Dev_ProfPlanning);
      }
      if (this.currentCase.DevelopmentInput.CI_Dev_ProfQS) {
        res += Number(this.currentCase.DevelopmentInput.CI_Dev_ProfQS);
      }
      if (this.currentCase.DevelopmentInput.DI_BuildProjectManag) {
        res += Number(this.currentCase.DevelopmentInput.DI_BuildProjectManag);
      }
      if (this.currentCase.DevelopmentInput.CI_Dev_S106CIL) {
        res += Number(this.currentCase.DevelopmentInput.CI_Dev_S106CIL);
      }
      if (this.currentCase.DevelopmentInput.DI_BuildMechEng) {
        res += Number(this.currentCase.DevelopmentInput.DI_BuildMechEng);
      }
      if (this.currentCase.DevelopmentInput.DI_BuildStrucEng) {
        res += Number(this.currentCase.DevelopmentInput.DI_BuildStrucEng);
      }
      if (this.currentCase.DevelopmentInput.DI_BuildPartyWall) {
        res += Number(this.currentCase.DevelopmentInput.DI_BuildPartyWall);
      }
      if (this.currentCase.DevelopmentInput.DI_BuildLandscaping) {
        res += Number(this.currentCase.DevelopmentInput.DI_BuildLandscaping);
      }
      if (this.currentCase.DevelopmentInput.DI_BuildWarranty) {
        res += Number(this.currentCase.DevelopmentInput.DI_BuildWarranty);
      }
      if (this.currentCase.DevelopmentInput.DI_BuildDemolition) {
        res += Number(this.currentCase.DevelopmentInput.DI_BuildDemolition);
      }
      if (this.currentCase.DevelopmentInput.DI_BuildOtherCosts) {
        res += Number(this.currentCase.DevelopmentInput.DI_BuildOtherCosts);
      }
    } else {
      if (this.currentCase.DevelopmentInput.CI_Dev_AdditionalOngoingCosts) {
        res += Number(
          this.currentCase.DevelopmentInput.CI_Dev_AdditionalOngoingCosts,
        );
      }
    }
    return res;
  }

  calcContingencyCost(): number {
    var res: number = 0;
    if (
      this.currentCase.DevelopmentInput.CI_Dev_Contingency &&
      this.currentCase.DevelopmentInput.CI_Dev_BuildCosts
    ) {
      res =
        this.currentCase.DevelopmentInput.CI_Dev_Contingency *
        this.currentCase.DevelopmentInput.CI_Dev_BuildCosts;
      return res;
    }
    return res;
  }

  /**
   * Process the Send Feedback button being clicked
   * @param message
   */
  sendChatMessage() {
    this.dataLoading = true;

    let CaseChatMessage = {
      Message: this.newChatMessage,
      CaseId: this.$routeParams.CaseId,
      CaseLenderId: this.$routeParams.CaseLenderId,
      SenderRole: this.getSenderMessageRole(),
      RecipientRoles: this.isLender ? 10 : 4,
    } as CaseLenderMessageDTO;

    this.caseLenderMessageService
      .addUpdate(CaseChatMessage)
      .then((caseChatMessageResponse) => {
        this.currentCaseLender.CaseLenderMessage.push(caseChatMessageResponse);
        this.newChatMessage = null;
      })
      .catch((error) => {
        this.messageContent =
          "Sorry, something went wrong while sending feedback. Please try again.";
      }).finally(() =>{
        this.dataLoading = false;
      });
  }

  showFullChatMessage(index) {
    this.showFullMessageId = index;
  }

  updateCaseLenderMessageReadDatetime() {
    this.caseLenderMessageService
      .updateCaseLenderMessageReadDatetime(this.$routeParams.CaseLenderId)
      .then((caseLenderMessageResponse) => {})
      .catch((error) => {});
  }

  onFileSelect(files: FileAttachmentDTO[], module: ModuleEnum) {
    if (files.length > 0) {
      this.fileAttachmentService
        .UploadFileLstInSync(
          files,
          this.uploadingFiles,
          this.$routeParams.CaseId,
          this.fileUpload,
          module,
          this.$routeParams.CaseLenderId,
        )
        .then((result) => {
          this.openModal = false;
        });
    }
  }

  downloadFile(file: FileAttachmentDTO) {
    this.dataLoading = true;
    this.fileAttachmentService
      .getFileUri(file.FileLocation)
      .then((uri) => {
        this.$window.open(uri);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  deleteFile(file: FileAttachmentDTO) {
    this.fileUpload.splice(this.fileUpload.indexOf(file), 1);
    this.fileAttachmentService.markasdeleted(file.Id).then((result) => {}); //remove on the server
  }

  getRateTypeText(e: InterestRateTypeEnum) {
    switch (e) {
      case InterestRateTypeEnum.Fixed:
        return "Fixed";
      case InterestRateTypeEnum.Variable:
        return "Variable";
      default:
        break;
    }
  }

  addClass(star: number, id: string) {
    this.feedBackService.addClass(star, id);
  }

  removeClass(star: number, id: string, rating) {
    this.feedBackService.removeClass(star, id, rating);
  }

  isFeedBackFormDisabled() {
    return this.feedBackService.isFeedBackFormDisabled(
      this.isLender,
      this.feedBackDTO,
    );
  }

  updateRating(star, rating) {
    this.feedBackDTO = this.feedBackService.updateRating(
      star,
      rating,
      this.feedBackDTO,
    );
  }

  submitFeedBack() {
    if (this.isLender) {
      this.feedBackDTO.OrganisationId = this.currentCase.BrokerOrganisationId;
      this.feedBackDTO.BrokerUserId = this.currentCase.BrokerUserId;
    } else {
      this.feedBackDTO.LenderId = this.currentCaseLender.LenderId;
    }

    this.feedBackService
      .addFeedBack(this.feedBackDTO, this.currentCaseLender.Id, false)
      .then((response) => {
        if (this.isLender) {
          this.$auth.clearSelectedNavMenuAndFilterStorageData();
          this.$location.path("/userdashboard");
        } else {
          this.$location.path(`/${this.prevPath}/${this.currentCase.Id}`);
        }
      });
  }

  onClickDeleteMessageButton(message: DealLenderMessageDTO) {
    this.showdeleteChatMessageModal = true;
    this.messageContent = "Are you sure you want to delete a selected message?";
    this.selectedDealLenderMessageDto = message;
    this.showConfirmDeleteButton = true;
  }

  deleteChatMessage() {
    this.dataLoading = true;
    this.caseLenderMessageService
      .markasdeleted(this.selectedDealLenderMessageDto.Id)
      .then((response) => {
        this.currentCaseLender.CaseLenderMessage =
          this.currentCaseLender.CaseLenderMessage.filter(
            (x) => x.Id != this.selectedDealLenderMessageDto.Id,
          );
      })
      .catch((error) => {
        this.messageContent =
          "Sorry, something went wrong while deleting message. Please try again.";
      })
      .finally(() => {
        this.showdeleteChatMessageModal = false;
        delete this.selectedDealLenderMessageDto;
        this.showConfirmDeleteButton = false;
        this.dataLoading = false;
      });
  }

  getSenderMessageRole() {
    if (this.isLender) {
      return MessageRoleEnum.Lender;
    } else if (this.isBroker) {
      return MessageRoleEnum.Broker;
    } else if (this.isAdmin) {
      return MessageRoleEnum.Admin;
    } else if (this.isClient) {
      return MessageRoleEnum.Client;
    }
  }

  getCurrentUserAndRoles() {
    this.dataLoading = true;
    this.userService
      .getcurentuserrecord()
      .then((result) => {
        this.currentUser = result;

        this.roleService.isClientUser().then((response) => {
          this.isClient = response;
        });

        this.roleService.isAdminUser().then((response) => {
          this.isAdmin = response;
        });

        this.roleService.isBroker().then((response) => {
          this.isBroker = response;
        });

        this.roleService.isLenderUser().then((response) => {
          this.isLender = response;
          if (!this.isLender) {
            this.toggleFeedbackSection = true;
            this.updateCaseLenderMessageReadDatetime();
          }
        });
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { LenderResultDTO } from "@js/DTO/DevelopmentFinance/LenderResultDTO.cs.d";
import { LenderResultSummaryDTO } from "@js/DTO/DevelopmentFinance/LenderResultSummaryDTO.cs.d";
import { LenderResultSetDTO } from "@js/DTO/LenderResultSet.cs.d";
import { LenderResultSetWithInputsDTO } from "@js/DTO/LenderResultSetWithInputsDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { ApplicantRoleEnum } from "@js/models/enum/ApplicantRoleEnum.cs.d";
import { OrganisationService } from "./OrganisationService";

export class LenderResultService extends BaseService<LenderResultDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
    "OrganisationService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
    private organisationService: OrganisationService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/lenderresult";
    this.$broadcastBusinessNameSingular = "lenderresult";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchMatchingResults(
    criteria: DevelopmentInputDTO,
    includeSenior: boolean,
    includeMez: boolean,
    totalNumberOfEachResults: number,
    sendResult: boolean,
    message: string,
    from: string,
    telephone: string,
    loanLabel: string,
    emailJourneyStarted: boolean = true,
    debug1?: boolean,
    debug2?: boolean,
    newLeadFirstName?: string,
    newLeadEmail?: string,
    orgCode?: string,
    isDevelopmentInputWithNoLogin?: boolean,
    userRole?: string,
    userDetails?: string,
    criteriaChanged: boolean = false,
  ): ng.IPromise<LenderResultSetDTO> {
    let defer = this.$q.defer<LenderResultSetDTO>();

    var newLeadFullName = "";
    var newLeadRole = "";

    if (window.self == window.top) {
      userRole = sessionStorage.getItem("userRole");
      userDetails = sessionStorage.getItem("userDetails");
    }

    // if the user isn't logged in and has gone via the registration journey
    if (
      ((window.self == window.top && !this.$cookies.get("access_token")) ||
        window.self != window.top) &&
      userRole &&
      userDetails
    ) {
      newLeadRole = userRole;

      if (newLeadRole == "borrower") {
        var data = userDetails;
        var userdetails = JSON.parse(data);

        telephone = userdetails.PhoneNumber;
        from = userdetails.Email;
        newLeadFullName = userdetails.FullName;

        sendResult = true;
      }
    }

    if (
      ((window.self == window.top && !this.$cookies.get("access_token")) ||
        window.self != window.top) &&
      userDetails &&
      orgCode != null
    ) {
      var data = userDetails;
      var userdetails = JSON.parse(data);
      if (userRole && userRole == "borrower") {
        newLeadRole = userRole;
      }
      telephone = userdetails.PhoneNumber;
      from = userdetails.Email;
      newLeadFullName = userdetails.FullName;

      sendResult = true;
    }

    this.$http
      .put(
        this.$baseAddress +
          "/listlargestandmatchingset?includeSenior=" +
          includeSenior +
          "&includeMez=" +
          includeMez +
          "&totalNumberOfEachResults=" +
          totalNumberOfEachResults +
          "&sendResult=" +
          sendResult +
          "&message=" +
          message +
          "&from=" +
          from +
          "&telephone=" +
          telephone +
          "&loanLabel=" +
          loanLabel +
          "&emailJourneyStarted=" +
          emailJourneyStarted +
          "&debug1=" +
          debug1 +
          "&debug2=" +
          debug2 +
          "&newLeadFirstName=" +
          newLeadFirstName +
          "&newLeadEmail=" +
          newLeadEmail +
          "&newLeadFullName=" +
          newLeadFullName +
          "&newLeadRole=" +
          newLeadRole +
          "&orgCode=" +
          orgCode +
          "&isDevelopmentInputWithNoLogin=" +
          isDevelopmentInputWithNoLogin +
          "&criteriaChanged=" +
          criteriaChanged,
        JSON.stringify(criteria),
      )
      .then((response) => {
        let results: LenderResultSetDTO = response.data as LenderResultSetDTO;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  //TODO JLH - doesn't seem to be referenced
  introducerSendResultToClient(
    criteria: DevelopmentInputDTO,
    includeSenior: boolean,
    includeMez: boolean,
    totalNumberOfEachResults: number,
    sendResult: boolean,
    message: string,
    from: string,
    telephone: string,
    loanLabel: string,
  ): ng.IPromise<LenderResultSetDTO> {
    let defer = this.$q.defer<LenderResultSetDTO>();
    this.$http
      .put(
        this.$baseAddress +
          "/listlargestandmatchingset?includeSenior=" +
          includeSenior +
          "&includeMez=" +
          includeMez +
          "&totalNumberOfEachResults=" +
          totalNumberOfEachResults +
          "&sendResult=" +
          sendResult +
          "&message=" +
          message +
          "&from=" +
          from +
          "&telephone=" +
          telephone +
          "&loanLabel=" +
          loanLabel,
        JSON.stringify(criteria),
      )
      .then((response) => {
        let results: LenderResultSetDTO = response.data as LenderResultSetDTO;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  // TODO - JLH - no references so can be deleted?
  captureNewUserEmail(
    loanCriteria: DevelopmentInputDTO,
    captureEmail: string,
    captureFirstName: string,
    captureSurname: string,
    applicantDefinedRole: ApplicantRoleEnum,
  ) {
    let defer = this.$q.defer<boolean>();
    this.$http
      .post(
        this.$baseAddress +
          "/captureNewUserEmail?captureEmail=" +
          captureEmail +
          "&captureFirstName=" +
          captureFirstName +
          "&captureSurname=" +
          captureSurname +
          "&applicantDefinedRole=" +
          applicantDefinedRole,
        JSON.stringify(loanCriteria),
      )
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  ////should retrun a userid of the user it stored the data with
  //calcAndStoreResults(criteria: DevelopmentInputDTO): ng.IPromise<string> {
  //    let defer = this.$q.defer<string>();
  //    this.$http.put(this.$baseAddress + "/calcAndStoreResults", JSON.stringify(criteria)).then((response) => {
  //        let results: string = response.data as string;
  //        defer.resolve(results);
  //    }).catch((response) => {
  //        defer.reject(response);
  //    });
  //    return defer.promise;
  //}

  //fetchLargestResults(criteria: DevelopmentInputDTO): ng.IPromise<LenderResultDTO[]> {
  //    let defer = this.$q.defer<LenderResultDTO[]>();
  //    this.$http.put(this.$baseAddress + "/listProductsOfferingLargestLoan", JSON.stringify(criteria)).then((response) => {
  //        let results: LenderResultDTO[] = response.data as LenderResultDTO[];
  //        defer.resolve(results);
  //    }).catch((response) => {
  //        defer.reject(response);
  //    });
  //    return defer.promise;
  //}

  startNewUserSearchEmail(
    uniqueRef: string,
    matchingResults: number,
    largestResults: number,
  ) {
    let defer = this.$q.defer<boolean>();
    this.$http
      .put(
        this.$baseAddress +
          "/startNewUserEmailJourney?uniqueRef=" +
          uniqueRef +
          "&matchingResults=" +
          matchingResults +
          "&largestResults=" +
          largestResults,
        null,
      )
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchByRelatedClient(
    DevelopmentInputId: number,
  ): ng.IPromise<LenderResultDTO[]> {
    let defer = this.$q.defer<LenderResultDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/listbyDevelopmentInput?DevelopmentInputId=" +
          DevelopmentInputId,
      )
      .then((response) => {
        defer.resolve(response.data as LenderResultDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchByUniqueRef(
    UniqueRef: string,
    totalNumberOfEachResults: number,
  ): ng.IPromise<LenderResultSetWithInputsDTO> {
    let defer = this.$q.defer<LenderResultSetWithInputsDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/listbyuniqueref?UniqueRef=" +
          UniqueRef +
          "&totalNumberOfEachResults=" +
          totalNumberOfEachResults,
      )
      .then((response) => {
        defer.resolve(response.data as LenderResultSetWithInputsDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  saveResults(
    user: ApplicationUserDTO,
    proceedWithEnquiry: boolean = false,
    selectedResult: LenderResultDTO,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    defer.resolve(true);

    return defer.promise;
  }

  RequestAssistance(reason: string, searchid: number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/requestassistance?reason=" +
          reason +
          "&searchid=" +
          searchid,
      )
      .then((response) => {
        let results: boolean = response.data as boolean;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendNoteToLender(
    lenderEmail: string,
    emailSubject: string,
    note: string,
  ): ng.IPromise<number> {
    note = note.replace(/\n/g, "<br />");
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        this.$baseAddress +
          "/contactLender?lenderEmail=" +
          lenderEmail +
          "&emailSubject=" +
          emailSubject +
          "&note=" +
          note,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sortProductsByLTGDV(
    results: LenderResultSummaryDTO[],
  ): ng.IPromise<LenderResultSummaryDTO[]> {
    let defer = this.$q.defer<LenderResultSummaryDTO[]>();
    this.$http
      .post(this.$baseAddress + "/sortProductsByLTGDV", results)
      .then((response) => {
        defer.resolve(response.data as LenderResultSummaryDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

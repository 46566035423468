import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { IntroducerDTO } from "@js/DTO/IntroducerDTO.cs.d";
import { IntroducerSimpleDTO } from "@js/DTO/IntroducerSimpleDTO.cs.d";
import { LenderDTO } from "@js/DTO/LenderDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { PageWithTotalCountDTO } from "@js/DTO/PageWithTotalCountDTO.cs.d";
import { RoleDTO } from "@js/DTO/RoleDTO.cs.d";
import { LenderProductTypeEnum } from "@js/models/enum/LenderProductTypeEnum.cs.d";
import { AccountSettingService } from "@js/services/AccountSettingsService";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { IntroducerService } from "@js/services/IntroducerService";
import { IntroducerSimpleService } from "@js/services/IntroducerSimpleService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class UserController {
  users: PageWithTotalCountDTO;
  roles: RoleDTO[];
  selectedUser: ApplicationUserDTO;
  selectedRole: string = "";
  uploadingFiles: FileUploadProgressDTO[];
  imagePath: string[];
  organisations: OrganisationDTO[];
  organisationName: string;

  userForm: ng.IFormController;
  page: number = 1;
  pageid: any;
  userFilter: string = "";
  countperpage: number = 10;
  introduceClientModal: number = null;
  unique: string;
  isBroker: boolean = false;
  isLender: boolean = false;
  isSelectedUserAdmin: boolean = false;

  introducersList: IntroducerSimpleDTO[];

  currentItemRoleSet: RoleDTO[];
  newIntroducer: IntroducerDTO = {} as IntroducerDTO;

  loadingRoles: boolean;
  loadingUsers: boolean;
  error: boolean;
  errorMessage: { user: ApplicationUserDTO; deleteAllWarning: boolean } =
    {} as { user: ApplicationUserDTO; deleteAllWarning: boolean };
  allActiveAppPackagePrices: AppPackagePricingDTO[];
  assignLicense: boolean = true;
  selectedProductList: AppPackagePricingDTO[] = [];
  licenseEndDate: Date;
  message: string;
  licenseStatusText: string;
  testEmailBody: string = "This is a test email.";
  testEmailSub: string = "Test email";
  testEmails: string;
  showTestEmailModal: boolean = false;
  onSendTestEmailClick: boolean = false;

  referrerOptions = [];
  locationOptions = [];

  hasLenderrole: boolean = false;
  lenderUserProductType: {}[] = [];
  lenders: LenderDTO[];
  assignedLenderUsers: ApplicationUserDTO[];
  singleLenderAdminError: string = "";

  noLicenseToAssignForAdminMessage: string;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
    "UserService",
    "RoleService",
    "DevelopmentInputService",
    "IntroducerService",
    "CaseService",
    "FileAttachmentService",
    "OrganisationService",
    "IntroducerSimpleService",
    "PaymentService",
    "SelectListService",
    "AccountSettingService",
    "LenderService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $auth: AuthService,
    private $user: UserService,
    private $role: RoleService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private introducerService: IntroducerService,
    private caseService: CaseService,
    private fileAttachmentService: FileAttachmentService,
    private organisationService: OrganisationService,
    private introducerSimpleService: IntroducerSimpleService,
    private $paymentService: PaymentService,
    private selectListService: SelectListService,
    private $accountSettingService: AccountSettingService,
    private $lenderservice: LenderService,
  ) {
    this.loadRoles();
    this.currentItemRoleSet = [];
    this.setPage(1);
    this.imagePath = [];
    $user.fileSelected = "img/BrickFlow_Logo_RGB_Blue.svg";

    //bring all organisations
    this.organisationService.fetchAll().then((response) => {
      this.organisations = response;
    });

    this.getIntroducerUser();

    this.referrerOptions = this.selectListService.GetReferrerOptions();
    this.locationOptions = this.selectListService.GetLocations();
  }

  clearIntroducer() {
    this.selectedUser.IntroducerId = null;
  }

  userOpportunityById(userId: number) {
    this.organisationService.fetchAll().then((response) => {
      const found = response.find((organisation) => organisation.Id === userId);
      if (found) {
        return (this.organisationName = found.Name);
      } else {
        return (this.organisationName = "None");
      }
    });
  }

  formChanged(): void {
    (this.$rootScope as any).formSaved = false;
  }

  loadRoles(): void {
    this.$role
      .fetchAll()
      .then((response) => {
        this.roles = response.filter((role) => role.Name !== "None");
        this.loadingRoles = false;
      })
      .catch((response) => {
        this.error = true;
        this.loadingRoles = false;
      });
  }
  getgroups(): void {
    this.currentItemRoleSet = [];
    this.roles.forEach((item: RoleDTO) => {
      (item as any).ticked = false;
      this.selectedUser.Roles.forEach((role: string) => {
        if (role == item.Name) {
          this.currentItemRoleSet.push(item); //put them in the output object in case not edited
          (item as any).ticked = true;
        }
      });
      if (this.selectedUser.IsIntroducer) {
        this.getIntroducerDetailsByUserId();
      }
    });
  }

  getIntroducerUser() {
    this.introducerSimpleService.fetchAll().then((result) => {
      this.introducersList = result;
    });
  }

  setgroups(): void {
    this.selectedUser.Roles = [];
    this.currentItemRoleSet.forEach((item: RoleDTO) => {
      this.selectedUser.Roles.push(item.Name);
    });
  }

  isUserBroker() {
    this.isBroker =
      this.selectedUser.Roles.find((role) => role === "Broker") !== undefined;
  }

  isUserAdmin() {
    this.isSelectedUserAdmin =
      this.selectedUser.Roles.find((role) => role === "Admin") !== undefined;
  }

  isUserLender() {
    this.isLender =
      this.selectedUser.Roles.find((role) => role === "Lender") !== undefined;
  }

  setPage(newPage: number): void {
    this.loadingUsers = true;
    this.page = newPage;
    this.$user
      .fetchpage(
        this.userFilter,
        this.page,
        this.countperpage,
        this.selectedRole,
      )
      .then((response) => {
        this.users = response;
        this.loadingUsers = false;
      })
      .catch((response) => {
        this.error = true;
        this.loadingUsers = false;
      });
  }

  prevPage(): void {
    this.setPage(this.page - 1);
  }

  nextPage(): void {
    this.setPage(this.page + 1);
  }

  addNewUser(): void {
    this.hasLenderrole = false;
    this.isSelectedUserAdmin = false;
    this.userForm.$setPristine();
    this.currentItemRoleSet = [];
    this.selectedUser = { ShowAutofill: true } as ApplicationUserDTO;
    this.lenderUserProductType = [];
    this.selectedUser.Roles = ["Client"];
    this.getgroups();
  }

  saveUser(): void {
    var validUser = true;
    this.error = false;
    this.singleLenderAdminError = null;

    if (isNaN(this.selectedUser.IntroducerId)) {
      this.selectedUser.IntroducerId = null;
    }

    this.setgroups();
    if (this.selectedUser.Id == null) {
      this.selectedUser.Id = "";
    }
    if (!this.selectedUser.UserName) {
      this.selectedUser.UserName = this.selectedUser.Email; //set user name to be same as email address for now
    }

    if (
      this.hasLenderrole &&
      !this.selectedUser.IsLenderAdmin &&
      this.selectedUser.LenderId != null
    ) {
      let lenderAdmin = this.assignedLenderUsers.find(
        (l) => l.IsLenderAdmin && l.Id != this.selectedUser.Id,
      );
      if (!lenderAdmin) {
        this.singleLenderAdminError = `Please assign a new Admin User.`;
        this.error = true;
        validUser = false;
      }
    }

    if (validUser) {
      this.$user
        .addUpdate(this.selectedUser)
        .then((response) => {
          if (response) {
            if (!this.selectedUser.Id) {
              this.setPage(this.page);
            }
            // Fetching a user data again to make sure to get a updated data for previous lenderAdmin.
            if (this.selectedUser.IsLenderAdmin) {
              this.setPage(1);
            }

            delete this.selectedUser;
            (this.$rootScope as any).formSaved = true;
          } else {
            this.error = true;
          }
        })
        .catch((response) => {
          this.error = true;
          if (response.data?.ExceptionMessage == "No license to assign.") {
            this.selectedUser.IsOrganisationAdmin = false;
            this.noLicenseToAssignForAdminMessage =
              "There isn't an available license to assign to this user therefore they have not been assigned as the admin for the organisation.";
          }
        });
    }
  }

  deleteUser(): void {
    this.$user
      .markasdeleted(this.selectedUser.UserName)
      .then((response) => {
        if (response) {
          this.users.List.splice(this.users.List.indexOf(this.selectedUser), 1);
          delete this.selectedUser;
        } else {
          this.error = true;
        }
      })
      .catch((response) => {
        this.error = true;
      });
  }

  registerIntroducerByUserId(userId: string, sendEmail: boolean = true): void {
    this.newIntroducer.UserId = userId;
    this.introducerService
      .registerIntroducer(this.newIntroducer, sendEmail)
      .then((success) => {
        this.selectedUser.IsIntroducer = true;
        this.selectedUser.IntroducerCode = success.IntroducerCode;
        this.message = "Selected user has been converted to Introducer";
        this.introduceClientModal = 2;
        this.getIntroducerUser();
      })
      .catch((error) => {
        this.error = true;
      });
  }

  sendLinkToUser() {
    this.introducerService
      .sendLinkToUser(
        this.selectedUser.FirstName,
        this.selectedUser.Email,
        this.selectedUser.Id,
      )
      .then((success) => {
        this.introduceClientModal = 4;
      });
  }

  copyLinkToClipboard() {
    this.introducerService
      .copyLinkToClipboard(this.selectedUser.Id)
      .then((response) => {
        this.unique = response.toString();
        navigator.clipboard.writeText(this.unique);
        this.introduceClientModal = 5;
      });
  }

  getIntroducerDetailsByUserId() {
    this.introducerService
      .getIntroducerDetailsByUserId(this.selectedUser.Id)
      .then((response) => {
        this.selectedUser.IntroducerCode = response.IntroducerCode;
      });
  }

  extrasettings = {
    displayProp: "Name",
    smartButtonMaxItems: 3,
    smartButtonTextConverter: function (itemText: string, originalItem: any) {
      return originalItem.Name;
    },
    scrollable: true,
    selectedToTop: true,
    enableSearch: true,
  };

  checkIntroducerrole(data) {
    if (data.Name == "Introducer" && data.ticked) {
      this.introduceClientModal = 6;
    }

    if (data.Name == "Lender") {
      if (data.ticked == true) {
        this.hasLenderrole = true;
        this.selectedUser.SendLenderInsightsEmail = true;
        this.getAllLenders();
      } else {
        this.hasLenderrole = false;
        this.selectedUser.LenderId = null;
        this.selectedUser.IsLenderAdmin = false;
        this.selectedUser.LenderProductTypes = 0;
        this.selectedUser.SendLenderInsightsEmail = false;
      }
    }

    if (data.Name == "Broker") {
      this.isBroker = !this.isBroker;
    }

    if (data.Name == "Admin") {
      this.isSelectedUserAdmin = !this.isSelectedUserAdmin;
    }
  }

  getActivePackagePricingsForTrialLicense() {
    this.allActiveAppPackagePrices = [];
    this.$paymentService
      .getActivePackagePricingsForTrialLicense(this.selectedUser.UserName)
      .then((response) => {
        this.allActiveAppPackagePrices = response;
      })
      .finally(() => {
        this.assignLicense = !this.assignLicense;
      });
  }

  getLicenseStatusText() {
    this.allActiveAppPackagePrices = [];
    this.$user
      .getLicenseStatusText(this.selectedUser.UserName)
      .then((response) => {
        this.licenseStatusText = response;
      });
  }

  onSelectingUser(user) {
    this.hasLenderrole = false;
    this.selectedUser = user;
    this.singleLenderAdminError = "";
    this.noLicenseToAssignForAdminMessage = "";
    this.hasLenderrole =
      this.selectedUser.Roles.find((role) => role === "Lender") !== undefined;
    if (this.hasLenderrole) {
      this.getAllLenders();
      this.initLenderUserProductType();
      if (this.selectedUser.LenderId) {
        this.fetchAllLenderUsers(this.selectedUser.LenderId);
      }
    }
    this.getgroups();
    this.isUserBroker();
    this.isUserAdmin();
    this.isUserLender();
    this.getLicenseStatusText();
  }

  onChangingPackage(selectedPackage) {
    if (
      this.selectedProductList != null &&
      this.selectedProductList.length > 0
    ) {
      var found = this.selectedProductList.find(function (product) {
        return product.Id == selectedPackage.Id;
      });

      if (found) {
        this.selectedProductList = this.selectedProductList.filter(
          function (e) {
            return e.Id != selectedPackage.Id;
          },
        );
      } else {
        this.selectedProductList.push(selectedPackage);
      }
    } else {
      this.selectedProductList.push(selectedPackage);
    }
  }

  assignLicenseToUser() {
    this.$user
      .assigTrialLicenseToUser(
        this.selectedProductList,
        this.licenseEndDate,
        this.selectedUser.Id,
      )
      .then((response) => {
        if (response) {
          this.message = `Trial license has been assigned to ${this.selectedUser.FullName}`;
          this.introduceClientModal = 2;
          this.allActiveAppPackagePrices = [];
        } else {
          this.message = `Problem while assigning trial license, please try after some time.`;
          this.introduceClientModal = 2;
        }
      });
  }

  goToSettingPage() {
    this.$location.path(`/settings/${this.selectedUser.Id}`);
  }

  sendTestEmail(): void {
    this.$user
      .sendTestEmail(this.testEmails, this.testEmailSub, this.testEmailBody)
      .then((response: boolean) => {
        if (response) {
          this.message = `Test email has been sent to ${this.selectedUser.FullName}`;
          this.showTestEmailModal = false;
          this.introduceClientModal = 2;
        } else {
          this.error = true;
        }
      })
      .catch((response) => {
        this.error = true;
      });
  }

  initLenderUserProductType() {
    if (!this.selectedUser.LenderProductTypes) {
      this.selectedUser.LenderProductTypes = 0;
    } else {
      for (let i = 1; i >= 0; i *= 2) {
        if (this.selectedUser.LenderProductTypes >= i) {
          //number is in enum sum, add it to object
          if (this.selectedUser.LenderProductTypes & i) {
            this.lenderUserProductType[i] = true;
          } else {
            this.lenderUserProductType[i] = false;
          }
        } else {
          return;
        }
      }
    }
  }

  updateLenderUserProductTypes(item: LenderProductTypeEnum) {
    this.selectedUser.LenderProductTypes =
      this.selectedUser.LenderProductTypes > 0
        ? this.selectedUser.LenderProductTypes
        : 0;
    if (item == LenderProductTypeEnum.All) {
      if (this.lenderUserProductType[item] == false) {
        this.selectedUser.LenderProductTypes -= item;
      } else {
        this.lenderUserProductType.forEach((value, index) => {
          this.lenderUserProductType[index] = false;
        });

        this.selectedUser.LenderProductTypes = 0;

        this.lenderUserProductType[item] = true;
        this.selectedUser.LenderProductTypes += item;
      }
    } else {
      //if we are setting item to false and item exists, remove it, else add it
      if (this.lenderUserProductType[item] === true) {
        this.selectedUser.LenderProductTypes += item;
      } else if (this.selectedUser.LenderProductTypes > 0) {
        this.selectedUser.LenderProductTypes -= item;
      }
    }
  }

  getAllLenders() {
    this.$lenderservice.fetchAll().then((response) => {
      this.lenders = response;
    });
  }

  isLenderUserProductTypeEmpty() {
    if (this.lenderUserProductType.length === 0) return true;

    for (let i = 0; i < this.lenderUserProductType.length; i++) {
      let value = this.lenderUserProductType[i];
      if (value !== undefined && value !== null && value !== false) {
        return false;
      }
    }
    return true;
  }

  fetchAllLenderUsers(lenderId) {
    this.$user.getUsersByLenderId(lenderId).then((response) => {
      this.assignedLenderUsers = response;
    });
  }

  onIsLenderAdminChange() {
    if (
      !this.selectedUser.IsLenderAdmin &&
      this.hasLenderrole &&
      this.selectedUser.Id
    ) {
      let selectedUserFrom = this.assignedLenderUsers.find(
        (l) => l.Id == this.selectedUser.Id,
      );
      let lenderAdmin = this.assignedLenderUsers.find(
        (l) => l.IsLenderAdmin && l.Id != this.selectedUser.Id,
      );
      if (selectedUserFrom?.IsLenderAdmin && !lenderAdmin) {
        this.singleLenderAdminError = `Please assign a new Admin User.`;
      }
    } else if (this.selectedUser.IsLenderAdmin) {
      this.singleLenderAdminError = "";
    }
  }

  onExpandingAssignTrialLicense() {
    if (this.assignLicense) {
      this.getActivePackagePricingsForTrialLicense();
    } else {
      this.assignLicense = !this.assignLicense;
    }
  }
}

import { AddressHistoryDTO } from "@js/DTO/AddressHistoryDTO.cs.d";
import { AddressLookupDTO } from "@js/DTO/AddressLookupDTO.cs.d";
import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { CaseChangedMessageDTO } from "@js/DTO/CaseChangedMessageDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { BridgingDealDTO } from "@js/DTO/Deal/BridgingDealDTO.cs.d";
import { DealClientDTO } from "@js/DTO/Deal/DealClientDTO.cs.d";
import { DealDTO } from "@js/DTO/Deal/DealDTO.cs.d";
import { DealFileAttachmentDTO } from "@js/DTO/Deal/DealFileAttachmentDTO.cs.d";
import { DealLenderDTO } from "@js/DTO/Deal/DealLenderDTO.cs.d";
import { DealNoteDTO } from "@js/DTO/Deal/DealNoteDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { ExternalLinksDTO } from "@js/DTO/ExternalLinksDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { LenderDTO } from "@js/DTO/LenderDTO.cs.d";
import { InvitedUserResponse } from "@js/DTO/Messages/Deal/InvitedUserMessage.cs.d";
import { LenderInfo } from "@js/DTO/Messages/Deal/LendersInfoForSubmitToLenderMessage.cs.d";
import { PromoteSearchToCaseResponse } from "@js/DTO/Messages/Deal/PromoteSearchToCaseMessage.cs.d";
import { SaveBridgingSearchRequest } from "@js/DTO/Messages/Deal/SaveBridgingSearchMessage.cs.d";
import { ShareModuleRequest } from "@js/DTO/Messages/Deal/ShareModuleMessage.cs.d";
import { SubmitToLendersRequest } from "@js/DTO/Messages/Deal/SubmitToLendersMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { UserSimpleDTO } from "@js/DTO/UserSimpleDTO.cs.d";
import { AppraisalModuleEnum } from "@js/models/enum/AppraisalModuleEnum.cs.d";
import { CaseAccessLevelEnum } from "@js/models/enum/CaseAccessLevelEnum.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { ExistingPlanningChangeTypeEnum } from "@js/models/enum/ExistingPlanningChangeTypeEnum.cs.d";
import { ExitStrategyEnum } from "@js/models/enum/ExitStrategyEnum.cs.d";
import { LandOrPropertyEnum } from "@js/models/enum/LandOrPropertyEnum.cs.d";
import { LenderProductTypeEnum } from "@js/models/enum/LenderProductTypeEnum.cs.d";
import { LicenseMasterStatusEnum } from "@js/models/enum/LicenseMasterStatusEnum.cs.d";
import { LoanPurposeEnum } from "@js/models/enum/LoanPurposeEnum.cs.d";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";
import { OwnNewDevelopmentEnum } from "@js/models/enum/OwnNewDevelopmentEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PropertyTypeDetailEnum } from "@js/models/enum/PropertyTypeDetailEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { TenureEnum } from "@js/models/enum/TenureEnum.cs.d";
import { TitleEnum } from "@js/models/enum/TitleEnum.cs.d";
import { YesNoMaybe } from "@js/models/enum/YesNoMaybeEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { AccountService } from "@js/services/AccountService";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { BridgingDealService } from "@js/services/Deal/BridgingDealService";
import { DealLenderService } from "@js/services/Deal/DealLenderService";
import { DealNoteService } from "@js/services/Deal/DealNoteService";
import { DealService } from "@js/services/Deal/DealService";
import { SharedDealService } from "@js/services/Deal/SharedDealService";
import { DealClientService } from "@js/services/DealClientService";
import { DealFileAttachmentService } from "@js/services/DealFileAttachmentService";
import { ExternalLinksService } from "@js/services/ExternalLinksService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationService } from "@js/services/OrganisationService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { StreetAddressService } from "@js/services/StreetAddressService";
import angular from "angular";

export class BridgingCaseController {
  isCaseDashboard: boolean = true;

  dataLoading: boolean;
  currentUser: ApplicationUserDTO;
  dealId: number;
  dealDto: BridgingDealDTO;
  tempDealDto: BridgingDealDTO;
  orgName: string;
  orgId: number;
  org: OrganisationDTO;
  fileNamePrefix: string = "Brickflow";
  newNote: DealNoteDTO = {} as DealNoteDTO;

  BridgingCaseDashboardTypeEnum = {
    Overview: 0,
    Borrower: 1,
    Property: 2,
    Loan: 3,
  };

  CountryList = [];
  NationalityList = [];

  selectedNavMenu: number = 0;

  dealForm: ng.IFormController;
  ownershipForm: ng.IFormController;
  companyDetailsForm: ng.IFormController;
  propertyDetailsForm: ng.IFormController;
  loanDetailsForm: ng.IFormController;
  lenderDetailsForm: ng.IFormController;

  personalDetailsForm: ng.IFormController;
  contactForm: ng.IFormController;
  occupationForm: ng.IFormController;
  creditForm: ng.IFormController;

  qualificationComplete: boolean = false;
  showAddressHistoryModal: boolean = false;

  tabs = {
    tabOpen: false,
    ownership: false,
    companyDetails: false,
    personalDetails: false,
    contactDetails: false,
    occupation: false,
    credit: false,
  };

  purchaseSelected: boolean = false;
  ownershipType: string = "";

  currentApplicant: DealClientDTO;
  tempApplicant: DealClientDTO;
  applicantList: DealClientDTO[] = [];
  appName: string;

  deal: DealDTO;

  searchterm: string;
  previousAddressSearchTerm: string;
  PostCodeOptions: PostalAddress[] = [];
  previousAddressPostCodeOptions: PostalAddress[] = [];
  address: PostalAddress;
  searchingForAddress: boolean = false;
  searchingForPreviousAddress: boolean = false;
  searchresults: string = "";
  previousAddressSearchResults: string[];
  addressHistoryItem: AddressHistoryDTO;
  applicantAddressHistory: AddressHistoryDTO[] = [];

  propertyTypeOptions = [];
  exitStrategyOptions = [];
  interestServiceOrRoll = [];
  tenureTypes = [];
  maritalStatusOptions = [];
  ownOrPurchaseOptions = [];
  chargeTypeOptions = [];
  ownNewDevelopmentOptions = [];
  yesNoOptions = [];
  siteTypeOptions = [];
  buildingConditionOptions = [];
  sourceOfIncomeOptions = [];
  loanPurposeOptions = [];
  projectCompletionOptions = [];
  planningStatusAtStartOfDevOptions = [];
  planningStatusAtEndOfDevOptions = [];
  planningOptions = [];
  titleOptions = [];
  existingPlanningChangeTypeOptions = [];
  buildStageOptions = [];
  ownershipRoleOptions = [];

  countryList = [];
  nationalityList = [];

  countryOfBirthList = [];
  countryOfResidenceList = [];
  loanCompletionTypeOptions = [];
  refurbishmentLevelOptions = [];
  locationOptions = [];

  //modal
  message: string = "";
  modal: boolean = false;
  showErrorMessage: boolean = false;

  uploadingFiles: FileUploadProgressDTO[];
  fileUpload: DealFileAttachmentDTO[];
  total: number = 0;
  renamingFile: DealFileAttachmentDTO;

  // Number of note shown on the casedashboard
  maxNotes: number = 4;

  // Controls showing Ts & Cs when a borrower/broker submits to lenders
  showTsAndCs: boolean = false;
  showApplyTsAndCs: boolean = false;
  showRequestEmailForm: boolean = false;
  requestMessageReason: string = "";
  showTsAndCsForm: boolean = false;
  sendingMessage: boolean = false;
  messagebool: boolean = false;
  invalidEmail: boolean = false;

  isBroker: boolean = false;
  isAdmin: boolean = false;
  isLender: boolean = false;
  isClient: boolean = false;
  isSubmitted: boolean = false;

  isSubmittedToLenders: boolean = false;
  messageContent: string;
  dealLender: DealLenderDTO;

  //Boolean to enable a re-submit button
  hasShortlistedDealLenders: boolean = false;

  caseStatusOptions = [];
  caseStatusText: string = "";
  caseOverview: string;

  selectedLendersName: string = "";
  shortlistedLendersName: string = "";

  //Sharing case
  shareContext: AppraisalModuleEnum = null;
  caseMembersWithAccess: ClientDTO[];
  showShare: boolean = false;
  selectedClientToShareWith: ClientDTO;
  shareNote: string;
  showModuleShareConfirmation: boolean;

  //show profile view
  showAppraisalPermission: boolean = false;

  //profile
  newApplicant: DealClientDTO;

  maxDateOfBirth: Date = new Date();
  minDateOfBirth: Date = new Date("Jan 01 1900");
  maxDateForDateField: Date = new Date("Jan 01 3000");
  showAddorEdit: boolean = false;
  disableQuickEditSave: boolean = false;
  applicantForm: ng.IFormController;
  isAddShareholder: boolean;
  showDelete: boolean = false;
  confirmDeleteApplicant: DealClientDTO;

  showExport: boolean = false;
  updatingName: boolean = false;
  orginalDealName: string;

  showAddHistoryConfirmDelete: boolean = false;
  addressHistoryIdToDelete: number;

  isLoggedInUser: boolean = false;

  showDeal: boolean = true;
  classUses: {} = {};

  tinymceOptions: any;
  missingAddressGaps: string[];

  ownOrPurchase: boolean;
  landOrProperty: boolean;
  isFamilyInResidence: boolean;
  totalCosts: number = 0;

  dateProperties = ["ExpiryDate"];

  profileDateProperties = [
    "DateOfBirth",
    "CurrentAddressFrom",
    "UkResidenceIssueDate",
    "CurrentEmploymentStartDate",
    "AddressStartDate",
  ];

  dealDateProperties = ["OriginalPurchaseDate", "CompletionDate"];

  clientDateProperties = ["DateOfBirth"];

  maxPurchaseDate: Date = new Date();
  minPurchaseDate: Date = new Date("Jan 01 1900");

  minCompletionDate: Date;
  dealProductTypeDisplayText: string;
  isInActiveLender: boolean = false;

  contingencyAmount: number = null;
  lendersDetailsForSubmittoLenders: LenderInfo[];
  shortListedLendersId: Number[];
  //SubmitToLender modal
  brokerPreferredContact: string;
  isBrokerPhoneNumberUpdated: boolean = false;
  brokerCommission: number;
  dealBrokerUser: ApplicationUserDTO;
  totalUnreadDealLenderMessages: number = null;

  //Lender Portal Links
  dealLenders: DealLenderDTO[];
  dealLendersWithPortalLinkEnabled: DealLenderDTO[];
  lendersWithPortalLinkEnabled: LenderDTO[];
  showLenderPortalLinks: boolean = false;
  portalLinks: {} = {};
  borrowerDetails: UserSimpleDTO;
  applicationForm: ng.IFormController;
  portalLinkDetailsAdded: {} = {};
  showMaximumApplicantsMessage: boolean = false;

  static $inject = [
    "$rootScope",
    "$routeParams",
    "$location",
    "$cookies",
    "AccountService",
    "StreetAddressService",
    "BridgingDealService",
    "DealNoteService",
    "AuthService",
    "SelectListService",
    "RoleService",
    "LenderService",
    "CaseService",
    "DealLenderService",
    "DealFileAttachmentService",
    "$window",
    "ExternalLinksService",
    "DealClientService",
    "OrganisationService",
    "$scope",
    "$sce",
    "ProductService",
    "DealService",
    "SharedDealService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $cookies: ng.cookies.ICookiesService,
    private $accountservice: AccountService,
    private $streetAddressService: StreetAddressService,
    private bridgingDealService: BridgingDealService,
    private dealNoteService: DealNoteService,
    private authService: AuthService,
    private selectListService: SelectListService,
    private roleService: RoleService,
    private $lenderService: LenderService,
    private caseService: CaseService,
    private dealLenderService: DealLenderService,
    private dealFileAttachmentService: DealFileAttachmentService,
    private $window: ng.IWindowService,
    private $externalLinksService: ExternalLinksService,
    private dealClientService: DealClientService,
    private organisationService: OrganisationService,
    private $scope: ng.IScope,
    private $sce: ng.ISCEService,
    private productService: ProductService,
    private dealService: DealService,
    private sharedDealService: SharedDealService,
  ) {
    if ($cookies.get("access_token")) {
      this.isLoggedInUser = true;
    }

    this.tinymceOptions = this.sharedDealService.tinymceOptions;

    this.roleService.GetUserRoles().then((result) => {
      if (result.filter((x) => x.toLowerCase() == "client").length > 0) {
        this.isClient = true;
      } else {
        this.isClient = false;
      }
    });

    if (this.$routeParams.DealId) {
      this.dataLoading = true;

      this.dealId = this.$routeParams.DealId;
      if (
        this.$routeParams.Promote &&
        this.$routeParams.Promote != "null" &&
        !this.dealDto
      ) {
        var promote = this.$routeParams.Promote as boolean;
        if (promote) {
          this.promoteSearchToCase();
        }
      } else {
        this.dealService
          .fetchByDealId(this.$routeParams.DealId, ProductFamilyEnum.Bridging,true)
          .then((response) => {
            if (response.HideBridgingDeal) {
              this.showDeal = false;
            } else {
              this.dealDto = response.BridgingDealDto;
              this.shortlistedLendersName = response.ShortlistedLendersName;
              this.selectedLendersName = response.SelectedLendersName;
              this.shortListedLendersId = response.ShortlistedLendersId;
              if (this.dealDto) {
                this.orgId = this.dealDto.BrokerOrganisationId;
                if (this.dealDto.ProjectDescription) {
                  this.caseOverview = this.dealDto.ProjectDescription;
                }
                this.totalUnreadDealLenderMessages =
                  response.TotalUnreadDealLenderMessages;
                this.postRetrieveProcessing();

                var newShortlist = this.$routeParams.NewShortlist as boolean;
                if (newShortlist) this.getLenderPortalLinks();
              } else {
                // If no case is returned, this could be that the logged in user has been removed from the case
                // - only do this if there is a logged in user, otherwise let the routing sort out where the redirect should go
                if ((this.$rootScope as any).selectedUser) {
                  this.$location.path("/dashboard");
                }
              }
            }
          })
          .finally(() => {
            this.dataLoading = false;
          });
      }
    }

    this.countryOfBirthList = this.countryOfResidenceList =
      this.selectListService.GetCountries();

    this.nationalityList = this.selectListService.GetNationalities();

    this.chargeTypeOptions = this.selectListService.GetChargeType();
    this.ownOrPurchaseOptions = this.selectListService.GetOwnOrPurchase();
    this.maritalStatusOptions =
      this.selectListService.GetMaritalStatusOptions();
    this.propertyTypeOptions = this.selectListService.GetPropertyType();
    this.exitStrategyOptions = this.selectListService.GetExitStrategy(false);
    this.interestServiceOrRoll =
      this.selectListService.GetInterestServiceOrRoll();
    this.ownNewDevelopmentOptions =
      this.selectListService.GetOwnNewDevelopmentOptions();

    this.yesNoOptions = this.selectListService.GetYesNoForBridging();
    this.siteTypeOptions = this.selectListService.GetSiteType();
    this.buildingConditionOptions =
      this.selectListService.GetBuildingConditionType();
    this.sourceOfIncomeOptions =
      this.selectListService.GetSourceOfIncomeOptions();
    this.projectCompletionOptions =
      this.selectListService.GetCompletionOptions();
    this.planningStatusAtStartOfDevOptions =
      this.selectListService.GetPlanningStatusAtStartOfDevelopmentOptions();
    this.planningStatusAtEndOfDevOptions =
      this.selectListService.GetPlanningStatusAtEndOfDevelopmentOptions();
    this.titleOptions = this.selectListService.GetTitleOptions();
    this.existingPlanningChangeTypeOptions =
      this.selectListService.GetExistingPlanningChangeTypeOptions();
    this.loanCompletionTypeOptions =
      this.selectListService.GetLoanCompletionOptions();
    this.buildStageOptions = this.selectListService.GetBuildStageOptions();
    this.ownershipRoleOptions =
      this.selectListService.GetOwnershipRoleOptions();
    this.refurbishmentLevelOptions =
      this.selectListService.GetRefurbishmentLevelOptions();
    this.locationOptions = this.selectListService.GetLocations();

    this.getAppName();
  }

  getRequiredRoles(isCaseDashboard: boolean) {
    if (isCaseDashboard) return `['Admin', 'Client', 'Broker', 'Introducer']`;
    return `['Admin', 'Client', 'Broker', 'Introducer', 'Lender']`;
  }

  editDealName() {
    var name = this.dealDto.Name;
    this.orginalDealName = name;
    this.updatingName = true;
  }
  cancelEditDealName() {
    this.updatingName = false;
    this.dealDto.Name = this.orginalDealName;
  }

  /**Gets the Case Status display text */
  getCaseStatusDisplayText() {
    var statusItem = null;

    if (this.isLender) {
      statusItem = this.caseStatusOptions.find(
        (statusOption) => this.dealLender.Status == statusOption.key,
      );
    } else {
      statusItem = this.caseStatusOptions.find(
        (statusOption) => this.dealDto.Status == statusOption.key,
      );
    }

    this.caseStatusText = statusItem.displayName;
  }

  postRetrieveProcessing() {
    if (this.$routeParams.selectedNavMenu) {
      this.selectedNavMenu = this.$routeParams.selectedNavMenu;
    }

    this.dealLenderService
      .fetchByDealId(this.$routeParams.DealId)
      .then((dls: DealLenderDTO[]) => {
        this.hasShortlistedDealLenders = dls.some(
          (dl) => dl.Status == CaseLenderStateEnum.Shortlisted,
        );
      });

    //These are used for directive.
    (this.$rootScope as any).statusRequirementId = this.dealDto.Id;
    this.caseService.updateCaseState(this.dealDto.Id, this.dealDto.Status);
    this.getCurrentUserAndRoles();
    this.fileUpload = this.dealDto.Attachments;
    this.applicantList = this.dealDto.DealClients;
    this.loanPurposeOptions = this.selectListService.GetLoanPurposeOptions(
      this.isRegulatedBridging(),
    );
    this.getPlanningOptions();
    this.updateClassUses();
    this.tenureTypes = this.selectListService.GetTenureTypes(
      this.dealDto.ProductType,
    );
    this.dealProductTypeDisplayText =
      this.productService.getProductTypeDisplayText(
        this.dealDto.ProductType,
        this.isRegulatedBridging(),
      );

    this.calcContingencyAmount();

    if (this.dealDto.BrokerOrganisationId) {
      this.getOrganisationbyDealId();
    }
  }

  keyPressEvent(event) {
    if (event.key === "Enter") {
      this.updatingName = false;
      this.submitForm(this.dealForm);
    }
    if (event.key === "Escape") {
      this.updatingName = false;
    }
  }

  submitForm(form: ng.IFormController) {
    this.dataLoading = true;

    var request: SaveBridgingSearchRequest = {
      DealDto: this.dealDto,
      ShareDealDto: null,
      OrgCode: "",
      EnterpriseClientDto: null,
    };

    this.bridgingDealService
      .saveBridgingSearchReturnsId(request)
      .then((response) => {
        if (response != null) {
          this.closeTabs();
          form.$setPristine();
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  closeModalAndGoToDasboard() {
    this.showTsAndCs = false;
    this.authService.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  closeModalAndGoToDealForum() {
    this.showTsAndCs = false;
    this.$location.path("/dealforum/" + this.dealDto.Id);
  }

  closeModal(test: number) {
    this.messagebool = false;
  }

  closeTabs(currentTab: string = "") {
    for (let key in this.tabs) {
      if (currentTab.length == 0 || currentTab != key) {
        this.tabs[key] = false;
      }
    }
  }

  discardOwnership() {
    this.dealDto.HowWillYouOwnNewDevelopment = null;
    //this.companyDetails.temp = null;
    this.dealDto.ExcludedLenders = null;
    this.dealDto.HasTheCompanyOrPensionBeenFormedYet = null;
  }

  gotoApplicant(applicant?: DealClientDTO) {
    this.currentApplicant = applicant || null;
    this.PostCodeOptions = null;

    if (
      this.currentApplicant &&
      this.currentApplicant.Profile &&
      this.currentApplicant.Profile.AddressHistory &&
      this.currentApplicant.Profile.AddressHistory.length > 0
    ) {
      this.parseAddressHistory(this.currentApplicant.Profile.AddressHistory);
    } else {
      this.applicantAddressHistory = [];
    }

    this.selectedNavMenu = 4;
    this.closeTabs();
  }

  getOwnershipTypeText(): string {
    var ownershipTypeText;

    if (this.dealDto && this.dealDto.HowWillYouOwnNewDevelopment) {
      switch (this.dealDto.HowWillYouOwnNewDevelopment) {
        case OwnNewDevelopmentEnum.UKLimitedCompany:
          this.ownershipType = ownershipTypeText = "Company";
          break;
        case OwnNewDevelopmentEnum.OverseasLimitedCompany:
          this.ownershipType = ownershipTypeText = "Overseas Company";
          break;
        case OwnNewDevelopmentEnum.PensionFund:
          this.ownershipType = ownershipTypeText = "Pension Fund";
          break;
        case OwnNewDevelopmentEnum.UKLimitedLiabilityPartnership:
          ownershipTypeText = "Limited Liability Partnership";
          this.ownershipType = "Partnership";
          break;
        default:
          ownershipTypeText = "";
      }
      return ownershipTypeText;
    }
    return "";
  }

  openAddressHistoryModal(addressHistory: AddressHistoryDTO = null) {
    this.previousAddressSearchResults = [];
    this.previousAddressPostCodeOptions = [];

    if (addressHistory) {
      this.addressHistoryItem = addressHistory;
    } else {
      this.addressHistoryItem = null;
    }

    this.showAddressHistoryModal = true;
  }

  addressHistoryComplete() {
    return this.sharedDealService.addressHistoryComplete(
      this.currentApplicant,
      this.applicantAddressHistory,
      this.missingAddressGaps,
    );
  }

  getAddressList(searchTerm: string, postalAddress: PostalAddress) {
    let foundMatch = false;

    if (this.PostCodeOptions && this.PostCodeOptions.length > 0) {
      let addressLookup = this.PostCodeOptions.find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;
        this.$streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            for (let prop in address) {
              if (address.hasOwnProperty(prop)) {
                postalAddress[prop] = address[prop];
              }
            }
            this.searchterm = "";
            this.searchresults = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        postalAddress.AddressLine1 = addressLookup.AddressLine2.replace(
          addressLookup.AddressLine2.substring(
            addressLookup.AddressLine2.indexOf("-") - 1,
          ),
          "",
        );
        postalAddress.AddressLine2 = addressLookup.AddressLine3;
        postalAddress.PostCode = addressLookup.AddressLine1;
        this.searchterm = "";
        this.searchresults = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      this.PostCodeOptions = [];

      this.$streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.PostCodeOptions = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  lookupPreviousAddress(searchTerm: string) {
    let foundMatch = false;

    if (
      this.previousAddressPostCodeOptions &&
      this.previousAddressPostCodeOptions &&
      this.previousAddressPostCodeOptions.length > 0
    ) {
      let addressLookup = this.previousAddressPostCodeOptions.find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.$streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            this.addressHistoryItem = {
              AddressLine1: address.AddressLine1,
              AddressLine2: address.AddressLine2,
              AddressLine3: address.AddressLine3,
              AddressLine4: address.AddressLine4,
              PostCode: address.PostCode,
            };

            this.previousAddressSearchTerm = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        this.addressHistoryItem = {
          AddressLine1: addressLookup.AddressLine2,
          AddressLine2: addressLookup.AddressLine3,
          AddressLine3: addressLookup.AddressLine4,
          AddressLine4: addressLookup.PostCode,
          PostCode: addressLookup.AddressLine1,
        };

        this.previousAddressSearchTerm = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.previousAddressPostCodeOptions = [];

      this.$streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.previousAddressPostCodeOptions = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  saveAddressHistory(selectedApplicant: DealClientDTO) {
    var id: number = 0;

    if (!this.applicantAddressHistory) {
      this.applicantAddressHistory = [];
    }

    // If the Id hasn't been set then this is a new previous address
    if (this.addressHistoryItem.Id == null) {
      id = this.getNextAddressHistoryId();
      this.addressHistoryItem.Id = id;
      this.applicantAddressHistory.push(this.addressHistoryItem);
    } else {
      var foundIndex = this.applicantAddressHistory
        .map(function (a) {
          return a.Id;
        })
        .indexOf(this.addressHistoryItem.Id);
      if (foundIndex > -1) {
        this.applicantAddressHistory.splice(
          foundIndex,
          1,
          this.addressHistoryItem,
        ); // removed previous entry and adds the updated one in its place
      }
    }

    this.dealClientService
      .saveAddressHistory(
        selectedApplicant.Id,
        JSON.stringify(this.applicantAddressHistory),
      )
      .then((addressHistory: string) => {
        selectedApplicant.Profile.AddressHistory = addressHistory;
        this.parseAddressHistory(addressHistory);
        this.showAddressHistoryModal = false;
      })
      .catch((error) => {
        this.modal = true;
        this.message =
          "There was a problem saving the address history. Please try again later.";
      })
      .finally(() => {
        this.showAddressHistoryModal = false;
      });
  }

  /**
   * Convert addres history JSON back to an array of PostalAddress objects
   * @param addressHistoryJson
   */
  parseAddressHistory(addressHistoryJson: string) {
    // JSON doesn't understand date types so therefore user the reviver function to manually convert them back to dates as opposed to strings
    this.applicantAddressHistory = JSON.parse(addressHistoryJson, this.reviver);

    // Order the address histories by the from date (ascending)
    this.applicantAddressHistory.sort(
      (a: AddressHistoryDTO, b: AddressHistoryDTO) => {
        return this.getTime(a.FromDate) - this.getTime(b.FromDate);
      },
    );
  }

  getAddressAsString(address: PostalAddress) {
    return this.sharedDealService.getAddressAsString(address);
  }

  concatenateAddressLine(addressLine: string, concatenatedAddress: string) {
    if (addressLine != null && addressLine.length > 0) {
      if (concatenatedAddress.length > 0) {
        concatenatedAddress = concatenatedAddress.concat(", ", addressLine);
      } else {
        concatenatedAddress = concatenatedAddress.concat(addressLine);
      }
    }

    return concatenatedAddress;
  }

  showConfirmAddressDelete(addressHistoryId: number) {
    this.showAddHistoryConfirmDelete = true;
    this.addressHistoryIdToDelete = addressHistoryId;
  }

  deleteAddressHistory() {
    this.showAddHistoryConfirmDelete = false;

    var foundIndex = this.applicantAddressHistory
      .map(function (a) {
        return a.Id;
      })
      .indexOf(this.addressHistoryIdToDelete);

    if (foundIndex > -1) {
      this.applicantAddressHistory.splice(foundIndex, 1);

      this.dealClientService
        .saveAddressHistory(
          this.currentApplicant.Id,
          JSON.stringify(this.applicantAddressHistory),
        )
        .then((addressHistory: string) => {
          this.currentApplicant.Profile.AddressHistory = addressHistory;
          this.parseAddressHistory(addressHistory);
          this.showAddressHistoryModal = false;
        })
        .catch((error) => {
          this.modal = true;
          this.message =
            "There was a problem deleting the address history item. Please try again later.";
        });
    }
  }

  getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  /**
   * Tests whether a string is a date and converts it if so - used in JSON.parse
   * @param key
   * @param value
   */
  reviver(key, value) {
    var a;
    if ((typeof value === "string" && key == "ToDate") || key == "FromDate") {
      a = Date.parse(value);
      if (a) {
        return new Date(a);
      }
    }
    return value;
  }

  getNextAddressHistoryId(): number {
    return this.sharedDealService.getNextAddressHistoryId(
      this.applicantAddressHistory,
    );
  }

  /** Promotes/converts a search to a case */
  promoteSearchToCase() {
    this.dealService
      .promoteSearchToCase(this.dealId, ProductFamilyEnum.Bridging)
      .then((response: PromoteSearchToCaseResponse) => {
        if (response != null && response.BridgingDealDto != null) {
          this.dealDto = response.BridgingDealDto;
          this.shortlistedLendersName = response.ShortlistedLendersName;
          this.selectedLendersName = response.SelectedLendersName;
          this.shortListedLendersId = response.ShortlistedLendersId;
          this.postRetrieveProcessing();
          this.getCurrentUserAndRoles();
        } else {
          this.message =
            "Problem while promoting a search to case, please try after some time.";
          this.showErrorMessage = true;
        }
      })
      .finally(() => {
        this.dataLoading = false;
        this.getLenderPortalLinks();
      });
  }

  getLenderPortalLinks() {
    this.dealLenderService
      .fetchByDealId(this.$routeParams.DealId)
      .then((dls: DealLenderDTO[]) => {
        this.dealLenders = dls;
        var searchChanged = this.$routeParams.SearchChanged;
        const lenderIds = dls
          .filter(
            (dl) =>
              !dl.HasAppliedInLenderPortal &&
              (dl.Status === CaseLenderStateEnum.Shortlisted ||
                searchChanged == "true"),
          )
          .map((dl) => dl.LenderId);
        return this.$lenderService.getLenderPortalLinks(lenderIds);
      })
      .then((links) => {
        this.portalLinks = links;
      })
      .catch((error) => {
        this.message = "Error fetching lender portal links: " + error;
        this.portalLinks = null;
      })
      .finally(() => {
        this.showLenderPortalLinks = Object.keys(this.portalLinks).length > 0;
      });
  }

  openPortalLink(dealLenderId: number, url: string) {
    if (!this.portalLinkDetailsAdded[dealLenderId]) {
      this.dealLenderService
        .addLenderPortalLinkDetails(dealLenderId, this.borrowerDetails)
        .then(() => {
          this.portalLinkDetailsAdded[dealLenderId] = true;
          this.openUrl(url);
        })
        .catch((error) => {
          console.error("Error adding lender portal link details:", error);
        });
    } else {
      this.openUrl(url);
    }
  }

  private openUrl(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      url = "http://" + url;
    }
    window.open(url, "_blank");
  }

  saveNote(): void {
    this.newNote.DealId = this.dealDto.Id;
    this.newNote.UserId = this.currentUser.Id;

    this.dataLoading = true;
    this.dealNoteService
      .addUpdate(this.newNote)
      .then((response) => {
        this.dealDto.DealNotes.push(response);
        this.newNote = {
          DealId: this.dealDto.Id,
          UserId: this.currentUser.Id,
        } as DealNoteDTO;
        this.dealForm.$setPristine();
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  saveOverview(): void {
    this.dealDto.ProjectDescription = this.caseOverview;
    this.submitForm(this.dealForm);
  }

  gotoResultpage() {
    this.showErrorMessage = false;
    this.$location.path("/bridgingresults/" + this.dealId);
  }

  onFileSelect(files) {
    var module = ModuleEnum.Case;

    this.dealFileAttachmentService
      .UploadFileListInSync(
        files,
        this.uploadingFiles,
        this.dealDto.Id,
        this.fileUpload,
        module,
      )
      .finally(() => {
        this.dataLoading = false;
      });
  }

  calculateTotalFilesRemoving(filter: number) {
    if (this.fileUpload) {
      this.total = this.fileUpload.filter(
        (item) => item.Module != filter,
      ).length;
    }
    return this.total;
  }

  EnumToString(item: ModuleEnum) {
    switch (item) {
      case 0:
        return "Case Overview";
      case 1:
        return "Developer Profile";
      case 2:
        return "Development Appraisal";
      case 3:
        return "Development Schedule";
      case 4:
        return "Planning";
      case 5:
        return "Professional Team";
      case 6:
        return "Comparable Properties";
      case 7:
        return "General";
      case 8:
        return "Track Record";
      case 9:
        return "Assets & Liabilities";
      case 10:
        return "Proof Of Income";
      case 11:
        return "Applicant Details";
      case 12:
        return "Pictures";
      default:
        return "General";
    }
  }

  downloadFile(file: DealFileAttachmentDTO, downloadFile: boolean = false) {
    this.dataLoading = true;
    this.dealFileAttachmentService
      .getFileUri(file.FileLocation)
      .then((uri) => {
        if (downloadFile) {
          this.sharedDealService.downloadFile(uri, file.FileName);
        } else {
            this.$window.open(uri);
        }      
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  renameFile(file: DealFileAttachmentDTO) {
    if (this.renamingFile === undefined) {
      // if renaming this file will dirty the case, we'll want to set it back to pristine when we're done.
      // this.renamingFileDirties = !$scope.caseForm.$dirty;
      this.renamingFile = file;
    } else {
      delete this.renamingFile;
    }
  }
  renamingFileComplete(file: DealFileAttachmentDTO) {
    this.dataLoading = true;
    this.dealFileAttachmentService
      .renameFile(file)
      .then((response) => {
        file.TempURL = response.TempURL;
      })
      .finally(() => {
        this.dataLoading = false;
      });
    delete this.renamingFile;
  }

  renamingLinkCompleted(link: ExternalLinksDTO) {
    this.dataLoading = true;
    this.$externalLinksService
      .addUpdate(link)
      .then((response) => {
        link.Link = response.Link;
      })
      .finally(() => {
        this.dataLoading = false;
      });
    delete this.renamingFile;
  }

  deleteFile(file: DealFileAttachmentDTO) {
    this.dealDto.Attachments.splice(this.dealDto.Attachments.indexOf(file), 1);
    this.dealFileAttachmentService.markasdeleted(file.Id).then((result) => {}); //remove on the server
  }

  markSectionAsComplete(propertyName: string) {
    if (this.dataLoading == false) {
      this.dataLoading = true;

      this.dealDto.IsLoanSectionComplete;

      this.dealService
        .markSectionAsComplete(
          this.dealDto.Id,
          propertyName,
          ProductFamilyEnum.Bridging,
        )
        .then((response) => {
          if (response) {
            this.dealDto.DealNotes.push(response.NewDealNote);
            this.dealDto = response.BridgingDealDto;
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  allFormsComplete(): boolean {
    if (this.dealDto) {
      if (
        this.dealDto.IsBorrowerSectionComplete &&
        this.dealDto.IsPropertySectionComplete &&
        this.dealDto.IsLoanSectionComplete
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  /** Opens the Terms and Conditions popup when the Submit to Lenders button is clicked*/
  showTermsAndConditionsForBorrower() {
    this.showApplyTsAndCs = true;
    this.requestMessageReason = "a review";
    this.showTsAndCs = true;
  }

  /** Opens the Terms and Conditions popup when the Submit to Lenders HoT button is clicked (borrower/broker) */
  showTermsAndConditions() {
    this.$lenderService
      .getShortlistedLendersInfoForSubmitToLenders(
        this.shortListedLendersId,
        LenderProductTypeEnum.BridgingFinance,
      )
      .then((response) => {
        this.lendersDetailsForSubmittoLenders = response;
      })
      .finally(() => {
        this.showTsAndCs = true;
        this.showTsAndCsForm = true;
        this.dealDto.SubmitTsAndCs = false;
        this.invalidEmail = false;
      });
  }

  gotoLendingPage() {
    (this.$rootScope as any).isLender = false;
    this.$location.path("/dealforum/" + this.dealDto.Id);
  }

  checkBrokerDeal() {
    return this.dealDto?.BrokerUserId != null && this.isClient ? true : false;
  }

  /** Submits a case to lenders */
  submitCaseToLenders() {
    this.showTsAndCsForm = false;
    this.sendingMessage = true;
    this.dataLoading = true;
    var isResubmitting = false;

    if (this.dealDto.Status == CaseStatusEnum.ReadyToReSubmit) {
      isResubmitting = true;
    }

    var request = {
      DealId: this.dealId,
      ProductType: this.dealDto.ProductType,
      ResubmitToLenders: isResubmitting,
      BrokerCommission: Number(this.brokerCommission),
      LendersInfo: this.lendersDetailsForSubmittoLenders,
      BrokerPhoneNumber: this.brokerPreferredContact,
      IsBrokerPhoneNumberUpdated: this.isBrokerPhoneNumberUpdated,
    } as SubmitToLendersRequest;

    this.dealService
      .submitToLenders(request)
      .then((response: CaseChangedMessageDTO) => {
        this.dealDto.Status = response.CaseState;
        this.caseService.updateCaseState(this.dealDto.Id, response.CaseState);
        this.showMessage(
          "This case has been submitted to Lenders to request a Decision in Principle.",
        );
        this.isSubmittedToLenders = true;
      })
      .catch((error) => {
        this.showMessage("Sorry, something went wrong. Please try again.");
      })
      .finally(() => {
        this.sendingMessage = false;
        this.dataLoading = false;
      });
  }

  validateEmails() {
    this.invalidEmail = false;

    angular.forEach(
      this.lendersDetailsForSubmittoLenders,
      (lenderdetails, index) => {
        var emailField = this.lenderDetailsForm["email" + index];

        if (emailField && emailField.$invalid) {
          this.invalidEmail = true;
        }
      },
    );
  }

  showMessage(message: string) {
    this.messageContent = message;
  }

  requestEmail(reason): void {
    this.showRequestEmailForm = true;
    this.requestMessageReason = reason;
    this.messagebool = true;
    this.messageContent = "";
  }

  sendRequestEmail() {
    this.showRequestEmailForm = false;
    this.sendingMessage = true;
    this.dataLoading = true;
    this.$accountservice
      .RequestAssistanceReview(
        this.requestMessageReason,
        this.dealDto.Id,
        this.dealDto.ProductFamily,
      )
      .then((response) => {
        this.showMessage(
          `${this.appName} have been notified that you require assistance and will be in touch shortly to help.`,
        );

        let dealNote = {
          DealId: this.dealDto.Id,
          UserId: this.currentUser.Id,
          NoteText: "You have requested " + this.requestMessageReason + ".",
          /*ExpandNote: false*/
        } as DealNoteDTO;
        this.dealNoteService.addUpdate(dealNote).then((caseNoteResponse) => {
          this.dealDto.DealNotes.push(caseNoteResponse);
        });
      })
      .catch((error) => {
        this.showMessage(
          "Sorry, something went wrong while sending your email. Please try again.",
        );
      })
      .finally(() => {
        this.sendingMessage = false;
        this.dataLoading = false;
      });
  }

  getAppName() {
    let lsd = sessionStorage.getItem("applicationName");
    if (lsd != null) {
      this.appName = lsd;
    } else {
      this.appName = "Brickflow";
    }
  }

  closeConfirmationModal() {
    if (this.isSubmittedToLenders) {
      if (this.$routeParams.Promote) {
        this.$location.path("/bridgingcasedashboard/" + this.dealDto.Id);
      } else {
        window.location.reload();
      }
    }
    this.showTsAndCs = false;
  }

  viewResults(loanCriteria: DevelopmentInputDTO) {
    this.$location.path("/bridgingresults/" + this.dealDto.Id);
  }

  /** Process the Heads of Terms button being clicked */
  headsOfTermsClicked() {
    sessionStorage.setItem("lenderTask", "terms");
    this.$location.path(
      "/bridgingheadsofterm/" + this.dealDto.Id + "/" + this.dealLender.Id,
    );
  }

  /** Lender rejects the case*/
  rejectButtonClicked() {
    sessionStorage.setItem("lenderTask", "reject");
    this.$location.path(
      "/bridgingheadsofterm/" + this.dealDto.Id + "/" + this.dealLender.Id,
    );
  }

  withDrawButtonClicked() {
    sessionStorage.setItem("lenderTask", "withdraw");
    this.$location.path(
      "/bridgingheadsofterm/" + this.dealDto.Id + "/" + this.dealLender.Id,
    );
  }

  feedbackButtonClicked() {
    sessionStorage.setItem("lenderTask", "feedback");
    this.$location.path(
      "/bridgingheadsofterm/" + this.dealDto.Id + "/" + this.dealLender.Id,
    );
  }

  getCurrentUserAndRoles() {
    this.roleService.GetUserRoles().then((result) => {
      if (result.filter((x) => x.toLowerCase() == "admin").length > 0) {
        this.isAdmin = true;
      }
      if (result.filter((x) => x.toLowerCase() == "broker").length > 0) {
        this.isBroker = true;
      }
      if (result.filter((x) => x.toLowerCase() == "client").length > 0) {
        this.isClient = true;
      }
      if (result.filter((x) => x.toLowerCase() == "lender").length > 0) {
        this.isLender = true;
        this.caseStatusOptions =
          this.selectListService.GetCaseLenderStateOptionsForLenders();

        this.dealLenderService
          .fetchDealLender(this.dealDto.Id)
          .then((response) => {
            this.dealLender = response;
            this.isInActiveLender = response.IsLenderInActive;
            this.getCaseStatusDisplayText();
          });
      }

      if (!this.isLender) {
        this.caseStatusOptions = this.selectListService.GetCaseStatusOptions();
        this.getCaseStatusDisplayText();
      }

      this.authService
        .getUpdatedProfile()
        .then((prof) => {
          this.currentUser = prof;
        })
        .then(() => {
          this.checkIfUserIsInvited();
          this.updateRequestAssitanceDealNoteMessage();
        });

     /* this.$lenderService
        .getSelectedDealLenderNames(this.dealDto.Id)
        .then((response) => {
          this.selectedLendersText = response.LendersName;
          if (this.dealDto.Status == CaseStatusEnum.NewCase) {
            this.shortListedLendersId = response.LendersId;
          }
        });

      this.$lenderService
        .getShortlistedDealLenderNames(this.dealDto.Id)
        .then((response) => {
          this.shortlistedLendersText = response.LendersName;
          if (this.dealDto.Status != CaseStatusEnum.NewCase) {
            this.shortListedLendersId = response.LendersId;
          }
        });*/
    });
  }

  //Share module functions
  openShareModuleModal(contextToShare: AppraisalModuleEnum) {
    this.shareContext = contextToShare;
    this.constructShareNoteMessage();

    this.dealClientService
      .fetchDealMembersWithAccess(this.dealDto.Id)
      .then((response: ClientDTO[]) => {
        this.caseMembersWithAccess = response;

        this.showShare = true;
      });
  }

  constructShareNoteMessage() {
    this.shareNote = this.sharedDealService.constructShareNoteMessage(
      this.shareContext,
      this.currentUser,
    );
  }

  sendToBorrower() {
    this.dataLoading = true;

    var request = {
      ClientFirstName: this.selectedClientToShareWith.FirstName,
      ClientLastName: this.selectedClientToShareWith.LastName,
      ClientEmail: this.selectedClientToShareWith.Email,
      Note: this.shareNote,
      DealId: this.dealDto.Id,
      Section: this.shareContext,
      ProductFamily: ProductFamilyEnum.Bridging,
    } as ShareModuleRequest;

    this.dealService.shareModuleToBorrower(request).then((success) => {
      if (success) {
        this.showShare = false;
        this.dataLoading = false;
        this.showModuleShareConfirmation = true;
        this.selectedClientToShareWith = null;
      } else {
        this.showShare = false;
        this.showMessage("Sorry, something went wrong. Please try again.");
      }
    });
  }

  isShareNoteFormComplete(): boolean {
    return this.selectedClientToShareWith &&
      this.shareNote &&
      this.shareNote.length > 0
      ? true
      : false;
  }

  //End of Share module functions

  // Profile view functions

  /**
   * Show the Add/Edit permissions profile modal
   * @param applicant
   */
  showPermissionsProfileModal(
    profile: DealClientDTO = null,
    isEdit: boolean = false,
  ): void {
    this.showMaximumApplicantsMessage = false;
      if (isEdit) {
        this.dealClientService.fetch(profile.Id).then((response) => {
            this.newApplicant = response;
            this.isAddShareholder = false;
            this.showAddorEdit = true;

        })

    } else {
        this.newApplicant = {} as DealClientDTO;
        this.isAddShareholder = true;
        this.newApplicant.AccessLevel = CaseAccessLevelEnum.Hidden;
        this.showAddorEdit = true;
    }
  }

  resetApplicant() {
    this.applicantForm.$setPristine();
    this.applicantForm.$setUntouched();
    this.newApplicant = {} as DealClientDTO;
    this.showAddorEdit = false;
  }

  /**
   * Saves the applicant being added/edited
   */
  saveApplicant(formController, currentTab: string = "") {
    this.dataLoading = true;

    if (!(this.currentApplicant.Id > 0)) {
      this.currentApplicant.IsApplicant = true;
      this.currentApplicant.DealId = this.dealDto.Id;
      this.currentApplicant.AccessLevel = CaseAccessLevelEnum.Hidden;
    }

    this.saveUpdatedApplicant(
      this.currentApplicant,
      false,
      formController,
      currentTab,
    );
  }

  /**
   * Add or update a applicant
   * @param applicant
   */
  saveUpdatedApplicant(
    applicant: DealClientDTO,
    isPermissionsProfile: boolean = false,
    formController = null,
    currentTab: string = "",
  ): void {
    this.dataLoading = true;

    this.disableQuickEditSave = true; // this is just because sometimes the save takes a little while and the user could click the save button again
    applicant.DealId = this.dealDto.Id;

    if (applicant) {
      this.dateProperties.forEach((property) => {
        let val = applicant[property];
        if (val) {
          applicant[property] = this.modifyDateForBackend(val);
        }
      });
    }

    if (applicant.Profile) {
      this.profileDateProperties.forEach((property) => {
        let val = applicant.Profile[property];
        if (val) {
          applicant.Profile[property] = this.modifyDateForBackend(val);
        }
      });
    }

    if (applicant.Client) {
      this.clientDateProperties.forEach((property) => {
        let val = applicant.Client[property];
        if (val) {
          applicant.Client[property] = this.modifyDateForBackend(val);
        }
      });
    }

    if (!applicant.Client.PostalAddress && isPermissionsProfile) {
      applicant.Client.PostalAddress = {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress;
    }

    this.dealClientService
      .addorUpdateApplicant(applicant, this.isAddShareholder)
      .then((response) => {
        this.dataLoading = true;

        if (response) {
          this.dateProperties.forEach((property) => {
            let val = response[property];
            if (val) {
              response[property] = this.modifyDateForFrontend(val);
            }
          });
        }

        if (response.Profile) {
          this.profileDateProperties.forEach((property) => {
            let val = response.Profile[property];
            if (val) {
              response.Profile[property] = this.modifyDateForFrontend(val);
            }
          });
        }

        if (response.Client) {
          this.clientDateProperties.forEach((property) => {
            let val = response.Client[property];
            if (val) {
              response.Client[property] = this.modifyDateForFrontend(val);
            }
          });
        }

        const existingShareholder = this.applicantList.find(
          (s) => s.Id == response.Id,
        );

        // If found then we're updating
        if (existingShareholder) {
          this.applicantList = this.applicantList.filter(
            (member) => member.Id != applicant.Id,
          );
          this.applicantList.push(response);
        } else {
          this.applicantList.push(response);
        }
        if (!isPermissionsProfile) {
          this.currentApplicant = response;
          //This closes all the tab
          //this.closeTabs(currentTab);
          formController.$setPristine();
        }
      })
      .finally(() => {
        delete this.newApplicant;
        this.showAddorEdit = false;
        this.disableQuickEditSave = false;
        this.dataLoading = false;
      });
  }

  cancelUpdateApplicant() {
    this.showAddorEdit = false;
    this.applicantList = this.applicantList.filter(
      (applicant) => applicant.Id != this.newApplicant?.Id,
    );
    this.applicantList.push(this.newApplicant); // Resets anything that is changed using the original data object
  }

  deleteApplicant(): void {
    this.dataLoading = true;
    this.dealClientService
      .markAsDeleteAndSetNextApplicantAsPrimary(
        this.dealDto.Id,
        this.confirmDeleteApplicant.Id,
      )
      .then((response) => {
        this.applicantList = response;
      })
      .finally(() => {
        this.dataLoading = false;
        this.confirmDeleteApplicant = {} as DealClientDTO;
        this.showDelete = false;
      });
  }

  deleteConfirmApplicant(applicant: DealClientDTO) {
    this.showDelete = true;
    this.confirmDeleteApplicant = { ...applicant };
  }

  nonUkWarningVisible(): boolean {
    return this.sharedDealService.nonUkWarningVisible(this.currentApplicant);
  }

  showInviteButton(shareholder: DealClientDTO) {
    return this.sharedDealService.showInviteButton(shareholder);
  }

  invite(applicant: DealClientDTO) {
    this.dataLoading = true;

    this.dealClientService
      .inviteApplicant(applicant.UniqueRef)
      .then((i) => {
        this.modal = true;
        this.message =
          "A request has been sent to " +
          applicant.Client.FirstName +
          " " +
          applicant.Client.LastName +
          " complete their shareholder profile.";
      })
      .catch((error) => {
        this.modal = true;
        this.message =
          "There was a problem sending the invite request to " +
          applicant.Client.FirstName +
          " " +
          applicant.Client.LastName +
          ". Please try again later.";
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  //End of Profile view functions

  /** Show and hide the export menu */
  showAndHideExport() {
    this.showExport = !this.showExport;
  }

  /**Get planning options based on Own/Purchase and land/property selections */
  getPlanningOptions() {
    if (
      this.dealDto.OwnOrPurchase == null ||
      this.dealDto.LandOrProperty == null
    ) {
      return;
    }

    if (
      this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing &&
      this.dealDto.LandOrProperty == LandOrPropertyEnum.Property
    ) {
      this.planningOptions =
        this.selectListService.GetPurchasingExistingBuildingPlanningTypeOptions(
          this.dealDto.LandOrProperty == LandOrPropertyEnum.Property,
        );
    } else {
      this.planningOptions = this.selectListService.GetPlanningOptions(
        this.dealDto.OwnOrPurchase,
        this.dealDto.LandOrProperty,
        this.dealDto.LandOrProperty == LandOrPropertyEnum.Property,
      );
    }
    //this.planningOptions =  this.selectListService.GetPlanningOptions(this.dealDto.OwnOrPurchase, this.dealDto.LandOrProperty);
  }

  propertySaveDisabled() {
    var commonFields = [
      this.dealDto.OwnOrPurchase,
      this.dealDto.Locations,
      this.dealDto.IsFamilyInResidence,
      this.dealDto.LandOrProperty,
      this.dealDto.PropertyAddress.AddressLine1,
      this.dealDto.PropertyAddress.PostCode,
    ];

    if (
      this.dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance
    ) {
      let purchaseOrRefinanceFields = [
        this.dealDto.PurchasePrice,
        this.dealDto.PurchaseCosts,
      ];

      commonFields.push(...purchaseOrRefinanceFields);
    } else if (
      this.dealDto.ProductType == ProductTypeEnum.BridgingDeveloperExit
    ) {
      let developerExitFields = [this.dealDto.BuildingCostTodate];

      commonFields.push(...developerExitFields);
    } else {
      let preConstructionFields = [
        this.dealDto.PurchasePrice,
        this.dealDto.GDV,
        this.dealDto.LoanTermReq,
      ];

      commonFields.push(...preConstructionFields);
    }

    if (this.propertyDetailsForm && this.propertyDetailsForm.$invalid)
      return true;

    //TODO: Not sure why this is used
    if (this.dealForm && this.dealForm.$invalid) return true;

    if (
      this.dealDto.IsFamilyInResidence == 0 ||
      (this.dealDto.IsFamilyInResidence == YesNoMaybe.Yes &&
        this.dealDto.ProductType !=
          ProductTypeEnum.BridgingPurchaseOrRefinance &&
        this.dealDto.ProductType != ProductTypeEnum.BridgingRefurb)
    )
      return true;

    if (
      this.dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction &&
      (this.dealDto.PlanningPermissionType == null ||
        this.dealDto.PlanningPermissionType < 1)
    ) {
      return true;
    }

    for (let i = 0; i < commonFields.length; i++) {
      if (
        commonFields[i] == null ||
        commonFields[i] == "0" ||
        commonFields[i] == ""
      ) {
        return true;
      }
    }

    if (this.dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction) {
      if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own) {
        let commonOwnFields = [
          this.dealDto.OriginalPurchaseDate,
          this.dealDto.Currentvalue,
          this.dealDto.LandTotalOtherCosts,
        ];
        for (let i = 0; i < commonOwnFields.length; i++) {
          if (commonOwnFields[i] == null) {
            return true;
          }
        }
        if (this.dealDto.LandOrProperty == LandOrPropertyEnum.Land) {
          let commonLandFields = [
            this.dealDto.PurchaseCosts,
            this.dealDto.EstimatedSpending,
          ];
          for (let i = 0; i < commonLandFields.length; i++) {
            if (commonLandFields[i] == null) {
              return true;
            }
          }
          if (this.dealDto.IsMortgaged) {
            if (
              this.dealDto.MortgageCurrentLender == null ||
              this.dealDto.MortgageCurrentLender == ""
            ) {
              return true;
            }
          }
        } else {
          let commonPropFields = [
            this.dealDto.EndPropertyType,
            this.dealDto.BuildingCondition,
          ];
          for (let i = 0; i < commonPropFields.length; i++) {
            if (commonPropFields[i] == null || commonPropFields[i] == "0") {
              return true;
            }
          }

          if (this.dealDto.EndPropertyType == PropertyTypeEnum.Residential) {
            if (
              this.dealDto.PropertyTypeDetail == null ||
              this.dealDto.PropertyTypeDetail == 0
            ) {
              return true;
            } else if (
              this.dealDto.PropertyTypeDetail ==
                PropertyTypeDetailEnum.Houses &&
              this.dealDto.HowManyHouses == null
            ) {
              return true;
            } else if (
              this.dealDto.PropertyTypeDetail == PropertyTypeDetailEnum.Flats &&
              this.dealDto.HowManyFlats == null
            ) {
              return true;
            } else if (
              this.dealDto.PropertyTypeDetail ==
                PropertyTypeDetailEnum.HousesAndFlats &&
              (this.dealDto.HowManyHouses == null ||
                this.dealDto.HowManyFlats == null)
            ) {
              return true;
            }
          }
        }
      }

      if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Purchasing) {
        let commonPurchFields = [
          this.dealDto.PurchaseCosts,
          this.dealDto.EstimatedSpending,
        ];
        for (let i = 0; i < commonPurchFields.length; i++) {
          if (commonPurchFields[i] == null) {
            return true;
          }
        }
        if (this.dealDto.LandOrProperty == LandOrPropertyEnum.Property) {
          let commonPropFields = [
            this.dealDto.EndPropertyType,
            this.dealDto.BuildingCondition,
          ];
          for (let i = 0; i < commonPropFields.length; i++) {
            if (commonPropFields[i] == null || commonPropFields[i] == "0") {
              return true;
            }
          }
        }
      }
    }

    if (
      this.dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance
    ) {
      if (this.dealDto.LandOrProperty == LandOrPropertyEnum.Land) {
        return true;
      } else {
        if (this.dealDto.OwnOrPurchase == OwnOrPurchaseEnum.Own) {
          if (
            this.dealDto.BuildingCostTodate == null ||
            this.dealDto.BuildingCostTodate == 0
          )
            return true;
        }

        if (
          this.dealDto.IsPropertyImprovementDuringLoanTerm &&
          this.dealDto.IsExistingPlanChange
        ) {
          if (
            this.dealDto.ExistingPlanningChangeType == null ||
            this.dealDto.ExistingPlanningChangeType == 0
          )
            return true;
        }

        if (
          this.dealDto.EndPropertyType == null ||
          this.dealDto.EndPropertyType == "0"
        )
          return true;

        if (this.dealDto.PropertyTenure == null) return true;

        if (
          this.dealDto.IsTenantedBuilding != null &&
          this.dealDto.IsTenantedBuilding
        ) {
          if (
            this.dealDto.BuildingCondition == null ||
            this.dealDto.BuildingCondition == "0"
          )
            return true;
        }
      }
    }

    if (this.dealDto.ProductType == ProductTypeEnum.BridgingDeveloperExit) {
      if (this.dealDto.PropertyTenure == null) return true;
    }

    if (
      (this.dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction ||
        this.dealDto.ProductType ==
          ProductTypeEnum.BridgingPurchaseOrRefinance) &&
      this.dealDto.PropertyTenure == TenureEnum.Leasehold
    ) {
      if (
        this.dealDto.YearsLeftOnLease < 0 ||
        this.dealDto.YearsLeftOnLease == null
      )
        return true;
    }

    return false;
  }

  loanSaveDisabled() {
    var selectFields = [];
    if (
      this.dealDto.ProductType == ProductTypeEnum.BridgingPurchaseOrRefinance
    ) {
      selectFields = [
        this.dealDto.AdditionalSecurity,
        this.dealDto.ExitStrategy,
        this.dealDto.InterestService,
        this.dealDto.LoanTermReq,
        this.dealDto.LoanPurpose,
      ];
    } else {
      selectFields = [
        this.dealDto.AdditionalSecurity,
        this.dealDto.ExitStrategy,
        this.dealDto.InterestService,
        this.dealDto.SourceOfIncome,
        this.dealDto.LoanPurpose,
        this.dealDto.PlanningStatusAtStartOfLoan,
        this.dealDto.PlanningStatusAtEndOfLoan,
      ];
    }

    if (this.loanDetailsForm.$invalid) return true;

    if (
      this.dealDto.LoanPurpose &&
      this.dealDto.LoanPurpose == LoanPurposeEnum.Other &&
      this.dealDto.ProductType == ProductTypeEnum.BridgingPreconstruction
    ) {
      if (
        !this.dealDto.LoanPurposeDescription ||
        this.dealDto.LoanPurposeDescription.length == 0
      ) {
        return true;
      }
    }

    for (let i = 0; i < selectFields.length; i++) {
      if (selectFields[i] === 0 || selectFields[i] === "0") {
        return true;
      }
    }

    return false;
  }

  checkIfUserIsInvited() {
    //added to scope for directive
    this.dealService
      .checkIfUserIsInvited(this.currentUser.Id, this.dealId)
      .then((response: InvitedUserResponse) => {
        // if user has no active subscription (and are not a lender or admin) then they should be redirected back to the dashboard
        if (
          this.currentUser.SubscriptionStatus !=
            LicenseMasterStatusEnum.PaidUp &&
          this.currentUser.SubscriptionStatus !=
            LicenseMasterStatusEnum.PreCancel &&
          this.currentUser.SubscriptionStatus !=
            LicenseMasterStatusEnum.PaymentProcessing &&
          !response.IsInvited &&
          !this.isAdmin &&
          !this.isLender
        ) {
          this.$location.path("/dashboard");
          return;
        }

        (this.$rootScope as any).isInvitedToCase = response.IsInvited;

        if (response.IsInvited) {
          if (response.DealAccessLevel == CaseAccessLevelEnum.ReadOnly) {
            (this.$rootScope as any).isInvitedAndHasReadonlyAccess = true;
          }
        }

        this.$rootScope.$broadcast("invitedStatusChange");
      });
  }

  modifyDateForBackend(property: Date) {
    return this.sharedDealService.modifyDateForBackend(property);
  }

  modifyDateForFrontend(property: Date) {
    return this.sharedDealService.modifyDateForFrontend(property);
  }

  disablePersonalDetails() {
    return this.sharedDealService.disablePersonalDetails(
      this.currentApplicant,
      this.personalDetailsForm,
    );
  }

  goToUserDashboard() {
    this.authService.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  // Setting the dependent data when option are changed
  clearSearchdata() {
    this.dealDto = this.bridgingDealService.clearSearchdata(this.dealDto);
  }

  datasetupOnOwnOrPurchaseChange() {
    this.dealDto = this.bridgingDealService.datasetupOnOwnOrPurchaseChange(
      this.dealDto,
    );
  }

  datasetupOnLandorPropertyChange() {
    this.dealDto = this.bridgingDealService.datasetupOnLandorPropertyChange(
      this.dealDto,
    );
  }

  datasetupOnInterestServiceChange() {
    this.dealDto = this.bridgingDealService.datasetupOnInterestServiceChange(
      this.dealDto,
    );
  }

  datasetupOnIsTenantedBuildingChange() {
    this.dealDto = this.bridgingDealService.datasetupOnIsTenantedBuildingChange(
      this.dealDto,
    );
  }

  datasetupOnEndPropertyTypeChange() {
    this.dealDto = this.bridgingDealService.datasetupOnEndPropertyTypeChange(
      this.dealDto,
    );
  }

  datasetupOnHasCompletionDateChange() {
    this.dealDto = this.bridgingDealService.datasetupOnHasCompletionDateChange(
      this.dealDto,
    );
  }

  datasetupOnMaxLoanRequiredChange() {
    this.dealDto = this.dealService.dataSetupOnMaxLoanRequiredChange(
      this.dealDto,
    );
  }

  updateClassUses() {
    if (this.dealDto.ClassUses > 0) {
      for (let i = 1; i < this.dealDto.ClassUses; i *= 2) {
        if (this.dealDto.ClassUses >= i) {
          //number is in enum sum, add it to object
          if (this.dealDto.ClassUses & i) {
            this.classUses[i] = true;
          }
        }
      }
    }

    //watch and update classuses
    this.$scope.$watchCollection("ctrl.classUses", (newVal: {}) => {
      let valuesList = Object.keys(newVal);
      let total: number = 0;
      //tot up all the numeric keys (class use enums)
      valuesList.forEach((v, i) => {
        let keyToNum = Number(v);
        if (newVal[keyToNum]) {
          total += keyToNum;
        }
      });
      this.dealDto.ClassUses = total;
    });
  }

  onPropertyTabSelection() {
    if (this.dealDto) {
      this.selectedNavMenu = this.BridgingCaseDashboardTypeEnum.Property;
      this.onTabSelection();
    }
  }

  discardDealData(form: ng.IFormController = null) {
    event.stopPropagation();
    if (form == this.propertyDetailsForm) {
      this.dealDto = JSON.parse(JSON.stringify(this.tempDealDto));
      this.PostCodeOptions = [];
      this.searchterm = null;
    } else {
      this.dealDto = JSON.parse(JSON.stringify(this.tempDealDto));
    }

    if (form) {
      form.$setPristine();
      form.$setUntouched();
    }
  }

  onLoanTabSelection() {
    if (this.dealDto) {
      this.selectedNavMenu = this.BridgingCaseDashboardTypeEnum.Loan;
      this.onTabSelection();
    }
  }

  onTabSelection() {
    this.tempDealDto = JSON.parse(JSON.stringify(this.dealDto));
  }

  discardApplicantData(form: ng.IFormController) {
    event.stopPropagation();
    this.currentApplicant = JSON.parse(JSON.stringify(this.tempApplicant));
    form.$setPristine();
    form.$setUntouched();
  }

  onApplicantTabSelection(tab) {
    const str = tab;
    this.tabs[str] = !this.tabs[str];

    this.tempApplicant = JSON.parse(JSON.stringify(this.currentApplicant));
  }

  carriageReturnCheck(event) {
    if (event.key === "Enter") {
      let position = event.target.selectionStart;
      if (this.newNote.NoteText) {
        this.newNote.NoteText = [
          this.newNote.NoteText.slice(0, position),
          "\n",
          this.newNote.NoteText.slice(position),
        ].join("");

        setTimeout(() => {
          event.target.setSelectionRange(position + 1, position + 1);
        }, 0);
      } else {
        this.newNote.NoteText = "\n";
      }
    }
  }

  trustedHtml(plainText) {
    if (this.isClient && plainText.startsWith("Project submitted to lenders")) {
      return this.$sce.trustAsHtml("Project submitted to lenders");
    } else {
      return this.$sce.trustAsHtml(plainText);
    }
  }

  initialiseTitleAndTitleOption() {
    if (this.currentApplicant?.Profile) {
      const { Title, TitleOption } = this.currentApplicant.Profile;

      // If TitleOption has a value greater than 0, set Title to null
      if (
        (TitleOption > TitleEnum.None && TitleOption != TitleEnum.Other) ||
        Title == null
      ) {
        this.currentApplicant.Profile.Title = "";
      }
      // If Title exists and TitleOption is not set, set TitleOption to Other
      else if (Title && !TitleOption) {
        this.currentApplicant.Profile.TitleOption = TitleEnum.Other;
      }
    }
  }

  onTitleOptionChange() {
    const { TitleOption } = this.currentApplicant.Profile;

    if (TitleOption > 0 && TitleOption != TitleEnum.Other) {
      this.currentApplicant.Profile.Title = "";
    }
  }

  getTotalCosts() {
    this.totalCosts =
      Number(this.dealDto.PurchasePrice) +
      Number(this.dealDto.PurchaseCosts) +
      Number(this.dealDto.EstimatedSpending);
    if (isNaN(this.totalCosts)) {
      this.totalCosts = 0;
    }
    return this.totalCosts;
  }

  totalWarningVisible(): boolean {
    if (!this.dealDto.GDV) return false;
    this.getTotalCosts();

    return this.totalCosts > this.dealDto.GDV;
  }

  onPurchaseDateChange() {
    this.minCompletionDate = this.dealDto.OriginalPurchaseDate;
  }

  getProductRoute(): string {
    switch (this.dealDto.ProductType) {
      case ProductTypeEnum.BridgingPreconstruction:
        return "";
      case ProductTypeEnum.BridgingPurchaseOrRefinance:
        return "/PurchaseRefinance";
      case ProductTypeEnum.BridgingDeveloperExit:
        return "/DeveloperExit";
      case ProductTypeEnum.BridgingRefurb:
        return "/Refurbishment";
      default:
        return "";
    }
  }

  onOwnOrPurchaseChange(OwnOrPurchase: boolean) {
    this.dealDto.OwnOrPurchase = this.dealDto.HasOwnOrPurchase
      ? OwnOrPurchaseEnum.Own
      : OwnOrPurchaseEnum.Purchasing;
    this.getPlanningOptions();
    this.datasetupOnOwnOrPurchaseChange();
  }

  onLandOrPropertyChange() {
    this.dealDto.LandOrProperty = this.dealDto.HasLandOrProperty
      ? LandOrPropertyEnum.Property
      : LandOrPropertyEnum.Land;
    this.getPlanningOptions();
    this.datasetupOnLandorPropertyChange();
  }

  onIsFamilyInResidenceChange() {
    this.dealDto.IsFamilyInResidence = this.dealDto.HasIsFamilyInResidence
      ? YesNoMaybe.Yes
      : YesNoMaybe.No;
    if (this.isRegulatedBridging()) {
      this.loanPurposeOptions =
        this.selectListService.GetLoanPurposeOptions(true);
    }
  }

  initialDataSetupForIsFamilyInResidence() {
    if (this.dealDto.HasIsFamilyInResidence) {
      this.dealDto.IsFamilyInResidence = YesNoMaybe.Yes;
      this.dealDto.HasIsFamilyInResidence = true;
    } else {
      this.dealDto.IsFamilyInResidence = YesNoMaybe.No;
      this.dealDto.HasIsFamilyInResidence = false;
    }
  }

  onExistingPlanChange() {
    if (!this.dealDto.IsExistingPlanChange) {
      this.dealDto.ExistingPlanningChangeType =
        ExistingPlanningChangeTypeEnum.None;
    }
  }

  onPropertyImprovementDuringLoanTermChange() {
    if (!this.dealDto.IsPropertyImprovementDuringLoanTerm) {
      this.dealDto.IsExistingPlanChange = false;
      this.dealDto.ExistingPlanningChangeType =
        ExistingPlanningChangeTypeEnum.None;
    }
  }

  onLoanPurposeChange() {
    if (this.dealDto.LoanPurpose != LoanPurposeEnum.Other) {
      this.dealDto.LoanPurposeDescription = null;
    }
  }

  onExitStrategyChange() {
    if (this.dealDto.ExitStrategy != ExitStrategyEnum.Other) {
      this.dealDto.ExitStrategyOther = null;
    }
  }

  onAdditionalSecurityChange() {
    if (!this.dealDto.AdditionalSecurity) {
      this.dealDto.AdditionalSecurityInfo = null;
    }
  }

  onIsMortgagedChange() {
    if (!this.dealDto.IsMortgaged) {
      this.dealDto.MortgageCurrentLender = null;
      this.dealDto.MortgageCurrentBalance = 0;
    }
  }

  onExistingPlanningChangeType() {
    if (
      this.dealDto.ExistingPlanningChangeType ==
      ExistingPlanningChangeTypeEnum.BrandNewPlanningApplication
    ) {
      this.dealDto.PurchasePrice = 0;
      this.dealDto.PurchaseCosts = 0;
      this.dealDto.HasCompletionDate = YesNoMaybe.No;
      this.dealDto.CompletionDate = null;
      this.dealDto.Currentvalue = 0;
    }
  }

  goToLoanTypeSelectionPage() {
    if (this.isLoggedInUser) {
      this.authService.setHasValidStatustoShowShareSearchModal(
        true,
      );
    } 
    this.$location.path("/allloans");
  }

  goToDevFinanceCriteria() {
    if (this.isLoggedInUser) {
      this.$location.path("/devfinancecriteria");
    } else {
      this.$location.path("/e/devfinancecriteria");
    }
  }

  calcContingencyAmount() {
    if (this.dealDto.Contingency && this.dealDto.BuildCosts) {
      this.contingencyAmount =
        this.dealDto.Contingency * this.dealDto.BuildCosts;
    } else {
      this.contingencyAmount = null;
    }
  }

  calculateTotalShareholding(currentapplicant: DealClientDTO) {
    return this.sharedDealService.calculateTotalShareholding(
      currentapplicant,
      this.applicantList,
    );
  }

  getOrganisationbyDealId() {
    this.organisationService
      .getOrganisationAndBrokerByDealId(this.dealDto.Id)
      .then((response) => {
        this.org = response.Organisation;
        this.dealBrokerUser = response.Broker;
        this.brokerCommission =
          this.dealDto.Status == CaseStatusEnum.NewCase
            ? this.org?.CommissionPercent
            : this.dealDto.BrokerCommissionPercent;
        this.brokerPreferredContact =
          this.dealDto.Status == CaseStatusEnum.NewCase
            ? response.Broker.PhoneNumber
            : this.dealDto.BrokerPhoneNumber;
        if (this.org.IsWhiteLabelled) {
          this.fileNamePrefix = this.org.Name.replace(/ /g, "_");
        } else {
          this.fileNamePrefix = "Brickflow";
        }
      });
  }

  updateRequestAssitanceDealNoteMessage() {
    if (this.dealDto?.DealNotes != null && this.currentUser != null) {
      this.dealDto.DealNotes = this.dealDto.DealNotes.map((d) => {
        if (
          d.UserId != this.currentUser.Id &&
          (d.NoteText == "Your have requested for assistance." ||
            d.NoteText == "You have requested assistance.")
        ) {
          return { ...d, NoteText: `${d.UserFullName} requested assistance.` };
        }

        return d;
      });
    }
  }

  getOwnershipPercentageText() {
    if (this.dealDto != null) {
      return this.sharedDealService.getOwnershipPercentageText(
        this.currentApplicant,
        this.dealDto.ProductType,
      );
    }
    return "Shareholding";
  }

  closeSubmitToLenderModal() {
    this.showTsAndCs = false;
    this.brokerCommission =
      this.dealDto.Status == CaseStatusEnum.NewCase
        ? this.org?.CommissionPercent
        : this.dealDto.BrokerCommissionPercent;
    this.brokerPreferredContact =
      this.dealDto.Status == CaseStatusEnum.NewCase
        ? this.dealBrokerUser?.PhoneNumber
        : this.dealDto.BrokerPhoneNumber;
    this.isBrokerPhoneNumberUpdated = false;
  }

  showBasedOnMainResidence() {
    return this.dealService.showBasedOnMainResidence(this.dealDto);
  }

  isRegulatedBridging() {
    if (
      this.dealDto.IsFamilyInResidence == YesNoMaybe.Yes &&
      (this.dealDto.ProductType ==
        ProductTypeEnum.BridgingPurchaseOrRefinance ||
        this.dealDto.ProductType == ProductTypeEnum.BridgingRefurb)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isCompanyAndOwnershipSaveDisabled() {
    if (this.isRegulatedBridging()) {
      return false;
    } else {
      return this.dealDto.HowWillYouOwnNewDevelopment == null ||
        this.dealDto.HowWillYouOwnNewDevelopment <= 0
        ? true
        : false;
    }
  }

  hasMaximumApplicants() {

    return this.applicantList.filter(a => a.IsApplicant).length >= 4 ? true : false;;
  }
}

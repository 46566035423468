import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AuthServiceBase } from "@js/filesfromccqbase/AuthServiceBase";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { RoleService } from "./RoleService";

export class AuthService extends AuthServiceBase<ApplicationUserDTO> {

  isValidStatus: boolean;

  static $inject = [
    "$http",

    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "$httpParamSerializerJQLike",
    "BroadcastService",
    "$location",
    "RoleService",
  ];

  isLoggedIn: boolean = false;
  configSettings: { [key: string]: string }[];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    httpParamSerializerJQLike: ng.IHttpParamSerializer,
    private $broadcastservice: BroadcastService,
    private $location: ng.ILocationService,
    protected roleService: RoleService,
  ) {
    super(http, cookies, rootScope, q, timeout, httpParamSerializerJQLike);
  }

  setProfileCookie(profile: ApplicationUserDTO) {
    this.$cookies.putObject("profile", profile);
  }

  logout(callLogin: boolean = true) {
    //TODO:CHECK THIS possibly meant to be?? this.$cookies.remove("token");
    delete (this.$cookies as any).token;
    delete (this.$cookies as any).loggedUser;
    delete (this.$cookies as any).loggedUserID;
    delete (this.$cookies as any).tokenValidTo;
    delete (this.$cookies as any).access_token;
    this.isLoggedIn = false;
    (this.$rootScope as any).selectedUser = null;
    (this.$rootScope as any).loanCriteria = null; //reset the loancriteria
    (this.$rootScope as any).currentUser = null;
    this.roleService.resetUserRoles(); //clear user roles
    super.logout();
    sessionStorage.clear();
    if (callLogin) {
      this.$location.path("#!/login");
    }
  }

  getIsLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  setIsLoggedIn(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
  }
  getUpdatedProfile(): ng.IPromise<ApplicationUserDTO> {
    let defer = this.$q.defer<ApplicationUserDTO>();

    this.$http
      .get("/api/users/me")
      .then((response) => {
        this.$cookies.putObject("profile", response.data);
        defer.resolve(response.data as ApplicationUserDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getHubspotDeveloperBookMeetingNoSearch(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get("/api/static/gethubspotdeveloperbookmeetingnosearch")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getHubspotDeveloperBookMeetingWithSearch(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get("/api/static/gethubspotdeveloperbookmeetingwithsearch")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getHubspotContactUsBookMeeting(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get("/api/static/gethubspotcontactusbookmeeting")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getWhiteLabelledUrl(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get("/api/static/getwhitelabelledurl")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getShowBridgingLenderSnapshot(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get("/api/static/getshowbridginglendersnapshot")
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getShowBridgingEligibility(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get("/api/static/getshowbridgingeligibility")
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getCompanyContactUsEmailAddresss(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get("/api/static/getcompanycontactusemailaddress")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  isCommercialOwnerOccupiedActive(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get("/api/static/iscommercialowneroccupiedactive")
      .then((response) => {
        defer.resolve((response.data as string).toLowerCase() == "true");
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getReferralContactDetails(): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .get("/api/static/getreferralcontactdetails")
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  clearStorageData() {
    sessionStorage.removeItem("userRole");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("assignUserToOrg");
    sessionStorage.removeItem("isUpgradedUser");
    sessionStorage.removeItem("skip");
    sessionStorage.removeItem("projectname");
    sessionStorage.removeItem("selectedPackage");
    sessionStorage.removeItem("returningFromdeletedSearch");
    sessionStorage.removeItem("addAddon");
    sessionStorage.removeItem("selectedProduct");
    sessionStorage.removeItem("searchuser");
    sessionStorage.removeItem("clientId");
    sessionStorage.removeItem("lenderTask");
    sessionStorage.removeItem("loanCriteria");
    sessionStorage.removeItem("DealClientUniqueRef");
    sessionStorage.removeItem("TempSavedResults");
    sessionStorage.removeItem("SelectedUser");
    sessionStorage.removeItem("UniqueId");
    sessionStorage.removeItem("loanCriteria");
    sessionStorage.removeItem("LoanCriteria");
    sessionStorage.removeItem("ComparisonList");
    sessionStorage.removeItem('previousRoute');
  }

  clearSelectedNavMenuAndFilterStorageData() {
    sessionStorage.removeItem("selectedNavMenu");
    sessionStorage.removeItem("clientCaseNameFilter");
    sessionStorage.removeItem("statusFilter");
  }

  getHasValidStatustoShowShareSearchModal() {
    return this.isValidStatus;
  }

  setHasValidStatustoShowShareSearchModal(isValidStatus) {
    this.isValidStatus = isValidStatus;
  }
}

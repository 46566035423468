import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { RoleService } from "@js/services/RoleService";
import { UserService } from "@js/services/UserService";

export class IntroducerController {
  firstTime: boolean = false;

  static $inject = [
    "$routeParams",
    "$rootScope",
    "$location",
    "DevelopmentInputService",
    "UserService",
    "CaseService",
    "RoleService",
    "AuthService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $DevelopmentInputservice: DevelopmentInputService,
    private $user: UserService,
    private $CaseService: CaseService,
    private roleService: RoleService,
    private authService: AuthService,
  ) {
    if (this.$routeParams.status === "new") {
      this.firstTime = true;
    }
  }

  continueToLogin() {
    (this.$rootScope as any) = null;
    this.go("/login");
  }

  go(path): void {
    this.$location.path(path);
  }
}

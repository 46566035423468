import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseService } from "@js/services/CaseService";
import { RoleService } from "@js/services/RoleService";

export class WelcomeController {
  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "CaseService",
    "AuthService",
    "RoleService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private caseService: CaseService,
    private authService: AuthService,
    private roleService: RoleService,
  ) {
    if (this.$cookies.get("access_token")) {
      $location.path("/userdashboard");
    }
  }

  quickSearch() {
    this.$location.path("/criteria/0/0/1");
  }

  startAppraisal() {
    if (this.$cookies.get("access_token")) {
      this.createNewBlankCase();
    } else {
      // Register the user first before creating a blank case
      this.$location.path("/register/startprojectappraisal");
    }
  }

  applyForDevLoan() {
    if (this.$cookies.get("access_token")) {
      this.createNewBlankCase(true);
    } else {
      // Register the user first before creating a blank case
      this.$location.path("/register/applyfordevloan");
    }
  }

  goToFindLoan() {
    this.$location.path("/criteria/0/0/1");
  }

  createNewBlankCase(applyForDevLoan: boolean = false) {
    this.authService.getProfile().then((prof) => {
      var currentUser: ApplicationUserDTO = prof;
      this.roleService.GetUserRoles().then((result) => {
        var isPrimaryApplicant: boolean = true;

        if (result.filter((x) => x == "Broker").length > 0) {
          isPrimaryApplicant = false;
        }

        this.caseService
          .newBlankCase(currentUser, isPrimaryApplicant)
          .then((newBlankCaseDto) => {
            if (newBlankCaseDto && newBlankCaseDto.NewCaseId) {
              // If applying for a development loan then go to the case dashboard of the new case
              if (applyForDevLoan == true) {
                this.$location.path(
                  "/casedashboard/" + newBlankCaseDto.NewCaseId,
                );
              } else {
                // If starting a project appraisal then go directly to the appraisal module of the new case
                this.$location.path(
                  "/criteriaappraisal/" +
                    newBlankCaseDto.NewCaseId +
                    "/" +
                    newBlankCaseDto.NewSearchId,
                );
              }
            }
          });
      });
    });
  }
}

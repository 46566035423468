import { AccountDTO } from "@js/DTO/AccountDTO.cs.d";
import { WebConfigDTO } from "@js/DTO/WebConfigDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";

export class AccountService extends BaseService<AccountDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/account";
    this.$broadcastBusinessNameSingular = "Account";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }
  SendLoansAdvertEmail(
    name: string,
    from: string,
    To: string,
    themessage: string,
  ): ng.IPromise<boolean> {
    themessage = themessage.replace(/\n/g, "<br />");
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/sendloansadvertemail?name=" +
          name +
          "&from=" +
          from +
          "&To=" +
          To +
          "&themessage=" +
          themessage,
      )
      .then((response) => {
        let results: boolean = response.data as boolean;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  SendFriendReferralEmail(
    username: string,
    usersurname: string,
    useremail: string,
    friendname: string,
    friendsurname: string,
    friendemail: string,
    friendbody: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/sendfriendreferralemail?username=" +
          username +
          "&usersurname=" +
          usersurname +
          "&useremail=" +
          useremail +
          "&friendname=" +
          friendname +
          "&friendsurname=" +
          friendsurname +
          "&friendemail=" +
          friendemail +
          "&friendbody=" +
          friendbody,
      )
      .then((response) => {
        let results: boolean = response.data as boolean;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  RequestAssistanceReview(
    reason: string,
    caseid: number,
    productFamily: ProductFamilyEnum = ProductFamilyEnum.None,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(
        this.$baseAddress +
          "/requestassistancereview?reason=" +
          reason +
          "&caseid=" +
          caseid +
          "&productFamily=" +
          productFamily,
      )
      .then((response) => {
        let results: boolean = response.data as boolean;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  SendIntroducerRegistrationEmail(
    introducerEmail: string,
    introducerFirstName: string,
    introducerCode: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodeUserName = encodeURIComponent(introducerEmail);
    this.$http
      .get(
        this.$baseAddress +
          "/sendintroducerregistrationemail?introducerEmail=" +
          encodeUserName +
          "&introducerFirstName=" +
          introducerFirstName +
          "&introducerCode=" +
          introducerCode,
      )
      .then((response) => {
        let results: boolean = response.data as boolean;
        defer.resolve(results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getFooterWebConfigValue(): ng.IPromise<WebConfigDTO> {
    let defer = this.$q.defer<WebConfigDTO>();

    this.$http
      .get(this.$baseAddress + "/getfooterwebconfigvalue")
      .then((response) => {
        defer.resolve(response.data as WebConfigDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "getfooterwebconfigvalue",
          "web config Value failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}

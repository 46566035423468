import { CommercialLenderResultSummaryDTO } from "@js/DTO/SearchResults/CommercialLenderResultSummaryDTO.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { CommercialDealService } from "@js/services/Deal/CommercialDealService";
import { OrganisationService } from "@js/services/OrganisationService";
import { ProductService } from "@js/services/ProductService";

export class CommercialComparisonController {
  isLoading: boolean;
  selectedCandidates: CommercialLenderResultSummaryDTO[];

  //Lender Logos
  showLenderLogo: boolean = false;

  dealId: number;
  uniqueid: string;
  criteria: {} = {};

  //This needs to be set to true as we are using a same view.
  isDeal: boolean = true;

  productLastVerifiedDates = {};

  static $inject = [
    "$scope",
    "$rootScope",
    "$location",
    "$http",
    "OrganisationService",
    "CommercialDealService",
    "$routeParams",
    "ProductService"
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private organisationService: OrganisationService,
    private commercialDealService: CommercialDealService,
    private $routeParams: ng.route.IRouteParamsService,
    private productService: ProductService
  ) {
    this.showOrHideLenderLogo();

    if (this.$routeParams.DealId) {
      this.dealId = this.$routeParams.DealId;
    }

    //if ((this.$rootScope as any).selectedUser || sessionStorage.getItem('SelectedUser')) {
    //    if ((this.$rootScope as any).selectedUser) {
    //        this.selectedUser = (this.$rootScope as any).selectedUser;
    //        sessionStorage.setItem('SelectedUser', JSON.stringify(this.selectedUser));
    //    } else if (sessionStorage.getItem('SelectedUser')) {
    //        this.selectedUser = JSON.parse(sessionStorage.getItem('SelectedUser'));
    //    }
    //}

    if (
      sessionStorage.getItem("ComparisonList") &&
      sessionStorage.getItem("LoanCriteria")
    ) {
      this.selectedCandidates = JSON.parse(
        sessionStorage.getItem("ComparisonList"),
      );
      this.criteria = JSON.parse(sessionStorage.getItem("LoanCriteria"));
      const productIds = this.selectedCandidates.map((product) => product.ProductID);
      this.getProductLastVerifiedDates(productIds);
    } else {
      this.goToResults();
    }
  }

  showOrHideLenderLogo() {
    this.organisationService.showLenderNamesAndLogos().then((result) => {
      this.showLenderLogo = result;
    });
  }

  goToResults(): void {
    sessionStorage.setItem(
      "ComparisonList",
      JSON.stringify(this.selectedCandidates),
    );

    this.commercialDealService.fetch(this.dealId).then((result) => {
      if (
        result.Status == CaseStatusEnum.ReadyToReSubmit ||
        result.Status == CaseStatusEnum.SubmittedToLendersForHoT
      ) {
        this.$location.path("/commercialshortlistmore/" + this.dealId);
      } else {
        this.$location.path("/commercialresults/" + this.dealId);
      }
    });
  }

  getProductLastVerifiedDates(productIds: number[]) {
    this.productService.getLastVerifiedDates(productIds).then((response) => {
      productIds.forEach(id => {
        if (response[id] != null) {
          this.productLastVerifiedDates[id] = this.formatDate(response[id]);
        }   
      })
    });
  }

  formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };
}

export const enum EventLogEnum {
  None = 0,
  PageLoad = 1,
  Save = 2,
  Shortlisting = 3,
  Export = 4,
  LenderReferral = 5,
  EnterpriseUserRegistration = 6,
  EnterpriseUserLogin = 7,
}

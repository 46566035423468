import { CaseMemberDTO } from "@js/DTO/CaseMemberDTO.cs.d";
import { SecurityCheckMessageRequest } from "@js/DTO/Messages/SecurityCheckMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class CaseMemberService extends BaseService<CaseMemberDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/applicant";
    this.$broadcastBusinessNameSingular = "applicant";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchByCaseId(CaseId: number): ng.IPromise<CaseMemberDTO[]> {
    let defer = this.$q.defer<CaseMemberDTO[]>();
    this.$http
      .get(this.$baseAddress + "/fetchByCaseId?CaseId=" + CaseId)
      .then((response) => {
        defer.resolve(response.data as CaseMemberDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchbycaseID",
          "Fetchby case id failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  inviteApplicant(uniqueId: string): ng.IPromise<CaseMemberDTO> {
    let defer = this.$q.defer<CaseMemberDTO>();
    this.$http
      .get(this.$baseAddress + "/inviteApplicant?uniqueId=" + uniqueId)
      .then((response) => {
        defer.resolve(response.data as CaseMemberDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "inviteApplicant",
          "inviteApplicant failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  securityCheck(
    uniqueId: string,
    phone?: string,
    postcode?: string,
    dob?: Date,
    orgUniqueRef?: string,
    brokerUserId?: string,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    var request = {
      UniqueId: uniqueId,
      PhoneNumber: phone,
      PostCode: postcode,
      DateOfBirth: dob,
      OrgUniqueRef: orgUniqueRef,
      BrokerUserId: brokerUserId,
    } as SecurityCheckMessageRequest;

    this.$http
      .post(this.$baseAddress + "/securityCheck", request)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "securityCheck",
          "securityCheck failed",
        );
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   * Get the security questions that have been answered
   * @param caseMemberId
   */
  getSecurityQuestions(uniqueId: string) {
    let defer = this.$q.defer<string>();

    this.$http
      .get(this.$baseAddress + "/getsecurityquestions?uniqueId=" + uniqueId)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "getsecurityquestions",
          "getsecurityquestions failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   *Get the email for the shareholder
   * @param caseMemberId
   */
  fetchCaseMemberAndReturnEmail(uniqueId: string) {
    let defer = this.$q.defer<string>();

    this.$http
      .get(
        this.$baseAddress +
          "/fetchCaseMemberAndReturnEmail?uniqueId=" +
          uniqueId,
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchCaseMemberAndReturnEmail",
          "fetchCaseMemberAndReturnEmail failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   *To copy all shareholders from a copied case
   * @param CaseId and DevelopmentInputId
   */
  copyShareholder(caseId: number, newCaseId: number) {
    let defer = this.$q.defer<string>();

    this.$http
      .get(
        this.$baseAddress +
          "/copyShareholder?caseId=" +
          caseId +
          "&newCaseId=" +
          newCaseId,
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "copyShareholder",
          "copyShareholder failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   *fetch shareholder by uniqueId
   * @param uniqueId and DevelopmentInputId
   */
  fetchCaseMember(uniqueId: string): ng.IPromise<CaseMemberDTO> {
    let defer = this.$q.defer<CaseMemberDTO>();

    this.$http
      .get(
        this.$baseAddress +
          "/FetchCaseMemberAndReturnRegisterInformation?uniqueId=" +
          uniqueId,
      )
      .then((response) => {
        defer.resolve(response.data as CaseMemberDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "FetchCaseMemberAndReturnRegisterInformation",
          "FetchCaseMemberAndReturnRegisterInformation failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  markAsDeleteAndSetNextCaseMenmberAsPrimary(
    caseId: number,
    caseMemberId: number,
  ): ng.IPromise<CaseMemberDTO[]> {
    let defer = this.$q.defer<CaseMemberDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/MarkAsDeleteAndSetNextCaseMemberAsPrimary?caseId=" +
          caseId +
          "&caseMemberId=" +
          caseMemberId,
      )
      .then((response) => {
        defer.resolve(response.data as CaseMemberDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchbycaseID",
          "Fetchby case id failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  saveAddressHistory(
    caseMemberId: number,
    addressHistory: string,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .put(
        this.$baseAddress + "/saveaddresshistory?caseMemberId=" + caseMemberId,
        JSON.stringify(addressHistory),
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "saveAddressHistory",
          "Save Address History failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  getWebConfigValue(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(this.$baseAddress + "/getWebConfigValue")
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "getWebConfigValue",
          "web config Value failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchcasememberswithaccess(caseId: number): ng.IPromise<CaseMemberDTO[]> {
    let defer = this.$q.defer<CaseMemberDTO[]>();
    this.$http
      .get(this.$baseAddress + "/fetchcasememberswithaccess?caseId=" + caseId)
      .then((response) => {
        defer.resolve(response.data as CaseMemberDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchcasememberswithaccess",
          "fetchcasememberswithaccess failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}

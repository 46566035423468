import { DevelopmentWithNoLoginDTO } from "@js/DTO/DevelopmentWithNoLoginDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class DevelopmentInputWithNoLoginService extends BaseService<
  DevelopmentWithNoLoginDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/developmentinputwithnologin";
    this.$broadcastBusinessNameSingular = "DevelopmentInputwithnologin";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchByIntroducerId(
    IntroducerId: number,
  ): ng.IPromise<DevelopmentWithNoLoginDTO[]> {
    let defer = this.$q.defer<DevelopmentWithNoLoginDTO[]>();
    this.$http
      .get(
        this.$baseAddress + "/fetchByIntroducerId?IntroducerId=" + IntroducerId,
      )
      .then((response) => {
        defer.resolve(response.data as DevelopmentWithNoLoginDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchByUniqueId(UniqueId: string): ng.IPromise<DevelopmentWithNoLoginDTO> {
    let defer = this.$q.defer<DevelopmentWithNoLoginDTO>();
    this.$http
      .get(this.$baseAddress + "/fetchByUniqueId?UniqueId=" + UniqueId)
      .then((response) => {
        defer.resolve(response.data as DevelopmentWithNoLoginDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendEmailToNewUser(
    uniqueId: string,
    showLenderNameValue: boolean = false,
    updateshowLenderName: boolean = false,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .get(
        this.$baseAddress +
          "/sendemailtonewuser?uniqueId=" +
          uniqueId +
          "&showLenderNameValue=" +
          showLenderNameValue +
          "&updateshowLenderName=" +
          updateshowLenderName,
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  saveChangesToReferredSearch(
    dto: DevelopmentWithNoLoginDTO,
    uniqueId: string,
    sendEnterpriseEmail: boolean = false,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    this.$http
      .post(
        this.$baseAddress +
          "/savechangestoreferredsearch?uniqueId=" +
          uniqueId +
          "&sendEnterpriseEmail=" +
          sendEnterpriseEmail,
        JSON.stringify(dto, this.jsonPropertyReplacer),
      )
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "savesearch",
          "Save search failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  isResultActive(UniqueId: string): ng.IPromise<DevelopmentWithNoLoginDTO> {
    let defer = this.$q.defer<DevelopmentWithNoLoginDTO>();
    this.$http
      .get(this.$baseAddress + "/isresultactive?UniqueId=" + UniqueId)
      .then((response) => {
        defer.resolve(response.data as DevelopmentWithNoLoginDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchDataByUniqueId(
    UniqueId: string,
  ): ng.IPromise<DevelopmentWithNoLoginDTO> {
    let defer = this.$q.defer<DevelopmentWithNoLoginDTO>();
    this.$http
      .get(this.$baseAddress + "/fetchDataByUniqueId?UniqueId=" + UniqueId)
      .then((response) => {
        defer.resolve(response.data as DevelopmentWithNoLoginDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  addClientToSearch(
    dto: DevelopmentWithNoLoginDTO,
    uniqueId: string,
    clientId: number,
  ): ng.IPromise<DevelopmentWithNoLoginDTO> {
    let defer = this.$q.defer<DevelopmentWithNoLoginDTO>();
    this.$http
      .post(
        this.$baseAddress +
          "/addclienttosearch?uniqueId=" +
          uniqueId +
          "&clientId=" +
          clientId,
        JSON.stringify(dto, this.jsonPropertyReplacer),
      )
      .then((response) => {
        defer.resolve(response.data as DevelopmentWithNoLoginDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "AddClientTosearch",
          "attach client to search failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }
}

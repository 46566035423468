import { RoleDTO } from "@js/DTO/RoleDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class RoleService extends BaseService<RoleDTO, string> {
  userroles: string[] = null;
  public IsAdminUser: boolean = false;
  public IsLenderUserOrAbove: boolean = false;
  public IsBrokerUserOrAbove: boolean = false;
  public IsClientUserOrAbove: boolean = false;
  public IsLender: boolean = false;
  public IsIntroducer: boolean = false;
  public IsClient: boolean = false;
  public IsLenderVisible: boolean = false;
  public IsBroker: boolean = false;
  public isTestBannerHidden: boolean = false;

  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
    "$location",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    private rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
    private $location: ng.ILocationService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/roles";
    this.$broadcastBusinessNameSingular = "Role";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  public UpdateUserRoles(userRoles: string[]): void {
    this.userroles = userRoles;
    this.rootScope.$broadcast("userRolesChange", userRoles);
  }

  public GetUserRoles(force: boolean = false): ng.IPromise<string[]> {
    let defer = this.$q.defer<string[]>();

    if (!this.userroles || force) {
      this.GetRolesIamAMemberOf()
        .then((result) => {
          this.UpdateUserRoles(result);
          // Changed else ifs to ifs because a user can have more than one role!
          if (result.filter((x) => x == "Admin").length > 0) {
            this.IsAdminUser = true;
            this.IsClientUserOrAbove = true;
            this.IsLenderUserOrAbove = true;
          }
          if (result.filter((x) => x == "Lender").length > 0) {
            this.IsLender = true;
            this.IsLenderUserOrAbove = true;
            this.IsClientUserOrAbove = true;
          }
          if (result.filter((x) => x == "Introducer").length > 0) {
            this.IsIntroducer = true;
            this.IsClientUserOrAbove = true;
          }
          if (result.filter((x) => x == "Client").length > 0) {
            this.IsClient = true;
            this.IsClientUserOrAbove = true;
          }
          if (result.filter((x) => x == "Broker").length > 0) {
            this.IsBroker = true;
            this.IsBrokerUserOrAbove = true;
          }
          defer.resolve(result);
        })
        .catch(() => {
          this.UpdateUserRoles([]);
          defer.reject(false);
        });
    } else {
      defer.resolve(this.userroles);
    }

    return defer.promise;
  }

  GetRolesIamAMemberOf(): ng.IPromise<string[]> {
    let defer = this.$q.defer<string[]>();
    this.$http
      .get(this.$baseAddress + "/getrolesiammemberof", null)
      .then((response) => {
        defer.resolve(response.data as string[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    //UserRoleEnum.Lender
    return defer.promise;
  }

  isAdminUser(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (result.filter((x) => x == "Admin").length > 0) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }
  isBrokerOrABove(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (result.filter((x) => x == "Broker" || x == "Admin").length > 0) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }

  isBroker(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (result.filter((x) => x == "Broker").length > 0) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }

  isIntroducer(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (result.filter((x) => x == "Introducer" || x == "Admin").length > 0) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }
  isClientUser(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (result.filter((x) => x == "Client").length > 0) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }
  isClientUserOrAbove(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (result.filter((x) => x == "Client" || x == "Admin").length > 0) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }
  isLenderUserOrAbove(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (result.filter((x) => x == "Lender" || x == "Admin").length > 0) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }
  isLenderUser(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (result.filter((x) => x == "Lender").length > 0) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }

  isIntroducerOnly(): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.GetUserRoles().then((result) => {
      if (
        result.filter((x) => x == "Introducer").length > 0 &&
        result.filter((x) => x == "Broker").length == 0 &&
        result.filter((x) => x == "Client").length == 0 &&
        result.filter((x) => x == "Admin").length == 0
      ) {
        defer.resolve(true);
      }
      defer.resolve(false);
    });

    return defer.promise;
  }

  goHomeBasedOnUser() {
    this.isClientUser().then((result) => {
      if (result) {
        this.$location.path("/userdashboard");
      } else {
        this.isLenderUser().then((result2) => {
          if (result2) {
            this.$location.path("/userdashboard");
          } else {
            this.$location.path("/dashboard");
          }
        });
      }
    });
  }

  getIsLenderVisible() {
    return this.IsLenderVisible;
  }

  setIsLenderVisible(isLenderVisible: boolean) {
    this.IsLenderVisible = isLenderVisible;
  }

  resetUserRoles() {
    this.userroles = null;
    this.IsAdminUser = false;
    this.IsLenderUserOrAbove = false;
    this.IsBrokerUserOrAbove = false;
    this.IsClientUserOrAbove = false;
    this.IsLender = false;
    this.IsIntroducer = false;
    this.IsClient = false;
    this.IsLenderVisible = false;
    this.IsBroker = false;
  }
}

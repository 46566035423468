export const enum CaseStatusEnum {
  Search = 999,
  NewCase = 0,
  InProgress = 1,
  UnderReview = 2,
  ReadyToSubmit = 3,
  SubmittedToLendersForHoT = 4,
  ReadyToReSubmit = 12,
  Applied = 5,
  CreditApproved = 10,
  WithProfessionals = 11,
  Completed = 6,
  Dormant = 7,
  SubmittedToLendersPendingReview = 9,
}

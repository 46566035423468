import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { IntroducerSimpleDTO } from "@js/DTO/IntroducerSimpleDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { LocationEnum } from "@js/models/enum/LocationEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { IntroducerSimpleService } from "@js/services/IntroducerSimpleService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";
import { Utils } from "@js/Utils";

export class ProfileController {
  profile: ApplicationUserDTO = null;
  profileEdit: ApplicationUserDTO = null;

  // Booleans to control which fields to display in the edit modal
  showEditModal: boolean = false;
  isEditIntro: boolean = false;
  isEditMiniBio: boolean = false;
  isEditOrganisation: boolean = false;
  isEditPassword: boolean = false;
  isEditContactPrefs: boolean = false;
  isEditSystemSettings: boolean = false;

  // Edit password
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;

  // manage errors
  error: boolean = false;
  errorMessage: string;

  editTitle: string = "";

  // Get all introducers
  introducerList: IntroducerSimpleDTO[];
  userIntroducer: IntroducerSimpleDTO;
  userHasIntroducer: boolean = false;

  //get all organisations
  organisations: OrganisationDTO[];
  userOrganisation: OrganisationDTO;

  // This is the toggle to open the file upload modal
  openModal: boolean = false;

  profileForm: FormData;

  fullAvatarUrl: string = "";

  loggedIn: boolean = false;

  tinymceOptions: any;
  showSystemSetting: boolean = false;

  referrerOptions = [];
  locationOptions = [];

  static $inject = [
    "$http",
    "AuthService",
    "UserService",
    "FileAttachmentService",
    "IntroducerSimpleService",
    "OrganisationService",
    "RoleService",
    "PaymentService",
    "$location",
    "$cookies",
    "SelectListService",
    "RoleService",
  ];

  constructor(
    private $http: ng.IHttpService,
    private authService: AuthService,
    private userService: UserService,
    private fileAttachmentService: FileAttachmentService,
    private introducerSimpleService: IntroducerSimpleService,
    private organisationService: OrganisationService,
    private $roleService: RoleService,
    private $paymentService: PaymentService,
    private $location: ng.ILocationService,
    private $cookies: ng.cookies.ICookiesService,
    private selectListService: SelectListService,
    private roleService : RoleService
  ) {
    this.tinymceOptions = {
      content_style:
        "body {color: #304b9a;font:300 13.33px Roboto,sans-serif;}",
      menubar: false,
      height: 300,
      branding: false,
      statusbar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | " +
        "bold italic backcolor forecolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | preview fullscreen |" +
        "removeformat | help",
    };

    if (
      this.$cookies.get("access_token") != null &&
      this.$cookies.get("access_token") != undefined
    ) {
      this.loggedIn = true;

      this.userService
        .getcurentuserrecord()
        .then((profile: ApplicationUserDTO) => {
          this.profile = profile;
          if (this.$location.path().startsWith("/managepreferences") && this.roleService.IsLender == true) {
            this.editContactPreferences();
          }
          this.profileEdit = Utils.deepCopy(this.profile);
          this.showSystemSetting = profile.IsOrganisationAdmin;
          this.getFileURL(this.profile.ProfilePictureUrl);
          // Get the introducer, if there is one
          this.introducerSimpleService.fetchAll().then((response) => {
            this.introducerList = response;
            if (this.profile && this.profile.IntroducerId) {
              this.userHasIntroducer = true;
              this.userIntroducer = this.introducerList.find(
                (x) => x.Id == this.profile.IntroducerId,
              );
            }
          });
          this.organisationService.fetchAll().then((response) => {
            this.organisations = response;

            if (this.profile && this.profile.OrganisationId) {
              this.userOrganisation = this.organisations.find(
                (x) => x.Id == this.profile.OrganisationId,
              );
            }
          });
        });
    }

    this.referrerOptions = this.selectListService.GetReferrerOptions();
    this.locationOptions = this.selectListService.GetLocations();
  }

  /** Close the edit modal */
  closeEditModal() {
    this.showEditModal = false;
    this.isEditIntro = false;
    this.isEditMiniBio = false;
    this.isEditPassword = false;
    this.isEditContactPrefs = false;
    this.editTitle = "";
    this.isEditOrganisation = false;
    this.isEditSystemSettings = false;

    this.profileEdit = null;
    delete this.currentPassword;
    delete this.newPassword;
    delete this.repeatPassword;
    this.error = false;
  }

  /** Save the profile changes */
  saveProfile() {
    this.userService.addUpdate(this.profileEdit).then((userResponse) => {
      // TODO - JLH - I don't like that I'm getting the profile again - but the addupdate function isn't returning the roles in the user dto which causes them to disappear if you save again.
      this.userService
        .getcurentuserrecord()
        .then((profile: ApplicationUserDTO) => {
          this.profile = profile;

          this.getFileURL(this.profile.ProfilePictureUrl);
          // Update the profile cookie so that the rest of the application can be aware of the profile changes
          this.authService.setProfileCookie(this.profile);
        });

      this.closeEditModal();
    });
  }

  /** Edit the mini bio */
  editMiniBio() {
    this.isEditMiniBio = true;
    this.editTitle = "Edit Mini Bio";
    this.openEditModal();
  }

  /** Edit the intro details */
  editIntro() {
    this.isEditIntro = true;
    this.editTitle = "Edit Intro";
    this.openEditModal();
  }

  /** Edit the default organisation details */
  editDefaultOrganisation() {
    this.isEditOrganisation = true;
    this.editTitle = "Edit default broker details";
    this.openEditModal();
  }

  /** Open the edit modal */
  openEditModal() {
    // Make a copy of the main profile object so that the main page doesn't get updated at the same time
    this.profileEdit = Utils.deepCopy(this.profile);
    this.showEditModal = true;
  }

  /** Returns the location text based on the profile's Location enum */
  getLocationName(): string {
    var locationName: string = "";

    if (this.profile) {
      switch (this.profile.Location) {
        case LocationEnum.London:
          locationName = "London";
          break;
        case LocationEnum.NorthWest:
          locationName = "North West";
          break;
        case LocationEnum.Midlands:
          locationName = "Midlands";
          break;
        case LocationEnum.EastofEngland:
          locationName = "East of England";
          break;
        case LocationEnum.SouthEast:
          locationName = "South East";
          break;
        case LocationEnum.SouthWest:
          locationName = "South West";
          break;
        case LocationEnum.NorthEast:
          locationName = "North East";
          break;
        case LocationEnum.Wales:
          locationName = "Wales";
          break;
        case LocationEnum.Scotland:
          locationName = "Scotland";
          break;
        case LocationEnum.NorthernIreland:
          locationName = "Northern Ireland";
          break;
        case LocationEnum.ChannelIsland:
          locationName = "Channel Islands";
          break;
        default:
          locationName = "";
      }
    }

    return locationName;
  }

  /**
   * Called when a profile picture has been selected
   * @param files
   */
  onFileSelect(files) {
    this.fileAttachmentService.uploadFileAndReturnURI(files).then((result) => {
      // Make a copy of the main profile object so that the main page doesn't get updated at the same time
      this.profileEdit = Utils.deepCopy(this.profile);
      //this.profileEdit = { ...this.profile };

      this.profileEdit.ProfilePictureUrl = result.FileLocation;
      this.saveProfile();
      this.getFileURL(this.profileEdit.ProfilePictureUrl);
    });
  }

  /**
   * Gets the full url for the url specified
   * @param url
   */
  getFileURL(url: string) {
    this.fileAttachmentService.getFileUri(url).then((result) => {
      this.fullAvatarUrl = result;
    });
  }

  /**
   * return the default organisation selected
   */
  getSelectedDefaultOrganisationName(id: number) {
    if (this.organisations) {
      var find = this.organisations.find((x) => x.Id == id);
    }

    if (find) {
      return find.Name;
    } else {
      return "none";
    }
  }

  /** Edit the current password */
  editPassword() {
    this.isEditPassword = true;
    this.editTitle = "Edit password";
    this.openEditModal();
  }

  // change passwords
  applyPasswordChange() {
    this.error = false;
    if (
      this.newPassword &&
      this.repeatPassword &&
      this.newPassword === this.repeatPassword
    ) {
      this.authService
        .changePassword(this.currentPassword, this.newPassword)
        .then((response) => {
          delete this.currentPassword;
          delete this.newPassword;
          delete this.repeatPassword;
        })
        .catch(() => {
          this.error = true;
          this.errorMessage = "Current password incorrect. Please try again";
        })
        .finally(() => {
          if (!this.error) {
            this.showEditModal = false;
          }
        });
    }
  }

  editContactPreferences() {
    this.isEditContactPrefs = true;
    this.editTitle = "Edit Contact Preferences";
    this.openEditModal();
  }

  editSystemSettings() {
    this.isEditSystemSettings = true;
    this.editTitle = "Edit System settings";
    this.openEditModal();
  }

  // We no loger need it as we are not useing plantype for organisation.
  /* isBasicMode() {
            if (this.userOrganisation != null) {
              return  this.userOrganisation.PlanType == PlanTypeEnum.Basic;
            } else {
               return false;
            }
        }*/

  manageLicence() {
    this.$location.path("/settings");
  }

  viewLicense() {
    this.$location.path("/license/1"); // + this.profile.LicenseMasterId);
  }
}

import angular from "angular";
import { AccountService } from "./services/AccountService";
import { AccountSettingService } from "./services/AccountSettingsService";
import { ApplicantService } from "./services/ApplicantService";
import { AssetLiabilityItemService } from "./services/AssetLiabilityItemService";
import { AuthService } from "./services/AuthService";
import { BridgingCaseService } from "./services/BridgingCaseService";
import { BridgingSearchService } from "./services/BridgingSearchService";
import { CaseLenderMessageService } from "./services/CaseLenderMessageService";
import { CaseLenderService } from "./services/CaseLenderService";
import { CaseMemberService } from "./services/CaseMemberService";
import { CaseNoteService } from "./services/CaseNoteService";
import { CaseService } from "./services/CaseService";
import { CaseSummaryService } from "./services/CaseSummaryService";
import { ClientService } from "./services/ClientService";
import { ComparablePropertyService } from "./services/ComparablePropertyService";
import { BridgingDealService } from "./services/Deal/BridgingDealService";
import { CommercialDealService } from "./services/Deal/CommercialDealService";
import { CommercialSearchResultsService } from "./services/Deal/CommercialSearchResultsService";
import { DealLenderMessageService } from "./services/Deal/DealLenderMessageService";
import { DealLenderService } from "./services/Deal/DealLenderService";
import { DealNoteService } from "./services/Deal/DealNoteService";
import { DealScheduleService } from "./services/Deal/DealScheduleService";
import { DealService } from "./services/Deal/DealService";
import { DevFinanceDealService } from "./services/Deal/DevFinanceDealService";
import { DevFinanceSearchResultsService } from "./services/Deal/DevFinanceSearchResultsService";
import { SharedCriteriaService } from "./services/Deal/SharedCriteriaService";
import { SharedDealService } from "./services/Deal/SharedDealService";
import { SharedSearchResultsService } from "./services/Deal/SharedSearchResultsService";
import { DealClientService } from "./services/DealClientService";
import { DealCompletionService } from "./services/DealCompletionService";
import { DealFileAttachmentService } from "./services/DealFileAttachmentService";
import { DevelopmentExperienceService } from "./services/DevelopmentExperienceService";
import { DevelopmentInputScheduleService } from "./services/DevelopmentInputScheduleService";
import { DevelopmentInputService } from "./services/DevelopmentInputService";
import { DevelopmentInputWithNoLoginService } from "./services/DevelopmentInputWithNoLoginService";
import { DevelopmentTrackRecordService } from "./services/DevelopmentTrackRecordService";
import { DeveloperExitFinanceDevelopmentInputService } from "./services/DevExitFinance/DeveloperExitFinanceDevelopmentInputService";
import { DeveloperExitFinanceDevScheduleInputService } from "./services/DevExitFinance/DeveloperExitFinanceDevScheduleInputService";
import { DeveloperExitFinanceLoanInputService } from "./services/DevExitFinance/DeveloperExitFinanceLoanInputService";
import { DeveloperExitFinanceResultService } from "./services/DevExitFinance/DeveloperExitFinanceResultService";
import { DevFinanceScheduleService } from "./services/DevFinanceScheduleService";
import { EventLogService } from "./services/EventLogService";
import { ExternalLinksService } from "./services/ExternalLinksService";
import { FeaturePriceService } from "./services/FeaturePriceService";
import { FeedBackService } from "./services/FeedBackService";
import { FileAttachmentService } from "./services/FIleAttachmentService";
import { IntroducerService } from "./services/IntroducerService";
import { IntroducerSimpleService } from "./services/IntroducerSimpleService";
import { InviteService } from "./services/InviteService";
import { LenderResultMinimalService } from "./services/LenderResultMinimalService";
import { LenderResultService } from "./services/LenderResultService";
import { LenderService } from "./services/LenderService";
import { LicenseService } from "./services/LicenseService";
import { ModalService } from "./services/ModalService";
import { OrganisationLinkService } from "./services/OrganisationLinkService";
import { OrganisationService } from "./services/OrganisationService";
import { PaymentService } from "./services/PaymentService";
import { ProductService } from "./services/ProductService";
import { ProductStubService } from "./services/ProductStubService";
import { QualificationsService } from "./services/QualificationsService";
import { RoleService } from "./services/RoleService";
import { SelectListService } from "./services/SelectListService";
import { SharedDataService } from "./services/SharedDataService";
import { StreetAddressService } from "./services/StreetAddressService";
import { TaskItemService } from "./services/TaskItemService";
import { TeamMemberService } from "./services/TeamMemberService";
import { UserService } from "./services/UserService";
import { Utils } from "./Utils";
import {EnterpriseService} from "./services/EnterpriseService"
import {UserDashboardService} from "./services/UserDashboardService"

export function registerServices() {
  angular
    .module("ccqapp")
    .service("AccountService", AccountService)
    .service("AccountSettingService", AccountSettingService)
    .service("ApplicantService", ApplicantService)
    .service("AssetLiabilityItemService", AssetLiabilityItemService)
    .service("AuthService", AuthService)
    .service("BridgingCaseService", BridgingCaseService)
    .service("BridgingSearchService", BridgingSearchService)
    .service("CaseLenderMessageService", CaseLenderMessageService)
    .service("CaseLenderService", CaseLenderService)
    .service("CaseMemberService", CaseMemberService)
    .service("CaseNoteService", CaseNoteService)
    .service("CaseService", CaseService)
    .service("CaseSummaryService", CaseSummaryService)
    .service("ClientService", ClientService)
    .service("ComparablePropertyService", ComparablePropertyService)
    .service("DealClientService", DealClientService)
    .service("DealCompletionService", DealCompletionService)
    .service("DealFileAttachmentService", DealFileAttachmentService)
    .service("DevelopmentExperienceService", DevelopmentExperienceService)
    .service("DevelopmentInputScheduleService", DevelopmentInputScheduleService)
    .service("DevelopmentInputService", DevelopmentInputService)
    .service(
      "DevelopmentInputWithNoLoginService",
      DevelopmentInputWithNoLoginService,
    )
    .service("DevelopmentTrackRecordService", DevelopmentTrackRecordService)
    .service(
      "DeveloperExitFinanceDevelopmentInputService",
      DeveloperExitFinanceDevelopmentInputService,
    )
    .service(
      "DeveloperExitFinanceDevScheduleInputService",
      DeveloperExitFinanceDevScheduleInputService,
    )
    .service(
      "DeveloperExitFinanceLoanInputService",
      DeveloperExitFinanceLoanInputService,
    )
    .service(
      "DeveloperExitFinanceResultService",
      DeveloperExitFinanceResultService,
    )
    .service("DevFinanceScheduleService", DevFinanceScheduleService)
    .service("EventLogService", EventLogService)
    .service("ExternalLinksService", ExternalLinksService)
    .service("FeaturePriceService", FeaturePriceService)
    .service("FeedBackService", FeedBackService)
    .service("FileAttachmentService", FileAttachmentService)
    .service("IntroducerService", IntroducerService)
    .service("IntroducerSimpleService", IntroducerSimpleService)
    .service("InviteService", InviteService)
    .service("LenderResultMinimalService", LenderResultMinimalService)
    .service("LenderResultService", LenderResultService)
    .service("LenderService", LenderService)
    .service("LicenseService", LicenseService)
    .service("ModalService", ModalService)
    .service("OrganisationLinkService", OrganisationLinkService)
    .service("OrganisationService", OrganisationService)
    .service("PaymentService", PaymentService)
    .service("ProductService", ProductService)
    .service("ProductStubService", ProductStubService)
    .service("QualificationsService", QualificationsService)
    .service("RoleService", RoleService)
    .service("SelectListService", SelectListService)
    .service("SharedDataService", SharedDataService)
    .service("StreetAddressService", StreetAddressService)
    .service("TaskItemService", TaskItemService)
    .service("TeamMemberService", TeamMemberService)
    .service("UserService", UserService)

    .service("BridgingDealService", BridgingDealService)
    .service("CommercialDealService", CommercialDealService)
    .service("CommercialSearchResultsService", CommercialSearchResultsService)
    .service("DealLenderMessageService", DealLenderMessageService)
    .service("DealLenderService", DealLenderService)
    .service("DealNoteService", DealNoteService)
    .service("DealScheduleService", DealScheduleService)
    .service("DealService", DealService)
    .service("DevFinanceDealService", DevFinanceDealService)
    .service("DevFinanceSearchResultsService", DevFinanceSearchResultsService)
    .service("SharedCriteriaService", SharedCriteriaService)
    .service("SharedDealService", SharedDealService)
    .service("SharedSearchResultsService", SharedSearchResultsService)
    .service("Util", Utils)
    .service("EnterpriseService", EnterpriseService)
    .service("UserDashboardService", UserDashboardService);
    ;
}

import { DealCompletionDTO } from "@js/DTO/DealCompletionDTO.cs.d";
import { BrokerAppraisalMessageResponse } from "@js/DTO/Messages/BrokerAppraisalMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class DealCompletionService extends BaseService<
  DealCompletionDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    private rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/dealcompletion";
    this.$broadcastBusinessNameSingular = "dealcompletion";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getBrokerAppraisalDetails() {
    let defer = this.$q.defer<BrokerAppraisalMessageResponse>();
    this.$http
      .get(this.$baseAddress + "/getbrokerappraisaldetails")
      .then((response) => {
        defer.resolve(response.data as BrokerAppraisalMessageResponse);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendDealCompletionEmail(newDealCompletion: DealCompletionDTO) {
    let defer = this.$q.defer<boolean>();
    this.$http
      .put(
        this.$baseAddress + "/senddealcompletionemail",
        JSON.stringify(newDealCompletion),
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

export const enum ModuleEnum {
  CaseOverview = 0,
  DeveloperProfile = 1,
  DevelopmentAppraisal = 2,
  DevelopmentSchedule = 3,
  Planning = 4,
  ProfessionalTeam = 5,
  ComparableProperties = 6,
  Case = 7,
  Track = 8,
  AssetsLiabilities = 9,
  ProofOfIncome = 10,
  ApplicantDetails = 11,
  Images = 12,
  DIP = 13,
  Property = 14,
  BuildCosts = 15,
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { LicenseAppPackageDTO } from "@js/DTO/LicenseAppPackageDTO.cs.d";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { UserService } from "@js/services/UserService";

export class AssignLicensesController {
  step: number = 4;
  licenseData: LicenseAppPackageDTO;
  totalNoOfLicense: number = 0;
  unassignedLicenses: number = 0;
  load: boolean = false;
  user: ApplicationUserDTO[] = [];
  isUserDisabled: boolean[] = [];
  error: string = "";
  assignLicenseForm: ng.IFormController;
  progressBar: {
    step: number;
    label: string;
    active: boolean;
    complete: boolean;
  }[] = [
    { step: 1, label: "About you", active: false, complete: true },
    { step: 2, label: "About your business", active: false, complete: true },
    {
      step: 3,
      label: "More about your business",
      active: false,
      complete: true,
    },
    { step: 4, label: "Assign licenses", active: false, complete: false },
  ];
  displayMsg: string = "";
  showMsg: boolean = false;
  assignLaterClicked: boolean = false;

  static $inject = [
    "$location",
    "PaymentService",
    "UserService",
    "OrganisationService",
  ];

  constructor(
    private $location: ng.ILocationService,
    private $paymentService: PaymentService,
    private userService: UserService,
    private organisationService: OrganisationService,
  ) {
    this.getLicense();
  }
  getLicense() {
    this.$paymentService.getLicense().then((response) => {
      this.licenseData = response;
      this.totalNoOfLicense = this.licenseData.TotalQuantity;
      if (this.totalNoOfLicense - 1 > 0) {
        this.$paymentService.getTotalUnassignedLicenses().then((response) => {
          this.unassignedLicenses = response;
          for (let i = 1; i <= this.unassignedLicenses; i++) {
            var newUser: ApplicationUserDTO = {
              Id: "",
              Roles: ["Introducer", "Broker"],
            } as ApplicationUserDTO;
            this.user.push(newUser);
            this.isUserDisabled.push(false);
          }
        });
      } else {
        this.progressBar[3].complete = true;
      }

      this.load = true;
    });
  }

  getNumber = function (num) {
    return new Array(num);
  };

  assignLicenses(user: ApplicationUserDTO, index: number) {
    var assignedLicenseusers = [];
    user.Roles = ["Introducer", "Broker"];
    assignedLicenseusers.push(user);
    this.userService
      .registerBrokerUsers(assignedLicenseusers)
      .then((response: string) => {
        if (response.length == 0) {
          this.displayMsg = `Successfully assigned a license to ${assignedLicenseusers[0].FirstName} ${assignedLicenseusers[0].LastName}`;
          this.isUserDisabled[index] = true;
          this.showMsg = true;
          this.unassignedLicenses -= 1;
        } else {
          this.displayMsg = response;
          this.showMsg = true;
        }
      });
  }

  goToUserDashboard() {
    this.$location.path("/userdashboard");
  }

  isFormValid() {
    if (this.unassignedLicenses > 0) {
      if (!this.assignLicenseForm.$invalid) {
        this.progressBar[3].complete = true;
        return true;
      } else {
        return false;
      }
    } else {
      this.progressBar[3].complete = true;
      return true;
    }
  }

  assignLater() {
    if (this.unassignedLicenses > 0 && this.assignLaterClicked) {
      this.organisationService.brokerSignUpJourneyG5Email().then((response) => {
        this.$location.path("/userdashboard");
        this.user = [];
      });
    } else {
      this.$location.path("/userdashboard");
      this.user = [];
    }
  }
}

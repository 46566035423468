

    export class TourPopupComponentController {

        public tourOrder: number;
        public tourState: any;
        public stepEnabled: boolean = true;
        public repeatStop: number;

        constructor(private $rootScope, private $element) { }

        $postLink() {
            // Update the total steps by adding ourself.
            this.tourState.tourTotalSteps = this.tourState.tourTotalSteps + 1;
        }

        $onDestroy() {
            // Update the total steps by removing ourself.
            this.tourState.tourTotalSteps = this.tourState.tourTotalSteps - 1;
        }

        $doCheck() {

            // See if we're the next step...
            if ((this.tourState.tourStep == this.tourOrder) && (this.tourOrder != this.$rootScope.repeatStop)) {

                if (!this.stepEnabled && this.stepEnabled !== undefined) {
                    // If we're disabled, we want to move to the next step immediately.
                    this.next();
                } else {
                    if (this.tourOrder != 1) {
                        let element = document.getElementById('tourStep' + this.tourState.tourStep);
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                    }
                }

                //suppress repeat calls for this step
                this.$rootScope.repeatStop = this.tourOrder;
            }
        }

        public next() {
            this.$rootScope.$broadcast('nextTour');
        }

        public back() {
            this.$rootScope.$broadcast('backTour');
        }

        public skip() {
            this.$rootScope.$broadcast('skipTour');
        }

    }


import { AddressLookupDTO } from "@js/DTO/AddressLookupDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseMemberDTO } from "@js/DTO/CaseMemberDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { LenderResultMinimalDTO } from "@js/DTO/DevelopmentFinance/LenderResultMinimalDTO.cs.d";
import { ProjectOwnershipDTO } from "@js/DTO/DevelopmentFinance/ProjectOwnershipDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { ProductDTO } from "@js/DTO/ProductDTO.cs.d";
import { BuildProcurementTypeEnum } from "@js/models/enum/BuildProcurementTypeEnum.cs.d";
import { CaseAccessLevelEnum } from "@js/models/enum/CaseAccessLevelEnum.cs.d";
import { ExitStrategyEnum } from "@js/models/enum/ExitStrategyEnum.cs.d";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";
import { OwnNewDevelopmentEnum } from "@js/models/enum/OwnNewDevelopmentEnum.cs.d";
import { PlanningPermissionTypeEnum } from "@js/models/enum/PlanningPermissionTypeEnum.cs.d";
import { PlanningResponsibilityEnum } from "@js/models/enum/PlanningResponsibilityEnum.cs.d";
import { PurchaseProcessTypeEnum } from "@js/models/enum/PurchaseProcessTypeEnum.cs.d";
import { PostalAddress } from "@js/models/PostalAddress.cs.d";
import { ApplicantService } from "@js/services/ApplicantService";
import { AuthService } from "@js/services/AuthService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { DevelopmentExperienceService } from "@js/services/DevelopmentExperienceService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { LenderResultService } from "@js/services/LenderResultService";
import { RoleService } from "@js/services/RoleService";
import { StreetAddressService } from "@js/services/StreetAddressService";
import { UserService } from "@js/services/UserService";

export class CaseOverviewController {
  dataLoading: boolean = false;

  //Applicants displayed
  shareholders: CaseMemberDTO[];
  currentApplicant: CaseMemberDTO;
  applicantForm: ng.IFormController;
  addEditApplicant: CaseMemberDTO;
  confirmDeleteApplicant: CaseMemberDTO;
  tempApplicant: CaseMemberDTO;
  showQuickEditModal: boolean = false;
  showDelete: boolean = false;
  confirmationDelete: boolean = false;
  maxDateOfBirth: Date = new Date();
  minDateOfBirth: Date = new Date("Jan 01 1900");
  maxDateForDateField: Date = new Date("Jan 01 3000");
  message: string = "";
  modal: boolean = false;
  disableQuickEditSave: boolean = false;
  isAddShareholder: boolean;

  isAdmin: boolean;
  isBroker: boolean;
  shareholder: CaseMemberDTO;
  isPrimaryApplicant: boolean;

  //Open / close the sections
  ownership: boolean = false;
  siteDetails: boolean = false;
  planning: boolean = false;
  procurement: boolean = false;
  deposit: boolean = false;
  guarantees: boolean = false;
  exitStrategy: boolean = false;
  completion: boolean = false;
  mezzanine: boolean = false;
  preferences: boolean = false;
  existingRelationships: boolean = false;
  caseOverview: boolean = false;
  isExpanded: boolean = false;
  executiveSummary: boolean = false;

  //File Section

  thisModuleSection: ModuleEnum.Planning;
  fileUpload: FileAttachmentDTO[];
  fileUploadPlaning: FileAttachmentDTO[];
  fileUploadTeam: FileAttachmentDTO[];
  fileUploadProperties: FileAttachmentDTO[];
  selectedProduct: ProductDTO;
  selectedResult: LenderResultMinimalDTO;
  total: number = 0;
  openModal: boolean = false;
  uploadingFiles: FileUploadProgressDTO[];
  renamingFile: FileAttachmentDTO;

  //Case info
  loanCriteria: DevelopmentInputDTO;
  dateOfOrigPurchMonth: number;
  dateOfOrigPurchYear: number;
  case: CaseDTO;

  //Search address
  searchresults: string;
  PostCodeOptions: PostalAddress[] = [];
  searchterm: string;
  searchingForAddress: boolean = false;

  //Development Experience info
  companyDetails: ProjectOwnershipDTO;

  tinymceOptions: any;
  multiPartForm1: ng.IFormController;
  showAutofill: boolean;

  static $inject = [
    "$rootScope",
    "$routeParams",
    "CaseMemberService",
    "CaseService",
    "DevelopmentInputService",
    "$location",
    "StreetAddressService",
    "$q",
    "LenderResultService",
    "ApplicantService",
    "DevelopmentExperienceService",
    "FileAttachmentService",
    "$window",
    "RoleService",
    "AuthService",
    "UserService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $caseMemberService: CaseMemberService,
    private caseService: CaseService,
    private developmentInputService: DevelopmentInputService,
    private $location: ng.ILocationService,
    private $streetAddressService: StreetAddressService,
    protected $q: ng.IQService,
    private $lenderresultservice: LenderResultService,
    private $applicantservice: ApplicantService,
    private $developmentexperienceservice: DevelopmentExperienceService,
    private fileAttachmentService: FileAttachmentService,
    private $window: ng.IWindowService,
    private roleService: RoleService,
    private $auth: AuthService,
    private userService: UserService,
  ) {
    this.tinymceOptions = {
      content_style:
        "body {color: #304b9a;font:300 13.33px Roboto,sans-serif;}",
      menubar: false,
      height: 300,
      branding: false,
      statusbar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | " +
        "bold italic backcolor forecolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | preview fullscreen |" +
        "removeformat | help",
    };

    this.userService.getUserAutofill().then((response) => {
      this.showAutofill = response;
    });

    if (this.$routeParams.CaseId) {
      this.dataLoading = true;

      //case is needed to get loanCriteria information
      this.caseService
        .fetch(this.$routeParams.CaseId)
        .then((response) => {
          this.case = response;

          if (this.$routeParams.Planning) {
            this.planning = true;
          }

          if (this.case) {
            this.fileUpload = this.case.Attachments;

            this.$developmentexperienceservice
              .fetch(this.case.DevelopmentExperienceID)
              .then((response) => {
                this.companyDetails = response;
              });

            this.developmentInputService
              .fetch(this.case.DevelopmentInputID)
              .then((response) => {
                this.loanCriteria = response;

                if (this.loanCriteria) {
                  if (this.loanCriteria.CI_Dev_DateOfOrigPurch) {
                    this.dateOfOrigPurchMonth =
                      this.loanCriteria.CI_Dev_DateOfOrigPurch.getMonth() + 1;
                    this.dateOfOrigPurchYear =
                      this.loanCriteria.CI_Dev_DateOfOrigPurch.getFullYear() %
                      100;
                  }
                  if (!this.loanCriteria.DI_PurchaseAgentFees) {
                    this.loanCriteria.DI_PurchaseAgentFees = 0;
                  }
                  if (!this.loanCriteria.DI_PurchaseLegalFees) {
                    this.loanCriteria.DI_PurchaseLegalFees = 0;
                  }
                  if (!this.loanCriteria.DI_PurchaseOtherFees) {
                    this.loanCriteria.DI_PurchaseOtherFees = 0;
                  }
                } else {
                  // If there's no loan criteria then something has gone wrong
                  this.$location.path("/dashboard");
                }
              })
              .catch((error) => {
                this.$location.path("/dashboard");
              })
              .finally(() => {
                this.dataLoading = false;
              });
          } else {
            // If no case is returned, this could be that the logged in user has been removed from the case
            // - only do this if there is a logged in user, otherwise let the routing sort out where the redirect should go
            if ((this.$rootScope as any).selectedUser) {
              this.$location.path("/dashboard");
            }
          }
        })
        .catch((error) => {
          this.$location.path("/dashboard");
        })
        .finally(() => {
          this.dataLoading = false;
        });

      this.checkForAdmin();
      this.checkForBroker();
      this.checkForPrimaryUser();

      this.$caseMemberService
        .fetchByCaseId(this.$routeParams.CaseId)
        .then((response) => {
          this.shareholders = response;
        });
    }
  }

  checkForAdmin(): void {
    this.dataLoading = true;
    this.roleService
      .isAdminUser()
      .then((response) => {
        this.isAdmin = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkForBroker(): void {
    this.dataLoading = true;
    this.roleService
      .isBroker()
      .then((response) => {
        this.isBroker = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkForPrimaryUser(): boolean {
    if (
      (this.$rootScope as any).selectedUser &&
      this.case &&
      (this.$rootScope as any).selectedUser.UserName == this.case.OwnerUser
    ) {
      this.isPrimaryApplicant = true;
      return true;
    }

    this.isPrimaryApplicant = false;
    return false;
  }

  clientAccountAccessCheck(shareholder: CaseMemberDTO): boolean {
    if (this.shareholders) {
      this.shareholder = this.shareholders.find(
        (x) => x.UserId == shareholder.UserId,
      );
    }

    if (this.shareholder.UserId == (this.$rootScope as any).selectedUser.Id) {
      return true;
    }

    if (this.checkForPrimaryUser()) {
      return true;
    }

    return false;
  }

  getAddressList(app, searchTerm: string) {
    let foundMatch = false;

    if (
      this.PostCodeOptions &&
      this.PostCodeOptions &&
      this.PostCodeOptions.length > 0
    ) {
      let addressLookup = this.PostCodeOptions.find((add) => {
        return add.AddressLine1 + " " + add.AddressLine2 === searchTerm;
      });

      if (addressLookup && !(addressLookup as AddressLookupDTO).IsPostCode) {
        foundMatch = true;

        // We've selected an address!
        this.$streetAddressService
          .getFullAddress((addressLookup as AddressLookupDTO).Id)
          .then((address) => {
            app.DI_StreetAddress = address;
            this.searchterm = "";
          });
      } else if (
        addressLookup &&
        (addressLookup as AddressLookupDTO).IsPostCode
      ) {
        foundMatch = true;
        this.loanCriteria.DI_StreetAddress.AddressLine1 =
          addressLookup.AddressLine2.replace(
            addressLookup.AddressLine2.substring(
              addressLookup.AddressLine2.indexOf("-") - 1,
            ),
            "",
          );
        this.loanCriteria.DI_StreetAddress.AddressLine2 =
          addressLookup.AddressLine3;
        this.loanCriteria.DI_StreetAddress.AddressLine3 =
          addressLookup.AddressLine4;
        this.loanCriteria.DI_StreetAddress.AddressLine4 =
          addressLookup.PostCode;
        this.loanCriteria.DI_StreetAddress.PostCode =
          addressLookup.AddressLine1;
        this.searchterm = "";
      }
    }

    if (searchTerm.length >= 3 && !foundMatch) {
      this.searchingForAddress = true;
      // Initial search term
      this.PostCodeOptions = [];

      this.$streetAddressService
        .getAddressList(searchTerm)
        .then((response) => {
          this.PostCodeOptions = response;
        })
        .finally(() => {
          this.searchingForAddress = false;
        });
    }
  }

  save(): ng.IPromise<number> {
    let defer = this.$q.defer<number>();
    if (!this.loanCriteria) {
      return defer.promise;
    }

    this.developmentInputService
      .addUpdatereturnonlyid(this.loanCriteria)
      .then((response) => {
        this.$lenderresultservice
          .fetchMatchingResults(
            this.loanCriteria,
            true,
            true,
            20,
            false,
            "",
            "",
            "",
            this.loanCriteria.SearchName,
          )
          .then((results) => {});
        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
      });

    if (this.case.CompletionDate) {
      var completionDate = new Date();
      completionDate.setHours(0, 0, 0, 0);
      this.case.CompletionDate = new Date(
        completionDate.setUTCFullYear(
          this.case.CompletionDate.getFullYear(),
          this.case.CompletionDate.getMonth(),
          this.case.CompletionDate.getDate(),
        ),
      );
    }

    this.caseService.addUpdatereturnonlyid(this.case).then((response) => {});

    this.$developmentexperienceservice
      .addUpdatereturnonlyid(this.companyDetails)
      .then((response) => {
        //set ID of selectedobject
        this.companyDetails.Id = response;
        //after save take out saving assoication each time
        this.companyDetails.parentCaseToAssoicateWithOnCreation = null;
        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
      });

    this.goToCaseDashboard(false);

    return defer.promise;
  }

  go(path): void {
    this.$location.path(path);
  }

  /**
   * Calculates the order number of all the preferences when one is toggled
   * @param toggledPreference
   */
  calculatePreferenceOrder(toggledPreference: string) {
    const preferenceNames = [
      "SearchPreferenceLoanSize",
      "SearchPreferencePricing",
      "SearchPreferenceCompletionSpeed",
      "SearchPreferenceCustomerService",
    ];
    // Finds the highest preference order of all preferences
    const maxPreferenceOrder = Math.max(
      this.loanCriteria.SearchPreferenceCompletionSpeed,
      this.loanCriteria.SearchPreferenceCustomerService,
      this.loanCriteria.SearchPreferenceLoanSize,
      this.loanCriteria.SearchPreferencePricing,
    );

    // If the preference that has been toggled already has a value greater than 0 then it is being turned OFF
    if (this.loanCriteria[toggledPreference] > 0) {
      // Find any preferences that have a higher preference number and decrease their order number by 1
      for (let preference of preferenceNames) {
        if (preference == toggledPreference) {
          continue;
        } else {
          if (
            this.loanCriteria[preference] > this.loanCriteria[toggledPreference]
          ) {
            this.loanCriteria[preference]--;
          }
        }
      }

      this.loanCriteria[toggledPreference] = 0;
    } else {
      // Preference is being turned ON
      // Use the highest preference order number and increase by 1
      this.loanCriteria[toggledPreference] = maxPreferenceOrder + 1;
    }
  }

  goToCaseDashboard(saveChanges: boolean): void {
    if (saveChanges && !this.roleService.IsLender) {
      this.save().then((response) => {
        (this.$rootScope as any).formSaved = true;
        this.$location.path("/casedashboard/" + this.case.Id);
      });
    } else {
      this.$location.path("/casedashboard/" + this.case.Id);
    }
  }

  /** Initialise the CaseMemberDTO for adding a new Shareholder/Applicant */
  initialiseNewApplicant() {
    this.addEditApplicant = {
      AccessLevel: CaseAccessLevelEnum.FullAccess,
      CaseId: this.$routeParams.CaseId,
      IsApplicant: true,
      Order:
        this.shareholders && this.shareholders.length > 0
          ? this.shareholders.length + 1
          : 1,
      StreetAddress: {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        AddressLine4: "",
        PostCode: "",
      } as PostalAddress,
    } as CaseMemberDTO;
  }

  deleteApplicant(): void {
    var id = this.confirmDeleteApplicant.Id;

    this.$applicantservice.delete(id).then((response) => {
      var index = this.shareholders
        .map(function (s) {
          return s.Id;
        })
        .indexOf(id);

      //let index = this.shareholders.indexOf(this.confirmDeleteApplicant);
      this.shareholders.splice(index, 1);
    });
    this.confirmDeleteApplicant = {} as CaseMemberDTO;
    this.showDelete = false;
  }

  deleteConfirmApplicant(applicant: CaseMemberDTO) {
    this.showDelete = true;
    this.confirmDeleteApplicant = { ...applicant };
  }

  /**
   * Open the Shareholder/Applicant quick edit
   * @param applicant
   */
  openShareholderQuickEdit(applicant: CaseMemberDTO) {
    if (applicant) {
      this.isAddShareholder = false;
      this.addEditApplicant = applicant;
      this.tempApplicant = { ...this.addEditApplicant };
    } else {
      this.isAddShareholder = true;
      if (this.applicantForm) {
        this.applicantForm.$setPristine();
        this.applicantForm.$setUntouched();
      }

      this.initialiseNewApplicant();
    }

    this.showQuickEditModal = true;
  }

  /** Cancel any adding or editing of a Shareholder/Applicant */
  cancelShareholderQuickEdit() {
    if (this.tempApplicant && this.tempApplicant.Id > 0) {
      this.shareholders = this.shareholders.filter(
        (applicant) => applicant.Id != this.tempApplicant.Id,
      );
      this.shareholders.push(this.tempApplicant);
    }

    this.closeShareholderQuickEdit();
  }

  /** Save a new or updated Shareholder/Applicant */
  saveApplicant(): void {
    this.disableQuickEditSave = true; // this is just because sometimes the save takes a little while and the user could click the save button again

    this.$applicantservice
      .addOrUpdateCaseMember(this.addEditApplicant, this.isAddShareholder)
      .then((response) => {
        response.DateOfBirth = this.addEditApplicant.DateOfBirth; // TODO should try to parse the response.DOB as it's being returned as a string

        const existingShareholder = this.shareholders.find(
          (s) => s.Id == response.Id,
        );

        // If found then we're updating
        if (existingShareholder) {
          this.shareholders = this.shareholders.filter(
            (member) => member.Id != this.addEditApplicant.Id,
          );
          this.shareholders.push(response);
        } else {
          this.shareholders.push(response);
        }
      })
      .finally(() => {
        this.closeShareholderQuickEdit();
        this.disableQuickEditSave = false;
      });
  }

  goToApplicantDetails(applicant: CaseMemberDTO): void {
    //change applicant.id to index (use a for loop to iterate through dto)
    var applicantId;

    for (let i = 0; i <= this.shareholders.length - 1; i++) {
      if (this.shareholders[i].Id == applicant.Id) {
        applicantId = i;
      }
    }
    if (applicantId || applicantId == 0) {
      this.go(
        "/applicantdetails/" +
          this.case.Id +
          "/" +
          this.case.DevelopmentExperienceID +
          "/4/" +
          applicantId,
      );
    }
  }

  closeShareholderQuickEdit() {
    this.showQuickEditModal = false;
    this.applicantForm.$setPristine();
    this.applicantForm.$setUntouched();
    this.tempApplicant = {} as CaseMemberDTO;
    this.addEditApplicant = {} as CaseMemberDTO;
  }

  /** Get the text to display based on the ownership type selected */
  getOwnershipTypeText(): string {
    var ownershipTypeText;

    if (
      this.companyDetails &&
      this.companyDetails.HowWillYouOwnNewDevelopment
    ) {
      switch (this.companyDetails.HowWillYouOwnNewDevelopment) {
        case OwnNewDevelopmentEnum.UKLimitedCompany:
          ownershipTypeText = "UK Limited Company";
          break;
        case OwnNewDevelopmentEnum.OverseasLimitedCompany:
          ownershipTypeText = "Overseas Limited Company";
          break;
        case OwnNewDevelopmentEnum.PensionFund:
          ownershipTypeText = "Pension Fund";
          break;
        case OwnNewDevelopmentEnum.UKLimitedLiabilityPartnership:
          ownershipTypeText = "UK Limited Liability Partnership";
          break;
        default:
          ownershipTypeText = "";
      }
      return ownershipTypeText;
    }
    return "";
  }

  /**Determine whether to show CompanyOrPartnershipName */
  showCompanyOrPartnershipName(): boolean {
    if (
      this.companyDetails &&
      this.companyDetails.HowWillYouOwnNewDevelopment !=
        OwnNewDevelopmentEnum.PersonalNames &&
      this.companyDetails.HasTheCompanyOrPensionBeenFormedYet
    ) {
      return true;
    }
    return false;
  }

  /**Determine whether to show CompanyOrPartnershipNumber */
  showCompanyOrPartnershipNumber(): boolean {
    if (
      this.companyDetails &&
      this.companyDetails.HowWillYouOwnNewDevelopment !=
        OwnNewDevelopmentEnum.PersonalNames &&
      this.companyDetails.HasTheCompanyOrPensionBeenFormedYet
    ) {
      return true;
    }
    return false;
  }

  /**Determine whether to show PensionType */
  showPensionType(): boolean {
    if (
      this.companyDetails &&
      this.companyDetails.HowWillYouOwnNewDevelopment ==
        OwnNewDevelopmentEnum.PensionFund &&
      this.companyDetails.HasTheCompanyOrPensionBeenFormedYet
    ) {
      return true;
    }
    return false;
  }

  /** Determing whether to show the Tell us more fields */
  showTellUsMoreFields(): boolean {
    if (
      this.companyDetails &&
      this.companyDetails.HowWillYouOwnNewDevelopment !=
        OwnNewDevelopmentEnum.PersonalNames &&
      this.companyDetails.HowWillYouOwnNewDevelopment !=
        OwnNewDevelopmentEnum.Other
    ) {
      return true;
    }
    return false;
  }

  setDate(dateToUpdate: Date, month: number, year: number) {
    // Two digit years!
    var fullYear: number;
    var today = new Date();
    if (today.getFullYear() < 2000 + year) {
      // If it's after this year, we probably mean 19xx.
      fullYear = 1900 + year;
    } else {
      // Otherwise it's probably in the 2000s.
      fullYear = 2000 + year;
    }
    dateToUpdate.setUTCFullYear(fullYear);
    // Note: Months are 0 based.
    dateToUpdate.setUTCMonth(month - 1);
  }

  invite(applicant: CaseMemberDTO) {
    this.$caseMemberService
      .inviteApplicant(applicant.UniqueRef)
      .then((i) => {
        this.modal = true;
        this.message = "Your email was successfully sent.";
      })
      .catch((error) => {
        this.modal = true;
        this.message =
          "there was a problem on sending the email. Please try again later.";
      });
  }

  //File upload

  //calculateFilesForThisModule() {
  //  this.fileUploadPlaning = this.fileUpload.filter(file => file.Module === ModuleEnum.Planning);
  //this.fileUploadTeam = this.fileUpload.filter(file => file.Module === ModuleEnum.ProfessionalTeam);
  //this.fileUploadProperties = this.fileUpload.filter(file => file.Module === ModuleEnum.ComparableProperties);
  //}

  calculateTotalFiles(filter: number) {
    if (this.fileUpload) {
      this.total = this.fileUpload.filter(
        (item) => item.Module === filter,
      ).length;
    }
    return this.total;
  }

  onFileSelect(
    files: FileAttachmentDTO[],
    module: ModuleEnum = this.thisModuleSection,
  ) {
    if (files.length > 0) {
      //if case has no id it must be saved first
      if (this.case.Id < 1) {
        this.caseService
          .addUpdate(this.case)
          .then((response) => {
            this.case = response;
            this.onFileSelect(files, module); //call itself and then exit as should now be saved so should be able to run
            return;
          })
          .finally(() => {
            this.dataLoading = false;
          });
        return;
      }
      this.fileAttachmentService
        .UploadFileLstInSync(
          files,
          this.uploadingFiles,
          this.case.Id,
          this.fileUpload,
          module,
        )
        .then((result) => {
          this.openModal = false;
        });
    }
  }

  downloadFile(file: FileAttachmentDTO) {
    this.dataLoading = true;
    this.fileAttachmentService
      .getFileUri(file.FileLocation)
      .then((uri) => {
        this.$window.open(uri);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  downloadFilesAsZip(files: FileAttachmentDTO) {
    var keys: string[] = [];
    for (var file in files) {
      if (files[file].FileLocation) {
        keys.push(files[file].FileLocation);
      }
    }
    this.fileAttachmentService.downloadZip(keys);
  }

  deleteFile(file: FileAttachmentDTO) {
    this.case.Attachments.splice(this.case.Attachments.indexOf(file), 1);
    this.fileAttachmentService.markasdeleted(file.Id).then((result) => {
      // code for file upload
      //this.calculateFilesForThisModule();
    }); //remove on the server
    // this.$rootScope.$apply();
  }
  renameFile(file: FileAttachmentDTO) {
    if (this.renamingFile === undefined) {
      // if renaming this file will dirty the case, we'll want to set it back to pristine when we're done.
      // this.renamingFileDirties = !$scope.caseForm.$dirty;
      this.renamingFile = file;
    } else {
      delete this.renamingFile;
    }
  }
  renamingFileComplete(file: FileAttachmentDTO) {
    this.dataLoading = true;
    this.fileAttachmentService
      .renameFile(file)
      .then((response) => {
        file.TempURL = response.TempURL;
      })
      .finally(() => {
        this.dataLoading = false;
      });
    delete this.renamingFile;
  }

  expandOrCollapse(value: boolean) {
    this.ownership = value;
    this.siteDetails = value;
    this.planning = value;
    this.procurement = value;
    this.deposit = value;
    this.guarantees = value;
    this.exitStrategy = value;
    this.completion = value;
    this.mezzanine = value;
    this.preferences = value;
    this.existingRelationships = value;
    this.caseOverview = value;
    return value;
  }

  /**
   * Determines when to show/hide the Invite button
   * @param shareholder
   */
  showInviteButton(shareholder: CaseMemberDTO) {
    // If the shareholder has already been verified then don't show the button
    if (shareholder.IsVerified) {
      return false;
    }

    // At least 2 of the security questions should be answered
    var count = 0;

    if (shareholder.PhoneNumber) {
      count++;
    }

    if (shareholder.StreetAddress.PostCode) {
      count++;
    }

    if (shareholder.DateOfBirth) {
      count++;
    }

    if (count >= 2) {
      return true;
    }

    return false;
  }

  autoFillCaseOverview() {
    this.loanCriteria.DI_StreetAddress.AddressLine1 = "10 Kings Road";
    this.loanCriteria.DI_StreetAddress.AddressLine2 = "York";
    this.loanCriteria.DI_StreetAddress.AddressLine3 = "";
    this.loanCriteria.DI_StreetAddress.AddressLine4 = "";
    this.loanCriteria.DI_StreetAddress.PostCode = "YO1 1ET";
    this.loanCriteria.DI_AreaSalesAreaResidential = 1000;
    this.case.PurchaseProcessType = PurchaseProcessTypeEnum.RegularPurchase;
    this.case.PlanningPermissionType = PlanningPermissionTypeEnum.NoPlanning;
    this.case.PlanningResponsibility = PlanningResponsibilityEnum.Borrower;
    this.case.PlanningDescription =
      "We purchased the site with outline planning 6 months ago, with a bridging loan.We havenow dealt with all reserved matters, and have detailed planning permission for 26x 3 & 4 bed houses.";
    this.case.SiteDescriptionOnCompletion =
      "The site will be bare land at completion.";
    this.case.BuildProcurementType =
      BuildProcurementTypeEnum.DesignAndBuildContract;
    this.case.DepositDescription =
      "50% of the deposit will come from the shareholders and 50% will come from investors. The investors are family and friends and their investment will be secured as a 2nd charge.";
    this.case.GuaranteeDescription = "This is the dummy text";
    this.loanCriteria.CI_Dev_ExitStrategy =
      ExitStrategyEnum.PartSalePartRefinance;
    this.case.HasSetCompletionDate = true;
    const maxDate = Date.now();
    const timestamp = Math.floor(Math.random() * maxDate);
    this.case.CompletionDate = new Date(timestamp);
    this.loanCriteria.SearchPreferenceLoanSize = 1;
    this.loanCriteria.SearchPreferencePricing = 2;
    this.loanCriteria.SearchPreferenceCompletionSpeed = 3;
    this.loanCriteria.SearchPreferenceCustomerService = 4;
    this.companyDetails.ExcludedLenders = "Example Lenders";
  }
}

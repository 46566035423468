import angular from "angular";
import { UserService } from "./services/UserService";

interface RequiredStatesOption {
  name: string;
  disable: boolean;
  hide: boolean;
}

export function registerRequiresStateDirective() {
  const ccqdirectives = angular.module("Ccq.directives");

  ccqdirectives.directive("statusRequirementId", [
    "$rootScope",
    ($rootScope: ng.IRootScopeService) => {
      return {
        restrict: "A",
        controller: function ($scope, $element, $attrs) {
          // Expose the scope.
          this.$scope = $scope;
          // This is set asynchronously, so we need to watch for changes.
          $scope.$watch("statusRequirementId", function () {
            this.statusRequirementId = ($scope as any).statusRequirementId;
          });
          // This is a static string, so we can set it immediately.
          this.statusRequirementEventName = (
            $scope as any
          ).statusRequirementEventName;
        },
        scope: {
          statusRequirementId: "=", // status of current case id, need to check this against required states 'after it is loaded async-ly'
          statusRequirementEventName: "@",
          hideIfNotInState: "=",
          requiredStatesOptions: "=",
        },
      };
    },
  ]);

  ccqdirectives.directive("requiredStatesTERMINATED", [
    "UserService",
    "$rootScope",
    (userService: UserService, $rootScope: ng.IRootScopeService) => {
      return {
        restrict: "A",
        require: ["^statusRequirementId"],
        controller: function ($scope, $element, $attrs) {
          $scope.userService = userService;
        },
        scope: false,
        link: function (
          scope: angular.IScope,
          element: JQLite,
          attr: angular.IAttributes,
          ctrls: angular.IController[],
        ) {
          const el: HTMLElement = angular.element(element)[0];
          const $rootScope = scope.$root;
          const $userService = (scope as any).userService;

          let statusRequirementId = (ctrls[0] as any).statusRequirementId;
          const statusRequirementEventName = (ctrls[0] as any)
            .statusRequirementEventName;
          let requiredStatesOptions: RequiredStatesOption[] =
            scope.$parent.$eval(attr.requiredStatesOptions);
          let hideIfNotInState: Boolean =
            scope.$parent.$eval(attr.hideIfNotInState) || false;

          let requiredStates: string[] | string = scope.$parent.$eval(
            attr.requiredStates,
          ); // get the value of the javascript attribute required-states

          //define callback scope with initial values
          let callbackScope = {
            parentScope: scope,
            requiredStates: requiredStates,
            statusRequirementId: statusRequirementId,
          };

          // if the status requirement id changes, we update the value in callbackScope
          (ctrls[0] as any).$scope.$watch(
            "statusRequirementId",
            function () {
              this.callbackScope.statusRequirementId = (
                ctrls[0] as any
              ).$scope.statusRequirementId;
              if (!!this.callbackScope.statusRequirementId) {
                let status = ($rootScope as any).caseStateChange[
                  this.callbackScope.statusRequirementId
                ] as string; // current state
                updateState(requiredStates, requiredStatesOptions, status);
              }
            }.bind({ callbackScope: callbackScope }),
          );

          let originalStyles = {
            cursor: el.style.cursor,
            pointerEvents: el.style.pointerEvents,
            opacity: el.style.opacity,
            display: el.style.display,
          };

          const permitted = function (): void {
            if (hideIfNotInState) {
              performPermitted(true, false); //show
            } else {
              performPermitted(); //enable
            }
          };

          const notPermitted = function (): void {
            if (hideIfNotInState) {
              performNotPermitted(true, false); //hidden
            } else {
              performNotPermitted(); //disabled
            }
          };

          const performPermitted = function (
            hideIfNotPermitted: Boolean = false,
            disableIfNotPermitted: Boolean = true,
          ): void {
            if (disableIfNotPermitted) {
              el.style.cursor = originalStyles.cursor;
              el.style.pointerEvents = originalStyles.pointerEvents;
              el.style.opacity = originalStyles.opacity;
            }

            if (hideIfNotPermitted) {
              el.style.display = originalStyles.display;
            }
          };

          const performNotPermitted = function (
            hideIfNotPermitted: Boolean = false,
            disableIfNotPermitted: Boolean = true,
          ): void {
            if (disableIfNotPermitted) {
              el.style.cursor = "not-allowed";
              el.style.pointerEvents = "none";
              el.style.opacity = "0.8";
            }

            if (hideIfNotPermitted) {
              el.style.display = "none";
            }
          };

          const executeOptions = function (option: RequiredStatesOption): void {
            // if state option is disabled or required it is hidden
            if (option.disable) {
              el.style.cursor = originalStyles.cursor;
              el.style.pointerEvents = originalStyles.pointerEvents;
              el.style.opacity = originalStyles.opacity;
              el.style.display = "none";
            }

            if (option.hide) {
              el.style.display = "none";
            }
          };

          const updateState = (
            requiredStates: string[] | string,
            requiredStatesOptions: RequiredStatesOption[],
            currentState: string,
          ): void => {
            // We may pass in a string or an array of strings. If it's jsut a single string, let's put it into an array so we can use the same handling either way.
            if (typeof requiredStates === "string") {
              requiredStates = [requiredStates];
            }

            if (requiredStates && currentState) {
              let matchingState = requiredStates.find(
                (state) => state.toLowerCase() === currentState.toLowerCase(),
              );
              if (!!matchingState) {
                permitted();
              } else {
                notPermitted();
              }
            }

            // requiredStatesOptions will always override anything applied as part of requiredStates
            if (requiredStatesOptions && currentState) {
              for (let i = 0; i < requiredStatesOptions.length; i++) {
                let stateOption = requiredStatesOptions[i];
                var matchingState =
                  currentState.toLowerCase() === stateOption.name.toLowerCase();

                if (!matchingState) {
                  executeOptions(stateOption);
                  break;
                }
              }
            }
          };

          // Setup defaults; we should assume we don't have access then grant it if we find we do.
          notPermitted();

          // Listen for updates to status.

          let testy = $rootScope.$on(
            statusRequirementEventName,
            function (event, statusUpdate: { id: number; status: string }) {
              if (statusUpdate.id === this.statusRequirementId) {
                updateState(
                  this.requiredStates,
                  this.requiredStatesOptions,
                  statusUpdate.status,
                );
              }
            }.bind(callbackScope),
          ); //uses updated values
        },
      };
    },
  ]);
}

import { OrganisationLinkDTO } from "@js/DTO/OrgnisationLinkDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";

export class OrganisationLinkService extends BaseService<
OrganisationLinkDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/organisationlink";
    this.$broadcastBusinessNameSingular = "organisationlink";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  /**
   * Gets the organisationlink and organisation by organisationlink id
   * @param organisationLinkId
   */
  getOrganisationLinkAndOrganisation(
    organisationLinkId: string,
    orgCode: string,
  ): ng.IPromise<OrganisationLinkDTO> {
    let defer = this.$q.defer<OrganisationLinkDTO>();
    this.$http
      .get(
        this.$baseAddress +
          "/getorganisationlinkandorganisation?organisationLinkId=" +
          organisationLinkId +
          "&orgCode=" +
          orgCode,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationLinkDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getOrganisationLinkLogoByLinkIdAndLogPageLoadEvent(
    organisationLinkId: number,
    eventType: string = "",
    dealId: number = 0,
    productType: ProductTypeEnum = ProductTypeEnum.None,
    userEmail: string = "",
    userClientId: string = ""
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(
        this.$baseAddress +
          "/getorganisationlinklogobylinkidandLogpageloadevent?organisationLinkId=" +
          organisationLinkId +
          "&eventType=" +
          eventType +
          "&dealId=" +
          dealId +
          "&productType=" +
          productType +
          "&userEmail=" +
          userEmail+
          "&userClientId=" +
          userClientId,
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }
}

import { AccountDTO } from "@js/DTO/AccountDTO.cs.d";
import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AccountService } from "@js/services/AccountService";
import { AuthService } from "@js/services/AuthService";

export class AccountsController {
  user: ApplicationUserDTO;
  performingAction: boolean;
  selectedAccount: AccountDTO;
  accounts: AccountDTO[];
  account: AccountDTO;
  photoUploadProgress: number;
  import1Message: string = null;
  import2Message: string = null;
  uploadingPhoto: boolean;

  static $inject = [
    "$scope",
    "$rootScope",
    "$cookies",
    "$location",
    "$http",
    "AuthService",
    "AccountService",
  ];

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $auth: AuthService,
    private $account: AccountService,
  ) {
    this.performingAction = false;

    this.selectedAccount = null;

    //get all the accounts back
    this.FetchAccounts();
  }
  selectAccount(account: AccountDTO) {
    this.selectedAccount = account;
  }

  createAccount(): void {
    this.selectedAccount = <AccountDTO>{ AccountName: "New Account" };
  }

  FetchAccounts(): void {
    this.performingAction = true;
    this.$account
      .fetchAll()
      .then((response) => {
        this.accounts = response;
        this.performingAction = false;
      })
      .catch((response) => {
        this.performingAction = false;
      });
  }

  deleteAccount(): void {
    this.$account.delete(this.selectedAccount.Id).then((result: boolean) => {
      this.accounts.splice(this.accounts.indexOf(this.selectedAccount), 1);
      //                this.$toaster.pop('success', "Deleted account!", "The account has been removed from the system.");
      delete this.selectedAccount;
    });
  }

  updateAccount(): void {
    this.performingAction = true;

    this.$account
      .addUpdatereturnonlyid(this.selectedAccount)
      .then((recID: number) => {
        this.performingAction = false;
        this.selectedAccount.Id = recID;
        if (this.selectedAccount.Id === undefined) {
          this.accounts.push(this.selectedAccount);
          //     this.$toaster.pop('success', "Created new account!", this.selectedAccount.AccountName + " has been added to the system.");
        } else {
          //   this.$toaster.pop('success', "Updated account!", this.selectedAccount.AccountName + " has been updated.");
        }
      });
  }
}

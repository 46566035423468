import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { FeatureEnum } from "@js/models/enum/FeatureEnum.cs.d";

export class FeaturePriceService extends BaseService<
  AppPackagePricingDTO,
  number
> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/featureprice";
    this.$broadcastBusinessNameSingular = "featureprice";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getFeaturePriceByFeature(
    feature: FeatureEnum,
    isShowAll,
  ): ng.IPromise<AppPackagePricingDTO[]> {
    let defer = this.$q.defer<AppPackagePricingDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/getfeaturepricebyfeature?feature=" +
          feature +
          "&isShowAll=" +
          isShowAll,
      )
      .then((response) => {
        defer.resolve(response.data as AppPackagePricingDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { DevelopmentWithNoLoginDTO } from "@js/DTO/DevelopmentWithNoLoginDTO.cs.d";
import { IntroducerDTO } from "@js/DTO/IntroducerDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class IntroducerService extends BaseService<IntroducerDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/introducer";
    this.$broadcastBusinessNameSingular = "introducer";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  registerIntroducer(
    dto: IntroducerDTO,
    sendEmail: boolean = true,
  ): ng.IPromise<IntroducerDTO> {
    let defer = this.$q.defer<IntroducerDTO>();
    this.$http
      .put(
        this.$baseAddress + "/register?sendEmail=" + sendEmail,
        JSON.stringify(dto),
      )
      .then((response) => {
        defer.resolve(response.data as IntroducerDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  registerAnotherIntroducer(
    dto: IntroducerDTO,
    introduceeID: string,
    sendNewEmail: boolean,
  ): ng.IPromise<IntroducerDTO> {
    let defer = this.$q.defer<IntroducerDTO>();
    this.$http
      .put(
        this.$baseAddress +
          "/registerAsIntroducee?sendEmail=" +
          sendNewEmail +
          "&introduceeID=" +
          introduceeID,
        JSON.stringify(dto),
      )
      .then((response) => {
        defer.resolve(response.data as IntroducerDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  introduceClient(
    clientFirstName: string,
    clientEmail: string,
    clientsurname: string,
    clientPhoneNumber: string,
    guid?: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodedClientEmail = encodeURIComponent(clientEmail);
    this.$http
      .get(
        this.$baseAddress +
          "/introduceclient?clientFirstName=" +
          clientFirstName +
          "&clientEmail=" +
          encodedClientEmail +
          "&clientsurname=" +
          clientsurname +
          "&clientPhoneNumber=" +
          clientPhoneNumber +
          "&guid=" +
          guid,
      )
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendLinkToUser(
    userFirstName: string,
    userEmail: string,
    userId: string,
  ): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    var encodedUserEmail = encodeURIComponent(userEmail);
    this.$http
      .get(
        this.$baseAddress +
          "/sendLinkToUser?userFirstName=" +
          userFirstName +
          "&userEmail=" +
          encodedUserEmail +
          "&userId=" +
          userId,
      )
      .then((response) => {
        defer.resolve(true);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  copyLinkToClipboard(userId: string): ng.IPromise<IntroducerDTO> {
    let defer = this.$q.defer<IntroducerDTO>();
    this.$http
      .get(this.$baseAddress + "/copyLinkToClipboard?userId=" + userId)
      .then((response) => {
        defer.resolve(response.data as IntroducerDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  sendResultsToClient(
    dto: DevelopmentInputDTO,
    clientFirstName: string,
    clientSurname: string,
    clientEmail: string,
    notifyBorrower: boolean,
    clientPhoneNumber: string = null,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    var encodedClientEmail = encodeURIComponent(clientEmail);
    this.$http
      .put(
        this.$baseAddress +
          "/sendresultstoclient?clientFirstName=" +
          clientFirstName +
          "&clientSurname=" +
          clientSurname +
          "&clientEmail=" +
          encodedClientEmail +
          "&notifyBorrower=" +
          notifyBorrower +
          "&clientPhoneNumber=" +
          clientPhoneNumber,
        JSON.stringify(dto),
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  fetchUnclaimedSearches(): ng.IPromise<DevelopmentWithNoLoginDTO[]> {
    let defer = this.$q.defer<DevelopmentWithNoLoginDTO[]>();
    this.$http
      .get(this.$baseAddress + "/fetchunclaimedsearches")
      .then((response) => {
        defer.resolve(response.data as DevelopmentWithNoLoginDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getIntroducerDetailsByUserId(userId: string): ng.IPromise<IntroducerDTO> {
    let defer = this.$q.defer<IntroducerDTO>();
    this.$http
      .get(this.$baseAddress + "/getintroducerdetailsbyuserid?userId=" + userId)
      .then((response) => {
        defer.resolve(response.data as IntroducerDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { LicenseAppPackageDTO } from "@js/DTO/LicenseAppPackageDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { BillingFrequencyEnum } from "@js/models/enum/BillingFrequencyEnum.cs.d";
import { ContractLengthEnum } from "@js/models/enum/ContractLengthEnum.cs.d";
import { LicenseMasterStatusEnum } from "@js/models/enum/LicenseMasterStatusEnum.cs.d";
import { PricingFrequencyEnum } from "@js/models/enum/PricingFrequencyEnum.cs.d";
import { TeamSizeEnum } from "@js/models/enum/TeamSizeEnum.cs.d";
import { UserRoleEnum } from "@js/models/enum/UserRoleEnum.cs.d";
import { AccountSettingService } from "@js/services/AccountSettingsService";
import { AuthService } from "@js/services/AuthService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class AccountSettingsController {
  selectedTab: string;
  noMoreMails: boolean;

  //Brokers
  organisationAdminId: string;
  organisationAdmin: ApplicationUserDTO;
  organisationAdminUsername: string;
  organisationUsers: ApplicationUserDTO[];
  organisationUsersExceptAdmin: ApplicationUserDTO[] = [];
  userOrganisation: OrganisationDTO;
  selectedBroker: ApplicationUserDTO;
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;

  newEmail: string;
  repeatEmail: string;

  passwordUpdated: boolean;
  emailUpdated: boolean;
  allActiveAppPackagePrices: AppPackagePricingDTO[];
  allActiveAppPackagePricesForFrequency: AppPackagePricingDTO[];
  selectedLicenseAppPackagePrice: LicenseAppPackageDTO;
  selectedProduct: AppPackagePricingDTO = null;

  assignedLicense: AppPackagePricingDTO = null;

  isBroker: boolean;
  message: string;
  showMsgModal: boolean = false;
  showBrokerUsers: boolean = false;
  addBrokerUsers: boolean = false;
  addCustomPrice: boolean = false;
  changeInQuantity: boolean = false;
  isSelected: boolean = false;
  showAssignLicense: boolean = false;
  numberOfAssignedLicenses: number = 1;
  selectedPricingFrequency: boolean = false;
  selectedPricingFrequencyEnum: PricingFrequencyEnum =
    PricingFrequencyEnum.Monthly;
  showAlertmsg: boolean = false;
  showCancelModel: boolean = false;
  newBrokerUser: ApplicationUserDTO = {
    Id: "",
    Roles: ["Introducer", "Broker"],
  } as ApplicationUserDTO;
  assignLicenseForm: ng.IFormController;
  assignCustompriceForm: ng.IFormController;
  remaningLicense: number = 0;
  dataLoading: boolean = false;
  isUpdateClicked: boolean = false;
  isSignUpClicked: boolean = false;
  selectedUserRole: UserRoleEnum = UserRoleEnum.None;
  isAdmin: boolean = false;
  selectedOrganisationId: number;
  selectedUser: ApplicationUserDTO;
  selectedUserName: string = "";
  currentUser: ApplicationUserDTO;

  //Additional info
  additionalInfoText: string;
  CurrentTotalAmountToPay: number = 0;
  totalAmountToPay: number = 0;
  showIncreasingQuantityModal: boolean = false;
  showdescringQuantityModal: boolean = false;
  customePrice: string;
  numberOfLicense: number = 0;
  showCustomePricesuccessModal: boolean = false;
  newAmountInfoText: string;
  showNewAmount: boolean = false;
  isCustomPriceApplied: boolean = false;
  orderButtonText: string = "Update";
  orderButtonDisabled: boolean = false;
  showOrderButton: boolean = false;
  showCancelButton: boolean = false;
  billingCycle: PricingFrequencyEnum.Monthly;

  billingFrequencyOptions = [];
  teamSizeOptions = [];
  leadGenPackage: LicenseAppPackageDTO;
  selectedleadGenPackage: LicenseAppPackageDTO;
  isAddOnPaid: boolean = false;
  isContractChanged: boolean = false;
  alertMsg: string;
  isAddOnSelected: boolean = false;
  whitelabelPrice: number = 0;
  showWhiteLabelModal: boolean = false;
  newTotalPayment: number = 0;
  isSettingPage: boolean = true;
  endDate: Date;

  showLeadGenUrlModal: boolean = false;
  showLeadGenCancellationModal: boolean = false;
  leadGeneratorName: string;
  isLegacyLicense: boolean = false;
  licenseStatusText: string;
  reloadPage: boolean = false;
  whiteLabelledUrl: string = "";
  isMonthlySubscription: boolean = true;
  isPageReloadAfterCustomePrice: boolean = false;

  copyPaymentlink: boolean = false;
  companyEmailAddress: string;
  static $inject = [
    "$http",
    "$cookies",
    "AuthService",
    "RoleService",
    "PaymentService",
    "AccountSettingService",
    "UserService",
    "OrganisationService",
    "$routeParams",
    "$location",
    "SelectListService",
  ];

  constructor(
    private $http: ng.IHttpService,
    private $cookies: ng.cookies.ICookiesService,
    private $auth: AuthService,
    private $roleService: RoleService,
    private $paymentService: PaymentService,
    private $accountSettingService: AccountSettingService,
    private userService: UserService,
    private $organisationService: OrganisationService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private selectListService: SelectListService,
  ) {
    this.billingFrequencyOptions =
      this.selectListService.GetBillingFrequencyOptions();
    this.teamSizeOptions = this.selectListService.GetTeamSizeOptions();
    this.getWhiteLabelledUrl();

    this.$auth.getCompanyContactUsEmailAddresss().then((response) => {
      this.companyEmailAddress = response;
    });

    this.$roleService.isAdminUser().then((response) => {
      this.isAdmin = response;

      if (this.$routeParams.userId && this.isAdmin) {
        this.userService
          .getUserByUserId(this.$routeParams.userId)
          .then((response) => {
            this.selectedUser = response;
            this.selectedUserName = this.selectedUser.UserName;
            this.getSelectedPackagesForCurrentuser(this.selectedUserName);

            if (this.selectedUser.IsOrganisationAdmin) {
              this.selectedOrganisationId = this.selectedUser.OrganisationId;
              this.organisationAdminUsername = this.selectedUser.UserName;
              this.$organisationService
                .fetch(this.selectedUser.OrganisationId)
                .then((organisation) => {
                  this.userOrganisation = organisation;
                  this.isAddOnSelected
                    ? (this.isAddOnPaid = organisation.HasWhiteLabelAddOnPaid)
                    : (this.isAddOnPaid = this.isAddOnSelected =
                        organisation.HasWhiteLabelAddOnPaid);
                })
                .then(() => {
                  this.getLicenseStatusText();
                  this.getBrokerUsers(this.selectedOrganisationId);
                });

              this.selectedUserRole = UserRoleEnum.Broker;
            } else {
              this.selectedUserRole = UserRoleEnum.Client;
            }
          });
      } else {
        this.$auth.getUpdatedProfile().then((response) => {
          this.currentUser = this.selectedUser = response;
          this.selectedUserName = this.currentUser.UserName;
          this.getSelectedPackagesForCurrentuser(this.currentUser.UserName);
          this.getCurrentOrganisation();
        });
      }
    });

    this.selectedTab = this.$routeParams.selectedTab
      ? this.$routeParams.selectedTab
      : "license";
    this.leadGeneratorName = this.$cookies.get("leadgeneratorname");
  }

  getCurrentOrganisation() {
    this.$organisationService.getOrganisation().then((response) => {
      this.userOrganisation = response;
      this.isAddOnSelected
        ? (this.isAddOnPaid = response.HasWhiteLabelAddOnPaid)
        : (this.isAddOnPaid = this.isAddOnSelected =
            response.HasWhiteLabelAddOnPaid);
      this.selectedOrganisationId = this.userOrganisation.Id;
      this.getBrokerUsers(this.selectedOrganisationId);
    });
  }

  getBrokerUsers(orgId): void {
    this.userService.getAllOrganisationMembers(orgId).then((response) => {
      this.organisationUsers = response;
      this.numberOfAssignedLicenses = this.organisationUsers.filter(
        (e) => e.SubscriptionStatus > 0,
      ).length;
      if (
        this.selectedLicenseAppPackagePrice &&
        this.selectedLicenseAppPackagePrice.Status !=
          LicenseMasterStatusEnum.PreSubscription &&
        this.selectedLicenseAppPackagePrice.Status !=
          LicenseMasterStatusEnum.None
      )
        this.remaningLicense =
          this.selectedLicenseAppPackagePrice.TotalQuantity -
          this.numberOfAssignedLicenses;
    });
    this.userService.getOrganisationNonAdminUsers(orgId).then((response) => {
      this.organisationUsersExceptAdmin = response;
    });
  }

  applyEmailChange() {
    if (
      this.newEmail &&
      this.repeatEmail &&
      this.newEmail === this.repeatEmail
    ) {
      this.$auth.changeEmail(this.newEmail).then((response) => {
        delete this.newEmail;
        delete this.repeatEmail;
        this.emailUpdated = true;
        this.$roleService.goHomeBasedOnUser();
      });
    }
  }
  applyPasswordChange() {
    if (
      this.newPassword &&
      this.repeatPassword &&
      this.newPassword === this.repeatPassword
    ) {
      this.$auth
        .changePassword(this.currentPassword, this.newPassword)
        .then((response) => {
          delete this.currentPassword;
          delete this.newPassword;
          delete this.repeatPassword;
          this.passwordUpdated = true;
          this.$roleService.goHomeBasedOnUser();
        });
    }
  }

  getAssignedPackage(userName) {
    this.$paymentService
      .getAllActivePackagePrices(userName)
      .then((response) => {
        this.allActiveAppPackagePrices = response;
        if (
          this.selectedLicenseAppPackagePrice &&
          this.selectedLicenseAppPackagePrice.Status !=
            LicenseMasterStatusEnum.PreSubscription &&
          this.selectedLicenseAppPackagePrice.Status !=
            LicenseMasterStatusEnum.None
        ) {
          var selectedProduct = this.allActiveAppPackagePrices.filter(
            (o1) =>
              o1.AppPackageId ===
                this.selectedLicenseAppPackagePrice.AppPackageId &&
              o1.PricingFrequency ===
                this.selectedLicenseAppPackagePrice.BillingFrequency &&
              o1.ContractLength ==
                this.selectedLicenseAppPackagePrice.ContractLength,
          );
          if (selectedProduct.length > 0) {
            this.selectedProduct = selectedProduct.map(
              (e) => ((e.Quantity = this.quantity(e.AppPackageId)), e),
            )[0];

            this.isMonthlySubscription =
              this.selectedProduct.PricingFrequency ==
              PricingFrequencyEnum.Monthly
                ? true
                : false;

            if (this.hasLegacyLicense()) {
              this.selectedProduct.PriceAmount =
                this.selectedProduct.PricingFrequency ==
                PricingFrequencyEnum.Monthly
                  ? 35
                  : 350;
              this.isLegacyLicense = true;
            }

            this.getAddOnPrice(this.selectedProduct.PricingFrequency);
            this.getEndDate(this.selectedLicenseAppPackagePrice.ActiveFromDate);

            if (
              this.selectedLicenseAppPackagePrice.Status ==
                LicenseMasterStatusEnum.PaidUp ||
              this.selectedLicenseAppPackagePrice.Status ==
                LicenseMasterStatusEnum.PaymentProcessing ||
              this.selectedLicenseAppPackagePrice.Status ==
                LicenseMasterStatusEnum.PreCancel
            ) {
              this.assignedLicense = JSON.parse(
                JSON.stringify(this.selectedProduct),
              );
            }
          }
        } else {
          this.selectedProduct = this.allActiveAppPackagePrices[0];
        }
      });
  }

  getSelectedPackagesForCurrentuser(userName: string = "") {
    this.$accountSettingService
      .getSelectedPackagesForCurrentuser(userName)
      .then((response) => {
        if (response != null) {
          this.selectedLicenseAppPackagePrice = response.find(
            (p) => !p.AppPackageDto.IsLeadGenerator,
          );
          this.selectedleadGenPackage = response.find(
            (p) => p.AppPackageDto.IsLeadGenerator,
          );
        }
        //Code Review
        if (
          this.selectedLicenseAppPackagePrice != null &&
          this.selectedLicenseAppPackagePrice.PricePerLicense != 0 &&
          this.selectedLicenseAppPackagePrice.Status !=
            LicenseMasterStatusEnum.None
        ) {
          if (
            this.selectedLicenseAppPackagePrice.TotalPricePerCycle >
            this.selectedLicenseAppPackagePrice.PricePerLicense *
              this.selectedLicenseAppPackagePrice.TotalQuantity
          ) {
            this.isAddOnSelected = true;
          }
        }

        this.getAssignedPackage(userName);
        var pricingFrequency = this.selectedLicenseAppPackagePrice
          ? this.selectedLicenseAppPackagePrice.BillingFrequency
          : PricingFrequencyEnum.Monthly;

        if (this.selectedLicenseAppPackagePrice != null) {
          this.selectedPricingFrequency =
            this.selectedLicenseAppPackagePrice &&
            this.selectedLicenseAppPackagePrice?.BillingFrequency ==
              BillingFrequencyEnum.Monthly
              ? false
              : true;
          if (this.selectedLicenseAppPackagePrice.IsCustomPriceApplied) {
            this.isCustomPriceApplied = true;
          }
        }

        this.getActivePackagePricesForFrequency(pricingFrequency);
      });
  }

  getActivePackagePricesForFrequency(pricingFrequency) {
    this.$paymentService
      .getActivePackagePricesForFrequency(
        pricingFrequency,
        this.selectedUserRole,
      )
      .then((response) => {
        if (
          this.selectedLicenseAppPackagePrice &&
          this.selectedLicenseAppPackagePrice.Status !=
            LicenseMasterStatusEnum.None &&
          this.selectedLicenseAppPackagePrice.Status !=
            LicenseMasterStatusEnum.PreSubscription &&
          this.selectedLicenseAppPackagePrice.Status !=
            LicenseMasterStatusEnum.Cancelled
        ) {
          this.allActiveAppPackagePricesForFrequency = response.filter(
            (al) =>
              this.returnContractLength(al.ContractLength) >=
              this.returnContractLength(
                this.selectedLicenseAppPackagePrice.ContractLength,
              ),
          );
          this.getAddOnPrice(pricingFrequency);
        } else {
          this.allActiveAppPackagePricesForFrequency = response;
          this.getAddOnPrice(
            this.selectedPricingFrequency == false
              ? PricingFrequencyEnum.Monthly
              : PricingFrequencyEnum.Yearly,
          );
        }
      });
  }

  callGetActivePackagePricesForFrequency() {
    var pricingFrequency: PricingFrequencyEnum =
      this.selectedPricingFrequency == false
        ? PricingFrequencyEnum.Monthly
        : PricingFrequencyEnum.Yearly;
    this.getActivePackagePricesForFrequency(pricingFrequency);
  }

  quantity(packageId) {
    var found = this.selectedLicenseAppPackagePrice.AppPackageId == packageId;

    if (found) {
      return this.selectedLicenseAppPackagePrice.TotalQuantity;
    } else {
      return 1;
    }
  }

  convertEnumToString(value) {
    switch (value) {
      case PricingFrequencyEnum.Monthly:
        return "month";
      case PricingFrequencyEnum.Yearly:
        return "year";
      default:
        return "";
    }
  }

  returnSubscriptionStatus(value) {
    switch (value) {
      case LicenseMasterStatusEnum.PaidUp:
      case LicenseMasterStatusEnum.PreCancel:
        return "Paid Up";
      case LicenseMasterStatusEnum.PayFailure:
        return "Pay Failure";
      case LicenseMasterStatusEnum.Cancelled:
        return "Cancelled";
      case LicenseMasterStatusEnum.Unpaid:
        return "Unpaid";
      case LicenseMasterStatusEnum.PaymentProcessing:
        return "Payment Processing";
      case LicenseMasterStatusEnum.Trial:
        return "Trial";
      default:
        return "Unsubscribed";
    }
  }

  /*isUpdateRequired() {
            if (this.isBroker) {
                if (this.selectedProduct && this.oldProduct) {
                    if (this.selectedProduct != this.oldProduct) {
                        return this.changeInProduct(true);
                    } else {
                        if (this.selectedProduct[0].AppPackageId != this.oldProduct[0].AppPackageId) {
                            return true;
                        } else {
                            if (this.selectedProduct[0].Quantity != this.oldProduct[0].Quantity) {
                                return this.changeInProduct(true);
                            } else {
                                return this.changeInProduct(false);
                            }
                        }

                    }
                } else if (this.selectedProduct != this.oldProduct) {
                    return this.changeInProduct(true);
                }
            }
        }*/

  managePayment() {
    this.$paymentService.createPortalSession().then((response) => {
      window.open(response);
    });
  }

  onClickOfCancel() {
    if (
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PaymentProcessing &&
      !this.isDirectDebitActive(
        this.selectedLicenseAppPackagePrice.ActiveFromDate,
      )
    ) {
      return;
    }

    this.$paymentService
      .isSubscriptionCancelable(
        this.selectedLicenseAppPackagePrice.ExternalSubscriptionRef,
      )
      .then((response) => {
        if (response) {
          this.showCancelModel = true;
        } else {
          this.showAlertmsg = true;
          this.alertMsg =
            "Sorry, you can not cancel your subscription at this time since you have committed to a " +
            this.getContractLengthText(
              this.assignedLicense.PricingFrequency,
              this.assignedLicense.ContractLength,
            );
        }
      });
  }

  cancelSubscription(subscriptionId, isLeadGeneratorLicense = false) {
    this.dataLoading = true;
    this.showCancelModel = false;
    this.showLeadGenCancellationModal = false;
    this.$paymentService
      .cancelSubscription(subscriptionId)
      .then((response) => {
        this.message = `Your subscription has been successfully cancelled however, you can continue to enjoy using the application until ${response}`;
        if (isLeadGeneratorLicense) {
          this.selectedleadGenPackage.Status =
            LicenseMasterStatusEnum.PreCancel;
        } else {
          this.selectedLicenseAppPackagePrice.Status =
            LicenseMasterStatusEnum.PreCancel;
        }
      })
      .catch(() => {
        this.message =
          "There is problem cancelling the subscription, Please try later.";
      })
      .finally(() => {
        this.showMsgModal = true;
        this.dataLoading = false;
      });
  }

  unCancelSubscription(subscriptionId, isLeadGeneratorLicense = false) {
    this.dataLoading = true;
    this.$paymentService
      .unCancelSubscription(subscriptionId)
      .then((response) => {
        this.message = `You have successfully renewed your subscription.`;
        if (isLeadGeneratorLicense) {
          this.selectedleadGenPackage.Status = LicenseMasterStatusEnum.PaidUp;
        } else {
          this.selectedLicenseAppPackagePrice.Status =
            LicenseMasterStatusEnum.PaidUp;
        }
      })
      .catch(() => {
        this.message =
          "There is problem renewing your subscription, Please try later.";
      })
      .finally(() => {
        this.showMsgModal = true;
        this.dataLoading = false;
      });
  }

  /*getLicense(selectedUserName) {
            this.$paymentService.getLicense(selectedUserName).then((response) => {
                this.licenseData = response;
            });
        }*/

  isPackageSelected(selectedProduct) {
    if (selectedProduct && this.selectedProduct) {
      var found = this.selectedProduct.Id == selectedProduct.Id;

      if (found) {
        return true;
      } else {
        return false;
      }
    }
  }

  isAssignedproduct(product) {
    if (product && this.assignedLicense) {
      var found = this.assignedLicense.Id == product.Id;

      if (found) {
        return true;
      } else {
        return false;
      }
    }
  }

  confirmUpdateSubscription() {
    this.message = ``;
    if (this.isAddOnPaid && !this.isAddOnSelected) {
      if (this.assignedLicense.Quantity == this.selectedProduct.Quantity) {
        this.onClickOfAddOnRemove();
        return;
      } else {
        this.message += `You are removing whitelabelling from your subscription. `;
      }
    } else if (!this.isAddOnPaid && this.isAddOnSelected) {
      this.message += `You are adding whitelabelling to your subscription. `;
    }
    if (this.assignedLicense.Quantity < this.selectedProduct.Quantity) {
      this.message += `You are increasing the number of licenses from ${
        this.assignedLicense.Quantity
      } to ${this.selectedProduct.Quantity} and your ${
        this.selectedProduct.PricingFrequency == PricingFrequencyEnum.Monthly
          ? "Monthly"
          : "Yearly"
      } payment will increase from £${
        this.assignedLicense.Quantity * this.assignedLicense.PriceAmount
      } to £${
        this.selectedProduct.Quantity * this.selectedProduct.PriceAmount
      }. Click Proceed to apply this change.`;
      this.showIncreasingQuantityModal = true;
    } else if (this.assignedLicense.Quantity > this.selectedProduct.Quantity) {
      this.message += `You are decreasing the number of licenses from ${this.assignedLicense.Quantity} to ${this.selectedProduct.Quantity}. Are you sure you wish to continue?`;
      this.showdescringQuantityModal = true;
    } else {
      this.showIncreasingQuantityModal = true;
    }
  }

  updateSubscription() {
    this.dataLoading = true;
    this.isUpdateClicked = true;

    const updateSubscriptionPromise =
      this.selectedLicenseAppPackagePrice.Status ==
      LicenseMasterStatusEnum.PreCancel
        ? this.$accountSettingService.updatePreCancelSubscription(
            [this.selectedProduct],
            this.selectedUserName,
            this.isAddOnSelected,
            true,
          )
        : this.$accountSettingService.updateSubscription(
            [this.selectedProduct],
            this.selectedUserName,
            this.isAddOnSelected,
          );

    updateSubscriptionPromise
      .then((response) => {
        this.assignedLicense = JSON.parse(JSON.stringify(this.selectedProduct));
        if (this.isLegacyLicense) {
          if (this.assignedLicense.ContractLength != ContractLengthEnum.None) {
            this.isLegacyLicense = false;
          }
        }
        this.numberOfLicense = this.assignedLicense.Quantity;
        this.remaningLicense =
          this.assignedLicense.Quantity - this.numberOfAssignedLicenses;
        // this.getLicense(this.selectedUserName);
        this.message = "Your subscription has been updated successfully.";
        this.isUpdateClicked = false;
        this.getEndDate(this.selectedLicenseAppPackagePrice.ActiveFromDate);
        this.selectedLicenseAppPackagePrice.ContractLength =
          this.selectedProduct.ContractLength;
      })
      .catch(() => {
        this.message =
          "There is problem updating the subscription, Please try later.";
        this.isUpdateClicked = false;
        if (this.selectedUserName.length > 0) {
          this.getSelectedPackagesForCurrentuser(this.selectedUserName);
        } else {
          this.getSelectedPackagesForCurrentuser(this.currentUser.UserName);
        }
      })
      .then(() => {
        //Check whether addOn needs to be added or removed
        /*  if (this.isAddOnSelected && !this.isAddOnPaid) {
                    this.addAddOnToLicense();
                } else if (!this.isAddOnSelected && this.isAddOnPaid) {
                    this.removeAddOnFromLicense();
                }*/
      })
      .finally(() => {
        this.callGetActivePackagePricesForFrequency();
        this.showMsgModal = true;
        this.dataLoading = false;
        this.showIncreasingQuantityModal = false;
        this.showdescringQuantityModal = false;
        this.showWhiteLabelModal = false;
        this.showNewAmount = false;
        this.isContractChanged = false;
        this.changeInProduct(false);
        this.isAddOnSelected
          ? (this.isAddOnPaid = this.isAddOnSelected = true)
          : (this.isAddOnPaid = this.isAddOnSelected = false);
      });
  }

  closeModal() {
    this.showMsgModal = false;
    this.message = null;
    if (
      this.reloadPage ||
      this.selectedLicenseAppPackagePrice == null ||
      this.isPageReloadAfterCustomePrice
    ) {
      if (
        this.$routeParams.selectedTab ||
        this.selectedLicenseAppPackagePrice == null ||
        this.isPageReloadAfterCustomePrice
      ) {
        window.location.reload();
      } else {
        this.$location.path(
          "/settings/" + this.$routeParams.userId + "/leadgenerator",
        );
      }
    }
  }

  createCheckoutSession() {
    this.isSignUpClicked = true;
    this.$paymentService
      .createCheckoutSession(
        this.selectedProduct,
        this.isAddOnSelected,
        this.selectedUserName,
        null,
      )
      .then((response) => {
        window.location.assign(response.SessionUrl);
      })
      .catch(() => {
        this.message = "There is problem signing up, Please try later.";
        this.isSignUpClicked = false;
        this.showMsgModal = true;
      })
      .finally(() => {
        this.isSignUpClicked = false;
      });
  }

  assignLicense(broker) {
    this.dataLoading = true;
    this.selectedBroker = broker;
    this.$accountSettingService
      .assignLicense(this.selectedBroker.Id, this.selectedBroker.OrganisationId)
      .then((response) => {
        if (response) {
          this.selectedBroker.SubscriptionStatus =
            LicenseMasterStatusEnum.PaidUp;
          this.numberOfAssignedLicenses += 1;
          this.remaningLicense =
            this.assignedLicense.Quantity - this.numberOfAssignedLicenses;
          this.message = `Subscription is successfully assigned to ${this.selectedBroker.FullName}.`;
          this.showMsgModal = true;
        } else {
          this.message =
            "There is problem assigning the subscription, Please try later.";
          this.showMsgModal = true;
        }
      })
      .finally(() => {
        this.dataLoading = false;
        this.showAlertmsg = false;
      });
  }

  unAssignLicense(broker) {
    this.dataLoading = true;
    this.$accountSettingService
      .unAssignLicense(broker.Id)
      .then((response) => {
        if (response) {
          broker.SubscriptionStatus = null;
          this.numberOfAssignedLicenses -= 1;
          this.remaningLicense =
            this.assignedLicense.Quantity - this.numberOfAssignedLicenses;
          this.message = `Subscription is successfully unassigned to ${broker.FullName}.`;
          this.showMsgModal = true;
        } else {
          this.message =
            "There is problem unassigning the subscription, Please try later.";
          this.showMsgModal = true;
        }
      })
      .finally(() => {
        this.dataLoading = false;
        this.showAlertmsg = false;
      });
  }

  onUpdatingLiceseQuantity() {
    if (
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PaymentProcessing &&
      !this.isDirectDebitActive(
        this.selectedLicenseAppPackagePrice.ActiveFromDate,
      )
    ) {
      this.selectedProduct.Quantity =
        this.selectedLicenseAppPackagePrice.TotalQuantity;
      return;
    }

    if (
      this.selectedProduct.Quantity > this.assignedLicense?.Quantity ||
      this.selectedProduct.Quantity < this.assignedLicense?.Quantity ||
      this.selectedProduct.Id != this.assignedLicense?.Id
    ) {
      if (this.numberOfAssignedLicenses > this.selectedProduct.Quantity) {
        this.showAlertmsg = true;
        this.alertMsg = `You are attempting to downgrade your total number of licences when they are already in use. In order to downgrade, please first unassign licenses via the "Company broker users" menu. You can then reduce your total number of licenses and update your subscription.`;
        this.selectedProduct.Quantity = this.numberOfAssignedLicenses;
      } else {
        this.showNewAmount = true;
        this.changeInProduct(true);
        this.changeInQuantity = true;
        this.showAlertmsg = false;
        this.alertMsg = null;
        this.setTotalAmount();
      }
    } else if (
      this.selectedProduct.Quantity == this.assignedLicense?.Quantity
    ) {
      this.setTotalAmount();
      this.showOrderButton = false;
      this.changeInQuantity = false;
      this.changeInProduct(false);
      this.showAlertmsg = false;
      this.alertMsg = null;
    }
  }

  assignLicenses() {
    var assignedLicenseusers = [];
    assignedLicenseusers.push(this.newBrokerUser);
    this.dataLoading = true;

    this.userService
      .registerBrokerUsers(assignedLicenseusers, this.selectedUserName)
      .then((response: string) => {
        if (response.length == 0) {
          this.message = `Successfully added and assigned a license to ${assignedLicenseusers[0].FirstName} ${assignedLicenseusers[0].LastName}`;
          this.showMsgModal = true;
          this.newBrokerUser = {
            Id: "",
            Roles: ["Introducer", "Broker"],
          } as ApplicationUserDTO;
          this.assignLicenseForm.$setPristine();
          this.assignLicenseForm.$setUntouched();
          this.getBrokerUsers(this.selectedOrganisationId);
        } else {
          this.message = response;
          this.showMsgModal = true;
          this.newBrokerUser = {
            Id: "",
            Roles: ["Introducer", "Broker"],
          } as ApplicationUserDTO;
          this.assignLicenseForm.$setPristine();
          this.assignLicenseForm.$setUntouched();
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  cancelChanges() {
    this.changeInProduct(false);
    this.selectedProduct = JSON.parse(JSON.stringify(this.assignedLicense));
    if (this.hasLegacyLicense()) {
      this.selectedProduct.PriceAmount =
        this.selectedProduct.PricingFrequency == PricingFrequencyEnum.Monthly
          ? 35
          : 350;
      this.isLegacyLicense = true;
      this.whitelabelPrice = 0;
    }
    this.isAddOnSelected = this.isAddOnPaid;
    this.showAlertmsg = false;
    this.showdescringQuantityModal = false;
    this.showIncreasingQuantityModal = false;
    this.showWhiteLabelModal = false;
    this.showNewAmount = false;
    this.isContractChanged = false;
  }

  setTotalAmount() {
    this.newTotalPayment =
      this.selectedProduct.PriceAmount * this.selectedProduct.Quantity;
  }

  goToCompanySettings() {
    this.$location.path("/Organisations");
  }

  assignCustomPrice() {
    this.dataLoading = true;
    this.isCustomPriceApplied = true;

    if (
      this.selectedLicenseAppPackagePrice != null &&
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PayFailure
    ) {
      this.message = `Custom price can't be applied for license with status 'Incomplete'.`;
      this.dataLoading = false;
      this.showMsgModal = true;
      return;
    }

    const customPricePromise =
      this.selectedLicenseAppPackagePrice != null &&
      (this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PaidUp ||
        this.selectedLicenseAppPackagePrice.Status ==
          LicenseMasterStatusEnum.PaymentProcessing ||
        this.selectedLicenseAppPackagePrice.Status ==
          LicenseMasterStatusEnum.PreCancel ||
        this.selectedLicenseAppPackagePrice.Status ==
          LicenseMasterStatusEnum.Unpaid)
        ? this.$accountSettingService.assignCustomePrice(
            this.customePrice,
            this.selectedProduct.Quantity,
            this.selectedUser.OrganisationId,
            this.isAddOnSelected,
            this.isMonthlySubscription,
          )
        : this.$accountSettingService.offerCustomPrice(
            this.customePrice,
            this.selectedProduct.Quantity,
            this.selectedUser.OrganisationId,
            this.isAddOnSelected,
            this.isMonthlySubscription,
          );

    customPricePromise
      .then((response) => {
        if (response) {
          this.message = `Custom price has been applied successfully.`;
          this.showMsgModal = true;
          this.selectedLicenseAppPackagePrice.TotalPricePerCycle = Number(
            this.customePrice,
          );
        } else {
          this.message =
            "There is problem assigning the custom price, Please try later.";
          this.showMsgModal = true;
          this.isCustomPriceApplied = false;
        }
      })
      .finally(() => {
        this.dataLoading = false;
        this.customePrice = null;
        this.assignCustompriceForm.$setPristine();
        this.assignCustompriceForm.$setUntouched();
        this.isPageReloadAfterCustomePrice = true;
      });
  }

  assignStandardPrice() {
    this.dataLoading = true;
    this.$accountSettingService
      .assignStandardPrice(this.selectedUser.OrganisationId)
      .then((response) => {
        if (response) {
          this.message = `Standard price has been applied successfully.`;
          this.showMsgModal = true;
          this.isCustomPriceApplied = false;
        } else {
          this.message =
            "There is problem assigning the standard price, Please try later.";
          this.showMsgModal = true;
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  changeInProduct(value) {
    if (
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PaidUp ||
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PreCancel ||
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PaymentProcessing
    ) {
      this.showOrderButton = value;
      this.showCancelButton = value;
    }
  }

  orderButton() {
    if (this.isContractChanged) {
      this.confirmContractLengthChange();
    } else {
      this.confirmUpdateSubscription();
    }
  }

  cancelButton() {
    this.cancelChanges();
  }

  offerLeadGeneratorLicense() {
    this.dataLoading = true;
    if (this.userOrganisation != null) {
      this.leadGenPackage.LicenseMasterId =
        this.userOrganisation.LicenseMasterId;
    }
    this.$accountSettingService
      .offerLeadGeneratorLicense(this.leadGenPackage)
      .then((response) => {
        if (response) {
          this.message =
            this.leadGeneratorName +
            ` license offer email has been sent to ${this.userOrganisation.Name} admin.`;
          this.showMsgModal = true;
          this.reloadPage = true;
        } else {
          this.message =
            "There is problem assigning the offer for " +
            this.leadGeneratorName +
            ". Please contact Brickflow.";
          this.showMsgModal = true;
        }
      })
      .then(() => {
        return this.$organisationService.saveOrganisation(
          this.userOrganisation,
        );
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  onSelectingAddon(selected: boolean) {
    if (
      this.selectedLicenseAppPackagePrice != null &&
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PaymentProcessing &&
      !this.isDirectDebitActive(
        this.selectedLicenseAppPackagePrice.ActiveFromDate,
      )
    ) {
      return;
    }

    this.isAddOnSelected = selected;
    this.changeInProduct(
      this.isAddOnPaid != this.isAddOnSelected ||
        this.assignedLicense.Quantity != this.selectedProduct.Quantity,
    );
  }

  onClickOfAddOnRemove() {
    if (
      this.selectedLicenseAppPackagePrice != null &&
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PaymentProcessing &&
      !this.isDirectDebitActive(
        this.selectedLicenseAppPackagePrice.ActiveFromDate,
      )
    ) {
      return;
    }
    this.showWhiteLabelModal = true;
  }

  /* addAddOnToLicense() {
            if ((this.selectedLicenseAppPackagePrice == null) || (this.selectedLicenseAppPackagePrice != null && (this.selectedLicenseAppPackagePrice.Status == LicenseMasterStatusEnum.PreSubscription || this.selectedLicenseAppPackagePrice.Status == LicenseMasterStatusEnum.None || this.selectedLicenseAppPackagePrice.Status == LicenseMasterStatusEnum.Cancelled))) {
                this.isAddOnSelected = true;
            } else if (this.selectedLicenseAppPackagePrice.Status == LicenseMasterStatusEnum.PayFailure) {
                this.alertMsg = "Please pay your pending payment to update subscription."
                this.showAlertmsg = true;
                this.changeInQuantity = false;
                this.isContractChanged = true;
            }
            else {
                this.dataLoading = true;
                this.$accountSettingService.addAddOnToLicense(this.userOrganisation.Id).then((response) => {
                    if (response) {
                        this.isAddOnPaid = this.isAddOnSelected = true;
                    } else {
                        this.message += "\nThere is problem adding Add-on, Please try later."
                    }
                }).finally(() => {
                    this.dataLoading = false;
                });
            }
        }

        removeAddOnFromLicense() {
            if ((this.selectedLicenseAppPackagePrice == null) || (this.selectedLicenseAppPackagePrice != null && (this.selectedLicenseAppPackagePrice.Status == LicenseMasterStatusEnum.PreSubscription || this.selectedLicenseAppPackagePrice.Status == LicenseMasterStatusEnum.None || this.selectedLicenseAppPackagePrice.Status == LicenseMasterStatusEnum.Cancelled ))) {
                this.isAddOnSelected = false;
                this.showWhiteLabelModal = false;
            } else if (this.selectedLicenseAppPackagePrice.Status == LicenseMasterStatusEnum.PayFailure) {
                this.alertMsg = "Please pay your pending payment to update subscription."
                this.showAlertmsg = true;
                this.changeInQuantity = false;
                this.isContractChanged = true;
            }
            else {
                this.dataLoading = true;
                this.$accountSettingService.removeAddOnFromLicense(this.userOrganisation.Id).then((response) => {
                    if (response) {
                        this.isAddOnPaid = this.isAddOnSelected = false;
                    } else {
                        this.message += "\nThere is problem removing a Add-on, Please try later."
                        this.showMsgModal = true;
                    }
                }).finally(() => {
                    this.dataLoading = false;
                    this.showWhiteLabelModal = false;
                });
            }
        }*/

  onSelectingPackage(product: AppPackagePricingDTO) {
    if (
      this.selectedLicenseAppPackagePrice != null &&
      this.selectedLicenseAppPackagePrice.Status ==
        LicenseMasterStatusEnum.PaymentProcessing &&
      !this.isDirectDebitActive(
        this.selectedLicenseAppPackagePrice.ActiveFromDate,
      )
    ) {
      return;
    }

    if (
      this.selectedLicenseAppPackagePrice == null ||
      this.selectedLicenseAppPackagePrice.Status !=
        LicenseMasterStatusEnum.PayFailure
    ) {
      this.selectedProduct = product;
      if (this.assignedLicense)
        this.selectedProduct.Quantity = this.assignedLicense.Quantity;
      if (
        !this.assignedLicense ||
        this.selectedProduct.Id != this.assignedLicense.Id
      ) {
        if (
          this.selectedProduct.Id != this.assignedLicense.Id &&
          this.isLegacyLicense
        ) {
          this.getAddOnPrice(this.selectedProduct.PricingFrequency);
        }
        this.isContractChanged = true;
        this.changeInProduct(true);
        this.showAlertmsg = false;
        this.alertMsg = null;
      } else {
        this.isContractChanged = false;
        if (this.hasLegacyLicense()) {
          this.selectedProduct.PriceAmount =
            this.selectedProduct.PricingFrequency ==
            PricingFrequencyEnum.Monthly
              ? 35
              : 350;
          this.whitelabelPrice = 0;
          this.isLegacyLicense = true;
          this.isAddOnSelected = true;
        }
        this.changeInProduct(false);
        this.showAlertmsg = false;
        this.alertMsg = null;
      }
    } else {
      this.alertMsg = "Please pay your pending payment to change the contract.";
      this.showAlertmsg = true;
      this.changeInQuantity = false;
      this.isContractChanged = true;
    }
  }

  confirmContractLengthChange() {
    this.message = `Are you sure you wish to continue?`;
    this.showMsgModal = true;
  }

  returnContractLength(value) {
    switch (value) {
      case ContractLengthEnum.TwoYearly:
        return 2;
      case ContractLengthEnum.Yearly:
        return 1;
      default:
        return 0;
    }
  }

  getAddOnPrice(frequency: PricingFrequencyEnum) {
    if (this.hasLegacyLicense()) {
      this.whitelabelPrice = 0;
    } else {
      this.$paymentService.getWhitelabelPrice(frequency).then((response) => {
        this.whitelabelPrice = response;
      });
    }
  }

  convertEnumToStringForLeadGenerator(value) {
    switch (value) {
      case PricingFrequencyEnum.Monthly:
        return "Monthly";
      case PricingFrequencyEnum.Monthly:
        return "Monthly";
      default:
        return "";
    }
  }

  calculateTotal(
    product: AppPackagePricingDTO,
    isUpdated: boolean = false,
  ): number {
    var total = Number(product ? product?.Quantity * product?.PriceAmount : 0);

    if (!isUpdated) {
      if (!this.isLegacyLicense) {
        total += Number(this.isAddOnPaid ? this.whitelabelPrice : 0);
      }
    }

    if (isUpdated) {
      total += Number(this.isAddOnSelected ? this.whitelabelPrice : 0);
    }

    return total;
  }

  showLeadeGeneratorForm() {
    if (
      this.selectedleadGenPackage &&
      this.selectedleadGenPackage.Status == LicenseMasterStatusEnum.Offered
    ) {
      return true;
    } else {
      return false;
    }
  }

  showLeadeGeneratorPackage() {
    if (
      this.selectedleadGenPackage &&
      this.selectedleadGenPackage.Status != LicenseMasterStatusEnum.Offered
    ) {
      return true;
    } else {
      return false;
    }
  }

  acceptLeadGenLicense() {
    this.dataLoading = true;
    this.$paymentService
      .createCheckoutSessionForLeadGen(this.selectedleadGenPackage)
      .then((response) => {
        window.location.assign(response.SessionUrl);
      })
      .catch(() => {
        this.message = "There is problem signing up, Please try later.";
        this.showMsgModal = true;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  createCheckoutForCustomPrice() {
    this.dataLoading = true;
    this.isSignUpClicked = true;
    this.$paymentService
      .createCheckoutSessionForCustomPrice(
        this.selectedLicenseAppPackagePrice,
        this.isAddOnSelected,
        this.selectedUserName,
        null,
      )
      .then((response) => {
        if (this.copyPaymentlink) {
          navigator.clipboard.writeText(response.SessionUrl);
          this.message = "Code has been copied to the clipboard";
          this.showMsgModal = true;
          this.copyPaymentlink = false;
        } else {
          window.location.assign(response.SessionUrl);
        }
      })
      .catch(() => {
        this.message = "There is problem signing up, Please try later.";
        this.isSignUpClicked = false;
        this.showMsgModal = true;
      })
      .finally(() => {
        this.dataLoading = false;
        this.isSignUpClicked = false;
      });
  }

  cancelLeadGeneratorOffer() {
    this.$accountSettingService
      .cancelLeadGeneratorOffer(this.selectedleadGenPackage.Id)
      .then((response) => {
        if (response) {
          this.message = this.leadGeneratorName + ` offer has been cancelled.`;
          this.showMsgModal = true;
          this.leadGenPackage = null;
          this.reloadPage = true;
        } else {
          this.message =
            "There is problem canceling the offer, Please try later.";
          this.showMsgModal = true;
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  showAssignLicenseSection() {
    if (
      (this.remaningLicense >= 1 &&
        this.selectedLicenseAppPackagePrice &&
        (this.selectedLicenseAppPackagePrice.Status ==
          LicenseMasterStatusEnum.PaidUp ||
          this.selectedLicenseAppPackagePrice.Status ==
            LicenseMasterStatusEnum.PreCancel ||
          this.selectedLicenseAppPackagePrice.Status ==
            LicenseMasterStatusEnum.PaymentProcessing)) ||
      this.userOrganisation?.IsOnFreeTrial
    ) {
      return true;
    } else {
      return false;
    }
  }

  getContractLengthText(
    frequency: PricingFrequencyEnum,
    contractLength: number,
  ) {
    if (contractLength == 1) {
      return "1 Year Plan";
    } else if (contractLength == 2) {
      return "2 Year Plan";
    } else {
      return frequency == PricingFrequencyEnum.Monthly
        ? "Monthly Plan"
        : "Annual Plan";
    }
  }

  returnNumberOfLicenseText(product) {
    return this.selectedProduct.Quantity > 1
      ? `${this.selectedProduct.Quantity} licenses`
      : `${this.selectedProduct.Quantity} license`;
  }

  isDirectDebitActive(activeFromDate) {
    var currentDate = new Date();
    var newDate = new Date(
      activeFromDate.getFullYear(),
      activeFromDate.getMonth(),
      activeFromDate.getDate() + 7,
    );

    return currentDate > newDate;
  }

  getTeamSizeDescription(teamSizeEnum: TeamSizeEnum) {
    var teamSizeOptions = this.selectListService.GetTeamSizeOptions();

    var teamSizeInfo = teamSizeOptions.find(
      (option) => option.key == teamSizeEnum,
    );
    if (teamSizeInfo) {
      return teamSizeInfo.displayName;
    }

    return "";
  }

  generateButtonCode() {
    if (
      this.$organisationService.hasValidWhitelabelProperties(
        this.userOrganisation,
      )
    ) {
      this.message = `${this.whiteLabelledUrl}${this.userOrganisation.OrganisationCode}#!/e/devfinancecriteria/`;
      this.showLeadGenUrlModal = true;
    } else {
      this.message =
        "To access your " +
        this.leadGeneratorName +
        " URL please ensure you have an active White Label add-on and you have completed the URL Company Code in your company settings (under White Label)";
      this.showMsgModal = true;
    }
  }

  generateButtonAllLoanCode() {
    if (
      this.$organisationService.hasValidWhitelabelProperties(
        this.userOrganisation,
      )
    ) {
      this.message = `${this.whiteLabelledUrl}${this.userOrganisation.OrganisationCode}#!/allloans`;
      this.showLeadGenUrlModal = true;
    } else {
      this.showMsgModal = true;
      this.message =
        "To access your " +
        this.leadGeneratorName +
        " URL please ensure you have an active White Label add-on and you have completed the URL Company Code in your company settings (under White Label)";
    }
  }

  generateButtonBridgingLoanCode() {
    if (
      this.$organisationService.hasValidWhitelabelProperties(
        this.userOrganisation,
      )
    ) {
      this.message = `${this.whiteLabelledUrl}${this.userOrganisation.OrganisationCode}#!/e/bridgingcriteria`;
      this.showLeadGenUrlModal = true;
    } else {
      this.showMsgModal = true;
      this.message =
        "To access your " +
        this.leadGeneratorName +
        " URL please ensure you have an active White Label add-on and you have completed the URL Company Code in your company settings (under White Label)";
    }
  }

  generateButtonCommercialLoanCode() {
    if (
      this.$organisationService.hasValidWhitelabelProperties(
        this.userOrganisation,
      )
    ) {
      this.message = `${this.whiteLabelledUrl}${this.userOrganisation.OrganisationCode}#!/e/commercialcriteria`;
      this.showLeadGenUrlModal = true;
    } else {
      this.showMsgModal = true;
      this.message =
        "To access your " +
        this.leadGeneratorName +
        " URL please ensure you have an active White Label add-on and you have completed the URL Company Code in your company settings (under White Label)";
    }
  }

  copyButtonCodeToClipBoard() {
    this.showLeadGenUrlModal = false;
    navigator.clipboard.writeText(this.message);
    this.message = "Code has been copied to the clipboard";
    this.showMsgModal = true;
  }

  getEndDate(startDate: Date) {
    const startDateCopy: Date = new Date(startDate.getTime());

    switch (this.selectedProduct.ContractLength) {
      case ContractLengthEnum.Yearly:
        startDateCopy.setFullYear(startDateCopy.getFullYear() + 1);
        this.endDate = startDateCopy;
        break;
      case ContractLengthEnum.TwoYearly:
        startDateCopy.setFullYear(startDateCopy.getFullYear() + 2);
        this.endDate = startDateCopy;
        break;
      default:
        this.endDate = null;
        break;
    }
  }

  showPaymentDetailsButton() {
    if (
      (this.selectedLicenseAppPackagePrice != null &&
        this.selectedLicenseAppPackagePrice.Status !=
          LicenseMasterStatusEnum.PreSubscription &&
        this.selectedLicenseAppPackagePrice.Status !=
          LicenseMasterStatusEnum.None) ||
      (this.selectedleadGenPackage != null &&
        this.selectedleadGenPackage.Status !=
          LicenseMasterStatusEnum.PreSubscription &&
        this.selectedleadGenPackage.Status != LicenseMasterStatusEnum.None &&
        this.selectedleadGenPackage.Status != LicenseMasterStatusEnum.Offered)
    ) {
      return true;
    } else {
      return false;
    }
  }

  bookCall() {
    window.open(
      "https://brickflow.com/brokers/brickflow-enterprise/book-a-demo",
    );
  }

  hasLegacyLicense() {
    if (
      (this.isLegacyLicense &&
        this.selectedProduct &&
        this.selectedProduct.ContractLength == ContractLengthEnum.None) ||
      (this.userOrganisation &&
        this.selectedLicenseAppPackagePrice &&
        this.userOrganisation.IsLegacyLicense &&
        this.selectedLicenseAppPackagePrice.ContractLength ==
          ContractLengthEnum.None &&
        (!this.assignedLicense ||
          this.selectedProduct.Id == this.assignedLicense.Id))
    ) {
      return true;
    } else {
      false;
    }
  }

  getLicenseStatusText() {
    this.allActiveAppPackagePrices = [];
    this.userService
      .getLicenseStatusText(this.organisationAdminUsername)
      .then((response) => {
        this.licenseStatusText = response;
      });
  }

  getWhiteLabelledUrl() {
    this.$auth.getWhiteLabelledUrl().then((response) => {
      this.whiteLabelledUrl = response;
    });
  }

  gotoBrickflowPricingPrice() {
    window.open("https://brickflow.com/pricing");
  }
}

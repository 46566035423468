import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { LenderResultDTO } from "@js/DTO/DevelopmentFinance/LenderResultDTO.cs.d";
import { DevelopmentWithNoLoginDTO } from "@js/DTO/DevelopmentWithNoLoginDTO.cs.d";
import { IntroducerDTO } from "@js/DTO/IntroducerDTO.cs.d";
import { ApplicantRoleEnum } from "@js/models/enum/ApplicantRoleEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ReferrerEnum } from "@js/models/enum/ReferrerEnum.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { ClientService } from "@js/services/ClientService";
import { DealService } from "@js/services/Deal/DealService";
import { DealClientService } from "@js/services/DealClientService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { DevelopmentInputWithNoLoginService } from "@js/services/DevelopmentInputWithNoLoginService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { InviteService } from "@js/services/InviteService";
import { LenderResultService } from "@js/services/LenderResultService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { UserService } from "@js/services/UserService";

export class RegistrationController {
  dataLoading: boolean = false;

  openModal: boolean = false;

  registeringAccount: boolean;
  registrationFormStep1: ng.IFormController;
  registrationFormStep2: ng.IFormController;

  error: string;
  passworderror: string;

  loanCriteria: DevelopmentInputDTO;
  selectedResult: LenderResultDTO;
  loanLabel: string = "";

  enquireAfterRegistration: boolean;

  newIntroducer: IntroducerDTO;

  //newUser: RegistrationRequestDTO = {
  //    Email: "", FirstName: "", LastName: ""
  //} as RegistrationRequestDTO;

  newUser: ApplicationUserDTO = {
    Id: "",
    Roles: ["Client"],
  } as ApplicationUserDTO;

  inviteGuid: string = "";
  step: number = 1;
  profileLogo: string;
  selectedPackage: AppPackagePricingDTO;
  selectedProductList: AppPackagePricingDTO[] = [];
  isPayClicked: boolean = false;

  isConvertingExistingUser: boolean = false;

  redirectToSearch: boolean = false;

  isShareholderRegistration: boolean = false;

  referrerOptions = [];
  locationOptions = [];

  showTsAndCs: boolean = false;
  showPrivacyPolicy: boolean = false;
  orgCode: string;
  productFamily: ProductFamilyEnum;

  static $inject = [
    "CaseService",
    "$scope",
    "$rootScope",
    "$routeParams",
    "$cookies",
    "$location",
    "$http",
    "$httpParamSerializerJQLike",
    "LenderResultService",
    "UserService",
    "AuthService",
    "RoleService",
    "CaseMemberService",
    "CaseService",
    "InviteService",
    "OrganisationService",
    "FileAttachmentService",
    "DevelopmentInputService",
    "PaymentService",
    "DevelopmentInputWithNoLoginService",
    "SelectListService",
    "ClientService",
    "DealClientService",
    "DealService",
  ];

  constructor(
    private $CaseService: CaseService,
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $cookies: ng.cookies.ICookiesService,
    private $location: ng.ILocationService,
    private $http: ng.IHttpService,
    private $httpParamSerializerJQLike: ng.IHttpParamSerializer,
    private $lenderresultservice: LenderResultService,
    private $user: UserService,
    private $auth: AuthService,
    private $roleService: RoleService,
    private caseMember: CaseMemberService,
    private caseService: CaseService,
    private inviteService: InviteService,
    private organizationService: OrganisationService,
    private fileAttachmentService: FileAttachmentService,
    private developmentInputService: DevelopmentInputService,
    private $paymentService: PaymentService,
    private devInputWithNoLoginService: DevelopmentInputWithNoLoginService,
    private selectListService: SelectListService,
    private clientService: ClientService,
    private dealClientService: DealClientService,
    private dealService: DealService,
  ) {
    // If the user is from security web, prepopulate the information
    if (
      this.$location.path().startsWith("/registershareholder") ||
      this.$routeParams.uniqueId ||
      this.$location.path().startsWith("/bridgingregistershareholder") ||
      this.$location.path().startsWith("/dealregistershareholder")
    ) {
      this.isShareholderRegistration = true;

      if (
        this.$location.path().startsWith("/bridgingregistershareholder") ||
        this.$location.path().startsWith("/dealregistershareholder")
      ) {
        this.dealClientService
          .fetchDealClientByUniqueId(this.$routeParams.uniqueId)
          .then((response) => {
            if (response.IsVerified) {
              this.$location.path("/login");
            } else {
              this.$user
                .checkUserNameExists(response.Client.Email)
                .then((userExists: boolean) => {
                  if (userExists) {
                    (this.$rootScope as any).loginRedirectPath =
                      "/dealsecuritycheck/" + this.$routeParams.uniqueId;
                    this.$location.path("/login");
                  } else {
                    this.newUser.Email = response.Client.Email;
                    this.newUser.FirstName = response.Client.FirstName;
                    this.newUser.LastName = response.Client.LastName;
                    this.newUser.PhoneNumber = response.Client.PhoneNumber;
                    this.newUser.OrgUniqueRef = this.$routeParams.brokerOrgUniqueRef;
                    this.newUser.DealClientUniqueRef = this.$routeParams.uniqueId;

                  }
                });
            }
          });
      } else {
        this.caseMember
          .fetchCaseMember(this.$routeParams.uniqueId)
          .then((response) => {
            if (response.IsVerified) {
              this.$location.path("/login");
            } else {
              this.$user
                .checkUserNameExists(response.Email)
                .then((userExists: boolean) => {
                  if (userExists) {
                    (this.$rootScope as any).loginRedirectPath =
                      "/securitycheck/" + this.$routeParams.uniqueId;
                    this.$location.path("/login");
                  } else {
                    this.newUser.Email = response.Email;
                    this.newUser.FirstName = response.FirstName;
                    this.newUser.LastName = response.Surname;
                    this.newUser.PhoneNumber = response.PhoneNumber;
                    this.newUser.OrgUniqueRef =
                      this.$routeParams.brokerOrgUniqueRef;
                  }
                });
            }
          });
      }
    }

    // if the user is coming through this path, it is a broker invited by organisation to join it.
    if (this.$location.path().startsWith("/registerbroker")) {
      this.newUser.OrgUniqueRef = this.$routeParams.uniqueRef;
      this.newUser.Roles = ["Broker", "Introducer"];
      (this.$rootScope as any).openWelcomeBrokerModal = true;
      //console.log((this.$rootScope as any).openWelcomeBrokerModal);
    }

    // If a broker invited the user to the platform we assign this broker org on the new user Organisation Referral Id.
    if (this.$location.path().startsWith("/registerborrower")) {
      this.newUser.OrgUniqueRef = this.$routeParams["brokerOrgUniqueRef"];
    }
    // if the user is coming through this path, it is a borrower wanting to view a search but they must register first
    else if (
      (this.$location.path().startsWith("/registerwithsearch") &&
        this.$routeParams["brokerOrgUniqueRef"]) ||
      (this.$location.path().startsWith("/registerwithbridgingsearch") &&
        this.$routeParams["brokerOrgUniqueRef"]) ||
      (this.$location.path().startsWith("/registerwithdeal") &&
        this.$routeParams["brokerOrgUniqueRef"])
    ) {
      this.newUser.OrgUniqueRef = this.$routeParams["brokerOrgUniqueRef"];
    }

    if (
      (this.$location.path().startsWith("/registerwithbridgingsearch") &&
        this.$routeParams["dealclientuniqueref"]) ||
      (this.$location.path().startsWith("/registerwithdeal") &&
        this.$routeParams["dealclientuniqueref"])
    ) {
      this.newUser.DealClientUniqueRef =
        this.$routeParams["dealclientuniqueref"];
      this.dealService
        .fetchProductFamilyByClientUniqueRef(
          this.$routeParams["dealclientuniqueref"],
        )
        .then((response) => {
          this.productFamily = response;
        });
    }

    // If we have an introducer code set, let's store a cookie.
    if (this.$routeParams.introducercode) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("introducer_code", this.$routeParams.introducercode, {
        expires: expiryDate,
      });
    } else if (this.$routeParams["ic"]) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("introducer_code", this.$routeParams["ic"], {
        expires: expiryDate,
      });
    }

    if (this.$routeParams["orgc"]) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 100);
      this.$cookies.put("org_code", this.$routeParams["orgc"], {
        expires: expiryDate,
      });
    }

    if (
      !location.pathname.startsWith("/otmx") &&
      !location.pathname.startsWith("/lnhn") &&
      location.pathname.replace("/", "") != null &&
      location.pathname.replace("/", "").length > 0
    ) {
      this.orgCode = location.pathname.replace("/", "");
    }

    if (this.$routeParams["invite"]) {
      this.inviteGuid = this.$routeParams["invite"];
    }

    this.loanCriteria = (this.$rootScope as any).loanCriteria;

    if (this.$routeParams.enquire !== undefined) {
      this.enquireAfterRegistration = true;
    }

    if (this.$cookies.get("access_token")) {
      this.$user.getcurentuserrecord().then((result) => {
        this.newUser = result;

        if (this.newUser.IsLegacyUser) {
          this.isConvertingExistingUser = true;
          this.newUser = result;

          this.newUser.Roles = ["Client"];

          this.newUser.IsOrganisationAdmin = false;
          this.newUser.ApplicantDefinedRole = ApplicantRoleEnum.Developer;
          this.newUser.ApplicantDefinedRoleIntroducer = null;
        } else {
          this.$roleService.goHomeBasedOnUser();
        }
      });
    } else {
      if (this.$routeParams["noLoginUniqueRef"]) {
        this.redirectToSearch = true;
        this.devInputWithNoLoginService
          .fetchDataByUniqueId(this.$routeParams["noLoginUniqueRef"])
          .then((noLoginSearchDto: DevelopmentWithNoLoginDTO) => {
            if (noLoginSearchDto) {
              if (noLoginSearchDto.IsDeleted) {
                this.redirectToSearch = false;
                sessionStorage.setItem("returningFromdeletedSearch", "true");
              }
              this.newUser.FirstName = noLoginSearchDto.IntroduceeFirstName;
              this.newUser.LastName = noLoginSearchDto.IntroduceeSurname;
              this.newUser.Email = noLoginSearchDto.IntroduceeEmail;
              this.newUser.PhoneNumber = noLoginSearchDto.IntroduceePhone;
            }
          });
      } else if (
        (this.$location.path().startsWith("/registerwithbridgingsearch") &&
          this.$routeParams["dealclientuniqueref"]) ||
        (this.$location.path().startsWith("/registerwithdeal") &&
          this.$routeParams["dealclientuniqueref"])
      ) {
        this.redirectToSearch = true;
        this.clientService
          .fetchByDealClientUniqueRef(this.$routeParams["dealclientuniqueref"])
          .then((clientDto: ClientDTO) => {
            if (clientDto) {
              if (clientDto.IsDeleted) {
                this.redirectToSearch = false;
                sessionStorage.setItem("returningFromdeletedSearch", "true");
              }
              this.newUser.FirstName = clientDto.FirstName;
              this.newUser.LastName = clientDto.LastName;
              this.newUser.Email = clientDto.Email;
              this.newUser.PhoneNumber = clientDto.PhoneNumber;
            }
          });
      } else {
        //Grab details to prefill from lender results page
        this.newUser.FirstName = (this.$rootScope as any).newUserFirstName;
        this.newUser.LastName = (this.$rootScope as any).newUserSurname;
        this.newUser.Email = (this.$rootScope as any).newUserEmail;
      }

      this.newUser.ApplicantDefinedRole = (this.$rootScope as any).newUserRole;

      if (
        this.$location.path().startsWith("/registerbroker") &&
        this.$routeParams.uniqueRef !== undefined
      ) {
        this.newUser.ApplicantDefinedRole = ApplicantRoleEnum.Introducer;
        this.newUser.ApplicantDefinedRoleIntroducer = 3;
      }

      if (sessionStorage.getItem("userDetails")) {
        let data = sessionStorage.getItem("userDetails");
        let userdetails = JSON.parse(data);

        let userFullName = userdetails.FullName;
        let spaceIndex = userFullName.indexOf(" ");

        let firstName = "";
        let lastName = "";

        // if not space has been added to the name then put the whole name in the first name field
        if (spaceIndex == -1) {
          firstName = userFullName;
        } else {
          firstName = userFullName.substring(0, userFullName.indexOf(" "));
          lastName = userFullName.substring(userFullName.indexOf(" ") + 1);
        }

        this.newUser.FirstName = firstName;
        this.newUser.LastName = lastName;
        this.newUser.Email = userdetails.Email;
        this.newUser.PhoneNumber = userdetails.PhoneNumber;
      }
    }

    if (
      sessionStorage.getItem("selectedPackage") &&
      sessionStorage.getItem("selectedPackage") != "undefined"
    ) {
      let data = sessionStorage.getItem("selectedPackage");
      this.selectedPackage = JSON.parse(data);
    }

    this.referrerOptions = this.selectListService.GetReferrerOptions();
    this.locationOptions = this.selectListService.GetLocations();
  }

  registerAccount() {
    this.registeringAccount = true;
    this.dataLoading = true;
    this.isPayClicked = true;
    if (
      !this.isConvertingExistingUser &&
      (this.newUser as any).ConfirmPassword !== this.newUser.Password
    ) {
      this.error = "Passwords do not match";
      this.dataLoading = false;
      this.isPayClicked = false;
    } else {
      //If has already agreed to terms, let's set this in the new user record
      if ((this.$rootScope as any).lenderResultsIntroSeen === true) {
        this.newUser.LenderResultsTermsAgreed = true;
      }

      this.newUser.UserName = this.newUser.Email; //set user name to be same as email address for now

      // If we have an org code, let's set it.
      if (this.$cookies.get("org_code")) {
        this.newUser.OrgCode = this.$cookies.get("org_code");
      }
      // If we have an introducer code, let's set it.
      if (this.$cookies.get("introducer_code")) {
        this.newUser.IntroducerCode = this.$cookies.get("introducer_code");
        this.userRegistration();
      } else {
        this.inviteService
          .checkIfRegisterUserWasInvited(this.newUser.Email, this.inviteGuid)
          .then((result) => {
            this.newUser.IntroducerCode = result;
            this.userRegistration();
          });
      }
    }
  }

  userRegistration() {
    // send registration

    var broker = this.newUser.Roles.filter((x) => x == "Broker");

    // Register Broker User
    if (broker != null && broker.length > 0) {
      this.organizationService
        .registerBroker(this.newUser, this.newUser.OrgUniqueRef)
        .then((user) => {
          this.login(user);
        })
        .catch((response) => {
          this.error = response.data.Message;
          this.registeringAccount = false;
          this.dataLoading = false;
        })
        .finally(() => {
          this.registeringAccount = false;
        });
    } else {
      if (this.inviteGuid != null && this.inviteGuid.length > 0) {
        this.newUser.InviteCode = this.inviteGuid;
      }

      // Register Client User
      this.$user
        .addUpdateWithOrgUniqueRef(this.newUser)
        .then((user) => {
          // clean up session storage
          sessionStorage.removeItem("userDetails");
          sessionStorage.removeItem("clientId");
          sessionStorage.removeItem("userRole");
          sessionStorage.removeItem("projectname");

          if (
            this.$cookies.get("access_token") &&
            this.isConvertingExistingUser
          ) {
            // clear and reset cached data based on the new information
            this.$auth.getProfile().then((response) => {
              this.$cookies.put("user_firstname", response.FirstName);
              (this.$rootScope as any).selectedUser = response;
            });

            (this.$rootScope as any).selectedUser = null;
            (this.$rootScope as any).currentUser = null;

            this.$roleService.GetUserRoles(true); //reset user roles

            sessionStorage.setItem("isUpgradedUser", "true");

            this.onRegistrationComplete();
          } else if (!this.$cookies.get("access_token")) {
            this.login(user);
          } else {
            this.error = "logged in but not converting an existing user";
            this.registeringAccount = false;
            this.dataLoading = false;
          }
        })
        .catch((response) => {
          this.error = response.data.Message;
          this.registeringAccount = false;
          this.dataLoading = false;
        })
        .finally(() => {
          this.registeringAccount = false;
        });
    }
  }

  login(user: ApplicationUserDTO) {
    (this.$rootScope as any).selectedUser = user;
    if (!this.$cookies.get("access_token")) {
      this.$auth
        .login(
          this.newUser.Email,
          this.newUser.Password,
          "CC022EBE67BE0424EA2A6548B062D2D71",
        )
        .then((response) => {
          this.$user.getcurentuserrecord().then((response) => {
            (this.$rootScope as any).currentUser = response;
          });

          let expiry: Date = response;

          this.$cookies.put("user_firstname", user.FirstName, {
            expires: expiry,
          });

          if (
            (this.$location.path().indexOf("/registerwithsearch") !== -1 &&
              this.$routeParams["noLoginUniqueRef"]) ||
            (this.$location.path().indexOf("/registerwithbridgingsearch") !==
              -1 &&
              this.$routeParams["dealclientuniqueref"]) ||
            (this.$location.path().indexOf("/registerwithdeal") !== -1 &&
              this.$routeParams["dealclientuniqueref"])
          ) {
            if (this.$routeParams["noLoginUniqueRef"]) {
              var url =
                "/referredSearch/" + this.$routeParams["noLoginUniqueRef"];
              if (
                this.$routeParams["brokerOrgUniqueRef"] &&
                this.$routeParams["brokerUserId"]
              ) {
                url +=
                  "/" +
                  this.$routeParams["brokerOrgUniqueRef"] +
                  "/" +
                  this.$routeParams["brokerUserId"];
              }
            } else if (this.$routeParams["searchId"]) {
              var url = "/bridgingresults/" + this.$routeParams["searchId"];
              if (
                this.$routeParams["brokerOrgUniqueRef"] &&
                this.$routeParams["brokerUserId"]
              ) {
                url +=
                  "/" +
                  this.$routeParams["brokerOrgUniqueRef"] +
                  "/" +
                  this.$routeParams["brokerUserId"];
              }
            } else if (this.$routeParams["dealclientuniqueref"]) {
              switch (this.productFamily) {
                case ProductFamilyEnum.Bridging:
                  var url =
                    "/referredsearchdeal/" +
                    this.$routeParams["dealclientuniqueref"];
                  break;
                case ProductFamilyEnum.Commercial:
                  var url =
                    "/referredsearchcommercial/" +
                    this.$routeParams["dealclientuniqueref"];
                  break;
                case ProductFamilyEnum.Development:
                  var url =
                    "/referredsearchdevfinance/" +
                    this.$routeParams["dealclientuniqueref"];
                  break;
                default:
                  var url =
                    "/referredsearchdeal/" +
                    this.$routeParams["dealclientuniqueref"];
                  break;
              }
              //if (this.$routeParams['brokerOrgUniqueRef'] && this.$routeParams['brokerUserId']) {
              //    url += "/" + this.$routeParams['brokerOrgUniqueRef'] + "/" + this.$routeParams['brokerUserId'];
              //}
            }

            if (this.redirectToSearch) {
              this.$location.path(url);
            } else {
              this.$location.path("/userdashboard");
            }
          } else if (
            this.loanCriteria &&
            !(
              this.loanCriteria.CI_Dev_OrigPP == 0 &&
              this.loanCriteria.CI_EndPropType == 0 &&
              this.loanCriteria.CI_GDV == 0 &&
              this.loanCriteria.CI_Dev_LoanReq == 0
            )
          ) {
            if (this.selectedPackage) {
              this.selectedProductList.push(this.selectedPackage);
              this.createCheckoutSession();
            } else {
              this.loanCriteria.UserId = user.Id;
              this.loanCriteria.User = user;
              this.loanCriteria.SaveQueryAndResults = true;
              var lenderName: string = "";
              if (this.selectedResult != null) {
                lenderName = this.selectedResult.LenderName;
                this.loanLabel = this.selectedResult.LoanLabel;
              }

              this.$lenderresultservice
                .fetchMatchingResults(
                  this.loanCriteria,
                  true,
                  true,
                  4,
                  false,
                  "",
                  user.Email,
                  user.PhoneNumber,
                  lenderName,
                )
                .then((response) => {
                  if (this.loanCriteria.SaveCase === true) {
                    //case creation
                    this.$CaseService
                      .promotesearchtocase(
                        response.Id,
                        (this.$rootScope as any)
                          .selectedResultForAttachingToCase.Id,
                        (this.$rootScope as any).productResultList,
                      )
                      .then((response) => {
                        this.$location.path("/userdashboard");
                      });
                  } else {
                    this.$location.path("/userdashboard");
                  }
                });
            }
          } else if (this.$routeParams.uniqueId) {
            if (
              this.$location
                .path()
                .startsWith("/bridgingregistershareholder") ||
              this.$location.path().startsWith("/dealregistershareholder")
            ) {
              this.$location.path(
                "/dealsecuritycheck/" + this.$routeParams.uniqueId,
              );
            } else {
              this.$location.path(
                "/securitycheck/" + this.$routeParams.uniqueId,
              );
            }
          } else if (
            this.$location.path().indexOf("/startprojectappraisal") !== -1
          ) {
            this.createNewBlankCase();
          } else if (this.$location.path().indexOf("/applyfordevloan") !== -1) {
            this.createNewBlankCase(true);
          } else {
            this.onRegistrationComplete();
          }

          if (this.enquireAfterRegistration) {
            // Send enquiry and pop up?
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    } else {
      this.$auth
        .getProfile()
        .then((response) => {
          this.$roleService.goHomeBasedOnUser();
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  onRegistrationComplete() {
    if (this.selectedPackage) {
      this.selectedProductList.push(this.selectedPackage);
      this.createCheckoutSession();
    } else {
      if (this.redirectToSearch) {
        var url = "/referredSearch/" + this.$routeParams["noLoginUniqueRef"];
        if (
          this.$routeParams["brokerOrgUniqueRef"] &&
          this.$routeParams["brokerUserId"]
        ) {
          url +=
            "/" +
            this.$routeParams["brokerOrgUniqueRef"] +
            "/" +
            this.$routeParams["brokerUserId"];
        }

        this.$location.path(url);
      } else {
        this.$location.path("/userdashboard");
      }
    }
  }

  change() {
    if (!this.isConvertingExistingUser) {
      if ((this.newUser as any).ConfirmPassword === this.newUser.Password) {
        this.passworderror = "";
      } else {
        this.passworderror = "Passwords do not match";
      }
    }
  }

  emailChange() {
    if (this.error) {
      this.error = "";
    }
  }

  clearRegistration() {
    this.registeringAccount = false;
  }

  go(path): void {
    this.$location.path(path);
  }

  createNewBlankCase(applyForDevLoan: boolean = false) {
    this.$auth.getProfile().then((prof) => {
      var currentUser: ApplicationUserDTO = prof;
      this.$roleService.GetUserRoles().then((result) => {
        var isPrimaryApplicant: boolean = true;

        if (result.filter((x) => x == "Broker").length > 0) {
          isPrimaryApplicant = false;
        }

        this.caseService
          .newBlankCase(currentUser, isPrimaryApplicant)
          .then((newBlankCaseDto) => {
            // If applying for a development loan then go to the case dashboard of the new case
            if (applyForDevLoan == true) {
              this.$location.path(
                "/casedashboard/" + newBlankCaseDto.NewCaseId,
              );
            } else {
              // If starting a project appraisal then go directly to the appraisal module of the new case
              this.$location.path(
                "/criteriaappraisal/" +
                  newBlankCaseDto.NewCaseId +
                  "/" +
                  newBlankCaseDto.NewSearchId,
              );
            }
          });
      });
    });
  }

  fillRegisterForm() {
    let name = new Date().toLocaleString().replace(/ |,|:|\/|/g, "");
    this.newUser.Email = `${name}@test.com`;
    this.newUser.FirstName = name;
    this.newUser.LastName = "t";
    this.newUser.PhoneNumber = "12345677";
    this.newUser.Location = 1;
    this.newUser.ApplicantDefinedRole = 0;
    this.newUser.Referrer = 1;
    this.newUser.Password = "test";
    (this.newUser as any).ConfirmPassword = "test";
  }

  next() {
    this.step++;
  }

  back() {
    this.step--;
  }

  changeProfileLogo(files) {
    if (files.length > 0) {
      this.fileAttachmentService
        .uploadFileAndReturnURI(files)
        .then((result) => {
          if (result) {
            this.newUser.ProfilePictureUrl = result.FileLocation;
            this.getLogoURL(this.newUser.ProfilePictureUrl);
          }
        });
    }
  }

  getLogoURL(url: string) {
    this.fileAttachmentService.getFileUri(url).then((result) => {
      this.profileLogo = result;
      //console.log(this.profileLogo);
    });
  }

  isFirstStepValid() {
    if (
      !this.registrationFormStep1.$invalid &&
      !(
        this.newUser.Referrer == ReferrerEnum.Other &&
        this.newUser.ReferrerOther == null
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  isSecondStepValid() {
    if (
      !this.registrationFormStep2.$invalid &&
      this.newUser.AgreedToTermsAndPP === true &&
      !this.passworderror &&
      !this.registeringAccount &&
      !this.dataLoading
    ) {
      return true;
    } else {
      return false;
    }
  }

  createCheckoutSession() {
    //this.selectedProductList[0].SelectedModuleAppFeature = this.moduleAppFeatures;
    // Need to save the search so we can get back to it after payment ($rootscope will lose the data)
    if ((this.$rootScope as any).loanCriteria != null) {
      (this.$rootScope as any).loanCriteria.UserId = (
        this.$rootScope as any
      ).selectedUser.Id;
      (this.$rootScope as any).loanCriteria.BrokerOrganisationId = (
        this.$rootScope as any
      ).selectedUser.OrganisationId;
      this.developmentInputService
        .saveSearch((this.$rootScope as any).loanCriteria)
        .then((developmentInputId: number) => {
          this.$paymentService
            .createCheckoutSession(
              this.selectedPackage,
              1,
              "",
              developmentInputId,
            )
            .then((response) => {
              window.location.assign(response.SessionUrl);
            });
        });
    } else {
      this.$paymentService
        .createCheckoutSession(this.selectedPackage, 1, "", null)
        .then((response) => {
          window.location.assign(response.SessionUrl);
        });
    }
  }

  cancelCheckout() {
    if ((this.$rootScope as any).loanCriteria) {
      this.$location.path("/results");
    } else {
      this.$location.path("/userdashboard");
    }
  }

  getApplicationName() {
    if (sessionStorage.getItem("applicationName") != null) {
      return sessionStorage.getItem("applicationName");
    } else {
      return "Brickflow";
    }
  }
}

import { BillingFrequencyEnum } from "@js/models/enum/BillingFrequencyEnum.cs.d";
import { BorrowingEntityTypeEnum } from "@js/models/enum/BorrowingEntityTypeEnum.cs.d";
import { BridgingPersonalGuaranteeLevelEnum } from "@js/models/enum/BridgingPersonalGuaranteeLevelEnum.cs.d";
import { BuildProcurementTypeEnum } from "@js/models/enum/BuildProcurementTypeEnum.cs.d";
import { BuildStageEnum } from "@js/models/enum/BuildStageEnum.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { ChargeTypeEnum } from "@js/models/enum/ChargeTypeEnum.cs.d";
import { ConditionEnum } from "@js/models/enum/ConditionEnum.cs.d";
import { EPCRatingTypeEnum } from "@js/models/enum/EPCRatingTypeEnum.cs.d";
import { EWS1GradeTypeEnum } from "@js/models/enum/EWS1GradeTypeEnum.cs.d";
import { ExistingPlanningChangeTypeEnum } from "@js/models/enum/ExistingPlanningChangeTypeEnum.cs.d";
import { ExitStrategyEnum } from "@js/models/enum/ExitStrategyEnum.cs.d";
import { FixedRateTermEnum } from "@js/models/enum/FixedRateTermEnum.cs.d";
import { InterestRateBasis } from "@js/models/enum/InterestRateBasisEnum.cs.d";
import { InterestRateTypeEnum } from "@js/models/enum/InterestRateTypeEnum.cs.d";
import { InterestServicedorRolledEnum } from "@js/models/enum/InterestServicedorRolledEnum.cs.d";
import { LandOrPropertyEnum } from "@js/models/enum/LandOrPropertyEnum.cs.d";
import { LinkTypeEnum } from "@js/models/enum/LinkTypeEnum.cs.d";
import { LoanCompletionTypeEnum } from "@js/models/enum/LoanCompletionTypeEnum.cs.d";
import { LoanPurposeEnum } from "@js/models/enum/LoanPurposeEnum.cs.d";
import { LoanRepaymentTypeEnum } from "@js/models/enum/LoanRepaymentTypeEnum.cs.d";
import { LocationEnum } from "@js/models/enum/LocationEnum.cs.d";
import { MaritalStatusEnum } from "@js/models/enum/MaritalStatusEnum.cs.d";
import { MaxFlatUnitPriceEnum } from "@js/models/enum/MaxFlatUnitPriceEnum.cs.d";
import { MaxHouseSalePriceEnum } from "@js/models/enum/MaxHouseSalePriceEnum.cs.d";
import { MaxIndividualUnitPriceEnum } from "@js/models/enum/MaxIndividualUnitPriceEnum.cs.d";
import { MaxPercCommercialForMixedUseEnum } from "@js/models/enum/MaxPercCommercialForMixedUseEnum.cs.d";
import { MaxSqFtSalePriceEnum } from "@js/models/enum/MaxSqFtSalePriceEnum.cs.d";
import { OwnershipRoleEnum } from "@js/models/enum/OwnershipRoleEnum.cs.d";
import { OwnNewDevelopmentEnum } from "@js/models/enum/OwnNewDevelopmentEnum.cs.d";
import { OwnOrPurchaseEnum } from "@js/models/enum/OwnOrPurchaseEnum.cs.d";
import { PersonalGuaranteeLevelEnum } from "@js/models/enum/PersonalGuaranteeLevelEnum.cs.d";
import { PlanningPermissionTypeEnum } from "@js/models/enum/PlanningPermissionTypeEnum.cs.d";
import { PlanningResponsibilityEnum } from "@js/models/enum/PlanningResponsibilityEnum.cs.d";
import { PreAgreementEnum } from "@js/models/enum/PreAgreementEnum.cs.d";
import { PreviousDevelopmentsEnum } from "@js/models/enum/PreviousDevelopmentsEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { ProjectCompletionTypeEnum } from "@js/models/enum/ProjectCompletionTypeEnum.cs.d";
import { PropertyTypeEnum } from "@js/models/enum/PropertyTypeEnum.cs.d";
import { ReferrerEnum } from "@js/models/enum/ReferrerEnum.cs.d";
import { RefurbishmentLevelEnum } from "@js/models/enum/RefurbishmentLevelEnum.cs.d";
import { ScheduleDescriptionTypeEnum } from "@js/models/enum/ScheduleDescriptionTypeEnum.cs.d";
import { ShareholderDepositRequiredEnum } from "@js/models/enum/ShareholderDepositRequiredEnum.cs.d";
import { SiteTypeEnum } from "@js/models/enum/SiteTypeEnum.cs.d";
import { SourceOfIncomeEnum } from "@js/models/enum/SourceOfIncomeEnum.cs.d";
import { TeamSizeEnum } from "@js/models/enum/TeamSizeEnum.cs.d";
import { TenureEnum } from "@js/models/enum/TenureEnum.cs.d";
import { TimeLeftOnLeaseEnum } from "@js/models/enum/TimeLeftOnLeaseEnum.cs.d";
import { TitleEnum } from "@js/models/enum/TitleEnum.cs.d";
import { TradingPeriodEnum } from "@js/models/enum/TradingPeriodEnum.cs.d";
import { YesNoEnum } from "@js/models/enum/YesNoEnum.cs.d";
import { YesNoMaybe } from "@js/models/enum/YesNoMaybeEnum.cs.d";
import { ValuationMethodEnum } from "@js/models/enum/ValuationMethodEnum.cs.d";
import { MaxCommercialFloorspaceEnum } from "@js/models/enum/MaxCommercialFloorspaceEnum.cs.d";
import { ProductService } from "./ProductService";

interface ListItem {
  key: number;
  displayName: string;
  order: number;
}

export class SelectListService {
  SelectLists: { [key: string]: ListItem[] } = {};
  lastOwnOrPurchase: any;
  lastLandOrProperty: any;

  productService: ProductService;

  static $inject = ["ProductService"];

  constructor(ProductService: ProductService) {
    this.productService = ProductService;
  }

  GetMaritalStatusOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["MaritalStatusEnum"] == null
    ) {
      this.SelectLists["MaritalStatusEnum"] = [
        { key: MaritalStatusEnum.Single, displayName: "Single", order: 1 },
        { key: MaritalStatusEnum.Married, displayName: "Married", order: 2 },
        {
          key: MaritalStatusEnum.LivingWithPartner,
          displayName: "Living With Partner",
          order: 3,
        },
        { key: MaritalStatusEnum.Widowed, displayName: "Widowed", order: 4 },
        {
          key: MaritalStatusEnum.CivilPartnership,
          displayName: "Civil Partnership",
          order: 5,
        },
        { key: MaritalStatusEnum.Divorced, displayName: "Divorced", order: 6 },
        {
          key: MaritalStatusEnum.Seperated,
          displayName: "Seperated",
          order: 7,
        },
      ];
    }

    return this.SelectLists["MaritalStatusEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetTenureTypes(productType: ProductTypeEnum): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["TenureEnum"] == null) {
      if (productType != ProductTypeEnum.BridgingPreconstruction) {
        this.SelectLists["TenureEnum"] = [
          { key: TenureEnum.Freehold, displayName: "Freehold", order: 1 },
          { key: TenureEnum.Leasehold, displayName: "Leasehold", order: 3 },
        ];
      } else {
        this.SelectLists["TenureEnum"] = [
          { key: TenureEnum.Freehold, displayName: "Freehold", order: 1 },
          {
            key: TenureEnum.ShareOfFreehold,
            displayName: "Share Of Freehold",
            order: 2,
          },
          { key: TenureEnum.Leasehold, displayName: "Leasehold", order: 3 },
          {
            key: TenureEnum.CommonholdOwnership,
            displayName: "Commonhold Ownership",
            order: 4,
          },
          //{ key: TenureEnum.JointOwnershipTenantsInCommon, displayName: 'Joint Ownership - Tenants In Common', order: 5 },
          //{ key: TenureEnum.JointOwnershipJointTenants, displayName: 'Joint Ownership - Joint Tenants', order: 6 }
        ];
      }
    }

    return this.SelectLists["TenureEnum"].sort((a, b) => a.order - b.order);
  }

  GetNumberOfPreviousDevelopments(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["PreviousDevelopmentsEnum"] == null
    ) {
      this.SelectLists["PreviousDevelopmentsEnum"] = [
        { key: PreviousDevelopmentsEnum.One, displayName: "1", order: 1 },
        { key: PreviousDevelopmentsEnum.Two, displayName: "2", order: 2 },
        { key: PreviousDevelopmentsEnum.Three, displayName: "3", order: 3 },
        {
          key: PreviousDevelopmentsEnum.FourOrMore,
          displayName: "4 +",
          order: 4,
        },
      ];
    }
    return this.SelectLists["PreviousDevelopmentsEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetNumberOfPreviousDevelopmentsForBridging(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["PreviousDevelopmentsEnumBridging"] == null
    ) {
      this.SelectLists["PreviousDevelopmentsEnumBridging"] = [
        {
          key: PreviousDevelopmentsEnum.OneOrTwo,
          displayName: "1 - 2",
          order: 1,
        },
        {
          key: PreviousDevelopmentsEnum.ThreeOrMore,
          displayName: "3 +",
          order: 2,
        },
      ];
    }
    return this.SelectLists["PreviousDevelopmentsEnumBridging"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetShareholderDepositRequired(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["ShareholderDepositRequiredEnum"] == null
    ) {
      this.SelectLists["ShareholderDepositRequiredEnum"] = [
        {
          key: ShareholderDepositRequiredEnum.None,
          displayName: "0%",
          order: 1,
        },
        {
          key: ShareholderDepositRequiredEnum.UpTo25Percent,
          displayName: "Up to 25%",
          order: 2,
        },
        {
          key: ShareholderDepositRequiredEnum.TwentyFiveTo50Percent,
          displayName: "25% - 50%",
          order: 3,
        },
        {
          key: ShareholderDepositRequiredEnum.FiftyPercentPlus,
          displayName: "51%+",
          order: 4,
        },
      ];
    }
    return this.SelectLists["ShareholderDepositRequiredEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetPersonalGuaranteeLevels(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["PersonalGuaranteeLevelEnum"] == null
    ) {
      this.SelectLists["PersonalGuaranteeLevelEnum"] = [
        {
          key: PersonalGuaranteeLevelEnum.UpTo20Percent,
          displayName: "Up to 20%",
          order: 1,
        },
        {
          key: PersonalGuaranteeLevelEnum.TwentyOneTo35Percent,
          displayName: "21-35%",
          order: 2,
        },
        {
          key: PersonalGuaranteeLevelEnum.ThirtySixTo50Percent,
          displayName: "36-50%",
          order: 3,
        },
        {
          key: PersonalGuaranteeLevelEnum.OneHundredPercent,
          displayName: "51-100%",
          order: 4,
        },
      ];
    }
    return this.SelectLists["PersonalGuaranteeLevelEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetBridgingPersonalGuaranteeLevels(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["BridgingPersonalGuaranteeLevelEnum"] == null
    ) {
      this.SelectLists["BridgingPersonalGuaranteeLevelEnum"] = [
        {
          key: BridgingPersonalGuaranteeLevelEnum.None,
          displayName: "0%",
          order: 1,
        },
        {
          key: BridgingPersonalGuaranteeLevelEnum.OneToTwentyFivePercent,
          displayName: "1-25%",
          order: 2,
        },
        {
          key: BridgingPersonalGuaranteeLevelEnum.TwentySixToFiftyPercent,
          displayName: "26-50%",
          order: 3,
        },
        {
          key: BridgingPersonalGuaranteeLevelEnum.FiftyOneToOneHundredPercent,
          displayName: "51-100%",
          order: 4,
        },
      ];
    }
    return this.SelectLists["BridgingPersonalGuaranteeLevelEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetMaxHouseSalePrices(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["MaxHouseSalePriceEnum"] == null
    ) {
      this.SelectLists["MaxHouseSalePriceEnum"] = [
        {
          key: MaxHouseSalePriceEnum.NoMax,
          displayName: "Above £5m",
          order: 5,
        },
        {
          key: MaxHouseSalePriceEnum.UpTo1Million,
          displayName: "Up to £1m",
          order: 2,
        },
        {
          key: MaxHouseSalePriceEnum.UpTo2Million,
          displayName: "Up to £2m",
          order: 3,
        },
        {
          key: MaxHouseSalePriceEnum.UpTo5Million,
          displayName: "Up to £5m",
          order: 4,
        },
      ];
    }

    return this.SelectLists["MaxHouseSalePriceEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetMaxFlatUnitPrices(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["MaxFlatUnitPriceEnum"] == null
    ) {
      this.SelectLists["MaxFlatUnitPriceEnum"] = [
        { key: MaxFlatUnitPriceEnum.None, displayName: "Above £3m", order: 5 },
        {
          key: MaxFlatUnitPriceEnum.UpTo1Million,
          displayName: "Up to £1m",
          order: 2,
        },
        {
          key: MaxFlatUnitPriceEnum.UpTo2Million,
          displayName: "Up to £2m",
          order: 3,
        },
        {
          key: MaxFlatUnitPriceEnum.UpTo3Million,
          displayName: "Up to £3m",
          order: 4,
        },
      ];
    }
    return this.SelectLists["MaxFlatUnitPriceEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetMaxSqFtSalePrices(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["MaxSqFtSalePriceEnum"] == null
    ) {
      this.SelectLists["MaxSqFtSalePriceEnum"] = [
        {
          key: MaxSqFtSalePriceEnum.NoMax,
          displayName: "Above £1500",
          order: 5,
        },
        {
          key: MaxSqFtSalePriceEnum.UpTo850,
          displayName: "Up to £850",
          order: 2,
        },
        {
          key: MaxSqFtSalePriceEnum.UpTo1000,
          displayName: "Up to £1000",
          order: 3,
        },
        {
          key: MaxSqFtSalePriceEnum.UpTo1500,
          displayName: "Up to £1500",
          order: 4,
        },
      ];
    }
    return this.SelectLists["MaxSqFtSalePriceEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetMaxPercCommercialForMixedUse(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["MaxPercCommercialForMixedUseEnum"] == null
    ) {
      this.SelectLists["MaxPercCommercialForMixedUseEnum"] = [
        {
          key: MaxPercCommercialForMixedUseEnum.UpTo25,
          displayName: "Up to 25%",
          order: 1,
        },
        {
          key: MaxPercCommercialForMixedUseEnum.TwentyFiveTo50,
          displayName: "26% - 50%",
          order: 2,
        },
        {
          key: MaxPercCommercialForMixedUseEnum.Over50,
          displayName: "Over 50%",
          order: 3,
        },
      ];
    }
    return this.SelectLists["MaxPercCommercialForMixedUseEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetInterestRateBasis(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["InterestRateBasis"] == null
    ) {
      this.SelectLists["InterestRateBasis"] = [
        { key: InterestRateBasis.BaseRate, displayName: "Base Rate", order: 1 },
        { key: InterestRateBasis.Sonia, displayName: "Sonia", order: 3 },
        {
          key: InterestRateBasis.LendersOwnSVR,
          displayName: "Lenders Own SVR",
          order: 2,
        },
      ];
    }
    return this.SelectLists["InterestRateBasis"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetReferrerOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["ReferrerOptions"] == null
    ) {
      this.SelectLists["ReferrerOptions"] = [
        {
          key: ReferrerEnum.OtherSearch,
          displayName: "Please Select",
          order: 1,
        },
        {
          key: ReferrerEnum.GoogleSearch,
          displayName: "Google Search",
          order: 9,
        },
        {
          key: ReferrerEnum.OtherSearch,
          displayName: "Other Search",
          order: 14,
        },
        { key: ReferrerEnum.LinkedIn, displayName: "LinkedIn", order: 10 },
        { key: ReferrerEnum.Facebook, displayName: "Facebook", order: 7 },
        { key: ReferrerEnum.Agent, displayName: "Estate Agent", order: 5 },
        {
          key: ReferrerEnum.ExistingUser,
          displayName: "Existing User",
          order: 6,
        },
        {
          key: ReferrerEnum.BrickflowConsultant,
          displayName: this.getAppName() + " Consultant",
          order: 20,
        },
        { key: ReferrerEnum.Broker, displayName: "Broker", order: 3 },
        { key: ReferrerEnum.Developer, displayName: "Developer", order: 4 },
        {
          key: ReferrerEnum.MassReports,
          displayName: "Mass Reports",
          order: 11,
        },
        { key: ReferrerEnum.NimbusMaps, displayName: "Nimbus Maps", order: 12 },
        {
          key: ReferrerEnum.PressRelease,
          displayName: "Press Release",
          order: 17,
        },
        { key: ReferrerEnum.TheRodcast, displayName: "The Rodcast", order: 16 },
        { key: ReferrerEnum.OTM, displayName: "OnTheMarket", order: 13 },
        {
          key: ReferrerEnum.WordOfMouth,
          displayName: "Word of Mouth",
          order: 19,
        },
        {
          key: ReferrerEnum.PersonalRecommendation,
          displayName: "Personal Recommendation",
          order: 15,
        },
        { key: ReferrerEnum.Ad, displayName: "Ad", order: 2 },
        {
          key: ReferrerEnum.SocialMedia,
          displayName: "Social Media",
          order: 18,
        },
        { key: ReferrerEnum.FIBA, displayName: "FIBA", order: 8 },
        { key: ReferrerEnum.Other, displayName: "Other", order: 21 },
      ];
    }
    return this.SelectLists["ReferrerOptions"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetBillingFrequencyOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["BillingFrequencyOptions"] == null
    ) {
      this.SelectLists["BillingFrequencyOptions"] = [
        { key: BillingFrequencyEnum.Monthly, displayName: "Monthly", order: 1 },
        { key: BillingFrequencyEnum.Yearly, displayName: "Annually", order: 2 },
      ];
    }
    return this.SelectLists["BillingFrequencyOptions"];
  }

  GetTeamSizeOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["TeamSizeOptions"] == null
    ) {
      this.SelectLists["TeamSizeOptions"] = [
        { key: TeamSizeEnum.One, displayName: "1", order: 1 },
        { key: TeamSizeEnum.TwoToFive, displayName: "2 - 5", order: 2 },
        { key: TeamSizeEnum.SixToTen, displayName: "6 - 10", order: 3 },
        { key: TeamSizeEnum.ElevenToTwenty, displayName: "11 - 20", order: 4 },
        { key: TeamSizeEnum.TwentyOnePlus, displayName: "21+", order: 5 },
      ];
    }

    return this.SelectLists["TeamSizeOptions"];
  }

  getAppName(): string {
    const lsd = sessionStorage.getItem("applicationName");
    if (lsd != null) {
      return lsd;
    } else {
      return "Brickflow";
    }
  }

  GetYesNo(): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["YesNoEnum"] == null) {
      this.SelectLists["YesNoEnum"] = [
        { key: YesNoEnum.No, displayName: "No", order: 1 },
        { key: YesNoEnum.Yes, displayName: "Yes", order: 2 },
      ];
    }
    return this.SelectLists["YesNoEnum"].sort((a, b) => a.order - b.order);
  }

  GetOwnOrPurchase(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["OwnOrPurchaseEnum"] == null
    ) {
      this.SelectLists["OwnOrPurchaseEnum"] = [
        { key: OwnOrPurchaseEnum.Own, displayName: "Own", order: 1 },
        {
          key: OwnOrPurchaseEnum.Purchasing,
          displayName: "Purchasing",
          order: 2,
        },
      ];
    }
    return this.SelectLists["OwnOrPurchaseEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetChargeType(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["ChargeTypeEnum"] == null
    ) {
      this.SelectLists["ChargeTypeEnum"] = [
        {
          key: ChargeTypeEnum.FirstCharge,
          displayName: "First Charge",
          order: 1,
        },
        {
          key: ChargeTypeEnum.SecondCharge,
          displayName: "Second Charge",
          order: 2,
        },
      ];
    }
    return this.SelectLists["ChargeTypeEnum"].sort((a, b) => a.order - b.order);
  }

  GetYesNoForBridging(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["YesNoEnumBridging"] == null
    ) {
      this.SelectLists["YesNoEnumBridging"] = [
        { key: YesNoMaybe.No, displayName: "No", order: 1 },
        { key: YesNoMaybe.Yes, displayName: "Yes", order: 2 },
      ];
    }
    return this.SelectLists["YesNoEnumBridging"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetBuildingConditionType(): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["conditionEnum"] == null) {
      this.SelectLists["conditionEnum"] = [
        { key: ConditionEnum.Good, displayName: "Good", order: 1 },
        { key: ConditionEnum.Fair, displayName: "Fair", order: 2 },
        { key: ConditionEnum.Poor, displayName: "Poor", order: 2 },
      ];
    }
    return this.SelectLists["conditionEnum"].sort((a, b) => a.order - b.order);
  }

  GetSiteType(): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["SiteTypeEnum"] == null) {
      this.SelectLists["SiteTypeEnum"] = [
        {
          key: SiteTypeEnum.ExistingBuilding,
          displayName: "Existing Building",
          order: 1,
        },
        { key: SiteTypeEnum.Land, displayName: "Land", order: 2 },
      ];
    }
    return this.SelectLists["SiteTypeEnum"].sort((a, b) => a.order - b.order);
  }

  GetPropertyType(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["PropertyTypeEnum"] == null
    ) {
      this.SelectLists["PropertyTypeEnum"] = [
        {
          key: PropertyTypeEnum.Residential,
          displayName: "Residential",
          order: 14,
        },
        { key: PropertyTypeEnum.MixedUse, displayName: "Mixed use", order: 10 },
        { key: PropertyTypeEnum.Hotel, displayName: "Hotel", order: 4 },
        { key: PropertyTypeEnum.Leisure, displayName: "Leisure", order: 6 },
        {
          key: PropertyTypeEnum.CareRetirement,
          displayName: "Care home",
          order: 1,
        },
        { key: PropertyTypeEnum.Medical, displayName: "Medical", order: 9 },
        { key: PropertyTypeEnum.Office, displayName: "Office", order: 11 },
        { key: PropertyTypeEnum.Retail, displayName: "Retail", order: 12 },
        {
          key: PropertyTypeEnum.LightIndustrial,
          displayName: "Light industrial",
          order: 8,
        },
        { key: PropertyTypeEnum.Student, displayName: "Student", order: 15 },
        {
          key: PropertyTypeEnum.HeavyIndustrial,
          displayName: "Heavy industrial",
          order: 3,
        },
        { key: PropertyTypeEnum.HMO, displayName: "Licenced HMO", order: 7 },
        {
          key: PropertyTypeEnum.LandWithoutPlanning,
          displayName: "Land without planning",
          order: 5,
        },
        { key: PropertyTypeEnum.Education, displayName: "Education", order: 2 },
        {
          key: PropertyTypeEnum.Retirement,
          displayName: "Retirement",
          order: 13,
        },
      ];
    }
    return this.SelectLists["PropertyTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetProductTypeOptions(isUserDashboard: boolean = false): ListItem[] {
    const selectListName: string = isUserDashboard
      ? "UserDashboardProductType"
      : "ProductTypeEnum";

    if (this.SelectLists == null || this.SelectLists[selectListName] == null) {
      this.SelectLists[selectListName] = [
        { key: ProductTypeEnum.None, displayName: "None", order: 1 },
        {
          key: ProductTypeEnum.Development,
          displayName: this.productService.getProductTypeDisplayText(
            ProductTypeEnum.Development,
          ),
          order: 2,
        },
        {
          key: ProductTypeEnum.BridgingPreconstruction,
          displayName: this.productService.getProductTypeDisplayText(
            ProductTypeEnum.BridgingPreconstruction,
          ),
          order: 3,
        },
        {
          key: ProductTypeEnum.BridgingDeveloperExit,
          displayName:
            this.productService.getProductTypeDisplayText(
              ProductTypeEnum.BridgingDeveloperExit,
            ) + " Bridge",
          order: 4,
        },
        {
          key: ProductTypeEnum.BridgingRefurb,
          displayName:
            this.productService.getProductTypeDisplayText(
              ProductTypeEnum.BridgingRefurb,
            ) + " Bridge",
          order: 5,
        },
        {
          key: ProductTypeEnum.CommercialInvestment,
          displayName: this.productService.getProductTypeDisplayText(
            ProductTypeEnum.CommercialInvestment,
          ),
          order: 6,
        },
        {
          key: ProductTypeEnum.BridgingPurchaseOrRefinance,
          displayName:
            this.productService.getProductTypeDisplayText(
              ProductTypeEnum.BridgingPurchaseOrRefinance,
            ) + " Bridge",
          order: 7,
        },
      ];

      if (isUserDashboard) {
        this.SelectLists[selectListName].push({
          key: ProductTypeEnum.CommercialOwnerOccupied,
          displayName: "Commercial Owner Occupied",
          order: 8,
        });
      } else {
        this.SelectLists[selectListName].push({
          key: ProductTypeEnum.CommercialOwnerOccupied,
          displayName: this.productService.getProductTypeDisplayText(
            ProductTypeEnum.CommercialOwnerOccupied,
          ),
          order: 8,
        });
      }
    }

    return this.SelectLists[selectListName].sort((a, b) => a.order - b.order);
  }

  GetProductFamilyOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["ProductFamilyEnum"] == null
    ) {
      this.SelectLists["ProductFamilyEnum"] = [
        {
          key: ProductFamilyEnum.Development,
          displayName: "Development Finance",
          order: 1,
        },
        { key: ProductFamilyEnum.Bridging, displayName: "Bridging", order: 2 },
        {
          key: ProductFamilyEnum.Commercial,
          displayName: "Commercial",
          order: 3,
        },
      ];
    }
    return this.SelectLists["ProductFamilyEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetInterestServiceOrRoll(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["interestServicedOrRolledEnum"] == null
    ) {
      this.SelectLists["interestServicedOrRolledEnum"] = [
        {
          key: InterestServicedorRolledEnum.Serviced,
          displayName: "Serviced",
          order: 2,
        },
        {
          key: InterestServicedorRolledEnum.Rolled,
          displayName: "Rolled up",
          order: 1,
        },
      ];
    }
    return this.SelectLists["interestServicedOrRolledEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }
  GetSourceOfIncomeOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["sourceOfIncomeEnum"] == null
    ) {
      this.SelectLists["sourceOfIncomeEnum"] = [
        {
          key: SourceOfIncomeEnum.currentRentalFromThisProperty,
          displayName: "Current rental from this property",
          order: 1,
        },
        {
          key: SourceOfIncomeEnum.futureRentalFromThisProperty,
          displayName: "Future rental from this property",
          order: 4,
        },
        {
          key: SourceOfIncomeEnum.fundsFromYourSavings,
          displayName: "Funds from your savings",
          order: 3,
        },
        {
          key: SourceOfIncomeEnum.fundsFromYourIncome,
          displayName: "Funds from your income",
          order: 2,
        },
      ];
    }
    return this.SelectLists["sourceOfIncomeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetLoanPurposeOptions(isRegulatedBridging: boolean = false): ListItem[] {
    if (isRegulatedBridging) {
      this.SelectLists["loanPurposeEnum"] = [
        {
          key: LoanPurposeEnum.AuctionPurchase,
          displayName: "Auction purchase ",
          order: 1,
        },
        {
          key: LoanPurposeEnum.ChainBreak,
          displayName: "Chain break ",
          order: 2,
        },
        {
          key: LoanPurposeEnum.Refurbishment,
          displayName: "Refurbishment",
          order: 3,
        },
        {
          key: LoanPurposeEnum.AnyOtherLegalPurpose,
          displayName: "Any other legal purpose (e.g. probate, tax bill etc.)",
          order: 4,
        },
      ];
    } else {
      this.SelectLists["loanPurposeEnum"] = [
        {
          key: LoanPurposeEnum.Speed,
          displayName: "Speed i.e. auction purpose",
          order: 1,
        },
        {
          key: LoanPurposeEnum.DepositForAnotherPurchase,
          displayName: "Deposit for another purchase",
          order: 2,
        },
        { key: LoanPurposeEnum.Rebridge, displayName: "Rebridge", order: 3 },
        {
          key: LoanPurposeEnum.Other,
          displayName: "Other, please state",
          order: 4,
        },
      ];
    }
    return this.SelectLists["loanPurposeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetPlanningOptions(
    OwnOrPurchase: OwnOrPurchaseEnum,
    LandOrProperty: LandOrPropertyEnum,
    showPermittedDevelopment: boolean = true,
  ): ListItem[] {
    if (this.SelectLists == null) {
      this.SelectLists = {};
    }

    if (
      !this.SelectLists["planningEnum"] ||
      this.lastOwnOrPurchase !== OwnOrPurchase ||
      this.lastLandOrProperty !== LandOrProperty
    ) {
      this.lastOwnOrPurchase = OwnOrPurchase;
      this.lastLandOrProperty = LandOrProperty;

      const ownOrPurchase =
        OwnOrPurchase == OwnOrPurchaseEnum.Own ? "Own" : "Buying";
      const landOrProperty =
        LandOrProperty == LandOrPropertyEnum.Land ? "land" : "property";
      this.SelectLists["planningEnum"] = [
        {
          key: PlanningPermissionTypeEnum.NoPlanning,
          displayName: `${ownOrPurchase} ${landOrProperty} with no planning`,
          order: 2,
        },
        {
          key: PlanningPermissionTypeEnum.OutlinePlanning,
          displayName: `${ownOrPurchase} ${landOrProperty} with outline planning`,
          order: 3,
        },
        {
          key: PlanningPermissionTypeEnum.DetailedPlanning,
          displayName: `${ownOrPurchase} ${landOrProperty} with detailed planning that you wish to vary`,
          order: 1,
        },
      ];
    }

    const permittedDevelopmentIndex = this.SelectLists["planningEnum"]
      .map((e) => e.key)
      .indexOf(PlanningPermissionTypeEnum.PermittedDevelopment);

    if (showPermittedDevelopment && permittedDevelopmentIndex == -1) {
      this.SelectLists["planningEnum"].push({
        key: PlanningPermissionTypeEnum.PermittedDevelopment,
        displayName: "Permitted development",
        order: 4,
      });
    } else if (!showPermittedDevelopment && permittedDevelopmentIndex > -1) {
      this.SelectLists["planningEnum"].splice(permittedDevelopmentIndex);
    }

    return this.SelectLists["planningEnum"].sort((a, b) => a.order - b.order);
  }

  GetPlanningStatusAtStartOfDevelopmentOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["PlanningStatusAtStartOfDevelopment"] == null
    ) {
      this.SelectLists["PlanningStatusAtStartOfDevelopment"] = [
        {
          key: PlanningPermissionTypeEnum.NoPlanning,
          displayName: "No planning",
          order: 2,
        },
        {
          key: PlanningPermissionTypeEnum.OutlinePlanning,
          displayName: "Outline planning",
          order: 3,
        },
        {
          key: PlanningPermissionTypeEnum.DetailedPlanning,
          displayName: "Detailed planning that you wish to vary",
          order: 1,
        },
        {
          key: PlanningPermissionTypeEnum.PermittedDevelopment,
          displayName: "Permitted development",
          order: 4,
        },
      ];
    }
    return this.SelectLists["PlanningStatusAtStartOfDevelopment"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetPlanningStatusAtEndOfDevelopmentOptions(
    productType: ProductTypeEnum = null,
  ): ListItem[] {
    if (productType == ProductTypeEnum.Development) {
      if (
        this.SelectLists == null ||
        this.SelectLists["PlanningPermissionTypeEnumDevFinance"] == null
      ) {
        this.SelectLists["PlanningPermissionTypeEnumDevFinance"] = [
          {
            key: PlanningPermissionTypeEnum.NoPlanning,
            displayName: "No planning",
            order: 2,
          },
          {
            key: PlanningPermissionTypeEnum.OutlinePlanning,
            displayName: "Outline planning",
            order: 4,
          },
          {
            key: PlanningPermissionTypeEnum.DetailedPlanning,
            displayName: "Detailed planning",
            order: 1,
          },
          {
            key: PlanningPermissionTypeEnum.NoPlanningRequired,
            displayName: "No planning required",
            order: 3,
          },
        ];
      }
      return this.SelectLists["PlanningPermissionTypeEnumDevFinance"].sort(
        (a, b) => a.order - b.order,
      );
    } else {
      if (
        this.SelectLists == null ||
        this.SelectLists["PlanningPermissionTypeEnum"] == null
      ) {
        this.SelectLists["PlanningPermissionTypeEnum"] = [
          {
            key: PlanningPermissionTypeEnum.OutlinePlanning,
            displayName: "Outline planning",
            order: 2,
          },
          {
            key: PlanningPermissionTypeEnum.DetailedPlanning,
            displayName: "Detailed planning",
            order: 1,
          },
          {
            key: PlanningPermissionTypeEnum.PermittedDevelopment,
            displayName: "Permitted development",
            order: 3,
          },
        ];
      }
      return this.SelectLists["PlanningPermissionTypeEnum"].sort(
        (a, b) => a.order - b.order,
      );
    }
  }

  GetPurchasingExistingBuildingPlanningTypeOptions(
    showPermittedDevelopment: boolean = true,
  ): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists[
      "PurchasingExistingBuildingPlanningPermissionTypeEnum"
      ] == null
    ) {
      this.SelectLists["PurchasingExistingBuildingPlanningPermissionTypeEnum"] =
        [
          {
            key: PlanningPermissionTypeEnum.VaryPlanningonExistingBuilding,
            displayName: "Vary planning on the existing building only",
            order: 3,
          },
          {
            key: PlanningPermissionTypeEnum.NewPlanningOnAnotherPartOfSite,
            displayName:
              "New planning on another part of the site (planning on existing building to stay as is)",
            order: 2,
          },
          {
            key: PlanningPermissionTypeEnum.VaryPlanningOnExistingAndNewPlanningOnAnotherPartOfSite,
            displayName:
              "Vary planning on the existing building & submit new planning on another part of the site",
            order: 4,
          },
          {
            key: PlanningPermissionTypeEnum.NewPlanningApplicationOrDemolishExistingBuilding,
            displayName:
              "New planning application / demolish existing building",
            order: 1,
          },
        ];
    }

    const permittedDevelopmentIndex = this.SelectLists[
      "PurchasingExistingBuildingPlanningPermissionTypeEnum"
    ]
      .map((e) => e.key)
      .indexOf(PlanningPermissionTypeEnum.PermittedDevelopment);

    if (showPermittedDevelopment && permittedDevelopmentIndex == -1) {
      this.SelectLists[
        "PurchasingExistingBuildingPlanningPermissionTypeEnum"
      ].push({
        key: PlanningPermissionTypeEnum.PermittedDevelopment,
        displayName: "Permitted development",
        order: 4,
      });
    } else if (!showPermittedDevelopment && permittedDevelopmentIndex > -1) {
      this.SelectLists[
        "PurchasingExistingBuildingPlanningPermissionTypeEnum"
      ].splice(permittedDevelopmentIndex);
    }

    return this.SelectLists[
      "PurchasingExistingBuildingPlanningPermissionTypeEnum"
    ].sort((a, b) => a.order - b.order);
  }

  GetCompletionOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["projectCompletionTypeEnum"] == null
    ) {
      this.SelectLists["projectCompletionTypeEnum"] = [
        {
          key: ProjectCompletionTypeEnum.LessThan1Week,
          displayName: "Less than 1 week",
          order: 1,
        },
        {
          key: ProjectCompletionTypeEnum.LessThan2Week,
          displayName: "Less than 2 week",
          order: 2,
        },
        {
          key: ProjectCompletionTypeEnum.NoSetTime,
          displayName: "Own land with no outline planning",
          order: 3,
        },
      ];
    }
    return this.SelectLists["projectCompletionTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetCaseStatusOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["CaseStatusEnum"] == null
    ) {
      this.SelectLists["CaseStatusEnum"] = [
        { key: CaseStatusEnum.Search, displayName: "Search", order: 1 },
        { key: CaseStatusEnum.NewCase, displayName: "New Case", order: 2 },
        {
          key: CaseStatusEnum.InProgress,
          displayName: "In Progress",
          order: 3,
        },
        {
          key: CaseStatusEnum.UnderReview,
          displayName: "Under Review",
          order: 4,
        },
        {
          key: CaseStatusEnum.ReadyToSubmit,
          displayName: "Ready To Submit",
          order: 5,
        },
        {
          key: CaseStatusEnum.SubmittedToLendersForHoT,
          displayName: "Submitted To Lenders for DIP",
          order: 6,
        },
        {
          key: CaseStatusEnum.ReadyToReSubmit,
          displayName: "Ready to Re-Submit",
          order: 7,
        },
        { key: CaseStatusEnum.Applied, displayName: "Applied", order: 8 },
        {
          key: CaseStatusEnum.CreditApproved,
          displayName: "Credit Approved",
          order: 9,
        },
        {
          key: CaseStatusEnum.WithProfessionals,
          displayName: "With Professionals",
          order: 10,
        },
        { key: CaseStatusEnum.Completed, displayName: "Completed", order: 11 },
        { key: CaseStatusEnum.Dormant, displayName: "Dormant", order: 12 },
        {
          key: CaseStatusEnum.SubmittedToLendersPendingReview,
          displayName: "Submitted To Lenders Pending Review",
          order: 13,
        },
      ];
    }

    return this.SelectLists["CaseStatusEnum"].sort((a, b) => a.order - b.order);
  }

  GetCaseLenderStateOptionsForLenders(): ListItem[] {
    if (this.SelectLists == null || this.SelectLists['CaseLenderStateEnumForLender'] == null) {
      this.SelectLists['CaseLenderStateEnumForLender'] = [
        { key: CaseLenderStateEnum.Received, displayName: 'Case to review', order: 1 },
        { key: CaseLenderStateEnum.Offered, displayName: 'DIP submitted', order: 2 },
        { key: CaseLenderStateEnum.Rejected, displayName: 'Case rejected', order: 3 },
        { key: CaseLenderStateEnum.Withdrawn, displayName: 'DIP withdrawn', order: 4 },
        { key: CaseLenderStateEnum.Applied, displayName: 'DIP submitted and accepted', order: 5 },
        { key: CaseLenderStateEnum.Cancelled, displayName: 'Application cancelled', order: 6 }
      ];
    }

    return this.SelectLists["CaseLenderStateEnumForLender"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetExitStrategy(excludeOther: boolean = true): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["ExitStrategyEnum"] == null
    ) {
      this.SelectLists["ExitStrategyEnum"] = [
        { key: ExitStrategyEnum.Sale, displayName: "Sale", order: 3 },
        { key: ExitStrategyEnum.Refinance, displayName: "Refinance", order: 2 },
        {
          key: ExitStrategyEnum.PartSalePartRefinance,
          displayName: "Part sale & part refinance",
          order: 1,
        },
        {
          key: ExitStrategyEnum.SaleOfAlternativeAsset,
          displayName: "Sale of an alternative asset",
          order: 4,
        },
      ];
    }

    const otherIndex = this.SelectLists["ExitStrategyEnum"]
      .map((e) => e.key)
      .indexOf(ExitStrategyEnum.Other);

    if (!excludeOther && otherIndex == -1) {
      this.SelectLists["ExitStrategyEnum"].push({
        key: ExitStrategyEnum.Other,
        displayName: "Other",
        order: 5,
      });
    } else if (excludeOther && otherIndex > -1) {
      this.SelectLists["ExitStrategyEnum"].splice(otherIndex);
    }

    return this.SelectLists["ExitStrategyEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetExitStrategyDevFinanceOptions() {
    if (
      this.SelectLists == null ||
      this.SelectLists["ExitStrategyDevFinanceEnum"] == null
    ) {
      this.SelectLists["ExitStrategyDevFinanceEnum"] = [
        { key: ExitStrategyEnum.Sale, displayName: "Sale of units", order: 3 },
        { key: ExitStrategyEnum.Refinance, displayName: "Refinance", order: 2 },
        {
          key: ExitStrategyEnum.PartSalePartRefinance,
          displayName: "Part sale & part refinance",
          order: 1,
        },
      ];
    }

    return this.SelectLists["ExitStrategyDevFinanceEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetOwnNewDevelopmentOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["OwnNewDevelopmentEnum"] == null
    ) {
      this.SelectLists["OwnNewDevelopmentEnum"] = [
        {
          key: OwnNewDevelopmentEnum.OverseasLimitedCompany,
          displayName: "Overseas Limited Company",
          order: 1,
        },
        {
          key: OwnNewDevelopmentEnum.PensionFund,
          displayName: "Pension Fund",
          order: 2,
        },
        {
          key: OwnNewDevelopmentEnum.PersonalNames,
          displayName: "Personal Names",
          order: 3,
        },
        {
          key: OwnNewDevelopmentEnum.UKLimitedCompany,
          displayName: "UK Limited Company",
          order: 4,
        },
        {
          key: OwnNewDevelopmentEnum.UKLimitedLiabilityPartnership,
          displayName: "UK Limited Partnership",
          order: 5,
        },
      ];
    }
    return this.SelectLists["OwnNewDevelopmentEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetCommercialOwnNewDevelopmentOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["CommercialOwnNewDevelopmentEnum"] == null
    ) {
      this.SelectLists["CommercialOwnNewDevelopmentEnum"] = [
        {
          key: OwnNewDevelopmentEnum.OverseasLimitedCompany,
          displayName: "Overseas Limited Company",
          order: 1,
        },
        {
          key: OwnNewDevelopmentEnum.PensionFund,
          displayName: "Pension Fund",
          order: 2,
        },
        {
          key: OwnNewDevelopmentEnum.PersonalNames,
          displayName: "Personal Names",
          order: 3,
        },
        {
          key: OwnNewDevelopmentEnum.UKLimitedCompany,
          displayName: "UK Limited Company",
          order: 4,
        },
        {
          key: OwnNewDevelopmentEnum.UKLimitedLiabilityPartnership,
          displayName: "Limited Liability Partnership",
          order: 5,
        },
      ];
    }
    return this.SelectLists["CommercialOwnNewDevelopmentEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetTitleOptions(): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["TitleEnum"] == null) {
      this.SelectLists["TitleEnum"] = [
        { key: TitleEnum.Mr, displayName: "Mr", order: 1 },
        { key: TitleEnum.Mrs, displayName: "Mrs", order: 2 },
        { key: TitleEnum.Miss, displayName: "Miss", order: 3 },
        { key: TitleEnum.Ms, displayName: "Ms", order: 4 },
        { key: TitleEnum.Other, displayName: "Other", order: 5 },
      ];
    }
    return this.SelectLists["TitleEnum"].sort((a, b) => a.order - b.order);
  }

  GetLoanCompletionOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["LoanCompletionTypeEnum"] == null
    ) {
      this.SelectLists["LoanCompletionTypeEnum"] = [
        {
          key: LoanCompletionTypeEnum.AsSoonAsPossible,
          displayName: "As soon as possible",
          order: 1,
        },
        {
          key: LoanCompletionTypeEnum.JustCheckingMyOptions,
          displayName: "Just checking my options",
          order: 3,
        },
        {
          key: LoanCompletionTypeEnum.WithinNextFewWeeks,
          displayName: "Within the next few weeks",
          order: 2,
        },
      ];
    }
    return this.SelectLists["LoanCompletionTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetInterestRateType(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["InterestRateType"] == null
    ) {
      this.SelectLists["InterestRateType"] = [
        { key: InterestRateTypeEnum.Fixed, displayName: "Fixed", order: 1 },
        {
          key: InterestRateTypeEnum.Variable,
          displayName: "Variable",
          order: 2,
        },
      ];
    }
    return this.SelectLists["InterestRateType"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetExistingPlanningChangeTypeOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["ExistingPlanningChangeTypeEnum"] == null
    ) {
      this.SelectLists["ExistingPlanningChangeTypeEnum"] = [
        {
          key: ExistingPlanningChangeTypeEnum.EnhancedPlanningOnExistingBuilding,
          displayName:
            "Enhanced planning on existing building e.g extension or add another storey",
          order: 1,
        },
        {
          key: ExistingPlanningChangeTypeEnum.PermittedDevelopmentOnExistingBuilding,
          displayName:
            "Permitted development on the existing building e.g convert commerical to residential",
          order: 2,
        },
        {
          key: ExistingPlanningChangeTypeEnum.BrandNewPlanningApplication,
          displayName:
            "Brand new planning application e.g knock down existing building & rebuild",
          order: 3,
        },
      ];
    }
    return this.SelectLists["ExistingPlanningChangeTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetBuildStageOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["BuildStageEnum"] == null
    ) {
      this.SelectLists["BuildStageEnum"] = [
        {
          key: BuildStageEnum.PracticalCompletion,
          displayName: "Practical Completion",
          order: 1,
        },
        {
          key: BuildStageEnum.WindAndWaterTight,
          displayName: "Wind & Water Tight",
          order: 2,
        },
        {
          key: BuildStageEnum.PreWindAndWaterTight,
          displayName: "Pre Wind And Water Tight",
          order: 3,
        },
      ];
    }
    return this.SelectLists["BuildStageEnum"].sort((a, b) => a.order - b.order);
  }

  GetOwnershipRoleOptions(
    productType: ProductTypeEnum = ProductTypeEnum.None,
  ): ListItem[] {
    // if (this.SelectLists == null || this.SelectLists['OwnerShipRoleEnum'] == null) {
    this.SelectLists["OwnerShipRoleEnum"] = [
      { key: OwnershipRoleEnum.Director, displayName: "Director", order: 1 },
      {
        key: OwnershipRoleEnum.DirectorAndShareholder,
        displayName: "Director & Shareholder",
        order: 3,
      },
      {
        key: OwnershipRoleEnum.Shareholder,
        displayName: "Shareholder",
        order: 2,
      },
    ];

    if (productType == ProductTypeEnum.CommercialOwnerOccupied) {
      this.SelectLists["OwnerShipRoleEnum"].push({
        key: OwnershipRoleEnum.Member,
        displayName: "Member",
        order: 4,
      });
      this.SelectLists["OwnerShipRoleEnum"].push({
        key: OwnershipRoleEnum.Partner,
        displayName: "Partner",
        order: 5,
      });
    }
    //}
    return this.SelectLists["OwnerShipRoleEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetRefurbishmentLevelOptions(includeNone: boolean = false): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["RefurbishmentLevelEnum"] == null
    ) {
      this.SelectLists["RefurbishmentLevelEnum"] = [
        { key: RefurbishmentLevelEnum.Light, displayName: "Light", order: 1 },
        { key: RefurbishmentLevelEnum.Medium, displayName: "Medium", order: 2 },
        { key: RefurbishmentLevelEnum.Heavy, displayName: "Heavy", order: 3 },
      ];
    }

    const noneIndex = this.SelectLists["RefurbishmentLevelEnum"]
      .map((e) => e.key)
      .indexOf(RefurbishmentLevelEnum.None);

    if (includeNone && noneIndex == -1) {
      this.SelectLists["RefurbishmentLevelEnum"].push({
        key: RefurbishmentLevelEnum.None,
        displayName: "None",
        order: 0,
      });
    } else if (!includeNone && noneIndex > -1) {
      this.SelectLists["RefurbishmentLevelEnum"].splice(noneIndex);
    }

    return this.SelectLists["RefurbishmentLevelEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetCurrentUseOfBuildingOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["DevFinancePropertyTypeEnum"] == null
    ) {
      this.SelectLists["DevFinancePropertyTypeEnum"] = [
        {
          key: PropertyTypeEnum.Residential,
          displayName: "Residential",
          order: 16,
        },
        { key: PropertyTypeEnum.MixedUse, displayName: "Mixed Use", order: 10 },
        { key: PropertyTypeEnum.Hotel, displayName: "Hotel", order: 4 },
        { key: PropertyTypeEnum.Leisure, displayName: "Leisure", order: 6 },
        {
          key: PropertyTypeEnum.CareRetirement,
          displayName: "Care Home",
          order: 1,
        },
        { key: PropertyTypeEnum.Medical, displayName: "Medical", order: 9 },
        { key: PropertyTypeEnum.Office, displayName: "Office", order: 13 },
        { key: PropertyTypeEnum.Retail, displayName: "Retail", order: 14 },
        {
          key: PropertyTypeEnum.LightIndustrial,
          displayName: "Light Industrial",
          order: 8,
        },
        { key: PropertyTypeEnum.Student, displayName: "Student", order: 17 },
        {
          key: PropertyTypeEnum.HeavyIndustrial,
          displayName: "Heavy Industrial",
          order: 3,
        },
        { key: PropertyTypeEnum.HMO, displayName: "Licenced HMO", order: 7 },
        //{ key: PropertyTypeEnum.LandWithoutPlanning, displayName: 'Land without Planning', order: 5 },
        { key: PropertyTypeEnum.Education, displayName: "Education", order: 2 },
        {
          key: PropertyTypeEnum.Retirement,
          displayName: "Retirement",
          order: 15,
        },
        /*{ key: PropertyTypeEnum.AllCommercialMixedUse, displayName: 'Mixed Use (all commercial)', order: 11 },*/
        /*{ key: PropertyTypeEnum.MultiUnitFreeholdBlock, displayName: 'Multi-Unit Freehold Block', order: 12 },*/
      ];
    }
    return this.SelectLists["DevFinancePropertyTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetCommercialCurrentUseOfBuildingOptions(
    productType = ProductTypeEnum.CommercialInvestment,
  ): ListItem[] {
    //if (this.SelectLists == null || this.SelectLists['CommercialPropertyTypeEnum'] == null) {
    this.SelectLists["CommercialPropertyTypeEnum"] = [
      {
        key: PropertyTypeEnum.MixedUse,
        displayName: "Mixed Use (part residential, part commercial)",
        order: 9,
      },
      { key: PropertyTypeEnum.Hotel, displayName: "Hotel", order: 4 },
      { key: PropertyTypeEnum.Leisure, displayName: "Leisure", order: 5 },
      {
        key: PropertyTypeEnum.CareRetirement,
        displayName: "Care Home",
        order: 1,
      },
      { key: PropertyTypeEnum.Medical, displayName: "Medical", order: 8 },
      { key: PropertyTypeEnum.Office, displayName: "Office", order: 12 },
      { key: PropertyTypeEnum.Retail, displayName: "Retail", order: 13 },
      {
        key: PropertyTypeEnum.LightIndustrial,
        displayName: "Light Industrial",
        order: 7,
      },
      { key: PropertyTypeEnum.Student, displayName: "Student", order: 15 },
      {
        key: PropertyTypeEnum.HeavyIndustrial,
        displayName: "Heavy Industrial",
        order: 3,
      },
      { key: PropertyTypeEnum.Education, displayName: "Education", order: 2 },
      {
        key: PropertyTypeEnum.Retirement,
        displayName: "Retirement",
        order: 14,
      },
      {
        key: PropertyTypeEnum.AllCommercialMixedUse,
        displayName: "Mixed Use (all commercial)",
        order: 10,
      },
    ];

    if (productType == ProductTypeEnum.CommercialInvestment) {
      this.SelectLists["CommercialPropertyTypeEnum"].push({
        key: PropertyTypeEnum.HMO,
        displayName: "Licenced HMO",
        order: 6,
      });
      this.SelectLists["CommercialPropertyTypeEnum"].push({
        key: PropertyTypeEnum.MultiUnitFreeholdBlock,
        displayName: "Multi-Unit Freehold Block",
        order: 11,
      });
    }

    //}
    return this.SelectLists["CommercialPropertyTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetLoanRepaymentTypeOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["LoanRepaymentTypeEnum"] == null
    ) {
      this.SelectLists["LoanRepaymentTypeEnum"] = [
        {
          key: LoanRepaymentTypeEnum.InterestOnly,
          displayName: "Interest only",
          order: 2,
        },
        {
          key: LoanRepaymentTypeEnum.Repayment,
          displayName: "Capital & Interest",
          order: 1,
        },
      ];
    }

    return this.SelectLists["LoanRepaymentTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetFixedRateTermOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["FixedRateTermEnum"] == null
    ) {
      this.SelectLists["FixedRateTermEnum"] = [
        { key: FixedRateTermEnum.TwoYear, displayName: "2 Year", order: 1 },
        { key: FixedRateTermEnum.FiveYear, displayName: "5 Year", order: 2 },
        { key: FixedRateTermEnum.TenYear, displayName: "10 Year", order: 3 },
      ];
    }

    return this.SelectLists["FixedRateTermEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetTimeLeftOnLeaseOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["TimeLeftOnLeaseEnum"] == null
    ) {
      this.SelectLists["TimeLeftOnLeaseEnum"] = [
        {
          key: TimeLeftOnLeaseEnum.LessThanYear,
          displayName: "Less than Year",
          order: 1,
        },
        {
          key: TimeLeftOnLeaseEnum.OneToThreeYears,
          displayName: "1 - 3 Years",
          order: 2,
        },
        {
          key: TimeLeftOnLeaseEnum.MoreThanThreeYears,
          displayName: "More than 3 Years",
          order: 3,
        },
      ];
    }

    return this.SelectLists["TimeLeftOnLeaseEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetLinkTypeOptions(
    showBridging: boolean = false,
    showCommercial: boolean = false,
  ): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["LinkTypeEnum"] == null) {
      this.SelectLists["LinkTypeEnum"] = [
        {
          key: LinkTypeEnum.Development,
          displayName: "Development Finance",
          order: 4,
        },
        { key: LinkTypeEnum.Bridging, displayName: "Bridging", order: 2 },
        { key: LinkTypeEnum.AllLoans, displayName: "All Loans", order: 1 },
        {
          key: LinkTypeEnum.Commercial,
          displayName: "Commercial Mortgage",
          order: 3,
        },
      ];
    }

    const bridgingIndex = this.SelectLists["LinkTypeEnum"]
      .map((e) => e.key)
      .indexOf(LinkTypeEnum.Bridging);

    if (showBridging && bridgingIndex == -1) {
      this.SelectLists["LinkTypeEnum"].push({
        key: LinkTypeEnum.Bridging,
        displayName: "Bridging",
        order: 2,
      });
    } else if (!showBridging && bridgingIndex > -1) {
      this.SelectLists["LinkTypeEnum"].splice(bridgingIndex);
    }

    const commercialIndex = this.SelectLists["LinkTypeEnum"]
      .map((e) => e.key)
      .indexOf(LinkTypeEnum.Commercial);

    if (showCommercial && commercialIndex == -1) {
      this.SelectLists["LinkTypeEnum"].push({
        key: LinkTypeEnum.Commercial,
        displayName: "Commercial Mortgage",
        order: 4,
      });
    } else if (!showCommercial && commercialIndex > -1) {
      this.SelectLists["LinkTypeEnum"].splice(commercialIndex);
    }

    return this.SelectLists["LinkTypeEnum"].sort((a, b) => a.order - b.order);
  }

  GetCountries() {
    const countrylist = [
      "United Kingdom of Great Britain and Northern Ireland (the)",
      "Afghanistan",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antarctica",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas (the)",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia (Plurinational State of)",
      "Bonaire, Sint Eustatius and Saba",
      "Bosnia and Herzegovina",
      "Botswana",
      "Bouvet Island",
      "Brazil",
      "British Indian Ocean Territory (the)",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cayman Islands (the)",
      "Central African Republic (the)",
      "Chad",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos (Keeling) Islands (the)",
      "Colombia",
      "Comoros (the)",
      "Congo (the Democratic Republic of the)",
      "Congo (the)",
      "Cook Islands (the)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Curaçao",
      "Cyprus",
      "Czechia",
      "Côte d'Ivoire",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic (the)",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Falkland Islands (the) [Malvinas]",
      "Faroe Islands (the)",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Territories (the)",
      "Gabon",
      "Gambia (the)",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Heard Island and McDonald Islands",
      "Holy See (the)",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran (Islamic Republic of)",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea (the Democratic People's Republic of)",
      "Korea (the Republic of)",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic (the)",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macao",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands (the)",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Micronesia (Federated States of)",
      "Moldova (the Republic of)",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands (the)",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger (the)",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands (the)",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine, State of",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines (the)",
      "Pitcairn",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Republic of North Macedonia",
      "Romania",
      "Russian Federation (the)",
      "Rwanda",
      "Réunion",
      "Saint Barthélemy",
      "Saint Helena, Ascension and Tristan da Cunha",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin (French part)",
      "Saint Pierre and Miquelon",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Sint Maarten (Dutch part)",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Georgia and the South Sandwich Islands",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan (the)",
      "Suriname",
      "Svalbard and Jan Mayen",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Taiwan",
      "Tajikistan",
      "Tanzania, United Republic of",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks and Caicos Islands (the)",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates (the)",
      "United States Minor Outlying Islands (the)",
      "United States of America (the)",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela (Bolivarian Republic of)",
      "Viet Nam",
      "Virgin Islands (British)",
      "Virgin Islands (U.S.)",
      "Wallis and Futuna",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe",
      "Åland Islands",
    ];
    return countrylist;
  }

  GetNationalities() {
    const nationalityList = [
      "British",
      "Afghan",
      "Albanian",
      "Algerian",
      "American",
      "Andorran",
      "Angolan",
      "Anguillan",
      "Argentine",
      "Armenian",
      "Australian",
      "Austrian",
      "Azerbaijani",
      "Bahamian",
      "Bahraini",
      "Bangladeshi",
      "Barbadian",
      "Belarusian",
      "Belgian",
      "Belizean",
      "Beninese",
      "Bermudian",
      "Bhutanese",
      "Bolivian",
      "Botswanan",
      "Brazilian",
      "British Virgin Islander",
      "Bruneian",
      "Bulgarian",
      "Burkinan",
      "Burmese",
      "Burundian",
      "Cambodian",
      "Cameroonian",
      "Canadian",
      "Cape Verdean",
      "Cayman Islander",
      "Central African",
      "Chadian",
      "Chilean",
      "Chinese",
      "Citizen of Antigua and Barbuda",
      "Citizen of Bosnia and Herzegovina",
      "Citizen of Guinea-Bissau",
      "Citizen of Kiribati",
      "Citizen of Seychelles",
      "Citizen of the Dominican Republic",
      "Citizen of Vanuatu ",
      "Colombian",
      "Comoran",
      "Congolese (Congo)",
      "Congolese (DRC)",
      "Cook Islander",
      "Costa Rican",
      "Croatian",
      "Cuban",
      "Cymraes",
      "Cymro",
      "Cypriot",
      "Czech",
      "Danish",
      "Djiboutian",
      "Dominican",
      "Dutch",
      "East Timorese",
      "Ecuadorean",
      "Egyptian",
      "Emirati",
      "English",
      "Equatorial Guinean",
      "Eritrean",
      "Estonian",
      "Ethiopian",
      "Faroese",
      "Fijian",
      "Filipino",
      "Finnish",
      "French",
      "Gabonese",
      "Gambian",
      "Georgian",
      "German",
      "Ghanaian",
      "Gibraltarian",
      "Greek",
      "Greenlandic",
      "Grenadian",
      "Guamanian",
      "Guatemalan",
      "Guinean",
      "Guyanese",
      "Haitian",
      "Honduran",
      "Hong Konger",
      "Hungarian",
      "Icelandic",
      "Indian",
      "Indonesian",
      "Iranian",
      "Iraqi",
      "Irish",
      "Israeli",
      "Italian",
      "Ivorian",
      "Jamaican",
      "Japanese",
      "Jordanian",
      "Kazakh",
      "Kenyan",
      "Kittitian",
      "Kosovan",
      "Kuwaiti",
      "Kyrgyz",
      "Lao",
      "Latvian",
      "Lebanese",
      "Liberian",
      "Libyan",
      "Liechtenstein citizen",
      "Lithuanian",
      "Luxembourger",
      "Macanese",
      "Macedonian",
      "Malagasy",
      "Malawian",
      "Malaysian",
      "Maldivian",
      "Malian",
      "Maltese",
      "Marshallese",
      "Martiniquais",
      "Mauritanian",
      "Mauritian",
      "Mexican",
      "Micronesian",
      "Moldovan",
      "Monegasque",
      "Mongolian",
      "Montenegrin",
      "Montserratian",
      "Moroccan",
      "Mosotho",
      "Mozambican",
      "Namibian",
      "Nauruan",
      "Nepalese",
      "New Zealander",
      "Nicaraguan",
      "Nigerian",
      "Nigerien",
      "Niuean",
      "North Korean",
      "Northern Irish",
      "Norwegian",
      "Omani",
      "Pakistani",
      "Palauan",
      "Palestinian",
      "Panamanian",
      "Papua New Guinean",
      "Paraguayan",
      "Peruvian",
      "Pitcairn Islander",
      "Polish",
      "Portuguese",
      "Prydeinig",
      "Puerto Rican",
      "Qatari",
      "Romanian",
      "Russian",
      "Rwandan",
      "Salvadorean",
      "Sammarinese",
      "Samoan",
      "Sao Tomean",
      "Saudi Arabian",
      "Scottish",
      "Senegalese",
      "Serbian",
      "Sierra Leonean",
      "Singaporean",
      "Slovak",
      "Slovenian",
      "Solomon Islander",
      "Somali",
      "South African",
      "South Korean",
      "South Sudanese",
      "Spanish",
      "Sri Lankan",
      "St Helenian",
      "St Lucian",
      "Stateless",
      "Sudanese",
      "Surinamese",
      "Swazi",
      "Swedish",
      "Swiss",
      "Syrian",
      "Taiwanese",
      "Tajik",
      "Tanzanian",
      "Thai",
      "Togolese",
      "Tongan",
      "Trinidadian",
      "Tristanian",
      "Tunisian",
      "Turkish",
      "Turkmen",
      "Turks and Caicos Islander",
      "Tuvaluan",
      "Ugandan",
      "Ukrainian",
      "Uruguayan",
      "Uzbek",
      "Vatican citizen",
      "Venezuelan",
      "Vietnamese",
      "Vincentian",
      "Wallisian",
      "Welsh",
      "Yemeni",
      "Zambian",
      "Zimbabwean",
    ];

    return nationalityList;
  }

  GetBorrowingEntityTypeOptions(
    productType: ProductTypeEnum = ProductTypeEnum.None,
  ): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["BorrowingEntityTypeEnum"] == null
    ) {
      if (productType == ProductTypeEnum.CommercialOwnerOccupied) {
        this.SelectLists["BorrowingEntityTypeEnum"] = [
          {
            key: BorrowingEntityTypeEnum.Company,
            displayName: "Limited Co",
            order: 1,
          },
          {
            key: BorrowingEntityTypeEnum.SoleTrader,
            displayName: "Sole Trader",
            order: 6,
          },
          {
            key: BorrowingEntityTypeEnum.Partnership,
            displayName: "Partnership",
            order: 4,
          },
          { key: BorrowingEntityTypeEnum.LLP, displayName: "LLP", order: 2 },
          {
            key: BorrowingEntityTypeEnum.Trust,
            displayName: "Trust",
            order: 7,
          },
          {
            key: BorrowingEntityTypeEnum.SIPPOrSSAS,
            displayName: "SIPP / SSAS",
            order: 5,
          },
          {
            key: BorrowingEntityTypeEnum.OverseasCo,
            displayName: "Overseas Co",
            order: 3,
          },
        ];
      } else {
        this.SelectLists["BorrowingEntityTypeEnum"] = [
          {
            key: BorrowingEntityTypeEnum.Company,
            displayName: "Company",
            order: 1,
          },
          {
            key: BorrowingEntityTypeEnum.Individual,
            displayName: "Individual",
            order: 2,
          },
        ];
      }
    }

    return this.SelectLists["BorrowingEntityTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetMaxIndividualUnitPrices(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["MaxIndividualUnitPriceEnum"] == null
    ) {
      this.SelectLists["MaxIndividualUnitPriceEnum"] = [
        {
          key: MaxIndividualUnitPriceEnum.NoMax,
          displayName: "Above £5m",
          order: 5,
        },
        {
          key: MaxIndividualUnitPriceEnum.UpTo1Million,
          displayName: "Up to £1m",
          order: 2,
        },
        {
          key: MaxIndividualUnitPriceEnum.UpTo2Million,
          displayName: "Up to £2m",
          order: 3,
        },
        {
          key: MaxIndividualUnitPriceEnum.UpTo5Million,
          displayName: "Up to £5m",
          order: 4,
        },
      ];
    }
    return this.SelectLists["MaxIndividualUnitPriceEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetAcceptableEPCRating(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["EPCRatingTypeEnum"] == null
    ) {
      this.SelectLists["EPCRatingTypeEnum"] = [
        { key: EPCRatingTypeEnum.AtoC, displayName: "A - C rating", order: 1 },
        { key: EPCRatingTypeEnum.DtoE, displayName: "D - E rating", order: 2 },
        { key: EPCRatingTypeEnum.AboveE, displayName: "F+ rating", order: 3 },
      ];
    }
    return this.SelectLists["EPCRatingTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetEWS1GradeType(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["EWS1GradeTypeEnum"] == null
    ) {
      this.SelectLists["EWS1GradeTypeEnum"] = [
        { key: EWS1GradeTypeEnum.A, displayName: "A rating", order: 1 },
        { key: EWS1GradeTypeEnum.B, displayName: "B rating", order: 2 },
      ];
    }
    return this.SelectLists["EWS1GradeTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetEPCRating(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["EPCRatingTypeEnum"] == null
    ) {
      this.SelectLists["EPCRatingTypeEnum"] = [
        { key: EPCRatingTypeEnum.A, displayName: "A", order: 1 },
        { key: EPCRatingTypeEnum.B, displayName: "B", order: 2 },
        { key: EPCRatingTypeEnum.C, displayName: "C", order: 3 },
        { key: EPCRatingTypeEnum.D, displayName: "D", order: 4 },
        { key: EPCRatingTypeEnum.E, displayName: "E", order: 5 },
        { key: EPCRatingTypeEnum.AboveE, displayName: "Above E", order: 6 },
      ];
    }
    return this.SelectLists["EPCRatingTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetLocations(): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["LocationEnum"] == null) {
      this.SelectLists["LocationEnum"] = [
        {
          key: LocationEnum.ChannelIsland,
          displayName: "Channel Islands",
          order: 1,
        },
        {
          key: LocationEnum.EastofEngland,
          displayName: "East of England",
          order: 2,
        },
        { key: LocationEnum.London, displayName: "London", order: 3 },
        { key: LocationEnum.Midlands, displayName: "Midlands", order: 4 },
        { key: LocationEnum.NorthEast, displayName: "North East", order: 5 },
        { key: LocationEnum.NorthWest, displayName: "North West", order: 6 },
        {
          key: LocationEnum.NorthernIreland,
          displayName: "Northern Ireland",
          order: 7,
        },
        { key: LocationEnum.Scotland, displayName: "Scotland", order: 8 },
        { key: LocationEnum.SouthEast, displayName: "South East", order: 9 },
        { key: LocationEnum.SouthWest, displayName: "South West", order: 10 },
        { key: LocationEnum.Wales, displayName: "Wales", order: 11 },
      ];
    }
    return this.SelectLists["LocationEnum"].sort((a, b) => a.order - b.order);
  }

  GetPlanningResponsibilityOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["PlanningResponsibilityEnum"] == null
    ) {
      this.SelectLists["PlanningResponsibilityEnum"] = [
        {
          key: PlanningResponsibilityEnum.Borrower,
          displayName: "Borrower",
          order: 1,
        },
        {
          key: PlanningResponsibilityEnum.PreviousOwner,
          displayName: "Previous owner",
          order: 2,
        },
        {
          key: PlanningResponsibilityEnum.SomebodyElse,
          displayName: "Someone else",
          order: 3,
        },
        { key: PlanningResponsibilityEnum.NA, displayName: "N/A", order: 4 },
      ];
    }
    return this.SelectLists["PlanningResponsibilityEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetBuildProcurementTypeOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["BuildProcurementTypeEnum"] == null
    ) {
      this.SelectLists["BuildProcurementTypeEnum"] = [
        {
          key: BuildProcurementTypeEnum.DesignAndBuildContract,
          displayName: "Design and build contract",
          order: 1,
        },
        {
          key: BuildProcurementTypeEnum.TraditionalContract,
          displayName: "Traditional contract (seperate design & build)",
          order: 3,
        },
        {
          key: BuildProcurementTypeEnum.SelfManaged,
          displayName:
            "Self-managed procurement (borrowers will manage the individual trade packages)",
          order: 2,
        },
      ];
    }
    return this.SelectLists["BuildProcurementTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetSceheduleTenureTypes(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["SceheduleTenureEnum"] == null
    ) {
      this.SelectLists["SceheduleTenureEnum"] = [
        { key: TenureEnum.Freehold, displayName: "Freehold", order: 1 },
        { key: TenureEnum.Leasehold, displayName: "Leasehold", order: 2 },
        { key: TenureEnum.Other, displayName: "Other", order: 3 },
      ];
    }

    return this.SelectLists["SceheduleTenureEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetPreAgreementTypes(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["PreAgreementEnum"] == null
    ) {
      this.SelectLists["PreAgreementEnum"] = [
        { key: PreAgreementEnum.PreLet, displayName: "Pre-Let", order: 1 },
        { key: PreAgreementEnum.PreSold, displayName: "Pre-Sold", order: 2 },
        { key: PreAgreementEnum.None, displayName: "None", order: 3 },
      ];
    }

    return this.SelectLists["PreAgreementEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetHouseDescriptionTypes(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["HouseDescriptionTypeEnum"] == null
    ) {
      this.SelectLists["HouseDescriptionTypeEnum"] = [
        {
          key: ScheduleDescriptionTypeEnum.Bungalow,
          displayName: "Bungalow",
          order: 1,
        },
        {
          key: ScheduleDescriptionTypeEnum.Cottage,
          displayName: "Cottage",
          order: 2,
        },
        {
          key: ScheduleDescriptionTypeEnum.DetachedHouse,
          displayName: "Detached house",
          order: 3,
        },
        {
          key: ScheduleDescriptionTypeEnum.SemiDetachedHouse,
          displayName: "Semi-detached house",
          order: 4,
        },
        {
          key: ScheduleDescriptionTypeEnum.TerrancedHouse,
          displayName: "Terranced house",
          order: 5,
        },
      ];
    }

    return this.SelectLists["HouseDescriptionTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetFlatDescriptionTypes(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["FlatDescriptionTypeEnum"] == null
    ) {
      this.SelectLists["FlatDescriptionTypeEnum"] = [
        {
          key: ScheduleDescriptionTypeEnum.Studio,
          displayName: "Studio",
          order: 1,
        },
        {
          key: ScheduleDescriptionTypeEnum.SingleStorey,
          displayName: "Single storey",
          order: 2,
        },
        {
          key: ScheduleDescriptionTypeEnum.Duplex,
          displayName: "Duplex",
          order: 3,
        },
        {
          key: ScheduleDescriptionTypeEnum.Triplex,
          displayName: "Triplex",
          order: 4,
        },
      ];
    }

    return this.SelectLists["FlatDescriptionTypeEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetTradingPeriodTypes(): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["TradingPeriod"] == null) {
      this.SelectLists["TradingPeriod"] = [
        {
          key: TradingPeriodEnum.LessThanTwoYears,
          displayName: "< 2 Years",
          order: 1,
        },
        {
          key: TradingPeriodEnum.MoreThanTwoYears,
          displayName: "2 Years +",
          order: 2,
        },
      ];
    }

    return this.SelectLists["TradingPeriod"].sort((a, b) => a.order - b.order);
  }

  GetPlanningPermissionTypesOfBridging(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["PlanningPermissionTypesOfBridging"] == null
    ) {
      this.SelectLists["PlanningPermissionTypesOfBridging"] = [
        {
          key: PlanningPermissionTypeEnum.NoPlanning,
          displayName: "No planning",
          order: 2,
        },
        {
          key: PlanningPermissionTypeEnum.OutlinePlanning,
          displayName: "Outline planning",
          order: 3,
        },
        {
          key: PlanningPermissionTypeEnum.DetailedPlanning,
          displayName: "Detailed planning",
          order: 1,
        },
      ];
    }
    return this.SelectLists["PlanningPermissionTypesOfBridging"].sort(
      (a, b) => a.order - b.order,
    );
  }

  GetValuationMethodOptions(): ListItem[] {
    if (this.SelectLists == null || this.SelectLists["ValuationMethodEnum"] == null) {
      this.SelectLists["ValuationMethodEnum"] = [
        {
          key: ValuationMethodEnum.OMV,
          displayName: "OMV",
          order: 4,
        },
        {
          key: ValuationMethodEnum.One80DayVP,
          displayName: "180 day VP",
          order: 1,
        }, {
          key: ValuationMethodEnum.NinetyDayVP,
          displayName: "90 day VP",
          order: 2,
        }, {
          key: ValuationMethodEnum.CommercialValuation,
          displayName: "Commercial Valuation",
          order: 3,
        },
      ];
    }

    return this.SelectLists["ValuationMethodEnum"].sort((a, b) => a.order - b.order);
  }

  GetMaxCommercialFloorspaceOptions(): ListItem[] {
    if (
      this.SelectLists == null ||
      this.SelectLists["MaxCommercialFloorspaceEnum"] == null
    ) {
      this.SelectLists["MaxCommercialFloorspaceEnum"] = [
        {
          key: MaxCommercialFloorspaceEnum.Below50,
          displayName: "0 - 50%",
          order: 1,
        },
        {
          key: MaxCommercialFloorspaceEnum.FiftyOneTo75,
          displayName: "51 - 75%",
          order: 2,
        },
        {
          key: MaxCommercialFloorspaceEnum.Above76,
          displayName: "Above 76%",
          order: 3,
        }
      ];
    }
    return this.SelectLists["MaxCommercialFloorspaceEnum"].sort(
      (a, b) => a.order - b.order,
    );
  }
}

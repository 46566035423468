import { CaseSummaryDTO } from "@js/DTO/CaseSummaryDTO.cs.d";
import {
  GetCasesForUserRequest,
  GetCasesForUserResponse,
} from "@js/DTO/Dashboard/GetCasesForUserMessage.cs.d";
import {
  GetSearchesForUserRequest,
  GetSearchesForUserResponse,
} from "@js/DTO/Dashboard/GetSearchesForUserMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";

export class CaseSummaryService extends BaseService<CaseSummaryDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/dashboard";
    this.$broadcastBusinessNameSingular = "dashboard";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  ////getmycasesandsearches(username: string, clientCaseNameFilter: string = null, statusFilter: CaseStatusEnum = null, limit: number = 100): ng.IPromise<CaseSummaryDTO[]> {
  ////    let defer = this.$q.defer<CaseSummaryDTO[]>();
  ////    var encodeUserName = encodeURIComponent(username);

  ////    var apiUrl = this.$baseAddress + "/getcasesandsearches?username=" + encodeUserName;

  ////    if (clientCaseNameFilter != null && clientCaseNameFilter.length > 0) {
  ////        apiUrl = apiUrl + '&clientCaseName=' + clientCaseNameFilter;
  ////    }

  ////    if (statusFilter != null) {
  ////        apiUrl = apiUrl + '&status=' + statusFilter;
  ////    }

  ////    apiUrl = apiUrl + '&limit=' + limit;

  ////    this.$http.get(apiUrl).then((response) => {
  ////        defer.resolve(response.data as CaseSummaryDTO[]);
  ////    }).catch((response) => {
  ////        this.$broadcastservice.broadcastError("Mycasesummary", "Mycasesummary by user failed");
  ////        defer.reject(response);
  ////    });
  ////    return defer.promise;
  ////}

  //getmycases(username: string, clientCaseNameFilter: string = null, statusFilter: CaseStatusEnum = null,  limit: number = 100): ng.IPromise<CaseSummaryDTO[]> {
  //    let defer = this.$q.defer<CaseSummaryDTO[]>();
  //    var encodeUserName = encodeURIComponent(username);

  //    var apiUrl = this.$baseAddress + "/getcases?username=" + encodeUserName;

  //    if (clientCaseNameFilter != null && clientCaseNameFilter.length > 0) {
  //        apiUrl = apiUrl + '&clientCaseName=' + clientCaseNameFilter;
  //    }

  //    if (statusFilter != null) {
  //        apiUrl = apiUrl + '&status=' + statusFilter;
  //    }

  //    apiUrl = apiUrl + '&limit=' + limit;

  //    this.$http.get(apiUrl).then((response) => {
  //        defer.resolve(response.data as CaseSummaryDTO[]);
  //    }).catch((response) => {
  //        this.$broadcastservice.broadcastError("Mycasesummary", "Mycasesummary by user failed");
  //        defer.reject(response);
  //    });
  //    return defer.promise;
  //}

  //getmysearches(username: string, clientCaseNameFilter: string = null, limit: number = 100): ng.IPromise<CaseSummaryDTO[]> {
  //    let defer = this.$q.defer<CaseSummaryDTO[]>();

  //    var encodeUserName = encodeURIComponent(username);

  //    var apiUrl = this.$baseAddress + "/getsearches?username=" + encodeUserName;

  //    if (clientCaseNameFilter != null && clientCaseNameFilter.length > 0) {
  //        apiUrl = apiUrl + '&clientCaseName=' + clientCaseNameFilter;
  //    }

  //    apiUrl = apiUrl + '&limit=' + limit;

  //    this.$http.get(apiUrl).then((response) => {
  //        defer.resolve(response.data as CaseSummaryDTO[]);
  //    }).catch((response) => {
  //        this.$broadcastservice.broadcastError("getmysearches", "getmysearches by user failed");
  //        defer.reject(response);
  //    });
  //    return defer.promise;
  //}

  /**
   * Get development finance searches for the parameters specified
   * @param username
   * @param searchText
   * @param limit
   */
  getDevFinanceSearchesForUser(
    username: string,
    searchText: string = null,
    searchFilter: CaseStatusEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getSearchesForUser(
      ProductTypeEnum.None,
      username,
      searchText,
      searchFilter,
      limit,
      ProductFamilyEnum.Development,
    );
  }

  /**
   * Get bridging searches for the parameters specified
   * @param username
   * @param searchText
   * @param limit
   */
  getBridgingSearchesForUser(
    username: string,
    searchText: string = null,
    searchFilter: CaseStatusEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getSearchesForUser(
      ProductTypeEnum.None,
      username,
      searchText,
      searchFilter,
      limit,
      ProductFamilyEnum.Bridging,
    );
  }

  /**
   * Get commercial searches for the parameters specified
   * @param username
   * @param searchText
   * @param limit
   */
  getCommercialSearchesForUser(
    username: string,
    searchText: string = null,
    searchFilter: CaseStatusEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getSearchesForUser(
      ProductTypeEnum.None,
      username,
      searchText,
      searchFilter,
      limit,
      ProductFamilyEnum.Commercial,
    );
  }

  /**
   * Get All searches, regardless of product type, for the parameters specified
   * @param username
   * @param searchText
   * @param limit
   */
  getAllSearchesForUser(
    username: string,
    searchText: string = null,
    statusFilter: CaseStatusEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getSearchesForUser(
      ProductTypeEnum.None,
      username,
      searchText,
      statusFilter,
      limit,
    );
  }

  /**
   * Get Searches for the parameters specified
   * @param productType
   * @param username
   * @param searchText
   * @param statusFilter
   * @param limit
   * @param productFamily
   */
  getSearchesForUser(
    productType: ProductTypeEnum,
    username: string,
    searchText: string = null,
    statusFilter: CaseStatusEnum = null,
    limit: number = 100,
    productFamily: ProductFamilyEnum = ProductFamilyEnum.None,
  ): ng.IPromise<CaseSummaryDTO[]> {
    let defer = this.$q.defer<CaseSummaryDTO[]>();

    var request: GetSearchesForUserRequest = {
      ProductType: productType,
      ProductFamily: productFamily,
      UserName: username,
      SearchText: searchText,
      StatusFilter: statusFilter,
      Limit: limit,
    };

    this.$http
      .post(this.$baseAddress + "/getsearchesforuser", JSON.stringify(request))
      .then((response) => {
        var res = response.data as GetSearchesForUserResponse;
        defer.resolve(res.Results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   * Get development finance cases for the parameters specified
   * @param username
   * @param searchText
   * @param statusFilter
   * @param limit
   */
  getDevFinanceCasesForUser(
    username: string,
    searchText: string = null,
    statusFilter: CaseStatusEnum = null,
    dipStatusFilter: CaseLenderStateEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getCasesForUser(
      ProductTypeEnum.None,
      username,
      searchText,
      statusFilter,
      dipStatusFilter,
      limit,
      ProductFamilyEnum.Development,
    );
  }

  /**
   * Get bridging pre-construction cases for the parameters specified
   * @param username
   * @param searchText
   * @param statusFilter
   * @param limit
   */
  getBridgingPreConstCasesForUser(
    username: string,
    searchText: string = null,
    statusFilter: CaseStatusEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getCasesForUser(
      ProductTypeEnum.BridgingPreconstruction,
      username,
      searchText,
      statusFilter,
      limit,
    );
  }

  /**
   * Get bridging cases for the parameters specified
   * @param username
   * @param searchText
   * @param statusFilter
   * @param limit
   */
  getBridgingCasesForUser(
    username: string,
    searchText: string = null,
    statusFilter: CaseStatusEnum = null,
    dipStatusFilter: CaseLenderStateEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getCasesForUser(
      ProductTypeEnum.None,
      username,
      searchText,
      statusFilter,
      dipStatusFilter,
      limit,
      ProductFamilyEnum.Bridging,
    );
  }

  /**
   * Get commercial cases for the parameters specified
   * @param username
   * @param searchText
   * @param statusFilter
   * @param limit
   */
  getCommercialCasesForUser(
    username: string,
    searchText: string = null,
    statusFilter: CaseStatusEnum = null,
    dipStatusFilter: CaseLenderStateEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getCasesForUser(
      ProductTypeEnum.None,
      username,
      searchText,
      statusFilter,
      dipStatusFilter,
      limit,
      ProductFamilyEnum.Commercial,
    );
  }

  /**
   * Get All cases, regardless of product type, for the parameters specified
   * @param username
   * @param searchText
   * @param limit
   */
  getAllCasesForUser(
    username: string,
    searchText: string = null,
    statusFilter: CaseStatusEnum = null,
    dipStatusFilter: CaseLenderStateEnum = null,
    limit: number = 100,
  ): ng.IPromise<CaseSummaryDTO[]> {
    return this.getCasesForUser(
      ProductTypeEnum.None,
      username,
      searchText,
      statusFilter,
      dipStatusFilter,
      limit,
    );
  }

  /**
   * Get Cases for the parameters specified
   * @param productType
   * @param username
   * @param searchText
   * @param statusFilter
   * @param limit
   * @param productGroup
   */
  getCasesForUser(
    productType: ProductTypeEnum,
    username: string,
    searchText: string = null,
    statusFilter: CaseStatusEnum = null,
    dipStatusFilter: CaseLenderStateEnum = null,
    limit: number = 100,
    productFamily: ProductFamilyEnum = ProductFamilyEnum.None,
  ): ng.IPromise<CaseSummaryDTO[]> {
    let defer = this.$q.defer<CaseSummaryDTO[]>();

    var request: GetCasesForUserRequest = {
      ProductType: productType,
      ProductFamily: productFamily,
      UserName: username,
      SearchText: searchText,
      StatusFilter: statusFilter,
      Limit: limit,
      DipStatus: dipStatusFilter,
    };

    this.$http
      .post(this.$baseAddress + "/getcasesforuser", JSON.stringify(request))
      .then((response) => {
        var res = response.data as GetCasesForUserResponse;
        defer.resolve(res.Results);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

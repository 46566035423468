import { ClientDTO } from "@js/DTO/ClientDTO.cs.d";
import { DealClientDTO } from "@js/DTO/Deal/DealClientDTO.cs.d";
import { GetShareholderEmailAndSecurityQuestionsResponse } from "@js/DTO/Messages/Deal/GetShareholderEmailAndSecurityQuestionsMessage.cs.d";
import { MakeReferralMessageRequest } from "@js/DTO/Messages/MakeReferralMessage.cs.d";
import { SecurityCheckMessageRequest } from "@js/DTO/Messages/SecurityCheckMessage.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";

export class DealClientService extends BaseService<DealClientDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/dealclient";
    this.$broadcastBusinessNameSingular = "dealclient";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  fetchDealMembersWithAccess(dealId: number): ng.IPromise<ClientDTO[]> {
    let defer = this.$q.defer<ClientDTO[]>();
    this.$http
      .get(this.$baseAddress + "/fetchdealmemberswithaccess?dealId=" + dealId)
      .then((response) => {
        defer.resolve(response.data as ClientDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "fetchdealmemberswithaccess",
          "fetchdealmemberswithaccess failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  addorUpdateApplicant(
    dto: DealClientDTO,
    sendInviteEmail: boolean = false,
  ): ng.IPromise<DealClientDTO> {
    let defer = this.$q.defer<DealClientDTO>();

    this.$http
      .post(
        this.$baseAddress +
          "/addorupdateapplicant?sendInviteEmail=" +
          sendInviteEmail,
        dto,
      )
      .then((response) => {
        defer.resolve(response.data as DealClientDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "addOrUpdateDealClient",
          "addOrUpdateDealClient failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  markAsDeleteAndSetNextApplicantAsPrimary(
    dealId: number,
    dealClientId: number,
  ): ng.IPromise<DealClientDTO[]> {
    let defer = this.$q.defer<DealClientDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/markasdeleteandsetnextapplicantasprimary?dealId=" +
          dealId +
          "&dealClientId=" +
          dealClientId,
      )
      .then((response) => {
        defer.resolve(response.data as DealClientDTO[]);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "markAsDeleteAndSetNextApplicantAsPrimary",
          "mark selected client as delete and set next deal client IsPrimary boolen to true",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  inviteApplicant(uniqueId: string): ng.IPromise<DealClientDTO> {
    let defer = this.$q.defer<DealClientDTO>();
    this.$http
      .get(this.$baseAddress + "/inviteapplicant?uniqueId=" + uniqueId)
      .then((response) => {
        defer.resolve(response.data as DealClientDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "inviteApplicant",
          "inviteApplicant failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   *Get the email for the shareholder
   * @param caseMemberId
   */
  getShareholderEmailAndSecurityQuestions(
    uniqueId: string,
  ): ng.IPromise<GetShareholderEmailAndSecurityQuestionsResponse> {
    let defer =
      this.$q.defer<GetShareholderEmailAndSecurityQuestionsResponse>();

    this.$http
      .get(
        this.$baseAddress +
          "/getshareholderemailandsecurityquestions?uniqueId=" +
          uniqueId,
      )
      .then((response) => {
        defer.resolve(
          response.data as GetShareholderEmailAndSecurityQuestionsResponse,
        );
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "getDealClientEmailAndSecurityQuestions",
          "getDealClientEmailAndSecurityQuestions failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  securityCheck(
    uniqueId: string,
    phone?: string,
    postcode?: string,
    dob?: Date,
    orgUniqueRef?: string,
    brokerUserId?: string,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    var dateOfBirthUtc;
    if (dob != null) {
      var tempDob = new Date();
      tempDob.setHours(0, 0, 0, 0);
      dateOfBirthUtc = new Date(
        tempDob.setUTCFullYear(
          dob.getFullYear(),
          dob.getMonth(),
          dob.getDate(),
        ),
      );
      dateOfBirthUtc.setUTCHours(0);
      dateOfBirthUtc.setUTCMinutes(0);
      dateOfBirthUtc.setUTCSeconds(0);
      dateOfBirthUtc.setUTCMilliseconds(0);
    }

    var request = {
      UniqueId: uniqueId,
      PhoneNumber: phone,
      PostCode: postcode,
      DateOfBirth: dateOfBirthUtc,
      OrgUniqueRef: orgUniqueRef,
      BrokerUserId: brokerUserId,
    } as SecurityCheckMessageRequest;

    this.$http
      .post(this.$baseAddress + "/securityCheck", request)
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "securityCheck",
          "securityCheck failed",
        );
        defer.reject(response);
      });

    return defer.promise;
  }

  /**
   *fetch shareholder by uniqueId
   * @param uniqueId
   */
  fetchDealClientByUniqueId(uniqueId: string): ng.IPromise<DealClientDTO> {
    let defer = this.$q.defer<DealClientDTO>();

    this.$http
      .get(
        this.$baseAddress + "/fetchdealclientbyuniqueId?uniqueId=" + uniqueId,
      )
      .then((response) => {
        defer.resolve(response.data as DealClientDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "FetchCaseMemberAndReturnRegisterInformation",
          "FetchCaseMemberAndReturnRegisterInformation failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  /**
   *updates a client, delclient and profile data
   * @param uniqueId
   */
  updateApplicant(dto: DealClientDTO): ng.IPromise<DealClientDTO> {
    let defer = this.$q.defer<DealClientDTO>();

    this.$http
      .post(this.$baseAddress + "/updateapplicantdetails", dto)
      .then((response) => {
        defer.resolve(response.data as DealClientDTO);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "UpdateApplicantDetails",
          "UpdateApplicantDetails failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  saveAddressHistory(
    dealClientId: number,
    addressHistory: string,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();

    this.$http
      .put(
        this.$baseAddress + "/saveaddresshistory?dealClientId=" + dealClientId,
        JSON.stringify(addressHistory),
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        this.$broadcastservice.broadcastError(
          "saveAddressHistory",
          "Save Address History failed",
        );
        defer.reject(response);
      });
    return defer.promise;
  }

  submitLenderReferral(
    lenderReferralData: MakeReferralMessageRequest,
  ): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .post(
        this.$baseAddress + "/submitlenderreferral",
        JSON.stringify(lenderReferralData),
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  addCurrentUserToDeal(dealUniqueRef: string): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();

    this.$http
      .get(
        this.$baseAddress +
        "/addcurrentusertodeal?dealUniqueRef=" +
        dealUniqueRef,
      )
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  UpdateClientContactInfoAndNotifyBroker(updatedContact: string, dealId: number, clientId:number): ng.IPromise<boolean> {
    let defer = this.$q.defer<boolean>();
    this.$http
      .get(this.$baseAddress + "/updateclientcontactinfoandnotifybroker?updatedContact=" +
        updatedContact +
        "&dealId=" +
        dealId +
        "&clientId=" + clientId)
      .then((response) => {
        defer.resolve(response.data as boolean);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
}

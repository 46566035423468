import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { AuthService } from "@js/services/AuthService";
import { CaseLenderService } from "@js/services/CaseLenderService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseService } from "@js/services/CaseService";
import { CaseSummaryService } from "@js/services/CaseSummaryService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { DevelopmentInputWithNoLoginService } from "@js/services/DevelopmentInputWithNoLoginService";
import { IntroducerService } from "@js/services/IntroducerService";
import { IntroducerSimpleService } from "@js/services/IntroducerSimpleService";
import { InviteService } from "@js/services/InviteService";
import { LenderService } from "@js/services/LenderService";
import { LicenseService } from "@js/services/LicenseService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { TaskItemService } from "@js/services/TaskItemService";
import { UserService } from "@js/services/UserService";

export class NewSearchSelectionsController {
  selectedRole: number;

  RoleEnum = {
    All: 0,
    DevelopmentFinance: 1,
    BridgingFinance: 2,
    Clients: 3,
  };

  selectedNavMenu: number;
  brokerageOrg: OrganisationDTO;
  hasBridging: boolean = false;
  hasCommercial: boolean = false;
  dataLoading: boolean = false;
  selectedProduct: string = null;
  userDetails: string = null;

  isCommercialOwnerOccupiedActive: boolean = false;

  static $inject = [
    "$rootScope",
    "$routeParams",
    "$location",
    "DevelopmentInputService",
    "DevelopmentInputWithNoLoginService",
    "UserService",
    "CaseService",
    "RoleService",
    "AuthService",
    "IntroducerService",
    "IntroducerSimpleService",
    "CaseSummaryService",
    "LenderService",
    "$cookies",
    "CaseMemberService",
    "InviteService",
    "OrganisationService",
    "PaymentService",
    "LicenseService",
    "CaseLenderService",
    "UserService",
    "TaskItemService",
    "SelectListService",
  ];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $routeParams: ng.route.IRouteParamsService,
    private $location: ng.ILocationService,
    private $DevelopmentInputService: DevelopmentInputService,
    private $DevelopmentInputWithNoLoginService: DevelopmentInputWithNoLoginService,
    private $user: UserService,
    private $CaseService: CaseService,
    private roleService: RoleService,
    private authService: AuthService,
    private $IntroducerService: IntroducerService,
    private $IntroducerSimpleService: IntroducerSimpleService,
    private $CaseSummaryService: CaseSummaryService,
    private $LenderService: LenderService,
    private $cookies: ng.cookies.ICookiesService,
    private $applicantservice: CaseMemberService,
    private inviteService: InviteService,
    private organisationService: OrganisationService,
    private $paymentService: PaymentService,
    private licenseService: LicenseService,
    private caseLenderService: CaseLenderService,
    private userService: UserService,
    private taskItemService: TaskItemService,
    private selectListService: SelectListService,
  ) {
    this.authService.isCommercialOwnerOccupiedActive().then((response) => {
      this.isCommercialOwnerOccupiedActive = response;
    });

    this.getUser()
      .then(() => {
        (this.$rootScope as any).isInvitedAndHasReadonlyAccess = false;

        if (this.$routeParams["Type"] == "bridging") {
          this.selectedRole = this.RoleEnum.BridgingFinance;
          this.selectedNavMenu = this.RoleEnum.BridgingFinance;
        } else {
          this.selectedRole = this.RoleEnum.All;
          this.selectedNavMenu = this.RoleEnum.All;
        }

        if (window.self == window.top && $cookies.get("access_token")) {
          this.organisationService
            .getOrganisationProductAccess()
            .then((response) => {
              this.hasBridging = response.HasBridging;
              this.hasCommercial = response.HasCommercial;
              this.checkProductSelected();
            });
        } else {
          if (
            !location.pathname.startsWith("/otmx") &&
            !location.pathname.startsWith("/lnhn")
          ) {
            var orgCode = location.pathname.replace("/", "");
            if (orgCode && orgCode.length > 0) {
              this.organisationService
                .getOrganisationByOrgCode(location.pathname.replace("/", ""))
                .then((org) => {
                  this.hasBridging = org?.HasBridging;
                  this.hasCommercial = org?.HasCommercialMortgage;
                  this.checkProductSelected();
                });
            } else {
              //This is for borrower belong to BF org
              this.hasBridging = true;
              this.hasCommercial = true;
            }
          }
        }
      })
      .catch((error) => {
        console.error("Failed to get initialize data: ", error);
      });
  }

  async getUser(): Promise<void> {
    if (window.self == window.top) {
      this.userDetails = sessionStorage.getItem("userDetails");
    } else {
      this.userDetails = await this.organisationService.getData("userDetails");
    }
  }

  goToNewDevFinanceSearch(productSelected: boolean = false) {
    if (productSelected || this.userDetails) {
      if (this.hasBridging) {
        //localStorage.removeItem('selectedProduct');
        this.$location.path("/criteria");
      }
    } else {
      this.selectedProduct = "devFinance";
      this.$location.path("/borrowerinitialregistration");
    }
  }

  goToNewBridgingSearch(productSelected: boolean = false) {
    if (productSelected || this.userDetails) {
      if (this.hasBridging) {
        //localStorage.removeItem('selectedProduct');
        this.$location.path("/bridgingcriteria");
      }
    } else {
      sessionStorage.setItem("selectedProduct", "bridging");
      this.$location.path("/bridgingcriteria");
    }
  }

  goToNewCommercialSearch() {
    if (this.userDetails) {
      if (this.hasCommercial) {
        this.$location.path("/commercialcriteria");
      }
    }
  }

  goToNewPurchaseOrRefinanceSearch(productSelected: boolean = false) {
    if (productSelected || sessionStorage.getItem("userDetails")) {
      if (this.hasBridging) {
        sessionStorage.removeItem("selectedProduct");
        this.$location.path("/purchaserefinancecriteria");
      }
    } else {
      this.selectedProduct = "purchaseOrRefinance";
      this.$location.path("/bridgingcriteria");
    }
  }

  checkProductSelected() {
    switch (this.selectedProduct) {
      case null:
        break;
      case "devFinance":
        this.goToNewDevFinanceSearch(true);
        break;
      case "bridging":
        this.goToNewBridgingSearch(true);
        break;
      case "purchaseOrRefinance":
        this.goToNewPurchaseOrRefinanceSearch(true);
        break;
      default:
        return;
    }
  }
}

export class ModalPopupComponentController {

    public toggleModal: boolean = false;
    public prevToggleModalVal: boolean = false;
    public loading: boolean = false;
    public formSectionNames: [] = [];
    public step: number;

    // Configures whether the modal closes when the user clicks outside of the modal - by default (true/undefined) the modal will close
    public closeOnBlur: boolean = true;

    constructor(private $element: ng.IAugmentedJQuery, private $scope: ng.IScope) {
    }

    sum(a: number, b: number): number {
        return a + b;
    }

}

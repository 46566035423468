export const enum PropertyTypeEnum {
  None = 0,
  Residential = 1,
  MixedUse = 2,
  Hotel = 4,
  Leisure = 8,
  CareRetirement = 16,
  Medical = 32,
  Office = 64,
  Retail = 128,
  LightIndustrial = 256,
  Student = 512,
  HeavyIndustrial = 1024,
  HMO = 2048,
  LandWithoutPlanning = 4096,
  Education = 8192,
  Retirement = 16384,
  AllCommercialMixedUse = 32768,
  MultiUnitFreeholdBlock = 65536,
}

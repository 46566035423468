import { ApplicationUserDTO } from "@js/DTO/ApplicationUserDTO.cs.d";
import { AppPackagePricingDTO } from "@js/DTO/AppPackagePricingDTO.cs.d";
import { CaseDTO } from "@js/DTO/CaseDTO.cs.d";
import { CaseLenderDTO } from "@js/DTO/CaseLenderDTO.cs.d";
import { CaseMemberDTO } from "@js/DTO/CaseMemberDTO.cs.d";
import { CaseNoteDTO } from "@js/DTO/CaseNoteDTO.cs.d";
import { ComparablePropertyDTO } from "@js/DTO/ComparablePropertyDTO.cs.d";
import { DevelopmentInputDTO } from "@js/DTO/DevelopmentFinance/DevelopmentInputDTO.cs.d";
import { LenderResultMinimalDTO } from "@js/DTO/DevelopmentFinance/LenderResultMinimalDTO.cs.d";
import { ExternalLinksDTO } from "@js/DTO/ExternalLinksDTO.cs.d";
import { FileAttachmentDTO } from "@js/DTO/FileAttachmentDTO.cs.d";
import { FileUploadProgressDTO } from "@js/DTO/FileUploadProgressDTO.cs.d";
import { LenderInfo } from "@js/DTO/Messages/Deal/LendersInfoForSubmitToLenderMessage.cs.d";
import { ModuleAppFeatureDTO } from "@js/DTO/ModuleAppFeatureDTO.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { ProductDTO } from "@js/DTO/ProductDTO.cs.d";
import { TeamMemberDTO } from "@js/DTO/TeamMemberDTO.cs.d";
import { BuildProcurementTypeEnum } from "@js/models/enum/BuildProcurementTypeEnum.cs.d";
import { CaseAccessLevelEnum } from "@js/models/enum/CaseAccessLevelEnum.cs.d";
import { CaseLenderStateEnum } from "@js/models/enum/CaseLenderStateEnum.cs.d";
import { CaseStatusEnum } from "@js/models/enum/CaseStatusEnum.cs.d";
import { EventLogEnum } from "@js/models/enum/EventLogEnum.cs.d";
import { ExitStrategyEnum } from "@js/models/enum/ExitStrategyEnum.cs.d";
import { ExternalLinksEnum } from "@js/models/enum/ExternalLinksEnum.cs.d";
import { LenderProductTypeEnum } from "@js/models/enum/LenderProductTypeEnum.cs.d";
import { LicenseMasterStatusEnum } from "@js/models/enum/LicenseMasterStatusEnum.cs.d";
import { ModuleEnum } from "@js/models/enum/ModuleEnum.cs.d";
import { PlanningPermissionTypeEnum } from "@js/models/enum/PlanningPermissionTypeEnum.cs.d";
import { PlanningResponsibilityEnum } from "@js/models/enum/PlanningResponsibilityEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { ProductTypeEnum } from "@js/models/enum/ProductTypeEnum.cs.d";
import { PurchaseProcessTypeEnum } from "@js/models/enum/PurchaseProcessTypeEnum.cs.d";
import { AccountService } from "@js/services/AccountService";
import { AuthService } from "@js/services/AuthService";
import { CaseLenderService } from "@js/services/CaseLenderService";
import { CaseMemberService } from "@js/services/CaseMemberService";
import { CaseNoteService } from "@js/services/CaseNoteService";
import { CaseService } from "@js/services/CaseService";
import { ComparablePropertyService } from "@js/services/ComparablePropertyService";
import { DevelopmentInputService } from "@js/services/DevelopmentInputService";
import { EventLogService } from "@js/services/EventLogService";
import { ExternalLinksService } from "@js/services/ExternalLinksService";
import { FileAttachmentService } from "@js/services/FIleAttachmentService";
import { InviteService } from "@js/services/InviteService";
import { LenderResultService } from "@js/services/LenderResultService";
import { LenderService } from "@js/services/LenderService";
import { OrganisationService } from "@js/services/OrganisationService";
import { PaymentService } from "@js/services/PaymentService";
import { ProductService } from "@js/services/ProductService";
import { RoleService } from "@js/services/RoleService";
import { SelectListService } from "@js/services/SelectListService";
import { TeamMemberService } from "@js/services/TeamMemberService";
import { UserService } from "@js/services/UserService";
import angular from "angular";

export class CaseController {
  dataLoading: boolean = false;

  assistanceSlider: boolean = false;
  totalLender: number = 0;

  selectedSection: string;

  thisModuleSection: ModuleEnum;
  fileUpload: FileAttachmentDTO[];
  fileUploadPlaning: FileAttachmentDTO[];
  fileUploadTeam: FileAttachmentDTO[];
  fileUploadProperties: FileAttachmentDTO[];
  selectedProduct: ProductDTO;
  selectedResult: LenderResultMinimalDTO;
  total: number = 0;
  openModal: boolean = false;
  objects: CaseDTO[]; //TODO JLH rename this to something meaningful but look into what it is used for
  case: CaseDTO;
  toggleEditSearchName: boolean = false;
  orgName: string;
  orgId: number;
  org: OrganisationDTO;
  fileNamePrefix: string = "Brickflow";

  caseLenders: CaseLenderDTO[];
  // indicates whether a bid has been accepted on the case
  hasBidAccepted: boolean = false;
  selectedCaseLender: CaseLenderDTO;

  ShowProceed: boolean = false;
  showEdit: boolean = false;
  confirmationDelete: boolean = false;

  uploadingFiles: FileUploadProgressDTO[];
  renamingFile: FileAttachmentDTO;
  currentUser: ApplicationUserDTO;

  //to populate the full login details
  showSetPasswordModal: boolean = false;
  verifyNewPassword: string;
  passwordSetSuccess: boolean = false;
  registrationForm: FormData;
  modal: ng.IFormController;

  newNote: CaseNoteDTO;
  newExternalLink: ExternalLinksDTO;
  newTeamMember: TeamMemberDTO;
  editTeamMember: TeamMemberDTO;
  tempTeamMember: TeamMemberDTO;
  confirmDeleteTeamMember: TeamMemberDTO;
  newComparable: ComparablePropertyDTO;
  editComparable: ComparablePropertyDTO;
  tempComparable: ComparablePropertyDTO;
  confirmDeleteComparable: ComparablePropertyDTO;
  appName: string;

  clickRequestReview: boolean = false;

  //check Payment Status and show modal
  paymentStatus: string;

  IsOnlyBroker: boolean = false;
  isAdminBroker: boolean = false;
  isBroker: boolean = false;
  isAdmin: boolean = false;
  isLender: boolean = false;
  isClient: boolean = false;
  showVideo: boolean = false;
  showShare: boolean = false;
  shareContext: number = null;
  shareSuplId: number = null;
  shareNote: string;
  userFirstName: string;
  showAdd: boolean = false;
  showDelete: boolean = false;
  showReject: boolean = false;
  showWithdraw: boolean = false;
  showStatusMessage: boolean = false;
  isCaseMember: boolean = false;
  redactSensitiveInfo: boolean = false;
  invalidEmail: boolean = false;

  caseForm: ng.IFormController;
  externalLinkForm: ng.IFormController;
  teamMemberForm: ng.IFormController;
  editTeamMemberForm: ng.IFormController;
  comparableForm: ng.IFormController;
  editComparableForm: ng.IFormController;
  planningForm: ng.IFormController;
  shareForm: ng.IFormController;
  lenderDetailsForm: ng.IFormController;

  showExport: boolean = false;
  //case overview
  caseOverviewTempBackup: string;
  //planning
  planningURLTempBackup: string;
  planningLocalAuthorityTempBackup: string;
  planningReferenceTempBackup: string;
  planningDescriptionTempBackup: string;
  lenderDetails: CaseLenderDTO;

  caseMembersWithAccess: CaseMemberDTO[];
  selectedClientToShareWith: CaseMemberDTO;

  isInActiveLender: boolean = false;
  brokerCommission: number;
  caseBrokerUser: ApplicationUserDTO;

  //carousel
  carouselData: string[] = [
    "Brickflow is the quickest and easiest way to apply for development finance.",
    "To get a Decision in Principle (DIP) from a Lender please complete the Appraisal modules below.",
    "The Decision in Principle may be different to the estimates you saw on the results screen.\nThe Decision in Principle is an accurate quote directly from the lender. ",
    "The lender is basing their Decision in Principle only on the information provided below in the Appraisal,\nso be sure to really showcase you and your project.",
    "The Decision in Principle should be provided to you within 48 - 72 hours of you completing the Appraisal.\nNo credit scores will be carried out to provide a Decision in Principle.",
    "The loan still needs to be approved by Credit Committee, but providing a Decision in Principle means the lender is confident in getting approval (but it's not guaranteed)",
    "Select 'Complete' against each Module once you're happy.\nOnce all modules are complete click 'Submit to Lenders'.",
    "A Brickflow Team Member will review your Case and then when they're happy,\nyour Case will be sent to the chosen lender(s) for review.",
    "Your Developer Profile is like a CV. It will be saved and updated as you complete loans in the platform. Complete it once and never have to do it again.",
    "When you're ready to start, click module 1 'Case Overview'",
  ];
  carouselStep: number = 0;

  caseLandingSeen: boolean = true;

  message: boolean = false;
  messageContent: string;
  sendingMessage: boolean = false;

  maxNotes: number = 3;
  showAllNotes: boolean = false;

  showRequestEmailForm: boolean = false;
  requestMessageReason: string = "";
  enquiryTelephone: string = "";

  // Controls showing Ts & Cs when a borrower/broker submits to lenders
  showTsAndCs: boolean = false;
  showTsAndCsForm: boolean = false;
  showApplyTsAndCs: boolean = false;
  acceptTerms: boolean = false;

  youtubeLinkToAdd: string;
  currentYoutubeVideo: string = "";

  //For build costs calculation
  totalBuild: number;
  additionalBuild: number;
  contingencyTotal: number;
  totalPurchaseCostsNotOwning: number;
  totalPurchaseCostsOwning: number;

  shareholders: CaseMemberDTO;

  //Accordion

  accordionShow: boolean[] = [];

  //Admin viewing on behalf

  proxyViewer: boolean = false;

  selectedUser: ApplicationUserDTO;
  clientUsernameBeingAccessed: string;
  tinymceOptions: any;
  //Guidance
  guidanceCheckbox: boolean = true;
  tourState: any = {
    tourStep: 1,
    tourTotalSteps: 0,
  };
  tourEnabled: boolean = false;
  brokerOrganisations: OrganisationDTO[];
  brokerUsers: ApplicationUserDTO[];

  seeFiles: boolean = false;

  isLoggedInUser: boolean = false;

  primaryShareholder: CaseMemberDTO;
  brokerEmail: string;

  isSelected: boolean = false;
  selectedPaymentProduct: AppPackagePricingDTO = null;
  showPaymentModal: boolean = false;
  moduleAppFeatures: ModuleAppFeatureDTO[];
  moduleFeatureCount: number = 0;
  selecteFeatureCount: number = 0;
  appPackagePrices: AppPackagePricingDTO[];
  selectedProductList: AppPackagePricingDTO[] = [];
  billingFrequency: Array<{ id: number; value: string }> = [
    { id: 1, value: "Monthly" },
    { id: 2, value: "Yearly" },
  ];
  selectedBillingFrequency: number;
  isSubmittedToLenders: boolean = false;

  selectedLendersText: string = "";
  shortlistedLendersText: string = "";
  lendersDetailsForSubmittoLenders: LenderInfo[];
  shortListedLendersId: Number[];

  referrerOptions = [];
  locationOptions = [];

  hasShortlistedCaseLenders: boolean;

  showModuleShareConfirmation: boolean;

  productTypeDisplayText: string;

  brokerPreferredContact: string;
  isBrokerPhoneNumberUpdated: boolean = false;
  showAutofill: boolean = false;

  totalUnreadCaseLenderMessages: number = 0;

  static $inject = [
    "$routeParams",
    "$rootScope",
    "CaseService",
    "DevelopmentInputService",
    "$location",
    "FileAttachmentService",
    "CaseNoteService",
    "RoleService",
    "AuthService",
    "$cookies",
    "AccountService",
    "ProductService",
    "LenderResultService",
    "ExternalLinksService",
    "$interval",
    "$q",
    "$window",
    "UserService",
    "TeamMemberService",
    "ComparablePropertyService",
    "CaseLenderService",
    "LenderService",
    "OrganisationService",
    "CaseMemberService",
    "InviteService",
    "PaymentService",
    "SelectListService",
    "EventLogService",
  ];

  constructor(
    private $routeParams: ng.route.IRouteParamsService,
    private $rootScope: ng.IRootScopeService,
    private caseService: CaseService,
    private developmentInputService: DevelopmentInputService,
    private $location: ng.ILocationService,
    private fileAttachmentService: FileAttachmentService,
    private caseNoteService: CaseNoteService,
    private roleService: RoleService,
    private authService: AuthService,
    private $cookies: ng.cookies.ICookiesService,
    private $accountservice: AccountService,
    private productService: ProductService,
    private $lenderresultservice: LenderResultService,
    private $externalLinksService: ExternalLinksService,
    private $interval: ng.IIntervalService,
    protected $q: ng.IQService,
    private $window: ng.IWindowService,
    private userService: UserService,
    private $teamMemberService: TeamMemberService,
    private comparablePropertyService: ComparablePropertyService,
    private caseLenderService: CaseLenderService,
    private $lenderService: LenderService,
    private organisationService: OrganisationService,
    private caseMemberService: CaseMemberService,
    private inviteService: InviteService,
    private $paymentService: PaymentService,
    private selectListService: SelectListService,
    private eventLogService: EventLogService,
  ) {
    if ($cookies.get("access_token")) {
      this.isLoggedInUser = true;

      this.userService.getUserAutofill().then((response) => {
        this.showAutofill = response;
      });
    }

    this.productTypeDisplayText = this.productService.getProductTypeDisplayText(
      ProductTypeEnum.Development,
    );

    sessionStorage.removeItem("lenderTask");

    //TOUR
    $rootScope.$on("nextTour", (event: ng.IAngularEvent) => {
      this.tourNext();
    });
    $rootScope.$on("backTour", (event: ng.IAngularEvent) => {
      this.tourBack();
    });
    $rootScope.$on("skipTour", (event: ng.IAngularEvent) => {
      this.tourSkip();
    });

    this.tinymceOptions = {
      content_style:
        "body {color: #304b9a;font:300 13.33px Roboto,sans-serif;}",
      menubar: false,
      height: 300,
      branding: false,
      statusbar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | " +
        "bold italic backcolor forecolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | preview fullscreen |" +
        "removeformat | help",
    };

    if ((this.$rootScope as any).acceptTerms) {
      this.showRequestEmailForm = false;
      this.requestMessageReason =
        "Thank you for accepting the Decision in Principle";
      this.message = true;
      this.messageContent =
        "Once you have made the application payment you will need to review the information in the Application Details module. Once this has been completed you will be able to submit your application to the lender";
    }

    if (this.$routeParams.PaymentStatus) {
      this.paymentStatus = this.$routeParams.PaymentStatus;
    }

    this.getTubeMapValue();

    this.checkForClient();

    if (this.$routeParams.CaseId) {
      this.dataLoading = true;

      this.organisationService.fetchAll().then((response) => {
        this.brokerOrganisations = response;
      });

      this.caseLenderService
        .fetchByCaseId(this.$routeParams.CaseId)
        .then((cls: CaseLenderDTO[]) => {
          this.hasShortlistedCaseLenders = cls.some(
            (cl) => cl.CaseLenderState == CaseLenderStateEnum.Shortlisted,
          );
          this.totalUnreadCaseLenderMessages = cls
            .filter((cls) => cls.TotalUnreadCaseLenderMessages != null)
            .reduce((a, b) => a + b.TotalUnreadCaseLenderMessages, 0);
        });

      this.caseService
        .fetch(this.$routeParams.CaseId)
        .then((response) => {
          this.case = response;
          this.orgId = this.case.BrokerOrganisationId;
          if (this.orgId) {
            this.organisationService.fetch(this.orgId).then((response) => {
              this.org = response;
              this.brokerCommission =
                this.case.CaseStatus == CaseStatusEnum.NewCase
                  ? response.CommissionPercent
                  : this.case.BrokerCommissionPercent;
              if (this.org.IsWhiteLabelled) {
                this.fileNamePrefix = this.org.Name.replace(/ /g, "_");
              } else {
                this.fileNamePrefix = "Brickflow";
              }
            });
          }

          if (this.case) {
            this.fileUpload = this.case.Attachments;
            // UpdateCaseState is essential to the RequiredState directive functionality
            //TODO JNG refactor to put within the caseservice fetch
            if (this.case) {
              ($rootScope as any).statusRequirementId = this.case.Id;

              this.caseService.updateCaseState(
                this.case.Id,
                this.case.CaseStatus,
              );

              if (this.case.CaseStatus == 0 || this.case.CaseStatus == 1) {
                this.caseService.updateTubeMap(CaseStatusEnum.InProgress);
              } else {
                this.caseService.updateTubeMap(this.case.CaseStatus);
              }

              this.CalcTotalBuild();

              if (this.case.DevelopmentInput.CI_Dev_DoYouOwnTheProperty) {
                this.CalcTotalPurchaseCostsOwning();
              } else {
                this.CalcTotalPurchaseCostsNotOwning();
              }

              //External links object initialisation
              this.newExternalLink = {
                CaseId: this.case.Id,
                LinkType: ExternalLinksEnum.Misc,
                Link: "https://",
              } as ExternalLinksDTO;
            }

            // doesn't use cookies to get updated subscription status
            this.authService.getUpdatedProfile().then((prof) => {
              this.currentUser = prof;
              this.isAdminBroker = this.currentUser.IsOrganisationAdmin;
              this.checkForAdmin();
              this.checkForBroker();
              this.checkForLender();
              this.checkIfOnlyBroker();
              this.checkForClient();
              this.updateRequestAssitanceCaseNoteMessage();
              //If half-registered user hasn't set a password, then prompt them to set one
              if (this.currentUser.TemporaryAccount === true) {
                this.showSetPasswordModal = true;
              }

              this.newTeamMember = {
                UserId: this.currentUser.Id,
                CaseId: this.case.Id,
                Website: "https://",
              } as TeamMemberDTO;
              this.newNote = {
                CaseId: this.case.Id,
                UserId: this.currentUser.Id,
                ExpandNote: false,
              } as CaseNoteDTO;
              this.newComparable = {
                CaseId: this.case.Id,
                URL: "https://",
              } as ComparablePropertyDTO;
            });

            this.clientAccountAccessCheck();
          } else if (
            (this.$rootScope as any).loginRedirectPath.includes(
              "/casedashboard",
            )
          ) {
          } else {
            // If no case is returned, this could be that the logged in user has been removed from the case
            // - only do this if there is a logged in user, otherwise let the routing sort out where the redirect should go
            if ((this.$rootScope as any).selectedUser) {
              this.$location.path("/dashboard");
            }
          }
        })
        .catch((error) => {
          this.$location.path("/dashboard");
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
    this.updateCaseLandingSeenState();

    //Tour & Guidance logic
    let cookieTourStep = this.$cookies.get("tourStepPreapp");
    if (cookieTourStep) {
      this.tourState.tourStep = cookieTourStep;
    }
    $rootScope.$on("guidanceStateChanged", (event: ng.IAngularEvent) => {
      this.updateGuidanceState();
    });
    this.updateGuidanceState();
    this.getAppName();

    this.getLenderCount();

    this.referrerOptions = this.selectListService.GetReferrerOptions();
    this.locationOptions = this.selectListService.GetLocations();
  }

  updateGuidanceState() {
    this.guidanceCheckbox =
      this.$cookies.get("guidance") === "on" ||
      this.$cookies.get("guidance") === undefined;

    // Update tour settings
    let tourEnabled = this.$cookies.get("tourEnabledPreapp");
    if (
      (tourEnabled == "true" || tourEnabled === undefined) &&
      this.guidanceCheckbox
    ) {
      this.tourEnabled = true;
    } else {
      this.tourEnabled = false;
    }
  }

  getTubeMapValue() {
    return this.caseService.getTubeMap();
  }

  closeModal(test: number) {
    this.message = false;
  }

  /**
   * Process the Accept/Cancel Application button being clicked
   */
  acceptCancelTerms() {
    this.caseLenderService
      .applyToLender(this.selectedCaseLender.CaseId, this.selectedCaseLender.Id)
      .then((response) => {
        this.selectedCaseLender.CaseLenderState = response.CaseLenderState;
        this.caseLenders = this.caseLenders.filter(
          (lender) => lender.Id != this.selectedCaseLender.Id,
        );
        this.caseLenders.push(this.selectedCaseLender);
      })
      .finally(() => {
        this.messageContent =
          "Thank you for submitting an application with this lender.";
        this.message = true;
      });

    this.showApplyTsAndCs = false;
  }

  turnOffGuidance() {
    this.guidanceCheckbox = false;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    this.$cookies.put("guidance", "off", { expires: expiryDate });
    this.$rootScope.$broadcast("guidanceStateChanged");
  }

  getGuidanceSwitchState() {
    if (!this.$cookies.get("guidance")) {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "on") {
      this.guidanceCheckbox = true;
    } else if (this.$cookies.get("guidance") === "off") {
      this.guidanceCheckbox = false;
    } else {
      this.guidanceCheckbox = true;
    }
    return this.guidanceCheckbox;
  }

  recordGuidanceCookie() {
    var guidanceSwitchState: string;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    if (this.guidanceCheckbox == true) {
      guidanceSwitchState = "on";
    } else {
      guidanceSwitchState = "off";
    }
    this.$cookies.put("guidance", guidanceSwitchState, { expires: expiryDate });
    this.$rootScope.$broadcast("guidanceStateChanged");
  }

  go(path): void {
    this.$location.path(path);
  }

  backToClientSearches(): void {
    (this.$rootScope as any).clientUsernameBeingViewed =
      this.case.DevelopmentInput.OwnerUser;
    this.authService.clearSelectedNavMenuAndFilterStorageData();
    this.go("/userdashboard");
  }

  clientAccountAccessCheck(): void {
    if (this.$cookies.get("access_token")) {
      this.userService.getcurentuserrecord().then((result) => {
        this.selectedUser = result;

        this.caseMemberService
          .fetchByCaseId(this.$routeParams.CaseId)
          .then((response) => {
            this.shareholders = response.find(
              (x) => x.UserId == this.selectedUser.Id,
            );
            this.primaryShareholder = response.find((x) => x.IsPrimary == true);

            if (
              (this.selectedUser.UserName !==
                this.case.DevelopmentInput.OwnerUser &&
                !this.shareholders) ||
              (this.selectedUser.UserName !==
                this.case.DevelopmentInput.OwnerUser &&
                this.isAdmin)
            ) {
              this.clientUsernameBeingAccessed =
                this.case.DevelopmentInput.OwnerUser;
            }
          });
      });
    }
  }

  paymentStatusClose() {
    this.go("/casedashboard/" + this.$routeParams.CaseId);
    this.paymentStatus = "";
  }

  carriageReturnCheck(event) {
    if (event.key === "Enter") {
      let position = event.target.selectionStart;
      if (this.case.CaseOverview) {
        this.case.CaseOverview = [
          this.case.CaseOverview.slice(0, position),
          "\n",
          this.case.CaseOverview.slice(position),
        ].join("");

        setTimeout(() => {
          event.target.setSelectionRange(position + 1, position + 1);
        }, 0);
      } else {
        this.case.CaseOverview = "\n";
      }
    }
  }

  toggleAccordion(id: number) {
    if (!this.accordionShow[id]) {
      this.accordionShow[id] = true;
    } else {
      this.accordionShow[id] = !this.accordionShow[id];
    }
  }

  formChanged(): void {
    (this.$rootScope as any).formSaved = false;
  }
  checkForAdmin(): void {
    this.dataLoading = true;
    this.roleService
      .isAdminUser()
      .then((response) => {
        this.isAdmin = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkForLender(): void {
    this.dataLoading = true;
    this.roleService
      .isLenderUser()
      .then((response) => {
        this.isLender = response;
        if (this.isLender) {
          this.caseLenderService
            .fetchCaseLender(this.case.Id)
            .then((response) => {
              this.lenderDetails = response;
              this.isInActiveLender = response.IsLenderInActive;
            });
        } else {
          if (this.case.DevelopmentInput.SelectedResults.length > 0) {
            this.$lenderService
              .getSelectedLendersName(
                this.case.DevelopmentInput.SelectedResults,
              )
              .then((response) => {
                this.selectedLendersText = response.LendersName;
                if (this.case.CaseStatus == CaseStatusEnum.NewCase) {
                  this.shortListedLendersId = response.LendersId;
                }
              });
            this.$lenderService
              .getShortlistedLendersName(
                this.case.DevelopmentInput.SelectedResults,
              )
              .then((response) => {
                this.shortlistedLendersText = response.LendersName;
                if (this.case.CaseStatus != CaseStatusEnum.NewCase) {
                  this.shortListedLendersId = response.LendersId;
                }
              });
          }
        }
        this.getBrokerUsers();
        this.checkIfUserIsInvited();
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkIfOnlyBroker() {
    this.dataLoading = true;
    this.roleService
      .isBroker()
      .then((response) => {
        this.IsOnlyBroker = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkForBroker(): void {
    this.dataLoading = true;
    this.roleService
      .isBrokerOrABove()
      .then((response) => {
        this.isBroker = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  checkForClient(): void {
    this.dataLoading = true;
    this.roleService
      .isClientUser()
      .then((response) => {
        this.isClient = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  // Gets the List of brokers belonging to broker organisation for admin or broker, and broker email to client or lender.
  getBrokerUsers(): void {
    this.dataLoading = true;
    if ((this.isAdmin || this.isBroker) && this.case.BrokerOrganisationId) {
      this.userService
        .getAllOrganisationMembers(this.case.BrokerOrganisationId)
        .then((response) => {
          if (this.case.BrokerUserId != null) {
            this.caseBrokerUser = response.find(
              (x) => x.Id == this.case.BrokerUserId,
            );
            this.brokerPreferredContact = this.caseBrokerUser?.PhoneNumber;
          }
          this.brokerUsers = response.filter(
            (x) => x.SubscriptionStatus > 0 || x.IsOrganisationAdmin,
          );
        })
        .finally(() => {
          this.dataLoading = false;
        });
    } else if (
      this.case.BrokerUserId != null &&
      !this.isAdmin &&
      !this.isBroker
    ) {
      this.userService
        .getUserEmail(this.case.BrokerUserId)
        .then((response) => {
          this.brokerEmail = response;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    }
  }

  completeAccount() {
    this.currentUser.TemporaryAccount = false;
    this.currentUser.Roles = [];
    this.currentUser.Roles.push("Client");
    if (this.currentUser.ApplicantDefinedRole == 0) {
      this.currentUser.NewUserEmailJourneyStarted = true;
    }
    this.userService.addUpdate(this.currentUser).then((response) => {
      this.passwordSetSuccess = true;
    });
  }

  afterSetPasswordContinue() {
    //User needs to be logged out and logged in again
    if (this.$cookies.get("access_token")) {
      this.authService.logout(false);
    }
    this.authService
      .login(
        this.currentUser.Email,
        this.currentUser.Password,
        "CC022EBE67BE0424EA2A6548B062D2D71",
      )
      .then((response) => {
        let expiry: Date = response;
        this.$cookies.put("user_firstname", this.currentUser.FirstName, {
          expires: expiry,
        });
      });

    this.showSetPasswordModal = false;
  }

  saveLink(link: ExternalLinksDTO): void {
    this.dataLoading = true;

    this.$externalLinksService
      .addUpdate(this.newExternalLink)
      .then((response) => {
        this.case.Links.push(response);

        this.newExternalLink = {
          CaseId: this.case.Id,
          LinkType: ExternalLinksEnum.Misc,
          Link: "https://",
        } as ExternalLinksDTO;

        this.externalLinkForm.$setPristine();
        this.externalLinkForm.$setUntouched();
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  removeLink(link: ExternalLinksDTO): void {
    this.dataLoading = true;
    this.$externalLinksService
      .delete(link.Id)
      .then((response) => {
        let index = this.case.Links.indexOf(link);
        this.case.Links.splice(index, 1);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  saveTeamMember(teamMember: TeamMemberDTO): void {
    this.newTeamMember.Website =
      this.newTeamMember.Website == "https://"
        ? ""
        : this.newTeamMember.Website;
    this.$teamMemberService.addUpdate(this.newTeamMember).then((response) => {
      this.case.TeamMembers.push(response);
      this.newTeamMember = {
        CaseId: this.case.Id,
        UserId: this.currentUser.Id,
        Website: "https://",
      } as TeamMemberDTO;
      this.teamMemberForm.$setPristine();
      this.teamMemberForm.$setUntouched();
      this.showAdd = false;
    });
  }

  addTeamMemberPopUp() {
    if (this.teamMemberForm) {
      this.teamMemberForm.$setPristine();
      this.teamMemberForm.$setUntouched();
    }

    this.showAdd = true;
  }

  resetTeamMember() {
    this.teamMemberForm.$setPristine();
    this.teamMemberForm.$setUntouched();
    this.newTeamMember = {
      CaseId: this.case.Id,
      UserId: this.currentUser.Id,
      Website: "https://",
    } as TeamMemberDTO;
    this.showAdd = false;
  }

  deleteTeamMember(): void {
    this.$teamMemberService
      .delete(this.confirmDeleteTeamMember.Id)
      .then((response) => {
        let index = this.case.TeamMembers.indexOf(this.confirmDeleteTeamMember);
        this.case.TeamMembers.splice(index, 1);
      });
    this.confirmDeleteTeamMember = {} as TeamMemberDTO;
    this.showDelete = false;
  }

  updateTeamMember(teamMember: TeamMemberDTO): void {
    this.editTeamMember = teamMember;
    this.tempTeamMember = { ...this.editTeamMember };
    this.editTeamMember.Website = this.editTeamMember.Website
      ? this.editTeamMember.Website
      : "https://";
    this.showEdit = true;
  }

  saveUpdatedTeamMember(teamMember: TeamMemberDTO): void {
    this.editTeamMember.Website =
      this.editTeamMember.Website == "https://"
        ? ""
        : this.editTeamMember.Website;

    this.$teamMemberService
      .addUpdate(this.editTeamMember)
      .then((response) => {
        this.case.TeamMembers = this.case.TeamMembers.filter(
          (member) => member.Id != this.editTeamMember.Id,
        );
        this.case.TeamMembers.push(response);
      })
      .finally(() => {
        this.showEdit = false;
      });
  }

  cancelUpdateTeamMember() {
    this.showEdit = false;
    this.case.TeamMembers = this.case.TeamMembers.filter(
      (member) => member.Id != this.tempTeamMember.Id,
    );
    this.case.TeamMembers.push(this.tempTeamMember);
  }

  deleteConfirmTeamMember(teamMember: TeamMemberDTO) {
    this.showDelete = true;
    this.confirmDeleteTeamMember = { ...teamMember };
  }

  saveComparable(comparable: ComparablePropertyDTO): void {
    this.newComparable.URL =
      this.newComparable.URL == "https://" ? "" : this.newComparable.URL;
    this.comparablePropertyService
      .addUpdate(this.newComparable)
      .then((response) => {
        this.case.Comparables.push(response);
        this.newComparable = {
          CaseId: this.case.Id,
          URL: "https://",
        } as ComparablePropertyDTO;
        this.comparableForm.$setPristine();
        this.comparableForm.$setUntouched();
        this.showAdd = false;
      });
  }

  resetComparable() {
    this.newComparable = {
      CaseId: this.case.Id,
      URL: "https://",
    } as ComparablePropertyDTO;
    this.comparableForm.$setPristine();
    this.comparableForm.$setUntouched();
    this.showAdd = false;
  }

  deleteComparable(): void {
    this.comparablePropertyService
      .delete(this.confirmDeleteComparable.Id)
      .then((response) => {
        let index = this.case.Comparables.indexOf(this.confirmDeleteComparable);
        this.case.Comparables.splice(index, 1);
      });
    this.confirmDeleteComparable = {} as ComparablePropertyDTO;
    this.confirmationDelete = false;
  }

  updateComparable(comparable: ComparablePropertyDTO): void {
    this.editComparable = comparable;
    this.tempComparable = { ...this.editComparable };
    this.editComparable.URL = this.editComparable.URL
      ? this.editComparable.URL
      : "https://";
    this.showEdit = true;
  }

  saveUpdatedComparable(comparable: ComparablePropertyDTO): void {
    this.editComparable.URL =
      this.editComparable.URL == "https://" ? "" : this.editComparable.URL;
    this.comparablePropertyService
      .addUpdate(this.editComparable)
      .then((response) => {
        this.case.Comparables = this.case.Comparables.filter(
          (member) => member.Id != this.editComparable.Id,
        );
        this.case.Comparables.push(response);
      })
      .finally(() => {
        this.showEdit = false;
      });
  }

  cancelDelete() {
    this.confirmationDelete = false;
  }

  deleteConfirmationMessage(row) {
    this.confirmationDelete = true;
    this.confirmDeleteComparable = { ...row };
  }

  cancelUpdateComparable() {
    this.showEdit = false;
    this.case.Comparables = this.case.Comparables.filter(
      (member) => member.Id != this.tempComparable.Id,
    );
    this.case.Comparables.push(this.tempComparable);
  }

  rejectOrWithdrawConfirmation(rejectorwithdraw: string) {
    rejectorwithdraw === "Withdraw"
      ? (this.showWithdraw = true)
      : (this.showReject = true);
  }

  goToCaseDashboard() {
    this.$location.path("/casedashboard/" + this.case.Id);
  }

  updateCaseLandingSeenState() {
    this.caseLandingSeen =
      this.$cookies.get("caseLandingDontShowAgain") === "yes";
  }

  caseLandingSeenClicked() {
    this.caseLandingSeen = true;
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    this.$cookies.put("caseLandingDontShowAgain", "yes", {
      expires: expiryDate,
    });
  }

  viewResults(loanCriteria: DevelopmentInputDTO) {
    this.$location.path(
      "/results/" + this.case.DevelopmentInputID + "/" + this.case.Id,
    );
  }

  CreateDeveloperExperience() {
    this.$location.path("/devexperience/" + this.case.Id + "/-1");
  }
  EditDeveloperExperience() {
    if (this.case.DevelopmentExperienceID) {
      this.$location.path(
        "/devexperience/" +
          this.case.Id +
          "/" +
          this.case.DevelopmentExperienceID,
      );
    } else {
      this.CreateDeveloperExperience();
    }
  }

  EditProfiles() {
    if (this.case.DevelopmentExperienceID) {
      this.$location.path(
        "/profiles/" + this.case.Id + "/" + this.case.DevelopmentExperienceID,
      );
    } else {
      this.CreateDeveloperExperience();
    }
  }

  UpdateDeveloperInformation() {
    this.$location.path(
      "/criteriaappraisal/" +
        this.case.Id +
        "/" +
        this.case.DevelopmentInput.Id,
    );
  }

  EditDeveloperSchedule() {
    this.$location.path(
      "/devschedule/" + this.case.Id + "/" + this.case.DevelopmentInput.Id,
    );
  }

  save() {
    this.dataLoading = true;
    this.caseService
      .addUpdatereturnonlyid(this.case)
      .then((response) => {
        this.case.Id = response;
        if (!this.objects) {
          this.objects = [];
        }

        let matches: CaseDTO[] = this.objects.filter((value, index) => {
          return value.Id == response;
        });

        if (!matches || matches.length === 0) {
          this.objects.push(this.case);
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  doRenameSearch() {
    this.developmentInputService
      .addUpdate(this.case.DevelopmentInput)
      .then((response) => {
        this.toggleEditSearchName = false;
      });
  }

  saveCase(): ng.IPromise<number> {
    this.dataLoading = true;
    let defer = this.$q.defer<number>();
    this.caseService
      .addUpdatereturnonlyid(this.case)
      .then((response) => {
        defer.resolve(response as number);
        return response;
      })
      .catch((response) => {
        defer.reject(response);
      })
      .finally(() => {
        this.dataLoading = false;
      });
    return defer.promise;
  }

  delete() {
    this.dataLoading = true;
    this.caseService
      .delete(this.case.Id)
      .then((response) => {
        if (response) {
          this.objects.splice(this.objects.indexOf(this.case), 1);
          this.caseForm.$setPristine();
          delete this.case;
        }
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  getdevelopmentinput(id: number) {
    this.dataLoading = true;
    this.developmentInputService
      .fetch(id)
      .then((response) => {
        this.case.DevelopmentInput = response;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  //File upload

  //calculateFilesForThisModule() {
  //  this.fileUploadPlaning = this.fileUpload.filter(file => file.Module === ModuleEnum.Planning);
  //this.fileUploadTeam = this.fileUpload.filter(file => file.Module === ModuleEnum.ProfessionalTeam);
  //this.fileUploadProperties = this.fileUpload.filter(file => file.Module === ModuleEnum.ComparableProperties);
  //}

  calculateTotalFiles(filter: number) {
    if (this.fileUpload) {
      this.total = this.fileUpload.filter(
        (item) => item.Module === filter,
      ).length;
    }
    return this.total;
  }

  calculateTotalFilesRemoving(filter: number) {
    if (this.fileUpload) {
      this.total = this.fileUpload.filter(
        (item) => item.Module != filter,
      ).length;
    }
    return this.total;
  }

  onFileSelect(files, moduleSelected) {
    var module = ModuleEnum.Case;
    if (moduleSelected === "Planning") {
      module = ModuleEnum.Planning;
    } else if (moduleSelected === "teammember") {
      module = ModuleEnum.ProfessionalTeam;
    } else if (moduleSelected === "properties") {
      module = ModuleEnum.ComparableProperties;
    } else if (moduleSelected === "images") {
      module = ModuleEnum.Images;
    }

    this.openModal = false;

    if (files.length > 0) {
      //if case has no id it must be saved first
      if (this.case.Id < 1) {
        this.dataLoading = true;

        this.caseService
          .addUpdate(this.case)
          .then((response) => {
            this.case = response;
            this.onFileSelect(files, module); //call itself and then exit as should now be saved so should be able to run
            return;
          })
          .catch((e) => {})
          .finally(() => {
            this.dataLoading = false;
            this.openModal = false;
          });
        //return;
      }

      this.fileAttachmentService
        .UploadFileLstInSync(
          files,
          this.uploadingFiles,
          this.case.Id,
          this.fileUpload,
          module,
        )
        .finally(() => {
          this.openModal = false;
          this.dataLoading = false;
        });
    }
  }

  downloadFile(file: FileAttachmentDTO) {
    this.dataLoading = true;
    this.fileAttachmentService
      .getFileUri(file.FileLocation)
      .then((uri) => {
        this.$window.open(uri);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  downloadFilesAsZip(files: FileAttachmentDTO) {
    var keys: string[] = [];
    for (var file in files) {
      if (files[file].FileLocation) {
        keys.push(files[file].FileLocation);
      }
    }
    this.fileAttachmentService.downloadZip(keys);
  }

  deleteFile(file: FileAttachmentDTO) {
    this.case.Attachments.splice(this.case.Attachments.indexOf(file), 1);
    this.fileAttachmentService.markasdeleted(file.Id).then((result) => {}); //remove on the server
  }

  renameFile(file: FileAttachmentDTO) {
    if (this.renamingFile === undefined) {
      // if renaming this file will dirty the case, we'll want to set it back to pristine when we're done.
      // this.renamingFileDirties = !$scope.caseForm.$dirty;
      this.renamingFile = file;
    } else {
      delete this.renamingFile;
    }
  }
  renamingFileComplete(file: FileAttachmentDTO) {
    this.dataLoading = true;
    this.fileAttachmentService
      .renameFile(file)
      .then((response) => {
        file.TempURL = response.TempURL;
      })
      .finally(() => {
        this.dataLoading = false;
      });
    delete this.renamingFile;
  }

  renamingLinkCompleted(link: ExternalLinksDTO) {
    this.dataLoading = true;
    this.$externalLinksService
      .addUpdate(link)
      .then((response) => {
        link.Link = response.Link;
      })
      .finally(() => {
        this.dataLoading = false;
      });
    delete this.renamingFile;
  }

  //TODO: this will probably need to be deleted once apply button is put on lendings page
  ProcessApplication() {
    let result = this.$location.path("/payment/" + this.case.Id);

    if (result) {
      this.setApplyStatus();
    }

    this.showTsAndCsForm = true;
    this.showTsAndCs = true;
  }

  //TOUR

  tourNext(): void {
    this.tourState.tourStep++;
    this.$cookies.put("tourStep", this.tourState.tourStep);
  }
  tourBack(): void {
    this.tourState.tourStep--;
    this.$cookies.put("tourStep", this.tourState.tourStep);
  }

  tourSkip(): void {
    this.tourEnabled = false;
    this.$cookies.put("tourEnabledPreapp", "false");
    this.$cookies.remove("tourStep");
  }

  startTour(): void {
    this.tourState.tourStep = 1;
    this.tourState.tourTotalSteps = 0;
    this.$cookies.put("tourStep", this.tourState.tourStep);
    this.tourEnabled = true;
    this.$cookies.put("tourEnabledPreapp", "true");
  }

  removeNote(note: CaseNoteDTO): void {
    this.dataLoading = true;
    this.caseNoteService
      .delete(note.Id)
      .then((response) => {
        let index = this.case.CaseNotes.indexOf(note);
        this.case.CaseNotes.splice(index, 1);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  saveNote(): void {
    this.dataLoading = true;
    this.caseNoteService
      .addUpdate(this.newNote)
      .then((response) => {
        this.case.CaseNotes.push(response);
        this.newNote = {
          CaseId: this.case.Id,
          UserId: this.currentUser.Id,
          ExpandNote: false,
        } as CaseNoteDTO;
        this.caseForm.$setPristine();
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  statusMap = [
    { status: 0, label: "New Case" },
    { status: 1, label: "Pre App" },
    { status: 2, label: "Decision in Principle" },
    { status: 3, label: "App Made" },
    { status: 4, label: "Credit Approval" },
    { status: 5, label: "With Professionals" },
    { status: 6, label: "Completed" },
    { status: 7, label: "Dormant" },
    { status: 9, label: "Submitted To Lenders Pending Review" },
  ];

  setStatus(newCaseStatus: CaseStatusEnum) {
    let statusText = this.statusMap.find((status) => {
      return status.status === newCaseStatus;
    });
    this.dataLoading = true;
    this.caseService
      .setStatusOnCase(this.case.Id, newCaseStatus)
      .then((statusUpdateResponse) => {
        this.caseService.updateTubeMap(newCaseStatus);
        let caseNote = {
          CaseId: this.case.Id,
          UserId: this.currentUser.Id,
          NoteText: "Status changed to: " + statusText.label,
          ExpandNote: false,
        } as CaseNoteDTO;
        this.caseNoteService.addUpdate(caseNote).then((caseNoteResponse) => {
          this.case.CaseNotes.push(caseNoteResponse);
        });
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  markSectionAsComplete(propertyName: string, noteText: string) {
    this.case[propertyName] = !this.case[propertyName];
    // TODO JNG this should probably not save the entire case.
    this.dataLoading = true;
    this.caseService
      .addUpdatereturnonlyid(this.case)
      .then((response) => {
        let caseNote = {
          CaseId: this.case.Id,
          UserId: this.currentUser.Id,
          NoteText:
            noteText +
            " marked as " +
            (this.case[propertyName] ? "complete" : "incomplete") +
            ".",
          ExpandNote: false,
        } as CaseNoteDTO;

        this.caseNoteService.addUpdate(caseNote).then((caseNoteResponse) => {
          this.case.CaseNotes.push(caseNoteResponse);
        });
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  showMessage(message: string) {
    this.messageContent = message;
  }

  requestEmail(reason): void {
    this.showRequestEmailForm = true;
    this.requestMessageReason = reason;
    this.message = true;
    this.messageContent = "";
  }

  sendRequestEmail() {
    this.showRequestEmailForm = false;
    this.sendingMessage = true;
    this.dataLoading = true;
    this.$accountservice
      .RequestAssistanceReview(this.requestMessageReason, this.case.Id)
      .then((response) => {
        this.showMessage(
          `${this.appName} have been notified that you require assistance and will be in touch shortly to help.`,
        );

        let caseNote = {
          CaseId: this.case.Id,
          UserId: this.currentUser.Id,
          NoteText: "You have requested " + this.requestMessageReason + ".",
          ExpandNote: false,
        } as CaseNoteDTO;
        this.caseNoteService.addUpdate(caseNote).then((caseNoteResponse) => {
          this.case.CaseNotes.push(caseNoteResponse);
        });
      })
      .catch((error) => {
        this.showMessage(
          "Sorry, something went wrong while sending your email. Please try again.",
        );
      })
      .finally(() => {
        this.sendingMessage = false;
        this.dataLoading = false;
      });
  }

  /** Opens the Terms and Conditions popup when the Submit to Lenders button is clicked*/
  showTermsAndConditionsForBorrower() {
    this.showApplyTsAndCs = true;
    this.requestMessageReason = "a review";
    this.showTsAndCs = true;
  }

  /** Request a Review button clicked */
  requestReview() {
    this.showTsAndCsForm = false;
    this.sendingMessage = true;
    this.dataLoading = true;
    this.requestMessageReason = "a review";
    this.caseService
      .requestReview(
        this.case.Id,
        this.currentUser.PhoneNumber,
        this.requestMessageReason,
      )
      .then((response) => {
        this.case.CaseStatus = response.CaseState;
        this.showMessage(
          `Thank you for accepting the terms and conditions. ${this.appName} have been notified that you require a review and will be in touch shortly to help.`,
        );
      })
      .catch((error) => {
        this.showMessage(
          "Sorry, something went wrong while sending your email. Please try again.",
        );
      })
      .finally(() => {
        this.sendingMessage = false;
        this.dataLoading = false;
        this.showApplyTsAndCs = false;
      });
  }

  sendPreAppEmail() {
    this.showTsAndCsForm = false;
    this.sendingMessage = true;
    this.dataLoading = true;
    this.caseService
      .SendPreAppEmail(this.case.Id)
      .then((response) => {
        this.showMessage(
          `${this.appName} have been notified that you have submitted your Appraisal and will be in touch shortly.`,
        );
        this.case.PreAppSubmitted = true;
        this.caseService.addUpdatereturnonlyid(this.case).then((response) => {
          let caseNote = {
            CaseId: this.case.Id,
            UserId: this.currentUser.Id,
            NoteText: "Your Appraisal has been submitted.",
            ExpandNote: false,
          } as CaseNoteDTO;

          this.caseNoteService.addUpdate(caseNote).then((caseNoteResponse) => {
            this.case.CaseNotes.push(caseNoteResponse);
          });
        });
      })
      .catch((error) => {
        this.showMessage(
          "Sorry, something went wrong while sending your email. Please try again.",
        );
      })
      .finally(() => {
        this.sendingMessage = false;
        this.dataLoading = false;
      });
  }

  /** Opens the Terms and Conditions popup when the Submit to Lenders HoT button is clicked (borrower/broker) */
  showTermsAndConditions() {
    this.dataLoading = true;
    this.$lenderService
      .getShortlistedLendersInfoForSubmitToLenders(
        this.shortListedLendersId,
        LenderProductTypeEnum.DevelopmentFinance,
        false,
      )
      .then((response) => {
        this.lendersDetailsForSubmittoLenders = response;
      })
      .finally(() => {
        this.showTsAndCs = true;
        this.showTsAndCsForm = true;
        this.case.SubmitTsAndCs = false;
        this.invalidEmail = false;
        this.dataLoading = false;
      });
  }

  /** Submit button clicked (on Terms and Conditions popup) */
  submitToLenders() {
    this.showTsAndCsForm = false;
    this.sendingMessage = true;
    this.dataLoading = true;

    this.caseService.addUpdatereturnonlyid(this.case);

    if (this.case.CaseStatus == CaseStatusEnum.ReadyToReSubmit) {
      this.caseService
        .resubmitToLenders(
          this.case.Id,
          this.brokerCommission,
          this.lendersDetailsForSubmittoLenders,
          this.brokerPreferredContact,
          this.isBrokerPhoneNumberUpdated,
        )
        .then((response) => {
          this.case.CaseStatus = response.CaseState;
          this.showMessage(
            "This case has been re-submitted to Lenders to request a Decision in Principle.",
          );
          this.isSubmittedToLenders = true;
        })
        .catch((error) => {
          this.showMessage("Sorry, something went wrong. Please try again.");
        })
        .finally(() => {
          this.sendingMessage = false;
          this.dataLoading = false;
          this.caseService.updateTubeMap(this.case.CaseStatus);
        });
    } else {
      this.caseService
        .submitToLenders(
          this.case.Id,
          this.brokerCommission,
          this.lendersDetailsForSubmittoLenders,
          this.brokerPreferredContact,
          this.isBrokerPhoneNumberUpdated,
        )
        .then((response) => {
          this.case.CaseStatus = response.CaseState;
          this.showMessage(
            "This case has been submitted to Lenders to request a Decision in Principle.",
          );
          this.isSubmittedToLenders = true;
        })
        .catch((error) => {
          this.showMessage("Sorry, something went wrong. Please try again.");
        })
        .finally(() => {
          this.sendingMessage = false;
          this.dataLoading = false;
          this.caseService.updateTubeMap(this.case.CaseStatus);
        });
    }
  }

  setApplyStatus() {
    this.dataLoading = true;
    this.showStatusMessage = true;
    this.caseService
      .setApplyStatus(this.case.Id)
      .then((statusUpdateResponse) => {
        this.case.CaseStatus = statusUpdateResponse;
        let caseNote = {
          CaseId: this.case.Id,
          UserId: this.currentUser.Id,
          NoteText: "User has moved application to app made stage",
          ExpandNote: false,
        } as CaseNoteDTO;
        this.caseNoteService.addUpdate(caseNote).then((caseNoteResponse) => {
          this.case.CaseNotes.push(caseNoteResponse);
        });
      })
      .catch((error) => {})
      .finally(() => {
        this.dataLoading = false;
      });
  }

  allFormsComplete(): boolean {
    if (this.case) {
      if (
        this.case.CaseOverviewComplete &&
        this.case.DeveloperExperienceComplete &&
        this.case.DevelopmentAppraisalComplete &&
        this.case.DevelopmentScheduleComplete
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  //case overview
  editCaseOverview() {
    //take a temp backup of what is in case overview in case they hit cancel
    this.caseOverviewTempBackup = this.case.CaseOverview;
    this.$location.path("/caseoverview/" + this.case.Id);
  }

  //Planning Module
  editPlanning() {
    //take a temp backup of what is in Planning Module in case they hit cancel
    this.planningURLTempBackup = this.case.PlanningURL;
    this.planningLocalAuthorityTempBackup = this.case.PlanningLocalAuthority;
    this.planningReferenceTempBackup = this.case.PlanningReference;
    this.planningDescriptionTempBackup = this.case.PlanningDescription;
    this.$location.path("/planning/" + this.case.Id);
    this.planningURLTempBackup = this.case.PlanningURL;
    this.planningLocalAuthorityTempBackup = this.case.PlanningLocalAuthority;
    this.planningReferenceTempBackup = this.case.PlanningReference;
    this.planningDescriptionTempBackup = this.case.PlanningDescription;
    this.$location.path("/planning/" + this.case.Id);
  }

  //Comparables Module
  editComparableProperties() {
    this.$location.path("/comparableproperties/" + this.case.Id);
  }

  editProfessionalTeam() {
    this.$location.path("/teammember/" + this.case.Id);
  }

  returnToPreApp(saveBefore: boolean): void {
    if (saveBefore) {
      this.saveCase().then((success) => {
        this.caseOverviewTempBackup = this.case.CaseOverview;
        this.$location.path("/casedashboard/" + this.case.Id);
      });
    } else {
      this.case.CaseOverview = this.caseOverviewTempBackup;
      this.$location.path("/casedashboard/" + this.case.Id);
    }
    (this.$rootScope as any).formSaved = true;
  }

  returnToCase(saveBefore: boolean): void {
    if (saveBefore) {
      this.saveCase().then((success) => {
        this.planningURLTempBackup = this.case.PlanningURL;
        this.planningLocalAuthorityTempBackup =
          this.case.PlanningLocalAuthority;
        this.planningReferenceTempBackup = this.case.PlanningReference;
        this.planningDescriptionTempBackup = this.case.PlanningDescription;
        this.$location.path("/casedashboard/" + this.case.Id);
      });
    } else {
      this.case.PlanningURL = this.planningURLTempBackup;
      this.case.PlanningLocalAuthority = this.planningLocalAuthorityTempBackup;
      this.case.PlanningReference = this.planningReferenceTempBackup;
      this.case.PlanningDescription = this.planningDescriptionTempBackup;
      this.$location.path("/casedashboard/" + this.case.Id);
    }
    (this.$rootScope as any).formSaved = true;
  }

  openSendToBorrower(contextToShare: number) {
    this.shareContext = contextToShare;
    this.shareSuplId = 0;
    if (this.selectedUser.UserName !== this.case.DevelopmentInput.OwnerUser) {
      this.userFirstName = "";
    } else {
      this.userFirstName = this.selectedUser.FirstName;
    }

    this.constructShareNoteMessage();

    this.caseMemberService
      .fetchcasememberswithaccess(this.case.Id)
      .then((response: CaseMemberDTO[]) => {
        this.caseMembersWithAccess = response;

        this.showShare = true;
      });
  }

  sendToBorrower() {
    this.dataLoading = true;
    this.caseService
      .sendNoteToBorrower(
        this.selectedClientToShareWith.FirstName,
        this.selectedClientToShareWith.Surname,
        this.selectedClientToShareWith.Email,
        this.shareNote,
        this.case.Id,
        this.shareContext,
        this.shareSuplId,
        this.case.DevelopmentExperienceID,
      )
      .then((success) => {
        this.showShare = false;
        this.dataLoading = false;
        this.showModuleShareConfirmation = true;
      });
  }

  closeModuleShareConfirmationModal() {
    this.selectedClientToShareWith = null;
    this.showModuleShareConfirmation = false;
  }

  CalcTotalPurchaseCostsNotOwning(): number {
    var res: number = 0;
    if (this.case.DevelopmentInput.CI_Dev_OrigPP) {
      res += Number(this.case.DevelopmentInput.CI_Dev_OrigPP);
    }
    if (this.case.DevelopmentInput.CI_Dev_SDLT) {
      res += Number(this.case.DevelopmentInput.CI_Dev_SDLT);
    }
    if (this.case.DevelopmentInput.DI_PurchaseAgentFees) {
      res += Number(this.case.DevelopmentInput.DI_PurchaseAgentFees);
    }
    if (this.case.DevelopmentInput.DI_PurchaseLegalFees) {
      res += Number(this.case.DevelopmentInput.DI_PurchaseLegalFees);
    }
    if (this.case.DevelopmentInput.DI_PurchaseOtherFees) {
      res += Number(this.case.DevelopmentInput.DI_PurchaseOtherFees);
    }
    this.totalPurchaseCostsNotOwning = res;
    return res;
  }

  CalcTotalPurchaseCostsOwning(): number {
    var res: number = 0;
    if (this.case.DevelopmentInput.CI_Dev_OrigPP) {
      res += Number(this.case.DevelopmentInput.CI_Dev_OrigPP);
    }
    if (this.case.DevelopmentInput.CI_Dev_SDLT) {
      res += Number(this.case.DevelopmentInput.CI_Dev_SDLT);
    }
    if (this.case.DevelopmentInput.DI_PurchaseAgentFees) {
      res += Number(this.case.DevelopmentInput.DI_PurchaseAgentFees);
    }
    if (this.case.DevelopmentInput.DI_PurchaseLegalFees) {
      res += Number(this.case.DevelopmentInput.DI_PurchaseLegalFees);
    }
    if (this.case.DevelopmentInput.DI_PurchaseOtherFees) {
      res += Number(this.case.DevelopmentInput.DI_PurchaseOtherFees);
    }
    this.totalPurchaseCostsOwning = res;
    return res;
  }

  CalcTotalBuild(): number {
    var res: number = this.CalcAdditionalBuild();
    if (this.case.DevelopmentInput.CI_Dev_BuildCosts) {
      res += Number(this.case.DevelopmentInput.CI_Dev_BuildCosts);
    }
    var res: number = this.CalcAdditionalBuild();
    if (this.case && this.case.DevelopmentInput.CI_Dev_BuildCosts) {
      res += Number(this.case.DevelopmentInput.CI_Dev_BuildCosts);
    }

    res += this.calcContingencyCost();
    this.totalBuild = res;
    return res;
  }

  calcContingencyCost(): number {
    var res: number = 0;
    if (
      this.case.DevelopmentInput.CI_Dev_Contingency &&
      this.case.DevelopmentInput.CI_Dev_BuildCosts
    ) {
      res =
        this.case.DevelopmentInput.CI_Dev_Contingency *
        this.case.DevelopmentInput.CI_Dev_BuildCosts;
      this.contingencyTotal = res;
      return res;
    }
    return res;
  }

  CalcAdditionalBuild(): number {
    var res: number = 0;
    if (this.case.DevelopmentInput.DI_BreakDownBuildCosts) {
      if (this.case.DevelopmentInput.CI_Dev_ProfPlanning) {
        res += Number(this.case.DevelopmentInput.CI_Dev_ProfPlanning);
      }
      if (this.case.DevelopmentInput.CI_Dev_ProfQS) {
        res += Number(this.case.DevelopmentInput.CI_Dev_ProfQS);
      }
      if (this.case.DevelopmentInput.DI_BuildProjectManag) {
        res += Number(this.case.DevelopmentInput.DI_BuildProjectManag);
      }
      if (this.case.DevelopmentInput.CI_Dev_S106CIL) {
        res += Number(this.case.DevelopmentInput.CI_Dev_S106CIL);
      }
      if (this.case.DevelopmentInput.DI_BuildMechEng) {
        res += Number(this.case.DevelopmentInput.DI_BuildMechEng);
      }
      if (this.case.DevelopmentInput.DI_BuildStrucEng) {
        res += Number(this.case.DevelopmentInput.DI_BuildStrucEng);
      }
      if (this.case.DevelopmentInput.DI_BuildPartyWall) {
        res += Number(this.case.DevelopmentInput.DI_BuildPartyWall);
      }
      if (this.case.DevelopmentInput.DI_BuildLandscaping) {
        res += Number(this.case.DevelopmentInput.DI_BuildLandscaping);
      }
      if (this.case.DevelopmentInput.DI_BuildWarranty) {
        res += Number(this.case.DevelopmentInput.DI_BuildWarranty);
      }
      if (this.case.DevelopmentInput.DI_BuildDemolition) {
        res += Number(this.case.DevelopmentInput.DI_BuildDemolition);
      }
      if (this.case.DevelopmentInput.DI_BuildOtherCosts) {
        res += Number(this.case.DevelopmentInput.DI_BuildOtherCosts);
      }
    } else {
      if (this.case.DevelopmentInput.CI_Dev_AdditionalOngoingCosts) {
        res += Number(this.case.DevelopmentInput.CI_Dev_AdditionalOngoingCosts);
      }
    }
    this.additionalBuild = res;
    return res;
  }

  videotour() {
    this.showVideo = true;
  }

  videopause(id) {
    this.showVideo = false;
    let containerElement = document.getElementById(id);
    let iframe_tag = containerElement.querySelector("iframe");
    if (iframe_tag) {
      let iframeSrc = iframe_tag.src;
      iframe_tag.src = iframeSrc;
    }
  }

  addTeamRoleOption(e) {
    this.newTeamMember.TeamRole = "";
    e.target.value = "";
  }

  editTeamRoleOption(e) {
    this.editTeamMember.TeamRole = "";
    e.target.value = "";
  }

  EnumToString(item: ModuleEnum) {
    switch (item) {
      case 0:
        return "Case Overview";
      case 1:
        return "Developer Profile";
      case 2:
        return "Development Appraisal";
      case 3:
        return "Development Schedule";
      case 4:
        return "Planning";
      case 5:
        return "Professional Team";
      case 6:
        return "Comparable Properties";
      case 7:
        return "General";
      case 8:
        return "Track Record";
      case 9:
        return "Assets & Liabilities";
      case 10:
        return "Proof Of Income";
      case 11:
        return "Applicant Details";
      case 12:
        return "Pictures";
      default:
        return "General";
    }
  }

  gotoLendingPage() {
    (this.$rootScope as any).isLender = false;
    this.$location.path("/lending/" + this.case.Id);
  }

  checkBrokerDeal() {
    return this.case?.BrokerUserId != null && this.isClient ? true : false;
  }

  /*  gotoPaymentPage() {
              this.$location.path("/payment/" + this.case.Id);
          }*/

  gotoHeadsOfTermsPage() {
    this.$location.path("/headsofterm/" + this.case.Id);
  }

  /** Approve button clicked (This functionality has been removed from the process)
        approve() {
            this.dataLoading = true;
            this.caseService.approve(this.case.Id).then((response) => {
                this.case.CaseStatus = response.CaseState;
                this.caseService.updateTubeMap(response.CaseState);
                this.showMessage("Your case has been approved.");
                this.showStatusMessage = true;
            }).catch((error) => {
                this.showMessage("Sorry, something went wrong while approving your case. Please try again.");
            }).finally(() => {
                this.dataLoading = false;
            });;
        }*/

  setBrokerOrganisation(value: number) {
    // TODO JNG this should probably not save the entire case.
    this.dataLoading = true;
    this.case.BrokerUserId = null;

    this.caseService
      .changeBrokerOrganisation(this.case.Id, this.case.BrokerOrganisationId)
      .then((response: CaseNoteDTO) => {
        //populating the users related to selected new organisation.
        this.getBrokerUsers();

        this.case.CaseNotes.push(response);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  setBrokerUser(value: string) {
    this.dataLoading = true;
    this.caseService
      .changeBrokerUser(this.case.Id, this.case.BrokerUserId)
      .then((response: CaseNoteDTO) => {
        this.case.CaseNotes.push(response);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  goToLending() {
    this.$location.path("/lending/" + this.case.Id);
    this.caseService.setIsProceed(false);
  }

  isShareNoteFormComplete(): boolean {
    if (!this.selectedClientToShareWith) {
      return false;
    }

    if (
      this.shareNote == "undefined" ||
      this.shareNote == null ||
      this.shareNote.length == 0
    ) {
      return false;
    }

    return true;
  }

  constructShareNoteMessage() {
    var message: string;
    var messageStart: string = `Please take a look at the `;
    var messageEnd: string =
      ` section in your Smart Appraisal™ and complete as much as you can.\n\nIf you would like our help or want us to complete this module with you, please do give me a call on ` +
      this.currentUser.PhoneNumber +
      `.`;

    switch (this.shareContext) {
      case 1: //overview
        message =
          `Please take a look at the case overview I have started for you and add to it where you can.\n\nThis section provides the lender with an overview about you, your team and your project.  Lenders approve just 1 in 4 applications due to poor presentation, so we need to make it as impactful as possible.\n\nIf you would like to call me so we can add the notes together, my number is ` +
          this.currentUser.PhoneNumber +
          `.`;
        break;
      case 2: //dev experience
        this.shareSuplId = this.case.DevelopmentExperienceID
          ? this.case.DevelopmentExperienceID
          : -1;
        message = messageStart + `ownership` + messageEnd;
        break;
      case 3: //dev appraisal
        this.shareSuplId = this.case.DevelopmentInput.Id;
        message = messageStart + `development appraisal` + messageEnd;
        break;
      case 4: //schedule
        this.shareSuplId = this.case.DevelopmentInput.Id;
        message = messageStart + `development schedule` + messageEnd;
        break;
      case 5: //files
        message = `Please upload the supporting documents associated with your project.\n\nAdding supporting documents helps lenders make quicker and more reliable decisions.`;
        break;
      case 6: //planning module
        this.shareSuplId = this.case.DevelopmentInput.Id;
        message = messageStart + `planning section` + messageEnd;
        break;
      case 7: //Professional Team
        this.shareSuplId = this.case.DevelopmentInput.Id;
        message = messageStart + `professional team` + messageEnd;
        break;
      case 8: //comparable properties module
        message = messageStart + `comparable properties` + messageEnd;
        break;
      case 9: //profiles module
        message = messageStart + `profiles` + messageEnd;
        break;
    }

    this.shareNote = message;
  }

  /** Process the Heads of Terms button being clicked */
  headsOfTermsClicked() {
    sessionStorage.setItem("lenderTask", "terms");
    this.$location.path(
      "/headsofterm/" + this.case.Id + "/" + this.lenderDetails.Id,
    );
  }

  showAndHideExport() {
    this.showExport = !this.showExport;
  }

  /** Lender rejects the case*/
  rejectButtonClicked() {
    sessionStorage.setItem("lenderTask", "reject");
    this.$location.path(
      "/headsofterm/" + this.case.Id + "/" + this.lenderDetails.Id,
    );
  }

  withDrawButtonClicked() {
    sessionStorage.setItem("lenderTask", "withdraw");
    this.$location.path(
      "/headsofterm/" + this.case.Id + "/" + this.lenderDetails.Id,
    );
  }

  feedbackButtonClicked() {
    sessionStorage.setItem("lenderTask", "feedback");
    this.$location.path(
      "/headsofterm/" + this.case.Id + "/" + this.lenderDetails.Id,
    );
  }

  getAppName() {
    let lsd = sessionStorage.getItem("applicationName");
    if (lsd != null) {
      this.appName = lsd;
    } else {
      this.appName = "Brickflow";
    }
  }

  closeModalAndGoToDasboard() {
    this.showTsAndCs = false;
    this.authService.clearSelectedNavMenuAndFilterStorageData();
    this.$location.path("/userdashboard");
  }

  closeModalAndGoToDealForum() {
    this.showTsAndCs = false;
    this.$location.path("/lending/" + this.case.Id);
  }

  checkIfUserIsInvited() {
    //added to scope for directive
    this.caseService
      .checkIfUserIsInvited(this.currentUser.Id, this.$routeParams.CaseId)
      .then((isInvited: boolean) => {
        // if user has no active subscription (and are not a lender or admin) then they should be redirected back to the dashboard
        if (
          this.currentUser.SubscriptionStatus !=
            LicenseMasterStatusEnum.PaidUp &&
          this.currentUser.SubscriptionStatus !=
            LicenseMasterStatusEnum.PreCancel &&
          this.currentUser.SubscriptionStatus !=
            LicenseMasterStatusEnum.PaymentProcessing &&
          !isInvited &&
          !this.isAdmin &&
          !this.isLender
        ) {
          this.$location.path("/dashboard");
          return;
        }

        (this.$rootScope as any).isInvitedToCase = isInvited;
        if (isInvited) {
          this.caseMemberService
            .fetchByCaseId(this.$routeParams.CaseId)
            .then((response) => {
              if (response != null && response.length > 0) {
                var currentShareholder = response.find(
                  (x) => x.UserId == this.currentUser.Id,
                );

                if (
                  currentShareholder != null &&
                  currentShareholder.AccessLevel == CaseAccessLevelEnum.ReadOnly
                ) {
                  (this.$rootScope as any).isInvitedAndHasReadonlyAccess = true;
                }
              }
              this.$rootScope.$broadcast("invitedStatusChange");
            });
        } else {
          this.$rootScope.$broadcast("invitedStatusChange");
        }
      });
  }

  getLenderCount() {
    this.$lenderService
      .getTotalLenders(ProductFamilyEnum.Development)
      .then((result) => {
        this.totalLender = result;
      });
  }

  closeConfirmationModal() {
    if (this.isSubmittedToLenders) {
      window.location.reload();
    }
    this.showTsAndCs = false;
  }

  getSelectedLenderName() {
    if (
      this.isBroker &&
      this.case.DevelopmentInput &&
      this.case.DevelopmentInput.SelectedResults.length == 1
    ) {
      this.productService
        .fetch(this.case.DevelopmentInput.SelectedResults[0].ProductId)
        .then((product: ProductDTO) => {
          this.selectedLendersText = product.LenderName;
        });
    }
  }

  redactInfo() {
    this.eventLogService.logEvent(
      "EXPORTCASEPDFCLICKED",
      EventLogEnum.Export,
      ProductTypeEnum.Development,
      this.case.Id,
    );
    if (this.isClient) {
      this.redactSensitiveInfo = true;
    }
  }

  autoFillComparable(save: boolean = true) {
    this.newTeamMember;
    this.newComparable = {
      CaseId: this.case.Id,
      URL: "https://www.rightmove.co.uk/properties/118409864#/?channel=RES_BUY",
      PropType: "4 bed detached house",
      Value: 1100000,
      IsGoodComparable: true,
      Comments:
        "It is a very similar spec and layout to our property and is in the same side of the road - south-facing",
    } as ComparablePropertyDTO;

    if (save) {
      this.saveComparable(this.newComparable);
    } else {
      this.case.Comparables.push(this.newComparable);
    }

    this.newComparable = {
      CaseId: this.case.Id,
      URL: "https://www.rightmove.co.uk/properties/119098043#/?channel=RES_BUY",
      PropType: "4 bed detached house",
      Value: 1510000,
      IsGoodComparable: true,
      Comments: "Similar property - different street",
    } as ComparablePropertyDTO;

    if (save) {
      this.saveComparable(this.newComparable);
    } else {
      this.case.Comparables.push(this.newComparable);
    }
  }

  autoFillTeammember(save: boolean = true) {
    this.newTeamMember = {
      UserId: this.currentUser.Id,
      CaseId: this.case.Id,
      TeamRole: "TeamRole",
      Name: "Jane Doe",
      Email: "jane@jdarchitects.com",
      Website: "https://janedoearchitects.com/",
      WorkedWithBefore: true,
      PreviousProjects: "Worked with them on our project in Soho Square",
    } as TeamMemberDTO;

    if (save) {
      this.saveTeamMember(this.newTeamMember);
    } else {
      this.case.TeamMembers.push(this.newTeamMember);
    }

    this.newTeamMember = {
      UserId: this.currentUser.Id,
      CaseId: this.case.Id,
      TeamRole: "Main Contractor ",
      Name: "JS Build & Design",
      Email: "JS Build & Design",
      Website: "https://jsbuildanddesign.com/",
      WorkedWithBefore: true,
      PreviousProjects: "https://jsbuildanddesign.com/",
    } as TeamMemberDTO;

    if (save) {
      this.saveTeamMember(this.newTeamMember);
    } else {
      this.case.TeamMembers.push(this.newTeamMember);
    }
  }

  autofillCase() {
    this.autoFillComparable(false);
    this.autoFillTeammember(false);
    this.case.PurchaseProcessType = PurchaseProcessTypeEnum.RegularPurchase;
    this.case.PlanningPermissionType =
      PlanningPermissionTypeEnum.DetailedPlanning;
    this.case.PlanningResponsibility = PlanningResponsibilityEnum.Borrower;
    this.case.PlanningDescription =
      "We purchased the site with outline planning 6 months ago, with a bridging loan.We havenow dealt with all reserved matters, and have detailed planning permission for 26x 3 & 4 bed houses.";
    this.case.SiteDescriptionOnCompletion =
      "The site will be bare land at completion.";
    this.case.BuildProcurementType = BuildProcurementTypeEnum.SelfManaged;
    this.case.BuildProcurementDescription =
      "We are managing the build ourselves and will tender the individual packages. We have worked this way on 3x previous personal projects. Prior to that one of our shareholders worked as a PM for 10 years at a regional house builder, managing projects of up to 150 houses. ";
    this.case.DepositDescription =
      "50% of the deposit will come from the shareholders and 50% will come from investors. The investors are family and friends and their investment will be secured as a 2nd charge.";
    this.case.GuaranteeDescription = "This is the dummy text";
    this.case.DevelopmentInput.CI_Dev_ExitStrategy =
      ExitStrategyEnum.PartSalePartRefinance;
    this.case.HasSetCompletionDate = true;
    let maxDate = Date.now();
    const timestamp = Math.floor(Math.random() * maxDate);
    this.case.CompletionDate = new Date(timestamp);
    this.case.DevelopmentInput.SearchPreferenceLoanSize = 1;
    this.case.DevelopmentInput.SearchPreferencePricing = 2;
    this.case.DevelopmentInput.SearchPreferenceCompletionSpeed = 3;
    this.case.DevelopmentInput.SearchPreferenceCustomerService = 4;
    this.case.DevelopmentInput.HowManyHouses = 2;
    this.caseService.autofillCase(this.case).then((response) => {
      window.location.reload();
    });
  }

  getNoteText(text: string) {
    if (this.isClient && text.startsWith("Project submitted to lenders")) {
      return "Project submitted to lenders";
    } else {
      return text;
    }
  }

  updateRequestAssitanceCaseNoteMessage() {
    if (this.case?.CaseNotes != null && this.currentUser != null) {
      this.case.CaseNotes = this.case.CaseNotes.map((d) => {
        if (
          d.UserId != this.currentUser.Id &&
          (d.NoteText == "Your have requested for assistance." ||
            d.NoteText == "You have requested assistance.")
        ) {
          return { ...d, NoteText: `${d.UserName} requested assistance.` };
        }
        return d;
      });
    }
  }

  closeSubmitToLenderModal() {
    this.showTsAndCs = false;
    this.brokerCommission =
      this.case.CaseStatus == CaseStatusEnum.NewCase
        ? this.org?.CommissionPercent
        : this.case.BrokerCommissionPercent;
    this.brokerPreferredContact =
      this.case.CaseStatus == CaseStatusEnum.NewCase
        ? this.caseBrokerUser?.PhoneNumber
        : this.case.BrokerPhoneNumber;
    this.isBrokerPhoneNumberUpdated = false;
  }

  validateEmails() {
    this.invalidEmail = false;

    angular.forEach(
      this.lendersDetailsForSubmittoLenders,
      (lenderdetails, index) => {
        var emailField = this.lenderDetailsForm["email" + index];

        if (emailField && emailField.$invalid) {
          this.invalidEmail = true;
        }
      },
    );
  }
}

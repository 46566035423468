import { LenderDTO } from "@js/DTO/LenderDTO.cs.d";
import { LenderInfo } from "@js/DTO/Messages/Deal/LendersInfoForSubmitToLenderMessage.cs.d";
import { GetSelectedProductLendersNameAndIdResponse } from "@js/DTO/Messages/GetSelectedProductLendersNameAndIdMessage.cs.d";
import { OrganisationDTO } from "@js/DTO/OrganisationDTO.cs.d";
import { SelectedResultDTO } from "@js/DTO/SelectedResultDTO.cs.d";
import { BaseService } from "@js/filesfromccqbase/BaseService";
import { BroadcastService } from "@js/filesfromccqbase/BroadcastService";
import { LenderProductTypeEnum } from "@js/models/enum/LenderProductTypeEnum.cs.d";
import { ProductFamilyEnum } from "@js/models/enum/ProductFamilyEnum.cs.d";
import { LenderProductPairDTO } from "@js/DTO/LenderProductPairDTO.cs.d"

export class LenderService extends BaseService<LenderDTO, number> {
  static $inject = [
    "$http",
    "$cookies",
    "$rootScope",
    "$q",
    "$timeout",
    "BroadcastService",
  ];

  constructor(
    http: ng.IHttpService,
    cookies: ng.cookies.ICookiesService,
    rootScope: ng.IRootScopeService,
    q: ng.IQService,
    timeout: ng.ITimeoutService,
    broadcastservice: BroadcastService,
  ) {
    super(http, cookies, rootScope, q, timeout, broadcastservice);
    this.$baseAddress = "/api/lender";
    this.$broadcastBusinessNameSingular = "lender";
    this.$broadcastBusinessNamePlural =
      this.$broadcastBusinessNameSingular + "s";
    this.$broadcastScreen = this.$broadcastBusinessNamePlural;
  }

  getUserLenderDetails(): ng.IPromise<LenderDTO> {
    let defer = this.$q.defer<LenderDTO>();
    this.$http
      .get(this.$baseAddress + "/getuserlenderdetails")
      .then((response) => {
        defer.resolve(response.data as LenderDTO);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getAllLenderLogos(lenderId: number[]): ng.IPromise<string[]> {
    let defer = this.$q.defer<string[]>();

    this.$http
      .post(
        this.$baseAddress + "/getlenderlogos",
        JSON.stringify(lenderId, this.jsonPropertyReplacer),
      )
      .then((response) => {
        defer.resolve(response.data as string[]);
      })
      .catch((response) => {
        defer.reject(response);
      });

    return defer.promise;
  }

  getTotalLenders(
    productType: ProductFamilyEnum = ProductFamilyEnum.None,
  ): ng.IPromise<number> {
    let defer = this.$q.defer<number>();

    this.$http
      .get(this.$baseAddress + "/gettotallenders?productType=" + productType)
      .then((response) => {
        defer.resolve(response.data as number);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getSelectedLendersName(
    selectedResults: SelectedResultDTO[],
  ): ng.IPromise<GetSelectedProductLendersNameAndIdResponse> {
    let defer = this.$q.defer<GetSelectedProductLendersNameAndIdResponse>();
    this.$http
      .post(
        this.$baseAddress + "/getselectedlendersname",
        JSON.stringify(selectedResults),
      )
      .then((response) => {
        defer.resolve(
          response.data as GetSelectedProductLendersNameAndIdResponse,
        );
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getSelectedDealLenderNames(
    dealId: number,
  ): ng.IPromise<GetSelectedProductLendersNameAndIdResponse> {
    let defer = this.$q.defer<GetSelectedProductLendersNameAndIdResponse>();
    this.$http
      .get(this.$baseAddress + "/getselecteddeallendernames?dealId=" + dealId)
      .then((response) => {
        defer.resolve(
          response.data as GetSelectedProductLendersNameAndIdResponse,
        );
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getShortlistedLendersName(
    selectedResults: SelectedResultDTO[],
  ): ng.IPromise<GetSelectedProductLendersNameAndIdResponse> {
    let defer = this.$q.defer<GetSelectedProductLendersNameAndIdResponse>();
    this.$http
      .post(
        this.$baseAddress + "/getshortlistedlendersname",
        JSON.stringify(selectedResults),
      )
      .then((response) => {
        defer.resolve(
          response.data as GetSelectedProductLendersNameAndIdResponse,
        );
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getShortlistedDealLenderNames(
    dealId: number,
  ): ng.IPromise<GetSelectedProductLendersNameAndIdResponse> {
    let defer = this.$q.defer<GetSelectedProductLendersNameAndIdResponse>();
    this.$http
      .get(
        this.$baseAddress + "/getshortlisteddeallendernames?dealId=" + dealId,
      )
      .then((response) => {
        defer.resolve(
          response.data as GetSelectedProductLendersNameAndIdResponse,
        );
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getLenderProductTypes(): ng.IPromise<LenderProductTypeEnum> {
    let defer = this.$q.defer<LenderProductTypeEnum>();
    this.$http
      .get(this.$baseAddress + "/getlenderproducttypes")
      .then((response) => {
        defer.resolve(response.data as LenderProductTypeEnum);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getShortlistedLendersInfoForSubmitToLenders(
    shortlistedLenders: Number[],
    productType: LenderProductTypeEnum,
    isDeal: boolean = true,
  ): ng.IPromise<LenderInfo[]> {
    let defer = this.$q.defer<LenderInfo[]>();
    this.$http
      .post(
        this.$baseAddress +
          "/getshortlistedlendersinfoforsubmittolenders?productType=" +
          productType +
          "&isDeal=" +
          isDeal,
        shortlistedLenders,
      )
      .then((response) => {
        defer.resolve(response.data as LenderInfo[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getSelectedLenderPreferredOrgs(
    lenderId: number,
  ): ng.IPromise<OrganisationDTO[]> {
    let defer = this.$q.defer<OrganisationDTO[]>();
    this.$http
      .get(
        this.$baseAddress +
          "/getselectedlenderpreferredorgs?lenderId=" +
          lenderId,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  updatePreferredOrgs(
    lenderId: number,
    orgIds: number[],
  ): ng.IPromise<OrganisationDTO[]> {
    let defer = this.$q.defer<OrganisationDTO[]>();
    this.$http
      .post(
        this.$baseAddress + "/updatepreferredorgs?lenderId=" + lenderId,
        orgIds,
      )
      .then((response) => {
        defer.resolve(response.data as OrganisationDTO[]);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getLenderPreferredPackagersText(lenderId: number): ng.IPromise<string> {
    let defer = this.$q.defer<string>();
    this.$http
      .get(
        this.$baseAddress +
          "/getlenderpreferredpackagerstext?lenderId=" +
          lenderId,
      )
      .then((response) => {
        defer.resolve(response.data as string);
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  getLenderPortalLinks(
    lenderIds: number[],
  ): ng.IPromise<{ [key: number]: string }> {
    let defer = this.$q.defer<{ [key: number]: string }>();
    this.$http
      .post(this.$baseAddress + "/getlenderportallinks", lenderIds)
      .then((response) => {
        defer.resolve(response.data as { [key: number]: string });
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }
  
  getDealDeletedLenderIds(dealId: number): ng.IPromise<LenderProductPairDTO[]> {
      let defer = this.$q.defer<LenderProductPairDTO[]>();

      this.$http.get(this.$baseAddress + "/getdealdeletedlenderids?dealId=" + dealId).then((response) => {
          defer.resolve(response.data as LenderProductPairDTO[]);
      }).catch((response) => {
          defer.reject(response);
      });

      return defer.promise;
  }

}

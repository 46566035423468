export class EnterpriseService {
    productFamily: number = 0;
  
    constructor() {}
  
    getProductFamily() {
      return this.productFamily;
    }
  
    setProductFamily(productFamily: number) {
      this.productFamily = productFamily;
    }
  }